import { Text } from "@gigsmart/atorasu";
import type { EnumRecord } from "@gigsmart/type-utils";
import startCase from "lodash/startCase";
import React from "react";
import BackgroundChecksModalContent from "./background-checks";
import DrugScreeningModalContent from "./drug-screening";
import EORWorkersModalContent from "./eor-workers";
import MotorVehicleChecksModalContent from "./motor-vehicle-checks";
import OccupationalAccidentInsuranceModalContent from "./occupational-accident-insurance";
import type {
  AddonName,
  AddonSpec,
  EngagementAddonName,
  EngagementAddonSpec
} from "./types";

const addons: { [key in AddonName]?: AddonSpec } = {
  BACKGROUND_CHECKS: {
    ModalContent: BackgroundChecksModalContent,
    name: "Background Checks",
    icon: "user-shield",
    optInKey: "backgroundCheckOptIn"
  },
  MOTOR_VEHICLE_CHECKS: {
    ModalContent: MotorVehicleChecksModalContent,
    name: "MVR Checks",
    icon: "car-side",
    optInKey: "driverCheckOptIn"
  },
  OCCUPATIONAL_ACCIDENT_INSURANCE: {
    ModalContent: OccupationalAccidentInsuranceModalContent,
    name: "Worker Insurance",
    icon: "user-helmet-safety",
    modalFooterText:
      "OAI coverage is required and included on all Gigs on the GigSmart platform."
  },
  GENERAL_LIABILITY_INSURANCE: {
    name: "General Liability Insurance",
    icon: "warehouse-full"
  },
  SHIFT_GIG_PAYMENTS: {
    hide: true,
    name: "Shift Gig Payments",
    icon: "money-bill-wave"
  },
  ONE_WEEK_BOOST: {
    hide: true,
    name: "One Week Boost",
    icon: "angles-up"
  },
  DRUG_SCREENS: {
    ModalContent: DrugScreeningModalContent,
    name: "Drug Screening",
    icon: "pills",
    optInKey: "drugScreenOptIn"
  },
  EOR_WORKERS: {
    ModalContent: EORWorkersModalContent,
    name: "W-2 Workers",
    icon: "passport"
  }
};

export function getAddonSpec(name: keyof typeof addons): AddonSpec {
  return (
    addons[name] ?? {
      ModalContent: () => (
        <Text align="center">
          Update your app to see additional information about this addon.
        </Text>
      ),
      name: name
        ? name
            .split(" ")
            .map((s) => startCase(s.toLowerCase()))
            .join(" ")
        : "",
      icon: "plus-square",
      optInKey: ""
    }
  );
}

const engagementAddons: EnumRecord<EngagementAddonName, EngagementAddonSpec> = {
  OCCUPATIONAL_ACCIDENT_INSURANCE: {
    name: "Trust & Support Fee",
    linkText: "More Info",
    linkUrl: "https://gigsmart.com/workers/occupational-accident-insurance"
  },
  GENERAL_LIABILITY_INSURANCE: {
    name: "General Liability Insurance",
    linkText: "More Info",
    linkUrl: "https://gigsmart.com/workers/general-liability-insurance"
  }
};

export function filterHiddenGigAddons<T extends { addonType: AddonName }>(
  addons: T[]
): T[] {
  return addons.filter(({ addonType }) => !getAddonSpec(addonType).hide);
}

export function getEngagementAddonSpec(
  name: string | null
): EngagementAddonSpec {
  if (!!name && name in engagementAddons) {
    return engagementAddons[name as keyof typeof engagementAddons];
  }
  return {
    name: name
      ? name
          .split(" ")
          .map((s) => startCase(s.toLowerCase()))
          .join(" ")
      : "",
    linkText: "Update to see more info."
  };
}
