/**
 * @generated SignedSource<<197000d916370233703f2a275e82efde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OfferedAttachment_engagement$data = {
  readonly gig: {
    readonly gigType: GigType | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
  };
  readonly id: string;
  readonly " $fragmentType": "OfferedAttachment_engagement";
};
export type OfferedAttachment_engagement$key = {
  readonly " $data"?: OfferedAttachment_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferedAttachment_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferedAttachment_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredReportTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "c658906bc3c2a8d9cd5cf58a68e776b7";

export default node;
