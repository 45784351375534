import {
  Column,
  Spacer,
  Stack,
  type StepProps,
  StepSubmit,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";
import useUpdateWorkerMutation from "./hooks/use-update-worker-mutation";
import type { OnboardingData } from "./types";

type Props = StepProps<OnboardingData>;

export default function DateOfBirthStep({ stepper }: Props) {
  const updateWorker = useUpdateWorkerMutation();
  const [age, setAge] = useState<string>("---");
  const styles = useStyles({
    ageCell: { minWidth: 56 }
  });

  const calculateAge = (dateOfBirth?: string | null) => {
    if (!dateOfBirth) return -1;
    const momentObj = moment(dateOfBirth, "MM/DD/YYYY", true);
    return moment().diff(momentObj, "years");
  };

  const showErrorsCalculateAge = (value: string | null) => {
    const age = calculateAge(value);
    const ageDisplay =
      Number.isNaN(age) || age < 0 || age > 120 ? "---" : age.toString();
    setAge(ageDisplay);
  };

  const handleSubmit: FomuSubmitFn = useCallback(async ({ values }, done) => {
    const formattedDate = DateTime.fromFormat(
      values.birthdate,
      "MM/dd/yyyy"
    ).toISODate();
    try {
      await updateWorker?.({ birthdate: formattedDate });
      stepper.nextStep({ birthdate: values.birthdate });
    } catch (err) {
      done();
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Stack horizontal>
        <Column fill>
          <FomuTextInput
            name="birthdate"
            validates={Validator.date({
              format: "MM/DD/YYYY",
              options: {
                strict: true,
                min: moment().subtract(113, "years"),
                max: moment().subtract(18, "years")
              }
            })}
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            testID="date-of-birth-input"
            onChangeText={(val) => {
              if (val && val.length === 10) {
                showErrorsCalculateAge(val);
              }
            }}
            type="number"
            mask="date"
            note="MM / DD / YYYY"
            autoFocus
            textAlign="left"
          />
          <Spacer />
          <Text variant="note" color="neutral">
            Date of birth is private and not shared with requesters.
          </Text>
        </Column>
        <Column style={styles.ageCell}>
          <Text variant="subheader" color="primary" align="center">
            Age
          </Text>
          <Spacer />
          <Text align="center">{age}</Text>
        </Column>
      </Stack>
      <StepSubmit testID="next-btn" label="Next" />
    </Form>
  );
}
