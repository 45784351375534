import {
  MessageList as AtlasMessageList,
  Column,
  Surface,
  humanizeDate
} from "@gigsmart/atorasu";
import {
  createSuspendedQueryContainer,
  getConnectionKey,
  getConnectionNodes,
  graphql,
  useRelayMutationPromise,
  useRelayPaginationFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import type { Object as GSObject } from "@gigsmart/type-utils";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import AdminConversationHeader from "./AdminConversationHeader";
import MessageListFooter from "./MessageListFooter";
import RequesterConversationHeader from "./RequesterConversationHeader";
import UserMessage from "./UserMessage";
import UserMessageContext from "./UserMessageContext";
import WorkerConversationHeader from "./WorkerConversationHeader";
import type { MessageListMutation } from "./__generated__/MessageListMutation.graphql";
import type { MessageListQuery } from "./__generated__/MessageListQuery.graphql";
import type { MessageListSubscription } from "./__generated__/MessageListSubscription.graphql";
import type {
  MessageList_ConversationLike$data,
  MessageList_ConversationLike$key
} from "./__generated__/MessageList_ConversationLike.graphql";

interface Props {
  conversationId: string;
  contextId?: string | undefined;
  variant: "requester" | "worker" | "admin";
}

type Message = GSObject.Path<
  MessageList_ConversationLike$data,
  ["messages", "edges", 0, "node"]
>;

export default createSuspendedQueryContainer<MessageListQuery, Props>(
  function MessageList({ conversationId, contextId, variant, response }) {
    const isAdmin = variant === "admin";
    const [editMessages, setEditMessages] = React.useState<boolean>(false);
    const { data, hasPrevious, loadPrevious, isLoadingPrevious } =
      useRelayPaginationFragment<
        MessageListQuery,
        MessageList_ConversationLike$key
      >(
        graphql`
          fragment MessageList_ConversationLike on ConversationLike
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 25 }
            cursor: { type: "String" }
          )
          @refetchable(queryName: "MessageListPaginationQuery") {
            ... on OrganizationWorkerConversation {
              id
            }
            ... on EngagementConversation {
              id
            }
            ... on ShiftGroupConversation {
              id
            }
            participant {
              id
              readCursor
            }
            capabilities
            messages(last: $count, before: $cursor)
              @connection(key: "MessageList_messages") {
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                cursor
                node {
                  id
                  insertedAt
                  author {
                    id
                  }
                  context {
                    ... on Gig {
                      id
                    }
                  }
                  ...UserMessage_userMessage
                  ...UserMessageContext_userMessage
                }
              }
            }
          }
        `,
        response?.node ?? null
      );
    useRelaySubscription<MessageListSubscription>(
      graphql`
        subscription MessageListSubscription(
          $connections: [ID!]!
          $conversationId: ID!
        ) {
          conversationMessageAdded(conversationId: $conversationId) {
            newMessageEdge @appendEdge(connections: $connections) {
              cursor
              node {
                id
                insertedAt
                author {
                  id
                }
                context {
                  ... on Gig {
                    id
                  }
                }
                ...UserMessage_userMessage
                ...UserMessageContext_userMessage
              }
            }
          }
        }
      `,
      {
        conversationId,
        connections: [getConnectionKey(conversationId, "MessageList_messages")]
      },
      {
        subscribe: !!conversationId
      }
    );
    const [markAsRead] = useRelayMutationPromise<MessageListMutation>(graphql`
      mutation MessageListMutation(
        $input: UpdateConversationParticipantReadCursorInput!
      ) {
        updateConversationParticipantReadCursor(input: $input) {
          conversationParticipant {
            readUntil
            readCursor
            id
          }
        }
      }
    `);
    const sections = useMemo(() => {
      const nodes = getConnectionNodes(data?.messages).reverse();
      const newCursor =
        data?.messages?.edges?.[data?.messages?.edges?.length - 1]?.cursor;
      if (
        !isAdmin &&
        newCursor &&
        data?.participant?.id &&
        data?.participant?.readCursor !== newCursor
      ) {
        void markAsRead({
          input: {
            conversationParticipantId: data?.participant?.id ?? "",
            readCursor:
              data?.messages?.edges?.[data?.messages?.edges?.length - 1]
                ?.cursor ?? ""
          }
        });
      }
      const groupedSections = groupBy(nodes, (node) =>
        humanizeDate({
          startsAt: node?.insertedAt,
          size: "lg",
          showDayOfWeek: true
        })
      );
      return Object.keys(groupedSections).map((key) => ({
        title: key,
        data: groupedSections[key] ?? []
      }));
    }, [data?.messages]);
    const handleEndReached = () => {
      if (hasPrevious && !isLoadingPrevious) {
        loadPrevious(25);
      }
    };

    return (
      <Surface fill variant="flat">
        {variant === "requester" && (
          <RequesterConversationHeader fragmentRef={response?.node} />
        )}
        {variant === "worker" && (
          <WorkerConversationHeader fragmentRef={response?.node} />
        )}
        {isAdmin && (
          <AdminConversationHeader
            fragmentRef={response?.node}
            editing={editMessages}
            setEditing={setEditMessages}
          />
        )}
        <Column fill justifyContent="space-between">
          <AtlasMessageList<Message>
            sections={sections}
            data={getConnectionNodes(data?.messages)}
            keyExtractor={(item) => item?.id ?? ""}
            onEndReached={handleEndReached}
            renderItem={({ item, section, index }) => {
              const previousAuthorId = section?.data?.[index - 1]?.author?.id;
              const nextAuthorId = section?.data?.[index + 1]?.author?.id;
              const nextContextId = section?.data?.[index + 1]?.context?.id;
              const showDate =
                index - 1 < 0 || previousAuthorId !== item?.author?.id;
              const showName =
                index + 1 >= section.data.length ||
                nextAuthorId !== item?.author?.id;
              const showContext =
                item?.context?.id &&
                (index + 1 >= section.data.length ||
                  nextContextId !== item?.context?.id);

              return (
                <UserMessage
                  showName={showName}
                  showDate={showDate}
                  fragmentRef={item}
                  editing={editMessages}
                  context={
                    showContext ? (
                      <UserMessageContext fragmentRef={item} />
                    ) : undefined
                  }
                />
              );
            }}
          />
          <MessageListFooter
            variant={variant}
            contextId={contextId}
            fragmentRef={response?.node}
          />
        </Column>
      </Surface>
    );
  },
  {
    query: graphql`
      query MessageListQuery($conversationId: ID!, $isWorker: Boolean = false) {
        node(id: $conversationId) {
          ... on ConversationLike {
            __typename
            id
            ...RequesterConversationHeader_conversationLike
              @arguments(isWorker: $isWorker)
            ...WorkerConversationHeader_conversationLike
            ...AdminConversationHeader_conversationLike @skip(if: $isWorker)
            ...MessageListFooter_conversation
            ...MessageList_ConversationLike
            ...MessageListFooter_conversation
            status
            ... on EngagementConversation {
              engagement {
                capabilities {
                  status
                  type
                }
                gigType
                currentState {
                  name
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ conversationId, variant }) => ({
      conversationId,
      isWorker: variant === "worker"
    })
  }
);
