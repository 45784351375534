/**
 * @generated SignedSource<<e8a35e913dfb36cce7902868d6dcef08>>
 * @relayHash 9c5b1e7c18252492dd0fd39b0768a66a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:dX-rq7SaFHr8AW-RcTh7X5OpcyVSOTmTmqUprwY7QPg

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateWorkerEducationInput = {
  educationLevel?: string | null | undefined;
  endDate?: string | null | undefined;
  graduatingYear?: number | null | undefined;
  schoolName?: string | null | undefined;
  startDate?: string | null | undefined;
  workerEducationId: string;
};
export type EducationFormUpdateMutation$variables = {
  input: UpdateWorkerEducationInput;
};
export type EducationFormUpdateMutation$data = {
  readonly updateWorkerEducation: {
    readonly workerEducation: {
      readonly " $fragmentSpreads": FragmentRefs<"EducationForm_worker">;
    };
  } | null | undefined;
};
export type EducationFormUpdateMutation = {
  response: EducationFormUpdateMutation$data;
  variables: EducationFormUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EducationFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerEducationPayload",
        "kind": "LinkedField",
        "name": "updateWorkerEducation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerEducation",
            "kind": "LinkedField",
            "name": "workerEducation",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EducationForm_worker"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EducationFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerEducationPayload",
        "kind": "LinkedField",
        "name": "updateWorkerEducation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerEducation",
            "kind": "LinkedField",
            "name": "workerEducation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schoolName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "educationLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:dX-rq7SaFHr8AW-RcTh7X5OpcyVSOTmTmqUprwY7QPg",
    "metadata": {},
    "name": "EducationFormUpdateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bf895a5e372386c8ccd3aeaf1eda1590";

export default node;
