import React from "react";
import AddonModalContent from "./addon-modal-content";
export default function BackgroundChecksModalContent() {
  return (
    <AddonModalContent
      introText="For an additonal fee, you can hire workers who have passed a Motor Vehicle Record (MVR) report by Checkr."
      listHeader="Workers who passed an MVR check have:"
      learnMoreText="Learn more about MVR checks"
      learnMoreLink="https://gigsmart.com/verified-gigs"
      listItems={[
        "No more than 1 moving violation in the past 3 years AND No more than 1 accident in the past 3 years",
        "No more than 2 accidents in the past 3 years",
        "No more than 2 moving violations in the past 3 years",
        "No major violation in the past 5 years (e.g. DUIs, speeding 40+ mph over the limit)"
      ]}
    />
  );
}
