import { ContentArea } from "@gigsmart/atorasu";
import { asEnum, like, neq, where } from "@gigsmart/biruda";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import Loader from "../Brand/Loader";
import ConversationList from "./ConversationList";
import type {
  ParticipatingConversationListActive_user$data,
  ParticipatingConversationListActive_user$key
} from "./__generated__/ParticipatingConversationListActive_user.graphql";
import type { ParticipatingConversationListAdminQuery } from "./__generated__/ParticipatingConversationListAdminQuery.graphql";
import type { ParticipatingConversationListInactive_user$key } from "./__generated__/ParticipatingConversationListInactive_user.graphql";
import type { ParticipatingConversationListQuery } from "./__generated__/ParticipatingConversationListQuery.graphql";

interface Props {
  activeConversationId?: string;
  onConversationPress: (id: string) => void;
  activeFilter: "active" | "inactive";
  searchTerm?: string;
  viewer?: "worker" | "requester";
}

type Conversation = ObjectPath<
  ParticipatingConversationListActive_user$data,
  ["active", "edges", 0, "node"]
>;

function ParticipatingConversationList({
  activeFilter,
  onConversationPress,
  activeConversationId,
  parentRef = null,
  viewer,
  searchTerm
}: Props & {
  parentRef?:
    | (ParticipatingConversationListActive_user$key &
        ParticipatingConversationListInactive_user$key)
    | null;
}) {
  if (activeFilter === "active")
    return (
      <ConversationList<
        ParticipatingConversationListQuery,
        ParticipatingConversationListActive_user$key,
        Conversation
      >
        key="active-conversation-list"
        parentRef={parentRef}
        fragmentInput={graphql`
          fragment ParticipatingConversationListActive_user on User
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 10 }
            after: { type: "String" }
            query: { type: "String!" }
          )
          @refetchable(
            queryName: "ParticipatingConversationListActiveRefetchableQuery"
          ) {
            __typename
            active: conversations(first: $count, after: $after, query: $query)
              @connection(key: "participatingConversationList_active") {
              edges {
                node {
                  ...ConversationRow_conversationLike
                  ... on OrganizationWorkerConversation {
                    id
                  }
                  ... on EngagementConversation {
                    id
                  }
                }
              }
            }
          }
        `}
        searchTerm={searchTerm}
        getData={(data) => {
          return getConnectionNodes(data?.active);
        }}
        onConversationPress={onConversationPress}
        activeConversationId={activeConversationId}
        viewer={viewer}
      />
    );
  if (activeFilter === "inactive")
    return (
      <ConversationList<
        ParticipatingConversationListQuery,
        ParticipatingConversationListInactive_user$key,
        Conversation
      >
        parentRef={parentRef}
        fragmentInput={graphql`
          fragment ParticipatingConversationListInactive_user on User
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 10 }
            after: { type: "String" }
            query: { type: "String!" }
          )
          @refetchable(
            queryName: "ParticipatingConversationListInactiveRefetchableQuery"
          ) {
            __typename
            inactive: conversations(
              first: $count
              after: $after
              query: $query
            ) @connection(key: "participatingConversationList_inactive") {
              edges {
                node {
                  ...ConversationRow_conversationLike
                  ... on OrganizationWorkerConversation {
                    id
                  }
                  ... on EngagementConversation {
                    id
                  }
                }
              }
            }
          }
        `}
        key="inactive-conversation-list"
        getData={(data) => {
          return getConnectionNodes(data?.inactive);
        }}
        searchTerm={searchTerm}
        onConversationPress={onConversationPress}
        activeConversationId={activeConversationId}
        viewer={viewer}
      />
    );
  return null;
}

export const AdminParticipatingConversationList = createSuspendedQueryContainer<
  ParticipatingConversationListAdminQuery,
  Props & { userId: string; viewer?: "worker" | "requester" }
>(
  function AdminConversationList({
    userId,
    viewer,
    variables,
    response,
    retry,
    ...props
  }) {
    return (
      <ParticipatingConversationList
        {...props}
        parentRef={response?.node}
        viewer={viewer}
      />
    );
  },
  {
    query: graphql`
      query ParticipatingConversationListAdminQuery(
        $userId: ID!
        $activeQuery: String!
        $inactiveQuery: String!
      ) {
        node(id: $userId) {
          ... on User {
            id
            ...ParticipatingConversationListActive_user
              @arguments(query: $activeQuery)
            ...ParticipatingConversationListInactive_user
              @arguments(query: $inactiveQuery)
          }
        }
      }
    `,
    variables: ({ userId, searchTerm }) => {
      const search = searchTerm
        ? { participantName: like(searchTerm) }
        : undefined;
      const activeQuery = where({
        status: asEnum("OPEN"),
        conversationType: neq(asEnum("SHIFT_GROUP"))
      })
        .and(search)
        .orderBy("lastMessageSentAt", "DESC NULLS LAST")
        .toString();
      const inactiveQuery = where({
        status: asEnum("CLOSED"),
        conversationType: neq(asEnum("SHIFT_GROUP"))
      })
        .and(search)
        .orderBy("lastMessageSentAt", "DESC NULLS LAST")
        .toString();
      return {
        userId,
        activeQuery,
        inactiveQuery
      };
    },
    FallbackComponent: () => (
      <ContentArea alignItems="center" justifyContent="center" fill>
        <Loader />
      </ContentArea>
    ),
    fetchPolicy: "network-only"
  }
);

export default createSuspendedQueryContainer<
  ParticipatingConversationListQuery,
  Props
>(
  function AppParticipatingConversationList({
    response,
    retry,
    variables,
    ...props
  }) {
    return (
      <ParticipatingConversationList {...props} parentRef={response?.viewer} />
    );
  },
  {
    query: graphql`
      query ParticipatingConversationListQuery(
        $activeQuery: String!
        $inactiveQuery: String!
      ) {
        viewer {
          id
          ...ParticipatingConversationListActive_user
            @arguments(query: $activeQuery)
          ...ParticipatingConversationListInactive_user
            @arguments(query: $inactiveQuery)
        }
      }
    `,
    variables: ({ searchTerm }) => {
      const search = searchTerm
        ? { participantName: like(searchTerm) }
        : undefined;
      const activeQuery = where({
        status: asEnum("OPEN"),
        conversationType: neq(asEnum("SHIFT_GROUP"))
      })
        .and(search)
        .orderBy("lastMessageSentAt", "DESC NULLS LAST")
        .toString();
      const inactiveQuery = where({
        status: asEnum("CLOSED"),
        conversationType: neq(asEnum("SHIFT_GROUP"))
      })
        .and(search)
        .orderBy("lastMessageSentAt", "DESC NULLS LAST")
        .toString();
      return {
        activeQuery,
        inactiveQuery
      };
    },
    FallbackComponent: () => (
      <ContentArea alignItems="center" justifyContent="center" fill>
        <Loader />
      </ContentArea>
    )
  }
);
