import React from "react";

import { Button, Spacer, Stack, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import InfoModal from "@gigsmart/isomorphic-shared/modal/info-modal";
import { StyledLink } from "@gigsmart/katana";

import ClipboardSvg from "@gigsmart/seibutsu/worker-verification/images/ClipboardSvg";

interface Props {
  visible: boolean;
  showLearnMore?: boolean;
  onClose: () => void;
  onAccept: () => void;
  onDeny?: () => void;
}

export default function LegalIntroductionModal({
  visible,
  onClose,
  onAccept,
  onDeny,
  showLearnMore = true
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    image: {
      width: "100%",
      marginVertical: getUnits(5),
      height: 140,
      alignSelf: "center"
    }
  }));
  return (
    <InfoModal
      testID="legal-modal"
      eventContext="Legal Modal"
      visible={visible}
      onClose={onClose}
      hideCancel
    >
      <Stack>
        <Text variant="header" color="primary" align="center">
          We need a few more things
        </Text>
        <ClipboardSvg style={styles.image} />
        <Text align="center">
          Please review and accept the following documents. This quick process
          should only take a few minutes.
        </Text>
        {showLearnMore && (
          <StyledLink center openURL="https://gigsmart.com/get-verified">
            Learn more about the GigSmart verification process
          </StyledLink>
        )}
        <>
          <Button
            label="Continue"
            onPress={onAccept}
            testID="legal-modal-continue-btn"
          />
          <Spacer size="compact" />
          <Button
            variant="clear"
            label="No, Thank you"
            onPress={() => {
              onDeny?.();
              onClose();
            }}
            testID="legal-modal-close-btn"
          />
        </>
      </Stack>
    </InfoModal>
  );
}
