/**
 * @generated SignedSource<<fd5b773064ac4557cfe832e1eaf32b60>>
 * @relayHash eaf3160c3fc321f7440c1d60980f539e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:boi9vp3AnE1BWjPLzNGHRZoKKlPsSDPJk9xHe0Ct4qA

import { ConcreteRequest } from 'relay-runtime';
export type useDeleteCommercialDriversLicenseModalQuery$variables = Record<PropertyKey, never>;
export type useDeleteCommercialDriversLicenseModalQuery$data = {
  readonly viewer: {
    readonly qualificationProofs?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useDeleteCommercialDriversLicenseModalQuery = {
  response: useDeleteCommercialDriversLicenseModalQuery$data;
  variables: useDeleteCommercialDriversLicenseModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE type = DRIVERS_LICENSE"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "WorkerQualificationDriversLicenseProof",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteCommercialDriversLicenseModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "qualificationProofs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "qualificationProofs(first:1,query:\"WHERE type = DRIVERS_LICENSE\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useDeleteCommercialDriversLicenseModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "qualificationProofs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v2/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "qualificationProofs(first:1,query:\"WHERE type = DRIVERS_LICENSE\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:boi9vp3AnE1BWjPLzNGHRZoKKlPsSDPJk9xHe0Ct4qA",
    "metadata": {},
    "name": "useDeleteCommercialDriversLicenseModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8bbf38cd2fb5a5532f90a508ba7a4552";

export default node;
