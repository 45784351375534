import * as React from "react";
import Svg, { Defs, Rect, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

export default function InfoSvg() {
  return (
    <Svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      height="100%"
      width="100%"
    >
      <Defs />
      <Rect
        x={0.219}
        y={88.184}
        width={99.562}
        height={11.816}
        rx={2.026}
        fill="#e7e7e7"
      />
      <Path
        d="M50 15.421A29.479 29.479 0 1079.479 44.9 29.512 29.512 0 0050 15.42zm-2.925 50.933a24.069 24.069 0 1124.069-24.069 24.096 24.096 0 01-24.069 24.069z"
        fill="none"
      />
      <Path
        d="M47.075 18.216a24.069 24.069 0 1024.069 24.07 24.096 24.096 0 00-24.069-24.07zM48 30.606a3.311 3.311 0 015.644 2.324 3.16 3.16 0 01-.979 2.377 3.314 3.314 0 01-4.665-.035 3.273 3.273 0 010-4.666zm8.065 26.547H44.42v-4.341h2.871V42.709h-2.871v-4.414h8.847v14.517h2.799z"
        fill="#fbb349"
        opacity={0.8}
      />
      <Path
        fill="#898b8d"
        d="M50.34 36.25a3.23 3.23 0 002.324-.943 3.328 3.328 0 00-2.323-5.679 3.31 3.31 0 000 6.621zM53.266 38.295h-8.847v4.414h2.871v10.103h-2.871v4.341h11.645v-4.341h-2.798V38.295z"
      />
      <Path
        fill="#898b8d"
        d="M50 13.421A31.479 31.479 0 1081.479 44.9 31.514 31.514 0 0050 13.42zm0 60.958a29.479 29.479 0 1129.479-29.48A29.513 29.513 0 0150 74.38z"
      />
    </Svg>
  );
}
