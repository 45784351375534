import * as React from "react";
import { View } from "react-native";

import { StyledIcon } from "../icon";
import {
  type TextStyleProp,
  type ViewStyleProp,
  createStyles,
  theme
} from "../style";
import StyledBubble from "../tappable/styled-bubble";

export interface BubbleType {
  id: string;
  name: string;
  icon?: Record<string, unknown>;
  rightIcon?: Record<string, unknown>;
  active?: boolean;
  testID?: string;
}

interface Props {
  customStyle?: ViewStyleProp;
  customActiveStyle?: ViewStyleProp;
  activeTextStyle?: TextStyleProp;
  style?: ViewStyleProp;
  onBubblePress: (bubble: { id: string; name: string }) => void;
  isToggle?: boolean;
  fontSize?: number;
  textStyle?: TextStyleProp;
  withStyledIcons?: boolean;
  bubbles: readonly BubbleType[];
  activeBubblesFirst?: boolean;
  eventTargetName: string | null;
}

const styles = createStyles(() => ({
  bubbleContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  bubble: {
    marginRight: 10,
    marginBottom: 10
  }
}));

const BubblesCollection = ({
  style,
  bubbles,
  activeBubblesFirst,
  activeTextStyle,
  customActiveStyle,
  customStyle,
  fontSize,
  onBubblePress,
  isToggle,
  withStyledIcons,
  textStyle,
  eventTargetName
}: Props) => {
  const bubblesToMap = activeBubblesFirst
    ? [...bubbles].sort((bubble) => (bubble.active ? -1 : 1))
    : bubbles;

  return (
    <View style={[styles.bubbleContainer, style]}>
      {bubblesToMap.map(
        ({ id, name, icon, rightIcon, active, testID: innerTestID }) => (
          <StyledBubble
            style={[
              styles.bubble,
              !active || !customActiveStyle ? customStyle : customActiveStyle
            ]}
            key={id}
            eventTargetName={eventTargetName ?? "Bubble"}
            eventProperties={{ name }}
            title={name}
            size={fontSize ?? theme.font.size.bodyCopy}
            activeTextStyle={activeTextStyle}
            textStyle={textStyle}
            isInactive={isToggle && !active}
            {...(withStyledIcons ? { IconComponent: StyledIcon } : {})}
            icon={icon}
            rightIcon={rightIcon}
            onPress={() => onBubblePress({ id, name })}
            testID={innerTestID}
          />
        )
      )}
    </View>
  );
};

export default BubblesCollection;
