import { integrate, reportDeviceId } from "@gigsmart/dekigoto";
import {
  DisableFirebaseAnalytics,
  DisableInstallEvents,
  DisableLifecycleEvents,
  DisableMixpanel,
  DisableRudderStack,
  DisableSentry,
  DisableUtmTracking
} from "@gigsmart/feature-flags";
import { setRemoteSessionId } from "@gigsmart/roga";
import { getDeviceId } from "./device-id";

export const loadEventReporters = async (
  plugins?: Parameters<typeof integrate>[0]
) => {
  await getDeviceId().then(async ({ bot: isBot, id }) => {
    setRemoteSessionId(id);
    await integrate({
      // Pre-environment Setup
      utm: !isBot && DisableUtmTracking.isDisabled(),
      // Actual Integrations
      console: true,
      featureFlags: true,
      mixpanel: !isBot && DisableMixpanel.isDisabled(),
      crashlytics: !isBot && DisableLifecycleEvents.isDisabled(),
      firebase: !isBot && DisableFirebaseAnalytics.isDisabled(),
      rudderstack: !isBot && DisableRudderStack.isDisabled(),
      sentry: !isBot && DisableSentry.isDisabled(),
      // Things that require integrations
      install: !isBot && DisableInstallEvents.isDisabled(),
      lifecycle: !isBot && DisableLifecycleEvents.isDisabled(),
      ...plugins
    });
    reportDeviceId(id);
  });
};
