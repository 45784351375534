/**
 * @generated SignedSource<<a8dbe1879826452b7384a9b5d67d0574>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type NegotiableGigField = "STARTS_AT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type dateItem_gig$data = {
  readonly actualStartsAt: string | null | undefined;
  readonly endsAt: string | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly insertedAt: string;
  readonly negotiable: ReadonlyArray<NegotiableGigField> | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly timezone: string | null | undefined;
  readonly " $fragmentType": "dateItem_gig";
};
export type dateItem_gig$key = {
  readonly " $data"?: dateItem_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"dateItem_gig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dateItem_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "negotiable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "1aae3e27bc0e01e0d7d2bd63a213c006";

export default node;
