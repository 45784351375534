/**
 * @generated SignedSource<<20838464c3d3841daa90851793d71d03>>
 * @relayHash d5efe5ee890eab46e5eebf4fd0ee5aed
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Ka6JRjsfKDD2xNYlmNBzjWMjFvGVX7GLh5Q034BE3nA

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
export type UpdateDirectHireClaimInput = {
  comments?: string | null | undefined;
  directHireClaimId: string;
  directHireDate?: string | null | undefined;
  directHireType?: DirectHireType | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  organizationId?: string | null | undefined;
  paymentMethodId?: string | null | undefined;
  placeId?: string | null | undefined;
  response?: string | null | undefined;
  timezone?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type SelectOrganizationModalUpdateClaimMutation$variables = {
  input: UpdateDirectHireClaimInput;
};
export type SelectOrganizationModalUpdateClaimMutation$data = {
  readonly updateDirectHireClaim: {
    readonly directHireClaim: {
      readonly id: string;
      readonly organization: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type SelectOrganizationModalUpdateClaimMutation = {
  response: SelectOrganizationModalUpdateClaimMutation$data;
  variables: SelectOrganizationModalUpdateClaimMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDirectHireClaimPayload",
    "kind": "LinkedField",
    "name": "updateDirectHireClaim",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaim",
        "kind": "LinkedField",
        "name": "directHireClaim",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectOrganizationModalUpdateClaimMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectOrganizationModalUpdateClaimMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "qpid:Ka6JRjsfKDD2xNYlmNBzjWMjFvGVX7GLh5Q034BE3nA",
    "metadata": {},
    "name": "SelectOrganizationModalUpdateClaimMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "92565d55c702394f5bc80dc5c7610ca3";

export default node;
