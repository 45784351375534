import React from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  style?: ViewStyleProp;
}

const ListFooterShadow = ({ style }: Props) => {
  const { styles } = useStyles(({ namedShadows, color }) => ({
    shadow: {
      ...namedShadows.card1,
      height: 1,
      backgroundColor: color.white
    }
  }));

  return <View style={[styles.shadow, style]} />;
};

export default ListFooterShadow;
