import { GigCard } from "@gigsmart/isomorphic-shared/gig";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { type ReactNode } from "react";

import { EngagementCardContent } from "../engagement-details";

import {
  getAddressContent,
  getCalendarContent
} from "../../available-gig/cards/content";
import type { engagementEntry_engagement$key } from "./__generated__/engagementEntry_engagement.graphql";

const fragmentSpec = graphql`
  fragment engagementEntry_engagement on Engagement {
    insertedAt
    startsAt
    endsAt
    gig {
      name
      place {
        streetAddress
      }
      placeId
      area
      gigType
      negotiable
      paymentType
      category {
        displayName
      }
      position {
        name
      }
      skills(first: 0) {
        totalCount
      }
    }
    skill {
      name
    }
  }
`;

interface Props {
  onPress?: () => void;
  disabled?: boolean;
  thumbnail?: ReactNode;
  addressInfo?: string | null;
  topAccessory?: ReactNode;
  bottomAccessory?: ReactNode;
  engagementRef: engagementEntry_engagement$key;
  hasMultipleLocations?: boolean;
  gigCount?: number;
}

const EngagementEntry = ({
  engagementRef,
  hasMultipleLocations,
  gigCount,
  addressInfo,
  thumbnail,
  bottomAccessory,
  ...props
}: Props) => {
  const engagement = useRelayFragment(fragmentSpec, engagementRef);
  const isProject = engagement.gig?.gigType === "PROJECT";
  const isRemote =
    engagement.gig?.placeId === "REMOTE" || engagement.gig?.area === "REMOTE";

  const [calendarNode, calendarInfoNode] = getCalendarContent({
    isProject,
    isTBD: engagement.gig?.negotiable?.includes("STARTS_AT") ?? false,
    node: engagement,
    gigCount
  });
  const addressNode = getAddressContent({
    hasMultipleLocations,
    remote: isRemote,
    area: engagement.gig?.place?.streetAddress ?? engagement.gig?.area
  });
  return (
    <GigCard
      count={gigCount}
      inset={3}
      spacing={4}
      headerVariant="worker"
      thumbnailIconName={isProject ? "pen-ruler" : "business-time"}
      thumbnailTintColor={isProject ? "blueTint" : undefined}
      name={engagement.gig?.name ?? ""}
      testID="engagement-entry"
      {...props}
    >
      <EngagementCardContent
        skillCount={engagement.gig?.skills?.totalCount ?? 1}
        skillOrCategoryName={
          isProject
            ? engagement.gig?.category?.displayName
            : engagement.gig?.position?.name ?? engagement.skill?.name
        }
        calendar={calendarNode}
        calendarInfo={calendarInfoNode}
        address={addressNode}
        addressInfo={isRemote ? null : addressInfo}
        paymentType={engagement.gig?.paymentType}
        thumbnail={thumbnail}
        gigType={engagement.gig?.gigType}
      />

      {bottomAccessory}
    </GigCard>
  );
};

export default EngagementEntry;
