import { DateTime } from "luxon";
import React, { useMemo } from "react";

import {
  ContentArea,
  Divider,
  Icon,
  Pressable,
  Row,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { Validator, useClearFormValue, useFormValue } from "@gigsmart/fomu";
import { useHistory } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import FomuTextInput from "../fomu/inputs/FomuTextInput";
import type { EmergencyContactFormRemoveContactMutation } from "./__generated__/EmergencyContactFormRemoveContactMutation.graphql";

type index = 1 | 2;

interface Props {
  formIndex: index;
  accountEmailAddress: string;
  accountPhoneNumber: string | null | undefined;
  birthDate: string | null | undefined;
  workerEmergencyContactId: string;
  onContactRemove: () => void;
  initialValues: any;
  hideForm: () => void;
}
export default function EmergencyContactForm({
  formIndex = 1,
  accountEmailAddress,
  accountPhoneNumber,
  birthDate,
  workerEmergencyContactId,
  onContactRemove,
  initialValues,
  hideForm
}: Props) {
  const index = formIndex - 1 === 1 ? 1 : 2;
  const history = useHistory();
  const clearFirstNameField = useClearFormValue(
    `firstName-contact-${formIndex}`
  );
  const clearLastNameField = useClearFormValue(`lastName-contact-${formIndex}`);
  const clearMobileField = useClearFormValue(
    `mobileNumber-contact-${formIndex}`
  );
  const clearEmailField = useClearFormValue(
    `emailAddress-contact-${formIndex}`
  );
  const clearRelationField = useClearFormValue(
    `relationship-contact-${formIndex}`
  );
  const clearIdField = useClearFormValue(
    `workerEmergencyContactId-contact-${formIndex}`
  );

  const { value: alreadyUsedContactNumber } = useFormValue({
    name: `mobileNumber-contact-${index}`
  });

  const { value: alreadyUsedEmailAddress } = useFormValue({
    name: `emailAddress-contact-${index}`
  });

  const [removeContactMutation] =
    useRelayMutation<EmergencyContactFormRemoveContactMutation>(graphql`
      mutation EmergencyContactFormRemoveContactMutation(
        $input: RemoveWorkerEmergencyContactInput!
      ) {
        removeWorkerEmergencyContact(input: $input) {
          removedWorkerEmergencyContactId
        }
      }
    `);

  const clearFormFields = () => {
    clearFirstNameField();
    clearLastNameField();
    clearEmailField();
    clearMobileField();
    clearRelationField();
    clearIdField();
  };

  const handleRemoveContactPress = () => {
    if (!initialValues[`workerEmergencyContactId-contact-${formIndex}`]) {
      hideForm();
      clearFormFields();
      return;
    }

    removeContactMutation(
      { input: { workerEmergencyContactId } },
      {
        onSuccess: () => {
          onContactRemove();
          toast.success("Emergency Contact successfully removed");
          history.replace("/profile");
        }
      }
    );
  };

  const workerAge = useMemo(() => {
    return birthDate
      ? DateTime.fromISO(birthDate).diffNow("years").years * -1
      : 0;
  }, [birthDate]);

  return (
    <Surface variant="outline">
      <ContentArea>
        <Row justifyContent="space-between">
          <Text color="primary" weight="bold">
            Emergency Contact {formIndex}
          </Text>
          {workerAge > 18 && (
            <Pressable
              onPress={handleRemoveContactPress}
              eventEntityType="remove"
              eventTargetName="Remove Emergency Contact Button"
              testID="remove-contact-icon"
            >
              <Icon name="trash-alt" variant="solid" color="primary" />
            </Pressable>
          )}
        </Row>
        <Spacer size="compact" />
        <Stack>
          <Divider />
          <FomuTextInput
            name={`firstName-contact-${formIndex}`}
            placeholder="First Name"
            label="First Name"
            validates={Validator.presence()}
          />
          <FomuTextInput
            name={`lastName-contact-${formIndex}`}
            placeholder="Last Name"
            label="Last Name"
            validates={Validator.presence()}
          />
          <FomuTextInput
            name={`relationship-contact-${formIndex}`}
            placeholder="Relationship"
            label="Relationship"
            validates={Validator.presence()}
          />
          <FomuTextInput
            name={`emailAddress-contact-${formIndex}`}
            label="Email"
            keyboardType="email-address"
            autoCapitalize="none"
            placeholder="Email"
            validates={Validator.composeValidations(
              (_, value) => {
                const otherEmailAddress =
                  alreadyUsedEmailAddress ??
                  initialValues[`emailAddress-contact-${index}`];

                if (value === accountEmailAddress)
                  return [new Error("Cannot match account email")];
                if (value === otherEmailAddress) {
                  return [
                    new Error("Cannot match other emergency email address")
                  ];
                }
                return [];
              },
              Validator.email({ message: "Invalid email" })
            )}
          />
          <FomuTextInput
            name={`mobileNumber-contact-${formIndex}`}
            label="Mobile Number"
            placeholder="(123) 456-7890"
            mask="phoneNumber"
            keyboardType="numeric"
            validates={Validator.composeValidations(
              (_, value) => {
                const formValue = value?.replace(/[^+\d]+/g, "");
                const otherNumber = alreadyUsedContactNumber
                  ? String(alreadyUsedContactNumber)?.replace(/[^+\d]+/g, "")
                  : initialValues[`mobileNumber-contact-${index}`];
                const accountNumber = accountPhoneNumber?.replace(
                  /[^+\d]+/g,
                  ""
                );

                if (accountNumber && `+1${formValue}` === accountNumber)
                  return [new Error("Cannot match account phone number")];

                if (otherNumber && formValue === otherNumber) {
                  return [
                    new Error("Cannot match other emergency phone number")
                  ];
                }

                return [];
              },
              Validator.phoneNumber({ message: "Invalid phone number" })
            )}
          />
        </Stack>
      </ContentArea>
    </Surface>
  );
}
