import {
  Avatar,
  Column,
  Stack,
  Sticker,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";
import React from "react";
import type { RequesterConversationHeader_conversationLike$data } from "./__generated__/RequesterConversationHeader_conversationLike.graphql";

interface Props {
  worker: RequesterConversationHeader_conversationLike$data["worker"];
  organization: RequesterConversationHeader_conversationLike$data["organization"];
  hasManyOrgs: boolean;
}

export default function WorkerInfo({
  worker,
  hasManyOrgs,
  organization
}: Props) {
  const isSmUp = useMatchesViewport(({ size }) => size.small.up);
  return (
    <Stack horizontal fill alignItems="center" justifyContent="flex-start">
      <Avatar
        size="medium"
        testID="worker-avatar"
        uri={worker?.profilePhoto?.url}
        sticker={
          worker?.isFavorite ? (
            <Sticker size="xxsmall" icon="star" color="purple" />
          ) : (worker?.groupAssociations?.totalCount ?? 0) > 0 ? (
            <Sticker size="xxsmall" icon="users-class" color="purple" />
          ) : undefined
        }
      />
      <Column fill>
        <Stack size="none">
          <Text testID="worker-name-text" variant="header" numberOfLines={1}>
            {worker?.displayName}
          </Text>
          {hasManyOrgs && (
            <Text
              color="disabled"
              testID="messaging-with-text"
              numberOfLines={1}
            >
              {isSmUp && <Text>Messaging With </Text>}
              <Text weight="bold">{organization?.name}</Text>
            </Text>
          )}
        </Stack>
      </Column>
    </Stack>
  );
}
