import React, { useState, type ReactNode } from "react";
import { LayoutAnimation, Platform, UIManager, View } from "react-native";
import Card from "../container/card";
import { useStyles } from "../style";
import CollapsibleCardButton from "./collapsible-card-button";

interface Props {
  readonly content: ReactNode;
  readonly collapsedContent?: ReactNode;
  readonly showButton?: boolean;
  readonly startCollapsed?: boolean;
}

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export default ({
  content,
  collapsedContent,
  showButton = true,
  startCollapsed = false
}: Props) => {
  const { styles } = useStyles(({ units }) => ({
    cardContent: {
      padding: units(4),
      overflow: "hidden"
    }
  }));
  const [collapsed, setCollapsed] = useState(!!startCollapsed);

  const toggleCollapsed = () => {
    LayoutAnimation.configureNext({
      duration: 250,
      create: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity
      },
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity
      }
    });
    setCollapsed(!collapsed);
  };

  return (
    <Card noMargin noSpacing noRadius>
      <View style={styles.cardContent}>
        {collapsed ? collapsedContent : content}
      </View>
      {showButton && (
        <CollapsibleCardButton
          testID="collapsible-card-toggle-view-button"
          collapsed={collapsed}
          onPress={toggleCollapsed}
        />
      )}
    </Card>
  );
};
