import React from "react";

import {
  ContentArea,
  Spacer,
  Stack,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";

interface Props {
  name: string;
  note: string;
}

export default function StepHeader({ name, note }: Props) {
  const isMediumUp = useMatchesViewport(({ size }) => size.medium.up);
  const OuterComponent = isMediumUp ? React.Fragment : ContentArea;
  return (
    <OuterComponent size="none">
      {!isMediumUp && <Spacer />}
      <Stack size="slim">
        <Text variant="header" color="primary">
          {name}
        </Text>
        <Text>{note}</Text>
      </Stack>
    </OuterComponent>
  );
}
