import {
  Card,
  ContentArea,
  Divider,
  type IconName,
  IconText,
  Text
} from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import WorkerProjectCard from "../../gig/WorkerProjectCard";
import EngagedProjectHeader from "./EngagedProjectHeader";
import type {
  WorkerProjectEngagementCard_engagement$data,
  WorkerProjectEngagementCard_engagement$key
} from "./__generated__/WorkerProjectEngagementCard_engagement.graphql";

interface Props {
  onPress?: () => void;
}

export function WorkerProjectEngagementCard({
  id,
  currentState,
  insertedAt,
  startsAt,
  workerDistance,
  topic,
  gig,
  onPress
}: FragmentContainerInnerComponentProps<
  WorkerProjectEngagementCard_engagement$key,
  Props
>) {
  const header = getEngagementProjectCardHeader(
    currentState?.name,
    startsAt,
    insertedAt,
    topic,
    id
  );
  const content = (
    <WorkerProjectCard
      fragmentRef={gig}
      workerDistance={workerDistance}
      onPress={onPress}
    />
  );

  return header ? (
    <Card
      onPress={onPress}
      testID={`engagement-card-${id}`}
      eventTargetName="Engagement Card"
    >
      <ContentArea
        size="slim"
        justifyContent="center"
        testID={`engagement-card-header-${id}`}
      >
        {header}
      </ContentArea>
      <Divider />
      {content}
    </Card>
  ) : (
    content
  );
}

const STATE_DETAILS = {
  PENDING_REVIEW: {
    icon: "spinner",
    text: "Pending Review"
  },
  HIRE_REQUESTED: {
    icon: "spinner",
    text: "Hire Requested"
  }
};

function getEngagementProjectCardHeader(
  currentState: WorkerProjectEngagementCard_engagement$data["currentState"]["name"],
  startsAt: string | null | undefined,
  insertedAt: string | null | undefined,
  topic: WorkerProjectEngagementCard_engagement$data["topic"],
  id: string | null
) {
  if (currentState === "APPLIED")
    return (
      <Text testID={`engagement-card-header-${id}-text`}>
        Applied on: {time.humanize(insertedAt, "dateTiny")}
      </Text>
    );
  if (currentState === "SCHEDULED" && startsAt)
    return time.isToday(startsAt) || time.isTomorrow(startsAt) ? (
      <IconText
        icon="circle"
        color="success"
        size="small"
        iconSize="tiny"
        spacing="compact"
        textWeight="bold"
        testID={`engagement-card-header-${id}-text`}
      >
        Scheduled Start:{" "}
        <Text weight="normal" color="textPrimary">
          {time.isToday(startsAt) ? "Today" : "Tomorrow"}
        </Text>
      </IconText>
    ) : null;
  if (["CANCELED", "APPLICATION_CANCELED"].includes(currentState))
    return <Text testID={`engagement-card-header-${id}-text`}>Canceled</Text>;
  if (currentState === "ENGAGED") return <EngagedProjectHeader topic={topic} />;
  if (currentState === "REJECTED")
    return <Text testID={`engagement-card-header-${id}-text`}>Rejected</Text>;
  if (currentState === "MISSED")
    return <Text testID={`engagement-card-header-${id}-text`}>Missed</Text>;
  if (Object.keys(STATE_DETAILS).includes(currentState))
    return (
      <IconText
        size="small"
        iconSize="tiny"
        iconColor="neutral"
        testID={`engagement-card-header-${id}-text`}
        icon={
          STATE_DETAILS[currentState as "HIRE_REQUESTED" | "PENDING_REVIEW"]
            .icon as IconName
        }
      >
        {
          STATE_DETAILS[currentState as "HIRE_REQUESTED" | "PENDING_REVIEW"]
            .text
        }
      </IconText>
    );
  return null;
}

export default createRelayFragmentContainer<
  WorkerProjectEngagementCard_engagement$key,
  Props
>(
  graphql`
    fragment WorkerProjectEngagementCard_engagement on Engagement {
      id
      startsAt
      insertedAt
      workerDistance
      currentState {
        name
      }
      topic {
        id
        messages(
          first: 0
          query: "WHERE receiptViewedAt = NULL AND messageType = USER_MESSAGE"
        ) @connection(key: "EngagedProjectHeader_messages") {
          totalCount
          edges {
            cursor
          }
        }
      }
      gig {
        ...WorkerProjectCard_gig
      }
    }
  `,
  WorkerProjectEngagementCard
);
