import React, { type ReactNode, useCallback } from "react";
import { Linking, Platform } from "react-native";

import {
  Column,
  Stack,
  Text,
  ToastProvider,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { StyledButton, StyledLink, StyledView } from "@gigsmart/katana";
import BuildInfo from "@gigsmart/seibutsu/shared/BuildInfo";
import RNRestart from "react-native-restart";
import MaintenanceSvg from "../img/maintenance-svg";

interface Props {
  showReload?: boolean;
  children?: ReactNode;
}

export function UndergoingMaintenance({ showReload, children }: Props) {
  const styles = useStyles(() => ({
    container: {
      height: "100%"
    }
  }));

  const reloadApp = useCallback(() => {
    Platform.OS === "web" ? window.location.replace("/") : RNRestart.restart();
  }, []);

  return (
    <>
      <StyledView
        style={styles.container}
        testID="undergoing-maintenance-screen"
      >
        <Column fill={1} justifyContent="center" testID="top-flex">
          <Stack
            testID="app-request-update"
            justifyContent="center"
            alignItems="center"
          >
            <Text align="center" weight="bold" color="primary" variant="title">
              We Will Be Back Soon!
            </Text>
            <Column justifyContent="flex-end">
              <MaintenanceSvg height={300} width={300} />
            </Column>
            <Column alignItems="center">
              <Stack justifyContent="flex-end" alignItems="center">
                <Text align="center">
                  Sorry for the inconvenience, we are currently performing app
                  maintenance.
                </Text>

                <Text align="center">
                  To learn more, please visit{" "}
                  <StyledLink
                    onPress={() =>
                      showEmbeddedBrowser({
                        title: "gigsmart.com",
                        url: "https://status.gigsmart.com"
                      })
                    }
                  >
                    status.gigsmart.com
                  </StyledLink>
                  . We will be back online shortly, thank you for your patience.
                </Text>

                <Text align="center">
                  For assistance please contact us at{" "}
                  <StyledLink
                    testID="email-support"
                    onPress={() =>
                      void Linking.openURL("mailto:support@gigsmart.com")
                    }
                  >
                    support@gigsmart.com
                  </StyledLink>
                  .
                </Text>
                {showReload && (
                  <StyledButton title="Reload App" onPress={reloadApp} />
                )}
                <BuildInfo />
              </Stack>
            </Column>
          </Stack>
        </Column>
        {children}
      </StyledView>
      <ToastProvider />
    </>
  );
}
