import React from "react";

import { Button, ContentArea, Stack } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "@gigsmart/seibutsu/gig-series/WorkerLatestArrivalTimeReminder";

import OfferedAttachment from "./OfferedAttachment";
import WorkerShiftCard from "./WorkerShiftCard";
import type { ShiftCardExtras } from "./extras";

import type { WorkerLatestArrivalTimeReminder_gig$key } from "@gigsmart/seibutsu/gig-series/__generated__/WorkerLatestArrivalTimeReminder_gig.graphql";
import type { WorkerShiftAggregateCardSubscription } from "./__generated__/WorkerShiftAggregateCardSubscription.graphql";
import type { WorkerShiftAggregateCard_engagement$key } from "./__generated__/WorkerShiftAggregateCard_engagement.graphql";

interface Props {
  onPress?: () => void;
  onPressAcceptOffers?: () => void;
  shiftCount?: number;
  distance?: ShiftCardExtras["distance"];
  netPay?: ShiftCardExtras["netPay"];
  nextGig?: WorkerLatestArrivalTimeReminder_gig$key | null | undefined;
}

export const WorkerShiftAggregateCard = ({
  id,
  currentState,
  estimatedPayment,
  gig,
  result,
  onPress,
  onPressAcceptOffers,
  shiftCount = 1,
  distance,
  netPay,
  nextGig
}: FragmentContainerInnerComponentProps<
  WorkerShiftAggregateCard_engagement$key,
  Props
>) => {
  useRelaySubscription<WorkerShiftAggregateCardSubscription>(
    graphql`
      subscription WorkerShiftAggregateCardSubscription($engagementId: ID!) {
        engagementUpdated(engagementId: $engagementId) {
          engagement {
            id
            ...WorkerShiftAggregateCard_engagement
          }
        }
      }
    `,
    { engagementId: id }
  );

  const actions =
    shiftCount > 1 ? (
      <Button
        testID="view-accept-offers-btn"
        label="View & Accept Offers"
        onPress={onPressAcceptOffers}
        size="small"
      />
    ) : (
      <Button
        testID="view-accept-offer-btn"
        size="small"
        label="View & Accept Offer"
        onPress={onPress}
      />
    );

  return (
    <WorkerShiftCard
      shiftCount={shiftCount}
      distance={distance}
      netPay={netPay ?? estimatedPayment?.netPay}
      payRate={estimatedPayment?.payRate}
      headerAttachment={
        <OfferedAttachment fragmentRef={result} shiftCount={shiftCount} />
      }
      footerAttachmentShadow
      footerAttachment={
        <ContentArea>
          <Stack>
            {actions}
            {showLatestArrivalReminder(nextGig) && (
              <WorkerLatestArrivalTimeReminder
                fragmentRef={nextGig}
                isMultiple={shiftCount > 1}
                workerStateName={currentState?.name}
              />
            )}
          </Stack>
        </ContentArea>
      }
      fragmentRef={gig}
      onPress={() => onPress?.()}
    />
  );
};

export default createRelayFragmentContainer<
  WorkerShiftAggregateCard_engagement$key,
  Props
>(
  graphql`
    fragment WorkerShiftAggregateCard_engagement on Engagement {
      id
      estimatedPayment {
        payRate
        netPay
      }
      currentState {
        name
      }
      gig {
        gigType
        ...WorkerShiftCard_gigLike
      }
      ...OfferedAttachment_engagement
    }
  `,
  WorkerShiftAggregateCard
);
