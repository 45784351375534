import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { createContext, useContext, type ReactNode } from "react";
import type {
  useGigSeriesData$data,
  useGigSeriesData$key
} from "./__generated__/useGigSeriesData.graphql";

export type GigSeriesData = useGigSeriesData$data;
const GigSeriesContext = createContext<GigSeriesData | null | undefined>(null);

export function GigSeriesDataProvider({
  children,
  gigSeries: gigSeriesRef
}: {
  children: ReactNode;
  gigSeries: useGigSeriesData$key | null | undefined;
}) {
  const gigSeries = useRelayFragment(
    graphql`
      fragment useGigSeriesData on GigSeries {
        id
        name
        place {
          streetAddress
        }
      }
    `,
    gigSeriesRef
  );
  return (
    <GigSeriesContext.Provider value={gigSeries}>
      {children}
    </GigSeriesContext.Provider>
  );
}

export const useGigSeriesData = () => useContext(GigSeriesContext);
