import React from "react";
import Svg, { Defs, Path, Polygon, Rect } from "react-native-svg";

const ApplySuccessImage = () => {
  return (
    <Svg width="100%" height={300} viewBox="0 0 100 100">
      <Defs />
      <Polygon
        fill="none"
        points="72.47 29.803 70.787 34.342 75.416 36.059 74.601 38.256 77.75 39.424 72.47 29.803"
      />
      <Path
        fill="none"
        d="M70.06223,61.79523c.08441.03128.16284.07131.24534.10544L78.18948,40.6492l-3.93521-1.45951-8.292,22.357A7.05229,7.05229,0,0,1,70.06223,61.79523Z"
      />
      <Rect
        fill="#e7e7e7"
        x={71.52189}
        y={33.83076}
        width={2.3437}
        height={4.93667}
        transform="translate(13.38202 91.83399) rotate(-69.65138)"
      />
      <Path
        fill="#e7e7e7"
        d="M68.87964,37.04085a.49785.49785,0,0,1-.01428-.38089l.98806-2.66419L45.14948,24.83333l-6.1491,16.57933,6.32434.03856a.49786.49786,0,1,1-.00607.99571l-6.68676-.04077L36.7594,47.45478l.42784.0026a.49786.49786,0,1,1-.00607.99571l-.79026-.00481-1.87243,5.04861,5.83454.03557a.49786.49786,0,1,1-.00607.99571l-6.197-.03778-1.87243,5.04843,4.09465.025a.49783.49783,0,1,1-.00607.99564l-4.457-.02717-2.07231,5.58732L60.10286,77.345l1.7513-4.7219a7.16954,7.16954,0,0,1-.70885-1.215,7.091,7.091,0,0,1,4.817-9.86132l8.292-22.357L69.159,37.3A.49874.49874,0,0,1,68.87964,37.04085Z"
      />
      <Rect
        fill="#003b5c"
        x={31.12073}
        y={44.39095}
        width={2.06418}
        height={0.99571}
        transform="translate(-21.11539 59.42517) rotate(-69.65064)"
      />
      <Rect
        fill="#003b5c"
        x={28.61429}
        y={50.45276}
        width={2.58046}
        height={0.99571}
        transform="translate(-28.26508 61.2726) rotate(-69.65217)"
      />
      <Path
        fill="#fcb44a"
        d="M67.96518,71.07949l-1.27972.58724-.39767.18252-.99172-2.16106-1.30152-2.83611-.08686-.18932.18931-.08686,1.97168-.9048,1.38838,3.02531.39779-.18252,1.27973-.5873,4.0646-1.86521a5.96548,5.96548,0,0,0-3.23789-3.22707c-.08213-.03462-.16087-.07411-.24527-.10538a6.10023,6.10023,0,0,0-7.66576,8.26378c.06993.15234.16862.28381.24965.42847a6.03532,6.03532,0,0,0,3.17332,2.74675c.08446.03134.1699.0528.25482.08017a6.07586,6.07586,0,0,0,7.95986-5.79484Z"
      />
      <Path
        fill="#003b5c"
        d="M79.4031,65.83052l-.99173-2.161-4.3076,1.97677a7.08108,7.08108,0,0,0-2.89936-3.30046l8.09178-21.81716-.02077-.00769a.47918.47918,0,0,0-.00961-.405L72.819,28.36867a.47927.47927,0,0,0-.26879-.21252l.00552-.01486L42.03214,16.82052,33.61276,39.52083l.93352.34646,8.0731-21.767,29.12335,10.8016-1.88931,5.09392L68.86536,36.66A.49792.49792,0,0,0,69.159,37.3l5.09526,1.88968,3.93521,1.45951L70.30757,61.90067c-.0825-.03413-.16093-.07416-.24534-.10544A7.096,7.096,0,0,0,61.14531,71.408a7.16954,7.16954,0,0,0,.70885,1.215A7.04675,7.04675,0,0,0,65.127,75.10163c.08428.03128.16948.05389.25415.08175l-2.07381,5.59153-38.62078-14.324,3.76049-10.139-.93351-.3464-4.10671,11.0727L63.89459,82.05463,66.35,75.43447a7.03738,7.03738,0,0,0,1.1943.11019,7.08169,7.08169,0,0,0,7.1268-7.54257Zm-4.80256-27.5744L69.972,36.53951l.815-2.19747L72.4704,29.803l5.28006,9.62136ZM73.31422,70.56974a6.05429,6.05429,0,0,1-7.58617,3.67836c-.08492-.02737-.17036-.04883-.25482-.08017a6.03532,6.03532,0,0,1-3.17332-2.74675c-.081-.14466-.17972-.27613-.24965-.42847A6.09969,6.09969,0,0,1,69.716,62.72893c.0844.03127.16314.07076.24527.10538a5.96548,5.96548,0,0,1,3.23789,3.22707l-4.0646,1.86521-1.27973.5873-.39779.18252L66.06868,65.6711l-1.97168.9048-.18931.08686.08686.18932,1.30152,2.83611.99172,2.16106.39767-.18252,1.27972-.58724,5.72273-2.62623A6.05712,6.05712,0,0,1,73.31422,70.56974Z"
      />
      <Path
        fill="#003b5c"
        d="M26.30219,41.33524l-9.33253-.0569a.49786.49786,0,0,0-.00607.99571l9.33253.0569a.49786.49786,0,0,0,.00607-.99571Z"
      />
      <Path
        fill="#003b5c"
        d="M45.32472,41.45122l-6.32434-.03856-9.87677-.06022a.49786.49786,0,1,0-.00607.99571l9.51435.058,6.68676.04077a.49786.49786,0,1,0,.00607-.99571Z"
      />
      <Path
        fill="#003b5c"
        d="M37.18724,47.45738l-.42784-.0026-15.77327-.09617a.49786.49786,0,1,0-.00607.99571l15.41085.094.79026.00481a.49786.49786,0,1,0,.00607-.99571Z"
      />
      <Path
        fill="#003b5c"
        d="M40.353,53.53246l-5.83454-.03557-11.37662-.06936a.49786.49786,0,1,0-.00607.99571l11.0142.06715,6.197.03778a.49786.49786,0,1,0,.00607-.99571Z"
      />
      <Path
        fill="#003b5c"
        d="M24.65947,59.49237l-10.126-.06173a.49783.49783,0,1,0-.00607.99564l10.126.06174a.49783.49783,0,1,0,.00607-.99565Z"
      />
      <Path
        fill="#003b5c"
        d="M36.37221,59.56379l-4.09465-.025-3.26471-.0199a.49783.49783,0,1,0-.00607.99564l2.90235.0177,4.457.02717a.49783.49783,0,1,0,.00607-.99564Z"
      />
    </Svg>
  );
};

export default ApplySuccessImage;
