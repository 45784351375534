/**
 * @generated SignedSource<<18cc25598e8b52724126af4490243e98>>
 * @relayHash 929572abcb2edd7e35070a4b28787a91
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:yp6t2kj0QmHPvEtq5qazn4tT5svH_FXixkyQCxHSqh4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HourlyRateBidStepQuery$variables = {
  id: string;
};
export type HourlyRateBidStepQuery$data = {
  readonly node: {
    readonly __typename: string;
    readonly availableGigs?: {
      readonly edges: ReadonlyArray<{
        readonly distance: number | null | undefined;
        readonly estimatedPayment: {
          readonly netPay: string;
        } | null | undefined;
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_gig">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly gig?: {
      readonly name: string | null | undefined;
      readonly payRate: string | null | undefined;
      readonly wageCompliance: {
        readonly adjustedMinimumWage: string;
      } | null | undefined;
    };
    readonly id: string;
    readonly name?: string | null | undefined;
    readonly payRate?: string | null | undefined;
    readonly wageCompliance?: {
      readonly adjustedMinimumWage: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_engagement" | "MultiDaySelectGigsStep_gig">;
  } | null | undefined;
};
export type HourlyRateBidStepQuery = {
  response: HourlyRateBidStepQuery$data;
  variables: HourlyRateBidStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "WageCompliance",
  "kind": "LinkedField",
  "name": "wageCompliance",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adjustedMinimumWage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestArrivalTime",
  "storageKey": null
},
v15 = {
  "kind": "InlineDataFragmentSpread",
  "name": "MultiDaySelectGigsStep_gig",
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v13/*: any*/),
    (v6/*: any*/),
    (v8/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "WorkerLatestArrivalTimeReminder_gig",
      "selections": [
        (v5/*: any*/),
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GigState",
          "kind": "LinkedField",
          "name": "currentState",
          "plural": false,
          "selections": [
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "GigState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HourlyRateBidStepQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "MultiDaySelectGigsStep_engagement",
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v15/*: any*/)
            ],
            "type": "Gig",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v16/*: any*/),
                "concreteType": "AvailableGigsConnection",
                "kind": "LinkedField",
                "name": "availableGigs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "availableGigs(first:1)"
              }
            ],
            "type": "GigSeries",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HourlyRateBidStepQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v2/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "Engagement",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v5/*: any*/),
              (v13/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v14/*: any*/),
              (v18/*: any*/)
            ],
            "type": "Gig",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v16/*: any*/),
                "concreteType": "AvailableGigsConnection",
                "kind": "LinkedField",
                "name": "availableGigs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v13/*: any*/),
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v14/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "availableGigs(first:1)"
              }
            ],
            "type": "GigSeries",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:yp6t2kj0QmHPvEtq5qazn4tT5svH_FXixkyQCxHSqh4",
    "metadata": {},
    "name": "HourlyRateBidStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "868bc023feaed330b5351a4f2ea50381";

export default node;
