import { NavAction, ScreenTop, Text, confirmPrompt } from "@gigsmart/atorasu";
import { useFormSubmit } from "@gigsmart/fomu";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import React from "react";
import type { ComponentProps } from "react";

type Props = ComponentProps<typeof ScreenTop> & {
  showCancelBtn?: boolean;
  dirtyOverride?: boolean;
};

export default function FomuScreenTop({
  showCancelBtn,
  rightAccessory,
  onBackPress,
  showBackButton,
  dirtyOverride,
  contentVariant,
  extraSpace,
  hiddenSpace,
  leftAccessory
}: Props) {
  const { dirty: fomuDirty } = useFormSubmit();
  const dirty = dirtyOverride ?? fomuDirty;

  const handleBackPress = () => {
    if (!dirty) return onBackPress?.();

    void confirmPrompt({
      title: "Are you sure you want to go back?",
      subTitle: "Your information will not be saved.",
      actions: [
        {
          testID: "confirm-cancel-yes-btn",
          label: "Yes",
          value: true
        },
        {
          testID: "confirm-cancel-no-btn",
          label: "No",
          value: false
        }
      ]
    }).then((confirmBack) => {
      if (confirmBack) {
        onBackPress?.();
        defaultBackHandler({ safeExit: true });
      }
    });

    return true;
  };

  const handleCancel = async () => {
    const confirmCancel =
      !dirty ||
      (await confirmPrompt({
        title: "Are you sure you want to Cancel?",
        subTitle: "Your information will not be saved.",
        actions: [
          {
            testID: "confirm-cancel-yes-btn",
            label: "Yes",
            value: true
          },
          {
            testID: "confirm-cancel-no-btn",
            label: "No",
            value: false
          }
        ]
      }));

    if (confirmCancel) {
      defaultBackHandler({ safeExit: true });
    }
  };

  return (
    <ScreenTop
      contentVariant={contentVariant}
      extraSpace={extraSpace}
      hiddenSpace={hiddenSpace}
      leftAccessory={leftAccessory}
      showBackButton={showBackButton}
      onBackPress={handleBackPress}
      rightAccessory={
        showCancelBtn ? (
          <NavAction testID="fomu-nav-cancel-button" onPress={handleCancel}>
            <Text weight="semibold">Cancel</Text>
          </NavAction>
        ) : (
          rightAccessory
        )
      }
    />
  );
}
