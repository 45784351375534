import { useEventer } from "@gigsmart/dekigoto";
import { useHistory } from "@gigsmart/kaizoku";
import React, { type ComponentProps, type ReactNode } from "react";
import { Linking, Platform } from "react-native";

import { type TextStyleProp, createStyles } from "../style";
import StyledText from "../text/styled-text";
import Hoverable from "./hoverable";

type Props = ComponentProps<typeof StyledText> & {
  color?: string;
  openURL?: string;
  children?: ReactNode;
  style?: TextStyleProp;
  hoverStyle?: TextStyleProp;
  target?: string;
  onPress?: () => unknown;
};

const styles = createStyles(() => ({
  hoverText: {
    textDecorationLine: "underline"
  }
}));

/** @deprecated use kaizoku Link component (or atlas) */
export default function StyledLink({
  openURL,
  style,
  hoverStyle = styles.hoverText,
  color = "spiceBlue",
  onPress,
  fontSize,
  bold,
  children,
  center,
  testID,
  target
}: Props) {
  const history = useHistory();
  const isExternalLink = !!openURL?.match(/^http[s]?:\/\//);
  const webProps = Platform.OS === "web" &&
    (isExternalLink || !!target) && {
      href: openURL,
      hrefAttrs: { target: target ?? (isExternalLink ? "blank" : undefined) }
    };

  const trackPress = useEventer("Pressed", testID ?? "Unknown", "Link");
  const handlePress = () => {
    trackPress();
    onPress?.();

    if (openURL && !webProps) {
      if (isExternalLink) {
        void Linking.openURL(openURL);
      } else {
        history.push(openURL);
      }
    }
  };

  return (
    <Hoverable>
      {(hover) => (
        <StyledText
          {...webProps}
          accessibilityRole="link"
          onPress={handlePress}
          style={[hover && hoverStyle, style]}
          color={color}
          fontSize={fontSize}
          bold={bold}
          center={center}
          testID={testID}
        >
          {children}
        </StyledText>
      )}
    </Hoverable>
  );
}
