/**
 * @generated SignedSource<<85e4c0b529e2cc4f5bf6c931f0185b82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancellationFeeInfoModal_cancellationFee$data = {
  readonly feeRate: number;
  readonly startDelta: string;
  readonly totalDue: string;
  readonly " $fragmentType": "CancellationFeeInfoModal_cancellationFee";
};
export type CancellationFeeInfoModal_cancellationFee$key = {
  readonly " $data"?: CancellationFeeInfoModal_cancellationFee$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancellationFeeInfoModal_cancellationFee">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "CancellationFeeInfoModal_cancellationFee"
};

(node as any).hash = "324143cf3fca894033c44e096aa649a9";

export default node;
