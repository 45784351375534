import React from "react";
import AddonModalContent from "./addon-modal-content";
export default function DrugScreeningModalContent() {
  return (
    <AddonModalContent
      introText="For an additional fee, you can hire Workers who have passed a Drug Screening by Checkr."
      listHeader="Workers who passed a Drug Screening have:"
      learnMoreText="Learn more about Drug Screenings"
      learnMoreLink="https://gigsmart.com/verified-gigs"
      listItems={[
        "Passed an on-site 9-panel comprehensive drug screening",
        "Undergone testing utilizing an FDA 510(k) cleared instrumented test that targets today’s most common illicit and prescription drugs"
      ]}
    />
  );
}
