import type { ForwardableProps } from "@gigsmart/type-utils";
import React from "react";
import { FlexRow } from "../../flex";
import { Spacer } from "../../spacer";
import KatanaStar from "./katana-star";

type Props = ForwardableProps<
  typeof FlexRow,
  {
    onChangeValue?: (arg0: number) => void;
    value?: number | null | undefined;
    max?: number;
    readOnly?: boolean;
    fontSize?: number;
    spacing?: number;
  },
  "children"
>;

const KatanaRatingStars = ({
  max = 5,
  value,
  onChangeValue,
  readOnly,
  fontSize,
  spacing = 2,
  ...props
}: Props) => (
  <FlexRow {...props}>
    {Array.from({ length: max }, (_, index) => (
      <React.Fragment key={`katana_star_${index + 1}`}>
        {index > 0 && <Spacer direction="row" units={spacing} />}
        <KatanaStar
          fontSize={fontSize}
          readOnly={readOnly}
          onChangeValue={onChangeValue}
          index={index + 1}
          value={value}
        />
      </React.Fragment>
    ))}
  </FlexRow>
);

export default KatanaRatingStars;
