/**
 * @generated SignedSource<<303a30ae605ec183686d2c605a799167>>
 * @relayHash caa5de91f159f865539f58f5fad62b60
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:KC_si_KeImoYLoRf7oWErB54r_RJhOh3Wy62NMWlULA

import { ConcreteRequest } from 'relay-runtime';
export type AddUserMessageInput = {
  attachmentIds?: ReadonlyArray<string> | null | undefined;
  body?: string | null | undefined;
  contextId?: string | null | undefined;
  conversationId: string;
};
export type NewMessageComposerMutation$variables = {
  input: AddUserMessageInput;
};
export type NewMessageComposerMutation$data = {
  readonly addUserMessage: {
    readonly newMessageEdge: {
      readonly cursor: string | null | undefined;
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type NewMessageComposerMutation = {
  response: NewMessageComposerMutation$data;
  variables: NewMessageComposerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddUserMessagePayload",
    "kind": "LinkedField",
    "name": "addUserMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMessagesEdge",
        "kind": "LinkedField",
        "name": "newMessageEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMessage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewMessageComposerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewMessageComposerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:KC_si_KeImoYLoRf7oWErB54r_RJhOh3Wy62NMWlULA",
    "metadata": {},
    "name": "NewMessageComposerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3d1c38f2a7da2c62d60d00cdc8672b70";

export default node;
