import React, { type ReactNode } from "react";

import { ContentArea, Divider, Stack } from "@gigsmart/atorasu";

interface Props {
  backgroundColor?: string;
  children?: ReactNode;
  spacing?: number;
}

const TopAccessory = ({ children }: Props) => {
  return (
    <ContentArea>
      <Stack horizontal alignItems="center">
        {children}
      </Stack>
      <Divider />
    </ContentArea>
  );
};

export default TopAccessory;
