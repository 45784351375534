import React from "react";

import {
  Button,
  ContentArea,
  IconCircle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Stack,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";

import BenefitsSVG from "./BenefitsSVG";

interface Props {
  onClose: () => void;
}

export default function StrideBenefitsModal({ onClose }: Props) {
  const handleAccept = () => {
    onClose();
    showEmbeddedBrowser({
      url: "https://gigsmart.com/worker-benefits"
    });
  };

  const item = (label: string) => (
    <Row alignItems="flex-start">
      <IconCircle size="tiny" icon="check" color="success" variant="well" />
      <ContentArea size="slim" variant="compact" fill>
        <Text>{label}</Text>
      </ContentArea>
    </Row>
  );

  return (
    <>
      <ModalHeader title="Work with Confidence" />
      <ModalBody>
        <Stack>
          <BenefitsSVG />
          <Text>
            Maximize your earning potential and peace of mind by signing up for
            expense tracking and other benefits.
          </Text>
          <Stack size="slim">
            {item("Mileage Tracking")}
            {item("Expense Tracking")}
            {item("Tax Assistance")}
            {item("Insurance Savings")}
          </Stack>
          <Text>
            Save time and money so you can get back to working on the things
            that really matter.
          </Text>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          testID="stride-benefits-accept"
          label="Get Started"
          onPress={handleAccept}
        />
        <Button
          testID="cancel"
          variant="clear"
          label="No, Thank You"
          onPress={onClose}
        />
      </ModalFooter>
    </>
  );
}
