import React, { type ReactNode, useState } from "react";

import { Button, ContentArea, IconText, Row, Surface } from "@gigsmart/atorasu";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";

import { gt, now, where } from "@gigsmart/biruda";
import { Divider, TabbedModal } from "@gigsmart/katana";
import RestrictionTabs from "./RestrictionTabs";
import type { UserRestrictionsQuery } from "./__generated__/UserRestrictionsQuery.graphql";

interface Props {
  children?: ReactNode;
}

export default createSuspendedQueryContainer<UserRestrictionsQuery, Props>(
  function UserRestrictions({ children, response }) {
    const [modalOpen, setModalOpen] = useState(false);
    const restrictions = getConnectionNodes(response?.viewer?.restrictions);
    const isRestricted = restrictions.some(
      (it) =>
        it.restrictedActions?.length !== 1 ||
        !it.restrictedActions.find((d) => d.type === "CONFIDENCE")
    );
    if (!isRestricted) return <>{children}</>;

    return (
      <>
        {modalOpen && (
          <TabbedModal
            showModal={true}
            testID="restrictions-modal"
            title="Your Account has been Restricted"
            handleClose={() => setModalOpen(!modalOpen)}
          >
            <RestrictionTabs
              toggleVisibility={(visible) => setModalOpen(visible)}
            />
          </TabbedModal>
        )}
        <Surface>
          <ContentArea>
            <Row justifyContent="space-between">
              <IconText icon="circle-info" iconColor="danger" color="danger">
                Your Account has been Restricted.
              </IconText>
              <Button
                color="danger"
                size="small"
                label="Learn More"
                testID="restrictions-learn-more-btn"
                onPress={() => setModalOpen(!modalOpen)}
              />
            </Row>
          </ContentArea>
        </Surface>
        <Divider />
        {children}
      </>
    );
  },
  {
    query: graphql`
      query UserRestrictionsQuery($query: String!) {
        viewer {
          restrictions(first: 3, query: $query) {
            totalCount
            edges {
              node {
                restrictedActions {
                  type
                }
              }
            }
          }
        }
      }
    `,
    variables: () => {
      const query = where({ expiresAt: gt(now()), removedAt: null })
        .or({ expiresAt: null, removedAt: null })
        .toString();

      return { query };
    }
  }
);
