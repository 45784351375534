import { Divider, Surface, TitleBar } from "@gigsmart/atorasu";
import { asEnum, where } from "@gigsmart/biruda";
import { useHistory } from "@gigsmart/kaizoku";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import Screen from "@gigsmart/seibutsu/layouts/Screen";
import ConversationStateTabs from "@gigsmart/seibutsu/user-message/ConversationStateTabs";
import MessageCenterEmptyState from "@gigsmart/seibutsu/user-message/MessageCenterEmptyState";
import ParticipatingConversationList from "@gigsmart/seibutsu/user-message/ParticipatingConversationList";
import React, { useState } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { messageCenterScreenWorkerQuery } from "./__generated__/messageCenterScreenWorkerQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "MessageCenter">;

export default createSuspendedQueryContainer<
  messageCenterScreenWorkerQuery,
  Props
>(
  function MessageCenterScreen({ navigation, route, response: result }) {
    const { activeFilter: initialActiveFilter } = route.params ?? {};
    const [activeFilter, setActiveFilter] = useState<"active" | "inactive">(
      initialActiveFilter ?? "active"
    );
    const [isEmpty, setIsEmpty] = useState(false);
    const history = useHistory();
    return (
      <Screen testID="message-center-screen">
        <Divider />
        <Surface variant="flat" fill>
          <TitleBar
            title="Message Center"
            icon="message-lines"
            variant="flat"
          />
          <ConversationStateTabs
            fragmentRef={result?.viewer ?? null}
            activeFilter={activeFilter}
            setActiveFilter={(activeFilter, newIsEmpty) => {
              setIsEmpty(newIsEmpty);
              setActiveFilter(activeFilter);
              navigation.setParams({
                activeFilter
              });
            }}
          />
          <Divider />
          {isEmpty && (
            <MessageCenterEmptyState
              userType="worker"
              activeTab={activeFilter}
            />
          )}
          {!isEmpty && (
            <ParticipatingConversationList
              onConversationPress={(id: string) =>
                history.push(`/conversation/${id}`)
              }
              activeFilter={activeFilter}
            />
          )}
        </Surface>
      </Screen>
    );
  },
  {
    query: graphql`
      query messageCenterScreenWorkerQuery(
        $activeQuery: String!
        $inactiveQuery: String!
      ) {
        viewer {
          id
          ...RequesterMessageCenterTabs_user
          ...ConversationStateTabs_user
            @arguments(activeQuery: $activeQuery, inactiveQuery: $inactiveQuery)
        }
      }
    `,
    variables: {
      activeQuery: where({
        status: asEnum("OPEN")
      }).toString(),
      inactiveQuery: where({
        status: asEnum("CLOSED")
      }).toString()
    }
  }
);
