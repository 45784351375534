/**
 * @generated SignedSource<<dbc464ea03aef47814999364ef87e957>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerProjectSummaryTab_engagement$data = {
  readonly payments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amount: string;
        readonly id: string;
        readonly insertedAt: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly requesterSummary: {
    readonly approvedAt: string | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
  } | null | undefined;
  readonly reviewOfRequester: {
    readonly rating: number;
    readonly review: string | null | undefined;
  } | null | undefined;
  readonly reviewOfWorker: {
    readonly rating: number;
    readonly review: string | null | undefined;
  } | null | undefined;
  readonly workerSummary: {
    readonly insertedAt: string;
    readonly paid: boolean | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WorkerProjectSummaryTab_engagement";
};
export type WorkerProjectSummaryTab_engagement$key = {
  readonly " $data"?: WorkerProjectSummaryTab_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerProjectSummaryTab_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentType",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rating",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "review",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerProjectSummaryTab_engagement",
  "selections": [
    {
      "alias": "workerSummary",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "WORKER"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paid",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": "timesheet(variant:\"WORKER\")"
    },
    {
      "alias": "requesterSummary",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "FINAL"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "approvedAt",
          "storageKey": null
        }
      ],
      "storageKey": "timesheet(variant:\"FINAL\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "reviewOfRequester",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "reviewOfWorker",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 25
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "ORDER BY insertedAt ASC"
        }
      ],
      "concreteType": "EngagementPaymentsConnection",
      "kind": "LinkedField",
      "name": "payments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementPayment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "payments(first:25,query:\"ORDER BY insertedAt ASC\")"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "0176991dbf452632a10cc6ecc54b842b";

export default node;
