import { Text, showModal } from "@gigsmart/atorasu";
import React from "react";

export function showShiftFilledModal(onClose: () => void) {
  return showModal({
    eventContext: "Shift Filled Modal",
    title: "This Shift has been filled.",
    onRequestClose: onClose,
    onClose,
    actions: [
      {
        testID: "shift-filled-modal-submit",
        label: "View Available Shifts for Pick Up Today",
        color: "emphasized"
      }
    ],
    children: () => (
      <Text>
        This Shift has been filled and is no longer available. Find more work
        opportunities in your area using the Browse tab.
      </Text>
    )
  });
}
