import React from "react";

import { Column, type IconName, Row } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { RadioButtonGroup, SolidButton } from "@gigsmart/katana";

interface Props {
  options: Array<{
    label: string;
    value: number;
    icon?: IconName;
    testID?: string;
  }>;
  setTempValue: (index: number) => void;
  tempValue: number;
  selectRadiusMiles: (index: number) => void;
}

export default ({
  options,
  setTempValue,
  tempValue,
  selectRadiusMiles
}: Props) => {
  const styles = useStyles(({ getColor, getUnits }) => ({
    dropdownContainer: {
      backgroundColor: getColor("surface", "fill")
    },
    dropdownRow: {
      padding: getUnits(4)
    },
    button: {
      backgroundColor: getColor("primary", "fill"),
      borderColor: getColor("primary", "fill")
    }
  }));
  return (
    <Column style={styles.dropdownContainer}>
      <RadioButtonGroup
        selectedValue={tempValue}
        onChange={setTempValue}
        options={options}
        defaultIndex={tempValue}
      />
      <Row style={styles.dropdownRow}>
        <SolidButton
          title="Save and Apply"
          onPress={() => selectRadiusMiles(tempValue)}
        />
      </Row>
    </Column>
  );
};
