/**
 * @generated SignedSource<<f308be1cb97edaa0fa27af78072d8b90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gigPaymentRows_availableGig$data = {
  readonly estimatedPayment: {
    readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_paymentInfo">;
  } | null | undefined;
  readonly node: {
    readonly gigSeries: {
      readonly acceptsTips: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "gigPaymentRows_availableGig";
};
export type gigPaymentRows_availableGig$key = {
  readonly " $data"?: gigPaymentRows_availableGig$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_availableGig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigPaymentRows_availableGig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "estimatedPayment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gigPaymentRows_paymentInfo"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigSeries",
          "kind": "LinkedField",
          "name": "gigSeries",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acceptsTips",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailableGigsEdge",
  "abstractKey": null
};

(node as any).hash = "8071cb69f100d1cd65117b592016907d";

export default node;
