import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import RequesterPrimaryContact from "../requester/requester-primary-contact";
import type { requesterPrimaryContactScreenQuery } from "./__generated__/requesterPrimaryContactScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "BrowseGigPrimaryContact">;

export default createSuspendedQueryContainer<
  requesterPrimaryContactScreenQuery,
  Props
>(
  function RequesterPrimaryContactScreen({ route, response: result }) {
    return (
      <RequesterPrimaryContact
        requesterId={result?.node?.requester?.id ?? ""}
        gigId={route.params.id}
      />
    );
  },
  {
    query: graphql`
      query requesterPrimaryContactScreenQuery($id: ID!) {
        node(id: $id) {
          ... on GigLike {
            requester {
              id
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
