/**
 * @generated SignedSource<<03e80e1a05ffcb86b2f6bbfd39bc3da8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ConversationParticipantCapability = "CALL" | "%future added value";
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RequesterConversationHeader_conversationLike$data = {
  readonly engagement?: {
    readonly worker: {
      readonly displayName: string | null | undefined;
      readonly groupAssociations?: {
        readonly totalCount: number;
      } | null | undefined;
      readonly id: string;
      readonly isFavorite?: boolean;
      readonly profilePhoto: {
        readonly url: string | null | undefined;
      } | null | undefined;
    };
  };
  readonly engagements?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly capabilities: ReadonlyArray<{
          readonly expiresAt: string | null | undefined;
          readonly restrictedBy: {
            readonly message: string;
          } | null | undefined;
          readonly status: EngagementCapabilityStatus;
          readonly type: EngagementCapabilityType;
        }>;
        readonly contactNumber: string | null | undefined;
        readonly gig: {
          readonly primaryContact: {
            readonly displayName: string | null | undefined;
            readonly id: string;
          } | null | undefined;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id?: string;
  readonly organization?: {
    readonly id: string;
    readonly name: string;
  };
  readonly participant: {
    readonly user: {
      readonly organizations?: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly participants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly capabilities: ReadonlyArray<ConversationParticipantCapability>;
        readonly id: string;
        readonly user: {
          readonly __typename: string;
          readonly displayName: string | null | undefined;
          readonly groupAssociations?: {
            readonly totalCount: number;
          } | null | undefined;
          readonly id: string;
          readonly isFavorite?: boolean;
          readonly primaryOrganization?: {
            readonly name: string;
          } | null | undefined;
          readonly profilePhoto: {
            readonly url: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly type: string;
  readonly worker?: {
    readonly displayName: string | null | undefined;
    readonly groupAssociations?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly id: string;
    readonly isFavorite?: boolean;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"RequesterMoreMenu_conversationLike" | "ShiftInfo_conversation">;
  readonly " $fragmentType": "RequesterConversationHeader_conversationLike";
};
export type RequesterConversationHeader_conversationLike$key = {
  readonly " $data"?: RequesterConversationHeader_conversationLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequesterConversationHeader_conversationLike">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "condition": "isWorker",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "WorkerGroupWorkersConnection",
      "kind": "LinkedField",
      "name": "groupAssociations",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "groupAssociations(first:0)"
    }
  ]
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isWorker"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequesterConversationHeader_conversationLike",
  "selections": [
    {
      "alias": "type",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConversationParticipant",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": (v0/*: any*/),
                  "concreteType": "OrganizationsConnection",
                  "kind": "LinkedField",
                  "name": "organizations",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": "organizations(first:0)"
                }
              ],
              "type": "Requester",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 30
        }
      ],
      "concreteType": "ConversationParticipantsConnection",
      "kind": "LinkedField",
      "name": "participants",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ConversationParticipantsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ConversationParticipant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "capabilities",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Organization",
                          "kind": "LinkedField",
                          "name": "primaryOrganization",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Requester",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v7/*: any*/)
                      ],
                      "type": "Worker",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "participants(first:30)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftInfo_conversation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequesterMoreMenu_conversationLike"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Engagement",
          "kind": "LinkedField",
          "name": "engagement",
          "plural": false,
          "selections": [
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "EngagementConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            },
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
            }
          ],
          "concreteType": "EngagementsConnection",
          "kind": "LinkedField",
          "name": "engagements",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Engagement",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "contactNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Gig",
                      "kind": "LinkedField",
                      "name": "gig",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OrganizationRequester",
                          "kind": "LinkedField",
                          "name": "primaryContact",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EngagementCapability",
                      "kind": "LinkedField",
                      "name": "capabilities",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "restrictedBy",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "message",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "expiresAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "engagements(first:50,query:\"WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]\")"
        }
      ],
      "type": "OrganizationWorkerConversation",
      "abstractKey": null
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};
})();

(node as any).hash = "49eb0393db283064a5a0b2772140e212";

export default node;
