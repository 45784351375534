/**
 * @generated SignedSource<<36ae9226d3146bfa2f41145236fe9cf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigPaymentRows_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly estimatedPayment: {
    readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_paymentInfo">;
  } | null | undefined;
  readonly gig: {
    readonly gigSeries: {
      readonly acceptsTips: boolean;
    };
  };
  readonly paymentInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_paymentInfo">;
  } | null | undefined;
  readonly " $fragmentType": "gigPaymentRows_engagement";
};
export type gigPaymentRows_engagement$key = {
  readonly " $data"?: gigPaymentRows_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "gigPaymentRows_paymentInfo"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigPaymentRows_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigSeries",
          "kind": "LinkedField",
          "name": "gigSeries",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acceptsTips",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "estimatedPayment",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "fe7b43a31eef591bae03983895c01b3c";

export default node;
