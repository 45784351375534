import { Link, Stack, showModal } from "@gigsmart/atorasu";
import JobBoardInfoModalContent from "@gigsmart/seibutsu/modals/JobBoardInfoModalContent";
import React from "react";

export const showJobBoardTooltip = () =>
  showModal({
    title: "Job Board",
    headerIcon: "newspaper",
    eventContext: "Job Board Tooltip",
    children: (
      <Stack>
        <JobBoardInfoModalContent />
        <Link align="center" openURL="https://gigsmart.com/workers/find-work/">
          View More Info about Job Board
        </Link>
      </Stack>
    ),
    actions: [{ label: "Got It", testID: "close-btn" }]
  });
