import {
  Column,
  ContentArea,
  Divider,
  HighlightedReminder,
  ProductInfoRow,
  Spacer,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { hasEngagementCapability } from "../../engagement/WithEngagementCapability";
import WorkerGigReceipt from "../../engagement/WorkerGigReceipt";
import WorkerUpdatedTimesheetReminder from "../../engagement/WorkerUpdatedTimesheetReminder";
import type { WorkerPaymentSurface_shift$key } from "./__generated__/WorkerPaymentSurface_shift.graphql";

type Props = {};

export const WorkerShiftReceipt = ({
  id,
  currentState,
  workerTimesheet,
  systemTimesheet,
  gig,
  result
}: FragmentContainerInnerComponentProps<
  WorkerPaymentSurface_shift$key,
  Props
>) => {
  const history = useHistory();
  const isPending = currentState?.name === "PENDING_TIMESHEET_APPROVAL";
  const hasApprovedTimesheet =
    isPending && systemTimesheet?.workerApprovals?.totalCount === 1;
  const neverStarted = systemTimesheet?.startedCount?.totalCount === 0;

  return (
    <Surface>
      <ContentArea gap="standard">
        {hasApprovedTimesheet && (
          <HighlightedReminder
            icon="share"
            header="Your Timesheet has been sent to the Requester. Please note, the Requester has the option to approve that Timesheet or a Timesheet they create."
          />
        )}
        {isPending &&
          !workerTimesheet?.isOverridden &&
          !hasApprovedTimesheet && (
            <HighlightedReminder
              icon="circle-exclamation"
              header={
                neverStarted ? (
                  <Text>
                    The 6-hour window to create a Timesheet has elapsed. If you
                    did work this Shift, a Timesheet will be approved by the
                    Requester no later than the end of the business day{" "}
                    {time.humanize(gig?.reconcilesAt, "dateTimeFull")}.
                  </Text>
                ) : (
                  <Text>
                    The 6-hour window to review your Timesheet has elapsed. A
                    Timesheet will be approved by the Requester no later than{" "}
                    {time.humanize(gig?.reconcilesAt, "dateTimeFull")}.
                  </Text>
                )
              }
            />
          )}
        <WorkerUpdatedTimesheetReminder fragmentRef={result} />
        {hasEngagementCapability(result, "DISPUTE_TIMESHEET") && (
          <ProductInfoRow
            variant="outline"
            testID="dispute-timesheet-info-row"
            name="Issue with your pay?"
            note="Start a Payment Dispute now"
            icon="file-exclamation"
            color="black"
            callToActionLabel="Start"
            callToActionOnPress={() =>
              history.push(`/gigs/${id}/dispute-timesheet`)
            }
          />
        )}
        <Column>
          <Spacer size="compact" />
          <Text variant="header" color="primary">
            Your Receipt
          </Text>
        </Column>
        {isPending && (
          <Text>
            Your receipt, including total earnings, will show here after the
            Requester has approved your timesheet.{" "}
            <Text weight="bold">
              Your timesheet will be approved no later than{" "}
              {time.humanize(gig?.reconcilesAt, "dateTimeFull")}.
            </Text>
          </Text>
        )}
      </ContentArea>
      <Divider />
      <WorkerGigReceipt fragmentRef={result} />
    </Surface>
  );
};

export default createRelayFragmentContainer<
  WorkerPaymentSurface_shift$key,
  Props
>(
  graphql`
    fragment WorkerPaymentSurface_shift on Engagement {
      ...WorkerGigReceipt_engagement
      ...WorkerUpdatedTimesheetReminder_engagement
      ...WithEngagementCapability_engagement
      id
      currentState {
        name
      }
      gig {
        reconcilesAt
      }
      workerTimesheet: timesheet(variant: WORKER) {
        isOverridden
        editable
      }
      systemTimesheet: timesheet(variant: SYSTEM) {
        startedCount: states(first: 0, query: "WHERE action = START") {
          totalCount
        }
        workerApprovals: approvals(first: 0, query: "WHERE approvedByType = WORKER") {
          totalCount
        }
      }
    }
  `,
  WorkerShiftReceipt
);
