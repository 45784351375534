import type { MediaPickerFile } from "@gigsmart/atorasu/organisms/MediaPicker";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import type { useAddUserFileMutation } from "./__generated__/useAddUserFileMutation.graphql";
import { pathToFile } from "./fileUploadHelpers";

export type UploadFileItem =
  | undefined
  | null
  | MediaPickerFile
  | MediaPickerFile[];

export type FileUpload = NonNullable<
  ObjectPath<
    useAddUserFileMutation,
    ["response", "addUserFile", "newUserFileEdge", "node"]
  >
>;

export default function useAddUserFile() {
  const [addUserFile, { loading }] =
    useRelayMutationPromise<useAddUserFileMutation>(graphql`
      mutation useAddUserFileMutation($input: AddUserFileInput!) {
        addUserFile(input: $input) {
          newUserFileEdge {
            node {
              id
              url
              filename
              mimeType
            }
          }
        }
      }
    `);

  return {
    loading,
    addUserFile,
    uploadFile: async (value: UploadFileItem): Promise<FileUpload | null> => {
      value = Array.isArray(value) ? value[0] : value;
      if (!value) return null;

      const file =
        typeof value === "string"
          ? pathToFile(value)
          : "uri" in value
            ? pathToFile(value.uri, value.type, value.name)
            : value;

      const userFile = await addUserFile(
        { input: { file: "file" } },
        { uploadables: { file } }
      );

      return userFile.addUserFile?.newUserFileEdge?.node ?? null;
    }
  };
}
