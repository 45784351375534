/**
 * @generated SignedSource<<d598087a00f324b7a741f9e875a6138d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransportationMethodCard_Worker$data = {
  readonly transportationMethod: string | null | undefined;
  readonly " $fragmentType": "TransportationMethodCard_Worker";
};
export type TransportationMethodCard_Worker$key = {
  readonly " $data"?: TransportationMethodCard_Worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransportationMethodCard_Worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransportationMethodCard_Worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transportationMethod",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "e72e4e99463202c1f805dd99e50cd086";

export default node;
