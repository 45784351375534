/**
 * @generated SignedSource<<9078db706d04548eb22ecc7dea2548ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type VerificationReportStatus = "CANCELED" | "FAIL" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PASS" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
export type WorkerVerificationStatus = "ENROLLED" | "NOT_ELIGIBLE" | "NOT_ENROLLED" | "PENDING" | "REQUIRED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEnrollmentData_verification$data = {
  readonly reports: ReadonlyArray<{
    readonly status: VerificationReportStatus;
    readonly statusDescription: string | null | undefined;
    readonly type: ReportType;
  }>;
  readonly status: WorkerVerificationStatus;
  readonly " $fragmentType": "useEnrollmentData_verification";
};
export type useEnrollmentData_verification$key = {
  readonly " $data"?: useEnrollmentData_verification$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEnrollmentData_verification">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useEnrollmentData_verification"
};

(node as any).hash = "a0fe565ca77ddee9a012fe6f3b2d3cf3";

export default node;
