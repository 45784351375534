import { View } from "@gigsmart/atorasu";
import React from "react";
import Svg, { Path } from "react-native-svg";

import { useStyles } from "@gigsmart/atorasu/style";

export default () => {
  const styles = useStyles(() => ({
    container: {
      height: "100%",
      width: "100%"
    },
    svg: {
      width: "100%",
      height: 160
    }
  }));
  return (
    <View style={styles.container}>
      <Svg
        viewBox="0 0 390 154"
        preserveAspectRatio="xMidYMax slice"
        style={styles.svg}
      >
        <Path
          opacity="0.08"
          d="M390 0V148.719C373.277 148.233 371.558 147.724 369.849 147.179L334.062 135.76C307.154 127.187 278.449 125.925 250.892 132.105L177.998 148.457C168.211 150.148 157.879 151.466 147.036 152.263C87.5444 156.64 36.7179 143.591 0 129.473V0H375Z"
          fill="#083C5C"
        />
      </Svg>
    </View>
  );
};
