/**
 * @generated SignedSource<<bd91c68385fa33b4705d9c278150e222>>
 * @relayHash 10067c679c38be20cf549f552495a034
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:3c7oqi10Ci5fB7OrvZrMOIMp4dEAogdsV0exbSJ95rI

import { ConcreteRequest } from 'relay-runtime';
export type paymentWorkerQuery$variables = Record<PropertyKey, never>;
export type paymentWorkerQuery$data = {
  readonly viewer: {
    readonly payableAccount?: {
      readonly paymentMethod: {
        readonly accountHolderName: string | null | undefined;
        readonly expirationMonth?: number;
        readonly expirationYear?: number;
        readonly last4: string;
        readonly postalCode?: string | null | undefined;
        readonly routingNumber?: string;
        readonly type: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type paymentWorkerQuery = {
  response: paymentWorkerQuery$data;
  variables: paymentWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountHolderName",
  "storageKey": null
},
v1 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    }
  ],
  "type": "BankCard",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "routingNumber",
      "storageKey": null
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayableAccount",
                "kind": "LinkedField",
                "name": "payableAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "paymentMethod",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "paymentWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayableAccount",
                "kind": "LinkedField",
                "name": "payableAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "paymentMethod",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:3c7oqi10Ci5fB7OrvZrMOIMp4dEAogdsV0exbSJ95rI",
    "metadata": {},
    "name": "paymentWorkerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e5e567eb04b286b7803cde8875fcf8ad";

export default node;
