import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import times from "lodash/times";
import React, { Component } from "react";
import { TouchableOpacity, View } from "react-native";

import { type IconName, type IconVariant, StyledIcon } from "../icon";
import {
  type StylesProps,
  type ViewStyleProp,
  theme as globalTheme,
  stylesStubs,
  withStyles
} from "../style";
import { StyledText } from "../text";

type RatingTuple = [IconName, IconVariant];

const RATING_EMPTY: RatingTuple = ["star", "light"];
const RATING_HALF: RatingTuple = ["star-half-alt", "solid"];
const RATING_FILLED: RatingTuple = ["star", "solid"];

type Props = StylesProps &
  EventerProps<"pressed"> & {
    color?: string;
    disabled?: boolean;
    fontSize?: number;
    onPress?: (arg0: number) => void;
    rating: number | null | undefined;
    style?: ViewStyleProp;
    testID?: string;
    label?: string;
  };
@withStyles(() => ({
  row: {
    flexDirection: "row"
  },
  star: {
    marginLeft: 1,
    marginRight: 1
  }
}))
@withEventers("Rating Stars", ["pressed"])
export default class RatingStars extends Component<Props> {
  static defaultProps = {
    fontSize: globalTheme.font.size.extraLarge,
    eventers: eventerStubs(["pressed"]),
    ...stylesStubs
  };

  get stars(): RatingTuple[] {
    const rating = Math.floor(this.props.rating ?? 0);
    const remainder = (this.props.rating ?? 0) % rating;
    let stars: RatingTuple[] = times(rating, () => RATING_FILLED);
    if (remainder > 0) {
      stars = stars.concat(
        [RATING_HALF],
        times(4 - rating, () => RATING_EMPTY)
      );
    } else {
      stars = stars.concat(times(5 - rating, () => RATING_EMPTY));
    }
    return stars;
  }

  handlePress = (rating: number) => {
    if (this.props.onPress) this.props.onPress(rating);
  };

  _mapStars = (icon: [IconName, IconVariant], index: number) => {
    const { color, disabled, fontSize, styles, theme } = this.props;
    const activeColor = icon[1] === "solid" ? color : theme.color.neutralDark;

    return (
      <TouchableOpacity
        disabled={disabled}
        testID={`star_${index}`}
        key={`star_${index}`}
        onPress={() => {
          this.props.eventers.pressed({}, `${index + 1}`);
          this.handlePress(index + 1);
        }}
      >
        <StyledIcon
          style={[styles.star, { fontSize }]}
          color={activeColor}
          name={icon[0]}
          variant={icon[1]}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const { rating, style, styles, testID, label, fontSize } = this.props;

    return (
      <View
        style={[styles.row, style]}
        testID={testID ?? `rating-score-${Math.floor(rating ?? 0)}`}
      >
        {this.stars.map(this._mapStars)}
        {label && <StyledText fontSize={fontSize}>{` ${label}`}</StyledText>}
      </View>
    );
  }
}
