/**
 * @generated SignedSource<<e0ea94de140bdb5132fe8b103429cdd6>>
 * @relayHash f7215b45a3988ea4b3d99fab28716f76
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:jgGk-gPeaB32sA0w-DhKmsMSL2MvtdrfBjlS64REK-U

import { ConcreteRequest } from 'relay-runtime';
export type GetWorkersSliderQuery$variables = Record<PropertyKey, never>;
export type GetWorkersSliderQuery$data = {
  readonly platformStats: {
    readonly workerCount: number;
  };
};
export type GetWorkersSliderQuery = {
  response: GetWorkersSliderQuery$data;
  variables: GetWorkersSliderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetWorkersSliderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlatformStats",
        "kind": "LinkedField",
        "name": "platformStats",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetWorkersSliderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlatformStats",
        "kind": "LinkedField",
        "name": "platformStats",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:jgGk-gPeaB32sA0w-DhKmsMSL2MvtdrfBjlS64REK-U",
    "metadata": {},
    "name": "GetWorkersSliderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c03fee522157a1589d3356aec3c9bd03";

export default node;
