import {
  AvatarStack,
  Column,
  ContentArea,
  Icon,
  Pressable,
  Stack,
  Text,
  showModal,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React, { useMemo } from "react";
import ParticipantRow from "./ParticipantRow";
import type { Participants_conversation$key } from "./__generated__/Participants_conversation.graphql";

export function useShowParticipantsModal(
  unsortedParticipants: NonNullable<
    Participants_conversation$key[" $data"]
  >["participants"],
  engagements: NonNullable<
    Participants_conversation$key[" $data"]
  >["engagements"],
  organization: NonNullable<
    Participants_conversation$key[" $data"]
  >["organization"]
) {
  const { participants } = useSortedParticipants(
    unsortedParticipants,
    organization
  );
  return () =>
    showModal({
      eventContext: "participantModal",
      variant: "border",
      title: `Participants (${participants?.length})`,
      useModalFooter: true,
      scrollable: true,
      dismissable: true,
      fixedHeight: 540,
      children: () => (
        <ContentArea>
          <Stack>
            {participants?.map((p) => (
              <ParticipantRow
                key={p?.user?.id}
                participant={p}
                engagements={engagements}
              />
            ))}
          </Stack>
        </ContentArea>
      )
    });
}

interface Props {
  showAsWorker?: boolean;
}

export function Participants({
  participants: unsortedParticipants,
  engagements,
  organization,
  showAsWorker
}: FragmentContainerInnerComponentProps<Participants_conversation$key, Props>) {
  const { participants } = useSortedParticipants(
    unsortedParticipants,
    showAsWorker ? undefined : organization
  );
  const avatars = participants?.map((participant) =>
    participant?.user?.__typename === "%other"
      ? null
      : participant?.user?.__typename === "%gigsmart"
        ? {
            icon: "gigsmart",
            color: "orange",
            fade: true,
            iconSize: "large"
          }
        : participant?.user?.profilePhoto?.url ??
          participant?.user?.displayName
            ?.split(" ")
            ?.map((n) => n[0])
            ?.join("")
  );
  const isSmall = useMatchesViewport(({ size }) => size.small.down);
  const isMedium = useMatchesViewport(({ size }) => size.medium.down);
  const isLarge = useMatchesViewport(({ size }) => size.large.down);
  const max = isSmall ? 2 : isLarge ? 3 : 5;
  const label =
    isSmall || (isLarge && !isMedium)
      ? `View ${avatars?.length}`
      : `View ${avatars?.length} Participants`;
  const handlePress = () => {
    return showModal({
      eventContext: "participantModal",
      variant: "border",
      title: `Participants (${participants?.length})`,
      useModalFooter: true,
      scrollable: true,
      fixedHeight: 540,
      children: () => (
        <ContentArea>
          <Stack>
            {participants?.map((p) => (
              <ParticipantRow
                key={p?.user?.id}
                participant={p}
                engagements={engagements}
              />
            ))}
          </Stack>
        </ContentArea>
      )
    });
  };
  return (
    <Stack horizontal size="compact">
      <AvatarStack max={max} avatars={avatars as string[]} />
      <Column justifyContent="center">
        <Pressable
          testID="participants-modal-btn"
          onPress={handlePress}
          eventEntityType="Participants"
          eventTargetName="ParticipantModalButton"
        >
          <Stack
            horizontal
            alignItems="center"
            justifyContent="center"
            size="compact"
          >
            <Text color="primary" weight="bold">
              {label}
            </Text>
            <Icon
              name="chevron-right"
              color="primary"
              size="small"
              variant="solid"
            />
          </Stack>
        </Pressable>
      </Column>
    </Stack>
  );
}

export default createRelayFragmentContainer<
  Participants_conversation$key,
  Props
>(
  graphql`
    fragment Participants_conversation on ConversationLike
    @argumentDefinitions(isWorker: { type: "Boolean", defaultValue: false }) {
      participants(first: 30) {
        edges {
          node {
            id
            capabilities
            user {
              __typename
              id
              ... on OrganizationRequester {
                displayName
                profilePhoto {
                  url
                }
                organization {
                  name
                }
              }
              ... on Requester {
                displayName
                profilePhoto {
                  url
                }
                primaryOrganization {
                  name
                }
              }
              ... on Worker {
                displayName
                profilePhoto {
                  url
                }
                isFavorite @skip(if: $isWorker)
                groupAssociations(first: 0) @skip(if: $isWorker) {
                  totalCount
                }
              }
            }
          }
        }
      }
      ... on OrganizationWorkerConversation {
        engagements(
          first: 50
          query: "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
        ) {
          edges {
            node {
              contactNumber
              gig {
                primaryContact {
                  id
                  displayName
                }
              }
              capabilities {
                type
                restrictedBy {
                  message
                }
                expiresAt
                status
              }
            }
          }
        }
        organization {
          primaryContact {
            id
          }
        }
      }
    }
  `,
  Participants
);

function useSortedParticipants(
  unsortedParticipants: NonNullable<
    Participants_conversation$key[" $data"]
  >["participants"],
  organization: NonNullable<
    Participants_conversation$key[" $data"]
  >["organization"]
) {
  const currentUser = useCurrentUser();
  return useMemo(() => {
    const all = getConnectionNodes(unsortedParticipants);
    all.push({
      id: "gigsmart",
      user: {
        id: "gigsmart",
        displayName: "GigSmart",
        __typename: "%gigsmart",
        profilePhoto: null
      },
      capabilities: []
    });

    const you = all?.find((participant) =>
      currentUser?.__typename === "OrganizationRequester"
        ? participant?.user?.id === currentUser?.requester?.id
        : participant?.user?.id === currentUser?.id
    );
    const rest =
      all?.filter((participant) =>
        currentUser?.__typename === "OrganizationRequester"
          ? participant?.user?.id !== currentUser?.requester?.id
          : participant?.user?.id !== currentUser?.id
      ) ?? [];
    const participants = you ? [you, ...rest] : rest;

    const primaryContact = all?.find(
      (participant) =>
        participant?.user?.id === organization?.primaryContact?.id
    );
    const nonPrimaryContactParticipants =
      all?.filter(
        (participant) =>
          participant?.user?.id !== organization?.primaryContact?.id
      ) ?? [];
    const avatarStackForWorkers = primaryContact
      ? [primaryContact, ...nonPrimaryContactParticipants]
      : nonPrimaryContactParticipants;

    return { participants, avatarStackForWorkers };
  }, [
    unsortedParticipants,
    currentUser?.__typename,
    currentUser?.requester?.id,
    currentUser?.id,
    organization?.primaryContact?.id
  ]);
}
