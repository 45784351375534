import { Avatar, ListRow, Text } from "@gigsmart/atorasu";
import { ConversationTopics } from "@gigsmart/feature-flags";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { type ReactNode } from "react";

import type { requesterThumbnailRow_gig$key } from "./__generated__/requesterThumbnailRow_gig.graphql";

interface Props {
  fragmentRef?: requesterThumbnailRow_gig$key | null | undefined;
  right?: ReactNode;
}

export default function RequesterThumbnailRow({ fragmentRef, right }: Props) {
  const gig = useRelayFragment(
    graphql`
      fragment requesterThumbnailRow_gig on GigLike {
        organization {
          name
          logoUrl
        }
        primaryContact {
          displayName
        }
        requester {
          displayName
          profilePhoto {
            url
          }
        }
      }
    `,
    fragmentRef ?? null
  );

  return (
    <ListRow
      spacing="small"
      left={
        <Avatar
          size="small"
          icon={gig?.organization ? "building" : "user"}
          uri={gig?.organization?.logoUrl ?? gig?.requester?.profilePhoto?.url}
        />
      }
      right={right}
      label={
        <Text weight="bold" color="primary" numberOfLines={1}>
          {gig?.organization?.name ?? gig?.requester?.displayName ?? ""}
        </Text>
      }
    >
      {!!gig?.organization &&
        !!gig?.primaryContact &&
        ConversationTopics.isEnabled() && (
          <Text variant="note" numberOfLines={1}>
            Primary Contact: {gig?.primaryContact?.displayName}
          </Text>
        )}
    </ListRow>
  );
}
