import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { EngagementDetailsFallbackQuery } from "./__generated__/EngagementDetailsFallbackQuery.graphql";
import type { WorkerShiftParams } from "./navigation";

type Props = AppScreenProps<WorkerShiftParams, "EngagementDetails">;

export default createSuspendedQueryContainer<
  EngagementDetailsFallbackQuery,
  Props
>(
  /**
   * Fallback to handle old `/gigs/:id` url
   */
  function EngagementDetailsFallback({ response, route }) {
    const isProject = response?.node?.gigType === "PROJECT";
    return isProject ? (
      <Redirect replace screen="ProjectDetails" screenParams={route.params} />
    ) : (
      <Redirect replace screen="ShiftDetails" screenParams={route.params} />
    );
  },
  {
    query: graphql`
      query EngagementDetailsFallbackQuery($id: ID!) {
        node(id: $id) {
          ... on Engagement {
            gigType
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
