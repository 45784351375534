import type { AppScreenProps } from "@gigsmart/kaizoku";
import {
  NavHeaderButton,
  NavPortal,
  Stepper,
  confirmPrompt,
  dismiss
} from "@gigsmart/katana";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import DisputeAgreementStep from "@gigsmart/seibutsu//engagement-dispute/worker/DisputeAgreementStep";
import type { StepPropsType } from "@gigsmart/seibutsu/engagement-dispute/worker/types";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../../navigation/types";
import type { disputeTimesheetScreenRemoveTimesheetMutation } from "./__generated__/disputeTimesheetScreenRemoveTimesheetMutation.graphql";
import CreateDisputeTimesheetStep from "./create-dispute-timesheet-step";
import DisputeTypeStep from "./dispute-type-step";
import PaymentDisputeStep from "./payment-dispute-step";
import RequestAdditionalPaymentStep from "./request-additional-payment-step";
import ReviewDisputeStep from "./review-dispute-step";

type Props = AppScreenProps<WorkerParamList, "DisputeTimesheet">;

export default function DisputeTimesheetScreen({ route, navigation }: Props) {
  const [commitRemoveTimesheet] =
    useRelayMutation<disputeTimesheetScreenRemoveTimesheetMutation>(graphql`
      mutation disputeTimesheetScreenRemoveTimesheetMutation(
        $input: RemoveEngagementTimesheetInput!
      ) {
        removeEngagementTimesheet(input: $input) {
          removedTimesheetId
        }
      }
    `);
  const steps = useMemo(
    () => [
      {
        name: "dispute-agreement",
        render: DisputeAgreementStep
      },
      {
        name: "dispute-type",
        render: DisputeTypeStep
      },
      {
        name: "dispute-payment",
        render: PaymentDisputeStep
      },
      {
        name: "create-dispute-timesheet",
        render: CreateDisputeTimesheetStep
      },
      {
        name: "request-additional-payment",
        render: RequestAdditionalPaymentStep
      },
      {
        name: "review",
        render: ReviewDisputeStep
      }
    ],
    []
  );

  const engagementId = route.params.id;

  return (
    <Stepper<StepPropsType>
      fill={1}
      eventContext="Dispute Timesheet Stepper"
      steps={steps}
      onComplete={() => null}
      initialData={{ engagementId }}
      outOfSequence
      historyBlock={false}
      renderHeader={({ stepData, currentStep }) => {
        return (
          <NavPortal.Entrance
            onPressBack={() => {
              if (
                (stepData?.disputeTimesheet ||
                  stepData?.disputeAdditionalPayment) &&
                stepData?.disputeTimesheetId &&
                currentStep?.number === 1
              ) {
                confirmPrompt({
                  title: "Are you sure you want to go back?",
                  subTitle:
                    "By going back, any changes you have made will not be saved.",
                  cancelLabel: "No",
                  onDo: () => {
                    if (stepData?.disputeTimesheetId) {
                      commitRemoveTimesheet(
                        {
                          input: { timesheetId: stepData.disputeTimesheetId }
                        },
                        { onSuccess: navigation.goBack }
                      );
                    } else {
                      navigation.goBack();
                    }
                  },
                  onCancel: dismiss
                });
              } else {
                navigation.goBack();
              }
            }}
            renderRightIcon={() => (
              <NavHeaderButton
                title="Exit"
                onPress={() => {
                  confirmPrompt({
                    title: "Are you sure you want to Exit?",
                    subTitle:
                      "Your Payment Dispute has not been submitted to the Requester.",
                    cancelLabel: "No",
                    onDo: () => {
                      if (stepData?.disputeTimesheetId) {
                        commitRemoveTimesheet(
                          {
                            input: {
                              timesheetId: stepData.disputeTimesheetId
                            }
                          },
                          {
                            onSuccess: () =>
                              navigation.replace("ShiftDetails", {
                                id: engagementId
                              })
                          }
                        );
                      } else {
                        navigation.replace("ShiftDetails", {
                          id: engagementId
                        });
                      }
                    },
                    onCancel: dismiss
                  });
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
