import {
  camelCase,
  isEmpty,
  isNil,
  isObject,
  isPlainObject,
  pick,
  snakeCase
} from "lodash";
import type {
  StripeBankAccountInput,
  StripeBankAccountTokenResponse,
  StripeCardInput,
  StripeCardTokenResponse,
  StripeConfig
} from "./types";

let publishableKey = "";

interface ObjectToQueryStringHelperObject {
  keyPath: string[];
  value: unknown;
}

function toFormDataHelper(
  object: any,
  path: string[] = [],
  result: ObjectToQueryStringHelperObject[] = []
): ObjectToQueryStringHelperObject[] {
  if (!object) return [];
  return Object.entries(object).reduce<ObjectToQueryStringHelperObject[]>(
    (acc, [key, value]) => {
      if (!isNil(value) || !isEmpty(value)) {
        isObject(value)
          ? acc.push(...toFormDataHelper(value, [...path, key], result))
          : acc.push({ keyPath: [...path, key], value });
      }
      return acc;
    },
    []
  );
}

export function toFormData(object: any): string {
  const simplifiedData = toFormDataHelper(object);
  const queryStrings = simplifiedData.map(
    ({ keyPath: [firstKey, ...otherKeys], value }) => {
      const nestedPath = otherKeys.map((key) => `[${key}]`).join("");
      return `${firstKey}${nestedPath}=${
        !isNil(value) ? encodeURI(`${value}`) : ""
      }`;
    }
  );
  return queryStrings.join("&");
}

export function configure(options: StripeConfig) {
  publishableKey = options.publishableKey;
}

async function post(resource: string, data: any) {
  const Authorization = `Bearer ${publishableKey}`;
  const response = await fetch(`https://api.stripe.com/v1/${resource}`, {
    method: "POST",
    headers: {
      Authorization,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: toFormData(snakeCaseKeys(data))
  });
  const json = camelCaseKeys(await response.json());
  if (json.error) throw new Error(`Error from stripe: ${json.error.message}`);
  return json;
}

function snakeCaseKeys(params: any): any {
  if (Array.isArray(params)) return params.map(snakeCaseKeys);
  if (isPlainObject(params))
    return Object.keys(params).reduce(
      (acc, key) => ({
        ...acc,
        [snakeCase(key)]: snakeCaseKeys(params[key])
      }),
      {}
    );
  return params;
}

function camelCaseKeys(params: any): any {
  if (Array.isArray(params)) return params.map(camelCaseKeys);
  if (isPlainObject(params))
    return Object.keys(params).reduce(
      (acc, key) => ({
        ...acc,
        [camelCase(key)]: camelCaseKeys(params[key])
      }),
      {}
    );
  return params;
}

export async function createTokenWithBankAccount(
  bankAccount: StripeBankAccountInput
): Promise<StripeBankAccountTokenResponse> {
  return await post("tokens", {
    bankAccount: pick(bankAccount, [
      "country",
      "currency",
      "accountNumber",
      "accountHolderName",
      "accountHolderType",
      "routingNumber"
    ])
  });
}

export async function createTokenWithCard(
  card: StripeCardInput
): Promise<StripeCardTokenResponse> {
  return await post("tokens", {
    card: pick(card, [
      "expMonth",
      "expYear",
      "number",
      "cvc",
      "name",
      "addressLine1",
      "addressLine2",
      "addressCity",
      "addressState",
      "addressZip",
      "addressCountry",
      "currency"
    ])
  });
}
