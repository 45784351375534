import { toast } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { confirmPrompt } from "@gigsmart/katana";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import { useCallback } from "react";
import type { useDeleteProfileItemEducationMutation } from "./__generated__/useDeleteProfileItemEducationMutation.graphql";
import type { useDeleteProfileItemLanguageMutation } from "./__generated__/useDeleteProfileItemLanguageMutation.graphql";
import type { useDeleteProfileItemMilitaryMutation } from "./__generated__/useDeleteProfileItemMilitaryMutation.graphql";
import type { useDeleteProfileItemWorkHistoryMutation } from "./__generated__/useDeleteProfileItemWorkHistoryMutation.graphql";

export default function useDeleteProfileItem() {
  const history = useHistory();

  const [removeWorkerWorkHistory] =
    useRelayMutationPromise<useDeleteProfileItemWorkHistoryMutation>(
      graphql`
        mutation useDeleteProfileItemWorkHistoryMutation(
          $input: RemoveWorkerWorkHistoryInput!
        ) {
          removeWorkerWorkHistory(input: $input) {
            removedWorkerWorkHistoryId
          }
        }
      `
    );

  const [removeWorkerEducation] =
    useRelayMutationPromise<useDeleteProfileItemEducationMutation>(
      graphql`
        mutation useDeleteProfileItemEducationMutation(
          $input: RemoveWorkerEducationInput!
        ) {
          removeWorkerEducation(input: $input) {
            removedWorkerEducationId
          }
        }
      `
    );

  const [removeWorkerLanguage] =
    useRelayMutationPromise<useDeleteProfileItemLanguageMutation>(graphql`
      mutation useDeleteProfileItemLanguageMutation(
        $input: RemoveWorkerLanguageInput!
      ) {
        removeWorkerLanguage(input: $input) {
          removedWorkerLanguageId
        }
      }
    `);

  const [removeWorkerMilitaryService] =
    useRelayMutationPromise<useDeleteProfileItemMilitaryMutation>(graphql`
      mutation useDeleteProfileItemMilitaryMutation(
        $input: RemoveWorkerMilitaryServiceInput!
      ) {
        removeWorkerMilitaryService(input: $input) {
          removedWorkerMilitaryServiceId
        }
      }
    `);

  return useCallback(
    (id: string, item: "WorkHistory" | "Education" | "Language" | "Military") =>
      confirmPrompt({
        title: "Delete Item from Profile",
        subTitle:
          "Are you sure you want to delete this item from your Profile?",
        yesLabel: "Yes",
        cancelLabel: "No",
        onDo: async () => {
          switch (item) {
            case "WorkHistory":
              await removeWorkerWorkHistory({
                input: {
                  workerWorkHistoryId: id
                }
              });
              toast.success("Work History successfully deleted");
              break;
            case "Education":
              await removeWorkerEducation({
                input: {
                  workerEducationId: id
                }
              });
              toast.success("Education successfully deleted");
              break;
            case "Language":
              await removeWorkerLanguage({
                input: {
                  workerLanguageId: id
                }
              });
              toast.success("Language successfully deleted");
              break;
            case "Military":
              await removeWorkerMilitaryService({
                input: {
                  workerMilitaryServiceId: id
                }
              });
              toast.success("Military successfully deleted");
              break;
            default:
              break;
          }
          history.push("/profile");
        }
      }),
    [
      history,
      removeWorkerEducation,
      removeWorkerLanguage,
      removeWorkerMilitaryService,
      removeWorkerWorkHistory
    ]
  );
}
