import {
  getConnectionEdges,
  graphql,
  useRelayOrchestrator
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import type { FeatureCollection, Point } from "geojson";
import compact from "lodash/compact";
import { useEffect, useMemo, useState } from "react";

import type { shiftGigsMapHooksGigsQuery } from "./__generated__/shiftGigsMapHooksGigsQuery.graphql";
import type { shiftGigsMapHooksPinsQuery } from "./__generated__/shiftGigsMapHooksPinsQuery.graphql";

type GigPinEdgeType = NonNullable<
  ObjectPath<
    shiftGigsMapHooksPinsQuery,
    ["response", "viewer", "availableGigs", "edges", 0]
  >
>;

type GigEdgeType = NonNullable<
  ObjectPath<
    shiftGigsMapHooksGigsQuery,
    ["response", "viewer", "allGigSeries", "edges", 0]
  >
>;

export type GigQuery$variables =
  | shiftGigsMapHooksPinsQuery["variables"]
  | shiftGigsMapHooksGigsQuery["variables"];

type PinsType = FeatureCollection<Point, Record<string, any>>;

const mapQuery = graphql`
  query shiftGigsMapHooksGigsQuery(
    $count: Int!
    $after: String
    $maxDistance: Int!
    $searchTerm: String
    $latitude: Float
    $longitude: Float
    $gigTypes: [GigType!]
  ) {
    viewer {
      ... on Worker {
        allGigSeries: availableGigSeries(
          first: $count
          after: $after
          input: {
            maxDistance: $maxDistance
            searchTerm: $searchTerm
            latitude: $latitude
            longitude: $longitude
            gigTypes: $gigTypes
          }
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              __typename
              id
            }
            ...WorkerAvailableSeriesCard_seriesEdge
          }
        }
      }
    }
  }
`;

const pinQuery = graphql`
  query shiftGigsMapHooksPinsQuery(
    $count: Int!
    $after: String
    $maxDistance: Int!
    $searchTerm: String
    $latitude: Float
    $longitude: Float
    $gigTypes: [GigType!]
    $pickupEligible: Boolean!
  ) {
    viewer {
      ... on Worker {
        availableGigs(
          first: $count
          after: $after
          maxDistance: $maxDistance
          searchTerm: $searchTerm
          latitude: $latitude
          longitude: $longitude
          gigTypes: $gigTypes
          pickupEligible: $pickupEligible
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              approximateLocation {
                latitude
                longitude
              }
              gigSeries {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useFilteredGigs = (
  vars?: GigQuery$variables,
  filtered?: string[]
) => {
  const { fetchQuery } = useRelayOrchestrator();
  const [cache, setCache] = useState<Map<string, GigEdgeType>>();

  useEffect(() => {
    let skip = false;
    const exec = async () => {
      let after: string | null | undefined;
      let hasNext = true;
      const newCache = new Map<string, GigEdgeType>();
      while (hasNext) {
        const res =
          vars && !skip
            ? await fetchQuery<shiftGigsMapHooksGigsQuery>(mapQuery, {
                ...vars,
                after
              })
            : null;

        res?.viewer?.allGigSeries?.edges?.forEach((edge) => {
          if (edge?.node?.id) newCache.set(edge.node.id, edge);
        });
        after = res?.viewer?.allGigSeries?.pageInfo.endCursor;
        hasNext = !!res?.viewer?.allGigSeries?.pageInfo.hasNextPage;
      }

      if (!skip) setCache(newCache);
    };

    void exec();
    return () => {
      skip = true;
    };
  }, [fetchQuery, vars]);

  const edges = useMemo(
    () => compact(filtered?.map((id) => cache?.get(id))),
    [filtered, cache]
  );

  return edges;
};

export const usePins = (vars?: GigQuery$variables, isPickupShift?: boolean) => {
  const { fetchQuery } = useRelayOrchestrator();
  const [pins, setPins] = useState<PinsType>();

  useEffect(() => {
    let skip = false;
    const exec = async () => {
      let after: string | null | undefined;
      let gigs: GigPinEdgeType[] = [];
      let hasNext = true;
      while (hasNext) {
        const res =
          vars && !skip
            ? await fetchQuery<shiftGigsMapHooksPinsQuery>(pinQuery, {
                ...vars,
                pickupEligible: !!isPickupShift,
                count: 200,
                after
              })
            : null;

        gigs = gigs.concat(getConnectionEdges(res?.viewer?.availableGigs));
        after = res?.viewer?.availableGigs?.pageInfo.endCursor;
        hasNext = !!res?.viewer?.availableGigs?.pageInfo.hasNextPage;
      }
      if (!skip) setPins(formatPins(gigs));
    };

    void exec();
    return () => {
      skip = true;
    };
  }, [fetchQuery, vars]);

  return pins;
};

const formatPins = (edges?: GigPinEdgeType[]): PinsType => {
  const features = edges?.reduce(
    (all, { node }) => {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (
        node?.approximateLocation?.latitude != null &&
        node?.approximateLocation?.longitude !== null
      ) {
        all.push({
          type: "Feature",
          properties: { id: node.id, seriesId: node.gigSeries?.id },
          geometry: {
            type: "Point",
            coordinates: [
              node?.approximateLocation?.longitude,
              node?.approximateLocation?.latitude
            ]
          }
        });
      }

      return all;
    },
    [] as PinsType["features"]
  );

  return {
    type: "FeatureCollection",
    features: features ?? []
  };
};
