/**
 * @generated SignedSource<<94bb54b1cca296510931bcba4636917f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementSummarySurface_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly gig: {
    readonly area: string | null | undefined;
    readonly organizationLocation: {
      readonly place: {
        readonly streetAddress: string | null | undefined;
      };
    } | null | undefined;
  };
  readonly insertedAt: string;
  readonly startsAt: string | null | undefined;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentType": "EngagementSummarySurface_engagement";
};
export type EngagementSummarySurface_engagement$key = {
  readonly " $data"?: EngagementSummarySurface_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"EngagementSummarySurface_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EngagementSummarySurface_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationLocation",
          "kind": "LinkedField",
          "name": "organizationLocation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Place",
              "kind": "LinkedField",
              "name": "place",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "streetAddress",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "676496e7983156f54bc7daae2018a06c";

export default node;
