import {
  UserMessage as AtlasUserMessage,
  SystemMessage,
  humanizeTime
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React from "react";
import type { ComponentProps } from "react";
import type { UserMessage_userMessage$key } from "./__generated__/UserMessage_userMessage.graphql";
import useAdminDeleteMessageConfirmationModal from "./useAdminDeleteMessageConfirmationModal";

interface Props {
  showName?: boolean;
  showDate?: boolean;
  context?: ComponentProps<typeof AtlasUserMessage>["context"];
  editing?: boolean;
}

export function UserMessage({
  id,
  attachments,
  author,
  insertedAt,
  body,
  showName = true,
  showDate = true,
  context,
  deletedAt,
  editing
}: FragmentContainerInnerComponentProps<UserMessage_userMessage$key, Props>) {
  const currentUser = useCurrentUser();
  const onDelete = useAdminDeleteMessageConfirmationModal(id);
  const user =
    currentUser?.__typename === "OrganizationRequester"
      ? currentUser?.requester
      : currentUser;
  if (author?.__typename === "SystemUser")
    return (
      <SystemMessage
        id={id}
        body={body}
        sentAt={humanizeTime({ startsAt: insertedAt, size: "sm" })}
      />
    );

  return (
    <AtlasUserMessage
      id={id}
      body={body}
      attachments={attachments}
      authorName={author?.displayName}
      authorPhotoUrl={author?.profilePhoto?.url}
      {...(author?.displayName === "GigSmart Support"
        ? {
            authorIcon: {
              name: "gigsmart",
              color: "orange",
              fade: true
            }
          }
        : {})}
      sentAt={humanizeTime({ startsAt: insertedAt, size: "sm" })}
      isSender={user?.id === author?.id}
      showName={showName}
      showDate={showDate}
      context={context}
      onDelete={editing ? onDelete : undefined}
      deletedAt={deletedAt}
    />
  );
}

export default createRelayFragmentContainer<UserMessage_userMessage$key, Props>(
  graphql`
    fragment UserMessage_userMessage on UserMessage {
      id
      body
      deletedAt
      insertedAt
      author {
        __typename
        id
        displayName
        profilePhoto {
          url
        }
      }
      attachments {
        id
        filename
        url
        mimeType
      }
    }
  `,
  UserMessage
);
