import React from "react";

import {
  Card,
  ContentArea,
  FancyHeader,
  GridNull,
  VideoPlayer
} from "@gigsmart/atorasu";
import { WOR_WELCOME_YT } from "@gigsmart/isomorphic-shared/constants";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";

export default function HomeVideoCard() {
  const [shown, setShown, isLoading] = useUserValue<boolean>(
    "WORKER_HOMESCREEN_GG_VIDEO"
  );
  if (shown || isLoading) return <GridNull />;

  return (
    <Card testID="home-video-card" eventTargetName="Home Video Card">
      <FancyHeader
        icon="film"
        testID="home-video-card"
        title="Get Gigs, How it Works"
        onDismiss={() => void setShown(true)}
      />
      <ContentArea>
        <VideoPlayer source="youtube" videoId={WOR_WELCOME_YT} />
      </ContentArea>
    </Card>
  );
}
