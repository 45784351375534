/**
 * @generated SignedSource<<16c713fcc583ac8f45fcfa3122c7136a>>
 * @relayHash b2cee7b054a718da711e0551cb4d8106
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:xno7cziQbQuylGwGnlN4exC-i9T5babv0H6djGGCqwA

import { ConcreteRequest } from 'relay-runtime';
export type UserRestrictionActionType = "CONFIDENCE" | "SEND_MESSAGES" | "VIEW_GIGS" | "VIEW_JOB_POSTINGS" | "WORK_GIGS" | "%future added value";
export type UserRestrictionsQuery$variables = {
  query: string;
};
export type UserRestrictionsQuery$data = {
  readonly viewer: {
    readonly restrictions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly restrictedActions: ReadonlyArray<{
            readonly type: UserRestrictionActionType;
          }> | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type UserRestrictionsQuery = {
  response: UserRestrictionsQuery$data;
  variables: UserRestrictionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RestrictedUserAction",
  "kind": "LinkedField",
  "name": "restrictedActions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRestrictionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserRestrictionsConnection",
            "kind": "LinkedField",
            "name": "restrictions",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRestrictionsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserRestriction",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserRestrictionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserRestrictionsConnection",
            "kind": "LinkedField",
            "name": "restrictions",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRestrictionsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserRestriction",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:xno7cziQbQuylGwGnlN4exC-i9T5babv0H6djGGCqwA",
    "metadata": {},
    "name": "UserRestrictionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f496e1b340241f6576f4a406014a21d1";

export default node;
