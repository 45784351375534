/**
 * @generated SignedSource<<c75a7f116c069e29283feb704d453ad0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HelpfulToDoList_engagement$data = {
  readonly checklist: ReadonlyArray<{
    readonly acknowledged: boolean;
    readonly id: string;
    readonly summary: string;
  } | null | undefined> | null | undefined;
  readonly gig: {
    readonly paymentType: PaymentType | null | undefined;
  };
  readonly " $fragmentType": "HelpfulToDoList_engagement";
};
export type HelpfulToDoList_engagement$key = {
  readonly " $data"?: HelpfulToDoList_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"HelpfulToDoList_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HelpfulToDoList_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementChecklistItem",
      "kind": "LinkedField",
      "name": "checklist",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "acknowledged",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "052b7667efbee13799429c3f7eca4724";

export default node;
