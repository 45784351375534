import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import { type CurrentUserShape, useCurrentUser } from "./current-user";

export interface WithCurrentUserProps {
  user?: CurrentUserShape | null | undefined;
}

const withCurrentUser: HOCVoid = (
  WrappedComponent: ComponentType<WithCurrentUserProps>
) =>
  applyHOCProperties({
    WrappedComponent,
    displayName: "withUser",
    HigherOrderComponent: (props) => {
      const user = useCurrentUser();
      return <WrappedComponent {...props} user={user} />;
    }
  });

export default withCurrentUser;
