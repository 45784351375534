import React from "react";
import AddonModalContent from "./addon-modal-content";
export default function EorWorkersModalContent() {
  return (
    <AddonModalContent
      introText="W-2 Workers"
      listHeader="W-2 Workers, work on GigSmart and employeed through a mutaul agreement with your company and Acsen."
      learnMoreText="Learn more about W-2 Workers"
      learnMoreLink="https://gigsmart.com/w2"
      listItems={[]}
    />
  );
}
