import ActionSheet from "./action-sheet";
import ActionSheetModal from "./action-sheet-modal";

export interface ActionSheetParams {
  cancelButtonIndex: number;
  options: string[];
  onPress: (arg0: number) => any;
}

export { ActionSheet, ActionSheetModal };
