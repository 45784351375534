import React from "react";

import { ContentArea, IconText, Text } from "@gigsmart/atorasu";

export default function SecureNotice() {
  return (
    <ContentArea alignItems="center">
      <IconText
        icon="lock-keyhole"
        iconSize="tiny"
        iconVariant="solid"
        iconColor="neutral"
        textVariant="note"
        spacing="medium"
      >
        <Text color="neutral" variant="subheader" weight="normal">
          Personal information is stored securely.
        </Text>
      </IconText>
    </ContentArea>
  );
}
