import range from "lodash/range";
import React from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../../style";

interface Props {
  style?: ViewStyleProp;
  count: number;
  activeIndex: number;
}

const SwipeCarouselIndicator = ({ style, count, activeIndex }: Props) => {
  const { styles } = useStyles(({ color }) => ({
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    dot: {
      margin: 10,
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: color.neutralDark
    },
    dotActive: {
      backgroundColor: color.blue
    }
  }));

  return (
    <View style={[styles.container, style]}>
      {range(count).map((idx) => (
        <View
          key={`${idx}`}
          style={[styles.dot, activeIndex === idx && styles.dotActive]}
        />
      ))}
    </View>
  );
};

export default SwipeCarouselIndicator;
