import { useStepper } from "@gigsmart/atorasu";
import { NavPortalEntrance, defaultBackHandler } from "@gigsmart/kaizoku";
import { NavHeaderButton, confirmPrompt, dismiss } from "@gigsmart/katana";
import React from "react";
import type { DirectHireClaimsStepper } from "./index";

interface Props {
  returnTo?: string;
  dirty?: boolean;
}

export default function Nav({ returnTo, dirty }: Props) {
  const { gotoStep, stepsData, prevStep } = useStepper<
    {},
    DirectHireClaimsStepper
  >();
  const handleBackPress = () => {
    const exec = () => {
      returnTo
        ? returnTo === "back"
          ? defaultBackHandler()
          : gotoStep(stepsData?.returnTo ?? returnTo, {
              ...stepsData,
              returnTo: null
            })
        : prevStep();
    };

    if (dirty) {
      confirmPrompt({
        title: "Are you sure you want to go back?",
        subTitle: "Your information will not be saved.",
        yesLabel: "Yes",
        cancelLabel: "No",
        onDo: exec
      });
    } else {
      exec();
    }
    return true;
  };

  return (
    <NavPortalEntrance
      onBackPress={handleBackPress}
      rightAccessory={() => (
        <NavHeaderButton
          title="Exit"
          onPress={() =>
            confirmPrompt({
              title: `Cancel your Direct ${
                stepsData?.directHireClaimType === "HIRE" ? "Hire" : "Payment"
              } Request`,
              subTitle: `Are you sure you want to Exit & Cancel your Direct ${
                stepsData?.directHireClaimType === "HIRE" ? "Hire" : "Payment"
              } Bonus Request?`,
              yesLabel: "Exit & Cancel",
              onDo: () => defaultBackHandler({ safeExit: true }),
              cancelLabel: "Do not Exit",
              onCancel: dismiss
            })
          }
        />
      )}
    />
  );
}
