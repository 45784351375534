/**
 * @generated SignedSource<<9db42394cf88d551ddb38bec853a5a77>>
 * @relayHash dd2b5899bc952001f7bdba7d086f276f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:FxrRsBF7eZlV7TGvyuQ4K_ztGT29yJr9Y51P493BcZo

import { ConcreteRequest } from 'relay-runtime';
export type SubmitSupportTicketInput = {
  description: string;
  emailAddress?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  subject: string;
};
export type SupportScreenMutation$variables = {
  input: SubmitSupportTicketInput;
};
export type SupportScreenMutation$data = {
  readonly submitSupportTicket: {
    readonly message: string;
  } | null | undefined;
};
export type SupportScreenMutation = {
  response: SupportScreenMutation$data;
  variables: SupportScreenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitSupportTicketPayload",
    "kind": "LinkedField",
    "name": "submitSupportTicket",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportScreenMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupportScreenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:FxrRsBF7eZlV7TGvyuQ4K_ztGT29yJr9Y51P493BcZo",
    "metadata": {},
    "name": "SupportScreenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "15d969de47362725b3deca9d80953c21";

export default node;
