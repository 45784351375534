import { Button, ContentArea, Row, Stack, Tag, Text } from "@gigsmart/atorasu";
import { showShiftGigInfoModal } from "@gigsmart/seibutsu/modals/ShiftGigsInfoModalTabs";
import React from "react";

export default function GigConfirming() {
  return (
    <ContentArea size="none" color="surface">
      <Row justifyContent="space-between" alignItems="center">
        <Stack horizontal alignItems="center">
          <Tag
            icon="circle-exclamation"
            color="purple"
            label="Confirming"
            variant="clear"
          />
          <Text>You are not hired yet.</Text>
        </Stack>
        <Button
          testID="about-btn"
          label="About"
          icon="circle-exclamation"
          variant="clear"
          onPress={() => showShiftGigInfoModal({ defaultTab: "STATUSES" })}
        />
      </Row>
    </ContentArea>
  );
}
