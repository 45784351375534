import React, { type ReactElement } from "react";

import PhoneModal from "./phone-modal";
import usePhoneCall, { type UsePhoneCallOptions } from "./use-phone-call";

type RenderCallElement = (options: {
  isCallable: boolean;
  onPress: () => void;
}) => ReactElement;

type Props = UsePhoneCallOptions & {
  children: RenderCallElement;
  calleePhoneNumber?: string | null;
  callerPhoneNumber?: string | null;
  counterpartName?: string | null;
};

const PhoneAction = ({
  counterpartName,
  calleePhoneNumber,
  callerPhoneNumber,
  children,
  ...props
}: Props) => {
  const { onPhoneCallPress, phoneModalVisible, closePhoneModal, isCallable } =
    usePhoneCall(props);

  return (
    <>
      {children({ isCallable, onPress: onPhoneCallPress })}
      <PhoneModal
        usersName={counterpartName}
        userType={props.isWorker ? "Requester" : "Worker"}
        calleePhoneNumber={calleePhoneNumber}
        callerPhoneNumber={callerPhoneNumber}
        showModal={phoneModalVisible}
        hideModal={closePhoneModal}
      />
    </>
  );
};

export default PhoneAction;
