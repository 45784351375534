import React, { useMemo } from "react";

import {
  ContentArea,
  ContentRow,
  Divider,
  IconText,
  Stack,
  Surface,
  Tag,
  Text,
  TooltipButton
} from "@gigsmart/atorasu";
import { duration } from "@gigsmart/isomorphic-shared/iso";

import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { showMileageTrackingInfoModal } from "../modals/MileageTrackingInfoModal";

interface Props {
  gigEstimatedMileage?: number | null;
  timesheetEstimatedMileage?: number | null;
  gigDuration?: string | null;
  variant?: "worker" | "requester";
  final?: boolean;
}

export default function EngagementTimesheetMileageBox({
  gigEstimatedMileage,
  gigDuration,
  timesheetEstimatedMileage,
  variant = "requester",
  final
}: Props) {
  const mileageValid = useMemo(() => {
    if (gigDuration === "PT0S" && timesheetEstimatedMileage === 0) return true;
    if (!timesheetEstimatedMileage) return false;
    const durationInHours = duration.toHours(gigDuration);
    const mph = timesheetEstimatedMileage / durationInHours;
    return mph <= 75;
  }, [timesheetEstimatedMileage, gigDuration]);

  if (!gigEstimatedMileage) return null;

  return (
    <Surface variant="outline">
      <ContentArea size="compact">
        <Stack horizontal size="compact">
          <IconText
            size="small"
            textWeight="bold"
            icon="truck-fast"
            color="primary"
          >
            Travel / Delivery Mileage
          </IconText>
          <TooltipButton
            testID="mileage-tracking-modal-btn"
            onPress={() => showMileageTrackingInfoModal(variant)}
          />
        </Stack>
      </ContentArea>
      <Divider />
      <ContentRow justifyContent="space-between" alignItems="center">
        <Stack horizontal alignItems="center" size="compact">
          <Text>Total Mileage</Text>
          {mileageValid &&
            gigEstimatedMileage !== timesheetEstimatedMileage &&
            !final && <Tag label="Adjusted" />}
        </Stack>
        {mileageValid ? (
          <Text>{pluralize(timesheetEstimatedMileage ?? 0, "miles")}</Text>
        ) : (
          <IconText color="danger" icon="triangle-exclamation" size="small">
            Please Provide Mileage
          </IconText>
        )}
      </ContentRow>
    </Surface>
  );
}
