import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import Requester from "../requester/requester";
import type { requesterSummaryScreenQuery } from "./__generated__/requesterSummaryScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "BrowseGigRequester">;

export default createSuspendedQueryContainer<
  requesterSummaryScreenQuery,
  Props
>(
  function RequesterSummaryScreen({ route, navigation, response: result }) {
    return (
      <Requester
        requesterId={result?.node?.requester?.id ?? ""}
        gigId={route.params.id}
        navigation={navigation}
      />
    );
  },
  {
    query: graphql`
      query requesterSummaryScreenQuery($id: ID!) {
        node(id: $id) {
          ... on GigLike {
            requester {
              id
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
