/**
 * @generated SignedSource<<b7b93fc78a1e28e84cf1e752822931da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkHistoryCard_worker$data = {
  readonly experience: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employer: string;
        readonly employmentEnd: string | null | undefined;
        readonly employmentStart: string;
        readonly id: string;
        readonly positionTitle: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WorkHistoryCard_worker";
};
export type WorkHistoryCard_worker$key = {
  readonly " $data"?: WorkHistoryCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkHistoryCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkHistoryCard_worker",
  "selections": [
    {
      "alias": "experience",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC"
        }
      ],
      "concreteType": "WorkerWorkHistoryConnection",
      "kind": "LinkedField",
      "name": "workHistory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerWorkHistoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerWorkHistory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "employer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "employmentStart",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "employmentEnd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "positionTitle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "workHistory(first:50,query:\"ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "a28e8527172d61006d44b9c49e261a32";

export default node;
