import React, { type ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import type { ViewStyleProp } from "../style";

interface Props {
  children: ReactNode;
  noTouch: boolean;
  style?: ViewStyleProp;
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%"
  }
});

export default function Floating({ children, noTouch, style }: Props) {
  return (
    <View
      pointerEvents={noTouch ? "none" : "auto"}
      style={[styles.container, style]}
    >
      {children}
    </View>
  );
}

Floating.defaultProps = {
  noTouch: false
};
