import { toast } from "@gigsmart/atorasu";
import type { IconName } from "@gigsmart/atorasu";
import type { FomuSubmitFn } from "@gigsmart/fomu";
import * as stripe from "@gigsmart/isomorphic-payments";
import {
  type CustomSubmitButtonProps,
  PaymentScreen as PaymentForm,
  paymentMethodToFormData
} from "@gigsmart/isomorphic-shared";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import moment from "moment-timezone";
import React, { type ReactElement, useCallback, useMemo } from "react";

import BankAccountFields from "./BankAccountFields";
import DebitCardFields from "./DebitCardFields";

import type {
  StripeBankAccountInput,
  StripeCardInput
} from "@gigsmart/isomorphic-payments";
import type { paymentWorkerMutation } from "./__generated__/paymentWorkerMutation.graphql";
import type { paymentWorkerQuery } from "./__generated__/paymentWorkerQuery.graphql";

interface Props {
  scrollEnabled?: boolean;
  onSave?: () => void | Promise<void>;
  headerText?: string;
  buttonText?: string;
  disableBankAccount?: boolean | null | undefined;
  disableBankAccountToast?: string;
  allowContinueWithoutSaving?: boolean;
  customSubmitRenderer?: (props: CustomSubmitButtonProps) => ReactElement;
}

const accountComponents = {
  BankAccount: BankAccountFields,
  BankCard: DebitCardFields
};

export const PaymentScreen = createSuspendedQueryContainer<
  paymentWorkerQuery,
  Props
>(
  function PaymentScreen({
    response,
    onSave = () => {
      toast.success("Changes Saved");
    },
    headerText = "Where would you like to receive your earnings?",
    buttonText = "Submit & Continue",
    disableBankAccount,
    allowContinueWithoutSaving,
    customSubmitRenderer,
    scrollEnabled,
    disableBankAccountToast
  }) {
    const initialValues = useMemo(() => {
      const payableAccount = response?.viewer?.payableAccount?.paymentMethod;
      const type = disableBankAccount
        ? "BankCard"
        : payableAccount?.type ?? "BankCard";
      return {
        type,
        ...paymentMethodToFormData(payableAccount)
      };
    }, [response, disableBankAccount]);

    const renderTerms = useMemo(() => {
      const { viewer } = response ?? {};
      const { payableAccount } = viewer ?? {};

      return !payableAccount;
    }, [response]);

    const [commit] = useRelayMutation<paymentWorkerMutation>(graphql`
      mutation paymentWorkerMutation($input: SetWorkerPayableAccountInput!) {
        setWorkerPayableAccount(input: $input) {
          worker {
            accessState
          }
          workerPayableAccount {
            paymentMethod {
              accountHolderName
              type: __typename
              last4
              ... on BankCard {
                expirationMonth
                expirationYear
                postalCode
              }
              ... on BankAccount {
                routingNumber
              }
            }
          }
        }
      }
    `);

    const getToken = useCallback(
      async ({
        type,
        BankAccount: bankAccount,
        BankCard: { expiration, ...card }
      }: {
        type: "BankAccount" | "BankCard";
        BankAccount: StripeBankAccountInput;
        BankCard: StripeCardInput & { expiration: string };
      }) => {
        const expirationDate = moment(expiration, "MM/YY");

        switch (type) {
          case "BankAccount":
            return await stripe.createTokenWithBankAccount({
              ...bankAccount,
              country: "US",
              currency: "USD"
            });
          case "BankCard":
            return await stripe.createTokenWithCard({
              ...card,
              currency: "USD",
              expMonth: Number(expirationDate.format("MM")),
              expYear: expirationDate.year()
            });
          default:
            return await Promise.resolve({ id: "" });
        }
      },
      []
    );

    const handleSuccess = useCallback(() => {
      void onSave();
    }, [onSave]);

    const handleErrors = useCallback((err: Error) => {
      toast.error(err.message || "Something Went Wrong");
    }, []);

    const handleSubmit = useCallback<FomuSubmitFn>(
      async (input, done) => {
        try {
          const { id: token } = await getToken(input.values as never);
          commit(
            { input: { token } },
            {
              onSuccess: () => {
                done();
                handleSuccess();
              },
              onError: (error) => {
                done();
                handleErrors(error || new Error());
              }
            }
          );
        } catch (err) {
          done();
          handleErrors(err || {});
        }
      },
      [commit, getToken, handleErrors, handleSuccess]
    );

    const getIcons = useCallback(() => {
      return [
        {
          icon: "credit-card" as IconName,
          id: "BankCard",
          label: "Debit Card"
        },
        {
          icon: "building-columns" as IconName,
          id: "BankAccount",
          label: "Bank Account",
          disabled: !!disableBankAccount
        }
      ];
    }, [disableBankAccount, disableBankAccountToast]);

    const showContinueWithoutUpdate =
      allowContinueWithoutSaving &&
      !!response?.viewer?.payableAccount?.paymentMethod?.type;
    return (
      <PaymentForm
        scrollEnabled={scrollEnabled}
        headerText={headerText}
        buttonText={
          showContinueWithoutUpdate ? "Update & Continue" : buttonText
        }
        onSubmit={handleSubmit}
        initialValues={initialValues}
        renderTerms={renderTerms}
        icons={getIcons()}
        accountComponents={accountComponents}
        allowContinueWithoutSave={showContinueWithoutUpdate}
        onContinueWithoutSave={onSave}
        customSubmitRenderer={customSubmitRenderer}
      />
    );
  },
  {
    query: graphql`
      query paymentWorkerQuery {
        viewer {
          ... on Worker {
            payableAccount {
              paymentMethod {
                accountHolderName
                type: __typename
                last4
                ... on BankCard {
                  expirationMonth
                  expirationYear
                  postalCode
                }
                ... on BankAccount {
                  routingNumber
                }
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);
