import {
  ContentArea,
  FooterContent,
  ScreenScroll,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form } from "@gigsmart/fomu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import SetPaymentMethodField from "@gigsmart/seibutsu/payment/SetPaymentMethodField";
import { useEditWorkerPayableAccount } from "@gigsmart/seibutsu/payment/workerAccount";
import BankingAdCard from "@gigsmart/seibutsu/worker/BankingAdCard";
import React from "react";
import type { WorkerParamList } from "../navigation/types";

type Props = AppScreenProps<WorkerParamList, "AddPaymentMethod">;

export default function AddPaymentMethodScreen({ navigation }: Props) {
  const editWorkerAccount = useEditWorkerPayableAccount();
  const handleSubmit: FomuSubmitFn = async ({ values }, done) => {
    await editWorkerAccount(values as any, done);
    done();

    navigation.goBack();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ScreenScroll
        constraint="large"
        testID="add-payment-method-screen"
        color="surface"
        footer={
          <FooterContent bgColor="surface">
            <FomuSubmit
              testID="add-payment-method-submit-btn"
              label="Verify and Save"
            />
          </FooterContent>
        }
      >
        <ContentArea>
          <Stack size="compact">
            <Text variant="header" color="primary">
              Where would you like to receive your earnings?
            </Text>
            <Text>
              All Shift Gig payments are made through the Get Gigs app. Add a
              debit card or bank account to transfer your balance.
            </Text>
          </Stack>
          <Spacer size="large" />
          <Stack>
            <BankingAdCard screen="WALLET" />
            <SetPaymentMethodField />
          </Stack>
        </ContentArea>
      </ScreenScroll>
    </Form>
  );
}
