import React, { useMemo } from "react";

import Flex, { type Props as FlexProps } from "../flex/flex";
import { useStyles } from "../style";

type Props = Omit<FlexProps, "fill"> & {
  children?: React.ReactNode;
  padding?: number;
  fill?: boolean | number;
  shadow?: boolean;
  maxWidth?: number | string;
  width?: number | string;
  verticalPadding?: number;
  paddingBottom?: number;
  horizontalPadding?: number;
};

export default function StyledCard({
  children,
  padding,
  fill,
  maxWidth,
  width,
  verticalPadding,
  paddingBottom,
  horizontalPadding,
  style,
  shadow,
  ...flexProps
}: Props) {
  const { styles, theme } = useStyles(
    ({ color, namedShadows, unit, units }) => ({
      card: {
        ...namedShadows.card1,
        padding: units(4),
        borderRadius: unit.borderRadius,
        backgroundColor: color.white
      }
    })
  );
  const cardStyles = useMemo(() => {
    return [
      styles.card,
      fill ? { flex: fill === true ? 1 : fill } : null,
      padding || padding === 0 ? { padding: theme.units(padding) } : null,
      width ? { width } : null,
      maxWidth
        ? {
            maxWidth:
              typeof maxWidth === "number" ? theme.units(maxWidth) : maxWidth
          }
        : null,
      verticalPadding || verticalPadding === 0
        ? { paddingVertical: theme.units(verticalPadding) }
        : null,
      horizontalPadding || horizontalPadding === 0
        ? { paddingHorizontal: theme.units(horizontalPadding) }
        : null,
      paddingBottom || paddingBottom === 0
        ? { paddingBottom: theme.units(paddingBottom) }
        : null,
      style
    ];
  }, [
    padding,
    fill,
    width,
    maxWidth,
    verticalPadding,
    horizontalPadding,
    paddingBottom
  ]);
  return (
    <Flex style={cardStyles} {...flexProps}>
      {children}
    </Flex>
  );
}
