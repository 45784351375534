import { FooterSpacer, Icon, Spacer, Stack, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { ListSeparator, SmallOutlineButton } from "@gigsmart/katana";
import React from "react";
import { View } from "react-native";
import type { WorkerParamList } from "../../navigation/types";

const ProjectGigsListFooter = () => {
  const navigation = useNavigation<AppNavigationProp<WorkerParamList>>();
  const styles = useStyles(({ getColor, getUnits }) => ({
    sep: { backgroundColor: getColor("black", "fill", { opacity: 0.08 }) },
    container: {
      alignItems: "center",
      paddingTop: getUnits(6),
      paddingHorizontal: getUnits(4)
    }
  }));

  return (
    <>
      <Spacer size="large" />
      <ListSeparator style={styles.sep} />
      <View style={styles.container}>
        <Icon name="pen-ruler" size="default" color="primary" />
        <Text weight="bold" color="primary">
          Interested in more opportunities?
        </Text>
        <Spacer size="medium" />
        <Stack horizontal>
          <SmallOutlineButton
            title="View Job Board"
            onPress={() => navigation.push("BrowseJobs")}
          />
          <SmallOutlineButton
            title="View Shift Gigs"
            onPress={() => navigation.push("BrowseShifts", { type: "shifts" })}
          />
        </Stack>
      </View>
      <FooterSpacer />
    </>
  );
};

export default ProjectGigsListFooter;
