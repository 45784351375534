import React from "react";

import {
  Button,
  Column,
  IconCircle,
  type IconName,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useHistory } from "@gigsmart/kaizoku";
import { StyledModal } from "@gigsmart/katana";

interface Props {
  variant: "shift" | "project" | "job";
  showModal: boolean;
  onClose: () => void;
}

const VARIANT_DETAILS = {
  project: {
    eventContext: "Project Not Available Modal",
    title: "Sorry, this Project Gig is no longer available.",
    subtitle: "Find more work opportunities in your area using the Browse tab.",
    icon: "message-exclamation",
    buttonLabel: "Browse Gigs & Jobs",
    buttonIcon: "search",
    route: "browse"
  },
  shift: {
    eventContext: "Shift Not Available Modal",
    title: "Sorry, this Shift Gig is no longer available.",
    subtitle: "Find more work opportunities in your area using the Browse tab.",
    icon: "message-exclamation",
    buttonLabel: "Browse Gigs & Jobs",
    buttonIcon: "search",
    route: "browse"
  },
  job: {
    eventContext: "Job Post Not Available Modal",
    title: "Sorry, this Job Post is no longer available.",
    subtitle:
      "This Job Post is no longer available. View more Job Postings in your area by visiting the GigSmart Job Board.",
    icon: "message-exclamation",
    buttonLabel: "View Job Board",
    buttonIcon: "newspaper",
    route: "jobs"
  }
};

export default function GigNotAvailable({
  variant,
  showModal,
  onClose
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    modal: {
      flex: 1,
      paddingBottom: getUnits(8)
    }
  }));
  const history = useHistory();
  const handlePress = () => {
    onClose();
    setTimeout(() => history.push(VARIANT_DETAILS[variant].route), 0);
  };
  return (
    <StyledModal
      eventContext={VARIANT_DETAILS[variant].eventContext}
      full
      animationType="slide"
      visible={!!showModal}
      hasCancelIcon
      onClose={onClose}
      childrenStyle={styles.modal}
    >
      <Column fill={1} justifyContent="flex-end" alignItems="center">
        <Column fill={1} justifyContent="center" alignItems="center">
          <IconCircle
            icon="message-exclamation"
            color="neutral"
            size="large"
            variant="well"
          />
        </Column>
        <Column fill={1}>
          <Stack>
            <Text variant="header" color="primary" align="center">
              {VARIANT_DETAILS[variant].title}
            </Text>
            <Text align="center">{VARIANT_DETAILS[variant].subtitle}</Text>
          </Stack>
        </Column>
        <Button
          fullWidth
          testID="browse-btn"
          label={VARIANT_DETAILS[variant].buttonLabel}
          icon={VARIANT_DETAILS[variant].buttonIcon as IconName}
          onPress={handlePress}
        />
      </Column>
    </StyledModal>
  );
}
