import React, { type ReactNode, useMemo } from "react";

import TabItemList, { type TabItemProps } from "./tab-item-list";
import type { TabConfig } from "./types";

export type TabLayoutProps<
  K extends string,
  T extends TabConfig<K>
> = TabItemProps<K, T> & {
  renderContent?: (tab: T) => ReactNode;
  tabItemsHideSingle?: boolean;
};

export const renderTabContent = (tab?: TabConfig<any>) => {
  if (tab && !tab.disabled) {
    const { component: TabComponent, renderContent } = tab;
    if (TabComponent) return <TabComponent key={`content-${tab.key}`} />;
    if (renderContent) return renderContent(tab);
  }

  return null;
};

const TabLayout = <K extends string, T extends TabConfig<K> = TabConfig<K>>({
  active,
  tabs,
  tabItemsHideSingle,
  renderContent = renderTabContent,
  ...props
}: TabLayoutProps<K, T>) => {
  const filteredTabs = useMemo(() => tabs.filter((t) => !t.disabled), [tabs]);
  const activeTab = active
    ? filteredTabs.find((t) => t.key === active)
    : filteredTabs[0];

  const content = activeTab ? renderContent(activeTab) : null;
  if (!activeTab) console.warn(`Tab "${active}" not found`);

  return (
    <>
      {tabItemsHideSingle && filteredTabs.length <= 1 ? null : (
        <TabItemList active={activeTab?.key} tabs={filteredTabs} {...props} />
      )}
      {content}
    </>
  );
};

export default TabLayout;
