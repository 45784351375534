import {
  Button,
  Card,
  ContentArea,
  Divider,
  RadioButtonGroup,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { useFormField, useFormValue } from "@gigsmart/fomu";
import type { DateTime } from "luxon";
import React, { Fragment } from "react";

import EditTimesheetBreak from "./EditTimesheetBreak";

interface Props {
  breaks: number[];
  setBreaks: (val: number[]) => void;
  volunteer: boolean;
  initialValues: Record<string, any>;
  timezone: string;
  variant?: "worker" | "requester";
  minimumPaidGigDuration?: string | null;
}

const BREAK_OPTIONS = [
  {
    title: "No",
    value: false,
    testID: "yes",
    eventTargetName: "Dont Include Breaks Radio"
  },
  {
    title: "Yes",
    value: true,
    testID: "no",
    eventTargetName: "Include Breaks Radio"
  }
];

export default function EditTimesheetBreaks({
  breaks,
  setBreaks,
  volunteer,
  initialValues,
  timezone,
  variant = "requester",
  minimumPaidGigDuration
}: Props) {
  const { value: includeBreaks, setValue: setIncludeBreaks } =
    useFormField<boolean>({ name: "includeBreaks" });
  const { value: startTime } = useFormValue<DateTime>({
    name: "startTimeDate"
  });
  const { value: endTime } = useFormValue<DateTime>({
    name: "endTimeDate"
  });
  return (
    <Card
      testID="edit-timesheet-breaks-card"
      eventTargetName="Edit Timesheet Breaks Card"
    >
      <ContentArea>
        <Text weight="bold">
          {variant === "requester"
            ? `Were there any ${volunteer ? "" : "unpaid"} breaks?`
            : "Did you take any unpaid breaks?"}
        </Text>
        <Spacer />
        <RadioButtonGroup
          value={includeBreaks}
          onChange={(value) => {
            if (breaks.length === 0) setBreaks([breaks.length]);
            setIncludeBreaks(value);
          }}
          buttons={BREAK_OPTIONS}
        />
        <Divider />
        {includeBreaks && (
          <>
            {breaks.map((breakNumber, index) => (
              <Fragment key={breakNumber}>
                <Spacer size="compact" />
                <EditTimesheetBreak
                  key={breakNumber}
                  index={index}
                  breakNumber={breakNumber}
                  gigStart={startTime}
                  gigEnd={endTime}
                  minimumPaidGigDuration={minimumPaidGigDuration}
                  removeBreak={() => {
                    if (breaks.length === 1) {
                      setIncludeBreaks(false);
                    } else {
                      setBreaks([...breaks.filter((br) => br !== breakNumber)]);
                    }
                  }}
                  breaks={breaks}
                  initialValues={initialValues}
                  timezone={timezone}
                />
                <Divider />
              </Fragment>
            ))}
            <Button
              testID="edit-timesheet-add-break"
              variant="clear"
              label="Add Another Break"
              icon="plus"
              onPress={() => {
                const lastBreak = breaks[breaks.length - 1] ?? 0;
                setBreaks([...breaks, lastBreak + 1]);
              }}
            />
          </>
        )}
      </ContentArea>
    </Card>
  );
}
