import React from "react";

import { ActionRow, type IconName, Row, Text } from "@gigsmart/atorasu";

interface Props {
  icon: IconName;
  title: string;
  selectedItems: number;
  availableItems: number;
  onPress: () => void;
}

export default function QualificationCategoryRow({
  icon,
  title,
  selectedItems = 0,
  availableItems = 0,
  onPress
}: Props) {
  return (
    <ActionRow
      title={title}
      icon={icon}
      testID={`${title}-qcr`}
      onPress={onPress}
      variant="outline"
    >
      <Row>
        <Text
          variant="note"
          color={selectedItems > 0 ? "success" : "black"}
          testID="selected-item-text-qcr"
        >
          {selectedItems} Selected
        </Text>
        <Text variant="note" color="neutral">
          {" "}
          {"\u2022"}{" "}
        </Text>
        <Text variant="note" color="neutral">
          {availableItems} Available
        </Text>
      </Row>
    </ActionRow>
  );
}
