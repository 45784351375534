import React, { PureComponent } from "react";
import { View } from "react-native";
import { type IconName, type IconVariant, StyledIcon } from "../icon";
import { StyledText } from "../text";

import Stack from "../container/stack";
import {
  type StylesProps,
  type ViewStyleProp,
  createStyles,
  stylesStubs
} from "../style";

export type TagProps = StylesProps & {
  style?: ViewStyleProp;
  testID?: string;
  labelStyle?: ViewStyleProp;
  color?: string;
  title: string;
  primary?: boolean;
  secondary?: boolean;
  textColor?: string;
  iconName?: IconName;
  variant?: IconVariant;
  iconColor?: string;
  size?: number;
  noFlex?: boolean;
};

export const TAG_HEIGHT = 20;

const styles = createStyles(({ color, font }) => ({
  container: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: TAG_HEIGHT,
    borderRadius: 4,
    marginRight: 8,
    paddingHorizontal: 8,
    flexDirection: "row",
    alignSelf: "flex-end"
  },
  flexDisplay: {
    display: "flex"
  },
  label: {
    fontSize: font.size.small,
    color: color.white,
    textAlign: "center",
    fontWeight: font.weight.semibold
  },
  primary: {
    backgroundColor: color.neutralDark
  },
  primaryText: {
    color: color.white
  },

  secondary: {
    backgroundColor: color.transparent,
    borderColor: color.neutralDark,
    borderWidth: 1
  },
  secondaryText: {
    color: color.neutralDark
  },
  icon: {
    minWidth: 12
  }
}));

export default class Tag extends PureComponent<TagProps> {
  static defaultProps = {
    labelStyle: {},
    primary: true,
    size: 12,
    ...stylesStubs
  };

  get tagStyle() {
    const { color, primary, secondary, theme } = this.props;
    const tagStyle = [];
    if (primary && !secondary) {
      tagStyle.push(styles.primary);
    }
    if (secondary) {
      tagStyle.push(styles.secondary);
    }
    if (typeof color === "string" && !secondary) {
      tagStyle.push({ backgroundColor: theme.color.getColor(color) });
    }

    return tagStyle;
  }

  get textStyle() {
    const { primary, secondary, textColor, theme } = this.props;
    const textStyle = [];
    if (primary && !secondary) {
      textStyle.push(styles.primaryText);
    }
    if (secondary) {
      textStyle.push(styles.secondaryText);
    }
    if (typeof textColor === "string") {
      textStyle.push({ color: theme.color.getColor(textColor) });
    }

    return textStyle.length > 0 ? textStyle : undefined;
  }

  get iconStyle() {
    const { primary, secondary } = this.props;
    const iconStyle = [];
    if (primary && !secondary) {
      iconStyle.push(styles.primaryText);
    }
    if (secondary) {
      iconStyle.push(styles.secondaryText);
    }

    return iconStyle.length > 0 ? iconStyle : undefined;
  }

  render() {
    const {
      style,
      testID,
      labelStyle,
      iconName,
      variant,
      title,
      size,
      iconColor,
      noFlex
    } = this.props;
    const labelStyles = [styles.label, this.textStyle, labelStyle];
    const containerStyles = [
      styles.container,
      this.tagStyle,
      !noFlex && styles.flexDisplay,
      style
    ];
    const iconStyles = [styles.icon, this.iconStyle];

    return (
      <View style={containerStyles} testID={testID}>
        <Stack direction="row" spacing={1} alignItems="center">
          {typeof iconName === "string" && (
            <StyledIcon
              name={iconName}
              style={iconStyles}
              variant={variant}
              size={size}
              color={iconColor}
            />
          )}
          <StyledText
            numberOfLines={1}
            allowFontScaling={false}
            style={labelStyles}
          >
            {title}
          </StyledText>
        </Stack>
      </View>
    );
  }
}
