import { compact, startCase } from "lodash";
/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef } from "react";

import { IconButton, Row, Stack, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { type FormFieldOptions, Validator, useFormField } from "@gigsmart/fomu";
import { StyledTextInput, confirmPrompt, dismiss } from "@gigsmart/katana";

export interface Props extends FormFieldOptions<string> {
  required?: boolean;
  displayAdditionalText?: boolean;
  removeable?: boolean;
  onRemove?: () => void;
  label?: string;
  placeholder?: string;
  max?: number;
  min?: number;
  minMessage?: string;
  removePrompt?: {
    title: string;
    subTitle: string;
    yesLabel?: string;
    cancelLabel?: string;
  };
}

export type ForwardRefProps =
  | {
      destroy: () => void;
    }
  | null
  | undefined;

export default forwardRef<ForwardRefProps, Props>(
  (
    {
      onRemove,
      removeable,
      name,
      validates,
      label,
      placeholder,
      required,
      displayAdditionalText = true,
      max,
      min,
      minMessage,
      removePrompt,
      shouldShowErrors
    }: Props,
    ref
  ) => {
    const styles = useStyles(({ getUnits }) => ({
      // temporary until we put inputs in Atorasu
      input: {
        height: getUnits(29)
      }
    }));
    const { value, setValue, removeField, errors, showErrors } =
      useFormField<string>({
        name,
        validates: compact([
          max &&
            Validator.length({
              max,
              message: `${startCase(name)} cannot exceed ${max} characters`
            }),
          !!(min ?? required) &&
            Validator.length({
              min: min ?? 1,
              message: min
                ? minMessage ??
                  `${startCase(name)} must be at least ${min} characters`
                : "Cannot be empty"
            }),
          required && Validator.presence(),
          ...(Array.isArray(validates)
            ? validates
            : validates
              ? [validates]
              : [])
        ]),
        shouldShowErrors
      });

    const handleRemove = () => {
      if (!removePrompt || !value || value?.length === 0) {
        confirmRemove();
        return;
      }
      confirmPrompt({
        ...removePrompt,
        onDo: () => {
          confirmRemove();
          dismiss();
        },
        onCancel: dismiss
      });
    };

    const confirmRemove = () => {
      removeField();
      onRemove?.();
    };

    useImperativeHandle(ref, () => ({
      destroy: () => removeField()
    }));

    return (
      <Stack size="compact">
        <Row justifyContent="space-between" alignItems="center">
          {label && (
            <Row>
              <Text weight="bold" color="primary" testID="label">
                {label}
              </Text>
              {!required && displayAdditionalText && (
                <Text color="neutral" testID="optional-label">
                  {" "}
                  - optional
                </Text>
              )}
            </Row>
          )}
          {removeable && (
            <IconButton
              testID={`${name}-remove-button`}
              onPress={handleRemove}
              size="small"
              name="trash-alt"
              color="primary"
              variant="solid"
            />
          )}
        </Row>
        <StyledTextInput
          testID={`${name}-comment-input`}
          style={styles.input}
          eventTargetName="Input Comment"
          multiline
          numberOfLines={5}
          value={value ?? undefined}
          onChangeText={setValue}
          onBlur={showErrors}
          errors={errors}
          placeholder={placeholder}
        />
      </Stack>
    );
  }
);
