import { toast } from "@gigsmart/atorasu";
import { withFeatureFlags } from "@gigsmart/feature-flags";
import { withDeepLinking } from "@gigsmart/isomorphic-shared/app/DeepLinking";
import type { AppSettingsType } from "@gigsmart/isomorphic-shared/app/app-settings";
import AppWrapper from "@gigsmart/isomorphic-shared/app/app-wrapper";
import { loadEventReporters } from "@gigsmart/isomorphic-shared/app/event-reporters";
import { openAppStore } from "@gigsmart/isomorphic-shared/app/helpers/links";
import { withSentryWrap } from "@gigsmart/isomorphic-shared/app/sentry-wrap";
import withBootSplash from "@gigsmart/isomorphic-shared/app/with-boot-splash";
import { CurrentUserProvider } from "@gigsmart/isomorphic-shared/current-user";
import { withErrorBoundary } from "@gigsmart/isomorphic-shared/debug/error-boundary";
import withTestInstrumenter from "@gigsmart/isomorphic-shared/e2e/instrumenter";
import { ExpoUpdateWrapper } from "@gigsmart/isomorphic-shared/expo-update";
import appRelayOrchestrate from "@gigsmart/isomorphic-shared/relay/app-relay-orchestrate";
import React, { Component } from "react";
import { WorkerRoot } from "../navigation/WorkerRoot";
import { createWorkerLinking } from "../navigation/linking";

const APP_SETTINGS: AppSettingsType = {
  appId: "worker",
  appName: "Get Gigs",
  onRequestUpdate: async () => await openAppStore("worker")
};

@withErrorBoundary
@withBootSplash({ fade: true, delay: 300 })
@withTestInstrumenter
@withFeatureFlags({
  onError: ({ message }) => {
    toast.error(message);
  },
  onReady: loadEventReporters
})
@withSentryWrap
@appRelayOrchestrate({ initStore: require("./relay-store.json") })
export default class WorkerApp extends Component {
  linking = withDeepLinking(createWorkerLinking());

  render() {
    return (
      <CurrentUserProvider>
        <AppWrapper settings={APP_SETTINGS} linking={this.linking}>
          <ExpoUpdateWrapper>
            <WorkerRoot />
          </ExpoUpdateWrapper>
        </AppWrapper>
      </CurrentUserProvider>
    );
  }
}
