import React from "react";

import { Icon, Stack, Text, Well } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { StyledView } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type {
  detailsCard_jobPosting$data,
  detailsCard_jobPosting$key
} from "./__generated__/detailsCard_jobPosting.graphql";
import ApplyButtons from "./apply-buttons";

interface Props {
  jobPost: detailsCard_jobPosting$key | null | undefined;
  isWorker?: boolean;
  onPressApplyViaWeb?: (id: string) => void;
  worker?: {
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly primaryEmail?: {
      readonly address: string;
    } | null;
    readonly primaryMobile?: {
      readonly number: string;
    } | null;
  } | null;
}

export default function DetailsCard({
  jobPost: jobPostRef,
  isWorker = false,
  onPressApplyViaWeb,
  worker
}: Props) {
  const jobPost = useRelayFragment<detailsCard_jobPosting$key>(
    graphql`
      fragment detailsCard_jobPosting on JobPosting {
        boosted
        title
        place {
          streetAddress
        }
        payRateNegotiable
        paySchedule
        minimumPayRate
        maximumPayRate
        ...applyButtons_jobPosting
      }
    `,
    jobPostRef
  );
  const styles = useStyles(({ getUnits }) => ({
    paddingRight: {
      paddingRight: getUnits(4)
    }
  }));
  return (
    <>
      {jobPost?.boosted && (
        <StyledView horizontalPadding={0} verticalPadding={0}>
          <Well color="emphasized">
            <Stack horizontal size="compact" alignItems="center">
              <Icon
                variant="solid"
                name="angles-up"
                color="emphasized"
                size="medium"
              />
              <Text color="emphasized" weight="semibold">
                Highlighted Job
              </Text>
            </Stack>
          </Well>
        </StyledView>
      )}
      <StyledView shadow>
        <Stack fill={1}>
          <Text color="primary" variant="bodyLg" weight="semibold">
            {jobPost?.title}
          </Text>
          <Stack horizontal size="compact" alignItems="center">
            <Icon
              variant="solid"
              name="location-dot"
              color="primary"
              size="small"
            />
            <Text>{jobPost?.place?.streetAddress}</Text>
          </Stack>
          <Stack horizontal size="compact" alignItems="center" fill={1}>
            <Icon
              variant="solid"
              name="dollar-sign"
              color="primary"
              size="small"
            />
            <Text style={styles.paddingRight}>
              {getPaymentType(jobPost)} {getPaymentAmount(jobPost)}
            </Text>
          </Stack>
          <ApplyButtons
            jobPost={jobPost}
            isWorker={isWorker}
            worker={worker}
            onPressApplyViaWeb={onPressApplyViaWeb}
          />
        </Stack>
      </StyledView>
    </>
  );
}

function getPaymentType(
  jobPost: detailsCard_jobPosting$data | null | undefined
): string {
  if (jobPost?.payRateNegotiable) return "Negotiable";
  if (jobPost?.paySchedule === "ANNUALLY") {
    return "Annual Salary • ";
  }
  return "Hourly Rate • ";
}

function getPaymentAmount(
  jobPost: detailsCard_jobPosting$data | null | undefined
) {
  const withRange = jobPost?.minimumPayRate !== jobPost?.maximumPayRate;
  if (jobPost?.payRateNegotiable) return "";
  if (jobPost?.paySchedule === "ANNUALLY" && withRange) {
    return `Min. ${currency.humanize(
      jobPost?.minimumPayRate
    )}/yr - Max. ${currency.humanize(jobPost?.maximumPayRate)}/yr`;
  }
  if (jobPost?.paySchedule === "ANNUALLY" && !withRange) {
    return `${currency.humanize(jobPost?.minimumPayRate)}/yr`;
  }
  if (jobPost?.paySchedule === "HOURLY" && withRange) {
    return `Min. ${currency.humanize(
      jobPost?.minimumPayRate
    )}/hr - Max. ${currency.humanize(jobPost?.maximumPayRate)}/hr`;
  }
  if (jobPost?.paySchedule === "HOURLY" && !withRange) {
    return `${currency.humanize(jobPost?.minimumPayRate)}/hr`;
  }
  return "";
}
