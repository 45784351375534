import React from "react";
import Svg, { Path, type SvgProps } from "react-native-svg";

import { View } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";

type Props = SvgProps;

export default function ClipboardSvg({ style, ...props }: Props) {
  const styles = useStyles(() => ({
    container: {
      aspectRatio: 237 / 71
    }
  }));
  return (
    <View style={[styles.container, style]}>
      <Svg
        height="100%"
        width="100%"
        viewBox="0 0 138 173"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        {...props}
      >
        <Path
          d="M64.341 7.176a6.687 6.687 0 00-6.067-3.886A6.7 6.7 0 0051.7 8.789l12.641-1.613z"
          fill="none"
        />
        <Path
          d="M111.824 8.092l-27.926 3.564.51 3.991a3.37 3.37 0 01-2.902 3.751l-43.351 5.533a3.367 3.367 0 01-3.751-2.902l-.509-3.991-27.926 3.564c-3.719.474-6.388 3.925-5.913 7.643l17.52 137.28c.475 3.718 3.925 6.387 7.644 5.913l105.855-13.51c3.719-.475 6.388-3.925 5.913-7.643l-17.521-137.28c-.474-3.719-3.924-6.388-7.643-5.913zm17.994 142.586l-2.528.322-100.398 12.814L10.156 32.679l100.398-12.813 2.527-.323 16.737 131.135z"
          fill="#bcc7cc"
          fillRule="nonzero"
        />
        <Path
          d="M79.696 5.216l-11.989 1.53A9.988 9.988 0 0058.274.001c-5.186 0-9.55 4.047-9.94 9.218l-11.989 1.53a3.366 3.366 0 00-2.902 3.751l.452 3.538.509 3.991a3.367 3.367 0 003.751 2.902l43.351-5.533a3.37 3.37 0 002.902-3.751l-.51-3.991-.451-3.538a3.37 3.37 0 00-3.751-2.902zM57.428 3.344a6.693 6.693 0 016.913 3.832L51.7 8.789a6.693 6.693 0 015.728-5.445z"
          fill="#898989"
          fillRule="nonzero"
        />
        <Path
          d="M35.143 40.237l-13.255 1.692 1.692 13.255 13.255-1.691-1.692-13.256zm-6.712 11.892l-3.944-2.969 1.339-1.738 2.114 1.633 5.332-6.862 1.839 1.311-6.68 8.625zM44.402 112.785l-13.254 1.691 1.691 13.256 13.255-1.692-1.692-13.255zm-6.712 11.891l-3.944-2.969 1.339-1.737 2.114 1.632 5.332-6.862 1.839 1.311-6.68 8.625zM41.316 88.602l-13.255 1.692 1.692 13.255 13.255-1.691-1.692-13.256zm-6.712 11.891l-3.944-2.968 1.339-1.738 2.113 1.633 5.333-6.862 1.839 1.311-6.68 8.624zM38.23 64.42l-13.255 1.691 1.692 13.256 13.254-1.692L38.23 64.42zm-6.712 11.891l-3.945-2.969 1.34-1.738 2.113 1.633 5.332-6.862 1.839 1.311-6.679 8.625z"
          fill="#f2f2f2"
          fillRule="nonzero"
        />
        <Path
          d="M10.156 32.679l16.736 131.135L127.29 151 110.554 19.866 10.156 32.679zm30.891 17.109l32.785-4.184.461 3.612L41.508 53.4l-.461-3.612zm3.107 24.342l32.784-4.184.461 3.612-32.784 4.184-.461-3.612zm3.107 24.342l32.784-4.184.461 3.612-32.784 4.184-.461-3.612zm3.106 24.342l32.785-4.184.461 3.612-32.785 4.184-.461-3.612zm-.068 28.274l-15.239 1.944-1.945-15.239 15.239-1.945 1.945 15.24zm-3.086-24.183l-15.239 1.945-1.945-15.239 15.239-1.945 1.945 15.239zm-3.087-24.182l-15.238 1.945-1.945-15.239 15.238-1.945 1.945 15.239zM41.04 78.54l-15.239 1.945-1.945-15.239 15.239-1.945L41.04 78.54zm-3.086-24.182l-15.239 1.945-1.945-15.239 15.239-1.945 1.945 15.239zm48.765 92.226l-32.784 4.184-.461-3.612 32.784-4.184.461 3.612zm26.276-8.758l-59.739 7.624-.461-3.612 59.739-7.624.461 3.612zm-.679-5.318l-59.738 7.624-.461-3.611 59.738-7.625.461 3.612zm-2.428-19.024l-59.738 7.624-.461-3.612 59.738-7.624.461 3.612zm-.678-5.318l-59.739 7.625-.461-3.612 59.739-7.625.461 3.612zm-2.428-19.024l-59.739 7.624-.461-3.612 59.739-7.624.461 3.612zm-.679-5.318l-59.739 7.625-.461-3.612 59.739-7.625.461 3.612zM103.675 64.8l-59.739 7.625-.461-3.612 59.739-7.625.461 3.612zm-.679-5.318l-59.739 7.625-.461-3.612 59.739-7.624.461 3.611zm-2.428-19.024l-59.739 7.625-.461-3.612 59.739-7.625.461 3.612zm-.678-5.317l-59.739 7.624-.461-3.612 59.739-7.624.461 3.612z"
          fill="#f2f2f2"
          fillRule="nonzero"
        />
        <Path
          d="M47.489 136.967l-13.255 1.692 1.692 13.255 13.254-1.692-1.691-13.255zm-6.712 11.891l-3.945-2.969 1.34-1.737 2.113 1.632 5.332-6.862 1.839 1.312-6.679 8.624z"
          fill="#f2f2f2"
          fillRule="nonzero"
        />
        <Path
          fill="#9e9e9e"
          d="M110.554 19.866l2.527-.322 16.736 131.133-2.527.323z"
        />
        <Path
          d="M35.06 153.032l15.239-1.944-1.945-15.24-15.239 1.945 1.945 15.239zm-.826-14.373l13.255-1.692 1.691 13.255-13.254 1.692-1.692-13.255z"
          fill="#afafaf"
          fillRule="nonzero"
        />
        <Path
          d="M40.285 145.784l-2.113-1.632-1.34 1.737 3.945 2.969 6.679-8.624-1.839-1.311-5.332 6.861z"
          fill="#383838"
          fillRule="nonzero"
        />
        <Path
          d="M31.974 128.85l15.239-1.945-1.945-15.239-15.239 1.945 1.945 15.239zm-.826-14.374l13.254-1.691 1.692 13.255-13.255 1.692-1.691-13.256z"
          fill="#afafaf"
          fillRule="nonzero"
        />
        <Path
          d="M37.199 121.602l-2.114-1.632-1.339 1.737 3.944 2.969 6.68-8.625-1.839-1.311-5.332 6.862z"
          fill="#383838"
          fillRule="nonzero"
        />
        <Path
          d="M28.888 104.668l15.238-1.945-1.945-15.239-15.238 1.945 1.945 15.239zm-.827-14.374l13.255-1.692 1.692 13.256-13.255 1.691-1.692-13.255z"
          fill="#afafaf"
          fillRule="nonzero"
        />
        <Path
          d="M34.112 97.419l-2.113-1.632-1.339 1.738 3.944 2.968 6.68-8.624-1.839-1.312-5.333 6.862z"
          fill="#383838"
          fillRule="nonzero"
        />
        <Path
          d="M25.801 80.485L41.04 78.54l-1.945-15.239-15.239 1.945 1.945 15.239zm-.826-14.374L38.23 64.42l1.691 13.255-13.254 1.692-1.692-13.256z"
          fill="#afafaf"
          fillRule="nonzero"
        />
        <Path
          d="M31.026 73.237l-2.113-1.632-1.34 1.737 3.945 2.969 6.679-8.625-1.839-1.311-5.332 6.862z"
          fill="#383838"
          fillRule="nonzero"
        />
        <Path
          d="M22.715 56.303l15.239-1.945-1.945-15.239-15.239 1.945 1.945 15.239zm-.827-14.374l13.255-1.692 1.692 13.256-13.255 1.691-1.692-13.255z"
          fill="#afafaf"
          fillRule="nonzero"
        />
        <Path
          d="M27.94 49.055l-2.114-1.633-1.339 1.738 3.944 2.969 6.68-8.625-1.839-1.311-5.332 6.862z"
          fill="#383838"
          fillRule="nonzero"
        />
        <Path
          fill="#cecece"
          d="M39.69 39.153l59.738-7.624.461 3.611-59.738 7.625zM40.369 44.47l59.738-7.624.461 3.612-59.738 7.624zM41.048 49.789l32.784-4.184.46 3.611L41.509 53.4zM42.797 63.495l59.738-7.624.461 3.611-59.738 7.625zM43.475 68.813l59.738-7.624.461 3.611-59.738 7.625zM44.153 74.13l32.785-4.184.46 3.611-32.784 4.185zM45.903 87.837l59.738-7.624.461 3.611-59.738 7.625zM46.582 93.155l59.738-7.624.461 3.611-59.738 7.625zM47.26 98.472l32.785-4.184.46 3.611-32.784 4.184zM49.01 112.179l59.738-7.624.461 3.611-59.738 7.625zM49.689 117.496l59.738-7.624.461 3.611-59.738 7.625zM50.367 122.814l32.785-4.184.46 3.611-32.784 4.184zM52.117 136.52l59.738-7.623.461 3.611-59.738 7.625zM52.795 141.838l59.738-7.624.461 3.611-59.738 7.625zM53.474 147.156l32.784-4.184.46 3.611-32.784 4.185z"
        />
      </Svg>
    </View>
  );
}
