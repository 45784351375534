/**
 * @generated SignedSource<<7f2ca209361ea9e263ebd77654bb462c>>
 * @relayHash 6a5ee0bd0df449ef16d03b1fbe07d556
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:YjTIYN07MOAlJui7YbGm7wBgH5vFXj0HZ_u1Ja1pK0s

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type requesterQuery$variables = {
  gigId: string;
  hasGigId: boolean;
  id: string;
  query: string;
};
export type requesterQuery$data = {
  readonly gig?: {
    readonly gigType?: GigType | null | undefined;
    readonly organization?: {
      readonly administrativeArea1: string | null | undefined;
      readonly allowsSmoking: boolean | null | undefined;
      readonly companySize: string | null | undefined;
      readonly countryCode: string | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly industry: string;
      readonly locality: string | null | undefined;
      readonly logoUrl: string | null | undefined;
      readonly name: string;
      readonly officeType: string | null | undefined;
      readonly overallRating: number;
      readonly postalCode: string | null | undefined;
      readonly reviewsReceived: {
        readonly totalCount: number;
      } | null | undefined;
      readonly street1: string | null | undefined;
      readonly street2: string | null | undefined;
      readonly union: boolean | null | undefined;
      readonly website: string | null | undefined;
      readonly yearFounded: number | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift">;
  } | null | undefined;
  readonly requester: {
    readonly biography?: string | null | undefined;
    readonly displayName?: string | null | undefined;
    readonly id?: string;
    readonly jobTitle?: string | null | undefined;
    readonly overallRating?: number;
    readonly primaryOrganization?: {
      readonly administrativeArea1: string | null | undefined;
      readonly allowsSmoking: boolean | null | undefined;
      readonly companySize: string | null | undefined;
      readonly countryCode: string | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly industry: string;
      readonly locality: string | null | undefined;
      readonly logoUrl: string | null | undefined;
      readonly name: string;
      readonly officeType: string | null | undefined;
      readonly overallRating: number;
      readonly postalCode: string | null | undefined;
      readonly reviewsReceived: {
        readonly totalCount: number;
      } | null | undefined;
      readonly street1: string | null | undefined;
      readonly street2: string | null | undefined;
      readonly union: boolean | null | undefined;
      readonly website: string | null | undefined;
      readonly yearFounded: number | null | undefined;
    } | null | undefined;
    readonly profilePhoto?: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly reviewsReceived?: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly viewer: {
    readonly id?: string;
    readonly organizations?: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type requesterQuery = {
  response: requesterQuery$data;
  variables: requesterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gigId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasGigId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": [
    (v5/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "OrganizationsConnection",
  "kind": "LinkedField",
  "name": "organizations",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gigId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v10 = [
  (v4/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v10/*: any*/),
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    (v5/*: any*/)
  ],
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "reviewsReceived(first:0)"
},
v14 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "industry",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locality",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postalCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countryCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "administrativeArea1",
    "storageKey": null
  },
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companySize",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "website",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "union",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "yearFounded",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "officeType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowsSmoking",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v15 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": (v14/*: any*/),
    "storageKey": null
  }
],
v16 = {
  "kind": "InlineFragment",
  "selections": (v15/*: any*/),
  "type": "Gig",
  "abstractKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": (v15/*: any*/),
  "type": "GigSeries",
  "abstractKey": null
},
v18 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "biography",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "primaryOrganization",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "requesterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasGigId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "gig",
            "args": (v8/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "BlockOrganizationCollapsibleRow_shift",
                "selections": [
                  (v11/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": "requester",
        "args": (v18/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v20/*: any*/),
              (v12/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "type": "RequesterLike",
            "abstractKey": "__isRequesterLike"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "requesterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v24/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "requester",
        "args": (v18/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v24/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  (v19/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              (v20/*: any*/),
              (v12/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "type": "RequesterLike",
            "abstractKey": "__isRequesterLike"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasGigId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "gig",
            "args": (v8/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v24/*: any*/),
              (v11/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "qpid:YjTIYN07MOAlJui7YbGm7wBgH5vFXj0HZ_u1Ja1pK0s",
    "metadata": {},
    "name": "requesterQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c07a0a084cdb33c4f4d08733ab07e7f8";

export default node;
