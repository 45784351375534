import {
  Button,
  ContentArea,
  HighlightedReminder,
  NavBar,
  ScreenScroll,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import Logo from "@gigsmart/isomorphic-shared/brand/logo";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useState } from "react";
import PositionsForm from "../user/Onboarding/PositionSelectionStep/PositionsForm";
import SkillCategories from "../worker-profile/SkillCategories";
import type { WorkerPositionCheckModal_Worker$key } from "./__generated__/WorkerPositionCheckModal_Worker.graphql";

interface Props {
  close: () => void;
}

export function WorkerPositionCheckModal({
  close,
  positions,
  result
}: FragmentContainerInnerComponentProps<
  WorkerPositionCheckModal_Worker$key,
  Props
>) {
  const confirmedPositionsCount = positions?.totalCount ?? 0;
  const [workerCategoryId, setWorkerCategoryId] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  return (
    <>
      <NavBar title={<Logo />} />
      <ScreenScroll testID="worker-position-check-modal" color="surface" grow>
        <ContentArea fill>
          {workerCategoryId && categoryId && (
            <PositionsForm
              showTitle
              workerCategoryId={workerCategoryId}
              categoryId={categoryId}
              onBackPress={() => {
                setWorkerCategoryId(null);
                setCategoryId(null);
                return true;
              }}
            />
          )}
          {(!workerCategoryId || !categoryId) && (
            <Stack>
              <Text variant="header">
                We have added Positions to Shift Gigs so you can easily find
                opportunities that you want to work.
              </Text>
              {confirmedPositionsCount === 0 && (
                <Text color="error">
                  <Text weight="bold">Add at least 1 Position</Text> to continue
                  using the app.
                </Text>
              )}
              {confirmedPositionsCount > 0 && (
                <Text color="success" weight="bold">
                  You have added {confirmedPositionsCount}{" "}
                  {pluralize(confirmedPositionsCount, "Position", false)}
                </Text>
              )}
              <Text variant="header" color="primary">
                Categories & Positions
              </Text>
              <SkillCategories
                fragmentRef={result}
                onCategoryPress={(newWorkerCategoryId, newCategoryId) => {
                  setWorkerCategoryId(newWorkerCategoryId);
                  setCategoryId(newCategoryId);
                }}
              />
              <HighlightedReminder
                icon="circle-exclamation"
                header="You must select at least one Position to continue. We will notify you of Shift Gig opportunities based on your selections."
              />
              <Button
                testID="worker-position-check-modal-close-btn"
                label="Save & Continue"
                onPress={close}
                disabled={confirmedPositionsCount === 0}
              />
            </Stack>
          )}
        </ContentArea>
        <Spacer />
      </ScreenScroll>
    </>
  );
}

export default createRelayFragmentContainer<
  WorkerPositionCheckModal_Worker$key,
  Props
>(
  graphql`
    fragment WorkerPositionCheckModal_Worker on Worker {
      id
      positions(first: 0, query: "WHERE status = CONFIRMED")
        @connection(key: "WorkerPositionCheckModal_positions") {
        totalCount
        edges {
          node {
            id
          }
        }
      }
      ...SkillCategories_categoriesScreen
    }
  `,
  WorkerPositionCheckModal
);
