import {
  type ButtonTab,
  Column,
  Constraint,
  ContentArea,
  ListEmpty,
  ListEmptySearch,
  Spacer,
  Stack,
  type UnitSize,
  useMatchesViewport
} from "@gigsmart/atorasu";
import type { KeyType, QuerySpec } from "@gigsmart/relay";
import React, { type ReactNode, useState } from "react";
import type { ComponentProps } from "react";
import Loader from "../../Brand/Loader";
import InfiniteGridList, {
  type Props as InfiniteGridListProps
} from "../../shared/InfiniteList/InfiniteGridList";
import ListFilterHeader from "../../shared/InfiniteList/ListFilterHeader";
import type {
  Props as ListHeaderProps,
  Values,
  getBubblesFn as getBubblesFnType
} from "../../shared/InfiniteList/ListFilterHeader";

interface Props<Q extends QuerySpec, TKey extends KeyType, T, V = {}>
  extends Omit<InfiniteGridListProps<Q, TKey, T>, "refetchVars"> {
  listEmptyIcon?: ComponentProps<typeof ListEmpty>["icon"];
  listEmptyTitle?: string;
  listEmptySubtitle?: string;
  listEmptyAction?: ReactNode;
  listEmptyVariant?: ComponentProps<typeof ListEmpty>["variant"];
  searchPlaceholder?: ListHeaderProps<V>["placeholder"];
  getRefetchVarsFromValuesFn?: (values?: V) => Partial<Q["variables"]>;
  FilterModalComponent?: ListHeaderProps<V>["FilterModalComponent"];
  InlineFilterComponent?: ListHeaderProps<V>["InlineFilterComponent"];
  initialFilterValues?: V;
  tabFilters?: Array<
    ButtonTab & {
      listEmptyLabel?: string;
      listEmptyDescription?: string;
      default?: true;
    }
  >;
  CSVButtonComponent?: (filter: V) => ReactNode;
  getBubblesFn?: getBubblesFnType<V>;
  headerSpacing?: UnitSize;
  headerConstraint?: ComponentProps<typeof Constraint>["size"];
  headerPosition?: "top" | "bottom";
  hideFooterIfEmptyList?: boolean;
}

export default function InfiniteGridListLayout<
  Q extends QuerySpec,
  TKey extends KeyType,
  T,
  V extends { searchTerm?: string; activeTab?: string } = {
    searchTerm?: undefined;
    activeTab?: undefined;
  }
>({
  header,
  footer,
  emptyContent,
  listEmptyTitle = "No Results Found",
  listEmptySubtitle,
  listEmptyIcon = "grid",
  listEmptyVariant = "card",
  getRefetchVarsFromValuesFn,
  FilterModalComponent,
  InlineFilterComponent,
  initialFilterValues,
  searchPlaceholder,
  getBubblesFn,
  tabFilters,
  headerSpacing,
  headerConstraint = "none",
  headerPosition = "top",
  hideFooterIfEmptyList,
  CSVButtonComponent,
  ...props
}: Props<Q, TKey, T, V>) {
  const isMdUp = useMatchesViewport(({ size }) => size.medium.up);
  const isWeb = useMatchesViewport(({ platform }) => platform.web);
  const [refetchVars, setRefetchVars] = useState(() =>
    getRefetchVarsFromValuesFn?.(initialFilterValues)
  );
  const [searchTerm, setSearchTerm] = useState(initialFilterValues?.searchTerm);
  const [selectedTab, setSelectedTab] = useState(
    tabFilters?.find((t) => !!t?.default)?.value
  );
  const handleFilterChange = (values: Values<V>) => {
    setSearchTerm(values?.searchTerm);
    setSelectedTab(values?.activeTab);
    setRefetchVars(getRefetchVarsFromValuesFn?.(values));
  };

  const activeTab = tabFilters?.find((t) => t?.value === selectedTab);
  const hasHeader = !!header || !!getRefetchVarsFromValuesFn;
  return (
    <InfiniteGridList<Q, TKey, T>
      {...props}
      refetchVars={refetchVars}
      renderHeaderView={(isEmpty) =>
        hasHeader ? (
          <ContentArea variant={headerSpacing}>
            <Constraint size={headerConstraint}>
              <Stack>
                {headerPosition === "top" && header}
                {!!getRefetchVarsFromValuesFn && (
                  <Stack horizontal fill alignItems="flex-start">
                    <ListFilterHeader<V>
                      placeholder={searchPlaceholder}
                      testID={`${props.testID}-header`}
                      onChange={handleFilterChange}
                      FilterModalComponent={FilterModalComponent}
                      InlineFilterComponent={InlineFilterComponent}
                      initialFilterValues={initialFilterValues}
                      tabFilters={tabFilters}
                      getBubblesFn={getBubblesFn}
                      isEmpty={isEmpty}
                      CSVButtonComponent={(filter) =>
                        isWeb &&
                        isMdUp &&
                        !isEmpty &&
                        refetchVars &&
                        CSVButtonComponent?.(filter)
                      }
                    />
                  </Stack>
                )}
                {headerPosition === "bottom" && header}
              </Stack>
            </Constraint>
          </ContentArea>
        ) : (
          <Spacer />
        )
      }
      renderEmptyView={(isFetching) => {
        if (isFetching) {
          return (
            <Column justifyContent="center" alignItems="center" fill>
              <Loader />
            </Column>
          );
        }
        if (searchTerm) return <ListEmptySearch searchTerm={searchTerm} />;
        if (emptyContent) return emptyContent;
        return (
          <ListEmpty
            icon={listEmptyIcon}
            color="primary"
            title={activeTab?.listEmptyLabel ?? listEmptyTitle}
            subtitle={activeTab?.listEmptyDescription ?? listEmptySubtitle}
            variant={listEmptyVariant}
          />
        );
      }}
      renderFooterView={(isFetching, totalCount) => {
        if (isFetching && totalCount > 0) {
          return (
            <Column justifyContent="center" alignItems="center">
              <Loader />
            </Column>
          );
        }

        if (hideFooterIfEmptyList && totalCount < 1) return null;

        return footer ?? null;
      }}
    />
  );
}
