import { Spacer } from "@gigsmart/atorasu";
import { FormValue } from "@gigsmart/fomu";
import React, {
  type ComponentProps,
  isValidElement,
  type ReactNode
} from "react";
import CommentQuestion, {
  type Props as CommentQuestionProps
} from "./CommentQuestion";
import ExpandableRadioQuestion from "./ExpandableRadioQuestion";
import MoneyQuestion, {
  type Props as MoneyQuestionProps
} from "./MoneyQuestion";
import RadioQuestion, {
  type Props as RadioQuestionProps
} from "./RadioQuestion";
import SubmitQuestionnaire, {
  type Props as SubmitQuestionnaireProps
} from "./SubmitQuestionnaire";

interface MoneyTypeProps extends MoneyQuestionProps {
  type: "money";
  nextQuestion: Record<string, string | ReactNode> | ReactNode;
}

interface RadioTypeProps<T = string> extends RadioQuestionProps<T> {
  type: "radio";
  nextQuestion: Record<string, string | ReactNode> | ReactNode;
}
interface ExpandableRadioTypeProps
  extends ComponentProps<typeof ExpandableRadioQuestion> {
  type: "expandable_radio";
  nextQuestion?: Record<string, string | ReactNode> | ReactNode;
}
interface CommentTypeProps extends CommentQuestionProps {
  type: "comment";
  nextQuestion?: Record<string, string | ReactNode> | ReactNode;
}
interface SubmitTypeProps extends SubmitQuestionnaireProps {
  type: "submit";
}

interface CustomTypeProps {
  type: "custom";
  render: () => ReactNode;
  nextQuestion?: Record<string, string | ReactNode> | ReactNode;
}

type Question =
  | RadioTypeProps
  | ExpandableRadioTypeProps
  | CommentTypeProps
  | SubmitTypeProps
  | MoneyTypeProps
  | CustomTypeProps;

export type Questions = Record<string, Question>;

interface Props {
  questions: Questions;
  question: string;
}

export default function Questionnaire({
  questions,
  question: questionName
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const question = questions[questionName]!;

  const renderNextQuestion = () => {
    if ("nextQuestion" in question) {
      const nextQuestion = question.nextQuestion;

      if (isValidElement(nextQuestion)) return nextQuestion;
      if (typeof nextQuestion === "string") {
        return <Questionnaire questions={questions} question={nextQuestion} />;
      }
      if (
        nextQuestion &&
        typeof nextQuestion === "object" &&
        "name" in question
      ) {
        return (
          <FormValue name={question.name}>
            {({ value }) => {
              const child = (nextQuestion as any)[value];
              return typeof child === "string" ? (
                <Questionnaire questions={questions} question={child} />
              ) : isValidElement(child) ? (
                child
              ) : null;
            }}
          </FormValue>
        );
      }
    }
    return null;
  };

  const renderQuestion = () => {
    switch (question?.type) {
      case "radio":
        return <RadioQuestion {...question} />;
      case "comment":
        return <CommentQuestion {...question} />;
      case "money":
        return <MoneyQuestion {...question} />;
      case "submit":
        return <SubmitQuestionnaire {...question} />;
      case "expandable_radio":
        return <ExpandableRadioQuestion {...question} />;
      case "custom":
        return question.render();
      default:
        return null;
    }
  };

  const questionNode = renderQuestion();
  const nextQuestionNode = renderNextQuestion();
  const hasSpacer = !!questionNode && !!nextQuestionNode;

  return (
    <>
      {questionNode}
      {hasSpacer && <Spacer />}
      {nextQuestionNode}
    </>
  );
}
