import {
  ContentArea,
  ErrorPage,
  LoadingView,
  ModalBody,
  ModalFooter,
  Stack,
  Surface,
  Text,
  TimeRemaining,
  showModal
} from "@gigsmart/atorasu";
import { Form, Validator } from "@gigsmart/fomu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { Duration } from "luxon";
import React from "react";
import FomuCheckbox from "../../fomu/inputs/FomuCheckbox";
import FomuSubmit from "../../fomu/inputs/FomuSubmit";
import type { FirstConfirmationModalQuery } from "./__generated__/FirstConfirmationModalQuery.graphql";

type Props = {
  gigId: string;
  onConfirm: (travelTime: string, endTime: string) => void;
};

export function showFirstConfirmationModal({
  onDismiss,
  onConfirm,
  gigId
}: Omit<Props, "onClose"> & { onDismiss: () => void }) {
  return showModal({
    title: "Are you able to work this Shift?",
    eventContext: "Pickup Shift First Confirmation Modal",
    onRequestClose: onDismiss,
    useModalBody: false,
    children: (closeFn) => (
      <FirstConfirmationModal
        gigId={gigId}
        onConfirm={(travelTime, endTime) => {
          closeFn();
          // wait modal animation
          setTimeout(() => {
            onConfirm?.(travelTime, endTime);
          }, 500);
        }}
      />
    )
  });
}

const FirstConfirmationModal = createSuspendedQueryContainer<
  FirstConfirmationModalQuery,
  Props
>(
  function FirstConfirmationModal({ response, onConfirm }) {
    const availableGig = response?.viewer?.gig;
    if (!availableGig) return null;

    const gig = availableGig?.gig || availableGig?.node;
    const pickupEndTime = gig?.pickup.windowEnd;
    const actualStartsAt = gig?.actualStartsAt ?? "";
    const travelTime = availableGig?.travelTime ?? "";
    const shouldShowLAT = ["ACTIVE", "IN_PROGRESS"].includes(
      gig?.currentState?.name ?? ""
    );
    const gigDistance = pluralize(availableGig?.distance, "Mile");
    const latestArrivalTime = shouldShowLAT
      ? time.humanize(gig?.latestArrivalTime ?? gig?.actualStartsAt)
      : null;

    const gigStartTime = time.humanize(actualStartsAt);
    const etaOffsetDuration = travelTime ? Duration.fromISO(travelTime) : null;
    const etaToShift = etaOffsetDuration?.toHuman() ?? "N/A";

    return (
      <Form
        onSubmit={() =>
          onConfirm?.(travelTime, gig?.latestArrivalTime ?? actualStartsAt)
        }
      >
        <ModalBody>
          <Stack>
            <Stack>
              <Text align="center">
                By picking up this Shift, you will be immediately scheduled to
                work
              </Text>
              <Surface variant="outline">
                <ContentArea size="compact">
                  <Text align="center">Time left to Pick Up</Text>
                  <TimeRemaining
                    align="center"
                    variant="header"
                    weight="bold"
                    color="emphasized"
                    date={pickupEndTime}
                    size="lg"
                    showSeconds
                  />
                </ContentArea>
              </Surface>
            </Stack>
            <Stack size="slim">
              <Text>
                <Text weight="bold">Shift Start Time: </Text>
                <Text>{gigStartTime}</Text>
              </Text>
              {latestArrivalTime && (
                <Text>
                  <Text weight="bold">Latest Arrival Time: </Text>
                  <Text>{latestArrivalTime}</Text>
                </Text>
              )}
              <Text>
                <Text weight="bold">ETA to Shift: </Text>
                <Text>{etaToShift}</Text>
              </Text>
              <Text>
                <Text weight="bold">Shift Distance: </Text>
                <Text>{gigDistance}</Text>
              </Text>
            </Stack>
            <FomuCheckbox
              title="I confirm that I am able to work this Shift."
              name="pickUpShiftFirstConfirmation"
              validates={[Validator.isTrue()]}
              expand
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <FomuSubmit
            label="Next"
            color="emphasized"
            testID="pick-up-shift-first-confirmation-modal-submit"
          />
        </ModalFooter>
      </Form>
    );
  },
  {
    query: graphql`
      query FirstConfirmationModalQuery($gigId: ID!) {
        viewer {
          ... on Worker {
            gig(id: $gigId) {
              ... on Engagement {
                distance: workerDistance
                travelTime
                gig {
                  actualStartsAt
                  latestArrivalTime
                  timezone
                  currentState {
                    name
                  }
                  pickup {
                    windowEnd
                  }
                }
              }
              ... on AvailableGigsEdge {
                distance
                travelTime
                node {
                  actualStartsAt
                  latestArrivalTime
                  timezone
                  currentState {
                    name
                  }
                  pickup {
                    windowEnd
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ gigId }) => ({ gigId }),
    FallbackComponent: () => <LoadingView size="xlarge" />,
    ErrorComponent: ErrorPage
  }
);
