/**
 * @generated SignedSource<<ce0222fb3b1800426e4d4f92fb3b28fc>>
 * @relayHash 86817866ab901cee160118e10bdcbbdf
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:xw4VomMHbhJbDuWScCsE7q154HOxApW2wJbVFLx6crg

import { ConcreteRequest } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type TotalEarnedModalQuery$variables = {
  engagementId: string;
};
export type TotalEarnedModalQuery$data = {
  readonly node: {
    readonly currentState?: {
      readonly name: EngagementStateName;
      readonly transitionedAt: string;
    };
    readonly gigType?: GigType;
    readonly paymentInfo?: {
      readonly additionalPay: string;
      readonly basePay: string;
      readonly billableDuration: string;
      readonly netPay: string;
      readonly payRate: string;
      readonly serviceFees: ReadonlyArray<{
        readonly amount: string;
        readonly feeType: EngagementAddonFeeType;
        readonly hourlyRate: string | null | undefined;
      }>;
      readonly totalServiceFee: {
        readonly amount: string;
      };
    } | null | undefined;
    readonly workerTimesheet?: {
      readonly editable: boolean;
      readonly paymentInfo: {
        readonly additionalPay: string;
        readonly basePay: string;
        readonly billableDuration: string;
        readonly netPay: string;
        readonly payRate: string;
        readonly serviceFees: ReadonlyArray<{
          readonly amount: string;
          readonly feeType: EngagementAddonFeeType;
          readonly hourlyRate: string | null | undefined;
        }>;
        readonly totalServiceFee: {
          readonly amount: string;
        };
      } | null | undefined;
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type TotalEarnedModalQuery = {
  response: TotalEarnedModalQuery$data;
  variables: TotalEarnedModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "engagementId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engagementId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalPay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerServiceFee",
      "kind": "LinkedField",
      "name": "serviceFees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "feeType",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourlyRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalServiceFee",
      "kind": "LinkedField",
      "name": "totalServiceFee",
      "plural": false,
      "selections": [
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TotalEarnedModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "workerTimesheet",
                "args": (v5/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              (v9/*: any*/)
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TotalEarnedModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "workerTimesheet",
                "args": (v5/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              (v9/*: any*/)
            ],
            "type": "Engagement",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:xw4VomMHbhJbDuWScCsE7q154HOxApW2wJbVFLx6crg",
    "metadata": {},
    "name": "TotalEarnedModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e24a6ed7d0787565b3ab7f35f335fcc4";

export default node;
