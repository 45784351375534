/* eslint-disable react-native/no-inline-styles, no-restricted-imports */
import { createPropsPortal } from "@gigsmart/aperture";
import { Column, NavBar, View } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import Loader from "@gigsmart/isomorphic-shared/app/loader";
import Logo from "@gigsmart/isomorphic-shared/brand/logo";
import React, { type ComponentType, type ReactNode } from "react";
import { StyleSheet } from "react-native";

export interface AppletResult<TProps = any> {
  props?: TProps;
  AppletPreview?: ComponentType<TProps>;
  AppletContent?: ComponentType<TProps>;
  AppletHeader?: ComponentType<TProps>;
}

export const {
  usePortal,
  useExit,
  Exit: AppletPortalExit,
  Realm: AppletPortalRealm
} = createPropsPortal<AppletResult>({});

export const AppletPortal = ({ children }: { children: ReactNode }) => {
  const styles = useStyles(({ getColor }) => ({
    applet: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: getColor("background", "fill")
    },
    hidden: { display: "none" }
  }));

  const renderContent = ({
    props,
    AppletContent,
    AppletPreview,
    AppletHeader
  }: Partial<AppletResult>) => {
    const isOpen = !!AppletContent;

    return (
      <>
        {/* Figure how to reduce memory */}
        <Column fill display={isOpen ? "none" : undefined}>
          {children}
        </Column>
        {isOpen && (
          <View style={styles.applet}>
            <NavBar title={<Logo />}>
              <Loader />
            </NavBar>
            {!!AppletHeader && <AppletHeader {...props} />}
            {!!AppletPreview && <AppletPreview {...props} />}
            <AppletContent {...props} />
          </View>
        )}
      </>
    );
  };

  return (
    <AppletPortalRealm>
      <AppletPortalExit>{renderContent}</AppletPortalExit>
    </AppletPortalRealm>
  );
};
