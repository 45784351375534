import { formatDistanceRange } from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";

export interface ShiftCardExtras {
  distance?: { min?: number | null; max?: number | null } | number | null;
  netPay?: { min?: string | null; max?: string | null } | string | null;
  payRate?: string | null;
  isTotal?: boolean;
}

export function formatDistance(distance: ShiftCardExtras["distance"]) {
  return typeof distance === "number"
    ? `(${distance.toFixed(1)}mi)`
    : formatDistanceRange(distance);
}

export function formatNetPay(
  netPay: ShiftCardExtras["netPay"],
  isTotal: ShiftCardExtras["isTotal"] = false
) {
  let localNetPay = netPay;
  if (localNetPay === "TBD") return localNetPay;
  if (typeof localNetPay === "string")
    localNetPay = { min: localNetPay, max: localNetPay };
  if (localNetPay && isTotal && typeof localNetPay !== "string")
    localNetPay = { min: localNetPay.max, max: localNetPay.max };

  const hasPlusSign =
    !!localNetPay &&
    localNetPay.min !== localNetPay.max &&
    currency.toFloat(localNetPay?.max) > 1000;
  const earnings = !localNetPay
    ? "$0.00"
    : localNetPay.min === localNetPay.max || hasPlusSign
      ? currency.humanize(localNetPay.min, { integer: true })
      : [localNetPay.min, localNetPay.max]
          .map((it) => currency.humanize(it, { integer: true }))
          .join("-");

  return `${earnings}${hasPlusSign ? "+" : ""}`;
}
