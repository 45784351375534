/**
 * @generated SignedSource<<95bc1319c787c0c1ecc575754eac9c7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeEmailFormCard_userEmail$data = {
  readonly address: string;
  readonly id: string;
  readonly " $fragmentType": "ChangeEmailFormCard_userEmail";
};
export type ChangeEmailFormCard_userEmail$key = {
  readonly " $data"?: ChangeEmailFormCard_userEmail$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeEmailFormCard_userEmail">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeEmailFormCard_userEmail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "type": "UserEmail",
  "abstractKey": null
};

(node as any).hash = "588d237de80deb323e060a9280ec88db";

export default node;
