/**
 * @generated SignedSource<<e694c81648373ef74fb6cb4b5c02650d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type summaryPaymentType_gig$data = {
  readonly paymentType: PaymentType | null | undefined;
  readonly " $fragmentType": "summaryPaymentType_gig";
};
export type summaryPaymentType_gig$key = {
  readonly " $data"?: summaryPaymentType_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"summaryPaymentType_gig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "summaryPaymentType_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "b0c9b2ed4a9a32b1d4af4c21a6a38efa";

export default node;
