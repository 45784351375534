import React, { useCallback } from "react";

import { Stack } from "@gigsmart/atorasu";
import { ShiftGroupChat } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import PhoneAction from "@gigsmart/isomorphic-shared/messaging/phone-action";
import { useHistory } from "@gigsmart/kaizoku";
import { HeaderIconButton } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import { showMessageSelectionModal } from "@gigsmart/seibutsu/user-message/shift-group-chat/MessageSelectionModal";

import type { activeEngagementActions_engagement$key } from "./__generated__/activeEngagementActions_engagement.graphql";

const fragmentSpec = graphql`
  fragment activeEngagementActions_engagement on Engagement {
    currentState {
      name
    }
    topic {
      id
      canSendMessages
    }
    gig {
      id
      gigType
      currentState {
        name
      }
      conversation {
        id
      }
      organization {
        name
      }
      requester {
        displayName
      }
      conversationRule
    }
    availableActions
    contactNumber
    id
    capabilities {
      type
    }
    conversation {
      ... on OrganizationWorkerConversation {
        id
      }
      ... on EngagementConversation {
        id
      }
      ... on ShiftGroupConversation {
        id
      }
    }
    ...usePhoneCall_engagement
  }
`;

interface Props {
  engagementRef?: activeEngagementActions_engagement$key | null | undefined;
  showProfileAction?: boolean;
  showPhone?: boolean;
}

export default function ActiveEngagementActions({
  engagementRef,
  showPhone = true,
  showProfileAction
}: Props) {
  const engagement = useRelayFragment(fragmentSpec, engagementRef ?? null);
  const history = useHistory();
  const user = useCurrentUser();
  const capabilities = engagement?.capabilities?.map((c) => c?.type);
  const uriPrefix =
    engagement?.gig?.gigType === "PROJECT" ? "/projects" : "/shifts";

  const iconSize = {
    diameter: 36,
    size: 18
  } as const;

  const handlePress = useCallback(() => {
    const hasShiftGroupChat = !!engagement?.gig?.conversation?.id;
    const canAccessShiftGroupChat =
      !!engagement?.gig &&
      ["ACTIVE", "IN_PROGRESS", "UPCOMING"].includes(
        engagement.gig?.currentState?.name ?? ""
      );

    if (
      ShiftGroupChat.isEnabled() &&
      hasShiftGroupChat &&
      canAccessShiftGroupChat
    ) {
      showMessageSelectionModal({
        orgName:
          engagement.gig?.organization?.name ??
          engagement.gig?.requester?.displayName ??
          "",
        conversationId: engagement?.conversation?.id ?? "",
        shiftGroupChatId: engagement.gig?.conversation?.id ?? ""
      });
    } else {
      history.push(
        `/conversation/${engagement?.conversation?.id}?contextId=${engagement?.gig?.id}`
      );
    }
  }, [engagement?.gig, engagement?.conversation?.id, engagement?.id]);

  if (!engagement) return null;
  return (
    <Stack horizontal>
      {showProfileAction && (
        <HeaderIconButton
          {...iconSize}
          testID="profile-action"
          name="circle-user"
          onPress={() => {
            history.push(`${uriPrefix}/${engagement.id || ""}/requester`);
          }}
        />
      )}
      {showPhone && (
        <PhoneAction
          isWorker
          engagement={engagement}
          calleePhoneNumber={engagement.contactNumber}
          callerPhoneNumber={user?.primaryMobile?.number}
        >
          {({ isCallable, onPress }) => (
            <HeaderIconButton
              {...iconSize}
              color={isCallable ? "blue" : "neutralDark"}
              name="phone"
              onPress={onPress}
            />
          )}
        </PhoneAction>
      )}
      <HeaderIconButton
        {...iconSize}
        testID="message-action"
        name={ShiftGroupChat.select("comment-lines", "message-lines")}
        disabled={
          engagement?.gig?.gigType === "PROJECT"
            ? !capabilities?.includes("VIEW_MESSAGE")
            : !engagement.topic?.canSendMessages
        }
        onPress={handlePress}
      />
    </Stack>
  );
}
