/**
 * @generated SignedSource<<6ec34e68f7220870a95c0ae5aa6bc9cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileCard_Worker$data = {
  readonly directHireBonus: string;
  readonly " $fragmentType": "ProfileCard_Worker";
};
export type ProfileCard_Worker$key = {
  readonly " $data"?: ProfileCard_Worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileCard_Worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileCard_Worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireBonus",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "afad89761b9f4784c02a805a6b2b4584";

export default node;
