/**
 * @generated SignedSource<<91eb13b41686226b8ab563f3b93c25e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NegotiableGigField = "STARTS_AT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type projectSummaryDateTime_gig$data = {
  readonly negotiable: ReadonlyArray<NegotiableGigField> | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentType": "projectSummaryDateTime_gig";
};
export type projectSummaryDateTime_gig$key = {
  readonly " $data"?: projectSummaryDateTime_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectSummaryDateTime_gig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectSummaryDateTime_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "negotiable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "cdcbb2692ce03df5ea727fe2a9ecbcfd";

export default node;
