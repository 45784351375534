import {
  Button,
  Card,
  CardHeader,
  Column,
  ContentArea,
  DateTime,
  IconText,
  Row,
  Stack,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { where } from "@gigsmart/biruda";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createRelayFragmentContainer,
  graphql,
  useRelayOrchestrator,
  useRelaySubscription
} from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React from "react";
import type { ShiftGroupChatShiftCardQuery } from "./__generated__/ShiftGroupChatShiftCardQuery.graphql";
import type { ShiftGroupChatShiftCardSubscription } from "./__generated__/ShiftGroupChatShiftCardSubscription.graphql";
import type { ShiftGroupChatShiftCard_conversation$key } from "./__generated__/ShiftGroupChatShiftCard_conversation.graphql";

interface Props {
  variant: "worker" | "requester";
  onPress: () => void;
}

export function ShiftGroupChatShiftCard({
  id,
  gig,
  participant,
  onPress
}: FragmentContainerInnerComponentProps<
  ShiftGroupChatShiftCard_conversation$key,
  Props
>) {
  const history = useHistory();
  const isMediumUp = useMatchesViewport((media) => media.size.medium.up);
  return (
    <Card
      hideInnerMargin
      grow
      onPress={onPress}
      eventTargetName="ShiftGroupChatShiftCard"
      testID={`shift-group-chat-shift-card-${id}`}
    >
      <CardHeader size="large" color="primary" variant="well">
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <Text
            color="primary"
            weight="bold"
            testID={`shift-group-chat-shift-card-${id}-unread-count`}
          >
            {participant?.messageStats?.unreadCount} New Shift Group Chat{" "}
            {pluralize(
              participant?.messageStats?.unreadCount,
              "Message",
              false
            )}
          </Text>
          {isMediumUp && (
            <Row justifyContent="center">
              <Button
                testID={`view-shift-group-chat-${id}`}
                outline
                size="small"
                label="View Shift Group Chat"
                icon="messages"
                onPress={() => history.push(`/conversation/${id}`)}
              />
            </Row>
          )}
        </Stack>
      </CardHeader>
      <ContentArea>
        <Stack>
          <Stack horizontal justifyContent="space-between" alignItems="center">
            <Stack size="slim" fill>
              <Text
                weight="bold"
                testID={`shift-group-chat-shift-card-${id}-shift-name`}
              >
                {gig?.name}
              </Text>
              <Text
                variant="note"
                testID={`shift-group-chat-shift-card-${id}-shift-pay-rate`}
              >
                {currency.humanize(gig?.payRate)} •{" "}
                <DateTime
                  testID={`shift-group-chat-shift-card-${id}-shift-time`}
                  startsAt={gig?.startsAt}
                  actualStartsAt={gig?.actualStartsAt}
                  endsAt={gig?.endsAt}
                  timezone={gig?.timezone}
                  size="lg"
                />
              </Text>
              <Text
                variant="note"
                testID={`shift-group-chat-shift-card-${id}-shift-address`}
              >
                {gig?.address ?? gig?.area}
              </Text>
            </Stack>
            <Column>
              <IconText
                icon="chevron-right"
                size="small"
                iconVariant="solid"
                color="primary"
                iconPlacement="right"
                textWeight="bold"
              >
                View{isMediumUp ? " Shift" : ""}
              </IconText>
            </Column>
          </Stack>
          {!isMediumUp && (
            <Row justifyContent="center">
              <Button
                testID={`view-shift-group-chat-${id}`}
                outline
                size="small"
                label="View Shift Group Chat"
                icon="messages"
                onPress={() => history.push(`/conversation/${id}`)}
              />
            </Row>
          )}
        </Stack>
      </ContentArea>
    </Card>
  );
}

export default createRelayFragmentContainer<
  ShiftGroupChatShiftCard_conversation$key,
  Omit<Props, "onPress">
>(
  graphql`
    fragment ShiftGroupChatShiftCard_conversation on ShiftGroupConversation {
      id
      participant {
        messageStats {
          unreadCount
        }
      }
      gig {
        id
        name
        address
        payRate
        startsAt
        actualStartsAt
        endsAt
        timezone
        area
      }
    }
  `,
  (props) => {
    const history = useHistory();
    const { fetchQuery } = useRelayOrchestrator();
    useRelaySubscription<ShiftGroupChatShiftCardSubscription>(
      graphql`
        subscription ShiftGroupChatShiftCardSubscription($conversationId: ID!) {
          conversationUpdated(conversationId: $conversationId) {
            conversation {
              ...ShiftGroupChatShiftCard_conversation
            }
          }
        }
      `,
      { conversationId: props.id ?? "" },
      { subscribe: !!props.id }
    );

    const handlePress = async () => {
      if (props.variant === "requester") {
        history.push(`/gigs/${props.gig?.id}`);
        return;
      }
      if (props.variant === "worker") {
        const res = await fetchQuery<ShiftGroupChatShiftCardQuery>(
          graphql`
            query ShiftGroupChatShiftCardQuery($query: String!) {
              viewer {
                ... on Worker {
                  engagements(first: 1, query: $query) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          `,
          { query: where({ gigId: props.gig?.id }).toString() }
        );
        const engagementId = res?.viewer?.engagements?.edges?.[0]?.node?.id;
        history.push(`/gigs/${engagementId}`);
      }
    };
    return <ShiftGroupChatShiftCard onPress={handlePress} {...props} />;
  }
);
