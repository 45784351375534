import {
  Button,
  ContentArea,
  Divider,
  IconText,
  ProductInfoRow,
  SectionHeader,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import useGigLocalDateTime from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import type { ProjectGigSection_worker$key } from "./__generated__/ProjectGigSection_worker.graphql";

export const ProjectGigSection = ({
  availableProjectGigs
}: FragmentContainerInnerComponentProps<ProjectGigSection_worker$key>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();

  if ((availableProjectGigs?.totalCount ?? 0) === 0) return null;

  const projectList = getConnectionNodes(availableProjectGigs);

  const RenderProductInfoRow = ({
    id,
    availableGigs
  }: {
    id: string;
    availableGigs: ObjectPath<
      ProjectGigSection_worker$key,
      [" $data", "availableProjectGigs", "edges", 0, "node", "availableGigs"]
    >;
  }) => {
    const gig = getConnectionNodes(availableGigs)[0];
    const formattedDate = useGigLocalDateTime(
      { startsAt: gig?.startsAt, timezone: gig?.timezone },
      { dateFormat: "fullDateShort" }
    );

    const flexible =
      !gig?.isAsap && !gig?.startsAt ? "Flexible Schedule" : null;

    return (
      <ProductInfoRow
        testID={`project-gig-${id}`}
        leftSpacing="small"
        onPress={() => navigation.push("BrowseProjectDetails", { id })}
        eventEntityType="WorkerHomeAvailableProjectGig"
        name={
          <Text color="black" variant="subheader">
            {gig?.name ?? ""}
          </Text>
        }
        icon="pen-ruler"
        color="highlight"
        iconSquareVariant="standard"
        note={
          <Text variant="note" color="neutral">
            {flexible ?? formattedDate.dateString}
          </Text>
        }
      />
    );
  };

  return (
    <>
      <ContentArea size="compact">
        <SectionHeader
          left={
            <IconText icon="pen-ruler" textVariant="subheader" color="black">
              Project Gigs
            </IconText>
          }
          variant="none"
          right={
            <Button
              testID="view-all-project-gigs-btn"
              color="primary"
              icon="chevron-right"
              iconPlacement="right"
              label="View All Project Gigs"
              onPress={() => navigation.push("BrowseProjects")}
              variant="clear"
              size="small"
            />
          }
        />
        <Spacer size="compact" />
        <Stack size="compact">
          {projectList.map(({ id, availableGigs }, index) => (
            <RenderProductInfoRow
              key={`availaible-project-${index}`}
              id={id}
              availableGigs={availableGigs}
            />
          ))}
        </Stack>
        <Spacer size="compact" />
      </ContentArea>
      <Divider />
    </>
  );
};

export default createRelayFragmentContainer<ProjectGigSection_worker$key>(
  graphql`
    fragment ProjectGigSection_worker on Worker
    @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableProjectGigs: availableGigSeries(
        first: 3
        input: { gigTypes: [PROJECT], maxDistance: $maxDistance }
      ) {
        edges {
          node {
            id
            availableGigs(first: 1) {
              edges {
                node {
                  name
                  startsAt
                  isAsap
                  timezone
                }
              }
              totalCount
            }
          }
        }
        totalCount
      }
    }
  `,
  ProjectGigSection
);
