import { graphql, useFetchQueryResult } from "@gigsmart/relay";
import { compact } from "lodash";
import { useMemo } from "react";
import type { AvailableShiftsDataQuery } from "./__generated__/AvailableShiftsDataQuery.graphql";

export type AvailableShiftData = {
  id: string;
  area?: string | null;
  endsAt: any;
  startsAt: any;
  distance?: number | null;
  actualStartsAt: any;
  timezone?: string | null;
  isRecurring: boolean;
};

export const useAvailableShiftsData = (id: string) => {
  const [result] = useFetchQueryResult<AvailableShiftsDataQuery>(
    graphql`
      query AvailableShiftsDataQuery($id: ID!, $isSeries: Boolean!) {
        viewer {
          ... on Worker {
            gig(id: $id) @skip(if: $isSeries) {
              ... on Engagement {
                id
                workerDistance
                gig {
                  area
                  startsAt
                  endsAt
                  actualStartsAt
                  timezone
                  isRecurring
                }
              }
              ... on AvailableGigsEdge {
                distance
                node {
                  id
                  area
                  startsAt
                  endsAt
                  actualStartsAt
                  timezone
                  isRecurring
                }
              }
            }
            gigSeries(id: $id) @include(if: $isSeries) {
              node {
                availableGigs(first: 90) {
                  totalCount
                  edges {
                    distance
                    node @trackImpressions(action: VIEWED) {
                      id
                      actualStartsAt
                      startsAt
                      endsAt
                      area
                      timezone
                      isRecurring
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      skip: !id,
      variables: {
        id,
        isSeries: id.startsWith("gigsr")
      }
    }
  );

  const series = result?.viewer?.gigSeries?.node;
  const gigOrEngagement = result?.viewer?.gig;

  return useMemo(() => {
    // handle GigSeries
    const shifts = compact<AvailableShiftData>(
      series?.availableGigs?.edges?.map((edge) =>
        edge?.node
          ? {
              id: edge.node?.id,
              area: edge.node?.area,
              endsAt: edge.node?.endsAt,
              startsAt: edge.node?.startsAt,
              distance: edge.distance,
              actualStartsAt: edge.node?.actualStartsAt,
              timezone: edge.node?.timezone,
              isRecurring: !!edge.node?.isRecurring
            }
          : null
      )
    );

    // handle Engagement
    if (gigOrEngagement?.gig) {
      // Engagement
      shifts.push({
        id: gigOrEngagement.id ?? "",
        area: gigOrEngagement.gig?.area,
        endsAt: gigOrEngagement.gig?.endsAt,
        startsAt: gigOrEngagement.gig?.startsAt,
        distance: gigOrEngagement.workerDistance,
        actualStartsAt: gigOrEngagement.gig?.actualStartsAt,
        timezone: gigOrEngagement.gig?.timezone,
        isRecurring: !!gigOrEngagement.gig?.isRecurring
      });
    } else if (gigOrEngagement?.node) {
      // AvailableGigsEdge
      shifts.push({
        id: gigOrEngagement.node?.id ?? "",
        area: gigOrEngagement.node?.area,
        endsAt: gigOrEngagement.node?.endsAt,
        startsAt: gigOrEngagement.node?.startsAt,
        distance: gigOrEngagement.distance,
        actualStartsAt: gigOrEngagement.node?.actualStartsAt,
        timezone: gigOrEngagement.node?.timezone,
        isRecurring: !!gigOrEngagement.node?.isRecurring
      });
    }

    // handle Gig (not supported)

    return { shifts };
  }, [series, gigOrEngagement]);
};
