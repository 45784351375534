/**
 * @generated SignedSource<<f1ed502c1c886823ef6d3276810a5e94>>
 * @relayHash ffeb69bcb5d14b038a8ccb634b6e7320
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:vOWZq6Zm-LuUOHLGk2lkxbtngqOy7DWb1ELNGcbpJw4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicantDirection = "EMAIL" | "WEBSITE" | "%future added value";
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
export type JobPostingReportingType = "HIDDEN" | "STANDARD" | "%future added value";
export type JobPostingState = "CANCELED" | "DISCARDED" | "DRAFT" | "EXPIRED" | "PUBLISHED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JobPostingSearch = {
  applicantDirection?: ApplicantDirection | null | undefined;
  approved?: boolean | null | undefined;
  assignedAdmins?: ReadonlyArray<string> | null | undefined;
  boosted?: boolean | null | undefined;
  canceled?: boolean | null | undefined;
  endDate?: string | null | undefined;
  excludedAdmins?: ReadonlyArray<string> | null | undefined;
  expired?: boolean | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  maxStartDate?: string | null | undefined;
  maxTransactionCount?: number | null | undefined;
  minStartDate?: string | null | undefined;
  minTransactionCount?: number | null | undefined;
  orderBy?: ReadonlyArray<OrderBy> | null | undefined;
  payRateNegotiable?: boolean | null | undefined;
  paySchedule?: JobPostingPaySchedule | null | undefined;
  reportingType?: ReadonlyArray<JobPostingReportingType> | null | undefined;
  search?: string | null | undefined;
  startDate?: string | null | undefined;
  state?: JobPostingState | null | undefined;
  states?: ReadonlyArray<JobPostingState> | null | undefined;
  visible?: boolean | null | undefined;
};
export type OrderBy = {
  direction?: SortDirection | null | undefined;
  field: string;
};
export type AffiliateJobPostingsInput = {
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  minimumSalary?: string | null | undefined;
  postedAfter?: string | null | undefined;
  search?: string | null | undefined;
};
export type AvailableGigsCardQuery$variables = {
  affiliateJobPostSearch: AffiliateJobPostingsInput;
  jobPostSearch: JobPostingSearch;
  maxDistance: number;
};
export type AvailableGigsCardQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"JobBoardSection_worker" | "ProjectGigSection_worker" | "ShiftGigSection_worker">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"JobBoardSection_root">;
};
export type AvailableGigsCardQuery = {
  response: AvailableGigsCardQuery$data;
  variables: AvailableGigsCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "affiliateJobPostSearch"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobPostSearch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDistance"
},
v3 = {
  "kind": "Variable",
  "name": "maxDistance",
  "variableName": "maxDistance"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 3
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boosted",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AvailableGigsCardQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "affiliateJobPostSearch",
            "variableName": "affiliateJobPostSearch"
          }
        ],
        "kind": "FragmentSpread",
        "name": "JobBoardSection_root"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "FragmentSpread",
                "name": "ShiftGigSection_worker"
              },
              {
                "args": (v4/*: any*/),
                "kind": "FragmentSpread",
                "name": "ProjectGigSection_worker"
              },
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "jobPostSearch",
                    "variableName": "jobPostSearch"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "JobBoardSection_worker"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AvailableGigsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "affiliateJobPostSearch"
          }
        ],
        "concreteType": "AffiliateJobPostingsConnection",
        "kind": "LinkedField",
        "name": "affiliateJobPostings",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AffiliateJobPostingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "externalId",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maximumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salaryInterval",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  (v5/*: any*/),
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "gigTypes",
                        "value": [
                          "PAID",
                          "VOLUNTEER"
                        ]
                      },
                      (v3/*: any*/)
                    ],
                    "kind": "ObjectValue",
                    "name": "input"
                  }
                ],
                "concreteType": "AvailableGigSeriesConnection",
                "kind": "LinkedField",
                "name": "availableGigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigSeriesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeries",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "AvailableGigsConnection",
                            "kind": "LinkedField",
                            "name": "availableGigs",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AvailableGigsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementPaymentInfo",
                                    "kind": "LinkedField",
                                    "name": "estimatedPayment",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "payRate",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "netPay",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Organization",
                                        "kind": "LinkedField",
                                        "name": "organization",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "logoUrl",
                                            "storageKey": null
                                          },
                                          (v8/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": "availableGigs(first:1)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "availableProjectGigs",
                "args": [
                  (v5/*: any*/),
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "gigTypes",
                        "value": [
                          "PROJECT"
                        ]
                      },
                      (v3/*: any*/)
                    ],
                    "kind": "ObjectValue",
                    "name": "input"
                  }
                ],
                "concreteType": "AvailableGigSeriesConnection",
                "kind": "LinkedField",
                "name": "availableGigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigSeriesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeries",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "AvailableGigsConnection",
                            "kind": "LinkedField",
                            "name": "availableGigs",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AvailableGigsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "startsAt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isAsap",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "timezone",
                                        "storageKey": null
                                      },
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": "availableGigs(first:1)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v5/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "search",
                    "variableName": "jobPostSearch"
                  }
                ],
                "concreteType": "AvailableJobPostingsConnection",
                "kind": "LinkedField",
                "name": "availableJobPostings",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableJobPostingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paySchedule",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateNegotiable",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maximumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minimumPayRate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:vOWZq6Zm-LuUOHLGk2lkxbtngqOy7DWb1ELNGcbpJw4",
    "metadata": {},
    "name": "AvailableGigsCardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e974fc294e61e7d51111c367d7d81df4";

export default node;
