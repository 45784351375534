import { type IconName, Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import SummaryRow from "./SummaryRow";
import type { CategorySummaryRow_gigLike$key } from "./__generated__/CategorySummaryRow_gigLike.graphql";

type Props = {
  icon?: IconName;
};

export const CategorySummaryRow = ({
  gigType,
  category,
  icon = "grid"
}: FragmentContainerInnerComponentProps<
  CategorySummaryRow_gigLike$key,
  Props
>) => {
  const { appId } = useAppSettings();
  const missing =
    gigType !== "PROJECT" && appId === "requester" && !category?.displayName;

  return (
    <SummaryRow
      testID="category-summary-row"
      icon={icon}
      field="gig_category_id"
      route="category"
      title="Category"
      missing={missing}
    >
      <Text testID="summary-category">
        {category?.displayName ?? "No Selection"}
      </Text>
    </SummaryRow>
  );
};

export default createRelayFragmentContainer<
  CategorySummaryRow_gigLike$key,
  Props
>(
  graphql`
    fragment CategorySummaryRow_gigLike on GigLike {
      gigType
      category {
        displayName
      }
    }
  `,
  CategorySummaryRow
);
