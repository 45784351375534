import {
  ContentArea,
  Stack,
  StarRating,
  Surface,
  Text
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { EngagementRatingStack_engagement$key } from "./__generated__/EngagementRatingStack_engagement.graphql";

interface Props {
  variant: "worker" | "requester";
}

export function EngagementRatingStack({
  variant,
  reviewOfWorker,
  reviewOfRequester
}: FragmentContainerInnerComponentProps<
  EngagementRatingStack_engagement$key,
  Props
>) {
  const ratingGiven = variant === "worker" ? reviewOfRequester : reviewOfWorker;
  return (
    <Surface>
      <ContentArea>
        <Stack size="medium">
          <Text weight="bold">Rating Given</Text>
          {ratingGiven?.rating ? (
            <StarRating rating={ratingGiven?.rating} variant="singular" />
          ) : (
            <Text color="neutral" testID="rating-given-placeholder">
              You have not left your review yet.
            </Text>
          )}
          {(!ratingGiven || ratingGiven?.review) && (
            <Text weight="bold">Comment Given</Text>
          )}
          {!ratingGiven && (
            <Text color="neutral" testID="review-given-placeholder">
              You have not left a comment yet.
            </Text>
          )}
          {ratingGiven?.review && (
            <Text testID="review-given">{ratingGiven?.review}</Text>
          )}
        </Stack>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<
  EngagementRatingStack_engagement$key,
  Props
>(
  graphql`
    fragment EngagementRatingStack_engagement on Engagement {
      reviewOfWorker {
        rating
        review
      }
      reviewOfRequester {
        rating
        review
      }
    }
  `,
  EngagementRatingStack
);
