import React from "react";
import { Platform, ScrollView } from "react-native";

import { Spacer, WysiwygContent } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { shareJob } from "@gigsmart/isomorphic-shared/app/share";
import { IconButton, NavPortal, StyledView } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayFragment
} from "@gigsmart/relay";

import type { jobDetailsQuery } from "./__generated__/jobDetailsQuery.graphql";
import type { jobDetails_jobPosting$key } from "./__generated__/jobDetails_jobPosting.graphql";
import ApplyButtons from "./apply-buttons";
import DetailsCard from "./details-card";
import HiringCompanyCard from "./hiring-company-card";

interface Props {
  jobId: string;
  onPressBack?: () => void;
  isWorker?: boolean;
  onPressApplyViaWeb?: (id: string) => void;
}

export default createSuspendedQueryContainer<jobDetailsQuery, Props>(
  function JobDetails({
    response: result,
    jobId,
    onPressBack,
    onPressApplyViaWeb,
    isWorker = false
  }) {
    const jobPost = useRelayFragment<jobDetails_jobPosting$key>(
      graphql`
        fragment jobDetails_jobPosting on JobPosting
        @argumentDefinitions(isWorker: { type: "Boolean!" }) {
          description
          ...applyButtons_jobPosting
          ...detailsCard_jobPosting
          organization @include(if: $isWorker) {
            ...hiringCompanyCard_organization
          }
        }
      `,
      (isWorker
        ? result?.trackedJob
        : result?.node) as jobDetails_jobPosting$key
    );
    const styles = useStyles(({ getColor, getUnits }) => ({
      flexGrow: {
        flexGrow: 1
      },
      modal: {
        paddingHorizontal: getUnits(4),
        paddingBottom: getUnits(4),
        backgroundColor: getColor("background", "fill")
      }
    }));
    return (
      <StyledView
        fill={1}
        color={isWorker ? "transparent" : "neutralLightGray"}
        horizontalPadding={0}
        verticalPadding={0}
      >
        {!isWorker && <Spacer />}
        {isWorker && (
          <NavPortal.Entrance
            showBack
            onPressBack={onPressBack}
            renderRightIcon={() => (
              <IconButton
                diameter={50}
                icon="external-link"
                iconColor="neutralLight"
                iconVariant="solid"
                onPress={() => shareJob(jobId)}
                width={50}
                testID="icon-share-button"
              />
            )}
          />
        )}
        <StyledView
          color="neutralLightGray"
          horizontalPadding={isWorker ? 0 : 4}
          verticalPadding={0}
        >
          <HiringCompanyCard
            organization={
              (isWorker
                ? jobPost?.organization
                : result?.viewer?.primaryOrganization) ?? null
            }
          />
        </StyledView>
        <ScrollView
          contentContainerStyle={[styles.flexGrow, !isWorker && styles.modal]}
        >
          <Spacer />
          <DetailsCard
            jobPost={jobPost ?? null}
            isWorker={isWorker}
            worker={isWorker ? result?.viewer : null}
            onPressApplyViaWeb={onPressApplyViaWeb}
          />
          <Spacer />
          <StyledView fill={1}>
            <WysiwygContent
              fontSize={Platform.OS !== "web" ? 12 : undefined}
              html={jobPost?.description}
            />
          </StyledView>
          <StyledView>
            <ApplyButtons
              jobPost={jobPost ?? null}
              isWorker={isWorker}
              worker={isWorker ? result?.viewer : null}
              onPressApplyViaWeb={onPressApplyViaWeb}
            />
          </StyledView>
        </ScrollView>
      </StyledView>
    );
  },
  {
    query: graphql`
      query jobDetailsQuery($id: ID!, $isWorker: Boolean!) {
        trackedJob: node(id: $id)
          @include(if: $isWorker)
          @trackImpressions(action: VIEWED) {
          ... on JobPosting {
            ...jobDetails_jobPosting @arguments(isWorker: $isWorker)
          }
        }
        node(id: $id) @skip(if: $isWorker) {
          ... on JobPosting {
            ...jobDetails_jobPosting @arguments(isWorker: $isWorker)
          }
        }
        viewer {
          ... on RequesterLike @skip(if: $isWorker) {
            primaryOrganization {
              ...hiringCompanyCard_organization
            }
          }
          ... on Worker @include(if: $isWorker) {
            firstName
            lastName
            primaryEmail {
              address
            }
            primaryMobile {
              number
            }
          }
        }
      }
    `,
    variables: ({ jobId, isWorker = false }) => ({ id: jobId, isWorker })
  }
);
