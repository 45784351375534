import {
  Button,
  Column,
  Row,
  type StepProps,
  StepSubmit,
  Text,
  showMediaPicker,
  toast,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Form, Validator, useFormField } from "@gigsmart/fomu";
import { AvatarPicker } from "@gigsmart/isomorphic-shared/media";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import type { OnboardingData } from "@gigsmart/seibutsu/user/Onboarding/types";
import useAddUserFile from "@gigsmart/seibutsu/utils/useAddUserFile";
import React, { useRef, useState } from "react";
import { Image } from "react-native";
import PhotoEducationModal from "../app/worker-avatar-picker/photo-education-modal";
import type { profilePhotoStepMutation } from "./__generated__/profilePhotoStepMutation.graphql";

type Props = StepProps<OnboardingData>;

export default function ProfilePhotoStep({ stepper }: Props) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);

  const styles = useStyles(() => ({
    mdContainer: { minHeight: 520 }
  }));

  const { uploadFile, loading } = useAddUserFile();

  const [setUserProfilePhoto] =
    useRelayMutation<profilePhotoStepMutation>(graphql`
      mutation profilePhotoStepMutation($input: SetUserProfilePhotoInput!) {
        setUserProfilePhoto(input: $input) {
          user {
            profilePhoto {
              url
            }
          }
        }
      }
    `);

  return (
    <Form
      onSubmit={({ values }, done) => {
        const { id: photoId, public: isPublic = true } =
          values.profilePhoto || {};

        setUserProfilePhoto(
          { input: { photoId, public: isPublic } },
          {
            onSuccess: (res) =>
              stepper.nextStep({
                photo: res.setUserProfilePhoto?.user.profilePhoto?.url
              }),
            onError: (err) => {
              toast.error(err.message || "Cannot upload photo");
              done();
            }
          }
        );
      }}
    >
      <Column
        grow
        testID="profile-photo-screen"
        style={isMd && styles.mdContainer}
      >
        <Column fill gap="standard" justifyContent="space-between">
          <Text>Add a professional photo to your worker profile</Text>
          <WorkerAvatarPicker onUploadFile={uploadFile} />
        </Column>
        <StepSubmit testID="next-btn" label="Next" loading={loading} />
      </Column>
    </Form>
  );
}

type WorkerAvatarPickerProps = {
  onUploadFile: (value: string[]) => Promise<{ url: string } | null>;
};
function WorkerAvatarPicker({ onUploadFile }: WorkerAvatarPickerProps) {
  const { value, setValue } = useFormField({
    name: "profilePhoto",
    validates: Validator.presence()
  });
  const [informationalModalVisible, setInformationalModalVisible] =
    useState(false);
  const initialTabRef = useRef<"camera" | "gallery">("camera");

  const handleInfoModal = (tab: "camera" | "gallery") => {
    initialTabRef.current = tab;
    setInformationalModalVisible(true);
  };

  const handleMediaPicker = () => {
    setInformationalModalVisible(false);
    setTimeout(() => {
      showMediaPicker({
        preview: "circle",
        crop: "circle",
        front: true,
        initialTab: initialTabRef.current,
        onSelect: async (uris: string[]) => {
          const file = await onUploadFile(uris);
          if (file) setValue(file);
        }
      });
    }, 500);
  };

  return (
    <>
      <AvatarPicker
        value={value}
        onChange={setValue}
        width={120}
        onOpen={() => handleInfoModal("camera")}
      />
      <Row gap="standard">
        <Column fill alignItems="center" gap="compact">
          <Image
            source={require("./photo-education/1.png")}
            resizeMode="contain"
          />
          <Text variant="note" align="center">
            Clearly Show Your Face
          </Text>
        </Column>
        <Column fill alignItems="center" gap="compact">
          <Image
            source={require("./photo-education/2.png")}
            resizeMode="contain"
          />
          <Text variant="note" align="center">
            Use a Solid Background
          </Text>
        </Column>
        <Column fill alignItems="center" gap="compact">
          <Image
            source={require("./photo-education/3.png")}
            resizeMode="contain"
          />
          <Text variant="note" align="center">
            Dress Professionally
          </Text>
        </Column>
      </Row>
      <Row gap="standard" justifyContent="flex-end">
        <Button
          fill
          outline
          label="Take Photo"
          testID="take-photo-btn"
          icon="camera"
          onPress={() => handleInfoModal("camera")}
        />
        <Button
          fill
          outline
          label="From Library"
          testID="from-library-btn"
          icon="images"
          onPress={() => handleInfoModal("gallery")}
        />
      </Row>
      <PhotoEducationModal
        informationalModalVisible={informationalModalVisible}
        openPicker={handleMediaPicker}
      />
    </>
  );
}
