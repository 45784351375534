import {
  Button,
  Column,
  List,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import React from "react";
import { Image } from "react-native";

interface Props {
  informationalModalVisible: boolean;
  openPicker: () => void;
}

export default ({ informationalModalVisible, openPicker }: Props) => {
  const styles = useStyles(() => ({
    img: { height: 70, width: 70, borderRadius: 35 }
  }));
  return (
    <Modal
      eventContext="Profile Photo Education Modal"
      visible={informationalModalVisible}
      onClose={openPicker}
    >
      <ModalHeader
        title="Stand Out."
        subTitle="Add a Professional Profile Photo"
        subTitleItalic={false}
      />
      <ModalBody gap="standard">
        <Row gap="large" alignItems="center" justifyContent="space-between">
          <Image
            source={require("./profile-photo-examples/1.png")}
            style={styles.img}
            resizeMode="contain"
          />
          <Image
            source={require("./profile-photo-examples/2.png")}
            style={styles.img}
            resizeMode="contain"
          />
          <Image
            source={require("./profile-photo-examples/3.png")}
            style={styles.img}
            resizeMode="contain"
          />
        </Row>
        <Text>
          Workers with professional looking profile photos are more likely to
          get hired for Gigs.
        </Text>

        <Column gap="compact">
          <Text weight="bold">
            Tips on taking a professional profile photo:
          </Text>
          <List variant="bullet" size="slim">
            <Text>Profile photo should clearly show your face</Text>
            <Text>Use solid backgrounds</Text>
            <Text>Make sure there is enough light</Text>
            <Text>Dress professionally</Text>
            <Text>Smile!</Text>
          </List>
        </Column>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Got It"
          onPress={openPicker}
          testID="profile-picture-info-modal-success"
        />
      </ModalFooter>
    </Modal>
  );
};
