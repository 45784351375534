/**
 * @generated SignedSource<<636a0b24f58f77b2cd8af27d9b217bb0>>
 * @relayHash d07bffa46cd0ba9388bafdd4b43e685f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:XBIZs8FcbIDGYWSGXWafiz37KNlDxz-ejth_PlHA9Sc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AffiliateJobPostingsInput = {
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  minimumSalary?: string | null | undefined;
  postedAfter?: string | null | undefined;
  search?: string | null | undefined;
};
export type jobsListAffiliateJobPostingsPaginationQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  input?: AffiliateJobPostingsInput | null | undefined;
};
export type jobsListAffiliateJobPostingsPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"jobsList_affiliateJobPostings">;
};
export type jobsListAffiliateJobPostingsPaginationQuery = {
  response: jobsListAffiliateJobPostingsPaginationQuery$data;
  variables: jobsListAffiliateJobPostingsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "input",
  "variableName": "input"
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "jobsListAffiliateJobPostingsPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "jobsList_affiliateJobPostings"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "jobsListAffiliateJobPostingsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AffiliateJobPostingsConnection",
        "kind": "LinkedField",
        "name": "affiliateJobPostings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AffiliateJobPostingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": "affiliateJobPost",
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "snippet",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salaryInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maximumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "industryName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AffiliateJobHiringCompany",
                    "kind": "LinkedField",
                    "name": "hiringCompany",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "input"
        ],
        "handle": "connection",
        "key": "jobsList_affiliateJobPostings",
        "kind": "LinkedHandle",
        "name": "affiliateJobPostings"
      }
    ]
  },
  "params": {
    "id": "qpid:XBIZs8FcbIDGYWSGXWafiz37KNlDxz-ejth_PlHA9Sc",
    "metadata": {},
    "name": "jobsListAffiliateJobPostingsPaginationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "683ec4f8435025ff782bc716cdd7d440";

export default node;
