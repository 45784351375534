import {
  ModalFooter,
  ModalHeader,
  Stack,
  Surface,
  Text,
  showModal,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormField } from "@gigsmart/fomu";
import React from "react";
import FomuSubmit from "../../fomu/inputs/FomuSubmit";
import ShiftGroupChatSettingsFields from "./ShiftGroupChatSettingsFields";
import { type GigConversationRule, useSetConversationRule } from "./helpers";

interface Props {
  gigId: string;
  conversationRule: GigConversationRule;
}

export function showShiftGroupChatSettingsModal({
  gigId,
  conversationRule
}: Props) {
  return showModal({
    eventContext: "shift-group-chat-settings-modal",
    children: (close) => (
      <ShiftGroupChatSettingsModal
        gigId={gigId}
        conversationRule={conversationRule}
        onClose={close}
      />
    )
  });
}

export default function ShiftGroupChatSettingsModal({
  gigId,
  conversationRule,
  onClose
}: Props & { onClose: () => void }) {
  const setConversationRule = useSetConversationRule();
  const handleSubmit: FomuSubmitFn = async ({ values }, done) => {
    try {
      await setConversationRule({
        gigLikeId: gigId,
        conversationRule: values.conversationRule
      });
      toast.success("Shift Group Chat Settings Updated");
      onClose();
    } finally {
      done();
    }
  };

  return (
    <Form initialValues={{ conversationRule }} onSubmit={handleSubmit}>
      <ModalHeader />
      <Stack testID="shift-group-chat-settings-modal">
        <Text variant="header" weight="bold" color="primary" align="center">
          Shift Group Chat Settings
        </Text>
        <Surface variant="outline">
          <FormField name="conversationRule">
            {({ value, setValue }) => (
              <ShiftGroupChatSettingsFields
                showActions
                value={value}
                onChange={setValue}
              />
            )}
          </FormField>
        </Surface>
        <FomuSubmit
          testID="shift-group-chat-settings-modal-save-btn"
          label="Save"
        />
      </Stack>
      <ModalFooter />
    </Form>
  );
}
