/**
 * @generated SignedSource<<ef1c44d4492f34e40baa18d36bbe1489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CancellationQuestionnaire_engagement$data = {
  readonly cancellationReasonCategories: ReadonlyArray<{
    readonly id: string;
    readonly summary: string;
    readonly " $fragmentSpreads": FragmentRefs<"CancellationReasonQuestion_reason">;
  }>;
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly gigType: GigType | null | undefined;
    readonly id: string;
    readonly qualificationsCount: {
      readonly totalCount: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"MissingQualificationsQuestion_Gig">;
  };
  readonly id: string;
  readonly worker: {
    readonly displayName: string | null | undefined;
  };
  readonly " $fragmentType": "CancellationQuestionnaire_engagement";
};
export type CancellationQuestionnaire_engagement$key = {
  readonly " $data"?: CancellationQuestionnaire_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancellationQuestionnaire_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancellationQuestionnaire_engagement",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": "qualificationsCount",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 0
            },
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE attestable = TRUE"
            }
          ],
          "concreteType": "GigFieldItemsConnection",
          "kind": "LinkedField",
          "name": "gigFieldItems",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "gigFieldItems(first:0,query:\"WHERE attestable = TRUE\")"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MissingQualificationsQuestion_Gig"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementCancellationReasonCategory",
      "kind": "LinkedField",
      "name": "cancellationReasonCategories",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancellationReasonQuestion_reason"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "403b7eee559416140dca244b7b2ed69d";

export default node;
