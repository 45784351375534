import type { ColorProp, IconName } from "@gigsmart/atorasu";
import type {
  EngagementStateAction,
  EngagementStateName
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { graphql } from "@gigsmart/relay";
import { useCallback } from "react";
import { useRelayCommand } from "../relay/use-relay-command";
import type {
  AsyncNegotiateEngagementInput,
  EngagementExtrasNegotiateMutation
} from "./__generated__/EngagementExtrasNegotiateMutation.graphql";

export type BidStateName =
  | "submit"
  | "countered"
  | "accepted"
  | "requested"
  | "offered";

export type BidSpec = {
  key: BidStateName;
  rateLabel: string;
  label: string;
  icon: IconName;
  tintColor: ColorProp;

  /// requester side of things
  billing: {
    icon: IconName;
    label: string;
  };
};

const BID_SPECS: Record<BidStateName, BidSpec> = {
  requested: {
    key: "requested",
    rateLabel: "Offered Hourly Rate",
    label: "Bid Requested",
    icon: "circle-dollar-to-slot",
    tintColor: "info",
    billing: { icon: "hand-holding-dollar", label: "Offered Rate" }
  },
  submit: {
    key: "submit",
    rateLabel: "Submitted Bid",
    label: "Bid Requested",
    icon: "circle-dollar-to-slot",
    tintColor: "info",
    billing: { icon: "circle-dollar", label: "Bid Rate" }
  },
  countered: {
    key: "countered",
    rateLabel: "Countered Hourly Rate",
    label: "Bid Countered",
    icon: "comment-dollar",
    tintColor: "warning",
    billing: { icon: "comment-dollar", label: "Counter Rate" }
  },
  accepted: {
    key: "accepted",
    rateLabel: "Accepted Hourly Rate",
    label: "Pending Offer",
    icon: "check",
    tintColor: "success",
    billing: { icon: "circle-dollar", label: "" }
  },
  offered: {
    key: "offered",
    rateLabel: "Offered Hourly Rate",
    label: "Pending Offer",
    icon: "check",
    tintColor: "success",
    billing: { icon: "circle-dollar", label: "" }
  }
};

export function getBidSpec(
  currentState?: {
    action: EngagementStateAction;
    name: EngagementStateName;
  } | null,
  fallbackKey?: BidStateName
) {
  const { name, action } = currentState ?? {};
  if (name === "OFFERED") {
    if (action === "ACCEPT") return BID_SPECS.accepted;
    if (action === "COUNTER_OFFER" || action === "MODIFY_OFFER") {
      return BID_SPECS.countered;
    }
    if (action === "OFFER" || action === "REOFFER" || action === "HIRE") {
      return BID_SPECS.offered;
    }
  } else if (name === "BID_REQUESTED") {
    return BID_SPECS.requested;
  } else if (name === "BID_REVIEW") {
    return BID_SPECS.submit;
  }

  return fallbackKey ? BID_SPECS[fallbackKey] : null;
}

export const useNegotiateEngagement = () => {
  const [negotiateEngagement] =
    useRelayCommand<EngagementExtrasNegotiateMutation>(graphql`
      mutation EngagementExtrasNegotiateMutation(
        $input: AsyncNegotiateEngagementInput!
      ) {
        asyncNegotiateEngagement(input: $input) {
          ...useRelayCommand_payload
        }
      }
    `);

  return useCallback(async (input: AsyncNegotiateEngagementInput) => {
    await negotiateEngagement({ input });
  }, []);
};
