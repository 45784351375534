import React, { type ComponentProps } from "react";
import { TouchableOpacity } from "react-native";
import StyledIcon from "../icon/styled-icon";
import { type ViewStyleProp, theme, useStyles } from "../style";

type Props = ComponentProps<typeof StyledIcon> & {
  testID?: string;
  style?: ViewStyleProp;
  onPress?: () => void;
  diameter?: number;
  disabled?: boolean;
};

const HeaderIconButton = ({
  testID,
  style,
  onPress,
  color = theme.color.blue,
  diameter = theme.units(14),
  variant = "solid",
  size = 20,
  disabled,
  ...iconProps
}: Props) => {
  const { styles } = useStyles(() => ({
    container: { alignItems: "center", justifyContent: "center" }
  }));

  return (
    <TouchableOpacity
      testID={testID}
      style={[styles.container, style, { width: diameter, height: diameter }]}
      onPress={onPress}
      disabled={disabled}
    >
      <StyledIcon
        color={disabled ? theme.color.neutralDark : color}
        variant={variant}
        size={size}
        {...iconProps}
      />
    </TouchableOpacity>
  );
};

export default HeaderIconButton;
