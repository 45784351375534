import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import React from "react";
import { Pressable, View } from "react-native";

import { type IconName, type IconVariant, StyledIcon } from "../icon";
import {
  type StylesProps,
  type TextStyleProp,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import StyledText from "../text/styled-text";
import KatanaTouchableOpacity from "./katana-touchable-opacity";

type Props = StylesProps &
  EventerProps<"pressed"> & {
    testID?: string;
    icon?: IconName;
    text?: string;
    disabled?: boolean;
    backgroundColor: string | null | undefined;
    borderColor?: string | null | undefined;
    diameter: number;
    fontColor?: string;
    active: boolean;
    iconColor: string;
    iconVariant?: IconVariant;
    label?: string;
    style?: ViewStyleProp;
    labelStyle?: TextStyleProp;
    width: string | number;
    padding?: boolean;
    numberOfLines: number;
    webProps?: Record<string, any>;
    disableIconHeight: boolean;
    badgeCount?: number;
    onPress?: () => unknown;
  };

@withStyles(({ color, font, units }) => ({
  outerContainer: {
    alignItems: "center"
  },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    ...font.body("bold"),
    fontSize: font.size.medium,
    textAlign: "center",
    color: color.blue
  },
  labelContainer: {
    padding: 5
  },
  badgeContainer: {
    borderRadius: units(2),
    height: units(4),
    minWidth: units(4),
    justifyContent: "center",
    backgroundColor: color.orange,
    position: "absolute",
    top: units(2),
    left: units(6)
  },
  badge: {
    color: color.white,
    fontSize: font.size.small,
    textAlign: "center"
  }
}))
@withEventers<"pressed", Props>("Icon Button", ["pressed"], "label")
export default class IconButton extends React.Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    eventers: eventerStubs<"pressed">(["pressed"]),
    backgroundColor: "transparent",
    iconColor: "white",
    active: false,
    width: 100,
    diameter: 80,
    disabled: false,
    numberOfLines: 2,
    disableIconHeight: false
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      // Shallow checks
      nextProps.backgroundColor !== this.props.backgroundColor ||
      nextProps.diameter !== this.props.diameter ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.icon !== this.props.icon ||
      nextProps.iconColor !== this.props.iconColor ||
      nextProps.active !== this.props.active ||
      nextProps.width !== this.props.width || // Deep checks
      !isEqual(nextProps.style, this.props.style) ||
      (nextProps.onPress ?? noop).toString() !==
        (this.props.onPress ?? noop).toString()
    );
  }

  get inlineStyle() {
    const {
      iconColor,
      backgroundColor,
      borderColor,
      diameter,
      active,
      disableIconHeight,
      theme
    } = this.props;
    const currentBorderColor = active ? backgroundColor : borderColor;
    const currentBackgroundColor = active
      ? borderColor ?? iconColor
      : backgroundColor;
    return {
      backgroundColor:
        typeof currentBackgroundColor === "string"
          ? theme.color.getColor(currentBackgroundColor)
          : undefined,
      borderColor:
        typeof currentBorderColor === "string"
          ? theme.color.getColor(currentBorderColor)
          : undefined,
      borderRadius: Math.floor(diameter / 2),
      borderWidth: active || typeof borderColor === "string" ? 2 : 0,
      height: disableIconHeight ? diameter / 2 : diameter,
      width: diameter
    };
  }

  get iconStyle() {
    const { backgroundColor, borderColor, diameter, iconColor, active, theme } =
      this.props;
    const iconScale =
      (active || typeof backgroundColor === "string") ?? borderColor
        ? 2.5
        : 1.5;
    const currentIconColor = active ? backgroundColor : iconColor;
    return {
      color:
        typeof currentIconColor === "string"
          ? theme.color.getColor(currentIconColor)
          : undefined,
      fontSize: diameter / iconScale
    };
  }

  get textStyle() {
    const { backgroundColor, borderColor, diameter, iconColor, active, theme } =
      this.props;
    const iconScale =
      (active || typeof backgroundColor === "string") ?? borderColor
        ? 2.5
        : 1.5;
    const currentIconColor = active ? backgroundColor : iconColor;
    return {
      color:
        typeof currentIconColor === "string"
          ? theme.color.getColor(currentIconColor)
          : undefined,
      fontSize: diameter / iconScale,
      lineHeight: diameter / iconScale
    };
  }

  colorStyle = (color?: string) => {
    return { color };
  };

  widthStyle = (width: string | number) => {
    return typeof this.props.label === "string" ? { width } : {};
  };

  handlePress = () => {
    this.props.eventers.pressed();
    if (this.props.onPress) this.props.onPress();
  };

  render() {
    const {
      disabled,
      icon,
      label,
      numberOfLines,
      onPress,
      style,
      labelStyle,
      padding,
      styles,
      testID,
      backgroundColor,
      text,
      width,
      webProps,
      theme,
      badgeCount
    } = this.props;
    let { iconVariant } = this.props;
    const paddingStyle = { padding: padding ? 5 : 0 };
    const desiredColor = this.props.fontColor ?? this.props.iconColor;
    const fontColor =
      typeof desiredColor === "string"
        ? theme.color.getColor(desiredColor)
        : undefined;

    const Touchable = disabled ?? !onPress ? Pressable : KatanaTouchableOpacity;

    if (typeof iconVariant === "undefined") {
      iconVariant = backgroundColor !== "transparent" ? "solid" : undefined;
    }
    return (
      <View
        style={[
          styles.outerContainer,
          this.widthStyle(width),
          paddingStyle,
          style
        ]}
      >
        <Touchable
          testID={testID ?? "icon-button"}
          disabled={disabled}
          eventTargetName="Icon Button"
          onPress={disabled ? undefined : this.handlePress}
          {...webProps}
        >
          <View style={[styles.iconContainer, this.inlineStyle]}>
            {typeof icon === "string" && (
              <StyledIcon
                name={icon}
                style={this.iconStyle}
                variant={iconVariant}
              />
            )}
            {typeof text === "string" && typeof icon === "undefined" && (
              <StyledText style={this.textStyle}>{text}</StyledText>
            )}
            {Number(badgeCount) > 0 ? (
              <View style={styles.badgeContainer}>
                <StyledText style={styles.badge}>{badgeCount}</StyledText>
              </View>
            ) : null}
          </View>
        </Touchable>
        {typeof label === "string" && (
          <View style={styles.labelContainer}>
            <StyledText
              testID="icon-button-text"
              numberOfLines={numberOfLines}
              onPress={disabled ? undefined : this.handlePress}
              style={[styles.text, this.colorStyle(fontColor), labelStyle]}
            >
              {label}
            </StyledText>
          </View>
        )}
      </View>
    );
  }
}
