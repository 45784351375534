/**
 * @generated SignedSource<<dcf407cb81105d889c4166580e140ddd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClockinLocationCard_organizationLocation$data = {
  readonly arrivalInstructions: string | null | undefined;
  readonly attachments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly filename: string | null | undefined;
        readonly id: string;
        readonly mimeType: string;
        readonly url: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly locationInstructions: string | null | undefined;
  readonly place: {
    readonly id: string;
    readonly location: {
      readonly latitude: number;
      readonly longitude: number;
    } | null | undefined;
    readonly streetAddress: string | null | undefined;
  };
  readonly " $fragmentType": "ClockinLocationCard_organizationLocation";
};
export type ClockinLocationCard_organizationLocation$key = {
  readonly " $data"?: ClockinLocationCard_organizationLocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClockinLocationCard_organizationLocation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClockinLocationCard_organizationLocation",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "arrivalInstructions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locationInstructions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        }
      ],
      "concreteType": "OrganizationLocationAttachmentsConnection",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationLocationAttachmentsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationLocationAttachment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "filename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mimeType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "attachments(first:50)"
    }
  ],
  "type": "OrganizationLocation",
  "abstractKey": null
};
})();

(node as any).hash = "39e28aef56d68ebd444ded4d053a5fbd";

export default node;
