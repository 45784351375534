import React, { useState, useEffect } from "react";
import { Animated, Keyboard, Platform } from "react-native";

import {
  Column,
  ContentArea,
  Icon,
  IconText,
  Spacer,
  Stack,
  Text,
  useDebouncedCallback
} from "@gigsmart/atorasu";
import { useEventer } from "@gigsmart/dekigoto";
import {
  type Location,
  useLocationAutocomplete
} from "@gigsmart/isomorphic-shared/geolocation";
import { SmallOutlineButton, StyledTextInput } from "@gigsmart/katana";
import type { PredictionResult } from "@gigsmart/katana/input/predictive-input";
import { useFetchPlaceDetails } from "@gigsmart/seibutsu/place/hooks";
import { useGeolocation } from "../../../geolocation";
import AddressResults from "./address-results";
import RadiusDropdown from "./radius-dropdown";
import RadiusInput from "./radius-input";

interface Props {
  onSearchLocation: (
    miles: number,
    location: { label: string; lat: number; lng: number }
  ) => void;
  radiusMiles: number;
  options: Array<{ shortLabel?: string; label: string; value: number }>;
  location: { label: string; lat: number; lng: number };
  locationDisabled?: boolean;
  crisis?: { id?: string; title?: string } | null;
  onClearCrisis?: () => void;
}

const LocationSearch = ({
  onSearchLocation,
  radiusMiles,
  options,
  location,
  locationDisabled,
  crisis,
  onClearCrisis
}: Props) => {
  const userCoords = useGeolocation();
  const [locationSearch, setLocationSearch] = useState<string | undefined>(
    location.label
  );

  const [locationResults, setLocationResults] =
    useState<Array<PredictionResult<Location>>>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeLineScale] = useState(new Animated.Value(0.01));
  const [tempValue, setTempValue] = useState(radiusMiles);
  useEffect(() => setTempValue(radiusMiles), [radiusMiles]);
  const trackPress = useEventer(
    "Set search radius of",
    `${tempValue}`,
    "miles"
  );
  const selectedOption = options.find((d) => d.value === radiusMiles);
  const radiusLabel = selectedOption?.shortLabel ?? `${radiusMiles} mi`;

  const toggleDropdown = () => {
    if (!showDropdown) setLocationResults(undefined);
    const scale = showDropdown ? 0.01 : 1.0;
    Keyboard.dismiss();
    Animated.timing(activeLineScale, {
      toValue: scale,
      duration: 250,
      useNativeDriver: Platform.OS !== "web"
    }).start();
    setShowDropdown(!showDropdown);
  };
  const selectRadiusMiles = (value: number) => {
    onSearchLocation(value, location);
    setTempValue(value);
    toggleDropdown();
  };

  const search = useLocationAutocomplete("LOCALITY");
  const doSearch = useDebouncedCallback(async (value: string) => {
    if (value) search(value).then(setLocationResults);
  }, 500);

  const fetchPlaceDetails = useFetchPlaceDetails();

  const searchLocations = (value: string) => {
    setLocationSearch(value);
    void doSearch(value);
  };

  const handleSelectLocation = async (
    locationValue: PredictionResult<Location>
  ) => {
    Keyboard.dismiss();
    setLocationResults(undefined);
    setLocationSearch(locationValue.label);
    if (!locationValue.data?.placeId) return;
    const place = (
      await fetchPlaceDetails(locationValue.data.placeId).toPromise()
    )?.place;
    if (place) {
      const cityState = place?.area ?? undefined;
      onSearchLocation(radiusMiles, {
        label: cityState ?? "",
        lat: place?.location?.latitude ?? userCoords?.latitude ?? 0,
        lng: place?.location?.longitude ?? userCoords?.longitude ?? 0
      });
    }
  };
  return (
    <>
      <ContentArea size="none">
        <Stack horizontal>
          <Column fill={0.8} alignSelf="flex-start">
            {locationDisabled ? (
              <Stack size="compact">
                <Text variant="subheader" color="primary">
                  Location
                </Text>
                <IconText icon="location-dot" color="primary" spacing="medium">
                  <Text color="black">{location.label}</Text>
                </IconText>
              </Stack>
            ) : (
              <StyledTextInput
                placeholder="Enter Address"
                value={locationSearch}
                onChangeText={searchLocations}
                eventTargetName="Input Job List Location"
                label="Location"
                onFocus={() => showDropdown && toggleDropdown()}
                clearable
                renderLeftAccessory={() => (
                  <Icon name="location-dot" color="primary" size="tiny" />
                )}
              />
            )}
          </Column>
          <Column fill={0.2} alignSelf="flex-end">
            <RadiusInput
              label={radiusLabel}
              showDropdown={showDropdown}
              toggleDropdown={toggleDropdown}
              activeLineScale={activeLineScale}
            />
          </Column>
        </Stack>
      </ContentArea>
      <Spacer />
      {!!crisis && (
        <Stack alignItems="flex-start" horizontal>
          <SmallOutlineButton
            tintColor="orange"
            title={crisis.title || "   "}
            icon="times"
            iconPosition="right"
            onPress={onClearCrisis}
          />
        </Stack>
      )}
      <Spacer size={crisis ? "standard" : "compact"} />
      {showDropdown && (
        <RadiusDropdown
          options={options}
          // biome-ignore lint/style/noCommaOperator: <explanation>
          setTempValue={(trackPress(), setTempValue)}
          tempValue={tempValue}
          selectRadiusMiles={selectRadiusMiles}
        />
      )}
      {locationResults && (
        <AddressResults
          locations={locationResults}
          selectLocation={handleSelectLocation}
          searchTerm={locationSearch}
        />
      )}
    </>
  );
};

export default LocationSearch;
