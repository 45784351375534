/**
 * @generated SignedSource<<2ec7210d1c1a6dbae8ca7a37c97c41bc>>
 * @relayHash 8a064452b52dfd6e7a6e2a15de780431
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:nDESVuCUyJ_2d8nlUmwP46CIYQB4z0z9I22nyq5RoN0

import { ConcreteRequest } from 'relay-runtime';
export type SetWorkerLegalInformationInput = {
  administrativeArea1?: string | null | undefined;
  backgroundCheckOptIn?: boolean | null | undefined;
  birthdate?: string | null | undefined;
  driverCheckOptIn?: boolean | null | undefined;
  driversLicenseId?: string | null | undefined;
  driversLicenseIssuer?: string | null | undefined;
  drugScreenOptIn?: boolean | null | undefined;
  emailAddress?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  middleName?: string | null | undefined;
  nationalIdNumber?: string | null | undefined;
  postalCode?: string | null | undefined;
  pspTosAccepted?: boolean | null | undefined;
  sendReportCopies?: boolean | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type applyGigStepperSendReportCopiesMutation$variables = {
  input: SetWorkerLegalInformationInput;
};
export type applyGigStepperSendReportCopiesMutation$data = {
  readonly setWorkerLegalInformation: {
    readonly workerLegalInformation: {
      readonly id: string;
      readonly sendReportCopies: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type applyGigStepperSendReportCopiesMutation$rawResponse = {
  readonly setWorkerLegalInformation: {
    readonly workerLegalInformation: {
      readonly id: string;
      readonly sendReportCopies: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type applyGigStepperSendReportCopiesMutation = {
  rawResponse: applyGigStepperSendReportCopiesMutation$rawResponse;
  response: applyGigStepperSendReportCopiesMutation$data;
  variables: applyGigStepperSendReportCopiesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetWorkerLegalInformationPayload",
    "kind": "LinkedField",
    "name": "setWorkerLegalInformation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerLegalInformation",
        "kind": "LinkedField",
        "name": "workerLegalInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sendReportCopies",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "applyGigStepperSendReportCopiesMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "applyGigStepperSendReportCopiesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:nDESVuCUyJ_2d8nlUmwP46CIYQB4z0z9I22nyq5RoN0",
    "metadata": {},
    "name": "applyGigStepperSendReportCopiesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "75d25e867fbc8e57ce1fd95df049e728";

export default node;
