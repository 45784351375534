import {
  Persistence,
  type StepProps,
  StepSubmit,
  toast
} from "@gigsmart/atorasu";
import { createConversion } from "@gigsmart/dekigoto";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import type { AuthParamList } from "@gigsmart/isomorphic-shared/auth/types";
import {
  type AppNavigationProp,
  useNavigation,
  useRoute
} from "@gigsmart/kaizoku";
import React, { useEffect } from "react";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";
import { useEmailChecker } from "./EmailChecker";
import type { OnboardingData } from "./types";

const RequesterEmailConversionTracker = createConversion(
  "requester_onboarding_email",
  { category: "conversion" }
);

type Props = StepProps<OnboardingData>;

export default function EmailStep({ stepper }: Props) {
  const checkEmail = useEmailChecker();
  const route = useRoute();
  const navigation = useNavigation<AppNavigationProp<AuthParamList, "Login">>();

  useEffect(() => {
    void RequesterEmailConversionTracker.track({
      step_index: stepper.index,
      event: "email_step",
      url: route.path
    });
  }, []);

  const handleSubmit: FomuSubmitFn = async ({ values }) => {
    const address = values.email;
    const status = await checkEmail({
      address,
      userType: stepper.data.userType
    });
    if (status === "AVAILABLE") stepper.nextStep({ email: address });
    else {
      toast.info("Email already in use. Please sign in.");
      void Persistence.save("email", address);
      Persistence.keep("email");
      navigation.push("Login", { method: "email" });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ email: stepper.data.invite?.emailAddress }}
    >
      <FomuTextInput
        name="email"
        validates={Validator.email()}
        testID="email-input"
        label="Email"
        maxLength={345}
        note="Email address is private and not shared with other users."
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="email-address"
        autoFocus
      />
      <StepSubmit testID="next-btn" label="Next" allowDirty />
    </Form>
  );
}
