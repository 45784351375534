import { graphql, readRelayInlineFragment } from "@gigsmart/relay";
import { useMemo } from "react";

import { groupBy, sortBy } from "lodash";
import type { useGigFields_gigOrEngagement$key } from "./__generated__/useGigFields_gigOrEngagement.graphql";

export default function useGigFields(
  gigOrEngagementRef?: useGigFields_gigOrEngagement$key | null
) {
  const gigLike = readRelayInlineFragment(
    graphql`
      fragment useGigFields_gigOrEngagement on GigLike @inline {
        ... on GigSeries {
          requiredQualifications {
            id
            label
            gigField {
              id
              title
              iconName
              order
            }
          }
        }
        ... on Gig {
          requiredQualifications {
            id
            label
            gigField {
              id
              title
              iconName
              order
            }
          }
        }
      }
    `,
    gigOrEngagementRef ?? null
  );

  return useMemo(
    () =>
      sortBy(
        Object.values(
          groupBy(gigLike?.requiredQualifications ?? [], "gigField.id")
        ).map((values) => ({
          ...values[0].gigField,
          selectedItems: values.map((definition) => ({ definition }))
        })),
        "order"
      ),
    [gigLike]
  );
}
