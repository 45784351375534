import { LoadingView } from "@gigsmart/atorasu";
import { reset as dekigotoReset } from "@gigsmart/dekigoto";
import { defaultResetHome } from "@gigsmart/kaizoku";
import {
  graphql,
  useRelayMutationPromise,
  useRelayOrchestrator
} from "@gigsmart/relay";
import React, { useEffect } from "react";
import { Notifications } from "../notifications";
import type { logoutScreenMutation } from "./__generated__/logoutScreenMutation.graphql";

export default function LogoutScreen() {
  const { reset } = useRelayOrchestrator();
  const [revokeCurrentAuthentication] =
    useRelayMutationPromise<logoutScreenMutation>(
      graphql`
        mutation logoutScreenMutation {
          revokeCurrentAuthentication {
            ok
          }
        }
      `
    );

  useEffect(() => {
    const afterLogout = async () => {
      await reset(null).catch(() => {});
      dekigotoReset();
      Notifications.resetBadge();
      setTimeout(() => defaultResetHome({ name: "Login" }), 500);
    };

    void revokeCurrentAuthentication().finally(afterLogout);
  }, []);

  return <LoadingView />;
}
