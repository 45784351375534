import {
  Button,
  Constraint,
  ContentArea,
  Dimmable,
  Divider,
  FooterSpacer,
  Icon,
  IconButton,
  ListRow,
  Platform,
  Screen,
  ScreenTop,
  SectionList,
  Spacer,
  Stack,
  Surface,
  Switch,
  Text,
  TextInput
} from "@gigsmart/atorasu";
import {
  type FlagSpec,
  FlagType,
  disableFeature,
  enableFeature,
  resetFeature,
  resetFeatures,
  sections
} from "@gigsmart/feature-flags/registry";
import { isEqual, orderBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import RNRestart from "react-native-restart";
import { search } from "ss-search";

export default function FlagsDebug() {
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleComplete = useCallback(() => {
    Platform.OS === "web" ? window.location.replace("/") : RNRestart.restart();
  }, []);

  const handleClear = useCallback(async () => {
    await resetFeatures();
    setForceUpdate((idx) => idx + 1);
  }, []);

  const flagsSections = useMemo(
    () =>
      orderBy(sections, ["name"])
        .map(({ name, flags }) => ({
          name,
          keyExtractor: (item: FlagSpec) => item.name,
          data: search(
            orderBy(Object.values(flags), ["flagType", "name"]),
            ["name"],
            searchQuery
          ).map((flag) => flag as FlagSpec)
        }))
        .filter(({ data }) => data.length > 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdate, searchQuery]
  );

  return (
    <Screen testID="feature-debug-screen">
      <ScreenTop showBackButton onBackPress={handleComplete} />
      <Constraint fill size="small">
        <Surface fill color="background">
          <Surface>
            <ContentArea alignItems="center" justifyContent="center">
              <Text
                testID="feature-debug-title"
                align="center"
                variant="titleLg"
              >
                Feature Flags
              </Text>
              <Spacer size="slim" />
              <Text testID="feature-debug-notice" align="center">
                The app will refresh after closing this modal
              </Text>
              <Spacer />
              <Stack horizontal justifyContent="center" alignItems="center">
                <Button
                  variant="rounded"
                  size="small"
                  onPress={handleClear}
                  label="Reset"
                  eventTargetName="Reset Feature Flags Button"
                  testID="feature-reset-button"
                />
                <Button
                  variant="rounded"
                  size="small"
                  onPress={handleComplete}
                  label="Done"
                  eventTargetName="Done Feature Flags Button"
                  testID="feature-done-button"
                />
              </Stack>
            </ContentArea>
            <ContentArea>
              <TextInput
                label="Search Flags"
                testID={"feature-search"}
                onChangeText={setSearchQuery}
                value={searchQuery}
              />
            </ContentArea>
          </Surface>
          <SectionList
            sections={flagsSections}
            renderSectionHeader={({ section: { name } }) => (
              <ContentArea size="compact" variant="compact">
                <Text color="primary" weight="bold">
                  {name.toUpperCase()}
                </Text>
              </ContentArea>
            )}
            ItemSeparatorComponent={Divider}
            renderItem={({ item }) => (
              <Flag
                flag={item}
                version={forceUpdate}
                setForceUpdate={setForceUpdate}
              />
            )}
          />
        </Surface>
      </Constraint>

      <FooterSpacer />
    </Screen>
  );
}

const Flag = React.memo(function Flag({
  flag,
  setForceUpdate
}: {
  flag: FlagSpec;
  version: number;
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>;
}) {
  const handleClearLocal = useCallback(
    (name: string) => {
      void resetFeature(name);
      setForceUpdate((idx) => idx + 1);
    },
    [setForceUpdate]
  );
  const handleSetOverride = useCallback(
    async (name: string, value: boolean) => {
      value
        ? await enableFeature(name, true)
        : await disableFeature(name, true);
      setForceUpdate((idx) => idx + 1);
    },
    [setForceUpdate]
  );
  const isPinned = flag.flagType === FlagType.PINNED;
  const isDisabledType = flag.flagType === FlagType.DISABLED;
  const enabledParent = flag.getEnabledParent();
  const isDisabled = flag.isDisabled();
  return (
    <Dimmable dim={isPinned ? 0.2 : isDisabled ? 0.7 : 1}>
      <Surface variant="flat">
        <ListRow
          testID="feature-row"
          label={
            <>
              <Text variant="bodyLg">{flag.name}</Text>
              {enabledParent && (
                <Stack horizontal size="slim">
                  <Text color="neutral" variant="note">
                    (enabled by {enabledParent.name})
                  </Text>
                  {enabledParent?.isToggleable() && (
                    <Switch
                      size="mini"
                      toggled={enabledParent.isIndividuallyEnabled()}
                      labels={{ true: "ON", false: "OFF" }}
                      onChange={(value: boolean) => {
                        void handleSetOverride(enabledParent.name, !!value);
                      }}
                      testID={`${flag.name}-inherits-${enabledParent.name}-flag-switch`}
                    />
                  )}
                </Stack>
              )}
            </>
          }
          right={
            !flag.isToggleable() ? (
              <Icon
                name={
                  flag.inherits?.isEnabled()
                    ? "arrow-up"
                    : isDisabledType
                      ? "ban"
                      : "thumbtack"
                }
                color="primary"
                variant="solid"
              />
            ) : (
              <Stack horizontal alignItems="center">
                <IconButton
                  testID="feature-row-clear"
                  name={
                    flag.flagType === FlagType.DEBUG
                      ? "wrench"
                      : flag.isOveridden()
                        ? "user-gear"
                        : "satellite-dish"
                  }
                  variant="solid"
                  color={
                    flag.isBlockedByDeps()
                      ? "error"
                      : flag.isOveridden()
                        ? "warning"
                        : flag.isEnabled()
                          ? "primary"
                          : "neutral"
                  }
                  onPress={() => handleClearLocal(flag.name)}
                />
                <Switch
                  toggled={flag.isIndividuallyEnabled()}
                  labels={{ true: "ON", false: "OFF" }}
                  onChange={(value: boolean) => {
                    void handleSetOverride(flag.name, !!value);
                  }}
                  testID={`${flag.name}-flag-switch`}
                />
              </Stack>
            )
          }
        />
      </Surface>
    </Dimmable>
  );
}, isEqual);
