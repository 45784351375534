import {
  ContentArea,
  Divider,
  ExpandableRadioGroup,
  type ExpandableRadioGroupRefType,
  Spacer,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { Validator, useFormField } from "@gigsmart/fomu";
import React, { type ComponentProps, useEffect, useRef } from "react";

export interface Props
  extends Omit<
    ComponentProps<typeof ExpandableRadioGroup>,
    "value" | "onChange"
  > {
  title: string;
  name: string;
  renderChildrenCollapsed?: boolean;
  inset?: boolean;
}

export default function ExpandableRadioQuestion({
  title,
  name,
  inset = true,
  ...props
}: Props) {
  const groupRef = useRef<ExpandableRadioGroupRefType>(null);
  const { value, setValue } = useFormField<string>({
    name,
    validates: Validator.presence()
  });

  useEffect(() => {
    groupRef.current?.expand(value);
  }, [value]);

  const content = (
    <>
      <Text weight="bold">{title}</Text>
      <Spacer />
      <Divider />
      <ExpandableRadioGroup
        ref={groupRef}
        value={value}
        onChange={setValue}
        {...props}
      />
    </>
  );

  return inset ? (
    <Surface>
      <ContentArea>{content}</ContentArea>
    </Surface>
  ) : (
    content
  );
}
