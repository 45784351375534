import React, { type ReactNode } from "react";

import { Card, Constraint, Divider } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { WorkerGigCard } from "../../gig/WorkerGigCard";
import { WorkerEngagementCardFooter } from "./WorkerEngagementCardFooter";
import { WorkerEngagementCardHeader } from "./WorkerEngagementCardHeader";
import type { WorkerEngagementCard_engagement$key } from "./__generated__/WorkerEngagementCard_engagement.graphql";

const showFooter = (
  engagementStateName: string,
  openDisputeExpiration: string | null | undefined,
  isPickupShift: boolean
) => {
  return (
    !!openDisputeExpiration ||
    ["CONFIRMING", "PENDING_TIMESHEET_APPROVAL"].includes(
      engagementStateName
    ) ||
    (engagementStateName === "SCHEDULED" && isPickupShift)
  );
};

interface Props {
  children?: ReactNode;
  onPress?: () => void;
}

/** @deprecated */
export function WorkerEngagementCard({
  id,
  currentState,
  startsAt,
  insertedAt,
  cancellationReason,
  workerDistance,
  paymentInfo,
  estimatedPayment,
  capabilities,
  gig,
  onPress,
  disputes,
  approvedDisputeCount,
  rejectedDisputeCount,
  finalTimesheet,
  systemTimeSheetSubmittedByWorker,
  timeSheetCreatedByWorker,
  pickupStateCount
}: FragmentContainerInnerComponentProps<
  WorkerEngagementCard_engagement$key,
  Props
>) {
  const openDisputeExpiration = disputes?.edges?.[0]?.node?.expiresAt;
  const isPickupShift = (pickupStateCount?.states?.totalCount ?? 0) > 0;
  const footerVisible = showFooter(
    currentState?.name,
    openDisputeExpiration,
    isPickupShift
  );
  return (
    <Constraint size="xsmall" fill>
      <Card
        onPress={onPress}
        testID="worker-engagement-card"
        eventTargetName="Worker Engagement Card"
      >
        <WorkerEngagementCardHeader
          id={id}
          disputes={disputes}
          approvedDisputeCount={approvedDisputeCount}
          rejectedDisputes={rejectedDisputeCount}
          startsAt={startsAt}
          currentState={currentState}
          insertedAt={insertedAt}
          cancellationReason={cancellationReason}
          capabilities={capabilities}
          finalTimesheet={finalTimesheet}
          systemTimeSheetSubmittedByWorker={systemTimeSheetSubmittedByWorker}
          timeSheetCreatedByWorker={timeSheetCreatedByWorker}
        />
        <Divider />
        <WorkerGigCard
          {...gig}
          currentState={currentState}
          distance={workerDistance}
          minPay={
            currentState?.name === "ENDED"
              ? paymentInfo?.netPay
              : estimatedPayment?.netPay
          }
          onPress={onPress}
          isMultiShift={false}
          nestedFooterVisible={footerVisible}
        />
        {footerVisible && (
          <WorkerEngagementCardFooter
            fill
            id={id}
            state={currentState?.name}
            reconcilesAt={gig?.reconcilesAt}
            capabilities={capabilities}
            openDisputeExpiration={openDisputeExpiration}
            isPickupShift={isPickupShift}
          />
        )}
      </Card>
    </Constraint>
  );
}

export default createRelayFragmentContainer<
  WorkerEngagementCard_engagement$key,
  Props
>(
  graphql`
    fragment WorkerEngagementCard_engagement on Engagement {
      id
      startsAt
      insertedAt
      currentState {
        name
        action
      }
      cancellationReason {
        reasonType
        option {
          variant
        }
      }
      estimatedPayment {
        netPay
      }
      paymentInfo {
        netPay
      }
      pickupStateCount: timesheet(variant: SYSTEM) {
        states(first: 0, query: "WHERE action = PICKUP") {
          totalCount
        }
      }
      systemTimeSheetSubmittedByWorker: timesheet(variant: SYSTEM) {
        approvals(first: 0, query: "WHERE approvedByType = WORKER") {
          totalCount
        }
      }
      timeSheetCreatedByWorker: timesheet(variant: WORKER) {
        approvals(first: 0) {
          totalCount
        }
      }
      finalTimesheet: timesheet(variant: FINAL) {
        resolvedDisputesCount: resolvedDisputes(first: 0) {
          totalCount
        }
      }
      disputes(first: 1, query: "WHERE approvalDisposition = NULL") {
        totalCount
        edges {
          node {
            expiresAt
          }
        }
      }
      approvedDisputeCount: disputes(
        first: 0
        query: "WHERE approvalDisposition = APPROVED"
      ) {
        totalCount
      }
      rejectedDisputeCount: disputes(
        first: 1
        query: "WHERE approvalDisposition = REJECTED"
      ) {
        totalCount
        edges {
          node {
            approval {
              submittedBy {
                __typename
              }
            }
          }
        }
      }
      capabilities {
        status
        type
        restrictedBy {
          message
        }
        expiresAt
      }
      workerDistance
      gig {
        id
        name
        area
        payRate
        startsAt
        endsAt
        insertedAt
        reconcilesAt
        position {
          name
        }
        skills(first: 1) {
          totalCount
          edges {
            node {
              skill {
                name
              }
            }
          }
        }
      }
    }
  `,
  WorkerEngagementCard
);
