import { useEventer } from "@gigsmart/dekigoto";
import React, { useCallback, type ComponentProps } from "react";
import { Platform, Switch, View } from "react-native";
import { type ViewStyleProp, createStyles, theme } from "../style";

const { color } = theme;

type Props = ComponentProps<typeof Switch> & {
  containerStyle?: ViewStyleProp;
  onValueChange: ((v: boolean) => unknown) | undefined;
  value: boolean;
  testID?: string;
};

const styles = createStyles(() => ({
  dimensions: {
    ...Platform.select({
      ios: {
        width: 51,
        height: 31
      },
      android: {
        width: 48,
        height: 27
      }
    })
  },
  track: {
    borderRadius: 15
  },
  switch: {
    justifyContent: "center",
    alignItems: "flex-end"
  }
}));

export default function KatanaSwitch({
  containerStyle,
  onValueChange,
  value,
  testID,
  disabled,
  thumbColor
}: Props) {
  const trackSwitch = useEventer(
    `Toggled ${value ? "Off" : "On"}`,
    testID ?? "Unknown",
    "Switch"
  );
  const handleValueChange = useCallback(
    (nextValue: boolean) => {
      trackSwitch();
      onValueChange?.(nextValue);
    },
    [onValueChange, trackSwitch]
  );
  const platformColor =
    Platform.OS === "ios"
      ? color.withOpacity(color.blue, 0.32)
      : color.transparent;
  const trackColor = {
    true: disabled ? platformColor : color.blue,
    false: color.neutralDark
  };
  const viewTrackColor = {
    true: color.blue,
    false: color.neutralDark
  };
  const trackBackground = {
    ...Platform.select({
      web: {
        backgroundColor: disabled
          ? color.transparent
          : viewTrackColor[value.toString() as keyof typeof viewTrackColor]
      },
      ios: {
        backgroundColor: color.withOpacity(
          viewTrackColor[value.toString() as keyof typeof viewTrackColor],
          0.32
        )
      },
      android: {
        backgroundColor: disabled
          ? color.withOpacity(
              viewTrackColor[value.toString() as keyof typeof viewTrackColor],
              0.32
            )
          : viewTrackColor[value.toString() as keyof typeof viewTrackColor]
      }
    })
  };

  return (
    <View
      style={[styles.track, trackBackground, containerStyle, styles.dimensions]}
    >
      <Switch
        {...(Platform.OS === "web"
          ? ({ activeThumbColor: color.white } as any)
          : {})}
        trackColor={trackColor}
        testID={testID ?? "styled-switch"}
        thumbColor={thumbColor ?? color.white}
        onValueChange={handleValueChange}
        value={value}
        disabled={disabled}
        style={styles.switch}
      />
    </View>
  );
}
