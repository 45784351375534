import { ContentArea, Divider, IconText, Surface } from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import ShiftDescriptionHeader from "../gig/ShiftDescriptionHeader";
import ShiftGroupChatActionRow from "../gig/organization/ShiftGroupChatActionRow";
import ShiftOrgActionRow from "../gig/organization/ShiftOrgActionRow";
import WorkerShiftApplets from "./WorkerShiftApplets";
import type { ActiveShiftHeader_engagement$key } from "./__generated__/ActiveShiftHeader_engagement.graphql";
import ShiftActions from "./accessory/ShiftActions";
import ShiftCommitmentReminder from "./accessory/ShiftCommitmentReminder";
import ShiftDescriptionItem from "./accessory/ShiftDescriptionItem";
import ShiftStatusTags from "./accessory/ShiftStatusTags";
import SingleShiftSummary from "./accessory/SingleShiftSummary";
import WorkerShiftStatusText from "./accessory/WorkerShiftStatusText";

type Props = {
  onRefreshLocation: () => void;
};

export const ActiveShiftHeader = ({
  gig,
  currentState,
  commitment,
  result,
  onRefreshLocation
}: FragmentContainerInnerComponentProps<
  ActiveShiftHeader_engagement$key,
  Props
>) => {
  const showParkingInfo =
    LocationUpdates.isEnabled() &&
    !!gig.organizationLocation?.parkingLocations?.totalCount;

  return (
    <Surface>
      <ContentArea gap="standard">
        <IconText
          icon="business-time"
          size="medium"
          iconAlign="center"
          textVariant="header"
        >
          {gig.name}
        </IconText>
        <ShiftStatusTags fragmentRef={result} />
        <WorkerShiftStatusText fragmentRef={result} />
        <ShiftDescriptionItem
          fragmentRef={result}
          showParkingInfo={showParkingInfo}
          showParkingReminder={showParkingInfo}
        />
        <ShiftDescriptionHeader fragmentRef={result} />
        <ShiftActions
          onRefreshLocation={onRefreshLocation}
          fragmentRef={result}
        />
        <ShiftCommitmentReminder fragmentRef={commitment} />
      </ContentArea>
      <Divider />
      <ShiftOrgActionRow fragmentRef={result}>
        <SingleShiftSummary
          fragmentRef={result}
          showAddress={[
            "BID_REQUESTED",
            "BID_REVIEW",
            "OFFERED",
            "APPLIED",
            "CONFIRMING"
          ].includes(currentState?.name)}
        />
        <ShiftGroupChatActionRow fragmentRef={gig} />
      </ShiftOrgActionRow>
      <WorkerShiftApplets showNote fragmentRef={result} />
    </Surface>
  );
};

export default createRelayFragmentContainer<
  ActiveShiftHeader_engagement$key,
  Props
>(
  graphql`
    fragment ActiveShiftHeader_engagement on Engagement {
      ...ShiftOrgActionRow_engagement
      ...SingleShiftSummary_engagement
      ...WorkerShiftStatusText_shift
      ...ShiftStatusTags_shift
      ...ShiftDescriptionHeader_availableGig
      ...ShiftActions_shift
      ...ShiftDescriptionItem_shift
      ...WorkerShiftApplets_shift
      currentState {
        name
      }
      gig {
        ...ShiftGroupChatActionRow_gig
        name
        organizationLocation {
          parkingLocations(first: 1) {
            totalCount
          }
        }
      }
      commitment {
        ...ShiftCommitmentReminder_commitment
      }
    }
  `,
  ActiveShiftHeader
);
