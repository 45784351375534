import {
  Icon,
  Pressable,
  ProgressBar,
  Row,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import HomeScreenCard from "../worker/HomescreenCard";
import useWorkerProfileStrength, {
  type ProfileLapses
} from "./useWorkerProfileStrength";

import { useHistory } from "@gigsmart/kaizoku";
import type { ProfileStrengthCard_worker$key } from "./__generated__/ProfileStrengthCard_worker.graphql";

type Props =
  FragmentContainerInnerComponentProps<ProfileStrengthCard_worker$key>;

export const ProfileStrengthCard = ({ result }: Props) => {
  const history = useHistory();
  const { progress, lapses } = useWorkerProfileStrength(result ?? null);

  const possibleLapses: Array<{
    title: string;
    onPress?: () => void;
    key: ProfileLapses;
  }> = [
    {
      title: "Fill out profile information",
      key: "ONBOARDING"
    },
    {
      title: "Add a professional summary",
      onPress: lapses.includes("BIOGRAPHY")
        ? () => {
            history.push("/profile/aboutMe", { professionalSummary: true });
          }
        : undefined,
      key: "BIOGRAPHY"
    },
    {
      title: "Add your work history",
      onPress: lapses.includes("EXPERIENCE")
        ? () => {
            history.push("/profile/work-history");
          }
        : undefined,
      key: "EXPERIENCE"
    },
    {
      title: "Add your education",
      onPress: lapses.includes("EDUCATION")
        ? () => {
            history.push("/profile/education");
          }
        : undefined,
      key: "EDUCATION"
    }
  ];

  return (
    <HomeScreenCard
      headerTitle="Profile Strength"
      headerIcon="chart-mixed"
      testID="profile-strength-card"
    >
      <Stack>
        <ProgressBar progress={progress} />
        <Text>
          Stand out to Requesters by adding information about yourself.
        </Text>
        <Stack size="compact">
          {possibleLapses.map(({ title, onPress, key }, index) => (
            <StrengthRow
              key={`strength-${index}`}
              testID={`strength-${index}`}
              title={title}
              onPress={onPress}
              complete={!lapses.includes(key)}
            />
          ))}
        </Stack>
      </Stack>
    </HomeScreenCard>
  );
};

export default createRelayFragmentContainer<
  ProfileStrengthCard_worker$key,
  Props
>(
  graphql`
    fragment ProfileStrengthCard_worker on Worker {
      ...useWorkerProfileStrength_worker
    }
  `,
  ProfileStrengthCard
);

interface StrengthRowProp {
  title: string;
  complete: boolean;
  testID: string;
  onPress?: () => void;
}
const StrengthRow = ({ title, complete, testID, onPress }: StrengthRowProp) => {
  return (
    <Pressable
      eventEntityType="ProfileStrengthCardStrengthRow"
      eventTargetName="Profile Strength Card Row Pressable"
      testID={testID}
      onPress={onPress}
    >
      <Well size="medium" color={complete ? "success" : undefined}>
        <Row alignItems="center" justifyContent="space-between">
          <Text color={complete ? "success" : undefined}>{title}</Text>
          <Icon
            name={complete ? "check" : "plus"}
            size="small"
            variant="regular"
            color={complete ? "success" : undefined}
          />
        </Row>
      </Well>
    </Pressable>
  );
};
