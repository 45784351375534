import {
  Button,
  ContentArea,
  Divider,
  IconCircle,
  IconText,
  ProductInfoRow,
  SectionHeader,
  Spacer,
  Stack,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import { capitalize } from "lodash";
import React from "react";
import type { JobBoardSection_root$key } from "./__generated__/JobBoardSection_root.graphql";
import type { JobBoardSection_worker$key } from "./__generated__/JobBoardSection_worker.graphql";

interface Props {
  jobPostFragmentRef: JobBoardSection_worker$key | undefined | null;
  affiliateJobPostFragmentRef?: JobBoardSection_root$key | null | undefined;
}

const JobBoardSection = ({
  jobPostFragmentRef,
  affiliateJobPostFragmentRef
}: Props) => {
  const history = useHistory();
  const jobPostsResult = useRelayFragment<JobBoardSection_worker$key>(
    graphql`
      fragment JobBoardSection_worker on Worker
      @argumentDefinitions(jobPostSearch: { type: "JobPostingSearch!" }) {
        availableJobPostings(first: 3, search: $jobPostSearch) {
          totalCount
          edges {
            node @trackImpressions(action: ACCESSED) {
              id
              title
              boosted
              paySchedule
              payRateNegotiable
              maximumPayRate
              minimumPayRate
            }
          }
        }
      }
    `,
    jobPostFragmentRef ?? null
  );

  const affiliateJobPostsResult = useRelayFragment<JobBoardSection_root$key>(
    graphql`
      fragment JobBoardSection_root on RootQueryType
      @argumentDefinitions(
        affiliateJobPostSearch: { type: "AffiliateJobPostingsInput!" }
      ) {
        affiliateJobPostings(first: 3, input: $affiliateJobPostSearch) {
          totalCount
          edges {
            node {
              externalId
              name
              url
              maximumSalary
              minimumSalary
              salaryInterval
            }
          }
        }
      }
    `,
    affiliateJobPostFragmentRef ?? null
  );

  const availableJobPostings = jobPostsResult?.availableJobPostings;
  const affiliateJobPostings = affiliateJobPostsResult?.affiliateJobPostings;

  if (
    (availableJobPostings?.totalCount ?? 0) === 0 &&
    (affiliateJobPostings?.totalCount ?? 0) === 0
  )
    return null;

  const jobsList = getConnectionNodes(availableJobPostings);
  const affiliatejobsList = getConnectionNodes(affiliateJobPostings);

  const generateNote = ({
    payRateNegotiable,
    minimumPayRate,
    maximumPayRate,
    paySchedule
  }: Exclude<
    ObjectPath<
      JobBoardSection_worker$key,
      [" $data", "availableJobPostings", "edges", 0, "node"]
    >,
    null | undefined
  >) => {
    if (payRateNegotiable) return "Salary • Negotiable";

    if (paySchedule === "ANNUALLY") {
      return `Salary • ${
        minimumPayRate === maximumPayRate
          ? `$${Math.floor(currency.toFloat(minimumPayRate) / 1000)}K`
          : `$${Math.floor(
              currency.toFloat(minimumPayRate) / 1000
            )}K to ${Math.floor(currency.toFloat(maximumPayRate) / 1000)}K`
      }`;
    }
    return `Hourly • ${
      minimumPayRate === maximumPayRate
        ? `$${Math.floor(currency.toFloat(minimumPayRate))}`
        : `$${Math.floor(currency.toFloat(minimumPayRate))} - $${Math.floor(
            currency.toFloat(maximumPayRate)
          )}`
    }`;
  };

  const generateAffiliateNote = ({
    minimumSalary,
    maximumSalary,
    salaryInterval
  }: Exclude<
    ObjectPath<
      JobBoardSection_root$key,
      [" $data", "affiliateJobPostings", "edges", 0, "node"]
    >,
    null | undefined
  >) => {
    if (!salaryInterval && !minimumSalary && !maximumSalary)
      return "Salary • Negotiable";

    const interval = salaryInterval ? `${capitalize(salaryInterval)} • ` : "";

    let salary = "";

    if (minimumSalary === null && maximumSalary === null) {
      return "Negotiable";
    }

    const min = Math.floor(currency.toFloat(minimumSalary));
    const max = Math.floor(currency.toFloat(maximumSalary));

    salary =
      min === max
        ? `$${min > 999 ? `${Math.floor(min / 1000)}K` : min.toFixed(2)}`
        : `$${min > 999 ? `${Math.floor(min / 1000)}K` : min.toFixed(2)}` +
          ` ${salaryInterval === "yearly" ? "to" : "-"} $` +
          `${max > 999 ? `${Math.floor(max / 1000)}K` : max.toFixed(2)}`;

    return interval + salary;
  };

  return (
    <>
      <ContentArea size="compact">
        <SectionHeader
          left={
            <IconText icon="newspaper" textVariant="subheader" color="black">
              Job Board
            </IconText>
          }
          variant="none"
          right={
            <Button
              testID="view-job-board-btn"
              color="primary"
              icon="chevron-right"
              iconPlacement="right"
              label="View Job Board"
              onPress={() => history.push("/browse/jobs")}
              variant="clear"
              size="small"
            />
          }
        />
        <Spacer size="compact" />
        <Stack size="compact">
          {jobsList.map((job, index) => {
            const { id, title, boosted } = job;
            return (
              <ProductInfoRow
                testID={`job-post-${index}`}
                key={`job-post-${index}`}
                leftSpacing="small"
                onPress={() => history.push(`/browse/jobs/${id}`)}
                eventEntityType="WorkerHomeAvailableJobs"
                name={
                  <Stack horizontal alignItems="center" size="slim">
                    {boosted && (
                      <IconCircle
                        color="emphasized"
                        icon="chevrons-up"
                        size="micro"
                      />
                    )}
                    <Stack fill>
                      <Text color="black" variant="subheader" numberOfLines={1}>
                        {title ?? ""}
                      </Text>
                    </Stack>
                  </Stack>
                }
                icon="newspaper"
                color="neutral"
                iconSquareVariant="standard"
                note={
                  <Text variant="note" color="neutral">
                    {generateNote(job)}
                  </Text>
                }
              />
            );
          })}
          {affiliatejobsList
            .slice(0, Math.max(0, 3 - jobsList.length))
            .map((job, index) => {
              const { name, url } = job;
              return (
                <ProductInfoRow
                  variant="outline"
                  key={`affiliate-job-post-${index}`}
                  testID={`affiliate-job-post-${index}`}
                  onPress={() => (url ? showEmbeddedBrowser({ url }) : null)}
                  eventEntityType="WorkerHomeAvailableJobs"
                  name={
                    <Text color="black" variant="subheader" numberOfLines={1}>
                      {name ?? ""}
                    </Text>
                  }
                  icon="newspaper"
                  color="neutral"
                  iconSquareVariant="standard"
                  note={
                    <Text variant="note" color="neutral">
                      {generateAffiliateNote(job)}
                    </Text>
                  }
                />
              );
            })}
        </Stack>
        <Spacer size="compact" />
      </ContentArea>
      <Divider />
    </>
  );
};

export default JobBoardSection;
