import { ContentArea, HighlightedReminder, Stack } from "@gigsmart/atorasu";
import { BlockWorkers, WorkerExcusedAbsences } from "@gigsmart/feature-flags";
import { type ValueObject, useFormValues } from "@gigsmart/fomu";
import {
  ConnectionHandler,
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelayFragment,
  useRelayMutationPromise
} from "@gigsmart/relay";
import Questionnaire, {
  type Questions
} from "@gigsmart/seibutsu/questionnaire/Questionnaire";
import React, { useMemo } from "react";
import useAutoBlockSetting, {
  type useAutoBlockSettingFragmentType
} from "../engagement-cancellation-review/useAutoBlockSetting";
import BlockWorkerQuestion from "../worker-groups/BlockWorkerQuestion";
import CancellationQuestionnaireSubmit from "./CancellationQuestionnaireSubmit";
import CancellationReasonQuestion from "./CancellationReasonQuestion";

import { showCancellationFeeModal } from "./CancellationFeeInfoModal";
import type {
  CancelEngagementInput,
  CancellationQuestionnaireMutation
} from "./__generated__/CancellationQuestionnaireMutation.graphql";
import type {
  CancellationQuestionnaire_engagement$key,
  EngagementStateName,
  GigType
} from "./__generated__/CancellationQuestionnaire_engagement.graphql";
import type { CancellationReasonOptionVariant } from "./__generated__/CancellationReasonQuestion_reason.graphql";

type Props = {
  viewerRef: useAutoBlockSettingFragmentType;
} & (
  | { question: "DID_WORK" | "HAD_ISSUE"; userType: "REQUESTER" }
  | { question: "HAD_ISSUE"; userType: "WORKER" }
);

export type { CancellationReasonOptionVariant };

export const CancellationQuestionnaire = ({
  gig,
  currentState,
  worker,
  cancellationReasonCategories,
  userType,
  question,
  viewerRef
}: FragmentContainerInnerComponentProps<
  CancellationQuestionnaire_engagement$key,
  Props
>) => {
  const { autoBlock } = useAutoBlockSetting(viewerRef);
  const questions = useMemo((): Questions => {
    const workerName = worker?.displayName ?? "";
    const stateName = currentState.name;
    const issueTitle = getIssueTitle(
      gig.gigType,
      stateName,
      workerName,
      userType
    );
    const categories =
      gig?.qualificationsCount?.totalCount === 0
        ? cancellationReasonCategories?.filter(
            (cat) =>
              !cat?.summary?.toLocaleLowerCase()?.includes("qualifications")
          )
        : cancellationReasonCategories;
    return {
      ...(userType === "WORKER"
        ? fromWorker()
        : fromRequester(workerName, gig.gigType === "VOLUNTEER")),
      HAD_ISSUE: {
        title: issueTitle,
        name: "category",
        type: "expandable_radio",
        renderChildrenCollapsed: false,
        rows: categories.map((cat) => ({
          variant: "no-button",
          selectable: true,
          testID: `category-${cat.id}`,
          value: cat.id,
          eventTargetName: cat.summary,
          header: cat.summary,
          children: (
            <CancellationReasonQuestion
              fragmentRef={cat}
              gigFragmentRef={gig}
            />
          )
        }))
      }
    };
  }, [
    worker?.displayName,
    currentState.name,
    userType,
    gig.gigType,
    cancellationReasonCategories
  ]);

  const { values } = useFormValues();
  const categoryId = values?.category;
  const opt = values?.[`${categoryId}.option`];
  const workerName = worker.displayName ?? "";
  const isCancellation =
    question === "HAD_ISSUE" ||
    (question === "DID_WORK" && values?.didWork === "no");
  const showBlock =
    BlockWorkers.isEnabled() &&
    isCancellation &&
    !!opt?.blockOption?.id &&
    !(opt.variant === "NO_SHOW" && WorkerExcusedAbsences.isEnabled());
  const showAutoBlockReminder =
    showBlock &&
    WorkerExcusedAbsences.isEnabled() &&
    autoBlock &&
    !(opt.title === "Other" && opt.variant === "STANDARD") &&
    opt.variant !== "NO_SHOW";

  return (
    <Stack>
      <Questionnaire questions={questions} question={question} />
      {showAutoBlockReminder && (
        <ContentArea size="none">
          <HighlightedReminder
            icon="circle-exclamation"
            header="By cancelling this Worker, they will be automatically blocked from your Organization's Shifts in accordance with your Organization Block Worker Settings."
          />
        </ContentArea>
      )}
      {showBlock && !showAutoBlockReminder && (
        <BlockWorkerQuestion workerName={workerName} />
      )}
      {isCancellation && (
        <CancellationQuestionnaireSubmit
          userType={userType}
          stateName={currentState.name}
          workerName={workerName}
          gigType={gig.gigType}
        />
      )}
    </Stack>
  );
};

const fragmentSpec = graphql`
  fragment CancellationQuestionnaire_engagement on Engagement {
    id
    currentState {
      name
    }
    worker {
      displayName
    }
    gig {
      id
      gigType
      qualificationsCount: gigFieldItems(
        first: 0
        query: "WHERE attestable = TRUE"
      ) {
        totalCount
      }
      ...MissingQualificationsQuestion_Gig
    }
    cancellationReasonCategories {
      id
      summary
      ...CancellationReasonQuestion_reason
    }
  }
`;

export function useCancelEngagement(
  engagementRef: CancellationQuestionnaire_engagement$key | null,
  authoredBy: "WORKER" | "REQUESTER"
) {
  const engagement = useRelayFragment(fragmentSpec, engagementRef ?? null);
  const [cancelEngagement] =
    useRelayMutationPromise<CancellationQuestionnaireMutation>(
      graphql`
        mutation CancellationQuestionnaireMutation(
          $input: CancelEngagementInput!
          $force: Boolean!
        ) {
          cancelEngagement(input: $input) @force(if: $force) {
            cancellationReason {
              id
            }
            restrictedBy {
              ... on EngagementCapabilityCancellationFeeRestriction {
                cancellationFee {
                  ...CancellationFeeInfoModal_cancellationFee
                }
              }
            }
          }
        }
      `,
      {
        updater: (store) => {
          const gigId = engagement?.gig?.id;
          if (!gigId) return;
          const gig = store.get(gigId);
          if (!gig) return;
          const hiredEngagements = ConnectionHandler.getConnection(
            gig,
            "engagementsList_engagements",
            {
              query:
                "WHERE currentStateName IN [WORKING, PAUSED, AWAITING_START, EN_ROUTE, SCHEDULED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL, PENDING_REVIEW, ENDED, PAYMENT_FAILED, RUNNING_LATE] ORDER BY currentStateName PRIORITY [WORKING, PAUSED, AWAITING_START, EN_ROUTE, SCHEDULED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL, PENDING_REVIEW, ENDED, PAYMENT_FAILED, RUNNING_LATE] ASC, currentStateTransitionedAt DESC"
            }
          );
          if (!hiredEngagements) return;
          ConnectionHandler.deleteNode(hiredEngagements, engagement?.id);
        }
      }
    );

  const [addDisqualification] = useRelayMutationPromise(graphql`
    mutation CancellationQuestionnaireAddDisqualificationMutation(
      $input: AddCancellationReasonDisqualificationInput!
    ) {
      addCancellationReasonDisqualification(input: $input) {
        newCancellationReasonDisqualificationEdge {
          node {
            id
          }
        }
      }
    }
  `);

  return async (
    values: ValueObject
  ): Promise<CancellationReasonOptionVariant> => {
    if (!engagement) {
      throw new Error("Select at least one option");
    }

    const categoryId = values.category;
    const opt = values[`${categoryId}.option`];
    if (!engagement || !categoryId || !opt?.id) {
      throw new Error("Select at least one option");
    }

    const input: CancelEngagementInput = {
      authoredBy,
      engagementId: engagement.id,
      cancellationReasonOptionId: opt.id,
      comment: values[`${categoryId}.comment`] ?? "",
      blockUser:
        BlockWorkers.isEnabled() &&
        !!opt.blockOption?.id &&
        values.shouldBlock === "yes"
    };

    await cancelEngagement({
      input,
      force: false
    }).then(async (response) => {
      if (!response.cancelEngagement?.restrictedBy?.cancellationFee) {
        if (values?.missingQualificationsSelected) {
          values?.missingQualifications?.forEach(
            (missingQualificationId: string) => {
              void addDisqualification({
                input: {
                  cancellationReasonId:
                    response?.cancelEngagement?.cancellationReason?.id,
                  gigFieldItemDefinitionId: missingQualificationId
                }
              });
            }
          );
        }
        return response;
      }
      return await new Promise((resolve, reject) => {
        showCancellationFeeModal(
          response.cancelEngagement?.restrictedBy?.cancellationFee ?? null,
          () => resolve(cancelEngagement({ input, force: true })),
          () => reject(new Error("canceled"))
        );
      });
    });

    return opt.variant as CancellationReasonOptionVariant;
  };
}

export default createRelayFragmentContainer<
  CancellationQuestionnaire_engagement$key,
  Props
>(fragmentSpec, CancellationQuestionnaire);

//
//  helpers
const fromRequester = (workerName: string, isVolunteer: boolean) => ({
  DID_WORK: {
    title: `Did ${workerName} work?`,
    type: "radio",
    name: "didWork",
    buttons: [
      { title: "Yes", value: "yes", testID: "didWork-yes" },
      { title: "No", value: "no", testID: "didWork-no" }
    ],
    nextQuestion: {
      yes: isVolunteer ? "PAY_TIMESHEET" : "PAYMENT",
      no: "HAD_ISSUE"
    }
  },
  PAYMENT: {
    title: `How would you like to pay ${workerName}?`,
    name: "payment",
    type: "radio",
    buttons: [
      {
        title: "I want to create a timesheet to calculate hourly pay.",
        value: "timesheet",
        testID: "create-timesheet"
      },
      {
        title: "I know how much I want to pay this person (flat amount).",
        value: "flatAmount",
        testID: "pay-flat"
      }
    ],
    nextQuestion: {
      timesheet: "PAY_TIMESHEET",
      flatAmount: "PAY_FLAT_AMOUNT"
    }
  },
  PAY_TIMESHEET: {
    type: "submit",
    label: "Create Timesheet"
  },
  PAY_FLAT_AMOUNT: {
    type: "submit",
    label: "Pay Worker Flat Amount"
  }
});

const fromWorker = () => ({});

function getIssueTitle(
  gigType: GigType | null | undefined,
  stateName: EngagementStateName,
  workerName: string,
  userType: Props["userType"]
) {
  const inlineType = gigType === "PROJECT" ? "Project" : "Shift";
  if (userType === "WORKER") {
    return stateName === "APPLIED"
      ? "Why are you withdrawing your application?"
      : `Why are you canceling yourself from this ${inlineType}?`;
  }
  return stateName === "CANCELED" || stateName === "PENDING_TIMESHEET_APPROVAL"
    ? "Tell us a little more about what happened."
    : `Why are you canceling ${workerName} from this ${inlineType}?`;
}
