import "@gigsmart/isomorphic-shared/debug/log-box";
import "moment-timezone";
import "react-native-get-random-values";
import "./background-notification-handler";
import "./google-api-config";
import { Text } from "@gigsmart/atorasu";
import { ErrorPage } from "@gigsmart/atorasu";
import * as stripe from "@gigsmart/isomorphic-payments";
import { setDefaultErrorPage } from "@gigsmart/relay/query/relay-error-boundary";
import { Platform, TextInput, UIManager } from "react-native";

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

setDefaultErrorPage(ErrorPage);

// Configure Stripe
stripe.configure({
  publishableKey: process.env.STRIPE_PUBLISHABLE_KEY ?? "",
  androidPayMode: process.env.CONFIG_ENV === "prod" ? "production" : "test",
  merchantId: "merchant.gigsmart"
});

(Text as any).defaultProps = {
  ...(Text as any).defaultProps,
  allowFontScaling: false
};
(TextInput as any).defaultProps = {
  ...(TextInput as any).defaultProps,
  allowFontScaling: false
};
