import {
  Button,
  CollapsibleList,
  CollapsibleWell,
  ContentArea,
  Divider,
  GridNull,
  IconText,
  Spacer,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import { WorkerEngagementView } from "@gigsmart/feature-flags";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React from "react";
import HomeScreenCard from "../worker/HomescreenCard";

import type { WorkHistoryCard_worker$key } from "./__generated__/WorkHistoryCard_worker.graphql";

interface Props {
  viewer?: "Requester" | "Worker";
  onPressAdd?: () => void;
  onPressEdit?: (id: string) => void;
}

export const WorkHistoryCard = ({
  experience,
  viewer = "Worker",
  onPressAdd,
  onPressEdit
}: FragmentContainerInnerComponentProps<WorkHistoryCard_worker$key, Props>) => {
  const workHistory = getConnectionEdges(experience);

  const formatDate = (v: string | null | undefined) =>
    DateTime.fromISO(v ?? new Date().toISOString()).toFormat("MMMM yyyy");

  const workerView = () => (
    <HomeScreenCard
      headerTitle="Work History"
      headerIcon="briefcase"
      testID="profile-work-history-card"
    >
      <Stack>
        <CollapsibleList
          items={workHistory.map(({ node }) => ({
            title: node?.positionTitle ?? "",
            content: (
              <>
                <Spacer size="slim" />
                <Text>{node?.employer ?? ""}</Text>
                <Text>
                  {formatDate(node?.employmentStart)} -{" "}
                  {node?.employmentEnd === null
                    ? "Current"
                    : formatDate(node?.employmentEnd)}
                </Text>
              </>
            ),
            workHistoryId: node?.id
          }))}
          placeholder="Adding work history can increase your chances of finding the right Shifts and being selected."
          iconName="pen-to-square"
          testID="work-history"
          onPress={({ workHistoryId }) => onPressEdit?.(workHistoryId ?? "")}
          startCollapsed
        />
        <Button
          icon="plus"
          label="Add Work History"
          testID="add-work-history"
          outline
          onPress={onPressAdd}
        />
      </Stack>
    </HomeScreenCard>
  );

  const requesterView = () => {
    if (!workHistory.length) {
      return <GridNull />;
    }

    return WorkerEngagementView.select(
      <CollapsibleWell
        testID="worker-profile-work-history"
        headerIcon="briefcase"
        headerText="Work History"
        items={workHistory.map(({ node }, index) => (
          <>
            <Text variant="subheader" color="black">
              {node?.positionTitle}
            </Text>
            <Spacer size="slim" />
            <Text>{node?.employer ?? ""}</Text>
            <Text>
              {formatDate(node?.employmentStart)} -{" "}
              {node?.employmentEnd === null
                ? "Current"
                : formatDate(node?.employmentEnd)}
            </Text>
            {index < workHistory.length - 1 && (
              <>
                <Spacer size="compact" />
                <Divider />
              </>
            )}
          </>
        ))}
      />,
      <Stack size="medium">
        <Well color="black" size="compact">
          <IconText
            icon="briefcase"
            spacing="medium"
            textWeight="bold"
            size="small"
          >
            Work History
          </IconText>
        </Well>
        <ContentArea size="none">
          <CollapsibleList
            items={workHistory.map(({ node }) => ({
              title: node?.positionTitle ?? "",
              content: (
                <>
                  <Spacer size="slim" />
                  <Text>{node?.employer ?? ""}</Text>
                  <Text>
                    {formatDate(node?.employmentStart)} -{" "}
                    {node?.employmentEnd === null
                      ? "Current"
                      : formatDate(node?.employmentEnd)}
                  </Text>
                </>
              )
            }))}
            iconName="pen-to-square"
            testID="requester-worker-work-history-row"
            startCollapsed={false}
            allowCollapse={false}
          />
        </ContentArea>
      </Stack>
    );
  };

  return viewer === "Worker" ? workerView() : requesterView();
};

export default createRelayFragmentContainer<WorkHistoryCard_worker$key, Props>(
  graphql`
    fragment WorkHistoryCard_worker on Worker {
      experience: workHistory(
        first: 50
        query: "ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC"
      ) {
        edges {
          node {
            id
            employer
            employmentStart
            employmentEnd
            positionTitle
          }
        }
      }
    }
  `,
  WorkHistoryCard
);
