import React, { useEffect, useState, type ComponentProps } from "react";
import { StyledText } from "../text";

const flashes = new Map<string, string>();

function popMessage(queue: string) {
  if (flashes.has(queue)) {
    const message = flashes.get(queue) ?? null;
    flashes.delete(queue);
    return message;
  }
  return null;
}

export const flash = (queue: string, message: string) => {
  flashes.set(queue, message);
  setTimeout(popMessage, 1000, queue);
};

type Props = Omit<ComponentProps<typeof StyledText>, "children"> & {
  queue: string;
};

export const FlashMessage = ({ queue, ...props }: Props) => {
  const [message, setMessage] = useState<string | null>(null);
  useEffect(() => {
    setMessage(popMessage(queue));
  }, [queue]);
  return message ? <StyledText {...props}>{message}</StyledText> : null;
};
