import { Query, where } from "@gigsmart/biruda";
import {
  getConnectionNodes,
  graphql,
  useRelayFragment,
  useRelayMutationPromise
} from "@gigsmart/relay";
import type { useWorkerGigFieldsMutation } from "./__generated__/useWorkerGigFieldsMutation.graphql";
import type { useWorkerGigFields_worker$key } from "./__generated__/useWorkerGigFields_worker.graphql";

export function makeWorkerGigFieldsVariables(...titles: string[]) {
  const query = titles.reduce(
    (acc, title, index) => (index === 0 ? where({ title }) : acc.or({ title })),
    new Query()
  );

  return {
    query: query.toString(),
    first: titles.length
  };
}

export function useWorkerGigFields(
  workerRef?: useWorkerGigFields_worker$key | null
) {
  const worker = useRelayFragment(
    graphql`
      fragment useWorkerGigFields_worker on Worker
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        query: { type: "String!" }
      ) {
        id
        gigFields(first: $first, query: $query) {
          edges {
            node {
              title
              iconName
              selected: itemDefinitions(
                first: 10
                query: "WHERE status = CONFIRMED"
              ) {
                edges {
                  node {
                    label
                    id
                  }
                }
              }
              denied: itemDefinitions(
                first: 10
                query: "WHERE status = DENIED"
              ) {
                edges {
                  node {
                    label
                    id
                  }
                }
              }
              available: itemDefinitions(
                first: 10
                query: "WHERE status = UNKNOWN"
              ) {
                edges {
                  node {
                    label
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    workerRef ?? null
  );

  const [setWorkerQualification] =
    useRelayMutationPromise<useWorkerGigFieldsMutation>(graphql`
      mutation useWorkerGigFieldsMutation(
        $input: SetWorkerQualificationInput!
      ) {
        setWorkerQualification(input: $input) {
          newWorkerQualificationEdge {
            node {
              gigFieldItemDefinition {
                id
                label
              }
            }
          }
        }
      }
    `);

  const checkOption = async ({
    value,
    id,
    onError
  }: {
    id: string;
    value: boolean;
    onError?: () => void;
  }) => {
    try {
      await setWorkerQualification({
        input: {
          status: value ? "CONFIRMED" : "DENIED",
          gigFieldItemDefinitionId: id,
          workerId: worker?.id ?? ""
        }
      });
    } catch (error) {
      onError?.();
    }
  };

  return {
    gigFieldOptions: Object.fromEntries(
      getConnectionNodes(worker?.gigFields, (gigField) => [
        gigField.title,
        [
          ...getConnectionNodes(gigField?.available).map((item) => ({
            ...item,
            value: null
          })),
          ...getConnectionNodes(gigField?.denied).map((item) => ({
            ...item,
            value: false
          })),
          ...getConnectionNodes(gigField?.selected).map((item) => ({
            ...item,
            value: true
          }))
        ]
      ])
    ),
    checkOption,
    setWorkerQualification
  };
}
