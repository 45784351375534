import {
  Button,
  ContentArea,
  ListEmpty,
  ProductInfoRow,
  ScrollView,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import type { AppNavigationProp } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { useGeolocation } from "../../geolocation";
import { showAffiliateJobDetail } from "../../job/affiliate-job-detail";
import AffiliateJobCardFragment from "../../job/job-list/affiliate-job-card-fragment";
import JobCardFragment from "../../job/job-list/job-card-fragment";
import type { WorkerParamList } from "../../navigation/types";
import { showJobBoardTooltip } from "../my-gigs-tab/job-board-tooltip";
import type { myGigsJobsTabQuery } from "./__generated__/myGigsJobsTabQuery.graphql";

type Props = {
  onPressJob: (id: string) => void;
};

export default createSuspendedQueryContainer<myGigsJobsTabQuery, Props>(
  function MyGigsJobsTab({ onPressJob, response, setVariables }) {
    const nav = useNavigation<AppNavigationProp<WorkerParamList, "MyGigs">>();
    const geolocation = useGeolocation(false);
    useEffect(
      () =>
        setVariables({
          input: {
            latitude: geolocation.latitude,
            longitude: geolocation.longitude,
            maxDistance: 75
          },
          search: {
            latitude: geolocation.latitude,
            longitude: geolocation.longitude,
            maxDistance: 75,
            orderBy: [{ field: "boosted", direction: "DESC" }]
          }
        }),
      [geolocation.latitude, geolocation.longitude]
    );

    const affiliateJobPostings = response?.affiliateJobPostings;
    const viewer = response?.viewer;
    const jobs = viewer?.availableJobPostings?.edges ?? [];
    const affiliateJobs = getConnectionNodes(affiliateJobPostings);
    const firstAffiliate = affiliateJobs[0];

    return (
      <ScrollView testID="my-gigs-jobs-tab" justifyContent="space-between" grow>
        <ContentArea variant="slim">
          <Stack size="compact">
            <ContentArea size="none" variant="medium">
              <Text color="primary" weight="bold" variant="header">
                Recommended for you
              </Text>
            </ContentArea>
            {jobs?.map((jobEdge) =>
              jobEdge?.jobPost?.id ? (
                <JobCardFragment
                  key={jobEdge?.jobPost?.id}
                  jobRef={jobEdge?.jobPost}
                  onPress={() => onPressJob(jobEdge.jobPost?.id ?? "")}
                />
              ) : null
            )}
            {jobs?.length === 0 &&
              affiliateJobs.map((job) => (
                <AffiliateJobCardFragment
                  key={job.externalId}
                  jobRef={job}
                  onPress={() => showAffiliateJobDetail(job.name, job.url)}
                />
              ))}
            {jobs?.length === 1 && !!firstAffiliate && (
              <AffiliateJobCardFragment
                key={firstAffiliate.externalId}
                jobRef={firstAffiliate}
                onPress={() =>
                  showAffiliateJobDetail(
                    firstAffiliate.name,
                    firstAffiliate.url
                  )
                }
              />
            )}
            {(jobs?.length !== 0 || affiliateJobs?.length !== 0) && (
              <ContentArea size="slim" variant="medium">
                <ProductInfoRow
                  testID="view-jobs-btn"
                  icon="newspaper"
                  name="Interested in applying to more Jobs?"
                  note="Browse and apply to open positions now"
                  onPress={() => nav.push("BrowseJobs")}
                />
              </ContentArea>
            )}
            {jobs?.length === 0 && affiliateJobs?.length === 0 && (
              <ContentArea variant="none">
                <ListEmpty
                  icon="newspaper"
                  subtitle="Unfortunately there are currently no Full or Part-Time Positions available."
                />
              </ContentArea>
            )}
          </Stack>
        </ContentArea>
        <Button
          testID="about-job-board-modal-btn"
          size="large"
          label="About Job Board"
          icon="circle-question"
          variant="clear"
          onPress={showJobBoardTooltip}
        />
        <Spacer size="large" />
      </ScrollView>
    );
  },
  {
    query: graphql`
      query myGigsJobsTabQuery(
        $input: AffiliateJobPostingsInput!
        $search: JobPostingSearch!
      ) {
        viewer {
          ... on Worker {
            availableJobPostings(first: 2, search: $search) {
              totalCount
              edges {
                jobPost: node @trackImpressions(action: ACCESSED) {
                  id
                  __typename
                  ...jobCardFragment_job
                }
              }
            }
          }
        }
        affiliateJobPostings(first: 2, input: $input) {
          totalCount
          edges {
            node {
              externalId
              name
              url
              ...affiliateJobCardFragment_job
            }
          }
        }
      }
    `,
    variables: () => {
      const geolocation = useGeolocation(false);
      return {
        input: {
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
          maxDistance: 75
        },
        search: {
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
          maxDistance: 75,
          orderBy: [{ field: "boosted", direction: "DESC" }]
        }
      };
    }
  }
);
