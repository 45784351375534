/**
 * @generated SignedSource<<47d3f73ee96fa362dbb4c3d5c8c2bf29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerAvatar_worker$data = {
  readonly belongsToWorkerGroup: boolean;
  readonly isBlocked: boolean;
  readonly isFavorite: boolean;
  readonly profilePhoto: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WorkerAvatar_worker";
};
export type WorkerAvatar_worker$key = {
  readonly " $data"?: WorkerAvatar_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerAvatar_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerAvatar_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "belongsToWorkerGroup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profilePhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "3ef344c2aff8d51cc68c7fe9586d47e7";

export default node;
