import { ContentArea, HighlightedReminder, Stack } from "@gigsmart/atorasu";
import { WorkerExcusedAbsences } from "@gigsmart/feature-flags";
import { Validator, useFormField } from "@gigsmart/fomu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useEffect } from "react";
import FomuTagSelect from "../fomu/inputs/FomuTagSelect";
import FomuTextInput from "../fomu/inputs/FomuTextInput";
import MissingQualificationsQuestion from "./MissingQualificationsQuestion";
import type { CancellationReasonQuestion_reason$key } from "./__generated__/CancellationReasonQuestion_reason.graphql";
import type { MissingQualificationsQuestion_Gig$key } from "./__generated__/MissingQualificationsQuestion_Gig.graphql";

type OptionType = NonNullable<
  ObjectPath<CancellationReasonQuestion_reason$key, [" $data", "options", 0]>
>;

interface Props {
  gigFragmentRef: MissingQualificationsQuestion_Gig$key;
}

export const CancellationReasonQuestion = ({
  id,
  prompt,
  options,
  summary,
  gigFragmentRef
}: FragmentContainerInnerComponentProps<
  CancellationReasonQuestion_reason$key,
  Props
>) => {
  const user = useCurrentUser();
  const { value: selectedOption, setValue: setSelectedOption } =
    useFormField<OptionType>({
      name: `${id}.option`,
      validates: Validator.presence()
    });
  const showCancellationReviewMessage =
    WorkerExcusedAbsences.isEnabled() &&
    user?.__typename === "Worker" &&
    ["I am no longer available to work this Shift", "Other"].includes(summary);

  useEffect(() => {
    if (!selectedOption && options.length === 1) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      setSelectedOption(options[0]!);
    }
  }, []);
  if (summary?.toLocaleLowerCase()?.includes("qualifications"))
    return (
      <MissingQualificationsQuestion
        categoryId={id}
        option={options?.[0]}
        fragmentRef={gigFragmentRef}
      />
    );

  if (options.length === 1) {
    const opt = options[0];
    return !opt || opt.commentType === "NONE" ? null : (
      <ContentArea>
        <Stack>
          <FomuTextInput
            name={`${id}.comment`}
            label={opt.commentPrompt ?? ""}
            multiline
            numberOfLines={5}
            min={opt.minimumCommentLength}
            max={opt.maximumCommentLength}
            placeholder="Enter additional information"
          />
          {showCancellationReviewMessage && (
            <HighlightedReminder
              icon="circle-exclamation"
              header="Your cancellation message will be provided to the Organization."
            />
          )}
        </Stack>
      </ContentArea>
    );
  }

  if (options.length) {
    return (
      <ContentArea>
        <Stack>
          <FomuTagSelect
            name={`${id}.option`}
            validates={Validator.presence()}
            label={prompt ?? ""}
            options={options}
            labelExtractor={(opt) => opt.title ?? ""}
            keyExtractor={(opt) => opt.id}
          />
          {!selectedOption || selectedOption.commentType === "NONE" ? null : (
            <FomuTextInput
              name={`${id}.comment`}
              label={selectedOption.commentPrompt ?? ""}
              multiline
              numberOfLines={5}
              min={selectedOption.minimumCommentLength}
              max={selectedOption.maximumCommentLength}
              placeholder="Enter additional information"
            />
          )}
        </Stack>
      </ContentArea>
    );
  }

  return null;
};

export default createRelayFragmentContainer<
  CancellationReasonQuestion_reason$key,
  Props
>(
  graphql`
    fragment CancellationReasonQuestion_reason on EngagementCancellationReasonCategory {
      id
      prompt
      summary
      options {
        id
        variant
        title
        commentPrompt
        commentType
        minimumCommentLength
        maximumCommentLength
        blockOption {
          id
        }
      }
    }
  `,
  CancellationReasonQuestion
);
