import {
  Collapsible,
  Column,
  ContentArea,
  IconText,
  List,
  Row,
  Surface,
  Text
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { compact, sortBy } from "lodash";
import { Duration } from "luxon";
import React from "react";
import CancellationPoliciesInfoModal from "../../gig/CancellationPoliciesInfoModal";
import type { CancellationPoliciesRow_shift$key } from "./__generated__/CancellationPoliciesRow_shift.graphql";

type Props = {};

export const CancellationPoliciesRow = ({
  requesterCancellationPolicies,
  workerCancellationPolicies
}: FragmentContainerInnerComponentProps<
  CancellationPoliciesRow_shift$key,
  Props
>) => {
  if (
    !requesterCancellationPolicies?.length &&
    !workerCancellationPolicies?.length
  ) {
    return null;
  }

  return (
    <Collapsible
      testID="cancellation-info"
      variant="surface"
      header={
        <IconText
          textWeight="bold"
          color="primary"
          icon="rectangle-xmark"
          size="small"
          iconAlign="center"
          spacing="compact"
        >
          Cancellation Info
        </IconText>
      }
    >
      <ContentArea gap="standard">
        {!!workerCancellationPolicies.length && (
          <Surface variant="outline">
            <ContentArea gap="standard">
              <Text variant="subheader" color="primary">
                Worker Cancellation Policy
              </Text>
              <Column gap="compact">
                <Text>
                  After accepting and being hired on this Shift, you are
                  expected to make every effort to work this Shift on time.
                </Text>
                <Text>
                  If you cancel this Shift for any of the following, you may be
                  blocked by the hiring Organization from working future Shifts
                  for them.
                </Text>
                <List variant="bullet" size="slim">
                  {sortPolicies(workerCancellationPolicies).map((policy, i) => (
                    <Text key={i}>{policy.description}</Text>
                  ))}
                </List>
              </Column>
            </ContentArea>
          </Surface>
        )}
        {!!requesterCancellationPolicies?.length && (
          <Surface variant="outline">
            <ContentArea gap="standard">
              <Text variant="subheader" color="primary">
                Requester Cancellation Policy
              </Text>
              <Column gap="compact">
                <Text>
                  To ensure you are fairly compensated for Shifts you have been
                  hired to work, GigSmart charges Cancellation Fees to
                  Requesters who cancel Shifts near the scheduled start time.
                </Text>
                <Text>
                  If a Requester cancels a Shift you are hired to work:
                </Text>
                <List variant="bullet" size="slim">
                  {sortPolicies(requesterCancellationPolicies).map(
                    (policy, i) => (
                      <Text key={i}>{policy.description}</Text>
                    )
                  )}
                </List>
              </Column>
              <Row justifyContent="center">
                <CancellationPoliciesInfoModal
                  testID="cancellation-fee-info-modal"
                  helpText="Learn More"
                />
              </Row>
            </ContentArea>
          </Surface>
        )}
      </ContentArea>
    </Collapsible>
  );
};

export default createRelayFragmentContainer<
  CancellationPoliciesRow_shift$key,
  Props
>(
  graphql`
    fragment CancellationPoliciesRow_shift on GigLike {
      requesterCancellationPolicies {
        description
        minimumStartDelta
      }
      workerCancellationPolicies {
        description
        minimumStartDelta
      }
    }
  `,
  CancellationPoliciesRow
);

function sortPolicies(
  policies: ReadonlyArray<{ minimumStartDelta: string; description: string }>
) {
  return sortBy(
    compact(policies),
    ({ minimumStartDelta }) =>
      -Duration.fromISO(minimumStartDelta).as("seconds")
  );
}
