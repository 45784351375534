import {
  Button,
  CollapsibleText,
  Column,
  HighlightedReminder,
  Text,
  useStyles
} from "@gigsmart/atorasu";
import {
  type EngagementStateName,
  formatDistanceRange,
  stateIsComplete,
  stateIsIncomplete
} from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";
import { ShiftLocationMap } from "../../gig/ShiftLocationMap";
import SummaryRow from "../../gig/SummaryRows/SummaryRow";
import { showEtaModal } from "./EtaModal";
import type { WorkerSummaryLocation_shift$key } from "./__generated__/WorkerSummaryLocation_shift.graphql";

type Props = {
  workerStateName?: EngagementStateName;
  workerDistance?: number | null;
};

export const WorkerSummaryLocation = ({
  id,
  gigType,
  organizationLocation,
  nextGig,
  workerDistance,
  workerStateName,
  area
}: FragmentContainerInnerComponentProps<
  WorkerSummaryLocation_shift$key,
  Props
>) => {
  workerDistance ??= nextGig?.edges?.[0]?.distance;
  const { place, arrivalInstructions } = organizationLocation ?? {};

  const styles = useStyles(() => ({
    rightAction: { top: -8 }
  }));

  const isBrowsing = !workerStateName;
  const isProject = gigType === "PROJECT";
  const skipLocation =
    isBrowsing || (!!workerStateName && stateIsIncomplete(workerStateName));

  const distanceStr = !skipLocation
    ? formatDistanceRange({ min: workerDistance }, " away")
    : "";
  const addr =
    (isBrowsing || !place?.streetAddress ? area : place?.streetAddress) ||
    "N/A";

  return (
    <SummaryRow
      editVariant="none"
      icon="map-location-dot"
      title="Location"
      rightNode={
        !skipLocation &&
        !stateIsComplete(workerStateName) &&
        !!place?.location && (
          <Column style={styles.rightAction}>
            <Button
              testID="directions-btn"
              outline
              size="small"
              label="Directions"
              onPress={() =>
                showLocation({
                  latitude: place?.location?.latitude ?? "",
                  longitude: place?.location?.longitude ?? "",
                  googlePlaceId: place?.id ?? "",
                  alwaysIncludeGoogle: true,
                  dialogTitle: "Get Directions",
                  dialogMessage: "Select a preferred navigation app"
                })
              }
            />
          </Column>
        )
      }
    >
      {addr === "REMOTE" ? (
        <Column gap="slim">
          <Text>Remote</Text>
          <Text variant="note" color="neutral">
            You do not need to be on-site to complete this Project.
          </Text>
        </Column>
      ) : (
        <Column gap="standard">
          <Text>
            {addr} {distanceStr}
          </Text>

          <Column gap="medium">
            {!skipLocation && !!place?.location && (
              <>
                <Text weight="bold">Clock-in Location:</Text>
                <ShiftLocationMap center={place.location} />
              </>
            )}
            {arrivalInstructions ? (
              <CollapsibleText numberOfLines={3} testID="arrival-instructions">
                <Text weight="bold">Arrival Instructions:</Text>{" "}
                {arrivalInstructions}
              </CollapsibleText>
            ) : (
              isBrowsing && (
                <HighlightedReminder
                  icon="map-location-dot"
                  header={
                    !isProject
                      ? "The Shift address and arrival instructions will be displayed upon hiring."
                      : "The Gig address will be displayed upon hiring."
                  }
                />
              )
            )}
            {isBrowsing && skipLocation && (
              <Button
                testID="calculate-estimated-travel-btn"
                outline
                size="small"
                label="Calculate Estimated Travel Time"
                onPress={() => showEtaModal(id)}
              />
            )}
          </Column>
        </Column>
      )}
    </SummaryRow>
  );
};

export default createRelayFragmentContainer<
  WorkerSummaryLocation_shift$key,
  Props
>(
  graphql`
    fragment WorkerSummaryLocation_shift on GigLike
      @argumentDefinitions(
        isBrowsing: { type: "Boolean", defaultValue: false }
      ) {
      id
      gigType
      area
      organizationLocation {
        arrivalInstructions @skip(if: $isBrowsing)
        place {
          id
          area
          streetAddress @skip(if: $isBrowsing)
          location @skip(if: $isBrowsing) {
            latitude
            longitude
          }
        }
      }
      ... on GigSeries {
        nextGig: availableGigs(first: 1) {
          edges {
            distance
          }
        }
      }
    }
  `,
  WorkerSummaryLocation
);
