/**
 * @generated SignedSource<<cc0bb412c6105befec4f886649bf3e6b>>
 * @relayHash 9dd05cfa9ee60657a1eed21d5a54354b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:FzBrLqpmHfSp5xeo-CirFMLamb9d1y3L0vsIXr0MDgk

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddWorkerEducationInput = {
  educationLevel: string;
  endDate?: string | null | undefined;
  graduatingYear?: number | null | undefined;
  schoolName: string;
  startDate?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type EducationFormAddMutation$variables = {
  input: AddWorkerEducationInput;
};
export type EducationFormAddMutation$data = {
  readonly addWorkerEducation: {
    readonly newWorkerEducationEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"EducationForm_worker">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EducationFormAddMutation = {
  response: EducationFormAddMutation$data;
  variables: EducationFormAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EducationFormAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerEducationPayload",
        "kind": "LinkedField",
        "name": "addWorkerEducation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerEducationEdge",
            "kind": "LinkedField",
            "name": "newWorkerEducationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerEducation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EducationForm_worker"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EducationFormAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerEducationPayload",
        "kind": "LinkedField",
        "name": "addWorkerEducation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerEducationEdge",
            "kind": "LinkedField",
            "name": "newWorkerEducationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerEducation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "educationLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:FzBrLqpmHfSp5xeo-CirFMLamb9d1y3L0vsIXr0MDgk",
    "metadata": {},
    "name": "EducationFormAddMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d7d81978a9f0d722cdf4786807099dea";

export default node;
