import { Avatar, type Color, Icon } from "@gigsmart/atorasu";
import {
  useCurrentOrganization,
  useCurrentUser
} from "@gigsmart/isomorphic-shared/current-user";
import React from "react";

interface Props {
  active?: boolean;
  iconColor?: Color;
}

export default function CurrentUserIcon({
  active,
  iconColor = "primary"
}: Props) {
  const user = useCurrentUser();
  const org = useCurrentOrganization();
  const uri = org?.logoUrl ?? user?.profilePhoto?.url;

  return uri ? (
    <Avatar size="small" icon="circle-user" uri={uri} />
  ) : (
    <Icon
      name="circle-user"
      color={iconColor}
      variant={active ? "solid" : "light"}
    />
  );
}
