import { Persistence, Platform } from "@gigsmart/atorasu";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import {} from "@gigsmart/isomorphic-shared/app/helpers/links";
import { useCurrentUrl } from "@gigsmart/kaizoku";
import { useEffect, useState } from "react";
import RNRestart from "react-native-restart";

const WEBAPP_KEY = "enableWebApp";

type Props = {
  allowedPaths?: string[];
  children: JSX.Element;
};

export default function WebLock({ allowedPaths, children }: Props) {
  const url = useCurrentUrl();
  const { appId } = useAppSettings();
  const [lock, setLock] = useState(() => !__DEV__ && Platform.OS === "web");

  useEffect(() => {
    void checkPersistence(url, allowedPaths).then(setLock);
  }, [url, JSON.stringify(allowedPaths)]);

  return lock ? null : children;
}

function shouldLock(url: string, allowedPaths?: string[]) {
  if (process.env.CONFIG_ENV !== "prod") return false;

  return (
    Platform.OS === "web" &&
    !allowedPaths?.find((prefix) => url.indexOf(prefix) === 0)
  );
}

async function checkPersistence(url: string, allowedPaths?: string[]) {
  if (__DEV__ || Platform.OS !== "web") return false;
  Persistence.keep(WEBAPP_KEY);

  if (url.startsWith("/login/")) await Persistence.toggleOn(WEBAPP_KEY);
  if (window.location.search.includes("toggle-app")) {
    await Persistence.toggle(WEBAPP_KEY);
    RNRestart.restart();
    return false;
  }

  const isOn =
    (await Persistence.isOn(WEBAPP_KEY)) || !shouldLock(url, allowedPaths);
  if (!isOn) redirect();
  return !isOn;
}

function redirect() {
  window.location.href = "https://gigsmart.com/workers";
}
