import React from "react";
import { View } from "react-native";

import { useStyles } from "../../style";

const TabIndicator = () => {
  const { styles } = useStyles(({ color }) => ({
    indicator: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: 3,
      backgroundColor: color.blue
    }
  }));

  return <View style={styles.indicator} />;
};

export default TabIndicator;
