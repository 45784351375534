import { useEventer } from "@gigsmart/dekigoto";
import React, { useCallback } from "react";
import { TouchableOpacity } from "react-native";
import { StyledIcon } from "../../icon";
import { createStyles, theme } from "../../style";

const styles = createStyles(({ color }) => ({
  spiceOrange: {
    color: color.spiceOrange
  },
  neutralDark: {
    color: color.neutralDark
  }
}));

interface Props {
  readonly onChangeValue?: (arg0: number) => void;
  readonly value?: number | null | undefined;
  readonly index: number;
  readonly readOnly?: boolean;
  readonly fontSize?: number;
}

export default function KatanaStar({
  index,
  value,
  onChangeValue,
  readOnly,
  fontSize = theme.units(6)
}: Props) {
  const isEmpty = !value || index > Math.ceil(value);
  const isHalf = value && Math.ceil(value) === index && index > value;
  const trackPress = useEventer("pressed", `${index}`, "Stars");
  const handleOnPress = useCallback(() => {
    trackPress();
    onChangeValue?.(index);
  }, [index, trackPress, onChangeValue]);
  return (
    <TouchableOpacity
      disabled={readOnly}
      testID={`katana_star_${index}`}
      onPress={handleOnPress}
    >
      <StyledIcon
        style={isEmpty ? styles.neutralDark : styles.spiceOrange}
        size={fontSize}
        name={isHalf ? "star-half-alt" : "star"}
        variant={!isHalf && isEmpty ? "light" : "solid"}
      />
    </TouchableOpacity>
  );
}
