import { Column, ContentArea } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import DirectHireActionRow from "../../direct-hire/worker/DirectHireActionRow";
import EngagementRatingStack from "../../engagement/EngagementRatingStack";
import VerifyMileage from "../../engagement/VerifyMileage";
import { hasEngagementCapability } from "../../engagement/WithEngagementCapability";
import WorkerTimesheetInfoCard from "../../engagement/WorkerTimesheetInfoCard";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "../../worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import WorkerPaymentSurface from "../accessory/WorkerPaymentSurface";
import WorkerCancellationFeeSummary from "./WorkerCancellationFeeSummary";
import type { WorkerShiftSummaryTabQuery } from "./__generated__/WorkerShiftSummaryTabQuery.graphql";

type Props = {
  id: string;
};

export default createSuspendedQueryContainer<WorkerShiftSummaryTabQuery, Props>(
  function WorkerShiftSummaryTab({ response }) {
    const node = response?.node;

    switch (node?.currentState?.name) {
      case "CANCELED_WITH_PAY": {
        return <WorkerCancellationFeeSummary fragmentRef={node} />;
      }
      case "PENDING_TIMESHEET_APPROVAL": {
        return (
          <Column gap="standard">
            <VerifyMileage
              gigEstimatedMileage={node?.gig?.estimatedMileage}
              gigDuration={node?.duration?.totalDurationWorked}
              engagementId={node?.id}
              fragmentRef={node}
            />
            {hasEngagementCapability(node, "APPROVE_SYSTEM_TIMESHEET") && (
              <WorkerTimesheetInfoCard fragmentRef={node} />
            )}
            <WorkerPaymentSurface fragmentRef={node} />
          </Column>
        );
      }
      default:
        return (
          <Column gap="standard">
            <WorkerPaymentSurface fragmentRef={node} />
            <ContentArea size="none">
              <DirectHireActionRow variant="card" />
            </ContentArea>
            <EngagementRatingStack variant="worker" fragmentRef={node} />
            {showWorkerBlockOrg(node?.gig, node?.currentState?.name) && (
              <BlockOrganizationCollapsibleRow
                organizationId={node?.gig?.organization?.id ?? ""}
              />
            )}
          </Column>
        );
    }
  },
  {
    query: graphql`
      query WorkerShiftSummaryTabQuery($id: ID!) {
        node(id: $id) {
          ...VerifyMileage_engagement
          ...WorkerPaymentSurface_shift
          ...EngagementRatingStack_engagement
          ...WithEngagementCapability_engagement
          ...WorkerTimesheetInfoCard_engagement
          ...WorkerCancellationFeeSummary_shift
          ... on Engagement {
            id
            currentState {
              name
            }
            gig {
              ...BlockOrganizationCollapsibleRow_shift
              estimatedMileage
              organization {
                id
              }
            }
            duration: timesheet {
              totalDurationWorked
            }
          }
        }
      }
    `,
    variables: ({ id }) => ({ id })
  }
);
