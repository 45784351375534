import type { HOCVoid } from "@gigsmart/hoc-utils";
import type { ComponentType } from "react";

interface Options {
  fade?: boolean;
  delay?: number;
}

export async function show(_fade?: boolean) {}

export async function hide(_fade?: boolean, _delay = 0) {}

export default (_options: Options): HOCVoid =>
  function withBootSplash<Props>(WrappedComponent: ComponentType<Props>) {
    return WrappedComponent;
  };
