import type { AppScreenProps } from "@gigsmart/kaizoku";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import AvailableGigDetailAlt from "./available-gig-detail-alt";
import AvailableGigSeriesDetail from "./available-gig-series-detail";

type Props = AppScreenProps<
  WorkerParamList,
  "BrowseShiftDetails" | "BrowseProjectDetails"
>;

/**
 * @deprecated
 */
export default function AvailableGigDetailsScreen({ route }: Props) {
  const { id } = route.params;
  const isSeries = id.startsWith("gigsr");
  if (isSeries) return <AvailableGigSeriesDetail id={id} />;
  return <AvailableGigDetailAlt id={id} />;
}
