import { isEqual } from "lodash";
import React, { Component } from "react";
import { Image, View } from "react-native";

import { type IconName, StyledIcon } from "../icon";
import {
  type ImageStyleProp,
  type StylesProps,
  type TextStyleProp,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { IconButton } from "../tappable";
import { StyledText } from "../text";

type Props = StylesProps & {
  isPublic?: boolean;
  uploadIcon: IconName;
  placeholderIcon: IconName;
  placeholderText: string;
  circle: boolean;
  placeholderBackgroundColor: string;
  backgroundColor: string;
  onCameraPress?: () => unknown;
  onPrivacyToggle?: (
    arg0: (string | null | undefined) | (boolean | null | undefined)
  ) => void;
  showControls?: boolean;
  showLabel?: boolean;
  sourceUri?: string | null;
  sourceUriId?: string | null;
  testID?: string | undefined;
  width: number;
  imageStyle?: ViewStyleProp;
  subtext?: string;
  subtextStyle?: TextStyleProp;
};

interface State {
  didError: boolean;
  isPublic?: boolean;
}

@withStyles(({ color, font }) => ({
  container: {
    position: "relative"
  },
  disabled: {
    color: color.black,
    opacity: 0.5
  },
  enabled: {
    color: color.black
  },
  icon: {
    fontSize: font.size.medium,
    marginRight: 5
  },
  iconBottom: {
    right: 0,
    position: "absolute",
    bottom: 0
  },
  iconCircleTop: {
    left: 0,
    position: "absolute",
    top: 0
  },
  iconSquareTop: {
    left: -20,
    top: -20,
    position: "absolute"
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  square: {
    marginTop: 20
  },
  image: {
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    width: "100%"
  },
  imageLabel: {
    marginTop: 5,
    color: color.white,
    fontSize: font.size.large,
    textAlign: "center",
    lineHeight: font.size.large * font.lineHeight.body
  },
  label: {
    fontSize: font.size.medium
  },
  placeholder: {
    fontSize: 150
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10
  },
  center: {
    alignItems: "center"
  }
}))
export default class Avatar extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    isPublic: false,
    circle: true,
    width: 100,
    uploadIcon: "camera",
    placeholderBackgroundColor: "blue",
    backgroundColor: "transparent",
    placeholderIcon: "user",
    placeholderText: "Upload\nPhoto"
  };

  static getDerivedStateFromProps({ isPublic }: Props, state: State) {
    return { ...state, isPublic };
  }

  state = {
    didError: false,
    isPublic: this.props.isPublic
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const shouldUpdateSourceUri = nextProps.sourceUriId
      ? nextProps.sourceUriId !== this.props.sourceUriId
      : nextProps.sourceUri !== this.props.sourceUri;
    return (
      // shallow checks
      nextProps.isPublic !== this.props.isPublic ||
      nextProps.showControls !== this.props.showControls ||
      nextProps.showLabel !== this.props.showLabel ||
      shouldUpdateSourceUri ||
      nextProps.width !== this.props.width ||
      nextProps.subtext !== this.props.subtext ||
      nextState.didError !== this.state.didError ||
      nextState.isPublic !== this.state.isPublic || // deep checks
      !isEqual(nextProps.subtextStyle, this.props.subtextStyle)
    );
  }

  handleError = () => this.setState({ didError: true });

  handlePrivacyToggle = () => {
    this.props.onPrivacyToggle
      ? this.props.onPrivacyToggle(this.state.isPublic)
      : this.setState((state) => ({ isPublic: !state.isPublic }));
  };

  get containerStyles() {
    return [this.props.styles.container, { width: this.props.width }];
  }

  get imageStyle(): ImageStyleProp {
    const { width, circle } = this.props;
    return {
      borderRadius: circle ? width / 2 : 0,
      height: width,
      width,
      overflow: "hidden"
    };
  }

  renderImage() {
    const {
      styles,
      sourceUri,
      imageStyle,
      theme,
      backgroundColor: bgColor
    } = this.props;

    return (
      <View style={this.imageStyle}>
        <Image
          resizeMode="contain"
          source={{ uri: sourceUri ?? "" }}
          style={
            [
              styles.image,
              imageStyle,
              { backgroundColor: theme.color.getColor(bgColor) }
            ] as ImageStyleProp
          }
          onError={this.handleError}
        />
      </View>
    );
  }

  renderSubtext() {
    const { styles, subtext, subtextStyle } = this.props;
    if (!subtext) return;
    return (
      <View style={styles.row}>
        <StyledText style={subtextStyle}>{subtext}</StyledText>
      </View>
    );
  }

  renderLabel() {
    const { styles, isPublic } = this.props;
    if (isPublic) {
      return (
        <View style={styles.row}>
          <StyledIcon name="eye" style={[styles.icon, styles.disabled]} />
          <StyledText style={[styles.label, styles.disabled]}>
            Public
          </StyledText>
        </View>
      );
    }
    return (
      <View style={styles.row}>
        <StyledIcon name="eye-slash" style={[styles.icon, styles.disabled]} />
        <StyledIcon name="key" style={[styles.icon, styles.disabled]} />
        <StyledText style={[styles.label, styles.disabled]}>
          Private until gig accepted
        </StyledText>
      </View>
    );
  }

  renderPlaceholder(showControls: boolean | null | undefined) {
    const {
      theme,
      styles,
      width,
      placeholderText,
      placeholderIcon,
      placeholderBackgroundColor: bgColor
    } = this.props;
    return (
      <View
        style={[
          styles.image,
          this.imageStyle,
          { backgroundColor: theme.color.getColor(bgColor) }
        ]}
      >
        <StyledIcon
          color="white"
          name={placeholderIcon}
          variant="solid"
          style={{ fontSize: width / 2.5 }}
        />
        {showControls && (
          <StyledText style={[styles.imageLabel]}>{placeholderText}</StyledText>
        )}
      </View>
    );
  }

  render() {
    const {
      onCameraPress,
      onPrivacyToggle,
      showControls,
      showLabel,
      sourceUri,
      testID,
      width,
      styles,
      theme,
      circle,
      uploadIcon
    } = this.props;
    const { didError, isPublic } = this.state;
    const showCameraButton = showControls && onCameraPress && width > 135;
    const showPrivacyButton = showControls && onPrivacyToggle && width > 135;
    const shouldRenderImage = sourceUri && !didError;
    return (
      <View style={[styles.center, !circle && styles.square]}>
        <View style={this.containerStyles}>
          {shouldRenderImage
            ? this.renderImage()
            : this.renderPlaceholder(showControls)}
          {showCameraButton && onCameraPress && (
            <View style={circle ? styles.iconCircleTop : styles.iconSquareTop}>
              <IconButton
                testID={testID}
                backgroundColor={theme.color.orange}
                diameter={width * 0.3}
                icon={uploadIcon}
                iconColor={theme.color.white}
                onPress={onCameraPress}
                width={width * 0.3}
              />
            </View>
          )}
          {shouldRenderImage && showPrivacyButton && (
            <View style={styles.iconBottom}>
              <IconButton
                testID="avatar-privacy-toggle"
                backgroundColor={
                  isPublic ? theme.color.white : theme.color.neutralDark
                }
                borderColor={isPublic ? theme.color.spiceBlue : null}
                diameter={width * 0.25}
                icon={isPublic ? "eye" : "eye-slash"}
                iconColor={isPublic ? theme.color.blue : theme.color.white}
                onPress={this.handlePrivacyToggle}
                width={width * 0.25}
              />
            </View>
          )}
        </View>
        {this.renderSubtext()}
        {showLabel && shouldRenderImage && this.renderLabel()}
      </View>
    );
  }
}
