import { Button, Stack, Surface, Text, toast } from "@gigsmart/atorasu";
import {
  type FomuOnChangeFn,
  type FomuSubmitFn,
  Form,
  FormField,
  FormSubmit,
  Validator,
  useFormRef
} from "@gigsmart/fomu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, {
  type ComponentProps,
  type ComponentType,
  useCallback,
  useState
} from "react";
import CardCheckbox from "./CardCheckbox";
import LegalDocument from "./LegalDocument";
import type { ConsentDocumentForm_consent$key } from "./__generated__/ConsentDocumentForm_consent.graphql";
import type { ConsentDocumentForm_document$key } from "./__generated__/ConsentDocumentForm_document.graphql";
import { useLegalDocumentData } from "./useLegalDocument";

interface ConsentType {
  prompt: string;
  checked: boolean;
  onConsent: (arg0: boolean) => void;
}

interface Props {
  defaultTitle?: string;
  defaultPrompt?: string;
  defaultDescription?: string;
  document?: ConsentDocumentForm_document$key;
  consent?: ConsentDocumentForm_consent$key | null | undefined;
  additionalConsents?: ConsentType[];
  onComplete: () => void;
  SubmitButton?: ComponentType<ComponentProps<typeof Button>>;
  allowUnread?: boolean;
  hideDocument?: boolean;
  autoSubmit?: boolean;
}

export default function ConsentDocumentForm({
  document: documentFragmentRef,
  consent: consentFragmentRef,
  onComplete,
  SubmitButton,
  additionalConsents = [],
  defaultTitle,
  defaultPrompt = "I agree to the terms.",
  defaultDescription,
  hideDocument = false,
  allowUnread = false,
  autoSubmit = false
}: Props) {
  const [complete, setComplete] = useState(false);
  const document = useRelayFragment(
    graphql`
      fragment ConsentDocumentForm_document on LegalDocument {
        ...useLegalDocument_document
        ...LegalDocument_document
      }
    `,
    documentFragmentRef ?? null
  );
  const consent = useRelayFragment(
    graphql`
      fragment ConsentDocumentForm_consent on UserConsentDocument {
        ...LegalDocument_consent
      }
    `,
    consentFragmentRef ?? null
  );
  const { sign, prompts, preamble, filename } = useLegalDocumentData(document, {
    defaultDescription,
    defaultTitle,
    defaultPrompt
  });

  const handleComplete = useCallback<() => void>(() => {
    setComplete(true);
    onComplete();
  }, [onComplete]);

  const handleSubmit = useCallback<FomuSubmitFn>(
    async (_, done) => {
      try {
        await sign(handleComplete);
      } catch (err) {
        toast.error(err.message ?? "Something went wrong.");
      } finally {
        done();
      }
    },
    [handleComplete, sign]
  );

  const formRef = useFormRef();

  const handleChange = useCallback<FomuOnChangeFn>(
    (changeset) => {
      if (autoSubmit && changeset.valid) formRef?.current?.act?.("submit");
    },
    [autoSubmit, formRef]
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} onChange={handleChange}>
      <Stack size="compact">
        <FormField
          name={`${filename}:consent-0`}
          validates={Validator.isTrue({ required: true })}
        />
        <FormField
          name="scroll-to-end"
          validates={allowUnread ? undefined : Validator.isTrue()}
        >
          {({ setValue }) => (
            <LegalDocument
              document={document}
              consent={consent}
              onEndReached={() => setValue(true)}
              hideDocument={hideDocument}
            />
          )}
        </FormField>
        <Surface variant="flat">
          <Stack size="compact">
            {preamble && (
              <Text variant="note" weight="normal" color="black">
                {preamble}
              </Text>
            )}
            {!filename.startsWith("gigsmart/") &&
              additionalConsents.map(
                ({ prompt: additionalPrompt, checked, onConsent }, idx) => (
                  <CardCheckbox
                    key={idx}
                    name={`additional_consent_${idx}`}
                    prompt={additionalPrompt}
                    onToggle={() => onConsent(!checked)}
                    toggled={checked}
                  />
                )
              )}
            {prompts.map((promptItem, idx) => (
              <CardCheckbox
                disabled={complete}
                toggled={complete ? true : undefined}
                key={`${filename}:consent-${idx}`}
                name={`${filename}:consent-${idx}`}
                testID={`${filename}:consent-${idx}`}
                validates={Validator.isTrue({ required: true })}
                prompt={promptItem}
              />
            ))}
            {!autoSubmit && (
              <FormSubmit>
                {({ submit, invalid, submitting }) => {
                  const ButtonComponent = SubmitButton ?? Button;
                  return (
                    <ButtonComponent
                      testID="consent-submit"
                      label="Agree & Continue"
                      onPress={submit}
                      disabled={invalid || submitting}
                    />
                  );
                }}
              </FormSubmit>
            )}
          </Stack>
        </Surface>
      </Stack>
    </Form>
  );
}
