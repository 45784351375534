import React from "react";

import { GridNull, Text } from "@gigsmart/atorasu";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";

import HomeScreenCard from "./HomescreenCard";

export default function WelcomeCard() {
  const [welcomeCardShown, setWelcomeCardShown, isLoading] =
    useUserValue<boolean>("WORKER_HOMESCREEN_WELCOME_CARD");

  if (welcomeCardShown || isLoading) return <GridNull />;

  return (
    <HomeScreenCard
      headerIcon="sparkles"
      headerTitle="Welcome to Get Gigs!"
      testID="welcome-card"
      footerLinkText="Learn More about Getting Started"
      footerLinkUrl="https://gigsmart.com/find-work/get-gigs-welcome-center/"
      onDismiss={async () => await setWelcomeCardShown(true)}
    >
      <Text>
        Whether you are looking for temporary work, or full or part-time
        employment, GigSmart has you covered!
      </Text>
    </HomeScreenCard>
  );
}
