/**
 * @generated SignedSource<<cb78424518e6431829946b732940bd9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileInfoCard_worker$data = {
  readonly biography: string | null | undefined;
  readonly favoritedByOrganizationsCount: number;
  readonly firstName: string | null | undefined;
  readonly gigStats: {
    readonly gigsCompleted: number;
    readonly totalEarned?: string;
    readonly uniqueOrganizationsWorked: number;
  } | null | undefined;
  readonly id: string;
  readonly overallRating: number;
  readonly reviewsReceived: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderRefreshed_worker">;
  readonly " $fragmentType": "ProfileInfoCard_worker";
};
export type ProfileInfoCard_worker$key = {
  readonly " $data"?: ProfileInfoCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileInfoCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipIfNotWorker"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileInfoCard_worker",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipIfNotWorker",
          "variableName": "skipIfNotWorker"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProfileHeaderRefreshed_worker"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biography",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerGigStats",
      "kind": "LinkedField",
      "name": "gigStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigsCompleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uniqueOrganizationsWorked",
          "storageKey": null
        },
        {
          "condition": "skipIfNotWorker",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalEarned",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoritedByOrganizationsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        }
      ],
      "concreteType": "EngagementReviewsConnection",
      "kind": "LinkedField",
      "name": "reviewsReceived",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "reviewsReceived(first:0)"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "6f9331cb6c1c0c9644aa907b1acbb01d";

export default node;
