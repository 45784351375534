import React, { type ReactNode, useCallback } from "react";
import { Pressable, View } from "react-native";
import { usePlaidLink } from "react-plaid-link";

// Import the RN types so we can adapt the same interface
import type {
  LinkError,
  LinkErrorCode,
  LinkErrorType
} from "react-native-plaid-link-sdk";

interface Props {
  token: string;
  noLoadingState?: boolean;
  children: ReactNode;
  onToken: (token: string, accountId: string) => void;
  onError: (error: LinkError) => void;
  onClose?: () => void;
}

function transformError(json: any): LinkError | undefined {
  if (json) {
    return {
      errorCode: json.error_code as LinkErrorCode,
      errorType: json.error_type as LinkErrorType,
      errorMessage: json.error_message,
      errorDisplayMessage: json.display_message,
      errorJson: JSON.stringify(json)
    };
  }
}

export default function LinkVerifiedBankAccount({
  token,
  noLoadingState,
  children,
  onToken,
  onError,
  onClose
}: Props) {
  const onSuccess = useCallback(
    (token: string, metadata: { accounts: Array<{ id: string }> }) => {
      onClose?.();
      onToken(token, metadata.accounts[0]?.id ?? "");
    },
    [onToken, onClose]
  );
  const onExit = useCallback(
    (err: any) => {
      onClose?.();
      const error = transformError(err);
      error && onError(error);
    },
    [onError, onClose]
  );
  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit
  });
  if (!ready) return null;
  return (
    <Pressable onPress={() => open()}>
      <View>{children}</View>
    </Pressable>
  );
}
