import { Button } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useFormSubmit } from "@gigsmart/fomu";
import React, { type ComponentProps } from "react";
import { View } from "react-native";

type Props = Omit<ComponentProps<typeof Button>, "onPress"> & {
  allowInvalid?: boolean;
};

export function FomuStyledSubmitButton({
  allowInvalid,
  disabled,
  ...props
}: Props) {
  const { submit, invalid } = useFormSubmit({ allowInvalid });
  const styles = useStyles(({ getUnits }) => ({
    spacer: {
      marginVertical: getUnits(2.5)
    }
  }));
  return (
    <View style={styles.spacer}>
      <Button onPress={submit} disabled={invalid || disabled} {...props} />
    </View>
  );
}
