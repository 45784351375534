/**
 * @generated SignedSource<<023c11635898906ca5d9a700cff3fc6b>>
 * @relayHash b6af7a0ddbf0e34a53632bff6f287d21
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:oPsK_MWPW8LKGAk55FbMGb9rYYkPzZybrOTU009xZ1k

import { ConcreteRequest } from 'relay-runtime';
export type useLoginUpdateAdminMutation$variables = {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
};
export type useLoginUpdateAdminMutation$data = {
  readonly updateAdmin: {
    readonly admin: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    };
  } | null | undefined;
};
export type useLoginUpdateAdminMutation = {
  response: useLoginUpdateAdminMutation$data;
  variables: useLoginUpdateAdminMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastName"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoginUpdateAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminPayload",
        "kind": "LinkedField",
        "name": "updateAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLoginUpdateAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminPayload",
        "kind": "LinkedField",
        "name": "updateAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:oPsK_MWPW8LKGAk55FbMGb9rYYkPzZybrOTU009xZ1k",
    "metadata": {},
    "name": "useLoginUpdateAdminMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1f9333619a9c0555cc95e936c1e44cee";

export default node;
