import { IconText, Surface, TimeRemaining, Well } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import moment from "moment";
import React from "react";

import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { BrowseShiftTopAccessory_shift$key } from "./__generated__/BrowseShiftTopAccessory_shift.graphql";

type Props = {};

export const BrowseShiftTopAccessory = ({
  startsAt,
  endsAt,
  pickup,
  nextGig
}: FragmentContainerInnerComponentProps<
  BrowseShiftTopAccessory_shift$key,
  Props
>) => {
  const gig = nextGig?.edges?.[0]?.node;
  if (gig) {
    startsAt = gig.startsAt;
    endsAt = gig.endsAt;
  }

  const hours = moment(startsAt ?? endsAt).diff(Date.now(), "hour", true);
  const showRemainingTime = hours > 0 && hours < 48;
  if (!pickup?.eligible && !showRemainingTime) return null;

  const color = pickup?.eligible ? "emphasized" : "danger";
  const textProps = {
    color,
    size: "small",
    textWeight: "bold",
    iconAlign: "center"
  } as const;

  return (
    <Surface variant="flat">
      <Well color={color} size="compact">
        {pickup?.eligible ? (
          <IconText {...textProps} icon="alarm-clock">
            Shift Available Today (
            <TimeRemaining
              weight="bold"
              size="lg"
              showLargestOnly
              date={pickup?.windowEnd}
            />{" "}
            left to pickup)
          </IconText>
        ) : (
          <IconText {...textProps} icon="clock">
            {hours <= 1
              ? "Less than 1 hour"
              : pluralize(Math.round(hours), "hour")}{" "}
            remaining to apply
          </IconText>
        )}
      </Well>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  BrowseShiftTopAccessory_shift$key,
  Props
>(
  graphql`
    fragment BrowseShiftTopAccessory_shift on GigLike {
      ... on Gig {
        startsAt
        endsAt
        pickup {
          eligible
          windowEnd
        }
      }
      ... on GigSeries  {
        nextGig: availableGigs(first: 1) {
          edges {
            node {
              startsAt
              endsAt
            }
          }
        }
      }
    }
  `,
  BrowseShiftTopAccessory
);
