import "./src/app/env.web";
import { AppRegistry } from "react-native";
import "./index.ts";
import { isMobileDevice } from "@gigsmart/isomorphic-shared/app/helpers/links";

const ready = () => {
  if (isMobileDevice() && process.env.URL_SCHEME) {
    window.location.href = `${process.env.URL_SCHEME}://${window.location.pathname}`;
  }

  AppRegistry.runApplication("Worker", {
    rootTag: document.getElementById("react-root")
  });
};

if (document.readyState !== "loading") {
  ready();
} else {
  document.addEventListener("DOMContentLoaded", ready);
}
