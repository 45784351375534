/**
 * @generated SignedSource<<759b24ff143afaf525f5da3002442b2a>>
 * @relayHash cfa145163404cf9d9a8b8ac92a40078c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:jE1_nfcJzerWcIvRqbQhk_-nqR4vZFNokZSxGwuuAxE

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type availableGigSeriesDetailQuery$variables = {
  id: string;
};
export type availableGigSeriesDetailQuery$data = {
  readonly viewer: {
    readonly gigSeries?: {
      readonly node: {
        readonly gigType: GigType | null | undefined;
        readonly id: string;
        readonly nextGig: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly endsAt: string | null | undefined;
              readonly payRate: string | null | undefined;
              readonly startsAt: string | null | undefined;
            } | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionHeader_availableGig">;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly organization: {
          readonly id: string;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift" | "RequesterInfoRow_gigLike" | "WorkerApplyFooter_gigLike" | "gigDescription_gigLike">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"gigSummaryRows_availableGigSeries">;
    } | null | undefined;
    readonly id?: string;
  } | null | undefined;
};
export type availableGigSeriesDetailQuery = {
  response: availableGigSeriesDetailQuery$data;
  variables: availableGigSeriesDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestArrivalTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = [
  (v17/*: any*/),
  (v1/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupEligible",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptsTips",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v22 = [
  (v9/*: any*/),
  (v1/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredPermissions",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "GigState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "RemoteApplicationTag",
  "kind": "LinkedField",
  "name": "customTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationIcon",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slots",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v29 = [
  (v28/*: any*/),
  (v1/*: any*/)
],
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    (v30/*: any*/)
  ],
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastInitial",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    }
  ],
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": [
    (v27/*: any*/)
  ],
  "storageKey": "reviewsReceived(first:0)"
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v28/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "availableGigSeriesDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AvailableGigSeriesEdge",
                "kind": "LinkedField",
                "name": "gigSeries",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "gigSummaryRows_availableGigSeries"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "BlockOrganizationCollapsibleRow_shift",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/)
                            ],
                            "type": "GigLike",
                            "abstractKey": "__isGigLike"
                          }
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "WorkerApplyFooter_gigLike"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "gigDescription_gigLike"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RequesterInfoRow_gigLike"
                      },
                      {
                        "alias": "nextGig",
                        "args": (v6/*: any*/),
                        "concreteType": "AvailableGigsConnection",
                        "kind": "LinkedField",
                        "name": "availableGigs",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AvailableGigsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ShiftDescriptionHeader_availableGig"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Gig",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "availableGigs(first:1)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "availableGigSeriesDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AvailableGigSeriesEdge",
                "kind": "LinkedField",
                "name": "gigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasMultipleLocations",
                        "storageKey": null
                      },
                      {
                        "alias": "nextGig",
                        "args": (v6/*: any*/),
                        "concreteType": "AvailableGigsConnection",
                        "kind": "LinkedField",
                        "name": "availableGigs",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AvailableGigsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "distance",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Gig",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "negotiable",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "paymentType",
                                    "storageKey": null
                                  },
                                  (v8/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Pickup",
                                    "kind": "LinkedField",
                                    "name": "pickup",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "eligible",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "arrivalInstructions",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "area",
                                    "storageKey": null
                                  },
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Crisis",
                                    "kind": "LinkedField",
                                    "name": "crisis",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigCategory",
                                    "kind": "LinkedField",
                                    "name": "category",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v16/*: any*/),
                                    "concreteType": "GigSkillsConnection",
                                    "kind": "LinkedField",
                                    "name": "skills",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSkillsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GigSkill",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Skill",
                                                "kind": "LinkedField",
                                                "name": "skill",
                                                "plural": false,
                                                "selections": (v18/*: any*/),
                                                "storageKey": null
                                              },
                                              (v1/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "skills(first:10)"
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigPosition",
                                    "kind": "LinkedField",
                                    "name": "position",
                                    "plural": false,
                                    "selections": (v18/*: any*/),
                                    "storageKey": null
                                  },
                                  (v19/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigSeries",
                                    "kind": "LinkedField",
                                    "name": "gigSeries",
                                    "plural": false,
                                    "selections": [
                                      (v20/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isGigLike"
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementPaymentInfo",
                                "kind": "LinkedField",
                                "name": "estimatedPayment",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "paySchedule",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "billableDuration",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "grossPay",
                                    "storageKey": null
                                  },
                                  (v21/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WorkerServiceFee",
                                    "kind": "LinkedField",
                                    "name": "serviceFees",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "amount",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hourlyRate",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "feeType",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "availableGigs(first:1)"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": "nextGig",
                        "args": (v6/*: any*/),
                        "concreteType": "AvailableGigsConnection",
                        "kind": "LinkedField",
                        "name": "availableGigs",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AvailableGigsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Gig",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "EngagementPaymentInfo",
                                        "kind": "LinkedField",
                                        "name": "estimatedPayment",
                                        "plural": false,
                                        "selections": [
                                          (v21/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "AvailableGigsEdge",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "EngagementState",
                                        "kind": "LinkedField",
                                        "name": "currentState",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "action",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "EngagementNegotiation",
                                            "kind": "LinkedField",
                                            "name": "negotiation",
                                            "plural": false,
                                            "selections": (v22/*: any*/),
                                            "storageKey": null
                                          },
                                          (v1/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "EngagementPaymentInfo",
                                        "kind": "LinkedField",
                                        "name": "estimatedPayment",
                                        "plural": false,
                                        "selections": [
                                          (v21/*: any*/),
                                          (v9/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Gig",
                                        "kind": "LinkedField",
                                        "name": "gig",
                                        "plural": false,
                                        "selections": (v22/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Engagement",
                                    "abstractKey": null
                                  }
                                ],
                                "type": "AvailableGigOrEngagement",
                                "abstractKey": "__isAvailableGigOrEngagement"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "availableGigs(first:1)"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v20/*: any*/),
                          (v13/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v15/*: any*/),
                              (v23/*: any*/),
                              (v19/*: any*/),
                              (v7/*: any*/),
                              (v12/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v26/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": (v16/*: any*/),
                                "concreteType": "GigPhotosConnection",
                                "kind": "LinkedField",
                                "name": "photos",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigPhotosEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigPhoto",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v29/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "photos(first:10)"
                              },
                              (v31/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Requester",
                                "kind": "LinkedField",
                                "name": "requester",
                                "plural": false,
                                "selections": [
                                  (v32/*: any*/),
                                  (v33/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "primaryOrganization",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v17/*: any*/),
                                      (v30/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v34/*: any*/),
                                  (v35/*: any*/),
                                  (v36/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "Gig",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "nextGig",
                                "args": (v6/*: any*/),
                                "concreteType": "AvailableGigsConnection",
                                "kind": "LinkedField",
                                "name": "availableGigs",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AvailableGigsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Gig",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v15/*: any*/),
                                          (v23/*: any*/),
                                          (v19/*: any*/),
                                          (v12/*: any*/),
                                          (v24/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "availableGigs(first:1)"
                              },
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "boosted",
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v25/*: any*/),
                              {
                                "alias": null,
                                "args": (v16/*: any*/),
                                "concreteType": "GigSeriesPhotosConnection",
                                "kind": "LinkedField",
                                "name": "photos",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigSeriesPhotosEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSeriesPhoto",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v29/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "photos(first:10)"
                              },
                              (v31/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Requester",
                                "kind": "LinkedField",
                                "name": "requester",
                                "plural": false,
                                "selections": [
                                  (v32/*: any*/),
                                  (v33/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "primaryOrganization",
                                    "plural": false,
                                    "selections": [
                                      (v17/*: any*/),
                                      (v30/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v34/*: any*/),
                                  (v35/*: any*/),
                                  (v36/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "GigSeries",
                            "abstractKey": null
                          }
                        ],
                        "type": "GigLike",
                        "abstractKey": "__isGigLike"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:jE1_nfcJzerWcIvRqbQhk_-nqR4vZFNokZSxGwuuAxE",
    "metadata": {},
    "name": "availableGigSeriesDetailQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "962b47842048ed1f034f4253e5ad3e4a";

export default node;
