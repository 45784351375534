import React from "react";
import { View } from "react-native";

import { theme } from "../style";

const DIRECTION = {
  column: "height",
  row: "width"
};

const BORDER = {
  column: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.neutralLight
  },
  row: {
    borderRightWidth: 1,
    borderRightColor: theme.color.neutralLight
  }
};

interface Props {
  readonly units?: number | string;
  readonly direction?: "row" | "column";
  readonly fill?: boolean;
  readonly border?: boolean;
}

export default function Spacer({
  units = 4,
  direction = "column",
  fill = false,
  border = false
}: Props) {
  const computedStyle = {
    [DIRECTION[direction]]:
      typeof units === "string" ? units : theme.units(units),
    ...(border ? BORDER[direction] : undefined),
    ...(fill ? { flexGrow: 1 } : undefined)
  };

  return <View pointerEvents="none" style={computedStyle} />;
}
