import { ContextProvider } from "recyclerlistview";

export default class ContextHelper extends ContextProvider {
  constructor(uniqueKey: string) {
    super();
    this._contextStore = {};
    this._uniqueKey = uniqueKey;
  }

  private readonly _uniqueKey: string;
  private _contextStore: Record<string, string | number>;

  getUniqueKey() {
    return this._uniqueKey;
  }

  save(key: string, value: string | number) {
    this._contextStore[key] = value;
  }

  get(key: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    return this._contextStore[key]!;
  }

  remove(key: string) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this._contextStore[key];
  }
}
