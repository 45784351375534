/**
 * @generated SignedSource<<bc230c7483087e7363624c004dc26bde>>
 * @relayHash 1ad45644e1797d039ff7468cd3c36e91
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:NDJkbHBW68ug34FbVaUogYDfRsPN1U5tm5SCvYKohQI

import { ConcreteRequest } from 'relay-runtime';
export type hooksPlaceDetailsSilentQuery$variables = {
  placeId: string;
};
export type hooksPlaceDetailsSilentQuery$data = {
  readonly place: {
    readonly administrativeAreaLevel1?: string | null | undefined;
    readonly administrativeAreaLevel2?: string | null | undefined;
    readonly area?: string | null | undefined;
    readonly country?: string | null | undefined;
    readonly id?: string;
    readonly locality?: string | null | undefined;
    readonly location?: {
      readonly latitude: number;
      readonly longitude: number;
    } | null | undefined;
    readonly postalCode?: string | null | undefined;
  } | null | undefined;
};
export type hooksPlaceDetailsSilentQuery = {
  response: hooksPlaceDetailsSilentQuery$data;
  variables: hooksPlaceDetailsSilentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "placeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "administrativeAreaLevel1",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "administrativeAreaLevel2",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksPlaceDetailsSilentQuery",
    "selections": [
      {
        "alias": "place",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Place",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hooksPlaceDetailsSilentQuery",
    "selections": [
      {
        "alias": "place",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Place",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:NDJkbHBW68ug34FbVaUogYDfRsPN1U5tm5SCvYKohQI",
    "metadata": {},
    "name": "hooksPlaceDetailsSilentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "318b6049b87804b9ebc170de6a3f372b";

export default node;
