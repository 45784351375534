import React from "react";

import { ActionRow, Icon, Stack } from "@gigsmart/atorasu";
import { WorkerBlockOrg } from "@gigsmart/feature-flags";
import HomeScreenCard from "@gigsmart/seibutsu/worker/HomescreenCard";

interface Props {
  onPressEdit?: () => void;
  onPressAccountSettings?: () => void;
  onPressEmergencyContacts?: () => void;
  onPressBlockedOrganizationManagement?: () => void;
}
export default function SettingsCard({
  onPressEdit,
  onPressAccountSettings,
  onPressEmergencyContacts,
  onPressBlockedOrganizationManagement
}: Props) {
  const renderIcon = () => (
    <Icon name="chevron-right" size="small" color="primary" variant="solid" />
  );

  return (
    <HomeScreenCard headerIcon="user-gear" headerTitle="Settings">
      <Stack size="compact">
        <ActionRow
          title="Edit Profile"
          icon="user"
          testID="settings-card-edit-profile-row"
          onPress={onPressEdit}
          right={renderIcon()}
        />
        <ActionRow
          title="Account Settings"
          icon="gear"
          testID="settings-card-account-settings-row"
          onPress={onPressAccountSettings}
          right={renderIcon()}
        />
        <ActionRow
          title="Emergency Contacts"
          icon="phone"
          testID="settings-card-emergency-contacts-row"
          onPress={onPressEmergencyContacts}
          right={renderIcon()}
        />
        {WorkerBlockOrg.isEnabled() && (
          <ActionRow
            title="Blocked Organization Management"
            icon="hexagon-xmark"
            testID="settings-card-blocked-organization-management-row"
            onPress={onPressBlockedOrganizationManagement}
            right={renderIcon()}
          />
        )}
      </Stack>
    </HomeScreenCard>
  );
}
