import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";

const inactiveStates = new Set<EngagementStateName>([
  "ENDED",
  "PAYMENT_FAILED",
  "MISSED",
  "CANCELED",
  "APPLICATION_DENIED",
  "APPLICATION_CANCELED",
  "REJECTED"
]);

export const isStateActive = (
  workerStateName?: EngagementStateName,
  isReviewed?: boolean
) => {
  return (
    !!workerStateName &&
    (!inactiveStates.has(workerStateName) ||
      (workerStateName === "ENDED" && !isReviewed))
  );
};

export const initialShiftTab = (
  stateName?: EngagementStateName | null
): "DETAILS" | "TIMESHEET" | "SUMMARY" => {
  return stateName === "PENDING_TIMESHEET_APPROVAL" || stateName === "ENDED"
    ? "SUMMARY"
    : "DETAILS";
};
