/**
 * @generated SignedSource<<853f468fe07962d3e127edcd390380be>>
 * @relayHash 16bc8cc5b6f704be18ea17407105de42
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:A9bjQQdAZ6yz4dmleg6vMpEhvGWL6bfvMTveuzS63uo

import { ConcreteRequest } from 'relay-runtime';
export type useLoginUpdateRequesterMutation$variables = {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
};
export type useLoginUpdateRequesterMutation$data = {
  readonly updateRequester: {
    readonly requester: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    };
  } | null | undefined;
};
export type useLoginUpdateRequesterMutation = {
  response: useLoginUpdateRequesterMutation$data;
  variables: useLoginUpdateRequesterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastName"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoginUpdateRequesterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRequesterPayload",
        "kind": "LinkedField",
        "name": "updateRequester",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Requester",
            "kind": "LinkedField",
            "name": "requester",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLoginUpdateRequesterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRequesterPayload",
        "kind": "LinkedField",
        "name": "updateRequester",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Requester",
            "kind": "LinkedField",
            "name": "requester",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:A9bjQQdAZ6yz4dmleg6vMpEhvGWL6bfvMTveuzS63uo",
    "metadata": {},
    "name": "useLoginUpdateRequesterMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "82824b99b06f007512c4acb361654bda";

export default node;
