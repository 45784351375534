/**
 * @generated SignedSource<<76e5a3813c37853d5c17067cc37dc7f0>>
 * @relayHash e1eb18e6e3a171560262935ed35fd3b5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:UYR8qjLgPsI7oJrBIJ3D4sMnZ_wwti_9KhVHzU_oGWg

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
export type UpdateDirectHireClaimInput = {
  comments?: string | null | undefined;
  directHireClaimId: string;
  directHireDate?: string | null | undefined;
  directHireType?: DirectHireType | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  organizationId?: string | null | undefined;
  paymentMethodId?: string | null | undefined;
  placeId?: string | null | undefined;
  response?: string | null | undefined;
  timezone?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type OrganizationStepUpdateDirectHireClaimMutation$variables = {
  input: UpdateDirectHireClaimInput;
};
export type OrganizationStepUpdateDirectHireClaimMutation$data = {
  readonly updateDirectHireClaim: {
    readonly directHireClaim: {
      readonly directHireDate: string | null | undefined;
      readonly directHireType: DirectHireType | null | undefined;
      readonly firstName: string | null | undefined;
      readonly id: string;
      readonly lastName: string | null | undefined;
      readonly place: {
        readonly streetAddress: string | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type OrganizationStepUpdateDirectHireClaimMutation = {
  response: OrganizationStepUpdateDirectHireClaimMutation$data;
  variables: OrganizationStepUpdateDirectHireClaimMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directHireDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directHireType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationStepUpdateDirectHireClaimMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDirectHireClaimPayload",
        "kind": "LinkedField",
        "name": "updateDirectHireClaim",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectHireClaim",
            "kind": "LinkedField",
            "name": "directHireClaim",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Place",
                "kind": "LinkedField",
                "name": "place",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationStepUpdateDirectHireClaimMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDirectHireClaimPayload",
        "kind": "LinkedField",
        "name": "updateDirectHireClaim",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectHireClaim",
            "kind": "LinkedField",
            "name": "directHireClaim",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Place",
                "kind": "LinkedField",
                "name": "place",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:UYR8qjLgPsI7oJrBIJ3D4sMnZ_wwti_9KhVHzU_oGWg",
    "metadata": {},
    "name": "OrganizationStepUpdateDirectHireClaimMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5a9edf70c8c73c751ee51eeb78d65891";

export default node;
