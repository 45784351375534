/**
 * @generated SignedSource<<e564b93288568fae4d986804816bd7b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DirectHireAction = "ACCEPT" | "ACKNOWLEDGE" | "AUTHOR" | "CHARGE" | "CLOSE" | "DENY" | "ESCALATE" | "GOODWILL" | "PUBLISH" | "REJECT" | "TIMEOUT" | "%future added value";
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DirectPaymentsAndHiresRow_DirectHireClaim$data = {
  readonly directHireType: DirectHireType | null | undefined;
  readonly id: string;
  readonly organization: {
    readonly logoUrl: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly rejected: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly action: DirectHireAction;
        readonly transitionedAt: string;
        readonly transitionedBy: {
          readonly agent: {
            readonly __typename: string;
          } | null | undefined;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly responseDeadline: string | null | undefined;
  readonly transitions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly transitionedAt: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly workerPayout: string | null | undefined;
  readonly " $fragmentType": "DirectPaymentsAndHiresRow_DirectHireClaim";
};
export type DirectPaymentsAndHiresRow_DirectHireClaim$key = {
  readonly " $data"?: DirectPaymentsAndHiresRow_DirectHireClaim$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectPaymentsAndHiresRow_DirectHireClaim">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectPaymentsAndHiresRow_DirectHireClaim",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerPayout",
      "storageKey": null
    },
    {
      "alias": "rejected",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE state = REJECTED"
        }
      ],
      "concreteType": "DirectHireClaimTransitionsConnection",
      "kind": "LinkedField",
      "name": "transitions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DirectHireClaimTransitionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DirectHireClaimTransition",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccessContext",
                  "kind": "LinkedField",
                  "name": "transitionedBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "agent",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "action",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "transitions(first:1,query:\"WHERE state = REJECTED\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "responseDeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE state = DISBURSED"
        }
      ],
      "concreteType": "DirectHireClaimTransitionsConnection",
      "kind": "LinkedField",
      "name": "transitions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DirectHireClaimTransitionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DirectHireClaimTransition",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "transitions(first:1,query:\"WHERE state = DISBURSED\")"
    }
  ],
  "type": "DirectHireClaim",
  "abstractKey": null
};
})();

(node as any).hash = "bc3cd6c52d7236696943e297791bdcbc";

export default node;
