import {
  Column,
  ContentArea,
  HighlightedReminder,
  Stack,
  Text,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, type ValueObject } from "@gigsmart/fomu";
import { useNavigation } from "@gigsmart/kaizoku";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import FomuSubmit from "../../fomu/inputs/FomuSubmit";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";
import { showBlockOrgModal } from "./BlockOrgModal";
import type { BlockOrgQuestionnaire_worker$key } from "./__generated__/BlockOrgQuestionnaire_worker.graphql";

interface Props {
  fragmentRef?: BlockOrgQuestionnaire_worker$key | null;
  organizationId: string;
}

export default function BlockOrgQuestionnaire({
  fragmentRef,
  organizationId
}: Props) {
  const worker = useRelayFragment(
    graphql`
    fragment BlockOrgQuestionnaire_worker on Worker @argumentDefinitions(
      blockedOrgsFilter: { type: "CqlFilterWorkerBlockedOrganizationInput" }
      activeOrgEngagementsFilter: { type: "CqlFilterEngagementInput" }
    ) {
      id
      blockedOrganizations(first: 0, where: $blockedOrgsFilter) {
        totalCount
      }
      activeOrgEngagements: engagements(first: 0, where: $activeOrgEngagementsFilter) {
        totalCount
      }
    }`,
    fragmentRef ?? null
  );
  const navigation = useNavigation();
  const hasActiveEngagements =
    (worker?.activeOrgEngagements?.totalCount ?? 0) > 0;

  const handleSubmit: FomuSubmitFn = ({ values }: ValueObject, done) => {
    showBlockOrgModal({
      organizationId,
      reason: values.blockReason,
      onSuccess: () => {
        toast.success("Organization has been successfully blocked.");
        navigation.goBack();
      }
    });
    done();
  };

  if (hasActiveEngagements) {
    return (
      <ContentArea>
        <HighlightedReminder
          icon="lock-keyhole"
          variant="danger"
          header={
            "Organization cannot be blocked while scheduled for Active Shifts."
          }
        />
      </ContentArea>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ContentArea>
        <Stack>
          <Text testID="block-org-instructional-txt">
            By blocking, you will no longer see or get notified of the
            Organization's available Shifts.
          </Text>
          <ContentArea size="none" variant="standard">
            <FomuTextInput
              name="blockReason"
              label="Why are you blocking this Organization?"
              testID="worker-block-org-text-input"
              placeholder="Enter additional information"
              multiline
              note="Minimum 25 characters"
              min={25}
            />
          </ContentArea>
          <Column gap="standard">
            <FomuSubmit
              size="small"
              alignSelf="center"
              testID="submit-btn"
              icon="octagon-xmark"
              color="danger"
              label="Block Organization"
            />
          </Column>
        </Stack>
      </ContentArea>
    </Form>
  );
}
