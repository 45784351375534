import { ButtonTabs, ContentArea, Stack } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { useEffect } from "react";
import MarkAllAsReadButton from "./MarkAllAsReadButton";
import type { ConversationStateTabsOrganization_viewer$key } from "./__generated__/ConversationStateTabsOrganization_viewer.graphql";
import type { ConversationStateTabsParticipating_viewer$key } from "./__generated__/ConversationStateTabsParticipating_viewer.graphql";
import type { ConversationStateTabs_user$key } from "./__generated__/ConversationStateTabs_user.graphql";

interface BaseProps {
  activeFilter: "active" | "inactive";
  setActiveFilter: (filter: "active" | "inactive", isEmpty: boolean) => void;
  activeTab?: "participating" | "organization";
}

interface UIProps extends Omit<BaseProps, "activeTab"> {
  activeCount?: number;
  inactiveCount?: number;
  showMarkAllAsReadBtn?: boolean;
}

interface Props extends BaseProps {
  fragmentRef: ConversationStateTabs_user$key | null | undefined;
}

function BaseConversationStateTabs({
  activeFilter,
  setActiveFilter,
  activeCount = 0,
  inactiveCount = 0,
  showMarkAllAsReadBtn
}: UIProps) {
  useEffect(() => {
    setActiveFilter(
      activeFilter,
      activeFilter === "active" ? activeCount === 0 : inactiveCount === 0
    );
  }, [activeCount, inactiveCount]);
  return (
    <ContentArea>
      <Stack horizontal justifyContent="space-between" size="slim">
        <ButtonTabs
          activeTab={activeFilter}
          setActiveTab={(t) =>
            setActiveFilter(
              t as "active" | "inactive",
              t === "active" ? activeCount === 0 : inactiveCount === 0
            )
          }
          tabs={[
            {
              label: "Active",
              count: activeCount,
              value: "active"
            },
            {
              label: "Inactive",
              count: inactiveCount,
              value: "inactive"
            }
          ]}
        />
        <MarkAllAsReadButton visible={showMarkAllAsReadBtn} />
      </Stack>
    </ContentArea>
  );
}

function ParticipatingConversationStateTabs({
  fragmentRef,
  ...rest
}: BaseProps & {
  fragmentRef: ConversationStateTabsParticipating_viewer$key | null | undefined;
}) {
  const viewer = useRelayFragment(
    graphql`
      fragment ConversationStateTabsParticipating_viewer on User {
        active: conversations(
          first: 0
          query: "WHERE status = OPEN AND conversationType != SHIFT_GROUP"
        ) {
          totalCount
        }
        inactive: conversations(
          first: 0
          query: "WHERE status = CLOSED AND conversationType != SHIFT_GROUP"
        ) {
          totalCount
        }
        userMessageStats(conversationTypes: [ENGAGEMENT, ORGANIZATION_WORKER]) {
          unreadCount
        }
      }
    `,
    fragmentRef
  );
  return (
    <BaseConversationStateTabs
      {...rest}
      activeCount={viewer?.active?.totalCount ?? 0}
      inactiveCount={viewer?.inactive?.totalCount ?? 0}
      showMarkAllAsReadBtn={(viewer?.userMessageStats?.unreadCount ?? 0) > 0}
    />
  );
}

function OrganizationConversationStateTabs({
  fragmentRef,
  ...rest
}: BaseProps & {
  fragmentRef: ConversationStateTabsOrganization_viewer$key | null | undefined;
}) {
  const viewer = useRelayFragment(
    graphql`
      fragment ConversationStateTabsOrganization_viewer on OrganizationRequester
      @argumentDefinitions(
        activeQuery: { type: "String" }
        inactiveQuery: { type: "String" }
      ) {
        organization {
          orgActive: conversations(first: 0, query: $activeQuery) {
            totalCount
          }
          orgInactive: conversations(first: 0, query: $inactiveQuery) {
            totalCount
          }
        }
      }
    `,
    fragmentRef
  );
  return (
    <BaseConversationStateTabs
      {...rest}
      activeCount={viewer?.organization?.orgActive?.totalCount ?? 0}
      inactiveCount={viewer?.organization?.orgInactive?.totalCount ?? 0}
    />
  );
}

export default function ConversationStateTabs({
  activeTab = "participating",
  fragmentRef,
  ...rest
}: Props) {
  const viewer = useRelayFragment<ConversationStateTabs_user$key>(
    graphql`
      fragment ConversationStateTabs_user on User
      @argumentDefinitions(
        activeQuery: { type: "String" }
        inactiveQuery: { type: "String" }
      ) {
        ...ConversationStateTabsParticipating_viewer
        ... on OrganizationRequester {
          ...ConversationStateTabsOrganization_viewer
            @arguments(activeQuery: $activeQuery, inactiveQuery: $inactiveQuery)
        }
      }
    `,
    fragmentRef
  );
  if (activeTab === "organization")
    return <OrganizationConversationStateTabs fragmentRef={viewer} {...rest} />;
  return <ParticipatingConversationStateTabs fragmentRef={viewer} {...rest} />;
}
