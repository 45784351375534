import React, { useMemo, useState } from "react";

import {
  ActionSheet,
  type ActionSheetOptions,
  ContentArea,
  Icon,
  IconButton,
  Pressable,
  Stack,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { NavPortalEntrance } from "@gigsmart/kaizoku";
import { graphql, useRelayFragment, useRelayMutation } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import type { AdminMoreMenuSetConversationStatusMutation } from "./__generated__/AdminMoreMenuSetConversationStatusMutation.graphql";
import type { AdminMoreMenuUpdateShiftGroupChatMutation } from "./__generated__/AdminMoreMenuUpdateShiftGroupChatMutation.graphql";
import type {
  AdminMoreMenu_conversationLike$data,
  AdminMoreMenu_conversationLike$key
} from "./__generated__/AdminMoreMenu_conversationLike.graphql";

interface Props {
  fragmentRef: AdminMoreMenu_conversationLike$key;
  editing: boolean;
  setEditing: (editing: boolean) => void;
}

export default function AdminMoreMenu({
  editing,
  setEditing,
  fragmentRef
}: Props) {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const [visible, setVisible] = useState(false);
  const conversation = useRelayFragment<AdminMoreMenu_conversationLike$key>(
    graphql`
      fragment AdminMoreMenu_conversationLike on ConversationLike {
        __typename
        status
        participant {
          id
          readUntil
        }
        ... on OrganizationWorkerConversation {
          id
          capabilities
          organization {
            conversationSettings {
              allowConversationStatusUpdate
            }
          }
          worker {
            id
          }
        }
        ... on EngagementConversation {
          engagement {
            worker {
              id
            }
          }
        }
        ... on ShiftGroupConversation {
          gig {
            id
            conversationRule
            currentState {
              name
            }
          }
        }
      }
    `,
    fragmentRef
  );

  const { id, gig, __typename, organization, status } = conversation;

  const [setStatus] =
    useRelayMutation<AdminMoreMenuSetConversationStatusMutation>(graphql`
      mutation AdminMoreMenuSetConversationStatusMutation(
        $input: SetConversationStatusInput!
      ) {
        setConversationStatus(input: $input) {
          conversation {
            ... on OrganizationWorkerConversation {
              id
            }
            __typename
            ... on ConversationLike {
              status
            }
          }
        }
      }
    `);

  const [commit] =
    useRelayMutation<AdminMoreMenuUpdateShiftGroupChatMutation>(graphql`
      mutation AdminMoreMenuUpdateShiftGroupChatMutation(
        $input: SetGigLikeConversationRuleInput!
      ) {
        setGigLikeConversationRule(input: $input) {
          gigLike {
            ... on GigLike {
              id
              conversationRule
            }
            ... on Gig {
              id
              conversationRule
              conversation {
                id
                status
                participant {
                  messageStats {
                    unreadCount
                  }
                }
                currentMessage {
                  id
                }
              }
            }
          }
        }
      }
    `);
  const currentUser = useCurrentUser();
  const allowEditing = currentUser?.role === "ADMIN";

  const { allowConversationStatusUpdate, conversationOpen } = useMemo(() => {
    const allowConversationStatusUpdate =
      !!organization?.conversationSettings?.allowConversationStatusUpdate;
    const conversationOpen = status === "OPEN";
    return { allowConversationStatusUpdate, conversationOpen };
  }, [organization, status]);
  const handleChangeConversationRule = (
    rule: ObjectPath<
      AdminMoreMenu_conversationLike$data,
      ["gig", "conversationRule"]
    >
  ) => {
    if (!rule || !gig?.id) return;
    commit(
      { input: { conversationRule: rule, gigLikeId: gig?.id } },
      {
        optimisticResponse: {
          setGigLikeConversationRule: {
            gigLike: {
              id,
              conversationRule: rule
            }
          }
        }
      }
    );
  };

  const handleSetStatus = () => {
    if (!id) return;
    setStatus(
      {
        input: {
          conversationId: id,
          status: conversationOpen ? "CLOSED" : "OPEN"
        }
      },
      {
        onSuccess: () => {
          setVisible(false);
        }
      }
    );
  };

  const options = useMemo(() => {
    const options: ActionSheetOptions = [];
    if (allowEditing) {
      options.push({
        testID: "set-editing-messages-btn",
        label: `${
          editing ? "Disable Message Deletion" : "Enable Message Deletion"
        }`,
        onPress: () => setEditing(!editing)
      });
    }
    if (__typename === "OrganizationWorkerConversation") {
      if (allowConversationStatusUpdate) {
        options.push({
          testID: "set-conversation-status-btn",
          label: `${conversationOpen ? "Disable" : "Enable"} Conversation`,
          onPress: handleSetStatus
        });
      }
    }
    if (__typename === "ShiftGroupConversation") {
      if (
        gig?.conversationRule === "ALL_PARTICIPANTS" ||
        gig?.conversationRule === "DISABLED"
      ) {
        options.push({
          testID: "disable-workers-from-messaging-btn",
          label: "Allow Requester Only Messaging",
          onPress: () => handleChangeConversationRule("REQUESTER_ONLY")
        });
      }
      if (
        gig?.conversationRule === "REQUESTER_ONLY" ||
        gig?.conversationRule === "DISABLED"
      ) {
        options.push({
          testID: "allow-workers-to-message-btn",
          label: "Allow Workers to Message",
          onPress: () => handleChangeConversationRule("ALL_PARTICIPANTS")
        });
      }
      if (gig?.conversationRule !== "DISABLED") {
        options.push({
          testID: "disable-group-chat-btn",
          label: "Disable Group Chat",
          onPress: () => handleChangeConversationRule("DISABLED"),
          destructive: true
        });
      }
    }

    return options;
  }, [
    gig?.conversationRule,
    conversationOpen,
    allowConversationStatusUpdate,
    allowEditing,
    editing
  ]);

  return (
    <ActionSheet
      visible={visible}
      options={options}
      position="right"
      onClose={() => setVisible(false)}
    >
      {isMd && (
        <Pressable
          testID="conversation-more-menu-btn"
          onPress={() => setVisible(true)}
          eventEntityType="Button"
          eventTargetName="ConversationMoreMenuButton"
        >
          <Stack
            horizontal
            alignItems="center"
            justifyContent="center"
            size="compact"
          >
            <Icon
              name="ellipsis-vertical"
              color="primary"
              size="small"
              variant="solid"
            />
            <Text color="primary" weight="bold">
              More
            </Text>
          </Stack>
        </Pressable>
      )}
      {!isMd && (
        <NavPortalEntrance
          rightAccessory={
            <ContentArea size="none">
              <IconButton
                size="medium"
                variant="solid"
                name="ellipsis-vertical"
                onPress={() => setVisible(true)}
                testID="conversation-more-menu-btn"
              />
            </ContentArea>
          }
        />
      )}
    </ActionSheet>
  );
}
