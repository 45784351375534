import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import * as React from "react";
import { TouchableOpacity } from "react-native";
import { StyledIcon } from "../icon";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import StyledText from "../text/styled-text";

type Props = StylesProps &
  EventerProps<"pressed"> & {
    canAdd: boolean;
    onAdd: () => void;
    title?: string;
    testID?: string;
  };

@withStyles(({ color }) => ({
  formButton: {
    alignItems: "center",
    borderColor: color.neutralDark,
    backgroundColor: color.white,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20
  },
  formButtonTop: {
    borderTopWidth: 1
  },
  icon: {
    color: color.orange,
    marginRight: 10
  }
}))
@withEventers<"pressed", Props>("Button", ["pressed"], "title")
export default class AddButton extends React.Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    eventers: eventerStubs(["pressed"]),
    canAdd: true,
    onAdd: () => {},
    title: "Add",
    testID: ""
  };

  handleAdd = () => {
    this.props.eventers.pressed();
    this.props.onAdd();
  };

  render() {
    const { styles, canAdd, onAdd, testID, title } = this.props;
    if (!canAdd) return null;
    return (
      <TouchableOpacity
        style={styles.formButton}
        onPress={onAdd}
        testID={testID}
      >
        <StyledIcon name="plus" style={styles.icon} />
        <StyledText color="blue">{title}</StyledText>
      </TouchableOpacity>
    );
  }
}
