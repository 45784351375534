import { ContentRow, Row, Stack, Text } from "@gigsmart/atorasu";
import { getEngagementAddonSpec } from "@gigsmart/isomorphic-shared/addon/dictionary";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import { StyledLink } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerGigReceipt_engagement$key } from "./__generated__/WorkerGigReceipt_engagement.graphql";

export function WorkerGigReceipt({
  currentState,
  paymentInfo
}: FragmentContainerInnerComponentProps<WorkerGigReceipt_engagement$key>) {
  const isPending = currentState?.name === "PENDING_TIMESHEET_APPROVAL";
  return (
    <Stack variant="divider">
      <ContentRow verticalSize="compact">
        <Row justifyContent="space-between" alignItems="center" fill>
          <Stack size="slim">
            <Text>Total Hourly Pay</Text>
            {!isPending && (
              <Text variant="note" color="neutral">
                {currency.humanize(paymentInfo?.payRate)} (
                {duration.humanize(
                  paymentInfo?.billableDuration,
                  "semi-compact-no-spaces"
                )}
                )
              </Text>
            )}
          </Stack>
          <Text>
            {isPending
              ? "Pending Approval"
              : currency.humanize(paymentInfo?.basePay)}
          </Text>
        </Row>
      </ContentRow>
      {!isPending &&
        paymentInfo?.additionalPay &&
        paymentInfo?.additionalPay !== "0 USD" && (
          <ContentRow verticalSize="compact">
            <Row justifyContent="space-between" alignItems="center" fill>
              <Stack size="slim">
                <Text>Additional Payment</Text>
                <Text variant="note" color="neutral">
                  Requesters use this for tips, reimbursements, etc.
                </Text>
              </Stack>
              <Text>+ {currency.humanize(paymentInfo?.additionalPay)}</Text>
            </Row>
          </ContentRow>
        )}
      {paymentInfo?.serviceFees?.map((fee) => {
        const isHourly = currency.toFloat(fee?.hourlyRate) > 0;
        const addonSpec = fee.feeType
          ? getEngagementAddonSpec(fee.feeType)
          : null;
        if (!addonSpec) return null;
        return (
          <ContentRow
            verticalSize="compact"
            key={`${addonSpec?.name}-receipt-row`}
          >
            <Row justifyContent="space-between" alignItems="center" fill>
              <Stack size="slim">
                <Text>{addonSpec?.name}</Text>
                <Stack horizontal size="compact">
                  {isHourly && (
                    <Text variant="note" color="neutral">
                      {currency.humanizeRate(fee?.hourlyRate)}
                    </Text>
                  )}
                  {addonSpec?.linkText && (
                    <StyledLink fontSize={12} openURL={addonSpec?.linkUrl}>
                      {addonSpec?.linkText}
                    </StyledLink>
                  )}
                </Stack>
              </Stack>
              <Text>
                {isPending
                  ? "Pending Approval"
                  : `- ${currency.humanize(fee?.amount)}`}
              </Text>
            </Row>
          </ContentRow>
        );
      })}
      <ContentRow verticalSize="compact">
        <Row justifyContent="space-between" alignItems="center" fill>
          <Stack size="slim">
            <Text weight="semibold">Total Earnings</Text>
          </Stack>
          <Text weight="semibold">
            {isPending
              ? "Pending Approval"
              : currency.humanize(paymentInfo?.netPay)}
          </Text>
        </Row>
      </ContentRow>
    </Stack>
  );
}

export default createRelayFragmentContainer<WorkerGigReceipt_engagement$key>(
  graphql`
    fragment WorkerGigReceipt_engagement on Engagement {
      currentState {
        name
      }
      paymentInfo {
        billableDuration
        additionalPay
        serviceFees {
          hourlyRate
          amount
          feeType
        }
        basePay
        netPay
        payRate
      }
    }
  `,
  WorkerGigReceipt
);
