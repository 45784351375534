/**
 * @generated SignedSource<<49eaf6d7521a7d3f70c749342d2c380c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerShiftCardFooter_gigLike$data = {
  readonly acceptsTips: boolean;
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly paymentType: PaymentType | null | undefined;
  readonly " $fragmentType": "WorkerShiftCardFooter_gigLike";
};
export type WorkerShiftCardFooter_gigLike$key = {
  readonly " $data"?: WorkerShiftCardFooter_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCardFooter_gigLike">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerShiftCardFooter_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptsTips",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "6482295ef38942df18f12a95e1d5298a";

export default node;
