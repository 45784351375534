import { AppBottomTabs } from "@gigsmart/atorasu";
import { IS_TESTING } from "@gigsmart/isomorphic-shared";
import {
  type AppMenuItem,
  type BottomTabBarProps,
  createBottomTabNavigator
} from "@gigsmart/kaizoku";
import CurrentUserIcon from "@gigsmart/seibutsu/user/CurrentUserIcon";
import { findFocusedRoute, useIsFocused } from "@react-navigation/native";
import React, { useMemo } from "react";
import BrowseTabScreen from "../gig/browse-tab/BrowseTabScreen";
import MyGigsScreen from "../gig/my-gigs-screen";
import HomeScreen from "../home-screen";
import ProfileScreen from "../profile/profile-screen";
import { WalletScreen } from "../wallet";
import type { WorkerParamList } from "./types";

const Tab = createBottomTabNavigator<WorkerParamList>();

export default function WorkerTabs() {
  const isFocused = useIsFocused();
  if (IS_TESTING && !isFocused) return null;

  return (
    <Tab.Navigator
      initialRouteName="HomeTab"
      tabBar={(props) => <BottomTabs {...props} />}
      screenOptions={{
        headerShown: false,
        unmountOnBlur: IS_TESTING,
        tabBarHideOnKeyboard: true
      }}
    >
      <Tab.Screen
        name="HomeTab"
        component={HomeScreen}
        options={{ title: "Home" }}
      />
      <Tab.Screen
        name="MyGigs"
        component={MyGigsScreen}
        initialParams={{ tab: "shifts" }}
        options={{ title: "My Gigs" }}
      />
      <Tab.Screen name="Wallet" component={WalletScreen} />
      <Tab.Screen name="Browse" component={BrowseTabScreen} />
      <Tab.Screen name="Profile" component={ProfileScreen} />
    </Tab.Navigator>
  );
}

function BottomTabs({ state, navigation }: BottomTabBarProps) {
  const items = useMemo(
    (): Array<AppMenuItem<WorkerParamList>> => [
      { icon: "house", title: "Home", to: "HomeTab" },
      { icon: "briefcase", title: "My Gigs", to: "MyGigs" },
      { icon: "search", title: "Browse", to: "Browse" },
      { icon: "circle-dollar", title: "Wallet", to: "Wallet" },
      { icon: <CurrentUserIcon />, title: "Profile", to: "Profile" }
    ],
    []
  );

  const handleItemPress = (item: AppMenuItem) => {
    if (item === "separator") return;
    if ("to" in item) navigation.navigate(item.to);
    else item.onPress?.();
  };

  const curRoute = findFocusedRoute(state);
  return (
    <AppBottomTabs
      items={items}
      active={curRoute?.name}
      onPressItem={handleItemPress}
    />
  );
}
