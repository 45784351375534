import {
  createRelayNodeContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import WorkerProjectEngagementCard from "@gigsmart/seibutsu/engagement/WorkerProjectEngagementCard";
import InfiniteGridList from "@gigsmart/seibutsu/shared/InfiniteList/InfiniteGridList";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useMemo } from "react";
import MyGigsEmptyList from "./MyGigsEmpty";
import MyGigsFooter from "./MyGigsFooter";
import ProjectGigsFilter, {
  PROJECT_STATES,
  type ProjectFilterType,
  getProjectArgs
} from "./ProjectGigsFilter";
import type { ProjectGigsTabPageQuery } from "./__generated__/ProjectGigsTabPageQuery.graphql";
import type { ProjectGigsTabPage_worker$key } from "./__generated__/ProjectGigsTabPage_worker.graphql";
import type { ProjectGigsTabQuery } from "./__generated__/ProjectGigsTabQuery.graphql";
import type { ProjectGigsTabSubscription } from "./__generated__/ProjectGigsTabSubscription.graphql";
import type { ProjectGigsTab_worker$key } from "./__generated__/ProjectGigsTab_worker.graphql";

type EntryType = NonNullable<
  ObjectPath<
    ProjectGigsTabPage_worker$key,
    [" $data", "engagements", "edges", 0, "node"]
  >
>;

type Props = {
  active?: ProjectFilterType;
  onChange: (value: ProjectFilterType) => void;
  onPressItem: (id: string) => void;
};

export default createRelayNodeContainer<
  ProjectGigsTab_worker$key,
  ProjectGigsTabQuery,
  ProjectGigsTabSubscription,
  Props
>(
  function ProjectGigsTab({ result, active, onChange, onPressItem }) {
    const worker = result ?? null;
    const refetchVars = useMemo(() => getProjectArgs(active), [active]);

    return (
      <InfiniteGridList<
        ProjectGigsTabPageQuery,
        ProjectGigsTabPage_worker$key,
        EntryType
      >
        testID="project-gigs-tab"
        refetchDebounce={0}
        refetchVars={refetchVars}
        parentRef={worker}
        fragmentInput={graphql`
          fragment ProjectGigsTabPage_worker on Worker
          @refetchable(queryName: "ProjectGigsTabPageQuery")
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 10 }
            after: { type: "String" }
            where: { type: "CqlFilterEngagementInput!" }
            orderBy: { type: "[CqlOrderEngagementInput!]" }
          ) {
            engagements(first: $count, after: $after, where: $where, orderBy: $orderBy)
              @connection(key: "ProjectGigsTab_engagements") {
              edges {
                node {
                  id
                  ...WorkerProjectEngagementCard_engagement
                }
              }
            }
          }
        `}
        getData={(result) => getConnectionNodes(result?.engagements)}
        keyExtractor={(node) => node.id}
        renderHeaderView={() => (
          <ProjectGigsFilter
            fragmentRef={worker}
            active={active}
            onChange={onChange}
          />
        )}
        renderFooterView={(isLoading) => (
          <MyGigsFooter
            variant="projects"
            active={active}
            onChange={onChange}
            isEmpty={worker?.projectsCount?.totalCount === 0}
            isLoading={isLoading}
          />
        )}
        renderEmptyView={(isLoading) => (
          <MyGigsEmptyList
            isLoading={isLoading}
            variant="projects"
            active={active}
          />
        )}
        renderItem={(item) => (
          <WorkerProjectEngagementCard
            fragmentRef={item}
            onPress={() => onPressItem(item.id)}
          />
        )}
      />
    );
  },
  {
    fragment: graphql`
      fragment ProjectGigsTab_worker on Worker @argumentDefinitions(
      where: { type: "CqlFilterEngagementInput!" }
      orderBy: { type: "[CqlOrderEngagementInput!]" }
      projectsStates: { type: "[EngagementStateName!]!" }
      projectsHiredStates: { type: "[EngagementStateName!]!" }
      projectsAppliedStates: { type: "[EngagementStateName!]!" }
      projectsOfferedStates: { type: "[EngagementStateName!]!" }
    ) {
        projectsCount: engagements(first: 0, where: { gigType: { _eq: PROJECT }, currentStateName: { _in: $projectsStates }}) {
          totalCount
        }
        ...ProjectGigsFilter_worker
          @arguments(
            projectsStates: $projectsStates
            projectsHiredStates: $projectsHiredStates
            projectsAppliedStates: $projectsAppliedStates
            projectsOfferedStates: $projectsOfferedStates
          )
        ...ProjectGigsTabPage_worker @arguments(where: $where, orderBy: $orderBy)
      }
      `,
    query: graphql`
      query ProjectGigsTabQuery(
        $where: CqlFilterEngagementInput!
        $orderBy: [CqlOrderEngagementInput!]
        $projectsStates: [EngagementStateName!]!
        $projectsHiredStates: [EngagementStateName!]!
        $projectsAppliedStates: [EngagementStateName!]!
        $projectsOfferedStates: [EngagementStateName!]!
      ) {
        viewer {
          ...ProjectGigsTab_worker @arguments(
            where: $where
            orderBy: $orderBy
            projectsStates: $projectsStates
            projectsHiredStates: $projectsHiredStates
            projectsAppliedStates: $projectsAppliedStates
            projectsOfferedStates: $projectsOfferedStates
          )
        }
      }
    `,
    subscription: graphql`
      subscription ProjectGigsTabSubscription(
        $where: CqlFilterEngagementInput!
        $orderBy: [CqlOrderEngagementInput!]
        $projectsStates: [EngagementStateName!]!
        $projectsHiredStates: [EngagementStateName!]!
        $projectsAppliedStates: [EngagementStateName!]!
        $projectsOfferedStates: [EngagementStateName!]!
      ) {
        workerUpdated {
          worker {
            ...ProjectGigsTab_worker @arguments(
            where: $where
            orderBy: $orderBy
            projectsStates: $projectsStates
            projectsHiredStates: $projectsHiredStates
            projectsAppliedStates: $projectsAppliedStates
            projectsOfferedStates: $projectsOfferedStates
          )
          }
        }
      }
    `,
    getQueryFragmentRef: (response) => response?.viewer,
    getSubscriptionFragmentRef: (response) => response?.workerUpdated?.worker,
    variables: ({ active }) =>
      useMemo(
        () => ({
          ...getProjectArgs(active),
          projectsStates: PROJECT_STATES.projects,
          projectsHiredStates: PROJECT_STATES.hired,
          projectsAppliedStates: PROJECT_STATES.applied,
          projectsOfferedStates: PROJECT_STATES.offered
        }),
        []
      )
  }
);
