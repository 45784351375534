import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  type GraphQLTaggedNode,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";

import { readWorkerEnrolledMetadata } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useApplyMetadata";
import type { useEnrollmentDataFragment } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentData";

import type { useEngagementListActiveQuery } from "./__generated__/useEngagementListActiveQuery.graphql";
import type { useEngagementListActive_worker$key } from "./__generated__/useEngagementListActive_worker.graphql";
import type { useEngagementListAddActiveSubscription } from "./__generated__/useEngagementListAddActiveSubscription.graphql";
import type { useEngagementListAddCanceledSubscription } from "./__generated__/useEngagementListAddCanceledSubscription.graphql";
import type { useEngagementListAddCompletedSubscription } from "./__generated__/useEngagementListAddCompletedSubscription.graphql";
import type { useEngagementListAddOfferedSubscription } from "./__generated__/useEngagementListAddOfferedSubscription.graphql";
import type { useEngagementListCanceledQuery } from "./__generated__/useEngagementListCanceledQuery.graphql";
import type { useEngagementListCanceled_worker$key } from "./__generated__/useEngagementListCanceled_worker.graphql";
import type { useEngagementListCompletedQuery } from "./__generated__/useEngagementListCompletedQuery.graphql";
import type { useEngagementListCompleted_worker$key } from "./__generated__/useEngagementListCompleted_worker.graphql";
import type { useEngagementListOfferedQuery } from "./__generated__/useEngagementListOfferedQuery.graphql";
import type { useEngagementListOffered_worker$key } from "./__generated__/useEngagementListOffered_worker.graphql";
import type { useEngagementListRemoveSubscription } from "./__generated__/useEngagementListRemoveSubscription.graphql";
import type {
  useEngagementListSeriesAggregate_engagement$data,
  useEngagementListSeriesAggregate_engagement$key
} from "./__generated__/useEngagementListSeriesAggregate_engagement.graphql";

export type { useEngagementListRemoveSubscription };

export type EngagementListVariant =
  | "active"
  | "completed"
  | "canceled"
  | "offered";

export interface useEngagementListAddSubscription {
  active: useEngagementListAddActiveSubscription;
  completed: useEngagementListAddCompletedSubscription;
  canceled: useEngagementListAddCanceledSubscription;
  offered: useEngagementListAddOfferedSubscription;
}

export interface useEngagementListQuery {
  active: useEngagementListActiveQuery;
  completed: useEngagementListCompletedQuery;
  canceled: useEngagementListCanceledQuery;
  offered: useEngagementListOfferedQuery;
}

export interface useEngagementList_worker$key {
  active: useEngagementListActive_worker$key;
  completed: useEngagementListCompleted_worker$key;
  canceled: useEngagementListCanceled_worker$key;
  offered: useEngagementListOffered_worker$key;
}

export type EngagementListEntry<
  T extends EngagementListVariant = EngagementListVariant
> = NonNullable<
  ObjectPath<
    useEngagementList_worker$key[T],
    [" $data", "engagements", "edges", 0, "node"]
  >
>;

const removeSubscriptionSpec = graphql`
  subscription useEngagementListRemoveSubscription(
    $workerId: ID!
    $engagementStates: [EngagementStateName!]
  ) {
    engagementAdded(workerId: $workerId, engagementStates: $engagementStates) {
      newEngagementEdge {
        cursor
        node {
          id
          currentState {
            name
          }
        }
      }
    }
  }
`;

export const engagementVariants: Record<
  EngagementListVariant,
  {
    querySpec: GraphQLTaggedNode;
    fragmentSpec: GraphQLTaggedNode;
    addSubscriptionSpec: GraphQLTaggedNode;
    removeSubscriptionSpec: GraphQLTaggedNode;
  }
> = {
  active: {
    querySpec: graphql`
      query useEngagementListActiveQuery($query: String!) {
        viewer {
          ... on Worker {
            ...useEngagementListActive_worker @arguments(query: $query)
          }
        }
      }
    `,
    fragmentSpec: graphql`
      fragment useEngagementListActive_worker on Worker
      @refetchable(queryName: "useEngagementListActivePageQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        after: { type: "String" }
        query: { type: "String!" }
      ) {
        engagements(first: $count, after: $after, query: $query)
          @connection(key: "engagementList_engagements", filters: ["query"]) {
          edges {
            node {
              id
              ...activeEntry_engagement
            }
          }
        }
      }
    `,
    addSubscriptionSpec: graphql`
      subscription useEngagementListAddActiveSubscription(
        $workerId: ID!
        $engagementStates: [EngagementStateName!]
      ) {
        engagementAdded(
          workerId: $workerId
          engagementStates: $engagementStates
        ) {
          newEngagementEdge {
            cursor
            node {
              id
              ...activeEntry_engagement
            }
          }
        }
      }
    `,
    removeSubscriptionSpec
  },
  //
  completed: {
    querySpec: graphql`
      query useEngagementListCompletedQuery($query: String!) {
        viewer {
          ... on Worker {
            ...useEngagementListCompleted_worker @arguments(query: $query)
          }
        }
      }
    `,
    fragmentSpec: graphql`
      fragment useEngagementListCompleted_worker on Worker
      @refetchable(queryName: "useEngagementListCompletedPageQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        after: { type: "String" }
        query: { type: "String!" }
      ) {
        engagements(first: $count, after: $after, query: $query)
          @connection(key: "engagementList_engagements", filters: ["query"]) {
          edges {
            node {
              id
              ...completedEntry_engagement
            }
          }
        }
      }
    `,
    addSubscriptionSpec: graphql`
      subscription useEngagementListAddCompletedSubscription(
        $workerId: ID!
        $engagementStates: [EngagementStateName!]
      ) {
        engagementAdded(
          workerId: $workerId
          engagementStates: $engagementStates
        ) {
          newEngagementEdge {
            cursor
            node {
              id
              ...completedEntry_engagement
            }
          }
        }
      }
    `,
    removeSubscriptionSpec
  },
  //
  canceled: {
    querySpec: graphql`
      query useEngagementListCanceledQuery($query: String!) {
        viewer {
          ... on Worker {
            ...useEngagementListCanceled_worker @arguments(query: $query)
          }
        }
      }
    `,
    fragmentSpec: graphql`
      fragment useEngagementListCanceled_worker on Worker
      @refetchable(queryName: "useEngagementListCanceledPageQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        query: { type: "String!" }
      ) {
        engagements(first: $count, after: $after, query: $query)
          @connection(key: "engagementList_engagements", filters: ["query"]) {
          edges {
            node {
              id
              ...canceledEntry_engagement
            }
          }
        }
      }
    `,
    addSubscriptionSpec: graphql`
      subscription useEngagementListAddCanceledSubscription(
        $workerId: ID!
        $engagementStates: [EngagementStateName!]
      ) {
        engagementAdded(
          workerId: $workerId
          engagementStates: $engagementStates
        ) {
          newEngagementEdge {
            cursor
            node {
              id
              ...canceledEntry_engagement
            }
          }
        }
      }
    `,
    removeSubscriptionSpec
  },
  //
  offered: {
    querySpec: graphql`
      query useEngagementListOfferedQuery(
        $query: String!
        $includeGigSeries: Boolean!
      ) {
        viewer {
          ... on Worker {
            ...useEngagementListOffered_worker
              @arguments(query: $query, includeGigSeries: $includeGigSeries)
          }
        }
      }
    `,
    fragmentSpec: graphql`
      fragment useEngagementListOffered_worker on Worker
      @refetchable(queryName: "useEngagementListOfferedPageQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        after: { type: "String" }
        query: { type: "String!" }
        includeGigSeries: { type: "Boolean!" }
      ) {
        engagements(first: $count, after: $after, query: $query)
          @connection(key: "engagementList_engagements", filters: ["query"]) {
          totalCount
          edges {
            node {
              id
              currentState @trackImpressions(action: VIEWED) {
                name
              }
              ...offeredEntry_engagement
              ...WorkerShiftAggregateCard_engagement
              ...useEngagementListSeriesAggregate_engagement
                @include(if: $includeGigSeries)
            }
          }
        }
      }
    `,
    addSubscriptionSpec: graphql`
      subscription useEngagementListAddOfferedSubscription(
        $workerId: ID!
        $engagementStates: [EngagementStateName!]
        $includeGigSeries: Boolean!
      ) {
        engagementAdded(
          workerId: $workerId
          engagementStates: $engagementStates
        ) {
          newEngagementEdge {
            cursor
            node {
              id
              ...offeredEntry_engagement
              ...WorkerShiftAggregateCard_engagement
              ...useEngagementListSeriesAggregate_engagement
                @include(if: $includeGigSeries)
            }
          }
        }
      }
    `,
    removeSubscriptionSpec
  }
} as const;

//
// TODO: remove
// Temporary Fake GigSeries Card
//
export interface SeriesGroupData {
  seriesId: string;
  location: string | null;
  hasMultipleLocations: boolean;
  paymentRange?: { min: string; max: string };
  distanceRange?: { min: number; max: number };
  engagementRef: EngagementListEntry | null;
  nextGig: useEngagementListSeriesAggregate_engagement$data["gig"] | null;
  gigCount: number;
  isEnrolled: boolean;
  positionId: string;
}

export const aggregateEngagements = (
  raw: useEngagementList_worker$key["offered"][" $data"] | null | undefined,
  enrollmentData: ReturnType<typeof useEnrollmentDataFragment>
) => {
  const groups: SeriesGroupData[] = [];
  const getGroup = (seriesId: string) => {
    let group = groups.find((d) => d.seriesId === seriesId);
    if (!group) {
      groups.push(
        (group = {
          location: null,
          engagementRef: null,
          nextGig: null,
          hasMultipleLocations: false,
          seriesId,
          gigCount: 0,
          isEnrolled: true,
          positionId: ""
        })
      );
    }

    return group;
  };

  raw?.engagements?.edges?.forEach((edge) => {
    const node = edge?.node ?? null;
    const nodeData =
      readRelayInlineFragment<useEngagementListSeriesAggregate_engagement$key>(
        graphql`
          fragment useEngagementListSeriesAggregate_engagement on Engagement
          @inline {
            ...useApplyMetadataIsEnrolled_gigOrEngagement
            workerDistance
            estimatedPayment {
              grossPay
            }
            gig {
              ...WorkerLatestArrivalTimeReminder_gig
              place {
                streetAddress
              }
              gigSeries {
                id
              }
              position {
                id
              }
            }
          }
        `,
        node
      );
    const { doesNotRequireMoreInfo } = readWorkerEnrolledMetadata(
      enrollmentData,
      nodeData
    );

    const seriesId = nodeData?.gig?.gigSeries?.id;
    if (!nodeData || !seriesId) return;

    const group = getGroup(seriesId);

    group.gigCount += 1;

    // store first gig/engagement data
    if (!group.engagementRef) group.engagementRef = node;
    if (!group.nextGig) group.nextGig = nodeData.gig;

    // if any engagement says it's not enrolled, set the group as not enrolled
    if (group.isEnrolled) group.isEnrolled = doesNotRequireMoreInfo;
    group.positionId = nodeData?.gig?.position?.id ?? "";
    // compute min/max distance
    const distance = nodeData.workerDistance;
    if (distance) {
      if (group.distanceRange) {
        if (group.distanceRange.min > distance) {
          group.distanceRange.min = distance;
        }
        if (group.distanceRange.max < distance) {
          group.distanceRange.max = distance;
        }
      } else {
        group.distanceRange = { min: distance, max: distance };
      }
    }

    // compute min/max payment
    const grossPay = nodeData.estimatedPayment?.grossPay;
    if (grossPay) {
      if (group.paymentRange) {
        const min = currency.toFloat(group.paymentRange.min);
        const max = currency.toFloat(group.paymentRange.max);
        const current = currency.toFloat(grossPay);
        if (min > current) group.paymentRange.min = grossPay;
        if (max < current) group.paymentRange.max = grossPay;
      } else {
        // initial value
        group.paymentRange = { min: grossPay, max: grossPay };
      }
    }

    // determine if gig has multiple locations
    if (group.location) {
      if (!group.hasMultipleLocations) {
        group.hasMultipleLocations =
          group.location !== nodeData?.gig?.place?.streetAddress ?? null;
      }
    } else {
      // initial value
      group.location = nodeData?.gig?.place?.streetAddress ?? null;
      group.hasMultipleLocations = false;
    }
  });

  return groups;
};
