import { toast } from "@gigsmart/atorasu";
import type {
  RelayMiddleware,
  RelayMiddlewareNextFn,
  RelayRequest,
  RelayRequestBatch,
  RelayResponse
} from "@gigsmart/relay";
import { createLogger } from "@gigsmart/roga";
import NetInfo, { type NetInfoState } from "@react-native-community/netinfo";
import { AppState, type AppStateStatus } from "react-native";

const logger = createLogger("📶", "Offline Request Queue");

const label = "net-connection";
const showWarning = (reason: string) =>
  toast.error(reason, {
    label,
    sticky: true,
    dismissable: false,
    exclusive: true,
    icon: "wifi-slash"
  });
const dismissWarning = () => toast.dismiss({ label });

export default class OfflineRequestQueue {
  warning: { dismiss: () => void } = { dismiss: () => {} };

  static createMiddleware() {
    return new OfflineRequestQueue().createMiddleware();
  }

  drainAfterTimeout?: ReturnType<typeof setTimeout>;
  isConnected = false;
  queue = new Set<Function>();
  nextRequestBackoff = 200;

  constructor() {
    NetInfo.addEventListener(this.handleConnectionChange);
    AppState.addEventListener("change", this.handleAppStateChange);
    void NetInfo.fetch().then(
      async (info) => await this.handleConnectionChange(info)
    );
  }

  handleAppStateChange = async (nextState: AppStateStatus) => {
    if (nextState === "active") {
      const info = await NetInfo.fetch();
      await this.handleConnectionChange(info);
    }
  };

  handleConnectionChange = async ({ type }: NetInfoState) => {
    logger.info("Connection change", type);
    switch (type) {
      case "none":
        toast.error("No Internet Connection", {
          label,
          sticky: true,
          dismissable: false,
          exclusive: true,
          icon: "wifi-slash"
        });
        this.isConnected = false;
        break;
      default:
        toast.dismiss({ label });
        this.isConnected = true;
        await this.drainQueue();
    }
  };

  queueRequest = async (
    next: RelayMiddlewareNextFn,
    request: RelayRequest | RelayRequestBatch,
    reason: string,
    resolveAfter = 0
  ): Promise<RelayResponse> => {
    this.warning = toast.warning(reason, { label, sticky: true });
    return await new Promise<void>((resolve) => {
      const retryFn = () => {
        logger.debug(
          `Request queued, reason: '${reason}', auto-retry in: '${resolveAfter}ms'"`
        );
        this.queue.delete(retryFn);
        resolve();
      };

      this.queue.add(retryFn);
      setTimeout(retryFn, resolveAfter);
    }).then(async () => {
      return await this.invokeOrQueue(next, request);
    });
  };

  invokeOrQueue = async (
    next: RelayMiddlewareNextFn,
    request: RelayRequest | RelayRequestBatch
  ) => {
    const promise = (
      this.isConnected
        ? next(request)
        : this.queueRequest(next, request, "No Internet Connection", 200)
    )
      .catch(async (error) => {
        if (
          error.name === "TypeError" &&
          error.message === "Network request failed"
        ) {
          this.nextRequestBackoff = Math.min(
            30000,
            this.nextRequestBackoff * 2
          );
          return await this.queueRequest(
            next,
            request,
            "Service Unreachable",
            this.nextRequestBackoff
          );
        }
        return await Promise.reject(error);
      })
      .then((res) => {
        this.nextRequestBackoff = 100;
        this.warning.dismiss();
        return res;
      });

    return await promise;
  };

  drainQueue = async () => {
    try {
      // keep a copy because queue's retryFn mutates the object `queue`
      const queue = [...this.queue];
      // runs the queue in sequence
      for (const retryFn of queue) retryFn();
    } catch (err) {
      logger.debug("Error draining queue");
    }
  };

  createMiddleware = (): RelayMiddleware => {
    return (next) => async (request) => await this.invokeOrQueue(next, request);
  };
}
