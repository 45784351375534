import { Column, Row, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import type { Location } from "@gigsmart/isomorphic-shared/geolocation";
import { splitLabel } from "@gigsmart/katana";
import type { PredictionResult } from "@gigsmart/katana/input/predictive-input";
import React from "react";
import { TouchableOpacity } from "react-native";

interface Props {
  locations: Array<PredictionResult<Location>>;
  selectLocation: (location: PredictionResult<Location>) => void;
  searchTerm: string | null | undefined;
}

export default ({ locations, selectLocation, searchTerm }: Props) => {
  const styles = useStyles(({ getColor, getUnits }) => ({
    dropdownContainer: {
      backgroundColor: getColor("surface", "fill")
    },
    dropdownRow: {
      borderTopWidth: 1,
      borderTopColor: getColor("background", "fill"),
      padding: getUnits(4)
    }
  }));

  return (
    <Column style={styles.dropdownContainer}>
      {locations.map((location, index) => {
        const labelArray = splitLabel(searchTerm, location.label);
        return (
          <TouchableOpacity
            key={index}
            onPress={() => selectLocation(location)}
          >
            <Row style={styles.dropdownRow} alignItems="center">
              <Text numberOfLines={2}>
                <Text color="neutral">{labelArray[0]}</Text>
                <Text>{labelArray[1]}</Text>
                <Text color="neutral">{labelArray[2]}</Text>
              </Text>
            </Row>
          </TouchableOpacity>
        );
      })}
    </Column>
  );
};
