import {
  Column,
  ContentArea,
  Icon,
  InfoElement,
  Pressable,
  Spacer,
  Surface,
  Tag,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { GigStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import { type NavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useMemo } from "react";
import type { SharedParamList } from "../../navigation/types";
import type { ShiftGroupChatActionRow_gig$key } from "./__generated__/ShiftGroupChatActionRow_gig.graphql";

type Props = {};

const enabledStates: GigStateName[] = ["ACTIVE", "IN_PROGRESS", "UPCOMING"];

export const ShiftGroupChatActionRow = ({
  conversationRule,
  conversation,
  currentState
}: FragmentContainerInnerComponentProps<
  ShiftGroupChatActionRow_gig$key,
  Props
>) => {
  const navigation = useNavigation<NavigationProp<SharedParamList>>();
  const disabled = useMemo(
    () =>
      !conversation?.id ||
      !enabledStates.includes(currentState?.name) ||
      conversationRule === "DISABLED" ||
      (conversationRule === "REQUESTER_ONLY" &&
        conversation.messages?.totalCount === 0),
    [conversation, conversationRule, currentState?.name]
  );

  if (disabled) return null;

  const newMessageCount =
    conversation?.participant?.messageStats?.unreadCount ?? 0;
  const hasNewMessages = newMessageCount > 0;

  const handleShowInfo = () =>
    showModal({
      eventContext: "Shift Group Chat Info Modal",
      title: "Shift Group Chat",
      useModalBody: true,
      children: () => (
        <Column gap="standard">
          <Text>
            In this Shift Group Chat, you will be able to chat with Requesters
            and other Workers on this Shift.
          </Text>
          <Text>
            Once the Shift ends, the Chat will be inactive but you will still
            access to it.
          </Text>
        </Column>
      ),
      actions: [{ testID: "got-it-btn", label: "Got It" }]
    });

  return (
    <Pressable
      testID="shift-group-chat-row-btn"
      eventEntityType="ActionRow"
      eventTargetName="Shift Group Chat"
      onPress={() => {
        navigation.navigate("Conversation", {
          topicId: conversation?.id ?? ""
        });
      }}
    >
      <Surface variant="outline">
        <ContentArea
          horizontal
          size="compact"
          gap="compact"
          alignItems="center"
        >
          <Text>Shift Group Chat</Text>
          <InfoElement
            testID="shift-group-chat-info-btn"
            variant="icon"
            openModal={handleShowInfo}
          />
          <Spacer size="slim" />
          {hasNewMessages && (
            <Tag
              label={`${pluralize(newMessageCount, "New Message")}`}
              color="success"
              variant="clear"
            />
          )}
          <Column fill />
          <Icon
            name="chevron-right"
            color="primary"
            size="small"
            variant="solid"
          />
        </ContentArea>
      </Surface>
    </Pressable>
  );
};

export default createRelayFragmentContainer<
  ShiftGroupChatActionRow_gig$key,
  Props
>(
  graphql`
    fragment ShiftGroupChatActionRow_gig on Gig {
      id
      organization {
        name
        logoUrl
      }
      currentState {
        name
      }
      conversationRule
      conversation {
        id
        messages(last: 1) {
          totalCount
        }
        participant {
          messageStats {
            unreadCount
          }
        }
      }
      primaryContact {
        displayName
      }
    }
  `,
  ShiftGroupChatActionRow
);
