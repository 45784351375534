import { LoadingView } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { defaultResetHome } from "@gigsmart/kaizoku";
import { useIsFocused } from "@react-navigation/native";
import React, { useEffect } from "react";
import { useOnboardingCheck } from "../app/onboarding-check";
import { HIDE_NEW_TAG_FOR_JOB_BOARD, useUserValue } from "../user-value";

export function OnboardingFallback() {
  const isFocused = useIsFocused();
  const user = useCurrentUser();

  const [hideNewTagForJobBoard, setHideNewTagForJobBoard] = useUserValue(
    HIDE_NEW_TAG_FOR_JOB_BOARD
  );

  const { hasFinishedOnboarding } = useOnboardingCheck();
  useEffect(() => {
    if (!isFocused) return;

    if (user?.accessState === "REQUIRES_BASIC_INFO") {
      if (!hideNewTagForJobBoard) void setHideNewTagForJobBoard(true);
    }

    const timeoutId = !hasFinishedOnboarding
      ? setTimeout(() => defaultResetHome({ name: "Onboarding" }), 200)
      : null;

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [hasFinishedOnboarding, isFocused]);

  return <LoadingView />;
}
