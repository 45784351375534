import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { ThreeColumnRow } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import moment from "moment-timezone";
import React from "react";
import { Dimensions } from "react-native";
import type { engagementRowWorker_engagement$key } from "./__generated__/engagementRowWorker_engagement.graphql";

interface Props {
  engagement: engagementRowWorker_engagement$key | null | undefined;
  first?: boolean;
}

const fragmentSpec = graphql`
  fragment engagementRowWorker_engagement on Engagement {
    id
    endsAt
    gig {
      name
    }
    paymentInfo {
      netPay
    }
  }
`;

const { width } = Dimensions.get("window");
const dateText = width < 375 ? "Date:" : "Earnings Date:";

const EngagementRow = ({ engagement: engagementFragmentRef, first }: Props) => {
  const history = useHistory();
  const engagement = useRelayFragment(fragmentSpec, engagementFragmentRef);
  if (!engagement) return null;
  return (
    <ThreeColumnRow
      column1Title={engagement.gig?.name ?? ""}
      column1Subtitle={`${dateText} ${moment(
        engagement.endsAt ?? undefined
      ).format("MM/DD/YY")}`}
      onLinkPress={() => history.push(`/gigs/${engagement.id}`)}
      column2Text="View Shift"
      column3Text={
        engagement.paymentInfo?.netPay
          ? currency.humanize(engagement?.paymentInfo?.netPay)
          : "-"
      }
      first={first}
    />
  );
};

export default EngagementRow;
