import { Button } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { BidStateName } from "../engagement/EngagementExtras";
import { showEngagementBidHistoryModal } from "../engagement/bids/EngagementBidHistoryModal";
import { HourlyRateSummary } from "./HourlyRateSummary";
import type { ShiftDescriptionHeader_availableGig$key } from "./__generated__/ShiftDescriptionHeader_availableGig.graphql";

type Props = {
  variant?: BidStateName;
  label?: string;
};

export const ShiftDescriptionHeader = ({
  id: engagementId,
  variant,
  node,
  currentState,
  estimatedPayment,
  gig,
  label
}: FragmentContainerInnerComponentProps<
  ShiftDescriptionHeader_availableGig$key,
  Props
>) => {
  const offeredPayRate =
    (currentState?.name === "OFFERED" || currentState?.name === "BID_REVIEW") &&
    (currentState.negotiation?.payRate ?? estimatedPayment?.payRate);

  const payRate = offeredPayRate || node?.payRate || gig?.payRate;
  const estimatedPay = estimatedPayment?.netPay;
  const showBidHistoryBtn =
    currentState?.name &&
    currentState.name !== "OFFERED" &&
    (currentState.action === "COUNTER_OFFER" ||
      currentState.action === "MODIFY_OFFER")
      ? currentState.name !== "BID_REQUESTED"
      : true;

  return (
    <HourlyRateSummary
      variant={variant}
      labelOverride={label}
      currentState={currentState}
      estimatedPayment={estimatedPay}
      payRate={payRate}
    >
      {!!engagementId && showBidHistoryBtn && (
        <Button
          alignSelf="center"
          testID="view-bid-history-btn"
          label="View Bid History"
          icon="circle-dollar-to-slot"
          variant="clear"
          size="small"
          onPress={() => showEngagementBidHistoryModal({ engagementId })}
        />
      )}
    </HourlyRateSummary>
  );
};

export default createRelayFragmentContainer<
  ShiftDescriptionHeader_availableGig$key,
  Props
>(
  graphql`
    fragment ShiftDescriptionHeader_availableGig on AvailableGigOrEngagement {
      ... on AvailableGigsEdge {
        estimatedPayment {
          netPay
        }
        node {
          payRate
        }
      }
      ... on Engagement {
        id
        currentState {
          name
          action
          negotiation {
            payRate
          }
        }
        estimatedPayment {
          netPay
          payRate
        }
        gig {
          payRate
        }
      }
    }
  `,
  ShiftDescriptionHeader
);
