import { CheckboxRow } from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import React, { type ComponentProps } from "react";

type Props = FormFieldOptions<boolean> &
  Omit<ComponentProps<typeof CheckboxRow>, "errors" | "selected">;

// type Props = CheckboxRowProps & FormFieldOptions<any>;

export default function FomuCheckbox({
  name,
  validates,
  onChange,
  ...props
}: Props) {
  const { value, setValue, errors } = useFormField<boolean>({
    name,
    validates
  });

  return (
    <CheckboxRow
      selected={!!value}
      onChange={(value) => {
        setValue(value);
        onChange?.(value);
      }}
      errors={errors ?? []}
      testID={`${name}-checkbox-row`}
      {...props}
    />
  );
}
