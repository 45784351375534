import React from "react";

import { EmptyContentSurface, Text } from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";

interface Props {
  searchTerm?: string;
}

const ShiftGigsEmptyView = ({ searchTerm }: Props) => {
  const shiftStr = PositionsAndLocations.isEnabled() ? "Shifts" : "Shift Gigs";
  return (
    <EmptyContentSurface
      iconName="business-time"
      message={
        searchTerm ? (
          <Text>
            No {shiftStr} Found for <Text weight="bold">"{searchTerm}"</Text>
          </Text>
        ) : (
          `Unfortunately, there are currently no open ${shiftStr} in your area.`
        )
      }
    />
  );
};

export default ShiftGigsEmptyView;
