import {
  BrandButton,
  Constraint,
  ContentArea,
  Divider,
  Persistence,
  Platform,
  Pressable,
  Row,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import type { AuthParamList } from "@gigsmart/isomorphic-shared/auth/types";
import {
  type AppNavigationProp,
  defaultResetHome,
  useNavigation
} from "@gigsmart/kaizoku";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import React, { useCallback, useEffect, useState } from "react";
import LegalDocumentLink from "../../user-consent/LegalDocumentLink";
import AppleLoginButon from "./buttons/AppleLoginButton";
import GoogleLoginButon from "./buttons/GoogleLoginButton";
import useLogin from "./hooks/useLogin";

interface Props {
  signup?: boolean;
  isEmail?: boolean;
}

export default function LoginForm({ signup, isEmail = false }: Props) {
  const { appId } = useAppSettings();
  const navigation = useNavigation<AppNavigationProp<AuthParamList>>();
  const appName = appId === "requester" ? "Get Workers" : "Get Gigs";
  const styles = useStyles(
    ({ getUnits }) => ({
      signInWithEmailText: { marginHorizontal: getUnits(2) },
      forgotPassword: {
        marginLeft: "auto"
      }
    }),
    []
  );
  const [expandEmailSignin, toggleExpandEmailSignin] = useState(isEmail);
  const [email, setEmail] = useState("");
  const [revealPassword, toggleRevealPassword] = useState(false);
  const [showRevealPasswordControls, toggleShowRevealPasswordControls] =
    useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const { processLogin, handleError, mutationErrors } = useLogin(
    appName === "Get Gigs" ? "WORKER" : "REQUESTER"
  );

  useEffect(() => {
    void Persistence.load<string>("email").then((e) => {
      setEmail(e ?? "");
      setShouldRender(true);
    });
  }, []);

  const handleSubmit: FomuSubmitFn = useCallback(
    async ({ values: { emailAddress, password, oneTimePassword } }) => {
      try {
        await processLogin({
          input: {
            identifier: String(emailAddress),
            credential: String(password),
            authenticationProvider: "EMAIL",
            oneTimePassword
          },
          onLogin: () => {
            void Persistence.save("email", emailAddress);
            Persistence.keep("email");
            defaultResetHome();
          }
        });
      } catch (err: any) {
        handleError(err, "Something went wrong");
      }
    },
    [handleError, processLogin]
  );

  const handleForgotPasswordPress = () => navigation.push("ForgotPassword");
  const handleSignUpPress = () => navigation.push("Signup");
  const handleSignInPress = () => navigation.push("Login");
  const handlePressEmailButton = () => {
    if (signup) navigation.push("Onboarding");
    else toggleExpandEmailSignin(!expandEmailSignin);
  };

  const title = signup ? "Create Your Account" : `Sign in to ${appName}`;

  if (!shouldRender) return null;

  return (
    <Form onSubmit={handleSubmit} initialValues={{ emailAddress: email }}>
      <ContentArea>
        <Stack>
          <Constraint size="xsmall">
            <Stack>
              <Text variant="titleLg" color="primary">
                {title}
              </Text>
              <AppleLoginButon {...{ appName, signup }} />
              <GoogleLoginButon {...{ appName, signup }} />
              {!expandEmailSignin && !isEmail && (
                <BrandButton
                  label={`Sign ${signup ? "up" : "in"} with Email`}
                  brand="email"
                  onPress={handlePressEmailButton}
                  appleFont={Platform.OS === "ios"}
                />
              )}
            </Stack>
          </Constraint>
          {!signup && (
            <Constraint size="xsmall">
              {(expandEmailSignin || isEmail) && (
                <Stack>
                  <Row justifyContent="space-between" alignItems="center">
                    <Stack fill>
                      <Divider horizontal />
                    </Stack>
                    <Text style={styles.signInWithEmailText} color="neutral">
                      Sign in with Email
                    </Text>
                    <Stack fill>
                      <Divider horizontal />
                    </Stack>
                  </Row>
                  {mutationErrors && (
                    <Well color="danger" size="compact">
                      <Text color="danger" align="center">
                        {mutationErrors
                          .map(({ message }) => message ?? "")
                          .join(", ")}
                      </Text>
                    </Well>
                  )}
                  <FomuTextInput
                    name="emailAddress"
                    validates={Validator.email()}
                    label="Email"
                    autoCorrect={false}
                    autoCapitalize="none"
                    keyboardType="email-address"
                    testID="email-address-input"
                  />
                  <FomuTextInput
                    name="password"
                    validates={[
                      Validator.presence(),
                      Validator.length({
                        min: 8,
                        message: "must have 8 or more characters"
                      })
                    ]}
                    label="Password"
                    autoCapitalize="none"
                    rightAccessory={
                      <>
                        {showRevealPasswordControls && (
                          <Pressable
                            testID="password-visibility-toggle"
                            eventTargetName="Password Visibility Toggle Button"
                            onPress={() => {
                              toggleRevealPassword(!revealPassword);
                            }}
                            eventEntityType="password-visibility-toggle"
                          >
                            <Text color="primary">
                              {revealPassword ? " HIDE " : " SHOW "}
                            </Text>
                          </Pressable>
                        )}
                      </>
                    }
                    testID="password-input"
                    secureTextEntry={!revealPassword}
                    onKeyPress={() => {
                      if (!showRevealPasswordControls)
                        toggleShowRevealPasswordControls(true);
                    }}
                  />
                  <Pressable
                    testID="forgot-password-button"
                    eventTargetName="Forgot Password Link Button"
                    onPress={handleForgotPasswordPress}
                    eventEntityType="forgot-password-link"
                    style={styles.forgotPassword}
                  >
                    <Text color="link">Forgot Password?</Text>
                  </Pressable>
                  <FomuSubmit
                    testID="sign-in-button"
                    color="primary"
                    label="Sign in"
                  />
                </Stack>
              )}
            </Constraint>
          )}
          {signup && (
            <Text color="neutral" variant="note" align="center">
              By signing up, I agree to{" "}
              <LegalDocumentLink
                filename="gigsmart/tos"
                defaultTitle="Terms of Service"
              />
              {" & "}
              <LegalDocumentLink
                filename="gigsmart/privacy"
                defaultTitle="Privacy Policy"
              />
              .
            </Text>
          )}
          {signup ? (
            <Pressable
              testID="sign-in-link"
              eventTargetName="Sign In Link Button"
              onPress={handleSignInPress}
              eventEntityType="signup-link"
            >
              <Text color="primary" align="center">
                Already have an account? <Text weight="bold">Sign in</Text>
              </Text>
            </Pressable>
          ) : (
            <Pressable
              onPress={handleSignUpPress}
              eventTargetName="Sign Up Link Button"
              eventEntityType="signup-link"
              testID="sign-up-link"
            >
              <Text color="primary" align="center">
                Don't have an account? <Text weight="bold">Sign up</Text>
              </Text>
            </Pressable>
          )}
        </Stack>
      </ContentArea>
    </Form>
  );
}
