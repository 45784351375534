/**
 * @generated SignedSource<<89e41bbe22be9862913fded3ee460f12>>
 * @relayHash 17c0beaea7d4a78b9dbac5ca1d126d56
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:X2fDS1snzKxZrebWjCvK-QZ0O8XOK3nGCJ290ZBRmSI

import { ConcreteRequest } from 'relay-runtime';
export type useUpdateWorkerQualificationQuery$variables = Record<PropertyKey, never>;
export type useUpdateWorkerQualificationQuery$data = {
  readonly viewer: {
    readonly qualificationProofs?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type useUpdateWorkerQualificationQuery = {
  response: useUpdateWorkerQualificationQuery$data;
  variables: useUpdateWorkerQualificationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "query",
  "value": "WHERE type = DRIVERS_LICENSE"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "WorkerQualificationDriversLicenseProof",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateWorkerQualificationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "qualificationProofs",
                "args": [
                  (v0/*: any*/)
                ],
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "__useUpdateWorkerQualification___qualificationProofs_connection",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "__useUpdateWorkerQualification___qualificationProofs_connection(query:\"WHERE type = DRIVERS_LICENSE\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useUpdateWorkerQualificationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "qualificationProofs",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "qualificationProofs(first:0,query:\"WHERE type = DRIVERS_LICENSE\")"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "query"
                ],
                "handle": "connection",
                "key": "useUpdateWorkerQualification___qualificationProofs",
                "kind": "LinkedHandle",
                "name": "qualificationProofs"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:X2fDS1snzKxZrebWjCvK-QZ0O8XOK3nGCJ290ZBRmSI",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "viewer",
            "qualificationProofs"
          ]
        }
      ]
    },
    "name": "useUpdateWorkerQualificationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4f6145c3c71f40d41dceec4403ea6fe4";

export default node;
