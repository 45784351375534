import { type IconName, Stack, Text, useStepper } from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit } from "@gigsmart/fomu";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import React, { useMemo } from "react";
import FomuMultiSelect from "../../fomu/inputs/FomuMultiSelect";
import type { PositionsStepMutation } from "./__generated__/PositionsStepMutation.graphql";
import type { PositionsStepQuery } from "./__generated__/PositionsStepQuery.graphql";
import useGigPositions from "./hooks/useGigPositions";

interface Props {
  gigId: string;
  handleUnmetRequirements: () => void;
}

export default createSuspendedQueryContainer<PositionsStepQuery, Props>(
  function PositionsStep({ response, retry, gigId, handleUnmetRequirements }) {
    const { nextStep } = useStepper<Step, {}>();
    const { requiredPosition, workerId, workerPositionStatus } =
      useGigPositions(response?.node);

    const categoryName = requiredPosition?.category?.displayName;
    const icon = requiredPosition?.category?.categoryIcon?.data as IconName;

    const [updatePositionsMutations] =
      useRelayMutationPromise<PositionsStepMutation>(graphql`
        mutation PositionsStepMutation($input: UpdateWorkerGigPositionInput!) {
          updateWorkerGigPosition(input: $input) {
            workerGigPosition {
              id
              status
            }
          }
        }
      `);

    const saveWorkerPosition = async (id?: string, value?: boolean | null) => {
      await updatePositionsMutations(
        {
          input: {
            status: value ? "CONFIRMED" : "DENIED",
            gigPositionId: id ?? "",
            workerId: workerId ?? ""
          }
        },
        {
          updater: (store) => {
            const node = store.get(gigId);
            if (node != null) {
              node.invalidateRecord();
            }
          }
        }
      );
    };

    const positionId = requiredPosition?.position?.id ?? "position";
    const initialValues = useMemo(
      () => ({
        [positionId]: workerPositionStatus === "CONFIRMED" ? true : undefined
      }),
      []
    );

    const handleSubmit: FomuSubmitFn = async ({ values }) => {
      const value = values[positionId];
      await saveWorkerPosition(positionId, value);
      if (value) {
        retry();
        nextStep();
      } else {
        handleUnmetRequirements();
      }
    };

    return (
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <Stack>
          <Text>
            This Shift Gig is for a Position you have not yet saved to your
            Profile. To apply, add it to your Profile by selecting the Position
            below.
          </Text>
          <Text weight="bold">
            Are you able to work the following Position?
          </Text>
          <Stack size="compact">
            <FomuMultiSelect
              testID="positions-step"
              header={categoryName}
              headerIcon={icon}
              headerOptions={["NO", "YES"]}
              options={[
                {
                  id: requiredPosition?.position?.id ?? "",
                  label: requiredPosition?.position?.name ?? ""
                }
              ]}
            />
            <Text align="center" color="neutral">
              This Position will be automatically saved to your Profile. Only
              add Positions that you can do.
            </Text>
          </Stack>
        </Stack>
        <FormSubmit>
          {({ dirty, invalid, submit, submitting }) => (
            <ResponsiveStepperBtnPortal.Entrance
              testID="submit-position"
              label="Continue"
              onPress={submit}
              disabled={!dirty || invalid || submitting}
            />
          )}
        </FormSubmit>
      </Form>
    );
  },
  {
    query: graphql`
      query PositionsStepQuery($id: ID!) {
        node(id: $id) {
          ...useGigPositions_gigLike
        }
      }
    `,
    variables: ({ gigId }) => ({ id: gigId })
  }
);
