/**
 * @generated SignedSource<<962d40bc91b771f2e476670da29e4460>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMessageContext_userMessage$data = {
  readonly author: {
    readonly __typename: string;
    readonly id: string;
  };
  readonly context: {
    readonly __typename: "Gig";
    readonly actualStartsAt: string | null | undefined;
    readonly name: string | null | undefined;
    readonly startsAt: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentType": "UserMessageContext_userMessage";
};
export type UserMessageContext_userMessage$key = {
  readonly " $data"?: UserMessageContext_userMessage$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMessageContext_userMessage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMessageContext_userMessage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "context",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startsAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualStartsAt",
              "storageKey": null
            }
          ],
          "type": "Gig",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserMessage",
  "abstractKey": null
};
})();

(node as any).hash = "76f87ef72ce55a0f192ea6d40c636807";

export default node;
