import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import {
  getAddressContent,
  getDistanceContent,
  getPaymentContent
} from "../../available-gig/cards/content";
import EngagementCardContent from "../../gig/engagement-details/engagement-card-content";
import type { seriesEngagementContent_engagement$key } from "./__generated__/seriesEngagementContent_engagement.graphql";

interface Props {
  hasMultipleLocations?: boolean;
}

const SeriesEngagementContent = ({
  gig,
  skill,
  workerDistance,
  estimatedPayment,
  hasMultipleLocations = false,
  gigType
}: FragmentContainerInnerComponentProps<
  seriesEngagementContent_engagement$key,
  Props
>) => {
  const paymentNode = getPaymentContent({
    payRate: estimatedPayment?.payRate,
    netPay: estimatedPayment?.netPay
  });
  const distanceNode = getDistanceContent(workerDistance);
  const addressNode = getAddressContent({
    hasMultipleLocations,
    area: gig?.place?.streetAddress ?? gig?.area
  });

  return (
    <EngagementCardContent
      spacing={0}
      skillCount={gig.position?.name ? 0 : gig?.skills?.totalCount ?? 1}
      skillOrCategoryName={gig.position?.name ?? skill?.name}
      address={addressNode}
      addressInfo={distanceNode}
      paymentType={gig?.paymentType}
      thumbnail={paymentNode}
      gigType={gigType}
    />
  );
};

export default createRelayFragmentContainer<
  seriesEngagementContent_engagement$key,
  Props
>(
  graphql`
    fragment seriesEngagementContent_engagement on Engagement {
      workerDistance
      gigType
      gig {
        area
        place {
          streetAddress
        }
        paymentType
        skills(first: 0) {
          totalCount
        }
        position {
          name
        }
      }
      skill {
        name
      }
      estimatedPayment {
        payRate
        netPay
      }
    }
  `,
  SeriesEngagementContent
);
