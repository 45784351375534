import { Stack } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import VerifyMileage from "@gigsmart/seibutsu/engagement/VerifyMileage";
import { useHasEngagementCapability } from "@gigsmart/seibutsu/engagement/WithEngagementCapability";
import WorkerTimesheetInfoCard from "@gigsmart/seibutsu/engagement/WorkerTimesheetInfoCard";
import WorkerPaymentSurface from "@gigsmart/seibutsu/shifts/accessory/WorkerPaymentSurface";
import React from "react";
import type { pendingApprovalTab_engagement$key } from "./__generated__/pendingApprovalTab_engagement.graphql";

interface Props {
  engagement: pendingApprovalTab_engagement$key;
}

const GigPendingApprovalTab = ({
  engagement: engagementFragmentRef
}: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment pendingApprovalTab_engagement on Engagement {
        ...WorkerTimesheetInfoCard_engagement
        ...VerifyMileage_engagement
        ...WorkerPaymentSurface_shift
        id
        gig {
          estimatedMileage
        }
        gigType
        currentState {
          name
        }
        duration: timesheet {
          totalDurationWorked
        }
        capabilities {
          type
          restrictedBy {
            message
          }
          status
          expiresAt
        }
      }
    `,
    engagementFragmentRef
  );

  const canApproveSystemTimesheet = useHasEngagementCapability(
    engagement?.capabilities ?? [],
    "APPROVE_SYSTEM_TIMESHEET"
  );

  if (!engagement) return null;
  return (
    <Stack>
      <VerifyMileage
        gigEstimatedMileage={engagement?.gig?.estimatedMileage}
        gigDuration={engagement?.duration?.totalDurationWorked}
        engagementId={engagement?.id}
        fragmentRef={engagement}
      />
      {canApproveSystemTimesheet && (
        <WorkerTimesheetInfoCard fragmentRef={engagement} />
      )}
      <WorkerPaymentSurface fragmentRef={engagement} />
    </Stack>
  );
};

export default GigPendingApprovalTab;
