import { useStyle } from "@gigsmart/atorasu/style";
import React from "react";
import Lottie from "react-lottie-player";

export default function Loader() {
  const style = useStyle(() => ({
    opacity: 0.75,
    width: 50,
    height: 50,
    marginBottom: 4
  }));
  return (
    <Lottie
      style={style}
      animationData={require("./loader.lottie.json")}
      play
      loop
    />
  );
}
