import { isEqual } from "lodash";
import React, { type ReactNode, Component } from "react";
import { TouchableOpacity, View } from "react-native";

import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { StyledText } from "../text";

type Props = StylesProps & {
  children?: ReactNode;
  disabled?: boolean;
  error?: Error;
  label: string;
  onTogglePress: () => unknown;
  style?: ViewStyleProp;
  testID?: string;
  toggle?: boolean;
  value?: number;
};
@withStyles(({ color }) => ({
  container: {
    padding: 20
  },
  content: {
    flexDirection: "row",
    justifyContent: "center"
  },
  label: {
    flex: 1
  },
  toggle: {
    borderColor: color.orange,
    borderWidth: 2,
    borderRadius: 10,
    height: 20,
    width: 20
  },
  toggleActive: {
    backgroundColor: color.orange
  },
  toggleButton: {
    alignItems: "center",
    justifyContent: "flex-start",
    width: 50
  },
  valueContainer: {
    alignItems: "center",
    justifyContent: "flex-start"
  }
}))
export default class StyledRadioToggle extends Component<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      // Shallow Checks
      nextProps.label !== this.props.label ||
      nextProps.onTogglePress !== this.props.onTogglePress ||
      nextProps.toggle !== this.props.toggle || // Deep Checks
      !isEqual(nextProps.children, this.props.children) ||
      !isEqual(nextProps.error, this.props.error) ||
      !isEqual(nextProps.style, this.props.style)
    );
  }

  render() {
    const {
      children,
      disabled,
      label,
      onTogglePress,
      styles,
      theme,
      style,
      value,
      testID,
      toggle
    } = this.props;
    const toggleStyle = toggle ? styles.toggleActive : undefined;
    return (
      <View style={[styles.container, style]}>
        <View style={styles.content}>
          <StyledText
            numberOfLines={4}
            bold
            color="blue"
            fontSize={theme.font.size.large}
            style={styles.label}
          >
            {label}
          </StyledText>
          {(value || value === 0) && (
            <View style={styles.valueContainer}>
              <StyledText color="blue" fontSize={theme.font.size.large}>
                ${value}
              </StyledText>
            </View>
          )}
          <TouchableOpacity
            style={styles.toggleButton}
            disabled={disabled}
            onPress={onTogglePress}
            testID={testID ?? "toggle-button"}
            accessible={false}
          >
            <View style={[styles.toggle, toggleStyle]} />
          </TouchableOpacity>
        </View>
        {toggle && children}
      </View>
    );
  }
}
