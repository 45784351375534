import { Icon, Pressable, SectionHeader } from "@gigsmart/atorasu";
import React, { type ComponentProps } from "react";

interface Props extends Omit<ComponentProps<typeof SectionHeader>, "right"> {
  collapsable?: boolean;
  collapsed?: boolean;
  onPress?: () => void;
}

export default function CollapsableSectionHeader({
  collapsable = true,
  collapsed = true,
  onPress,
  ...props
}: Props) {
  return (
    <Pressable
      testID="collapsible-section-header"
      eventTargetName="Collapsible Section Header"
      eventEntityType="CollapsableSectionHeader"
      onPress={collapsable ? onPress : undefined}
      disabled={!collapsable}
    >
      <SectionHeader
        {...props}
        right={
          collapsable && (
            <Icon
              name={collapsed ? "chevron-down" : "chevron-up"}
              size="small"
              color="primary"
              variant="solid"
            />
          )
        }
      />
    </Pressable>
  );
}
