import { Icon, useStyles } from "@gigsmart/atorasu";
import {
  type FormFieldOptions,
  Validator,
  useFormFields
} from "@gigsmart/fomu";
import {
  type GeocodeLayer,
  type Location,
  type PlaceProvider,
  useLocationAutocomplete
} from "@gigsmart/isomorphic-shared/geolocation";
import PredictiveInput, {
  type PredictiveInputProps,
  type PredictionResult
} from "@gigsmart/katana/input/predictive-input";
import React, { useState, type ReactNode } from "react";

interface CommonProps
  extends Omit<FormFieldOptions, "name" | "sensitive" | "shouldShowErrors"> {
  label?: string;
  initialValue?: string | null;
  layer?: GeocodeLayer;
  provider?: PlaceProvider;
  leftAccessory?: ReactNode;
  clearable?: boolean;
  onSelect?: (res?: PredictionResult<Location> | null) => void;
  fieldPrefix?: string;
  placeholder?: string;
}

type Props = Pick<
  PredictiveInputProps<Location>,
  "value" | "error" | "onBlur" | "onChangeText"
> &
  CommonProps;

export function AddressInput({
  label,
  layer = "PLACE",
  provider,
  leftAccessory,
  placeholder,
  ...props
}: Props) {
  const styles = useStyles(({ getColor, getUnits, getFontSize }) => ({
    textInputStyle: {
      color: getColor("black", "fill"),
      paddingRight: getUnits(4),
      ...getFontSize(4),
      flex: 1
    },
    textInputContainerStyle: {
      borderBottomColor: getColor("background", "fill"),
      zIndex: 300
    },
    predictionStyle: {
      borderColor: getColor("background", "fill")
    }
  }));

  const predictFn = useLocationAutocomplete(layer, provider);

  return (
    <PredictiveInput
      eventTargetName="Location Autocomplete Input"
      testID="location-input"
      label={label}
      iconName="search"
      iconColor="blue"
      placeholder={placeholder ?? "Enter Address"}
      inputContainerStyle={styles.textInputContainerStyle}
      inputStyle={styles.textInputStyle}
      predictionStyle={styles.predictionStyle}
      renderLeftAccessory={() =>
        leftAccessory ?? (
          <Icon
            name="location-dot"
            color="primary"
            size="small"
            variant="solid"
          />
        )
      }
      renderRightAccessory={() => null}
      predictFn={predictFn}
      {...props}
    />
  );
}

export function UncontrolledAddressInput({ onSelect, ...props }: CommonProps) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <AddressInput
      {...props}
      value={searchTerm}
      onSelect={(addr) => {
        if (addr) setSearchTerm(addr.label);
        onSelect?.(addr);
      }}
      onChangeText={setSearchTerm}
    />
  );
}

export function FomuAddressInput({
  onSelect,
  fieldPrefix,
  validates,
  ...props
}: CommonProps) {
  const computedFieldNames = {
    searchTerm: computeFieldName("searchTerm", fieldPrefix),
    placeLocation: computeFieldName("placeLocation", fieldPrefix),
    placeId: computeFieldName("placeId", fieldPrefix),
    address: computeFieldName("address", fieldPrefix)
  };

  const values = useFormFields({
    [computedFieldNames.searchTerm]: null,
    [computedFieldNames.placeLocation]: validates ?? null,
    [computedFieldNames.placeId]: Validator.presence({
      message: "You must select a location from the dropdown."
    }),
    [computedFieldNames.address]: null
  });

  return (
    <AddressInput
      {...props}
      value={
        values[computedFieldNames.searchTerm]?.value ??
        values[computedFieldNames.address]?.value
      }
      onSelect={(addr) => {
        if (addr) {
          values[computedFieldNames.placeId]?.setValue(
            addr.data?.placeId ?? null
          );
          values[computedFieldNames.searchTerm]?.setValue(addr.label);
          values[computedFieldNames.address]?.setValue(addr.label);
          values[computedFieldNames.placeLocation]?.setValue(
            addr.data?.place.location
          );
        }
        onSelect?.(addr);
      }}
      error={
        values[computedFieldNames.placeLocation]?.errorMessage ??
        values[computedFieldNames.placeId]?.errorMessage ??
        values[computedFieldNames.searchTerm]?.errorMessage
      }
      onBlur={
        values[computedFieldNames.placeLocation]?.showErrors ??
        values[computedFieldNames.placeId]?.showErrors
      }
      onChangeText={(term) => {
        values[computedFieldNames.searchTerm]?.setValue(term);
        if (values[computedFieldNames.placeId]?.value)
          values[computedFieldNames.placeId]?.setValue(null);
      }}
    />
  );
}

function computeFieldName(field: string, prefix?: string | null) {
  return prefix ? `${prefix}-${field}` : field;
}
