import {
  Button,
  ContentArea,
  List,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { useState } from "react";

import RequesterThumbnailRow from "../../requester/requester-thumbnail-row";
import ActiveEngagementActions from "../engagement-top-card/active-engagement-actions";
import NextStepsProjectModal from "./next-steps-project-modal";

import type { projectAppliedHeader_engagement$key } from "./__generated__/projectAppliedHeader_engagement.graphql";

interface Props {
  engagementRef?: projectAppliedHeader_engagement$key | null | undefined;
}

const ProjectAppliedHeader = ({ engagementRef }: Props) => {
  const engagement = useRelayFragment<projectAppliedHeader_engagement$key>(
    graphql`
      fragment projectAppliedHeader_engagement on Engagement {
        gig {
          ...requesterThumbnailRow_gig
        }
        ...activeEngagementActions_engagement
      }
    `,
    engagementRef ?? null
  );

  const [showModal, setShowModal] = useState(false);
  if (!engagement) return null;
  return (
    <Stack>
      <Surface>
        <RequesterThumbnailRow
          fragmentRef={engagement?.gig}
          right={
            <ActiveEngagementActions
              showProfileAction
              showPhone={false}
              engagementRef={engagement}
            />
          }
        />
      </Surface>
      <Surface>
        <ContentArea>
          <Stack>
            <Text color="primary" weight="bold" testID="applied-card-title">
              Congrats, you have applied to this Project Gig! 🎉
            </Text>
            <Text>
              Hang Tight! The Requester will review your bid and application. If
              they are interested or have questions, they will contact you
              directly.
            </Text>
            <List variant="bullet" size="compact">
              <Text>
                Full Project Gig address will be displayed upon hiring
              </Text>
              <Text>
                If no longer available or interested in this Project Gig, you
                can cancel your application from the 'More' menu
              </Text>
            </List>
            <Button
              size="small"
              label="Next Steps for Project Gigs"
              testID="project-next-steps-btn"
              onPress={() => setShowModal(true)}
              outline
            />
          </Stack>
        </ContentArea>
      </Surface>
      <NextStepsProjectModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
      />
    </Stack>
  );
};

export default ProjectAppliedHeader;
