import React, { type ComponentProps, Component } from "react";
import { Keyboard, TouchableOpacity } from "react-native";
import StyledTextInput from "../input/styled-text-input/styled-text-input";
import { StyledText } from "../text";

type Props = ComponentProps<typeof StyledTextInput> & {
  label?: string;
  value: string;
  reveal?: boolean;
  onReveal?: (arg0: boolean) => any;
};
interface State {
  reveal: boolean;
  showAccessory: boolean;
}

export default class StyledPasswordInput extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, nextState: State) {
    return Object.prototype.hasOwnProperty.call(props, "reveal")
      ? { ...nextState, reveal: props.reveal }
      : nextState;
  }

  static defaultProps = {
    secureTextEntry: true
  };

  state: State = { reveal: false, showAccessory: false };

  accessoryHide = () => {
    const { testID } = this.props;
    return (
      this.state.showAccessory && (
        <TouchableOpacity
          onPress={this.handleReveal}
          testID={typeof testID === "string" ? `${testID}-hide` : undefined}
        >
          <StyledText color="blue">HIDE</StyledText>
        </TouchableOpacity>
      )
    );
  };

  accessoryShow = () => {
    const { testID } = this.props;

    return (
      this.state.showAccessory && (
        <TouchableOpacity
          onPress={this.handleReveal}
          testID={typeof testID === "string" ? `${testID}-show` : undefined}
        >
          <StyledText color="blue">SHOW</StyledText>
        </TouchableOpacity>
      )
    );
  };

  handleReveal = () => {
    if (this.props.onReveal) {
      this.props.onReveal(!this.state.reveal);
      Keyboard.dismiss();
    } else {
      this.setState(
        (prevState: any) => ({ reveal: !prevState.reveal }),
        Keyboard.dismiss
      );
    }
  };

  handleKeyPress = () => {
    if (!this.state.showAccessory) this.setState({ showAccessory: true });
  };

  render() {
    const { reveal = this.state.reveal, onReveal, ...props } = this.props;
    return (
      <StyledTextInput
        {...props}
        keyboardType="default"
        clearTextOnFocus={false}
        secureTextEntry={!reveal}
        autoCorrect={false}
        autoCapitalize="none"
        onKeyPress={this.handleKeyPress}
        renderRightAccessory={reveal ? this.accessoryHide : this.accessoryShow}
      />
    );
  }
}
