import {
  Column,
  Icon,
  Row,
  Stack,
  Text,
  useWindowDimensions
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import {
  type AppNavigationProp,
  useHistory,
  useNavigation
} from "@gigsmart/kaizoku";
import { SmallOutlineButton } from "@gigsmart/katana";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import {
  HIDE_NEW_TAG_FOR_JOB_BOARD,
  VIEWED_JOB_BOARD_INTRO,
  useUserValue
} from "../user-value";

interface Props {
  endOfList?: boolean;
}

const JobBoardNudge = ({ endOfList = false }: Props) => {
  const { height } = useWindowDimensions();
  const history = useHistory();
  const navigation = useNavigation<AppNavigationProp<WorkerParamList>>();
  const styles = useStyles(({ getUnits }) => ({
    container: {
      padding: getUnits(5),
      width: "75%"
    },
    containerNoGigs: {
      height: height / 2
    },
    headerText: {
      padding: getUnits(4)
    },
    buttonStyle: {
      paddingVertical: getUnits(2)
    },
    buttonContainer: {
      alignItems: "center",
      marginTop: getUnits(4),
      paddingHorizontal: getUnits(4)
    }
  }));
  const [, setHideNewTagForJobBoard] = useUserValue<boolean>(
    HIDE_NEW_TAG_FOR_JOB_BOARD
  );
  const [viewedJobBoardIntro] = useUserValue<boolean>(VIEWED_JOB_BOARD_INTRO);
  const handleJobBoardButtonPress = () => {
    setHideNewTagForJobBoard(true);
    if (viewedJobBoardIntro) navigation.push("BrowseJobs");
    else navigation.push("BrowseJobsIntro");
  };

  const handleProjectGigButtonPress = () => navigation.push("BrowseProjects");

  return (
    <Row justifyContent="center">
      <Column
        justifyContent="center"
        alignItems="center"
        style={[styles.container, !endOfList && styles.containerNoGigs]}
      >
        <Icon name="pen-ruler" size="default" color="primary" />
        <Text
          color="primary"
          variant={endOfList ? undefined : "bodyLg"}
          style={styles.headerText}
          align="center"
          weight={endOfList ? "bold" : "semibold"}
        >
          Interested in more opportunities?
        </Text>
        <Column style={styles.buttonContainer}>
          <Stack horizontal>
            <SmallOutlineButton
              title="View Job Board"
              onPress={handleJobBoardButtonPress}
            />
            <SmallOutlineButton
              title="View Project Gigs"
              onPress={handleProjectGigButtonPress}
            />
          </Stack>
        </Column>
      </Column>
    </Row>
  );
};

export default JobBoardNudge;
