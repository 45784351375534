import React, { useMemo } from "react";

import { DateTime } from "luxon";

import {
  GridCol,
  GridContainer,
  ScreenScroll,
  Spacer,
  StickyReminderFooter
} from "@gigsmart/atorasu";
import { asEnum, asEnums, gte, inList, lte, where } from "@gigsmart/biruda";
import {
  PickupShifts,
  RemoveBenefitsMenu,
  ShiftGroupChat,
  TimesheetDisputes
} from "@gigsmart/feature-flags";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import BenefitsPromptCtrl from "@gigsmart/seibutsu/benefits/BenefitsPromptCtrl";
import DirectHireClaimCard from "@gigsmart/seibutsu/direct-hire/worker/ProfileCard";
import PendingDisputesCard from "@gigsmart/seibutsu/engagement-dispute/worker/HomescreenPendingDisputesCard";
import ShiftGroupChatHomeTabCard from "@gigsmart/seibutsu/user-message/shift-group-chat/ShiftGroupChatHomeTabCard";
import { AvailableGigsCardWithQuery as AvailableGigsCard } from "@gigsmart/seibutsu/worker/AvailableGigsCard/AvailableGigsCard";
import BankingAdCard from "@gigsmart/seibutsu/worker/BankingAdCard";
import BenefitCenterCard from "@gigsmart/seibutsu/worker/BenefitCenterCard";
import CompleteProfileHomeCard from "@gigsmart/seibutsu/worker/CompleteProfileHomeCard";
import GigSmartBlogSection from "@gigsmart/seibutsu/worker/GigSmartBlogSection";
import HiredProjectsCard from "@gigsmart/seibutsu/worker/HiredProjectsCard";
import HomeVideoCard from "@gigsmart/seibutsu/worker/HomeVideoCard";
import OpportunitiesCard from "@gigsmart/seibutsu/worker/OpportunitiesCard";
import PendingTimesheetsReviewCard from "@gigsmart/seibutsu/worker/PendingTimesheetsReviewCard";
import UpcomingShiftReminder from "@gigsmart/seibutsu/worker/UpcomingShiftReminder";
import UpcomingShifts from "@gigsmart/seibutsu/worker/UpcomingShifts";
import VerificationCard from "@gigsmart/seibutsu/worker/VerificationCenterCard";
import { WalletBalanceCardWithQuery as WalletBalanceCard } from "@gigsmart/seibutsu/worker/WalletBalanceCard";
import WelcomeCard from "@gigsmart/seibutsu/worker/WelcomeCard";
import AvailablePickupShiftsCard from "@gigsmart/seibutsu/worker/pickup-shift/AvailablePickupShiftsCard";
import WorkerAnnouncements from "../announcement";
import { useGeolocation } from "../geolocation";
import type { homeScreenQuery } from "./__generated__/homeScreenQuery.graphql";

const timeSheetReviewQuery = where({
  currentStateName: asEnum("PENDING_TIMESHEET_APPROVAL"),
  workerCanApproveTimesheet: true
}).toString();

const hiredProjectsQuery = where({
  currentStateName: inList(asEnums(["SCHEDULED", "PENDING_REVIEW"])),
  gigType: asEnum("PROJECT")
})
  .orderBy("startsAt", "ASC NULLS LAST")
  .toString();

export default createSuspendedQueryContainer<homeScreenQuery>(
  function HomeScreen({ response: result, variables }) {
    const geolocation = useGeolocation(false);

    const upcomingShiftCount =
      result?.viewer?.upcomingShiftCount?.totalCount ?? 0;

    return (
      <>
        <BenefitsPromptCtrl />
        <ScreenScroll
          testID="home-screen"
          footer={!!upcomingShiftCount && <UpcomingShiftReminder />}
        >
          <GridContainer variant="compact" size="compact">
            <GridCol>
              <WorkerAnnouncements enableSpacing={false} />
              <PendingTimesheetsReviewCard
                fragmentRef={result?.viewer}
                query={timeSheetReviewQuery}
              />
              {ShiftGroupChat.isEnabled() && (
                <ShiftGroupChatHomeTabCard fragmentRef={result?.viewer} />
              )}
              {PickupShifts.isEnabled() && <AvailablePickupShiftsCard />}
              <UpcomingShifts query={variables?.upcomingShiftsQuery ?? ""} />
              {TimesheetDisputes.isEnabled() && <PendingDisputesCard />}
              <HiredProjectsCard
                fragmentRef={result?.viewer}
                query={hiredProjectsQuery}
              />
              <WelcomeCard />
              <HomeVideoCard />
              <OpportunitiesCard />
              <CompleteProfileHomeCard />
              <VerificationCard workerRef={result?.viewer} />
              <BankingAdCard screen="HOME" />
              <WalletBalanceCard />
              <AvailableGigsCard geolocation={geolocation} />
              <DirectHireClaimCard fragmentRef={result?.viewer} />
              {RemoveBenefitsMenu.isDisabled() && <BenefitCenterCard />}
              <GigSmartBlogSection />
              <Spacer />
              {!!upcomingShiftCount && <StickyReminderFooter />}
            </GridCol>
          </GridContainer>
        </ScreenScroll>
      </>
    );
  },
  {
    query: graphql`
      query homeScreenQuery(
        $timeSheetReviewQuery: String!
        $hiredProjectsQuery: String!
        $upcomingShiftsQuery: String!
      ) {
        viewer {
          ... on Worker {
            ...ProfileCard_Worker
            ...VerificationCenterCard_worker
            ...ShiftGroupChatHomeTabCard_shift
            ...PendingTimesheetsReviewCard_worker
              @arguments(query: $timeSheetReviewQuery)
            ...HiredProjectsCard_worker @arguments(query: $hiredProjectsQuery)
            upcomingShiftCount: engagements(
              first: 0
              query: $upcomingShiftsQuery
            ) {
              totalCount
            }
          }
        }
      }
    `,
    variables: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const upcomingShiftsQuery = useMemo(() => {
        const now = new Date();
        now.setSeconds(0, 0);
        return where({
          currentStateName: asEnum("SCHEDULED"),
          startsAt: gte(now),
          endsAt: lte(DateTime.fromJSDate(now).plus({ days: 7 }).toJSDate())
        })
          .orderBy("startsAt", "ASC NULLS FIRST")
          .toString();
      }, []);

      return {
        timeSheetReviewQuery,
        hiredProjectsQuery,
        upcomingShiftsQuery
      };
    }
  }
);
