import { EmptyContentSurface } from "@gigsmart/atorasu";
import React from "react";

const ProjectTopicEmptyView = ({ searchTerm }: { searchTerm?: string }) => {
  return (
    <EmptyContentSurface
      iconName="pen-ruler"
      message={
        searchTerm
          ? `No Project Gigs Found for "${searchTerm}".`
          : "Unfortunately, there are currently no open Project Gigs in your area."
      }
    />
  );
};

export default ProjectTopicEmptyView;
