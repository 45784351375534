import { toast } from "@gigsmart/atorasu";
import { captureError } from "@gigsmart/dekigoto";
import React, {
  type ComponentType,
  type DependencyList,
  useEffect,
  useMemo,
  useState
} from "react";

import { AppletsLocalDebug } from "@gigsmart/feature-flags";
import { ActivityIndicator } from "react-native";
import type { AppletContract } from "./createApplet";

type DependencyName = keyof typeof deps;

const deps = {
  react: require("react"),
  "react-native": require("react-native"),
  "@gigsmart/dekigoto": require("@gigsmart/dekigoto"),
  "@gigsmart/applets": require("@gigsmart/applets/client"),
  "@gigsmart/onfleet-sdk": require("@gigsmart/onfleet-sdk"),
  "@gigsmart/fomu": require("@gigsmart/fomu"),
  "@gigsmart/feature-flags": require("@gigsmart/feature-flags"),
  "@gigsmart/relay": require("@gigsmart/relay"),

  // utils
  buffer: require("buffer"),
  lodash: require("lodash"),
  luxon: require("luxon"),
  "moment-timezone": require("moment-timezone"),
  moment: require("moment"),
  "react-native-md5": require("react-native-md5"),
  "@react-native-async-storage/async-storage": require("@react-native-async-storage/async-storage"),
  // navigation
  "@react-navigation/native": require("@react-navigation/native"),
  "@react-navigation/native-stack": require("@react-navigation/native-stack")
};

function _requires(name: string) {
  if (!(name in deps)) {
    throw new Error(
      `Could not require '${name}'. '${name}' does not exist in dependencies.`
    );
  }
  return deps[name as DependencyName];
}

const etagStore: Record<string, string> = {};
const cache: Record<string, any> = {};

export async function loadRemoteModule<T>(url: string): Promise<T> {
  let targetUrl = url;
  if (AppletsLocalDebug.isEnabled()) {
    targetUrl = targetUrl.replace(
      /^https:\/\/onfleet\.gigsmart\.(ninja|soy|com)/,
      "http://localhost:4019"
    );

    targetUrl = process.env.LOCAL_IP
      ? targetUrl.replace("localhost", process.env.LOCAL_IP)
      : targetUrl;
  }

  const currentEtag = etagStore[targetUrl];
  const response = await fetch(targetUrl, {
    headers:
      currentEtag && cache[currentEtag]
        ? {
            "If-None-Match": currentEtag
          }
        : {}
  });

  const nextEtag = response.headers.get("etag");
  if (nextEtag && nextEtag !== currentEtag) {
    if (currentEtag && currentEtag in Object.keys(cache)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete cache[currentEtag];
    }
    etagStore[targetUrl] = nextEtag;
  }
  const cacheKey = nextEtag ?? targetUrl;
  return (cache[cacheKey] ??= await (async () => {
    if (!response.ok) {
      throw new Error(`Network response was not ok ${response.status}`);
    }

    const code = await response.text();
    const exports = {};
    const module: { exports: Record<string, unknown> } = { exports };
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
    const func = new Function("require", "module", "exports", code);
    func(_requires, module, exports);
    const mod = (module.exports.default ?? module.exports) as AppletContract;
    mod.cacheKey = cacheKey;
    return mod;
  })());
}

const NullComponent = () => <ActivityIndicator />;

export function useApplet<T>(
  url: string | null | undefined,
  wrapper: (
    applet: AppletContract<T>["__gigsmart_applet__"]
  ) => ComponentType<T>,
  deps: DependencyList
) {
  const [version, setVersion] = useState(0);
  const [result, setResult] = useState<AppletContract>({
    __gigsmart_applet__: {
      id: 0,
      preview: NullComponent,
      content: NullComponent
    }
  });

  useEffect(() => {
    if (!AppletsLocalDebug.isEnabled()) return;
    const int = setInterval(setVersion, 3000, (n) => n + 1);
    return () => clearInterval(int);
  }, []);

  useEffect(() => {
    if (!url) return;

    fetchApplet(url, false)
      .then((nextResult) => {
        if (result !== nextResult) setResult(nextResult);
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, wrapper, version, ...deps]);

  return useMemo(() => wrapper(result.__gigsmart_applet__), [result, wrapper]);
}

async function fetchApplet(
  url: string,
  alwaysReturn = true
): Promise<AppletContract> {
  try {
    return await loadRemoteModule(url);
  } catch (error) {
    captureError(error);
    toast.error("Unable to load applet!");

    if (!alwaysReturn) throw error;
    return {
      __gigsmart_applet__: { preview: () => null, id: 0, content: () => null }
    };
  }
}
