import { useEffect } from "react";

import type { NotificationEntry } from "@gigsmart/isomorphic-shared/notifications";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type RelayPreloadedQuery,
  graphql,
  useRelayPreloadedQuery
} from "@gigsmart/relay";

import { handleTopic } from "./notification-routing-handler";

import type { notificationRedirectNodeQuery } from "./__generated__/notificationRedirectNodeQuery.graphql";

interface Props {
  message: NotificationEntry;
  setCurrentMessage: (message: NotificationEntry | null) => void;
  queryRef: RelayPreloadedQuery<notificationRedirectNodeQuery>;
  showNotAvailableModal: (variant: "shift" | "job" | "project") => void;
}

export default function NotificationRedirect({
  message,
  queryRef,
  setCurrentMessage,
  showNotAvailableModal
}: Props) {
  const { node } = useRelayPreloadedQuery(
    graphql`
      query notificationRedirectNodeQuery($id: ID!) {
        node(id: $id) {
          id
          ...notificationRoutingHandler_node
        }
      }
    `,
    queryRef
  );

  const history = useHistory();
  useEffect(() => {
    const { pathname, showNotAvailable, state } =
      handleTopic(node, message) ?? {};

    if (showNotAvailable) {
      showNotAvailableModal(showNotAvailable);
    } else if (pathname) {
      state ? history.push({ pathname, state }) : history.push(pathname);
      setCurrentMessage(null);
    }
  }, [node, message.id]);

  return null;
}
