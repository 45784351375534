import {
  Column,
  ContentArea,
  Date as DateComp,
  QuoteContent,
  Surface,
  Text,
  Time as TimeComp
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ReactNode } from "react";

import { formatDistanceRange } from "@gigsmart/isomorphic-shared/gig/helpers";
import type { EngagementSummarySurface_engagement$key } from "./__generated__/EngagementSummarySurface_engagement.graphql";

type Props = {
  showAddress?: boolean;
  bottomAccessory?: ReactNode;
};

// TODO: Move to `browse/*` components
/** @deprecated */
export const EngagementSummarySurface = ({
  showAddress,
  startsAt,
  insertedAt,
  endsAt,
  workerDistance,
  gig,
  bottomAccessory
}: FragmentContainerInnerComponentProps<
  EngagementSummarySurface_engagement$key,
  Props
>) => {
  const actualStartsAt = startsAt ?? insertedAt;
  const addr = gig.organizationLocation?.place.streetAddress ?? gig.area ?? "-";
  return (
    <Surface variant="outline">
      <ContentArea gap="standard" size="medium" variant="medium">
        {showAddress && (
          <Text>
            <Text weight="bold">{addr} &bull; </Text>
            {formatDistanceRange({ min: workerDistance }, " away")}
          </Text>
        )}
        <Column gap="medium">
          <Text weight="bold" color="primary">
            {!showAddress ? "Shift Time" : "1 Shift Available"}
          </Text>
          <QuoteContent>
            <DateComp
              weight="bold"
              startsAt={startsAt}
              actualStartsAt={actualStartsAt}
              showDayOfWeek
              size="lg"
            />
            <Text>
              <Text weight="bold">Time: </Text>
              <TimeComp
                startsAt={startsAt}
                actualStartsAt={actualStartsAt}
                endsAt={endsAt}
              />
            </Text>
          </QuoteContent>
          {bottomAccessory}
        </Column>
      </ContentArea>
    </Surface>
  );
};

/** @deprecated */
export default createRelayFragmentContainer<
  EngagementSummarySurface_engagement$key,
  Props
>(
  graphql`
    fragment EngagementSummarySurface_engagement on Engagement {
      startsAt
      endsAt
      insertedAt
      workerDistance
      gig {
        area
        organizationLocation {
          place {
            streetAddress
          }
        }
      }
    }
  `,
  EngagementSummarySurface
);
