import { showEmbeddedBrowser } from "@gigsmart/atorasu";

export default function useLinkToTwilio() {
  return {
    linkToGigsmartSmsTerms: () => {
      showEmbeddedBrowser({
        url: "https://gigsmart.com/sms-terms-conditions"
      });
    }
  };
}
