import {
  Column,
  ContentArea,
  ContentRow,
  Divider,
  HighlightedReminder,
  ListRow,
  ProductInfoRow,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { duration, time } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import TimesheetTabs from "@gigsmart/seibutsu/engagement/TimesheetTabs";
import VerifyMileage from "@gigsmart/seibutsu/engagement/VerifyMileage";
import { readEngagementCapability } from "@gigsmart/seibutsu/engagement/WithEngagementCapability";
import WorkerTimesheetInfoCard from "@gigsmart/seibutsu/engagement/WorkerTimesheetInfoCard";
import WorkerUpdatedTimesheetReminder from "@gigsmart/seibutsu/engagement/WorkerUpdatedTimesheetReminder";
import moment from "moment";
import React from "react";
import type { ShiftTimesheetTabQuery } from "./__generated__/ShiftTimesheetTabQuery.graphql";
import type { ShiftTimesheetTabSubscription } from "./__generated__/ShiftTimesheetTabSubscription.graphql";

type Props = {
  id: string;
};

export default createSuspendedQueryContainer<ShiftTimesheetTabQuery, Props>(
  function ShiftTimesheetTab({ id, response }) {
    const history = useHistory();

    const engagement = response?.node;
    const isProject = engagement?.gigType === "PROJECT";

    useRelaySubscription<ShiftTimesheetTabSubscription>(
      graphql`
        subscription ShiftTimesheetTabSubscription($id: ID!) {
          engagementUpdated(engagementId: $id) {
            engagement {
              ...VerifyMileage_engagement
              ...WorkerTimesheetInfoCard_engagement
              ...WorkerUpdatedTimesheetReminder_engagement
              ...WithEngagementCapability_engagement
              ...TimesheetTabs_engagement
              paymentInfo {
                timesheetPaymentStyle
                billableDuration
              }
            }
          }
        }
      `,
      { id },
      { subscribe: !!id }
    );

    if (!engagement) return null;

    const stateName = engagement.currentState?.name;
    const paymentStyle = engagement.paymentInfo?.timesheetPaymentStyle;

    const workerSubmittedTimesheet =
      engagement.workerTimesheet?.isOverridden &&
      !engagement.workerTimesheet?.editable;

    const canApproveSystemTimesheet = readEngagementCapability(
      engagement,
      "APPROVE_SYSTEM_TIMESHEET"
    )?.available;
    const disputeTimesheetCapability = readEngagementCapability(
      engagement,
      "DISPUTE_TIMESHEET"
    )?.available;

    const adjustedTotalHours =
      paymentStyle === "FIXED_HOURS" &&
      moment.duration(engagement.paymentInfo?.billableDuration);

    const neverStarted =
      engagement?.systemTimesheet?.startedCount?.totalCount === 0;
    const workerHasApprovedTimesheet =
      stateName === "PENDING_TIMESHEET_APPROVAL" &&
      engagement?.systemTimesheet?.workerApprovals?.totalCount === 1;
    const workerSubmittedUpdatedTimesheet =
      engagement?.workerTimesheet?.isOverridden;
    const hasTimesheet =
      stateName !== "PENDING_TIMESHEET_APPROVAL" ||
      !!engagement.paymentInfo?.billableDuration ||
      workerSubmittedTimesheet;

    if (paymentStyle === "FIXED_AMOUNT") {
      return (
        <Surface>
          <ContentArea gap="standard">
            <Text weight="bold">No Timesheet</Text>
            <HighlightedReminder
              icon="circle-exclamation"
              header="This Requester has opted to pay you without a timesheet."
            />
            {disputeTimesheetCapability && (
              <ProductInfoRow
                variant="outline"
                testID="dispute-timesheet-info-row"
                name="Issue with your pay?"
                note="Start a Payment Dispute now"
                icon="file-exclamation"
                color="black"
                callToActionLabel="Start"
                callToActionOnPress={() =>
                  history.push(`/gigs/${engagement?.id}/dispute-timesheet`)
                }
              />
            )}
            <Column>
              <Divider />
              <ListRow
                label="Time Worked"
                right={duration.humanize(
                  engagement?.paymentInfo?.billableDuration,
                  "semi-compact-no-spaces"
                )}
              />
              <Divider />
            </Column>
          </ContentArea>
        </Surface>
      );
    }

    return (
      <Column gap="standard">
        <VerifyMileage
          gigEstimatedMileage={engagement?.gig?.estimatedMileage}
          gigDuration={engagement?.systemTimesheet?.totalDurationWorked}
          engagementId={engagement?.id}
          fragmentRef={engagement}
        />
        {!hasTimesheet && !isProject && (
          <Surface>
            <ContentArea>
              <HighlightedReminder
                icon="circle-info"
                header="You do not have a Timesheet. You have no time recorded on this Shift."
              />
            </ContentArea>
          </Surface>
        )}
        {canApproveSystemTimesheet && (
          <WorkerTimesheetInfoCard fragmentRef={engagement} />
        )}
        <Surface variant="outline">
          {adjustedTotalHours ? (
            <ContentArea gap="standard">
              <Text weight="bold">No Timesheet</Text>
              <WorkerUpdatedTimesheetReminder
                fragmentRef={engagement}
                spacerSize="standard"
                tabName="timesheet"
              />
              <Column>
                <Divider />
                <ContentRow justifyContent="space-between">
                  <Text>Time Worked</Text>
                  <Text>
                    {duration.humanize(
                      adjustedTotalHours,
                      "compact-no-seconds-no-trim"
                    )}
                  </Text>
                </ContentRow>
                <Divider />
              </Column>
            </ContentArea>
          ) : (
            <ContentArea gap="standard">
              {workerHasApprovedTimesheet && (
                <HighlightedReminder
                  icon="share"
                  header="Your Timesheet has been sent to the Requester. Please note, the Requester has the option to approve that Timesheet or a Timesheet they create."
                />
              )}
              {stateName === "PENDING_TIMESHEET_APPROVAL" &&
                !workerSubmittedUpdatedTimesheet &&
                !canApproveSystemTimesheet &&
                !workerHasApprovedTimesheet && (
                  <HighlightedReminder
                    icon="circle-exclamation"
                    header={
                      neverStarted ? (
                        <Text>
                          The 6-hour window to create a Timesheet has elapsed.
                          If you did work this Shift, a Timesheet will be
                          approved by the Requester no later than the end of the
                          business day{" "}
                          {time.humanize(
                            engagement.gig?.reconcilesAt,
                            "dateTimeFull"
                          )}
                          .
                        </Text>
                      ) : (
                        <Text>
                          The 6-hour window to review your Timesheet has
                          elapsed. A Timesheet will be approved by the Requester
                          no later than{" "}
                          {time.humanize(
                            engagement.gig?.reconcilesAt,
                            "dateTimeFull"
                          )}
                          .
                        </Text>
                      )
                    }
                  />
                )}
              <WorkerUpdatedTimesheetReminder
                fragmentRef={engagement}
                spacerSize="standard"
                tabName="timesheet"
              />
              {disputeTimesheetCapability && (
                <ProductInfoRow
                  variant="outline"
                  testID="dispute-timesheet-info-row"
                  name="Issue with your pay?"
                  note="Start a Payment Dispute now"
                  icon="file-exclamation"
                  color="black"
                  callToActionLabel="Start"
                  callToActionOnPress={() =>
                    history.push(`/gigs/${engagement?.id}/dispute-timesheet`)
                  }
                />
              )}
              <TimesheetTabs
                inset={false}
                variant="worker"
                fragmentRef={engagement}
                enableEdit={false}
              />
            </ContentArea>
          )}
        </Surface>
      </Column>
    );
  },
  {
    query: graphql`
      query ShiftTimesheetTabQuery($id: ID!) {
        node(id: $id) {
          ...VerifyMileage_engagement
          ...WorkerTimesheetInfoCard_engagement
          ...WorkerUpdatedTimesheetReminder_engagement
          ...TimesheetTabs_engagement
          ...WithEngagementCapability_engagement
          ... on Engagement {
            id
            gigType
            currentState {
              name
            }
            paymentInfo {
              timesheetPaymentStyle
              billableDuration
            }
            gig {
              reconcilesAt
              estimatedMileage
            }
            systemTimesheet: timesheet(variant: SYSTEM) {
              workerApprovals: approvals(
                first: 0
                query: "WHERE approvedByType = WORKER"
              ) {
                totalCount
              }
              totalDurationWorked
              estimatedMileage
              startedCount: states(first: 0, query: "WHERE action = START") {
                totalCount
              }
            }
            workerTimesheet: timesheet(variant: WORKER) {
              isOverridden
              editable
              totalDurationWorked
              estimatedMileage
            }
          }

        }
      }
    `,
    variables: ({ id }) => ({ id })
  }
);
