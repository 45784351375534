/**
 * @generated SignedSource<<71c43c8ef3fa997940a7fa87b2212c0b>>
 * @relayHash 0d4162ad5f256ca1fab9e0228d265716
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:90DMT5G4LQmRxZOvv8V7CJoXNflGWBj-cDRSFdRmer8

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingTimesheetsReviewCardSubscription$variables = {
  timeSheetReviewQuery: string;
};
export type PendingTimesheetsReviewCardSubscription$data = {
  readonly workerUpdated: {
    readonly worker: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"PendingTimesheetsReviewCard_worker">;
    };
  } | null | undefined;
};
export type PendingTimesheetsReviewCardSubscription = {
  response: PendingTimesheetsReviewCardSubscription$data;
  variables: PendingTimesheetsReviewCardSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timeSheetReviewQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "timeSheetReviewQuery"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingTimesheetsReviewCardSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "args": [
                  (v2/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "PendingTimesheetsReviewCard_worker"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingTimesheetsReviewCardSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": "reviewProjects",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 50
                  },
                  (v2/*: any*/)
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "SYSTEM"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 1
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE name = PENDING_TIMESHEET_APPROVAL"
                                  }
                                ],
                                "concreteType": "EngagementStateOrOverridesConnection",
                                "kind": "LinkedField",
                                "name": "states",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementStateOrOverridesEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "transitionedAt",
                                            "storageKey": null
                                          },
                                          (v1/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "states(first:1,query:\"WHERE name = PENDING_TIMESHEET_APPROVAL\")"
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"SYSTEM\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startsAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endsAt",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "insertedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timezone",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logoUrl",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:90DMT5G4LQmRxZOvv8V7CJoXNflGWBj-cDRSFdRmer8",
    "metadata": {},
    "name": "PendingTimesheetsReviewCardSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "8b80d593fa90f1ecab78e7c1a1cebc70";

export default node;
