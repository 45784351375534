import * as React from "react";
import { type LayoutChangeEvent, ScrollView } from "react-native";

import type { ViewStyleProp } from "../style";
import Context from "./context";
import ScrollHelper from "./scroll-helper";

// This class allows to scroll to text inputs on focus.
// ***Note:
// ScrollableTextInput and ScrollableSearchInput must not be wrapped in parent
// Views, as it would result in onLayout having y=0.
// Avoid wrapping in favor of passing style directly into
//   `ScrollableTextInput && ScrollableSearchInput`
// Otherwise warp in ScrollableView or React.Fragment.

interface Props {
  children: React.ReactNode;
  restoreScrollPositionOnHeightChange?: boolean;
  extraScrollHeight?: number;
  contentContainerStyle?: ViewStyleProp;
  style?: ViewStyleProp;
  testID?: string;
}

export default class Scroller extends React.Component<Props> {
  scrollHelper = new ScrollHelper();
  layoutHeight = 0;
  contentOffsetY = 0;

  handleElementLayout = (elementName: string, { y }: { y: number }) => {
    const { extraScrollHeight } = this.props;
    this.scrollHelper.onLayout(elementName, {
      y: y + (extraScrollHeight ?? 0)
    });
  };

  handleScrollToElement = (elementName: string) => {
    this.scrollHelper.scrollToElement(elementName);
  };

  handleScrollToTop = () => {
    this.scrollHelper.scrollToTop();
  };

  handleScrollToEnd = () => {
    this.scrollHelper.scrollToEnd();
  };

  providerValue = {
    onElementLayout: this.handleElementLayout,
    scrollToElement: this.handleScrollToElement,
    scrollToTop: this.handleScrollToTop,
    scrollToEnd: this.handleScrollToEnd
  };

  _handleScroll = ({
    nativeEvent: {
      contentOffset: { y }
    }
  }: any) => {
    this.contentOffsetY = y;
  };

  _handlePossibleHeightChange = ({ nativeEvent }: LayoutChangeEvent) => {
    if (!nativeEvent) return;

    const height = nativeEvent.layout.height;
    const { restoreScrollPositionOnHeightChange } = this.props;
    const { layoutHeight, contentOffsetY } = this;
    if (Math.abs(height - layoutHeight) > 5) {
      if (restoreScrollPositionOnHeightChange && contentOffsetY > 0) {
        this.scrollHelper.scrollToY({
          y: contentOffsetY,
          animated: false
        });
      }
      this.layoutHeight = height;
    }
  };

  render() {
    const { style, contentContainerStyle, testID } = this.props;

    const { restoreScrollPositionOnHeightChange } = this.props;

    return (
      <Context.Provider value={this.providerValue}>
        <ScrollView
          testID={testID}
          ref={this.scrollHelper.setScrollRef}
          style={style}
          nestedScrollEnabled
          contentContainerStyle={contentContainerStyle}
          keyboardShouldPersistTaps="always"
          onScroll={
            restoreScrollPositionOnHeightChange ? this._handleScroll : undefined
          }
          onLayout={
            restoreScrollPositionOnHeightChange
              ? this._handlePossibleHeightChange
              : undefined
          }
        >
          {this.props.children}
        </ScrollView>
      </Context.Provider>
    );
  }
}
