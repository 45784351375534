import { currency } from "@gigsmart/isomorphic-shared/iso";
import React from "react";

import SquareData from "./square-data";

interface Props {
  netPay?: string;
  payRate?: string;
}
const TotalEarningsPaySquare = ({ netPay, payRate }: Props) => {
  const netPayValue = currency.toFloat(netPay, 0);
  const integer = netPayValue > 99;
  return (
    <SquareData
      label="Total Earnings"
      mainText={currency.humanize(netPay, { integer })}
      hintText={payRate ? currency.humanizeRate(payRate, "hr") : undefined}
    />
  );
};

export default TotalEarningsPaySquare;
