import {
  Button,
  CollapsibleContainer,
  ContentArea,
  FancyHeader,
  type IconName,
  Spacer,
  Stack,
  TooltipButton
} from "@gigsmart/atorasu";
import type { ColorProp } from "@gigsmart/atorasu/style/theme/colors";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayPaginationFragment
} from "@gigsmart/relay";
import React from "react";
import { DirectPaymentsAndHiresRow } from "./DirectPaymentsAndHiresRow";
import type { DirectPaymentsAndHiresListSurfaceQuery } from "./__generated__/DirectPaymentsAndHiresListSurfaceQuery.graphql";
import type {
  DirectPaymentsAndHiresListSurface_Root$data,
  DirectPaymentsAndHiresListSurface_Root$key
} from "./__generated__/DirectPaymentsAndHiresListSurface_Root.graphql";
import useWorkerAboutDirectHireModal from "./useWorkerAboutDirectHireModal";

const ICON: Record<string, IconName> = {
  APPROVED: "check",
  PENDING: "spinner",
  REJECTED: "ban"
};

const COLOR: Record<string, ColorProp> = {
  APPROVED: "success",
  REJECTED: "error",
  PENDING: "black"
};

interface Props {
  variant: "APPROVED" | "PENDING" | "REJECTED";
  directHireBonus?: string | null;
  count?: number;
  query: string;
}

interface InnerProps {
  variant: "APPROVED" | "PENDING" | "REJECTED";
  count?: number;
  data: DirectPaymentsAndHiresListSurface_Root$data | null | undefined;
  hasNext: boolean;
  isLoadingNext: boolean;
  loadNext: (number: number) => void;
  directHireBonus?: string | null;
}

export function DirectPaymentsAndHiresListSurface({
  variant,
  count = 0,
  data,
  hasNext,
  loadNext,
  isLoadingNext,
  directHireBonus
}: InnerProps) {
  const handleLoadMorePress = () => {
    if (hasNext && !isLoadingNext) {
      loadNext(10);
    }
  };
  const { showApproved, showPending, showRejected } =
    useWorkerAboutDirectHireModal();
  return (
    <CollapsibleContainer
      expandLabel="Show More"
      collapseLabel="Show less"
      testID={`${variant?.toLocaleLowerCase()}-container`}
      variant="surface"
      header={
        <FancyHeader
          title={getTitle(variant, count)}
          icon={ICON[variant] as IconName}
          color={COLOR[variant]}
          variant={COLOR[variant]}
          action={
            <TooltipButton
              testID="show-info-modal-btn"
              onPress={
                variant === "APPROVED"
                  ? showApproved
                  : variant === "PENDING"
                    ? showPending
                    : showRejected
              }
              variant="solid"
            />
          }
        />
      }
      content={
        <ContentArea size="none">
          <Spacer />
          <Stack size="compact">
            {getConnectionNodes(data?.directHireClaims)
              ?.slice(0, 3)
              ?.map((node) => (
                <DirectPaymentsAndHiresRow
                  key={node?.id}
                  variant={variant}
                  directHireBonus={directHireBonus}
                  {...node}
                />
              ))}
          </Stack>
          <Spacer size={count > 3 ? "compact" : "standard"} />
        </ContentArea>
      }
    >
      {count > 3 && (
        <ContentArea size="none">
          <Stack size="compact">
            {getConnectionNodes(data?.directHireClaims)
              ?.slice(3)
              ?.map((node) => (
                <DirectPaymentsAndHiresRow
                  key={node?.id}
                  variant={variant}
                  directHireBonus={directHireBonus}
                  {...node}
                />
              ))}
            {hasNext && (
              <Button
                testID="load-more-btn"
                size="xsmall"
                variant="clear"
                label="Load More"
                onPress={handleLoadMorePress}
              />
            )}
          </Stack>
          <Spacer size="compact" />
        </ContentArea>
      )}
    </CollapsibleContainer>
  );
}

export default createSuspendedQueryContainer<
  DirectPaymentsAndHiresListSurfaceQuery,
  Props
>(
  function DirectPaymentsAndHiresListSurfacePaginationWrapper({
    response: result,
    ...rest
  }) {
    const paginationProps = useRelayPaginationFragment<
      DirectPaymentsAndHiresListSurfaceQuery,
      DirectPaymentsAndHiresListSurface_Root$key
    >(
      graphql`
        fragment DirectPaymentsAndHiresListSurface_Root on RootQueryType
        @refetchable(
          queryName: "DirectPaymentsAndHiresListSurfacePaginationQuery"
        )
        @argumentDefinitions(
          first: { type: "Int", defaultValue: 25 }
          after: { type: "String" }
          query: { type: "String" }
        ) {
          directHireClaims(first: $first, after: $after, query: $query)
            @connection(
              key: "DirectPaymentsAndHiresListSurface_directHireClaims"
            ) {
            edges {
              node {
                id
                directHireType
                workerPayout
                organization {
                  name
                  logoUrl
                }
                responseDeadline
                rejected: transitions(
                  first: 1
                  query: "WHERE state = REJECTED"
                ) {
                  edges {
                    node {
                      action
                      transitionedAt
                      transitionedBy {
                        agent {
                          __typename
                        }
                      }
                    }
                  }
                }
                transitions(first: 1, query: "WHERE state = DISBURSED") {
                  edges {
                    node {
                      transitionedAt
                    }
                  }
                }
              }
            }
          }
        }
      `,
      result ?? null
    );
    return <DirectPaymentsAndHiresListSurface {...rest} {...paginationProps} />;
  },
  {
    query: graphql`
      query DirectPaymentsAndHiresListSurfaceQuery($query: String!) {
        ...DirectPaymentsAndHiresListSurface_Root @arguments(query: $query)
      }
    `,
    variables: (props) => ({ query: props.query })
  }
);

function getTitle(
  variant: "APPROVED" | "PENDING" | "REJECTED",
  count: number
): string {
  if (variant === "PENDING")
    return `${count} Pending ${DirectPaymentsOnly.select(
      "Direct Payment",
      "Direct Payment & Hire"
    )} ${pluralize(count, "Request", false)}`;

  return `${count} ${
    variant === "REJECTED" ? "Rejected" : "Confirmed"
  } Direct ${pluralize(count, "Payment", false)}${DirectPaymentsOnly.select(
    "",
    ` & ${pluralize(count, "Hire", false)}`
  )}`;
}
