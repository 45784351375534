import { FormField, Validator } from "@gigsmart/fomu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import ConsentDocumentForm from "../../user-consent/ConsentDocumentForm";
import type { MobileNumberDisclosureQuery } from "./__generated__/MobileNumberDisclosureQuery.graphql";

export default createSuspendedQueryContainer<MobileNumberDisclosureQuery>(
  function MobileNumberDisclosure({ response }) {
    const { legalDocument, consent } = response?.viewer?.consentStatus ?? {};
    if (!legalDocument || !!consent) return null;
    return (
      <FormField
        name="smsConsent"
        validates={Validator.isTrue({ required: true })}
      >
        {({ setValue }) => (
          <ConsentDocumentForm
            key={legalDocument.filename}
            document={legalDocument}
            autoSubmit
            hideDocument
            allowUnread
            onComplete={() => setValue(true)}
          />
        )}
      </FormField>
    );
  },
  {
    query: graphql`
      query MobileNumberDisclosureQuery {
        viewer {
          ... on User {
            consentStatus(filename: "gigsmart/sms") {
              consent {
                consentedAt
              }
              legalDocument {
                filename
                ...ConsentDocumentForm_document
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);
