import { Divider, Row, Spacer, useMatchesViewport } from "@gigsmart/atorasu";
import { asEnums, gt, now, where } from "@gigsmart/biruda";
import { useCurrentUrl } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import NavUserProfile from "../user/NavUserProfile";
import MessageCenterIcon from "./MessageCenterIcon";
import NotificationIcon, {
  NOTIFICATION_MESSAGE_TYPES
} from "./NotificationIcon";
import type { ViewerTopNavQuery } from "./__generated__/ViewerTopNavQuery.graphql";

const MAIN_TABS = new Set([
  /// Shared
  "/",

  /// Requester
  "/posts",
  "/shifts",
  "/profile",

  /// Worker
  "/applied",
  "/hired",
  "/upcoming",
  "/wallet",
  "/profile",
  "/gigs/applied",
  "/gigs/scheduled",
  "/browse",

  // MyGigs tabs
  "/shifts",
  "/projects",
  "/jobs"
]);

export default createSuspendedQueryContainer<ViewerTopNavQuery>(
  function ViewerTopNav({ response }) {
    const isMd = useMatchesViewport((media) => media.size.medium.up);
    const url = useCurrentUrl();
    const isSm = useMatchesViewport(({ size }) => size.small.down);
    const showIcons =
      !isSm || MAIN_TABS.has(url) || url.includes("/shifts?tab");

    if (!response?.viewer) return null;
    return (
      <Row>
        {showIcons && (
          <>
            <NotificationIcon fragmentRef={response.viewer} />
            <MessageCenterIcon />
          </>
        )}
        {isMd && (
          <>
            <Spacer size="medium" horizontal />
            <Divider dir="y" />
            <NavUserProfile />
          </>
        )}
      </Row>
    );
  },
  {
    query: graphql`
      query ViewerTopNavQuery($notificationQuery: String!) {
        viewer {
          ...NotificationIcon_User @arguments(query: $notificationQuery)
        }
      }
    `,
    variables: {
      notificationQuery: where({
        receiptViewedAt: null,
        messageType: asEnums(NOTIFICATION_MESSAGE_TYPES)
      })
        .and(where({ expiresAt: null }).or({ expiresAt: gt(now()) }))
        .toString()
    }
  }
);
