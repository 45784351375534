import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { EducationForm } from "@gigsmart/seibutsu/worker-profile/EducationForm";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { educationScreenQuery } from "./__generated__/educationScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "ProfileEducation">;

export default createSuspendedQueryContainer<educationScreenQuery, Props>(
  function EducationScreen({ response: { node } = {} }) {
    return <EducationForm {...node} />;
  },
  {
    query: graphql`
      query educationScreenQuery($id: ID!) {
        node(id: $id) {
          ... on WorkerEducation {
            id
            schoolName
            educationLevel
            startDate
            endDate
          }
        }
      }
    `,
    variables: ({ route }) => ({
      id: route.params.educationId ?? ""
    })
  }
);
