import {
  Button,
  ContentArea,
  LoadingView,
  ScreenScroll,
  ScreenTop,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { type AppScreenProps, defaultResetHome } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React, { useEffect } from "react";
import { useAppSettings } from "../app/app-settings";
import type { AuthParamList } from "../auth/types";
import type { confirmRegistrationMutation } from "./__generated__/confirmRegistrationMutation.graphql";
import type { confirmRegistrationQuery } from "./__generated__/confirmRegistrationQuery.graphql";

export const ConfirmRegistrationScreen = createSuspendedQueryContainer<
  confirmRegistrationQuery,
  AppScreenProps<AuthParamList, "ConfirmRegistration">
>(
  function ConfirmRegistration({ route, response }) {
    const viewer = response?.viewer;
    const [confirmRegistrationMutation, { errors, loading }] =
      useRelayMutation<confirmRegistrationMutation>(
        graphql`
          mutation confirmRegistrationMutation($input: ConfirmUserEmailInput!) {
            confirmUserEmail(input: $input) {
              userEmail {
                address
              }
            }
          }
        `
      );

    const { appId } = useAppSettings();
    const hasErrors = !!errors?.length;
    const handleContinue = () => defaultResetHome();

    useEffect(() => {
      if (
        viewer?.primaryEmail?.confirmed ||
        !(appId === "worker" || appId === "requester")
      ) {
        handleContinue();
      }

      confirmRegistrationMutation(
        {
          input: {
            token: route.params.token,
            userType: appId === "requester" ? "REQUESTER" : "WORKER"
          }
        },
        { onSuccess: handleContinue }
      );
    }, []);

    const { address, confirmed } = viewer?.primaryEmail ?? {};
    return (
      <ScreenScroll testID="confirm-registration-page" constraint="small">
        <ScreenTop showBackButton={false} extraSpace="large" />
        <Surface variant="shadow">
          <ContentArea>
            <Spacer />
            {loading ? (
              <LoadingView />
            ) : hasErrors ? (
              <Stack>
                <Text
                  variant="titleLg"
                  testID="confirm-registration-response-text"
                >
                  We could not verify your email address.
                </Text>
                <Button
                  testID="confirm-registration-continue-button"
                  label="Continue"
                  onPress={handleContinue}
                />
              </Stack>
            ) : (
              <Stack>
                <Text variant="titleLg" color="primary">
                  Success!
                </Text>
                <Text testID="confirm-registration-response-text">
                  Your email address {address} has been confirmed.
                </Text>
                <Button
                  onPress={handleContinue}
                  label="Continue"
                  disabled={!confirmed}
                  testID="confirm-registration-continue-button"
                />
              </Stack>
            )}
          </ContentArea>
        </Surface>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query confirmRegistrationQuery {
        viewer {
          primaryEmail {
            address
            confirmed
          }
        }
      }
    `,
    variables: {}
  }
);
