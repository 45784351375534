import { useDebouncedEffect, useLatestCallback } from "@gigsmart/atorasu";
import { take } from "lodash";
import React, {
  type ComponentProps,
  useCallback,
  useEffect,
  useState
} from "react";
import {
  Keyboard,
  type LayoutChangeEvent,
  Platform,
  ScrollView,
  TouchableOpacity,
  View
} from "react-native";
import { IconInputAccessory, type IconName, StyledIcon } from "../icon";
import StyledTextInput from "../input/styled-text-input/styled-text-input";
import { splitLabel } from "../input/text-utils";
import {
  type TextStyleProp,
  type ViewStyleProp,
  stylesStubs,
  useStyles
} from "../style";
import StyledText from "../text/styled-text";

export interface PredictionResult<
  T extends Record<string, unknown> = Record<string, unknown>
> {
  label: string;
  data?: T;
}

export interface PredictiveInputProps<
  T extends Record<string, unknown> = Record<string, unknown>
> extends Omit<ComponentProps<typeof StyledTextInput>, "value" | "onChange"> {
  initialValue?: string | null;
  value?: string | null;
  predictFn?: (
    arg0: string,
    arg1: number | null | undefined
  ) => Promise<Array<PredictionResult<T>>>;
  errorOnEmpty?: boolean;
  onChangeText?: (arg0: string) => void;
  onChange?: (arg0: PredictionResult<T> | null | undefined) => void;
  onSelect?: (arg0: PredictionResult<T> | null | undefined) => void;
  hideOnInputAccessoryDone?: boolean;
  showCancel?: boolean;
  predictionStyle?: ViewStyleProp;
  onOptionsShown?: () => unknown;
  onOptionsHidden?: () => unknown;
  predictionContainerStyle?: ViewStyleProp;
  maxResults?: number;
  predictionHeader?: string;
  iconName?: IconName | null;
  testID?: string;
  iconColor?: string;
  shouldSearchOnFocus?: boolean;
  inputRowStyle?: ViewStyleProp;
  style?: ViewStyleProp;
  initialPredictions?: ReadonlyArray<PredictionResult<T>>;
  customRenderPredictions?: (
    predictions: ReadonlyArray<PredictionResult<T>>
  ) => React.ReactNode;
  onSearchLayout?: (arg0: LayoutChangeEvent) => void;
  customTextOuter?: TextStyleProp;
  customTextInner?: TextStyleProp;
  customTextStyle?: TextStyleProp;
  noResults?: string;
  showCaret?: boolean;
}

function shouldShowOptions({
  focused,
  results
}: {
  focused: boolean;
  results?: readonly PredictionResult[] | null;
}) {
  return focused && !!results?.length;
}

export default function PredictiveInput<
  T extends Record<string, unknown> = Record<string, unknown>
>({
  value,
  initialValue,
  predictionContainerStyle,
  predictionHeader,
  style,
  inputRowStyle,
  showCancel,
  iconColor,
  iconName,
  initialPredictions,
  maxResults,
  onSearchLayout,
  customRenderPredictions,
  hideOnInputAccessoryDone,
  onOptionsHidden,
  onOptionsShown,
  onChange,
  onSelect,
  predictFn,
  predictionStyle,
  shouldSearchOnFocus,
  // eslint-disable-next-line react/prop-types
  error,
  // eslint-disable-next-line react/prop-types
  renderRightAccessory,
  showCaret,
  // eslint-disable-next-line react/prop-types
  onBlur,
  // eslint-disable-next-line react/prop-types
  onFocus,
  noResults,
  onChangeText,
  customTextOuter,
  customTextInner,
  customTextStyle,
  ...props
}: PredictiveInputProps<T>) {
  const { theme, styles } = useStyles(({ color, font }) => ({
    container: {
      maxHeight: "100%",
      width: "100%"
    },
    icon: {
      fontSize: font.size.extraLarge,
      padding: 15
    },
    iconRightAccessory: { width: 20 },
    input: {
      flex: 1,
      fontSize: font.size.extraLarge,
      paddingBottom: 15,
      paddingRight: 15,
      paddingTop: 15
    },
    inputContainer: {
      alignItems: "center",
      backgroundColor: color.white,
      flexDirection: "row",
      justifyContent: "center"
    },
    prediction: {
      borderColor: color.blue,
      borderBottomWidth: 2,
      padding: 10
    },
    border: {
      borderColor: color.blue,
      borderBottomWidth: 3
    },
    borderStyle: {
      borderColor: color.blue
    },
    cancelButton: { paddingLeft: 5, paddingBottom: 7 },
    text: {
      ...font.withSize(font.size.extraLarge, font.lineHeight.header),
      color: color.neutralDark
    },
    highlightedTextInner: {
      ...font.withSize(font.size.extraLarge, font.lineHeight.header),
      color: color.black
    },
    highlightedTextParent: {
      fontSize: font.size.extraLarge
    }
  }));

  const [isOpen, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [results, setResults] = useState<ReadonlyArray<PredictionResult<T>>>(
    []
  );

  const handlePrediction = useCallback(
    async (input: string) => {
      if (!predictFn || !input) return;
      let results = await predictFn(input, maxResults);
      if (results.length === 0 && noResults) results = [{ label: noResults }];
      setResults(results);
    },
    [predictFn]
  );

  const handleFocus = useLatestCallback<NonNullable<typeof onFocus>>(
    (event) => {
      if (shouldSearchOnFocus) void handlePrediction(value ?? "");
      setFocused(true);
      onFocus?.(event);
    }
  );

  const handleBlur = useLatestCallback<NonNullable<typeof onBlur>>((event) => {
    setFocused(false);
    onBlur?.(event);
  });

  const handleChange = useLatestCallback((nextValue: string) => {
    if (onChangeText) onChangeText(nextValue);
    if (onChange) onChange({ label: nextValue });
  });

  const handleClear = useLatestCallback(() => {
    if (onSelect) onSelect(null);
    if (onChange) onChange(null);
    if (onChangeText) onChangeText("");
  });

  const handleSelect = useLatestCallback((result: PredictionResult<T>) => {
    setResults([]);
    if (result.label === noResults) return;

    if (onSelect) onSelect(result);
    if (onChange) onChange(result);
    if (onChangeText) onChangeText(result.label);
  });

  const renderHighlightedText = (label: string) => {
    const texts = splitLabel(value, label);

    return (
      <StyledText style={styles.highlightedTextParent}>
        <StyledText style={[styles.text, customTextOuter]}>
          {texts[0]}
        </StyledText>
        <StyledText style={[styles.highlightedTextInner, customTextInner]}>
          {texts[1]}
        </StyledText>
        <StyledText style={[styles.text, customTextOuter]}>
          {texts[2]}
        </StyledText>
      </StyledText>
    );
  };

  const renderText = (label: string) => (
    <StyledText style={[styles.text, customTextStyle && customTextStyle]}>
      {label}
    </StyledText>
  );

  const renderPrediction = (
    { label, data }: PredictionResult<T>,
    index: number
  ) => {
    const subIndex = label.toUpperCase().indexOf(label.toUpperCase());
    const len = value?.length ?? 0;
    const renderFn =
      len >= 3 && subIndex >= 0 ? renderHighlightedText : renderText;

    return (
      <TouchableOpacity
        style={[styles.prediction, predictionStyle]}
        key={`${label}-${index}`}
        testID="prediction-result"
        onPress={() => handleSelect({ label, data })}
      >
        {renderFn(label)}
      </TouchableOpacity>
    );
  };

  const handleInputAccessoryDone = useLatestCallback(() => {
    if (hideOnInputAccessoryDone) setFocused(false);
  });

  useDebouncedEffect(() => {
    if (!focused || !value) return;
    void handlePrediction(value);
  }, [value, handlePrediction]);

  useEffect(() => {
    const newOpen = shouldShowOptions({ focused, results });
    if (newOpen === isOpen) return;
    let timeoutID: ReturnType<typeof setTimeout> | undefined;
    if (newOpen) setOpen(true);
    else timeoutID = setTimeout(() => setOpen(false), 1000);

    return () => {
      if (timeoutID) clearTimeout(timeoutID);
    };
  }, [focused, results]);

  let filteredResults: ReadonlyArray<PredictionResult<T>> = results;
  if (!filteredResults.length && focused && initialPredictions?.length) {
    filteredResults = initialPredictions;
  }
  if (typeof maxResults === "number") {
    filteredResults = take(filteredResults, maxResults);
  }

  const customRightAccessory =
    renderRightAccessory ??
    (iconName
      ? () => (
          <StyledIcon
            style={styles.iconRightAccessory}
            color={iconColor ?? "blue"}
            name={iconName}
          />
        )
      : showCaret
        ? () => (
            <IconInputAccessory
              color="blue"
              name={focused ? "caret-up" : "caret-down"}
            />
          )
        : undefined);
  return (
    <View style={[styles.container, style]}>
      <View style={inputRowStyle} onLayout={onSearchLayout}>
        <StyledTextInput
          {...props}
          error={error}
          value={value ?? initialValue ?? ""}
          onChangeText={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onDone={handleInputAccessoryDone}
          renderRightAccessory={customRightAccessory}
        />
        {showCancel && !!value && (
          <TouchableOpacity onPress={handleClear}>
            <StyledText
              fontSize={theme.font.size.extraLarge}
              color="neutralDark"
              style={styles.cancelButton}
            >
              Cancel
            </StyledText>
          </TouchableOpacity>
        )}
      </View>
      {isOpen ? (
        customRenderPredictions ? (
          customRenderPredictions(filteredResults)
        ) : (
          <ScrollView
            onScroll={() => {
              Platform.OS !== "web" && Keyboard.dismiss();
            }}
            // eslint-disable-next-line react-native/no-inline-styles
            contentContainerStyle={{ flexGrow: 1 }}
            style={predictionContainerStyle}
            keyboardShouldPersistTaps="handled"
            nestedScrollEnabled
          >
            {typeof predictionHeader === "string" && (
              <StyledText>{predictionHeader}</StyledText>
            )}
            {filteredResults.map(renderPrediction)}
          </ScrollView>
        )
      ) : null}
    </View>
  );
}

PredictiveInput.defaultProps = {
  ...stylesStubs,
  predictFn: async () => await Promise.resolve([]),
  onSearchLayout: () => {}
};
