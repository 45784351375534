import { ActionRow, GridNull, Text } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { ShiftGroupChatHomeTabCard_shift$key } from "./__generated__/ShiftGroupChatHomeTabCard_shift.graphql";

export function ShiftGroupChatHomeTabCard({
  userMessageStats
}: FragmentContainerInnerComponentProps<ShiftGroupChatHomeTabCard_shift$key>) {
  const unreadCount = userMessageStats?.unreadCount ?? 0;
  const history = useHistory();
  if (!unreadCount) return <GridNull />;

  return (
    <ActionRow
      testID="shift-group-chat-messages-link"
      icon="messages"
      title={
        <Text color="primary">
          <Text weight="bold">{unreadCount} </Text>
          <Text>
            New Shift Group Chat {pluralize(unreadCount, "Message", false)}
          </Text>
        </Text>
      }
      onPress={() => history.push("/shift-group-chats")}
    />
  );
}

export default createRelayFragmentContainer<ShiftGroupChatHomeTabCard_shift$key>(
  graphql`
    fragment ShiftGroupChatHomeTabCard_shift on User {
      userMessageStats(conversationTypes: [SHIFT_GROUP]) {
        unreadCount
      }
    }
  `,
  ShiftGroupChatHomeTabCard
);
