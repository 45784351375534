import {
  Button,
  Column,
  ContentArea,
  HighlightedReminder,
  ScreenScroll,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import NavBackButton from "@gigsmart/isomorphic-shared/nav/nav-back-button";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import { confirmPrompt, dismiss } from "@gigsmart/katana";
import type { StepProps } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import DisputeAdditionalPaymentCard from "@gigsmart/seibutsu/engagement-dispute/worker/DisputeAdditionalPaymentCard";
import DisputeTimesheetCard from "@gigsmart/seibutsu/engagement-dispute/worker/DisputeTimesheetCard";
import DisputeTypeCard from "@gigsmart/seibutsu/engagement-dispute/worker/DisputeTypeCard";
import type { StepPropsType } from "@gigsmart/seibutsu/engagement-dispute/worker/types";
import WorkerShiftInfoCollapsible from "@gigsmart/seibutsu/engagement/WorkerShiftInfoCollapsible";
import StepHeader from "@gigsmart/seibutsu/shared/Stepper/StepHeader";
import React from "react";
import type { paymentDisputeStepQuery } from "./__generated__/paymentDisputeStepQuery.graphql";

type Props = StepProps<StepPropsType>;

export default createSuspendedQueryContainer<paymentDisputeStepQuery, Props>(
  function PaymentDisputeStep({
    prevStep,
    stepData,
    gotoStepByName,
    updateData,
    response
  }) {
    const { engagement, timesheet } = response ?? {};
    const timesheetDisputed = stepData?.disputeTimesheet;
    const additionalPayDisputed = stepData?.disputeAdditionalPayment;

    return (
      <ScreenScroll testID="payment-dispute-step">
        <Column fill>
          <NavBackButton onGoBack={prevStep} />
          <Column fill justifyContent="space-between">
            <Stack>
              <StepHeader
                name="Payment Dispute"
                note="Please review your Approved Shift Info and create updates as needed. When you are finished, continue to the next step to review and submit your Dispute to the Requester."
              />
              <WorkerShiftInfoCollapsible fragmentRef={engagement} />
              <DisputeTypeCard
                disputeTimesheet={!!timesheetDisputed}
                disputeAdditional={!!additionalPayDisputed}
                onEditType={prevStep}
              />
              {timesheetDisputed && (
                <DisputeTimesheetCard
                  onCreatePress={() =>
                    gotoStepByName("create-dispute-timesheet")
                  }
                  comment={stepData?.timesheetComment}
                  fragmentRef={timesheet}
                />
              )}
              {additionalPayDisputed && (
                <DisputeAdditionalPaymentCard
                  onRequestPress={() =>
                    gotoStepByName("request-additional-payment")
                  }
                  comment={stepData?.additionalPayComment}
                  fragmentRef={timesheet}
                  updateData={updateData}
                  stepData={stepData}
                />
              )}
            </Stack>
            <ContentArea size="none">
              <Spacer />
              <Stack>
                {(!timesheetDisputed || stepData?.timesheetComment) &&
                  (!additionalPayDisputed ||
                    stepData?.additionalPayComment) && (
                    <HighlightedReminder
                      icon="circle-exclamation"
                      header="Your Dispute is not finished. Continue to the next step to review and submit the Dispute for the Requester to review."
                    />
                  )}
                <Button
                  testID="cancel-dispute-btn"
                  outline
                  label="Cancel, I no Longer Wish to Dispute"
                  onPress={() => {
                    confirmPrompt({
                      cancelLabel: "No",
                      title: "Are you sure you want to cancel your Dispute?",
                      subTitle: "Your Dispute will not be saved if you cancel.",
                      onDo: () => {
                        defaultBackHandler({ safeExit: true });
                        toast.notice("Your Dispute has been canceled");
                      },
                      onCancel: dismiss
                    });
                  }}
                />
                <Button
                  testID="next-btn"
                  onPress={() => gotoStepByName("review")}
                  label={`Continue with ${currency.humanize(
                    timesheet?.disputedFinalTimesheet?.diff?.totalPayment
                  )} Dispute`}
                  disabled={
                    !(
                      (!timesheetDisputed || stepData?.timesheetComment) &&
                      (!additionalPayDisputed || stepData?.additionalPayComment)
                    )
                  }
                />
                <Spacer />
              </Stack>
            </ContentArea>
          </Column>
        </Column>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query paymentDisputeStepQuery(
        $engagementId: ID!
        $timesheetId: ID!
        $hasTimesheetId: Boolean!
      ) {
        engagement: node(id: $engagementId) {
          ... on Engagement {
            ...WorkerShiftInfoCollapsible_engagement
          }
        }
        timesheet: node(id: $timesheetId) @include(if: $hasTimesheetId) {
          ... on EngagementTimesheet {
            disputedFinalTimesheet {
              diff {
                totalPayment
              }
            }
            ...DisputeAdditionalPaymentCard_EngagementTimesheet
            ...DisputeTimesheetCard_EngagementTimesheet
          }
        }
      }
    `,
    variables: ({ stepData }) => ({
      engagementId: stepData.engagementId ?? "",
      timesheetId: stepData?.disputeTimesheetId ?? "",
      hasTimesheetId: !!stepData?.disputeTimesheetId
    })
  }
);
