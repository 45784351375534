import { toast } from "@gigsmart/atorasu";
import { createConversion } from "@gigsmart/dekigoto";
import dynamicLinks from "@react-native-firebase/dynamic-links";
import { Alert, type AlertButton, Platform, Share } from "react-native";
import { IS_TESTING } from "../constants";
import apiKey from "./google-api-key";

const packageName = process.env.DEEPLINK_BUNDLE_ID;
const appStoreId = process.env.APPLE_APP_STORE_ID;
const fallbackUrl = process.env.DEEPLINK_FALLBACK_URL;
const domainUri = process.env.FIREBASE_DEEPLINK_DOMAIN;

type AppNameType = "Requester" | "Worker";

const shareConversion = createConversion("Share");

export function share(appName: AppNameType) {
  void shareConversion.track({ appName });
  let url = "";
  let message = "";
  if (appName === "Requester") {
    url = "https://gigsmart.com/requester";
    message = `Find self-reliant workers.\n\nThis isn't just an app - it's a whole new way to work.\n\n${url}`;
  } else if (appName === "Worker") {
    url = "https://gigsmart.com/workers";
    message = `Find jobs with more freedom and flexibility.\n\nThis isn't just an app - it's a whole new way to work.\n\n${url}`;
  }
  void Share.share(
    {
      title: "GigSmart",
      message
    },
    {
      excludedActivityTypes: [
        "com.apple.mobilenotes.SharingExtension",
        "com.apple.mobileslideshow.StreamShareService",
        "com.apple.reminders.RemindersEditorExtension",
        "com.apple.UIKit.activity.AddToReadingList",
        "com.apple.UIKit.activity.AssignToContact",
        "com.apple.UIKit.activity.MarkupAsPDF",
        "com.apple.UIKit.activity.OpenInIBooks",
        "com.apple.UIKit.activity.PostToFlickr",
        "com.apple.UIKit.activity.PostToVimeo",
        "com.apple.UIKit.activity.Print",
        "com.apple.UIKit.activity.SaveToCameraRoll"
      ]
    }
  );
}

function shareShortLink(link: string, buildMessage: (url: string) => string) {
  if (!domainUri || !fallbackUrl || !packageName || !appStoreId || !apiKey) {
    return;
  }
  void dynamicLinks()
    .buildShortLink(
      {
        link,
        domainUriPrefix: `https://${domainUri}`,
        android: {
          packageName
        },
        ios: {
          bundleId: packageName,
          appStoreId: appStoreId || ""
        }
      },
      dynamicLinks.ShortLinkType.SHORT
    )
    .then((url) => {
      const message = buildMessage(url);

      void Share.share(
        {
          title: "GigSmart",
          message
        },
        {
          excludedActivityTypes: [
            "com.apple.mobilenotes.SharingExtension",
            "com.apple.mobileslideshow.StreamShareService",
            "com.apple.reminders.RemindersEditorExtension",
            "com.apple.UIKit.activity.AddToReadingList",
            "com.apple.UIKit.activity.AssignToContact",
            "com.apple.UIKit.activity.MarkupAsPDF",
            "com.apple.UIKit.activity.OpenInIBooks",
            "com.apple.UIKit.activity.PostToFlickr",
            "com.apple.UIKit.activity.PostToVimeo",
            "com.apple.UIKit.activity.Print",
            "com.apple.UIKit.activity.SaveToCameraRoll"
          ]
        }
      );
    });
}

export function referralShare(
  appName: AppNameType,
  referralCode?: string | null | undefined
) {
  void shareConversion.track({ appName, referralCode });
  Platform.OS === "web" && requesterShare(appName, referralCode);
  shareShortLink(
    referralCode
      ? `${fallbackUrl}/signup?referredByCode=${referralCode}`
      : `${fallbackUrl}/signup`,
    (url) =>
      referralCode
        ? `Short on staff? Choose from thousands of workers that meet your businesses' needs. Download the GigSmart Get Workers app: ${url}, and use referral code ${referralCode} during Sign Up!`
        : `Find self-reliant workers.\n\nThis isn't just an app - it's a whole new way to work.\n${url}`
  );
}

export function shareJob(jobId: string) {
  shareShortLink(
    `https://gigsmart.com/workers/how-it-works?jobId=${jobId}`,
    (url) => `Check out this job on the GigSmart Job Board: ${url}`
  );
}

export async function requesterShare(
  appName: AppNameType,
  referralCode?: string | null | undefined,
  fallbackUrl = "https://gigsmart.com"
) {
  if (!domainUri || !fallbackUrl || !packageName || !apiKey) return;

  const showErrors = IS_TESTING || __DEV__;
  const newUrl = referralCode
    ? `${fallbackUrl}/signup?referredByCode=${referralCode}`
    : `${fallbackUrl}/signup`;
  const data = {
    dynamicLinkInfo: {
      domainUriPrefix: domainUri,
      link: newUrl,
      androidInfo: {
        androidPackageName: packageName,
        androidFallbackLink: fallbackUrl
      },
      iosInfo: {
        iosBundleId: packageName,
        iosFallbackLink: fallbackUrl
      }
    }
  };

  return await fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(async (res) => {
      // fail gracefully
      if (!res.ok && showErrors) {
        toast.error(res.statusText || "Error loading dynamic links");
      }
      return await res.json();
    })
    .then((json) => {
      return json?.shortLink || fallbackUrl;
    })
    .catch((err) => {
      console.error(err);
      if (showErrors) toast.error("Error loading dynamic links");
      return fallbackUrl;
    });
}

export default function showShareAlert(
  referralCode?: string | null | undefined
) {
  const buttons: AlertButton[] = [
    {
      text: "GigSmart: Get Workers",
      onPress: () => referralShare("Requester", referralCode)
    },
    {
      text: "GigSmart: Get Gigs",
      onPress: () => share("Worker")
    },
    { text: "Cancel", style: "cancel" }
  ];
  Alert.alert(
    "Share GigSmart",
    "Which GigSmart app would you like to share?",
    Platform.OS === "ios" ? buttons : buttons.reverse(),
    { cancelable: false }
  );

  Platform.OS === "web" && referralShare("Requester", referralCode);
}
