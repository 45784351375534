import React, { useMemo } from "react";

import { Button, IconText, Stack, Text } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import HomeScreenCard from "../worker/HomescreenCard";
import type { TransportationMethodCard_Worker$key } from "./__generated__/TransportationMethodCard_Worker.graphql";

interface Props {
  transportationMethod: string;
}

export function TransportationMethodCard({ transportationMethod }: Props) {
  const history = useHistory();

  const tranportationLabel = useMemo(() => {
    switch (transportationMethod) {
      case "car":
        return "Car";
      case "transit":
        return "Public Transportation";
      case "bike":
        return "Bike";
      case "walk":
        return "Walk";
    }
  }, [transportationMethod]);

  return (
    <HomeScreenCard
      headerTitle="Preferred Transportation Method"
      headerIcon="car"
      testID="profile-transportation-card"
    >
      <Stack>
        <IconText icon="check" color="success" spacing="compact">
          <Text
            color="black"
            variant="subheader"
            weight="normal"
            testID="transporation-card-selected-label"
          >
            {tranportationLabel}
          </Text>
        </IconText>
        <Button
          label="Manage Transportation Method"
          size="large"
          variant="clear"
          outline
          fullWidth
          testID="manage-transportation-method-button"
          onPress={() => history.push("/profile/transportation-method")}
        />
      </Stack>
    </HomeScreenCard>
  );
}

export default createRelayFragmentContainer<TransportationMethodCard_Worker$key>(
  graphql`
    fragment TransportationMethodCard_Worker on Worker {
      transportationMethod
    }
  `,
  ({ transportationMethod }) => {
    return (
      <TransportationMethodCard
        transportationMethod={transportationMethod ?? ""}
      />
    );
  }
);
