import { ContentArea, Divider, Stack, Surface, Text } from "@gigsmart/atorasu";
import React from "react";
import FomuRadioButtonGroup, {
  type Props as FomuRadioButtonGroupProps
} from "../fomu/inputs/FomuRadioButtonGroup";

export interface Props<T = string> extends FomuRadioButtonGroupProps<T> {
  title: string;
}

export default function RadioQuestion<T>({ title, ...restProps }: Props<T>) {
  return (
    <Surface>
      <ContentArea>
        <Stack testID="radio-question">
          <Text weight="bold" testID="radio-question-title">
            {title}
          </Text>
          <FomuRadioButtonGroup {...restProps} />
        </Stack>
        <Divider />
      </ContentArea>
    </Surface>
  );
}
