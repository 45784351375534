import { CollapsibleText, Stack, Text, Well } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  label: string;
  comment?: string;
}

export default function DisputeCommentContainer({ comment, label }: Props) {
  return (
    <Well color="neutral">
      <Stack>
        <Text weight="semibold">{label}:</Text>
        <CollapsibleText numberOfLines={2} italic testID="comment">
          {comment}
        </CollapsibleText>
      </Stack>
    </Well>
  );
}
