import { Modal, usePermission } from "@gigsmart/atorasu";
import {
  BenefitPlansOnboardingModal,
  SFCUBranded,
  SFCUGeneric
} from "@gigsmart/feature-flags";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import React, { useState, useMemo } from "react";

import { useCurrentUrl } from "@gigsmart/kaizoku";
import { useUserHasPendingConsents } from "../user-consent/UserDocumentConsentCheck";
import { DISPLAY_SFCU_MODAL, DISPLAY_STRIDE_MODAL } from "../user/user-values";
import SFCUBenefitModal from "./SFCUBenefitModal";
import StrideBenefitsModal from "./StrideBenefitsModal";

export default function BenefitsPromptCtrl() {
  const { determined: locationPermissionDetermined } = usePermission(
    "location",
    { init: "none", trace: "BenefitsPromptCtl" }
  );
  const { determined: backgroundLocationPermissionDetermined } = usePermission(
    "backgroundLocation",
    { init: "none", trace: "BenefitsPromptCtl" }
  );
  const anyLocationPermissionDetermined =
    locationPermissionDetermined || backgroundLocationPermissionDetermined;

  const { determined: notificationPermissionDetermined } = usePermission(
    "notification",
    { init: "none", trace: "BenefitsPromptCtl" }
  );

  const currentUrl = useCurrentUrl();
  const userValueKey =
    SFCUBranded.isEnabled() || SFCUGeneric.isEnabled()
      ? DISPLAY_SFCU_MODAL
      : DISPLAY_STRIDE_MODAL;
  const [shown, setShown] = useState(false);
  const [displayModal, setDisplayModal] = useUserValue<boolean>(userValueKey);
  const hasBenefitsFeature =
    BenefitPlansOnboardingModal.isEnabled() ||
    SFCUBranded.isEnabled() ||
    SFCUGeneric.isEnabled();
  const userHasPendingConsents = useUserHasPendingConsents();
  const shouldShowModal =
    notificationPermissionDetermined &&
    anyLocationPermissionDetermined &&
    hasBenefitsFeature &&
    !shown &&
    !userHasPendingConsents &&
    displayModal &&
    currentUrl === "/" &&
    process.env.IS_TESTING !== "true";

  const { eventContext, ModalComponent } = useMemo(
    () =>
      SFCUBranded.isEnabled() || SFCUGeneric.isEnabled()
        ? {
            eventContext: SFCUBranded.select(
              "SFCU Branded Modal",
              "SFCU Generic Modal"
            ),
            ModalComponent: SFCUBenefitModal
          }
        : {
            eventContext: "Benefits Plan Onboarding Modal",
            ModalComponent: StrideBenefitsModal
          },
    []
  );

  return (
    <>
      <Modal
        eventContext={eventContext}
        useModalBody={false}
        dismissable={false}
        visible={!!shouldShowModal}
      >
        <ModalComponent
          onClose={() => {
            setShown(true);
            setDisplayModal(false);
          }}
        />
      </Modal>
    </>
  );
}
