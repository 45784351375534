import { useCallback } from "react";

import { toast } from "@gigsmart/atorasu";
import { VehicleTypeSelection } from "@gigsmart/feature-flags";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import type {
  UpdateWorkerInput,
  useUpdateWorkerMutation as UseUpdateWorkerMutation
} from "./__generated__/useUpdateWorkerMutation.graphql";

export default function useUpdateWorkerMutation() {
  const [updateWorker] = useRelayMutationPromise<UseUpdateWorkerMutation>(
    graphql`
      mutation useUpdateWorkerMutation($input: UpdateWorkerInput!) {
        updateWorker(input: $input) {
          worker {
            accessState
            firstName
            lastName
            primaryMobile {
              number
              confirmed
            }
            birthdate
            parentConsent
            postalCode
            profilePhoto {
              url
            }
            skills(first: 0) {
              totalCount
            }
            transportationMethod
            transportationState
          }
        }
      }
    `,
    {
      onError: (err) => {
        console.error(err);
        toast.error(err.message);
      }
    }
  );

  return useCallback(async (input?: UpdateWorkerInput) => {
    if (!input) {
      console.warn("Must send input");
      throw new Error("invalid input");
    }

    input = { ...input };
    if (
      VehicleTypeSelection.isEnabled() &&
      input.transportationMethod &&
      input.transportationMethod !== "car"
    ) {
      input.transportationState = null;
    } else if (
      VehicleTypeSelection.isDisabled() &&
      input.transportationMethod
    ) {
      input.transportationState = null;
    }

    return await updateWorker({ input });
  }, []);
}
