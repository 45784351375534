import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React from "react";

import {
  CheckList,
  Collapsible,
  ContentArea,
  HighlightedReminder,
  Stack,
  Tag,
  Text
} from "@gigsmart/atorasu";

import type { HelpfulToDoListMutation } from "./__generated__/HelpfulToDoListMutation.graphql";
import type { HelpfulToDoList_engagement$key } from "./__generated__/HelpfulToDoList_engagement.graphql";

export function HelpfulToDoList({
  checklist,
  gig
}: FragmentContainerInnerComponentProps<HelpfulToDoList_engagement$key>) {
  const [updateCheck] = useRelayMutation<HelpfulToDoListMutation>(graphql`
    mutation HelpfulToDoListMutation($input: UpdateChecklistItemInput!) {
      updateChecklistItem(input: $input) {
        checklistItem {
          acknowledged
          id
        }
      }
    }
  `);

  const options =
    checklist?.map((item) => {
      const note =
        item?.summary.includes("How you will be paid for this Project Gig") &&
        gig?.paymentType === "DIGITAL_TRANSFER"
          ? "(Venmo, Cash App, etc.)"
          : null;
      return {
        value: item?.id ?? "",
        Component: (
          <Stack size="small">
            <Text wrap>{item?.summary}</Text>
            {!!note && (
              <Text variant="note" color="neutral">
                {note}
              </Text>
            )}
          </Stack>
        )
      };
    }) ?? [];

  const selectedValues =
    checklist?.reduce((acc: string[], item) => {
      if (!item) return acc;
      if (item?.acknowledged) return [...acc, item?.id];
      return acc;
    }, []) ?? [];

  const handleCheck = (
    _values: string[],
    selectedValue: string | undefined
  ) => {
    updateCheck({
      input: {
        checklistItemId: selectedValue ?? "",
        acknowledged: !checklist?.find((item) => item?.id === selectedValue)
          ?.acknowledged
      }
    });
  };

  const header = (
    <Stack horizontal alignItems="center" size="compact">
      <Text weight="bold" color="primary">
        Helpful To-Do List
      </Text>
      <Tag label="Recommended" />
    </Stack>
  );
  return (
    <Collapsible
      header={header}
      variant="surface"
      startCollapsed
      testID="helpful-to-do-list-collapsible"
    >
      <ContentArea>
        <Stack testID="list-container">
          <Text>
            For a smooth Project Gig experience, check off the following items
            when you and the Requester reach an agreement.
          </Text>
          <CheckList
            testID="checklist"
            options={options}
            selectedValues={selectedValues}
            onChange={handleCheck}
          />
          <HighlightedReminder
            icon="message-lines"
            header="Use in-app messaging to finalize and agree upon these details with the Requester."
          />
        </Stack>
      </ContentArea>
    </Collapsible>
  );
}

export default createRelayFragmentContainer<HelpfulToDoList_engagement$key>(
  graphql`
    fragment HelpfulToDoList_engagement on Engagement {
      checklist {
        summary
        id
        acknowledged
      }
      gig {
        paymentType
      }
    }
  `,
  HelpfulToDoList
);
