import { ContentArea, ProductInfoRow, Surface, Text } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  disputeTimesheet: boolean;
  disputeAdditional: boolean;
  onEditType: () => void;
}

export default function DisputeTypeCard({
  disputeTimesheet,
  disputeAdditional,
  onEditType
}: Props) {
  return (
    <Surface>
      <ProductInfoRow
        variant="shadow"
        testID="dispute-type-info"
        name="Dispute Type"
        icon="file-medical-alt"
        callToActionLabel="Edit"
        callToActionIcon="pen-to-square"
        callToActionOnPress={onEditType}
      />
      <ContentArea>
        <Text weight="semibold">
          • {disputeTimesheet ? "Dispute Timesheet" : ""}
          {disputeTimesheet && disputeAdditional ? (
            <Text weight="normal"> & </Text>
          ) : (
            ""
          )}
          {disputeAdditional ? "Additional Payment" : ""}
        </Text>
      </ContentArea>
    </Surface>
  );
}
