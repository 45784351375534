/**
 * @generated SignedSource<<d990280de184488f4d2219cdf6343602>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type GigPaySchedule = "FIXED" | "HOURLY" | "INFO_REQUIRED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigPaymentRows_paymentInfo$data = {
  readonly billableDuration: string;
  readonly grossPay: string;
  readonly netPay: string;
  readonly payRate: string;
  readonly paySchedule: GigPaySchedule;
  readonly serviceFees: ReadonlyArray<{
    readonly amount: string;
    readonly feeType: EngagementAddonFeeType;
    readonly hourlyRate: string | null | undefined;
  }>;
  readonly " $fragmentType": "gigPaymentRows_paymentInfo";
};
export type gigPaymentRows_paymentInfo$key = {
  readonly " $data"?: gigPaymentRows_paymentInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_paymentInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigPaymentRows_paymentInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paySchedule",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grossPay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerServiceFee",
      "kind": "LinkedField",
      "name": "serviceFees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourlyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "feeType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementPaymentInfo",
  "abstractKey": null
};

(node as any).hash = "e8b368a5d3d80064560fefed302578cf";

export default node;
