import { createScreenComponent } from "@gigsmart/atorasu";
import type { OperationType } from "@gigsmart/relay";
import React from "react";

import type { PaginatorComponent, PaginatorProps } from "./FlatListPaginator";

type Props<TOperation extends OperationType> = PaginatorProps<TOperation> & {
  testID: string;
  paginator: PaginatorComponent<TOperation>;
};

const FlatListPaginatorScreen = createScreenComponent(
  <TOperation extends OperationType>({
    paginator: Paginator,
    ...props
  }: Props<TOperation>) => <Paginator {...props} />
);

export default FlatListPaginatorScreen;
