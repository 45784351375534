import { PickupShifts } from "@gigsmart/feature-flags";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { RemainingTime } from "./RemainingTime";
import WorkerShiftCard from "./WorkerShiftCard";

import { PickupEligibleAttachment } from "./PickupEligibleAttachment";
import type { WorkerAvailableShiftCard_gigEdge$key } from "./__generated__/WorkerAvailableShiftCard_gigEdge.graphql";

interface Props {
  onPress?: (id: string) => void;
}

export const WorkerAvailableShiftCard = ({
  distance,
  estimatedPayment,
  node,
  onPress
}: FragmentContainerInnerComponentProps<
  WorkerAvailableShiftCard_gigEdge$key,
  Props
>) => {
  const isPickup = PickupShifts.isEnabled() && !!node?.pickup?.eligible;
  const date = isPickup
    ? node.pickup.windowEnd
    : node?.startsAt ?? node?.endsAt;

  return (
    <WorkerShiftCard
      distance={distance}
      netPay={estimatedPayment?.netPay}
      payRate={estimatedPayment?.payRate}
      fragmentRef={node}
      headerAttachment={isPickup ? <PickupEligibleAttachment /> : null}
      bodyHeader={<RemainingTime isPickup={isPickup} date={date} />}
      onPress={onPress}
      isPickupElligible={isPickup}
    />
  );
};

export default createRelayFragmentContainer<
  WorkerAvailableShiftCard_gigEdge$key,
  Props
>(
  graphql`
    fragment WorkerAvailableShiftCard_gigEdge on AvailableGigsEdge {
      distance
      estimatedPayment {
        payRate
        netPay
      }
      node {
        ...WorkerShiftCard_gigLike
        startsAt
        endsAt
        pickup {
          eligible
          windowEnd
        }
      }
    }
  `,
  WorkerAvailableShiftCard
);
