import {
  Button,
  Column,
  ListRow,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit } from "@gigsmart/fomu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { compact } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { ResponsiveStepperBtnPortal, type Step } from "../../shared/Stepper";
import { HourlyRateInfo } from "../HourlyRateInfo";
import { HourlyRateSummary } from "../HourlyRateSummary";
import { HourlyRateBidFields } from "./HourlyRateBidFields";
import {
  type MultiDaySelectEntry,
  createMultiDaySelectEntry,
  createMultiDaySelectEntryFromEngagement,
  createMultiDaySelectEntryFromGig
} from "./MultiDaySelectGigsStep";
import type { HourlyRateBidStepQuery } from "./__generated__/HourlyRateBidStepQuery.graphql";

type Props = {
  nodeId: string;
  isMultiShift?: boolean;
  isBid?: boolean;
};

export default createSuspendedQueryContainer<HourlyRateBidStepQuery, Props>(
  function HourlyRateBidStep({ isMultiShift, isBid, response }) {
    const { stepsData, prevStep, nextStep } = useStepper<
      Step,
      {
        hourlyBid: string | Array<{ id: string; value: string }>;
        selectedGigsData?: MultiDaySelectEntry[];
      }
    >();

    const { dates, estimatedPayment, entries } = useMemo(() => {
      const node = response?.node;
      const data = isMultiShift
        ? stepsData.selectedGigsData
        : compact(
            node?.__typename === "GigSeries"
              ? node?.availableGigs?.edges?.map(createMultiDaySelectEntry)
              : node?.__typename === "Gig"
                ? [createMultiDaySelectEntryFromGig(node)]
                : node?.__typename === "Engagement"
                  ? [createMultiDaySelectEntryFromEngagement(node)]
                  : []
          );

      return computeSelectedShiftsData(data);
    }, [stepsData.selectedGigsData]);

    useEffect(() => {
      // back to previous step if no entries (recover from inconsistent state)
      if (isMultiShift && entries.length === 0) prevStep();
    }, []);

    const handleSubmit: FomuSubmitFn = async ({ values }) => {
      const hourlyBid = values.sameBid
        ? currency.toISOString(values.hourlyBid)
        : entries.map((it) => ({
            id: it.value,
            value: currency.toISOString(values[`hourlyBid.${it.value}`])
          }));

      nextStep({ hourlyBid });
    };

    const gigLike =
      response?.node?.__typename === "Engagement"
        ? response.node.gig
        : response?.node;

    const payRate = gigLike?.payRate;
    const minPayRate = gigLike?.wageCompliance?.adjustedMinimumWage;

    const initialValues = useMemo(() => {
      const hourlyBid = currency.toFloat(payRate).toFixed(2);
      const values: {
        sameBid: boolean;
        hourlyBid: string;
        [str: `hourlyBid.${string}`]: string;
      } = { sameBid: true, hourlyBid };
      entries.forEach((it) => {
        values[`hourlyBid.${it.value}`] = hourlyBid;
      });
      return values;
    }, [payRate]);

    // inconsistent state, should not happen
    if (!entries.length) return null;

    const isMultiple = entries.length > 1;
    return (
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        <Column gap="medium">
          {isMultiple && (
            <Surface variant="outline">
              <ListRow
                numberOfLines={1}
                label={
                  <Text weight="bold" numberOfLines={1}>
                    {gigLike?.name}
                  </Text>
                }
                right={
                  <Button
                    size="small"
                    variant="clear"
                    testID="change-btn"
                    label="Change"
                    icon="pen-to-square"
                    onPress={() => prevStep()}
                  />
                }
              >
                <Text variant="note" numberOfLines={1}>
                  {pluralize(dates.length, "Shift")} &bull; {dates.join(", ")}
                </Text>
              </ListRow>
            </Surface>
          )}
          <HourlyRateSummary
            variant="requested"
            payRate={payRate}
            estimatedPayment={estimatedPayment}
          />
          <HourlyRateBidFields
            name="hourlyBid"
            basePayRate={payRate}
            minPayRate={minPayRate}
            baseEstimatedPayment={estimatedPayment}
            entries={entries}
          />
          <HourlyRateInfo />
        </Column>

        <FormSubmit>
          {({ submit, submitting, invalid }) => (
            <ResponsiveStepperBtnPortal.Entrance
              testID="next-button"
              label={isBid ? "Submit Bid" : "Continue"}
              disabled={submitting || invalid}
              onPress={submit}
            />
          )}
        </FormSubmit>
      </Form>
    );
  },
  {
    query: graphql`
      query HourlyRateBidStepQuery($id: ID!) {
        node(id: $id) {
          id
          __typename
          ... on Engagement {
            ...MultiDaySelectGigsStep_engagement
            gig {
              name
              payRate
              wageCompliance {
                adjustedMinimumWage
              }
            }
          }
          ... on Gig {
            name
            payRate
            wageCompliance {
              adjustedMinimumWage
            }
            ...MultiDaySelectGigsStep_gig
          }
          ... on GigSeries {
            name
            payRate
            wageCompliance {
              adjustedMinimumWage
            }
            availableGigs(first: 1) {
              edges {
                distance
                estimatedPayment {
                  netPay
                }
                node {
                  ...MultiDaySelectGigsStep_gig
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ nodeId }) => ({ id: nodeId })
  }
);

function computeSelectedShiftsData(raw: MultiDaySelectEntry[] = []) {
  // TODO: format entries?
  const entries = raw.map((it) => it);

  const dates: string[] = [];
  const estimatedPayment = entries.length
    ? { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY }
    : { min: 0, max: 0 };

  for (const it of entries) {
    const current = currency.toFloat(it.estimatedPayment?.netPay ?? "0 USD");
    estimatedPayment.min = Math.min(estimatedPayment.min, current);
    estimatedPayment.max = Math.max(estimatedPayment.max, current);

    dates.push(it.startsAt ? moment(it.startsAt).format("MM/DD/YY") : "ASAP");
  }

  return { estimatedPayment, dates, entries };
}
