import { CollapsibleRow } from "@gigsmart/atorasu";
import { WorkerBlockOrg } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  createSuspendedQueryContainer,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import React from "react";
import BlockOrgQuestionnaire from "./BlockOrgQuestionnaire";
import type { BlockOrganizationCollapsibleRowQuery } from "./__generated__/BlockOrganizationCollapsibleRowQuery.graphql";
import type { BlockOrganizationCollapsibleRow_shift$key } from "./__generated__/BlockOrganizationCollapsibleRow_shift.graphql";

type Props = {
  organizationId: string;
};

const VALID_STATES = new Set<EngagementStateName>([
  "APPLICATION_DENIED",
  "APPLICATION_CANCELED",
  "APPLIED",
  "BID_REQUESTED",
  "BID_REVIEW",
  "CANCELED",
  "CANCELED_WITH_PAY",
  "ENDED",
  "MISSED",
  "OFFERED",
  "REJECTED"
]);

export const showWorkerBlockOrg = (
  fragmentRef?: BlockOrganizationCollapsibleRow_shift$key | null,
  workerStateName?: EngagementStateName | null
) => {
  const node = readRelayInlineFragment(
    graphql`
      fragment BlockOrganizationCollapsibleRow_shift on GigLike @inline {
        gigType
        organization {
          id
        }
      }
    `,
    fragmentRef ?? null
  );

  return (
    WorkerBlockOrg.isEnabled() &&
    !!node?.organization?.id &&
    (!workerStateName || VALID_STATES.has(workerStateName))
  );
};

export default createSuspendedQueryContainer<
  BlockOrganizationCollapsibleRowQuery,
  Props
>(
  function BlockOrganizationCollapsibleRow({ organizationId, response }) {
    const isBlocked = !!response?.viewer?.blockedOrganizations?.totalCount;
    if (isBlocked) return null;

    return (
      <CollapsibleRow
        testID="block-worker-collapsible"
        icon="octagon-xmark"
        iconColor="textPrimary"
        title="Block Organization"
        note={"Block them to stop seeing their Shifts."}
        startCollapsed
      >
        <BlockOrgQuestionnaire
          fragmentRef={response?.viewer ?? null}
          organizationId={organizationId}
        />
      </CollapsibleRow>
    );
  },
  {
    query: graphql`
    query BlockOrganizationCollapsibleRowQuery($activeOrgEngagementsFilter: CqlFilterEngagementInput!, $blockedOrgsFilter: CqlFilterWorkerBlockedOrganizationInput!) {
      viewer {
        id
        ... on Worker {
          blockedOrganizations(first: 0, where: $blockedOrgsFilter) {
            totalCount
          }
        ...BlockOrgQuestionnaire_worker @arguments(activeOrgEngagementsFilter: $activeOrgEngagementsFilter, blockedOrgsFilter: $blockedOrgsFilter)
      }
    }
  }
  `,
    variables: ({ organizationId }) => {
      return {
        activeOrgEngagementsFilter: {
          currentStateName: {
            _in: [
              "CONFIRMING",
              "EN_ROUTE",
              "PAUSED",
              "SCHEDULED",
              "WORKING",
              "RUNNING_LATE"
            ]
          },
          organizationId: { _eq: organizationId }
        },
        blockedOrgsFilter: {
          organizationId: { _eq: organizationId }
        }
      };
    }
  }
);
