import { Platform } from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledDateInput } from "@gigsmart/katana";
import React, { type ComponentProps } from "react";

type Props = Omit<
  ComponentProps<typeof StyledDateInput>,
  "onSubmitEditing" | "value" | "onChangeText" | "errors"
> &
  FormFieldOptions;

export default function FomuDateInput({ name, validates, ...props }: Props) {
  const { setValue, value, submit, errors, showErrors } = useFormField<string>({
    name,
    validates
  });
  return (
    <StyledDateInput
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value ?? undefined}
      onChangeText={(dateValue) => setValue(dateValue)}
      onBlur={showErrors}
      errors={errors}
      {...props}
    />
  );
}
