/**
 * @generated SignedSource<<b06aa7cd908af758181547ff2c43e219>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletBalanceCard_worker$data = {
  readonly id: string;
  readonly walletBalance: string;
  readonly " $fragmentType": "WalletBalanceCard_worker";
};
export type WalletBalanceCard_worker$key = {
  readonly " $data"?: WalletBalanceCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WalletBalanceCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletBalanceCard_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "walletBalance",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "dd81809a286566c6beffbe8ff06f7123";

export default node;
