import {
  Button,
  ContentArea,
  ContentRow,
  Divider,
  IconText,
  Spacer,
  Stack,
  Surface,
  Text,
  TooltipButton
} from "@gigsmart/atorasu";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import type { JsonValue } from "@gigsmart/isomorphic-shared/user-value/use-user-value";
import { useHistory } from "@gigsmart/kaizoku";
import { graphql, readRelayInlineFragment } from "@gigsmart/relay";
import React from "react";
import { showMileageTrackingInfoModal } from "../modals/MileageTrackingInfoModal";
import type { VerifyMileage_engagement$key } from "./__generated__/VerifyMileage_engagement.graphql";
import useMileageIsValid from "./hooks/useMileageIsValid";

export const HIDE_GIG_DETAILS_MILEAGE_CARD = "hideGigDetailsMileageCard";

interface Props {
  gigEstimatedMileage?: number | null;
  gigDuration?: string | null;
  engagementId?: string | null;
  fragmentRef: VerifyMileage_engagement$key | null;
}

export function showVerifyMileage(
  hideGigDetailsMileageCard: JsonValue,
  fragmentRef: VerifyMileage_engagement$key | null
) {
  const engagement = readRelayInlineFragment(
    graphql`
      fragment VerifyMileage_engagement on Engagement @inline {
        gig {
          estimatedMileage
        }
        currentState {
          name
        }
        systemTimesheet: timesheet(variant: SYSTEM) {
          startedCount: states(first: 0, query: "WHERE action = START") {
            totalCount
          }
        }
        workerTimesheet: timesheet(variant: WORKER) {
          isOverridden
          editable
        }
      }
    `,
    fragmentRef ?? null
  );

  const neverStarted =
    engagement?.systemTimesheet?.startedCount?.totalCount === 0;
  const workerSubmittedTimesheet =
    engagement?.workerTimesheet?.isOverridden &&
    !engagement?.workerTimesheet?.editable;

  return (
    engagement?.currentState?.name === "PENDING_TIMESHEET_APPROVAL" &&
    !neverStarted &&
    !!engagement?.gig?.estimatedMileage &&
    !hideGigDetailsMileageCard &&
    !workerSubmittedTimesheet
  );
}

export default function VerifyMileage({
  gigEstimatedMileage,
  gigDuration,
  engagementId,
  fragmentRef
}: Props) {
  const [hideGigDetailsMileageCard, setHideGigDetailsMileageCard] =
    useUserValue(`${HIDE_GIG_DETAILS_MILEAGE_CARD}${engagementId}`);
  const history = useHistory();
  const mileageValid = useMileageIsValid(gigEstimatedMileage, gigDuration);

  if (!showVerifyMileage(hideGigDetailsMileageCard, fragmentRef)) return null;
  return (
    <Surface>
      <ContentArea>
        <IconText
          icon="truck-fast"
          color="primary"
          textWeight="semibold"
          spacing="compact"
        >
          Does this Total Travel / Delivery Mileage look accurate?
        </IconText>
        <Spacer />
        <Divider />
        <ContentRow justifyContent="space-between" alignItems="center">
          <Stack size="slim">
            <Stack horizontal size="compact">
              <Text weight="bold" color={mileageValid ? undefined : "danger"}>
                Total Mileage
              </Text>
              <TooltipButton
                testID="show-mileage-modal-btn"
                onPress={() => showMileageTrackingInfoModal("worker")}
              />
            </Stack>
            <Text variant="note" color={mileageValid ? "neutral" : "danger"}>
              Miles traveled while working this Shift
            </Text>
          </Stack>
          {mileageValid ? (
            <Text>{gigEstimatedMileage} miles</Text>
          ) : (
            <IconText icon="triangle-exclamation" color="danger" size="small">
              Needed
            </IconText>
          )}
        </ContentRow>
        <Divider />
        <Spacer />
        {mileageValid ? (
          <Stack horizontal fill>
            <Button
              label="No"
              fill
              outline
              size="small"
              testID="review-timesheet-mileage-btn"
              onPress={() =>
                history.push(`/gigs/${engagementId}/review-timesheet`)
              }
            />
            <Button
              label="Yes"
              fill
              outline
              size="small"
              testID="dismiss-verify-mileage-card-btn"
              onPress={async () => await setHideGigDetailsMileageCard(true)}
            />
          </Stack>
        ) : (
          <Button
            testID="review-timesheet-mileage-btn"
            label="Add Mileage & Review Timesheet"
            size="small"
            onPress={() =>
              history.push(`/gigs/${engagementId}/review-timesheet`)
            }
          />
        )}
      </ContentArea>
    </Surface>
  );
}
