/**
 * @generated SignedSource<<c0bf25cbad740da0bbc68f35207bf51e>>
 * @relayHash fe223b7fa1141330424551aee1222ba2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:FWpyWqC-Iwue7cUuwGnuG-gx5vVZCVrO26-7YDdW9LY

import { ConcreteRequest } from 'relay-runtime';
export type gigDistanceCheckWrapperQuery$variables = {
  id: string;
  isSeries: boolean;
};
export type gigDistanceCheckWrapperQuery$data = {
  readonly viewer: {
    readonly gig?: {
      readonly distance?: number | null | undefined;
      readonly workerDistance?: number | null | undefined;
    } | null | undefined;
    readonly gigSeries?: {
      readonly gigDistance: {
        readonly minDistance: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type gigDistanceCheckWrapperQuery = {
  response: gigDistanceCheckWrapperQuery$data;
  variables: gigDistanceCheckWrapperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSeries"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distance",
      "storageKey": null
    }
  ],
  "type": "AvailableGigsEdge",
  "abstractKey": null
},
v4 = {
  "condition": "isSeries",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "AvailableGigSeriesEdge",
      "kind": "LinkedField",
      "name": "gigSeries",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DistanceRange",
          "kind": "LinkedField",
          "name": "gigDistance",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minDistance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "gigDistanceCheckWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v4/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "gigDistanceCheckWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v4/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:FWpyWqC-Iwue7cUuwGnuG-gx5vVZCVrO26-7YDdW9LY",
    "metadata": {},
    "name": "gigDistanceCheckWrapperQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "cee8626a1f91b10692fff3e02069de2b";

export default node;
