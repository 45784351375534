/**
 * @generated SignedSource<<d8e5d9ab8708889509f2058423609dc3>>
 * @relayHash a8e4722367b0ab7393332e3d171668b5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:rdsURdIvq7EbJ6XrbM1LsHTI3UISjU3Qxjvj3MrHklk

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shiftGroupChatsScreenWorkerQuery$variables = Record<PropertyKey, never>;
export type shiftGroupChatsScreenWorkerQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly userMessageStats: {
      readonly unreadCount: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"shiftGroupChatsScreenWorker_viewer">;
  } | null | undefined;
};
export type shiftGroupChatsScreenWorkerQuery = {
  response: shiftGroupChatsScreenWorkerQuery$data;
  variables: shiftGroupChatsScreenWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unreadCount",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "conversationTypes",
      "value": [
        "SHIFT_GROUP"
      ]
    }
  ],
  "concreteType": "UserMessageStats",
  "kind": "LinkedField",
  "name": "userMessageStats",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": "userMessageStats(conversationTypes:[\"SHIFT_GROUP\"])"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE conversationType = SHIFT_GROUP AND hasUnreadMessages = TRUE"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "shiftGroupChatsScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "shiftGroupChatsScreenWorker_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "shiftGroupChatsScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ConversationsConnection",
            "kind": "LinkedField",
            "name": "conversations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ConversationParticipant",
                            "kind": "LinkedField",
                            "name": "participant",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserMessageStats",
                                "kind": "LinkedField",
                                "name": "messageStats",
                                "plural": false,
                                "selections": (v1/*: any*/),
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "address",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "payRate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startsAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "actualStartsAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endsAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timezone",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "area",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ShiftGroupConversation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "conversations(first:12,query:\"WHERE conversationType = SHIFT_GROUP AND hasUnreadMessages = TRUE\")"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "shfitGroupChatsScreenWorkerPaginationQuery_conversations",
            "kind": "LinkedHandle",
            "name": "conversations"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:rdsURdIvq7EbJ6XrbM1LsHTI3UISjU3Qxjvj3MrHklk",
    "metadata": {},
    "name": "shiftGroupChatsScreenWorkerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1120f1fbb2bff68d3874e576d8d0b284";

export default node;
