import path from "path";

let RNFS: typeof import("react-native-fs");
try {
  RNFS = require("react-native-fs");
} catch (err) {
  console.warn("react-native-fs module not available");
}

export const writeFile = async (fileName: string, data: string) => {
  if (!RNFS) throw new Error("Unsupported operation");

  const filePath = path.join(RNFS.CachesDirectoryPath, fileName);
  await RNFS.writeFile(filePath, data, "base64");
  return filePath;
};
