import {
  ContentArea,
  IsConstrainedProvider,
  Screen,
  ScreenHeader,
  ScreenScroll,
  ScreenTop,
  Surface,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { type BreakpointName, useStyles } from "@gigsmart/atorasu/style";
import {
  type RenderTabOptions,
  type TabConfig,
  TabItemList
} from "@gigsmart/katana";
import React, { useState } from "react";
import type { ComponentProps, ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import { StyleSheet } from "react-native";
import { Screen as RNScreen, ScreenContainer } from "react-native-screens";

export type { TabConfig };

export interface TabScreenProps<
  TKey extends string,
  T extends TabConfig<TKey> = TabConfig<TKey>
> extends Omit<ComponentProps<typeof ScreenHeader>, "title" | "variant"> {
  header?: ReactNode;
  title?: ReactNode;
  tabs?: readonly T[];
  activeTab?: TKey;
  setActiveTab?: (t: TKey) => void;
  tabItemsMaxWidth?: number;
  renderTabLabel?: (opt: RenderTabOptions<TKey, T>) => ReactNode;
  showBackButton?: boolean;
  testID: string;
  scrollable?: boolean;
  inset?: boolean;
  grow?: boolean;
  renderTab?: (tab: T, isActive: boolean) => ReactNode;
  constraint?: BreakpointName | "none";
  showTabItems?: boolean;
  children?: ReactNode;
}

export default function TabScreen<
  TKey extends string,
  T extends TabConfig<TKey> = TabConfig<TKey>
>({
  testID,
  header,
  title,
  scrollable = false,
  grow,
  tabs,
  activeTab,
  setActiveTab,
  tabItemsMaxWidth,
  renderTab,
  renderTabLabel,
  showBackButton,
  inset,
  children,
  constraint = "xxlarge",
  showTabItems = true,
  ...rest
}: TabScreenProps<TKey, T>) {
  if (showTabItems) showTabItems = !!tabs?.length && tabs.length > 1;
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const insetHeader = isMd && !!showBackButton;
  const styles = useStyles(() => ({
    container: { flex: 1, overflow: "hidden" }
  }));

  const WrapperComponent = scrollable ? ScreenScroll : Screen;

  header ??= title ? (
    <ScreenHeader title={title} variant="flat" {...rest} />
  ) : null;

  const [loaded, setLoaded] = useState(() => (activeTab ? [activeTab] : []));
  if (activeTab && !loaded.includes(activeTab)) {
    setLoaded([...loaded, activeTab]);
  }

  return (
    <WrapperComponent
      testID={testID}
      constraint={constraint}
      grow={grow}
      inset={inset}
      header={
        <IsConstrainedProvider value={insetHeader}>
          <ContentArea
            size="none"
            constraint={insetHeader ? "xxlarge" : "none"}
            variant={insetHeader ? "standard" : "none"}
            zIndex={10}
          >
            {showBackButton && <ScreenTop />}
            <Surface variant="shadow" overflow="hidden">
              {header}
              {showTabItems && !!tabs && (
                <TabItemList
                  tabs={tabs}
                  active={activeTab}
                  tabItemsMaxWidth={tabItemsMaxWidth}
                  onSelectTab={setActiveTab}
                  renderLabel={renderTabLabel}
                />
              )}
            </Surface>
          </ContentArea>
        </IsConstrainedProvider>
      }
    >
      {children ?? (
        <ScreenContainer enabled hasTwoStates style={styles.container}>
          {tabs?.map((tab) => {
            const isActive = tab.key === activeTab;
            if (!loaded.includes(tab.key) && !isActive) {
              // Don't render a lazy screen if we've never navigated to it
              return null;
            }

            return (
              <RNScreen
                key={tab.key}
                style={[
                  StyleSheet.absoluteFill,
                  // eslint-disable-next-line react-native/no-inline-styles
                  { zIndex: isActive ? 0 : -1 }
                ]}
                activityState={isActive ? 2 : 0}
                enabled
              >
                {renderTab?.(tab, isActive) ?? null}
              </RNScreen>
            );
          })}
          {tabs?.length === 0 && activeTab && (
            <RNScreen
              key={activeTab}
              style={[
                StyleSheet.absoluteFill,
                // eslint-disable-next-line react-native/no-inline-styles
                { zIndex: 0 }
              ]}
              activityState={2}
              enabled
            >
              {renderTab?.({ key: activeTab } as T, true) ?? null}
            </RNScreen>
          )}
        </ScreenContainer>
      )}
    </WrapperComponent>
  );
}
