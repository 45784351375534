/**
 * @generated SignedSource<<e19c692cd8874bebd442b9dd930c4e2e>>
 * @relayHash 7300b53239fb188d359b10cd876f1abe
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ug5vhGHAQcemREyLmLXhXxxE3-TxOjt7_NWxwZKvFHE

import { ConcreteRequest } from 'relay-runtime';
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type UpdateWorkerInput = {
  administrativeArea1?: string | null | undefined;
  allowLegalDuplicates?: boolean | null | undefined;
  biography?: string | null | undefined;
  birthdate?: string | null | undefined;
  demographic?: string | null | undefined;
  disableOtp?: boolean | null | undefined;
  firstName?: string | null | undefined;
  hubspotVid?: string | null | undefined;
  isApproved?: boolean | null | undefined;
  isAvailable?: boolean | null | undefined;
  isVolunteer?: boolean | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  minimumHourlyRate?: string | null | undefined;
  postalCode?: string | null | undefined;
  preferredTimeZone?: string | null | undefined;
  referralSource?: string | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
  transportationMethod?: string | null | undefined;
  transportationState?: WorkerTransportationState | null | undefined;
  workerId?: string | null | undefined;
};
export type ZipCodeStepWorkerMutation$variables = {
  input: UpdateWorkerInput;
};
export type ZipCodeStepWorkerMutation$data = {
  readonly updateWorker: {
    readonly worker: {
      readonly postalCode: string | null | undefined;
    };
  } | null | undefined;
};
export type ZipCodeStepWorkerMutation = {
  response: ZipCodeStepWorkerMutation$data;
  variables: ZipCodeStepWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ZipCodeStepWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ZipCodeStepWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ug5vhGHAQcemREyLmLXhXxxE3-TxOjt7_NWxwZKvFHE",
    "metadata": {},
    "name": "ZipCodeStepWorkerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5306c26427d759537f463914ff95a1af";

export default node;
