/**
 * @generated SignedSource<<06a43aaeeade6b3abfc5ea6f60ef7489>>
 * @relayHash db1424a6d18a3fc040f3ec2857daf699
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:-BSg7pAGr3f_A-w-NSHNhRX61g2HqQ0EzOHB1Yh-PEY

import { ConcreteRequest } from 'relay-runtime';
export type RemoveWorkerQualificationDriversLicenseProofInput = {
  workerQualificationProofId: string;
};
export type useDeleteCommercialDriversLicenseModalRemoveProofMutation$variables = {
  input: RemoveWorkerQualificationDriversLicenseProofInput;
};
export type useDeleteCommercialDriversLicenseModalRemoveProofMutation$data = {
  readonly removeWorkerQualificationDriversLicenseProof: {
    readonly removedWorkerQualificationProofId: string | null | undefined;
  } | null | undefined;
};
export type useDeleteCommercialDriversLicenseModalRemoveProofMutation = {
  response: useDeleteCommercialDriversLicenseModalRemoveProofMutation$data;
  variables: useDeleteCommercialDriversLicenseModalRemoveProofMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removedWorkerQualificationProofId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteCommercialDriversLicenseModalRemoveProofMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveWorkerQualificationDriversLicenseProofPayload",
        "kind": "LinkedField",
        "name": "removeWorkerQualificationDriversLicenseProof",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteCommercialDriversLicenseModalRemoveProofMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveWorkerQualificationDriversLicenseProofPayload",
        "kind": "LinkedField",
        "name": "removeWorkerQualificationDriversLicenseProof",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "removedWorkerQualificationProofId"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:-BSg7pAGr3f_A-w-NSHNhRX61g2HqQ0EzOHB1Yh-PEY",
    "metadata": {},
    "name": "useDeleteCommercialDriversLicenseModalRemoveProofMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e1339d30ee0e51d3bc3632025a33de43";

export default node;
