import { Divider, Link, Text, showModal } from "@gigsmart/atorasu";
import { getEngagementAddonSpec } from "@gigsmart/isomorphic-shared/addon/dictionary";
import {
  engagementMoneyEarnedStr,
  engagementTimeEarned,
  formatPayRate,
  getTimeWorkedString
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { DataRow } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useMemo } from "react";
import type { TotalEarnedModalQuery } from "./__generated__/TotalEarnedModalQuery.graphql";

type Props = {
  engagementId: string;
  onClose: () => void;
};

const TotalEarnedModal = createSuspendedQueryContainer<
  TotalEarnedModalQuery,
  Props
>(
  function TotalEarnedModal({ response }) {
    const engagement = response?.node;
    if (!engagement) return null;

    const useWorkerTimesheet =
      engagement?.currentState?.name === "PENDING_TIMESHEET_APPROVAL" &&
      engagement?.workerTimesheet?.paymentInfo &&
      !engagement.workerTimesheet?.editable &&
      !!engagement.workerTimesheet?.totalDurationWorked;

    const { basePay, additionalPay, totalPay, fees } = useMemo(() => {
      const currentTime = new Date();

      if (engagement?.currentState?.name === "ENDED") {
        const fees = engagement?.paymentInfo?.serviceFees?.map((fee) => ({
          type: fee?.feeType,
          amount: currency.humanize(fee.amount),
          hourlyRate: `${currency.humanize(fee?.hourlyRate)}/hr`
        }));
        return {
          totalPay: currency.humanize(engagement?.paymentInfo?.netPay),
          basePay: currency.humanize(engagement?.paymentInfo?.basePay),
          showFees:
            currency.toFloat(engagement?.paymentInfo?.totalServiceFee?.amount) >
            0,
          fees
        };
      }
      if (
        engagement?.currentState?.name === "PENDING_TIMESHEET_APPROVAL" &&
        engagement?.workerTimesheet?.paymentInfo &&
        !engagement.workerTimesheet?.editable &&
        !!engagement.workerTimesheet?.totalDurationWorked
      ) {
        const paymentInfo = engagement?.workerTimesheet?.paymentInfo;
        const secondsEarned = engagementTimeEarned({
          currentState: engagement.currentState,
          billableDuration: paymentInfo?.billableDuration,
          currentTime
        });
        const hoursEarned = secondsEarned / 3600;
        const feesTotal =
          paymentInfo?.serviceFees?.reduce((acc, fee) => {
            return (
              Number(acc) +
              Number(
                (currency.toFloat(fee?.hourlyRate) * hoursEarned).toFixed(2)
              )
            );
          }, 0) ?? 0;
        const fees = paymentInfo?.serviceFees?.map((fee) => ({
          type: fee?.feeType,
          amount: currency.humanize(
            currency.toFloat(fee?.hourlyRate) * hoursEarned
          ),
          hourlyRate: `${currency.humanize(fee?.hourlyRate)}/hr`
        }));
        return {
          totalPay: currency.humanize(`${paymentInfo?.netPay} USD`),
          basePay: currency.humanize(`${paymentInfo?.basePay} USD`),
          showFees: feesTotal > 0,
          fees
        };
      }
      const basePay = engagementMoneyEarnedStr({
        currentState: engagement.currentState,
        billableDuration: engagement.paymentInfo?.billableDuration,
        basePay: engagement.paymentInfo?.basePay,
        payRate: engagement.paymentInfo?.payRate ?? ""
      });
      const secondsEarned = engagementTimeEarned({
        currentState: engagement.currentState,
        billableDuration: engagement.paymentInfo?.billableDuration,
        currentTime
      });

      const hoursEarned = secondsEarned / 3600;
      const feesTotal =
        engagement?.paymentInfo?.serviceFees?.reduce((acc, fee) => {
          return (
            Number(acc) +
            Number((currency.toFloat(fee?.hourlyRate) * hoursEarned).toFixed(2))
          );
        }, 0) ?? 0;
      const fees = engagement?.paymentInfo?.serviceFees?.map((fee) => ({
        type: fee?.feeType,
        amount: currency.humanize(
          currency.toFloat(fee?.hourlyRate) * hoursEarned
        ),
        hourlyRate: `${currency.humanize(fee?.hourlyRate)}/hr`
      }));
      const additionalPay =
        engagement?.paymentInfo?.additionalPay &&
        currency.toFloat(engagement?.paymentInfo?.additionalPay) > 0
          ? currency.humanize(engagement?.paymentInfo?.additionalPay)
          : null;
      let totalPay = +basePay - feesTotal;
      if (totalPay < 0) totalPay = 0;
      return {
        totalPay: currency.humanize(`${totalPay} USD`),
        basePay: currency.humanize(`${basePay} USD`),
        additionalPay,
        showFees: feesTotal > 0,
        fees
      };
    }, []);

    return (
      <>
        <Divider />
        <DataRow
          label="Total Hourly Pay"
          secondaryTitle={`${formatPayRate(
            engagement.paymentInfo?.payRate,
            engagement.gigType
          )} (${getTimeWorkedString({
            billableDuration: useWorkerTimesheet
              ? engagement?.workerTimesheet?.totalDurationWorked
              : engagement.paymentInfo?.billableDuration,
            currentState: engagement.currentState,
            showSeconds: true
          })})`}
          data={basePay}
          justifyData="flex-start"
        />
        {fees?.map(({ type, amount, hourlyRate }) => {
          if (!type) return null;
          const addonSpec = getEngagementAddonSpec(type);
          return (
            <DataRow
              key={type}
              label={addonSpec.name ?? ""}
              secondaryTitle={
                <Text variant="note" color="neutral">
                  {hourlyRate}{" "}
                  {addonSpec.linkUrl ? (
                    <Link openURL={addonSpec.linkUrl}>
                      {addonSpec.linkText}
                    </Link>
                  ) : (
                    <Text color="neutral">{addonSpec.linkText}</Text>
                  )}
                </Text>
              }
              data={`- ${amount}`}
              justifyData="flex-start"
            />
          );
        })}
        {additionalPay && (
          <DataRow
            label="Additional Pay"
            data={additionalPay}
            justifyData="flex-start"
            boldLabel
            boldData
          />
        )}
        <DataRow
          label="Total Earnings"
          data={totalPay}
          justifyData="flex-start"
          boldLabel
          boldData
        />
      </>
    );
  },
  {
    query: graphql`
      query TotalEarnedModalQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            gigType
            currentState {
              name
              transitionedAt
            }
            workerTimesheet: timesheet(variant: WORKER) {
              editable
              totalDurationWorked
              paymentInfo {
                netPay
                basePay
                billableDuration
                additionalPay
                payRate
                serviceFees {
                  feeType
                  amount
                  hourlyRate
                }
                totalServiceFee {
                  amount
                }
              }
            }
            paymentInfo {
              netPay
              basePay
              billableDuration
              additionalPay
              payRate
              serviceFees {
                feeType
                amount
                hourlyRate
              }
              totalServiceFee {
                amount
              }
            }
          }
        }
      }
    `,
    variables: ({ engagementId }) => ({ engagementId })
  }
);

export function showTotalEarnedModal(engagementId: string) {
  return showModal({
    eventContext: "Total Earned Modal",
    title: "",
    useModalFooter: true,
    children: (closeFn) => (
      <TotalEarnedModal engagementId={engagementId} onClose={closeFn} />
    )
  });
}
