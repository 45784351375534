/**
 * @generated SignedSource<<032522e0f90512be1afca9a1fb01f39b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type browseGigs_availableGigs$data = {
  readonly availableGigs: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string | null | undefined;
      readonly node: {
        readonly __typename: "Gig";
        readonly id: string;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"WorkerAvailableShiftCard_gigEdge">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "browseGigs_availableGigs";
};
export type browseGigs_availableGigs$key = {
  readonly " $data"?: browseGigs_availableGigs$data;
  readonly " $fragmentSpreads": FragmentRefs<"browseGigs_availableGigs">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "availableGigs"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "crisisId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gigTypes"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./browseGigsGigsPageQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "browseGigs_availableGigs",
  "selections": [
    {
      "alias": "availableGigs",
      "args": [
        {
          "kind": "Variable",
          "name": "crisisId",
          "variableName": "crisisId"
        },
        {
          "kind": "Variable",
          "name": "gigTypes",
          "variableName": "gigTypes"
        },
        {
          "kind": "Variable",
          "name": "maxDistance",
          "variableName": "maxDistance"
        },
        {
          "kind": "Literal",
          "name": "pickupEligible",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "__browseGigsList_availableGigs_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableGigsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorkerAvailableShiftCard_gigEdge"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "bd43f1fa1c0eaf1b80772b9e4dfa3269";

export default node;
