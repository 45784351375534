import { HourlyRateBids } from "@gigsmart/feature-flags";
import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { compact } from "lodash";
import React from "react";
import ApplyGigStepper from "../gig/apply-gig-stepper/apply-gig-stepper";
import type { WorkerParamList } from "../navigation/types";
import type { engagementAcceptQuery } from "./__generated__/engagementAcceptQuery.graphql";

export default function EngagementAcceptScreen({
  route
}: AppScreenProps<WorkerParamList, "EngagementAccept">) {
  const { id, engagementsToAccept } = route.params;
  const isGig = !!id.startsWith("gig");
  if (isGig) {
    return (
      <ApplyGigStepper
        action="ACCEPT"
        isSeries={id.startsWith("gigsr")}
        gigId={id}
        engagementsToAccept={engagementsToAccept}
      />
    );
  }

  return <EngagementContainer engagementId={id} />;
}

// Engagement
const EngagementContainer = createSuspendedQueryContainer<
  engagementAcceptQuery,
  { engagementId: string }
>(
  function EngagementAccept({ response }) {
    const stateName = response?.node?.currentState?.name;
    const engagementsToAccept = compact([response?.node?.id]);

    const gigId = HourlyRateBids.select(
      response?.node?.gig?.id,
      response?.node?.gigSeries?.id
    );

    if (!gigId) return <Redirect back />;

    return (
      <ApplyGigStepper
        action={
          HourlyRateBids.isEnabled() && stateName !== "OFFERED"
            ? "BID"
            : "ACCEPT"
        }
        gigId={gigId}
        engagementsToAccept={engagementsToAccept}
        isSeries={gigId.indexOf("gigsr") === 0}
      />
    );
  },
  {
    query: graphql`
      query engagementAcceptQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            id
            currentState {
              name
            }
            gig {
              id
            }
            gigSeries {
              id
            }
          }
        }
      }
    `,
    variables: ({ engagementId }) => ({ engagementId })
  }
);
