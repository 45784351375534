import { useHistory } from "@gigsmart/kaizoku";
import { useCallback } from "react";
import { confirmPrompt, dismiss } from "../confirm-prompt";

export interface CustomPrompt {
  title: string;
  subTitle: string;
  yesLabel: string;
  cancelLabel: string;
}

const defaultPrompt = {
  title: "Are you sure you want to Cancel?",
  subTitle: "Your information will not be saved.",
  yesLabel: "Yes",
  cancelLabel: "No"
};

export default function useCancel() {
  const history = useHistory();
  return useCallback(
    (
      dirty?: boolean,
      editing?: boolean,
      firstStep?: boolean,
      customPrompt?: CustomPrompt
    ) => {
      const handleCancel = () => {
        if (editing) history.go(-1);
        else {
          history.replace({ pathname: "/", state: { safeExit: true } });
        }
      };

      if ((editing || firstStep) && !dirty) handleCancel();
      else confirmCancel(handleCancel, customPrompt);
    },
    []
  );
}

function confirmCancel(onCancel: () => void, customPrompt?: CustomPrompt) {
  confirmPrompt({
    ...(customPrompt ? { ...customPrompt } : { ...defaultPrompt }),
    actions: [
      {
        title: customPrompt?.cancelLabel ?? defaultPrompt.cancelLabel,
        onPress: dismiss
      },
      {
        title: customPrompt?.yesLabel ?? defaultPrompt.yesLabel,
        onPress: () => {
          dismiss();
          onCancel();
        }
      }
    ]
  });
}
