import React, { useState, useCallback, type ReactNode } from "react";
import { Platform, View } from "react-native";

import { useStyles } from "../style";
import PanelCardHeader from "./panel-card-header";

interface Props {
  readonly children: ReactNode;
  readonly headerContent?: ReactNode;
  readonly expandDisabled?: boolean;
  readonly testID?: string;
}

export default ({ children, headerContent, expandDisabled, testID }: Props) => {
  const { styles } = useStyles(({ color, units, namedShadows }) => ({
    container: {
      backgroundColor: color.white,
      marginHorizontal: units(4),
      borderRadius: units(1),
      ...namedShadows.card1
    },
    childCard: {
      marginVertical: 0,
      paddingVertical: units(4),
      marginHorizontal: 0,
      zIndex: 1,
      elevation: 1,
      backgroundColor: color.white,
      borderBottomLeftRadius: units(1),
      borderBottomRightRadius: units(1)
    },
    // @ts-expect-error web stuff
    childCardWebWrapper: {
      ...Platform.select({
        web: {
          transition: "height ease 0.25s",
          overflowY: "hidden"
        }
      })
    }
  }));
  const [collapsed, setCollapsed] = useState(true);
  const [childrenHeight, setChildrenHeight] = useState(0);
  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed]
  );
  const getChildrenWrapperHeight = () => {
    return collapsed ? 0 : childrenHeight + 2;
  };
  return (
    <View style={styles.container} testID={testID}>
      <PanelCardHeader
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        expandDisabled={expandDisabled}
      >
        {headerContent}
      </PanelCardHeader>

      <View
        style={[
          styles.childCardWebWrapper,
          { height: getChildrenWrapperHeight() }
        ]}
      >
        <View
          style={styles.childCard}
          onLayout={({ nativeEvent }) =>
            nativeEvent && setChildrenHeight(nativeEvent.layout.height)
          }
        >
          {children}
        </View>
      </View>
    </View>
  );
};
