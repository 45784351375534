import capitalize from "lodash/capitalize";
import React from "react";

import {
  PAYMENT_TYPES,
  type PaymentTypeKey
} from "@gigsmart/isomorphic-shared/gig/payment-types";

import { currency } from "../../iso";
import GigCardItem from "./item";

interface Props {
  gigType?: string | null;
  payRate?: string | null;
  paymentType?: PaymentTypeKey | null;
}

const GigCardPayRateItem = ({ gigType, payRate, paymentType }: Props) => {
  return gigType === "PROJECT" ? (
    <GigCardItem
      testID="payment-type"
      iconName={PAYMENT_TYPES[paymentType ?? "NO_PREFERENCE"].icon}
    >
      Paying Workers via{" "}
      {PAYMENT_TYPES[paymentType ?? "NO_PREFERENCE"].shortLabel}
    </GigCardItem>
  ) : (
    <GigCardItem
      testID="pay-rate-item"
      iconName={gigType === "VOLUNTEER" ? "hands-heart" : "dollar-sign"}
    >
      {gigType === "PAID"
        ? `${currency.humanize(payRate)}/hr`
        : capitalize(gigType ?? "")}
    </GigCardItem>
  );
};

export default GigCardPayRateItem;
