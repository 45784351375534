import { useQueryParams } from "@gigsmart/kaizoku";
import { graphql, useFetchQueryResult } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import { useMemo } from "react";

import type { browseGigsSeriesQuery } from "./__generated__/browseGigsSeriesQuery.graphql";
import type { browseGigs_availableGigSeries$key } from "./__generated__/browseGigs_availableGigSeries.graphql";

export type AvailableGigEdgeType = NonNullable<
  ObjectPath<
    browseGigs_availableGigSeries$key,
    [" $data", "availableSeries", "edges", 0]
  >
>;

export type BrowseGigsFragmentType$key = browseGigs_availableGigSeries$key;

// **TODO: move to a common folder
export const useBrowseGigsQuery = (isPickupShifts?: boolean) => {
  const querySpec = graphql`
    query browseGigsSeriesQuery($hasCrisisId: Boolean!, $crisisId: ID!) {
      ... on RootQueryType @include(if: $hasCrisisId) {
        crisis: node(id: $crisisId) {
          ... on Crisis {
            id
            title
          }
        }
      }
    }
  `;

  const fragmentSpec = graphql`
    fragment browseGigs_availableGigSeries on Worker
    @refetchable(queryName: "browseGigsGigSeriesPageQuery")
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      after: { type: "String" }
      maxDistance: { type: "Int" }
      crisisId: { type: "ID" }
      searchTerm: { type: "String" }
      gigTypes: { type: "[GigType!]" }
    ) {
      availableSeries: availableGigSeries(
        first: $count
        after: $after
        input: {
          crisisId: $crisisId
          maxDistance: $maxDistance
          searchTerm: $searchTerm
          gigTypes: $gigTypes
        }
      ) @connection(key: "browseGigsList_availableSeries", filters: ["input"]) {
        edges {
          cursor
          node @trackImpressions(action: ACCESSED) {
            __typename
            id
          }
          ...WorkerAvailableSeriesCard_seriesEdge
        }
      }
    }
  `;

  const pickupShiftsFragmentSpec = graphql`
    fragment browseGigs_availableGigs on Worker
    @refetchable(queryName: "browseGigsGigsPageQuery")
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      after: { type: "String" }
      maxDistance: { type: "Int" }
      crisisId: { type: "ID" }
      searchTerm: { type: "String" }
      gigTypes: { type: "[GigType!]" }
    ) {
      availableGigs: availableGigs(
        first: $count
        after: $after
        crisisId: $crisisId
        maxDistance: $maxDistance
        searchTerm: $searchTerm
        gigTypes: $gigTypes
        pickupEligible: true
      ) @connection(key: "browseGigsList_availableGigs") {
        edges {
          cursor
          node @trackImpressions(action: ACCESSED) {
            __typename
            id
          }
          ...WorkerAvailableShiftCard_gigEdge
        }
      }
    }
  `;

  const queryParams = useQueryParams<{ crisisId?: string } | null>();
  const initialVars = useMemo(
    () => ({
      hasCrisisId: !!queryParams?.crisisId,
      crisisId: queryParams?.crisisId || ""
    }),
    []
  );

  const [{ crisis = null } = {}] = useFetchQueryResult<browseGigsSeriesQuery>(
    querySpec,
    {
      variables: initialVars
    }
  );

  return {
    crisis,
    fragmentInput: isPickupShifts ? pickupShiftsFragmentSpec : fragmentSpec
  } as const;
};
