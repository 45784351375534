/**
 * @generated SignedSource<<d1868432bcea8c3fd998af1bd7c9e0a8>>
 * @relayHash 624d44bc28d1e4c3d40b020decba913d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:RFJCwc5P6idFk-f1o1LY4fdIUadRCghnXGkkRwni6Kk

import { ConcreteRequest } from 'relay-runtime';
export type SetUserMobileInput = {
  confirmed?: boolean | null | undefined;
  escalationCode?: string | null | undefined;
  number?: string | null | undefined;
  userId?: string | null | undefined;
};
export type MobileNumberStepUpdateMobileNumberMutation$variables = {
  input: SetUserMobileInput;
};
export type MobileNumberStepUpdateMobileNumberMutation$data = {
  readonly setUserMobile: {
    readonly user: {
      readonly pendingConsents: ReadonlyArray<{
        readonly body: string;
        readonly filename: string;
      }>;
      readonly primaryMobile: {
        readonly confirmed: boolean;
        readonly number: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type MobileNumberStepUpdateMobileNumberMutation = {
  response: MobileNumberStepUpdateMobileNumberMutation$data;
  variables: MobileNumberStepUpdateMobileNumberMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalDocument",
  "kind": "LinkedField",
  "name": "pendingConsents",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileNumberStepUpdateMobileNumberMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserMobilePayload",
        "kind": "LinkedField",
        "name": "setUserMobile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMobile",
                "kind": "LinkedField",
                "name": "primaryMobile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MobileNumberStepUpdateMobileNumberMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserMobilePayload",
        "kind": "LinkedField",
        "name": "setUserMobile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMobile",
                "kind": "LinkedField",
                "name": "primaryMobile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:RFJCwc5P6idFk-f1o1LY4fdIUadRCghnXGkkRwni6Kk",
    "metadata": {},
    "name": "MobileNumberStepUpdateMobileNumberMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "94c713300eac164ad96ab09b83eaa988";

export default node;
