import React, { type ReactNode, type ComponentProps } from "react";

import { FlexRow } from "../flex";
import { useStyles } from "../style";

type Props = ComponentProps<typeof FlexRow> & {
  children: ReactNode;
  noBorder?: boolean;
};

const BaseRow = ({ style, children, noBorder = false, ...props }: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    row: {
      backgroundColor: color.white,
      paddingHorizontal: units(4),
      paddingVertical: units(3),
      minHeight: 52,
      borderBottomWidth: 1,
      borderBottomColor: color.neutralLight
    },
    noBorder: {
      borderBottomWidth: 0
    }
  }));
  return (
    <FlexRow
      style={[styles.row, noBorder && styles.noBorder, style]}
      justifyContent="space-between"
      {...props}
    >
      {children}
    </FlexRow>
  );
};

export default BaseRow;
