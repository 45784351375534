/**
 * @generated SignedSource<<8eaa2172dd3761c4c340067deb7321dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigPaySchedule = "FIXED" | "HOURLY" | "INFO_REQUIRED" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type seriesSummaryCard_series$data = {
  readonly area: string | null | undefined;
  readonly category: {
    readonly displayName: string;
  } | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly hasMultipleLocations: boolean;
  readonly id: string;
  readonly nextOffered: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currentBid: {
          readonly estimatedAmount: string | null | undefined;
          readonly expectedDuration: string | null | undefined;
          readonly note: string | null | undefined;
          readonly payRate: string | null | undefined;
          readonly paySchedule: GigPaySchedule;
        } | null | undefined;
        readonly gig: {
          readonly payRate: string | null | undefined;
          readonly place: {
            readonly area: string | null | undefined;
            readonly location: {
              readonly latitude: number;
              readonly longitude: number;
            } | null | undefined;
          } | null | undefined;
          readonly placeId: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftScheduleSummaryRow_gig" | "projectSummaryDateTime_gig">;
        };
        readonly workerDistance: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftScheduleSummaryRow_engagement" | "gigPaymentRows_engagement">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly position: {
    readonly name: string;
  } | null | undefined;
  readonly skills: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly skill: {
          readonly name: string;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "seriesSummaryCard_series";
};
export type seriesSummaryCard_series$key = {
  readonly " $data"?: seriesSummaryCard_series$data;
  readonly " $fragmentSpreads": FragmentRefs<"seriesSummaryCard_series">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "engagementsQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "seriesSummaryCard_series",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasMultipleLocations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "GigSeriesSkillsConnection",
      "kind": "LinkedField",
      "name": "skills",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GigSeriesSkillsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSeriesSkill",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Skill",
                  "kind": "LinkedField",
                  "name": "skill",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "skills(first:10)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigPosition",
      "kind": "LinkedField",
      "name": "position",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "nextOffered",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "engagementsQuery"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Engagement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorkerShiftScheduleSummaryRow_engagement"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "gigPaymentRows_engagement"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workerDistance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementBid",
                  "kind": "LinkedField",
                  "name": "currentBid",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimatedAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expectedDuration",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "note",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paySchedule",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "gig",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "WorkerShiftScheduleSummaryRow_gig"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "projectSummaryDateTime_gig"
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Place",
                      "kind": "LinkedField",
                      "name": "place",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Location",
                          "kind": "LinkedField",
                          "name": "location",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "latitude",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "longitude",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "placeId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "GigSeries",
  "abstractKey": null
};
})();

(node as any).hash = "f485ee86ae551832fda65baaedc42da7";

export default node;
