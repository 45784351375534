import { isEmpty, sortBy } from "lodash";
import React, { useMemo } from "react";

import {
  CheckboxRow,
  Divider,
  type IconName,
  MultiSelectHeader,
  MultiSelectRow,
  RadioButtonGroup,
  Stack,
  type StepProps,
  StepSubmit,
  Text
} from "@gigsmart/atorasu";
import {
  BenefitPlansOnboardingModal,
  VehicleTypeSelection
} from "@gigsmart/feature-flags";
import { Form, FormField, Validator, useFormValue } from "@gigsmart/fomu";
import { CompletedOnboardingConversion } from "@gigsmart/isomorphic-shared/onboarding/conversions";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import {
  makeWorkerGigFieldsVariables,
  useWorkerGigFields
} from "@gigsmart/seibutsu/category-position-qualification/hooks/useWorkerGigFields";
import type {
  UpdateWorkerInput,
  WorkerTransportationState
} from "@gigsmart/seibutsu/user/Onboarding/hooks/__generated__/useUpdateWorkerMutation.graphql";
import { underscore } from "inflected";
import { DISPLAY_SFCU_MODAL, DISPLAY_STRIDE_MODAL } from "../user-values";
import type { TransportationStepQuery } from "./__generated__/TransportationStepQuery.graphql";
import useUpdateWorkerMutation from "./hooks/use-update-worker-mutation";
import type { OnboardingData } from "./types";

const TRANSPORTATION_OPTIONS: Array<{
  title: string;
  value: string;
  icon?: IconName;
  testID: string;
  eventTargetName: string;
}> = [
  {
    title: "Car",
    value: "car",
    icon: "car",
    testID: "car",
    eventTargetName: "Car Option Radio"
  },
  {
    title: "Public Transportation",
    value: "transit",
    icon: "bus",
    testID: "bus",
    eventTargetName: "Public Transportation Option Radio"
  },
  {
    title: "Bike",
    value: "bike",
    icon: "bicycle",
    testID: "bicycle",
    eventTargetName: "Bike Option Radio"
  },
  {
    title: "Walk",
    value: "walk",
    icon: "walking",
    testID: "walking",
    eventTargetName: "Walking Option Radio"
  }
];

export const TRANSPORTATION_STATE_OPTIONS = [
  { label: "Sedan", value: "SEDAN" },
  { label: "SUV", value: "SUV" },
  { label: "Truck", value: "TRUCK" },
  { label: "Van", value: "VAN" },
  { label: "Motorcycle", value: "MOTORCYCLE" }
];

type Props = StepProps<OnboardingData>;

export default createSuspendedQueryContainer<TransportationStepQuery, Props>(
  function TransportationStepWorkerGigFieldsSuspenseWrapper({ response }) {
    const updateWorker = useUpdateWorkerMutation();
    const { viewer, WorkerTransportationState } = response ?? {};
    const { gigFieldOptions, checkOption } = useWorkerGigFields(viewer);
    const [, setDisplayStrideBenefitsModal] =
      useUserValue<boolean>(DISPLAY_STRIDE_MODAL);
    const [, setDisplaySfcuModal] = useUserValue<boolean>(DISPLAY_SFCU_MODAL);
    const existingUserIncompleteInfo =
      VehicleTypeSelection.isEnabled() &&
      viewer?.transportationMethod === "car" &&
      !viewer.transportationState;

    const initialData = useMemo(
      () => ({
        transportationMethod: viewer?.transportationMethod ?? "car",
        transportationState: Object.fromEntries(
          (gigFieldOptions["Vehicle Requirements"] ?? []).map((item) => [
            item.id,
            item.value
          ])
        )
      }),
      [viewer?.transportationMethod, gigFieldOptions]
    );

    return (
      <Form
        initialValues={initialData}
        onSubmit={async ({ values }) => {
          if (
            BenefitPlansOnboardingModal.isEnabled() &&
            !existingUserIncompleteInfo
          ) {
            void setDisplayStrideBenefitsModal(true);
            void setDisplaySfcuModal(true);
          }

          const updateInput: UpdateWorkerInput = {
            transportationMethod: values.transportationMethod
          };
          const fields: Record<string, boolean> = {
            ...values.transportationState,
            ...values.travelAndDelivery
          };
          const tasks: Array<Promise<void>> = [];
          for (const id in fields) {
            tasks.push(checkOption({ id, value: !!fields[id] }));
          }
          await Promise.all(tasks);

          updateInput.transportationState = gigFieldOptions[
            "Vehicle Requirements"
          ]
            ?.filter((item) =>
              isValidTransportationState(
                WorkerTransportationState?.enumValues ?? [],
                item
              )
            )
            ?.find((item) =>
              Object.keys(values.transportationState).includes(item.id)
            )
            ?.label.toUpperCase() as WorkerTransportationState;

          await updateWorker(updateInput);
          void CompletedOnboardingConversion.track();
        }}
      >
        <Stack>
          <Text>
            {existingUserIncompleteInfo
              ? "If your transportation method has changed, you can update it now."
              : "This can be changed at any time."}
          </Text>
          <FormField
            name="transportationMethod"
            validates={Validator.presence()}
          >
            {({ value, setValue }) => (
              <>
                <RadioButtonGroup
                  onChange={setValue}
                  buttons={TRANSPORTATION_OPTIONS}
                  value={value}
                />
                <Divider />
              </>
            )}
          </FormField>
          <TransportationType
            gigFieldOptions={gigFieldOptions}
            validValues={WorkerTransportationState?.enumValues ?? []}
          />
          <StepSubmit
            testID="next-btn"
            label={existingUserIncompleteInfo ? "Save" : "Get Started"}
          />
        </Stack>
      </Form>
    );
  },
  {
    variables: makeWorkerGigFieldsVariables(
      "Vehicle Requirements",
      "Travel / Delivery"
    ),
    query: graphql`
      query TransportationStepQuery($query: String!, $first: Int!) {
        viewer {
          ... on Worker {
            ...useWorkerGigFields_worker
              @arguments(query: $query, first: $first)
            transportationMethod
            transportationState
          }
        }
        WorkerTransportationState: __type(name: "WorkerTransportationState") {
          enumValues {
            name
          }
        }
      }
    `
  }
);

interface TransportationTypeProps {
  gigFieldOptions: Record<
    string,
    Array<{ value: boolean | null; id: string; label: string }>
  >;
  validValues: ReadonlyArray<{ readonly name: string }>;
}
const hasAtleastOneValidProp = Validator.create<
  {
    message?: string;
  },
  string
>(({ message }) => (_field, value) => {
  if (isEmpty(value)) return null;
  if (!Object.values(value).some((val) => !!val)) {
    return [new Error(message ?? "Object must have atleast one valid value")];
  }
  return null;
});

function TransportationType({
  gigFieldOptions,
  validValues
}: TransportationTypeProps) {
  const { value: transportationMethod } = useFormValue({
    name: "transportationMethod"
  });
  if (transportationMethod !== "car") return null;

  return (
    <Stack>
      <Stack size="medium">
        <Text variant="header">
          What type of vehicle(s) do you have access to?
        </Text>
        <Text>
          Certain Gigs may require specific vehicles. At least one type of
          vehicle must be selected if you set your transportation method to Car.
        </Text>
        <FormField
          name="transportationState"
          validates={[
            Validator.presence({
              message: "You must select Type of Car before continuing."
            }),
            hasAtleastOneValidProp({
              message: "You must select Type of Car before continuing."
            })
          ]}
        >
          {({ value, setValue }) => (
            <>
              <Stack size="compact">
                {sortBy(gigFieldOptions["Vehicle Requirements"] ?? [], (item) =>
                  item.label.toLowerCase()
                )
                  .filter((item) =>
                    isValidTransportationState(validValues, item)
                  )
                  .map((option) => (
                    <CheckboxRow
                      title={option.label}
                      testID="vehicle-checkbox"
                      key={String(option.id)}
                      selected={value?.[option.id]}
                      onChange={(v) => {
                        setValue({
                          ...(value ?? {}),
                          [option.id]: v
                        });
                      }}
                    />
                  ))}
              </Stack>
            </>
          )}
        </FormField>
      </Stack>
      <Stack size="slim">
        <Stack size="compact">
          <Text variant="header" color="primary">
            Will you work Gigs that have the following?
          </Text>
          <Text>
            Certain Gigs may require you to travel and/or make deliveries.
          </Text>
        </Stack>
        <>
          <MultiSelectHeader
            testID="travel-and-delivery"
            options={["NO", "YES"]}
          />
          <Stack size="compact">
            <FormField
              name="travelAndDelivery"
              validates={Validator.presence()}
            >
              {({ value, setValue }) => (
                <>
                  {(gigFieldOptions["Travel / Delivery"] ?? []).map(
                    (option, index) => (
                      <MultiSelectRow
                        key={`option-${index}`}
                        testID={`traveldelivery-option-${index}`}
                        title={option.label}
                        onChange={(v) => {
                          setValue({
                            ...(value ?? {}),
                            [option.id]: v
                          });
                        }}
                        options={[
                          {
                            testID: `traveldelivery-option-${index}-no`,
                            value: false
                          },
                          {
                            testID: `traveldelivery-option-${index}-yes`,
                            value: true
                          }
                        ]}
                        value={value?.[option.id]}
                      />
                    )
                  )}
                </>
              )}
            </FormField>
          </Stack>
        </>
      </Stack>
    </Stack>
  );
}

const isValidTransportationState = (
  enumValues: ReadonlyArray<{ readonly name: string }>,
  item: {
    label: string;
  }
) => {
  const names = enumValues?.map(({ name }) => name) ?? [];
  const value = underscore(item.label)?.toUpperCase();
  const has = names.includes(value);
  return has;
};
