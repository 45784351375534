/**
 * @generated SignedSource<<e5172e85d7ee7e91877912b46de010c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ConversationCapability = "CLOSE" | "JOIN" | "LEAVE" | "OPEN" | "%future added value";
export type ConversationStatus = "CLOSED" | "OPEN" | "%future added value";
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminMoreMenu_conversationLike$data = {
  readonly __typename: string;
  readonly capabilities?: ReadonlyArray<ConversationCapability>;
  readonly engagement?: {
    readonly worker: {
      readonly id: string;
    };
  };
  readonly gig?: {
    readonly conversationRule: GigConversationRule;
    readonly currentState: {
      readonly name: GigStateName;
    };
    readonly id: string;
  };
  readonly id?: string;
  readonly organization?: {
    readonly conversationSettings: {
      readonly allowConversationStatusUpdate: boolean;
    };
  };
  readonly participant: {
    readonly id: string;
    readonly readUntil: string | null | undefined;
  } | null | undefined;
  readonly status: ConversationStatus;
  readonly worker?: {
    readonly id: string;
  };
  readonly " $fragmentType": "AdminMoreMenu_conversationLike";
};
export type AdminMoreMenu_conversationLike$key = {
  readonly " $data"?: AdminMoreMenu_conversationLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminMoreMenu_conversationLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminMoreMenu_conversationLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConversationParticipant",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readUntil",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capabilities",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationConversationSettings",
              "kind": "LinkedField",
              "name": "conversationSettings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowConversationStatusUpdate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "type": "OrganizationWorkerConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Engagement",
          "kind": "LinkedField",
          "name": "engagement",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "EngagementConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conversationRule",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigState",
              "kind": "LinkedField",
              "name": "currentState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ShiftGroupConversation",
      "abstractKey": null
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};
})();

(node as any).hash = "d3c4d5e088e4204b3f59ebf9b9d68dd5";

export default node;
