import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";
import React from "react";
import { useQualifications } from "./hooks/useQualifications";

export default function ExperienceScreen() {
  const {
    title,
    subTitle,
    reminderText,
    iconName,
    options,
    onBackPress,
    checkOption
  } = useQualifications({ qualificationTitle: "Experience" });

  if (!options || options?.length === 0) return null;

  return (
    <ScreenScroll testID="worker-experience-screen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="worker-experience-screen-layout"
          header={title ?? ""}
          note={subTitle}
          searchHeader="Do you have the following Experience?"
          searchPlaceholder="Search Experience"
          selectionRowHeader="Experience"
          selectionRowHeaderIcon={iconName}
          reminder={reminderText}
          options={options}
          onCheckOption={checkOption}
        />
      </ContentArea>
    </ScreenScroll>
  );
}
