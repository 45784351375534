import type { IconName } from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { asEnum, eq, where } from "@gigsmart/biruda";
import {
  type UseMutationCommitFunctionType,
  graphql,
  useFetchQueryResult,
  useRelayMutation
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils/src";
import type { useQualificationsMutation } from "./__generated__/useQualificationsMutation.graphql";
import type {
  WorkerQualificationProofStatus,
  useQualificationsQuery
} from "./__generated__/useQualificationsQuery.graphql";

interface Props {
  qualificationTitle: string;
}

interface Option {
  id?: string;
  label?: string;
  value?: boolean | null | undefined;
}

type fieldItems = ReadonlyArray<
  | {
      readonly node:
        | {
            readonly id: string;
            readonly label: string;
          }
        | null
        | undefined;
    }
  | null
  | undefined
>;

export type CheckOptionArgs = Option & {
  checkSuccessMessage?: string;
  onCompleted?: (
    v: ObjectPath<
      useQualificationsMutation,
      [
        "response",
        "setWorkerQualification",
        "newWorkerQualificationEdge",
        "node"
      ]
    >
  ) => void;
};

export interface UseQualificationsResult {
  title: string;
  subTitle: string;
  reminderText: string;
  iconName: IconName;
  onBackPress: () => void;
  workerId: string;
  options: Option[];
  refetch: () => void;
  setQualification: UseMutationCommitFunctionType<useQualificationsMutation>;
  checkOption: (v: CheckOptionArgs) => void;
  proofId?: string;
  proofStatus?: WorkerQualificationProofStatus;
}

export const useQualifications = ({
  qualificationTitle
}: Props): UseQualificationsResult => {
  const fieldsQuery = where({ title: qualificationTitle }).toString();
  const selectedItemsQuery = where({ status: asEnum("CONFIRMED") }).toString();
  const deniedItemsQuery = where({ status: asEnum("DENIED") }).toString();
  const availableItemsQuery = where({
    status: asEnum("UNKNOWN"),
    deletedAt: eq(null)
  }).toString();

  const [{ viewer = null } = {}, { retry }] =
    useFetchQueryResult<useQualificationsQuery>(
      graphql`
        query useQualificationsQuery(
          $fieldsQuery: String!
          $selectedItemsQuery: String!
          $deniedItemsQuery: String!
          $availableItemsQuery: String!
        ) {
          viewer {
            ... on Worker {
              id
              qualificationProofs(
                first: 1
                query: "WHERE type = DRIVERS_LICENSE"
              ) @connection(key: "useQualifications___qualificationProofs") {
                totalCount
                edges {
                  node {
                    ... on WorkerQualificationDriversLicenseProof {
                      id
                      status
                    }
                  }
                }
              }
              gigFields(first: 1, query: $fieldsQuery) {
                edges {
                  node {
                    title
                    iconName
                    selectedDefinitions: itemDefinitions(
                      first: 40
                      query: $selectedItemsQuery
                    ) {
                      edges {
                        node {
                          label
                          id
                        }
                      }
                    }
                    deniedDefinitions: itemDefinitions(
                      first: 40
                      query: $deniedItemsQuery
                    ) {
                      edges {
                        node {
                          label
                          id
                        }
                      }
                    }
                    availableDefinitions: itemDefinitions(
                      first: 40
                      query: $availableItemsQuery
                    ) {
                      edges {
                        node {
                          label
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        variables: {
          fieldsQuery,
          selectedItemsQuery,
          deniedItemsQuery,
          availableItemsQuery
        },
        fetchPolicy: "network-only"
      }
    );

  const [setQualificationMutation] =
    useRelayMutation<useQualificationsMutation>(graphql`
      mutation useQualificationsMutation($input: SetWorkerQualificationInput!) {
        setWorkerQualification(input: $input) {
          newWorkerQualificationEdge {
            node {
              id
              gigFieldItemDefinition {
                id
                label
              }
            }
          }
        }
      }
    `);

  const workerId = viewer?.id ?? "";
  const category = viewer?.gigFields?.edges?.[0]?.node;

  const onBackPress = () => retry();

  const parseOptions = (
    items: fieldItems,
    isSelected: boolean | null | undefined
  ): Option[] => {
    if (!items?.length) {
      return [];
    }

    return items.map((item) => ({
      id: item?.node?.id ?? "",
      label: item?.node?.label ?? "",
      value: isSelected
    }));
  };

  const computeOptions = () => {
    const selectedItems = category?.selectedDefinitions?.edges ?? [];
    const deniedItems = category?.deniedDefinitions?.edges ?? [];
    const availableItems = category?.availableDefinitions?.edges ?? [];

    const options: Option[] = parseOptions(selectedItems, true)
      .concat(parseOptions(deniedItems, false))
      .concat(parseOptions(availableItems, null))
      .sort((a, b) => a?.label?.localeCompare(b?.label ?? "") ?? 0);

    return options;
  };

  const checkOption = ({ value, id, onCompleted }: CheckOptionArgs) => {
    setQualificationMutation(
      {
        input: {
          status: value ? "CONFIRMED" : "DENIED",
          gigFieldItemDefinitionId: id ?? "",
          workerId
        }
      },
      {
        onError: () => {
          toast.error("Something went wrong");
          retry();
        },
        onCompleted: (res) => {
          onCompleted?.(
            res.setWorkerQualification?.newWorkerQualificationEdge?.node
          );
        }
      }
    );
  };

  return {
    title: category?.title ?? "",
    subTitle:
      "Shift Gigs may require specific Qualifications to work them. Select the Qualifications you meet to save them to your Profile.",
    reminderText:
      "Adding Qualifications to your profile that you do not have may result in cancellation from the Shift by the Organization.",
    iconName: category?.iconName as IconName,
    onBackPress,
    workerId,
    options: computeOptions(),
    refetch: retry,
    setQualification: setQualificationMutation,
    proofId: viewer?.qualificationProofs?.edges?.[0]?.node?.id,
    proofStatus: viewer?.qualificationProofs?.edges?.[0]?.node?.status,
    checkOption
  };
};
