import { ContentArea, ScreenScroll, Stack, Text } from "@gigsmart/atorasu";
import { asEnum, inList, where } from "@gigsmart/biruda";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import DirectPaymentCollapsibleCard from "@gigsmart/seibutsu/direct-hire/worker/DirectHirePaymentCollapsibleCard";
import DirectPaymentsAndHiresListSurface from "@gigsmart/seibutsu/direct-hire/worker/DirectPaymentsAndHiresListSurface";
import React from "react";
import type { directPaymentsAndHiresScreenWorkerQuery } from "./__generated__/directPaymentsAndHiresScreenWorkerQuery.graphql";

const APPROVED_QUERY = where({
  currentState: inList([asEnum("DISBURSED"), asEnum("CHARGED")]),
  currentStateAction: inList([asEnum("ACCEPT"), asEnum("ACKNOWLEDGE")])
})
  .orderBy("chargeCapturedAt", "DESC")
  .toString();
const PENDING_QUERY = where({
  currentState: asEnum("PUBLISHED"),
  currentStateAction: asEnum("PUBLISH")
})
  .orderBy("currentStateTransitionedAt", "DESC")
  .toString();
const REJECTED_QUERY = where({
  currentState: asEnum("REJECTED"),
  currentStateAction: asEnum("REJECT")
})
  .or({
    currentState: asEnum("REJECTED"),
    currentStateAction: asEnum("TIMEOUT")
  })
  .or({ currentState: asEnum("CLOSED"), currentStateAction: asEnum("TIMEOUT") })
  .or({ currentState: asEnum("ESCALATED") })
  .orderBy("currentStateTransitionedAt", "DESC")
  .toString();

export default createSuspendedQueryContainer<directPaymentsAndHiresScreenWorkerQuery>(
  function DirectPaymentsAndHiresScreen({ response: result }) {
    return (
      <ScreenScroll testID="direct-payments-and-hires-screen">
        <ContentArea>
          <Text variant="header" align="center">
            {DirectPaymentsOnly.select(
              "Direct Payments",
              "Direct Payments & Hires"
            )}
          </Text>
        </ContentArea>
        <Stack>
          <DirectPaymentCollapsibleCard fragmentRef={result?.viewer} />
          {result?.pendingCount?.totalCount !== 0 && (
            <DirectPaymentsAndHiresListSurface
              query={PENDING_QUERY}
              variant="PENDING"
              count={result?.pendingCount?.totalCount}
              directHireBonus={result?.viewer?.directHireBonus}
            />
          )}
          {result?.approvedHiresCount?.totalCount !== 0 && (
            <DirectPaymentsAndHiresListSurface
              query={APPROVED_QUERY}
              variant="APPROVED"
              count={result?.approvedHiresCount?.totalCount}
              directHireBonus={result?.viewer?.directHireBonus}
            />
          )}
          {result?.rejectedCount?.totalCount !== 0 && (
            <DirectPaymentsAndHiresListSurface
              query={REJECTED_QUERY}
              variant="REJECTED"
              count={result?.rejectedCount?.totalCount}
              directHireBonus={result?.viewer?.directHireBonus}
            />
          )}
        </Stack>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query directPaymentsAndHiresScreenWorkerQuery(
        $approvedQuery: String!
        $pendingQuery: String!
        $rejectedQuery: String!
      ) {
        viewer {
          ... on Worker {
            ...DirectHirePaymentCollapsibleCard_Worker
            directHireBonus
          }
        }
        approvedHiresCount: directHireClaims(first: 0, query: $approvedQuery) {
          totalCount
        }
        pendingCount: directHireClaims(first: 0, query: $pendingQuery) {
          totalCount
        }
        rejectedCount: directHireClaims(first: 0, query: $rejectedQuery) {
          totalCount
        }
      }
    `,
    variables: {
      approvedQuery: APPROVED_QUERY,
      pendingQuery: PENDING_QUERY,
      rejectedQuery: REJECTED_QUERY
    }
  }
);
