import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import { View } from "react-native";

import { Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { InfiniteList } from "@gigsmart/isomorphic-shared/list";
import { EmptyView } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";

import type { requesterReviewsPageQuery } from "./__generated__/requesterReviewsPageQuery.graphql";
import type { requesterReviewsQuery } from "./__generated__/requesterReviewsQuery.graphql";
import type { requesterReviews_requester$key } from "./__generated__/requesterReviews_requester.graphql";
import RequesterReviewRow from "./requester-review-row";

type ReviewEdgeType = NonNullable<
  ObjectPath<
    requesterReviews_requester$key,
    [" $data", "reviewsReceived", "edges", 0]
  >
>;

interface Props {
  requesterId: string;
}

export default createSuspendedQueryContainer<requesterReviewsQuery, Props>(
  function RequesterReviews({ response: { node } = {} }) {
    const styles = useStyles(({ getColor, getFontSize }) => ({
      header: {
        color: getColor("primary", "fill"),
        ...getFontSize(6),
        padding: 20,
        textAlign: "center"
      },
      bordered: {
        borderBottomWidth: 2
      },
      flex1: {
        flex: 1
      }
    }));

    return (
      <View style={styles.flex1}>
        <Text
          color="primary"
          variant="bodyLg"
          weight="semibold"
          style={styles.header}
        >
          Requester Reviews
        </Text>

        <InfiniteList<
          requesterReviewsPageQuery,
          requesterReviews_requester$key,
          ReviewEdgeType
        >
          testID="requester-reviews"
          parentRef={node ?? null}
          fragmentInput={graphql`
            fragment requesterReviews_requester on RequesterLike
            @refetchable(queryName: "requesterReviewsPageQuery")
            @argumentDefinitions(
              count: { type: "Int", defaultValue: 4 }
              after: { type: "String" }
            ) {
              reviewsReceived(
                first: $count
                after: $after
                query: "ORDER BY insertedAt DESC"
              ) @connection(key: "requesterReviews_reviewsReceived") {
                edges {
                  node {
                    id
                  }
                  ...requesterReviewRow_engagementReviewsEdge
                }
                totalCount
                pageInfo {
                  hasNextPage
                }
              }
            }
          `}
          getData={(data) => getConnectionEdges(data?.reviewsReceived)}
          keyExtractor={(item, idx) => item?.node?.id ?? `${idx}`}
          renderItem={({ item }) => <RequesterReviewRow fragmentRef={item} />}
          renderEmptyView={(loading) =>
            loading ? null : (
              <EmptyView
                type="boxed"
                message="This requester has no reviews yet."
              />
            )
          }
        />
      </View>
    );
  },
  {
    query: graphql`
      query requesterReviewsQuery($id: ID!) {
        node(id: $id) {
          ...requesterReviews_requester
        }
      }
    `,
    variables: ({ requesterId }) => ({ id: requesterId })
  }
);
