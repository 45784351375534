/**
 * @generated SignedSource<<2db01b3494b8c8b6771fa93c74da8317>>
 * @relayHash 9f927e40254dbdf2793ca53a2ddca0c3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:47-TPejAQ-rgjLXO0PhFcT2ozfU-aBoX32O_Ksj5GT8

import { ConcreteRequest } from 'relay-runtime';
export type UserEmailStatus = "AVAILABLE" | "INVALID" | "SSO" | "UNAVAILABLE" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type CheckUserEmailStatusInput = {
  address: string;
  userType: UserType;
};
export type EmailCheckerQuery$variables = {
  input: CheckUserEmailStatusInput;
};
export type EmailCheckerQuery$data = {
  readonly checkUserEmailStatus: {
    readonly userEmailStatus: UserEmailStatus;
  } | null | undefined;
};
export type EmailCheckerQuery = {
  response: EmailCheckerQuery$data;
  variables: EmailCheckerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CheckUserEmailStatusPayload",
    "kind": "LinkedField",
    "name": "checkUserEmailStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userEmailStatus",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailCheckerQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailCheckerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:47-TPejAQ-rgjLXO0PhFcT2ozfU-aBoX32O_Ksj5GT8",
    "metadata": {},
    "name": "EmailCheckerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1b071ea41fc06db16b348aa0789617aa";

export default node;
