/**
 * @generated SignedSource<<544d7ed81306c0afd2dd1f6a108fa5e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationStateTabsParticipating_viewer$data = {
  readonly active: {
    readonly totalCount: number;
  } | null | undefined;
  readonly inactive: {
    readonly totalCount: number;
  } | null | undefined;
  readonly userMessageStats: {
    readonly unreadCount: number;
  };
  readonly " $fragmentType": "ConversationStateTabsParticipating_viewer";
};
export type ConversationStateTabsParticipating_viewer$key = {
  readonly " $data"?: ConversationStateTabsParticipating_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConversationStateTabsParticipating_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConversationStateTabsParticipating_viewer",
  "selections": [
    {
      "alias": "active",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status = OPEN AND conversationType != SHIFT_GROUP"
        }
      ],
      "concreteType": "ConversationsConnection",
      "kind": "LinkedField",
      "name": "conversations",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "conversations(first:0,query:\"WHERE status = OPEN AND conversationType != SHIFT_GROUP\")"
    },
    {
      "alias": "inactive",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status = CLOSED AND conversationType != SHIFT_GROUP"
        }
      ],
      "concreteType": "ConversationsConnection",
      "kind": "LinkedField",
      "name": "conversations",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "conversations(first:0,query:\"WHERE status = CLOSED AND conversationType != SHIFT_GROUP\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "conversationTypes",
          "value": [
            "ENGAGEMENT",
            "ORGANIZATION_WORKER"
          ]
        }
      ],
      "concreteType": "UserMessageStats",
      "kind": "LinkedField",
      "name": "userMessageStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        }
      ],
      "storageKey": "userMessageStats(conversationTypes:[\"ENGAGEMENT\",\"ORGANIZATION_WORKER\"])"
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();

(node as any).hash = "b7a741cdbfa500c4d1d2ef42d1e5789e";

export default node;
