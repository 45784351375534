import moment from "moment-timezone";
import * as React from "react";
import { View } from "react-native";

import { StyledIcon } from "../icon";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import StyledCheckbox from "./styled-checkbox";
import StyledDatePicker from "./styled-date-picker";

type Props = StylesProps & {
  labelFrom?: string;
  labelTo?: string;
  maxFromDate?: Date;
  testID?: string;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  onChange?: (arg0: DateRange | null | undefined) => void;
  showToggle?: boolean;
  value?: DateRange;
};

export interface DateRange {
  valueFrom?: Date | null | undefined;
  valueTo?: (Date | null | undefined) | string;
}

interface State {
  current: boolean | null | undefined;
  valueFrom?: Date | null | undefined;
  valueTo?: (Date | null | undefined) | string;
}

@withStyles(({ color, font }) => ({
  row: {
    alignItems: "flex-end",
    flexDirection: "row"
  },
  icon: {
    color: color.orange,
    fontSize: font.size.largest,
    marginBottom: 10,
    marginHorizontal: 15,
    flex: 1
  },
  input: {
    flex: 5,
    maxWidth: "42%"
  },
  rowFromRight: {
    flexDirection: "row-reverse"
  },
  checkboxText: {
    color: color.blue,
    fontSize: font.size.large
  },
  checkboxContainer: {
    padding: 0,
    marginHorizontal: 0
  },
  marginBottom: {
    marginBottom: 5
  },
  orange: {
    color: color.orange
  }
}))
export default class StyledDateRangeInput extends React.Component<
  Props,
  State
> {
  static defaultProps = {
    testID: "datepicker",
    maxDate: new Date(),
    showToggle: false,
    ...stylesStubs
  };

  static getDerivedStateFromProps({ value }: Props) {
    const { valueFrom, valueTo } = value ?? { valueFrom: null, valueTo: null };
    const current = valueTo === "current";
    return {
      valueFrom,
      valueTo: current ? null : valueTo,
      current
    };
  }

  state: State = {
    current: false,
    valueFrom: null,
    valueTo: null
  };

  handleChange = (
    field: "valueTo" | "valueFrom",
    text?: Date | null | undefined
  ) => {
    const { onChange } = this.props;
    if (field === "valueFrom" && onChange) {
      const selectedToday =
        text &&
        moment(text).format("YYYY-MM-DD") ===
          moment(new Date()).format("YYYY-MM-DD");
      if (selectedToday) onChange({ valueFrom: text, valueTo: "current" });
      else onChange({ valueFrom: text, valueTo: this.state.valueTo });
    } else if (field === "valueTo" && onChange) {
      onChange({ valueFrom: this.state.valueFrom, valueTo: text });
    }
    this.setState(
      field === "valueFrom" ? { valueFrom: text } : { valueTo: text }
    );
  };

  handleDateFrom = (value: Date | null | undefined) => {
    this.handleChange("valueFrom", value);
  };

  handleDateTo = (value: Date | null | undefined) => {
    this.handleChange("valueTo", value);
  };

  handleToggle = () => {
    const { valueFrom } = this.state;
    const selectedToday =
      this.state.valueFrom &&
      moment(this.state.valueFrom).format("YYYY-MM-DD") ===
        moment(new Date()).format("YYYY-MM-DD");
    if (selectedToday) return true;
    this.setState((prevState) => {
      const current = !prevState.current;
      if (this.props.onChange) {
        if (current) {
          this.props.onChange({
            valueFrom,
            valueTo: "current"
          });
        } else {
          this.props.onChange({
            valueFrom,
            valueTo: null
          });
        }
      }
      return { current };
    });
  };

  getMinToDate = (
    valueFrom: Date | null | undefined,
    maxDate: Date | null | undefined
  ): Date | null | undefined => {
    if (!valueFrom) return valueFrom;
    const minToDate = moment(valueFrom).add(1, "d");
    return maxDate &&
      minToDate.startOf("day").isAfter(moment(maxDate).startOf("day"))
      ? maxDate
      : minToDate.toDate();
  };

  getValueFromIsToday = (valueFrom: Date | null | undefined): boolean => {
    if (!valueFrom) return false;
    return moment(valueFrom).startOf("day").isSame(moment().startOf("day"));
  };

  render() {
    const { current, valueFrom, valueTo } = this.state;
    const {
      labelFrom,
      labelTo,
      maxDate,
      maxFromDate,
      minDate,
      showToggle,
      styles,
      testID
    } = this.props;

    const minToDate = this.getMinToDate(valueFrom, maxDate);
    const valueFromIsToday = this.getValueFromIsToday(valueFrom);

    return (
      <View>
        <View style={styles.row}>
          <StyledDatePicker
            style={styles.input}
            date={valueFrom}
            label={labelFrom}
            minDate={minDate}
            maxDate={maxFromDate ?? maxDate}
            onDateChange={this.handleDateFrom}
            placeholder="From"
            eventTargetName="Input From Date Picker"
            testID={typeof testID === "string" ? `${testID}-from` : undefined}
          />
          <StyledIcon
            name="arrow-right-long"
            style={[styles.icon, styles.orange]}
          />
          <StyledDatePicker
            disabled={valueFromIsToday}
            date={valueTo}
            displayValue={current ? "Present" : null}
            label={labelTo}
            minDate={minToDate}
            maxDate={maxDate}
            onDateChange={this.handleDateTo}
            placeholder="To"
            eventTargetName="Input To Date Picker"
            style={styles.input}
            testID={typeof testID === "string" ? `${testID}-to` : undefined}
          />
        </View>
        <View style={styles.rowFromRight}>
          {showToggle && (
            <View style={styles.input}>
              <StyledCheckbox
                checked={!!current}
                onPress={this.handleToggle}
                title="Current"
                eventTargetName="Current Date Checkbox"
                textStyle={styles.checkboxText}
                style={styles.checkboxContainer}
                testID={
                  typeof testID === "string"
                    ? `${testID}-current-checkbox`
                    : undefined
                }
              />
            </View>
          )}
        </View>
      </View>
    );
  }
}
