import React, { useMemo } from "react";

import {
  Divider,
  HighlightedReminder,
  Stack,
  Text,
  TimeRemaining,
  isInThePast
} from "@gigsmart/atorasu";
import { asEnum, where } from "@gigsmart/biruda";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useRouteMatch } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionEdges,
  graphql,
  useRelayFragment
} from "@gigsmart/relay";
import SummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/SummaryRow";
import WorkerShiftScheduleSummary from "@gigsmart/seibutsu/gig/SummaryRows/WorkerShiftScheduleSummaryDetails";
import type { WorkerShiftScheduleSummaryRowQuery } from "./__generated__/WorkerShiftScheduleSummaryRowQuery.graphql";
import type { WorkerShiftScheduleSummaryRow_engagement$key } from "./__generated__/WorkerShiftScheduleSummaryRow_engagement.graphql";
import type { WorkerShiftScheduleSummaryRow_gig$key } from "./__generated__/WorkerShiftScheduleSummaryRow_gig.graphql";

interface Props {
  nodeId: string;
  gigRef?: WorkerShiftScheduleSummaryRow_gig$key | null | undefined;
  engagementRef?:
    | WorkerShiftScheduleSummaryRow_engagement$key
    | null
    | undefined;
}

export default createSuspendedQueryContainer<
  WorkerShiftScheduleSummaryRowQuery,
  Props
>(
  function WorkerShiftScheduleSummaryRow({ response, gigRef, engagementRef }) {
    const isOfferedSeries = useRouteMatch()?.path === "/gigsr/:id/offered";
    const gig = useRelayFragment(
      graphql`
        fragment WorkerShiftScheduleSummaryRow_gig on Gig {
          startsAt
          endsAt
          isRecurring
          latestArrivalTime
          pickup {
            eligible
          }
        }
      `,
      gigRef ?? null
    );
    const engagement = useRelayFragment(
      graphql`
        fragment WorkerShiftScheduleSummaryRow_engagement on Engagement {
          startsAt
          endsAt
        }
      `,
      engagementRef ?? null
    );

    const gigSeries = response?.viewer?.gigSeries?.node;
    const shifts = useMemo(() => {
      if (gigSeries?.availableGigs?.edges?.length && !isOfferedSeries) {
        return getConnectionEdges(gigSeries?.availableGigs, (edge) => ({
          id: edge.node?.id,
          area: edge.node?.area,
          endsAt: edge.node?.endsAt,
          startsAt: edge.node?.startsAt,
          distance: edge.distance,
          actualStartsAt: edge.node?.actualStartsAt,
          timezone: edge.node?.timezone,
          isRecurring: edge.node?.isRecurring
        }));
      }

      if (gigSeries?.engagements?.edges?.length) {
        return getConnectionEdges(gigSeries?.engagements, (edge) => ({
          id: edge.node?.id,
          area: edge.node?.gig?.area,
          endsAt: edge.node?.gig?.endsAt,
          startsAt: edge.node?.gig?.startsAt,
          distance: edge.node?.workerDistance,
          actualStartsAt: edge.node?.gig?.actualStartsAt,
          timezone: edge.node?.gig?.timezone,
          isRecurring: edge.node?.gig?.isRecurring
        }));
      }

      return [];
    }, [gigSeries?.availableGigs, gigSeries?.engagements]);
    const shiftsCount = shifts.length;

    const { startsAt, endsAt } = engagement ?? gig ?? shifts[0] ?? {};
    const hasRecurringShifts =
      shifts.some((shift) => shift.isRecurring === true) || gig?.isRecurring;
    const hasAvailableGigs = !!response?.viewer?.gigSeries && !isOfferedSeries;

    return (
      <>
        <SummaryRow
          testID="schedule-summary-row"
          icon="calendar-days"
          field="schedule"
          route="schedule"
          title="Shift Schedule"
        >
          <Stack>
            <WorkerShiftScheduleSummary
              hasAvailableGigs={hasAvailableGigs}
              isRecurring={hasRecurringShifts}
              schedule={
                shiftsCount <= 1
                  ? [{ startsAt, endsAt, isRecurring: hasRecurringShifts }]
                  : shifts.map((shift) => ({
                      startsAt: shift.startsAt,
                      endsAt: shift.endsAt,
                      timezone: shift.timezone,
                      actualStartsAt: shift.actualStartsAt,
                      isRecurring: shift.isRecurring
                    }))
              }
            />
            {gig?.pickup?.eligible && (
              <HighlightedReminder
                icon="circle-exclamation"
                header={
                  <Text weight="semibold">
                    This Shift is available today starting in{" "}
                    <TimeRemaining
                      size="lg"
                      showLargestOnly
                      color="emphasized"
                      weight="semibold"
                      date={
                        isInThePast(gig?.startsAt)
                          ? gig?.latestArrivalTime
                          : gig?.startsAt
                      }
                    />
                  </Text>
                }
              />
            )}
          </Stack>
        </SummaryRow>
        <Divider />
      </>
    );
  },
  {
    query: graphql`
      query WorkerShiftScheduleSummaryRowQuery(
        $seriesId: ID!
        $engagementsQuery: String!
      ) {
        viewer {
          ... on Worker {
            gigSeries(id: $seriesId) {
              ... on AvailableGigSeriesEdge {
                node {
                  availableGigs(first: 90) {
                    edges {
                      distance
                      node @trackImpressions(action: VIEWED) {
                        id
                        actualStartsAt
                        startsAt
                        endsAt
                        area
                        timezone
                        isRecurring
                      }
                    }
                    totalCount
                  }
                  engagements(first: 90, query: $engagementsQuery) {
                    edges {
                      node {
                        id
                        workerDistance
                        gig {
                          area
                          startsAt
                          endsAt
                          actualStartsAt
                          timezone
                          isRecurring
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ nodeId }) => {
      const user = useCurrentUser();
      const engagementsQuery = where({
        workerId: user?.id,
        currentStateName: asEnum("OFFERED")
      })
        .orderBy("startsAt", "ASC NULLS FIRST")
        .orderBy("endsAt", "ASC")
        .toString();

      return { seriesId: nodeId, engagementsQuery };
    }
  }
);
