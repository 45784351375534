import {
  Button,
  Column,
  type IconName,
  InputLabel,
  showMediaPicker,
  useStyles
} from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { UpdatedAvatar } from "@gigsmart/katana";
import React from "react";
import { useImagePreview } from "../media/avatar-picker";

type Props = FormFieldOptions & {
  label?: string;
  placeholderIcon?: IconName;
  buttonLabel?: string;
  align?: "center" | "left";
  width?: number;
  front?: boolean;
};

export function FomuAvatarPicker({
  name,
  validates,
  label,
  buttonLabel,
  align,
  width = 120,
  front,
  placeholderIcon = front ? "user" : "image"
}: Props) {
  const { setValue, value } = useFormField({ name, validates });
  const previewUrl = useImagePreview(value);
  const styles = useStyles(() => ({
    container: { paddingBottom: 8 }
  }));

  const handlePress = () =>
    showMediaPicker({
      front,
      crop: "circle",
      preview: "circle",
      onSelect: ([uri]) => setValue?.(uri)
    });

  return (
    <Column gap="standard" style={styles.container}>
      {!!label && <InputLabel label={label} />}
      <UpdatedAvatar
        align={align}
        onPress={handlePress}
        onIconPress={handlePress}
        sourceUri={previewUrl}
        width={width}
        testID="camera-icon"
        placeholderIcon={placeholderIcon}
      />
      {!!buttonLabel && (
        <Button
          testID="upload-avatar-btn"
          label={buttonLabel}
          icon="upload"
          outline
          onPress={handlePress}
          size="small"
          alignSelf="flex-start"
        />
      )}
    </Column>
  );
}
