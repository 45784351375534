import React from "react";

import { Stack, Text } from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { SummaryRow } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { projectSummaryDateTime_gig$key } from "./__generated__/projectSummaryDateTime_gig.graphql";

interface Props {
  gig: projectSummaryDateTime_gig$key | null | undefined;
}

export default function ProjectSummaryDateTime({ gig: gigFragmentRef }: Props) {
  const gig = useRelayFragment<projectSummaryDateTime_gig$key>(
    graphql`
      fragment projectSummaryDateTime_gig on Gig {
        negotiable
        startsAt
      }
    `,
    gigFragmentRef
  );
  if (gig?.negotiable?.includes("STARTS_AT"))
    return (
      <SummaryRow
        iconName="calendar-days"
        sectionLabel="Date & Time"
        variant={"classic"}
        sectionContent={
          <Stack fill={1} size="compact">
            <Text>Flexible</Text>
            <Text variant="note" color="neutral">
              Set a start date and time with the Requester.
            </Text>
          </Stack>
        }
      />
    );
  if (!gig?.startsAt) {
    return (
      <SummaryRow
        iconName="calendar-days"
        sectionLabel="Date & Time"
        variant={"classic"}
        sectionContent={<Text weight="bold">As soon as possible</Text>}
      />
    );
  }
  return (
    <SummaryRow
      iconName="calendar-days"
      sectionLabel="Date & Time"
      variant={"classic"}
      sectionContent={
        <Stack fill={1} size="compact">
          <Text>{time.humanize(gig?.startsAt, "fullDate")}</Text>
          <Text>{time.humanize(gig?.startsAt, "hm")}</Text>
        </Stack>
      }
    />
  );
}
