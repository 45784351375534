/**
 * @generated SignedSource<<fae489d3a8f97f78b74a025c5d6e1669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type offeredGigTopSection_worker$data = {
  readonly currentEngagement: {
    readonly currentState: {
      readonly name: EngagementStateName;
    };
    readonly id: string;
    readonly reviewOfRequester: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EngagementDetailsCard_engagement" | "WorkerShiftApplets_shift" | "activeEngagementSummaryRow_engagement">;
  } | null | undefined;
  readonly id: string;
  readonly pendingTimesheets: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"usePendingOfferCount_worker">;
  readonly " $fragmentType": "offeredGigTopSection_worker";
};
export type offeredGigTopSection_worker$key = {
  readonly " $data"?: offeredGigTopSection_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"offeredGigTopSection_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offeredGigTopSection_worker",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePendingOfferCount_worker"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Engagement",
      "kind": "LinkedField",
      "name": "currentEngagement",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkerShiftApplets_shift"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EngagementDetailsCard_engagement"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "activeEngagementSummaryRow_engagement"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementState",
          "kind": "LinkedField",
          "name": "currentState",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementReview",
          "kind": "LinkedField",
          "name": "reviewOfRequester",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "pendingTimesheets",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE workerCanApproveTimesheet = TRUE"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "engagements(first:0,query:\"WHERE workerCanApproveTimesheet = TRUE\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "bdbfa9c22315a0575ebeb84fc6dbc824";

export default node;
