/**
 * @generated SignedSource<<60140c5371db030592d75102266a144c>>
 * @relayHash c5fbe5a0d60238c28f72d949b8f92e01
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Pa5iY3m23swmrzik3rHU-QZOpY12QlJvCkl7tV2bEp4

import { ConcreteRequest } from 'relay-runtime';
export type confirmRegistrationQuery$variables = Record<PropertyKey, never>;
export type confirmRegistrationQuery$data = {
  readonly viewer: {
    readonly primaryEmail: {
      readonly address: string;
      readonly confirmed: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type confirmRegistrationQuery = {
  response: confirmRegistrationQuery$data;
  variables: confirmRegistrationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmed",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "confirmRegistrationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "confirmRegistrationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Pa5iY3m23swmrzik3rHU-QZOpY12QlJvCkl7tV2bEp4",
    "metadata": {},
    "name": "confirmRegistrationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "890d01d17953cff5f706df0fd07e3eb5";

export default node;
