import { Persistence, Stack } from "@gigsmart/atorasu";
import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import {
  InstrumentationProvider,
  isInstrumented,
  useTestInstrumenter
} from "@gigsmart/pickle/support/utils/instrumentation-client";
import { without } from "lodash";
import React, {
  useCallback,
  useEffect,
  useState,
  type ComponentType
} from "react";
import { Platform, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { generate } from "short-uuid";

const instrumentationKey = "_instrumentationKey_";
Persistence.keep(instrumentationKey);

const withTestInstrumenter: HOCVoid = (
  WrappedComponent: ComponentType<unknown>
) => {
  if (!isInstrumented || process.env.IS_TESTING !== "true") {
    return WrappedComponent;
  }
  return applyHOCProperties({
    displayName: "withTestInstrumentation",
    WrappedComponent,
    HigherOrderComponent: (props) => {
      const [commands, setCommands] = useState<string[]>([]);
      const setLastCommand = useCallback((command: string) => {
        setCommands((prevCommands) => [command, ...prevCommands]);
        setTimeout(
          () => setCommands((prevCommands) => without(prevCommands, command)),
          5000
        );
      }, []);
      const [sessionId, setSessionId] = useState<string | null | undefined>(
        __DEV__ && process.env.IS_TESTING !== "true" ? "DEV" : null
      );
      const { instrumenter, testerUrl, status } = useTestInstrumenter(
        sessionId,
        { platform: Platform.OS },
        () => setSessionId(generate()),
        (requestId, command, target, payload, response) => {
          if (typeof requestId !== "number") return;
          setLastCommand(
            JSON.stringify({ requestId, command, target, payload, response })
          );
        }
      );
      const backgroundColor = {
        NONE: "#999999",
        WAITING: "#3EAFE0",
        READY: "#24925c",
        CLOSED: "#ffB549",
        ERRORED: "#B22222"
      }[status];
      const [bypass] = useState(false);
      useEffect(() => {
        if (sessionId) {
          void Persistence.save(instrumentationKey, sessionId);
        } else {
          Persistence.fetch<string>(instrumentationKey, () => generate())
            .then((newSessionId) => {
              setSessionId(newSessionId);
            })
            .catch(console.error);
        }
      }, [sessionId, bypass]);

      // Display status and URL
      return (
        <>
          <InstrumentationProvider value={instrumenter}>
            <WrappedComponent {...props} />
          </InstrumentationProvider>
          <View pointerEvents="none" style={styles.container}>
            <SafeAreaView pointerEvents="none">
              <Stack size="compact">
                {[
                  ...commands
                    .map((command, index) => (
                      <View
                        key={command + String(commands.length)}
                        pointerEvents="none"
                        style={[
                          styles.box,
                          styles.debug,
                          { opacity: Math.max(1.0 - index * 0.25, 0.0) }
                        ]}
                      >
                        <Text style={styles.text}>{command}</Text>
                      </View>
                    ))
                    .reverse(),
                  <View
                    key="__url__"
                    pointerEvents="none"
                    style={[
                      styles.box,
                      { backgroundColor },
                      status === "READY" && styles.hidden
                    ]}
                  >
                    <Text
                      style={styles.text}
                      testID="instrumentation-url"
                      selectable
                    >
                      {testerUrl}
                    </Text>
                  </View>
                ]}
              </Stack>
            </SafeAreaView>
          </View>
        </>
      );
    }
  });
};

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    bottom: Platform.OS === "web" ? 8 : 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    zIndex: 99
  },
  // eslint-disable-next-line react-native/no-color-literals
  text: {
    textAlign: "center",
    fontSize: 6,
    color: "#ffffff"
  },
  // eslint-disable-next-line react-native/no-color-literals
  debug: {
    backgroundColor: "#999999"
  },
  hidden: {
    opacity: 0.5
  },
  // eslint-disable-next-line react-native/no-color-literals
  box: {
    flexShrink: 1,
    padding: 10,
    borderRadius: 100
  }
});

export default withTestInstrumenter;
