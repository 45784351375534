import {
  Persistence,
  Pressable,
  type StepProps,
  StepSubmit,
  Text,
  toast
} from "@gigsmart/atorasu";
import { createConversion } from "@gigsmart/dekigoto";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useRoute } from "@gigsmart/kaizoku";
import {
  graphql,
  useRelayMutationPromise,
  useRelayOrchestrator
} from "@gigsmart/relay";
import React, { useCallback, useEffect, useState } from "react";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";
import type { PasswordStepInviteMutation } from "./__generated__/PasswordStepInviteMutation.graphql";
import type { PasswordStepMutation } from "./__generated__/PasswordStepMutation.graphql";
import type { OnboardingData } from "./types";

const RequesterPasswordConversionTracker = createConversion(
  "requester_onboarding_password"
);

type Props = StepProps<OnboardingData>;

export default function PasswordStep({ stepper }: Props) {
  const { reset } = useRelayOrchestrator();
  const route = useRoute();
  const user = useCurrentUser();

  useEffect(() => {
    void RequesterPasswordConversionTracker.track({
      step_index: stepper.index,
      event: "password_step",
      url: route.path,
      userId: user?.uuid
    });
  }, [stepper.index, route.path, user?.uuid]);

  const [revealPassword, toggleRevealPassword] = useState(false);
  const [showRevealPasswordControls, toggleShowRevealPasswordControls] =
    useState(false);
  const [addUser] = useRelayMutationPromise<PasswordStepMutation>(graphql`
    mutation PasswordStepMutation($input: AddUserInput!) {
      addUser(input: $input) {
        token
      }
    }
  `);
  const [acceptOrganizationInvite] =
    useRelayMutationPromise<PasswordStepInviteMutation>(
      graphql`
        mutation PasswordStepInviteMutation(
          $input: AcceptOrganizationInviteInput!
        ) {
          acceptOrganizationInvite(input: $input) {
            token
          }
        }
      `
    );

  const handleSubmit: FomuSubmitFn = useCallback(
    async ({ values }, done) => {
      const { email, userType, invite } = stepper.data;

      try {
        const res = await addUser({
          input: {
            userType,
            emailAddress: email,
            password: values.password ?? ""
          }
        });

        void Persistence.save("email", email);
        Persistence.keep("email");

        await reset(res.addUser?.token);
        if (invite?.id) {
          const inviteRes = await acceptOrganizationInvite({
            input: { organizationInviteId: invite.id }
          });
          await reset(inviteRes.acceptOrganizationInvite?.token);
        }
        stepper.nextStep();
      } catch (err) {
        setTimeout(() => toast.error(err?.message || "Unknown Error"), 10);
        done();
      }
    },
    [stepper.data]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FomuTextInput
        name="password"
        validates={[
          Validator.presence(),
          Validator.length({
            min: 8,
            message: "must have 8 or more characters"
          })
        ]}
        label="Password"
        note="Password must be at least 8 characters."
        rightAccessory={
          <>
            {showRevealPasswordControls && (
              <Pressable
                eventTargetName="Password Visibility Toggle Button"
                testID="password-visibility-toggle"
                onPress={() => {
                  toggleRevealPassword(!revealPassword);
                }}
                eventEntityType="password-visibility-toggle"
              >
                <Text color="primary">
                  {revealPassword ? " HIDE " : " SHOW "}
                </Text>
              </Pressable>
            )}
          </>
        }
        testID="password-input"
        secureTextEntry={!revealPassword}
        onKeyPress={() => {
          if (!showRevealPasswordControls)
            toggleShowRevealPasswordControls(true);
        }}
        autoCapitalize="none"
        autoCorrect={false}
        autoFocus
      />
      <StepSubmit testID="next-btn" label="Next" />
    </Form>
  );
}
