import React, { type ComponentType } from "react";
import { TouchableOpacity } from "react-native";

import Card from "../container/card";
import { FlexRow } from "../flex";
import { type IconName, StyledIcon } from "../icon";
import { Spacer } from "../spacer";
import { type ViewStyleProp, useStyles } from "../style";
import { SmallOutlineButton, SmallSolidButton } from "../tappable";
import { KatanaText } from "../text";

interface Props {
  style?: ViewStyleProp;
  header?: string;
  content?: string;
  cta?: string;
  ctaIcon?: IconName;
  ctaVariant?: "solid" | "outline";
  ImageComponent?: ComponentType<any>;
  onPressInfo?: () => void;
  onPressCta?: () => void;
}

const InfoCard = ({
  style,
  ImageComponent,
  header,
  content,
  cta,
  ctaIcon,
  ctaVariant,
  onPressInfo,
  onPressCta
}: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    container: {
      marginHorizontal: 0
    },
    img: {
      width: 100,
      height: 100,
      alignSelf: "center"
    },
    text: {
      textAlign: "center",
      marginHorizontal: units(4)
    },
    cta: {
      marginTop: "auto",
      paddingVertical: units(3),
      paddingHorizontal: units(4)
    },
    info: {
      width: 34,
      height: 34,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 4,
      right: 4
    },
    infoIcon: {
      color: color.spiceBlue,
      fontSize: 24
    }
  }));

  const topSpacing = ImageComponent ? 2 : onPressInfo ? 3 : 0;
  const CtaButton =
    ctaVariant === "outline" ? SmallOutlineButton : SmallSolidButton;

  return (
    <Card style={[styles.container, style]}>
      {!!topSpacing && <Spacer units={topSpacing} />}
      <Spacer units={topSpacing} />
      {!!ImageComponent && <ImageComponent style={styles.img} />}
      <Spacer />
      <KatanaText style={styles.text} variant="h4">
        {header}
      </KatanaText>
      <Spacer units={2} />
      <KatanaText style={styles.text}>{content}</KatanaText>
      {!!cta && (
        <FlexRow style={styles.cta}>
          <CtaButton fill title={cta} icon={ctaIcon} onPress={onPressCta} />
        </FlexRow>
      )}

      {!!onPressInfo && (
        <TouchableOpacity style={styles.info} onPress={onPressInfo}>
          <StyledIcon style={styles.infoIcon} name="circle-question" />
        </TouchableOpacity>
      )}
    </Card>
  );
};

export default InfoCard;
