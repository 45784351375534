import { IconText, Row, Stack, Text } from "@gigsmart/atorasu";
import { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import moment from "moment";
import React from "react";

export interface Props {
  id?: string | null;
  skill?: string | null;
  position?: string | null;
  area?: string | null;
  skillsCount?: number;
  distance?: number | { max?: number | null; min?: number | null } | null;
  startsAt?: string | null;
  endsAt?: string | null;
  insertedAt?: string | null;
  isMultiShift?: boolean;
}

export default function WorkerGigListCardGigInfo({
  id,
  skill,
  position,
  area,
  skillsCount = 0,
  distance,
  startsAt,
  endsAt,
  insertedAt,
  isMultiShift
}: Props) {
  return (
    <Stack fill size="slim">
      <Row>
        <IconText
          fill={1}
          numberOfLines={1}
          size="small"
          icon="box-check"
          iconSize="tiny"
          iconAlign="center"
          color="neutral"
          testID={`worker-gig-card-skill-${id}`}
        >
          {position ?? skill}
        </IconText>
        {skillsCount > 1 && !position && (
          <Text color="neutral" testID={`worker-gig-card-skill-count-${id}`}>
            (+{skillsCount - 1} More)
          </Text>
        )}
      </Row>
      <IconText
        numberOfLines={1}
        size="small"
        icon="calendar-days"
        iconSize="tiny"
        iconAlign="center"
        color="neutral"
        testID={`worker-gig-card-datetime-${id}`}
      >
        {isMultiShift ? (
          <>
            <Text weight="bold">Multi-Shift &bull; </Text>
            {moment(startsAt ?? insertedAt).format("MMM Do")}
          </>
        ) : (
          gigHelpers.gigFullTime({ startsAt, endsAt, insertedAt })
        )}
      </IconText>

      <Row>
        <IconText
          fill={1}
          numberOfLines={1}
          size="small"
          icon="location-dot"
          iconSize="tiny"
          iconAlign="center"
          color="neutral"
          testID={`worker-gig-card-area-${id}`}
        >
          {area}
        </IconText>
        <Text color="neutral" testID={`worker-gig-card-distance-${id}`}>
          {typeof distance === "number"
            ? `(${distance.toFixed(1)}mi)`
            : gigHelpers.formatDistanceRange(distance)}
        </Text>
      </Row>
      <IconText
        size="small"
        icon="mobile-alt"
        iconSize="tiny"
        iconAlign="center"
        color="neutral"
      >
        Paid via GigSmart App
      </IconText>
    </Stack>
  );
}
