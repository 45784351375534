/**
 * @generated SignedSource<<9586feeb00eb4a8e43fe3c5d4fc793de>>
 * @relayHash f461835b2f48af379c136e6911b03a43
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:e5_P-ofWZUfEXy9Uw34rzOO4gmyh85NJxOBdJn7z-s8

import { ConcreteRequest } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type createDisputeTimesheetStepQuery$variables = {
  engagementId: string;
  hasTimesheetId: boolean;
  timesheetId: string;
};
export type createDisputeTimesheetStepQuery$data = {
  readonly engagement: {
    readonly endsAt?: string | null | undefined;
    readonly finalTimesheet?: {
      readonly additionalPayment: string | null | undefined;
      readonly estimatedMileage: number | null | undefined;
      readonly id: string;
      readonly paymentStyle: EngagementTimesheetPaymentStyle;
      readonly states: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: string;
            readonly action: EngagementStateAction;
            readonly id: string;
            readonly name: EngagementStateName;
            readonly negotiation: {
              readonly payRate: string | null | undefined;
              readonly submittedBy: {
                readonly __typename: string;
                readonly displayName: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly overridesState?: {
              readonly id: string;
            } | null | undefined;
            readonly transitionedAt: string;
            readonly transitionedBy: {
              readonly __typename: string;
              readonly displayName: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
    readonly gig?: {
      readonly estimatedMileage: number | null | undefined;
      readonly gigType: GigType | null | undefined;
    };
    readonly paymentInfo?: {
      readonly payRate: string;
      readonly serviceFees: ReadonlyArray<{
        readonly amount: string;
        readonly feeType: EngagementAddonFeeType;
        readonly hourlyRate: string | null | undefined;
      }>;
    } | null | undefined;
    readonly startsAt?: string | null | undefined;
    readonly systemTimesheet?: {
      readonly estimatedMileage: number | null | undefined;
      readonly id: string;
      readonly paymentStyle: EngagementTimesheetPaymentStyle;
      readonly states: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: string;
            readonly action: EngagementStateAction;
            readonly id: string;
            readonly name: EngagementStateName;
            readonly negotiation: {
              readonly payRate: string | null | undefined;
              readonly submittedBy: {
                readonly __typename: string;
                readonly displayName: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly overridesState?: {
              readonly id: string;
            } | null | undefined;
            readonly transitionedAt: string;
            readonly transitionedBy: {
              readonly __typename: string;
              readonly displayName: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly totalDurationWorked: string | null | undefined;
    } | null | undefined;
    readonly timezone?: string | null | undefined;
  } | null | undefined;
  readonly timesheet?: {
    readonly disputedTimesheet?: {
      readonly id: string;
    } | null | undefined;
    readonly estimatedMileage?: number | null | undefined;
    readonly id?: string;
    readonly states?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: string;
          readonly action: EngagementStateAction;
          readonly id: string;
          readonly name: EngagementStateName;
          readonly negotiation: {
            readonly payRate: string | null | undefined;
            readonly submittedBy: {
              readonly __typename: string;
              readonly displayName: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly overridesState?: {
            readonly id: string;
          } | null | undefined;
          readonly transitionedAt: string;
          readonly transitionedBy: {
            readonly __typename: string;
            readonly displayName: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly totalDurationWorked?: string | null | undefined;
  } | null | undefined;
};
export type createDisputeTimesheetStepQuery = {
  response: createDisputeTimesheetStepQuery$data;
  variables: createDisputeTimesheetStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagementId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTimesheetId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timesheetId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engagementId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerServiceFee",
      "kind": "LinkedField",
      "name": "serviceFees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourlyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "feeType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "FINAL"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalPayment",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Literal",
    "name": "overridden",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "ORDER BY transitionedAt ASC"
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v22 = [
  (v17/*: any*/),
  (v21/*: any*/)
],
v23 = [
  (v12/*: any*/)
],
v24 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "overridesState",
      "plural": false,
      "selections": (v23/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EngagementStateOverride",
  "abstractKey": null
},
v25 = {
  "alias": null,
  "args": (v16/*: any*/),
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementStateOrOverridesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v12/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "transitionedBy",
              "plural": false,
              "selections": (v22/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementNegotiation",
              "kind": "LinkedField",
              "name": "negotiation",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "submittedBy",
                  "plural": false,
                  "selections": (v22/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "states(first:50,overridden:true,query:\"ORDER BY transitionedAt ASC\")"
},
v26 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v27 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "timesheetId"
  }
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheet",
  "kind": "LinkedField",
  "name": "disputedTimesheet",
  "plural": false,
  "selections": (v23/*: any*/),
  "storageKey": null
},
v29 = [
  (v17/*: any*/),
  (v21/*: any*/),
  (v12/*: any*/)
],
v30 = {
  "alias": null,
  "args": (v16/*: any*/),
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementStateOrOverridesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v12/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "transitionedBy",
              "plural": false,
              "selections": (v29/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementNegotiation",
              "kind": "LinkedField",
              "name": "negotiation",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "submittedBy",
                  "plural": false,
                  "selections": (v29/*: any*/),
                  "storageKey": null
                },
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "states(first:50,overridden:true,query:\"ORDER BY transitionedAt ASC\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createDisputeTimesheetStepQuery",
    "selections": [
      {
        "alias": "engagement",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              {
                "alias": "finalTimesheet",
                "args": (v11/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v6/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": "systemTimesheet",
                "args": (v26/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v6/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasTimesheetId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "timesheet",
            "args": (v27/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v12/*: any*/),
                  (v28/*: any*/),
                  (v15/*: any*/),
                  (v6/*: any*/),
                  (v25/*: any*/)
                ],
                "type": "EngagementTimesheet",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "createDisputeTimesheetStepQuery",
    "selections": [
      {
        "alias": "engagement",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v17/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              {
                "alias": "finalTimesheet",
                "args": (v11/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v6/*: any*/),
                  (v30/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": "systemTimesheet",
                "args": (v26/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v6/*: any*/),
                  (v30/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasTimesheetId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "timesheet",
            "args": (v27/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v12/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v28/*: any*/),
                  (v15/*: any*/),
                  (v6/*: any*/),
                  (v30/*: any*/)
                ],
                "type": "EngagementTimesheet",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "qpid:e5_P-ofWZUfEXy9Uw34rzOO4gmyh85NJxOBdJn7z-s8",
    "metadata": {},
    "name": "createDisputeTimesheetStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1aa6025c8d0c8e0d63b522a069bfc1eb";

export default node;
