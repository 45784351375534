/**
 * @generated SignedSource<<376a4c23a72af29915f08a36cc9b0423>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useGigData$data = {
  readonly currentState: {
    readonly name: GigStateName;
  };
  readonly gigSeries: {
    readonly " $fragmentSpreads": FragmentRefs<"useGigSeriesData">;
  };
  readonly name: string | null | undefined;
  readonly " $fragmentType": "useGigData";
};
export type useGigData$key = {
  readonly " $data"?: useGigData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGigData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGigData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GigState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigSeries",
      "kind": "LinkedField",
      "name": "gigSeries",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGigSeriesData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};
})();

(node as any).hash = "8f80ff38b44bb6b6628797d886a77491";

export default node;
