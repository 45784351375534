import {
  Button,
  Column,
  ContentArea,
  HighlightedReminder,
  Screen,
  Stack,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { Form, FormSubmit } from "@gigsmart/fomu";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import React from "react";
import FomuCheckboxGroup from "../../../fomu/inputs/FomuCheckboxGroup";
import StepHeader from "../../../shared/Stepper/StepHeader";
import Nav from "./Nav";
import type { AgreementStepCreateClaimMutation } from "./__generated__/AgreementStepCreateClaimMutation.graphql";
import type { DirectHireClaimsStepper } from "./index";

export default function AgreementStep() {
  const { nextStep, stepsData } = useStepper<{}, DirectHireClaimsStepper>();
  const [commit] = useRelayMutation<AgreementStepCreateClaimMutation>(graphql`
    mutation AgreementStepCreateClaimMutation(
      $input: AddDirectHireClaimInput!
    ) {
      addDirectHireClaim(input: $input) {
        newDirectHireClaimEdge {
          node {
            id
          }
        }
      }
    }
  `);

  const handleNext = () => {
    commit(
      { input: DirectPaymentsOnly.select({ directHireType: "PAYMENT" }, {}) },
      {
        onSuccess: (result) => {
          nextStep({
            id: result?.addDirectHireClaim?.newDirectHireClaimEdge?.node?.id
          });
        }
      }
    );
  };
  const claimExists = !!stepsData?.id;
  return (
    <Form
      onSubmit={handleNext}
      initialValues={{
        agreementOne: claimExists,
        agreementTwo: claimExists
      }}
    >
      <Nav returnTo="back" />
      <Screen testID="direct-hire-payment-agreement-screen">
        <Column fill justifyContent="space-between">
          <Stack>
            <StepHeader
              name={DirectPaymentsOnly.select(
                "Direct Payment Bonus Agreement",
                "Direct Payment & Hire Bonus Agreement"
              )}
              note={DirectPaymentsOnly.select(
                "If an Organization has paid you directly outside the app or brought you on as a non-W2 employee for the Organization, you may be eligible for a bonus.",
                "If an Organization has paid or hired you directly outside of the app, you may be eligible for a bonus."
              )}
            />
            <Surface>
              <ContentArea>
                <Stack>
                  <Text weight="semibold">
                    Please confirm you agree with the following statements by
                    checking the boxes below:
                  </Text>
                  <FomuCheckboxGroup
                    ordered
                    checkboxes={[
                      {
                        name: "agreementOne",
                        required: true,
                        label: DirectPaymentsOnly.select(
                          "I have been paid directly by an Organization to work for them outside of the app or brought on as a non-W2 employee for the Organization.",
                          "I have been paid directly or hired by an Organization to work for them outside of the app."
                        ),
                        testID: "agreement-one-checkbox"
                      },
                      {
                        name: "agreementTwo",
                        required: true,
                        label: DirectPaymentsOnly.select(
                          "I will not submit false Direct Payment confirmation requests.",
                          "I will not submit false Direct Payment & Hire confirmation requests."
                        ),
                        testID: "agreement-two-checkbox"
                      }
                    ]}
                  />
                  <HighlightedReminder header="If you were hired for a W2 full or part-time position, you are not eligible for the bonus." />
                </Stack>
              </ContentArea>
            </Surface>
          </Stack>
          <ContentArea>
            <FormSubmit>
              {({ invalid, submit }) => (
                <Button
                  testID="continue-btn"
                  label="I Agree, Continue"
                  disabled={invalid}
                  onPress={submit}
                />
              )}
            </FormSubmit>
          </ContentArea>
        </Column>
      </Screen>
    </Form>
  );
}
