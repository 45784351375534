import * as React from "react";
import { TouchableOpacity, View } from "react-native";
import { FlexColumn } from "../flex";
import { StyledIcon } from "../icon";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import { StyledButton } from "../tappable";
import { StyledText } from "../text";

interface Option {
  label: string;
  value: any;
}

type Props = StylesProps & {
  transparent?: boolean;
  label: string;
  padded: boolean;
  options: Option[];
  onSaveSelection: (index: number, value: any) => unknown;
  selectedOption: number;
};

interface State {
  showDropdown: boolean;
  selectedIndex: number;
}

@withStyles(({ color, shadow, font }) => ({
  padded: {
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  opaque: {
    backgroundColor: color.white,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: color.neutralDark
  },
  row: { flexDirection: "row", alignItems: "center" },
  icon: {
    flex: 1,
    margin: 6,
    lineHeight: font.size.medium
  },
  dropdownContainer: {
    backgroundColor: color.white,
    paddingVertical: 10,
    ...shadow()
  },
  optionsContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30
  },
  option: { paddingVertical: 5 },
  buttonContainer: { width: "100%" }
}))
export default class StyledDropdown extends React.Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    selectedOption: 0,
    padded: true
  };

  state: State = {
    selectedIndex:
      this.props.selectedOption >= 0 ? this.props.selectedOption : 0,
    showDropdown: false
  };

  componentDidUpdate({ selectedOption }: Props) {
    if (
      this.props.selectedOption !== selectedOption &&
      this.props.selectedOption >= 0
    ) {
      this.updateIndex(this.props.selectedOption);
    }
  }

  updateIndex = (index: number) => {
    this.setState({ selectedIndex: index });
  };

  handleSave = () => {
    const { selectedIndex } = this.state;
    this.setState({ showDropdown: false });
    this.props.onSaveSelection(
      selectedIndex,
      this.props.options[selectedIndex]
    );
  };

  toggle = () => {
    const { selectedOption } = this.props;
    const { selectedIndex, showDropdown } = this.state;

    this.setState({
      showDropdown: !showDropdown,
      selectedIndex:
        selectedIndex !== selectedOption ? selectedOption : selectedIndex
    });
  };

  render() {
    const { options, selectedOption, styles, theme, transparent, padded } =
      this.props;
    const { selectedIndex, showDropdown } = this.state;

    return (
      <FlexColumn>
        <View
          style={[padded && styles.rowContainer, !transparent && styles.opaque]}
        >
          <StyledText color="neutralDark">{this.props.label}</StyledText>
          <TouchableOpacity onPress={this.toggle}>
            <View style={styles.row}>
              <StyledText color="blue" bold>
                {options[selectedIndex]?.label}
              </StyledText>
              <View>
                <StyledIcon
                  name="sort-down"
                  variant="solid"
                  color={
                    showDropdown ? theme.color.orange : theme.color.neutralDark
                  }
                  size={theme.font.size.largest}
                  style={styles.icon}
                />
              </View>
            </View>
          </TouchableOpacity>
        </View>
        {this.state.showDropdown && (
          <View style={styles.dropdownContainer}>
            <View style={styles.optionsContainer}>
              {options.map((option, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      this.updateIndex(index);
                    }}
                  >
                    <StyledText
                      color={selectedIndex === index ? "blue" : "neutralDark"}
                      bold={selectedIndex === index}
                      fontSize={theme.font.size.large}
                      style={styles.option}
                    >
                      {option.label}
                    </StyledText>
                  </TouchableOpacity>
                );
              })}
              <View style={styles.buttonContainer}>
                <StyledButton
                  title="Save"
                  onPress={this.handleSave}
                  disabled={selectedOption === selectedIndex}
                />
              </View>
            </View>
          </View>
        )}
      </FlexColumn>
    );
  }
}
