/**
 * @generated SignedSource<<50f017252e610847abdb639d6f0a3a2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiDaySelectGigsStep_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly estimatedPayment: {
    readonly netPay: string;
  } | null | undefined;
  readonly gig: {
    readonly area: string | null | undefined;
  };
  readonly id: string;
  readonly insertedAt: string;
  readonly startsAt: string | null | undefined;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentType": "MultiDaySelectGigsStep_engagement";
};
export type MultiDaySelectGigsStep_engagement$key = {
  readonly " $data"?: MultiDaySelectGigsStep_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_engagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "MultiDaySelectGigsStep_engagement"
};

(node as any).hash = "bc2da8dee240745c365c993fadf1c30d";

export default node;
