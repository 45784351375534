import {
  AbsoluteContainer,
  Button,
  ContentArea,
  IconButton,
  Spacer,
  Surface,
  View
} from "@gigsmart/atorasu";
import React from "react";
import ShiftGigsInfoContent from "../../gig/my-gigs-tab/shift-gigs-info-content";
import { HIDE_SHIFT_GIG_EDUCATION_CARD, useUserValue } from "../../user-value";

const ShiftGigsDismissableCard = () => {
  const [hide, setHide] = useUserValue<boolean>(HIDE_SHIFT_GIG_EDUCATION_CARD);
  const handleClose = async () => await setHide(true);
  if (hide) return <Spacer />;

  return (
    <ContentArea>
      <Surface variant="shadow">
        <ContentArea gap="standard">
          <View />
          <ShiftGigsInfoContent showHeader />
          <Button
            outline
            testID="dismiss-btn"
            size="small"
            alignSelf="center"
            label="Got It"
            onPress={handleClose}
          />
        </ContentArea>
        <AbsoluteContainer right={8} top={12} left="auto">
          <IconButton
            testID="dismiss-icon-btn"
            name="times"
            align="center"
            onPress={handleClose}
          />
        </AbsoluteContainer>
      </Surface>
    </ContentArea>
  );
};

export default ShiftGigsDismissableCard;
