import {
  Column,
  ContentArea,
  ContentRow,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { PAYMENT_TYPES } from "@gigsmart/isomorphic-shared/gig/payment-types";
import { currency, time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { EngagementRatingStack } from "./EngagementRatingStack";

import type { WorkerProjectSummaryTab_engagement$key } from "./__generated__/WorkerProjectSummaryTab_engagement.graphql";

export function WorkerProjectSummaryTab({
  reviewOfRequester,
  reviewOfWorker,
  workerSummary,
  requesterSummary,
  payments
}: FragmentContainerInnerComponentProps<WorkerProjectSummaryTab_engagement$key>) {
  return (
    <Stack>
      <Surface>
        <Stack variant="divider">
          <ContentArea>
            <Text variant="header" color="primary">
              SUMMARY
            </Text>
          </ContentArea>
          {workerSummary && (
            <ContentRow justifyContent="space-between">
              <Stack size="slim">
                <Text>Marked as Complete</Text>
                <Text variant="note" color="neutral">
                  By You
                </Text>
              </Stack>
              <Text testID="worker-summary-date">{`${time.humanize(
                workerSummary?.insertedAt,
                "dateCompact"
              )} at ${time.humanize(workerSummary?.insertedAt)}`}</Text>
            </ContentRow>
          )}
          {requesterSummary && (
            <ContentRow justifyContent="space-between">
              <Stack size="slim">
                <Text>Marked as Complete</Text>
                <Text variant="note" color="neutral">
                  By Requester
                </Text>
              </Stack>
              <Text testID="requester-summary-date">{`${time.humanize(
                requesterSummary?.approvedAt,
                "dateCompact"
              )} at ${time.humanize(requesterSummary?.approvedAt)}`}</Text>
            </ContentRow>
          )}
        </Stack>
      </Surface>
      <Surface>
        <Stack variant="divider">
          <ContentArea>
            <Text variant="header" color="primary">
              PAYMENT STATUS
            </Text>
          </ContentArea>
          {!requesterSummary && (
            <ContentRow fill justifyContent="space-between">
              <Column>
                <Stack size="slim">
                  <Text>
                    Submitted on{" "}
                    {time.humanize(workerSummary?.insertedAt, "dateCompact")}
                  </Text>

                  <Text variant="note" color="neutral">
                    By You
                  </Text>
                </Stack>
              </Column>
              <Column>
                <Text testID="worker-summary-payment">
                  {workerSummary?.paid ? "Payment" : "Awaiting Payment"}{" "}
                  {
                    PAYMENT_TYPES[workerSummary?.paymentType ?? "IN_APP"]
                      .altLabel
                  }
                </Text>
              </Column>
            </ContentRow>
          )}
          {requesterSummary && requesterSummary?.paymentType !== "IN_APP" && (
            <ContentRow justifyContent="space-between">
              <Stack size="slim">
                <Text>
                  Submitted on{" "}
                  {time.humanize(workerSummary?.insertedAt, "dateCompact")}
                </Text>
                <Text variant="note" color="neutral">
                  By Requester
                </Text>
              </Stack>
              {workerSummary?.paymentType && (
                <Column fill={1} alignItems="flex-end">
                  <Text testID="requester-summary-payment">
                    Payment{" "}
                    {
                      PAYMENT_TYPES[workerSummary?.paymentType ?? "IN_APP"]
                        .altLabel
                    }
                  </Text>
                </Column>
              )}
            </ContentRow>
          )}
          {payments?.edges?.map((paymentEdge) => (
            <ContentRow
              key={paymentEdge?.node?.id}
              justifyContent="space-between"
            >
              <Stack size="slim">
                <Text>
                  Paid on{" "}
                  {time.humanize(paymentEdge?.node?.insertedAt, "dateCompact")}
                </Text>
                <Text variant="note" color="neutral">
                  By Requester
                </Text>
              </Stack>
              <Text>{currency.humanize(paymentEdge?.node?.amount)}</Text>
            </ContentRow>
          ))}
          {payments?.edges?.length ||
            (workerSummary?.paid === false &&
              workerSummary?.paymentType === "IN_APP" && (
                <ContentArea size="compact">
                  <Text align="center" color="neutral" variant="note">
                    In-App payments can be accessed through your Wallet.
                  </Text>
                </ContentArea>
              ))}
        </Stack>
      </Surface>
      <EngagementRatingStack
        variant="worker"
        reviewOfRequester={reviewOfRequester}
        reviewOfWorker={reviewOfWorker}
      />
    </Stack>
  );
}

export default createRelayFragmentContainer<WorkerProjectSummaryTab_engagement$key>(
  graphql`
    fragment WorkerProjectSummaryTab_engagement on Engagement {
      workerSummary: timesheet(variant: WORKER) {
        paid
        paymentType
        insertedAt
      }
      requesterSummary: timesheet(variant: FINAL) {
        paymentType
        approvedAt
      }
      reviewOfRequester {
        rating
        review
      }
      reviewOfWorker {
        rating
        review
      }
      payments(first: 25, query: "ORDER BY insertedAt ASC") {
        edges {
          node {
            id
            amount
            insertedAt
          }
        }
      }
    }
  `,
  WorkerProjectSummaryTab
);
