/**
 * @generated SignedSource<<4f0f67dea9519dd7bc6024e4f718def4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationRatingRow_org$data = {
  readonly logoUrl: string | null | undefined;
  readonly name: string;
  readonly overallRating: number;
  readonly primaryContact?: {
    readonly displayName: string | null | undefined;
  } | null | undefined;
  readonly reviewsReceived: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "OrganizationRatingRow_org";
};
export type OrganizationRatingRow_org$key = {
  readonly " $data"?: OrganizationRatingRow_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationRatingRow_org">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipPrimaryContact"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationRatingRow_org",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        }
      ],
      "concreteType": "EngagementReviewsConnection",
      "kind": "LinkedField",
      "name": "reviewsReceived",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "reviewsReceived(first:0)"
    },
    {
      "condition": "skipPrimaryContact",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "primaryContact",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5bda9c478c2da43c986bb26f496653c2";

export default node;
