import { InfoModal } from "@gigsmart/atorasu";
import React from "react";

import JobBoardInfoModalContent from "../../modals/JobBoardInfoModalContent";

export default function JobBoardInfoModal() {
  return (
    <InfoModal
      title="Job Board"
      headerIcon="newspaper"
      headerColor="primary"
      testID="job-board-info-modal"
      variant="button"
    >
      <JobBoardInfoModalContent />
    </InfoModal>
  );
}
