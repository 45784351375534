/**
 * @generated SignedSource<<0e3e452cb90d0fa41b8e1f8cb8bbca76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type tabContent_workerPayableAccount$data = {
  readonly " $fragmentSpreads": FragmentRefs<"paymentMethod_payableAccount">;
  readonly " $fragmentType": "tabContent_workerPayableAccount";
};
export type tabContent_workerPayableAccount$key = {
  readonly " $data"?: tabContent_workerPayableAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"tabContent_workerPayableAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "tabContent_workerPayableAccount",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "paymentMethod_payableAccount"
    }
  ],
  "type": "WorkerPayableAccount",
  "abstractKey": null
};

(node as any).hash = "82acc1fc0833a44f42d23ef87bf69f8d";

export default node;
