import React, { type ComponentProps } from "react";
import StyledTextInput from "../input/styled-text-input";
import { ccDigits, ccFormat } from "./credit-card-utils";
import { numericKeyboardType } from "./text-utils";

type Props = ComponentProps<typeof StyledTextInput> & {
  label: string;
  value?: string;
  onChangeText: (arg0: string) => unknown;
  error?: string | null | undefined;
  errors?: Error[];
};

const StyledCreditCardInput = ({ ...props }: Props) => (
  <StyledTextInput
    legacyImplementation
    normalize={ccDigits}
    format={ccFormat}
    placeholder="xxxx-xxxx-xxxx-xxxx"
    keyboardType={numericKeyboardType()}
    {...props}
  />
);

export default StyledCreditCardInput;
