/**
 * @generated SignedSource<<a51d3485dd941dabc806957cfd1c76b1>>
 * @relayHash 4247749544bb7d4071f0311f4395bfa8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:a6mIXhmvbtO4Ldz9glFmZdMrp2S2r2U8Iln4nZbTRFs

import { ConcreteRequest } from 'relay-runtime';
export type MarkAllAsReadButtonSubscription$variables = {
  userId: string;
};
export type MarkAllAsReadButtonSubscription$data = {
  readonly userMessageStatsUpdated: {
    readonly user: {
      readonly id: string;
      readonly userMessageStats: {
        readonly unreadCount: number;
      };
    };
  } | null | undefined;
};
export type MarkAllAsReadButtonSubscription = {
  response: MarkAllAsReadButtonSubscription$data;
  variables: MarkAllAsReadButtonSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserMessageStats",
  "kind": "LinkedField",
  "name": "userMessageStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkAllAsReadButtonSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMessageStatsUpdatedPayload",
        "kind": "LinkedField",
        "name": "userMessageStatsUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkAllAsReadButtonSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMessageStatsUpdatedPayload",
        "kind": "LinkedField",
        "name": "userMessageStatsUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:a6mIXhmvbtO4Ldz9glFmZdMrp2S2r2U8Iln4nZbTRFs",
    "metadata": {},
    "name": "MarkAllAsReadButtonSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "28035ee55cbd9c35e552023cc06631b4";

export default node;
