import {
  ActionRow,
  Button,
  Column,
  ContentArea,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  FormField,
  FormSubmit,
  Validator
} from "@gigsmart/fomu";
import usStateList from "@gigsmart/isomorphic-shared/app/us-state-list";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import FomuPickerV2 from "@gigsmart/seibutsu/fomu/inputs/FomuPickerV2";
import FomuSwitch from "@gigsmart/seibutsu/fomu/inputs/FomuSwitch";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import ProfileHeader from "@gigsmart/seibutsu/worker-profile/ProfileHeader";
import { DateTime } from "luxon";
import React from "react";

import FomuConfirmExit from "../fomu/inputs/FomuConfirmExit";
import useUserPronoun from "../user/useUserPronoun";
import type { EditProfileFormSetUserMobileMutation } from "./__generated__/EditProfileFormSetUserMobileMutation.graphql";
import type { EditProfileFormSetUserProfilePhotoMutation } from "./__generated__/EditProfileFormSetUserProfilePhotoMutation.graphql";
import type { EditProfileFormUpdateWorkerMutation } from "./__generated__/EditProfileFormUpdateWorkerMutation.graphql";
import type { EditProfileForm_worker$key } from "./__generated__/EditProfileForm_worker.graphql";

export const EditprofileForm = ({
  id: workerId,
  firstName,
  lastName,
  biography,
  birthdate,
  primaryMobile,
  street1,
  street2,
  locality,
  administrativeArea1,
  postalCode,
  profilePhoto,
  verification,
  result
}: FragmentContainerInnerComponentProps<EditProfileForm_worker$key>) => {
  const { pronounList, pronoun, setUserPronoun, unsetUserPronoun } =
    useUserPronoun();

  const [updateWorker] =
    useRelayMutationPromise<EditProfileFormUpdateWorkerMutation>(
      graphql`
        mutation EditProfileFormUpdateWorkerMutation(
          $input: UpdateWorkerInput!
        ) {
          updateWorker(input: $input) {
            worker {
              ...EditProfileForm_worker
            }
          }
        }
      `
    );

  const [setUserMobile] =
    useRelayMutationPromise<EditProfileFormSetUserMobileMutation>(
      graphql`
        mutation EditProfileFormSetUserMobileMutation(
          $input: SetUserMobileInput!
        ) {
          setUserMobile(input: $input) {
            userMobile {
              number
              confirmed
            }
            user {
              ... on Worker {
                ...EditProfileForm_worker
              }
            }
          }
        }
      `
    );

  const [setProfilePhoto] =
    useRelayMutationPromise<EditProfileFormSetUserProfilePhotoMutation>(
      graphql`
        mutation EditProfileFormSetUserProfilePhotoMutation(
          $input: SetUserProfilePhotoInput!
        ) {
          setUserProfilePhoto(input: $input) {
            user {
              ... on Worker {
                ...EditProfileForm_worker
              }
            }
          }
        }
      `
    );

  const initialValues = {
    firstName,
    lastName,
    biography,
    mobileNumber: (primaryMobile?.number ?? "").replace(/^\+1/, ""),
    birthdate: birthdate
      ? DateTime.fromFormat(birthdate, "yyyy-MM-dd").toFormat("MM/dd/yyyy")
      : null,
    street1,
    street2,
    locality,
    administrativeArea1,
    postalCode,
    profilePhotoPrivacy: profilePhoto?.public,
    pronoun: pronoun?.id
  };

  const saveEdits: FomuSubmitFn = async (
    {
      values: { profilePhotoPrivacy, mobileNumber, birthdate, pronoun, ...rest }
    },
    done,
    reset
  ) => {
    await updateWorker({
      input: {
        ...rest,
        birthdate: DateTime.fromFormat(birthdate, "MM/dd/yyyy").toISODate(),
        workerId
      }
    });
    if (profilePhotoPrivacy !== initialValues.profilePhotoPrivacy) {
      await setProfilePhoto({ input: { public: profilePhotoPrivacy } });
    }
    if (mobileNumber !== initialValues.mobileNumber) {
      await setUserMobile({
        input: { number: mobileNumber, userId: workerId }
      });
    }

    if (pronoun !== initialValues.pronoun) {
      pronoun === "reset" || pronoun === null
        ? await unsetUserPronoun({ input: { userId: workerId } })
        : await setUserPronoun({
            input: { userId: workerId, userPronounId: pronoun }
          });
    }

    reset();
    defaultBackHandler({ safeExit: true });
    toast.success("Your profile was successfully updated!");
  };
  const verificationOptIn = verification?.reports?.some(
    (report) =>
      report?.status !== "NOT_STARTED" && report?.status !== "NOT_ELIGIBLE"
  );
  return (
    <Form initialValues={initialValues} onSubmit={saveEdits}>
      <ProfileHeader workerRef={result ?? null} variant="avatar-only" />
      <Spacer />
      <ContentArea>
        <Stack>
          <FormField name="profilePhotoPrivacy">
            {({ value }) => (
              <ActionRow
                variant="outline"
                title="Public Profile Photo"
                note={
                  value
                    ? "Photo will be shown to everyone"
                    : "Photo will be hidden until Gig is accepted."
                }
                testID="profile-photo-privacy"
                right={<FomuSwitch name="profilePhotoPrivacy" />}
              />
            )}
          </FormField>
          <FomuTextInput
            label="First Name"
            placeholder="First Name"
            name="firstName"
            maxLength={50}
            autoCorrect={false}
            validates={Validator.name()}
            autoCapitalize="words"
            editable={!verificationOptIn}
          />
          <FomuTextInput
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            maxLength={50}
            autoCorrect={false}
            validates={Validator.name()}
            autoCapitalize="words"
            editable={!verificationOptIn}
          />
          <FomuPickerV2
            label="Pronoun (optional)"
            testID="pronoun-picker"
            eventTargetName="Input Pronoun Picker"
            placeholder="Pronoun (optional)"
            options={[
              ...pronounList.map(({ id, name }) => ({
                label: name,
                value: id
              })),
              { label: "", value: "reset" }
            ]}
            name="pronoun"
          />
          <FomuTextInput
            label="Professional Summary"
            placeholder="What makes you great? Write a summary of how your unique experience, skills, and passion result in excellent work."
            name="biography"
            maxLength={250}
            validates={[
              Validator.length({ max: 250 }),
              Validator.noEmailAddressesOrGmail(),
              Validator.noPhoneNumbers()
            ]}
            autoCapitalize="sentences"
            multiline
          />
          <Stack horizontal>
            <FomuTextInput
              label="Mobile Number"
              placeholder="(123) 456-7890"
              name="mobileNumber"
              mask="phoneNumber"
              note="Only accessible during active Gigs"
              validates={Validator.phoneNumber()}
              keyboardType="number-pad"
              fill
            />
            <FomuTextInput
              label="Birthdate"
              placeholder="MM/DD/YYYY"
              name="birthdate"
              mask="date"
              validates={Validator.date({
                message: "Invalid date of birth",
                format: "MM/DD/YYYY",
                options: {
                  min: DateTime.fromJSDate(new Date())
                    .minus({ years: 105 })
                    .toJSDate(),
                  max: DateTime.fromJSDate(new Date())
                    .minus({ years: 14 })
                    .toJSDate()
                }
              })}
              keyboardType="numeric"
              fill
            />
          </Stack>
          <FomuTextInput
            label="Street 1"
            placeholder="Street Address"
            name="street1"
            autoCorrect={false}
          />
          <FomuTextInput
            label="Street 2 (optional)"
            placeholder="Street Address (optional)"
            name="street2"
            autoCorrect={false}
          />
          <FomuTextInput
            label="City"
            placeholder="City"
            name="locality"
            autoCorrect={false}
            validates={Validator.presence()}
          />
          <Stack horizontal>
            <Column fill>
              <FomuPickerV2
                label="State"
                eventTargetName="Input State Picker"
                name="administrativeArea1"
                validates={Validator.presence()}
                options={usStateList.map((state) => ({
                  value: state,
                  label: state
                }))}
                placeholder="State"
              />
            </Column>
            <FomuTextInput
              label="Zip Code"
              placeholder="Zip Code"
              name="postalCode"
              validates={Validator.zipcode()}
              keyboardType="numeric"
              fill
            />
          </Stack>
          <FormSubmit>
            {({ submit, submitting, invalid, dirty }) => (
              <Button
                testID="save-edit-profile"
                label="Save"
                onPress={submit}
                disabled={submitting || invalid || !dirty}
              />
            )}
          </FormSubmit>
        </Stack>
      </ContentArea>
      <FomuConfirmExit />
    </Form>
  );
};

export default createRelayFragmentContainer<EditProfileForm_worker$key>(
  graphql`
    fragment EditProfileForm_worker on Worker {
      ...ProfileHeaderRefreshed_worker @arguments(skipIfNotWorker: false)
      id
      firstName
      lastName
      street1
      street2
      locality
      administrativeArea1
      postalCode
      birthdate
      biography
      primaryMobile {
        number
      }
      profilePhoto {
        public
      }
      verification {
        reports {
          status
        }
      }
    }
  `,
  EditprofileForm
);
