/**
 * @generated SignedSource<<57e442f7b9674e664d60333feec8cc9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PickupShiftTopicPreview_worker$data = {
  readonly availableGigs: {
    readonly edges: ReadonlyArray<{
      readonly estimatedPayment: {
        readonly netPay: string;
        readonly payRate: string;
      } | null | undefined;
      readonly node: {
        readonly gigSeries: {
          readonly boosted: boolean;
          readonly id: string;
        };
        readonly id: string;
        readonly name: string | null | undefined;
        readonly organization: {
          readonly logoUrl: string | null | undefined;
        } | null | undefined;
        readonly pickupEligible: boolean;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "PickupShiftTopicPreview_worker";
};
export type PickupShiftTopicPreview_worker$key = {
  readonly " $data"?: PickupShiftTopicPreview_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"PickupShiftTopicPreview_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PickupShiftTopicPreview_worker",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "kind": "Literal",
          "name": "gigTypes",
          "value": [
            "PAID",
            "VOLUNTEER"
          ]
        },
        {
          "kind": "Literal",
          "name": "maxDistance",
          "value": 75
        },
        {
          "kind": "Literal",
          "name": "pickupEligible",
          "value": true
        }
      ],
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "availableGigs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableGigsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementPaymentInfo",
              "kind": "LinkedField",
              "name": "estimatedPayment",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payRate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPay",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pickupEligible",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigSeries",
                  "kind": "LinkedField",
                  "name": "gigSeries",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "boosted",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "logoUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "availableGigs(first:3,gigTypes:[\"PAID\",\"VOLUNTEER\"],maxDistance:75,pickupEligible:true)"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "8ce649048a53b8607fc8b3b9c93523a9";

export default node;
