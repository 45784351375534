import { Column } from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { confirmPrompt, dismiss } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React, { useState } from "react";
import EngagementDisputeSummary from "./EngagementDisputeSummary";
import type { EngagementDisputeSummaryStackQuery } from "./__generated__/EngagementDisputeSummaryStackQuery.graphql";
import type { EngagementDisputeSummaryStackWithdrawDisputeMutation } from "./__generated__/EngagementDisputeSummaryStackWithdrawDisputeMutation.graphql";
import type { EngagementDisputeSummaryStack_engagement$key } from "./__generated__/EngagementDisputeSummaryStack_engagement.graphql";

export default createSuspendedQueryContainer<
  EngagementDisputeSummaryStackQuery,
  {
    engagementId: string;
  }
>(
  function EngagementDisputeSummaryStackContainer({ response }) {
    return (
      <EngagementDisputeSummaryStackFragment fragmentRef={response?.node} />
    );
  },
  {
    query: graphql`
      query EngagementDisputeSummaryStackQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            ...EngagementDisputeSummaryStack_engagement
          }
        }
      }
    `,
    variables: ({ engagementId }) => ({ engagementId })
  }
);

export function EngagementDisputeSummaryStack({
  openDisputes,
  approvedDisputes,
  rejectedDisputes,
  finalTimesheet
}: FragmentContainerInnerComponentProps<EngagementDisputeSummaryStack_engagement$key>) {
  const history = useHistory();
  const approvedDispute = approvedDisputes?.edges?.[0]?.node;
  const rejectedDispute = rejectedDisputes?.edges?.[0]?.node;
  const modifiedDispute =
    (finalTimesheet?.resolvedDisputesCount?.totalCount ?? 0) >= 1
      ? finalTimesheet
      : null;
  const openDispute = openDisputes?.edges?.[0]?.node;
  const disputeTimesheet =
    openDispute?.disputeTarget ??
    approvedDispute?.disputeTarget ??
    modifiedDispute ??
    rejectedDispute?.disputeTarget;
  const disputeStatus = openDispute
    ? "PENDING"
    : approvedDispute
      ? "APPROVED"
      : modifiedDispute
        ? "MODIFIED"
        : rejectedDispute
          ? "REJECTED"
          : null;
  const isRejected = disputeStatus === "REJECTED";
  const [hasDispute, setHasDispute] = useState(!!disputeStatus);
  const [commit] =
    useRelayMutation<EngagementDisputeSummaryStackWithdrawDisputeMutation>(graphql`
      mutation EngagementDisputeSummaryStackWithdrawDisputeMutation(
        $input: SetEngagementDisputeApprovalInput!
      ) {
        setEngagementDisputeApproval(input: $input) {
          engagementDispute {
            id
          }
        }
      }
    `);
  const handleWithdrawPress = () => {
    confirmPrompt({
      title: "Are you sure you want to withdraw your Dispute?",
      subTitle: "",
      cancelLabel: "No",
      onDo: () => {
        commit(
          {
            input: {
              engagementDisputeId: openDispute?.id ?? "",
              disposition: "WITHDRAWN"
            }
          },
          {
            onSuccess: () => {
              toast.notice("Your Dispute has been successfully withdrawn");
              setHasDispute(false);
            }
          }
        );
      },
      onCancel: dismiss
    });
  };
  const timesheetDisputedComment = (
    openDispute ??
    approvedDispute ??
    rejectedDispute
  )?.comments?.find((comment) => comment?.type === "TIMESHEET")?.body;
  const additionalPayDisputedComment = (
    openDispute ??
    approvedDispute ??
    rejectedDispute
  )?.comments?.find((comment) => comment?.type === "ADDITIONAL_PAYMENT")?.body;
  if (!hasDispute) return null;
  return (
    <Column gap="standard">
      <EngagementDisputeSummary
        fragmentRef={disputeTimesheet ?? null}
        disputeStatus={disputeStatus}
        noResponse={
          rejectedDispute?.approval?.submittedBy?.__typename === "SystemUser"
        }
        isRejected={isRejected}
        approvedAt={
          rejectedDispute?.approval?.approvedAt ??
          approvedDispute?.approval?.approvedAt
        }
        expiresAt={openDispute?.expiresAt}
        rejectedDisputeComment={rejectedDispute?.approval?.comment ?? undefined}
        timesheetComment={timesheetDisputedComment}
        additionalPayComment={additionalPayDisputedComment}
        onEscalateDispute={() => history.push("/support")}
        onWithdrawDispute={handleWithdrawPress}
      />
      {disputeStatus === "MODIFIED" && (
        <EngagementDisputeSummary
          fragmentRef={rejectedDispute?.disputeTarget}
          collapsible
          isRejected
          disputeStatus={"REJECTED"}
          approvedAt={rejectedDispute?.approval?.approvedAt}
          timesheetComment={timesheetDisputedComment}
          additionalPayComment={additionalPayDisputedComment}
          onEscalateDispute={() => history.push("/support")}
          onWithdrawDispute={handleWithdrawPress}
          rejectedDisputeComment=""
        />
      )}
    </Column>
  );
}

const EngagementDisputeSummaryStackFragment =
  createRelayFragmentContainer<EngagementDisputeSummaryStack_engagement$key>(
    graphql`
      fragment EngagementDisputeSummaryStack_engagement on Engagement {
        openDisputes: disputes(
          first: 1
          query: "WHERE approvalDisposition = NULL"
        ) {
          edges {
            node {
              id
              expiresAt
              comments {
                type
                body
              }
              disputeTarget {
                ... on EngagementTimesheet {
                  ...EngagementDisputeSummary_EngagementTimesheet
                  paymentStyle
                  disputedFinalTimesheet {
                    diff {
                      billableDuration
                      basePayment
                      totalPayment
                      totalWorkerServiceFeeAmount
                      additionalPayment
                    }
                    engagementTimesheet {
                      paymentInfo {
                        billableDuration
                        netPay
                        payRate
                      }
                    }
                  }
                  id
                  paymentInfo {
                    billableDuration
                    netPay
                    payRate
                  }
                }
              }
            }
          }
        }
        approvedDisputes: disputes(
          first: 1
          query: "WHERE approvalDisposition = APPROVED"
        ) {
          edges {
            node {
              id
              approval {
                approvedAt
              }
              comments {
                type
                body
              }
              disputeTarget {
                ... on EngagementTimesheet {
                  id
                  ...EngagementDisputeSummary_EngagementTimesheet
                  paymentStyle
                  disputedFinalTimesheet {
                    diff {
                      billableDuration
                      basePayment
                      totalPayment
                      totalWorkerServiceFeeAmount
                      additionalPayment
                    }
                    engagementTimesheet {
                      paymentInfo {
                        billableDuration
                        netPay
                        payRate
                      }
                    }
                  }
                  paymentInfo {
                    billableDuration
                    netPay
                    payRate
                  }
                }
              }
            }
          }
        }
        rejectedDisputes: disputes(
          first: 1
          query: "WHERE ApprovalDisposition = REJECTED"
        ) {
          edges {
            node {
              id
              comments {
                type
                body
              }
              expiresAt
              approval {
                approvedAt
                comment
                submittedBy {
                  __typename
                }
              }
              disputeTarget {
                ... on EngagementTimesheet {
                  ...EngagementDisputeSummary_EngagementTimesheet
                  id
                  paymentStyle
                  disputedFinalTimesheet {
                    diff {
                      billableDuration
                      basePayment
                      totalPayment
                      totalWorkerServiceFeeAmount
                      additionalPayment
                    }
                    engagementTimesheet {
                      paymentInfo {
                        billableDuration
                        netPay
                        payRate
                      }
                    }
                  }
                  paymentInfo {
                    billableDuration
                    netPay
                    payRate
                  }
                }
              }
            }
          }
        }
        finalTimesheet: timesheet(variant: FINAL) {
          id
          ...EngagementDisputeSummary_EngagementTimesheet
          paymentStyle
          disputedFinalTimesheet {
            diff {
              billableDuration
              basePayment
              totalPayment
              totalWorkerServiceFeeAmount
              additionalPayment
            }
            engagementTimesheet {
              paymentInfo {
                billableDuration
                netPay
                payRate
              }
            }
          }
          paymentInfo {
            billableDuration
            netPay
            payRate
          }
          resolvedDisputesCount: resolvedDisputes(first: 0) {
            totalCount
          }
          approvedDisputes: disputes(
            first: 1
            query: "WHERE approvalDisposition = APPROVED"
          ) {
            edges {
              node {
                id
                approval {
                  approvedAt
                }
                expiresAt
                comments {
                  type
                  body
                }
                disputeTarget {
                  ...EngagementDisputeSummary_EngagementTimesheet
                }
              }
            }
          }
          rejectedDisputes: disputes(
            first: 1
            query: "WHERE ApprovalDisposition = REJECTED"
          ) {
            edges {
              node {
                id
                comments {
                  type
                  body
                }
                approval {
                  approvedAt
                  comment
                  submittedBy {
                    __typename
                  }
                }
                disputeTarget {
                  ...EngagementDisputeSummary_EngagementTimesheet
                }
              }
            }
          }
        }
      }
    `,
    EngagementDisputeSummaryStack
  );
