import { toast } from "@gigsmart/atorasu";
import {
  disableFeature,
  enableFeature,
  getFlags
} from "@gigsmart/feature-flags/registry";
import { Redirect } from "@gigsmart/kaizoku";
import React from "react";

export default function FlagSet({
  flags,
  enable
}: {
  flags: string[];
  enable: boolean;
}) {
  const featureFlags = getFlags();
  flags.forEach((flag) => {
    if (flag && featureFlags[flag]) {
      enable ? void enableFeature(flag, true) : void disableFeature(flag, true);
      toast.success(`${flag} was ${enable ? "enabled" : "disabled"}`);
    } else {
      toast.error(`${flag} is not a valid feature flag`);
    }
  });
  return <Redirect to="/" />;
}
