/**
 * @generated SignedSource<<338206e61e7e4052bbb804ab5733dab3>>
 * @relayHash e78ac2e876a8fc94360fdc9cd25c0efa
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:M5QmF1WNC73IO0YjeTbZZcO-x63PFcV__ediE2amwXg

import { ConcreteRequest } from 'relay-runtime';
export type shiftGroupChatsScreenWorkerSubscription$variables = Record<PropertyKey, never>;
export type shiftGroupChatsScreenWorkerSubscription$data = {
  readonly userMessageStatsUpdated: {
    readonly user: {
      readonly id: string;
      readonly userMessageStats: {
        readonly unreadCount: number;
      };
    };
  } | null | undefined;
};
export type shiftGroupChatsScreenWorkerSubscription = {
  response: shiftGroupChatsScreenWorkerSubscription$data;
  variables: shiftGroupChatsScreenWorkerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "conversationTypes",
      "value": [
        "SHIFT_GROUP"
      ]
    }
  ],
  "concreteType": "UserMessageStats",
  "kind": "LinkedField",
  "name": "userMessageStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    }
  ],
  "storageKey": "userMessageStats(conversationTypes:[\"SHIFT_GROUP\"])"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "shiftGroupChatsScreenWorkerSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMessageStatsUpdatedPayload",
        "kind": "LinkedField",
        "name": "userMessageStatsUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "shiftGroupChatsScreenWorkerSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMessageStatsUpdatedPayload",
        "kind": "LinkedField",
        "name": "userMessageStatsUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:M5QmF1WNC73IO0YjeTbZZcO-x63PFcV__ediE2amwXg",
    "metadata": {},
    "name": "shiftGroupChatsScreenWorkerSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "9b0aeb123abe0bea8e2e26d9ad8b0147";

export default node;
