import React, { type ReactElement } from "react";
import { View } from "react-native";
import { type IconName, StyledIcon } from "../icon";
import { Spacer } from "../spacer";
import { useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  iconName: IconName;
  message: string | ReactElement;
  minHeight?: number | string;
}

const EmptyViewAlt = ({ iconName, message, minHeight }: Props) => {
  const { styles } = useStyles(({ font, color, unit, units }) => ({
    container: {
      borderRadius: unit.borderRadius,
      borderColor: color.neutralMediumGray,
      borderWidth: units(0.5),
      backgroundColor: "#fff",
      padding: units(4),
      justifyContent: "center",
      marginHorizontal: units(4)
    },
    iconWrapper: {
      width: 58,
      height: 58,
      borderRadius: 29,
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      backgroundColor: color.neutralLightGray
    },
    icon: {
      fontSize: 32,
      color: color.neutralDark2
    },
    message: {
      ...font.withSize(font.size.small),
      textAlign: "center"
    }
  }));
  return (
    <View style={[styles.container, !!minHeight && { minHeight }]}>
      <View style={styles.iconWrapper}>
        <StyledIcon variant="solid" name={iconName} style={styles.icon} />
      </View>
      <Spacer />
      <KatanaText style={styles.message}>{message}</KatanaText>
      <Spacer units={2} />
    </View>
  );
};

export default EmptyViewAlt;
