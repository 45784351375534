import { noop } from "lodash";
import type { StripeNativePayOptions } from "./types";
export * from "./stripeRestApi";

export const createNativePayToken = async (
  _arg0: StripeNativePayOptions,
  _arg1: "apple" | "google"
) => undefined;
export const completeNativePayRequest = noop;
export const cancelNativePayRequest = noop;
export const supportsNativePay = () => false;
