import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop,
  toast
} from "@gigsmart/atorasu";
import type { MultiSelectionRowGroupOption } from "@gigsmart/atorasu";
import { showCommercialDriversLicenseModal } from "@gigsmart/seibutsu/worker/CommercialDriversLicenseModal";
import { showDeleteCommercialDriversLicenseModal } from "@gigsmart/seibutsu/worker/useDeleteCommercialDriversLicenseModal";
import useWorkerQualificationProof from "@gigsmart/seibutsu/worker/useWorkerQualificationProof";
import React from "react";

import { useQualifications } from "./hooks/useQualifications";

export default function CommercialDriversLicenseScreen() {
  const {
    title,
    subTitle,
    reminderText,
    iconName,
    options,
    proofId,
    proofStatus,
    onBackPress,
    checkOption
  } = useQualifications({
    qualificationTitle: "Commercial Driver's License"
  });
  const { setProofOnWorkerQualification, unsetProofOnWorkerQualification } =
    useWorkerQualificationProof();

  const onComplete = async ({
    value,
    onValueChange,
    id,
    label,
    workerQualificationProofId = ""
  }: MultiSelectionRowGroupOption & {
    workerQualificationProofId?: string;
  }) => {
    onValueChange?.(value);
    checkOption({
      value,
      id,
      label,
      onCompleted: async (workerQualification) => {
        try {
          if (workerQualification) {
            if (value)
              await setProofOnWorkerQualification({
                input: {
                  workerQualificationId: workerQualification.id,
                  workerQualificationProofId:
                    proofStatus === "VALID" && proofId
                      ? proofId
                      : workerQualificationProofId
                }
              });
            else
              await unsetProofOnWorkerQualification({
                input: {
                  workerQualificationId: workerQualification.id
                }
              });
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    });
  };

  const handleEdit = async ({ label }: MultiSelectionRowGroupOption) => {
    showCommercialDriversLicenseModal({
      title: label ?? "",
      onComplete: () => null
    });
  };

  const handleCheck = async (
    { value, label, id, onValueChange }: MultiSelectionRowGroupOption,
    values: MultiSelectionRowGroupOption[]
  ) => {
    if (value && (proofStatus !== "VALID" || !proofId)) {
      showCommercialDriversLicenseModal({
        title: label ?? "",
        onComplete: (workerQualificationProofId) =>
          void onComplete({
            workerQualificationProofId,
            value,
            onValueChange,
            label,
            id
          })
      });
    } else if (
      !value &&
      (proofId || proofStatus === "VALID") &&
      values.every((it) => !it.value)
    ) {
      showDeleteCommercialDriversLicenseModal();
      onComplete({ value, onValueChange, label, id });
    } else void onComplete({ value, onValueChange, label, id });
  };
  if (!options || options?.length === 0) return null;
  return (
    <ScreenScroll testID="cdl-screen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="cdl-screen-layout"
          header={title ?? ""}
          note={subTitle}
          searchPlaceholder={`Search ${title}`}
          searchHeader="Do you have the following Commercial Driver’s Licenses?"
          selectionRowHeader={title ?? ""}
          selectionRowHeaderIcon={iconName}
          reminder={reminderText}
          options={options}
          autoChecking={false}
          onCheckOption={handleCheck}
          onEdit={handleEdit}
        />
      </ContentArea>
    </ScreenScroll>
  );
}
