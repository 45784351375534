import { toLower } from "lodash";
import React, { type ReactNode, useMemo, useState, useEffect } from "react";
import { Platform, View } from "react-native";

import { useStyles, useViewport } from "../../style";
import KatanaTouchableOpacity from "../../tappable/katana-touchable-opacity";
import { KatanaText } from "../../text";

export interface Tab {
  label: string;
  smallLabel?: string;
  largeLabel?: string;
  Contents: ReactNode;
  testID?: string;
  onPress?: () => void;
}

interface Props {
  tabs: Tab[];
  defaultTab?: string | null | undefined;
  onChangeTab?: (tabName: string) => void;
  shadowOff?: boolean;
  tabContainerShadowOff?: boolean;
  limitLabelContainerOnWeb?: boolean | number;
  roundTop?: boolean;
}

export default function KatanaTabs({
  defaultTab,
  onChangeTab,
  tabs,
  shadowOff,
  tabContainerShadowOff,
  limitLabelContainerOnWeb,
  roundTop
}: Props) {
  const [activeTab, setActiveTab] = useState(defaultTab ?? tabs[0]?.label);
  const { breakpoint } = useViewport();
  useEffect(() => {
    if (defaultTab) setActiveTab(defaultTab);
  }, [defaultTab]);

  const { styles } = useStyles(({ color, font, shadow, units }) => ({
    tabContainer: {
      ...Platform.select({
        web: {
          alignItems: "center",
          backgroundColor: color.white
        }
      })
    },
    tabContainerShadow: {
      ...Platform.select({
        web: {
          ...shadow()
        }
      })
    },
    tabContainerRoundTop: {
      borderTopLeftRadius: units(1),
      borderTopRightRadius: units(1)
    },
    labelContainer: {
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      zIndex: 2,
      elevation: 3,
      backgroundColor: color.white,
      ...Platform.select({
        web: !!limitLabelContainerOnWeb && {
          maxWidth: "100%",
          width:
            typeof limitLabelContainerOnWeb === "boolean"
              ? 400
              : limitLabelContainerOnWeb
        }
      })
    },
    labelContainerShadow: {
      ...shadow()
    },
    labelWrapper: {
      flex: 1
    },
    label: {
      minHeight: units(11.5),
      paddingTop: units(4),
      borderBottomWidth: 1,
      borderColor: color.neutralLight,
      shadowColor: color.neutralLight,
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.8,
      shadowRadius: 1
    },
    noShadow: {
      shadowOpacity: 0
    },
    labelText: {
      ...font.body("bold"),
      textAlign: "center",
      fontSize: font.size.medium,
      color: color.inactiveText
    },
    labelUnderline: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: units(1),
      backgroundColor: color.blue
    },
    activeLabelText: {
      color: color.blue
    }
  }));

  const labelNormalizer = (label: string) =>
    label
      .split(/[^a-z]/i)
      .map(toLower)
      .join("-");
  const renderActiveTab = useMemo(
    () =>
      tabs.find(({ label }) => label === activeTab)?.Contents ??
      tabs[0]?.Contents,
    [activeTab, tabs]
  );

  const getLabel = ({
    smallLabel,
    label,
    largeLabel
  }: {
    smallLabel?: string;
    label: string;
    largeLabel?: string;
  }) => {
    if (["@none", "@phone", "@phoneDown"].includes(breakpoint) && smallLabel)
      return smallLabel;
    if (["@desktop", "@desktopWide"].includes(breakpoint) && largeLabel)
      return largeLabel;
    return label;
  };

  return (
    <>
      <View
        style={
          !!limitLabelContainerOnWeb && [
            styles.tabContainer,
            !tabContainerShadowOff && styles.tabContainerShadow
          ]
        }
      >
        <View
          style={[
            styles.labelContainer,
            !shadowOff && styles.labelContainerShadow,
            !!roundTop && [styles.tabContainerRoundTop]
          ]}
        >
          {tabs.map(({ label, testID, onPress, smallLabel, largeLabel }) => {
            const key = testID || `${labelNormalizer(label)}-tab-label`;
            const isActive = activeTab === label;
            return (
              <View key={key} style={styles.labelWrapper}>
                <KatanaTouchableOpacity
                  style={[styles.label, shadowOff && styles.noShadow]}
                  testID={key}
                  eventTargetName=""
                  onPress={() => {
                    setActiveTab(label);
                    onChangeTab?.(label);
                    onPress?.();
                  }}
                >
                  <KatanaText
                    style={[
                      styles.labelText,
                      isActive && styles.activeLabelText
                    ]}
                  >
                    {getLabel({ smallLabel, label, largeLabel })}
                  </KatanaText>
                </KatanaTouchableOpacity>
                {isActive && (
                  <View pointerEvents="none" style={styles.labelUnderline} />
                )}
              </View>
            );
          })}
        </View>
      </View>
      {renderActiveTab}
    </>
  );
}
