import { toast } from "@gigsmart/atorasu";
import { captureError } from "@gigsmart/dekigoto";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type GraphQLTaggedNode,
  graphql,
  promiseMutation,
  useRelayMutationPromise,
  useRelayOrchestrator
} from "@gigsmart/relay";
import { useCallback } from "react";

import type {
  UserType,
  useLoginMutation
} from "./__generated__/useLoginMutation.graphql";
import type { useLoginUpdateAdminMutation } from "./__generated__/useLoginUpdateAdminMutation.graphql";
import type { useLoginUpdateRequesterMutation } from "./__generated__/useLoginUpdateRequesterMutation.graphql";
import type { useLoginUpdateWorkerMutation } from "./__generated__/useLoginUpdateWorkerMutation.graphql";

export type AuthorizedCallback = (
  id: string,
  cred: string,
  userProps?: { firstName?: string | null; lastName?: string | null }
) => unknown;

type UpdateMutation =
  | useLoginUpdateWorkerMutation
  | useLoginUpdateRequesterMutation
  | useLoginUpdateAdminMutation;

const UPDATE_MUTATION_NODE: { [key in UserType]?: GraphQLTaggedNode } = {
  WORKER: graphql`
    mutation useLoginUpdateWorkerMutation(
      $firstName: String
      $lastName: String
    ) {
      updateWorker(input: { firstName: $firstName, lastName: $lastName }) {
        worker {
          firstName
          lastName
        }
      }
    }
  `,
  REQUESTER: graphql`
    mutation useLoginUpdateRequesterMutation(
      $firstName: String
      $lastName: String
    ) {
      updateRequester(input: { firstName: $firstName, lastName: $lastName }) {
        requester {
          firstName
          lastName
        }
      }
    }
  `,
  ADMIN: graphql`
    mutation useLoginUpdateAdminMutation(
      $firstName: String
      $lastName: String
    ) {
      updateAdmin(input: { firstName: $firstName, lastName: $lastName }) {
        admin {
          firstName
          lastName
        }
      }
    }
  `
};

interface ProcessLoginInput {
  input: Omit<useLoginMutation["variables"]["input"], "userType">;
  userProps?: { firstName?: string | null; lastName?: string | null };
  onLogin?: () => void;
}

export default function useLogin(userType: UserType) {
  const relay = useRelayOrchestrator();
  const history = useHistory();

  const handleError = useCallback(
    (err: string | Error, fallBackMsg: string) => {
      captureError(typeof err === "string" ? new Error(err) : err);
      toast.error(
        __DEV__ ? (typeof err === "string" ? err : err.message) : fallBackMsg
      );
    },
    []
  );

  const [login, { errors: mutationErrors }] =
    useRelayMutationPromise<useLoginMutation>(graphql`
      mutation useLoginMutation($input: AuthenticateUserInput!) {
        authenticateUser(input: $input) {
          token
        }
      }
    `);

  const processLogin = async ({
    input,
    userProps,
    onLogin
  }: ProcessLoginInput) => {
    const mutation = UPDATE_MUTATION_NODE[userType];
    if (!mutation) return;

    try {
      const res = await login({ input: { ...input, userType } });
      const newEnvironment = await relay.reset(res.authenticateUser?.token);
      if (
        newEnvironment &&
        mutation &&
        userProps?.firstName &&
        userProps.lastName
      ) {
        // we don't need to wait updateUser before redirect the user
        void promiseMutation<UpdateMutation>(newEnvironment, {
          mutation,
          variables: userProps
        });
      }

      if (onLogin) onLogin();
      else history.push("/");
    } catch (err) {}
  };

  return { processLogin, handleError, mutationErrors };
}
