import { ContentArea, Stack, Text } from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { RatingStars } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { ReviewRow_EngagementReview$key } from "./__generated__/ReviewRow_EngagementReview.graphql";

export function ReviewRow({
  author,
  rating,
  insertedAt,
  skill,
  review
}: FragmentContainerInnerComponentProps<ReviewRow_EngagementReview$key>) {
  return (
    <ContentArea>
      <Stack>
        <Stack horizontal justifyContent="space-between">
          <Text color="primary" weight="bold">
            {author?.displayName}
          </Text>
          <Text>{time.humanize(insertedAt, "dateCompact")}</Text>
        </Stack>
        <Text weight="semibold">{skill?.name}</Text>
        <RatingStars
          rating={rating}
          color="spiceOrange"
          disabled
          fontSize={16}
        />
        {review ? (
          <Text variant="note" color="neutral">
            {review}
          </Text>
        ) : (
          <Text variant="note" color="neutral">
            No written review provided.
          </Text>
        )}
      </Stack>
    </ContentArea>
  );
}

export default createRelayFragmentContainer<ReviewRow_EngagementReview$key>(
  graphql`
    fragment ReviewRow_EngagementReview on EngagementReview {
      id
      skill {
        name
      }
      review
      rating
      author {
        displayName
      }
      insertedAt
    }
  `,
  ReviewRow
);
