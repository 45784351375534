import {
  Column,
  type FilterTabOption,
  FilterTabs,
  IconText,
  Text
} from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import DismissableCard from "@gigsmart/seibutsu/shared/DismissableCard";
import { compact } from "lodash";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../../navigation/types";
import type { ShiftGigsFilter_worker$key } from "./__generated__/ShiftGigsFilter_worker.graphql";
import type {
  CqlFilterEngagementInput,
  CqlOrderEngagementInput
} from "./__generated__/ShiftGigsTabQuery.graphql";

export type ShiftFilterType = NonNullable<
  WorkerParamList["MyGigs"]["shiftsTab"]
>;

const allStates: EngagementStateName[] = [
  "PAUSED",
  "WORKING",
  "EN_ROUTE",
  "RUNNING_LATE",
  "AWAITING_START",
  "SCHEDULED",
  "CONFIRMING",
  "PENDING_TIMESHEET_APPROVAL"
];

const hiredStates: EngagementStateName[] = [
  "PAUSED",
  "WORKING",
  "EN_ROUTE",
  "RUNNING_LATE",
  "AWAITING_START",
  "SCHEDULED"
];

export const SHIFT_QUERY_STATES: Record<
  ShiftFilterType,
  CqlFilterEngagementInput
> = {
  shifts: {
    _or: [
      {
        currentStateName: { _in: allStates }
      },
      {
        currentStateName: { _eq: "ENDED" },
        anyEngagementDisputeDisposition: { _isNull: true }
      }
    ]
  },
  hired: {
    currentStateName: { _in: hiredStates }
  },
  confirming: {
    currentStateName: { _eq: "CONFIRMING" }
  },
  applied: {
    currentStateName: { _eq: "APPLIED" }
  },
  timesheetReview: {
    currentStateName: { _eq: "PENDING_TIMESHEET_APPROVAL" },
    workerCanApproveTimesheet: { _eq: true }
  },
  paymentPending: {
    currentStateName: { _eq: "PENDING_TIMESHEET_APPROVAL" },
    workerCanApproveTimesheet: { _eq: false }
  },
  disputePending: {
    currentStateName: { _eq: "ENDED" },
    anyEngagementDisputeDisposition: { _isNull: true }
  },
  pendingOffers: {
    currentStateName: { _in: ["OFFERED"] }
  },
  bidSubmitted: {
    currentStateName: { _eq: "BID_REVIEW" }
  },
  bidRequests: {
    currentStateName: { _eq: "BID_REQUESTED" }
  }
};

const makeOrderBy = (
  filter: ShiftFilterType = "shifts"
): CqlOrderEngagementInput[] => {
  switch (filter) {
    case "shifts":
      return [
        {
          currentStateName: {
            priority: [...allStates, "ENDED"],
            direction: "ASC"
          }
        },
        { startsAt: { direction: "ASC_NULLS_FIRST" } }
      ];
    case "hired":
      return [
        { currentStateName: { priority: hiredStates, direction: "ASC" } },
        { startsAt: { direction: "DESC_NULLS_FIRST" } }
      ];
    case "applied":
    case "confirming":
    case "bidSubmitted":
    case "bidRequests":
    case "pendingOffers":
      return [{ startsAt: { direction: "ASC_NULLS_FIRST" } }];
    case "paymentPending":
    case "disputePending":
      return [{ gigEndsAt: { direction: "ASC_NULLS_FIRST" } }];
    case "timesheetReview":
      return [{ currentStateTransitionedAt: { direction: "ASC" } }];
    default:
      throw new Error("Invalid params");
  }
};

export const makeShiftQuery = (filter: ShiftFilterType = "shifts") => {
  const where: CqlFilterEngagementInput = {
    ...SHIFT_QUERY_STATES[filter],
    gigType: { _neq: "PROJECT" }
  };
  return { where, orderBy: makeOrderBy(filter) };
};

type Props = {
  active?: ShiftFilterType;
  onChange: (value: ShiftFilterType) => void;
};

export const ShiftGigsFilter = ({
  appliedCount,
  confirmingCount,
  pendingOffersCount,
  disputePendingCount,
  hiredCount,
  paymentPendingCount,
  shiftsCount,
  timesheetReviewCount,
  bidSubmittedCount,
  bidRequestsCount,
  active = "shifts",
  result,
  onChange
}: FragmentContainerInnerComponentProps<ShiftGigsFilter_worker$key, Props>) => {
  const filters = useMemo(
    () =>
      compact<FilterTabOption & { key: ShiftFilterType }>([
        {
          key: "shifts",
          label: pluralize(shiftsCount?.totalCount ?? 0, "Shifts", false),
          count: shiftsCount?.totalCount ?? 0
        },
        { key: "hired", label: "Hired", count: hiredCount?.totalCount ?? 0 },
        {
          key: "confirming",
          label: "Confirming",
          count: confirmingCount?.totalCount ?? 0
        },
        HourlyRateBids.select({
          key: "pendingOffers",
          label: pluralize(
            shiftsCount?.totalCount ?? 0,
            "Pending Offer",
            false
          ),
          count: pendingOffersCount?.totalCount ?? 0
        }),
        HourlyRateBids.select({
          key: "bidRequests",
          label: pluralize(bidRequestsCount?.totalCount, "Bid Request", false),
          count: bidRequestsCount?.totalCount
        }),
        {
          key: "timesheetReview",
          label: "Timesheet Review",
          count: timesheetReviewCount?.totalCount
        },
        {
          key: "paymentPending",
          label: "Payment Pending",
          count: paymentPendingCount?.totalCount
        },
        {
          key: "disputePending",
          label: "Dispute Pending",
          count: disputePendingCount?.totalCount ?? 0
        },
        HourlyRateBids.select(
          {
            key: "bidSubmitted",
            label: "Bid Submitted",
            count: bidSubmittedCount?.totalCount ?? 0
          },
          {
            key: "applied",
            label: "Applied",
            count: appliedCount?.totalCount ?? 0
          }
        )
      ]),
    [result]
  );

  const index = filters.findIndex((it) => it.key === active);
  return (
    <Column gap="standard">
      <FilterTabs
        tabs={filters}
        value={index}
        onChange={(idx) => {
          const newValue = filters[idx]?.key;
          if (newValue) onChange(newValue);
        }}
      />
      {HourlyRateBids.select(
        null,
        <DismissableCard
          testID="applied-info-card"
          userValueKey="MY_GIGS_APPLIED_INFO_CARD"
        >
          <Column gap="standard">
            <IconText icon="user-check" color="primary" textWeight="bold">
              Note: Shift Applications
            </IconText>
            <Text>
              The list is meant to help you manage upcoming Shifts. Any Shifts
              you have applied to will not appear here. You can view Shifts you
              have applied to in the Applied tab.
            </Text>
          </Column>
        </DismissableCard>
      )}
    </Column>
  );
};

export default createRelayFragmentContainer<ShiftGigsFilter_worker$key, Props>(
  graphql`
    fragment ShiftGigsFilter_worker on Worker
    @argumentDefinitions(
      shiftsWhere: { type: "CqlFilterEngagementInput!" }
      hiredWhere: { type: "CqlFilterEngagementInput!" }
      confirmingWhere: { type: "CqlFilterEngagementInput!" }
      pendingOffersWhere: { type: "CqlFilterEngagementInput!" }
      appliedWhere: { type: "CqlFilterEngagementInput!" }
      timesheetReviewWhere: { type: "CqlFilterEngagementInput!" }
      paymentPendingWhere: { type: "CqlFilterEngagementInput!" }
      disputePendingWhere: { type: "CqlFilterEngagementInput!" }
      bidSubmittedWhere: { type: "CqlFilterEngagementInput!" }
      bidRequestsWhere: { type: "CqlFilterEngagementInput!" }
    ) {
      shiftsCount: engagements(first: 0, where: $shiftsWhere) {
        totalCount
      }
      hiredCount: engagements(first: 0, where: $hiredWhere) {
        totalCount
      }
      confirmingCount: engagements(first: 0, where: $confirmingWhere) {
        totalCount
      }
      pendingOffersCount: engagements(first: 0, where: $pendingOffersWhere) {
        totalCount
      }
      appliedCount: engagements(first: 0, where: $appliedWhere) {
        totalCount
      }
      timesheetReviewCount: engagements(
        first: 0
        where: $timesheetReviewWhere
      ) {
        totalCount
      }
      paymentPendingCount: engagements(first: 0, where: $paymentPendingWhere) {
        totalCount
      }
      disputePendingCount: engagements(first: 0, where: $disputePendingWhere) {
        totalCount
      }
      bidSubmittedCount: engagements(first: 0, where: $bidSubmittedWhere) {
        totalCount
      }
      bidRequestsCount: engagements(first: 0, where: $bidRequestsWhere) {
        totalCount
      }
    }
  `,
  ShiftGigsFilter
);
