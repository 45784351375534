/**
 * @generated SignedSource<<34e3a7fa3686c230629b6f8e6c78d07d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGigFields_gigOrEngagement$data = {
  readonly requiredQualifications?: ReadonlyArray<{
    readonly gigField: {
      readonly iconName: string;
      readonly id: string;
      readonly order: number;
      readonly title: string;
    };
    readonly id: string;
    readonly label: string;
  }>;
  readonly " $fragmentType": "useGigFields_gigOrEngagement";
};
export type useGigFields_gigOrEngagement$key = {
  readonly " $data"?: useGigFields_gigOrEngagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGigFields_gigOrEngagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useGigFields_gigOrEngagement"
};

(node as any).hash = "02c73b7900fa96536d2871855673b99c";

export default node;
