import { Button, Column, Row, Text } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showEngagementBidHistoryModal } from "../../engagement/bids/EngagementBidHistoryModal";
import SummaryRow from "../../gig/SummaryRows/SummaryRow";
import WorkerPaymentRows from "./WorkerPaymentRows";
import type { WorkerPaymentSummaryRow_shift$key } from "./__generated__/WorkerPaymentSummaryRow_shift.graphql";

const showAsOffered: EngagementStateName[] = [
  // pre-scheduled states
  "APPLIED",
  "BID_REQUESTED",
  "BID_REVIEW",
  "OFFERED",
  "CONFIRMING",

  // inactive states
  "MISSED",
  "APPLICATION_DENIED",
  "APPLICATION_CANCELED",
  "REJECTED",
  "CANCELED",
  "CANCELED_WITH_PAY"
];

type Props = {
  workerStateName?: EngagementStateName;
  engagementId?: string;
  negotiatedPayRate?: string | null;
};

/**
 * This component is not compatible with Project Gigs.
 */
export const WorkerPaymentSummaryRow = ({
  id,
  acceptsTips,
  pickupEligible,
  payRate,
  nextGig,
  workerStateName,
  engagementId,
  negotiatedPayRate
}: FragmentContainerInnerComponentProps<
  WorkerPaymentSummaryRow_shift$key,
  Props
>) => {
  const available = nextGig?.edges?.[0];
  if (available?.node) {
    pickupEligible ??= available.node.pickupEligible;
    payRate ??= available.node.payRate;
  }

  return (
    <SummaryRow icon="dollar-sign" title="Payment" editVariant="none">
      <Column gap="standard">
        <Row>
          <Text fill>
            {pickupEligible
              ? "Pick Up Rate"
              : HourlyRateBids.select(
                  !workerStateName || showAsOffered.includes(workerStateName)
                    ? "Offered Hourly Rate"
                    : "Accepted Hourly Rate",
                  "Hourly Rate"
                )}
          </Text>
          <Text>{currency.humanizeRate(negotiatedPayRate ?? payRate)}</Text>
        </Row>
        {!!available?.node && (
          <WorkerPaymentRows showTips={acceptsTips} fragmentRef={available} />
        )}
        {!!engagementId && !!workerStateName && (
          <Button
            alignSelf="flex-start"
            size="small"
            outline
            label="View Bid History"
            testID="view-bid-history-button"
            onPress={() => showEngagementBidHistoryModal({ engagementId })}
          />
        )}
      </Column>
    </SummaryRow>
  );
};

export default createRelayFragmentContainer<
  WorkerPaymentSummaryRow_shift$key,
  Props
>(
  graphql`
    fragment WorkerPaymentSummaryRow_shift on GigLike {
      id
      acceptsTips
      ... on Gig {
        payRate
        pickupEligible
      }
      ... on GigSeries {
        nextGig: availableGigs(first: 1) {
          edges {
            ...WorkerPaymentRows_availableGig
            node {
              payRate
              pickupEligible
            }
          }
        }
      }
    }
  `,
  WorkerPaymentSummaryRow
);
