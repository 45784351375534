import {
  Button,
  Column,
  Link,
  ModalFooter,
  Spacer,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React from "react";
import type { DeleteAccountMutation } from "./__generated__/DeleteAccountMutation.graphql";
import type { DeleteAccountPrimaryContactGigsQuery } from "./__generated__/DeleteAccountPrimaryContactGigsQuery.graphql";

type Props = {
  label?: string;
};

export default createSuspendedQueryContainer<
  DeleteAccountPrimaryContactGigsQuery,
  Props
>(
  function DeleteAccount({
    label = "Permanently Delete My Account",
    response: { member } = {}
  }) {
    const history = useHistory();
    const [submitting, setSubmitting] = React.useState(false);

    const requesterWithGigs =
      member?.__typename === "OrganizationRequester"
        ? (member?.requester?.gigs?.totalCount ?? 0) > 0
        : member?.__typename === "Requester"
          ? (member?.gigs?.totalCount ?? 0) > 0
          : false;

    const workerWithGigs =
      member?.__typename === "Worker" &&
      (member?.engagements?.totalCount ?? 0) > 0;

    const [deleteAccount] = useRelayMutation<DeleteAccountMutation>(graphql`
      mutation DeleteAccountMutation($input: RemoveUserInput!) {
        removeUser(input: $input) {
          removedUserId
        }
      }
    `);

    const warningText =
      member?.__typename === "Worker" ? (
        workerWithGigs ? (
          <Text align="center" variant="body">
            Your account cannot be deleted while you have an active Gig. Once
            your Gig is fully completed you can delete your account.
          </Text>
        ) : (
          <Text align="center" variant="body">
            Are you sure you want to delete your account?{" "}
            <Text align="center" variant="body" weight="bold">
              Please note that deleting your account is final, and there is no
              option to restore it once it has been deleted.
            </Text>{" "}
            If your account was permanently restricted, deleting the account
            will not circumvent our{" "}
            <Link
              openURL="https://gigsmart.com/find-work/account-reinstatement-information/"
              testID="delete-account-reinstatement-process-link"
            >
              reinstatement process
            </Link>{" "}
            if you wish to work on the platform in the future.
          </Text>
        )
      ) : requesterWithGigs ? (
        <Text align="center" variant="body">
          You cannot delete your account because you are the Primary Contact on
          current Shifts. Please assign another Organization Member as the
          Primary Contact on your Shifts in order to delete your account.
        </Text>
      ) : (
        <Text align="center" variant="body">
          Are you sure you want to delete your account?{" "}
          <Text align="center" variant="body" weight="bold">
            Please note that deleting your account is final, and there is no
            option to restore it once it has been deleted.
          </Text>
        </Text>
      );

    const handleDeleteAccount = (close: () => void) => {
      setSubmitting(true);
      const userId =
        member?.__typename === "OrganizationRequester"
          ? member?.requester?.id
          : member?.id;
      if (!userId) return;
      deleteAccount(
        { input: { userId } },
        {
          onSuccess: () => {
            setTimeout(() => {
              history.push("/");
            }, 500);
            setTimeout(() => {
              toast.success("Your account was successfully deleted.");
            }, 1000);
            close();
          },
          onError: () => {
            toast.error("There was an error deleting your account.");
          }
        }
      );
      setSubmitting(false);
    };

    const handlePress = () =>
      showModal({
        eventContext: "",
        title: "Permanently Delete My Account",
        children: (close: any) => (
          <Stack justifyContent="center">
            {warningText}
            {requesterWithGigs ? (
              <Button
                label="Ok"
                testID="req-with-gigs-ok-button"
                onPress={close}
              />
            ) : (
              <Column>
                <Button
                  label="No, Do Not Delete"
                  variant="clear"
                  fullWidth
                  onPress={close}
                  testID="cancel-delete-account-button"
                />
                <Spacer size="compact" />
                <Button
                  label="Yes, Delete"
                  fullWidth
                  disabled={
                    submitting ||
                    !member?.id ||
                    requesterWithGigs ||
                    workerWithGigs
                  }
                  onPress={() => handleDeleteAccount(close)}
                  leftAccessory={<Spacer horizontal size="compact" />}
                  testID="submit-delete-account-button"
                />
              </Column>
            )}
            <ModalFooter />
          </Stack>
        )
      });

    return (
      <Button
        testID="delete-account-btn"
        outline
        label={label}
        onPress={handlePress}
      />
    );
  },
  {
    query: graphql`
      query DeleteAccountPrimaryContactGigsQuery(
        $requesterFilter: CqlFilterGigInput!
        $workerFilter: CqlFilterEngagementInput!
        $userId: ID!
      ) {
        member: node(id: $userId) {
          id
          __typename
          ... on Worker {
            engagements(first: 0, where: $workerFilter) {
              totalCount
            }
          }
          ... on Requester {
            gigs(first: 0, where: $requesterFilter) {
              totalCount
            }
          }
          ... on OrganizationRequester {
            displayName
            requester {
              id
              gigs(first: 0, where: $requesterFilter) {
                totalCount
              }
            }
          }
        }
      }
    `,
    FallbackComponent: true,
    variables: () => {
      const user = useCurrentUser();
      const userId = user?.id ?? "";
      return {
        userId,
        workerFilter: {
          currentStateName: {
            _in: ["WORKING", "PAUSED", "PENDING_TIMESHEET_APPROVAL"]
          }
        },
        requesterFilter: {
          primaryContactId: { _eq: userId },
          gigType: { _neq: "PROJECT" },
          currentStateName: {
            _in: ["PENDING_REVIEW", "IN_PROGRESS", "ACTIVE", "UPCOMING"]
          }
        }
      };
    }
  }
);
