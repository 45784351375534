/**
 * @generated SignedSource<<212cdc59974ea693fdfd4bddbf3b57d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type walletTabs_workerPayableAccount$data = {
  readonly " $fragmentSpreads": FragmentRefs<"priorTransfers_workerPayableAccount">;
  readonly " $fragmentType": "walletTabs_workerPayableAccount";
};
export type walletTabs_workerPayableAccount$key = {
  readonly " $data"?: walletTabs_workerPayableAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"walletTabs_workerPayableAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "walletTabs_workerPayableAccount",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "priorTransfers_workerPayableAccount"
    }
  ],
  "type": "WorkerPayableAccount",
  "abstractKey": null
};

(node as any).hash = "0ff8dba660252a9e4ee747ba46c9f82b";

export default node;
