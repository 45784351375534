import { HighlightedReminder, type IconName } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import type { WorkerUpdatedTimesheetReminder_engagement$key } from "./__generated__/WorkerUpdatedTimesheetReminder_engagement.graphql";

interface Props {
  spacerSize?: "large" | "standard";
  tabName?: "timesheet" | "summary";
}

const WorkerUpdatedTimesheetReminder = ({
  currentState,
  gig,
  workerTimesheet,
  finalTimesheet,
  systemTimesheet,
  spacerSize = "large",
  tabName = "summary"
}: FragmentContainerInnerComponentProps<
  WorkerUpdatedTimesheetReminder_engagement$key,
  Props
>) => {
  const message = computeMessage({
    finalTimesheet,
    workerTimesheet,
    systemTimesheet,
    currentState,
    tabName
  });

  let iconName: IconName =
    currentState.name === "ENDED" ? "circle-check" : "share";
  if (
    finalTimesheet?.paymentStyle !== "CALCULATED" ||
    (!finalTimesheet?.submittedBy && tabName === "summary")
  ) {
    iconName = "circle-info";
  }

  if (!message) return null;
  return <HighlightedReminder icon={iconName} header={message} />;
};

function computeMessage({
  finalTimesheet,
  workerTimesheet,
  systemTimesheet,
  currentState,
  tabName
}: Pick<
  FragmentContainerInnerComponentProps<
    WorkerUpdatedTimesheetReminder_engagement$key,
    Props
  >,
  | "finalTimesheet"
  | "workerTimesheet"
  | "systemTimesheet"
  | "currentState"
  | "tabName"
>) {
  if (finalTimesheet) {
    if (finalTimesheet.paymentStyle !== "CALCULATED") {
      return "This Requester has opted to pay you without a timesheet.";
    }

    const neverStarted = systemTimesheet?.startedCount?.totalCount === 0;
    if (neverStarted && finalTimesheet.submittedBy?.__typename === "Worker") {
      return;
    }

    const approvedTimesheet =
      finalTimesheet.submittedBy?.__typename === "Worker"
        ? "your Updated Timesheet"
        : finalTimesheet.submittedBy?.__typename === "Requester"
          ? "a Timesheet they updated"
          : "the Original Timesheet";

    return `The Requester has approved ${approvedTimesheet}. Your final hourly pay is based on ${
      tabName === "summary"
        ? "the timesheet in the Timesheet tab."
        : "the timesheet below."
    }`;
  }

  if (
    currentState.name === "PENDING_TIMESHEET_APPROVAL" &&
    workerTimesheet?.isOverridden &&
    !workerTimesheet.editable
  ) {
    return "Your Updated Timesheet has been sent to the Requester. Please note, the Requester has the option to approve your Updated Timesheet, the Original Timesheet, or a Timesheet they create.";
  }
}

export default createRelayFragmentContainer<
  WorkerUpdatedTimesheetReminder_engagement$key,
  Props
>(
  graphql`
    fragment WorkerUpdatedTimesheetReminder_engagement on Engagement {
      currentState {
        name
      }
      gig {
        gigType
      }
      systemTimesheet: timesheet(variant: SYSTEM) {
        startedCount: states(first: 0, query: "WHERE action = START") {
          totalCount
        }
      }
      finalTimesheet: timesheet(variant: FINAL) {
        paymentStyle
        submittedBy {
          __typename
        }
      }
      workerTimesheet: timesheet(variant: WORKER) {
        isOverridden
        editable
      }
    }
  `,
  WorkerUpdatedTimesheetReminder
);
