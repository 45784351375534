/**
 * @generated SignedSource<<7daf3a355f34cdd8ba825f225a1a9b38>>
 * @relayHash c05b7f7d007f7f5a2c1bb68faf8c617a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:FThAW-3FVF0UzE5HCyWMgNc9LZk6gVGnl3gB2XX8N78

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectPaymentsAndHiresListSurfaceQuery$variables = {
  query: string;
};
export type DirectPaymentsAndHiresListSurfaceQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DirectPaymentsAndHiresListSurface_Root">;
};
export type DirectPaymentsAndHiresListSurfaceQuery = {
  response: DirectPaymentsAndHiresListSurfaceQuery$data;
  variables: DirectPaymentsAndHiresListSurfaceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DirectPaymentsAndHiresListSurfaceQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "DirectPaymentsAndHiresListSurface_Root"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DirectPaymentsAndHiresListSurfaceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DirectHireClaimsConnection",
        "kind": "LinkedField",
        "name": "directHireClaims",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectHireClaimsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectHireClaim",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "directHireType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "workerPayout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logoUrl",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "responseDeadline",
                    "storageKey": null
                  },
                  {
                    "alias": "rejected",
                    "args": [
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE state = REJECTED"
                      }
                    ],
                    "concreteType": "DirectHireClaimTransitionsConnection",
                    "kind": "LinkedField",
                    "name": "transitions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DirectHireClaimTransitionsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DirectHireClaimTransition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AccessContext",
                                "kind": "LinkedField",
                                "name": "transitionedBy",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "agent",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "transitions(first:1,query:\"WHERE state = REJECTED\")"
                  },
                  {
                    "alias": null,
                    "args": [
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE state = DISBURSED"
                      }
                    ],
                    "concreteType": "DirectHireClaimTransitionsConnection",
                    "kind": "LinkedField",
                    "name": "transitions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DirectHireClaimTransitionsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DirectHireClaimTransition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "transitions(first:1,query:\"WHERE state = DISBURSED\")"
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "query"
        ],
        "handle": "connection",
        "key": "DirectPaymentsAndHiresListSurface_directHireClaims",
        "kind": "LinkedHandle",
        "name": "directHireClaims"
      }
    ]
  },
  "params": {
    "id": "qpid:FThAW-3FVF0UzE5HCyWMgNc9LZk6gVGnl3gB2XX8N78",
    "metadata": {},
    "name": "DirectPaymentsAndHiresListSurfaceQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7361aeed0c8242f36bc070c6b7b7edee";

export default node;
