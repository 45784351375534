import { Linking } from "@gigsmart/atorasu";
import parsePhoneNumber from "libphonenumber-js";

export const SUPPORT_PHONE_NUMBER = "+17208287720";

export const SUPPORT_PHONE_NUMBER_FMT = "720-828-7720";

export function callSupport() {
  void Linking.openURL(`tel:${SUPPORT_PHONE_NUMBER}`);
}

export function formatPhoneNumber(number: string) {
  return parsePhoneNumber(number.replace(/^\+1/, ""), "US")?.formatNational();
}

export function formatPhoneNumberInput(number: string) {
  return parsePhoneNumber(number.replace(/^\+1/, ""), "US")
    ?.formatInternational()
    .replace(/[^0-9+]/g, "");
}
