import React, { type ReactNode } from "react";
import { View } from "react-native";

import { FlexRow } from "../flex";
import { type IconName, StyledIcon } from "../icon";
import { useStyles } from "../style";
import { StyledText } from "../text";

interface Props {
  readonly iconName?: IconName;
  readonly label?: string;
  readonly children: ReactNode;
  readonly subtext?: string | null | undefined;
  readonly subtextColor?: string;
  readonly testID?: string;
}

export default ({
  iconName,
  label,
  children,
  subtext,
  subtextColor,
  testID
}: Props) => {
  const { styles, theme } = useStyles(({ color, units }) => ({
    container: {
      backgroundColor: color.white,
      padding: units(4),
      borderRadius: 3
    },
    icon: {
      marginRight: units(2)
    },
    row: {
      paddingBottom: units(4)
    },
    subtextRow: {
      marginTop: units(2)
    },
    border: {
      borderBottomWidth: 1,
      borderBottomColor: color.neutralLight
    }
  }));
  return (
    <View style={styles.container} testID={testID}>
      {label && (
        <FlexRow style={styles.row}>
          {iconName && (
            <StyledIcon
              name={iconName}
              variant="solid"
              color="blue"
              style={styles.icon}
              size={theme.font.size.large}
            />
          )}
          <StyledText bold>{label}</StyledText>
        </FlexRow>
      )}
      <View style={styles.border} />
      {children}
      {!!subtext && (
        <FlexRow justifyContent="center" style={styles.subtextRow}>
          <StyledText
            color={subtextColor}
            fontSize={theme.font.size.small}
            center
          >
            {subtext}
          </StyledText>
        </FlexRow>
      )}
    </View>
  );
};
