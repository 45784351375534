import {
  Spacer,
  Stack,
  type StepProps,
  StepSubmit,
  Text
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import React from "react";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";
import useUpdateWorkerMutation from "./hooks/use-update-worker-mutation";
import type { OnboardingData } from "./types";

type Props = StepProps<OnboardingData>;

export default function SelfIntroductionStep({ stepper }: Props) {
  const updateWorker = useUpdateWorkerMutation();
  const handleSubmit: FomuSubmitFn = async ({ values }) => {
    await updateWorker?.({
      firstName: values.firstName,
      lastName: values.lastName
    });
    stepper.nextStep();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Text>Let's start by telling us your first and last name.</Text>
      <Spacer />
      <Spacer size="compact" />
      <Stack>
        <FomuTextInput
          name="firstName"
          validates={Validator.name()}
          label="First Name"
          maxLength={50}
          autoCorrect={false}
          autoFocus
          testID="first-name-input"
        />
        <FomuTextInput
          name="lastName"
          validates={Validator.name()}
          label="Last Name"
          maxLength={50}
          autoCorrect={false}
          keyboardType="email-address"
          testID="last-name-input"
          note="Only the first initial from last name will be visible to requesters."
        />
      </Stack>
      <StepSubmit testID="next-btn" label="Next" />
    </Form>
  );
}
