import { Button, Column, HighlightedReminder } from "@gigsmart/atorasu";
import { graphql, readRelayInlineFragment } from "@gigsmart/relay";
import React from "react";
import type { CurrentUserShape } from "../current-user";
import type { helpersWorker_engagement$key } from "./__generated__/helpersWorker_engagement.graphql";

export interface WorkerReports {
  status: string;
  type: string;
  statusDescription: string;
}

export function toFormattedString(number: number, delimiter = ",") {
  return addDelimiter(number.toString(), delimiter);
}

export function addDelimiter(string: string, delimiter = ",") {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}

export function checkIsWorkerVerified(
  requiredReportTypes?: readonly string[] | null,
  user?: Pick<NonNullable<CurrentUserShape>, "verification"> | null
): boolean {
  const userReports = user?.verification?.reports;

  if (!requiredReportTypes?.length) return true;
  if (!userReports?.length) return false;
  return requiredReportTypes.every((report) => {
    return !userReports.find(
      (it) => it.type === report && it.status !== "PASS"
    );
  });
}

export function getWaitingStartState(
  fragmentRef?: helpersWorker_engagement$key | null
) {
  const engagement = readRelayInlineFragment(
    graphql`
      fragment helpersWorker_engagement on Engagement @inline {
        currentState {
          name
          action
        }
        capabilities {
          type
          status
        }
      }
    `,
    fragmentRef ?? null
  );

  const isWaitingStart = engagement?.currentState?.name === "AWAITING_START";
  const isAutoArrive =
    isWaitingStart && engagement?.currentState?.action === "AUTO_ARRIVE";
  const canStart = engagement?.capabilities?.find(
    (it) => it.status === "AVAILABLE" && it.type === "START"
  );

  if (canStart || !isWaitingStart) return null;
  if (isAutoArrive) return "auto-arrive";
  return "refresh-location";
}

export function renderWaitingStartNode(
  fragmentRef?: helpersWorker_engagement$key | null,
  onRefreshLocation?: () => void
) {
  switch (getWaitingStartState(fragmentRef)) {
    case "auto-arrive":
      return (
        <HighlightedReminder
          header="You can start your clock 15 minutes before the scheduled Shift start time."
          icon="circle-info"
        />
      );
    case "refresh-location":
      return onRefreshLocation ? (
        <Column gap="standard">
          <HighlightedReminder
            icon="location-exclamation"
            header="You must be closer to the Shift location to start your own clock. The Requester can always start the clock for you."
          />
          <Button
            key="refreshLocation"
            icon="redo"
            label="Refresh Location"
            outline
            fill
            onPress={onRefreshLocation}
            testID={"refreshLocationButton"}
          />
        </Column>
      ) : (
        <HighlightedReminder
          header="You must be closer to the Shift location to start your own clock. The Requester can always start the clock for you."
          icon="location-exclamation"
        />
      );
    default:
      return null;
  }
}
