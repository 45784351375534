/**
 * @generated SignedSource<<851e2a8023795a5d4d513e0ee0dcd121>>
 * @relayHash 740fc46f90c21e3ca491933c936157a7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:CGEmglN8pH5-Zl8S4wDQM2TZGbgM9AKCsPF99kSTaWI

import { ConcreteRequest } from 'relay-runtime';
export type RemoveWorkerWorkHistoryInput = {
  workerWorkHistoryId: string;
};
export type useDeleteProfileItemWorkHistoryMutation$variables = {
  input: RemoveWorkerWorkHistoryInput;
};
export type useDeleteProfileItemWorkHistoryMutation$data = {
  readonly removeWorkerWorkHistory: {
    readonly removedWorkerWorkHistoryId: string | null | undefined;
  } | null | undefined;
};
export type useDeleteProfileItemWorkHistoryMutation = {
  response: useDeleteProfileItemWorkHistoryMutation$data;
  variables: useDeleteProfileItemWorkHistoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveWorkerWorkHistoryPayload",
    "kind": "LinkedField",
    "name": "removeWorkerWorkHistory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedWorkerWorkHistoryId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteProfileItemWorkHistoryMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteProfileItemWorkHistoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:CGEmglN8pH5-Zl8S4wDQM2TZGbgM9AKCsPF99kSTaWI",
    "metadata": {},
    "name": "useDeleteProfileItemWorkHistoryMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "625f6874bfdf15155f9850c1eb24682f";

export default node;
