import { FooterSpacer, ScreenSectionList, ScreenTop } from "@gigsmart/atorasu";
import React, { type ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import NotificationRow from "./notification-row";
import NotificationSectionFooter from "./notification-section-footer";
import NotificationSectionHeader from "./notification-section-header";
import type { NotificationSection } from "./use-notification-section";
import useReadAll from "./use-read-all-notifications";

import type { notificationRow_message$data } from "./__generated__/notificationRow_message.graphql";
import MissingPermissionsCard from "./missing-permissions-card";

const styles = StyleSheet.create({
  separator: { height: 10 }
});

export interface Props {
  ListHeaderComponent?: ReactNode;
  showBackButton?: boolean;
  sections: NotificationSection[];
  onPress?: (entry: notificationRow_message$data) => void;
}

export default function NotificationList({
  sections,
  onPress,
  showBackButton,
  ListHeaderComponent = null
}: Props) {
  const handleReadAll = useReadAll();
  return (
    <ScreenSectionList
      constraint="medium"
      testID="notifications-page"
      sections={sections}
      ListHeaderComponent={
        <>
          <ScreenTop extraSpace showBackButton={showBackButton} />
          {ListHeaderComponent}
          <MissingPermissionsCard size="none" />
        </>
      }
      ListFooterComponent={<FooterSpacer size="large" />}
      keyExtractor={(item, idx) => item?.id ?? `${idx}`}
      renderItem={({ section, item }) => (
        <NotificationRow
          isNew={section.isNew}
          message={item}
          onPress={onPress}
        />
      )}
      renderSectionHeader={({ section }) => (
        <NotificationSectionHeader
          section={section as NotificationSection}
          onReadAll={handleReadAll}
        />
      )}
      renderSectionFooter={({ section }) => (
        <>
          <NotificationSectionFooter section={section as NotificationSection} />
          <View style={styles.separator} />
        </>
      )}
    />
  );
}
