import { Column, FooterSpacer, Screen, Spacer } from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { Form, type ValueObject } from "@gigsmart/fomu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import EngagementInfoRow from "@gigsmart/seibutsu/engagement/EngagementInfoRow";
import RateAndReview from "@gigsmart/seibutsu/engagement/RateAndReview";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { reviewRequesterScreenMutation } from "./__generated__/reviewRequesterScreenMutation.graphql";
import type { reviewRequesterScreenQuery } from "./__generated__/reviewRequesterScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "ReviewRequester">;

export default createSuspendedQueryContainer<reviewRequesterScreenQuery, Props>(
  function ReviewRequesterScreen({
    route,
    navigation,
    response: { node } = {}
  }) {
    const engagementId = route.params?.id ?? "";
    const submitReviewMutationSpec = graphql`
      mutation reviewRequesterScreenMutation(
        $input: AddEngagementReviewInput!
      ) {
        addEngagementReview(input: $input) {
          newEngagementReviewEdge {
            node {
              rating
              review
              engagement {
                gigType
                capabilities {
                  type
                  status
                }
              }
            }
          }
        }
      }
    `;

    const [submitReview] = useRelayMutation<reviewRequesterScreenMutation>(
      submitReviewMutationSpec
    );
    const onSubmit = ({ values }: ValueObject) => {
      const { rating, review } = values;

      submitReview(
        {
          input: {
            rating,
            review,
            engagementId
          }
        },
        {
          onSuccess: (res) => {
            const isProject =
              res.addEngagementReview?.newEngagementReviewEdge?.node?.engagement
                .gigType === "PROJECT";
            toast.success("Review successfully submitted!");
            navigation.replace(isProject ? "ProjectDetails" : "ShiftDetails", {
              id: engagementId
            });
          }
        }
      );
    };

    return (
      <Screen testID="review-requester-screen">
        <EngagementInfoRow fragmentRef={node} />
        <Spacer />
        <Column fill>
          <Form onSubmit={onSubmit}>
            <RateAndReview
              displayName={node?.gig?.requester?.displayName || ""}
            />
          </Form>
        </Column>
        <FooterSpacer />
      </Screen>
    );
  },
  {
    query: graphql`
      query reviewRequesterScreenQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            ...EngagementInfoRow_engagement
            gig {
              requester {
                displayName
              }
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({
      engagementId: route.params?.id ?? ""
    })
  }
);
