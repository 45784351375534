import React, {
  type ComponentProps,
  type ComponentType,
  type ReactNode,
  type ReactElement
} from "react";

import { toast } from "@gigsmart/atorasu";
import {
  Button,
  ContentArea,
  ScrollView,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import {
  Form,
  FormSubmit,
  FormToggle,
  FormValue,
  NestedForm,
  Validator
} from "@gigsmart/fomu";
import { FlashMessage, StyledView } from "@gigsmart/katana";

import PaymentScreenHeader from "./payment-screen-header";
import StripeTermsOfService from "./stripe-terms-of-service";

type AccountComponentType = Record<string, ComponentType<any>>;

export interface CustomSubmitButtonProps {
  disabled: boolean;
  onPress: () => void;
  testID: string;
  label: string;
  onPressDisabled:
    | (() => "")
    | ((timeout?: number | undefined) => void)
    | undefined;
}

type Props = Omit<ComponentProps<typeof Form>, "children"> &
  ComponentProps<typeof PaymentScreenHeader> & {
    buttonText?: string;
    accountComponents: AccountComponentType;
    showBottomNote?: boolean;
    renderTerms: boolean;
    renderPaymentNotes?: () => ReactNode;
    disabledSubmitToast?: string;
    allowContinueWithoutSave?: boolean;
    onContinueWithoutSave?: () => void | Promise<void>;
    customSubmitRenderer?: (props: CustomSubmitButtonProps) => ReactElement;
    scrollEnabled?: boolean;
  };

const PaymentScreen = ({
  icons,
  headerText,
  cardHeader,
  buttonText = "Verify and Continue",
  onSubmit,
  initialValues,
  accountComponents,
  showBottomNote = true,
  renderTerms,
  renderPaymentNotes,
  disabledSubmitToast,
  allowContinueWithoutSave,
  onContinueWithoutSave,
  customSubmitRenderer,
  scrollEnabled = true
}: Props) => {
  const toastDisabledWarning = () =>
    disabledSubmitToast && toast.notice(disabledSubmitToast);

  const content = (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      <Spacer />
      <PaymentScreenHeader
        icons={icons}
        headerText={headerText}
        cardHeader={cardHeader}
      />
      <FlashMessage queue="notice" center color="red" />
      <ContentArea size="none">
        <FormValue name="type">
          {({ value }) => {
            const AccountComponent = accountComponents[value];
            if (!AccountComponent) return null;
            return (
              <>
                <Spacer />
                <NestedForm
                  name={value || "card"}
                  initialValues={
                    initialValues ? initialValues[value] : undefined
                  }
                >
                  <AccountComponent />
                </NestedForm>
              </>
            );
          }}
        </FormValue>
        {showBottomNote && (
          <>
            <Spacer />
            <Text align="center" color="neutral">
              Note: GigSmart does not store this information.
            </Text>
          </>
        )}
      </ContentArea>
      <StyledView color="neutralLighter" verticalPadding={0}>
        {renderTerms && (
          <FormToggle
            name="stripeTermsAccepted"
            validates={[
              Validator.isTrue({
                message: "must accept Stripe Terms"
              })
            ]}
          >
            {({ value: stripeTermsValue, toggle }) => (
              <StripeTermsOfService
                eventTargetName="Stripe ToS Checkbox"
                stripeTermsAccepted={stripeTermsValue}
                handleStripeTermsAccepted={toggle}
              />
            )}
          </FormToggle>
        )}
        <Spacer />
        <FormSubmit>
          {({ invalid, submit, submitting }) =>
            customSubmitRenderer ? (
              customSubmitRenderer({
                disabled: invalid || submitting,
                onPress: submit,
                label: buttonText,
                onPressDisabled: toastDisabledWarning,
                testID: "payment-form-submit-button"
              })
            ) : (
              <Button
                disabled={invalid || submitting}
                onPress={submit}
                testID="payment-form-submit-button"
                label={buttonText}
                onPressDisabled={toastDisabledWarning}
              />
            )
          }
        </FormSubmit>
        <Spacer size={allowContinueWithoutSave ? "standard" : "large"} />
        {allowContinueWithoutSave && (
          <>
            <Button
              onPress={onContinueWithoutSave}
              testID="payment-form-submit-without-save"
              label="Continue Without Update"
              variant="clear"
              outline
            />
            <Spacer size="large" />
          </>
        )}
      </StyledView>
      {!!renderPaymentNotes && renderPaymentNotes()}
    </Form>
  );

  return scrollEnabled ? (
    <ScrollView testID="payment-screen-scroll-view" enableOnAndroid={false}>
      {content}
    </ScrollView>
  ) : (
    content
  );
};

export default PaymentScreen;
