import type { ActionSheetOptions } from "@gigsmart/atorasu";
import {
  ActionSheet,
  ContentArea,
  Icon,
  IconButton,
  Pressable,
  Stack,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { NavPortalEntrance, useHistory } from "@gigsmart/kaizoku";
import {
  createRelayFragmentContainer,
  graphql,
  useFetchQueryResult
} from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React, { useState, useMemo } from "react";
import type { WorkerMoreMenuQuery } from "./__generated__/WorkerMoreMenuQuery.graphql";
import type { WorkerMoreMenu_conversationLike$key } from "./__generated__/WorkerMoreMenu_conversationLike.graphql";

export function WorkerMoreMenu({
  organization,
  engagement,
  type,
  gig
}: FragmentContainerInnerComponentProps<WorkerMoreMenu_conversationLike$key>) {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const gigId = gig?.id;
  const [result] = useFetchQueryResult<WorkerMoreMenuQuery>(
    graphql`
      query WorkerMoreMenuQuery($id: ID!) {
        viewer {
          ... on Worker {
            gig(id: $id) {
              ... on Engagement {
                id
              }
            }
          }
        }
      }
    `,
    { variables: { id: gigId ?? "" } }
  );

  const options = useMemo(() => {
    const options: ActionSheetOptions = [];

    if (type === "ShiftGroupConversation") {
      options.push({
        testID: "view-shift-btn",
        label: "View Shift",
        onPress: () => {
          const engagementId = result?.viewer?.gig?.id;
          history.push(`/gigs/${engagement?.id ?? engagementId}`);
        }
      });
    } else {
      if (!engagement) {
        options.push({
          testID: "view-org-profile-btn",
          label: "View Organization Profile",
          onPress: () => history.push(`/organization/${organization?.id}`)
        });
      } else {
        options.push({
          testID: "view-req-profile-btn",
          label: "View Requester Profile",
          onPress: () =>
            history.push(
              `/gigs/${engagement?.id}/requester?gigId=${engagement?.gig?.id}`
            )
        });
      }
    }
    return options;
  }, [type, engagement, organization, result]);
  return (
    <ActionSheet
      visible={visible}
      options={options}
      position="right"
      onClose={() => setVisible(false)}
      autoClose
    >
      {isMd && (
        <Pressable
          testID="conversation-more-menu-btn"
          onPress={() => setVisible(true)}
          eventEntityType="Button"
          eventTargetName="ConversationMoreMenuButton"
        >
          <Stack
            horizontal
            alignItems="center"
            justifyContent="center"
            size="compact"
          >
            <Icon
              name="ellipsis-vertical"
              color="primary"
              size="small"
              variant="solid"
            />
            <Text color="primary" weight="bold">
              More
            </Text>
          </Stack>
        </Pressable>
      )}
      {!isMd && (
        <NavPortalEntrance
          rightAccessory={
            <ContentArea size="none">
              <IconButton
                size="medium"
                variant="solid"
                name="ellipsis-vertical"
                onPress={() => setVisible(true)}
                testID="conversation-more-menu-btn"
              />
            </ContentArea>
          }
        />
      )}
    </ActionSheet>
  );
}

export default createRelayFragmentContainer<WorkerMoreMenu_conversationLike$key>(
  graphql`
    fragment WorkerMoreMenu_conversationLike on ConversationLike {
      type: __typename
      ...ShiftInfo_conversation
      ... on ShiftGroupConversation {
        gig {
          id
        }
      }
      ... on EngagementConversation {
        engagement {
          id
          gig {
            id
          }
        }
      }
      ... on OrganizationWorkerConversation {
        organization {
          id
        }
      }
    }
  `,
  WorkerMoreMenu
);
