import React from "react";

import { IconText } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import {
  ConnectionHandler,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import type { EngagedProjectHeaderSubscription } from "./__generated__/EngagedProjectHeaderSubscription.graphql";
import type { WorkerProjectEngagementCard_engagement$data } from "./__generated__/WorkerProjectEngagementCard_engagement.graphql";
interface Props {
  topic: WorkerProjectEngagementCard_engagement$data["topic"];
}

export default function EngagedProjectHeader({ topic }: Props) {
  useRelaySubscription<EngagedProjectHeaderSubscription>(
    graphql`
      subscription EngagedProjectHeaderSubscription(
        $messageTypes: [MessageType!]
        $topicId: ID!
      ) {
        messageAdded(messageTypes: $messageTypes, topicId: $topicId) {
          unreadCount
        }
      }
    `,
    { messageTypes: ["USER_MESSAGE"], topicId: topic?.id ?? "" },
    {
      updater: (store, data) => {
        if (!topic?.id) return;
        const storeTopic = store.get(topic?.id);
        if (!storeTopic) return;
        const messagesConnection = ConnectionHandler.getConnection(
          storeTopic,
          "EngagedProjectHeader_messages",
          { query: "WHERE receiptViewedAt = NULL" }
        );
        if (!messagesConnection) return;
        messagesConnection.setValue(
          Math.max(data?.messageAdded?.unreadCount ?? 0, 0) || 0,
          "totalCount"
        );
      }
    }
  );

  const totalCount = topic?.messages?.totalCount ?? 0;
  return totalCount === 0 ? (
    <IconText
      icon="message-lines"
      iconColor="neutral"
      iconSize="tiny"
      size="small"
      spacing="compact"
    >
      Messaging with Requester
    </IconText>
  ) : (
    <IconText
      icon="circle"
      color="info"
      size="small"
      iconSize="tiny"
      spacing="compact"
      textWeight="bold"
    >
      {pluralize(totalCount, "unread message")}
    </IconText>
  );
}
