/**
 * @generated SignedSource<<5e339f9b4cb53d850d2865fa5dfd2dc6>>
 * @relayHash e733053e67ba2ba99a2e91df84415d76
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:S6yvXyfMZL_i-LjXJRR-hk4oBYOVLFKwrOtCir-t9ag

import { ConcreteRequest } from 'relay-runtime';
export type UpdateUserDeviceInput = {
  deviceId?: string | null | undefined;
  pushRegistrationToken?: string | null | undefined;
  userId?: string | null | undefined;
};
export type notificationsRegisterDeviceMutation$variables = {
  input: UpdateUserDeviceInput;
};
export type notificationsRegisterDeviceMutation$data = {
  readonly updateUserDevice: {
    readonly userDevice: {
      readonly active: boolean;
      readonly pushRegistrationToken: string | null | undefined;
    };
  } | null | undefined;
};
export type notificationsRegisterDeviceMutation = {
  response: notificationsRegisterDeviceMutation$data;
  variables: notificationsRegisterDeviceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pushRegistrationToken",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "notificationsRegisterDeviceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserDevicePayload",
        "kind": "LinkedField",
        "name": "updateUserDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDevice",
            "kind": "LinkedField",
            "name": "userDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "notificationsRegisterDeviceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserDevicePayload",
        "kind": "LinkedField",
        "name": "updateUserDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDevice",
            "kind": "LinkedField",
            "name": "userDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:S6yvXyfMZL_i-LjXJRR-hk4oBYOVLFKwrOtCir-t9ag",
    "metadata": {},
    "name": "notificationsRegisterDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b4b51405e7b853909737a09cc7c77d57";

export default node;
