import { Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import SummaryRow from "./SummaryRow";
import type { CrisisSummaryRow_gigLike$key } from "./__generated__/CrisisSummaryRow_gigLike.graphql";

type Props = {};

export const CrisisSummaryRow = ({
  crisis
}: FragmentContainerInnerComponentProps<
  CrisisSummaryRow_gigLike$key,
  Props
>) => {
  return (
    <SummaryRow
      icon="house-chimney-crack"
      iconColor="danger"
      field="crisis_id"
      title="Crisis Related Gig"
      testID="crisis-summary-row"
    >
      <Text weight="bold" testID="summary-crisis">
        {crisis?.title}
      </Text>
    </SummaryRow>
  );
};

export default createRelayFragmentContainer<
  CrisisSummaryRow_gigLike$key,
  Props
>(
  graphql`
    fragment CrisisSummaryRow_gigLike on GigLike {
      crisis {
        title
      }
    }
  `,
  CrisisSummaryRow
);
