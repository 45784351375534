import React, { type ReactNode } from "react";

import {
  Button,
  ContentArea,
  Divider,
  IconText,
  Row,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { currency, numeric } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import { StatsWell } from "@gigsmart/seibutsu/shared/StatsWell";
import ProfileHeader from "./ProfileHeader";
import type {
  ProfileInfoCard_worker$data,
  ProfileInfoCard_worker$key
} from "./__generated__/ProfileInfoCard_worker.graphql";

interface WrapperProps {
  workerRef: ProfileInfoCard_worker$key | null | undefined;
  viewer?: "Requester" | "Worker";
  requesterWorkerShiftCard?: ReactNode;
  requesterWorkerActions?: ReactNode;
  isAuthenticated?: boolean;
  toolbar?: JSX.Element | null;
  isPublicView?: boolean;
}

interface ProfileInfoCardProps extends Omit<WrapperProps, "workerRef"> {
  isAuthenticated?: boolean;
  worker: ProfileInfoCard_worker$data | null | undefined;
  toolbar?: JSX.Element | null;
}

/** @deprecated */
export default function ProfileInfoCardWrapper({
  workerRef,
  viewer = "Worker",
  requesterWorkerShiftCard,
  requesterWorkerActions,
  isAuthenticated = true,
  toolbar,
  isPublicView
}: WrapperProps) {
  const worker = useRelayFragment(
    graphql`
      fragment ProfileInfoCard_worker on Worker
      @argumentDefinitions(
        skipIfNotWorker: { type: "Boolean", defaultValue: false }
      ) {
        ...ProfileHeaderRefreshed_worker
          @arguments(skipIfNotWorker: $skipIfNotWorker)
        id
        biography
        firstName
        overallRating
        gigStats {
          gigsCompleted
          uniqueOrganizationsWorked
          totalEarned @skip(if: $skipIfNotWorker)
        }
        favoritedByOrganizationsCount
        reviewsReceived(first: 0) {
          totalCount
        }
      }
    `,
    workerRef ?? null
  );

  return (
    <Surface>
      <ProfileHeader
        workerRef={worker}
        viewer={viewer}
        toolbar={toolbar}
        isPublicView={isPublicView}
      />
      <ProfileInfoCard
        viewer={viewer}
        worker={worker}
        requesterWorkerShiftCard={requesterWorkerShiftCard}
        requesterWorkerActions={requesterWorkerActions}
        isAuthenticated={isAuthenticated}
      />
    </Surface>
  );
}

export function ProfileInfoCard({
  viewer,
  worker,
  requesterWorkerShiftCard,
  requesterWorkerActions,
  isAuthenticated
}: ProfileInfoCardProps) {
  const history = useHistory();

  if (!worker) return null;

  const {
    firstName,
    biography,
    gigStats,
    favoritedByOrganizationsCount,
    overallRating,
    id,
    reviewsReceived
  } = worker;

  const displayStats = (
    value: string | number | undefined,
    type?: "rating" | "currency"
  ) => {
    if (currency.deHumanized(String(value ?? 0)) === 0) {
      return "N/A";
    }

    switch (type) {
      case "rating":
        return numeric.rating(typeof value === "number" ? value : 0);
      case "currency":
        return currency.humanize(value).replace("$", "");
      default:
        return value;
    }
  };
  const defaultBiography =
    "Add a Professional Summary to highlight your experience and skills.";

  return (
    <ContentArea testID="profile-info-card">
      {viewer === "Worker" && (
        <>
          <Divider />
          <ContentArea variant="none">
            <Row fill justifyContent="space-between">
              <Button
                label="View Public Profile"
                variant="clear"
                outline
                size="small"
                testID="view-public-profile-button"
                fill
                onPress={() => history.push("/profile/public-view")}
              />
              <Spacer size="compact" horizontal />
              <Button
                label="Edit Profile"
                variant="clear"
                outline
                size="small"
                testID="edit-profile-button"
                fill
                onPress={() => history.push("/profile/aboutMe")}
              />
            </Row>
          </ContentArea>
        </>
      )}
      {viewer === "Requester" && requesterWorkerActions}
      <Divider />
      <Spacer />
      <Text variant="subheader" color="primary" testID="worker-first-name">
        About {viewer === "Requester" ? firstName : "Me"}
      </Text>
      {viewer === "Worker" && (
        <>
          <Spacer size="compact" />
          <Text
            variant="subheader"
            color={!biography ? "neutral" : "black"}
            weight="normal"
            testID="worker-biography"
          >
            {biography ?? defaultBiography}
          </Text>
        </>
      )}
      {viewer === "Requester" && !!biography && (
        <>
          <Spacer size="compact" />
          <Text
            variant="subheader"
            color={!biography ? "neutral" : "black"}
            weight="normal"
            testID="worker-biography"
          >
            {biography ?? defaultBiography}
          </Text>
        </>
      )}
      <Spacer />
      <Stack size="compact">
        <IconText icon="business-time" color="primary" spacing="compact">
          <Text variant="subheader" color="black" testID="worker-gigs-worked">
            Shifts Worked:{" "}
            <Text weight="normal">{displayStats(gigStats?.gigsCompleted)}</Text>
          </Text>
        </IconText>
        <IconText icon="handshake" color="success" spacing="compact">
          <Text
            variant="subheader"
            color="black"
            testID="worker-businesses-worked"
          >
            Number of Businesses Worked With:{" "}
            <Text weight="normal">
              {displayStats(gigStats?.uniqueOrganizationsWorked)}
            </Text>
          </Text>
        </IconText>
        <IconText icon="heart" color="danger" spacing="compact">
          <Text
            variant="subheader"
            color="black"
            testID="worker-businesses-favorited"
          >
            Favorited by Businesses:{" "}
            <Text weight="normal">
              {displayStats(favoritedByOrganizationsCount)}
            </Text>
          </Text>
        </IconText>
      </Stack>
      <Spacer />
      <Stack horizontal justifyContent="space-between" size="compact">
        <StatsWell
          icon="star"
          iconColor="star"
          label="Average Rating"
          value={displayStats(
            reviewsReceived?.totalCount ? overallRating : 0,
            "rating"
          )}
        />
        {viewer === "Worker" && (
          <StatsWell
            icon="dollar-sign"
            iconColor="success"
            value={displayStats(gigStats?.totalEarned, "currency")}
            label="Dollars Earned"
          />
        )}
        {viewer === "Requester" && requesterWorkerShiftCard}
      </Stack>
    </ContentArea>
  );
}
