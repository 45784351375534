import { Column, ContentArea, Stack, Switch, Text } from "@gigsmart/atorasu";
import React, { type ReactNode } from "react";
import type { GigConversationRule } from "./helpers";

type Props = {
  header?: ReactNode;
  showActions?: boolean;
  value?: GigConversationRule | null;
  onChange?: (rule: GigConversationRule) => void;
};

export default function ShiftGroupChatSettingsFields({
  header,
  showActions = true,
  value,
  onChange
}: Props) {
  const enableChat = value === "REQUESTER_ONLY" || value === "ALL_PARTICIPANTS";
  const lockWorkers = value === "DISABLED" || !value;
  const enableWorkers = value === "ALL_PARTICIPANTS";

  return (
    <Stack variant="divider">
      {header}
      {showActions && (
        <ContentArea>
          <Stack horizontal justifyContent="space-between" alignItems="center">
            <Stack size="compact" fill>
              <Text weight="bold">Enable Shift Group Chat?</Text>
              <Text variant="note" color="disabled">
                Create a group chat with your scheduled Workers on each Shift.
              </Text>
            </Stack>
            <Switch
              testID="enable-group-chat-switch"
              toggled={enableChat}
              onChange={() => {
                if (value === "DISABLED" || !value) {
                  onChange?.("REQUESTER_ONLY");
                } else {
                  onChange?.("DISABLED");
                }
              }}
            />
          </Stack>
        </ContentArea>
      )}
      {showActions && (
        <ContentArea>
          <Stack horizontal justifyContent="space-between" alignItems="center">
            <Column fill>
              <Text
                weight="bold"
                color={value === "DISABLED" ? "disabled" : undefined}
              >
                Allow Messages from Workers?
              </Text>
            </Column>
            <Switch
              locked={lockWorkers}
              testID="allow-worker-group-chat-switch"
              toggled={enableWorkers}
              onChange={() => {
                if (value === "REQUESTER_ONLY") {
                  onChange?.("ALL_PARTICIPANTS");
                } else if (value === "ALL_PARTICIPANTS") {
                  onChange?.("REQUESTER_ONLY");
                }
              }}
            />
          </Stack>
        </ContentArea>
      )}
    </Stack>
  );
}
