import type { ScrollView } from "react-native";

export default class ScrollHelper {
  // Scrolling handling
  yPositions: Record<string, number> = {};
  scrollRef: ScrollView | null = null;

  setScrollRef = (ref: ScrollView) => (this.scrollRef = ref);

  onLayout = (elementName: string, { y }: { y: number }) => {
    this.yPositions[elementName] = y;
  };

  scrollToY = ({ y, animated = true }: { y: number; animated?: boolean }) => {
    this.scrollRef?.scrollTo({ y, animated });
  };

  scrollToEnd = () => {
    this.scrollRef?.scrollToEnd({ animated: true });
  };

  scrollToTop = () => {
    this.scrollRef?.scrollTo({ y: 0, animated: true });
  };

  scrollToElement = (elementName: string) => {
    const y = this.yPositions[elementName] ?? 0;
    if (y > 0 && this.scrollRef) {
      this.scrollRef.scrollTo({ y, animated: true });
    }
  };
}
