/**
 * @generated SignedSource<<4d8387d0e14d87e1f561b360d0107388>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NewMessageComposer_conversation$data = {
  readonly __typename: "ShiftGroupConversation";
  readonly gig: {
    readonly conversationRule: GigConversationRule;
    readonly id: string;
  };
  readonly " $fragmentType": "NewMessageComposer_conversation";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "NewMessageComposer_conversation";
};
export type NewMessageComposer_conversation$key = {
  readonly " $data"?: NewMessageComposer_conversation$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewMessageComposer_conversation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewMessageComposer_conversation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conversationRule",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ShiftGroupConversation",
      "abstractKey": null
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};

(node as any).hash = "01a8f761dc78f27bb58200ae80bbc9c4";

export default node;
