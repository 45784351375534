import { SummaryRow } from "@gigsmart/katana";
import { getConnectionNodes } from "@gigsmart/relay";
import React from "react";

interface Props {
  skillConnection?:
    | {
        readonly totalCount?: number | null | undefined;
        readonly edges:
          | ReadonlyArray<
              | {
                  readonly node:
                    | {
                        readonly skill: {
                          readonly name: string;
                        };
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
}

const SummarySkills = ({ skillConnection }: Props) => {
  const skillCount =
    skillConnection?.totalCount ?? skillConnection?.edges?.length ?? 0;
  const skillNames = getConnectionNodes(
    skillConnection,
    (d) => d.skill.name
  ).join(", ");

  return (
    <SummaryRow
      sectionContentTestID="skill-name"
      iconName="box-check"
      sectionLabel={skillCount === 1 ? "Skill" : "Skills"}
      sectionContent={skillNames}
    />
  );
};

export default SummarySkills;
