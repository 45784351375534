import {
  Column,
  Icon,
  type IconName,
  LoadingView,
  ModalBody,
  ModalFooter,
  Row,
  Text,
  Well,
  showModal,
  toast,
  useDebouncedCallback
} from "@gigsmart/atorasu";
import {
  type FomuOnChangeFn,
  type FomuSubmitFn,
  Form,
  FormField,
  FormValue,
  Validator
} from "@gigsmart/fomu";
import { duration } from "@gigsmart/isomorphic-shared/iso";
import CollapsibleView from "@gigsmart/katana/container/collapsible-view";
import StyledPicker from "@gigsmart/katana/input/styled-picker";
import IconButton from "@gigsmart/katana/tappable/icon-button";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import FomuSubmit from "../../fomu/inputs/FomuSubmit";
import { TRANSPORTATION_STATE_OPTIONS } from "../../user/Onboarding/TransportationStep";
import type { EtaModalMutation } from "./__generated__/EtaModalMutation.graphql";
import type { EtaModalQuery } from "./__generated__/EtaModalQuery.graphql";

const icons: Array<{ value: string; icon: IconName }> = [
  { value: "car", icon: "car" },
  { value: "transit", icon: "bus" },
  { value: "bike", icon: "bicycle" },
  { value: "walk", icon: "walking" }
];

const iconsMap: Record<string, IconName> = {
  car: "car",
  transit: "bus",
  bike: "bicycle",
  walk: "walking"
};

type Props = {
  id: string;
  onClose: () => void;
};

const EtaModal = createSuspendedQueryContainer<EtaModalQuery, Props>(
  function EtaModal({ onClose, response, retry }) {
    const viewer = response?.viewer ?? null;
    const travelTime =
      viewer?.gig?.travelTime ??
      viewer?.gigSeries?.node?.availableGigs?.edges?.[0]?.travelTime;

    const [setTransportationMethod, { loading }] =
      useRelayMutationPromise<EtaModalMutation>(
        graphql`
        mutation EtaModalMutation($input: UpdateWorkerInput!) {
          updateWorker(input: $input) {
            worker {
              id
            }
          }
        }
      `,
        { onSuccess: () => retry() }
      );

    const initialValues = useMemo(
      () => ({
        transportationMethod: viewer?.transportationMethod,
        transportationState: viewer?.transportationState
      }),
      []
    );

    const isSubmitted = useRef(false);
    const valuesRef = useRef(initialValues);

    useEffect(() => {
      return () => {
        if (!isSubmitted.current) return;
        const transportationMethod = initialValues.transportationMethod;
        setTransportationMethod(
          { input: { transportationMethod } },
          { onSuccess: onClose, onCompleted: () => retry() }
        );
      };
    }, []);

    const handleUpdate = useDebouncedCallback(
      async ({ transportationMethod, transportationState }: any) => {
        const shouldUpdate = !isEqual(valuesRef.current, {
          transportationMethod,
          transportationState
        });
        if (!shouldUpdate) return;

        valuesRef.current = { transportationMethod, transportationState };
        await setTransportationMethod({
          input: {
            transportationMethod,
            ...(transportationMethod === "car"
              ? { transportationState: transportationState ?? "SEDAN" }
              : undefined)
          }
        });
      },
      100
    );

    const handleSubmit: FomuSubmitFn = async ({ values }, done) => {
      await handleUpdate(values);
      isSubmitted.current = true;
      onClose();
      done();

      toast.success("Your method of transportation has been updated", {
        delayMs: 300
      });
    };

    const handleChange: FomuOnChangeFn = ({ values }) => {
      void handleUpdate(values);
    };

    return (
      <Form
        onSubmit={handleSubmit}
        onChange={handleChange}
        initialValues={initialValues}
      >
        <ModalBody zIndex={2}>
          <Column
            pointerEvents={loading ? "none" : "auto"}
            style={{ opacity: loading ? 0.5 : 1 }}
          >
            <Well
              alignItems="center"
              justifyContent="center"
              gap="standard"
              color="highlight"
              size="large"
            >
              {viewer?.transportationMethod ? (
                <Icon
                  align="center"
                  name={iconsMap[viewer?.transportationMethod] ?? "question"}
                  variant="solid"
                />
              ) : (
                <LoadingView />
              )}

              {!!travelTime && (
                <Text variant="title" color="primary">
                  {duration.humanize(travelTime, "semi-compact")}
                </Text>
              )}
              {travelTime ? (
                <Text align="center">
                  It will take you
                  <Text weight="bold">{` ${duration.humanize(
                    travelTime,
                    "long-no-seconds"
                  )} `}</Text>
                  to travel to this Shift.
                </Text>
              ) : (
                <Text align="center">
                  Travel time not available for transit. Update method of
                  transportation for this shift.
                </Text>
              )}
            </Well>
            <CollapsibleView
              collapsedText="Change Method of Transportation"
              notCollapsedText="Change Method of Transportation"
              startCollapsed
              chevronPos="left"
            >
              <FormField
                name="transportationMethod"
                validates={Validator.presence()}
              >
                {({ value, setValue }) => (
                  <Row
                    alignItems="center"
                    justifyContent="center"
                    gap="compact"
                  >
                    {icons.map((item) => (
                      <IconButton
                        testID={`${item.value}-icon`}
                        backgroundColor={
                          item.value === value ? "#003b5c" : "#ffffff"
                        }
                        borderColor={"#003b5c"}
                        diameter={48}
                        icon={item.icon}
                        iconColor={item.value === value ? "#ffffff" : "#003b5c"}
                        key={item.value}
                        onPress={() => setValue(item.value)}
                      />
                    ))}
                  </Row>
                )}
              </FormField>
              <FormValue name="transportationMethod">
                {({ value: transportationMethod }) => {
                  if (transportationMethod !== "car") return null;
                  return (
                    <FormField
                      name="transportationState"
                      validates={Validator.presence({
                        message:
                          "You must select Type of Car before continuing."
                      })}
                    >
                      {({ value, setValue }) => (
                        <StyledPicker
                          style={{ zIndex: 10 }}
                          testID="transportationState"
                          options={TRANSPORTATION_STATE_OPTIONS}
                          value={value ?? "SEDAN"}
                          label="Type of Car"
                          eventTargetName="Input Vehicle Type Picker"
                          onChangeText={setValue}
                        />
                      )}
                    </FormField>
                  );
                }}
              </FormValue>
            </CollapsibleView>
          </Column>
        </ModalBody>
        <ModalFooter>
          <FomuSubmit testID="got-it-button" label="Got It" loading={loading} />
        </ModalFooter>
      </Form>
    );
  },
  {
    query: graphql`
      query EtaModalQuery($id: ID!, $isSeries: Boolean!) {
        viewer {
          ... on Worker {
            transportationMethod
            transportationState
            gig(id: $id) @skip(if: $isSeries) {
              ... on AvailableGigsEdge {
                travelTime
              }
            }
            gigSeries(id: $id) @include(if: $isSeries) {
              node {
                availableGigs(first: 1) {
                  edges {
                    ... on AvailableGigsEdge {
                      travelTime
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ id }) => ({ id, isSeries: id.startsWith("gigsr") }),
    FallbackComponent: () => <LoadingView size="large" />
  }
);

export function showEtaModal(id: string) {
  return showModal({
    eventContext: "Show ETA Modal",
    title: "Estimated Travel Time",
    subTitleItalic: false,
    subTitle:
      "To calculate your estimated travel time we use your current location, your preferred method of transportation, and the date and the start time of the Shift.",
    useModalBody: false,
    children: (closeFn) => <EtaModal id={id} onClose={closeFn} />
  });
}
