import { ContentArea, Spacer, Stack, Surface } from "@gigsmart/atorasu";
import { Form, Validator } from "@gigsmart/fomu";
import stateList from "@gigsmart/isomorphic-shared/app/us-state-list";
import {
  FomuStyledDateInput,
  FomuStyledOptionalTextInput,
  FomuStyledTextInput
} from "@gigsmart/isomorphic-shared/fomu-inputs";
import { type StepProps, numericKeyboardType } from "@gigsmart/katana";
import FomuPickerV2 from "@gigsmart/seibutsu/fomu/inputs/FomuPickerV2";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import CardCheckbox from "@gigsmart/seibutsu/worker-verification/CardCheckbox";
import DriverFields from "@gigsmart/seibutsu/worker-verification/DriverFields";
import SecureNotice from "@gigsmart/seibutsu/worker-verification/SecureNotice";
import moment from "moment-timezone";
import React from "react";

import StepDetail from "../app/step-detail";
import { formatSSN, normalizeSSN } from "./ssn-helpers";

type Props = StepProps & {
  loading: boolean;
};

export default function VerifiedInformation({ loading, ...props }: Props) {
  if (loading) return null;

  return (
    <Form
      initialValues={props.stepData}
      onSubmit={({ values, valid }) => {
        valid && props.nextStep(values);
      }}
    >
      <StepDetail
        title="Information"
        description="Your information is secure and used only for verification purposes."
        renderNextButton={() => (
          <>
            <Spacer size="slim" />
            <FomuSubmit
              testID="continue-button"
              loading={loading}
              label={
                props.hasNext ? "Enroll & Continue" : "Complete Enrollment"
              }
            />
          </>
        )}
        {...props}
        testID="information-screen"
      >
        <Stack size="medium">
          <Surface>
            <ContentArea>
              <Stack size="compact">
                <FomuStyledTextInput
                  name="firstName"
                  validates={[Validator.presence()]}
                  eventTargetName="Input Verification First Name"
                  label="First Name"
                  placeholder="First Name"
                  tip
                  disabled={props.stepData?.status === "ENROLLED"}
                  testID="first-name-input"
                />
                <FomuStyledOptionalTextInput
                  name="middleName"
                  label="Middle Name"
                  placeholder="Middle Name"
                  eventTargetName="Input Verification Middle Name"
                  tip
                  disabled={props.stepData?.status === "ENROLLED"}
                  testID="middle-name-input"
                />
                <FomuStyledTextInput
                  name="lastName"
                  validates={Validator.presence()}
                  eventTargetName="Input Verification Last Name"
                  label="Last Name"
                  placeholder="Last Name"
                  tip
                  disabled={props.stepData?.status === "ENROLLED"}
                  testID="last-name-input"
                />
                <FomuStyledDateInput
                  name="birthdate"
                  eventTargetName="Input Verification Date of Birth"
                  validates={Validator.date({
                    format: "YYYY-MM-DD",
                    message: "Invalid date of birth",
                    options: {
                      max: moment().subtract(14, "years"),
                      min: moment().subtract(105, "years")
                    }
                  })}
                  label="Date of Birth"
                  disabled={props.stepData?.status === "ENROLLED"}
                  tip
                  testID="dob-picker"
                />
                <FomuStyledTextInput
                  name="emailAddress"
                  validates={[Validator.email(), Validator.presence()]}
                  eventTargetName="Input Verification Email"
                  label="Email"
                  placeholder="Email"
                  disabled={props.stepData?.status === "ENROLLED"}
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  autoCapitalize="none"
                  autoCorrect={false}
                  tip
                  testID="email-input"
                />
                <FomuStyledTextInput
                  name="street1"
                  validates={[Validator.presence()]}
                  textContentType="streetAddressLine1"
                  eventTargetName="Input Verification Street Address 1"
                  label="Street 1"
                  placeholder="Street Address"
                  tip
                  testID="street1-input"
                />
                <FomuStyledTextInput
                  name="street2"
                  textContentType="streetAddressLine2"
                  eventTargetName="Input Verification Street Address 2"
                  label="Street 2"
                  placeholder="Street Address"
                  testID="street2-input"
                />
                <FomuStyledTextInput
                  name="locality"
                  validates={[Validator.presence()]}
                  label="City"
                  textContentType="addressCity"
                  eventTargetName="Input Verification City"
                  tip
                  testID="locality-input"
                />
                <FomuPickerV2
                  eventTargetName="State Picker"
                  name="administrativeArea1"
                  validates={[Validator.presence()]}
                  label="State"
                  placeholder="State"
                  textContentType="addressState"
                  options={stateList}
                />
                <FomuStyledTextInput
                  name="postalCode"
                  validates={[
                    Validator.zipcode({ message: "invalid zip code" }),
                    Validator.presence()
                  ]}
                  label="Zip Code"
                  eventTargetName="Input Verification Zip Code"
                  placeholder="Zip Code"
                  textContentType="postalCode"
                  keyboardType={numericKeyboardType()}
                  tip
                  maxLength={5}
                  normalize={(zipcode) =>
                    (zipcode.match(/\d{1,5}/g) ?? []).toString()
                  }
                  testID="postal-code-input"
                />
                <FomuStyledTextInput
                  name="nationalIdNumber"
                  eventTargetName="Input Verification Social Security"
                  validates={[
                    Validator.presence(),
                    Validator.length({
                      exact: 9,
                      message: "must have exactly 9 numbers"
                    })
                  ]}
                  label="Social Security Number"
                  tip
                  placeholder="###-##-####"
                  disabled={props.stepData?.status === "ENROLLED"}
                  textContentType="none"
                  keyboardType="number-pad"
                  autoCapitalize="none"
                  autoCorrect={false}
                  maxLength={11}
                  format={formatSSN}
                  normalize={normalizeSSN}
                  testID="national-id-input"
                />
                <FomuStyledTextInput
                  name="nationalIdNumberConfirmation"
                  eventTargetName="Input Verification Confirm Social Security"
                  validates={[
                    Validator.confirmationOf({
                      field: "nationalIdNumber",
                      message: "must match social security number"
                    })
                  ]}
                  tip
                  label="Confirm Social Security Number"
                  placeholder="###-##-####"
                  disabled={props.stepData?.status === "ENROLLED"}
                  textContentType="none"
                  keyboardType="number-pad"
                  autoCapitalize="none"
                  autoCorrect={false}
                  maxLength={11}
                  format={formatSSN}
                  normalize={normalizeSSN}
                  testID="national-id-confirm-input"
                />
              </Stack>
              <SecureNotice />
            </ContentArea>
          </Surface>
          {!!props.stepData.driverCheckOptIn && (
            <Surface>
              <ContentArea>
                <DriverFields />
                <SecureNotice />
              </ContentArea>
            </Surface>
          )}
          <CardCheckbox
            variant="shadow"
            size="medium"
            name="pspTosAccepted"
            validates={[Validator.isTrue()]}
            testID="stripe-agree-checkbox"
          />
        </Stack>
      </StepDetail>
    </Form>
  );
}
