import { useCallback, useState } from "react";
import { Linking, Platform } from "react-native";

import { OperatorCalling } from "@gigsmart/feature-flags";
import { confirmPrompt, dismiss } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import showOperatorModal from "@gigsmart/seibutsu/shared/OperatorModal";
import { useCurrentUser } from "../current-user";
import type { gigHelpers } from "../gig";
import type { usePhoneCall_engagement$key } from "./__generated__/usePhoneCall_engagement.graphql";

export interface UsePhoneCallOptions {
  isWorker: boolean;
  engagement: usePhoneCall_engagement$key | null | undefined;
}

const INACTIVE_STATES: gigHelpers.EngagementStateName[] = [
  "APPLIED",
  "OFFERED"
];

export type usePhoneCallProps = usePhoneCall_engagement$key | null | undefined;

const usePhoneCall = ({
  isWorker,
  engagement: engagementFragmentRef
}: UsePhoneCallOptions) => {
  const currentUser = useCurrentUser();
  const engagement = useRelayFragment(
    graphql`
      fragment usePhoneCall_engagement on Engagement {
        contactNumber
        gig {
          primaryContact {
            id
            displayName
          }
        }
        currentState {
          name
        }
        capabilities {
          type
          status
        }
        worker {
          displayName
          id
        }
      }
    `,
    engagementFragmentRef ?? null
  );
  const state = engagement?.currentState?.name;
  const phoneNumber = engagement?.contactNumber ?? "";
  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const closePhoneModal = useCallback(() => setPhoneModalVisible(false), []);
  const isCallable =
    engagement?.capabilities?.find((capability) => capability?.type === "CALL")
      ?.status === "AVAILABLE";

  const onPhoneCallPress = useCallback(async () => {
    if (!isCallable) {
      const subTitle = isWorker
        ? ["PENDING_TIMESHEET_APPROVAL", "ENDED"].includes(state ?? "")
          ? "Your Shift Gig has ended. Calling is only available for upcoming and active Shift Gigs."
          : "Calling will be enabled when you are hired for the Gig."
        : !!state && INACTIVE_STATES.includes(state)
          ? "Calling will be enabled once the Worker is hired for your Gig."
          : "Calling is enabled during active Gigs. In order to call, hire this worker for another Gig.";

      confirmPrompt({
        title: "In-App Calling",
        subTitle,
        yesLabel: "Got It",
        oneLabel: true,
        onDo: () => dismiss()
      });
      return;
    }
    if (OperatorCalling.isEnabled()) {
      showOperatorModal(
        currentUser?.__typename === "Worker"
          ? {
              recipientId: engagement?.gig?.primaryContact?.id ?? "",
              recipientsName: engagement?.gig?.primaryContact?.displayName ?? ""
            }
          : {
              recipientId: engagement?.worker?.id ?? "",
              recipientsName: engagement?.worker?.displayName ?? ""
            }
      );
    } else if (Platform.OS === "web") {
      setPhoneModalVisible(true);
    } else {
      if (await Linking.canOpenURL(`tel:${phoneNumber}`)) {
        void Linking.openURL(`tel:${phoneNumber}`);
      }
    }
  }, [
    isCallable,
    isWorker,
    state,
    currentUser?.__typename,
    engagement?.gig?.primaryContact?.id,
    engagement?.gig?.primaryContact?.displayName,
    engagement?.worker?.id,
    engagement?.worker?.displayName,
    phoneNumber
  ]);

  return {
    isCallable,
    onPhoneCallPress,
    closePhoneModal,
    phoneModalVisible
  };
};

export default usePhoneCall;
