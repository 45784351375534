/**
 * @generated SignedSource<<5f6150fc7ac12703c69b183d8dc0a206>>
 * @relayHash 57ccd5abfae8926a9f5ecec77b9dcfd9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:FG6UpgRg1yaaKJsiTwrPlMJebgtRADhjPAoIAUB-Q8E

import { ConcreteRequest } from 'relay-runtime';
export type WalletBalanceCardQuery$variables = Record<PropertyKey, never>;
export type WalletBalanceCardQuery$data = {
  readonly viewer: {
    readonly id?: string;
    readonly walletBalance?: string;
  } | null | undefined;
};
export type WalletBalanceCardQuery = {
  response: WalletBalanceCardQuery$data;
  variables: WalletBalanceCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "walletBalance",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletBalanceCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WalletBalanceCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:FG6UpgRg1yaaKJsiTwrPlMJebgtRADhjPAoIAUB-Q8E",
    "metadata": {},
    "name": "WalletBalanceCardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8afac70db77aceb831e8aa420a395b46";

export default node;
