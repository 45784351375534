import {
  AbsoluteContainer,
  Checkbox,
  Column,
  ContentArea,
  FooterContent,
  IconButton,
  Row,
  ScreenScroll,
  ScreenTop,
  Stack,
  Text,
  toast
} from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  Validator,
  useFormFields
} from "@gigsmart/fomu";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import {
  createRelayFragmentContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React from "react";
import FomuConfirmExit from "../fomu/inputs/FomuConfirmExit";
import FomuTextInput from "../fomu/inputs/FomuTextInput";

import { StyledDatePicker } from "@gigsmart/katana";

import FomuSubmit from "../fomu/inputs/FomuSubmit";
import type { WorkHistoryFormAddWorkHistoryMutation } from "./__generated__/WorkHistoryFormAddWorkHistoryMutation.graphql";
import type { WorkHistoryFormUpdateWorkHistoryMutation } from "./__generated__/WorkHistoryFormUpdateWorkHistoryMutation.graphql";
import type { WorkHistoryForm_worker$key } from "./__generated__/WorkHistoryForm_worker.graphql";
import useDeleteProfileItem from "./useDeleteProfileItem";

interface Props {
  employer?: string;
  employmentEnd?: any | null;
  employmentStart?: any;
  id?: string;
  positionTitle?: string | null;
}

export const WorkHistoryForm = ({
  id,
  employer,
  employmentStart,
  employmentEnd,
  positionTitle
}: Props) => {
  const [addWorkerWorkHistory] =
    useRelayMutationPromise<WorkHistoryFormAddWorkHistoryMutation>(
      graphql`
        mutation WorkHistoryFormAddWorkHistoryMutation(
          $input: AddWorkerWorkHistoryInput!
        ) {
          addWorkerWorkHistory(input: $input) {
            newWorkerWorkHistoryEdge {
              node {
                ...WorkHistoryForm_worker
              }
            }
          }
        }
      `
    );

  const [updateWorkerWorkHistory] =
    useRelayMutationPromise<WorkHistoryFormUpdateWorkHistoryMutation>(
      graphql`
        mutation WorkHistoryFormUpdateWorkHistoryMutation(
          $input: UpdateWorkerWorkHistoryInput!
        ) {
          updateWorkerWorkHistory(input: $input) {
            workerWorkHistory {
              ...WorkHistoryForm_worker
            }
          }
        }
      `
    );

  const deleteItem = useDeleteProfileItem();

  const initialValues = {
    employer,
    positionTitle,
    employmentStart,
    employmentEnd: id
      ? employmentEnd === null
        ? new Date().toISOString()
        : employmentEnd
      : "",
    presentPosition: employmentEnd === null
  };

  const save: FomuSubmitFn = async (
    {
      values: {
        positionTitle,
        presentPosition,
        employer,
        employmentStart,
        employmentEnd
      }
    },
    done,
    reset
  ) => {
    if (id) {
      await updateWorkerWorkHistory({
        input: {
          employer,
          employmentStart,
          employmentEnd: presentPosition ? null : employmentEnd,
          workerWorkHistoryId: id,
          positionTitle
        }
      });
    } else {
      await addWorkerWorkHistory({
        input: {
          employer,
          employmentStart,
          ...(!presentPosition && {
            employmentEnd
          }),
          positionTitle
        }
      });
    }

    reset();
    defaultBackHandler({ safeExit: true });
    toast.success("Work history successfully saved!");
  };

  return (
    <Form initialValues={initialValues} onSubmit={save}>
      <ScreenScroll
        automaticallyAdjustContentInsets={false}
        contentInsetAdjustmentBehavior="never"
        testID="worker-history-form"
        color="surface"
        footer={
          <FooterContent bgColor="surface" bgSolid>
            <FomuSubmit label="Save" testID="save-work-history" />
          </FooterContent>
        }
      >
        <ScreenTop showBackButton />
        <ContentArea>
          <Stack size="large">
            <Text color="primary" variant="header">
              Work History
            </Text>
            <Stack>
              {id && (
                <AbsoluteContainer right={0}>
                  <Row justifyContent="flex-end">
                    <IconButton
                      name="trash-alt"
                      testID="delete-work-experience"
                      variant="solid"
                      color="primary"
                      onPress={() => deleteItem(id, "WorkHistory")}
                    />
                  </Row>
                </AbsoluteContainer>
              )}
              <FomuTextInput
                label="Employer"
                placeholder="Employer"
                name="employer"
                maxLength={75}
                validates={Validator.presence()}
                autoCapitalize="sentences"
                autoCorrect={false}
              />
              <FomuTextInput
                label="Position Title"
                placeholder="Position Title"
                name="positionTitle"
                maxLength={75}
                validates={Validator.presence()}
                autoCapitalize="sentences"
              />
              <EmploymentPeriod />
            </Stack>
          </Stack>
        </ContentArea>
        <FomuConfirmExit />
      </ScreenScroll>
    </Form>
  );
};

function EmploymentPeriod() {
  const { employmentStart, employmentEnd, presentPosition } = useFormFields({
    employmentStart: Validator.presence(),
    employmentEnd: Validator.presence(),
    presentPosition: null
  });
  return (
    <Stack horizontal>
      <Column fill>
        <StyledDatePicker
          label="Dates"
          placeholder="Start"
          eventTargetName="Input Start Date Picker"
          value={employmentStart.value}
          date={employmentStart.value}
          maxDate={new Date()}
          onDateChange={(v) => {
            employmentStart.setValue(
              DateTime.fromJSDate(v ?? new Date()).toFormat("yyyy-MM-dd")
            );
          }}
        />
      </Column>
      <Column fill>
        <Stack size="small">
          <StyledDatePicker
            label=" "
            placeholder="End"
            eventTargetName="Input End Date Picker"
            value={employmentEnd.value}
            date={employmentEnd.value}
            minDate={DateTime.fromJSDate(
              new Date(employmentStart.value || Date.now())
            ).toJSDate()}
            maxDate={new Date()}
            onDateChange={(v) => {
              employmentEnd.setValue(
                DateTime.fromJSDate(v ?? new Date()).toFormat("yyyy-MM-dd")
              );
              presentPosition.setValue(false);
            }}
            displayValue={presentPosition.value ? "Present" : null}
          />
          <Stack horizontal alignItems="center" size="compact">
            <Checkbox
              testID="current"
              color="primary"
              selected={presentPosition.value ?? false}
              onChange={(v) => {
                presentPosition.setValue(v);
                employmentEnd.setValue(v ? new Date() : undefined);
              }}
            />
            <Text color="primary">Current</Text>
          </Stack>
        </Stack>
      </Column>
    </Stack>
  );
}

export default createRelayFragmentContainer<WorkHistoryForm_worker$key>(
  graphql`
    fragment WorkHistoryForm_worker on WorkerWorkHistory {
      id
      employer
      employmentStart
      employmentEnd
      positionTitle
    }
  `,
  WorkHistoryForm
);
