import * as React from "react";
import { type LayoutChangeEvent, View } from "react-native";

import type { ViewStyleProp } from "../style";
import Context, { type ContextValues } from "./context";

interface Props {
  children: React.ReactNode;
  testID?: string;
  style?: ViewStyleProp;
  contextValues: ContextValues;
}

// All ScrollableTextInput, ScrollableSearchInput that are not direct children
// of Scroller should be wrapped in this view or React.Fragment

interface State {
  parentOnElementLayout?: any;
  providerValue: ContextValues;
}

class ScrollableViewProvider extends React.Component<Props, State> {
  static defaultProps = {
    testID: "",
    contextValues: {
      onElementLayout(_elementName: string, _event: any) {
        /* noop */
      },
      scrollToElement(_elementName: string) {
        /* noop */
      },
      scrollToTop() {
        /* noop */
      },
      scrollToEnd() {
        /* noop */
      }
    }
  };

  ourY = 0;

  yPositions: Record<string, number> = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      parentOnElementLayout: this.props.contextValues
        ? this.props.contextValues.onElementLayout
        : null,
      providerValue: {
        ...this.props.contextValues,
        onElementLayout: this.createHandleElementLayout(
          this.props.contextValues
        )
      }
    };
  }

  handleLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    if (!nativeEvent) return;

    this.ourY = nativeEvent.layout.y;
    const { parentOnElementLayout } = this.state;

    // Parent onLayout fires AFTER children onLayout.
    // So refresh children's yPositions in parent provider (Scroller or
    // ScrollableView)

    // This "flattens" children's positions up 1 level.
    // The root Scroller will get nested children's absolute positions.
    Object.keys(this.yPositions).forEach((elementName) => {
      parentOnElementLayout?.(elementName, {
        y: (this.yPositions[elementName] ?? 0) + this.ourY
      });
    });
  };

  createHandleElementLayout =
    (contextValues: ContextValues) =>
    (elementName: string, { y }: { y: number }) => {
      const ourY = this.ourY;

      // remember child's position for later
      this.yPositions[elementName] = y;

      contextValues?.onElementLayout?.(elementName, { y: y + (ourY ?? 0) });
    };

  render() {
    const { style, testID } = this.props;

    return (
      <Context.Provider value={this.state.providerValue}>
        <View testID={testID} onLayout={this.handleLayout} style={style}>
          {this.props.children}
        </View>
      </Context.Provider>
    );
  }
}

export default ScrollableViewProvider;
