import { Icon, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { RatingStars, StyledButtonRounded } from "@gigsmart/katana";
import moment from "moment-timezone";
import React, { useState } from "react";
import {
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  type ViewStyle
} from "react-native";

interface Props {
  disable?: boolean;
  gigEntity: string;
  gigTitle: string;
  onRemove?: () => void;
  onSubmit?: () => void;
  rating: number;
  response?: string;
  responseDate?: Date;
  review: string;
  reviewDate?: Date | string | null;
  style?: ViewStyle;
}

export default function ReviewParagraph({
  disable,
  response,
  responseDate,
  onSubmit,
  onRemove,
  gigEntity,
  gigTitle,
  review,
  reviewDate,
  style,
  rating
}: Props) {
  const [showControls, setShowControls] = useState(true);
  const [text, setText] = useState<string | undefined>(undefined);
  const reviewResponseRef = React.useRef<TextInput>(null);
  const styles = useStyles(({ getColor }) => ({
    blue: {
      color: getColor("primary", "fill")
    },
    orange: {
      color: getColor("orange", "fill")
    },
    neutralMedium: {
      color: getColor("background", "fill")
    },
    container: {
      backgroundColor: getColor("surface", "fill")
    },
    controls: {
      height: 40,
      marginTop: 15
    },
    contentLeft: {
      alignItems: "center",
      flex: 4
    },
    contentRight: {
      alignItems: "center",
      textAlign: "right"
    },
    contentCenter: {
      alignItems: "center",
      flex: 1
    },
    padding: {
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20
    },
    responseContent: {
      borderTopColor: getColor("background", "fill"),
      borderTopWidth: 2,
      backgroundColor: getColor("background", "fill"),
      position: "relative"
    },
    responseEdit: {
      borderTopColor: getColor("primary", "fill"),
      borderTopWidth: 2,
      paddingTop: 15
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      marginTop: 20
    },
    flexContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
      marginTop: 20
    },
    ratingContainer: {
      paddingTop: 20
    },
    webRatingContainer: {
      paddingTop: 10,
      paddingBottom: 15
    },
    tooltip: {
      borderBottomColor: getColor("background", "fill"),
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderTopColor: "transparent",
      borderWidth: 20,
      height: 0,
      left: "50%",
      position: "relative",
      top: -40,
      width: 0,
      transform: [{ translateX: -20 }]
    },
    tooltipTail: {
      borderBottomColor: getColor("background", "fill"),
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderTopColor: "transparent",
      borderWidth: 18,
      height: 0,
      left: -18,
      position: "absolute",
      top: -14,
      width: 0
    },
    tooltipWrapper: {
      height: 0
    },
    review: {
      color: getColor("black", "fill"),
      paddingTop: 16
    },
    noReview: {
      color: getColor("neutral", "fill")
    },
    flex1: {
      flex: 1
    },
    marginLeft5: {
      marginLeft: 4
    },
    marginRight5: {
      marginRight: 4
    }
  }));

  const getControls = () => {
    if (showControls && onSubmit) {
      return (
        <View style={[styles.controls, styles.row]}>
          <StyledButtonRounded
            onPress={() => {
              setShowControls(false);
              setText(undefined);
            }}
            secondary
            style={[styles.flex1, styles.marginRight5]}
            title="Cancel"
          />
          <StyledButtonRounded
            onPress={onSubmit}
            primary
            style={[styles.flex1, styles.marginLeft5]}
            testID="review-submit"
            title="Submit"
          />
        </View>
      );
    }
  };

  const getResponseContent = () => {
    if (response) {
      return (
        <View style={[styles.padding, styles.responseContent]}>
          <View style={styles.tooltipWrapper}>
            <View style={styles.tooltip}>
              <View style={styles.tooltipTail} />
            </View>
          </View>
          <View style={styles.row}>
            <Text style={[styles.blue, styles.contentLeft]}>
              {responseDate && moment(responseDate).format("MM/DD/YY")}
            </Text>
            {!disable && (
              <TouchableOpacity
                testID="review-remove"
                onPress={onRemove}
                style={styles.contentCenter}
              >
                <Icon name="times" size="default" style={[styles.blue]} />
              </TouchableOpacity>
            )}
          </View>
          <Text weight="bold" variant="bodyLg">
            {response}
          </Text>
        </View>
      );
    }
    if (!disable) {
      return (
        <View style={styles.padding}>
          <View style={styles.responseEdit}>
            <TextInput
              allowFontScaling={false}
              ref={reviewResponseRef}
              testID="review-response"
              maxLength={500}
              multiline
              onChangeText={(newText) => setText(newText)}
              onFocus={() => setShowControls(true)}
              placeholder="Reply to this Review"
              placeholderTextColor="#003b5c"
              value={text}
            />
            {getControls()}
          </View>
        </View>
      );
    }
  };

  return (
    <View style={[styles.container, style]}>
      <View style={styles.padding}>
        <View style={styles.flexContainer}>
          <Text
            numberOfLines={1}
            variant="bodyLg"
            weight="bold"
            color="primary"
            style={styles.contentLeft}
          >
            {gigEntity}
          </Text>
          <Text
            style={
              Platform.OS === "web"
                ? [styles.contentRight, styles.neutralMedium]
                : styles.contentRight
            }
          >
            {reviewDate && Platform.OS === "web"
              ? moment(reviewDate).format("MMMM DD, YYYY")
              : moment(reviewDate ?? undefined).format("MM/DD/YY")}
          </Text>
        </View>
        <Text numberOfLines={2} variant="bodyLg" weight="bold">
          {gigTitle}
        </Text>
        {Platform.OS === "web" && (
          <View style={styles.webRatingContainer}>
            <RatingStars
              color="spiceOrange"
              disabled
              fontSize={15}
              rating={rating}
            />
          </View>
        )}
        {Platform.OS !== "web" && (
          <View style={styles.ratingContainer}>
            <RatingStars
              color="spiceOrange"
              disabled
              fontSize={24}
              rating={rating}
            />
          </View>
        )}
        <Text
          style={[styles.review, !review && styles.noReview]}
          testID="review-text"
        >
          {review || "No written review provided."}
        </Text>
      </View>
      {getResponseContent()}
    </View>
  );
}
