/**
 * @generated SignedSource<<a7fa2228b5b089b175aa80a07628236d>>
 * @relayHash c7433a49f21b8f1d3d15b43150e4424d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Yb2dSB75vIUDi_hQKUn6UotodUEMQPqLz0X8OiA-pkY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNumberDisclosureQuery$variables = Record<PropertyKey, never>;
export type MobileNumberDisclosureQuery$data = {
  readonly viewer: {
    readonly consentStatus: {
      readonly consent: {
        readonly consentedAt: string;
      } | null | undefined;
      readonly legalDocument: {
        readonly filename: string;
        readonly " $fragmentSpreads": FragmentRefs<"ConsentDocumentForm_document">;
      };
    } | null | undefined;
  } | null | undefined;
};
export type MobileNumberDisclosureQuery = {
  response: MobileNumberDisclosureQuery$data;
  variables: MobileNumberDisclosureQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filename",
    "value": "gigsmart/sms"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consentedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileNumberDisclosureQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "UserConsentStatus",
            "kind": "LinkedField",
            "name": "consentStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConsentDocument",
                "kind": "LinkedField",
                "name": "consent",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalDocument",
                "kind": "LinkedField",
                "name": "legalDocument",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ConsentDocumentForm_document"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "consentStatus(filename:\"gigsmart/sms\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MobileNumberDisclosureQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "UserConsentStatus",
            "kind": "LinkedField",
            "name": "consentStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConsentDocument",
                "kind": "LinkedField",
                "name": "consent",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalDocument",
                "kind": "LinkedField",
                "name": "legalDocument",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "format",
                    "storageKey": null
                  },
                  {
                    "alias": "source",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "consentStatus(filename:\"gigsmart/sms\")"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Yb2dSB75vIUDi_hQKUn6UotodUEMQPqLz0X8OiA-pkY",
    "metadata": {},
    "name": "MobileNumberDisclosureQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a1216366af8ef497862a85fd85843d72";

export default node;
