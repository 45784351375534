const DOMAINS = {
  PROFILE: "profile",
  ROUTES: "routes"
};

export const TWILIO_SMS_CAPABLE_PHONE = "twilioSmsCapablePhone";
export const TWILIO_CONSENT_GIG_OR_WORKERS_NOTIFICATIONS =
  "twilioConsentGigOrWorkersNotifications";
export const TWILIO_CONSENT_ACCOUNT_STATUS_NOTIFICATIONS =
  "twilioConsentAccountStatusNotifications";
export const TWILIO_CONSENT_NEW_FEATURE_OR_BENEFITS_NOTIFICATIONS =
  "twilioConsentNewFeatureOrBenefitsNotifications";
export const TWILIO_AGREE_TO_TOS = "twilioAgreeToTos";
export const GIGSMART_AGREE_TO_SMS_TOS = "gigsmartAgreeToSmsTos";

// predefined requester keys
const DISPLAY_ORG_CARD = "displayOrgCard";
const DISPLAY_ORG_BADGE = "displayOrgBadge";
const PROFILE_ICON_BADGE_TEXT = "profileIconBadgeText";

export const PROFILE_ICON_KEY = `${DOMAINS.ROUTES}_${PROFILE_ICON_BADGE_TEXT}`;
export const DISPLAY_ORG_BADGE_KEY = `${DOMAINS.PROFILE}_${DISPLAY_ORG_BADGE}`;
export const DISPLAY_ORG_CARD_KEY = `${DOMAINS.PROFILE}_${DISPLAY_ORG_CARD}`;
export const DISPLAY_BUSINESS_CARD = "businessCardShown";
export const DISPLAY_PERSONAL_CARD = "personalCardShown";
export const DISPLAY_OAI_MODAL = "OAIModalShown";
export const DISPLAY_INFORMATIONAL_PROJECT = "InformationalProjectShown";
export const HAS_SHOWN_HOURLY_RATE_NUDGE_MODAL = "hasShownHourlyRateNudgeModal";
export const DIRECT_HIRE_FEE_CONSENT = "DirectHireFeeConsent";

// Predefined worker keys
export const DISPLAY_VERIFIED_MODAL = "getVerifiedModalShown";
export const HIDE_NEW_TAG_FOR_JOB_BOARD = "hideNewTagForJobBoard";
export const VIEWED_JOB_BOARD_INTRO = "viewedJobBoardIntro";
export const HIDE_JOB_BOARD_EDUCATION_CARD = "hideJobBoardEducationCard";
export const HIDE_PROJECT_GIG_EDUCATION_CARD = "hideProjectGigEducationCard";
export const HIDE_SHIFT_GIG_EDUCATION_CARD = "hideShiftGigEducationCard";
export const DISPLAY_STRIDE_MODAL = "showStrideBenefitsModal";
export const DISPLAY_SFCU_MODAL = "showSFCUBenefitsModal";
