import React, { type ReactNode } from "react";
import { View } from "react-native";
import { useStyles } from "../style";

interface Props {
  background?: string;
  children?: ReactNode;
  spacing?: number;
  topSpacing?: number;
}

const Footer = ({
  background = "neutralLight",
  spacing = 4,
  topSpacing,
  ...props
}: Props) => {
  const { styles } = useStyles(
    ({ color, units, metric }) => {
      const px = units(spacing);
      return {
        container: {
          backgroundColor: color.getColor(background),
          paddingTop: units(topSpacing ?? spacing),
          paddingBottom: Math.max(px, metric.bottomSpace()),
          paddingHorizontal: px
        }
      };
    },
    [topSpacing, spacing, background]
  );
  return <View style={styles.container} {...props} />;
};

export default Footer;
