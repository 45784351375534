/**
 * @generated SignedSource<<70c598897ffa372f188828d7cc2f79ad>>
 * @relayHash b8ac06b1151b8c623193506fd620cb26
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:3VijBgRp0O3UrOpJkkMs6YURXb_QQPHbbrf5zvGsRlA

import { ConcreteRequest } from 'relay-runtime';
export type WorkerQualificationProofStatus = "INVALID" | "NOT_PROVIDED" | "VALID" | "%future added value";
export type CommercialDriversLicenseModalQuery$variables = Record<PropertyKey, never>;
export type CommercialDriversLicenseModalQuery$data = {
  readonly viewer: {
    readonly id?: string;
    readonly qualificationProofs?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly backPhoto?: {
            readonly id: string;
            readonly url: string;
          } | null | undefined;
          readonly expirationDate?: string | null | undefined;
          readonly frontPhoto?: {
            readonly id: string;
            readonly url: string;
          } | null | undefined;
          readonly id?: string;
          readonly issueDate?: string | null | undefined;
          readonly issuingState?: string | null | undefined;
          readonly licenseNumber?: string | null | undefined;
          readonly status?: WorkerQualificationProofStatus;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CommercialDriversLicenseModalQuery = {
  response: CommercialDriversLicenseModalQuery$data;
  variables: CommercialDriversLicenseModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE type = DRIVERS_LICENSE"
  }
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFile",
      "kind": "LinkedField",
      "name": "backPhoto",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFile",
      "kind": "LinkedField",
      "name": "frontPhoto",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuingState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licenseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "WorkerQualificationDriversLicenseProof",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommercialDriversLicenseModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "qualificationProofs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "qualificationProofs(first:1,query:\"WHERE type = DRIVERS_LICENSE\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommercialDriversLicenseModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "qualificationProofs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v0/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "qualificationProofs(first:1,query:\"WHERE type = DRIVERS_LICENSE\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:3VijBgRp0O3UrOpJkkMs6YURXb_QQPHbbrf5zvGsRlA",
    "metadata": {},
    "name": "CommercialDriversLicenseModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "999ea9155d62b7978b341749cf058dd8";

export default node;
