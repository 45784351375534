import React, { type ComponentProps } from "react";
import { FlexRow } from "../flex";
import { IconText } from "../icon";
import { theme, useStyles } from "../style";
import { KatanaText } from "../text";

type Props = ComponentProps<typeof IconText> & {
  order?: number;
  inset?: number;
  bold?: boolean;
  infoText?: string | null;
  inline?: boolean;
};

const BulletListItem = ({
  iconText,
  iconName,
  iconColor,
  tintColor,
  textStyle,
  children,
  order,
  bold = true,
  spacing,
  inset = 4,
  iconStyle,
  style,
  infoText,
  inline = true,
  ...props
}: Props) => {
  // setup defaults
  if (order) iconText = iconText || `${order}.`;
  else iconName = iconName || "check";
  iconColor =
    iconColor ?? tintColor ?? (order ? theme.color.black : theme.color.green);
  spacing = spacing || (order ? 1.5 : 2);

  const { styles } = useStyles(
    ({ units, font }) => ({
      noFlex: { flex: -1 },
      textWrapper: { flex: 1, paddingHorizontal: units(inset) },
      icon: {
        fontWeight: bold ? font.weight.bold : font.weight.regular,
        marginLeft: 0,
        marginRight: 0,
        minWidth: 26,
        textAlign: "center",
        paddingRight: 6
      },
      infoText: { marginLeft: "auto", textAlign: "right" }
    }),
    [inset]
  );

  return (
    <FlexRow style={style}>
      <IconText
        style={[styles.textWrapper, inline && styles.noFlex]}
        iconStyle={[styles.icon, iconStyle]}
        iconText={iconText}
        iconName={iconName}
        iconColor={iconColor}
        tintColor={tintColor}
        alignItems="flex-start"
        textStyle={[inline && styles.noFlex, textStyle]}
        spacing={spacing}
        {...props}
      >
        {children}
      </IconText>
      {!!infoText && (
        <KatanaText style={styles.infoText} color={tintColor}>
          {infoText}
        </KatanaText>
      )}
    </FlexRow>
  );
};

export default BulletListItem;
