import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type RNScrollView,
  RefreshControl,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { useStyle } from "@gigsmart/atorasu/style";
import {
  type GraphQLTaggedNode,
  type OperationType,
  type RelayPreloadedQuery,
  Suspense,
  refetchOnly,
  useFetchQuery,
  useRelayOrchestrator,
  useRelayPaginationFragment,
  useRelayPreloadedQuery,
  useRelayQueryLoader,
  type useRelayQueryLoaderHookType
} from "@gigsmart/relay";
import React, {
  useEffect,
  type ComponentProps,
  createContext,
  type ReactNode,
  useContext,
  useCallback,
  useMemo,
  useState,
  useRef,
  type RefObject
} from "react";
import {
  DataProvider,
  type Dimension,
  LayoutProvider,
  RecyclerListView,
  type RecyclerListViewProps
} from "recyclerlistview";
import ContextProvider from "./ContextProvider";
import PaginatorScrollView from "./PaginatorScrollView";

export type PaginatorComponent<TOperation extends OperationType> = (
  props: PaginatorProps<TOperation>
) => JSX.Element | null;

export type QueryVariablesType = Record<
  string,
  string | number | null | undefined
> | null;

export type QueryVariablesContextType = [
  QueryVariablesType,
  (val: QueryVariablesType) => void
];

interface Options {
  displayName: string;
  query: GraphQLTaggedNode;
}

interface LoadBoundaryProps {
  children: ReactNode;
}

export interface PaginatorProps<TOperation extends OperationType> {
  testID: string;
  variables: TOperation["variables"];
  paginationFragment: GraphQLTaggedNode;
  extractPaginationFragment: (queryResult: TOperation["response"]) => any;
  extractData: (paginationFragmentResult: any) => any[];
  rowRenderer: ComponentProps<typeof RecyclerListView>["rowRenderer"];
  forceNonDeterministicRendering?: ComponentProps<
    typeof RecyclerListView
  >["forceNonDeterministicRendering"];
  ListHeaderComponent?:
    | ReactNode
    | ((v: { data: any; refetching: boolean }) => ReactNode);
  ListFooterComponent?: ReactNode;
  ListEmptyComponent?: ReactNode;
  pageSize?: number;
  layoutProviderFn: (type: number | string, dim: Dimension) => void;
  dataProviderFn: (r1: any, r2: any) => boolean;
  onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  layoutTypeProviderFn?: (index: number, data: any) => number | string;
  fixedHeightRows?: boolean;
  applyWindowCorrection?: RecyclerListViewProps["applyWindowCorrection"];
  extendedState?: RecyclerListViewProps["extendedState"];
  canChangeSize?: RecyclerListViewProps["canChangeSize"];
  renderItemContainerWeb?: RecyclerListViewProps["renderItemContainer"];
  renderItemContainerMobile?: RecyclerListViewProps["renderItemContainer"];
  footerPosition?: "standard" | "bottom";
  automaticallyAdjustContentInsets?: boolean;
}

interface InternalPaginatorProps<TOperation extends OperationType>
  extends PaginatorProps<TOperation> {
  queryReference: RelayPreloadedQuery<TOperation>;
  scrollViewRef: RefObject<RNScrollView | null>;
  refetching: boolean;
}

export default function createPaginator<TOperation extends OperationType>({
  query,
  displayName
}: Options) {
  const LoaderContext = createContext<
    [
      useRelayQueryLoaderHookType<TOperation>[0],
      useRelayQueryLoaderHookType<TOperation>[1],
      GraphQLTaggedNode | null
    ]
  >([null, () => {}, null]);

  const ScrollIndexContext = createContext<ContextProvider | undefined>(
    undefined
  );

  const QueryVariablesContext = createContext<QueryVariablesContextType>([
    null,
    () => null
  ]);

  const LoadBoundary = ({ children }: LoadBoundaryProps) => {
    const [queryReference, loadQuery, disposeQuery] =
      useRelayQueryLoader<TOperation>(query);
    const [scrollIndexContext] = useState(new ContextProvider(displayName));
    const [queryVariables, setQueryVariables] =
      useState<QueryVariablesType>(null);
    useEffect(() => {
      return () => {
        disposeQuery();
      };
    }, [disposeQuery]);
    return (
      <LoaderContext.Provider value={[queryReference, loadQuery, query]}>
        <ScrollIndexContext.Provider value={scrollIndexContext}>
          <QueryVariablesContext.Provider
            value={[queryVariables, setQueryVariables]}
          >
            {children}
          </QueryVariablesContext.Provider>
        </ScrollIndexContext.Provider>
      </LoaderContext.Provider>
    );
  };

  const PaginatorQueryLoader = ({
    variables,
    pageSize,
    paginationFragment,
    extractPaginationFragment,
    extractData,
    ...listProps
  }: PaginatorProps<TOperation>) => {
    const [refetching, setRefetching] = useState(false);
    const [queryReference, loadQuery, query] = useContext(LoaderContext);
    const fetchQuery = useFetchQuery(query);
    const [queryVariables, setQueryVariables] = useContext(
      QueryVariablesContext
    );
    const scrollViewRef = useRef<RNScrollView>(null);
    useEffect(() => {
      if (!queryReference)
        loadQuery(variables, { fetchPolicy: "network-only" });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryReference, loadQuery]);
    useEffect(() => {
      if (
        JSON.stringify(variables) !== JSON.stringify(queryVariables) &&
        query
      ) {
        setRefetching(true);
        fetchQuery(variables).subscribe({
          complete: () => {
            loadQuery(variables, {
              fetchPolicy: "store-or-network"
            });
            setQueryVariables(variables);
            setRefetching(false);
            setTimeout(() => {
              scrollViewRef?.current?.scrollTo?.({
                x: 0,
                y: 0,
                animated: false
              });
            }, 250);
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(variables),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(queryVariables),
      setQueryVariables,
      loadQuery
    ]);

    return queryReference ? (
      <Suspense fallback={null}>
        <Paginator
          queryReference={queryReference}
          pageSize={pageSize}
          variables={variables}
          paginationFragment={paginationFragment}
          extractPaginationFragment={extractPaginationFragment}
          extractData={extractData}
          scrollViewRef={scrollViewRef}
          refetching={refetching}
          {...listProps}
        />
      </Suspense>
    ) : null;
  };

  const Paginator = ({
    testID,
    variables,
    pageSize = 10,
    queryReference,
    paginationFragment,
    extractPaginationFragment,
    extractData,
    ListHeaderComponent,
    ListFooterComponent,
    ListEmptyComponent,
    onScroll,
    layoutProviderFn,
    layoutTypeProviderFn,
    fixedHeightRows,
    dataProviderFn,
    rowRenderer,
    scrollViewRef,
    refetching,
    footerPosition,
    renderItemContainerMobile,
    renderItemContainerWeb,
    automaticallyAdjustContentInsets,
    ...listProps
  }: InternalPaginatorProps<TOperation>) => {
    const containerStyle = useStyle({ flex: 1 });
    const { fetchQuery } = useRelayOrchestrator();
    const isWeb = useMatchesViewport(({ platform }) => platform.web);
    const result = useRelayPreloadedQuery<TOperation>(query, queryReference);
    const paginationFragmentRef = extractPaginationFragment(result);
    const { data, hasNext, loadNext, refetch, isLoadingNext } =
      useRelayPaginationFragment<TOperation, any>(
        paginationFragment,
        paginationFragmentRef
      );

    const scrollIndexContext = useContext(ScrollIndexContext);
    const dataProvider = useMemo(() => {
      const dataProviderOverrideFn = (r1: any, r2: any) => {
        if (r1 === "REFETCH" || r2 === "REFETCH") return true;
        if (r1 === "EMPTY" || r2 === "EMPTY") {
          return true;
        }
        return dataProviderFn(r1, r2);
      };
      return new DataProvider(dataProviderOverrideFn);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const listData = useMemo(
      () => {
        if (refetching) {
          return dataProvider.cloneWithRows(["REFETCH"]);
        }
        const extractedData = extractData(data);
        if (extractedData?.length === 0)
          return dataProvider.cloneWithRows(["EMPTY"]);
        return dataProvider.cloneWithRows(extractData(data));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, refetching]
    );
    const [refreshing, setRefreshing] = useState(false);

    const getLayoutTypeWithDataFn = useCallback(
      (index: number): string | number => {
        const data = listData.getDataForIndex(index);
        if (data === "EMPTY") return "EMPTY";
        if (data === "REFETCH") return "REFETCH";
        if (fixedHeightRows && layoutTypeProviderFn)
          return layoutTypeProviderFn(index, null);
        if (!layoutTypeProviderFn) return 0;
        return layoutTypeProviderFn(index, data);
      },
      [layoutTypeProviderFn, fixedHeightRows, listData]
    );

    const layoutProvider = useMemo(
      () => new LayoutProvider(getLayoutTypeWithDataFn, layoutProviderFn),
      [layoutProviderFn, getLayoutTypeWithDataFn]
    );

    const handleEndReached = useCallback(() => {
      if (hasNext && !isLoadingNext) {
        loadNext(pageSize);
      }
    }, [hasNext, loadNext, pageSize, isLoadingNext]);

    const handleRefresh = useCallback(() => {
      setRefreshing(true);
      refetchOnly<TOperation>(
        refetch,
        fetchQuery,
        paginationFragmentRef,
        paginationFragment,
        data.id
      ).finally(() => setRefreshing(false));
    }, [data.id]);

    const wrappedRowRenderer = useCallback(
      (type: any, data: any, index: number, extendedState: any) => {
        if (type === "EMPTY") {
          return <>{ListEmptyComponent}</>;
        }
        if (type === "REFETCH") {
          return null;
        }

        return rowRenderer(type, data, index, extendedState);
      },
      [rowRenderer, ListEmptyComponent]
    );
    return (
      <RecyclerListView
        style={containerStyle}
        canChangeSize
        externalScrollView={PaginatorScrollView}
        rowRenderer={wrappedRowRenderer}
        layoutProvider={layoutProvider}
        dataProvider={listData}
        contextProvider={scrollIndexContext}
        onEndReached={handleEndReached}
        onRecreate={(params) => {
          if (params?.lastOffset)
            setTimeout(() => {
              scrollViewRef?.current?.scrollTo?.({
                x: 0,
                y: params?.lastOffset,
                animated: false
              });
            }, 250);
        }}
        scrollViewProps={{
          testID,
          footerPosition,
          header:
            typeof ListHeaderComponent === "function"
              ? ListHeaderComponent({ data, refetching })
              : ListHeaderComponent,
          footer: ListFooterComponent,
          isLoading: isLoadingNext,
          scrollViewRef,
          onScrollHandler: onScroll,
          refreshControl: (
            <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
          ),
          automaticallyAdjustContentInsets
        }}
        renderItemContainer={
          isWeb ? renderItemContainerWeb : renderItemContainerMobile
        }
        {...listProps}
      />
    );
  };

  Paginator.displayName = displayName;
  return [
    LoadBoundary,
    PaginatorQueryLoader as PaginatorComponent<TOperation>,
    QueryVariablesContext
  ] as const;
}
