/**
 * @generated SignedSource<<011301c07b4f0f1f0f5db3efa366ceac>>
 * @relayHash 61b321bce4d44c27224d10326a571468
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:b7i0-3uM2gWpC96DUzKwyucbqNa36NZVR3LqkU3-4WU

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
export type TypeStepDirectHireClaimQuery$variables = {
  id: string;
};
export type TypeStepDirectHireClaimQuery$data = {
  readonly node: {
    readonly directHireType?: DirectHireType | null | undefined;
  } | null | undefined;
};
export type TypeStepDirectHireClaimQuery = {
  response: TypeStepDirectHireClaimQuery$data;
  variables: TypeStepDirectHireClaimQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireType",
      "storageKey": null
    }
  ],
  "type": "DirectHireClaim",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TypeStepDirectHireClaimQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TypeStepDirectHireClaimQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:b7i0-3uM2gWpC96DUzKwyucbqNa36NZVR3LqkU3-4WU",
    "metadata": {},
    "name": "TypeStepDirectHireClaimQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5ca0f97eaae7c8a7376710e5fbee791d";

export default node;
