/**
 * @generated SignedSource<<48be7b96859d165d2d4e57ed1a69585b>>
 * @relayHash 9d11be75c1571690f23ecc18f18ecabb
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:X1HNp1yBw2hZ7jDxxyURxfW_Y37hMi3kEBvQkgaw-wI

import { ConcreteRequest } from 'relay-runtime';
export type EngagementDisputeCommentType = "ADDITIONAL_PAYMENT" | "TIMESHEET" | "%future added value";
export type AddEngagementDisputeInput = {
  comments?: ReadonlyArray<EngagementDisputeCommentInput> | null | undefined;
  disputeTargetId: string;
  engagementId: string;
};
export type EngagementDisputeCommentInput = {
  body: string;
  type: EngagementDisputeCommentType;
};
export type reviewDisputeStepAddEngagementDisputeMutation$variables = {
  input: AddEngagementDisputeInput;
};
export type reviewDisputeStepAddEngagementDisputeMutation$data = {
  readonly addEngagementDispute: {
    readonly newEngagementDisputeEdge: {
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type reviewDisputeStepAddEngagementDisputeMutation = {
  response: reviewDisputeStepAddEngagementDisputeMutation$data;
  variables: reviewDisputeStepAddEngagementDisputeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddEngagementDisputePayload",
    "kind": "LinkedField",
    "name": "addEngagementDispute",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EngagementDisputesEdge",
        "kind": "LinkedField",
        "name": "newEngagementDisputeEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementDispute",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reviewDisputeStepAddEngagementDisputeMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reviewDisputeStepAddEngagementDisputeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:X1HNp1yBw2hZ7jDxxyURxfW_Y37hMi3kEBvQkgaw-wI",
    "metadata": {},
    "name": "reviewDisputeStepAddEngagementDisputeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "ec04ac9213578ac18db12f738638051c";

export default node;
