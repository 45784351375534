import React, { useState, type ComponentProps } from "react";
import { FlexColumn, FlexRow } from "../flex";
import { useStyles } from "../style";
import { StyledText } from "../text";
import StyledCheckbox from "./styled-checkbox";
import StyledTextInput from "./styled-text-input";

export default function StyledOptionalTextInput(
  props: ComponentProps<typeof StyledTextInput>
) {
  const [isCheckedNone, setCheckedNone] = useState(false);
  const checked = props.value === null || isCheckedNone;

  const { styles } = useStyles(({ units }) => ({
    noneCheckbox: {
      top: units(1)
    }
  }));

  const toggleNone = () => {
    setCheckedNone(!checked);
    props.onChangeText?.("");
  };

  return (
    <FlexRow alignItems="flex-start">
      <FlexColumn fill={1}>
        <StyledTextInput
          {...props}
          disabled={Boolean(props.disabled) || isCheckedNone}
        />
      </FlexColumn>
      <FlexColumn grow={0} style={styles.noneCheckbox}>
        <StyledCheckbox
          disabled={props.disabled}
          onPress={toggleNone}
          checked={checked}
          eventTargetName="Optional Text Checkbox"
          title={<StyledText color="blue">None</StyledText>}
          position="top"
        />
      </FlexColumn>
    </FlexRow>
  );
}
