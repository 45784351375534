import { ContentArea, Dimensions, Spacer } from "@gigsmart/atorasu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { getConnectionEdges, graphql } from "@gigsmart/relay";
import WorkerEngagementCard from "@gigsmart/seibutsu/engagement/WorkerEngagementCard";
import { createPaginator } from "@gigsmart/seibutsu/shared/Paginator";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import React, { useCallback } from "react";
import type { WorkerParamList } from "../navigation/types";
import type {
  pendingTimesheetsScreenQuery,
  pendingTimesheetsScreenQuery$data
} from "./__generated__/pendingTimesheetsScreenQuery.graphql";
import type { pendingTimesheetsScreen_worker$data } from "./__generated__/pendingTimesheetsScreen_worker.graphql";

const [LoadBoundary, Paginator] = createPaginator<pendingTimesheetsScreenQuery>(
  {
    query: graphql`
      query pendingTimesheetsScreenQuery {
        viewer {
          ... on Worker {
            ...pendingTimesheetsScreen_worker
          }
        }
      }
    `,
    displayName: "PendingTimesheetsList"
  }
);

const paginationFragment = graphql`
  fragment pendingTimesheetsScreen_worker on Worker
  @refetchable(queryName: "PendingTimesheetsScreenPageQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
  ) {
    engagements(
      first: $count
      after: $after
      query: "WHERE workerCanApproveTimesheet = TRUE ORDER BY currentStateTransitionedAt ASC"
    ) @connection(key: "pendingTimesheetsScreen_engagements", filters: []) {
      edges {
        node {
          id
          ...WorkerEngagementCard_engagement
        }
      }
    }
  }
`;

type Props = AppScreenProps<WorkerParamList, "PendingTimesheets">;

export default function PendingTimesheetsScreen({ navigation }: Props) {
  const { width: windowWidth } = Dimensions.get("window");
  const dataProviderFn = useCallback(
    (r1: any, r2: any) => r1?.node?.id !== r2?.node?.id,
    []
  );

  const layoutProviderFn = useCallback(
    (_type: any, dim: { width: number; height: number }) => {
      dim.width = windowWidth;
      dim.height = 260;
    },
    [windowWidth]
  );
  return (
    <LoadBoundary>
      <Paginator
        testID="pending-timesheets-list"
        variables={{}}
        extractData={(result: pendingTimesheetsScreen_worker$data) =>
          getConnectionEdges(result?.engagements)
        }
        extractPaginationFragment={(
          result: pendingTimesheetsScreenQuery$data
        ) => result?.viewer}
        dataProviderFn={dataProviderFn}
        layoutProviderFn={layoutProviderFn}
        forceNonDeterministicRendering
        paginationFragment={paginationFragment}
        ListHeaderComponent={<Spacer />}
        ListFooterComponent={<Spacer />}
        rowRenderer={(
          _type: string | number,
          item: ObjectUtils.Path<
            pendingTimesheetsScreen_worker$data,
            ["engagements", "edges", 0]
          >
        ) => (
          <ContentArea fill size="none">
            <WorkerEngagementCard
              fragmentRef={item?.node}
              onPress={() =>
                navigation.push("ShiftDetails", { id: item?.node?.id ?? "" })
              }
            />
            <Spacer />
          </ContentArea>
        )}
      />
    </LoadBoundary>
  );
}
