import {
  Button,
  Column,
  ContentArea,
  Divider,
  GridNull,
  IconText,
  SectionHeader,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import ShiftPreviewRow from "../../gig/worker/ShiftPreviewRow";
import HomeScreenCard from "../HomescreenCard";
import type {
  AvailablePickupShiftsCardQuery,
  AvailablePickupShiftsCardQuery$data
} from "./__generated__/AvailablePickupShiftsCardQuery.graphql";

export default createSuspendedQueryContainer<AvailablePickupShiftsCardQuery>(
  function AvailablePickupShiftsCardQueryWrapper({ response }) {
    return (
      <AvailablePickupShiftsCard
        availableGigs={response?.viewer?.availableGigs}
      />
    );
  },
  {
    query: graphql`
      query AvailablePickupShiftsCardQuery {
        viewer {
          ... on Worker {
            availableGigs(
              first: 3
              gigTypes: [PAID, VOLUNTEER]
              maxDistance: 75
              pickupEligible: true
            ) {
              edges {
                estimatedPayment {
                  payRate
                  netPay
                }
                node {
                  id
                  name
                  pickupEligible
                  gigSeries {
                    id
                    boosted
                  }
                  position {
                    name
                  }
                  organization {
                    logoUrl
                  }
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    variables: {}
  }
);

interface Props {
  availableGigs: ObjectUtils.Path<
    AvailablePickupShiftsCardQuery$data,
    ["viewer", "availableGigs"]
  >;
}

export function AvailablePickupShiftsCard({ availableGigs }: Props) {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const gigsList = getConnectionEdges(availableGigs);
  if (gigsList?.length === 0) return <GridNull />;

  return (
    <HomeScreenCard
      headerIcon="alarm-clock"
      headerTitle="Shifts Available for Pick Up Today"
      testID="available-pickup-shifts-card"
      contentAreaSize="none"
      contentAreaVariant="none"
      headerColor="emphasized"
    >
      <Column>
        <ContentArea size="none">
          <SectionHeader
            left={
              <IconText
                icon="business-time"
                textVariant="subheader"
                color="black"
                size="small"
              >
                Shifts
              </IconText>
            }
            variant="none"
            right={
              <Button
                testID="view-all-pickup-shifts-btn"
                color="primary"
                icon="chevron-right"
                iconPlacement="right"
                label="View All"
                onPress={() =>
                  navigation.push("BrowseShifts", { type: "pickups" })
                }
                variant="clear"
                size="small"
              />
            }
          />
        </ContentArea>
        <ContentArea size="none">
          <Stack size="compact">
            {gigsList.map(({ node, estimatedPayment }) =>
              node ? (
                <ShiftPreviewRow
                  key={node.id}
                  name={node.name}
                  logoUrl={node.organization?.logoUrl}
                  id={node.id}
                  boosted={node.gigSeries?.boosted}
                  pickupEligible={node.pickupEligible}
                  netPay={estimatedPayment?.netPay}
                />
              ) : null
            )}
          </Stack>
          <Spacer />
        </ContentArea>
        <Divider />
      </Column>
    </HomeScreenCard>
  );
}
