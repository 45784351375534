import React from "react";
import { View } from "react-native";
import { useStyles } from "../../style";
import { KatanaText } from "../../text";

interface Props {
  testID?: string;
  label: string;
  active?: boolean;
  showBadge?: boolean;
}

const TabItemLabel = ({ testID, label, showBadge, active }: Props) => {
  const { styles } = useStyles(({ font, units, color }) => ({
    text: {
      ...font.body("bold"),
      textAlign: "center",
      fontSize: font.size.medium,
      color: color.inactiveText
    },
    textActive: {
      color: color.blue
    },
    circle: {
      width: units(3),
      height: units(3),
      borderRadius: units(3) / 2,
      backgroundColor: color.orange,
      position: "absolute",
      top: 7,
      right: "7%",
      "@tablet": { right: "0%" }
    }
  }));
  return (
    <>
      <KatanaText
        testID={testID}
        style={[styles.text, active && styles.textActive]}
      >
        {label}
      </KatanaText>
      {showBadge && <View style={styles.circle} />}
    </>
  );
};

export default TabItemLabel;
