import { captureError } from "@gigsmart/dekigoto";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import type { NotificationEntry } from "@gigsmart/isomorphic-shared/notifications";
import {
  getConnectionNodes,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import { intersection } from "lodash";
import { DateTime } from "luxon";
import moment from "moment-timezone";

import type { notificationRoutingHandler_node$key } from "./__generated__/notificationRoutingHandler_node.graphql";

type Message = Pick<NonNullable<NotificationEntry>, "data">;

interface RouteData {
  pathname?: string | null | undefined;
  state?: {
    drawerVisible?: boolean | null;
    showSummaryTab?: boolean | null;
  } | null;

  showNotAvailable?: "shift" | "project" | "job";
  nodeId?: string | null;
}
interface MessageDataPayload {
  action: string;
  engagementId: string;
  engagementGlobalId?: string;
  gigId?: string;
  gigGlobalId?: string;
  globalId?: string;
  directHireClaimId?: string;
}

const getMessagePayload = (
  message: Message | null | undefined
): { data?: any; payload?: MessageDataPayload | null } => {
  if (!message?.data) return {};

  try {
    const data = JSON.parse(message.data);
    let payload = null;
    if (data.payload) {
      payload =
        typeof data.payload === "object"
          ? data.payload
          : JSON.parse(data.payload);
    }

    return { data, payload };
  } catch (err) {
    // Error reading message payload
    captureError(err as Error);
  }

  return {};
};

export const handleAction = (
  message: NotificationEntry
): RouteData | null | undefined => {
  const { data, payload } = getMessagePayload(message);
  if (payload?.directHireClaimId) {
    return { pathname: `/direct-hire/${payload?.directHireClaimId}` };
  }

  switch (payload?.action) {
    case "engagement_payment":
      return { pathname: "/wallet" };
    case "no_payment_method":
      return { pathname: "/profile/payment" };
    case "payout_paid":
      return { pathname: "/wallet" };
    case "payout_failed":
      return { pathname: "/support" };
    default: {
      // if there's no organization, we don't know if it's a project or a shift
      const prefix = message.organization?.name ? "/shifts" : "/gigs";
      const engagementId =
        payload?.engagementGlobalId ??
        payload?.engagementId ??
        data.engagementId;

      if (engagementId) {
        return { pathname: `${prefix}/${engagementId}` };
      }
      return null;
    }
  }
};

export const handleTopic = (
  nodeRef: notificationRoutingHandler_node$key | null | undefined,
  message: NotificationEntry
): RouteData | null | undefined => {
  const node = readRelayInlineFragment(
    graphql`
      fragment notificationRoutingHandler_node on Node @inline {
        id
        __typename

        ... on Gig {
          gigType
          endsAt
          isClosed
          currentState {
            name
            canApply
          }
          gigSeries {
            id
          }
        }

        ... on GigSeries {
          gigType
          engagements(first: 20) {
            edges {
              node {
                currentState {
                  name
                }
              }
            }
          }
          nextGig: availableGigs(first: 1, input: { maxDistance: 75 }) {
            edges {
              node {
                id
              }
            }
          }
        }

        ... on JobPosting {
          canceled
          endDate
          visible
        }
      }
    `,
    nodeRef
  );

  const isProject = node?.gigType === "PROJECT";

  switch (node?.__typename) {
    case "Engagement": {
      const prefix = isProject ? "/projects" : "/shifts";
      return { pathname: `${prefix}/${node.id}` };
    }
    case "GigSeries": {
      const { data } = getMessagePayload(message);
      if (
        data?.notificationType === "GigSeriesEngagementsTransition" ||
        data?.notificationType === "NewEngagementsNotification"
      ) {
        const states = getConnectionNodes(node?.engagements)?.map(
          (engagement) => engagement?.currentState?.name
        );
        const type = isProject ? "projects" : "shifts";
        return {
          pathname: getPathnameForEngagementTransitionState(states, type)
        };
      }

      const prefix = isProject ? "/browse/projects" : "/browse/shifts";
      return node.nextGig?.edges?.[0]?.node?.id
        ? { pathname: `${prefix}/${node.id}` }
        : { showNotAvailable: isProject ? "project" : "shift" };
    }

    case "Gig": {
      const isAvailable =
        !!node.currentState?.canApply &&
        !node.isClosed &&
        (isProject || !node.endsAt || moment(node.endsAt) > moment());

      return isProject
        ? isAvailable
          ? { pathname: `/browse/projects/${node?.gigSeries?.id}` }
          : { showNotAvailable: "project" }
        : isAvailable
          ? { pathname: `/browse/shifts/${node.id}` }
          : { showNotAvailable: "shift" };
    }

    case "JobPosting": {
      return node.canceled ||
        DateTime.fromISO(node.endDate ?? "") < DateTime.local() ||
        !node.visible
        ? { showNotAvailable: "job" }
        : { pathname: `/browse/jobs/${node.id}` };
    }
  }

  return null;
};

export function getPathnameForEngagementTransitionState(
  states: EngagementStateName[],
  type: "shifts" | "projects"
) {
  if (
    intersection(
      [
        "SCHEDULED",
        "EN_ROUTE",
        "AWAITING_START",
        "WORKING",
        "PAUSED",
        "PENDING_TIMESHEET_APPROVAL"
      ],
      states
    ).length
  ) {
    return `/${type}`;
  }
  if (states?.includes("CONFIRMING")) {
    return `/${type}?shiftsTab=confirming`;
  }
  if (states?.includes("ENDED")) {
    return "/history/completed";
  }
  if (intersection(["CANCELED", "CANCELED_WITH_PAY"], states).length) {
    return "/history/canceled";
  }
  return "/history/not-hired";
}
