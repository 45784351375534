import { ActionRow } from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { type ComponentProps } from "react";
import type { DirectHireActionRowQuery } from "./__generated__/DirectHireActionRowQuery.graphql";

interface Props {
  bonus?: string | null;
  variant: ComponentProps<typeof ActionRow>["variant"];
  workerId?: string;
}

export function DirectHireActionRow({ bonus, variant, workerId }: Props) {
  const history = useHistory();
  const title = workerId
    ? DirectPaymentsOnly.select(
        "Paid this Worker Directly?",
        "Paid or Hired this Worker Directly?"
      )
    : DirectPaymentsOnly.select(
        "Paid Direct by this Organization?",
        "Paid or Hired Directly by this Org?"
      );

  const note = workerId
    ? DirectPaymentsOnly.select(
        "Start a direct payment acknowledgement.",
        "Start a direct payment or hire acknowledgement."
      )
    : DirectPaymentsOnly.select(
        `You may be eligible to receive a ${currency.humanize(
          bonus
        )} Direct Payment Bonus!`,
        `Earn ${currency.humanize(bonus)} by letting us know!`
      );

  return (
    <ActionRow
      testID="direct-hire-action-row"
      variant={variant}
      title={title}
      icon="handshake"
      note={note}
      onPress={() =>
        workerId
          ? history.push(`/direct-hire/${workerId}/add`)
          : history.push("/direct-hire")
      }
    />
  );
}

export default createSuspendedQueryContainer<DirectHireActionRowQuery, Props>(
  function DirectHireActionRowQueryWrapper({
    variant,
    workerId,
    response: result
  }) {
    return (
      <DirectHireActionRow
        bonus={result?.viewer?.directHireBonus}
        variant={variant}
        workerId={workerId}
      />
    );
  },
  {
    query: graphql`
      query DirectHireActionRowQuery {
        viewer {
          ... on Worker {
            directHireBonus
          }
        }
      }
    `,
    variables: {}
  }
);
