import {
  NavAction,
  Text,
  defaultBackPrompt,
  defaultCancelPrompt,
  useHistoryBlock
} from "@gigsmart/atorasu";
import { useFormSubmit } from "@gigsmart/fomu";
import { NavPortalEntrance, defaultBackHandler } from "@gigsmart/kaizoku";
import React, { useCallback } from "react";

interface Props {
  onGoBack?: () => void;
  onCancel?: () => void;
}

export default function FomuNavBackButton({ onGoBack, onCancel }: Props) {
  const { dirty } = useFormSubmit();

  const unblock = useHistoryBlock({
    enabled: dirty,
    onConfirm: () => {
      onGoBack?.();
      return true;
    }
  });

  const handleCancel = useCallback(async () => {
    if (await defaultCancelPrompt()) {
      unblock();
      onCancel ? onCancel() : defaultBackHandler();
    }
  }, [unblock, onCancel]);

  const handleBackPress = useCallback(async () => {
    if (dirty) {
      if (await defaultBackPrompt()) onGoBack?.();
    } else {
      onGoBack?.();
    }
    return true;
  }, [dirty, onGoBack]);
  return (
    <NavPortalEntrance
      onBackPress={() => {
        handleBackPress();
        return true;
      }}
      rightAccessory={
        <NavAction
          testID="create-gig-stepper-cancel-btn"
          onPress={handleCancel}
        >
          <Text weight="bold">Cancel</Text>
        </NavAction>
      }
    />
  );
}
