/**
 * @generated SignedSource<<c7027c4a6904d6098f1a9143cc649eb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalDocument_consent$data = {
  readonly consentedAt: string;
  readonly consentedIp: string;
  readonly " $fragmentType": "LegalDocument_consent";
};
export type LegalDocument_consent$key = {
  readonly " $data"?: LegalDocument_consent$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_consent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LegalDocument_consent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consentedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consentedIp",
      "storageKey": null
    }
  ],
  "type": "UserConsentDocument",
  "abstractKey": null
};

(node as any).hash = "631cdf555a86531b8354b99a32d20fdc";

export default node;
