/**
 * @generated SignedSource<<73e9071cbd6aaed3294d0bd46f4d7b06>>
 * @relayHash 7561c4be148b49d5b8098b3da0dcd48d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:TXPqnNXXhmLpFfr4wDw23tE2QPcjOP1csh9t6z_NRLI

import { ConcreteRequest } from 'relay-runtime';
export type WorkerQualificationProofStatus = "INVALID" | "NOT_PROVIDED" | "VALID" | "%future added value";
export type useQualificationsQuery$variables = {
  availableItemsQuery: string;
  deniedItemsQuery: string;
  fieldsQuery: string;
  selectedItemsQuery: string;
};
export type useQualificationsQuery$data = {
  readonly viewer: {
    readonly gigFields?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly availableDefinitions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly label: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly deniedDefinitions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly label: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly iconName: string;
          readonly selectedDefinitions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly label: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly title: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id?: string;
    readonly qualificationProofs?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly status?: WorkerQualificationProofStatus;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type useQualificationsQuery = {
  response: useQualificationsQuery$data;
  variables: useQualificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "availableItemsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deniedItemsQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldsQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedItemsQuery"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "query",
  "value": "WHERE type = DRIVERS_LICENSE"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "WorkerQualificationDriversLicenseProof",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v12 = [
  (v11/*: any*/),
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "fieldsQuery"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iconName",
  "storageKey": null
},
v15 = {
  "kind": "Literal",
  "name": "first",
  "value": 40
},
v16 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinitionsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GigFieldItemDefinition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v17 = {
  "alias": "selectedDefinitions",
  "args": [
    (v15/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "selectedItemsQuery"
    }
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": "deniedDefinitions",
  "args": [
    (v15/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "deniedItemsQuery"
    }
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": "availableDefinitions",
  "args": [
    (v15/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "availableItemsQuery"
    }
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v20 = [
  (v11/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useQualificationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": "qualificationProofs",
                "args": [
                  (v5/*: any*/)
                ],
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "__useQualifications___qualificationProofs_connection",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "__useQualifications___qualificationProofs_connection(query:\"WHERE type = DRIVERS_LICENSE\")"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useQualificationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v20/*: any*/),
                "concreteType": "WorkerQualificationProofsConnection",
                "kind": "LinkedField",
                "name": "qualificationProofs",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerQualificationProofsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "qualificationProofs(first:1,query:\"WHERE type = DRIVERS_LICENSE\")"
              },
              {
                "alias": null,
                "args": (v20/*: any*/),
                "filters": [
                  "query"
                ],
                "handle": "connection",
                "key": "useQualifications___qualificationProofs",
                "kind": "LinkedHandle",
                "name": "qualificationProofs"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:TXPqnNXXhmLpFfr4wDw23tE2QPcjOP1csh9t6z_NRLI",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "viewer",
            "qualificationProofs"
          ]
        }
      ]
    },
    "name": "useQualificationsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8e25bb4097876348989dd081560b3887";

export default node;
