import {
  Button,
  Column,
  Constraint,
  FooterContent,
  type IconName,
  type RNScrollView,
  ScreenScroll,
  ScreenTop,
  Spacer,
  Stack,
  StepperProvider,
  Surface,
  type TBaseStep,
  useMatchesViewport,
  useStepper
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Form } from "@gigsmart/fomu";
import React, { useEffect, useRef } from "react";
import HStepLayout from "./HStepLayout";
import VStepLayout from "./VStepLayout";
import {
  ResponsiveStepperBtnPortal,
  ResponsiveStepperPortal,
  ResponsiveStepperRightAccessoryPortal
} from "./portal";

export interface Step {
  stepTitle: string;
  stepDescription?: string | ((stepData: any) => string);
  stepTitleIcon?: IconName;
  hideBackButton?: boolean;
  backButtonExitTo?: string;
  buttonShouldAvoidKeyboard?: boolean;
  stickyFooter?: boolean;
  testID?: string;
  grow?: boolean;
}

interface Props<S extends Record<string, any>> {
  steps: Array<TBaseStep<Step, S>>;
  stepsData?: S;
  eventContext?: string | null;
  initialStep?: number | string;
  onComplete?: (props: any) => void;
  showStepIndicator?: boolean;
}

/** @deprecated use `createStepper` factory instead. */
export default function ResponsiveStepper<S extends Record<string, any>>({
  steps,
  stepsData,
  initialStep,
  eventContext,
  onComplete,
  showStepIndicator = true
}: Props<S>) {
  return (
    <ResponsiveStepperPortal.Realm>
      <ResponsiveStepperBtnPortal.Realm>
        <ResponsiveStepperRightAccessoryPortal.Realm>
          <Form onSubmit={() => {}} initialValues={stepsData}>
            <StepperProvider<Step, S>
              eventContext={eventContext}
              steps={steps}
              initialStepsData={stepsData}
              initialStep={initialStep}
              onComplete={onComplete}
            >
              <Content showStepIndicator={showStepIndicator} />
            </StepperProvider>
          </Form>
        </ResponsiveStepperRightAccessoryPortal.Realm>
      </ResponsiveStepperBtnPortal.Realm>
    </ResponsiveStepperPortal.Realm>
  );
}

function Content<TStep extends Step>({
  showStepIndicator
}: {
  showStepIndicator: boolean;
}) {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const scrollRef = useRef<RNScrollView | null>(null);
  const { activeStep, prevStep } = useStepper<TStep, {}>();
  const styles = useStyles(() => ({
    btnContainer: {
      marginRight: 100,
      width: 198
    }
  }));

  useEffect(() => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: false });
  }, [activeStep?.stepName]);

  if (!activeStep) return null;

  const {
    hideBackButton,
    buttonShouldAvoidKeyboard,
    stepName,
    stickyFooter,
    grow
  } = activeStep;

  // TODO: ? provide custom step layouts
  const StepLayout = isMd ? VStepLayout : HStepLayout;
  const computeFooter = (showBtn: boolean | undefined) =>
    !showBtn ? null : isMd && stickyFooter ? (
      <ResponsiveStepperBtnPortal.Exit>
        {({ accessory, accessoryInline, ...btnProps }) => (
          <FooterContent bgColor="transparent" bgSolid size="large">
            <Stack
              {...(accessoryInline
                ? { horizontal: true, justifyContent: "flex-end" }
                : { alignItems: "flex-end" })}
            >
              {accessory}
              <Column style={styles.btnContainer}>
                <Button {...btnProps} />
              </Column>
            </Stack>
          </FooterContent>
        )}
      </ResponsiveStepperBtnPortal.Exit>
    ) : (
      !isMd &&
      buttonShouldAvoidKeyboard && (
        <ResponsiveStepperBtnPortal.Exit>
          {({ accessory, accessoryInline, ...props }) => (
            <FooterContent bgColor="surface" bgSolid size="xsmall">
              <Stack horizontal={accessoryInline}>
                {accessory}
                <Button {...props} />
              </Stack>
            </FooterContent>
          )}
        </ResponsiveStepperBtnPortal.Exit>
      )
    );

  return (
    <ResponsiveStepperPortal.Exit>
      {({ showBtn }) => (
        <ScreenScroll
          scrollRef={scrollRef}
          testID={`${stepName}-screen`}
          color={isMd ? "background" : "surface"}
          footer={computeFooter(showBtn)}
          automaticallyAdjustContentInsets={false}
          keyboardDismissMode="none"
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          grow={grow}
        >
          <Constraint size="large" grow={grow}>
            <Surface
              color={isMd ? "clear" : "surface"}
              variant="flat"
              grow={grow}
            >
              <ScreenTop
                extraSpace="large"
                showBackButton={hideBackButton}
                rightAccessory={<ResponsiveStepperRightAccessoryPortal.Exit />}
              />
              <StepLayout showStepIndicator={showStepIndicator} />
            </Surface>
          </Constraint>
          <Spacer />
        </ScreenScroll>
      )}
    </ResponsiveStepperPortal.Exit>
  );
}
