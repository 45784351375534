/**
 * @generated SignedSource<<bad9e83824d4ea7406461e456c1ce33b>>
 * @relayHash fdd7382fbdb690518c1e453ebd353897
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:h3uTzJe-0zkR3SFYQEFplcB5EAt01Gy4cpD9qgiWTIA

import { ConcreteRequest } from 'relay-runtime';
export type WorkerQualificationStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type SetWorkerQualificationInput = {
  gigFieldItemDefinitionId: string;
  status: WorkerQualificationStatus;
  workerId: string;
};
export type useWorkerGigFieldsMutation$variables = {
  input: SetWorkerQualificationInput;
};
export type useWorkerGigFieldsMutation$data = {
  readonly setWorkerQualification: {
    readonly newWorkerQualificationEdge: {
      readonly node: {
        readonly gigFieldItemDefinition: {
          readonly id: string;
          readonly label: string;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useWorkerGigFieldsMutation = {
  response: useWorkerGigFieldsMutation$data;
  variables: useWorkerGigFieldsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "GigFieldItemDefinition",
  "kind": "LinkedField",
  "name": "gigFieldItemDefinition",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useWorkerGigFieldsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetWorkerQualificationPayload",
        "kind": "LinkedField",
        "name": "setWorkerQualification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerQualificationsEdge",
            "kind": "LinkedField",
            "name": "newWorkerQualificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerQualification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWorkerGigFieldsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetWorkerQualificationPayload",
        "kind": "LinkedField",
        "name": "setWorkerQualification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerQualificationsEdge",
            "kind": "LinkedField",
            "name": "newWorkerQualificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerQualification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:h3uTzJe-0zkR3SFYQEFplcB5EAt01Gy4cpD9qgiWTIA",
    "metadata": {},
    "name": "useWorkerGigFieldsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "314d159bb9cf260204b4c64d8ecbafc4";

export default node;
