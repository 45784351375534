import React, { type ReactNode } from "react";
import { View } from "react-native";
import { StyledIcon } from "../icon";
import { type ViewStyleProp, createStyles } from "../style";

const styles = createStyles(({ color, font, units }) => ({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    marginBottom: 4
  },
  content: {
    flex: 1,
    paddingLeft: units(3),
    paddingRight: units(3)
  },
  chevron: {
    margin: 6,
    fontSize: font.size.extraLarge,
    color: color.neutralLight
  }
}));

interface Props {
  children?: ReactNode;
  chevron?: boolean;
  style?: ViewStyleProp;
}

export default ({ children, chevron, style }: Props) => (
  <View style={[styles.container, style]}>
    <View style={styles.content}>{children}</View>
    {chevron && <StyledIcon name="chevron-right" style={styles.chevron} />}
  </View>
);
