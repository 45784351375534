import { ScreenScroll, Spacer } from "@gigsmart/atorasu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import DirectHireClaimDetails from "@gigsmart/seibutsu/direct-hire/worker/DirectHireClaimDetails";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { directPaymentsAndHiresDetailsScreenWorkerQuery } from "./__generated__/directPaymentsAndHiresDetailsScreenWorkerQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "DirectPaymentsAndHiresDetails">;

export default createSuspendedQueryContainer<
  directPaymentsAndHiresDetailsScreenWorkerQuery,
  Props
>(
  function DirectPaymentsAndHiresDetailsScreen({ response: result }) {
    return (
      <ScreenScroll testID="direct-payments-and-hires-details-screen">
        <Spacer />
        <DirectHireClaimDetails
          fragmentRef={result?.node}
          directHireBonus={result?.viewer?.directHireBonus}
        />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query directPaymentsAndHiresDetailsScreenWorkerQuery($claimId: ID!) {
        viewer {
          ... on Worker {
            directHireBonus
          }
        }
        node(id: $claimId) {
          ... on DirectHireClaim {
            ...DirectHireClaimDetailsWorker_DirectHireClaim
          }
        }
      }
    `,
    variables: ({ route }) => ({ claimId: route.params.id })
  }
);
