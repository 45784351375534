import React from "react";

import { IconText, Pressable, Text } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";

import HomeScreenCard from "../worker/HomescreenCard";
import QualificationCategories from "./QualificationCategories";
import type { QualificationCategories_gigFields$key } from "./__generated__/QualificationCategories_gigFields.graphql";

interface Props {
  workerRef: QualificationCategories_gigFields$key | null | undefined;
}
export default function QualificationsCard({ workerRef }: Props) {
  const history = useHistory();

  const ManageAllButton = (
    <Pressable
      onPress={() => history.push("/profile/qualifications")}
      eventEntityType="qualification-card-press"
      testID="qualification-card-press"
      eventTargetName="Manage Qualifications Button"
    >
      <IconText
        icon="chevron-right"
        iconPlacement="right"
        color="primary"
        iconSize="small"
        spacing="compact"
      >
        <Text variant="subheader" color="primary">
          Manage All
        </Text>
      </IconText>
    </Pressable>
  );

  return (
    <HomeScreenCard
      headerTitle="Your Qualifications"
      headerIcon="clipboard-check"
      action={ManageAllButton}
    >
      <QualificationCategories fragmentRef={workerRef} />
    </HomeScreenCard>
  );
}
