/**
 * @generated SignedSource<<cae092303b5182fbf6e6f1c7a2b9a284>>
 * @relayHash c49c88f4ce6bc043cbfc6127a8c9b979
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:cmk3uy7LR0HdXNhBczXmzjqF3Mr8l8WeKxyCwbzoTas

import { ConcreteRequest } from 'relay-runtime';
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type ConfirmUserEmailInput = {
  token: string;
  userType: UserType;
};
export type confirmRegistrationMutation$variables = {
  input: ConfirmUserEmailInput;
};
export type confirmRegistrationMutation$data = {
  readonly confirmUserEmail: {
    readonly userEmail: {
      readonly address: string;
    };
  } | null | undefined;
};
export type confirmRegistrationMutation = {
  response: confirmRegistrationMutation$data;
  variables: confirmRegistrationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirmRegistrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmUserEmailPayload",
        "kind": "LinkedField",
        "name": "confirmUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "userEmail",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirmRegistrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmUserEmailPayload",
        "kind": "LinkedField",
        "name": "confirmUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "userEmail",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:cmk3uy7LR0HdXNhBczXmzjqF3Mr8l8WeKxyCwbzoTas",
    "metadata": {},
    "name": "confirmRegistrationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "04fe93d8844c3cf3f0cf17e8c2c02c78";

export default node;
