import { Spacer } from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { getConnectionEdges, graphql } from "@gigsmart/relay";
import InfiniteGridList from "@gigsmart/seibutsu/shared/InfiniteList/InfiniteGridList";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../../navigation/types";
import type { RelayGigType, SearchFilter } from "../browse-screen-base/search";
import GigAvailableCard from "../cards/gig-available-card";
import type { projectGigsListPageQuery } from "./__generated__/projectGigsListPageQuery.graphql";
import type { projectGigsList_availableProjects$key } from "./__generated__/projectGigsList_availableProjects.graphql";
import ProjectGigsEmptyView from "./project-gigs-empty-view";
import ProjectGigsInfoDismissableCard from "./project-gigs-info-dismissable-card";
import ProjectGigsListFooter from "./project-gigs-list-footer";

interface Props {
  filter: SearchFilter;
  showProjectGigEducationCard: boolean;
  setHideProjectGigEducationCard: (val: boolean) => void;
  viewerRef: projectGigsList_availableProjects$key | null | undefined;
}

type AvailableProjectType = NonNullable<
  ObjectPath<
    projectGigsList_availableProjects$key,
    [" $data", "availableProjects", "edges", 0]
  >
>;

const ProjectGigsList = ({
  filter,
  showProjectGigEducationCard,
  setHideProjectGigEducationCard,
  viewerRef
}: Props) => {
  const nav = useNavigation<AppNavigationProp<WorkerParamList>>();
  const variables = useMemo(
    () => ({
      searchTerm: filter.searchTerm,
      maxDistance: filter.radiusMiles,
      gigTypes: ["PROJECT"] as RelayGigType[]
    }),
    [filter]
  );

  return (
    <InfiniteGridList<
      projectGigsListPageQuery,
      projectGigsList_availableProjects$key,
      AvailableProjectType
    >
      parentRef={viewerRef}
      fragmentInput={graphql`
        fragment projectGigsList_availableProjects on Worker
        @refetchable(queryName: "projectGigsListPageQuery")
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 10 }
          after: { type: "String" }
          maxDistance: { type: "Int" }
          crisisId: { type: "ID" }
          searchTerm: { type: "String" }
          gigTypes: { type: "[GigType!]" }
        ) {
          availableProjects: availableGigSeries(
            first: $count
            after: $after
            input: {
              crisisId: $crisisId
              maxDistance: $maxDistance
              searchTerm: $searchTerm
              gigTypes: $gigTypes
            }
          )
            @connection(
              key: "availableProjectsList_availableProjects"
              filters: ["input"]
            ) {
            edges {
              ...gigAvailableCard_seriesEdge
              cursor
              node @trackImpressions(action: ACCESSED) {
                __typename
                id
              }
            }
          }
        }
      `}
      getData={(data) => getConnectionEdges(data?.availableProjects)}
      refetchDebounce={10}
      refetchVars={variables}
      keyExtractor={(item) => item.node?.id ?? ""}
      testID="project-gigs-list"
      spacing="xsmall"
      renderItem={(item) => (
        <GigAvailableCard
          edgeRef={item}
          onPress={() =>
            nav.push("BrowseProjectDetails", { id: item?.node?.id ?? "" })
          }
        />
      )}
      renderHeaderView={() => (
        <>
          {showProjectGigEducationCard ? (
            <ProjectGigsInfoDismissableCard
              onClose={() => setHideProjectGigEducationCard(true)}
            />
          ) : null}
          <Spacer />
        </>
      )}
      renderFooterView={() => <ProjectGigsListFooter />}
      renderEmptyView={() => (
        <ProjectGigsEmptyView searchTerm={filter.searchTerm} />
      )}
    />
  );
};

export default ProjectGigsList;
