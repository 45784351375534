import React from "react";

import {
  Column,
  Divider,
  type IconName,
  IconText,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";

import SummaryListRow from "./SummaryListRow";
import SummaryRow from "./SummaryRow";

import { uniq } from "lodash";
import pluralize from "pluralize";
import type { QualificationSummaryRowQuery } from "./__generated__/QualificationSummaryRowQuery.graphql";

interface Props {
  nodeId: string;
}

const ORDER = new Map<string, number>([
  ["Licenses & Certifications", 0],
  ["Experience", 1],
  ["Physical Requirements", 2],
  ["Attire", 3],
  ["Tools & Equipment", 4],
  ["Commercial Driver's License", 5],
  ["Travel / Delivery", 6],
  ["Vehicle Requirements", 7]
]);

export default createSuspendedQueryContainer<
  QualificationSummaryRowQuery,
  Props
>(
  function QualificationSummaryRow({ response }) {
    const {
      gigQualifications,
      estimatedMileage,
      requiresVehicle,
      requiredQualifications
    } = response?.node ?? {};

    const renderSection = (field: (typeof orderedNodes)[0]) => {
      const requiresTravel =
        field.title === "Travel / Delivery" && !!estimatedMileage;

      const renderContent = () => {
        if (requiresTravel) {
          return (
            <Column gap="slim">
              <Text variant="subheader">
                You will be required to travel or make deliveries during this
                Shift Gig.
              </Text>
              <Well color="primary" size="compact">
                <IconText icon="car" color="primary" spacing="standard">
                  <Text variant="subheader">
                    The total estimated mileage for each Shift is{" "}
                    {estimatedMileage} {pluralize("mile", estimatedMileage)}.
                  </Text>
                </IconText>
              </Well>
            </Column>
          );
        }

        // merge requiredQualifications and selectedGigFieldItems
        const items = uniq([
          ...(requiredQualifications ?? [])
            .filter((it) => it.gigField.id === field.id)
            .map((it) => it.label),
          ...getConnectionNodes(
            field.selectedGigFieldItems ?? field.selectedGigSeriesFieldItems,
            (item) => item.definition.label
          )
        ]).sort();

        if (field.title === "Vehicle Requirements" && requiresVehicle) {
          const copy = "Required to use your own vehicle";
          return items.length
            ? [copy, ...items]
            : [`${copy}  (ALL vehicle types accepted)`];
        }

        return items.length ? items : null;
      };

      const content = renderContent();
      return (
        <SummaryListRow
          key={field.id}
          title={field.title}
          icon={field.iconName as IconName}
          listVariant={requiresTravel ? "plain" : "bullet"}
          disabled={!content}
        >
          {content ?? "No Qualifications"}
        </SummaryListRow>
      );
    };

    const orderedNodes = getConnectionNodes(gigQualifications).sort(
      (a, b) => (ORDER.get(a.title) ?? 10) - (ORDER.get(b.title) ?? 10)
    );

    return (
      <SummaryRow
        icon="clipboard-check"
        field="addons"
        route="qualifications"
        title="Qualifications"
        testID="qualification-summary-row"
      >
        <Column>
          <Divider />
          <Stack variant="divider">{orderedNodes.map(renderSection)}</Stack>
          <Divider />
        </Column>
      </SummaryRow>
    );
  },
  {
    query: graphql`
      query QualificationSummaryRowQuery($nodeId: ID!) {
        node(id: $nodeId) {
          ... on GigLike {
            id
            estimatedMileage
            requiresVehicle
            ... on Gig {
              requiredQualifications {
                id
                label
                gigField {
                  id
                }
              }
              gigQualifications: fields(first: 10) {
                edges {
                  node {
                    id
                    title
                    iconName
                    selectedGigFieldItems(first: 10) {
                      edges {
                        node {
                          definition {
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on GigSeries {
              requiredQualifications {
                id
                label
                gigField {
                  id
                }
              }
              gigQualifications: fields(first: 10) {
                edges {
                  node {
                    id
                    title
                    iconName
                    selectedGigSeriesFieldItems(first: 10) {
                      edges {
                        node {
                          definition {
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ nodeId }) => ({ nodeId })
  }
);
