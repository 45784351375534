import { findLast } from "lodash";
import { DateTime } from "luxon";
import type { EditTimesheetForm_engagement$data } from "./__generated__/EditTimesheetForm_engagement.graphql";

export default function timesheetInitialValues(
  isCreate: boolean,
  timesheet:
    | EditTimesheetForm_engagement$data["systemTimesheet"]
    | undefined
    | null,
  startsAt: string | undefined | null,
  endsAt: string | undefined | null,
  timezone: string
) {
  const startTime =
    findStartTime(isCreate, timesheet, startsAt, timezone) ??
    DateTime.local().setZone(timezone);
  const endTime =
    findEndTime(isCreate, timesheet, endsAt, timezone) ??
    startTime.plus({ minutes: 1 });

  const { breaks, breakValues } = isCreate
    ? { breaks: [], breakValues: {} }
    : findInitialBreaks(timesheet, timezone);
  const mileage = `${Number(timesheet?.estimatedMileage ?? 0)}`;
  return {
    initialValues: {
      ...breakValues,
      startTimeDate: startTime.startOf("day"),
      startTimeTime: isCreate ? "" : startTime.toFormat("hh:mm"),
      startTimeAmpm: isCreate ? "AM" : startTime.toFormat("a"),
      endTimeDate: endTime.startOf("day"),
      endTimeTime: isCreate ? "" : endTime.toFormat("hh:mm"),
      endTimeAmpm: isCreate ? "PM" : endTime.toFormat("a"),
      includeBreaks: breaks.length > 0,
      mileage: mileage || undefined
    },
    engagementStartTime: startsAt
      ? DateTime.fromISO(startsAt, { zone: timezone })
      : null,
    initialStartTime: startTime,
    initialEndTime: endTime,
    initialBreaks: breaks
  };
}

function findStartTime(
  isCreate: boolean,
  timesheet:
    | EditTimesheetForm_engagement$data["systemTimesheet"]
    | undefined
    | null,
  engagementStartsAt: string | undefined | null,
  timezone: string
) {
  let startsAt = engagementStartsAt;
  if (!isCreate) {
    startsAt =
      timesheet?.states?.edges?.find((d) => d?.node?.action === "START")?.node
        ?.transitionedAt ?? startsAt;
  }

  // starts at not found. try grabbing from SCHEDULED state
  if (!startsAt) {
    startsAt = findLast(
      timesheet?.states?.edges,
      (it) => it?.node?.name === "SCHEDULED"
    )?.node?.transitionedAt;
  }

  return startsAt ? DateTime.fromISO(startsAt, { zone: timezone }) : null;
}

function findEndTime(
  isCreate: boolean,
  timesheet:
    | EditTimesheetForm_engagement$data["systemTimesheet"]
    | undefined
    | null,
  engagementEndsAt: string | undefined | null,
  timezone: string
) {
  let endsAt = engagementEndsAt;
  if (!isCreate) {
    endsAt =
      timesheet?.states?.edges?.find(
        (d) =>
          d?.node?.action === "END" || d?.node?.action === "EXCEED_DURATION"
      )?.node?.transitionedAt ?? endsAt;
  }
  return endsAt ? DateTime.fromISO(endsAt, { zone: timezone }) : null;
}

const findInitialBreaks = (
  timesheet:
    | EditTimesheetForm_engagement$data["systemTimesheet"]
    | undefined
    | null,
  timezone: string
) => {
  let statesArray = timesheet?.states?.edges ?? [];

  let breaksFound = true;
  const breaks: Record<string, DateTime | string> = {};
  let breakCount = 0;
  while (breaksFound) {
    const pause = statesArray.find(
      (stateEdge) => stateEdge?.node?.action === "PAUSE"
    );
    statesArray = statesArray.filter((state) => state !== pause);
    if (pause) {
      const resume = statesArray.find(
        (stateEdge) => stateEdge?.node?.action === "RESUME"
      );
      const end = statesArray.find(
        (stateEdge) => stateEdge?.node?.action === "END"
      );
      const exceedDuration = statesArray.find(
        (stateEdge) => stateEdge?.node?.action === "EXCEED_DURATION"
      );

      const endOfBreak = resume ?? end ?? exceedDuration;

      if (endOfBreak) {
        ++breakCount;
        breaks[`break${breakCount}StartDate`] = (
          pause?.node?.transitionedAt
            ? DateTime.fromISO(pause?.node?.transitionedAt, { zone: timezone })
            : DateTime.local().setZone(timezone)
        ).startOf("day");
        breaks[`break${breakCount}StartTime`] = (
          pause?.node?.transitionedAt
            ? DateTime.fromISO(pause?.node?.transitionedAt, { zone: timezone })
            : DateTime.local().setZone(timezone)
        ).toFormat("hh:mm");
        breaks[`break${breakCount}StartAmpm`] = (
          pause?.node?.transitionedAt
            ? DateTime.fromISO(pause?.node?.transitionedAt, { zone: timezone })
            : DateTime.local().setZone(timezone)
        ).toFormat("a");
        breaks[`break${breakCount}EndDate`] = (
          endOfBreak?.node?.transitionedAt
            ? DateTime.fromISO(endOfBreak?.node?.transitionedAt, {
                zone: timezone
              })
            : DateTime.local().setZone(timezone)
        ).startOf("day");
        breaks[`break${breakCount}EndTime`] = (
          endOfBreak?.node?.transitionedAt
            ? DateTime.fromISO(endOfBreak?.node?.transitionedAt, {
                zone: timezone
              })
            : DateTime.local().setZone(timezone)
        ).toFormat("hh:mm");
        breaks[`break${breakCount}EndAmpm`] = (
          endOfBreak?.node?.transitionedAt
            ? DateTime.fromISO(endOfBreak?.node?.transitionedAt, {
                zone: timezone
              })
            : DateTime.local().setZone(timezone)
        ).toFormat("a");
        statesArray = statesArray.filter((state) => state !== endOfBreak);
      }
    } else {
      breaksFound = false;
    }
  }
  return {
    breaks: [...Array(breakCount).keys()].map((key) => key + 1),
    breakValues: breaks
  };
};
