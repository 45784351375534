/**
 * @generated SignedSource<<57aa7b2cbd6e3c8f546908e40072e940>>
 * @relayHash ff7c477c5206ab251b6d36406adcccc7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:EDND7Er6asGXlK1G_iSaQXAZ7MA6os6GzDM_FIYZ_LM

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type NegotiateBidScreenQuery$variables = {
  id: string;
};
export type NegotiateBidScreenQuery$data = {
  readonly node: {
    readonly capabilities?: ReadonlyArray<{
      readonly status: EngagementCapabilityStatus;
      readonly type: EngagementCapabilityType;
      readonly via: string | null | undefined;
    }>;
    readonly currentState?: {
      readonly action: EngagementStateAction;
      readonly name: EngagementStateName;
      readonly negotiation: {
        readonly payRate: string | null | undefined;
      } | null | undefined;
    };
    readonly endsAt?: string | null | undefined;
    readonly estimatedPayment?: {
      readonly netPay: string;
      readonly payRate: string;
    } | null | undefined;
    readonly gig?: {
      readonly wageCompliance: {
        readonly adjustedMinimumWage: string;
      } | null | undefined;
    };
    readonly id?: string;
    readonly insertedAt?: string;
    readonly startsAt?: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionHeader_availableGig">;
  } | null | undefined;
};
export type NegotiateBidScreenQuery = {
  response: NegotiateBidScreenQuery$data;
  variables: NegotiateBidScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementCapability",
  "kind": "LinkedField",
  "name": "capabilities",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "via",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "WageCompliance",
  "kind": "LinkedField",
  "name": "wageCompliance",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adjustedMinimumWage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NegotiateBidScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementNegotiation",
                    "kind": "LinkedField",
                    "name": "negotiation",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ShiftDescriptionHeader_availableGig"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NegotiateBidScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementNegotiation",
                    "kind": "LinkedField",
                    "name": "negotiation",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "AvailableGigsEdge",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "gig",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "Engagement",
                    "abstractKey": null
                  }
                ],
                "type": "AvailableGigOrEngagement",
                "abstractKey": "__isAvailableGigOrEngagement"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:EDND7Er6asGXlK1G_iSaQXAZ7MA6os6GzDM_FIYZ_LM",
    "metadata": {},
    "name": "NegotiateBidScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "83b962ef92b2e3217e8682353678fa80";

export default node;
