import {
  type Color,
  type IconName,
  ListEmptyView,
  Stack,
  Text
} from "@gigsmart/atorasu";
import React, { type ReactNode } from "react";

const SHIFTS_TAB_CONTENT: Record<
  string,
  {
    title: string;
    body: ReactNode | string;
    icon: IconName;
    color: Color;
    iconColor?: Color;
    variant?: "standard";
  }
> = {
  shifts: {
    title: "You do not have any upcoming Shift Gigs",
    body: "Apply to available Shift Gigs to increase your chances of getting hired.",
    icon: "business-time",
    color: "surface",
    iconColor: "disabled",
    variant: "standard"
  },
  hired: {
    title: "You have not been hired for any upcoming Shifts",
    body: (
      <Stack size="compact">
        <Text align="center">Shifts you are hired for will appear here.</Text>
        <Text align="center">
          Do <Text weight="bold">NOT</Text> show up to work a Shift unless it
          shows here as Hired.
        </Text>
      </Stack>
    ),
    icon: "check",
    color: "success"
  },
  confirming: {
    title: "You have no Shifts that are being confirmed",
    body: (
      <Stack size="compact">
        <Text align="center">
          Shifts that are Confirming will appear here. When a Shift is
          Confirming, a background and/or MVR check will be run. You will be
          notified if you are hired for the Shift.
        </Text>
        <Text align="center">
          Do <Text weight="bold">NOT</Text> show up to work a Shift that is
          still Confirming.
        </Text>
      </Stack>
    ),
    icon: "circle-exclamation",
    color: "purple"
  },
  pendingReview: {
    title: "You do not have any Shifts that are Pending Review",
    body: "Shifts that you are Pending Review for will appear here. A Shift is Pending Review when you are waiting for the Requester to review and approve a Timesheet.",
    icon: "spinner",
    color: "surface",
    iconColor: "disabled",
    variant: "standard"
  },
  timesheetReview: {
    title: "There are no Timesheets for you to review",
    body: "Any Shift Timesheets that need your review will appear here. You have up to 6 hours after the Shift end time to edit and submit your Timesheet.",
    icon: "triangle-exclamation",
    color: "danger"
  },
  paymentPending: {
    title: "You do not have any Shifts that are Pending Payment",
    body: "Shifts that you are Pending Payment for will appear here. A Shift is Pending Payment after you have submitted a Timesheet to the Requester and you are waiting for them to approve a Timesheet.",
    icon: "spinner",
    color: "surface",
    iconColor: "disabled",
    variant: "standard"
  },
  applied: {
    title: "You have not applied to any upcoming Shift Gigs",
    body: (
      <Stack size="compact">
        <Text align="center">
          Shifts you apply to will appear here. We will notify you if a
          Requester chooses to offer you a Shift.
        </Text>
        <Text align="center">
          Do <Text weight="bold">NOT</Text> show up to work a Shift unless you
          are hired & notified through the app.
        </Text>
      </Stack>
    ),
    color: "primary",
    icon: "user-check"
  },
  disputePending: {
    title: "You do not have any Shifts that are Pending Dispute",
    body: "Shifts that you are Pending Dispute review for will appear here. A Shift is Pending Dispute after you have submitted a Dispute and you are waiting for a response.",
    icon: "file-exclamation",
    color: "surface",
    iconColor: "disabled",
    variant: "standard"
  },

  pendingOffers: {
    title: "There are no Shifts with pending offers",
    body: "Shifts that have pending offers will appear here.",
    color: "success",
    icon: "check"
  },
  bidSubmitted: {
    icon: "file-check",
    color: "primary",
    title: "There are no submitted bids that are pending review",
    body: "Shifts you submitted a bid on will appear here."
  },
  bidRequests: {
    icon: "hand-holding-dollar",
    color: "info",
    title: "There are no Shifts with Bid Requests",
    body: "Shifts that have pending Bid Requests will appear here."
  }
};

const PROJECTS_TAB_CONTENT: Record<
  string,
  {
    title: string;
    body: ReactNode | string;
    icon: IconName;
    color: Color;
    iconColor?: Color;
    variant?: "standard";
  }
> = {
  projects: {
    title: "You do not have any upcoming Project Gigs",
    body: "Submit bids on residential Project Gigs to increase your chances of getting hired.",
    icon: "pen-ruler",
    color: "surface",
    iconColor: "disabled",
    variant: "standard"
  },
  hired: {
    title: "You have not been hired for any current or upcoming Project Gigs",
    body: "Project Gigs you are hired for will appear here.",
    icon: "check",
    color: "success"
  },
  applied: {
    title: "You have not applied to any upcoming Project Gigs",
    body: "Project Gigs you apply to will appear here.",
    color: "primary",
    icon: "user-check"
  }
};

interface Props {
  active?: string;
  isLoading?: boolean;
  variant: "shifts" | "projects";
}

export default function MyGigsEmptyList({ isLoading, variant, active }: Props) {
  const content = active
    ? variant === "shifts"
      ? SHIFTS_TAB_CONTENT[active]
      : PROJECTS_TAB_CONTENT[active]
    : null;

  if (!content || isLoading) return null;
  return (
    <ListEmptyView
      color={content.color}
      icon={content.icon}
      iconColor={content.iconColor}
      variant={content.variant}
      title={content.title}
      body={content.body}
    />
  );
}
