export default function useHandleApplyViaEmail(
  _worker?: {
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly primaryEmail?: {
      readonly address: string;
    } | null;
    readonly primaryMobile?: {
      readonly number: string;
    } | null;
  } | null,
  _jobPost?: {
    readonly id: string;
    readonly title: string | null;
    readonly applyUrl: string | null;
    readonly applyEmailAddress: string | null;
    readonly " $fragmentType": "applyButtons_jobPosting";
  } | null
) {
  return () => null;
}
