export * from "./hooks";
export * from "./Components";
export * from "./helpers/fs";

export { default as AsyncStorage } from "@react-native-async-storage/async-storage";
export { default as createApplet } from "./createApplet";
export { default as pluralize } from "pluralize";
export const resolveUrl = (url: string) =>
  process.env.LOCAL_IP && url
    ? url.replace("localhost", process.env.LOCAL_IP)
    : url;
export type { AppletProps } from "./createApplet";
