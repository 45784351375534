import type { StepProps } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import DisputeTypeStepContent from "@gigsmart/seibutsu/engagement-dispute/worker/DisputeTypeStep";
import type { StepPropsType } from "@gigsmart/seibutsu/engagement-dispute/worker/types";
import useSetEngagementSummary from "@gigsmart/seibutsu/engagement/useSetEngagementSummary";
import React from "react";
import type { disputeTypeStepQuery } from "./__generated__/disputeTypeStepQuery.graphql";
import type { disputeTypeStepRemoveTimesheetMutation } from "./__generated__/disputeTypeStepRemoveTimesheetMutation.graphql";

type Props = StepProps<StepPropsType>;

export default createSuspendedQueryContainer<disputeTypeStepQuery, Props>(
  function DisputeTypeStep({ stepData, nextStep, response, ...rest }) {
    const engagement = response?.engagement;
    const [commitRemoveTimesheet] =
      useRelayMutation<disputeTypeStepRemoveTimesheetMutation>(graphql`
        mutation disputeTypeStepRemoveTimesheetMutation(
          $input: RemoveEngagementTimesheetInput!
        ) {
          removeEngagementTimesheet(input: $input) {
            removedTimesheetId
          }
        }
      `);
    const commitSetEngagementSummary = useSetEngagementSummary();
    const handleNextPress = (
      disputeTimesheet: boolean,
      disputeAdditionalPayment: boolean
    ) => {
      if (stepData?.disputeTimesheetId) {
        if (!disputeTimesheet && stepData?.disputeTimesheet) {
          commitRemoveTimesheet(
            { input: { timesheetId: stepData.disputeTimesheetId } },
            {
              onSuccess: () => {
                if (stepData.additionalPayValue) {
                  commitSetEngagementSummary(
                    {
                      input: {
                        additionalPayment: stepData.additionalPayValue,
                        engagementId: stepData.engagementId,
                        disputedTimesheetId: engagement?.finalTimesheet?.id,
                        createOverrides: false
                      }
                    },
                    {
                      onSuccess: (result) => {
                        nextStep({
                          ...stepData,
                          disputeTimesheet: false,
                          disputeTimesheetId:
                            result?.setEngagementSummary?.engagementTimesheet
                              ?.id,
                          disputeAdditionalPayment,
                          timesheetComment: undefined
                        });
                      }
                    }
                  );
                } else {
                  nextStep({
                    ...stepData,
                    disputeTimesheet: false,
                    disputeAdditionalPayment,
                    disputeTimesheetId: undefined,
                    timesheetComment: undefined
                  });
                }
              }
            }
          );
        }
        if (!disputeAdditionalPayment && stepData?.disputeAdditionalPayment) {
          commitSetEngagementSummary(
            {
              input: {
                additionalPayment: "0 USD",
                engagementId: stepData.engagementId,
                disputedTimesheetId: engagement?.finalTimesheet?.id,
                createOverrides: false
              }
            },
            {
              onSuccess: (result) => {
                nextStep({
                  ...stepData,
                  disputeTimesheet,
                  disputeTimesheetId:
                    result?.setEngagementSummary?.engagementTimesheet?.id,
                  disputeAdditionalPayment: false,
                  additionalPayValue: undefined,
                  additionalPayComment: undefined
                });
              }
            }
          );
        } else {
          nextStep({
            ...stepData,
            disputeTimesheet,
            disputeAdditionalPayment
          });
        }
      } else {
        nextStep({
          ...stepData,
          disputeTimesheet,
          disputeAdditionalPayment
        });
      }
    };
    return (
      <DisputeTypeStepContent
        onNextStep={handleNextPress}
        stepData={stepData}
        nextStep={nextStep}
        {...rest}
      />
    );
  },
  {
    query: graphql`
      query disputeTypeStepQuery($engagementId: ID!) {
        engagement: node(id: $engagementId) {
          ... on Engagement {
            ...ApprovedEarningsModalRow_engagement
            finalTimesheet: timesheet(variant: FINAL) {
              id
            }
          }
        }
      }
    `,
    variables: ({ stepData }) => ({ engagementId: stepData.engagementId })
  }
);
