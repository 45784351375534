import {
  Column,
  Icon,
  Linking,
  Platform,
  Share,
  Text,
  useStyles
} from "@gigsmart/atorasu";
import SharedJobDetails from "@gigsmart/isomorphic-shared/job-posts/job-details";
import {
  type AppScreenProps,
  NavPortalEntrance,
  Redirect
} from "@gigsmart/kaizoku";
import {
  IconButton,
  KatanaLoadingView,
  StyledButton,
  StyledView
} from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useState } from "react";
import WebView from "react-native-webview";
import type { WorkerParamList } from "../navigation/types";
import type { jobDetailsScreenQuery } from "./__generated__/jobDetailsScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "BrowseJobDetails" | "JobDetails">;

export default function JobDetailsScreen({ route }: Props) {
  const { id, action } = route.params;
  if (action === "apply") return <JobsApply id={id} />;
  return <SharedJobDetails jobId={route.params.id} isWorker />;
}

type JobsApplyProps = {
  id: string;
};
const JobsApply = createSuspendedQueryContainer<
  jobDetailsScreenQuery,
  JobsApplyProps
>(
  function ApplyViaWeb({ response }) {
    const styles = useStyles(({ getColor, getUnits }) => ({
      headerText: {
        marginVertical: getUnits(4)
      },
      errorContainer: {
        marginTop: getUnits(4),
        marginHorizontal: getUnits(12)
      },
      button: {
        backgroundColor: getColor("primary", "fill"),
        borderColor: getColor("primary", "fill"),
        marginTop: getUnits(4)
      },
      webView: {
        opacity: 0.99
      }
    }));
    const [error, setError] = useState(false);
    const { title, applyUrl } = response?.node ?? {};
    const uri = (applyUrl ?? "").match(/^http[s]?:\/\//)
      ? applyUrl ?? ""
      : `https://${applyUrl}`;
    if (!applyUrl) return <Redirect back />;

    return (
      <>
        <NavPortalEntrance
          showBack
          rightAccessory={
            <IconButton
              diameter={50}
              icon="external-link"
              iconColor="neutralLight"
              iconVariant="solid"
              onPress={async () =>
                await Share.share({
                  title: title || "",
                  ...Platform.select({
                    ios: {
                      message: `Check out this job I found on the GigSmart Job Board: ${applyUrl}`
                    },
                    default: {
                      message: `Check out this job I found on the GigSmart Job Board: ${applyUrl}`
                    }
                  })
                })
              }
              width={50}
              testID="icon-share-button"
            />
          }
        />
        {error ? (
          <Column
            fill={1}
            alignItems="center"
            justifyContent="center"
            style={styles.errorContainer}
          >
            <Icon name="times-circle" size="default" />
            <Text
              color="primary"
              weight="bold"
              style={styles.headerText}
              variant="bodyLg"
              align="center"
            >
              Error
            </Text>
            <Text>An error occurred while opening the job.</Text>
            <StyledButton
              title="Open In Browser"
              onPress={() => {
                const url = applyUrl.match(/^http[s]?:\/\//)
                  ? applyUrl
                  : `https://${applyUrl}`;
                Linking.openURL(url);
              }}
              buttonStyle={styles.button}
              color="#003b5c"
            />
          </Column>
        ) : (
          <WebView
            source={{ uri }}
            onError={() => {
              setError(true);
            }}
            style={styles.webView}
            androidLayerType="software"
            startInLoadingState
            renderLoading={() => (
              <StyledView fill alignItems="center" justifyContent="flex-start">
                <KatanaLoadingView size="large" />
              </StyledView>
            )}
          />
        )}
      </>
    );
  },
  {
    query: graphql`
      query jobDetailsScreenQuery($id: ID!) {
        node(id: $id) @trackImpressions(action: ENGAGED) {
          ... on JobPosting {
            applyUrl
            title
          }
        }
      }
    `,
    variables: ({ id }) => ({ id })
  }
);
