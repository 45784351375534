import {
  Button,
  Stack,
  useOnEndReached,
  useScrollContext
} from "@gigsmart/atorasu";
import React, { useEffect } from "react";
import LegalHTML from "./LegalHtml";
import LegalMarkdown from "./LegalMarkdown";
import LegalUnsupported from "./LegalUnsupported";

export default function DynamicLegalDocument({
  filename,
  body,
  format,
  onEndReached
}: {
  filename: string;
  body: string;
  format: string;
  onEndReached?: () => void;
}) {
  const { scrollTo, scrollable } = useScrollContext();
  const handleScrollToBottom = () => scrollTo("end");

  useEffect(() => {
    scrollTo("start");
  }, []);

  useOnEndReached(onEndReached);

  const content =
    format === "html" ? (
      <LegalHTML html={body} />
    ) : format === "md" ? (
      <LegalMarkdown markdown={body} />
    ) : (
      <LegalUnsupported filename={filename} format={format} body={body} />
    );

  return (
    <Stack>
      {scrollable && (
        <Button
          variant="rounded"
          size="xsmall"
          fullWidth={false}
          icon="chevrons-down"
          testID="scroll-to-bottom"
          onPress={handleScrollToBottom}
          label="Click To Scroll to Bottom"
        />
      )}
      {content}
    </Stack>
  );
}
