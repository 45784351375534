import type { TabIcon } from "@gigsmart/katana";
import {
  ConnectionHandler,
  type OperationType,
  type RecordSourceSelectorProxy,
  createSuspendedQueryContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";
import type { notificationIconContainerQuery } from "./__generated__/notificationIconContainerQuery.graphql";
import type { notificationIconContainerSubscription } from "./__generated__/notificationIconContainerSubscription.graphql";
import messageTypes from "./message-types";
import NotificationIcon from "./notification-icon";
import { notificationsQuery } from "./use-notification-section";

type Props = ComponentPropsWithDefaults<typeof TabIcon>;

export function notificationIconUpdater<
  TResponse extends OperationType["response"]
>(
  store: RecordSourceSelectorProxy<TResponse>,
  nextCount: number | ((prevCount: number) => number)
) {
  let localNextCount = nextCount;
  const root = store.getRoot();
  const viewer = root.getLinkedRecord("viewer");
  if (!viewer) return;
  const messagesConnection = ConnectionHandler.getConnection(
    viewer,
    "notificationIconContainer_messages"
  );
  if (messagesConnection) {
    if (typeof localNextCount === "function") {
      localNextCount = localNextCount(
        Number(messagesConnection.getValue("totalCount")) ?? 0
      );
    }
    messagesConnection.setValue(Math.max(localNextCount, 0) || 0, "totalCount");
  }
  const iconConnection = ConnectionHandler.getConnection(
    viewer,
    "NotificationIcon_notifications"
  );
  if (iconConnection) {
    if (typeof localNextCount === "function") {
      localNextCount = localNextCount(
        Number(iconConnection.getValue("totalCount")) ?? 0
      );
    }
    iconConnection.setValue(Math.max(localNextCount, 0) || 0, "totalCount");
  }
}

export default createSuspendedQueryContainer<
  notificationIconContainerQuery,
  Props
>(
  function NotificationIconContainer({ response, ...props }) {
    const viewer = response?.viewer;
    useRelaySubscription<notificationIconContainerSubscription>(
      graphql`
        subscription notificationIconContainerSubscription(
          $messageTypes: [MessageType!]
        ) {
          messageAdded(messageTypes: $messageTypes) {
            unreadCount
          }
        }
      `,
      { messageTypes },
      {
        updater: (store, data) =>
          notificationIconUpdater(store, data?.messageAdded?.unreadCount ?? 0),
        subscribe: !!viewer
      }
    );
    const notificationCount = viewer?.messages?.totalCount ?? 0;
    return (
      <NotificationIcon {...props} notificationCount={notificationCount} />
    );
  },
  {
    query: graphql`
      query notificationIconContainerQuery($query: String) {
        viewer {
          messages(first: 0, query: $query)
            @connection(
              key: "notificationIconContainer_messages"
              filters: []
            ) {
            totalCount
            edges {
              cursor
            }
          }
        }
      }
    `,
    variables: { query: notificationsQuery(true) }
  }
);
