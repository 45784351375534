import { type IconName, Spacer, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useHistory } from "@gigsmart/kaizoku";
import {
  Accordion,
  AccordionContent,
  ListSeparator,
  PromptModal,
  StyledLink,
  StyledScrollView
} from "@gigsmart/katana";
import AccordionHeader from "@gigsmart/katana/container/accordion-header";
import React, { type ReactNode, useState } from "react";
import { Linking, TouchableOpacity } from "react-native";

interface Props {
  visible: boolean;
  onClose: () => void;
}

interface JobBoardHelpEntry {
  iconName: IconName;
  title: string;
  content: ReactNode;
}

const JobBoardHelpPrompt = ({ visible, onClose }: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    scrollview: {
      maxHeight: getUnits(30)
    }
  }));
  const [activeSections, setActiveSections] = useState<number[]>([]);
  const history = useHistory();
  const handleShowFaq = () => {
    void Linking.openURL("https://gigsmart.com/workers/faqs");
    onClose();
  };

  const SECTIONS: JobBoardHelpEntry[] = [
    {
      iconName: "business-time",
      title: "What is a Shift Gig?",
      content:
        "Shift Gigs are hourly Gigs you can apply to directly in the Get Gigs app. Your time is tracked via a Gig clock and your earnings are based off of the hourly pay rate and the total time worked. Once your timesheet is approved, Gig earnings are transferred directly to the Wallet in the app."
    },
    {
      iconName: "pen-ruler",
      title: "What is a Project Gig?",
      content:
        "Project Gigs are open to bids. As part of your application, you'll send your initial Project bid directly through the app. If the Requester is interested, you can finalize the timeline, pay, method of payment, and other details directly with them."
    },
    {
      iconName: "newspaper",
      title: "What is the Job Board?",
      content:
        "Browse open part-time and full-time positions hiring near you. You can search for specific titles, industries, companies, or locations. Apply directly through each hiring company's website or preferred email address."
    },
    {
      iconName: "shield",
      title: "Safety Tips",
      content: (
        <Text>
          Please be mindful when browsing and applying to Gigs or Jobs. When you
          communicate with hiring managers, you should never share your personal
          information, credit card information, or banking information. If you
          see anything suspicious, please notify our{" "}
          <StyledLink
            onPress={() => history.push("/support")}
            style={{ color: "#3EAFE0" }}
          >
            support team
          </StyledLink>{" "}
          immediately.
        </Text>
      )
    }
  ];

  return (
    <PromptModal
      eventContext="Job Board Help"
      actions={[
        {
          title: "View the Knowledge Base to see all FAQs",
          onPress: handleShowFaq,
          variant: "outline"
        }
      ]}
      title="Need some help?"
      titleIcon="circle-question"
      visible={visible}
      onClose={onClose}
    >
      <Text>
        Learn more about the Get Gigs app below, or visit our Knowledge Base to
        see additional FAQs.
      </Text>
      <Spacer />
      <Accordion
        sections={SECTIONS}
        touchableComponent={TouchableOpacity}
        onChange={setActiveSections}
        activeSections={activeSections}
        renderSectionTitle={() => <ListSeparator inset={0} />}
        renderHeader={(section, _idx, isActive) => (
          <AccordionHeader
            title={section.title}
            iconName={section.iconName}
            isActive={isActive}
          />
        )}
        renderContent={(section) => (
          <AccordionContent>
            <StyledScrollView style={styles.scrollview}>
              {typeof section.content === "string" ? (
                <Text>{section.content}</Text>
              ) : (
                <>{section.content}</>
              )}
              <Spacer />
            </StyledScrollView>
          </AccordionContent>
        )}
      />
      <ListSeparator inset={0} />
    </PromptModal>
  );
};

export default JobBoardHelpPrompt;
