/**
 * @generated SignedSource<<14143c457711da817389c2965a9a8499>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type actionRow_engagement$data = {
  readonly commitment: {
    readonly " $fragmentSpreads": FragmentRefs<"ShiftCommitmentReminder_commitment">;
  } | null | undefined;
  readonly currentState: {
    readonly action: EngagementStateAction;
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly gigType: GigType | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
    readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftActions_shift" | "ShiftDescriptionItem_shift" | "projectDescriptionItem_engagement">;
  readonly " $fragmentType": "actionRow_engagement";
};
export type actionRow_engagement$key = {
  readonly " $data"?: actionRow_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionRow_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "actionRow_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredReportTypes",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "WorkerLatestArrivalTimeReminder_gig",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startsAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestArrivalTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigState",
              "kind": "LinkedField",
              "name": "currentState",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementCommitment",
      "kind": "LinkedField",
      "name": "commitment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShiftCommitmentReminder_commitment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftActions_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftDescriptionItem_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectDescriptionItem_engagement"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "03dfe4f2b6587530700c2e557927c427";

export default node;
