/**
 * @generated SignedSource<<1f65504b0e50fe806fc632768873e21c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGigsFilter_worker$data = {
  readonly projectsAppliedCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly projectsCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly projectsHiredCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly projectsOfferedCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "ProjectGigsFilter_worker";
};
export type ProjectGigsFilter_worker$key = {
  readonly " $data"?: ProjectGigsFilter_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGigsFilter_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = {
  "kind": "Literal",
  "name": "gigType",
  "value": {
    "_eq": "PROJECT"
  }
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsAppliedStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsHiredStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsOfferedStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsStates"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGigsFilter_worker",
  "selections": [
    {
      "alias": "projectsCount",
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_in",
                  "variableName": "projectsStates"
                }
              ],
              "kind": "ObjectValue",
              "name": "currentStateName"
            },
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "projectsHiredCount",
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_in",
                  "variableName": "projectsHiredStates"
                }
              ],
              "kind": "ObjectValue",
              "name": "currentStateName"
            },
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "projectsOfferedCount",
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_in",
                  "variableName": "projectsOfferedStates"
                }
              ],
              "kind": "ObjectValue",
              "name": "currentStateName"
            },
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "projectsAppliedCount",
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_in",
                  "variableName": "projectsAppliedStates"
                }
              ],
              "kind": "ObjectValue",
              "name": "currentStateName"
            },
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "a5b482fd5d0b6044ccd265bb3e261a38";

export default node;
