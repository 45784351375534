import React, { useEffect } from "react";

import { TabIcon } from "@gigsmart/katana";

import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import { setBadge } from "./notifications";

type Props = ComponentPropsWithDefaults<typeof TabIcon> & {
  notificationCount?: number | null | undefined;
};

const NotificationIcon = ({ notificationCount = 0, ...iconProps }: Props) => {
  let badgeText;
  if (!notificationCount) badgeText = undefined;
  else if (notificationCount > 99) badgeText = "99+";
  else if (notificationCount > 0) badgeText = `${notificationCount}`;

  useEffect(() => {
    setBadge(notificationCount);
  }, [notificationCount]);

  return <TabIcon {...iconProps} badgeText={badgeText} />;
};

export default NotificationIcon;
