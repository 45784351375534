import {
  NavAction,
  Text,
  defaultBackPrompt,
  defaultCancelPrompt,
  useMatchesViewport,
  useStepper
} from "@gigsmart/atorasu";
import { useFormSubmit } from "@gigsmart/fomu";
import { NavPortalEntrance, defaultBackHandler } from "@gigsmart/kaizoku";
import React, { useCallback } from "react";
import { ResponsiveStepperRightAccessoryPortal } from "./portal";

interface Props {
  onCancel?: false | (() => void);
  dirty?: boolean;
}

export default function StepperNavActions({
  onCancel = defaultBackHandler,
  dirty: dirtyOverride
}: Props) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const { prevStep } = useStepper<{}, { isEdit?: boolean }>();
  const { dirty } = useFormSubmit();
  const handleCancel = useCallback(async () => {
    if (await defaultCancelPrompt()) {
      onCancel ? onCancel() : defaultBackHandler();
    }
  }, [onCancel]);

  const handleBackPress = useCallback(async () => {
    if (dirty || dirtyOverride) {
      if (await defaultBackPrompt()) {
        prevStep();
      }
    } else {
      prevStep();
    }
    return true;
  }, [prevStep, dirty, dirtyOverride]);
  return (
    <>
      <NavPortalEntrance
        onBackPress={() => {
          handleBackPress();
          return true;
        }}
        rightAccessory={
          !isMd &&
          !!onCancel && (
            <NavAction
              testID="create-gig-stepper-cancel-btn"
              onPress={handleCancel}
            >
              <Text weight="bold">Cancel</Text>
            </NavAction>
          )
        }
      />
      {!!onCancel && (
        <ResponsiveStepperRightAccessoryPortal.Entrance>
          <NavAction
            testID="create-gig-stepper-cancel-btn"
            onPress={handleCancel}
          >
            <Text color="primary" variant="header" weight="normal">
              Cancel
            </Text>
          </NavAction>
        </ResponsiveStepperRightAccessoryPortal.Entrance>
      )}
    </>
  );
}
