import { type FetchQueryFn, getConnectionNodes } from "@gigsmart/relay";
import React from "react";
import type { announcementQuery } from "./__generated__/announcementQuery.graphql";
import type { ActionType, AnnouncementType } from "./types";

type ActionComponent = React.ComponentType<
  any & {
    onComplete: () => void;
  }
>;

interface RegistryEntry<MetaT extends object = {}> {
  actionType: ActionType;
  condition: AnnouncementCondition<MetaT>;
  renderAction: ActionComponent;
}

type AnnouncementCondition<MetaT extends object> = (
  actionMeta: MetaT,
  fetchQuery: FetchQueryFn
) => boolean | Promise<boolean>;

const registry = new Set<RegistryEntry<any>>();

export async function connectionToAnnouncementList(
  connection: announcementQuery["response"]["announcements"],
  fetchQuery: FetchQueryFn
): Promise<AnnouncementType[]> {
  return await Promise.all(
    getConnectionNodes(connection).map<Promise<AnnouncementType>>(
      async (node) => {
        const { actionType, actionMeta, ...announcement } = node;
        const actionMetaParsed = actionMeta ? JSON.parse(actionMeta) : {};
        return {
          ...announcement,
          renderAction: await fetchAnnouncementRenderer(
            actionType,
            actionMetaParsed,
            fetchQuery
          )
        };
      }
    )
  );
}

export async function fetchAnnouncementRenderer(
  actionType: ActionType | null | undefined,
  actionMeta: any,
  fetchQuery: FetchQueryFn
): Promise<React.ComponentType<{ onComplete: () => void }> | typeof undefined> {
  return [...registry].reduce(async (acc: any, entry) => {
    if (await acc) return acc;
    const handler =
      entry.actionType === actionType &&
      (await entry.condition(actionMeta, fetchQuery)) &&
      entry;
    if (!handler) return;
    const { renderAction: HandlerAction } = handler;
    return ({ onComplete }: any) => (
      <HandlerAction {...actionMeta} onComplete={onComplete} />
    );
  }, Promise.resolve(undefined));
}

export const registerAnnouncementHandler = <MetaT extends object>(
  actionType: ActionType,
  renderAction: React.ComponentType<
    any & {
      onComplete: () => void;
    }
  >,
  condition: AnnouncementCondition<MetaT> = () => true
) => {
  registry.add({ actionType, condition, renderAction });
};
