/**
 * @generated SignedSource<<1c5611bedc2b68f091be06f179fe345d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ConversationCapability = "CLOSE" | "JOIN" | "LEAVE" | "OPEN" | "%future added value";
export type ConversationStatus = "CLOSED" | "OPEN" | "%future added value";
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MessageListFooter_conversation$data = {
  readonly __typename: string;
  readonly capabilities: ReadonlyArray<ConversationCapability>;
  readonly currentMessage?: {
    readonly id: string;
  } | null | undefined;
  readonly engagement?: {
    readonly capabilities: ReadonlyArray<{
      readonly status: EngagementCapabilityStatus;
      readonly type: EngagementCapabilityType;
    }>;
    readonly gigType: GigType;
  };
  readonly gig?: {
    readonly conversationRule: GigConversationRule;
    readonly currentState: {
      readonly name: GigStateName;
    };
    readonly id: string;
  };
  readonly id?: string;
  readonly participant: {
    readonly id: string;
  } | null | undefined;
  readonly status: ConversationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"NewMessageComposer_conversation">;
  readonly " $fragmentType": "MessageListFooter_conversation";
};
export type MessageListFooter_conversation$key = {
  readonly " $data"?: MessageListFooter_conversation$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageListFooter_conversation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageListFooter_conversation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Node",
      "abstractKey": "__isNode"
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConversationParticipant",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Engagement",
          "kind": "LinkedField",
          "name": "engagement",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementCapability",
              "kind": "LinkedField",
              "name": "capabilities",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gigType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EngagementConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserMessage",
          "kind": "LinkedField",
          "name": "currentMessage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conversationRule",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigState",
              "kind": "LinkedField",
              "name": "currentState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ShiftGroupConversation",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewMessageComposer_conversation"
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};
})();

(node as any).hash = "e0058a837d8a05e33ead61f1cb89056d";

export default node;
