import { Column, Text, showModal } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { graphql, useFetchQueryResult } from "@gigsmart/relay";
import { Duration } from "luxon";
import React from "react";
import type { ShiftFarAwayModalQuery } from "./__generated__/ShiftFarAwayModalQuery.graphql";

interface Props {
  gigId: string;
}

export const showShiftFarAwayModal = ({
  gigId,
  onClose
}: Props & { onClose: () => void }) => {
  return showModal({
    title: "You are too far from this Shift.",
    eventContext: "Pickup Shift Far Away Modal",
    onRequestClose: onClose,
    onClose,
    actions: [
      {
        label: "View Available Shifts for Pick Up Today",
        color: "emphasized",
        testID: "pick-up-shift-far-away-modal-submit"
      }
    ],
    children: () => <ShiftFarAwayModal gigId={gigId} />
  });
};

function ShiftFarAwayModal({ gigId }: Props) {
  const [{ viewer = null } = {}] = useFetchQueryResult<ShiftFarAwayModalQuery>(
    graphql`
      query ShiftFarAwayModalQuery($gigId: ID!) {
        viewer {
          ... on Worker {
            gig(id: $gigId) {
              ... on Engagement {
                distance: workerDistance
                travelTime
                gig {
                  actualStartsAt
                }
              }
              ... on AvailableGigsEdge {
                distance
                travelTime
                node {
                  actualStartsAt
                }
              }
            }
          }
        }
      }
    `,
    { variables: { gigId } }
  );

  const availableGig = viewer?.gig;
  const actualStartsAt =
    availableGig?.node?.actualStartsAt ?? availableGig?.gig?.actualStartsAt;
  const gigStartTime = time.humanize(actualStartsAt);
  const etaToShift = availableGig?.travelTime
    ? Duration.fromISO(availableGig?.travelTime).toHuman()
    : "N/A";
  const gigDistance = pluralize(availableGig?.distance ?? 0, "Mile");

  return (
    <Column gap="standard">
      <Text>
        It looks like you won't be able to arrive to your Shift on time due to
        distance based on your method of transportation.
      </Text>
      <Column gap="slim">
        <Text>
          <Text weight="bold">Shift Start Time: </Text>
          <Text>{gigStartTime}</Text>
        </Text>
        <Text>
          <Text weight="bold">ETA to Shift: </Text>
          <Text>{etaToShift}</Text>
        </Text>
        <Text>
          <Text weight="bold">Shift Distance: </Text>
          <Text>{gigDistance}</Text>
        </Text>
      </Column>
    </Column>
  );
}
