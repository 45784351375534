import React, { type ReactElement } from "react";

import { type IconName, Text } from "@gigsmart/atorasu";

export type PaymentTypeKey =
  | "CASH"
  | "CHECK"
  | "DIGITAL_TRANSFER"
  | "IN_APP"
  | "NO_PREFERENCE"
  | "%future added value";

export const PAYMENT_TYPES: {
  [key in PaymentTypeKey]: {
    icon: IconName;
    label: string;
    workerInfoText: ReactElement<Text> | string;
    workerNote?: string;
    shortLabel?: string;
    altLabel?: string;
  };
} = {
  CASH: {
    icon: "money-bill",
    label: "Cash",
    shortLabel: "Cash",
    altLabel: "via Cash",
    workerInfoText: (
      <Text>
        This Requester is planning to pay Workers with{" "}
        <Text weight="bold">cash</Text>. Once the Project is completed, you'll
        be responsible for collecting your earnings directly from the Requester.
      </Text>
    )
  },
  CHECK: {
    icon: "money-check-edit-alt",
    label: "Check",
    shortLabel: "Check",
    altLabel: "via Check",
    workerInfoText: (
      <Text>
        This Requester is planning to pay Workers with a{" "}
        <Text>personal check</Text>. Once the Project is completed, you'll be
        responsible for collecting your earnings directly from the Requester.
      </Text>
    ),
    workerNote:
      "Be sure you are comfortable with accepting a personal check before you start working."
  },
  DIGITAL_TRANSFER: {
    icon: "messages-dollar",
    label: "3rd Party Digital Transfer",
    shortLabel: "Digital Transfer",
    altLabel: "via Digital Transfer",
    workerInfoText: (
      <Text>
        This Requester is planning to pay Workers via a{" "}
        <Text weight="bold">3rd Party Digital Transfer</Text> like Venmo, Cash
        App, etc. Once the Project is completed, you'll be responsible for
        collecting your earnings directly from the Requester.
      </Text>
    ),
    workerNote:
      "Be sure you are comfortable with accepting the Requester's form of payment before you start working."
  },
  IN_APP: {
    icon: "mobile-alt",
    label: "GigSmart App",
    shortLabel: "GigSmart App",
    altLabel: "In-App",
    workerInfoText: (
      <Text>
        This Requester is planning to pay Workers securely{" "}
        <Text weight="bold">through the app</Text>. Once the Project is
        completed, earnings will be added to your Wallet
      </Text>
    )
  },
  NO_PREFERENCE: {
    icon: "thumbs-up",
    label: "No Preference",
    shortLabel: "No Preference",
    altLabel: "Outside of App",
    workerInfoText: (
      <Text>
        This Requester{" "}
        <Text weight="bold">does not have a preferred payment method</Text>.
        Please discuss this with them prior to beginning any work on this
        Project.
      </Text>
    ),
    workerNote:
      "Be sure you and the Requester agree on how your will be paid before you start working."
  },
  "%future added value": {
    icon: "thumbs-up",
    label: "FUTURE ADDED VALUE",
    workerInfoText: "FUTURE ADDED VALUE"
  }
};
