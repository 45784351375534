import { showEmbeddedBrowser, useMatchesViewport } from "@gigsmart/atorasu";
import {
  FeaturesDebugFeature,
  RemoveBenefitsMenu
} from "@gigsmart/feature-flags";
import { type AppMenuItem, defaultResetHome } from "@gigsmart/kaizoku";
import { compact } from "lodash";
import { useMemo } from "react";
import type { WorkerParamList } from "./types";

interface MenuOptions {
  isAuthenticated: boolean;
  hasFinishedOnboarding: boolean;
}

export function useWorkerMenu({
  isAuthenticated,
  hasFinishedOnboarding
}: MenuOptions) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  return useMemo((): AppMenuItem[] => {
    const baseLinks = compact<AppMenuItem<WorkerParamList>>([
      {
        icon: "square-share-nodes",
        title: "Share",
        to: "Share",
        testID: "menu-share"
      },
      {
        icon: "square-info",
        title: "About",
        to: "About",
        testID: "menu-about"
      },
      {
        icon: "square-question",
        title: "Support",
        to: "Support",
        testID: "menu-support"
      },
      FeaturesDebugFeature.isEnabled() && {
        icon: "flag-checkered",
        title: isMd ? "Flags" : "Feature Flags",
        to: "FeatureFlags",
        testID: "menu-flags"
      }
    ]);

    if (isMd) {
      // tablet+ sizes / has no drawer
      return !hasFinishedOnboarding
        ? baseLinks
        : compact<AppMenuItem<WorkerParamList>>([
            {
              icon: "house",
              title: "Home",
              to: "HomeTab",
              parent: "Home",
              testID: "menu-home"
            },
            {
              icon: "briefcase",
              title: "My Gigs",
              to: "MyGigs",
              parent: "Home",
              testID: "menu-my-gigs"
            },
            {
              icon: "search",
              title: "Browse",
              to: "Browse",
              parent: "Home",
              testID: "menu-browse"
            },
            {
              icon: "circle-dollar",
              title: "Wallet",
              to: "Wallet",
              parent: "Home",
              testID: "menu-wallet"
            },
            "separator",
            {
              icon: "clock-rotate-left",
              title: "History",
              to: "History",
              testID: "menu-history"
            },
            ...baseLinks
          ]);
    }

    return compact<AppMenuItem<WorkerParamList>>([
      hasFinishedOnboarding && {
        icon: "clock-rotate-left",
        title: "History",
        to: "History",
        testID: "menu-history"
      },
      RemoveBenefitsMenu.isDisabled() && {
        icon: "book-medical",
        title: "Benefit Plans",
        testID: "menu-benefit-plans",
        onPress: () =>
          showEmbeddedBrowser({
            url: "https://gigsmart.com/worker-benefits",
            title: "Benefit Plans"
          })
      },
      "separator",
      ...baseLinks,
      isAuthenticated &&
        ({
          title: "Sign Out",
          icon: "right-from-bracket",
          testID: "menu-logout",
          onPress: () => defaultResetHome({ name: "Logout" })
        } as AppMenuItem<WorkerParamList>)
    ]);
  }, [isMd, hasFinishedOnboarding]);
}
