/**
 * @generated SignedSource<<474bac94ca79eeb7ba7aaf2ecb5c351a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewRow_EngagementReview$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
  };
  readonly id: string;
  readonly insertedAt: string;
  readonly rating: number;
  readonly review: string | null | undefined;
  readonly skill: {
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "ReviewRow_EngagementReview";
};
export type ReviewRow_EngagementReview$key = {
  readonly " $data"?: ReviewRow_EngagementReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewRow_EngagementReview">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewRow_EngagementReview",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "review",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    }
  ],
  "type": "EngagementReview",
  "abstractKey": null
};

(node as any).hash = "3f2cd2d2db22e4d845e837306744ce52";

export default node;
