/**
 * @generated SignedSource<<54de928ca3d9abc122ede39de1982577>>
 * @relayHash 15e440a8a7ad561fa5575ecbfc1fedb3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ddhvKmKeyRIN1yEssN21OfcYuRfy0ixlJ7yE01tkpf8

import { ConcreteRequest } from 'relay-runtime';
export type SMSVerificationStepResendCodeMutation$variables = Record<PropertyKey, never>;
export type SMSVerificationStepResendCodeMutation$data = {
  readonly sendUserMobileConfirmation: {
    readonly ok: boolean;
  } | null | undefined;
};
export type SMSVerificationStepResendCodeMutation = {
  response: SMSVerificationStepResendCodeMutation$data;
  variables: SMSVerificationStepResendCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "SendUserMobileConfirmationPayload",
    "kind": "LinkedField",
    "name": "sendUserMobileConfirmation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": "sendUserMobileConfirmation(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SMSVerificationStepResendCodeMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SMSVerificationStepResendCodeMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "qpid:ddhvKmKeyRIN1yEssN21OfcYuRfy0ixlJ7yE01tkpf8",
    "metadata": {},
    "name": "SMSVerificationStepResendCodeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c035e6df1dbf8ae8db3ba4490ef8ce4b";

export default node;
