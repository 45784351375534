import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledPasswordInput } from "@gigsmart/katana";
import React, { type ComponentProps } from "react";
import { Platform } from "react-native";

type StyledPasswordInputProps = ComponentProps<typeof StyledPasswordInput>;

type RefinedStyledTextInputProps = Omit<
  StyledPasswordInputProps,
  "onSubmitEditing" | "value" | "onChangeText" | "errors"
>;

type Props = RefinedStyledTextInputProps & FormFieldOptions;

export function FomuStyledPasswordInput({ name, validates, ...props }: Props) {
  const { setValue, value, submit, errors, showErrors } = useFormField({
    name,
    validates
  });
  return (
    <StyledPasswordInput
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value}
      onChangeText={setValue}
      onBlur={showErrors}
      errors={errors}
      {...props}
    />
  );
}
