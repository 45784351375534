import { useHistory } from "@gigsmart/kaizoku";
import { useCallback } from "react";
import { confirmPrompt, dismiss } from "../confirm-prompt";

export default function useStepperGoBack(
  editing?: boolean,
  prevStep?: () => void
) {
  const history = useHistory();
  return useCallback(
    (dirty?: boolean, isFirstStep = false, goBackNumber = 1) => {
      const handleBack = () => {
        if (!editing && prevStep && !isFirstStep) prevStep();
        else history.go(-goBackNumber);
      };

      if (dirty) {
        confirmGoBack(handleBack);
      } else handleBack();

      return true;
    },
    [editing, prevStep, history]
  );
}

function confirmGoBack(onConfirm: () => void) {
  confirmPrompt({
    title: "Are you sure you want to go back?",
    subTitle: "Your information will not be saved.",
    yesLabel: "Yes",
    cancelLabel: "No",
    onDo: () => {
      dismiss();
      onConfirm();
    },
    onCancel: () => {
      // Nothing to do on cancel press
      dismiss();
    }
  });
}
