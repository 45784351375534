import { FooterSpacer, ScreenScroll, Stack } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { RemoveBenefitsMenu } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ShiftInfoCollapsable from "@gigsmart/seibutsu/engagement/ShiftInfoCollapsable";
import { WorkerBenefitsRow } from "@gigsmart/seibutsu/worker/WorkerBenefitsRow";
import React from "react";
import { GigDescription } from "../gig/engagement-details";
import EngagementDetailsHeader from "../gig/engagement-details/engagement-details-header";
import type { WorkerParamList } from "../navigation/types";
import type { gigSeriesOfferedScreenLegacyQuery } from "./__generated__/gigSeriesOfferedScreenLegacyQuery.graphql";
import type { gigSeriesOfferedScreenQuery } from "./__generated__/gigSeriesOfferedScreenQuery.graphql";
import SeriesSummaryCard from "./cards/series-summary-card";
import { offeredSeriesEngagementsQuery } from "./series-queries";

type Props = AppScreenProps<WorkerParamList, "WorkerGigSeriesOffered">;

export default createSuspendedQueryContainer<
  gigSeriesOfferedScreenQuery,
  Props
>(
  function GigSeriesOfferedScreen(props) {
    const series = props.response?.node;
    const engagement = series?.nextEngagement?.edges?.[0]?.node ?? null;
    const shouldRedirectToEngagementScreen =
      HourlyRateBids.isEnabled() &&
      series?.gigType !== "PROJECT" &&
      !!engagement?.id;

    return shouldRedirectToEngagementScreen ? (
      <Redirect
        screen="ShiftDetails"
        screenParams={{ id: engagement.id }}
        replace
      />
    ) : (
      <LegacyGigSeriesOfferedScreen {...props} />
    );
  },
  {
    query: graphql`
    query gigSeriesOfferedScreenQuery(
      $seriesId: ID!
      $engagementsQuery: String!
    ) {
      node(id: $seriesId) {
        ... on GigSeries {
          gigType
          nextEngagement: engagements(first: 1, query: $engagementsQuery) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  `,
    variables: ({ route }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const user = useCurrentUser();
      const seriesId = route.params.seriesId;
      return {
        seriesId,
        engagementsQuery: offeredSeriesEngagementsQuery(user?.id)
      };
    }
  }
);

const LegacyGigSeriesOfferedScreen = createSuspendedQueryContainer<
  gigSeriesOfferedScreenLegacyQuery,
  Props
>(
  ({ response: { series } = {} }) => {
    const engagement = series?.nextEngagement?.edges?.[0]?.node ?? null;
    const shiftCount = series?.nextEngagement?.totalCount ?? 0;
    const isProject = series?.gigType === "PROJECT";

    return (
      <ScreenScroll testID="gig-series-details">
        <EngagementDetailsHeader
          shiftsCount={shiftCount}
          engagementRef={engagement}
        />
        <Stack>
          {!isProject && (
            <ShiftInfoCollapsable
              shiftsCount={shiftCount}
              fragmentRef={engagement}
            />
          )}
          <GigDescription gigLikeRef={series} isApplied />
          <SeriesSummaryCard fragmentRef={series} />
          {RemoveBenefitsMenu.isDisabled() && <WorkerBenefitsRow />}
        </Stack>
        <FooterSpacer size="large" />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query gigSeriesOfferedScreenLegacyQuery(
        $seriesId: ID!
        $engagementsQuery: String!
      ) {
        series: node(id: $seriesId) {
          ... on GigSeries {
            ...gigDescription_gigLike
            ...seriesSummaryCard_series
              @arguments(engagementsQuery: $engagementsQuery)
            gigType
            payRate
            nextEngagement: engagements(first: 1, query: $engagementsQuery) {
              edges {
                node {
                  estimatedPayment {
                    netPay
                  }
                  id
                  ...ShiftDescriptionHeader_availableGig
                  ...engagementDetailsHeader_engagement
                  ...ShiftInfoCollapsable_engagement
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    variables: ({ route }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const user = useCurrentUser();
      const seriesId = route.params.seriesId;
      return {
        seriesId,
        engagementsQuery: offeredSeriesEngagementsQuery(user?.id)
      };
    }
  }
);
