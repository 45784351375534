/**
 * @generated SignedSource<<60fa44b6c91f44a0f1601c231e9f27d1>>
 * @relayHash 24829f5b9c5cbeade8572873acdff2a5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:qnuLrO8aXTHDj5_Icc8oFPWtmUxoNs6iHkWjXSfVUZI

import { ConcreteRequest } from 'relay-runtime';
export type WorkerQualificationStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type SetWorkerQualificationInput = {
  gigFieldItemDefinitionId: string;
  status: WorkerQualificationStatus;
  workerId: string;
};
export type useQualificationsMutation$variables = {
  input: SetWorkerQualificationInput;
};
export type useQualificationsMutation$data = {
  readonly setWorkerQualification: {
    readonly newWorkerQualificationEdge: {
      readonly node: {
        readonly gigFieldItemDefinition: {
          readonly id: string;
          readonly label: string;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useQualificationsMutation = {
  response: useQualificationsMutation$data;
  variables: useQualificationsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetWorkerQualificationPayload",
    "kind": "LinkedField",
    "name": "setWorkerQualification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerQualificationsEdge",
        "kind": "LinkedField",
        "name": "newWorkerQualificationEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerQualification",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GigFieldItemDefinition",
                "kind": "LinkedField",
                "name": "gigFieldItemDefinition",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useQualificationsMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useQualificationsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "qpid:qnuLrO8aXTHDj5_Icc8oFPWtmUxoNs6iHkWjXSfVUZI",
    "metadata": {},
    "name": "useQualificationsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "322ea6666924f5818a0b2062626cb4f5";

export default node;
