import {
  ActionRow,
  Avatar,
  Button,
  ContentArea,
  Divider,
  ScreenScroll,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { RatingStars } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ReviewRow from "@gigsmart/seibutsu/organization/ReviewRow";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { organizationSummaryScreenQuery } from "./__generated__/organizationSummaryScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "OrganizationSummary">;

export default createSuspendedQueryContainer<
  organizationSummaryScreenQuery,
  Props
>(
  function OrganizationSummaryScreen({ response: result, navigation }) {
    const org = result?.node;
    return (
      <ScreenScroll testID="organization-summary-screen">
        <ContentArea alignItems="center">
          <Avatar uri={org?.logoUrl} size="xLarge" icon="building" />
        </ContentArea>
        <Stack>
          <Surface>
            <ContentArea>
              <Stack alignItems="center" justifyContent="center">
                <Text variant="header">{org?.name}</Text>
                <RatingStars
                  color="spiceOrange"
                  disabled
                  fontSize={30}
                  rating={org?.overallRating ?? 5}
                />
                <Text>
                  {pluralize(org?.reviewsReceived?.totalCount, "Review")}
                </Text>
              </Stack>
            </ContentArea>
            <Divider />
            <Stack variant="divider">
              <ActionRow
                testID="primary-contact"
                title="Primary Contact"
                icon="user"
                right={<Text>{org?.primaryContact?.displayName}</Text>}
                variant="flat"
              />
              <ActionRow
                testID="industry"
                title="Industry"
                icon="wrench"
                right={<Text>{org?.industry ?? ""}</Text>}
                variant="flat"
              />
              {org?.numberEmployees && (
                <ActionRow
                  testID="employees"
                  title="Employees"
                  icon="id-badge"
                  right={<Text>{org?.numberEmployees ?? ""}</Text>}
                  variant="flat"
                />
              )}
              {org?.website && (
                <ActionRow
                  testID="website"
                  title="Website"
                  icon="globe"
                  right={<Text>{org?.website ?? ""}</Text>}
                  variant="flat"
                />
              )}
              {org?.yearFounded && (
                <ActionRow
                  testID="year-founded"
                  title="Year Founded"
                  icon="calendar"
                  right={<Text>{org?.yearFounded}</Text>}
                  variant="flat"
                />
              )}
              {org?.officeType && (
                <ActionRow
                  testID="office-type"
                  title="Office Type"
                  icon="house"
                  right={org?.officeType ?? ""}
                  variant="flat"
                />
              )}
              {org?.allowsSmoking && (
                <ActionRow
                  testID="work-environment"
                  icon="smoking-ban"
                  title="Work Environment"
                  right="Smoking"
                  variant="flat"
                />
              )}
              {org?.union && (
                <ActionRow
                  testID="affiliations"
                  icon="handshake"
                  title="Affiliations"
                  right="Union"
                  variant="flat"
                />
              )}
              {org?.description && (
                <ContentArea>
                  <Text>{org?.description}</Text>
                </ContentArea>
              )}
            </Stack>
          </Surface>
          {org?.reviewsReceived?.totalCount !== 0 && (
            <Stack>
              <Surface>
                <ContentArea>
                  <Text variant="header" align="center">
                    Reviews
                  </Text>
                </ContentArea>
                <Divider />
                <ReviewRow
                  fragmentRef={org?.reviewsReceived?.edges?.[0]?.node}
                />
              </Surface>
              {(org?.reviewsReceived?.totalCount ?? 0) > 1 && (
                <ContentArea size="none">
                  <Button
                    testID="read-additional-rreviews-btn"
                    outline
                    label="Read Additional Reviews"
                    onPress={() =>
                      navigation.push("OrganizationReviews", {
                        orgId: org?.id ?? ""
                      })
                    }
                  />
                  <Spacer />
                </ContentArea>
              )}
            </Stack>
          )}
        </Stack>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query organizationSummaryScreenQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            id
            name
            logoUrl
            primaryContact {
              displayName
            }
            industry
            numberEmployees: companySize
            website
            yearFounded
            officeType
            description
            union
            allowsSmoking
            overallRating
            reviewsReceived(first: 1) {
              totalCount
              edges {
                node {
                  ...ReviewRow_EngagementReview
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({
      id: route.params.orgId
    })
  }
);
