import React from "react";

import { Spacer, Text } from "@gigsmart/atorasu";
import { SmallOutlineButton } from "@gigsmart/katana";
import { graphql, useRelayPaginationFragment } from "@gigsmart/relay";
import type { priorTransferList_payouts$key } from "./__generated__/priorTransferList_payouts.graphql";
import type {
  priorTransfersWorkerStripePayoutsQuery,
  priorTransfersWorkerStripePayoutsQuery$data
} from "./__generated__/priorTransfersWorkerStripePayoutsQuery.graphql";
import type { priorTransfers_workerPayableAccount$data } from "./__generated__/priorTransfers_workerPayableAccount.graphql";
import PriorTransferRow from "./prior-transfer-row";

interface Props {
  workerBalance: number;
  workerPayableAccount:
    | priorTransfers_workerPayableAccount$data
    | null
    | undefined;
  query: priorTransfersWorkerStripePayoutsQuery$data;
}

export default ({ query, workerBalance, workerPayableAccount }: Props) => {
  const { data, hasNext, loadNext } = useRelayPaginationFragment<
    priorTransfersWorkerStripePayoutsQuery,
    priorTransferList_payouts$key
  >(
    graphql`
      fragment priorTransferList_payouts on RootQueryType
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        priorTransfersQuery: { type: "String" }
      )
      @refetchable(queryName: "priorTransferListRefetchQuery") {
        viewer {
          ... on Worker {
            payouts(
              first: $count
              after: $after
              query: "ORDER BY insertedAt DESC"
            ) @connection(key: "priorTransferEngagementRows_payouts") {
              totalCount
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              edges {
                cursor
                node {
                  ...priorTransferRow_payout
                    @arguments(priorTransfersQuery: $priorTransfersQuery)
                }
              }
            }
          }
        }
      }
    `,
    query
  );
  const payouts = data?.viewer?.payouts?.edges;
  if (!payouts || payouts.length === 0) {
    let message =
      "Add a debit card or bank account to transfer your Account Balance.";
    if (workerPayableAccount?.paymentMethod?.last4) {
      message =
        "Shift earning transfers to your saved debit card or bank account will appear here once completed.";
      if (workerBalance > 0) {
        message =
          "Start a transfer to send Shift earnings to your account on file.";
      }
    }
    return (
      <>
        <Spacer />
        <Text align="center">{message}</Text>
      </>
    );
  }
  return (
    <>
      {payouts.map((edge, index) => (
        <PriorTransferRow
          key={edge?.cursor ?? `stripePayout_paid_${index}`}
          testID={
            edge?.cursor
              ? `stripePayout_${edge?.cursor || ""}`
              : `stripePayout_${index}`
          }
          stripePayout={edge?.node ?? null}
        />
      ))}
      {hasNext && (
        <>
          <Spacer />
          <SmallOutlineButton
            title="Load More Transfers"
            onPress={() => loadNext(10)}
          />
        </>
      )}
    </>
  );
};
