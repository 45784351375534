/**
 * @generated SignedSource<<69a5ba7a51d572a539fe1963b397f5dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RequesterInfoRow_gigLike$data = {
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly organization?: {
    readonly id: string;
    readonly logoUrl: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly requester?: {
    readonly displayName: string | null | undefined;
    readonly firstName: string | null | undefined;
    readonly lastInitial: string | null | undefined;
    readonly overallRating: number;
    readonly primaryOrganization: {
      readonly id: string;
      readonly logoUrl: string | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly reviewsReceived: {
      readonly totalCount: number;
    } | null | undefined;
  };
  readonly " $fragmentType": "RequesterInfoRow_gigLike";
};
export type RequesterInfoRow_gigLike$key = {
  readonly " $data"?: RequesterInfoRow_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequesterInfoRow_gigLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastInitial",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    }
  ],
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "reviewsReceived(first:0)"
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequesterInfoRow_gigLike",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Requester",
          "kind": "LinkedField",
          "name": "requester",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "primaryOrganization",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Requester",
          "kind": "LinkedField",
          "name": "requester",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "primaryOrganization",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "45682c3d6c6cebeb864413891f9db8be";

export default node;
