import { useEventer } from "@gigsmart/dekigoto";
import React, { useCallback, type ComponentProps } from "react";
import { Platform, Switch, View } from "react-native";
import { type ViewStyleProp, createStyles, theme } from "../style";

const { color } = theme;

type Props = ComponentProps<typeof Switch> & {
  containerStyle?: ViewStyleProp;
  onValueChange?: (value: boolean) => void;
  value: boolean | undefined;
  testID?: string;
  transparent?: boolean;
};

const styles = createStyles(() => ({
  dimensions: {
    ...Platform.select({
      ios: {
        width: 51,
        height: 31
      },
      android: {
        width: 48,
        height: 27
      },
      web: {
        width: 40
      }
    })
  },
  track: {
    borderRadius: 15
  }
}));

export default function StyledSwitch({
  containerStyle,
  onValueChange,
  value,
  testID,
  transparent,
  disabled,
  style,
  thumbColor = color.white
}: Props) {
  const trackSwitch = useEventer(
    `Toggled ${value ? "Off" : "On"}`,
    testID ?? "Unknown",
    "Switch"
  );
  const handleValueChange = useCallback(
    (nextValue: boolean) => {
      trackSwitch();
      onValueChange?.(nextValue);
    },
    [onValueChange, trackSwitch]
  );
  const trackColor = transparent
    ? {
        true: color.white,
        false: color.transparent
      }
    : {
        true: color.blue,
        false: color.neutralDark
      };

  return (
    <View style={[styles.track, containerStyle, styles.dimensions]}>
      <Switch
        {...(Platform.OS === "web"
          ? ({ activeThumbColor: color.white } as any)
          : {})}
        trackColor={trackColor}
        testID={testID ?? "styled-switch"}
        thumbColor={thumbColor}
        onValueChange={handleValueChange}
        value={value}
        disabled={disabled}
        style={style}
      />
    </View>
  );
}
