import { type Color, Row, Tag } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type {
  EngagementStateAction,
  EngagementStateName,
  ShiftStatusTags_shift$key
} from "./__generated__/ShiftStatusTags_shift.graphql";

type Props = {};

export const ShiftStatusTags = ({
  gigType,
  currentState,
  availableActions,
  customTags
}: FragmentContainerInnerComponentProps<ShiftStatusTags_shift$key, Props>) => {
  if (!currentState) return null; // sanity check...

  const tag = renderStatusTag(
    gigType === "PROJECT",
    currentState?.name,
    currentState?.action,
    availableActions
  );
  if (!tag && !customTags?.length) return null;

  return (
    <Row gap="compact" wrap="wrap">
      {typeof tag === "string" ? (
        <Tag label={tag} iconType="FONT_AWESOME" icon="circle" />
      ) : (
        tag
      )}
      {customTags?.map(
        (it) =>
          !!it && (
            <Tag
              key={it.content}
              variant="clear"
              color={(it.color ?? "highlight") as Color}
              icon={it.icon.data}
              iconType={it.icon.type as any}
              label={it.content}
            />
          )
      )}
    </Row>
  );
};

export default createRelayFragmentContainer<ShiftStatusTags_shift$key, Props>(
  graphql`
    fragment ShiftStatusTags_shift on Engagement {
      gigType
      availableActions
      currentState {
        name
        action
      }
      customTags {
        icon {
          type
          data
        }
        color
        content
      }
    }
  `,
  ShiftStatusTags
);

const renderStatusTag = (
  isProject: boolean,
  stateName: EngagementStateName,
  stateAction: EngagementStateAction,
  availableActions?: readonly EngagementStateAction[]
) => {
  const noun = isProject ? "Project" : "Gig";

  switch (stateName) {
    case "BID_REQUESTED":
      return (
        <Tag
          label="Bid Requested"
          color="link"
          iconType="FONT_AWESOME"
          icon="hand-holding-dollar"
        />
      );
    case "BID_REVIEW":
      return (
        <Tag
          label="Bid Submitted"
          color="primary"
          iconType="FONT_AWESOME"
          icon="file-check"
          variant="clear"
        />
      );
    case "OFFERED":
      return (
        <Tag
          iconType="FONT_AWESOME"
          icon={stateAction === "COUNTER_OFFER" ? "comment-dollar" : "check"}
          label={
            stateAction === "COUNTER_OFFER" ? "Bid Countered" : "Pending Offer"
          }
          color={stateAction === "COUNTER_OFFER" ? "warning" : "success"}
        />
      );
    case "CONFIRMING":
      return "Pending Verification";
    case "SCHEDULED": {
      const label = isProject
        ? "Hired"
        : availableActions?.includes("EMBARK")
          ? "Waiting to Depart"
          : "Scheduled";
      return (
        <Tag
          label={label}
          color={isProject ? "success" : "neutral"}
          iconType="FONT_AWESOME"
          icon="circle"
        />
      );
    }
    case "ENGAGED":
      return (
        <Tag
          label="Messaging with Requester"
          iconType="FONT_AWESOME"
          icon="message-lines"
        />
      );
    case "HIRE_REQUESTED":
      return (
        <Tag label="Hire Pending" iconType="FONT_AWESOME" icon="spinner" />
      );
    case "EN_ROUTE":
      return "Departed";
    case "AWAITING_START":
      return "Arrived";
    case "PAUSED":
      return (
        <Tag
          label="Paused"
          color="warning"
          iconType="FONT_AWESOME"
          icon="pause"
        />
      );
    case "WORKING":
      return (
        <Tag
          label="Working"
          color="success"
          iconType="FONT_AWESOME"
          icon="play"
        />
      );

    case "PENDING_REVIEW":
      return (
        <Tag label="Pending Review" iconType="FONT_AWESOME" icon="spinner" />
      );
    case "PENDING_TIMESHEET_APPROVAL":
    case "ENDED":
      return `${noun} Complete`;

    default:
      return null;
  }
};
