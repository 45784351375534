import {
  Card,
  Column,
  ContentArea,
  Icon,
  IconCircle,
  IconText,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import { PAYMENT_TYPES } from "@gigsmart/isomorphic-shared/gig/payment-types";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerProjectCard_gig$key } from "./__generated__/WorkerProjectCard_gig.graphql";

interface Props {
  workerDistance?: number | { max?: number; min?: number } | null;
  onPress?: () => void;
}

export const WorkerProjectCard = ({
  name,
  area,
  category,
  startsAt,
  negotiable,
  paymentType,
  workerDistance,
  onPress
}: FragmentContainerInnerComponentProps<WorkerProjectCard_gig$key, Props>) => {
  return (
    <Card
      onPress={onPress}
      testID="worker-project-card"
      eventTargetName="Worker Project Card"
    >
      <ContentArea>
        <Stack>
          <Stack horizontal justifyContent="space-between" fill>
            <Stack horizontal alignItems="center" fill>
              <IconCircle icon="pen-ruler" color="primary" size="small" />
              <Text
                weight="bold"
                numberOfLines={2}
                testID={"worker-project-card-title"}
                wrap
              >
                {name}
              </Text>
            </Stack>
            <IconText
              iconPlacement="right"
              icon="chevron-right"
              color="primary"
              size="small"
            >
              <Text weight="bold">View</Text>
            </IconText>
          </Stack>
          <Stack horizontal>
            <Spacer horizontal size="slim" />
            <Spacer horizontal size="compact" />
            <Column fill={3} justifyContent="center">
              <Stack horizontal size="compact" alignItems="center">
                <Column justifyContent="center">
                  <Stack
                    size="compact"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon
                      color="neutral"
                      name="box-check"
                      size="tiny"
                      variant="solid"
                    />
                    <Icon
                      color="neutral"
                      name="calendar-days"
                      size="tiny"
                      variant="solid"
                    />
                    <Icon
                      color="neutral"
                      name="location-dot"
                      size="tiny"
                      variant="solid"
                    />
                    <Icon
                      color="neutral"
                      name="mobile-alt"
                      size="tiny"
                      variant="solid"
                    />
                  </Stack>
                </Column>
                <Column fill>
                  <Stack size="slim" justifyContent="center">
                    <Text
                      color="neutral"
                      testID={"worker-project-card-category"}
                    >
                      {category?.displayName}
                    </Text>
                    <Text
                      color="neutral"
                      testID={"worker-project-card-datetime"}
                    >
                      {gigHelpers.workerProjectCardDateTimeString(
                        startsAt,
                        !!negotiable?.includes("STARTS_AT")
                      )}
                    </Text>
                    <Stack
                      horizontal
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text color="neutral" testID={"worker-project-card-area"}>
                        {area}
                      </Text>
                      {area !== "REMOTE" && (
                        <Text
                          color="neutral"
                          testID={"worker-project-card-distance"}
                        >
                          {typeof workerDistance === "number"
                            ? `(${workerDistance.toFixed(1)}mi)`
                            : gigHelpers.formatDistanceRange(workerDistance)}
                        </Text>
                      )}
                    </Stack>
                    <Text color="neutral">
                      Paid{" "}
                      {paymentType ? PAYMENT_TYPES[paymentType].altLabel : ""}
                    </Text>
                  </Stack>
                </Column>
              </Stack>
            </Column>
          </Stack>
        </Stack>
      </ContentArea>
    </Card>
  );
};

export default createRelayFragmentContainer<WorkerProjectCard_gig$key, Props>(
  graphql`
    fragment WorkerProjectCard_gig on Gig {
      id
      insertedAt
      startsAt
      endsAt
      name
      place {
        streetAddress
      }
      placeId
      area
      gigType
      negotiable
      paymentType
      category {
        displayName
      }
    }
  `,
  WorkerProjectCard
);
