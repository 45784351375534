/**
 * @generated SignedSource<<2ba5b82a5f924c85c17aaf49c6b3726c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerOnboardingScreen_worker$data = {
  readonly birthdate: string | null | undefined;
  readonly primaryMobile: {
    readonly confirmed: boolean;
  } | null | undefined;
  readonly transportationMethod: string | null | undefined;
  readonly transportationState: WorkerTransportationState | null | undefined;
  readonly workerPositions: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "WorkerOnboardingScreen_worker";
};
export type WorkerOnboardingScreen_worker$key = {
  readonly " $data"?: WorkerOnboardingScreen_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerOnboardingScreen_worker">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "WorkerOnboardingScreen_worker"
};

(node as any).hash = "e0a8374dc89e0ef4b539c24668269e6e";

export default node;
