import {
  Button,
  ContentArea,
  type ModalHandler,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";
import {
  type FomuOnSubmitArgs,
  type FomuSubmitFn,
  Form,
  Validator
} from "@gigsmart/fomu";
import {
  graphql,
  useFetchQueryResult,
  useRelayMutation
} from "@gigsmart/relay";
import FomuRadioButtonGroup from "@gigsmart/seibutsu/fomu/inputs/FomuRadioButtonGroup";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import { parsePhoneNumber } from "libphonenumber-js";
import { upperFirst } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import type { otpCaptureQuery } from "./__generated__/otpCaptureQuery.graphql";
import type {
  EscalationCodeMethod,
  otpCaptureSendCodeMutation
} from "./__generated__/otpCaptureSendCodeMutation.graphql";

interface Props {
  onComplete: (otp: string) => void;
  onMethodChange: (method: EscalationCodeMethod) => void;
  otpMethod: EscalationCodeMethod;
  message?: string | undefined | null;
}

function OTPCapture({ onComplete, onMethodChange, message, otpMethod }: Props) {
  const [otpState, setOtpState] = useState<{
    codeSent: boolean;
    message: string | undefined | null;
  }>({
    codeSent: false,
    message
  });

  const [result] = useFetchQueryResult<otpCaptureQuery>(
    graphql`
      query otpCaptureQuery {
        viewer {
          primaryMobile {
            number
          }
          primaryEmail {
            address
          }
        }
      }
    `
  );
  const [commit] = useRelayMutation<otpCaptureSendCodeMutation>(graphql`
    mutation otpCaptureSendCodeMutation($input: SendEscalationCodeInput!) {
      sendEscalationCode(input: $input) {
        ok
        message
      }
    }
  `);

  const handleCodeSubmit = useCallback<FomuSubmitFn>(
    ({ values: { otp } }, done) => {
      onComplete(otp);
      done();
    },
    [onComplete]
  );

  const handleMethodFormSubmit = async ({
    values: { otpMethod }
  }: FomuOnSubmitArgs) => {
    await handleMethodChange(otpMethod);
  };

  const handleMethodChange = async (otpMethod: EscalationCodeMethod) => {
    if (!viewer) {
      onMethodChange(otpMethod);
      return;
    }
    commit(
      {
        input: { method: otpMethod }
      },
      {
        onSuccess: ({ sendEscalationCode }) => {
          setOtpState({ codeSent: true, message: sendEscalationCode?.message });
        },
        onPayloadErrors: (errors) => {
          const errorMsg = errors[0]?.message ?? "Error sending code";
          setOtpState((state) => ({ ...state, message: errorMsg }));
        }
      }
    );
  };

  const buttons = useMemo(() => {
    const { primaryMobile, primaryEmail } = result?.viewer ?? {};
    const buttons = [];
    if (primaryMobile) {
      buttons.push({
        title: `Mobile Number: ${parsePhoneNumber(
          primaryMobile.number
        ).formatNational()}`,
        label: primaryMobile.number,
        value: "SMS",
        testID: "mobile-radio"
      });
    }

    if (primaryEmail) {
      buttons.push({
        title: `Email: ${primaryEmail.address}`,
        label: primaryEmail.address,
        value: "EMAIL",
        testID: "email-radio"
      });
    }

    return buttons;
  }, [result?.viewer]);

  const viewer = result?.viewer;
  const { codeSent } = otpState;

  const inverseOtpMethod: EscalationCodeMethod =
    otpMethod === "SMS" ? "EMAIL" : "SMS";

  if (!viewer || codeSent) {
    return (
      <ContentArea>
        <Form onSubmit={handleCodeSubmit}>
          <Stack>
            <Text variant="note">{message}</Text>
            <FomuTextInput
              validates={[
                Validator.presence(),
                Validator.length({ min: 6, max: 7 })
              ]}
              name="otp"
              label="Verification Code"
            />
            <Button
              variant="clear"
              outline
              label="Resend Code"
              testID="resend-code"
              onPress={async () => await handleMethodChange(otpMethod)}
            />
            <FomuSubmit testID="otp-submit" label="Verify" />
            {!viewer && (
              <Button
                variant="clear"
                label={`Send Code to ${
                  inverseOtpMethod === "SMS" ? "Mobile Phone" : "Email Address"
                }`}
                testID={`send-to-${inverseOtpMethod.toLowerCase()}`}
                onPress={async () => await handleMethodChange(inverseOtpMethod)}
              />
            )}
          </Stack>
        </Form>
      </ContentArea>
    );
  }
  return (
    <ContentArea>
      <Form onSubmit={handleMethodFormSubmit}>
        <Stack>
          <Text>
            Verification is required. Select the method by which you would like
            to receive a verification code
          </Text>
          <FomuRadioButtonGroup name="otpMethod" buttons={buttons} required />
          <FomuSubmit testID="otp-method-submit" label="Send Code" />
        </Stack>
      </Form>
    </ContentArea>
  );
}

type OtpCaptureShowFn = (
  message: string | null | undefined,
  method: EscalationCodeMethod,
  afterCaptureFn?: () => void
) => void;

export function useOtpCapture(): [
  React.MutableRefObject<string | undefined>,
  React.MutableRefObject<EscalationCodeMethod | undefined>,
  OtpCaptureShowFn
] {
  const otpModalHandler = useRef<ModalHandler>();
  const otpMethod = useRef<EscalationCodeMethod | undefined>(undefined);
  const otpCode = useRef<string>();
  const handleOtpCapture = useCallback(
    async (otp: string, afterCaptureFn?: () => void) => {
      otpCode.current = otp;
      otpModalHandler.current?.dispose();
      afterCaptureFn?.();
    },
    []
  );
  const handleMethodChange = useCallback(
    (method: EscalationCodeMethod | undefined, afterCaptureFn?: () => void) => {
      otpCode.current = undefined;
      otpMethod.current = method;
      otpModalHandler.current?.dispose();
      afterCaptureFn?.();
    },
    []
  );

  const show = useCallback<OtpCaptureShowFn>(
    (
      message: string | null | undefined,
      method: EscalationCodeMethod,
      afterCaptureFn?: () => void
    ) => {
      otpModalHandler.current?.dispose();
      otpModalHandler.current = showModal({
        title: "Account Verification",
        testID: "account-verification-modal",
        eventContext: "Account Verification Modal",
        children: (
          <OTPCapture
            onComplete={async (otp) =>
              await handleOtpCapture(otp, afterCaptureFn)
            }
            onMethodChange={(nextMethod) =>
              handleMethodChange(nextMethod, afterCaptureFn)
            }
            otpMethod={method}
            message={message && upperFirst(message)}
          />
        )
      });
    },
    [handleMethodChange, handleOtpCapture]
  );

  return [otpCode, otpMethod, show];
}
