import moment from "moment";
import React from "react";
import { pluralize } from "../../app/inflector";
import GigCardAside from "./aside";

interface Props {
  lastUsed?: string | null;
  timesUsed?: number | null;
}

const formatLastUsed = (lastUsedDate?: string | null) => {
  if (!lastUsedDate) return "Never";
  const today = moment().startOf("day");
  const lastUsed = moment(lastUsedDate).startOf("day");
  if (lastUsed.isSame(today, "day")) return "Today";
  const numberOfDays = today.diff(lastUsed, "days");
  return `${pluralize(numberOfDays, "Day")} Ago`;
};

const GigLastUsedAside = ({ timesUsed, lastUsed }: Props) => (
  <GigCardAside
    content={[
      `Used ${pluralize(timesUsed ?? 0, "Time")}`,
      `Last Used ${formatLastUsed(lastUsed)}`
    ]}
  />
);

export default GigLastUsedAside;
