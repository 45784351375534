import React, { useMemo } from "react";

import {
  ContentArea,
  Divider,
  GridNull,
  IconText,
  Row,
  Text
} from "@gigsmart/atorasu";
import {
  getReportStatus,
  getReportType,
  typeKeys
} from "@gigsmart/isomorphic-shared/report/dictionary";
import {
  graphql,
  useRelayFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import compact from "lodash/compact";
import HomescreenCard from "./HomescreenCard";

import type { VerificationCenterCardSubscription } from "./__generated__/VerificationCenterCardSubscription.graphql";
import type {
  ReportType,
  VerificationCenterCard_worker$key,
  VerificationReportStatus
} from "./__generated__/VerificationCenterCard_worker.graphql";

export type Report = NonNullable<
  ObjectPath<
    VerificationCenterCard_worker$key,
    [" $data", "verification", "reports", "0"]
  >
>;

type LegalInformation = NonNullable<
  ObjectPath<VerificationCenterCard_worker$key, [" $data", "legalInformation"]>
>;

interface WrapperProps {
  workerRef?: VerificationCenterCard_worker$key | null | undefined;
}

interface CardProps {
  reports: Report[] | [];
  legalInformation?: LegalInformation | null;
}

export default function VerificationCenterCardWrapper({
  workerRef
}: WrapperProps) {
  const { verification, legalInformation } =
    useRelayFragment(
      graphql`
        fragment VerificationCenterCard_worker on Worker {
          verification {
            status
            reports {
              type
              status
              statusDescription
            }
          }
          legalInformation {
            id
            backgroundCheckOptIn
            driverCheckOptIn
            drugScreenOptIn
          }
        }
      `,
      workerRef ?? null
    ) || {};

  useRelaySubscription<VerificationCenterCardSubscription>(
    graphql`
      subscription VerificationCenterCardSubscription {
        workerUpdated {
          worker {
            ...VerificationCenterCard_worker
          }
        }
      }
    `,
    {},
    {
      subscribe: true
    }
  );

  const reports = verification?.reports ?? [];

  const shouldShowVerificationCard = useMemo(() => {
    const excludedType: ReportType = "IDENTITY_CHECK" as ReportType;
    const selectedReports = reports.filter(({ type }) => type !== excludedType);
    return selectedReports.some(({ status }) => {
      return status === "PENDING" || status === "REQUIRES_ATTENTION";
    });
  }, [reports]);

  if (!shouldShowVerificationCard) return <GridNull />;

  return (
    <VerificationCenterCard
      reports={compact(reports)}
      legalInformation={legalInformation}
    />
  );
}

export const VerificationCenterCard = ({
  reports,
  legalInformation
}: CardProps) => {
  const checkrReports: string[] = typeKeys
    .filter(
      (type) =>
        !getReportStatus(
          reports.find((report) => report?.type === type)?.status || ""
        ).exclude
    )
    .filter((type) => type !== "IDENTITY_CHECK");

  const getPermissionStatusColor = (
    status: VerificationReportStatus | "OPTED_OUT"
  ) => {
    switch (status) {
      case "FAIL":
        return "danger";
      case "REQUIRES_ATTENTION":
        return "warning";
      case "NOT_STARTED":
      case "PENDING":
      case "OPTED_OUT":
        return "neutral";
      case "NOT_ELIGIBLE":
        return "danger";
      case "PASS":
        return "success";
      default:
        return "black";
    }
  };

  const ReportRenderer = () => {
    return (
      <>
        {checkrReports.map((type, index) => {
          const {
            title: reportTitle,
            iconName,
            optInKey
          } = getReportType(type);
          const { status } = reports.find(
            (report) => report?.type === type && legalInformation?.[optInKey]
          ) || {
            status: legalInformation?.[optInKey] ? "NOT_STARTED" : "OPTED_OUT"
          };

          const { title: statusTitle } = getReportStatus(status);

          if (
            status !== ("PENDING" as VerificationReportStatus) &&
            status !== ("REQUIRES_ATTENTION" as VerificationReportStatus)
          )
            return null;

          return (
            <>
              <Divider />
              <ContentArea>
                <Row justifyContent="space-between">
                  <IconText icon={iconName} spacing="medium" color="primary">
                    <Text testID={`report-title-${index}`} variant="subheader">
                      {reportTitle}
                    </Text>
                  </IconText>
                  <Text color={getPermissionStatusColor(status)}>
                    {statusTitle}
                  </Text>
                </Row>
              </ContentArea>
            </>
          );
        })}
      </>
    );
  };

  return (
    <HomescreenCard
      headerTitle="Verification Status"
      headerIcon="shield-check"
      footerLinkText="Learn More about Verification Status"
      footerLinkUrl="https://gigsmart.com/workers/get-verified"
    >
      <ReportRenderer />
      <Divider />
    </HomescreenCard>
  );
};
