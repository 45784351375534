/**
 * @generated SignedSource<<85f24bca2cb0e5195a6b935acb3e73e9>>
 * @relayHash 4032e4f874aa96a7de8d6f244c068a85
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:JSjgV0XYE6ZPU8kj9Xfa1mh6QV24BTwKExnHM2pUlT4

import { ConcreteRequest } from 'relay-runtime';
export type WorkerNotificationHandlerQuery$variables = Record<PropertyKey, never>;
export type WorkerNotificationHandlerQuery$data = {
  readonly viewer: {
    readonly currentEngagement?: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type WorkerNotificationHandlerQuery = {
  response: WorkerNotificationHandlerQuery$data;
  variables: WorkerNotificationHandlerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Engagement",
      "kind": "LinkedField",
      "name": "currentEngagement",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerNotificationHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WorkerNotificationHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:JSjgV0XYE6ZPU8kj9Xfa1mh6QV24BTwKExnHM2pUlT4",
    "metadata": {},
    "name": "WorkerNotificationHandlerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "59b429f5769ce4824b95cb761dea6351";

export default node;
