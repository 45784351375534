/**
 * @generated SignedSource<<1c35394f6cc308161ca313eb4c10c0ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type requesterReviewRow_engagementReviewsEdge$data = {
  readonly node: {
    readonly author: {
      readonly displayName: string | null | undefined;
    };
    readonly engagement: {
      readonly gig: {
        readonly position: {
          readonly name: string;
        } | null | undefined;
      };
    };
    readonly insertedAt: string;
    readonly rating: number;
    readonly review: string | null | undefined;
    readonly skill: {
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "requesterReviewRow_engagementReviewsEdge";
};
export type requesterReviewRow_engagementReviewsEdge$key = {
  readonly " $data"?: requesterReviewRow_engagementReviewsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"requesterReviewRow_engagementReviewsEdge">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "requesterReviewRow_engagementReviewsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Engagement",
          "kind": "LinkedField",
          "name": "engagement",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "gig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigPosition",
                  "kind": "LinkedField",
                  "name": "position",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "review",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "insertedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementReviewsEdge",
  "abstractKey": null
};
})();

(node as any).hash = "f39144dbbcc69bb57178de9559efb05b";

export default node;
