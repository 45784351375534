import { Column, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useHistory } from "@gigsmart/kaizoku";
import { StyledButton } from "@gigsmart/katana";
import React, { useEffect } from "react";
import { View } from "react-native";
import { VIEWED_JOB_BOARD_INTRO, useUserValue } from "../user-value";
import JobsIntroductionSvg from "./jobs-introduction-svg";

export default function JobsIntroduction() {
  const [viewedJobBoardIntro, setViewedJobBoardIntro] = useUserValue<boolean>(
    VIEWED_JOB_BOARD_INTRO
  );
  const history = useHistory();
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      paddingHorizontal: getUnits(4),
      paddingBottom: getUnits(4),
      paddingTop: getUnits(12),
      backgroundColor: getColor("surface", "fill")
    },
    textContainer: {
      marginHorizontal: getUnits(8),
      marginTop: getUnits(4)
    },
    headerText: {
      marginBottom: 0
    },
    svgContainer: {
      marginHorizontal: getUnits(16)
    }
  }));
  useEffect(() => {
    if (!viewedJobBoardIntro) setViewedJobBoardIntro(true);
  }, []);
  return (
    <Column justifyContent="space-between" fill={1} style={styles.container}>
      <Column>
        <Text
          color="primary"
          variant="bodyLg"
          weight="semibold"
          style={styles.headerText}
        >
          Welcome to the
        </Text>
        <Text color="primary" variant="bodyLg" weight="semibold">
          GigSmart Job Board!
        </Text>
        <View style={styles.textContainer}>
          <Text>
            Here you can browse and apply to part-time and full-time jobs
            matching your skill set.
          </Text>
        </View>
      </Column>
      <View style={styles.svgContainer}>
        <JobsIntroductionSvg />
      </View>
      <StyledButton
        color="blue"
        title="View Jobs"
        onPress={() => history.replace("/jobs")}
      />
    </Column>
  );
}
