/**
 * @generated SignedSource<<786f14560d18ad98415aa9cf948b4a25>>
 * @relayHash 61cdea8fcfcffd3f08c7915241d0f2e1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:efMKz6giUwglPHG1i_ndPCwa2lc0eLiWbiYtlGfVdqI

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountScreenQuery$variables = Record<PropertyKey, never>;
export type accountScreenQuery$data = {
  readonly viewer: {
    readonly primaryEmail?: {
      readonly " $fragmentSpreads": FragmentRefs<"ChangeEmailFormCard_userEmail">;
    } | null | undefined;
  } | null | undefined;
};
export type accountScreenQuery = {
  response: accountScreenQuery$data;
  variables: accountScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEmail",
                "kind": "LinkedField",
                "name": "primaryEmail",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ChangeEmailFormCard_userEmail"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEmail",
                "kind": "LinkedField",
                "name": "primaryEmail",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:efMKz6giUwglPHG1i_ndPCwa2lc0eLiWbiYtlGfVdqI",
    "metadata": {},
    "name": "accountScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "72cfadf1216872be1efe629422c57f32";

export default node;
