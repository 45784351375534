/**
 * @generated SignedSource<<e4fbf217e7dc50dd784594cc08a9f38a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerAvailableShiftCard_gigEdge$data = {
  readonly distance: number | null | undefined;
  readonly estimatedPayment: {
    readonly netPay: string;
    readonly payRate: string;
  } | null | undefined;
  readonly node: {
    readonly endsAt: string | null | undefined;
    readonly pickup: {
      readonly eligible: boolean;
      readonly windowEnd: string | null | undefined;
    };
    readonly startsAt: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCard_gigLike">;
  } | null | undefined;
  readonly " $fragmentType": "WorkerAvailableShiftCard_gigEdge";
};
export type WorkerAvailableShiftCard_gigEdge$key = {
  readonly " $data"?: WorkerAvailableShiftCard_gigEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerAvailableShiftCard_gigEdge">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerAvailableShiftCard_gigEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "estimatedPayment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPay",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkerShiftCard_gigLike"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pickup",
          "kind": "LinkedField",
          "name": "pickup",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eligible",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "windowEnd",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailableGigsEdge",
  "abstractKey": null
};

(node as any).hash = "fb7b9ac7067583c83f99c74b8120062a";

export default node;
