import {
  Button,
  CheckboxRow,
  Column,
  ListEmptySearch,
  Row,
  Spacer,
  Stack,
  Text,
  TextInput
} from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useEffect, useMemo, useState } from "react";
import usePositionsByCategory, {
  positionsByCategoryVariables
} from "../../../positions/hooks/usePositionsByCategory";
import { ResponsiveStepperPortal } from "../../../shared/Stepper";
import type { PositionsFormQuery } from "./__generated__/PositionsFormQuery.graphql";

interface Props {
  categoryId: string;
  workerCategoryId: string;
  onBackPress: () => void;
  showTitle?: boolean;
  onChangeTitle?: (title: string | null) => void;
}

export default createSuspendedQueryContainer<PositionsFormQuery, Props>(
  function PositionsForm({
    workerCategoryId,
    onBackPress,
    onChangeTitle,
    showTitle,
    response
  }) {
    const [searchTerm, setSearchTerm] = useState("");
    const { title, subtitle, options, checkOption } = usePositionsByCategory(
      response?.viewer,
      workerCategoryId
    );
    const filteredOptions = useMemo(() => {
      return options.filter((opt) => opt.label?.includes(searchTerm));
    }, [searchTerm, options]);

    useEffect(() => {
      onChangeTitle?.(title ?? null);
    }, [title]);

    return (
      <>
        {!showTitle && (
          <ResponsiveStepperPortal.Entrance stepTitle={title} showBtn={false} />
        )}
        <Stack fill>
          {showTitle && (
            <Text variant="header" color="primary">
              {title}
            </Text>
          )}
          <Text>{subtitle}</Text>
          <Row>
            <Button
              size="small"
              outline
              icon="chevron-left"
              label="Back to Categories"
              onPress={onBackPress}
              testID="positions-back-to-categories-btn"
            />
          </Row>
          <Text weight="bold">Select all Positions you are able to work.</Text>
          <TextInput
            testID="position-search-input"
            value={searchTerm}
            onChangeText={(val) => setSearchTerm(val)}
            placeholder="Search Position Title"
          />
          {(filteredOptions?.length ?? 0) > 0 ? (
            <Stack size="compact">
              {filteredOptions?.map((opt) => (
                <CheckboxRow
                  key={opt.id}
                  onChange={(value) =>
                    checkOption({
                      value,
                      id: opt.id,
                      label: opt.label,
                      workerGigPositionId: opt?.workerGigPositionId
                    })
                  }
                  selected={!!opt.value}
                  testID={"position-checkbox"}
                  title={opt.label}
                />
              ))}
            </Stack>
          ) : (
            <ListEmptySearch searchTerm={searchTerm} />
          )}
          <Column fill justifyContent="flex-end">
            <Button
              icon="chevron-left"
              label="Back to Categories"
              outline
              testID="positions-back-to-categories-btn"
              onPress={onBackPress}
            />
          </Column>
          <Spacer />
        </Stack>
      </>
    );
  },
  {
    query: graphql`
      query PositionsFormQuery(
        $categoryQuery: String!
        $positionsQuery: String!
      ) {
        viewer {
          ...usePositionsByCategory_worker
            @arguments(
              categoryQuery: $categoryQuery
              positionsQuery: $positionsQuery
            )
        }
      }
    `,
    variables: positionsByCategoryVariables
  }
);
