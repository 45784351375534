import { Text } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";

import {
  projectCardDateTimeString,
  useGigCardDateTimeString
} from "../helpers";
import type { dateItem_gig$key } from "./__generated__/dateItem_gig.graphql";
import GigCardItem from "./item";

interface Props {
  gigRef?: dateItem_gig$key | null | undefined;
  totalCount?: number;
}

const GigCardDateItem = ({ gigRef, totalCount }: Props) => {
  const gig = useRelayFragment(
    graphql`
      fragment dateItem_gig on Gig {
        negotiable
        gigType
        startsAt
        actualStartsAt
        insertedAt
        endsAt
        timezone
      }
    `,
    gigRef ?? null
  );

  const gigCardDateTimeString = useGigCardDateTimeString(gig);

  if (totalCount && totalCount > 1) {
    return (
      <GigCardItem testID="date-item" iconName="calendar-days">
        <Text weight="bold">Multi-Shift &bull; </Text>
        <Text>{totalCount} shifts</Text>
      </GigCardItem>
    );
  }

  if (gig) {
    return (
      <GigCardItem testID="date-item" iconName="calendar-days">
        {gig?.gigType === "PROJECT"
          ? projectCardDateTimeString(
              gig?.startsAt,
              gig?.insertedAt,
              gig?.negotiable?.includes("STARTS_AT") ?? false
            )
          : gigCardDateTimeString}
      </GigCardItem>
    );
  }

  return null;
};

export default GigCardDateItem;
