import gradientColor from "gradient-color";
import memoize from "lodash/memoize";
import React, { PureComponent } from "react";
import { View } from "react-native";
import Card from "../../container/card";
import CardTitle from "../../container/card-title";
import StyledView from "../../container/styled-view";

import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../../style";
import { StyledText } from "../../text";
import TrackBar from "./track-bar";
import TrackStar from "./track-star";

type Props = StylesProps & {
  title?: string;
  points: number;
  starCount: number;
  barCount: number;
  message: string;
  style?: ViewStyleProp;
};

@withStyles(({ font }) => ({
  title: {
    fontSize: font.size.largest
  },
  steps: {
    flexDirection: "row",
    alignItems: "center"
  },
  message: {
    marginTop: 20
  }
}))
export default class CompletionProgress extends PureComponent<Props> {
  static defaultProps = {
    ...stylesStubs,
    starCount: 4,
    barCount: 12
  };

  render() {
    const { styles, style, title, message } = this.props;

    return (
      <Card style={[styles.container, style]}>
        {!!title && <CardTitle title={title} />}
        <StyledView color="transparent">
          <View style={styles.steps}>{this.createProgressSteps()}</View>
          {!!message && (
            <StyledText style={styles.message}>{message}</StyledText>
          )}
        </StyledView>
      </Card>
    );
  }

  createProgressSteps = () => {
    const { theme, barCount, starCount, points } = this.props;
    const length = starCount + barCount;
    const barsPerSegment = Math.floor(barCount / (starCount - 1));

    const colorArray = createGradient(
      [theme.color.blue, theme.color.spiceBlue],
      length
    );

    let currentStar = 0;
    let currentBar = 0;

    return Array.from({ length }, (v, idx) => {
      const isStar = idx % (barsPerSegment + 1) === 0;
      const isBeforeStar = (idx + 1) % (barsPerSegment + 1) === 0;

      if (isStar) {
        currentStar += 1;
        return (
          <TrackStar
            key={`star:${currentStar}`}
            isActive={points >= currentBar}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            color={colorArray[idx]!}
          />
        );
      }

      currentBar += 1;
      return (
        <TrackBar
          key={`bar:${currentBar}`}
          isActive={points >= currentBar}
          isBeforeStar={isBeforeStar}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          color={colorArray[idx]!}
        />
      );
    });
  };
}

const createGradient = memoize(
  (color: string[], length: number) => {
    return gradientColor(color, length);
  },
  (color: string[], length: number) => `${length}.${color.join(".")}`
);
