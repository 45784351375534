import React from "react";
import { Image, View } from "react-native";
import { GrayscaleContainer, type ImageStyleProp, useStyles } from "../style";

interface Props {
  style?: ImageStyleProp;
  uri?: string | null | undefined;
  size?: number;
  grayscale?: boolean;
}

const Thumbnail = ({ style, uri, size = 28, grayscale = false }: Props) => {
  const { styles, theme } = useStyles(({ color }) => ({
    thumb: {},
    placeholder: {
      borderColor: color.neutralMedium,
      borderWidth: 1
    }
  }));

  const containerStyles: ImageStyleProp = [
    (uri ? styles.thumb : styles.placeholder) as ImageStyleProp,
    theme.shorthand.circle(size),
    style
  ];

  return (
    <GrayscaleContainer enabled={grayscale}>
      {uri ? (
        <Image style={containerStyles} source={{ uri }} />
      ) : (
        <View style={containerStyles} />
      )}
    </GrayscaleContainer>
  );
};

export default Thumbnail;
