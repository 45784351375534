/**
 * @generated SignedSource<<d2673694feb5c791b2a87af7c5734e49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GeolocationProvider_worker$data = {
  readonly currentEngagement: {
    readonly gig: {
      readonly currentState: {
        readonly name: GigStateName;
      };
    };
  } | null | undefined;
  readonly currentLocation: {
    readonly " $fragmentSpreads": FragmentRefs<"GeolocationProvider_workerLocation">;
  } | null | undefined;
  readonly id: string;
  readonly typeName: "Worker";
  readonly uuid: string;
  readonly " $fragmentType": "GeolocationProvider_worker";
};
export type GeolocationProvider_worker$key = {
  readonly " $data"?: GeolocationProvider_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeolocationProvider_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeArea"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeolocationProvider_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": "typeName",
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Engagement",
      "kind": "LinkedField",
      "name": "currentEngagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigState",
              "kind": "LinkedField",
              "name": "currentState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerLocation",
      "kind": "LinkedField",
      "name": "currentLocation",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "includeArea",
              "variableName": "includeArea"
            }
          ],
          "kind": "FragmentSpread",
          "name": "GeolocationProvider_workerLocation"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "2e4b63765b52238a4fc2d967fa6d79fe";

export default node;
