import React, { Component } from "react";
import { Modal, Pressable, TouchableOpacity, View } from "react-native";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import { StyledText } from "../text";

type Props = StylesProps & {
  actionSheetRef: (arg0: CustomActionSheet | null) => void;
  options: string[];
  onPress: (arg0: number) => void;
  cancelButtonIndex: number;
  destructiveButtonIndex?: number;
  testID?: string;
  accessible?: boolean;
};

interface State {
  visible: boolean;
}

@withStyles(({ color }) => ({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: color.withOpacity(color.black, 0.5)
  },
  buttonsContainer: {
    flex: 1,
    justifyContent: "flex-end",
    padding: 10
  },
  button: {
    width: "100%",
    paddingVertical: 15,
    borderColor: color.neutralLight,
    borderWidth: 0.5,
    backgroundColor: color.white,
    alignItems: "center"
  }
}))
export default class CustomActionSheet extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    actionSheetRef: () => null
  };

  state = {
    visible: false
  };

  componentDidMount() {
    this.props.actionSheetRef(this);
  }

  componentWillUnmount() {
    this.props.actionSheetRef(null);
  }

  show = () => {
    this.setState({ visible: true });
  };

  closeActionSheet = () => {
    this.setState({ visible: false });
  };

  buttonTestID = (text: string) =>
    `${text.toLowerCase().split(" ").join("-")}-button`;

  renderButton = (option: string, index: number) => {
    const { styles, theme, options, onPress, destructiveButtonIndex } =
      this.props;
    const isCancel = this.props.cancelButtonIndex === index;
    const borderTopRadius = index === 0 || isCancel ? 10 : 0;
    const borderBottomRadius =
      index === options.length - 2 || isCancel ? 10 : 0;
    return (
      <TouchableOpacity
        key={index}
        style={[
          styles.button,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: isCancel ? 10 : 0,
            borderTopLeftRadius: borderTopRadius,
            borderTopRightRadius: borderTopRadius,
            borderBottomLeftRadius: borderBottomRadius,
            borderBottomRightRadius: borderBottomRadius
          }
        ]}
        onPress={() => {
          onPress(index);
          this.closeActionSheet();
        }}
        testID={this.buttonTestID(option)}
      >
        <StyledText
          color={
            index === destructiveButtonIndex
              ? theme.color.red
              : theme.color.blueTint
          }
          bold={isCancel}
          fontSize={theme.font.size.extraLarge}
        >
          {option}
        </StyledText>
      </TouchableOpacity>
    );
  };

  renderOptions() {
    const { options, cancelButtonIndex } = this.props;
    return options
      .filter((_o, i) => i !== cancelButtonIndex)
      .map((option, index) => {
        return this.renderButton(option, index);
      });
  }

  render() {
    const { styles, options, cancelButtonIndex, testID, accessible } =
      this.props;
    const { visible } = this.state;
    return (
      <Modal
        visible={visible}
        animationType="none"
        transparent
        onRequestClose={this.closeActionSheet}
      >
        <Pressable
          onPress={this.closeActionSheet}
          testID={testID}
          accessible={accessible}
          style={styles.container}
        >
          <View style={styles.buttonsContainer}>
            {this.renderOptions()}
            {options[cancelButtonIndex] &&
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              this.renderButton(options[cancelButtonIndex]!, cancelButtonIndex)}
          </View>
        </Pressable>
      </Modal>
    );
  }
}
