import React from "react";
import { View } from "react-native";

import { FlexColumn, FlexRow } from "../flex";
import { StyledIcon } from "../icon";
import { useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  stepNumber: number;
  stepsLength: number;
  milestoneTitles: string[];
  filledMilestones?: number[];
}

const VerticalStepperHeader = ({
  filledMilestones = [],
  stepNumber,
  stepsLength,
  milestoneTitles
}: Props) => {
  const { styles } = useStyles(({ color, font, units }) => ({
    zIndex: { zIndex: 1 },
    circle: {
      width: units(6),
      height: units(6),
      borderRadius: units(3),
      alignItems: "center",
      justifyContent: "center"
    },
    circleText: {
      color: color.white,
      ...font.body("bold")
    },
    milestoneText: {
      color: color.blue,
      ...font.body("bold"),
      paddingHorizontal: units(2)
    },
    line: {
      zIndex: 0,
      height: units(12),
      width: units(2),
      marginHorizontal: units(2),
      marginVertical: -units(1)
    },
    inactive: {
      backgroundColor: color.neutralDark
    },
    active: {
      backgroundColor: color.blue
    }
  }));

  const renderMilestones = () => {
    const milestones = [];
    for (let i = 1; i <= stepsLength; i++) {
      milestones.push(
        <FlexRow key={i} alignItems="center" style={styles.zIndex}>
          <View
            style={[
              i <= stepNumber || filledMilestones.includes(i)
                ? styles.active
                : styles.inactive,
              styles.circle
            ]}
          >
            {i < stepNumber || filledMilestones.includes(i) ? (
              <StyledIcon
                name="check"
                color="white"
                variant="solid"
                size={14}
              />
            ) : (
              <KatanaText style={styles.circleText}>{i}</KatanaText>
            )}
          </View>
          <KatanaText style={styles.milestoneText}>
            {milestoneTitles[i - 1]}
          </KatanaText>
        </FlexRow>
      );
      if (i < stepsLength) {
        milestones.push(
          <View
            key={`${i}-line`}
            style={[
              i < stepNumber || filledMilestones.includes(i + 1)
                ? styles.active
                : styles.inactive,
              styles.line
            ]}
          />
        );
      }
    }
    return milestones;
  };
  return <FlexColumn>{renderMilestones()}</FlexColumn>;
};

export default VerticalStepperHeader;
