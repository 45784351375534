import { useStyles } from "@gigsmart/atorasu/style";
import React, { type ComponentProps } from "react";
import Svg, { Defs, G, Path } from "react-native-svg";

import { View } from "@gigsmart/atorasu";

type Props = ComponentProps<typeof Svg>;

export default function SetYourselfApartSvg({ style, ...props }: Props) {
  const styles = useStyles(() => ({
    container: {
      aspectRatio: 237 / 71
    }
  }));
  return (
    <View style={[styles.container, style]}>
      <Svg viewBox="0 0 236.266 70.8" {...props}>
        <Defs />
        <G id="prefix__Layer_2" data-name="Layer 2">
          <G id="prefix__TEXT">
            <Path
              fill="#fff"
              d="M220.162 50.227l.061-.083.043-.06-.043.06-.06.083zM219.875 50.596l.04-.048.039-.05-.04.05zM220.365 49.939l.03-.046c.021-.031.042-.062.061-.093l-.06.093zM218.96 51.547q.214-.192.42-.401c-.19.193-.394.36-.595.532a1.663 1.663 0 00.175-.13zM219.72 50.778a5.52 5.52 0 00.065-.076l.041-.048-.04.048-.066.076zM220.576 49.608l.036-.063.032-.054-.032.054zM221.648 46.102v-.027.024zM215.08 61.915a9.629 9.629 0 003.167-4.223 9.63 9.63 0 01-3.168 4.223zM221.252 48.156l-.028.079.028-.079.025-.073.033-.104-.033.104z"
            />
            <Path
              fill="#eeeeef"
              d="M213.766 27.395a20.883 20.883 0 00-14.052 36.35 14.268 14.268 0 015.354-6.91V41.067c-.024-4.997 3.297-9.064 7.403-9.064h3.11c4.05 0 7.341 3.958 7.402 8.87h.002v2.53h-.001v12.629a2.268 2.268 0 01-.119.722 14.276 14.276 0 015.345 6.637 20.89 20.89 0 00-14.444-35.996z"
            />
            <Path
              fill="#fff"
              d="M210.21 57.332v-4.923a9.183 9.183 0 01-.001 0v6.227a8.551 8.551 0 00.993 1.52 8.549 8.549 0 01-.993-1.52zM206.392 45.77a7.906 7.906 0 003.261 6.243 7.907 7.907 0 01-3.26-6.244z"
            />
            <Path
              d="M222.985 41.004v-.131h-.002v.001l.002.13z"
              fill="#4c0000"
            />
            <Path
              fill="#003b5c"
              d="M213.766 25.8a22.5 22.5 0 1022.5 22.5 22.5 22.5 0 00-22.5-22.5zm14.444 37.59a14.276 14.276 0 00-5.345-6.636 13.969 13.969 0 00-2.723-1.41q-.482-.187-.979-.34c-.219-.068-.434-.142-.657-.2v1.981a7.8 7.8 0 01-.259.907 9.629 9.629 0 01-3.168 4.223c-.336.274-.683.542-1.053.798-.178-.123-.338-.258-.509-.385q-.531-.397-1.012-.821a11.387 11.387 0 01-1.303-1.35 8.551 8.551 0 01-.993-1.521v2.07a4.994 4.994 0 00.631 2.429 4.993 4.993 0 01.632 2.429v3.604a20.762 20.762 0 01-5.636-1.432l-.009-.016a5.345 5.345 0 01-.36-.77 6.29 6.29 0 01-.4-2.253v-7.862a14.268 14.268 0 00-5.353 6.91 20.9 20.9 0 1128.496-.354z"
            />
            <Path
              fill="#8a8c8e"
              d="M218.506 51.93a1.11 1.11 0 01.279-.252c.201-.172.405-.34.595-.532l.001-.002.031-.032v-.001l.107-.11.047-.052.017-.018.07-.078.055-.062.012-.013.065-.076.041-.048.017-.02.032-.038.04-.048.039-.05.02-.025.066-.083.03-.04.03-.04.062-.083.061-.083.043-.06.015-.022.06-.088.024-.035.03-.046.061-.093.05-.08.044-.07.017-.028.009-.014.036-.063.032-.054.03-.05.039-.071.062-.114.017-.032.013-.025c.043-.082.08-.168.12-.252s.083-.166.12-.252v-.002c.022-.048.041-.097.061-.146l.022-.053.052-.136.025-.068.02-.055.027-.079.025-.073.033-.104.01-.03.023-.077.038-.135.022-.08.024-.09.011-.046.02-.082.03-.14.008-.032.01-.05.027-.143.015-.081.024-.148.01-.08.006-.039.015-.115.008-.074c.007-.054.012-.109.017-.164l.005-.062V46.3a6.45 6.45 0 00.013-.168l.002-.029v-.027c.008-.14.013-.283.013-.427v-4.58h-15.27v4.58c0 .04 0 .08.002.121a7.907 7.907 0 003.26 6.244c.183.14.37.273.556.396v6.227a8.549 8.549 0 00.993 1.52 11.387 11.387 0 001.303 1.35q.48.426 1.012.822c.171.127.33.262.509.385.37-.256.717-.524 1.053-.798a9.63 9.63 0 003.168-4.223 7.8 7.8 0 00.259-.907V51.93z"
            />
            <Path
              fill="#000"
              d="M222.984 43.403v-2.4-.13c-.062-4.912-3.352-8.87-7.402-8.87h-3.111c-4.106 0-7.427 4.067-7.403 9.064V64.697a6.29 6.29 0 00.4 2.254 5.345 5.345 0 00.36.77l.008.015a20.762 20.762 0 005.636 1.432v-3.604a4.993 4.993 0 00-.632-2.43 4.994 4.994 0 01-.631-2.428v-8.297a9.261 9.261 0 01-.556-.396 7.906 7.906 0 01-3.26-6.244l-.001-.121v-4.58h15.269v4.58c0 .144-.005.286-.013.427v.027l-.002.03a6.278 6.278 0 01-.012.167v.012c-.003.02-.004.042-.006.062-.005.055-.01.11-.017.164l-.008.074c-.004.039-.01.077-.015.115l-.005.04-.011.079-.025.148-.014.081-.027.144-.01.05-.007.032-.031.14-.02.08-.011.046-.024.092-.022.08-.038.134-.024.077-.01.03c-.01.035-.02.07-.032.104l-.025.073-.028.079-.019.055-.025.067a6.218 6.218 0 01-.052.136l-.022.054-.06.146-.001.002c-.037.086-.08.168-.12.252s-.078.17-.12.252l-.013.024-.017.033-.062.113c-.013.024-.026.049-.04.072l-.029.05-.032.055-.037.062-.008.014-.017.028-.044.07-.05.08-.06.093-.031.046-.023.035-.06.088-.017.022-.042.06-.06.083-.063.083-.03.04-.03.04a6.37 6.37 0 01-.066.083l-.02.025-.04.05-.04.048-.031.038-.017.02-.04.048-.066.076-.012.013-.055.062-.07.078-.017.018-.047.051-.106.111-.032.033-.002.002q-.205.209-.42.401a1.663 1.663 0 01-.174.131 1.11 1.11 0 00-.28.251v2.876c.224.057.44.131.658.199q.497.153.979.34a13.969 13.969 0 012.723 1.41 2.268 2.268 0 00.12-.722z"
            />
            <Path
              fill="#eeeeef"
              d="M158.647 27.395A20.882 20.882 0 00143.336 62.5l5.343-1.638 3.283-1.008 2.666-.817v-4.435l-3.098-3.135v-3.009c-.904.528-2.486-3.226-1.647-4.435a1.273 1.273 0 01.811-.61.388.388 0 00-.106-.022 15.19 15.19 0 01-.541-2.228h-.002s-1.146-5.826 2.755-6.813c.09-.051.18-.1.271-.147.12-.182.299-.541.343-.601a5.848 5.848 0 011.578-1.436c2.34-1.47 5.254-1.221 7.818-.59 3.881.956 5.311 4.664 5.051 8.302a13.193 13.193 0 01-.67 3.513.39.39 0 00-.106.022 1.273 1.273 0 01.81.61c.84 1.21-.742 4.963-1.646 4.435v3.01l-3.098 3.134v4.435l1.367.377.003.001 1.383.392 3.291.935 5.057 1.435a20.881 20.881 0 00-15.605-34.782z"
            />
            <Path
              fill="#8a8c8e"
              d="M158.88 60.967h.482l.003.004 3.786-1.934v-4.435l3.098-3.135v-3.009c.904.528 2.486-3.226 1.647-4.435a1.273 1.273 0 00-.811-.61c-.246.082-.836.488-.836 2.502h-.754s.175-5.949-2.344-6.497a19.713 19.713 0 00-8.522 0c-2.52.548-2.345 6.497-2.345 6.497h-.754c0-2.014-.59-2.42-.836-2.502a1.273 1.273 0 00-.81.61c-.84 1.21.742 4.963 1.646 4.435v3.01l3.098 3.134v4.435l3.779 1.93z"
            />
            <Path
              fill="#fff"
              d="M163.151 59.037l-3.786 1.934.426.663-.471.408.681 4.767-1.044 2.575 4.194-10.347zM158.478 62.075l-.509-.441.428-.667h.01l-3.779-1.93 3.15 7.77.7-4.732z"
            />
            <Path
              fill="#000"
              d="M160 66.809l-.68-4.767.47-.408-.425-.663-.003-.004h-.965l-.428.667.51.441-.701 4.732-.003.018 1.049 2.566c.045 0 .089-.006.133-.007z"
            />
            <Path
              fill="#003b5c"
              d="M158.647 25.8a22.5 22.5 0 1022.5 22.5 22.5 22.5 0 00-22.5-22.5zm15.605 36.377l-5.057-1.435-3.291-.935-1.383-.392-.003-.001-1.367-.377-4.194 10.347c-.044.001-.088.006-.133.007l-1.05-2.566.004-.018-3.15-7.77-2.666.817-3.283 1.008-5.343 1.638a20.906 20.906 0 1130.916-.323z"
            />
            <Path
              fill="#000"
              d="M151.53 45.915h.754s-.175-5.949 2.345-6.498a19.713 19.713 0 018.522 0c2.519.549 2.344 6.498 2.344 6.498h.754c0-2.014.59-2.42.836-2.502a.39.39 0 01.106-.022 13.193 13.193 0 00.67-3.513c.26-3.638-1.17-7.346-5.051-8.302-2.564-.631-5.479-.88-7.818.59a5.848 5.848 0 00-1.578 1.436c-.044.06-.223.419-.343.601a7.115 7.115 0 00-.271.147c-3.9.987-2.755 6.814-2.755 6.814l.002-.002a15.19 15.19 0 00.54 2.229.388.388 0 01.107.022c.246.082.836.488.836 2.502z"
            />
            <Path
              fill="#fff"
              d="M23.338 60.883l.002.003-.002-.003zM18.604 58.952l3.778 1.931-3.778-1.931z"
            />
            <Path
              fill="#eeeeef"
              d="M22.5 27.395A20.88 20.88 0 007.155 62.464l11.168-3.426a.985.985 0 00.28-.683v-3.43a.99.99 0 00-.285-.696l-2.527-2.556a.99.99 0 01-.286-.696v-1.815a.983.983 0 00-.47-.833c-.875-.555-1.883-3.373-1.177-4.39a1.272 1.272 0 01.811-.61.388.388 0 00-.106-.022 15.222 15.222 0 01-.541-2.229l-.002.002s.78-3.978 2.145-5.343c.09-.051 1.274-1.32 1.365-1.366a15.695 15.695 0 011.366-1.365c.505-.506 2.126.38 2.73 0 2.34-1.47 4.187 1.365 6.828 1.365 1.365 0 1.365 1.366 3.382 5.423a13.194 13.194 0 01-.67 3.513.388.388 0 00-.106.022 1.272 1.272 0 01.81.61c.707 1.017-.3 3.835-1.176 4.39a.983.983 0 00-.47.833v1.815a.99.99 0 01-.285.696l-2.528 2.556a.99.99 0 00-.285.696v3.274a.99.99 0 00.726.954l-.726-.2.726.2.641.177h.005l.155.044 1.24.342-.014.007 2.066.587 6.246 1.773A20.883 20.883 0 0022.5 27.395zm5.993 31.935l.005.001zm1.386.393l.013-.007-1.239-.342 3.1.856a.985.985 0 01.192.08z"
            />
            <Path
              fill="#8a8c8e"
              d="M18.604 58.952l3.778 1.93h.956l.002.004 3.785-1.934.727.2a.99.99 0 01-.727-.953v-3.274a.99.99 0 01.286-.696l2.527-2.556a.99.99 0 00.286-.696v-1.815a.983.983 0 01.47-.833c.876-.555 1.883-3.373 1.177-4.39a1.272 1.272 0 00-.811-.61c-.246.082-.835.487-.835 2.502h-.755s.175-5.949-2.345-6.498a19.713 19.713 0 00-8.521 0c-2.52.549-2.345 6.498-2.345 6.498h-.754c0-2.015-.59-2.42-.836-2.502a1.272 1.272 0 00-.81.61c-.707 1.017.3 3.835 1.176 4.39a.983.983 0 01.47.833v1.815a.99.99 0 00.286.696l2.527 2.556a.99.99 0 01.286.696v3.43a.985.985 0 01-.28.683l.28-.086zM28.653 59.374l1.24.342-.014.007 2.066.587a.985.985 0 00-.192-.08z"
            />
            <Path
              fill="none"
              stroke="#003b5c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0}
              d="M28.498 59.331l-.005-.001"
            />
            <Path
              fill="#003b5c"
              d="M22.5 25.8A22.5 22.5 0 1045 48.3a22.5 22.5 0 00-22.5-22.5zm15.69 36.283l-6.245-1.773-2.066-.587.013-.007-1.239-.342-.155-.043-.005-.001-.64-.177-.728-.2-3.785 1.933-.002-.003h-.956l-3.778-1.93-.28.085-11.169 3.426a20.904 20.904 0 1131.036-.381z"
            />
            <Path
              fill="#000"
              d="M15.505 45.83h.754s-.175-5.948 2.345-6.497a19.713 19.713 0 018.521 0c2.52.549 2.345 6.498 2.345 6.498h.755c0-2.015.589-2.42.835-2.502a.388.388 0 01.107-.022 13.194 13.194 0 00.67-3.513c-2.018-4.058-2.018-5.423-3.383-5.423-2.641 0-4.488-2.835-6.828-1.365-.604.38-2.225-.506-2.73 0a15.695 15.695 0 00-1.366 1.365c-.091.047-1.276 1.315-1.365 1.365-1.366 1.366-2.145 5.344-2.145 5.344l.001-.002a15.222 15.222 0 00.541 2.229.388.388 0 01.107.022c.246.082.836.487.836 2.502z"
            />
            <Path
              d="M105.566 64.818a32.14 32.14 0 01-14.98 3.688l-.09-.002a32.191 32.191 0 01-20.05-7.043c.001-.018.003-.035.003-.053a32.9 32.9 0 1151.831-17.285l2.338.974a35.39 35.39 0 10-17.414 21.555 22.476 22.476 0 01-1.638-1.834z"
              fill="#24925c"
            />
            <Path
              fill="#fff"
              d="M86.485 50.162a16.85 16.85 0 004.011 3.744 16.852 16.852 0 01-4.011-3.744zM92.37 22.307s-.044.05-.128.136c.084-.087.127-.136.127-.136zM96.42 39.646a11.805 11.805 0 002.225-2.308 11.812 11.812 0 01-2.226 2.308zM90.624 53.812a14.993 14.993 0 004.83-5.154 15 15 0 01-4.83 5.154z"
            />
            <Path
              fill="#fff"
              d="M90.574 2.509a32.885 32.885 0 00-20.125 58.899A18.81 18.81 0 0183.005 43.94v-.014l.002-.001c.218-.078.44-.15.662-.218l.066-.023c-2.108.006-7.542-.518-8.576-5.965-1.29-6.79 3.433-12.12 3.433-12.12v-2.033c0-6.202 4.112-11.23 9.185-11.23h5.29a3.297 3.297 0 012.895 2.033c4.14 0 6.438 3.97 6.438 11.23 0 0 4.722 5.33 3.433 12.12-1.034 5.447-6.467 5.97-8.575 5.965.023.007.044.017.068.024.221.069.442.14.66.217h.001v.015a18.773 18.773 0 013.787 1.839l11.164-4.65a2.79 2.79 0 012.155 0l7.188 2.994A32.87 32.87 0 0090.574 2.51z"
            />
            <Path
              fill="#003b5c"
              d="M83.735 43.684l-.066.023.07-.023zM97.326 43.708c-.024-.007-.046-.017-.069-.024h-.004l.073.024z"
            />
            <Path
              d="M96.42 43.45v-3.804a11.812 11.812 0 002.225-2.308c.16-.221.306-.456.452-.692.083-.136.175-.262.252-.403a10.022 10.022 0 001.243-4.904V25.6a3.583 3.583 0 01-2.375-1.024 7.004 7.004 0 01-.688-.71 10.027 10.027 0 01-.636-.841q-.153-.224-.299-.46a19.3 19.3 0 01-.816-1.486l-.012-.026a23.278 23.278 0 01-.465-1.013 9.324 9.324 0 01-2.932 3.914v-1.646s-.043.05-.127.136a11.69 11.69 0 01-1.746 1.452 9.092 9.092 0 01-5.048 1.704h-5.047v5.74a10.12 10.12 0 00.727 3.82 10.015 10.015 0 00.515 1.084 10.718 10.718 0 001.33 1.885 12.121 12.121 0 001.6 1.517v6.422a10.328 10.328 0 00.981 2.623 11.582 11.582 0 00.931 1.472 16.852 16.852 0 004.011 3.743c.044-.03.084-.063.128-.093a15 15 0 004.83-5.154 10.31 10.31 0 00.965-2.591V43.45z"
              fill="#af8a69"
            />
            <Path
              fill="#000"
              d="M84.573 43.649v-4.004a12.121 12.121 0 01-1.6-1.517 10.718 10.718 0 01-1.33-1.885 10.015 10.015 0 01-.515-1.083 10.12 10.12 0 01-.727-3.82v-5.741h5.047a9.092 9.092 0 005.048-1.704 11.69 11.69 0 001.746-1.452c.084-.087.127-.136.127-.136v1.646a9.324 9.324 0 002.932-3.914c.146.34.302.679.465 1.013l.013.027a19.234 19.234 0 00.815 1.485q.146.236.299.46a10.027 10.027 0 00.636.84 7.004 7.004 0 00.689.71 3.583 3.583 0 002.374 1.025v5.74a10.022 10.022 0 01-1.243 4.904c-.077.14-.17.267-.252.403-.146.236-.291.47-.452.692a11.805 11.805 0 01-2.226 2.308v4.003s.317.033.834.035h.005c2.108.006 7.541-.518 8.575-5.965 1.29-6.79-3.433-12.12-3.433-12.12 0-7.26-2.298-11.23-6.438-11.23a3.297 3.297 0 00-2.894-2.033h-5.291c-5.073 0-9.185 5.028-9.185 11.23v2.033s-4.722 5.33-3.433 12.12c1.034 5.447 6.468 5.97 8.576 5.965h.005c.517-.002.833-.035.833-.035z"
            />
            <Path
              fill="#003b5c"
              d="M97.987 43.926l-.001-.001a18.68 18.68 0 00-.66-.217l-.073-.024c-.517-.002-.834-.035-.834-.035v2.418a10.31 10.31 0 01-.964 2.591 14.993 14.993 0 01-4.831 5.154c-.044.03-.084.063-.128.093a16.85 16.85 0 01-4.011-3.743 11.582 11.582 0 01-.93-1.472 10.328 10.328 0 01-.982-2.623v-2.418s-.317.033-.833.035l-.07.023c-.223.069-.445.14-.664.218v.015a18.81 18.81 0 00-12.557 17.468l-.002.053a32.191 32.191 0 0020.05 7.043l.088.002a32.14 32.14 0 0014.98-3.688 25.897 25.897 0 01-5.563-16.433 2.802 2.802 0 011.726-2.587l.046-.02a18.773 18.773 0 00-3.787-1.838z"
            />
            <Path
              fill="#fff"
              d="M122.226 49.825a.938.938 0 00-1.319 0l-8.763 8.764-4.093-4.093a.938.938 0 00-1.319 0l-1.318 1.32a.934.934 0 000 1.32l6.07 6.07a.938.938 0 001.32 0l10.74-10.741a.929.929 0 000-1.32z"
            />
            <Path
              d="M126.3 45.798l-1.682-.701-2.338-.974-7.188-2.994a2.79 2.79 0 00-2.154 0l-11.164 4.65-.046.019a2.802 2.802 0 00-1.726 2.587 25.897 25.897 0 005.564 16.433 22.476 22.476 0 001.638 1.834 18.288 18.288 0 005.728 3.934 2.816 2.816 0 002.154 0c5.002-2.083 12.936-9.282 12.936-22.201a2.805 2.805 0 00-1.722-2.587zm-2.755 6.667l-10.742 10.742a.938.938 0 01-1.318 0l-6.071-6.071a.934.934 0 010-1.32l1.318-1.32a.938.938 0 011.32 0l4.092 4.093 8.763-8.764a.938.938 0 011.319 0l1.319 1.32a.929.929 0 010 1.32z"
              fill="#043f93"
            />
          </G>
        </G>
      </Svg>
    </View>
  );
}
