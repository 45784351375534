import {
  Button,
  ContentArea,
  HighlightedStatement,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spacer,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { useHistory } from "@gigsmart/kaizoku";
import React, { useEffect } from "react";
import ApplySuccessImage from "./images/ApplySuccessImage";

interface Props {
  isProject?: boolean;
  onClose: () => void;
}

export const showApplySuccessModal = ({
  isProject
}: Omit<Props, "onClose"> = {}) =>
  showModal({
    eventContext: "Application Success Modal",
    variant: "full",
    useModalBody: false,
    children: (closeFn) => (
      <ApplySuccessModal isProject={isProject} onClose={closeFn} />
    )
  });

function ApplySuccessModal({ isProject, onClose }: Props) {
  const history = useHistory();
  const subtitle = isProject
    ? "The Requester will review your application and bid. If they are interested or have questions, they will reach out to you."
    : "The Requester will review your application. If interested, they will reach out to you directly through the app and send you an offer.";

  useEffect(() => {
    history.push(
      isProject
        ? "/projects?projectsTab=applied"
        : HourlyRateBids.select(
            "/shifts?shiftsTab=bidSubmitted",
            "/shifts?shiftsTab=applied"
          )
    );
  }, []);

  return (
    <>
      <ModalHeader />
      <ModalBody constraint="xsmall">
        <ContentArea
          fill
          alignItems="center"
          justifyContent="center"
          testID={isProject ? "project-apply-success" : "shift-apply-success"}
          gap="standard"
        >
          <ApplySuccessImage />
          <Text weight="bold" color="primary" align="center">
            Application Successfully Submitted!
          </Text>
          <Text align="center">{subtitle}</Text>
        </ContentArea>
      </ModalBody>
      <ModalFooter>
        {!isProject ? (
          <HighlightedStatement
            icon="file-lines"
            color="info"
            iconVariant="solid"
          >
            If you are no longer available to work the Shift, you can withdraw
            your application or reject the Requester's offer without being
            penalized.
          </HighlightedStatement>
        ) : (
          <Spacer />
        )}
        <Button testID="ok-button" label="OK" onPress={onClose} />
      </ModalFooter>
    </>
  );
}
