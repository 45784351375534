import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import { type CurrentDeviceShape, useCurrentDevice } from "./current-user";

export interface WithCurrentDeviceProps {
  device?: CurrentDeviceShape | null;
}

const withCurrentUser: HOCVoid = (
  WrappedComponent: ComponentType<WithCurrentDeviceProps>
) =>
  applyHOCProperties({
    WrappedComponent,
    displayName: "withUser",
    HigherOrderComponent: (props) => {
      const device = useCurrentDevice();
      return <WrappedComponent {...props} device={device} />;
    }
  });

export default withCurrentUser;
