/**
 * @generated SignedSource<<97a5bf9accb931a3d2aaaec68762bed8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type NegotiableGigField = "STARTS_AT" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type engagementEntry_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly gig: {
    readonly area: string | null | undefined;
    readonly category: {
      readonly displayName: string;
    } | null | undefined;
    readonly gigType: GigType | null | undefined;
    readonly name: string | null | undefined;
    readonly negotiable: ReadonlyArray<NegotiableGigField> | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
    readonly place: {
      readonly streetAddress: string | null | undefined;
    } | null | undefined;
    readonly placeId: string | null | undefined;
    readonly position: {
      readonly name: string;
    } | null | undefined;
    readonly skills: {
      readonly totalCount: number;
    } | null | undefined;
  };
  readonly insertedAt: string;
  readonly skill: {
    readonly name: string;
  } | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentType": "engagementEntry_engagement";
};
export type engagementEntry_engagement$key = {
  readonly " $data"?: engagementEntry_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"engagementEntry_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "engagementEntry_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Place",
          "kind": "LinkedField",
          "name": "place",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streetAddress",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "negotiable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigCategory",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigPosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 0
            }
          ],
          "concreteType": "GigSkillsConnection",
          "kind": "LinkedField",
          "name": "skills",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "skills(first:0)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "b9bde990b857629ee768ecffae77dcb0";

export default node;
