import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";

import { Spacer, Text } from "@gigsmart/atorasu";
import { DataRow, StyledView } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import { formatPaymentMethod } from "../helpers";
import EngagementRow from "./engagement-row";

import type { priorTransferEngagementRows_engagements$key } from "./__generated__/priorTransferEngagementRows_engagements.graphql";
import type { priorTransferRow_payout$data } from "./__generated__/priorTransferRow_payout.graphql";

type WorkerPayableAccount = ObjectPath<
  priorTransferRow_payout$data,
  ["providerDetails", "destination"]
>;

interface Props {
  payout: priorTransferEngagementRows_engagements$key;
  instant: boolean;
  destination?: WorkerPayableAccount;
  feeAmount: string;
}

export default ({
  payout: payoutFragmentRef,
  instant,
  destination,
  feeAmount
}: Props) => {
  const payout = useRelayFragment(
    graphql`
      fragment priorTransferEngagementRows_engagements on WorkerPayout
      @argumentDefinitions(priorTransfersQuery: { type: "String" }) {
        engagements(first: 8, query: $priorTransfersQuery) {
          edges {
            node {
              ...engagementRowWorker_engagement
              id
              endsAt
            }
          }
        }
      }
    `,
    payoutFragmentRef
  );

  const rowData = (payout?.engagements?.edges ?? [])
    ?.slice()
    .sort((edgeA, edgeB) => {
      const dateA = new Date(edgeA?.node?.endsAt ?? Date.now()).getTime();
      const dateB = new Date(edgeB?.node?.endsAt ?? Date.now()).getTime();
      return dateB - dateA;
    });
  return (
    <StyledView color="transparent" verticalPadding={0}>
      {rowData?.map((edge, index) => {
        if (!edge?.node) return null;
        return (
          <EngagementRow
            key={edge.node.id || `${index}`}
            engagement={edge.node}
            first={index === 0}
          />
        );
      })}
      {!!rowData?.length && (
        <>
          {instant && (
            <DataRow
              color="neutralDark"
              label="Rapid Transfer Fee"
              secondaryTitle="3% deducted from payout"
              data={<Text color="neutral">{feeAmount}</Text>}
            />
          )}
          <Spacer />
          <Text align="center" color="neutral" variant="note">
            Transferred to:{" "}
            {destination ? formatPaymentMethod(destination) : null}
          </Text>
        </>
      )}
    </StyledView>
  );
};
