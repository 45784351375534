/**
 * @generated SignedSource<<fa2e323bfb3ba5fac95d8233935c0a37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type content_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly currentState: {
      readonly name: GigStateName;
    };
    readonly " $fragmentSpreads": FragmentRefs<"WorkerApplyFooter_gigLike">;
  };
  readonly gigType: GigType;
  readonly id: string;
  readonly insertedAt: string;
  readonly " $fragmentSpreads": FragmentRefs<"HelpfulToDoList_engagement" | "ShiftInfoCollapsable_engagement" | "engagementDetailsHeader_engagement" | "infoTabs_engagement">;
  readonly " $fragmentType": "content_engagement";
};
export type content_engagement$key = {
  readonly " $data"?: content_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"content_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "content_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkerApplyFooter_gigLike"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigState",
          "kind": "LinkedField",
          "name": "currentState",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "infoTabs_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HelpfulToDoList_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftInfoCollapsable_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "engagementDetailsHeader_engagement"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "2ffd388e20e0ded555a7c6128dbc0773";

export default node;
