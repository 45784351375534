/**
 * @generated SignedSource<<a39083044d231db9e2584f72acfb2d3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClaimOrganizationSurface_DirectHireClaim$data = {
  readonly directHireDate: string | null | undefined;
  readonly directHireType: DirectHireType | null | undefined;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly lastName: string | null | undefined;
  readonly organization: {
    readonly name: string;
  } | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ClaimOrganizationSurface_DirectHireClaim";
};
export type ClaimOrganizationSurface_DirectHireClaim$key = {
  readonly " $data"?: ClaimOrganizationSurface_DirectHireClaim$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimOrganizationSurface_DirectHireClaim">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimOrganizationSurface_DirectHireClaim",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DirectHireClaim",
  "abstractKey": null
};

(node as any).hash = "fd6e75c970c617c2bf9ca73f2a91f6b9";

export default node;
