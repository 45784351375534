/**
 * @generated SignedSource<<2dde2412ce65f173127a0e930d69a11c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationStateTabs_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConversationStateTabsOrganization_viewer" | "ConversationStateTabsParticipating_viewer">;
  readonly " $fragmentType": "ConversationStateTabs_user";
};
export type ConversationStateTabs_user$key = {
  readonly " $data"?: ConversationStateTabs_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConversationStateTabs_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "activeQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "inactiveQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConversationStateTabs_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConversationStateTabsParticipating_viewer"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "activeQuery",
              "variableName": "activeQuery"
            },
            {
              "kind": "Variable",
              "name": "inactiveQuery",
              "variableName": "inactiveQuery"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ConversationStateTabsOrganization_viewer"
        }
      ],
      "type": "OrganizationRequester",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};

(node as any).hash = "a8065e07821307b64c5ea40b8ba2a5b9";

export default node;
