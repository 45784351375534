/**
 * @generated SignedSource<<521756415be6f35b41c47f7a21ef2989>>
 * @relayHash 451d0675fe288a4b358e04eded645cbf
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:o9DDxTSV8p55zeslBplhWSWoHkz6S2XO9u5wUZVuLIk

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationStatus = "CLOSED" | "OPEN" | "%future added value";
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type MessageListQuery$variables = {
  conversationId: string;
  isWorker?: boolean | null | undefined;
};
export type MessageListQuery$data = {
  readonly node: {
    readonly __typename?: string;
    readonly engagement?: {
      readonly capabilities: ReadonlyArray<{
        readonly status: EngagementCapabilityStatus;
        readonly type: EngagementCapabilityType;
      }>;
      readonly currentState: {
        readonly name: EngagementStateName;
      };
      readonly gigType: GigType;
    };
    readonly id?: string;
    readonly status?: ConversationStatus;
    readonly " $fragmentSpreads": FragmentRefs<"AdminConversationHeader_conversationLike" | "MessageListFooter_conversation" | "MessageList_ConversationLike" | "RequesterConversationHeader_conversationLike" | "WorkerConversationHeader_conversationLike">;
  } | null | undefined;
};
export type MessageListQuery = {
  response: MessageListQuery$data;
  variables: MessageListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "conversationId"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "isWorker"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "conversationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementCapability",
  "kind": "LinkedField",
  "name": "capabilities",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v11 = [
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capabilities",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v15 = [
  (v3/*: any*/)
],
v16 = {
  "kind": "InlineFragment",
  "selections": (v15/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v14/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = [
  (v8/*: any*/),
  (v3/*: any*/)
],
v19 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isFavorite",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v9/*: any*/),
    "concreteType": "WorkerGroupWorkersConnection",
    "kind": "LinkedField",
    "name": "groupAssociations",
    "plural": false,
    "selections": (v11/*: any*/),
    "storageKey": "groupAssociations(first:0)"
  }
],
v20 = [
  {
    "kind": "Literal",
    "name": "last",
    "value": 25
  }
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v13/*: any*/),
    (v17/*: any*/),
    {
      "condition": "isWorker",
      "kind": "Condition",
      "passingValue": false,
      "selections": (v19/*: any*/)
    }
  ],
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserMobile",
    "kind": "LinkedField",
    "name": "primaryMobile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "isWorker",
                    "variableName": "isWorker"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "RequesterConversationHeader_conversationLike"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkerConversationHeader_conversationLike"
              },
              {
                "condition": "isWorker",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdminConversationHeader_conversationLike"
                  }
                ]
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MessageListFooter_conversation"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MessageList_ConversationLike"
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Engagement",
                    "kind": "LinkedField",
                    "name": "engagement",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "EngagementConversation",
                "abstractKey": null
              }
            ],
            "type": "ConversationLike",
            "abstractKey": "__isConversationLike"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "type",
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationParticipant",
                "kind": "LinkedField",
                "name": "participant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": (v9/*: any*/),
                            "concreteType": "OrganizationsConnection",
                            "kind": "LinkedField",
                            "name": "organizations",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": "organizations(first:0)"
                          }
                        ],
                        "type": "Requester",
                        "abstractKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readUntil",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 30
                  }
                ],
                "concreteType": "ConversationParticipantsConnection",
                "kind": "LinkedField",
                "name": "participants",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationParticipantsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConversationParticipant",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v13/*: any*/),
                              (v17/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "primaryOrganization",
                                    "plural": false,
                                    "selections": (v18/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "Requester",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v19/*: any*/),
                                "type": "Worker",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "organization",
                                    "plural": false,
                                    "selections": (v18/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "OrganizationRequester",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "participants(first:30)"
              },
              (v12/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v20/*: any*/),
                "concreteType": "UserMessagesConnection",
                "kind": "LinkedField",
                "name": "messages",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMessagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserMessage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v13/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "context",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v8/*: any*/),
                                  (v21/*: any*/),
                                  (v22/*: any*/)
                                ],
                                "type": "Gig",
                                "abstractKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "body",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deletedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserMessageAttachment",
                            "kind": "LinkedField",
                            "name": "attachments",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "filename",
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mimeType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "messages(last:25)"
              },
              {
                "alias": null,
                "args": (v20/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MessageList_messages",
                "kind": "LinkedHandle",
                "name": "messages"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endsAt",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "conversationRule",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMessage",
                    "kind": "LinkedField",
                    "name": "currentMessage",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "ShiftGroupConversation",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logoUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "primaryContact",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 50
                      },
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
                      }
                    ],
                    "concreteType": "EngagementsConnection",
                    "kind": "LinkedField",
                    "name": "engagements",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Engagement",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contactNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Gig",
                                "kind": "LinkedField",
                                "name": "gig",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrganizationRequester",
                                    "kind": "LinkedField",
                                    "name": "primaryContact",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementCapability",
                                "kind": "LinkedField",
                                "name": "capabilities",
                                "plural": true,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "restrictedBy",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "message",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "expiresAt",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "engagements(first:50,query:\"WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]\")"
                  }
                ],
                "type": "OrganizationWorkerConversation",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Engagement",
                    "kind": "LinkedField",
                    "name": "engagement",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "gig",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Requester",
                            "kind": "LinkedField",
                            "name": "requester",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v13/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "EngagementConversation",
                "abstractKey": null
              },
              {
                "condition": "isWorker",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": "conversationParticipants",
                    "args": (v9/*: any*/),
                    "concreteType": "ConversationParticipantsConnection",
                    "kind": "LinkedField",
                    "name": "participants",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": "participants(first:0)"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationConversationSettings",
                            "kind": "LinkedField",
                            "name": "conversationSettings",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "allowConversationStatusUpdate",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "primaryContact",
                            "plural": false,
                            "selections": (v24/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v25/*: any*/)
                    ],
                    "type": "OrganizationWorkerConversation",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "engagement",
                        "plural": false,
                        "selections": [
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Requester",
                                "kind": "LinkedField",
                                "name": "requester",
                                "plural": false,
                                "selections": (v24/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "EngagementConversation",
                    "abstractKey": null
                  }
                ]
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNode"
              }
            ],
            "type": "ConversationLike",
            "abstractKey": "__isConversationLike"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:o9DDxTSV8p55zeslBplhWSWoHkz6S2XO9u5wUZVuLIk",
    "metadata": {},
    "name": "MessageListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f07b62d4886517e7b355dd39950c2032";

export default node;
