import * as Immutable from "immutable";
import compact from "lodash/compact";

import type Enum from "./enum";
import { asEnum } from "./enum";
import { FunctionDefinition } from "./functionDefinition";
import {
  type OperationsObject,
  parseOperations,
  transformValue
} from "./operation";
export type BasicSortDirection = "ASC" | "DESC";
export type SortDirection =
  | BasicSortDirection
  | "ASC NULLS FIRST"
  | "ASC NULLS LAST"
  | "DESC NULLS FIRST"
  | "DESC NULLS LAST";

type OrderBy = Immutable.OrderedMap<
  string,
  | [undefined, undefined, SortDirection]
  | ["PRIORITY", Enum[], BasicSortDirection]
  | [
      FunctionDefinition<"GEO_DISTANCE", [Enum, number, number]>,
      undefined,
      BasicSortDirection
    ]
>;
class Comb {
  constructor(readonly parts: ReadonlyArray<string | Comb>) {}

  toString(): string {
    return this.parts.reduce<string>((acc, part, i) => {
      if (i === 0) return part.toString();
      if (typeof part === "string") return `${acc} AND ${part}`;
      return `${acc} ${part.token} ${part.toString()}`;
    }, "");
  }

  hasSingleValue(): boolean {
    if (!this.parts.length) return true;
    return (
      this.parts.length === 1 &&
      // biome-ignore lint/style/noNonNullAssertion: use null assertion
      (typeof this.parts[0] === "string" || this.parts[0]!.hasSingleValue())
    );
  }

  nestsAnOr(): boolean {
    return this.parts.some(
      (part) =>
        part instanceof Comb && (part instanceof OrComb || part.nestsAnOr())
    );
  }

  token = "";
}

class AndComb extends Comb {
  constructor(readonly parts: ReadonlyArray<string | Comb>) {
    super(parts);
  }

  toString(): string {
    return this.nestsAnOr() ? `(${super.toString()})` : super.toString();
  }

  token = "AND";
}

class OrComb extends Comb {
  constructor(readonly parts: readonly Comb[]) {
    super(parts);
  }

  toString(): string {
    return this.hasSingleValue() ? super.toString() : `(${super.toString()})`;
  }

  token = "OR";
}

export class Query {
  constructor(
    readonly queryParts: Comb[] = [],
    readonly orderByRules: OrderBy = Immutable.OrderedMap()
  ) {}

  private replaceLastComb(poppedFn: (c?: Comb) => Comb) {
    const ary = [...this.queryParts];
    const last = ary.pop();
    const replaced = poppedFn(last);
    if (!replaced.parts.length) return this;
    return new Query([...ary, replaced], this.orderByRules);
  }

  private appendComb(comb: Comb) {
    return new Query([...this.queryParts, comb], this.orderByRules);
  }

  private combineCombs(query: Query) {
    return new Query(
      [...this.queryParts, ...query.queryParts],
      this.orderByRules
    );
  }

  isAllAnds() {
    return this.queryParts.every((part) => part instanceof AndComb);
  }

  hasOrs() {
    return this.queryParts.some((part) => part instanceof OrComb);
  }

  and<Q extends OperationsObject | Query>(where?: Q): Query {
    if (where instanceof Query && where.isAllAnds() && this.isAllAnds()) {
      return this.combineCombs(where as any);
    }
    if (where instanceof Query) {
      return this.appendComb(new AndComb(where.queryParts));
    }
    if (this.isAllAnds()) {
      return this.replaceLastComb((comb) => {
        return new AndComb([...(comb?.parts ?? []), ...parseOperations(where)]);
      });
    }
    return this.appendComb(new AndComb([...parseOperations(where)]));
  }

  or<Q extends OperationsObject | Query>(where?: Q): Query {
    if (where instanceof Query) {
      return this.appendComb(new OrComb(where.queryParts));
    }
    return this.appendComb(new OrComb([new AndComb(parseOperations(where))]));
  }

  orderBy(field: string, direction: SortDirection): Query {
    return new Query(
      this.queryParts,
      this.orderByRules.set(field, [undefined, undefined, direction])
    );
  }

  orderByGeoDistance(
    field: string,
    lat: number,
    lng: number,
    direction: "ASC" | "DESC" = "ASC"
  ): Query {
    return new Query(
      this.queryParts,
      this.orderByRules.set(field, [
        new FunctionDefinition("GEO_DISTANCE", [asEnum(field), lat, lng]),
        undefined,
        direction
      ])
    );
  }

  orderByPriority(
    field: string,
    enums: Enum[],
    direction: "ASC" | "DESC" = "ASC"
  ): Query {
    return new Query(
      this.queryParts,
      this.orderByRules.set(field, ["PRIORITY", enums, direction])
    );
  }

  private whereString(combs: Comb[] = this.queryParts): string | undefined {
    const str = new Comb(combs).toString();
    return str.length ? `WHERE ${str}` : undefined;
  }

  private orderByString(): string | undefined {
    if (!this.orderByRules.size) return;
    return `ORDER BY ${compact(
      this.orderByRules.toArray().map(([key, rule]) => {
        if (!rule) return null;
        const [fn, args, direction] = rule;
        if (fn === "PRIORITY") {
          return `${key} PRIORITY ${transformValue(args)} ${direction}`;
        }
        if (fn instanceof FunctionDefinition) {
          return `${transformValue(fn)} ${direction}`;
        }
        return `${key} ${direction}`;
      })
    ).join(", ")}`;
  }

  toString() {
    return [this.whereString(), this.orderByString()]
      .filter((s) => !!s)
      .join(" ");
  }
}

// Build a query with a where clause
export const where = (...args: Parameters<Query["and"]>) =>
  new Query().and(...args);

// Build a query with an orderBy clause
export const orderBy = (...args: Parameters<Query["orderBy"]>) =>
  new Query().orderBy(...args);

// Build a query with an orderBy clause
export const orderByPriority = (
  ...args: Parameters<Query["orderByPriority"]>
) => new Query().orderByPriority(...args);

// Build a query with an orderBy GeoDIstance clause
export const orderByGeoDistance = (
  ...args: Parameters<Query["orderByGeoDistance"]>
) => new Query().orderByGeoDistance(...args);
