import type { AppScreenProps } from "@gigsmart/kaizoku";
import type { TabConfig } from "@gigsmart/katana";
import WorkerEngagementHistoryList from "@gigsmart/seibutsu/engagement/WorkerEngagementHistoryList";
import TabScreen from "@gigsmart/seibutsu/layouts/TabScreen";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../navigation/types";

export default function HistoryScreen({
  navigation,
  route
}: AppScreenProps<WorkerParamList, "History">) {
  const activeTab = route.params.tab ?? "completed";
  const handleTabChange = (tab: string) =>
    tab !== activeTab &&
    navigation.setParams({
      tab: tab as "completed" | "not-hired" | "canceled"
    });

  const tabs = useMemo<Array<TabConfig<string>>>(
    () => [
      { key: "completed", label: "COMPLETED" },
      { key: "not-hired", label: "NOT HIRED" },
      { key: "canceled", label: "CANCELED" }
    ],
    []
  );
  return (
    <TabScreen<string, TabConfig<string>>
      tabItemsMaxWidth={240}
      testID="history-screen"
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={handleTabChange}
      renderTab={(tab) => {
        switch (tab.key) {
          case "completed":
          case "not-hired":
          case "canceled":
            return <WorkerEngagementHistoryList variant={tab.key} />;
          default:
            return null;
        }
      }}
    />
  );
}
