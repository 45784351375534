import { ProjectGigsPayInApp } from "@gigsmart/feature-flags";
import type { Questions } from "@gigsmart/seibutsu/questionnaire/Questionnaire";
import { useMemo } from "react";

export default function useGigCompletionQuestions(): Questions {
  return useMemo(
    () => ({
      PROJECT_COMPLETE: {
        testID: "is-project-complete",
        title: "Is the Project Gig complete?",
        type: "radio",
        name: "projectComplete",
        buttons: [
          {
            title: "Yes, this Project Gig is complete",
            value: "yes",
            testID: "project-complete",
            eventTargetName: "Project Is Complete Radio"
          },
          {
            title: "No, this Project Gig is not complete",
            value: "no",
            testID: "project-incomplete",
            eventTargetName: "Project Is Incomplete Radio"
          }
        ],
        nextQuestion: {
          yes: "BEEN_PAID",
          no: "NOT_COMPLETE"
        }
      },
      NOT_COMPLETE: {
        type: "submit",
        label: "Continue"
      },
      BEEN_PAID: {
        title: "Have you been paid?",
        type: "radio",
        name: "beenPaid",
        buttons: [
          {
            title: "Yes, I've been paid",
            value: "yes",
            testID: "paid",
            eventTargetName: "Worker Was Paid Radio"
          },
          {
            title: "No, I'm awaiting payment",
            value: "no",
            testID: "unpaid",
            eventTargetName: "Worker Was Not Paid Radio"
          }
        ],
        nextQuestion: {
          yes: "PAYMENT_TYPE",
          no: ProjectGigsPayInApp.isEnabled()
            ? "EXPECTED_PAYMENT"
            : "EXPECTED_PAYMENT_TYPE"
        }
      },
      PAYMENT_TYPE: {
        title: "How were you paid?",
        type: "radio",
        name: "paymentType",
        buttons: [
          {
            title: "Cash",
            value: "CASH",
            testID: "cash",
            eventTargetName: "Cash Payment Type Radio"
          },
          {
            title: "Check",
            value: "CHECK",
            testID: "check",
            eventTargetName: "Check Payment Type Radio"
          },
          {
            title: "3rd Party Digital Transfer (Venmo, Cash App, Etc.)",
            value: "DIGITAL_TRANSFER",
            testID: "digital-transfer",
            eventTargetName: "Digital Transfer Payment Type Radio"
          },
          {
            title: "Other",
            value: "NO_PREFERENCE",
            testID: "other",
            eventTargetName: "Other Payment Type Radio"
          }
        ],
        nextQuestion: {
          CASH: "MARK_COMPLETE",
          CHECK: "MARK_COMPLETE",
          DIGITAL_TRANSFER: "MARK_COMPLETE",
          NO_PREFERENCE: "MARK_COMPLETE"
        }
      },
      EXPECTED_PAYMENT: {
        title: "How are you expecting to be paid?",
        type: "radio",
        name: "expectedPayment",
        buttons: [
          {
            title: "Through the app",
            value: "IN_APP",
            testID: "in-app",
            eventTargetName: "Expecting In App Payment Radio"
          },
          {
            title: "Outside the app",
            value: "OUT_APP",
            testID: "outside-app",
            eventTargetName: "Expecting Outside App Payment Radio"
          }
        ],
        nextQuestion: {
          IN_APP: "MARK_COMPLETE",
          OUT_APP: "EXPECTED_PAYMENT_TYPE"
        }
      },
      EXPECTED_PAYMENT_TYPE: {
        title: `How are you expecting to receive payment${
          ProjectGigsPayInApp.isEnabled() ? " Outside the app?" : "?"
        }`,
        type: "radio",
        name: "expectedPaymentType",
        buttons: [
          {
            title: "Cash",
            value: "CASH",
            testID: "cash",
            eventTargetName: "Expecting Cash Payment Radio"
          },
          {
            title: "Check",
            value: "CHECK",
            testID: "check",
            eventTargetName: "Expecting Check Payment Radio"
          },
          {
            title: "3rd Party Digital Transfer (Venmo, Cash App, Etc.)",
            value: "DIGITAL_TRANSFER",
            testID: "digital-transfer",
            eventTargetName: "Expecting Digital Transfer Payment Radio"
          },
          {
            title: "Other",
            value: "NO_PREFERENCE",
            testID: "other",
            eventTargetName: "Expecting Other Payment Radio"
          }
        ],
        nextQuestion: {
          CASH: "MARK_COMPLETE",
          CHECK: "MARK_COMPLETE",
          DIGITAL_TRANSFER: "MARK_COMPLETE",
          NO_PREFERENCE: "MARK_COMPLETE"
        }
      },
      MARK_COMPLETE: {
        type: "submit",
        label: "Continue",
        testID: "submit-questionnaire"
      }
    }),
    []
  );
}
