import { NavIcon } from "@gigsmart/atorasu";
import { setBadge } from "@gigsmart/isomorphic-shared/notifications/notifications";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createRelayFragmentContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React, { useEffect } from "react";

import type {
  MessageType,
  NotificationIconSubscription
} from "./__generated__/NotificationIconSubscription.graphql";
import type { NotificationIcon_User$key } from "./__generated__/NotificationIcon_User.graphql";
import { updateMessageAdded } from "./helpers";

export const NOTIFICATION_MESSAGE_TYPES: MessageType[] = [
  "ALERT",
  "CORPORATE",
  "GIG_POSTED",
  "GIG",
  "MISCELLANEOUS",
  "PAYMENT",
  "JOB_BOOSTED",
  "JOB_POSTING",
  "DIRECT_HIRE"
];

export function NotificationIcon({
  notifications
}: FragmentContainerInnerComponentProps<NotificationIcon_User$key>) {
  const history = useHistory();
  useRelaySubscription<NotificationIconSubscription>(
    graphql`
      subscription NotificationIconSubscription($messageTypes: [MessageType!]) {
        messageAdded(messageTypes: $messageTypes) {
          unreadCount
        }
      }
    `,
    { messageTypes: NOTIFICATION_MESSAGE_TYPES },
    {
      updater: (store, data) =>
        updateMessageAdded(
          store,
          data?.messageAdded?.unreadCount ?? 0,
          "NotificationIcon_messages"
        )
    }
  );

  const totalCount = notifications?.totalCount;
  useEffect(() => {
    setBadge(totalCount);
  }, [totalCount]);

  return (
    <NavIcon
      testID="notification-center-icon"
      name="bell"
      badge={totalCount}
      onPress={() => history.push("/notifications")}
    />
  );
}

export default createRelayFragmentContainer<NotificationIcon_User$key>(
  graphql`
    fragment NotificationIcon_User on User
    @argumentDefinitions(query: { type: "String" }) {
      notifications: messages(first: 0, query: $query)
        @connection(key: "NotificationIcon_notifications", filters: []) {
        totalCount
        edges {
          cursor
        }
      }
    }
  `,
  NotificationIcon
);
