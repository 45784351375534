import React from "react";
import { View } from "react-native";

import { createStyles } from "../../style";
import TimesheetBar from "./timesheet-bar";
import TimesheetDot from "./timesheet-dot";

const styles = createStyles(({ units }) => ({
  dotWrapper: {
    alignItems: "center",
    marginRight: units(2)
  }
}));

interface Props {
  disabled?: boolean;
  barActive?: boolean;
  barHidden?: boolean;
}

const KatanaTimesheetEntry = ({ disabled, barActive, barHidden }: Props) => (
  <View style={styles.dotWrapper}>
    <TimesheetDot disabled={disabled} />
    {!barHidden && <TimesheetBar active={barActive} />}
  </View>
);

export default KatanaTimesheetEntry;
