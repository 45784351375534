import { Icon, Row, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { graphql, useRelayFragment, useRelayMutation } from "@gigsmart/relay";
import moment from "moment-timezone";
import React, { useCallback } from "react";
import { TouchableOpacity, View } from "react-native";
import { useCurrentUser } from "../current-user";
import moveMessageUpdater from "./move-message-updater";
import NotificationAvatar from "./notification-avatar";
import { notificationIconUpdater } from "./notification-icon-container";

import type { notificationRowReadSilentMutation } from "./__generated__/notificationRowReadSilentMutation.graphql";
import type {
  notificationRow_message$data,
  notificationRow_message$key
} from "./__generated__/notificationRow_message.graphql";

interface Props {
  isNew: boolean;
  message: notificationRow_message$key | null | undefined;
  onPress?: (entry: notificationRow_message$data) => void;
}

const formatNotificationTime = (sentAt: string | null | undefined): string => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const sentAtMoment = moment(sentAt ?? undefined);
  if (sentAtMoment.isSame(today, "day")) {
    return `Today at ${sentAtMoment.format("h:mm a")}`;
  }
  if (sentAtMoment.isSame(yesterday, "day")) {
    return `Yesterday at ${sentAtMoment.format("h:mm a")}`;
  }
  if (sentAtMoment.isAfter(moment().subtract(1, "years"))) {
    return `${sentAtMoment.format("MMM Do")} at ${sentAtMoment.format(
      "h:mm a"
    )}`;
  }
  return sentAtMoment.format("MMM YYYY");
};

const getMessageBody = (
  body: string | null | undefined,
  type: notificationRow_message$data["messageType"],
  name: string | null | undefined
): string => {
  if (type === "ALERT" && body) {
    return ` ${body.replace("Alert", "").trim()}`;
  }
  if (body && name && body.indexOf(name) === 0) {
    return ` ${body.replace(name, "").trim()}`;
  }
  return name ? ` ${body ?? ""}` : body ?? "";
};

const getNotificationName = (
  type: notificationRow_message$data["messageType"],
  name: string | null | undefined
): string => {
  if (type === "ALERT") {
    return "Alert ";
  }
  if (type === "CORPORATE") {
    return "GigSmart ";
  }
  return name ?? "";
};

export default function NotificationRow({
  message: messageRef,
  isNew,
  onPress
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    wrapper: {
      backgroundColor: getColor("surface", "fill")
    },
    container: {
      paddingVertical: 10,
      paddingLeft: 20,
      paddingRight: 10,
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: getColor("divider", "fill"),
      height: 80
    },
    newNotification: {
      backgroundColor: getColor("info", "fill", { opacity: 0.08 })
    },
    pastNotification: {
      backgroundColor: "transparent"
    },
    subtext: {
      color: getColor("disabled", "fill"),
      fontSize: 10,
      paddingTop: 5
    },
    description: {
      flex: 1,
      paddingHorizontal: 10
    },
    icon: {
      fontSize: 24,
      color: getColor("disabled", "fill"),
      margin: 12
    }
  }));

  const message = useRelayFragment<notificationRow_message$key>(
    graphql`
      fragment notificationRow_message on Message {
        organization {
          name
          logoUrl
        }
        author {
          id
          displayName
          profilePhoto {
            url
          }
          ... on RequesterLike {
            primaryOrganization {
              name
              logoUrl
            }
          }
        }
        body
        id
        messageType
        expiresAt
        receipt {
          id
          sentAt
          viewedAt
        }
        title
        data
        topicId
      }
    `,
    messageRef
  );

  const user = useCurrentUser();
  const isUnread = isNew;
  const [markRead] = useRelayMutation<notificationRowReadSilentMutation>(
    graphql`
      mutation notificationRowReadSilentMutation(
        $input: UpdateMessageReceiptInput!
      ) {
        updateMessageReceipt(input: $input) {
          messageReceipt {
            id
            viewedAt
          }
        }
      }
    `,
    {
      variables: {
        input: { messageReceiptId: message?.receipt?.id ?? "", viewed: true }
      },
      onError: (err) => console.warn("Error viewing message:", err),
      updater: (store) => {
        moveMessageUpdater(store, message?.id);
        notificationIconUpdater(store, (count) => count - 1);
      }
    }
  );

  const handlePress = useCallback(async () => {
    if (isUnread) markRead();
    if (onPress && message) onPress(message);
  }, [isUnread, markRead, message, onPress]);

  if (!message) return null;

  const authorPhotoUrl = message.author?.profilePhoto?.url;
  const authorName = message.author?.displayName;
  let name = authorName;
  let photoUrl = authorPhotoUrl;
  if (user?.__typename === "Worker" && message.author?.id !== user?.id) {
    photoUrl = message.organization?.logoUrl;
    name = message.organization?.name;
  }

  return (
    <Row style={styles.wrapper}>
      <TouchableOpacity
        style={[
          styles.container,
          isUnread ? styles.newNotification : styles.pastNotification
        ]}
        onPress={handlePress}
        testID="notification-row"
      >
        <NotificationAvatar
          avatarUrl={photoUrl ?? authorPhotoUrl}
          name={name ?? authorName ?? undefined}
          messageType={message.messageType}
        />
        <View style={styles.description}>
          <Text numberOfLines={3} variant="note">
            <Text weight="bold" color="primary">
              {getNotificationName(message.messageType, name)}
            </Text>
            {getMessageBody(message.body, message.messageType, name)}
          </Text>
          <Text style={styles.subtext}>
            {formatNotificationTime(message.receipt?.sentAt)}
          </Text>
        </View>
        {isUnread && (
          <TouchableOpacity onPress={() => markRead()}>
            <Icon name="times" style={styles.icon} />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    </Row>
  );
}
