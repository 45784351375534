/**
 * @generated SignedSource<<d8e4de35beef630469e42b39abf65542>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type currentUser_device$data = {
  readonly id: string;
  readonly pushRegistrationToken: string | null | undefined;
  readonly " $fragmentType": "currentUser_device";
};
export type currentUser_device$key = {
  readonly " $data"?: currentUser_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"currentUser_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "currentUser_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pushRegistrationToken",
      "storageKey": null
    }
  ],
  "type": "UserDevice",
  "abstractKey": null
};

(node as any).hash = "a607d288ad23b7e6ddba24bb7c40addb";

export default node;
