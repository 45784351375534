import React from "react";

import {
  Button,
  ModalBody,
  ModalFooter,
  Spacer,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";

interface WrapperProps {
  onMarkArrival: () => void;
}
interface Props extends WrapperProps {
  closeModal: () => void;
}

export default function MarkArrivalConfirmationModal({
  closeModal,
  onMarkArrival
}: Props) {
  return (
    <>
      <ModalBody>
        <Text>
          By marking yourself as Arrived, your live location will be shared with
          the Organization.
        </Text>
        <Spacer size="large" />
        <Stack size="compact">
          <Button
            color="primary"
            outline
            label="Cancel"
            onPress={closeModal}
            testID="cancel-btn"
          />
          <Button
            color="primary"
            onPress={() => {
              closeModal();
              setTimeout(onMarkArrival, 500);
            }}
            label="Mark as Arrived"
            testID="mark-arrived-btn"
          />
        </Stack>
      </ModalBody>
      <ModalFooter />
    </>
  );
}

export function showMarkArrivalConfirmationModal({
  onMarkArrival
}: WrapperProps) {
  showModal({
    eventContext: "MarkArrivalConfirmationModal",
    title: "Are you sure you want to mark yourself as Arrived?",
    children: (close) => (
      <MarkArrivalConfirmationModal
        closeModal={close}
        onMarkArrival={onMarkArrival}
      />
    )
  });
}
