import { Column, ContentArea, HighlightedReminder } from "@gigsmart/atorasu";
import { RemoveBenefitsMenu } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import TabLayout from "@gigsmart/katana/nav/katana-tabs/tab-layout";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import { compact } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import EngagementDisputeSummaryStack from "../engagement-dispute/worker/EngagementDisputeSummaryStack";
import ShiftInfoCollapsable from "../engagement/ShiftInfoCollapsable";
import WithdrawButton from "../gig/WithdrawButton";
import ClockinLocationCard from "../locations/ClockinLocationCard";
import ParkingLocationCard from "../locations/ParkingLocationCard";
import { WorkerBenefitsRow } from "../worker/WorkerBenefitsRow";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "../worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import ShiftDescriptionCard from "./ShiftDescriptionCard";
import ShiftDetailsCard from "./ShiftDetailsCard";
import ShiftTimesheetTab from "./ShiftTimesheetTab";
import { initialShiftTab } from "./WorkerShiftState";
import type { WorkerShiftTabsQuery } from "./__generated__/WorkerShiftTabsQuery.graphql";
import type { WorkerShiftTabsSubscription } from "./__generated__/WorkerShiftTabsSubscription.graphql";
import CancellationPoliciesRow from "./accessory/CancellationPoliciesRow";
import WorkerShiftSummaryTab from "./content/WorkerShiftSummaryTab";

const SHOW_TIMESHEET = new Set<EngagementStateName>([
  "ENDED",
  "WORKING",
  "PAUSED",
  "PENDING_TIMESHEET_APPROVAL"
]);
const SHOW_SUMMARY = new Set<EngagementStateName>([
  "ENDED",
  "PENDING_REVIEW",
  "PENDING_TIMESHEET_APPROVAL",
  "CANCELED_WITH_PAY"
]);
const SHOW_TIMESHEET_REMINDER = new Set<EngagementStateName>([
  "AWAITING_START",
  "EN_ROUTE",
  "RUNNING_LATE",
  "WORKING",
  "PAUSED"
]);

type Props = {
  shiftId: string;
};

export default createSuspendedQueryContainer<WorkerShiftTabsQuery, Props>(
  function WorkerShiftTabs({ shiftId, response }) {
    const node = response?.node;
    const skipRedirect = useRef(false);
    const [active, setActive] = useState(() =>
      initialShiftTab(node?.currentState?.name)
    );

    useEffect(() => {
      const nextTab = initialShiftTab(node?.currentState?.name);
      if (nextTab === "SUMMARY" && active !== "SUMMARY") {
        if (skipRedirect.current) return;
        skipRedirect.current = true;
        setActive(nextTab);
      }
    }, [node?.currentState?.name]);

    useRelaySubscription<WorkerShiftTabsSubscription>(
      graphql`
        subscription WorkerShiftTabsSubscription($shiftId: ID!) {
          engagementUpdated(engagementId: $shiftId) {
            engagement {
              ...ShiftInfoCollapsable_engagement
              ...WithdrawButton_engagement
              workerDistance
              reviewOfWorker {
                id
              }
              currentState {
                name
              }
              gig {
                ...BlockOrganizationCollapsibleRow_shift
                ...ShiftLocationCard_shift
                organizationLocation {
                  ...ParkingLocationCard_orgLocation
                }
              }
            }
          }
        }
      `,
      { shiftId },
      { subscribe: !!node?.id }
    );

    if (!node) return null;

    const gig = node.gig;
    const workerStateName = node.currentState?.name ?? "%future added value";
    const isTimesheet = SHOW_TIMESHEET.has(workerStateName);
    const isSummary = SHOW_SUMMARY.has(workerStateName);
    const showTimesheetReminder = SHOW_TIMESHEET_REMINDER.has(workerStateName);

    return (
      <Column gap="standard">
        {["EN_ROUTE", "SCHEDULED", "RUNNING_LATE"].includes(
          workerStateName
        ) && (
          <>
            <ParkingLocationCard
              fragmentRef={gig?.organizationLocation}
              currentState={workerStateName}
            />
            <ClockinLocationCard
              fragmentRef={gig?.organizationLocation}
              currentState={workerStateName}
            />
          </>
        )}
        <ShiftInfoCollapsable fragmentRef={node} />
        <EngagementDisputeSummaryStack engagementId={node.id} />
        {showTimesheetReminder && (
          <ContentArea size="none">
            <HighlightedReminder
              icon="clock"
              header="Was there an issue with your timesheet? You can always submit an Updated Timesheet at the end of the Shift."
            />
          </ContentArea>
        )}
        <Column>
          <TabLayout
            tabItemsHideSingle
            tabs={compact([
              { key: "DETAILS" },
              isTimesheet && { key: "TIMESHEET" },
              isSummary && { key: "SUMMARY" }
            ])}
            active={active}
            onSelectTab={setActive}
            renderContent={(tab) => {
              switch (tab.key) {
                case "TIMESHEET": {
                  return <ShiftTimesheetTab id={node.id} />;
                }
                case "SUMMARY": {
                  return <WorkerShiftSummaryTab id={node.id} />;
                }
                default: {
                  return (
                    <Column gap="standard">
                      <ShiftDescriptionCard
                        showHeader={!isTimesheet && !isSummary}
                        fragmentRef={gig}
                        isBrowsing={false}
                      />
                      {workerStateName === "AWAITING_START" && (
                        <>
                          <ParkingLocationCard
                            fragmentRef={gig?.organizationLocation}
                            currentState={workerStateName}
                          />
                          <ClockinLocationCard
                            fragmentRef={gig?.organizationLocation}
                            currentState={workerStateName}
                          />
                        </>
                      )}
                      {[
                        "WORKING",
                        "PAUSED",
                        "DISBURSED",
                        "ENDED",
                        "PAID",
                        "PENDING_REVIEW",
                        "PENDING_TIMESHEET_APPROVAL"
                      ].includes(workerStateName) && (
                        <>
                          <ParkingLocationCard
                            fragmentRef={gig?.organizationLocation}
                            currentState={workerStateName}
                          />
                          <ClockinLocationCard
                            fragmentRef={gig?.organizationLocation}
                            currentState={workerStateName}
                          />
                        </>
                      )}
                      <ShiftDetailsCard
                        fragmentRef={gig}
                        workerDistance={node.workerDistance}
                        workerStateName={node.currentState?.name}
                        engagementId={node.id}
                        negotiatedPayRate={node.currentNegotiation?.payRate}
                      />
                      <CancellationPoliciesRow fragmentRef={gig ?? null} />
                      {RemoveBenefitsMenu.isDisabled() && <WorkerBenefitsRow />}
                      {showWorkerBlockOrg(
                        node.gig,
                        node.currentState?.name
                      ) && (
                        <BlockOrganizationCollapsibleRow
                          organizationId={node.gig?.organization?.id ?? ""}
                        />
                      )}
                      <WithdrawButton fragmentRef={node} />
                    </Column>
                  );
                }
              }
            }}
          />
        </Column>
      </Column>
    );
  },
  {
    query: graphql`
      query WorkerShiftTabsQuery($shiftId: ID!) {
        node(id: $shiftId) {
          id
          ...ShiftInfoCollapsable_engagement
          ...WithdrawButton_engagement
          ... on Engagement {
            workerDistance
            currentState {
              name
            }
            currentNegotiation {
              payRate
            }
            gig {
              ...BlockOrganizationCollapsibleRow_shift
              ...ShiftDescriptionCard_shift
              ...ShiftLocationCard_shift
              ...ShiftDetailsCard_shift
              ...CancellationPoliciesRow_shift
              organization {
                id
              }
              organizationLocation {
                ...ParkingLocationCard_orgLocation
                ...ClockinLocationCard_organizationLocation
              }
            }
          }
        }
      }
    `,
    variables: ({ shiftId }) => ({ shiftId })
  }
);
