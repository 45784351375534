/**
 * @generated SignedSource<<827f4005f7300d56e4f6533f638aa832>>
 * @relayHash a20f313ceae2b99378bed6cd23c591c1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:nZQkTTwwS90t9m2mtNdLoMTsNZF6L-PokgJeAG4n1Yo

import { ConcreteRequest } from 'relay-runtime';
export type addDirectPaymentOrHireScreenQuery$variables = Record<PropertyKey, never>;
export type addDirectPaymentOrHireScreenQuery$data = {
  readonly viewer: {
    readonly directHireBonus?: string;
  } | null | undefined;
};
export type addDirectPaymentOrHireScreenQuery = {
  response: addDirectPaymentOrHireScreenQuery$data;
  variables: addDirectPaymentOrHireScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireBonus",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "addDirectPaymentOrHireScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "addDirectPaymentOrHireScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:nZQkTTwwS90t9m2mtNdLoMTsNZF6L-PokgJeAG4n1Yo",
    "metadata": {},
    "name": "addDirectPaymentOrHireScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "475159d0c364b264f1332b782e51bd88";

export default node;
