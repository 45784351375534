import { StepWithTitle, createStepper, inspect } from "@gigsmart/atorasu";
import { formatPhoneNumber } from "@gigsmart/isomorphic-shared/app/phone";
import {
  type CurrentUserShape,
  useCurrentUser
} from "@gigsmart/isomorphic-shared/current-user";
import {
  createSuspendedQueryContainer,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import DateOfBirthStep from "@gigsmart/seibutsu/user/Onboarding/DateOfBirthStep";
import EmailStep from "@gigsmart/seibutsu/user/Onboarding/EmailStep";
import MobileNumberStep from "@gigsmart/seibutsu/user/Onboarding/MobileNumberStep";
import { OnboardingNav } from "@gigsmart/seibutsu/user/Onboarding/OnboardingNav";
import PasswordStep from "@gigsmart/seibutsu/user/Onboarding/PasswordStep";
import PositionSelectionStep from "@gigsmart/seibutsu/user/Onboarding/PositionSelectionStep";
import SMSVerificationStep from "@gigsmart/seibutsu/user/Onboarding/SMSVerificationStep";
import SelfIntroductionStep from "@gigsmart/seibutsu/user/Onboarding/SelfIntroductionStep";
import TransportationStep from "@gigsmart/seibutsu/user/Onboarding/TransportationStep";
import ZipCodeStep from "@gigsmart/seibutsu/user/Onboarding/ZipCodeStep";
import type { OnboardingData } from "@gigsmart/seibutsu/user/Onboarding/types";
import React, { useMemo } from "react";
import type { WorkerOnboardingScreenQuery } from "./__generated__/WorkerOnboardingScreenQuery.graphql";
import type { WorkerOnboardingScreen_worker$key } from "./__generated__/WorkerOnboardingScreen_worker.graphql";
import ProfilePhotoStep from "./profile-photo-step";

const SKIP_PROFILE_PHOTO = process.env.IS_TESTING === "true";

const Onboarding = createStepper<typeof StepWithTitle, OnboardingData>();

export function nextStepName(
  user?: CurrentUserShape | null,
  extraRef?: WorkerOnboardingScreen_worker$key | null
) {
  const extra = readRelayInlineFragment(
    graphql`
      fragment WorkerOnboardingScreen_worker on Worker @inline {
        birthdate
        transportationMethod
        transportationState
        workerPositions(first: 0, query: "WHERE status = CONFIRMED") {
          totalCount
        }
        primaryMobile {
          confirmed
        }
      }
    `,
    extraRef ?? null
  );

  if (!user) return "Email";
  if (!user.firstName || !user.lastName) return "Introduce Yourself";
  if (!user.primaryMobile?.number) return "Mobile Number";
  if (!extra?.primaryMobile?.confirmed) return "SMS Verification";
  if (!extra.birthdate) return "Date of Birth";
  if (!user.postalCode) return "Zip Code";
  if (!SKIP_PROFILE_PHOTO && !user.profilePhoto) return "Profile Photo";
  if (!extra.workerPositions?.totalCount) return "Positions";
  if (
    !extra.transportationMethod ||
    (extra.transportationMethod === "car" && !extra.transportationState)
  ) {
    return "Transportation";
  }
}

export default createSuspendedQueryContainer<WorkerOnboardingScreenQuery>(
  function WorkerOnboardingScreen({ response }) {
    const { viewer } = response ?? {};

    const user = useCurrentUser();
    const data = useMemo(
      (): OnboardingData => ({
        userType: "WORKER",
        email: "",
        phone: formatPhoneNumber(user?.primaryMobile?.number ?? "") ?? "",
        birthdate: ""
      }),
      [user]
    );
    const initialStepName = useMemo(
      () => inspect(nextStepName(user, viewer)),
      [user, viewer]
    );

    return (
      <Onboarding.Stepper
        testID="onboarding-stepper"
        layout={StepWithTitle}
        extraData={data}
        initialData={data}
        initialStepName={initialStepName}
        header={<OnboardingNav />}
      >
        <Onboarding.Step
          name="Email"
          component={EmailStep}
          options={{
            icon: "envelope",
            title: "Please enter your email",
            testID: "email-screen"
          }}
        />
        <Onboarding.Step
          name="Password"
          component={PasswordStep}
          options={{
            icon: "key",
            title: "Please create a secure password",
            testID: "password-screen"
          }}
        />
        <Onboarding.Step
          name="Introduce Yourself"
          component={SelfIntroductionStep}
          options={{
            icon: "circle",
            title: "Introduce Yourself",
            testID: "introduce-yourself-screen"
          }}
        />
        <Onboarding.Step
          name="Mobile Number"
          component={MobileNumberStep}
          options={{
            title: "Please verify your phone number",
            icon: "phone",
            testID: "mobile-number-screen"
          }}
        />
        <Onboarding.Step
          name="SMS Verification"
          component={SMSVerificationStep}
          options={{
            title: `Please enter the 6-digit code sent to ${data.phone}`,
            icon: "comment-sms",
            testID: "sms-verification-screen"
          }}
        />
        <Onboarding.Step
          name="Date of Birth"
          component={DateOfBirthStep}
          options={{
            icon: "calendar",
            title: "Please provide your date of birth",
            testID: "dob-screen"
          }}
        />
        <Onboarding.Step
          name="Zip Code"
          component={ZipCodeStep}
          options={{
            icon: "location-dot",
            title: "Please provide your zip code",
            testID: "zip-code-screen"
          }}
        />
        {!SKIP_PROFILE_PHOTO && (
          <Onboarding.Step
            name="Profile Photo"
            component={ProfilePhotoStep}
            options={{
              icon: "user",
              title: "Make a great first impression",
              testID: "profile-photo-screen"
            }}
          />
        )}
        <Onboarding.Step
          name="Positions"
          component={PositionSelectionStep}
          options={{
            icon: "user-tie",
            title: "Let's get you working",
            testID: "position-screen"
          }}
        />
        <Onboarding.Step
          name="Transportation"
          component={TransportationStep}
          options={{
            icon: "car",
            title: "What is your primary method of transportation?",
            testID: "transportation-screen"
          }}
        />
      </Onboarding.Stepper>
    );
  },
  {
    query: graphql`
      query WorkerOnboardingScreenQuery {
        viewer {
          ...WorkerOnboardingScreen_worker
        }
      }
    `,
    variables: {}
  }
);
