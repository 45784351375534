import React from "react";
import { TouchableOpacity } from "react-native";
import { IconText } from "../icon";
import { useStyles } from "../style";

interface Props {
  testID?: string;
  collapsed: boolean;
  collapsedText?: string;
  uncollapsedText?: string;
  onPress?: () => void;
}

const CollapsibleCardButton = ({
  testID,
  collapsed,
  collapsedText = "View More",
  uncollapsedText = "View Less",
  onPress
}: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    viewMoreButton: {
      borderTopWidth: 1,
      borderTopColor: color.blue,
      paddingVertical: units(4),
      justifyContent: "center",
      alignItems: "center"
    }
  }));

  return (
    <TouchableOpacity
      testID={testID}
      style={styles.viewMoreButton}
      onPress={onPress}
      accessible={false}
    >
      <IconText
        alignItems="center"
        tintColor="blue"
        variant="bold"
        iconName={collapsed ? "chevron-down" : "chevron-up"}
      >
        {collapsed ? collapsedText : uncollapsedText}
      </IconText>
    </TouchableOpacity>
  );
};

export default CollapsibleCardButton;
