/**
 * @generated SignedSource<<b1569c06b649828e3123e43e41edc7c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MessageType = "ALERT" | "CALL" | "CORPORATE" | "DIRECT_HIRE" | "GIG" | "GIG_POSTED" | "JOB_BOOSTED" | "JOB_POSTING" | "MISCELLANEOUS" | "PAYMENT" | "SILENT_MESSAGE" | "USER_MESSAGE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type notificationRow_message$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly id: string;
    readonly primaryOrganization?: {
      readonly logoUrl: string | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly body: string | null | undefined;
  readonly data: string | null | undefined;
  readonly expiresAt: string | null | undefined;
  readonly id: string;
  readonly messageType: MessageType;
  readonly organization: {
    readonly logoUrl: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly receipt: {
    readonly id: string;
    readonly sentAt: string;
    readonly viewedAt: string | null | undefined;
  } | null | undefined;
  readonly title: string;
  readonly topicId: string | null | undefined;
  readonly " $fragmentType": "notificationRow_message";
};
export type notificationRow_message$key = {
  readonly " $data"?: notificationRow_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"notificationRow_message">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoUrl",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "notificationRow_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profilePhoto",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "primaryOrganization",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "type": "RequesterLike",
          "abstractKey": "__isRequesterLike"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageReceipt",
      "kind": "LinkedField",
      "name": "receipt",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sentAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "data",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicId",
      "storageKey": null
    }
  ],
  "type": "Message",
  "abstractKey": null
};
})();

(node as any).hash = "26126453989d452faa5dea82e9611c83";

export default node;
