import React, { Component } from "react";
import { View } from "react-native";

import { type ViewStyleProp, createStyles } from "../style";
import Chip from "./chip";

export type ValueType<T = string> = T | T[] | null;

export interface OptionType<T = string> {
  readonly label: string;
  readonly value: T;
}

interface Props {
  style?: ViewStyleProp;
  value: ValueType<string>;
  onChangeValue?: (value: ValueType<string>) => void;
  multiple: boolean;
  options: ReadonlyArray<OptionType<string>>;
  testID: string;
  labelStyle?: ViewStyleProp;
  activeContainerStyle?: ViewStyleProp;
  activeLabelStyle?: ViewStyleProp;
}

const styles = createStyles(() => ({
  container: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  chip: {
    marginRight: 10,
    marginVertical: 8
  }
}));

export default class ChipSelect extends Component<Props> {
  static defaultProps = {
    options: [],
    multiple: false,
    value: null,
    testID: "chip-select",
    labelStyle: {},
    activeContainerStyle: {},
    activeLabelStyle: {}
  };

  shouldComponentUpdate({ value, options }: Props) {
    return this.props.value !== value || this.props.options !== options;
  }

  isChecked = (optionValue: string) => {
    const { value, multiple } = this.props;
    if (!value) return false;
    return multiple
      ? Array.isArray(value) && value.includes(optionValue)
      : value === optionValue;
  };

  selectOption = (optionValue: string) => {
    const { value, multiple, onChangeValue } = this.props;
    const isChecked = this.isChecked(optionValue);
    let newValue = null;
    if (!onChangeValue) return;

    if (multiple) {
      // Can we just use another ternary here?
      if (!value) newValue = [optionValue];
      else if (Array.isArray(value)) {
        newValue = isChecked
          ? value.filter((d) => d !== optionValue)
          : [...value, optionValue];
      }
    } else {
      newValue = isChecked ? null : optionValue;
    }

    onChangeValue(newValue);
  };

  render() {
    const { style, options, testID } = this.props;
    return (
      <View style={[styles.container, style]} testID={testID}>
        {options.map(this.renderOption)}
      </View>
    );
  }

  renderOption = (option: OptionType<string>) => {
    const {
      activeContainerStyle,
      activeLabelStyle,
      labelStyle,
      onChangeValue
    } = this.props;

    return (
      <Chip
        style={styles.chip}
        labelStyle={labelStyle}
        activeLabelStyle={activeLabelStyle}
        activeContainerStyle={activeContainerStyle}
        key={option.value}
        checked={this.isChecked(option.value)}
        onPress={onChangeValue && (() => this.selectOption(option.value))}
      >
        {option.label}
      </Chip>
    );
  };
}
