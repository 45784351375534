import { toast } from "@gigsmart/atorasu";
import { where } from "@gigsmart/biruda";
import {
  ConnectionHandler,
  type RecordSourceSelectorProxy,
  getConnectionEdges,
  graphql,
  useRelayFragment,
  useRelayMutation
} from "@gigsmart/relay";
import type {
  usePositionsByCategoryMutation,
  usePositionsByCategoryMutation$data,
  usePositionsByCategoryMutation$rawResponse
} from "./__generated__/usePositionsByCategoryMutation.graphql";
import type { usePositionsByCategory_worker$key } from "./__generated__/usePositionsByCategory_worker.graphql";

interface Option {
  id?: string;
  label?: string;
  value?: boolean | null;
  workerGigPositionId?: string;
}

export const positionsByCategoryVariables = ({
  categoryId
}: {
  categoryId: string;
}) => ({
  categoryQuery: where({ id: categoryId }).toString(),
  positionsQuery: where({ gigCategoryId: categoryId }).toString()
});

export default function usePositionsByCategory(
  fragmentRef: usePositionsByCategory_worker$key | null | undefined,
  workerCategoryId: string
) {
  const worker = useRelayFragment(
    graphql`
      fragment usePositionsByCategory_worker on Worker
      @argumentDefinitions(
        categoryQuery: { type: "String!" }
        positionsQuery: { type: "String!" }
      ) {
        id
        category: workerCategories(first: 1, query: $categoryQuery) {
          edges {
            association {
              id
              confirmedPositions
            }
            node {
              id
              displayName
              categoryIcon {
                data
              }
            }
          }
        }
        workerPositions(first: 0, query: "WHERE status = CONFIRMED") {
          totalCount
        }
        categoryPositions: workerPositions(first: 50, query: $positionsQuery) {
          edges {
            association {
              status
              id
            }
            node {
              id
              name
            }
          }
        }
      }
    `,
    fragmentRef ?? null
  );

  const [updatePositionsMutations] =
    useRelayMutation<usePositionsByCategoryMutation>(graphql`
      mutation usePositionsByCategoryMutation(
        $input: UpdateWorkerGigPositionInput!
      ) @raw_response_type {
        updateWorkerGigPosition(input: $input) {
          workerGigPosition {
            id
            status
          }
        }
      }
    `);

  const workerId = worker?.id ?? "";
  const categories = getConnectionEdges(worker?.category);
  const category = categories?.[0];
  const positions = getConnectionEdges(worker?.categoryPositions);

  const options: Option[] = positions
    .map(({ association, node }) => ({
      id: node?.id,
      label: node?.name,
      value:
        association?.status === "CONFIRMED"
          ? true
          : association?.status === "DENIED"
            ? false
            : null,
      workerGigPositionId: association?.id
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label ?? "") ?? 0);

  const selectOption = ({
    value,
    id,
    workerGigPositionId
  }: Option & { checkSuccessMessage?: string }) => {
    updatePositionsMutations(
      {
        input: {
          status: value ? "CONFIRMED" : "DENIED",
          gigPositionId: id ?? "",
          workerId
        }
      },
      {
        onError: () => {
          toast.error("Something went wrong");
        },
        optimisticResponse: {
          updateWorkerGigPosition: {
            workerGigPosition: {
              id: workerGigPositionId,
              status: value ? "CONFIRMED" : "UNKNOWN"
            }
          }
        },
        updater: (store) => {
          customUpdater(workerId, store, value, workerCategoryId);
        }
      }
    );
  };

  const checkOption = ({
    value,
    id,
    workerGigPositionId
  }: Option & { checkSuccessMessage?: string }) => {
    updatePositionsMutations(
      {
        input: {
          status: value ? "CONFIRMED" : "UNKNOWN",
          gigPositionId: id ?? "",
          workerId
        }
      },
      {
        onError: () => {
          toast.error("Something went wrong");
        },
        optimisticResponse: {
          updateWorkerGigPosition: {
            workerGigPosition: {
              id: workerGigPositionId,
              status: value ? "CONFIRMED" : "UNKNOWN"
            }
          }
        },
        updater: (store) => {
          customUpdater(workerId, store, value, workerCategoryId);
        }
      }
    );
  };

  return {
    title: category?.node?.displayName,
    icon: category?.node?.categoryIcon?.data,
    subtitle:
      "Select Positions you are interested in and able to work. We will notify you of Gig opportunities based on your selections.",
    searchHeader: "Are you able to work the following Positions?",
    searchPlaceholder: "Search Position Title",
    options,
    checkOption,
    selectOption
  };
}

function customUpdater(
  workerId: string,
  store: RecordSourceSelectorProxy<
    usePositionsByCategoryMutation$rawResponse &
      usePositionsByCategoryMutation$data
  >,
  value: boolean | null | undefined,
  workerCategoryId: string
) {
  const worker = store.get(workerId);
  const workerCategory = store.get(workerCategoryId);

  const confirmedCount = workerCategory?.getValue(
    "confirmedPositions"
  ) as number;
  const newConfirmedCount = value ? confirmedCount + 1 : confirmedCount - 1;
  const unknownCount = workerCategory?.getValue("unknownPositions") as number;
  const newUnknownCount = value ? unknownCount - 1 : unknownCount + 1;
  workerCategory?.setValue(newConfirmedCount, "confirmedPositions");
  workerCategory?.setValue(newUnknownCount, "unknownPositions");

  if (!worker) return;

  const workerPositions = worker.getLinkedRecord("workerPositions", {
    first: 0,
    query: "WHERE status = CONFIRMED"
  });
  if (workerPositions) {
    const current = workerPositions.getValue("totalCount") as number;
    workerPositions.setValue(value ? current + 1 : current - 1, "totalCount");
  }

  const positionsConnection = ConnectionHandler.getConnection(
    worker,
    "PositionSelectionStep_positions",
    { query: "WHERE status = CONFIRMED" }
  );
  if (positionsConnection) {
    const currentCount = (positionsConnection.getValue("totalCount") ??
      0) as number;
    const newCount = value ? currentCount + 1 : currentCount - 1;
    positionsConnection.setValue(newCount, "totalCount");
  }
  const onboardingConnection = ConnectionHandler.getConnection(
    worker,
    "WorkerPositionCheck_workerPositions",
    {
      query: "WHERE status = CONFIRMED"
    }
  );
  if (onboardingConnection) {
    const currentCount = (onboardingConnection.getValue("totalCount") ??
      0) as number;
    const newCount = value ? currentCount + 1 : currentCount - 1;
    onboardingConnection.setValue(newCount, "totalCount");
  }
  const onboardingModalConnection = ConnectionHandler.getConnection(
    worker,
    "WorkerPositionCheckModal_positions",
    {
      query: "WHERE status = CONFIRMED"
    }
  );
  if (onboardingModalConnection) {
    const currentCount = (onboardingModalConnection.getValue("totalCount") ??
      0) as number;
    const newCount = value ? currentCount + 1 : currentCount - 1;
    onboardingModalConnection.setValue(newCount, "totalCount");
  }
}
