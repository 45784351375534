import { IsConstrainedProvider } from "@gigsmart/atorasu/atoms/Constraint";
import React, { type ReactElement } from "react";
import { FlatList, type FlatListProps, View } from "react-native";

import { type ViewStyleProp, useStyles, useViewport } from "../style";

export interface GridListRenderItemInfo<T> {
  item: T;
  index: number;
  width: string | number;
}

export type GridListRenderItem<T> = (
  info: GridListRenderItemInfo<T>
) => ReactElement | null | undefined;

export type Props<T> = Omit<FlatListProps<T>, "numColumns" | "renderItem"> & {
  renderItem: GridListRenderItem<T>;
  testID: string;
  numColumns?:
    | number
    | Record<string, number>
    | ((breakpoint: string) => number);
  contentContainerStyle?: ViewStyleProp;
  itemStyle?: ViewStyleProp;
  rowCentered?: boolean;
  footerBottom?: boolean;
};

const defaultBreakpointColumns = {
  "@tabletDown": 2,
  "@desktop": 3,
  "@desktopWide": 3
};

export default function GridList<T>({
  renderItem,
  numColumns = defaultBreakpointColumns,
  contentContainerStyle,
  columnWrapperStyle,
  itemStyle,
  rowCentered,
  footerBottom,
  ListFooterComponentStyle,
  ...listProps
}: Props<T>) {
  const { breakpoint } = useViewport();
  const { styles } = useStyles(({ units }) => ({
    list: { padding: units(2), flexGrow: 1 },
    cell: { padding: units(2) },
    rowCenter: { justifyContent: "center" },
    footerBottom: { marginTop: "auto" }
  }));

  const columns =
    typeof numColumns === "number"
      ? numColumns
      : typeof numColumns === "function"
        ? numColumns(breakpoint)
        : numColumns[breakpoint] ?? 1;

  const columnWrapperStyles: ViewStyleProp[] = [];
  if (columnWrapperStyle) columnWrapperStyles.push(columnWrapperStyle);
  if (rowCentered) columnWrapperStyles.push(styles.rowCenter);

  const width = `${100 / columns}%`;

  return (
    <IsConstrainedProvider value>
      <FlatList
        contentContainerStyle={[styles.list, contentContainerStyle]}
        columnWrapperStyle={columns > 1 && columnWrapperStyles}
        ListFooterComponentStyle={[
          footerBottom && styles.footerBottom,
          ListFooterComponentStyle
        ]}
        {...listProps}
        numColumns={columns}
        renderItem={({ index, item }) => {
          const content = renderItem({ item, index, width });
          return <View style={[styles.cell, { width }]}>{content}</View>;
        }}
        // Changing numColumns on the fly is not supported
        key={`cols_${columns}`}
      />
    </IsConstrainedProvider>
  );
}
