/**
 * @generated SignedSource<<00427bf828fa23f0cda9d77efd7b3bc5>>
 * @relayHash 1faeb40858d612e4f15fc76c5557de01
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:kVhEakJoFp0bYMRU6Mhrxq9XvyeLcl2gF5bdoDGs08o

import { ConcreteRequest } from 'relay-runtime';
export type GigPositionStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type SetWorkerPositionInput = {
  gigPositionId: string;
  status: GigPositionStatus;
  workerId: string;
};
export type showRejectGigPositionPromptMutation$variables = {
  input: SetWorkerPositionInput;
};
export type showRejectGigPositionPromptMutation$data = {
  readonly setWorkerPosition: {
    readonly workerPosition: {
      readonly id: string;
      readonly status: GigPositionStatus;
    };
  } | null | undefined;
};
export type showRejectGigPositionPromptMutation = {
  response: showRejectGigPositionPromptMutation$data;
  variables: showRejectGigPositionPromptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetWorkerPositionPayload",
    "kind": "LinkedField",
    "name": "setWorkerPosition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerPosition",
        "kind": "LinkedField",
        "name": "workerPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "showRejectGigPositionPromptMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "showRejectGigPositionPromptMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:kVhEakJoFp0bYMRU6Mhrxq9XvyeLcl2gF5bdoDGs08o",
    "metadata": {},
    "name": "showRejectGigPositionPromptMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "92287a5d001b9192a619dfe924af4855";

export default node;
