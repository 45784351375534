import { graphql, useRelayFragment } from "@gigsmart/relay";
import kebabCase from "lodash/kebabCase";
import React, {
  type ReactNode,
  useContext,
  createContext,
  useMemo
} from "react";
import type {
  useSummaryRowEdit_gigLike$data,
  useSummaryRowEdit_gigLike$key
} from "./__generated__/useSummaryRowEdit_gigLike.graphql";

export type EditableField =
  | "address"
  | "gig_category_id"
  | "gig_position_id"
  | "payment_type"
  | "payment_method_id"
  | "skills"
  | "description"
  | "is_posted"
  | "slots"
  | "pay_rate"
  | "crisis_id"
  | "starts_at"
  | "ends_at"
  | "schedule"
  | "name"
  | "addons"
  | "primary_contact_id";

type EditFn = ((__typename: string, id: string, route: string) => void) | null;

const Context = createContext<{
  gigLike: useSummaryRowEdit_gigLike$data | null | undefined;
  onEdit: EditFn;
}>({
  gigLike: null,
  onEdit: null
});

interface Props {
  gigLike: useSummaryRowEdit_gigLike$key | null | undefined;
  onEdit: EditFn;
  children: ReactNode;
}

export function SummaryRowEditProvider({
  gigLike: gigLikeRef,
  onEdit,
  children
}: Props) {
  const gigLike = useRelayFragment(
    graphql`
      fragment useSummaryRowEdit_gigLike on GigLike {
        __typename
        id
        publishedAt
        ... on Gig {
          gigType
          editableFields {
            field
          }
        }
      }
    `,
    gigLikeRef
  );
  return (
    <Context.Provider
      value={{
        gigLike,
        onEdit
      }}
    >
      {children}
    </Context.Provider>
  );
}

export type CheckFn = (gig: useSummaryRowEdit_gigLike$data) => boolean;

export default function useSummaryRowEdit(
  field?: EditableField | CheckFn,
  route?: string | undefined
): [boolean, (() => void) | null] {
  const { gigLike, onEdit: contextOnEdit } = useContext(Context);
  const disableProjectFieldsEditBypass =
    gigLike?.gigType === "PROJECT" &&
    ["gig_category_id", "address"].includes(field as string);

  const check: CheckFn = useMemo(
    () =>
      typeof field === "function"
        ? field
        : ({ editableFields }) => {
            if (
              field &&
              gigLike?.__typename === "Gig" &&
              !disableProjectFieldsEditBypass &&
              [
                "address",
                "gig_category_id",
                "gig_position_id",
                "addons"
              ].includes(field)
            ) {
              // bypass editable field
              return false;
            }

            return (
              gigLike?.__typename === "GigSeries" ||
              (!!field &&
                !!editableFields?.map(({ field }) => field).includes(field))
            );
          },
    [field, gigLike]
  );

  let localRoute = route;

  if (!route && typeof field === "string") localRoute = kebabCase(field);
  const editable = useMemo(
    () => !!localRoute && (!gigLike || check(gigLike)),
    [gigLike, check, localRoute]
  );
  const onEdit = useMemo(
    () =>
      gigLike && localRoute && contextOnEdit
        ? () =>
            contextOnEdit(gigLike.__typename, gigLike.id, String(localRoute))
        : null,
    [gigLike, localRoute, contextOnEdit]
  );
  return [editable, onEdit];
}
