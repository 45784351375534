import { StepperProvider, useStepper } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import type { $ElementType } from "@gigsmart/type-utils";
import React, { type ComponentProps, useMemo } from "react";
import { ResponsiveStepperBtnPortal, type Step } from "../shared/Stepper";
import StepperNavActions from "../shared/Stepper/StepperNavActions";
import ConsentDocumentForm from "./ConsentDocumentForm";
import type { ConsentDocumentStepper_documents$key } from "./__generated__/ConsentDocumentStepper_documents.graphql";

interface Props {
  additionalConsents?: $ElementType<
    ComponentProps<typeof ConsentDocumentForm>,
    "additionalConsents"
  >;
  documents?: ConsentDocumentStepper_documents$key;
  onComplete: () => void;
}

export default function ConsentDocumentStepper({
  additionalConsents,
  documents: documentsFragmentRef,
  onComplete
}: Props) {
  const documents = useRelayFragment(
    graphql`
      fragment ConsentDocumentStepper_documents on LegalDocument
      @relay(plural: true) {
        filename
        ...ConsentDocumentForm_document
      }
    `,
    documentsFragmentRef ?? null
  );

  const steps = useMemo(
    () =>
      (documents ?? []).map((document, index, arr) => ({
        stepName: `legalDocument-${index}`,
        stepTitle: "",
        buttonShouldAvoidKeyboard: true,
        component: (
          <StepComponent
            key={document.filename}
            document={document}
            additionalConsents={additionalConsents}
          />
        )
      })),
    [documents, additionalConsents]
  );

  return (
    <StepperProvider
      stepParam="legal"
      steps={steps}
      eventContext="Legal"
      onComplete={onComplete}
    />
  );
}

type StepProps = Omit<
  ComponentProps<typeof ConsentDocumentForm>,
  "onComplete" | "SubmitButton"
>;

function StepComponent(props: StepProps) {
  const { nextStep } = useStepper<Step, {}>();
  return (
    <>
      <StepperNavActions />
      <ConsentDocumentForm
        {...props}
        onComplete={nextStep}
        SubmitButton={ResponsiveStepperBtnPortal.Entrance}
      />
    </>
  );
}
