import {
  Checkbox,
  Column,
  ContentArea,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormToggle } from "@gigsmart/fomu";
import { StyledLink } from "@gigsmart/katana";
import React from "react";

type Props = FormFieldOptions & {
  onToggle?: () => void;
  toggled?: boolean;
  prompt?: string;
  testID?: string;
  variant?: "outline" | "shadow";
  size?: "compact" | "medium";
};

export default function CardCheckbox({
  onToggle,
  toggled,
  testID,
  prompt,
  variant = "outline",
  size = "compact",
  ...options
}: Props) {
  const { toggle, value } = useFormToggle(options);

  return (
    <Surface variant={variant}>
      <ContentArea size={size}>
        <Stack horizontal alignItems="center">
          <Checkbox
            selected={typeof toggled === "undefined" ? value : toggled}
            testID={testID ?? "card-checkbox"}
            onChange={onToggle ?? toggle}
          />
          <Column fill>
            {prompt ? (
              <Text>{prompt}</Text>
            ) : (
              <Text>
                I agree to Stripe's{" "}
                <StyledLink openURL="https://stripe.com/connect-account/legal">
                  <Text>Terms of Service</Text>
                </StyledLink>{" "}
                and{" "}
                <StyledLink openURL="https://stripe.com/privacy">
                  <Text>Privacy Policy</Text>
                </StyledLink>
              </Text>
            )}
          </Column>
        </Stack>
      </ContentArea>
    </Surface>
  );
}
