import React, { type ReactNode, type ComponentProps } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import StyledIcon from "../icon/styled-icon";
import { type ViewStyleProp, useStyles } from "../style";
import StyledText from "../text/styled-text";

type Prop = ComponentProps<typeof View> & {
  noMargin?: boolean;
  noSpacing?: boolean;
  noRadius?: boolean;
  style?: ViewStyleProp;
  shadow?: boolean;
  border?: boolean;
  onClose?: () => void;
  iconStyle?: ViewStyleProp;
  badgeText?: string | null | undefined;
  iconContainerStyle?: ViewStyleProp;
  children: ReactNode;
  paddingUnits?: number;
  isHover?: boolean;
};

export default function Card({
  noMargin,
  noSpacing,
  noRadius,
  onClose,
  iconStyle,
  badgeText,
  style,
  shadow = true,
  border = false,
  children,
  iconContainerStyle,
  paddingUnits,
  isHover,
  ...rest
}: Prop) {
  const showCloseIcon = !!onClose;
  const { styles } = useStyles(
    ({ color, font, namedShadows, unit, units }) => ({
      belowCloseButton: {
        paddingTop: 15
      },
      container: {
        position: "relative",
        zIndex: 1,
        marginVertical: 5,
        marginHorizontal: 12,
        backgroundColor: color.white,
        borderRadius: unit.borderRadius,
        padding: paddingUnits ? units(paddingUnits) : 0
      },
      shadow: {
        ...namedShadows.card1
      },
      shadowHover: {
        ...namedShadows.card1Hover,
        ...(Platform.OS === "web" && { cursor: "pointer" })
      },
      border: {
        borderWidth: 1,
        borderColor: color.neutralLight
      },
      noSpacing: { marginHorizontal: 0 },
      noRadius: { borderRadius: 0 },
      noMargin: { marginVertical: 0 },
      closeIcon: {
        fontSize: font.size.largest,
        color: color.blue
      },
      closeIconContainer: {
        padding: 10,
        position: "absolute",
        top: -2,
        right: 0,
        zIndex: 5
      },
      badgeContainer: {
        position: "absolute",
        top: -8,
        right: -8,
        zIndex: 10
      },
      badge: {
        alignItems: "center",
        justifyContent: "center",
        width: 18,
        height: 18,
        backgroundColor: color.orange,
        borderRadius: 13
      },
      badgeText: {
        color: color.white,
        fontSize: font.size.smallest
      }
    }),
    [paddingUnits]
  );
  return (
    <View
      style={[
        styles.container,
        shadow && styles.shadow,
        shadow && isHover && styles.shadowHover,
        border && styles.border,
        noSpacing && styles.noSpacing,
        noRadius && styles.noRadius,
        noMargin && styles.noMargin,
        showCloseIcon && styles.belowCloseButton,
        style
      ]}
      {...rest}
    >
      {badgeText && (
        <View style={styles.badgeContainer}>
          <View style={styles.badge}>
            <StyledText style={styles.badgeText}>{badgeText}</StyledText>
          </View>
        </View>
      )}
      {showCloseIcon && (
        <TouchableOpacity
          style={[styles.closeIconContainer, iconContainerStyle]}
          onPress={onClose}
        >
          <StyledIcon name="times" style={[styles.closeIcon, iconStyle]} />
        </TouchableOpacity>
      )}
      {children}
    </View>
  );
}
