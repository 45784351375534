import * as React from "react";

import FlexRow from "../flex/row";
import type { ViewStyleProp } from "../style";

import AdminAvatar, { type AvatarSize } from "./avatar";

interface BorderedAvatarProps {
  user: any;
  descriptionText?: string | null | undefined;
  containerStyles?: ViewStyleProp;
  stackName?: boolean;
  size?: AvatarSize;
}

export default function BorderedAvatar({
  containerStyles = {},
  stackName = false,
  size = "small",
  ...props
}: BorderedAvatarProps) {
  const { user, descriptionText } = props;

  return (
    <FlexRow alignItems="flex-start" justifyContent="flex-start">
      <AdminAvatar
        displayName
        firstName={user.firstName}
        lastName={user.lastName}
        userId={user.id}
        pictureUrl={user.avatarUrl || null}
        role={descriptionText}
      >
        {descriptionText}
      </AdminAvatar>
    </FlexRow>
  );
}
