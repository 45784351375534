import React, { PureComponent } from "react";
import { View } from "react-native";

import { type StylesProps, stylesStubs, withStyles } from "../../style";

type Props = StylesProps & {
  color: string;
  isActive: boolean;
  isBeforeStar: boolean;
};

const SIZE = 6;
@withStyles(({ color }) => ({
  bar: {
    backgroundColor: color.neutralLight,
    height: SIZE,
    flex: 1,
    marginRight: 1
  },
  lastBar: {
    marginRight: 0
  }
}))
export default class TrackBar extends PureComponent<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  render() {
    const {
      styles,
      color: backgroundColor,
      isBeforeStar,
      isActive
    } = this.props;
    return (
      <View
        style={[
          styles.bar,
          isBeforeStar && styles.lastBar,
          isActive && { backgroundColor }
        ]}
      />
    );
  }
}
