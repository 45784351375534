/**
 * @generated SignedSource<<b935b01f34da1e3b2cd2a2826590363e>>
 * @relayHash a4d5751beb8db622999c52c8cec6c1c1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:T7SdOWc8231nKjbxTSH0s5tdQv8jV4khd9ahzz-0tUE

import { ConcreteRequest } from 'relay-runtime';
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type transportationMethodScreenQuery$variables = Record<PropertyKey, never>;
export type transportationMethodScreenQuery$data = {
  readonly viewer: {
    readonly transportationMethod?: string | null | undefined;
    readonly transportationState?: WorkerTransportationState | null | undefined;
  } | null | undefined;
};
export type transportationMethodScreenQuery = {
  response: transportationMethodScreenQuery$data;
  variables: transportationMethodScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transportationMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transportationState",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "transportationMethodScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "transportationMethodScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:T7SdOWc8231nKjbxTSH0s5tdQv8jV4khd9ahzz-0tUE",
    "metadata": {},
    "name": "transportationMethodScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "91dbd83913ec177eda1f5075331d49a6";

export default node;
