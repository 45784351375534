import { Text } from "@gigsmart/atorasu";
import { BrandedHoorayAd, GenericHoorayAd } from "@gigsmart/feature-flags";
import HomeScreenCard from "@gigsmart/seibutsu/worker/HomescreenCard";
import React from "react";
import HealthcareAdCard from "../banners/HealthcareAdCard";

export default function BenefitCenterCard() {
  if (GenericHoorayAd.isEnabled() || BrandedHoorayAd.isEnabled()) {
    return <HealthcareAdCard inset />;
  }

  return (
    <HomeScreenCard
      headerIcon="book-medical"
      testID="worker-benefits-center-card"
      headerTitle="Worker Benefits Center"
      footerLinkText="Visit Benefits Center"
      footerLinkUrl="https://gigsmart.com/worker-benefits/"
    >
      <Text>
        View exclusive health care and medical cost-sharing membership offers
        from our partners and learn more about other benefits you can earn for
        being a GigSmart Worker!
      </Text>
    </HomeScreenCard>
  );
}
