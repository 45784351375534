import { ScreenScroll } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import EditprofileForm from "@gigsmart/seibutsu/worker-profile/EditProfileForm";
import React from "react";

import type { editProfileScreenQuery } from "./__generated__/editProfileScreenQuery.graphql";

export default createSuspendedQueryContainer<editProfileScreenQuery>(
  function EditProfile({ response: result }) {
    return (
      <ScreenScroll testID="edit-profile-screen" color="surface">
        <EditprofileForm fragmentRef={result?.viewer} />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query editProfileScreenQuery {
        viewer {
          ... on Worker {
            ...EditProfileForm_worker
          }
        }
      }
    `,
    variables: {}
  }
);
