import {
  ActionRow,
  CollapsibleContainer,
  ContentArea,
  FancyHeader,
  GridNull,
  Image,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import useGigLocalDateTime from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useState, type ComponentProps } from "react";
import type { UpcomingShiftsQuery } from "./__generated__/UpcomingShiftsQuery.graphql";
import type { UpcomingShiftsWorkerHomeScreenSubscription } from "./__generated__/UpcomingShiftsWorkerHomeScreenSubscription.graphql";
import type { UpcomingShifts_WorkerHomeScreen$key } from "./__generated__/UpcomingShifts_WorkerHomeScreen.graphql";

interface UpcomingShiftsWrapperProps {
  query: string;
}

type Shift = NonNullable<
  ObjectPath<
    UpcomingShifts_WorkerHomeScreen$key,
    [" $data", "upcomingShifts", "edges", 0, "node"]
  >
>;

interface UpcomingShiftsProps {
  shifts: Shift[];
}

export default createSuspendedQueryContainer<
  UpcomingShiftsQuery,
  UpcomingShiftsWrapperProps
>(
  function UpcomingShiftsWrapper({ query, response }) {
    useRelaySubscription<UpcomingShiftsWorkerHomeScreenSubscription>(
      graphql`
        subscription UpcomingShiftsWorkerHomeScreenSubscription(
          $upcomingShiftsQuery: String!
        ) {
          workerUpdated {
            worker {
              ...UpcomingShifts_WorkerHomeScreen
                @arguments(upcomingShiftsQuery: $upcomingShiftsQuery)
            }
          }
        }
      `,
      {
        upcomingShiftsQuery: query
      },
      {
        subscribe: true
      }
    );

    const shifts = getConnectionNodes(response?.viewer?.upcomingShifts);
    return shifts.length ? <UpcomingShifts shifts={shifts} /> : <GridNull />;
  },
  {
    query: graphql`
      query UpcomingShiftsQuery($upcomingShiftsQuery: String) {
        viewer {
          ... on Worker {
            upcomingShifts: engagements(
              first: 10
              query: $upcomingShiftsQuery
            ) {
              totalCount
              edges {
                node {
                  id
                  currentState {
                    id
                    name
                  }
                  endsAt
                  startsAt
                  gig {
                    name
                    description
                    timezone
                    organization {
                      logoUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ query }) => ({ upcomingShiftsQuery: query })
  }
);

export const UpcomingShifts = ({ shifts }: UpcomingShiftsProps) => {
  const history = useHistory();
  const isPositionsEnabled = PositionsAndLocations.isEnabled();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const shiftCount = shifts.length;

  if (shiftCount < 1) {
    return null;
  }

  const shiftNoun = shiftCount > 1 ? "Shifts" : "Shift";
  const headerTitle = `${shiftCount} Upcoming Hired ${shiftNoun} (Next 7 Days)`;

  const RenderShift = ({ shift }: { shift: Shift }) => {
    const time = useGigLocalDateTime(
      {
        startsAt: shift?.startsAt,
        endsAt: shift?.endsAt,
        timezone: shift.gig?.timezone
      },
      { dateFormat: "fullDateShort" }
    );

    return (
      <ActionRow
        variant="outline"
        onPress={() => history.push(`/gigs/${shift.id}`)}
        eventEntityType="PressProductInfoRow"
        {...(isPositionsEnabled && shift.gig.organization?.logoUrl
          ? {
              left: (
                <Image
                  height={36}
                  variant="square"
                  source={{
                    uri: shift.gig.organization?.logoUrl ?? ""
                  }}
                />
              )
            }
          : { icon: "business-time" })}
        iconSquareVariant="standard"
        title={shift.gig.name}
        note={`${time.dateString} ${time.timeString}`}
        pressableIconColor="primary"
        testID={shift.id}
        key={shift.id}
        color="primary"
        iconColor={isPositionsEnabled ? "primary" : "warning"}
      />
    );
  };

  const Content = ({
    startSlice,
    stopSlice,
    size
  }: {
    startSlice?: number;
    stopSlice?: number;
    size?: ComponentProps<typeof ContentArea>["size"];
  }) => (
    <ContentArea size="none">
      <Spacer size={size === "none" ? "compact" : "standard"} />
      <Stack size="compact">
        {(shifts?.slice(startSlice, stopSlice) ?? []).map((shift) => (
          <RenderShift shift={shift} key={shift.id} />
        ))}
      </Stack>
      <Spacer
        size={
          (size !== "none" && isCollapsed) || shiftCount <= 3
            ? "standard"
            : size === "none" && !isCollapsed
              ? "standard"
              : "none"
        }
      />
    </ContentArea>
  );

  return (
    <CollapsibleContainer
      testID="upcoming-shifts"
      startCollapsed
      variant="card"
      header={<FancyHeader icon="calendar-week" title={headerTitle} />}
      onSetCollapsed={setIsCollapsed}
      content={<Content startSlice={0} stopSlice={3} />}
      expandLabel="Show More"
      collapseLabel="Show Less"
    >
      {shiftCount > 3 && <Content startSlice={3} size="none" />}
    </CollapsibleContainer>
  );
};

export const UpcomingShiftsFragment = graphql`
  fragment UpcomingShifts_WorkerHomeScreen on Worker
  @argumentDefinitions(upcomingShiftsQuery: { type: "String" }) {
    upcomingShifts: engagements(first: 10, query: $upcomingShiftsQuery) {
      totalCount
      edges {
        node {
          id
          currentState {
            id
            name
          }
          endsAt
          startsAt
          gig {
            name
            description
            timezone
            organization {
              logoUrl
            }
          }
        }
      }
    }
  }
`;
