import React, { Component, type ReactNode } from "react";
import {
  Animated,
  type GestureResponderEvent,
  type LayoutChangeEvent,
  PanResponder,
  type PanResponderGestureState,
  type PanResponderInstance,
  View
} from "react-native";

import { type ViewStyleProp, createStyles, theme } from "../../style";
import SwipeCarouselIndicator from "./indicator";

const styles = createStyles(() => ({
  rowContainer: {
    overflow: "hidden"
  },
  row: {
    flexDirection: "row",
    alignItems: "center"
  }
}));

interface Props<T> {
  data: T[];
  renderItem: (item: T) => ReactNode;
  keyExtractor?: (item: T) => string;

  style?: ViewStyleProp;
  indicatorStyle?: ViewStyleProp;

  sliderWidth?: number;
  itemWidth: number;
  scaleFactor?: number;
  minDistanceToCapture: number;
  minDistanceForAction: number;
}

interface State {
  computedWidth?: number;
  activeIndex: number;
  interpolators?: Animated.AnimatedInterpolation<string | number>[];
}

export default class SwipeCarousel<T> extends Component<Props<T>, State> {
  static defaultProps: Partial<Props<any>> = {
    minDistanceToCapture: 15,
    minDistanceForAction: 0.2
  };

  private readonly panResponder: PanResponderInstance;

  private readonly pan = new Animated.Value(0);
  private animatedX = 0;
  private offsetX = 0;

  state: State = {
    activeIndex: 0
  };

  constructor(props: Props<T>) {
    super(props);
    this.panResponder = this.createPanResponder();
  }

  componentDidMount() {
    this.pan.addListener((d) => (this.animatedX = d.value));
    this.initInterpolators();
  }

  componentWillUnmount() {
    this.pan.removeAllListeners();
  }

  getSliderWidth() {
    return (
      this.props.sliderWidth ||
      this.state.computedWidth ||
      theme.metric.deviceWidth()
    );
  }

  getItemWidth() {
    return this.props.itemWidth;
  }

  getLeftPadding() {
    const { itemWidth } = this.props;
    return (this.getSliderWidth() - itemWidth) / 2;
  }

  render() {
    const { style, indicatorStyle, data } = this.props;
    const { activeIndex } = this.state;

    const single = data.length <= 1;
    const paddingLeft = this.getLeftPadding();
    const handlers = !single && this.panResponder.panHandlers;

    return (
      <>
        <View style={[styles.rowContainer, style]} onLayout={this.fixSpacing}>
          <View {...handlers} style={[styles.row, { paddingLeft }]}>
            {data.map(this.renderItem)}
          </View>
        </View>
        {!single && (
          <SwipeCarouselIndicator
            style={indicatorStyle}
            count={data.length}
            activeIndex={activeIndex}
          />
        )}
      </>
    );
  }

  private readonly renderItem = (item: T, index: number) => {
    const {
      keyExtractor,
      renderItem,
      itemWidth,
      scaleFactor = 0.8
    } = this.props;
    const { interpolators } = this.state;
    const animatedValue = interpolators?.[index];
    if (!animatedValue) return null;

    const key = keyExtractor?.(item) || `${index}`;
    const transform: any[] = [{ translateX: this.pan }];
    if (scaleFactor > 0 && scaleFactor < 1) {
      transform.push({
        scale: animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [scaleFactor, 1]
        })
      });
    }

    return (
      <Animated.View
        key={key}
        style={{ width: itemWidth, transform }}
        pointerEvents="box-none"
      >
        {renderItem(item)}
      </Animated.View>
    );
  };

  private readonly fixSpacing = ({ nativeEvent }: LayoutChangeEvent) => {
    if (!nativeEvent) return;
    const computedWidth = nativeEvent.layout.width;
    if (!this.props.sliderWidth && computedWidth !== this.state.computedWidth) {
      this.setState({ computedWidth });
    }
  };

  private readonly fixState = () => {
    this.offsetX = this.animatedX;
    this.pan.setOffset(this.animatedX);
    this.pan.setValue(0);
  };

  private createPanResponder() {
    const { minDistanceToCapture } = this.props;
    return PanResponder.create({
      onPanResponderMove: Animated.event([null, { dx: this.pan }], {
        useNativeDriver: false
      }),
      onPanResponderGrant: this.fixState,
      onMoveShouldSetPanResponderCapture: (e, state) =>
        Math.abs(state.dx) > minDistanceToCapture,

      onPanResponderTerminationRequest: () => false,
      onPanResponderRelease: this.handleGestureState
    });
  }

  private readonly handleGestureState = (
    e: GestureResponderEvent,
    gesture: PanResponderGestureState
  ) => {
    const { minDistanceForAction } = this.props;
    const correction = gesture.dx;
    const itemWidth = this.getItemWidth();
    const shouldReset = Math.abs(correction) < itemWidth * minDistanceForAction;

    const delta = shouldReset ? 0 : Math.round(correction / itemWidth) * -1;
    this.changeIndex(delta);

    return true;
  };

  private changeIndex(delta: number) {
    const { data, itemWidth } = this.props;
    const { activeIndex } = this.state;
    let newIndex = activeIndex + delta;
    if (newIndex < 0) newIndex = 0;
    else if (newIndex >= data.length) newIndex = data.length - 1;

    if (newIndex === activeIndex) {
      this.animateTo(0);
      return;
    }
    const toValue = -1 * (itemWidth * newIndex) - this.offsetX;
    this.setState({ activeIndex: newIndex });
    this.animateTo(toValue);
  }

  private animateTo(toValue: number) {
    Animated.spring(this.pan, {
      toValue,
      bounciness: 0,
      useNativeDriver: false
    }).start();
  }

  private initInterpolators() {
    const { data, itemWidth } = this.props;
    const interpolators = data.map((_, index) =>
      this.pan.interpolate({
        inputRange: [-1, 0, 1].map((range) => (index - range) * -itemWidth),
        outputRange: [0, 1, 0],
        extrapolate: "clamp"
      })
    );

    this.setState({ interpolators });
  }
}
