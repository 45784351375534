/**
 * @generated SignedSource<<4d825640dae5d5d0c3e47f9bc129b69e>>
 * @relayHash 02e1c7672fa71e0de71d6d1ecfd41db1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:FCxI_DprrezKhBckZQTAnl5ZiDWM3Rc9dfCOjok-2JQ

import { ConcreteRequest } from 'relay-runtime';
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type UpdateWorkerInput = {
  administrativeArea1?: string | null | undefined;
  allowLegalDuplicates?: boolean | null | undefined;
  biography?: string | null | undefined;
  birthdate?: string | null | undefined;
  demographic?: string | null | undefined;
  disableOtp?: boolean | null | undefined;
  firstName?: string | null | undefined;
  hubspotVid?: string | null | undefined;
  isApproved?: boolean | null | undefined;
  isAvailable?: boolean | null | undefined;
  isVolunteer?: boolean | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  minimumHourlyRate?: string | null | undefined;
  postalCode?: string | null | undefined;
  preferredTimeZone?: string | null | undefined;
  referralSource?: string | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
  transportationMethod?: string | null | undefined;
  transportationState?: WorkerTransportationState | null | undefined;
  workerId?: string | null | undefined;
};
export type EtaModalMutation$variables = {
  input: UpdateWorkerInput;
};
export type EtaModalMutation$data = {
  readonly updateWorker: {
    readonly worker: {
      readonly id: string;
    };
  } | null | undefined;
};
export type EtaModalMutation = {
  response: EtaModalMutation$data;
  variables: EtaModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorkerPayload",
    "kind": "LinkedField",
    "name": "updateWorker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Worker",
        "kind": "LinkedField",
        "name": "worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EtaModalMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EtaModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:FCxI_DprrezKhBckZQTAnl5ZiDWM3Rc9dfCOjok-2JQ",
    "metadata": {},
    "name": "EtaModalMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d6ddb77530bfdb496af731fd799b176b";

export default node;
