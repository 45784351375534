import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";

import GigCardItem from "./item";

import type { skills_gig$key } from "./__generated__/skills_gig.graphql";
import type { skills_gigSeries$key } from "./__generated__/skills_gigSeries.graphql";

interface Props {
  gigRef?: skills_gig$key | null | undefined;
  seriesRef?: skills_gigSeries$key | null | undefined;
}

const GigSkillsItem = ({ gigRef, seriesRef }: Props) => {
  const gig = useRelayFragment(
    graphql`
      fragment skills_gig on Gig {
        skills(first: 10) {
          edges {
            node {
              skill {
                name
              }
            }
          }
        }
        category {
          displayName
        }
      }
    `,
    gigRef ?? null
  );

  const series = useRelayFragment(
    graphql`
      fragment skills_gigSeries on GigSeries {
        skills(first: 10) {
          edges {
            node {
              skill {
                name
              }
            }
          }
        }
        category {
          displayName
        }
      }
    `,
    seriesRef ?? null
  );

  const skills = gig?.skills || series?.skills;
  const skillNames = getConnectionNodes(skills, (d) => d?.skill?.name);
  const category = gig?.category?.displayName || series?.category?.displayName;

  return (
    <GigCardItem iconName="box-check">
      {category ?? skillNames.join(", ")}
    </GigCardItem>
  );
};

export default GigSkillsItem;
