/**
 * @generated SignedSource<<fac2914a1ca632cd68a4918aeeb866bd>>
 * @relayHash c61c816f8d841eefe8ea3fc4918d329b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:3aU8PMOPxx1N9c-uSJSLzw7yG8GhIIFsgcSlyDLDVCo

import { ConcreteRequest } from 'relay-runtime';
export type HomescreenPendingDisputesCardQuery$variables = Record<PropertyKey, never>;
export type HomescreenPendingDisputesCardQuery$data = {
  readonly viewer: {
    readonly engagements?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly disputes: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly disputeTarget: {
                  readonly disputedFinalTimesheet?: {
                    readonly diff: {
                      readonly totalPayment: string;
                    } | null | undefined;
                  } | null | undefined;
                };
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly gig: {
            readonly name: string | null | undefined;
            readonly organization: {
              readonly logoUrl: string | null | undefined;
            } | null | undefined;
            readonly position: {
              readonly name: string;
            } | null | undefined;
          };
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type HomescreenPendingDisputesCardQuery = {
  response: HomescreenPendingDisputesCardQuery$data;
  variables: HomescreenPendingDisputesCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE anyEngagementDisputeDisposition = NULL"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE approvalDisposition = NULL"
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetComparison",
      "kind": "LinkedField",
      "name": "disputedFinalTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetDiff",
          "kind": "LinkedField",
          "name": "diff",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPayment",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomescreenPendingDisputesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": (v3/*: any*/),
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementDisputesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementDispute",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "disputeTarget",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "engagements(first:10,query:\"WHERE anyEngagementDisputeDisposition = NULL\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomescreenPendingDisputesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": (v3/*: any*/),
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementDisputesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementDispute",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "disputeTarget",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/),
                                          (v4/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v2/*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "engagements(first:10,query:\"WHERE anyEngagementDisputeDisposition = NULL\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:3aU8PMOPxx1N9c-uSJSLzw7yG8GhIIFsgcSlyDLDVCo",
    "metadata": {},
    "name": "HomescreenPendingDisputesCardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "81d96cffe976633be5e4962d55277bb8";

export default node;
