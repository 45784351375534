/**
 * @generated SignedSource<<c0e6d826b3d78f44e714b4d34efe5170>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditTimesheetForm_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly gig: {
    readonly estimatedMileage: number | null | undefined;
    readonly gigType: GigType | null | undefined;
    readonly timezone: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CollapsibleGigSchedule_gig">;
  };
  readonly startsAt: string | null | undefined;
  readonly systemTimesheet: {
    readonly estimatedMileage: number | null | undefined;
    readonly states: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: string;
          readonly action: EngagementStateAction;
          readonly id: string;
          readonly name: EngagementStateName;
          readonly overridesState?: {
            readonly id: string;
          } | null | undefined;
          readonly transitionedAt: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly timesheetVariant: {
    readonly estimatedMileage: number | null | undefined;
    readonly states: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: string;
          readonly action: EngagementStateAction;
          readonly id: string;
          readonly name: EngagementStateName;
          readonly overridesState?: {
            readonly id: string;
          } | null | undefined;
          readonly transitionedAt: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly totalDurationWorked: string | null | undefined;
  } | null | undefined;
  readonly worker: {
    readonly displayName: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EditTimesheetTimeWorkedCard_engagement">;
  readonly " $fragmentType": "EditTimesheetForm_engagement";
};
export type EditTimesheetForm_engagement$key = {
  readonly " $data"?: EditTimesheetForm_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTimesheetForm_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "overridden",
  "variableName": "overridden"
},
v1 = {
  "kind": "Variable",
  "name": "variant",
  "variableName": "variant"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v4 = {
  "kind": "Literal",
  "name": "query",
  "value": "ORDER BY transitionedAt ASC"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transitionedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "overridden"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "variant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTimesheetForm_engagement",
  "selections": [
    {
      "args": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "EditTimesheetTimeWorkedCard_engagement"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollapsibleGigSchedule_gig"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "systemTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "SYSTEM"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": "states(first:50,query:\"ORDER BY transitionedAt ASC\")"
        }
      ],
      "storageKey": "timesheet(variant:\"SYSTEM\")"
    },
    {
      "alias": "timesheetVariant",
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalDurationWorked",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": [
            (v3/*: any*/),
            (v0/*: any*/),
            (v4/*: any*/)
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "9f2a4539d160cb339bbc7d50df38a070";

export default node;
