/**
 * @generated SignedSource<<41528179b29ed234aab11f5131e6f1df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type summaryTab_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift">;
  };
  readonly id: string;
  readonly reviewOfRequester: {
    readonly rating: number;
    readonly review: string | null | undefined;
  } | null | undefined;
  readonly reviewOfWorker: {
    readonly rating: number;
    readonly review: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentSurface_shift">;
  readonly " $fragmentType": "summaryTab_engagement";
};
export type summaryTab_engagement$key = {
  readonly " $data"?: summaryTab_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"summaryTab_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rating",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "review",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "summaryTab_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerPaymentSurface_shift"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "reviewOfRequester",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "reviewOfWorker",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "BlockOrganizationCollapsibleRow_shift",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gigType",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "type": "GigLike",
              "abstractKey": "__isGigLike"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "a79b65760d71917f5f8090fa3f2ff3eb";

export default node;
