import React, { Component, type ReactNode } from "react";
import { Animated, Dimensions, Easing } from "react-native";

import { type StylesProps, stylesStubs, withStyles } from "../style";

type Props = StylesProps & {
  children: ReactNode;
};
@withStyles(() => ({
  customModal: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0
  }
}))
export default class Modal extends Component<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  progress: Animated.Value = new Animated.Value(0);

  animate = (toValue: number) => {
    Animated.timing(this.progress, {
      easing: Easing.linear,
      duration: 800,
      toValue,
      useNativeDriver: false
    }).start();
  };

  componentDidMount() {
    this.animate(100);
  }

  get progressStyles() {
    const { height } = Dimensions.get("window");
    return {
      transform: [
        {
          translateY: this.progress.interpolate({
            inputRange: [0, 100],
            outputRange: [-height, 0]
          })
        }
      ]
    };
  }

  get customModalStyles() {
    console.debug(this.props.styles.customModal);
    return [this.props.styles.customModal, this.progressStyles];
  }

  render() {
    return (
      <Animated.View style={this.customModalStyles}>
        {this.props.children}
      </Animated.View>
    );
  }
}
