import React from "react";

import { useHistory } from "@gigsmart/kaizoku";
import {
  DataRow,
  SmallOutlineButton,
  SmallSolidButton
} from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { paymentMethod_payableAccount$key } from "./__generated__/paymentMethod_payableAccount.graphql";
import { formatPaymentMethod } from "./helpers";

interface Props {
  payableAccount: paymentMethod_payableAccount$key | null | undefined;
}

export default ({ payableAccount }: Props) => {
  const account = useRelayFragment(
    graphql`
      fragment paymentMethod_payableAccount on WorkerPayableAccount {
        paymentMethod {
          __typename
          last4
          ... on BankCard {
            brand
          }
          ... on BankAccount {
            bankName
          }
        }
      }
    `,
    payableAccount
  );
  const history = useHistory();
  const navigateToPayment = () =>
    history.push({
      pathname: "/wallet/payment"
    });
  if (!account?.paymentMethod?.last4) {
    return (
      <DataRow
        label="No Account Added"
        iconName="circle-exclamation"
        color="red"
        data={
          <SmallSolidButton
            title="Add"
            icon="plus"
            onPress={navigateToPayment}
          />
        }
      />
    );
  }
  return (
    <DataRow
      label={formatPaymentMethod(account.paymentMethod)}
      data={
        <SmallOutlineButton
          title="Update"
          testID="update-btn"
          onPress={navigateToPayment}
        />
      }
    />
  );
};
