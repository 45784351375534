import React from "react";

import { ContentArea, useStepper } from "@gigsmart/atorasu";
import { Form, FormSubmit, FormValue } from "@gigsmart/fomu";
import EngagementBidForm from "@gigsmart/seibutsu/engagement/EngagementBidForm";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import StepperNavActions from "../../shared/Stepper/StepperNavActions";

interface Props {
  gigId: string;
}

export default function BidStep({ gigId }: Props) {
  const { nextStep, stepsData } = useStepper<Step, {}>();
  return (
    <Form
      initialValues={stepsData}
      onSubmit={({ values }) =>
        nextStep({ ...stepsData, ...(getValues(values) ?? null) })
      }
    >
      <StepperNavActions />
      <ContentArea fill>
        <EngagementBidForm />
      </ContentArea>
      <FormValue name="paySchedule">
        {({ value }) => (
          <FormSubmit>
            {({ submit, invalid }) => (
              <ResponsiveStepperBtnPortal.Entrance
                testID="submit-bid"
                label={getButtonText(value)}
                onPress={submit}
                disabled={invalid}
              />
            )}
          </FormSubmit>
        )}
      </FormValue>
    </Form>
  );
}

function getButtonText(value: string) {
  if (value === "HOURLY") return "Continue with Hourly Rate Bid";
  if (value === "FIXED") return "Continue with Flat Rate Bid";
  if (value === "INFO_REQUIRED") return "Continue with Not Enough Info";
  return "No Option Selected";
}

function getValues(values: {
  paySchedule?: string;
  payRate?: string;
  expectedDuration?: string;
  estimatedAmount?: string;
  note?: string;
}) {
  if (values?.paySchedule === "HOURLY")
    return {
      paySchedule: "HOURLY",
      payRate: values?.payRate,
      expectedDuration: values?.expectedDuration,
      note: values?.note ?? null,
      estimatedAmount: null
    };
  if (values?.paySchedule === "FIXED")
    return {
      paySchedule: "FIXED",
      estimatedAmount: values?.estimatedAmount,
      note: values?.note ?? null,
      payRate: null,
      expectedDuration: null
    };
  if (values?.paySchedule === "INFO_REQUIRED")
    return {
      paySchedule: "INFO_REQUIRED",
      note: values?.note ?? null,
      estimatedAmount: null,
      payRate: null,
      expectedDuration: null
    };
  return {
    note: values?.note ?? null
  };
}
