import { GridList, type GridListProps } from "@gigsmart/atorasu";
import type { GraphQLTaggedNode, KeyType, QuerySpec } from "@gigsmart/relay";
import React from "react";
import useInfiniteList, { type Options } from "./useInfiniteList";

export type Props<Q extends QuerySpec, TKey extends KeyType, T> = Omit<
  GridListProps<T>,
  "data" | "grow"
> &
  Options<Q, TKey, T> & {
    fragmentInput: GraphQLTaggedNode;
    parentRef: TKey | null | undefined;
    testID: string;
    grow?: boolean | "auto";
  };

export default function InfiniteGridList<
  Q extends QuerySpec,
  TKey extends KeyType,
  T
>({
  fragmentInput,
  parentRef,
  getData,
  pageSize,
  refetchDebounce,
  refetchVars,
  renderEmptyView,
  renderFooterView,
  renderHeaderView,
  grow = "auto",
  ...props
}: Props<Q, TKey, T>) {
  const listProps = useInfiniteList<Q, TKey, T>(fragmentInput, parentRef, {
    getData,
    pageSize,
    refetchDebounce,
    refetchVars,
    renderEmptyView,
    renderFooterView,
    renderHeaderView
  });

  // hack so we don't break the loadMore functionaliyy
  if (grow === "auto") grow = !listProps.hasNext;
  return <GridList<T> {...listProps} {...props} grow={grow} />;
}
