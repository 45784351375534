import React, { Component, type ReactNode } from "react";
import { View } from "react-native";
import {
  type BreakpointStylesFn,
  type StylesProps,
  stylesStubs,
  withStyles
} from "../style";
import TabLabel from "./label";

const TabContainerStyles: BreakpointStylesFn = ({ color, font, units }) => ({
  container: {
    width: "100%"
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row"
  },
  label: {
    flexGrow: 0,
    flexShrink: 1,
    paddingVertical: units(2),
    paddingRight: units(4),
    marginRight: units(4),
    borderTopColor: color.neutralMedium,
    borderTopWidth: units(1)
  },
  labelText: {
    ...font.body("bold"),
    color: color.neutralMedium,
    textTransform: "uppercase"
  },
  labelActive: {
    borderTopColor: color.blue
  },
  labelTextActive: {
    color: color.blue
  },
  contentsContainer: {
    position: "relative"
  },
  contents: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    opacity: 0
  },
  contentsActive: {
    zIndex: 1,
    opacity: 1
  }
});

interface Tab {
  id: string;
  label: string;
  contents: ReactNode;
  active?: boolean;
  testID?: string;
}

type Props = StylesProps & {
  tabs: Tab[];
  tabContentHeight?: number;
};

interface State {
  tabs: Tab[];
}
@withStyles(TabContainerStyles)
export default class TabContainer extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    tabContentHeight: 100
  };

  state: State = {
    tabs: []
  };

  componentDidMount = () => {
    this.setupStateTabs();
  };

  setupStateTabs = () => {
    const { tabs } = this.props;

    this.setState({ tabs });
  };

  changeTab = (id: string) => {
    const { tabs } = this.props;
    const newTabs: Props["tabs"] = [];
    let currentTab;

    for (let tab = 0, l = tabs.length; tab < l; tab++) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      currentTab = { ...tabs[tab]! };

      if (tabs[tab]?.active && id !== tabs[tab]?.id) {
        currentTab.active = false;
      } else if (!tabs[tab]?.active && id === tabs[tab]?.id) {
        currentTab.active = true;
      }

      newTabs.push(currentTab);
    }

    this.setState({
      tabs: newTabs
    });
  };

  containerHeight = () => {
    const { tabContentHeight } = this.props;

    return { height: tabContentHeight };
  };

  render() {
    const { tabs } = this.state;
    const { styles } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.labelContainer}>
          {tabs.length > 0 &&
            tabs.map(({ id, label, active, testID }) => (
              <TabLabel
                key={`tabLabel_${id}`}
                testID={testID ?? `tabLabel_${id}`}
                style={
                  active ? [styles.label, styles.labelActive] : styles.label
                }
                textStyle={
                  active
                    ? [styles.labelText, styles.labelTextActive]
                    : styles.labelText
                }
                onPress={() => this.changeTab(id)}
              >
                {label}
              </TabLabel>
            ))}
        </View>
        <View style={[styles.contentsContainer, this.containerHeight()]}>
          {tabs.length > 0 &&
            tabs.map(({ id, contents, active }) => (
              <View
                key={`tabContents_${id}`}
                style={
                  active
                    ? [styles.contents, styles.contentsActive]
                    : styles.contents
                }
              >
                {contents}
              </View>
            ))}
        </View>
      </View>
    );
  }
}
