/**
 * @generated SignedSource<<17ac0e54cc92c66a2d4ab80e5835caed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerSummaryLocation_shift$data = {
  readonly area: string | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly nextGig?: {
    readonly edges: ReadonlyArray<{
      readonly distance: number | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly organizationLocation: {
    readonly arrivalInstructions?: string | null | undefined;
    readonly place: {
      readonly area: string | null | undefined;
      readonly id: string;
      readonly location?: {
        readonly latitude: number;
        readonly longitude: number;
      } | null | undefined;
      readonly streetAddress?: string | null | undefined;
    };
  } | null | undefined;
  readonly " $fragmentType": "WorkerSummaryLocation_shift";
};
export type WorkerSummaryLocation_shift$key = {
  readonly " $data"?: WorkerSummaryLocation_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerSummaryLocation_shift">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isBrowsing"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerSummaryLocation_shift",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationLocation",
      "kind": "LinkedField",
      "name": "organizationLocation",
      "plural": false,
      "selections": [
        {
          "condition": "isBrowsing",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "arrivalInstructions",
              "storageKey": null
            }
          ]
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Place",
          "kind": "LinkedField",
          "name": "place",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "condition": "isBrowsing",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "streetAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Location",
                  "kind": "LinkedField",
                  "name": "location",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latitude",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "longitude",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "nextGig",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AvailableGigsConnection",
          "kind": "LinkedField",
          "name": "availableGigs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailableGigsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "distance",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "availableGigs(first:1)"
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "199499efe077ce144ab7186d912d87c7";

export default node;
