import { captureError } from "@gigsmart/dekigoto";
import { type DependencyList, useEffect } from "react";
import type { Config, State } from "react-native-background-geolocation";
import type { WorkerLocationRationale } from "./__generated__/GeolocationProvider_workerLocation.graphql";

export const useEffectAfterReady = (
  fn: (state: State) => void | Promise<void>,
  deps: DependencyList
) =>
  useEffect(() => {
    void (async () => {
      try {
        await fn({
          enabled: false,
          schedulerEnabled: false,
          trackingMode: 1,
          isMoving: false,
          odometer: 0,
          didLaunchInBackground: false,
          didDeviceReboot: false
        });
      } catch (error) {
        captureError(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

export const reportLocation = async (
  _rationale: WorkerLocationRationale,
  _desiredAccuracy?: number
) => {};

export const init = () => {};
export const start = () => {};
export const setConfig = async (_config: Config) => {};
