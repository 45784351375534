import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import { View } from "react-native";

import {
  ContentArea,
  HighlightedReminder,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { getEngagementAddonSpec } from "@gigsmart/isomorphic-shared/addon/dictionary";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import { DataRow, StyledLink } from "@gigsmart/katana";

import { PositionsAndLocations } from "@gigsmart/feature-flags";
import type { gigPaymentRows_availableGig$key } from "./__generated__/gigPaymentRows_availableGig.graphql";
import type { gigPaymentRows_engagement$key } from "./__generated__/gigPaymentRows_engagement.graphql";
import type { gigPaymentRows_paymentInfo$key } from "./__generated__/gigPaymentRows_paymentInfo.graphql";

interface Props {
  availableGig?: gigPaymentRows_availableGig$key | null | undefined;
  engagement?: gigPaymentRows_engagement$key | null | undefined;
}

export default function GigPaymentRows({
  availableGig: availableGigRef,
  engagement: engagementRef
}: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    paymentSubRow: {
      marginLeft: getUnits(7)
    },
    rowBorder: {
      marginLeft: getUnits(4),
      borderBottomWidth: 1,
      borderBottomColor: getColor("background", "fill")
    },
    withMarginLeft: {
      marginLeft: getUnits(4)
    }
  }));

  const availableGig = useRelayFragment<gigPaymentRows_availableGig$key>(
    graphql`
      fragment gigPaymentRows_availableGig on AvailableGigsEdge {
        estimatedPayment {
          ...gigPaymentRows_paymentInfo
        }
        node {
          gigSeries {
            acceptsTips
          }
        }
      }
    `,
    availableGigRef ?? null
  );

  const engagement = useRelayFragment<gigPaymentRows_engagement$key>(
    graphql`
      fragment gigPaymentRows_engagement on Engagement {
        gig {
          gigSeries {
            acceptsTips
          }
        }
        currentState {
          name
        }
        estimatedPayment {
          ...gigPaymentRows_paymentInfo
        }
        paymentInfo {
          ...gigPaymentRows_paymentInfo
        }
      }
    `,
    engagementRef ?? null
  );

  const paymentInfo = useRelayFragment<gigPaymentRows_paymentInfo$key>(
    graphql`
      fragment gigPaymentRows_paymentInfo on EngagementPaymentInfo {
        payRate
        paySchedule
        billableDuration
        grossPay
        netPay
        serviceFees {
          amount
          hourlyRate
          feeType
        }
      }
    `,
    engagement?.paymentInfo ??
      engagement?.estimatedPayment ??
      availableGig?.estimatedPayment ??
      null
  );

  const isPayable = !!engagement?.paymentInfo;
  const isEstimated =
    !isPayable &&
    !!(engagement?.estimatedPayment ?? availableGig?.estimatedPayment);
  const labelPrefix = isEstimated ? "Estimated " : "";

  const showTips =
    engagement?.gig?.gigSeries?.acceptsTips ||
    availableGig?.node?.gigSeries?.acceptsTips;

  const durationString = duration.humanize(
    paymentInfo?.billableDuration,
    "compact-no-seconds"
  );

  return (
    <View style={styles.paymentSubRow}>
      <View style={[styles.rowBorder, styles.withMarginLeft]} />

      {paymentInfo?.grossPay !== "0 USD" && (
        <DataRow
          noBorder
          label={`${labelPrefix}Hourly Pay`}
          secondaryTitle={`${currency.humanize(
            paymentInfo?.payRate
          )}/hr (${durationString})`}
          data={currency.humanize(paymentInfo?.grossPay)}
          justifyData="flex-start"
        />
      )}
      {paymentInfo?.serviceFees?.map((fee, idx) => {
        const addonSpec = getEngagementAddonSpec(fee?.feeType);
        const secondaryTitleUrl = fee?.hourlyRate ? "" : addonSpec.linkUrl;
        if (isPayable) return null;

        const rateRow = () => (
          <Row>
            <Text variant="note" color="neutral">
              {`${currency.humanize(fee?.hourlyRate)}/hr (${durationString}) `}
            </Text>
            <StyledLink fontSize={12} openURL={addonSpec.linkUrl}>
              {addonSpec.linkText}
            </StyledLink>
          </Row>
        );

        return (
          <DataRow
            key={fee?.feeType ?? `idx-${idx}`}
            noBorder
            label={labelPrefix + addonSpec.name}
            secondaryTitle={fee?.hourlyRate ? rateRow() : addonSpec.linkText}
            secondaryTitleUrl={secondaryTitleUrl}
            data={`- ${currency.humanize(fee.amount)}`}
            justifyData="flex-start"
          />
        );
      })}
      {paymentInfo?.netPay !== "0 USD" && (
        <DataRow
          noBorder
          label={`${labelPrefix}Total Earnings`}
          data={`${currency.humanize(paymentInfo?.netPay)}`}
          justifyData="flex-start"
        />
      )}
      {isEstimated && (
        <>
          <ContentArea size="none">
            <HighlightedReminder
              header="Estimated Earnings are not guaranteed. Earnings are based on approved time worked from your finalized Timesheet."
              icon="circle-info"
            />
          </ContentArea>
          <Spacer />
        </>
      )}
      {showTips ? (
        <>
          <ContentArea size="none">
            <HighlightedReminder
              variant="info"
              header={PositionsAndLocations.select(
                "Tips are commonly paid out to Workers who work this Shift.",
                "Tips are commonly paid out to Workers who work this Shift Gig."
              )}
              icon="hand-holding-dollar"
            />
          </ContentArea>
          <ContentArea size="medium">{null}</ContentArea>
        </>
      ) : null}
    </View>
  );
}
