import { Divider, Stack, Text } from "@gigsmart/atorasu";
import { KatanaLoadingView, StyledView } from "@gigsmart/katana";
import React from "react";

interface Props {
  hasMore: boolean;
  showFooter: boolean;
}

export default function JobsListFooter({ hasMore, showFooter }: Props) {
  if (hasMore) return <KatanaLoadingView />;
  if (showFooter) return null;
  return (
    <StyledView color="transparent">
      <Stack>
        <Divider />
        <Text align="center">
          You've reached the bottom of your Job Board list.
        </Text>
      </Stack>
    </StyledView>
  );
}
