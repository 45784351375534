import { ContentArea, IconText, Text } from "@gigsmart/atorasu";
import moment from "moment";
import React from "react";

interface Props {
  insertedAt?: string;
}

export const GigAppliedOn = ({ insertedAt }: Props) => (
  <ContentArea size="compact" color="surface">
    <IconText iconAlign="center" icon="user-check" size="small">
      Applied on:{" "}
      <Text weight="bold">{moment(insertedAt).format("MMM Do, YYYY")}</Text>
    </IconText>
  </ContentArea>
);
