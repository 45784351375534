import React from "react";

import { Modal } from "@gigsmart/atorasu";

import { PaymentScreen } from "./index";

interface Props {
  showPaymentModal: boolean;
  hidePaymentModal: () => void;
}

export default function PaymentModal({
  showPaymentModal,
  hidePaymentModal
}: Props) {
  return (
    <Modal
      full
      eventContext="Missing Payment Modal"
      header={{
        title: "Missing Payment Information",
        body: "Please add payment information so that funds can be sent to your account"
      }}
      visible={showPaymentModal}
      hasCancelIcon
      onClose={hidePaymentModal}
    >
      <PaymentScreen onSave={hidePaymentModal} />
    </Modal>
  );
}
