/**
 * @generated SignedSource<<dc8fc1155f48a6176afae52779ba50ec>>
 * @relayHash 8caf267dad8fe043c06236319ef1e861
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:0u8LWO1NZxjMHWf6oN0c3W080L8lADceUP2HhqoyYKw

import { ConcreteRequest } from 'relay-runtime';
export type ConversationStatus = "CLOSED" | "OPEN" | "%future added value";
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
export type SetGigLikeConversationRuleInput = {
  conversationRule: GigConversationRule;
  gigLikeId: string;
};
export type RequesterMoreMenuUpdateShiftGroupChatMutation$variables = {
  input: SetGigLikeConversationRuleInput;
};
export type RequesterMoreMenuUpdateShiftGroupChatMutation$data = {
  readonly setGigLikeConversationRule: {
    readonly gigLike: {
      readonly conversation?: {
        readonly currentMessage: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly participant: {
          readonly messageStats: {
            readonly unreadCount: number;
          } | null | undefined;
        } | null | undefined;
        readonly status: ConversationStatus;
      } | null | undefined;
      readonly conversationRule: GigConversationRule;
      readonly id: string;
    };
  } | null | undefined;
};
export type RequesterMoreMenuUpdateShiftGroupChatMutation = {
  response: RequesterMoreMenuUpdateShiftGroupChatMutation$data;
  variables: RequesterMoreMenuUpdateShiftGroupChatMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversationRule",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserMessageStats",
  "kind": "LinkedField",
  "name": "messageStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserMessage",
  "kind": "LinkedField",
  "name": "currentMessage",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequesterMoreMenuUpdateShiftGroupChatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetGigLikeConversationRulePayload",
        "kind": "LinkedField",
        "name": "setGigLikeConversationRule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "gigLike",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShiftGroupConversation",
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConversationParticipant",
                        "kind": "LinkedField",
                        "name": "participant",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Gig",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequesterMoreMenuUpdateShiftGroupChatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetGigLikeConversationRulePayload",
        "kind": "LinkedField",
        "name": "setGigLikeConversationRule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "gigLike",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isGigLike"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShiftGroupConversation",
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConversationParticipant",
                        "kind": "LinkedField",
                        "name": "participant",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Gig",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:0u8LWO1NZxjMHWf6oN0c3W080L8lADceUP2HhqoyYKw",
    "metadata": {},
    "name": "RequesterMoreMenuUpdateShiftGroupChatMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8d96e6d62ca2ae826f8fbe9d4525ebda";

export default node;
