import React, { type ReactNode } from "react";
import { View } from "react-native";

import { useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  progress?: number | null;
  height: number;
  rightContent?: ReactNode;
}

export default function ProgressBar({ height, progress, rightContent }: Props) {
  const { styles } = useStyles(({ color, unit }) => ({
    container: {
      borderRadius: 3,
      backgroundColor: color.neutralMedium,
      width: "100%"
    },
    progress: {
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
      backgroundColor: color.blue
    },
    fullBar: {
      borderRadius: 3,
      backgroundColor: color.blue,
      width: "100%"
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: unit.margin.medium
    }
  }));

  return (
    <>
      {!progress || progress < 100 ? (
        <View style={[styles.container, { height }]}>
          <View
            style={[styles.progress, { height, width: `${progress || 0}%` }]}
          />
        </View>
      ) : (
        <View style={[styles.fullBar, { height }]} />
      )}
      <View style={styles.footer}>
        <KatanaText>{progress}% Complete</KatanaText>
        {rightContent}
      </View>
    </>
  );
}
