import {
  ActionRow,
  Button,
  Column,
  ContentArea,
  HighlightedReminder,
  Stack,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { ClaimOrganizationSurface_DirectHireClaim$key } from "./__generated__/ClaimOrganizationSurface_DirectHireClaim.graphql";
import type { DirectHireClaimsStepper } from "./index";

interface Props {
  variant?: "review" | "standard";
}

export function ClaimOrganizationSurface({
  firstName,
  lastName,
  place,
  directHireType,
  directHireDate,
  organization,
  variant = "standard"
}: FragmentContainerInnerComponentProps<
  ClaimOrganizationSurface_DirectHireClaim$key,
  Props
>) {
  const { gotoStep, stepsData } = useStepper<{}, DirectHireClaimsStepper>();
  const isHire = directHireType === "HIRE";
  return (
    <Surface>
      <ActionRow
        testID="requester-organization-row"
        title={
          <Text color="primary" weight="semibold">
            Requester Organization{" "}
            {!firstName && (
              <Text color="danger" weight="semibold">
                (Required)
              </Text>
            )}
          </Text>
        }
        icon="user-tie"
        right={
          firstName ? (
            <Button
              testID="edit-organization-btn"
              size="small"
              label="Edit"
              icon="pen-to-square"
              outline
              onPress={() =>
                variant === "review"
                  ? gotoStep("organization", {
                      ...stepsData,
                      returnTo: "review"
                    })
                  : gotoStep("organization")
              }
            />
          ) : undefined
        }
      />
      <ContentArea>
        {!firstName && (
          <Stack>
            <HighlightedReminder
              icon="circle-exclamation"
              header={`You must add the Organization that directly ${
                isHire ? "hired you" : "paid you outside the app"
              }.`}
            />
            <Column alignItems="center">
              <Button
                testID="add-organization-btn"
                size="small"
                icon="plus"
                label="Add Organization"
                onPress={() => gotoStep("organization")}
              />
            </Column>
          </Stack>
        )}
        {firstName && (
          <Stack size="compact">
            <Stack horizontal justifyContent="space-between" fill>
              <Text>Organization</Text>
              <Text numberOfLines={1} fill align="right" weight="semibold">
                {organization?.name}
              </Text>
            </Stack>
            <Stack horizontal justifyContent="space-between" fill>
              <Text>Person who {isHire ? "Hired" : "Paid"}</Text>
              <Text numberOfLines={1} fill align="right" weight="semibold">
                {lastName}, {firstName}
              </Text>
            </Stack>
            <Stack horizontal justifyContent="space-between" fill>
              <Text fill={0}>
                {isHire ? "Where you were Hired" : "Work Location"}
              </Text>
              <Text numberOfLines={1} fill align="right" weight="semibold">
                {place?.streetAddress}
              </Text>
            </Stack>
            <Stack horizontal justifyContent="space-between" fill>
              <Text>{isHire ? "Hire" : "Payment"} Date</Text>
              <Text numberOfLines={1} fill align="right" weight="semibold">
                {time.humanize(directHireDate, "dateCompact")}
              </Text>
            </Stack>
          </Stack>
        )}
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<
  ClaimOrganizationSurface_DirectHireClaim$key,
  Props
>(
  graphql`
    fragment ClaimOrganizationSurface_DirectHireClaim on DirectHireClaim {
      id
      directHireType
      firstName
      lastName
      directHireDate
      place {
        streetAddress
      }
      organization {
        name
      }
    }
  `,
  ClaimOrganizationSurface
);
