import React from "react";

import { Divider, Row, Spacer, Text } from "@gigsmart/atorasu";
import { DataRow, StyledLink, StyledView } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import PaymentMethod from "../payment-method";
import type { tabContent_workerPayableAccount$key } from "./__generated__/tabContent_workerPayableAccount.graphql";

interface Props {
  readonly selectedTab: number;
  readonly payableAccount: tabContent_workerPayableAccount$key;
  readonly fees: string;
}

export default ({ selectedTab, payableAccount, fees }: Props) => {
  const account = useRelayFragment(
    graphql`
      fragment tabContent_workerPayableAccount on WorkerPayableAccount {
        ...paymentMethod_payableAccount
      }
    `,
    payableAccount
  );
  return (
    <StyledView verticalPadding={0}>
      <Spacer />
      <Text weight="bold">Transfer To</Text>
      <Spacer size="slim" />
      <Divider />
      <PaymentMethod payableAccount={account} />
      {selectedTab === 0 && (
        <>
          <Spacer />
          <Text weight="bold">Fees</Text>
          <Spacer size="slim" />
          <Divider />
          <DataRow
            label="Rapid Fee (3%)"
            secondaryTitle="$1 Minimum Fee"
            data={`$${fees}`}
          />
        </>
      )}
      <Spacer />
      <Text>
        {selectedTab === 0
          ? "Transfer speeds depend on your bank and can take anywhere from minutes up to 2 business days. Transfers are reviewed and may result in funds being delayed or removed from your GigSmart account."
          : "Transfer speeds depend on your bank and can take anywhere from 2 - 5 business days. Transfers are reviewed and may result in funds being delayed or removed from your GigSmart account."}
      </Text>
      <Spacer />
      <Row justifyContent="center">
        <StyledLink openURL="https://help.gigsmart.com/">
          Learn more about transfers
        </StyledLink>
      </Row>
    </StyledView>
  );
};
