/**
 * @generated SignedSource<<a51be923c21b900c400436a3580206f3>>
 * @relayHash b2a22c1da50bbe272a39876ce136832f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:nuVza-NQiNEbMNsr7xkF_b2apHophSSC8_24F6vXf5w

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePendingOfferCountSubscription$variables = {
  workerId: string;
};
export type usePendingOfferCountSubscription$data = {
  readonly workerUpdated: {
    readonly worker: {
      readonly " $fragmentSpreads": FragmentRefs<"usePendingOfferCount_worker">;
    };
  } | null | undefined;
};
export type usePendingOfferCountSubscription = {
  response: usePendingOfferCountSubscription$data;
  variables: usePendingOfferCountSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workerId",
    "variableName": "workerId"
  }
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v4 = {
  "_neq": "PROJECT"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePendingOfferCountSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "usePendingOfferCount_worker"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePendingOfferCountSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "allOffers",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "OFFERED",
                          "BID_REQUESTED"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\",\"BID_REQUESTED\"]}})"
              },
              {
                "alias": "projectOffers",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_eq": "OFFERED"
                      },
                      "gigType": {
                        "_eq": "PROJECT"
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_eq\":\"OFFERED\"},\"gigType\":{\"_eq\":\"PROJECT\"}})"
              },
              {
                "alias": "pendingCount",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "OFFERED"
                        ]
                      },
                      "gigType": (v4/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              },
              {
                "alias": "bidRequestsCount",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "BID_REQUESTED"
                        ]
                      },
                      "gigType": (v4/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"BID_REQUESTED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:nuVza-NQiNEbMNsr7xkF_b2apHophSSC8_24F6vXf5w",
    "metadata": {},
    "name": "usePendingOfferCountSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "29b6e159d5628e33db97e47024a6e672";

export default node;
