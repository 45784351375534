import {
  ContentArea,
  ContentRow,
  Dimmable,
  Divider,
  Radio,
  Row,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { WorkerShiftCardUI } from "@gigsmart/feature-flags";
import {
  GigDateTimeContent,
  type gigHelpers
} from "@gigsmart/isomorphic-shared/gig";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useInfiniteList } from "@gigsmart/isomorphic-shared/list";
import { getConnectionNodes, graphql } from "@gigsmart/relay";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "@gigsmart/seibutsu/gig-series/WorkerLatestArrivalTimeReminder";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { memo, useMemo } from "react";
import { FlatList, View } from "react-native";

// eslint-disable-next-line no-restricted-imports
import { useStyles } from "@gigsmart/atorasu/style";

import type { EngagementActionTablePageQuery } from "./__generated__/EngagementActionTablePageQuery.graphql";
import type { engagementActionTable_series$key } from "./__generated__/engagementActionTable_series.graphql";

type EngagementType = NonNullable<
  ObjectPath<
    engagementActionTable_series$key,
    [" $data", "engagements", "edges", 0, "node"]
  >
>;

const fragmentSpec = graphql`
  fragment engagementActionTable_series on GigSeries
  @refetchable(queryName: "EngagementActionTablePageQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 90 }
    after: { type: "String" }
    query: { type: "String!" }
  ) {
    engagements(first: $count, after: $after, query: $query)
      @connection(
        key: "engagementActionTable_series_engagements"
        filters: ["query"]
      ) {
      edges {
        node {
          id
          workerDistance
          availableActions
          estimatedPayment {
            netPay
          }
          gig {
            ...WorkerLatestArrivalTimeReminder_gig
            startsAt
            endsAt
            area
          }
        }
      }
    }
  }
`;

interface Props {
  seriesRef: engagementActionTable_series$key | null | undefined;
  hasMultipleLocations?: boolean;
  actions: gigHelpers.EngagementStateAction[];
  lockedAction: gigHelpers.EngagementStateAction;
  lockedMessage: string;
  conflictingAction: gigHelpers.EngagementStateAction;
  cellWidth?: number;
  selected?: Record<string, gigHelpers.EngagementStateAction>;
  onChange?: (
    selected: Record<string, gigHelpers.EngagementStateAction>
  ) => void;
  stickyHeader?: boolean;
}

const EngagementActionTable = ({
  seriesRef,
  hasMultipleLocations,
  actions,
  lockedMessage,
  lockedAction,
  conflictingAction,
  cellWidth = 56,
  selected,
  onChange,
  stickyHeader = true
}: Props) => {
  const styles = useStyles(
    ({ getUnits }) => ({
      mainCell: { flex: 1, alignSelf: "center" },
      cell: {
        width: cellWidth,
        alignItems: "center",
        justifyContent: "center",
        marginLeft: getUnits(2)
      },
      lockedTr: { opacity: 0.5 },
      lockedMessage: {
        marginTop: -getUnits(1),
        marginBottom: getUnits(2),
        paddingHorizontal: getUnits(4),
        fontSize: 12
      },
      arrivalTime: {
        marginTop: -getUnits(1),
        paddingHorizontal: getUnits(4),
        paddingBottom: getUnits(4)
      }
    }),
    [cellWidth]
  );

  const header = (
    <ContentArea color="foreground" size="medium">
      <Row>
        <Text fill weight="bold" color="neutral">
          SHIFT
        </Text>
        {actions.map((action) => (
          <Text
            key={action}
            weight="bold"
            align="center"
            color="neutral"
            style={styles.cell}
            numberOfLines={1}
          >
            {action}
          </Text>
        ))}
      </Row>
    </ContentArea>
  );

  const { data, ...listProps } = useInfiniteList<
    EngagementActionTablePageQuery,
    engagementActionTable_series$key,
    EngagementType
  >(fragmentSpec, seriesRef, {
    getData: (data) => getConnectionNodes(data?.engagements)
  });

  const conflictMap = useMemo(() => createConflictMap(data), [data]);
  return (
    <FlatList
      {...listProps}
      data={data}
      extraData={selected}
      keyExtractor={(item) => item.id}
      stickyHeaderIndices={stickyHeader ? [0] : undefined}
      ItemSeparatorComponent={ItemSeparator}
      ListHeaderComponent={header}
      renderItem={({ item }) => {
        const locked = isLocked(conflictingAction, item, conflictMap, selected);
        const selectedAction = locked ? lockedAction : selected?.[item.id];
        const handleChange = (newAction: gigHelpers.EngagementStateAction) => {
          if (locked || selectedAction === newAction || !onChange) return;
          const newSelected = { ...selected, [item.id]: newAction };
          if (newAction === conflictingAction) {
            conflictMap
              .get(item.id)
              ?.forEach((id) => (newSelected[id] = lockedAction));
          }
          onChange(newSelected);
        };

        return (
          <>
            <Dimmable dim={locked}>
              <ContentRow>
                <Stack fill size="small">
                  <GigDateTimeContent
                    small
                    startsAt={item.gig?.startsAt}
                    endsAt={item.gig?.endsAt}
                    distance={item.workerDistance}
                    address={hasMultipleLocations ? item.gig?.area : null}
                  />
                  {WorkerShiftCardUI.isEnabled() && (
                    <Text variant="note" color="neutral">
                      Estimated Earnings:{" "}
                      <Text weight="bold">
                        {currency.humanize(item.estimatedPayment?.netPay, {
                          integer: true
                        })}
                      </Text>
                    </Text>
                  )}
                </Stack>
                {actions.map((action) => (
                  <View style={styles.cell} key={action}>
                    <Radio
                      testID={`${action}-radio`}
                      eventTargetName="Engagement Action Radio"
                      selected={action === selectedAction}
                      onSelect={() => handleChange(action)}
                    />
                  </View>
                ))}
              </ContentRow>
              {showLatestArrivalReminder(item.gig) && (
                <View style={styles.arrivalTime}>
                  <WorkerLatestArrivalTimeReminder fragmentRef={item.gig} />
                </View>
              )}
            </Dimmable>
            {locked && (
              <Text color="danger" style={styles.lockedMessage}>
                {lockedMessage}
              </Text>
            )}
          </>
        );
      }}
    />
  );
};

const ItemSeparator = () => <Divider />;

export default memo(
  EngagementActionTable,
  (prevProps, nextProps) =>
    prevProps?.seriesRef === nextProps?.seriesRef &&
    prevProps?.selected === nextProps?.selected
);

const isLocked = (
  conflictingAction: gigHelpers.EngagementStateAction,
  eng: EngagementType,
  conflictMap: Map<string, string[]>,
  selected?: Record<string, gigHelpers.EngagementStateAction>
) => {
  if (selected?.[eng.id] !== conflictingAction) {
    return !!conflictMap
      .get(eng.id)
      ?.find((otherEngId) => selected?.[otherEngId] === conflictingAction);
  }

  return false;
};

const isConflict = (eng1: EngagementType, eng2: EngagementType) => {
  const s1 = eng1.gig.startsAt ? new Date(eng1.gig.startsAt) : new Date();
  const s2 = eng2.gig.startsAt ? new Date(eng2.gig.startsAt) : new Date();
  const e1 = eng1.gig.endsAt ? new Date(eng1.gig.endsAt) : null;
  const e2 = eng2.gig.endsAt ? new Date(eng2.gig.endsAt) : null;
  if (!e1 || !e2) return false;
  return s1 < e2 && e1 > s2;
};

const createConflictMap = (data: readonly EngagementType[] | null) => {
  const result = new Map<string, string[]>();
  if (data) {
    for (let i = 0; i < data.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const eng1 = data[i]!;
      const conflicts = [] as string[];
      result.set(eng1.id, conflicts);

      const checkConflict = (eng2: EngagementType) => {
        if (!isConflict(eng1, eng2)) return true;
        conflicts.push(eng2.id);
      };

      // look backwards
      for (let j = i - 1; j >= 0; j--) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        if (checkConflict(data[j]!)) break;
      }
      // look forward
      for (let k = i + 1; k < data.length; k++) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        if (checkConflict(data[k]!)) break;
      }
    }
  }

  return result;
};
