import {
  Button,
  Collapsible,
  ContentArea,
  Divider,
  IconSquare,
  Row,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import React from "react";

export function HourlyRateInfo() {
  return (
    <>
      <Collapsible
        testID="hourly-rate-info"
        variant="outline"
        noPad
        header={
          <ContentArea size="compact" variant="compact">
            <Row gap="compact" alignItems="center">
              <IconSquare
                icon="circle-dollar-to-slot"
                size="medium"
                color="primary"
                variant="well"
              />
              <Text weight="bold" color="primary" fill>
                Tips for Submitting a Bid
              </Text>
            </Row>
          </ContentArea>
        }
      >
        <Divider />
        <ContentArea gap="medium">
          <Text>
            Your Bid will be submitted to the Organization for review.
          </Text>
          <Text>
            Placing a Bid at or under the Offered Hourly Rate will increase your
            chances of being hired.
          </Text>
        </ContentArea>
      </Collapsible>
      <Button
        testID="hourly-rate-info-learn-more-btn"
        alignSelf="center"
        icon="external-link"
        label="Learn More About Bids"
        variant="clear"
        onPress={() =>
          showEmbeddedBrowser({ url: "https://help.gigsmart.com/bid-tips" })
        }
      />
    </>
  );
}
