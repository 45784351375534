import React, { type ReactNode } from "react";
import { View } from "react-native";
import { type IconName, type IconVariant, StyledIcon } from "../icon";
import { type ViewStyleProp, createStyles } from "../style";

interface Props {
  style?: ViewStyleProp;
  iconName: IconName;
  size?: number;
  children?: ReactNode;
  variant?: IconVariant;
}

const styles = createStyles(() => ({
  container: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  iconContainer: {
    paddingLeft: 20,
    paddingVertical: 2,
    width: 64
  },
  content: {
    flex: 1,
    paddingRight: 20
  }
}));

export default ({ style, iconName, size, children, variant }: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.iconContainer}>
        <StyledIcon size={size} name={iconName} variant={variant} />
      </View>
      <View style={styles.content}>{children}</View>
    </View>
  );
};
