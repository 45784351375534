/**
 * @generated SignedSource<<6f2c22c4f3185e62ba3d34f26380438c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConsentDocumentForm_document$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_document" | "useLegalDocument_document">;
  readonly " $fragmentType": "ConsentDocumentForm_document";
};
export type ConsentDocumentForm_document$key = {
  readonly " $data"?: ConsentDocumentForm_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConsentDocumentForm_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConsentDocumentForm_document",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useLegalDocument_document"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalDocument_document"
    }
  ],
  "type": "LegalDocument",
  "abstractKey": null
};

(node as any).hash = "6c042b5aa4cd5f0a9127fb7c6e9ee68a";

export default node;
