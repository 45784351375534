import {
  Constraint,
  FooterSpacer,
  ScreenScroll,
  ScreenTop,
  Stack,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form } from "@gigsmart/fomu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { confirmPrompt } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import CancellationQuestionnaire, {
  useCancelEngagement
} from "@gigsmart/seibutsu/engagement/CancellationQuestionnaire";
import EngagementInfoRow from "@gigsmart/seibutsu/engagement/EngagementInfoRow";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { gigCancelScreenEngagementQuery } from "./__generated__/gigCancelScreenEngagementQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "CancelEngagement">;

export default createSuspendedQueryContainer<
  gigCancelScreenEngagementQuery,
  Props
>(
  function GigCancelScreen({ navigation, route, response }) {
    const engagement = response?.node;
    const cancelEngagement = useCancelEngagement(engagement ?? null, "WORKER");

    const handleSubmit: FomuSubmitFn = ({ values }, done) => {
      const isWithdraw = engagement?.currentState?.name === "APPLIED";
      const inlineType =
        engagement?.gigType === "PROJECT" ? "Project" : "Shift";

      const handleSetCancellationReason = async () => {
        try {
          await cancelEngagement(values);

          toast.notice(
            isWithdraw
              ? "Your application has been withdrawn"
              : `You have canceled yourself from the ${inlineType}`
          );

          navigation.reset({
            routes: [
              { name: "Home" },
              { name: "History", params: { tab: "canceled" } },
              { name: "ShiftDetails", params: { id: route.params.id } }
            ]
          });
        } catch (err) {
          if ((err as Error).message !== "canceled") {
            toast.error("Something went wrong");
          }
          done();
        } finally {
          done();
        }
      };

      confirmPrompt({
        ...(isWithdraw
          ? {
              title: "Withdraw Application",
              subTitle: `Are you sure you want to withdraw your application? You will not be able to apply to this ${inlineType} again.`,
              yesLabel: "Withdraw Application",
              cancelLabel: "Don't Withdraw Application"
            }
          : {
              title: `Cancel from ${inlineType}`,
              subTitle: `Are you sure you want to cancel yourself from this ${inlineType}? You will not be able to apply to this ${inlineType} again.`,
              yesLabel: `Cancel from ${inlineType}`,
              cancelLabel: `Don't cancel from ${inlineType}`
            }),
        onDo: handleSetCancellationReason,
        onCancel: done
      });
    };

    return (
      <Form onSubmit={handleSubmit}>
        <ScreenScroll testID="gig-cancel-screen">
          <ScreenTop extraSpace />
          <Constraint size="xsmall">
            <Stack>
              <EngagementInfoRow fragmentRef={engagement} />
              <CancellationQuestionnaire
                userType="WORKER"
                question="HAD_ISSUE"
                fragmentRef={engagement}
                viewerRef={response?.viewer ?? null}
              />
            </Stack>
            <FooterSpacer />
          </Constraint>
        </ScreenScroll>
      </Form>
    );
  },
  {
    query: graphql`
      query gigCancelScreenEngagementQuery($id: ID!) {
        viewer {
          ...useAutoBlockSetting_organizationRequester
        }
        node(id: $id) {
          ... on Engagement {
            ...EngagementInfoRow_engagement
            ...CancellationQuestionnaire_engagement
            currentState {
              name
            }
            gigType
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
