import {
  Divider,
  ListHeader,
  ScreenSectionList,
  useDebouncedCallback
} from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import PendingOfferRow from "@gigsmart/seibutsu/engagement/PendingOfferRow";
import { showRejectGigPositionPrompt } from "@gigsmart/seibutsu/gig/showRejectGigPositionPrompt";
import React, { useCallback, useState } from "react";
import FakeGigSeriesList from "../gig-series/fake-gig-series-list";
import SeriesEngagementsActions from "../gig-series/modals/series-engagements-actions";
import { offeredSeriesEngagementsQuery } from "../gig-series/series-queries";
import type { WorkerParamList } from "../navigation/types";
import { OfferedGigHeader } from "./OfferedGigHeader";
import {
  filterOfferedEngagements,
  useOfferedGigSections
} from "./OfferedGigSections";
import type { offeredGigScreenQuery } from "./__generated__/offeredGigScreenQuery.graphql";

const liveAdd: EngagementStateName[] = ["OFFERED"];

type Props = AppScreenProps<WorkerParamList, "OfferedGigs">;
export default function OfferedGigScreenWrapper(props: Props) {
  return HourlyRateBids.isEnabled() &&
    props.route.params.gigType === "SHIFT" ? (
    <OfferedGigScreen {...props} />
  ) : (
    <LegacyOfferedGigScreen {...props} />
  );
}

const OfferedGigScreen = createSuspendedQueryContainer<
  offeredGigScreenQuery,
  Props
>(
  function OfferedGigScreen({ response, variables, navigation, route }) {
    const [vars, setVars] = useState(variables);
    const state = route.params.state;
    const { sections, ...listProps } = useOfferedGigSections(
      response?.viewer ?? null,
      vars,
      state
    );

    const pendingCount = response?.viewer?.pendingCount?.totalCount ?? 0;
    const handleSearch = useDebouncedCallback((term: string) => {
      setVars({
        where: filterOfferedEngagements(
          term,
          route?.params?.gigType ?? undefined,
          state
        )
      });
    });

    const handlePress = useCallback((id: string) => {
      navigation.push("ShiftDetails", { id });
    }, []);

    return (
      <ScreenSectionList
        {...listProps}
        keyExtractor={(item) => item.id}
        testID="offered-gig-screen"
        header={
          <OfferedGigHeader
            onSearch={handleSearch}
            count={pendingCount}
            state={state}
          />
        }
        sections={sections ?? []}
        ItemSeparatorComponent={Divider}
        renderSectionHeader={({ section }) => (
          <ListHeader label={section.key} divider />
        )}
        renderItem={({ item }) => (
          <PendingOfferRow fragmentRef={item} onPress={handlePress} />
        )}
      />
    );
  },
  {
    query: graphql`
      query offeredGigScreenQuery($where: CqlFilterEngagementInput, $pendingCountWhere: CqlFilterEngagementInput) {
        viewer {
          ...OfferedGigSections_worker @arguments(where: $where)
          ... on Worker {
            pendingCount: engagements(
              where: $pendingCountWhere
              first: 0
            ) {
              totalCount
            }
          }
        }
      }
    `,
    variables: ({ route }) => {
      const currentStateName = route.params.state;

      return {
        where: filterOfferedEngagements(
          undefined,
          route.params.gigType,
          currentStateName
        ),
        pendingCountWhere: {
          gigType: { _neq: "PROJECT" },
          currentStateName: { _eq: currentStateName }
        }
      };
    }
  }
);

/** @deprecated */
function LegacyOfferedGigScreen({ navigation, route }: Props) {
  const worker = useCurrentUser();
  const [toAccept, setToAccept] = useState<{
    seriesId: string;
    positionId: string;
    needsEnrollment: boolean;
  }>();
  const offeredGigsQuery = offeredSeriesEngagementsQuery(
    null,
    route?.params?.gigType
  );

  return (
    <>
      <FakeGigSeriesList
        query={offeredGigsQuery}
        variant="offered"
        liveAdd={liveAdd}
        onCardPress={(seriesId) =>
          navigation.push("WorkerGigSeriesOffered", { seriesId })
        }
        onAcceptOffers={(seriesId, isEnrolled, positionId) =>
          setToAccept({ seriesId, positionId, needsEnrollment: !isEnrolled })
        }
        renderEmptyView={(loading) =>
          loading ? null : <Redirect replace screen="Home" />
        }
        testID="offered-gigs-list"
      />
      {!!worker?.id && !!toAccept?.seriesId && (
        <SeriesEngagementsActions
          {...toAccept}
          workerId={worker.id}
          onCancel={() => setToAccept(undefined)}
          onConfirm={(engagementsToAccept, allEngagementsRejected) => {
            if (allEngagementsRejected) {
              if (toAccept.positionId) {
                setTimeout(() => {
                  showRejectGigPositionPrompt({ id: toAccept.seriesId });
                }, 400);
              }

              setToAccept(undefined);
              return;
            }
            if (engagementsToAccept?.length) {
              navigation.push("EngagementAccept", {
                id: toAccept.seriesId,
                engagementsToAccept
              });
            } else {
              navigation.goBack();
            }
            setToAccept(undefined);
          }}
        />
      )}
    </>
  );
}
