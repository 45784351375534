/* eslint-disable no-case-declarations */
import { titleize } from "inflected";
import React, { useEffect, useState } from "react";
import { Button, Text, View } from "react-native";
import GeoLocation from "react-native-geolocation-service";
import { useGeolocation } from "../geolocation";

export default function LocationLogsForeground() {
  const [updaterType, setUpdaterType] = useState<"interval" | "watch">("watch");
  const [location, setLocation] = useState<GeoLocation.GeoPosition>();
  const [error, setError] = useState<GeoLocation.GeoError>();

  useEffect(() => {
    const options: GeoLocation.BaseOptions = {
      enableHighAccuracy: true
    };

    const intervalOptions: GeoLocation.GeoOptions = {
      ...options,
      maximumAge: 0,
      timeout: 1000
    };

    const watchOptions: GeoLocation.GeoWatchOptions = {
      distanceFilter: 10,
      useSignificantChanges: false,
      ...options
    };

    switch (updaterType) {
      case "watch": {
        const watch = GeoLocation.watchPosition(
          setLocation,
          setError,
          watchOptions
        );
        return () => GeoLocation.clearWatch(watch);
      }
      case "interval": {
        const interval = setInterval(
          () =>
            GeoLocation.getCurrentPosition(
              setLocation,
              setError,
              intervalOptions
            ),
          1000
        );
        return () => clearInterval(interval);
      }
    }
  }, [updaterType]);

  const workerData = useGeolocation();

  const data = {
    ...location?.coords,
    error,
    updaterType,
    timestamp: new Date(location?.timestamp ?? 0),
    mocked: !!location?.mocked
  };

  return (
    <>
      <Button
        title="Change Type"
        onPress={() =>
          setUpdaterType((current) =>
            current === "interval" ? "watch" : "interval"
          )
        }
      />
      <Text>[RAW DATA]</Text>
      {Object.keys(data)
        .sort()
        .map((key) => (
          <View key={key}>
            <Text>
              {"  "} {titleize(key)}:{" "}
              {JSON.stringify(data[key as keyof typeof data])}
            </Text>
          </View>
        ))}
      <Text>[WORKER DATA]</Text>
      {Object.keys(workerData)
        .sort()
        .map((key) => (
          <View key={key}>
            <Text>
              {"  "} {titleize(key)}:{" "}
              {JSON.stringify(data[key as keyof typeof data])}
            </Text>
          </View>
        ))}
    </>
  );
}
