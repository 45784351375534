/**
 * @generated SignedSource<<f33e4ec4a61b77c75a6a8accc4dfd693>>
 * @relayHash 61169f554cedfc64c1a8c0f47dd2bfd1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:2xu8-VD4jAdO4Rr1qv9rtCqD6IXPioBPdzFCEyXJXJo

import { ConcreteRequest } from 'relay-runtime';
export type SetUserPasswordInput = {
  currentPassword: string;
  newPassword: string;
  userId?: string | null | undefined;
};
export type ChangePasswordFormCardMutation$variables = {
  input: SetUserPasswordInput;
};
export type ChangePasswordFormCardMutation$data = {
  readonly setUserPassword: {
    readonly ok: boolean;
  } | null | undefined;
};
export type ChangePasswordFormCardMutation = {
  response: ChangePasswordFormCardMutation$data;
  variables: ChangePasswordFormCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetUserPasswordPayload",
    "kind": "LinkedField",
    "name": "setUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordFormCardMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePasswordFormCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:2xu8-VD4jAdO4Rr1qv9rtCqD6IXPioBPdzFCEyXJXJo",
    "metadata": {},
    "name": "ChangePasswordFormCardMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0953b88b83c769061c9d94221361f8ba";

export default node;
