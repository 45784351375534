import React, { type ReactElement } from "react";
import { View } from "react-native";

import Stack from "../../container/stack";
import StyledIcon from "../../icon/styled-icon";
import useStyles from "../../style/use-styles";
import KatanaTouchableOpacity from "../../tappable/katana-touchable-opacity";
import StyledText from "../../text/styled-text";

export interface FancyButtonProps<V> {
  ImageComponent: ReactElement;
  title: string;
  bodyText?: string;
  selected?: boolean;
  testID?: string;
  titleTextStyle?: any;
  bodyTextStyle?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  value: V;
}

type Props<V> = {
  onPress: () => void;
} & FancyButtonProps<V>;

export default function FancyButton<V>({
  ImageComponent,
  title,
  bodyText,
  selected = false,
  testID,
  onPress,
  titleTextStyle,
  bodyTextStyle
}: Props<V>) {
  const { styles, theme } = useStyles(({ color, units, namedShadows }) => ({
    container: {
      backgroundColor: color.white,
      borderRadius: units(1),
      borderColor: color.neutralLight,
      borderWidth: 1,
      alignItems: "center",
      flex: 1,
      paddingVertical: units(4),
      paddingHorizontal: units(3)
    },
    selectedContainer: {
      borderColor: color.blue,
      borderWidth: 2,
      ...namedShadows.noShadow
    },
    icon: {
      position: "absolute",
      top: 8,
      right: 8
    },
    imageContainer: {
      alignItems: "center",
      width: "50%",
      maxWidth: units(30),
      aspectRatio: 1,
      paddingBottom: units(4),
      "@phoneDown": {
        maxWidth: units(13)
      }
    }
  }));
  return (
    <KatanaTouchableOpacity
      testID={testID ?? "fancy-button"}
      eventTargetName="Fancy Button"
      style={[styles.container, selected && styles.selectedContainer]}
      onPress={onPress}
    >
      {selected && (
        <StyledIcon
          name="circle-check"
          size={24}
          variant="solid"
          style={styles.icon}
        />
      )}
      <View style={styles.imageContainer}>{ImageComponent}</View>
      <Stack alignItems="center" spacing={4}>
        <StyledText
          bold
          fontSize={theme.font.size.medium}
          center
          color="blue"
          style={titleTextStyle}
        >
          {title}
        </StyledText>
        {!!bodyText && (
          <StyledText
            fontSize={theme.font.size.small}
            color="blue"
            center
            style={bodyTextStyle}
          >
            {bodyText}
          </StyledText>
        )}
      </Stack>
    </KatanaTouchableOpacity>
  );
}
