/**
 * @generated SignedSource<<151447a087e00d441687d92ada6262c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type gigSummaryRows_availableGig$data = {
  readonly distance: number | null | undefined;
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"gigSummaryRows_gig">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"gigPaymentRows_availableGig">;
  readonly " $fragmentType": "gigSummaryRows_availableGig";
};
export type gigSummaryRows_availableGig$key = {
  readonly " $data"?: gigSummaryRows_availableGig$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigSummaryRows_availableGig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigSummaryRows_availableGig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gigSummaryRows_gig"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "gigPaymentRows_availableGig"
    }
  ],
  "type": "AvailableGigsEdge",
  "abstractKey": null
};

(node as any).hash = "705dc0ab6d296c48eb143cc1b0c5096d";

export default node;
