import {
  ContentArea,
  Date as DateComponent,
  DateTime as DateTimeText,
  IconText,
  Stack,
  Tag,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { type ShiftCardExtras, formatDistance } from "./extras";

import type { WorkerShiftCardBody_gig$key } from "./__generated__/WorkerShiftCardBody_gig.graphql";

interface Props {
  variant?: "default" | "confirm-modal";
  shiftCount?: number;
  distance?: ShiftCardExtras["distance"];
  boosted?: boolean;
  startsAtOverride?: any | null;
  estimatedMileageOverride?: number | null;
  header?: JSX.Element | null;
}

export const WorkerShiftCardBody = ({
  variant = "default",
  shiftCount = 1,
  distance,
  gigType,
  placeId,
  organization,
  category,
  position,
  startsAt,
  actualStartsAt,
  endsAt,
  timezone,
  boosted,
  isRecurring,
  estimatedMileage,
  area,
  startsAtOverride,
  estimatedMileageOverride,
  negotiable,
  header
}: FragmentContainerInnerComponentProps<
  WorkerShiftCardBody_gig$key,
  Props
>) => {
  estimatedMileageOverride ??= estimatedMileage;
  startsAtOverride ??= startsAt;

  const isCompact = variant === "confirm-modal";
  const icProps = {
    size: "small",
    iconSize: "tiny",
    iconAlign: "center",
    iconColor: "primary"
  } as const;

  header ??= organization?.name ? (
    <IconText
      {...icProps}
      fill={1}
      numberOfLines={1}
      iconColor={isCompact ? "primary" : "neutral"}
      color={isCompact ? "textPrimary" : "neutral"}
      icon="building"
    >
      {organization.name}
    </IconText>
  ) : null;

  const isProject = gigType === "PROJECT";
  const isRemote = placeId === "REMOTE" || area === "REMOTE";
  const descName = (isProject ? category?.displayName : position?.name) ?? "";

  const fillDate = false; // TODO: check if it's needed
  const fillDesc = descName.length > 28 || !estimatedMileage ? 1 : undefined;

  return (
    <ContentArea {...(isCompact && { size: "none", variant: "none" })}>
      <Stack size={isCompact ? "compact" : "standard"}>
        {!!header && (
          <Stack horizontal size="medium">
            {boosted && (
              <Tag
                iconType="FONT_AWESOME"
                icon="chevrons-up"
                color="emphasized"
                label="Highlighted"
              />
            )}
            {header}
          </Stack>
        )}
        <Stack size="compact">
          <Stack horizontal alignItems="center" size="medium">
            <IconText
              {...icProps}
              fill={fillDesc}
              icon="box-check"
              numberOfLines={1}
            >
              {descName}
            </IconText>
            {!!estimatedMileageOverride && (
              <IconText
                {...icProps}
                iconAlign="left"
                color="warning"
                iconColor="warning"
                icon="truck-fast"
              >
                Travel/Delivery
              </IconText>
            )}
          </Stack>
          <Stack horizontal alignItems="center" size="medium">
            {shiftCount > 1 ? (
              <IconText {...icProps} fill={fillDate} icon="calendar-days">
                <Text weight="bold">
                  {pluralize(shiftCount, "Shift")} &bull;
                </Text>{" "}
                <DateComponent
                  size="lg"
                  startsAt={startsAtOverride}
                  actualStartsAt={actualStartsAt}
                  timezone={timezone}
                />
              </IconText>
            ) : (
              <IconText {...icProps} fill={fillDate} icon="calendar-days">
                {isProject && !!negotiable?.includes("STARTS_AT") ? (
                  "Flexible Schedule"
                ) : (
                  <DateTimeText
                    size="md"
                    startsAt={startsAtOverride}
                    actualStartsAt={actualStartsAt}
                    endsAt={endsAt}
                    timezone={timezone}
                  />
                )}
              </IconText>
            )}
            {isRecurring && (
              <IconText
                {...icProps}
                iconAlign="left"
                color="primary"
                icon="repeat"
              >
                Recurring
              </IconText>
            )}
          </Stack>
          <IconText {...icProps} icon="location-dot">
            {isRemote ? "Remote" : `${area} ${formatDistance(distance)}`}
          </IconText>
        </Stack>
      </Stack>
    </ContentArea>
  );
};

export default createRelayFragmentContainer<WorkerShiftCardBody_gig$key, Props>(
  graphql`
    fragment WorkerShiftCardBody_gig on Gig {
      gigType
      area
      placeId
      isRecurring
      estimatedMileage
      organization {
        name
      }
      category {
        displayName
      }
      position {
        name
      }
      startsAt
      actualStartsAt
      endsAt
      timezone
      negotiable
    }
  `,
  WorkerShiftCardBody
);

//
// Formatters
