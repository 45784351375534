import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledDatePicker } from "@gigsmart/katana";
import React, { type ComponentProps } from "react";
import { Platform } from "react-native";

type StyledDatePickerProps = ComponentProps<typeof StyledDatePicker>;

type RefinedStyledDatePickerProps = Omit<
  StyledDatePickerProps,
  "onSubmitEditing" | "date" | "onDateChange" | "errors"
>;

type Props = RefinedStyledDatePickerProps & FormFieldOptions;

export function FomuStyledDatePicker({ name, validates, ...props }: Props) {
  const { setValue, value, submit, errors, showErrors } = useFormField({
    name,
    validates
  });

  return (
    <StyledDatePicker
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      date={value}
      initialDate={value}
      onDateChange={setValue}
      onBlur={showErrors}
      errors={errors}
      {...props}
    />
  );
}
