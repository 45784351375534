import React, { useMemo } from "react";

import { type IconName, useStepper } from "@gigsmart/atorasu";
import { asEnum, where } from "@gigsmart/biruda";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import MultiCategorySelectionRowGroup, {
  type Option,
  type SelectionCategory
} from "@gigsmart/seibutsu/shared/MultiCategorySelectionRowGroup";
import { ResponsiveStepperBtnPortal } from "@gigsmart/seibutsu/shared/Stepper";
import type { QualificationsStepQuery } from "./__generated__/QualificationsStepQuery.graphql";
import useGigFields from "./hooks/useGigFields";
import useUpdateWorkerQualification from "./hooks/useUpdateWorkerQualification";

interface Props {
  gigId: string;
  workerId: string;
  handleUnmetRequirements: () => void;
}

export default createSuspendedQueryContainer<QualificationsStepQuery, Props>(
  function QualificationsStep({
    response,
    gigId,
    workerId,
    handleUnmetRequirements
  }) {
    const { updateWorkerQualification } = useUpdateWorkerQualification({
      workerId,
      gigId
    });

    const { nextStep } = useStepper();
    const gigFields = useGigFields(response?.node);
    const qualificationCategories = useMemo(() => {
      const workerFields = getConnectionNodes(response?.viewer?.gigFields);
      const workerSelectedFieldItems: string[] = [];
      const workerDeniedFieldItems: string[] = [];

      workerFields.forEach((field) => {
        field.selectedDefinitions?.edges?.forEach((selectedItem) =>
          workerSelectedFieldItems.push(selectedItem?.node?.id ?? "")
        );
        field.deniedDefinitions?.edges?.forEach((deniedItem) =>
          workerDeniedFieldItems.push(deniedItem?.node?.id ?? "")
        );
      });

      const qualificationCategories: SelectionCategory[] = [];

      gigFields.forEach((field) => {
        const selectionRowHeader = field.title;
        const selectionRowHeaderIcon = field.iconName as IconName;

        const enableAutoChecking =
          selectionRowHeader !== "Commercial Driver's License";

        const options = field.selectedItems?.map((option) => ({
          id: option.definition.id,
          label: option.definition.label,
          value: workerSelectedFieldItems?.includes(option.definition.id)
            ? true
            : workerDeniedFieldItems?.includes(option.definition.id)
              ? false
              : null,
          enableAutoChecking:
            option.definition.label === "Driver's License"
              ? false
              : enableAutoChecking
        }));

        if (options?.length) {
          qualificationCategories.push({
            selectionRowHeader,
            selectionRowHeaderIcon,
            options
          });
        }
      });

      return qualificationCategories;
    }, [response?.viewer, gigFields]);

    const handleSubmit = ({ options }: { options: Option[] }) => {
      const deniedQualifications = options.some(
        (option) => option.value === false
      );
      if (deniedQualifications) {
        handleUnmetRequirements();
      } else {
        nextStep();
      }
    };

    return (
      <MultiCategorySelectionRowGroup
        selectionCategories={qualificationCategories}
        note="The following Qualifications must be met in order to apply to this Gig."
        subNote="Do you have the following Qualifications?"
        info="Any changes made will be automatically saved to your Profile."
        reminder="Adding Qualifications to your profile that you do not have may result in cancellation from the Shift by the Organization."
        onCheckOption={updateWorkerQualification}
        onSubmit={handleSubmit}
        submitButton={ResponsiveStepperBtnPortal.Entrance}
        submitLabel="Continue"
      />
    );
  },
  {
    query: graphql`
      query QualificationsStepQuery(
        $id: ID!
        $selectedItemsQuery: String!
        $deniedItemsQuery: String!
      ) {
        viewer {
          ... on Worker {
            id
            gigFields(first: 10) {
              edges {
                node {
                  title
                  iconName
                  selectedDefinitions: itemDefinitions(
                    first: 40
                    query: $selectedItemsQuery
                  ) {
                    edges {
                      node {
                        label
                        id
                      }
                    }
                  }
                  deniedDefinitions: itemDefinitions(
                    first: 40
                    query: $deniedItemsQuery
                  ) {
                    edges {
                      node {
                        label
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        node(id: $id) {
          ...useGigFields_gigOrEngagement
        }
      }
    `,
    variables: ({ gigId }) => ({
      id: gigId,
      selectedItemsQuery: where({ status: asEnum("CONFIRMED") }).toString(),
      deniedItemsQuery: where({ status: asEnum("DENIED") }).toString()
    })
  }
);
