import { WebInfoModal } from "@gigsmart/atorasu";
import type { Props as InfoElementProps } from "@gigsmart/atorasu/organisms/InfoElement";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import React from "react";

type UserType = "Worker" | "Requester";

const urls: Record<string, string> = {
  "Worker-Requester":
    "https://help.gigsmart.com/requester-cancellation-policy-for-workers",
  "Worker-Worker":
    "https://help.gigsmart.com/requester-cancellation-policy-for-workers",
  "OrganizationRequester-Requester":
    "https://gigsmart.com/shift-gig-cancellation-policy/#:~:text=Cancelling%20Shifts%20more%20than%20one,scheduled%20worker%20will%20be%20charged",
  "OrganizationRequester-OrganizationRequester":
    "https://gigsmart.com/shift-gig-cancellation-policy/#:~:text=Cancelling%20Shifts%20more%20than%20one,scheduled%20worker%20will%20be%20charged"
};

type Props = InfoElementProps & {
  testID?: string;
  policyType?: UserType;
};
export default function CancellationPoliciesInfoModal({
  policyType,
  variant,
  helpText,
  testID
}: Props) {
  const userType = useCurrentUser()?.__typename;
  const url = urls[`${userType}-${policyType ?? userType}`];
  if (!url) return null;
  return (
    <WebInfoModal
      testID={testID ?? "cancellation-policies-info-modal"}
      variant={variant}
      helpText={helpText}
      title="Learn More About Cancellation Fees"
      url={url}
    />
  );
}

CancellationPoliciesInfoModal.defaultProps = {
  helpText: true,
  title: "",
  variant: "icon"
};
