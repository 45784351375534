/**
 * @generated SignedSource<<a192ab189dfb77b41ea2d4091ab0a5dc>>
 * @relayHash e1fe0dba8f29fda6f6302948142454c3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:S_8SF46dZVpDPfAqhzI85M6BVHom0LE6MQbuBxiPi88

import { ConcreteRequest } from 'relay-runtime';
export type WorkerQualificationProofStatus = "INVALID" | "NOT_PROVIDED" | "VALID" | "%future added value";
export type AddWorkerQualificationDriversLicenseProofInput = {
  backPhotoId: string;
  expirationDate: string;
  frontPhotoId: string;
  issueDate: string;
  issuingState: string;
  licenseNumber?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type CommercialDriversLicenseModalMutation$variables = {
  input: AddWorkerQualificationDriversLicenseProofInput;
};
export type CommercialDriversLicenseModalMutation$data = {
  readonly addWorkerQualificationDriversLicenseProof: {
    readonly newWorkerQualificationProofEdge: {
      readonly node: {
        readonly id?: string;
        readonly status?: WorkerQualificationProofStatus;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CommercialDriversLicenseModalMutation = {
  response: CommercialDriversLicenseModalMutation$data;
  variables: CommercialDriversLicenseModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "WorkerQualificationDriversLicenseProof",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommercialDriversLicenseModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerQualificationDriversLicenseProofPayload",
        "kind": "LinkedField",
        "name": "addWorkerQualificationDriversLicenseProof",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerQualificationProofsEdge",
            "kind": "LinkedField",
            "name": "newWorkerQualificationProofEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommercialDriversLicenseModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerQualificationDriversLicenseProofPayload",
        "kind": "LinkedField",
        "name": "addWorkerQualificationDriversLicenseProof",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerQualificationProofsEdge",
            "kind": "LinkedField",
            "name": "newWorkerQualificationProofEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:S_8SF46dZVpDPfAqhzI85M6BVHom0LE6MQbuBxiPi88",
    "metadata": {},
    "name": "CommercialDriversLicenseModalMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "124c4640f0de107f2ed21de13ade2c75";

export default node;
