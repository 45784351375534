/**
 * @generated SignedSource<<76c509834c721713a6c82934db041cd1>>
 * @relayHash c9d9fdd854dc2ba6e7226dfb0ea42035
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:B4nZGRKbNr-2PA04yKgAeoeohtM6-pUgarXImbH7Mzg

import { ConcreteRequest } from 'relay-runtime';
export type otpCaptureQuery$variables = Record<PropertyKey, never>;
export type otpCaptureQuery$data = {
  readonly viewer: {
    readonly primaryEmail: {
      readonly address: string;
    } | null | undefined;
    readonly primaryMobile: {
      readonly number: string;
    } | null | undefined;
  } | null | undefined;
};
export type otpCaptureQuery = {
  response: otpCaptureQuery$data;
  variables: otpCaptureQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "otpCaptureQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMobile",
            "kind": "LinkedField",
            "name": "primaryMobile",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "otpCaptureQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMobile",
            "kind": "LinkedField",
            "name": "primaryMobile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:B4nZGRKbNr-2PA04yKgAeoeohtM6-pUgarXImbH7Mzg",
    "metadata": {},
    "name": "otpCaptureQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6c80bef5c03b1b3277c801fd73fd7ba9";

export default node;
