/**
 * @generated SignedSource<<4d49a17d7b7f6554f9134766eb691b5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ConversationCapability = "CLOSE" | "JOIN" | "LEAVE" | "OPEN" | "%future added value";
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RequesterMoreMenu_conversationLike$data = {
  readonly __typename: string;
  readonly capabilities: ReadonlyArray<ConversationCapability>;
  readonly engagement?: {
    readonly worker: {
      readonly id: string;
    };
  };
  readonly gig?: {
    readonly conversationRule: GigConversationRule;
    readonly currentState: {
      readonly name: GigStateName;
    };
    readonly id: string;
  };
  readonly id?: string;
  readonly participant: {
    readonly id: string;
    readonly readUntil: string | null | undefined;
  } | null | undefined;
  readonly worker?: {
    readonly id: string;
  };
  readonly " $fragmentType": "RequesterMoreMenu_conversationLike";
};
export type RequesterMoreMenu_conversationLike$key = {
  readonly " $data"?: RequesterMoreMenu_conversationLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequesterMoreMenu_conversationLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequesterMoreMenu_conversationLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConversationParticipant",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readUntil",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "OrganizationWorkerConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Engagement",
          "kind": "LinkedField",
          "name": "engagement",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "EngagementConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conversationRule",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigState",
              "kind": "LinkedField",
              "name": "currentState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ShiftGroupConversation",
      "abstractKey": null
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};
})();

(node as any).hash = "9c0b0eda38049f86ab21c3c19a8e6811";

export default node;
