import { integrate } from "@gigsmart/dekigoto";
import type { HOCVoid } from "@gigsmart/hoc-utils";
import { wrap } from "@sentry/react-native";
import type { ComponentType } from "react";
import { Platform } from "react-native";

integrate({ sentry: true });

export const withSentryWrap: HOCVoid = (
  WrappedComponent: ComponentType<any>
) => {
  return Platform.OS === "web" || __DEV__
    ? WrappedComponent
    : wrap(WrappedComponent);
};
