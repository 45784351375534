import React, { type ComponentProps } from "react";
import { View } from "react-native-animatable";
import { type ViewStyleProp, createStyles } from "../style";
import { StyledHeader1, StyledText } from "../text";

interface EmptyViewProp {
  testID?: string;
  header?: ComponentProps<typeof StyledHeader1>["children"];
  message?: ComponentProps<typeof StyledText>["children"];
  type: "boxed" | "clean";
  style?: ViewStyleProp;
}

const styles = createStyles(({ color, font }) => ({
  "container.clean": {
    marginTop: 40,
    marginBottom: 16,
    marginHorizontal: "12%"
  },
  "container.boxed": {
    backgroundColor: color.white,
    padding: 20
  },
  header: {
    color: color.blue,
    marginBottom: 36,
    textAlign: "center"
  },
  message: {
    lineHeight: font.size.bodyCopy * font.lineHeight.body,
    textAlign: "center"
  },
  "message.boxed": {
    fontSize: font.size.large
  }
}));

const EmptyView = ({ testID, style, type, header, message }: EmptyViewProp) => (
  <View
    style={[styles[`container.${type}` as keyof typeof styles], style]}
    testID={testID}
  >
    {!!header && (
      <StyledHeader1 style={styles.header} testID="empty-view-title">
        {header}
      </StyledHeader1>
    )}
    {!!message && (
      <StyledText
        style={[
          styles.message,
          styles[`message.${type}` as keyof typeof styles]
        ]}
        testID="empty-view-body"
      >
        {message}
      </StyledText>
    )}
  </View>
);

EmptyView.defaultProps = { type: "clean" };

export default EmptyView;
