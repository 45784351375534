import { useEffect, useMemo, useState } from "react";
import { Platform } from "react-native";
import { RNOnfleetSDK, addListener } from "./RNOnfleetSDK";
import type { RNOnfleetTask } from "./types";

export function refreshCompletedTasks() {
  if (Platform.OS === "android") {
    void RNOnfleetSDK.getCompletedTasks();
  }
}

export function useOnfleetHasActiveTask() {
  const [hasActiveTask, setHasActiveTask] = useState(true);
  useEffect(() => {
    const listener = addListener("activeTaskId", (id) => {
      if (!!id !== hasActiveTask) {
        setHasActiveTask(!!id);
      }
    });
    if (Platform.OS === "ios") {
      void RNOnfleetSDK.subscribeToActiveTaskId();
    }
    return listener.remove;
  }, []);
  return hasActiveTask;
}

export function useOnfleetTasks() {
  const [tasks, setTasks] = useState<RNOnfleetTask[]>([]);
  const [completedTasks, setCompletedTasks] = useState<RNOnfleetTask[]>([]);
  useEffect(() => {
    const listeners = [
      addListener("completedTasks", setCompletedTasks),
      addListener("tasks", setTasks)
    ];
    void RNOnfleetSDK.getCompletedTasks();
    return () => listeners.forEach((it) => it.remove());
  }, []);

  return useMemo(
    () => [
      { key: "unfinished", data: tasks },
      { key: "completed", data: completedTasks }
    ],
    [tasks, completedTasks]
  );
}

export function useOnfleetTask(id: string) {
  const [task, setTask] = useState<RNOnfleetTask | null>(null);
  useEffect(() => {
    const listener = addListener(
      Platform.OS === "android" ? `activeTaskById:${id}` : "activeTask",
      (t) => t?.id === id && setTask(t)
    );
    RNOnfleetSDK.getTaskById(id)
      .then((it) => setTask(it ?? null))
      .catch((err) => console.warn("err=", err));
    return () => listener.remove();
  }, [id]);
  return task;
}

export function useOnfleetCompletedTask(id: string) {
  const [task, setTask] = useState<RNOnfleetTask | null>(null);
  useEffect(() => {
    const listener = addListener(
      Platform.OS === "android" ? `completeTask:${id}` : "completedTask",
      (t) => t?.id === id && setTask(t)
    );

    RNOnfleetSDK.subscribeToCompletedTaskById(id);
    return listener.remove;
  }, []);
  return task;
}
