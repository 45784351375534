import {
  Column,
  ContentArea,
  Pressable,
  Row,
  Tag,
  Text,
  Time,
  ViewButton
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import React from "react";
import { formatDistance } from "../gig-like/WorkerShiftCard/extras";
import { getBidSpec } from "./EngagementExtras";
import type { PendingOfferRowSubscription } from "./__generated__/PendingOfferRowSubscription.graphql";
import type { PendingOfferRow_engagement$key } from "./__generated__/PendingOfferRow_engagement.graphql";

type Props = {
  onPress?: (id: string) => void;
};

export const PendingOfferRow = ({
  id,
  gig,
  currentState,
  currentNegotiation,
  estimatedPayment,
  startsAt,
  endsAt,
  workerDistance,
  onPress
}: FragmentContainerInnerComponentProps<
  PendingOfferRow_engagement$key,
  Props
>) => {
  const bidSpec = getBidSpec(currentState);
  const isSuccess = bidSpec?.key === "accepted";

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      container: {
        borderLeftColor: getColor(bidSpec?.tintColor, "fill"),
        borderLeftWidth: getUnits(1)
      },
      pressed: { opacity: 0.7 },
      disabled: { opacity: 0.3 },
      tag: { position: "absolute", bottom: 0, right: 0 }
    }),
    [bidSpec?.tintColor]
  );

  const offeredPayRate =
    ((currentState?.name === "OFFERED" ||
      currentState?.name === "BID_REVIEW") &&
      (currentNegotiation?.payRate ?? estimatedPayment?.payRate)) ||
    estimatedPayment?.payRate;

  const orgName = gig.organization?.name;
  const area = gig.area;

  useRelaySubscription<PendingOfferRowSubscription>(
    graphql`
      subscription PendingOfferRowSubscription($id: ID!) {
        engagementUpdated(engagementId: $id) {
          engagement {
            currentState {
              name
              action
            }
            currentNegotiation {
              payRate
            }
            estimatedPayment {
              payRate
              netPay
            }
          }
        }
      }
    `,
    { id },
    { subscribe: !!bidSpec?.key }
  );

  const disabled = !bidSpec?.key;

  return (
    <Pressable
      style={({ pressed }) => [
        styles.container,
        disabled && styles.disabled,
        pressed && styles.pressed
      ]}
      eventEntityType="Pressable"
      eventTargetName="PendingOfferRow"
      testID="engagement-pending-row"
      disabled={disabled}
      onPress={() => onPress?.(id)}
    >
      <ContentArea size="medium" variant="medium" color="surface">
        <Row>
          <Column fill gap={2}>
            <Text weight="bold">{gig.name}</Text>
            <Column>
              <Text variant="note">
                {orgName} • {area} {formatDistance(workerDistance)}
              </Text>
              <Time
                variant="note"
                startsAt={startsAt}
                endsAt={endsAt}
                showDuration
              />
              <Text variant="note">
                {bidSpec?.rateLabel ?? "Hourly Rate"}:{" "}
                {currency.humanizeRate(offeredPayRate)}
              </Text>
              <Text variant="note">
                Estimated Pay:{" "}
                <Text {...(isSuccess && { color: "success", weight: "bold" })}>
                  {currency.humanize(estimatedPayment?.netPay)}
                </Text>
                {gig.acceptsTips ? " + Tips" : ""}
              </Text>
            </Column>
          </Column>
          {!disabled && <ViewButton testID="view-pending-offer" size="md" />}
          {!!bidSpec && (
            <Column style={styles.tag}>
              <Tag
                icon={bidSpec.icon}
                label={bidSpec.label}
                color={bidSpec.tintColor}
                variant="clear"
              />
            </Column>
          )}
        </Row>
      </ContentArea>
    </Pressable>
  );
};

export default createRelayFragmentContainer<
  PendingOfferRow_engagement$key,
  Props
>(
  graphql`
    fragment PendingOfferRow_engagement on Engagement {
      id
      startsAt
      endsAt
      workerDistance
      currentState @trackImpressions(action: VIEWED) {
        name
        action
      }
      estimatedPayment {
        payRate
        netPay
      }
      currentNegotiation {
        payRate
      }
      gig {
        name
        acceptsTips
        area
        organization {
          name
        }
      }
    }
  `,
  PendingOfferRow
);
