import { IconText, Pressable } from "@gigsmart/atorasu";
import { ActionSheet, NavPortal, StyledView } from "@gigsmart/katana";
import { showProjectGigsInfoModal } from "@gigsmart/seibutsu/modals/ProjectGigsInfoModalTabs";
import React, { useRef } from "react";

const ProjectGigNav = () => {
  const actionSheetRef = useRef<ActionSheet | null>(null);
  const options = ["About Project Gigs", "Cancel"];
  const handleSelectOption = (index: number) => {
    if (index === 0) showProjectGigsInfoModal();
  };
  return (
    <>
      <NavPortal.Entrance
        renderRightIcon={() => (
          <StyledView
            color="transparent"
            horizontalPadding={0}
            verticalPadding={0}
            paddingRight={4}
          >
            <Pressable
              testID="more-button"
              eventEntityType="More Pressable"
              eventTargetName="More Pressable"
              onPress={() => actionSheetRef.current?.show()}
            >
              <IconText icon="ellipsis-vertical" iconColor="white">
                More
              </IconText>
            </Pressable>
          </StyledView>
        )}
      />
      <ActionSheet
        actionSheetRef={(ref) => (actionSheetRef.current = ref)}
        options={options}
        onPress={handleSelectOption}
        cancelButtonIndex={options.length - 1}
      />
    </>
  );
};

export default ProjectGigNav;
