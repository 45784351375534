import {
  AbsoluteContainer,
  Button,
  Column,
  ContentArea,
  Row,
  Spacer,
  Stack,
  Surface,
  Text,
  View
} from "@gigsmart/atorasu";
import { useMatchesViewport, useStyles } from "@gigsmart/atorasu/style";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import type { AuthParamList } from "@gigsmart/isomorphic-shared/auth/types";
import Logo from "@gigsmart/isomorphic-shared/brand/logo";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import React from "react";
import GetGigsSlider from "./Slides/GetGigsSlider";
import GetWorkersSlider from "./Slides/GetWorkersSlider";
import BackgroundWave from "./svgs/BlueWaveOne";

export default function WelcomeScreen({
  navigation
}: AppScreenProps<AuthParamList, "Home">) {
  const { appName } = useAppSettings();
  const styles = useStyles(() => ({
    buttonsPadding: { paddingHorizontal: "18%" },
    swiperWrapper: { flex: 1, justifyContent: "center" }
  }));

  const isLg = useMatchesViewport((media) => media.size.large.up);
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const OuterComponent = isLg ? Row : Column;

  const handleSignin = () => navigation.push("Login");
  const handleSignup = () => navigation.push("Signup");

  if (isMd) {
    return (
      <Surface fill={1} testID="welcome-screen">
        <OuterComponent fill>
          <Column fill justifyContent="center" grow={1.2}>
            <Surface variant="flat" fill={1} color="primary" fade>
              <View style={styles.swiperWrapper}>
                {appName === "Get Gigs" ? (
                  <GetGigsSlider />
                ) : appName === "Get Workers" ? (
                  <GetWorkersSlider />
                ) : null}
              </View>
            </Surface>
          </Column>
          <Column fill justifyContent="center" shrink={1}>
            <Stack size="compact" justifyContent="center">
              <Stack alignItems="center" size="medium">
                <Logo height={36} />
                <Text color="primary" weight="bold" align="center">
                  {appName}
                </Text>
              </Stack>
              <View style={styles.buttonsPadding}>
                <Spacer size="large" />
                <Button
                  testID="sign-up-btn"
                  size="large"
                  label="Sign up"
                  fullWidth
                  onPress={handleSignup}
                />
                <Spacer />
                <Button
                  testID="sign-in-btn"
                  size="large"
                  label="Sign in"
                  variant="clear"
                  outline
                  onPress={handleSignin}
                />
              </View>
            </Stack>
          </Column>
        </OuterComponent>
      </Surface>
    );
  }

  return (
    <Surface fill={1} testID="welcome-screen">
      <AbsoluteContainer>
        <BackgroundWave />
      </AbsoluteContainer>
      <Spacer size="large" />
      <Spacer size="medium" />
      {appName === "Get Gigs" && <GetGigsSlider />}
      {appName === "Get Workers" && <GetWorkersSlider />}
      <AbsoluteContainer bottom={0}>
        <ContentArea fill>
          <Stack horizontal>
            <Column fill>
              <Button
                testID="sign-in-btn"
                size="large"
                label="Sign in"
                variant="clear"
                outline
                onPress={handleSignin}
              />
            </Column>
            <Column fill>
              <Button
                size="large"
                testID="sign-up-btn"
                label="Sign up"
                onPress={handleSignup}
              />
            </Column>
          </Stack>
          <Spacer />
        </ContentArea>
      </AbsoluteContainer>
    </Surface>
  );
}
