import React, { type ComponentProps, type ReactNode } from "react";

import { Flex, FlexRow } from "../flex";
import IconInputAccessory from "../icon/icon-input-accessory";
import StyledTextInput from "../input/styled-text-input";
import { numericKeyboardType } from "../input/text-utils";
import { useStyles } from "../style";

type Props = ComponentProps<typeof StyledTextInput> & {
  first?: boolean;
  inputLabel?: ReactNode;
  mask?: "money" | "money:3" | "money:4";
};

const MoneyInputRow = ({
  first = false,
  mask = "money:3",
  inputLabel,
  style,
  ...inputProps
}: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    rowContainer: {
      borderTopWidth: first ? 1 : 0,
      borderTopColor: color.neutralLight,
      borderBottomWidth: 1,
      borderBottomColor: color.neutralLight,
      paddingVertical: units(1),
      paddingHorizontal: units(4),
      minHeight: units(15)
    },
    input: {
      // Need this for Firefox  ¯\_(ツ)_/¯
      overflow: "hidden",
      textAlign: "right"
    }
  }));

  return (
    <FlexRow
      style={styles.rowContainer}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex fill={2}>{inputLabel}</Flex>
      <Flex fill={1}>
        <StyledTextInput
          {...inputProps}
          style={styles.input}
          placeholder="0.00"
          mask={mask}
          keyboardType={numericKeyboardType()}
          renderLeftAccessory={() => (
            <IconInputAccessory name="dollar-sign" color="blue" />
          )}
        />
      </Flex>
    </FlexRow>
  );
};

export default MoneyInputRow;
