import { isEqual } from "lodash";
import React from "react";
import { TouchableOpacity, View } from "react-native";

import { type IconName, StyledIcon } from "../icon";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { StyledText } from "../text";

type Props = StylesProps & {
  backgroundColorActive: string;
  backgroundColorInactive?: string | undefined;
  borderColor?: string | undefined;
  diameter: number;
  fontColor?: string;
  icon: IconName;
  iconColorActive?: string;
  iconColorInactive?: string;
  label?: string;
  style?: ViewStyleProp;
  testID?: string | undefined;
  text?: string;
  width: string | number;
  value: boolean;
  onPress: () => unknown;
};

@withStyles(({ font }) => ({
  outerContainer: {
    alignItems: "center",
    padding: 5
  },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    fontSize: font.size.small,
    textAlign: "center"
  }
}))
export default class ToggleIconButton extends React.Component<Props> {
  static defaultProps = {
    width: 100,
    diameter: 80,
    ...stylesStubs
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      // Shallow checks
      nextProps.backgroundColorActive !== this.props.backgroundColorActive ||
      nextProps.backgroundColorInactive !==
        this.props.backgroundColorInactive ||
      nextProps.diameter !== this.props.diameter ||
      nextProps.icon !== this.props.icon ||
      nextProps.iconColorActive !== this.props.iconColorActive ||
      nextProps.iconColorInactive !== this.props.iconColorInactive ||
      nextProps.width !== this.props.width ||
      nextProps.onPress !== this.props.onPress || // Deep checks
      !isEqual(nextProps.style, this.props.style)
    );
  }

  get inlineStyle() {
    const {
      backgroundColorActive,
      backgroundColorInactive,
      borderColor,
      diameter
    } = this.props;
    const marginBottom =
      (backgroundColorActive || backgroundColorInactive) ?? borderColor ? 5 : 0;
    return {
      borderColor,
      marginBottom,
      borderRadius: Math.floor(diameter / 2),
      borderWidth: borderColor ? 2 : 0,
      height: diameter,
      width: diameter
    };
  }

  get iconStyle() {
    const { diameter } = this.props;
    const iconScale = 2.5;
    return {
      fontSize: diameter / iconScale
    };
  }

  get textStyle() {
    const { diameter, theme } = this.props;
    const iconScale = 2.5;
    return {
      color: theme.color.neutralDark,
      fontSize: diameter / iconScale,
      lineHeight: diameter / iconScale
    };
  }

  colorStyle = (color?: string) => {
    return { color };
  };

  widthStyle = (width: string | number) => {
    return { width };
  };

  backgroundColorStyle = (value: boolean) => {
    const { backgroundColorActive, backgroundColorInactive } = this.props;
    return {
      backgroundColor: value ? backgroundColorActive : backgroundColorInactive
    };
  };

  iconColorStyle = (value: boolean) => {
    const { iconColorActive, iconColorInactive } = this.props;
    return {
      color: value ? iconColorActive : iconColorInactive
    };
  };

  render() {
    const {
      fontColor = this.props.theme.color.neutralDark,
      icon,
      label,
      onPress,
      style,
      styles,
      testID,
      text,
      width,
      value
    } = this.props;
    return (
      <View style={[styles.outerContainer, this.widthStyle(width), style]}>
        <TouchableOpacity
          testID={testID ?? "icon-button"}
          onPress={onPress}
          style={[
            styles.iconContainer,
            this.inlineStyle,
            this.backgroundColorStyle(value)
          ]}
        >
          {icon && (
            <StyledIcon
              name={icon}
              style={[this.iconStyle, this.iconColorStyle(value)]}
            />
          )}
          {text && !icon && (
            <StyledText style={this.textStyle}>{text}</StyledText>
          )}
        </TouchableOpacity>
        {label && (
          <StyledText
            testID="icon-button-text"
            numberOfLines={2}
            onPress={onPress}
            style={[styles.text, this.colorStyle(fontColor)]}
          >
            {label}
          </StyledText>
        )}
      </View>
    );
  }
}
