import { toast } from "@gigsmart/atorasu";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import { useCallback } from "react";
import type {
  GigConversationRule,
  SetGigLikeConversationRuleInput,
  helpersSetConversationRuleMutation
} from "./__generated__/helpersSetConversationRuleMutation.graphql";

export type { GigConversationRule };

export function useSetConversationRule() {
  const [setGigLikeConversationRule] =
    useRelayMutationPromise<helpersSetConversationRuleMutation>(graphql`
      mutation helpersSetConversationRuleMutation(
        $input: SetGigLikeConversationRuleInput!
      ) {
        setGigLikeConversationRule(input: $input) {
          gigLike {
            id
            conversationRule
            ... on Gig {
              id
              conversationRule
              conversation {
                id
                status
                participant {
                  messageStats {
                    unreadCount
                  }
                }
                currentMessage {
                  id
                }
              }
            }
          }
        }
      }
    `);

  return useCallback(
    async ({
      conversationRule,
      gigLikeId
    }: SetGigLikeConversationRuleInput) => {
      const __typename = gigLikeId.startsWith("gigsr") ? "GigSeries" : "Gig";
      return await setGigLikeConversationRule(
        { input: { conversationRule, gigLikeId } },
        {
          onError: (e) =>
            toast.error(`Error saving Conversation settings ${e.message}`),
          optimisticResponse: {
            setGigLikeConversationRule: {
              gigLike: { __typename, id: gigLikeId, conversationRule }
            }
          }
        }
      );
    },
    []
  );
}
