/**
 * @generated SignedSource<<1d42206b1ce7d149f10755ae655d2c99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DirectHireAction = "ACCEPT" | "ACKNOWLEDGE" | "AUTHOR" | "CHARGE" | "CLOSE" | "DENY" | "ESCALATE" | "GOODWILL" | "PUBLISH" | "REJECT" | "TIMEOUT" | "%future added value";
export type DirectHireState = "CHARGED" | "CLOSED" | "DISBURSED" | "DRAFT" | "ESCALATED" | "PUBLISHED" | "REJECTED" | "%future added value";
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DirectHireClaimDetailsWorker_DirectHireClaim$data = {
  readonly approved: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly transitionedAt: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly comments: string | null | undefined;
  readonly currentTransition: {
    readonly state: DirectHireState;
  } | null | undefined;
  readonly directHireDate: string | null | undefined;
  readonly directHireType: DirectHireType | null | undefined;
  readonly disbursed: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly transitionedAt: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly lastName: string | null | undefined;
  readonly organization: {
    readonly id: string;
    readonly logoUrl: string | null | undefined;
    readonly name: string;
    readonly overallRating: number;
    readonly reviewCount: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly photos: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly published: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly transitionedAt: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly rejected: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly action: DirectHireAction;
        readonly transitionedAt: string;
        readonly transitionedBy: {
          readonly agent: {
            readonly __typename: string;
          } | null | undefined;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly response: string | null | undefined;
  readonly responseDeadline: string | null | undefined;
  readonly timezone: string | null | undefined;
  readonly workerPayout: string | null | undefined;
  readonly " $fragmentType": "DirectHireClaimDetailsWorker_DirectHireClaim";
};
export type DirectHireClaimDetailsWorker_DirectHireClaim$key = {
  readonly " $data"?: DirectHireClaimDetailsWorker_DirectHireClaim$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectHireClaimDetailsWorker_DirectHireClaim">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DirectHireClaimTransitionsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaimTransition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectHireClaimDetailsWorker_DirectHireClaim",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "response",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerPayout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DirectHireClaimTransition",
      "kind": "LinkedField",
      "name": "currentTransition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        }
      ],
      "concreteType": "DirectHireClaimPhotosConnection",
      "kind": "LinkedField",
      "name": "photos",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DirectHireClaimPhotosEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DirectHireClaimPhoto",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "photos(first:5)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "responseDeadline",
      "storageKey": null
    },
    {
      "alias": "rejected",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE state = REJECTED"
        }
      ],
      "concreteType": "DirectHireClaimTransitionsConnection",
      "kind": "LinkedField",
      "name": "transitions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DirectHireClaimTransitionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DirectHireClaimTransition",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "action",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccessContext",
                  "kind": "LinkedField",
                  "name": "transitionedBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "agent",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "transitions(first:1,query:\"WHERE state = REJECTED\")"
    },
    {
      "alias": "published",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE state = PUBLISHED"
        }
      ],
      "concreteType": "DirectHireClaimTransitionsConnection",
      "kind": "LinkedField",
      "name": "transitions",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "transitions(first:1,query:\"WHERE state = PUBLISHED\")"
    },
    {
      "alias": "approved",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE action = ACCEPT"
        }
      ],
      "concreteType": "DirectHireClaimTransitionsConnection",
      "kind": "LinkedField",
      "name": "transitions",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "transitions(first:1,query:\"WHERE action = ACCEPT\")"
    },
    {
      "alias": "disbursed",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE state = DISBURSED"
        }
      ],
      "concreteType": "DirectHireClaimTransitionsConnection",
      "kind": "LinkedField",
      "name": "transitions",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "transitions(first:1,query:\"WHERE state = DISBURSED\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overallRating",
          "storageKey": null
        },
        {
          "alias": "reviewCount",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 0
            }
          ],
          "concreteType": "EngagementReviewsConnection",
          "kind": "LinkedField",
          "name": "reviewsReceived",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "reviewsReceived(first:0)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DirectHireClaim",
  "abstractKey": null
};
})();

(node as any).hash = "a7b2378e01da0f47303e4bbed85b75a4";

export default node;
