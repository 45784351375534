import {
  Stack,
  type StepProps,
  StepSubmit,
  Text,
  toast
} from "@gigsmart/atorasu";
import {
  GenericConsents,
  TwilioConsentOnboarding
} from "@gigsmart/feature-flags";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import React, { useCallback } from "react";
import LegacyMobileNumberDisclosure from "./LegacyMobileNumberDisclosure";
import LegacyTwilioTermsOfServiceCheckbox from "./LegacyTwilioTermsOfServiceCheckbox";
import MobileNumberDisclosure from "./MobileNumberDisclosure";
import type { MobileNumberStepUpdateMobileNumberMutation } from "./__generated__/MobileNumberStepUpdateMobileNumberMutation.graphql";
import type { OnboardingData } from "./types";

type Props = StepProps<OnboardingData>;

export default function MobileNumberStep({ stepper }: Props) {
  const [updateMobileNumber] =
    useRelayMutationPromise<MobileNumberStepUpdateMobileNumberMutation>(
      graphql`
        mutation MobileNumberStepUpdateMobileNumberMutation(
          $input: SetUserMobileInput!
        ) {
          setUserMobile(input: $input) {
            user {
              primaryMobile {
                number
                confirmed
              }
              pendingConsents {
                body
                filename
              }
            }
          }
        }
      `
    );

  const handleSubmit: FomuSubmitFn = useCallback(
    async ({ values }) => {
      const { phone } = values;

      try {
        await updateMobileNumber({ input: { number: phone } });
        stepper.nextStep({ phone });
      } catch (err) {
        toast.error(
          err.message || "Unexpected error while updating phone number"
        );
      }
    },
    [updateMobileNumber]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Stack>
        <Text>We'll send you a code to verify your account.</Text>
        <FomuTextInput
          name="phone"
          validates={Validator.phoneNumber()}
          label="Mobile Number"
          note="Mobile number is private and not shared with other users."
          mask="phoneNumber"
          type="number"
          autoFocus
        />
        {GenericConsents.isEnabled() ? (
          <MobileNumberDisclosure />
        ) : TwilioConsentOnboarding.isEnabled() ? (
          <LegacyMobileNumberDisclosure />
        ) : null}
        {GenericConsents.isDisabled() && TwilioConsentOnboarding.isEnabled() ? (
          <LegacyTwilioTermsOfServiceCheckbox />
        ) : null}
      </Stack>
      <StepSubmit testID="next-btn" label="Next" />
    </Form>
  );
}
