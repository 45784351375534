/**
 * @generated SignedSource<<c6ba3703eae933a72434288a21abe5db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAutoBlockSetting_organizationRequester$data = {
  readonly organization: {
    readonly settings: {
      readonly autoBlock: boolean;
    };
  };
  readonly " $fragmentType": "useAutoBlockSetting_organizationRequester";
};
export type useAutoBlockSetting_organizationRequester$key = {
  readonly " $data"?: useAutoBlockSetting_organizationRequester$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAutoBlockSetting_organizationRequester">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAutoBlockSetting_organizationRequester",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "autoBlock",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationRequester",
  "abstractKey": null
};

(node as any).hash = "986851bad82bbf725fe20cf4de6e57b0";

export default node;
