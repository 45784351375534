import { registerAnnouncementHandler } from "@gigsmart/isomorphic-shared/announcement";
import { useHistory } from "@gigsmart/kaizoku";
import { graphql } from "@gigsmart/relay";
import type { handlersCrisisGigsQuery } from "./__generated__/handlersCrisisGigsQuery.graphql";

registerAnnouncementHandler<{
  name?: string | null;
  options: { crisisId?: string | null };
}>(
  "OPEN_SCREEN",
  ({ options: { crisisId } }) => {
    const history = useHistory();
    history.push({
      pathname: "/browse/shift-gigs",
      queryParams: { crisisId }
    });
    return null;
  },
  async ({ name, options: { crisisId } }, fetchQuery) => {
    if (name !== "available-gigs") return false;
    const result = await fetchQuery<handlersCrisisGigsQuery>(
      graphql`
        query handlersCrisisGigsQuery($crisisId: ID) {
          viewer {
            ... on Worker {
              availableGigs(crisisId: $crisisId, first: 0, maxDistance: 75) {
                totalCount
              }
            }
          }
        }
      `,
      { crisisId }
    );
    return !!result.viewer?.availableGigs?.totalCount;
  }
);
