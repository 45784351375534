import { Column, Icon, Spacer, Text } from "@gigsmart/atorasu";
import React from "react";

export default function JobsListEmptyMessage() {
  return (
    <>
      <Spacer />
      <Column fill={1} alignItems="center" justifyContent="center">
        <Icon name="folder-open" size="default" />
        <Spacer />
        <Text color="primary" weight="bold" variant="bodyLg" align="center">
          No Matching Results
        </Text>
        <Text>Update your search criteria for more Job Postings.</Text>
      </Column>
    </>
  );
}
