/**
 * @generated SignedSource<<bc4a0ffcb966c8d3f8f43ac91c16bc09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMessage_userMessage$data = {
  readonly attachments: ReadonlyArray<{
    readonly filename: string | null | undefined;
    readonly id: string;
    readonly mimeType: string;
    readonly url: string;
  }>;
  readonly author: {
    readonly __typename: string;
    readonly displayName: string | null | undefined;
    readonly id: string;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
  };
  readonly body: string | null | undefined;
  readonly deletedAt: string | null | undefined;
  readonly id: string;
  readonly insertedAt: string;
  readonly " $fragmentType": "UserMessage_userMessage";
};
export type UserMessage_userMessage$key = {
  readonly " $data"?: UserMessage_userMessage$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMessage_userMessage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMessage_userMessage",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profilePhoto",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMessageAttachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimeType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserMessage",
  "abstractKey": null
};
})();

(node as any).hash = "f38d0c7cf7aba06a5e1f9fd0228b08ca";

export default node;
