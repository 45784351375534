import { type FormFieldOptions, Validator, useFormField } from "@gigsmart/fomu";
import { StyledCreditCardInput, numericKeyboardType } from "@gigsmart/katana";
import React, { type ComponentProps, useMemo } from "react";
import { Platform } from "react-native";

type StyledCreditCardInputProps = ComponentProps<typeof StyledCreditCardInput>;

type RefinedStyledCreditCardInputProps = Omit<
  StyledCreditCardInputProps,
  "onSubmitEditing" | "value" | "onChangeText" | "errors"
>;

type Props = RefinedStyledCreditCardInputProps & FormFieldOptions;

export function FomuStyledCreditCardInput({
  name,
  validates = [Validator.cardNumber({ message: "invalid card number" })],
  shouldShowErrors,
  sensitive,
  disabled,
  ...props
}: Props) {
  const { setValue, value, submit, errors, showErrors, triggerFocus } =
    useFormField({
      name,
      validates: disabled ? [] : validates,
      shouldShowErrors,
      sensitive
    });
  const keyboardType = useMemo(numericKeyboardType, []);
  return (
    <StyledCreditCardInput
      keyboardType={keyboardType}
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value}
      onChangeText={setValue}
      onBlur={showErrors}
      onFocus={triggerFocus}
      disabled={disabled}
      errors={errors ?? []}
      {...props}
    />
  );
}
