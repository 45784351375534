import { Icon, Row, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import React from "react";
import { Animated, TouchableOpacity, View } from "react-native";

interface Props {
  label: string;
  showDropdown: boolean;
  toggleDropdown: () => void;
  activeLineScale: Animated.Value;
}

export default ({
  label,
  showDropdown,
  toggleDropdown,
  activeLineScale
}: Props) => {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      backgroundColor: getColor("background", "fill"),
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomColor: getColor("neutral", "fill"),
      borderBottomWidth: 1,
      justifyContent: "center",
      height: 44,
      marginBottom: 4
    },
    focused: {
      backgroundColor: getColor("background", "fill")
    },
    innerContainer: {
      paddingHorizontal: getUnits(4)
    },
    iconContainer: {
      marginLeft: getUnits(1),
      marginBottom: getUnits(1.5)
    },
    activeUnderline: {
      backgroundColor: getColor("highlight", "fill"),
      height: 2,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    }
  }));
  return (
    <Row
      style={[styles.container, showDropdown && styles.focused]}
      alignItems="center"
    >
      <TouchableOpacity onPress={toggleDropdown} testID="radius-picker">
        <Row alignItems="center" style={styles.innerContainer}>
          <Text color="primary" weight="semibold">
            {label}
          </Text>
          <View style={styles.iconContainer}>
            <Icon
              name="sort-down"
              variant="solid"
              color="primary"
              size="medium"
            />
          </View>
        </Row>
      </TouchableOpacity>
      <Animated.View
        style={[
          styles.activeUnderline,
          {
            transform: [
              {
                scale: activeLineScale
              }
            ]
          }
        ]}
      />
    </Row>
  );
};
