import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";
import NotificationList from "./notification-list";
import useNotificationSections from "./use-notification-sections";

type Props = Omit<
  ComponentPropsWithDefaults<typeof NotificationList>,
  "sections"
>;

export default function NotificationCenter(props: Props) {
  const sections = useNotificationSections();
  return <NotificationList {...props} sections={sections} />;
}
