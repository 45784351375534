import React, { type ReactNode } from "react";
import { View } from "react-native";

import { Visible } from "@gigsmart/atorasu";
import { responsive, useStyles } from "@gigsmart/atorasu/style";
import { WebContent, WebHeader } from "@gigsmart/isomorphic-shared/layout";
import { BackgroundCard } from "@gigsmart/katana";

interface Props {
  buttonRow: React.ComponentType<any>;
  children: ReactNode;
  testID?: string;
  spaceBetween?: boolean;
}

export default ({
  buttonRow: ButtonRow,
  children,
  testID,
  spaceBetween
}: Props) => {
  const styles = useStyles(({ getUnits, media, getColor }) => ({
    mobileFlex: [
      responsive(media.size.xsmall.down, {
        flex: 1,
        backgroundColor: getColor("surface", "fill")
      })
    ],
    container: [
      {
        paddingVertical: getUnits(8),
        paddingHorizontal: getUnits(8)
      },
      responsive(media.size.small.down, {
        paddingVertical: getUnits(4),
        paddingHorizontal: getUnits(4)
      })
    ],
    webButtonContainer: {
      paddingVertical: getUnits(4)
    },
    buttonContainer: [
      {
        paddingHorizontal: getUnits(4) * 8
      },
      responsive(media.size.small.down, { paddingHorizontal: getUnits(4) * 2 }),
      responsive(media.size.xsmall.down, { paddingHorizontal: getUnits(4) })
    ],
    spaceBetween: {
      justifyContent: "space-between",
      paddingBottom: getUnits(4) * 2
    }
  }));
  return (
    <>
      <WebHeader showBackButton />
      <View style={styles.mobileFlex} testID={testID}>
        <BackgroundCard
          style={styles.mobileFlex}
          contentStyle={[
            styles.mobileFlex,
            spaceBetween && styles.spaceBetween
          ]}
        >
          <View style={[styles.container, spaceBetween && styles.mobileFlex]}>
            {children}
          </View>
          <Visible media={({ size }) => size.xsmall.down}>
            <View style={styles.buttonContainer}>
              <ButtonRow />
            </View>
          </Visible>
        </BackgroundCard>
        <Visible media={({ size }) => size.small.up}>
          <WebContent>
            <View style={[styles.webButtonContainer, styles.buttonContainer]}>
              <ButtonRow />
            </View>
          </WebContent>
        </Visible>
      </View>
    </>
  );
};
