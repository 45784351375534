import { ContentArea, List, Stack, Text, Well } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { PromptModal, StyledScrollView } from "@gigsmart/katana";
import React from "react";

interface Props {
  showModal: boolean;
  handleClose: () => void;
}

const NextStepsProjectModal = ({ showModal, handleClose }: Props) => {
  const styles = useStyles(({ getUnits, getColor }) => ({
    contentContainer: {
      borderWidth: 1,
      borderRadius: 4,
      maxHeight: getUnits(88.5),
      borderColor: getColor("background", "fill")
    }
  }));

  return (
    <PromptModal
      testID="next-steps-project-modal"
      eventContext="Next Steps Project Modal"
      visible={showModal}
      title="Next Steps for Project Gigs"
      onClose={handleClose}
      actions={[
        {
          title: "Got It",
          onPress: handleClose,
          variant: "solid",
          testID: "close-next-steps-project-modal"
        }
      ]}
    >
      <StyledScrollView style={styles.contentContainer}>
        <ContentArea>
          <Stack>
            <Text>Here's what happens after you apply:</Text>
            <Well>
              <List size="compact">
                <Text>
                  The Requester will review your application and bid. If
                  interested they will reach out to you
                </Text>
                <Stack size="compact">
                  <Text>
                    Use in-app messaging to agree upon the Project details with
                    the Requester
                  </Text>
                  <List variant="bullet" size="compact">
                    <Text>Amount you will be paid for the Project Gig</Text>
                    <Text>Date & time you to start the Project Gig</Text>
                    <Text>
                      The payment method (cash, check, in-app, etc.) for the
                      Project Gig
                    </Text>
                  </List>
                </Stack>
                <Text>
                  If you are hired for the Project Gig, you'll be prompted to
                  accept or reject the Project Gig offer
                </Text>
                <Text>If you accept, you'll be hired on the Project Gig</Text>
                <Text>
                  Complete the Project Gig and mark it as completed in the app
                  once you finish
                </Text>
                <Text>Leave the Requester a rating and review</Text>
                <Text>
                  Receive your earnings from the Requester via the agreed upon
                  payment method
                </Text>
              </List>
            </Well>
          </Stack>
        </ContentArea>
      </StyledScrollView>
    </PromptModal>
  );
};

export default NextStepsProjectModal;
