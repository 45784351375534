/**
 * @generated SignedSource<<27f1e122c58dfcc47ae42cb5cafa19e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useEngagementListSeriesAggregate_engagement$data = {
  readonly estimatedPayment: {
    readonly grossPay: string;
  } | null | undefined;
  readonly gig: {
    readonly gigSeries: {
      readonly id: string;
    };
    readonly place: {
      readonly streetAddress: string | null | undefined;
    } | null | undefined;
    readonly position: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
  };
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadataIsEnrolled_gigOrEngagement">;
  readonly " $fragmentType": "useEngagementListSeriesAggregate_engagement";
};
export type useEngagementListSeriesAggregate_engagement$key = {
  readonly " $data"?: useEngagementListSeriesAggregate_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEngagementListSeriesAggregate_engagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useEngagementListSeriesAggregate_engagement"
};

(node as any).hash = "9dfc09e6352ee15b7bdda2aea55c49d8";

export default node;
