import {
  Button,
  CollapsibleList,
  CollapsibleWell,
  ContentArea,
  Divider,
  GridNull,
  IconText,
  Spacer,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import { WorkerEngagementView } from "@gigsmart/feature-flags";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";
import React from "react";
import HomeScreenCard from "../worker/HomescreenCard";

import { DateTime } from "luxon";
import type { EducationCard_worker$key } from "./__generated__/EducationCard_worker.graphql";

interface Props {
  viewer?: "Requester" | "Worker";
  onPressAdd?: () => void;
  onPressEdit?: (id: string) => void;
}

export const EducationCard = ({
  educationHistory,
  viewer = "Worker",
  onPressAdd,
  onPressEdit
}: FragmentContainerInnerComponentProps<EducationCard_worker$key, Props>) => {
  const education = getConnectionEdges(educationHistory);

  const formatDate = (v: string) =>
    DateTime.fromISO(v ?? new Date().toISOString()).toFormat("MMMM yyyy");

  const workerView = () => (
    <HomeScreenCard
      headerTitle="Education"
      headerIcon="graduation-cap"
      testID="profile-education-card"
    >
      <Stack>
        <CollapsibleList
          items={education.map(({ node }) => ({
            title: node?.schoolName ?? "",
            content: (
              <>
                <Spacer size="slim" />
                <Text>{node?.educationLevel ?? ""}</Text>
                {node?.startDate && node?.endDate && (
                  <Text>
                    {formatDate(node.startDate)} - {formatDate(node.endDate)}
                  </Text>
                )}
              </>
            ),
            educationId: node?.id
          }))}
          placeholder="Adding your education can increase your chances of finding the right Shifts and being selected."
          iconName="pen-to-square"
          testID="education-history"
          onPress={({ educationId }) => onPressEdit?.(educationId ?? "")}
          startCollapsed
        />
        <Button
          icon="plus"
          label="Add Education"
          testID="add-education"
          outline
          onPress={onPressAdd}
        />
      </Stack>
    </HomeScreenCard>
  );

  const reqeusterView = () => {
    if (!education.length) {
      return <GridNull />;
    }

    return WorkerEngagementView.select(
      <CollapsibleWell
        testID="worker-profile-education"
        headerIcon="graduation-cap"
        headerText="Education"
        items={education.map(({ node }, index) => (
          <>
            <Text variant="subheader" color="black">
              {node?.schoolName}
            </Text>
            <Spacer size="slim" />
            <Text>{node?.educationLevel ?? ""}</Text>
            {node?.startDate && node?.endDate && (
              <Text>
                {formatDate(node.startDate)} - {formatDate(node.endDate)}
              </Text>
            )}
            {index < education.length - 1 && (
              <>
                <Spacer size="compact" />
                <Divider />
              </>
            )}
          </>
        ))}
      />,
      <Stack size="medium">
        <Well color="black" size="compact">
          <IconText
            icon="graduation-cap"
            spacing="medium"
            textWeight="bold"
            size="small"
          >
            Education
          </IconText>
        </Well>
        <ContentArea size="none">
          <CollapsibleList
            items={education.map(({ node }) => ({
              title: node?.schoolName ?? "",
              content: (
                <>
                  <Spacer size="slim" />
                  <Text>{node?.educationLevel ?? ""}</Text>
                  {node?.startDate && node?.endDate && (
                    <Text>
                      {formatDate(node.startDate)} - {formatDate(node.endDate)}
                    </Text>
                  )}
                </>
              )
            }))}
            iconName="pen-to-square"
            testID="requester-worker-education-row"
            startCollapsed={false}
            allowCollapse={false}
          />
        </ContentArea>
      </Stack>
    );
  };

  return viewer === "Worker" ? workerView() : reqeusterView();
};

export default createRelayFragmentContainer<EducationCard_worker$key, Props>(
  graphql`
    fragment EducationCard_worker on Worker {
      educationHistory: education(
        first: 50
        query: "ORDER BY endDate DESC NULLS FIRST, startDate DESC NULLS FIRST"
      ) {
        edges {
          node {
            id
            schoolName
            educationLevel
            startDate
            endDate
          }
        }
      }
    }
  `,
  EducationCard
);
