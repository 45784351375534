import moment from "moment-timezone";
interface PaymentMethod {
  readonly accountHolderName: string | null | undefined;
  readonly type: string;
  readonly last4: string;
  readonly expirationMonth?: number;
  readonly expirationYear?: number;
  readonly postalCode?: string | null;
  readonly routingNumber?: string;
}

export function paymentMethodToFormData(
  paymentMethod?: PaymentMethod | null | undefined
) {
  const emptyCard = {
    name: "",
    number: "",
    cvc: "",
    expiration: "",
    addressZip: ""
  };
  const bankAccount = {
    routingNumber: "",
    accountHolderName: "",
    accountNumber: ""
  };
  if (!paymentMethod) {
    return {
      BankCard: emptyCard,
      BankAccount: bankAccount
    };
  }

  const {
    type,
    accountHolderName,
    expirationMonth,
    expirationYear,
    last4,
    postalCode,
    routingNumber
  } = paymentMethod;
  return {
    BankCard:
      type === "BankCard"
        ? {
            name: accountHolderName,
            number: `••••••••••••${last4}`,
            cvc: "•••",
            expiration: moment({
              month: Number(expirationMonth) - 1,
              year: Number(expirationYear)
            }).format("MM/YY"),
            addressZip: postalCode
          }
        : emptyCard,
    BankAccount:
      type === "BankAccount"
        ? {
            routingNumber,
            accountHolderName,
            accountNumber: `••••••${last4}`
          }
        : bankAccount
  };
}
