import type { Moment } from "moment";
import React from "react";
import { TouchableOpacity, View } from "react-native";

import { StyledIcon } from "../../icon";
import { type ViewStyleProp, createStyles } from "../../style";
import { StyledText } from "../../text";

import { DATE_FORMAT } from "./helpers";

const styles = createStyles(({ color, font }) => ({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    ...font.body("bold"),
    fontSize: 15, // font.size.large,
    color: color.blue,
    minWidth: 200,
    textAlign: "center"
  },
  icon: {
    fontSize: 18,
    color: color.blue,
    paddingVertical: 14,
    paddingHorizontal: 18
  },
  disabledIcon: {
    color: color.neutralMedium
  }
}));

interface Props {
  style?: ViewStyleProp;
  min?: string;
  max?: string;
  month: Moment;
  onSetMonth: (delta: number) => void;
}

export default ({ style, min, max, month, onSetMonth }: Props) => {
  const disabledPrev =
    !!min && min >= month.clone().startOf("month").format(DATE_FORMAT);

  const disabledNext =
    !!max && max <= month.clone().endOf("month").format(DATE_FORMAT);

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        testID="date-picker-month-prev"
        disabled={disabledPrev}
        onPress={() => onSetMonth(-1)}
      >
        <StyledIcon
          name="chevron-left"
          style={[styles.icon, disabledPrev && styles.disabledIcon]}
        />
      </TouchableOpacity>
      <StyledText style={styles.label}>{month.format("MMMM YYYY")}</StyledText>
      <TouchableOpacity
        testID="date-picker-month-next"
        disabled={disabledNext}
        onPress={() => onSetMonth(1)}
      >
        <StyledIcon
          name="chevron-right"
          style={[styles.icon, disabledNext && styles.disabledIcon]}
        />
      </TouchableOpacity>
    </View>
  );
};
