import sortedIndex from "lodash/sortedIndex";
import type { Moment } from "moment";
import React from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../../style";
import { StyledText } from "../../text";
import { MONTH_FORMAT } from "./helpers";
import MonthCalendarEntry from "./month-calendar-entry";
import useCalendar from "./use-calendar";

interface Props {
  style?: ViewStyleProp;
  min?: string;
  max?: string;
  month: Moment;
  selectedDays?: string[];
  onChange?: (value: string[]) => void;
  onChangeMonth?: (arg0: string) => void;
  multiple?: boolean;
  pinnedDays?: string[];
}

export default ({
  min,
  max,
  month,
  selectedDays,
  onChange,
  multiple,
  pinnedDays
}: Props) => {
  const { styles } = useStyles(({ color, font }) => ({
    header: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    week: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginVertical: 1
    },
    weekHeader: {
      ...font.body("bold"),
      textAlign: "center",
      fontSize: font.size.small,
      color: color.neutralDark
    },
    container: {
      alignItems: "center"
    },
    content: {
      width: 400,
      "@phone": {
        width: "100%"
      }
    }
  }));
  const weeks = useCalendar(month);
  const monthStr = month.format(MONTH_FORMAT);

  const isDayDisabled = (day: string) =>
    (!!min && min > day) || (!!max && max < day);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.week}>
          <StyledText style={styles.weekHeader}>S</StyledText>
          <StyledText style={styles.weekHeader}>M</StyledText>
          <StyledText style={styles.weekHeader}>T</StyledText>
          <StyledText style={styles.weekHeader}>W</StyledText>
          <StyledText style={styles.weekHeader}>T</StyledText>
          <StyledText style={styles.weekHeader}>F</StyledText>
          <StyledText style={styles.weekHeader}>S</StyledText>
        </View>

        {weeks.map((week) => (
          <View key={`week-${week[0]?.value}`} style={styles.week}>
            {week.map((day) => {
              const isDisabled = isDayDisabled(day.value);
              const isSelected =
                !!selectedDays && selectedDays.includes(day.value);
              const isPinned = pinnedDays?.includes(day.value);
              return (
                <MonthCalendarEntry
                  key={day.value}
                  testID={`date-${day.value}`}
                  isSelected={isSelected}
                  isSameMonth={day.month === monthStr}
                  isPinned={isPinned}
                  disabled={isDisabled}
                  onPress={() => {
                    if (multiple) {
                      const idx = sortedIndex(selectedDays, day.value);
                      const newValue = selectedDays ? [...selectedDays] : [];
                      if (isSelected) newValue.splice(idx, 1);
                      else newValue.splice(idx, 0, day.value);
                      onChange?.(newValue);
                    } else if (!isSelected) {
                      onChange?.([day.value]);
                    }
                  }}
                  {...day}
                />
              );
            })}
          </View>
        ))}
      </View>
    </View>
  );
};
