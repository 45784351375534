import type { AppScreenProps } from "@gigsmart/kaizoku";
import React from "react";
import ApplyGigStepper from "../gig/apply-gig-stepper/apply-gig-stepper";
import type { WorkerParamList } from "../navigation/types";
import GigDistanceCheckWrapper from "./gig-distance-check-wrapper";

type Props = AppScreenProps<WorkerParamList, "BrowseShiftAction">;

export default function AvailableGigApply({ route }: Props) {
  const { id, engagementsToAccept, action: routeAction } = route.params;
  const isSeries = id?.startsWith("gigsr");
  const action = routeAction === "apply" ? "APPLY" : "PICK_UP";
  return (
    <GigDistanceCheckWrapper id={id} isSeries={isSeries}>
      <ApplyGigStepper
        engagementsToAccept={engagementsToAccept}
        action={action}
        isSeries={isSeries}
        gigId={id}
      />
    </GigDistanceCheckWrapper>
  );
}
