/**
 * @generated SignedSource<<2764dbbf5730b5dd855fd638a23966d1>>
 * @relayHash a846892230b576f3ccc8b2574512c0f5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:e3zVAstQO8Dqv-3wnrjZglZFpcxV1lGo_zP2BpGJQS0

import { ConcreteRequest } from 'relay-runtime';
export type UpdateWorkerQualificationDriversLicenseProofInput = {
  backPhotoId?: string | null | undefined;
  expirationDate?: string | null | undefined;
  frontPhotoId?: string | null | undefined;
  issueDate?: string | null | undefined;
  issuingState?: string | null | undefined;
  licenseNumber?: string | null | undefined;
  workerQualificationProofId: string;
};
export type CommercialDriversLicenseModalUpdateMutation$variables = {
  input: UpdateWorkerQualificationDriversLicenseProofInput;
};
export type CommercialDriversLicenseModalUpdateMutation$data = {
  readonly updateWorkerQualificationDriversLicenseProof: {
    readonly updatedWorkerQualificationDriversLicenseProof: {
      readonly id: string;
    };
  } | null | undefined;
};
export type CommercialDriversLicenseModalUpdateMutation = {
  response: CommercialDriversLicenseModalUpdateMutation$data;
  variables: CommercialDriversLicenseModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorkerQualificationDriversLicenseProofPayload",
    "kind": "LinkedField",
    "name": "updateWorkerQualificationDriversLicenseProof",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerQualificationDriversLicenseProof",
        "kind": "LinkedField",
        "name": "updatedWorkerQualificationDriversLicenseProof",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommercialDriversLicenseModalUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommercialDriversLicenseModalUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:e3zVAstQO8Dqv-3wnrjZglZFpcxV1lGo_zP2BpGJQS0",
    "metadata": {},
    "name": "CommercialDriversLicenseModalUpdateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bd4e82407598c5a9d75d2c1c43f74323";

export default node;
