import React, { type ReactElement } from "react";
import { View } from "react-native";

import FlexRow from "../flex/row";
import useStyles from "../style/use-styles";
import SmallClearButton from "../tappable/small-buttons/small-clear-button";
import SmallSolidButton from "../tappable/small-buttons/small-solid-button";
import KatanaText from "../text/katana-text";
import Card from "./card";

interface Props {
  title: string;
  testID?: string;
  bodyText: string;
  buttonTitle: string;
  onAction: () => void;
  secondaryButtonTitle?: string | null;
  onSecondaryAction?: () => void;
  disabled?: boolean;
  ImageComponent?: () => ReactElement;
}

const CallToActionCard = ({
  title,
  bodyText,
  buttonTitle,
  onAction,
  secondaryButtonTitle,
  onSecondaryAction,
  disabled,
  ImageComponent,
  testID
}: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    card: {
      borderRadius: units(1)
    },
    container: {
      padding: units(4)
    },
    title: {
      color: color.blue,
      paddingBottom: units(2)
    },
    buttonContainer: {
      paddingTop: units(4)
    }
  }));
  return (
    <Card
      noSpacing
      style={styles.card}
      testID={testID ?? "call-to-action-card"}
    >
      {!!ImageComponent && <ImageComponent />}
      <View style={styles.container}>
        <KatanaText variant="rowTitle" style={styles.title}>
          {title}
        </KatanaText>
        <KatanaText>{bodyText}</KatanaText>
        <FlexRow justifyContent="center" style={styles.buttonContainer}>
          <SmallSolidButton
            fill
            testID={testID ? `${testID}-btn` : "call-to-action-card-btn"}
            disabled={disabled}
            title={buttonTitle}
            onPress={onAction}
          />
        </FlexRow>
        {secondaryButtonTitle && (
          <FlexRow justifyContent="center" style={styles.buttonContainer}>
            <SmallClearButton
              fill
              testID={
                testID
                  ? `${testID}-secondary-btn`
                  : "call-to-action-card-secondary-btn"
              }
              title={secondaryButtonTitle}
              onPress={onSecondaryAction}
            />
          </FlexRow>
        )}
      </View>
    </Card>
  );
};

export default CallToActionCard;
