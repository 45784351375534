import {
  Button,
  CollapsibleContainer,
  ContentArea,
  Divider,
  ProductInfoRow,
  Row,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { type StepProps, confirmPrompt, dismiss } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import useSetEngagementSummary from "../../engagement/useSetEngagementSummary";
import DisputeCommentContainer from "../DisputeCommentContainer";
import type { DisputeAdditionalPaymentCard_EngagementTimesheet$key } from "./__generated__/DisputeAdditionalPaymentCard_EngagementTimesheet.graphql";

interface Props {
  onRequestPress: () => void;
  comment?: string | null;
  updateData: StepProps["updateData"];
  stepData: StepProps["stepData"];
}

export function DisputeAdditionalPaymentCard({
  onRequestPress,
  comment,
  id,
  disputedFinalTimesheet,
  updateData,
  stepData
}: FragmentContainerInnerComponentProps<
  DisputeAdditionalPaymentCard_EngagementTimesheet$key,
  Props
>) {
  const commit = useSetEngagementSummary();
  const disputeTimesheetExists = !!id && !!comment;

  if (!disputeTimesheetExists) {
    return (
      <DisputeAdditionalPaymentCardFallback
        stepData={stepData}
        updateData={updateData}
        onRequestPress={onRequestPress}
      />
    );
  }

  return (
    <CollapsibleContainer
      testID="dispute-additional-payment-card"
      startCollapsed
      header={
        <ProductInfoRow
          variant="shadow"
          testID="dispute-timesheet-title"
          icon="dollar-sign"
          name={
            <Text color="primary" variant="subheader">
              Additional Payment
            </Text>
          }
          callToActionLabel="Edit"
          callToActionIcon="pen-to-square"
          callToActionOnPress={onRequestPress}
        />
      }
      content={
        <ContentArea>
          <Row justifyContent="space-between" alignItems="center">
            <Text>Additional Payment Request</Text>
            <Text weight="semibold" color="success">
              +{" "}
              {currency.humanize(
                disputedFinalTimesheet?.diff?.additionalPayment
              )}
            </Text>
          </Row>
        </ContentArea>
      }
    >
      <Divider />
      <ContentArea>
        <Stack>
          <DisputeCommentContainer
            label="Why you requested Additional Payment"
            comment={comment}
          />
          <Row justifyContent="center">
            <Button
              testID="delete-additional-payment-btn"
              size="small"
              label="Delete Additional Payment"
              icon="times"
              outline
              onPress={() =>
                confirmPrompt({
                  cancelLabel: "No",
                  title:
                    "Are you sure you want to delete your Additional Payment Request?",
                  subTitle: "",
                  onDo: () => {
                    commit(
                      {
                        input: {
                          additionalPayment: "0 USD",
                          engagementId: stepData.engagementId,
                          disputedTimesheetId: stepData?.disputedTimesheetId,
                          createOverrides: false
                        }
                      },
                      {
                        onSuccess: () => {
                          updateData({
                            ...stepData,
                            additionalPayComment: null
                          });
                          toast.notice(
                            "Your Additional Payment Request has been deleted."
                          );
                        }
                      }
                    );
                  },
                  onCancel: dismiss
                })
              }
            />
          </Row>
        </Stack>
      </ContentArea>
    </CollapsibleContainer>
  );
}

export function DisputeAdditionalPaymentCardFallback({
  onRequestPress
}: Props) {
  return (
    <Surface>
      <ProductInfoRow
        variant="shadow"
        testID="dispute-timesheet-title"
        icon="dollar-sign"
        name={
          <Text color="primary" variant="subheader">
            Additional Payment <Text color="danger">(Required)</Text>
          </Text>
        }
      />
      <ContentArea>
        <Stack>
          <Text weight="semibold">
            Please provide the Additional Payment you are requesting for other
            reasons (tips, reimbursement, etc.)
          </Text>
          <Row justifyContent="center">
            <Button
              testID="request-additional-payment-btn"
              label="Request Additional Payment"
              size="small"
              icon="plus"
              onPress={onRequestPress}
            />
          </Row>
        </Stack>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<
  DisputeAdditionalPaymentCard_EngagementTimesheet$key,
  Props
>(
  graphql`
    fragment DisputeAdditionalPaymentCard_EngagementTimesheet on EngagementTimesheet {
      id
      disputedFinalTimesheet {
        diff {
          additionalPayment
        }
      }
    }
  `,
  DisputeAdditionalPaymentCard,
  DisputeAdditionalPaymentCardFallback
);
