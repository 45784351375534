import { Query } from "@gigsmart/biruda";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";

import type { useWorkerQualificationProofSetMutation } from "./__generated__/useWorkerQualificationProofSetMutation.graphql";
import type { useWorkerQualificationProofUnsetMutation } from "./__generated__/useWorkerQualificationProofUnsetMutation.graphql";

export default function useWorkerQualificationProof(
  filter: Query = new Query()
) {
  const [setProofOnWorkerQualification] =
    useRelayMutationPromise<useWorkerQualificationProofSetMutation>(
      graphql`
        mutation useWorkerQualificationProofSetMutation(
          $input: SetProofOnWorkerQualificationInput!
        ) {
          setProofOnWorkerQualification(input: $input) {
            updatedWorkerQualification {
              id
            }
          }
        }
      `
    );

  const [unsetProofOnWorkerQualification] =
    useRelayMutationPromise<useWorkerQualificationProofUnsetMutation>(
      graphql`
        mutation useWorkerQualificationProofUnsetMutation(
          $input: UnsetProofOnWorkerQualificationInput!
        ) {
          unsetProofOnWorkerQualification(input: $input) {
            updatedWorkerQualification {
              id
            }
          }
        }
      `
    );

  return {
    setProofOnWorkerQualification,
    unsetProofOnWorkerQualification
  };
}
