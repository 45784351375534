import { Column, ContentArea, IconText, Surface } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ReactNode } from "react";
import OrganizationRatingRow from "../organization/OrganizationRatingRow";
import type { BrowseShiftHeader_shift$key } from "./__generated__/BrowseShiftHeader_shift.graphql";

type Props = {
  children?: ReactNode;
  onInfo?: () => void;
};

export const BrowseShiftHeader = ({
  name,
  organization,
  primaryContact,
  onInfo,
  children
}: FragmentContainerInnerComponentProps<
  BrowseShiftHeader_shift$key,
  Props
>) => {
  return (
    <Surface>
      <ContentArea gap="standard">
        <IconText
          icon="business-time"
          size="medium"
          iconAlign="center"
          textVariant="header"
        >
          {name}
        </IconText>
        <Column gap="compact">
          <OrganizationRatingRow
            fragmentRef={organization}
            primaryContactName={primaryContact?.displayName}
            onInfo={onInfo}
          />
          {children}
        </Column>
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<BrowseShiftHeader_shift$key, Props>(
  graphql`
    fragment BrowseShiftHeader_shift on GigLike {
      name
      primaryContact {
        displayName
      }
      organization {
        ...OrganizationRatingRow_org @arguments(skipPrimaryContact: true)
      }
    }
  `,
  BrowseShiftHeader
);
