/**
 * @generated SignedSource<<b4a51cda58cb3b98be9a9cd46b964157>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftActions_shift$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"helpersWorker_engagement" | "useEngagementTransition_engagement">;
  readonly " $fragmentType": "ShiftActions_shift";
};
export type ShiftActions_shift$key = {
  readonly " $data"?: ShiftActions_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftActions_shift">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementCapability",
  "kind": "LinkedField",
  "name": "capabilities",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftActions_shift",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersWorker_engagement",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementState",
          "kind": "LinkedField",
          "name": "currentState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "action",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useEngagementTransition_engagement",
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementState",
          "kind": "LinkedField",
          "name": "currentState",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Worker",
          "kind": "LinkedField",
          "name": "worker",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transportationMethod",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v2/*: any*/)
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "607e26b0c6f47ae5668247879b5a3894";

export default node;
