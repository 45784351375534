/**
 * @generated SignedSource<<e15841f13d1864997a306485d3a14c6b>>
 * @relayHash 5f7fd45947ace61c57d4731d22abce0d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:CuXvr6vZiDHUozDiI9wYNIGFpGDKZgBF5GrNdFqX3_g

import { ConcreteRequest } from 'relay-runtime';
export type UpdateWorkerLanguageInput = {
  language?: string | null | undefined;
  level?: string | null | undefined;
  workerLanguageId: string;
};
export type ManageLanguageFormEditLanguageMutation$variables = {
  input: UpdateWorkerLanguageInput;
};
export type ManageLanguageFormEditLanguageMutation$data = {
  readonly updateWorkerLanguage: {
    readonly workerLanguage: {
      readonly id: string;
      readonly language: string;
      readonly level: string;
    };
  } | null | undefined;
};
export type ManageLanguageFormEditLanguageMutation = {
  response: ManageLanguageFormEditLanguageMutation$data;
  variables: ManageLanguageFormEditLanguageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorkerLanguagePayload",
    "kind": "LinkedField",
    "name": "updateWorkerLanguage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerLanguage",
        "kind": "LinkedField",
        "name": "workerLanguage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageLanguageFormEditLanguageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageLanguageFormEditLanguageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:CuXvr6vZiDHUozDiI9wYNIGFpGDKZgBF5GrNdFqX3_g",
    "metadata": {},
    "name": "ManageLanguageFormEditLanguageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7772e141b2adfadf5e2d6d003827fc30";

export default node;
