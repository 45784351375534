/**
 * @generated SignedSource<<d9a796f8f2c04d0ad3813c6d104e98fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useApplyMetadata_gigOrEngagement$data = {
  readonly __typename: string;
  readonly distance?: number | null | undefined;
  readonly gig?: {
    readonly area: string | null | undefined;
    readonly currentState: {
      readonly name: GigStateName;
    };
    readonly gigSeries: {
      readonly unmetQualifications: {
        readonly totalCount: number;
      } | null | undefined;
    };
    readonly gigType: GigType | null | undefined;
    readonly id: string;
    readonly paymentType: PaymentType | null | undefined;
    readonly placeId: string | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
  };
  readonly id?: string;
  readonly node?: {
    readonly area: string | null | undefined;
    readonly currentState: {
      readonly name: GigStateName;
    };
    readonly gigSeries: {
      readonly unmetQualifications: {
        readonly totalCount: number;
      } | null | undefined;
    };
    readonly gigType: GigType | null | undefined;
    readonly id: string;
    readonly paymentType: PaymentType | null | undefined;
    readonly placeId: string | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadataIsEnrolled_gigOrEngagement">;
  readonly " $fragmentType": "useApplyMetadata_gigOrEngagement";
};
export type useApplyMetadata_gigOrEngagement$key = {
  readonly " $data"?: useApplyMetadata_gigOrEngagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadata_gigOrEngagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalDocument",
  "kind": "LinkedField",
  "name": "pendingConsents",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConsentDocumentStepper_documents"
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "GigSeries",
  "kind": "LinkedField",
  "name": "gigSeries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        }
      ],
      "concreteType": "GigFieldItemDefinitionsConnection",
      "kind": "LinkedField",
      "name": "unmetQualifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "unmetQualifications(first:0)"
    }
  ],
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "GigPosition",
    "kind": "LinkedField",
    "name": "position",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "GigState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useApplyMetadata_gigOrEngagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useApplyMetadataIsEnrolled_gigOrEngagement",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "gig",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "type": "Engagement",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "type": "AvailableGigsEdge",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "Engagement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v9/*: any*/),
            (v1/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "AvailableGigsEdge",
      "abstractKey": null
    }
  ],
  "type": "AvailableGigOrEngagement",
  "abstractKey": "__isAvailableGigOrEngagement"
};
})();

(node as any).hash = "2d9ddcaa63c716baecedf78db9e10ca1";

export default node;
