import React, { useState } from "react";

import { Button, ContentArea, Stack, Text, toast } from "@gigsmart/atorasu";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import HomeScreenCard from "@gigsmart/seibutsu/worker/HomescreenCard";

import type { earningsHistoryCard_sendEarningsEmailMutation } from "./__generated__/earningsHistoryCard_sendEarningsEmailMutation.graphql";

export default function EarningsHistoryCard() {
  const [sendingEarningsEmail, setSendingEarningsEmail] = useState(false);
  const [sendEarningsEmail] =
    useRelayMutation<earningsHistoryCard_sendEarningsEmailMutation>(graphql`
      mutation earningsHistoryCard_sendEarningsEmailMutation(
        $input: EmailWorkerEarningsInput!
      ) {
        emailWorkerEarnings(input: $input) {
          ok
        }
      }
    `);

  const handleSendEmail = () => {
    setSendingEarningsEmail(true);
    sendEarningsEmail(
      { input: {} },
      {
        onSuccess: () => {
          setSendingEarningsEmail(false);
          toast.success("Earnings Report Sent to Email Address on File");
        },
        onError: (error) => {
          setSendingEarningsEmail(false);
          toast.error(
            "Unable to Send Earnings Report. Contact Support for More Information"
          );
        }
      }
    );
  };

  return (
    <HomeScreenCard
      testID="earnings-history-card"
      headerTitle="Earnings History"
      headerIcon="inbox-in"
    >
      <ContentArea variant="compact" size="compact">
        <Stack>
          <Text>
            If you would like to view all your Shift earnings history in one
            place, you can export a comprehensive earnings report to your email
            address on file.
          </Text>
          <Button
            onPress={handleSendEmail}
            label="Email Earnings Report"
            testID="send-earnings-report-email-btn"
            disabled={sendingEarningsEmail}
          />
        </Stack>
      </ContentArea>
    </HomeScreenCard>
  );
}
