/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/return-await */

import allSettled from "promise.allsettled";

// FIXME: figure out why this is needed
// FIXME: Something is replacing Promise implementation with a non-standard one

Promise.allSettled ||= allSettled;

export { allSettled };
