import { ContentArea, Spacer, Stack, Text, Well } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Carousel } from "@gigsmart/isomorphic-shared/media";
import React from "react";

interface Props {
  comment?: string | null;
  photos?: Array<{
    readonly id?: string | null;
    readonly url?: string | null;
  }>;
}

export default function ConfirmationRequestSupport({ comment, photos }: Props) {
  const styles = useStyles(() => ({
    carousel: { backgroundColor: "transparent" }
  }));
  return (
    <Well color="neutral" contentVariant="compact">
      <ContentArea variant="compact" size="none">
        <Stack>
          <Text weight="semibold">Confirmation Request Support:</Text>
          <Text italic>{comment}</Text>
        </Stack>
      </ContentArea>
      <Spacer />
      <Carousel
        isDeletable={false}
        style={styles.carousel}
        sources={photos ?? []}
        thumbWidth={60}
      />
    </Well>
  );
}
