/**
 * @generated SignedSource<<cd41cb4e4570d4f3d7f5f10e661f0f11>>
 * @relayHash 881a0d834cbb11a1a02b98ac6493203c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:7C4-ZiNOvLDeZdsTUsvMRnvUpNWiB0olX_x44YJriqs

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CqlSortDirection = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type CqlFilterWorkerBlockedOrganizationInput = {
  _and?: ReadonlyArray<CqlFilterWorkerBlockedOrganizationInput> | null | undefined;
  _not?: CqlFilterWorkerBlockedOrganizationInput | null | undefined;
  _or?: ReadonlyArray<CqlFilterWorkerBlockedOrganizationInput> | null | undefined;
  insertedAt?: CqlOpDatetimeInput | null | undefined;
  organizationId?: CqlOpIdInput | null | undefined;
  organizationName?: CqlOpStringInput | null | undefined;
  workerId?: CqlOpIdInput | null | undefined;
  workerName?: CqlOpStringInput | null | undefined;
};
export type CqlOpDatetimeInput = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOpIdInput = {
  _eq?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOpStringInput = {
  _eq?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOrderWorkerBlockedOrganizationInput = {
  insertedAt?: CqlOrderStandardInput | null | undefined;
  organizationId?: CqlOrderStandardInput | null | undefined;
  organizationName?: CqlOrderStandardInput | null | undefined;
  workerId?: CqlOrderStandardInput | null | undefined;
  workerName?: CqlOrderStandardInput | null | undefined;
};
export type CqlOrderStandardInput = {
  direction: CqlSortDirection;
};
export type blockedOrganizationManagementScreenQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  orderBy?: ReadonlyArray<CqlOrderWorkerBlockedOrganizationInput> | null | undefined;
  where?: CqlFilterWorkerBlockedOrganizationInput | null | undefined;
};
export type blockedOrganizationManagementScreenQuery$data = {
  readonly viewer: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"WorkerBlockedOrganizationsList_worker">;
  } | null | undefined;
};
export type blockedOrganizationManagementScreenQuery = {
  response: blockedOrganizationManagementScreenQuery$data;
  variables: blockedOrganizationManagementScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "blockedOrganizationManagementScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "args": (v5/*: any*/),
                "kind": "FragmentSpread",
                "name": "WorkerBlockedOrganizationsList_worker"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "blockedOrganizationManagementScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "WorkerBlockedOrganizationsConnection",
                "kind": "LinkedField",
                "name": "blockedOrganizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerBlockedOrganizationsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerBlockedOrganization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "logoUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reason",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "where",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "WorkerBlockedOrganizationsList_blockedOrganizations",
                "kind": "LinkedHandle",
                "name": "blockedOrganizations"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:7C4-ZiNOvLDeZdsTUsvMRnvUpNWiB0olX_x44YJriqs",
    "metadata": {},
    "name": "blockedOrganizationManagementScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0a082c61e2b92b6c8527fc963b6f27b9";

export default node;
