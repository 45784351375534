import type { TimesheetEntry } from "@gigsmart/atorasu";
import { humanizeRate } from "@gigsmart/isomorphic-shared/iso/currency";
import { graphql, readRelayInlineFragment } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import { compact } from "lodash";
import type {
  helpersGenerateEntries_timesheet$data,
  helpersGenerateEntries_timesheet$key
} from "./__generated__/helpersGenerateEntries_timesheet.graphql";

export const generateEntries = (
  fragmentRef?: helpersGenerateEntries_timesheet$key | null,
  hideAttribution?: boolean,
  gigPayRate?: string | number | null,
  currentNegotiationPayRate?: string | number | null
) => {
  const timesheet = readRelayInlineFragment(
    graphql`
      fragment helpersGenerateEntries_timesheet on EngagementTimesheet
      @inline
      @argumentDefinitions(where: { type: "CqlFilterEngagementStateInput" }) {
        states(
          first: 100
          where: $where
          orderBy: [{ transitionedAt: { direction: ASC } }]
        ) {
          edges {
            node {
              id
              name
              action
              transitionedAt
              transitionedBy {
                __typename
                displayName
              }
              negotiation {
                payRate
                submittedBy {
                  __typename
                  displayName
                }
              }
            }
          }
        }
      }
    `,
    fragmentRef ?? null
  );
  return generateEntriesFromStates(
    timesheet?.states?.edges,
    hideAttribution,
    gigPayRate,
    currentNegotiationPayRate
  );
};

export const generateEntriesFromStates = (
  states?: ObjectPath<
    helpersGenerateEntries_timesheet$data,
    ["states", "edges"]
  >,
  hideAttribution = false,
  gigPayRate: string | number | null = null,
  currentNegotiationPayRate: string | number | null = null
) => {
  return compact(
    states?.map((edge) => {
      const node = edge?.node;
      if (!node) return null;

      let label = "";
      let transitionedBy = "";
      let note: JSX.Element | string | undefined = node.negotiation?.payRate
        ? humanizeRate(node.negotiation.payRate)
        : undefined;

      const acceptedPayRate = currentNegotiationPayRate
        ? humanizeRate(currentNegotiationPayRate)
        : undefined;

      switch (node.name) {
        case "SCHEDULED":
          if (node.action === "ACCEPT" || node.action === "CONFIRM") {
            label = `${
              hideAttribution ? "Offer Accepted" : "Worker Accepted Offer"
            }`;
          }
          if (node.action === "PICKUP") {
            label = `${hideAttribution ? "" : "Worker "}Accepted Offer`;
            note = `${gigPayRate ? humanizeRate(gigPayRate) : undefined}`;
          }
          break;
        case "BID_REQUESTED":
          label = "Offered - Request to Bid";
          break;
        case "BID_REVIEW":
          switch (node.action) {
            case "MODIFY_BID":
              label = "Bid Edited";
              break;
            case "ACCEPT":
              label = "Bid Accepted";
              break;
            case "REJECT":
              label = "Bid Rejected";
              break;
            case "COUNTER_BID":
              label = `${
                hideAttribution ? "" : "Worker "
              }Counter-Bid Submitted`;
              break;
            default:
              label = `${hideAttribution ? "" : "Worker "}Bid Submitted`;
          }
          break;
        case "OFFERED":
          switch (node.action) {
            case "MODIFY_OFFER":
              label = "Offer Edited";
              break;
            case "ACCEPT":
              label = "Bid Accepted";
              note = acceptedPayRate;
              if (!hideAttribution) {
                transitionedBy = node.transitionedBy?.displayName
                  ? `by ${node.transitionedBy?.displayName}`
                  : "Bid Automatically Accepted";
              }
              break;
            case "COUNTER_OFFER":
              label = "Counter-Offer Sent";
              note = `${
                node.negotiation?.payRate
                  ? humanizeRate(node.negotiation.payRate)
                  : undefined
              }${
                hideAttribution
                  ? ""
                  : ` by ${node.negotiation?.submittedBy?.displayName}`
              }`;
              break;
            case "OFFER":
              label = "Offer Received";
              break;
            case "REOFFER":
              label = "Re-Offered (No Response to SMS Confirmation)";
              break;
            default:
              label = "Offered - Request to Bid";
          }
          break;
        case "REJECTED":
          label = `${
            node.transitionedBy?.__typename === "Requester"
              ? `${node.transitionedBy.displayName} `
              : hideAttribution
                ? ""
                : "Worker "
          }Rejected Offer`;
          break;
        default:
          label = "No matching label found";
      }

      return {
        testID: node.id,
        active: false,
        label,
        note,
        timestamp: node.transitionedAt,
        transitionedBy
      } as TimesheetEntry;
    })
  );
};
