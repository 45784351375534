import { showEmbeddedBrowser } from "@gigsmart/atorasu";

export default function useLinkToTwilio() {
  return {
    linkToTermsOfService: () => {
      showEmbeddedBrowser({ url: "https://www.twilio.com/legal/tos" });
    },
    linkToPrivacyPolicy: () => {
      showEmbeddedBrowser({ url: "https://www.twilio.com/legal/privacy" });
    }
  };
}
