/**
 * @generated SignedSource<<c44bc9b20150b2d76473db726c104409>>
 * @relayHash 31363c486cd65f748e3fa94ac8a62783
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:LrT6mwhXat772bSDPQ1kHon6p27iSNeNiMcS_JoqXnc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type ShiftTimesheetTabSubscription$variables = {
  id: string;
};
export type ShiftTimesheetTabSubscription$data = {
  readonly engagementUpdated: {
    readonly engagement: {
      readonly paymentInfo: {
        readonly billableDuration: string;
        readonly timesheetPaymentStyle: EngagementTimesheetPaymentStyle;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"TimesheetTabs_engagement" | "VerifyMileage_engagement" | "WithEngagementCapability_engagement" | "WorkerTimesheetInfoCard_engagement" | "WorkerUpdatedTimesheetReminder_engagement">;
    };
  } | null | undefined;
};
export type ShiftTimesheetTabSubscription = {
  response: ShiftTimesheetTabSubscription$data;
  variables: ShiftTimesheetTabSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "engagementId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v7 = {
  "alias": "startedCount",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = START"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = START\")"
},
v8 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timesheetPaymentStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableDuration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v23 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v24 = {
  "kind": "Literal",
  "name": "query",
  "value": "ORDER BY transitionedAt ASC"
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v26 = [
  (v25/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v16/*: any*/)
],
v27 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v25/*: any*/),
          (v16/*: any*/),
          (v3/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transitionedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transitionedBy",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementNegotiation",
            "kind": "LinkedField",
            "name": "negotiation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submittedBy",
                "plural": false,
                "selections": (v26/*: any*/),
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementStateLocation",
                "kind": "LinkedField",
                "name": "workerTransitionLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementState",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v28 = {
  "alias": "timesheetListStates",
  "args": [
    (v23/*: any*/),
    {
      "kind": "Literal",
      "name": "overridden",
      "value": true
    },
    (v24/*: any*/)
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": "states(first:20,overridden:true,query:\"ORDER BY transitionedAt ASC\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShiftTimesheetTabSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EngagementUpdatedPayload",
        "kind": "LinkedField",
        "name": "engagementUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "VerifyMileage_engagement",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "systemTimesheet",
                    "args": (v4/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"SYSTEM\")"
                  },
                  {
                    "alias": "workerTimesheet",
                    "args": (v8/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"WORKER\")"
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkerTimesheetInfoCard_engagement"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkerUpdatedTimesheetReminder_engagement"
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "WithEngagementCapability_engagement",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCapability",
                    "kind": "LinkedField",
                    "name": "capabilities",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "restrictedBy",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimesheetTabs_engagement"
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShiftTimesheetTabSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EngagementUpdatedPayload",
        "kind": "LinkedField",
        "name": "engagementUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reconcilesAt",
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v16/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "systemTimesheet",
                "args": (v4/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": "workerApprovals",
                    "args": [
                      (v5/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE approvedByType = WORKER"
                      }
                    ],
                    "concreteType": "EngagementTimesheetApprovalsConnection",
                    "kind": "LinkedField",
                    "name": "approvals",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": "approvals(first:0,query:\"WHERE approvedByType = WORKER\")"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v8/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v16/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v2/*: any*/),
                  (v22/*: any*/),
                  (v18/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCapability",
                "kind": "LinkedField",
                "name": "capabilities",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "restrictedBy",
                    "plural": false,
                    "selections": [
                      (v25/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "finalTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "FINAL"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": [
                      (v25/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workerEta",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": "inactiveCancelStates",
                "args": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE action = INACTIVE_CANCEL"
                  }
                ],
                "concreteType": "EngagementStateOrOverridesConnection",
                "kind": "LinkedField",
                "name": "states",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "states(first:0,query:\"WHERE action = INACTIVE_CANCEL\")"
              },
              {
                "alias": "original",
                "args": (v4/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v2/*: any*/),
                  (v22/*: any*/),
                  (v7/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": "timesheetListStates",
                    "args": [
                      (v23/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "overridden",
                        "value": false
                      },
                      (v24/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": "states(first:20,overridden:false,query:\"ORDER BY transitionedAt ASC\")"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "REQUESTER"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v20/*: any*/),
                  (v9/*: any*/),
                  (v21/*: any*/),
                  (v2/*: any*/),
                  (v22/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:LrT6mwhXat772bSDPQ1kHon6p27iSNeNiMcS_JoqXnc",
    "metadata": {},
    "name": "ShiftTimesheetTabSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "25e217663e3b0e17a4b34ca93f9c64d8";

export default node;
