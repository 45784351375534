/**
 * @generated SignedSource<<d097902366207998ef5cf7f3932304a8>>
 * @relayHash ddf2540b41ccf9e5174d30e3ae1ce87a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:DRlxAUW57cE5HoiAmC0xeWHj5JgFSWwlBFmXV8_N6CE

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddWorkerWorkHistoryInput = {
  contactName?: string | null | undefined;
  emailAddress?: string | null | undefined;
  employer: string;
  employmentEnd?: string | null | undefined;
  employmentStart: string;
  phoneNumber?: string | null | undefined;
  positionTitle?: string | null | undefined;
  responsibilities?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type WorkHistoryFormAddWorkHistoryMutation$variables = {
  input: AddWorkerWorkHistoryInput;
};
export type WorkHistoryFormAddWorkHistoryMutation$data = {
  readonly addWorkerWorkHistory: {
    readonly newWorkerWorkHistoryEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"WorkHistoryForm_worker">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type WorkHistoryFormAddWorkHistoryMutation = {
  response: WorkHistoryFormAddWorkHistoryMutation$data;
  variables: WorkHistoryFormAddWorkHistoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkHistoryFormAddWorkHistoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerWorkHistoryPayload",
        "kind": "LinkedField",
        "name": "addWorkerWorkHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerWorkHistoryEdge",
            "kind": "LinkedField",
            "name": "newWorkerWorkHistoryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerWorkHistory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "WorkHistoryForm_worker"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkHistoryFormAddWorkHistoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerWorkHistoryPayload",
        "kind": "LinkedField",
        "name": "addWorkerWorkHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerWorkHistoryEdge",
            "kind": "LinkedField",
            "name": "newWorkerWorkHistoryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerWorkHistory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentEnd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "positionTitle",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:DRlxAUW57cE5HoiAmC0xeWHj5JgFSWwlBFmXV8_N6CE",
    "metadata": {},
    "name": "WorkHistoryFormAddWorkHistoryMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5b269e73b1ba571271b42f1a815082ac";

export default node;
