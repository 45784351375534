import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { ThreeColumnRow } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import moment from "moment-timezone";
import React from "react";
import { Dimensions } from "react-native";
import type { projectEngagementRowPayments_engagement$key } from "./__generated__/projectEngagementRowPayments_engagement.graphql";

interface Props {
  engagement: projectEngagementRowPayments_engagement$key | null | undefined;
  first?: boolean;
}

const fragmentSpec = graphql`
  fragment projectEngagementRowPayments_engagement on Engagement {
    id
    endsAt
    gig {
      name
    }
    payments(first: 20) {
      edges {
        node {
          id
          amount
        }
      }
    }
  }
`;

const { width } = Dimensions.get("window");
const dateText = width < 375 ? "Date:" : "Earnings Date:";

export default function ProjectEngagementRow({
  engagement: engagementFragmentRef,
  first
}: Props) {
  const history = useHistory();
  const engagement = useRelayFragment(fragmentSpec, engagementFragmentRef);
  if (!engagement) return null;
  if (!engagement?.payments?.edges || engagement?.payments?.edges?.length === 0)
    return null;
  return (
    <>
      {engagement?.payments?.edges?.map((payment, innerIndex) => (
        <ThreeColumnRow
          key={payment?.node?.id}
          column1Title={engagement.gig?.name ?? ""}
          column1Subtitle={`${dateText} ${moment(
            engagement.endsAt ?? undefined
          ).format("MM/DD/YY")}`}
          onLinkPress={() => history.push(`/gigs/${engagement.id}`)}
          column2Text="View Gig"
          column3Text={
            payment?.node?.amount
              ? currency.humanize(payment?.node?.amount)
              : "-"
          }
          first={first && innerIndex === 0}
        />
      ))}
    </>
  );
}
