/**
 * @generated SignedSource<<e56fb4c1e2060c54611f03349fa345d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftGroupChatActionRow_gig$data = {
  readonly conversation: {
    readonly id: string;
    readonly messages: {
      readonly totalCount: number;
    } | null | undefined;
    readonly participant: {
      readonly messageStats: {
        readonly unreadCount: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly conversationRule: GigConversationRule;
  readonly currentState: {
    readonly name: GigStateName;
  };
  readonly id: string;
  readonly organization: {
    readonly logoUrl: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly primaryContact: {
    readonly displayName: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ShiftGroupChatActionRow_gig";
};
export type ShiftGroupChatActionRow_gig$key = {
  readonly " $data"?: ShiftGroupChatActionRow_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftGroupChatActionRow_gig">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftGroupChatActionRow_gig",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "conversationRule",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftGroupConversation",
      "kind": "LinkedField",
      "name": "conversation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "last",
              "value": 1
            }
          ],
          "concreteType": "UserMessagesConnection",
          "kind": "LinkedField",
          "name": "messages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "messages(last:1)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ConversationParticipant",
          "kind": "LinkedField",
          "name": "participant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserMessageStats",
              "kind": "LinkedField",
              "name": "messageStats",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unreadCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationRequester",
      "kind": "LinkedField",
      "name": "primaryContact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};
})();

(node as any).hash = "c8ad65bf74e69d8d88290e8bd32ece1b";

export default node;
