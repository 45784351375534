import {
  ContentArea,
  Divider,
  QuoteContent,
  Row,
  Spacer,
  Stack,
  Surface,
  Tag,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { GigDateTimeContent } from "@gigsmart/isomorphic-shared/gig";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { DatePickerInput as Calendar, KatanaTabs } from "@gigsmart/katana";
import { DateTime } from "luxon";
import React from "react";

interface Props {
  schedule: Array<{
    actualStartsAt?: string | null;
    startsAt: string | null | undefined;
    endsAt: string | null | undefined;
    timezone?: string | null;
    isRecurring?: boolean;
  }>;
  isRecurring?: boolean;
  hasAvailableGigs: boolean;
}

const WEEK_DAYS_SORT_ORDER = [
  "Mondays",
  "Tuesdays",
  "Wednesdays",
  "Thursdays",
  "Fridays",
  "Saturdays",
  "Sundays"
];

export default function ({ schedule, isRecurring, hasAvailableGigs }: Props) {
  const shiftCount = schedule?.length;

  const shiftDates = schedule?.map((item) =>
    getDate(item.timezone, item.actualStartsAt)
  );

  const recurringShifts = schedule.filter((item) => item.isRecurring === true);
  const recurringShiftDays = recurringShifts
    .map((fItem) => `${DateTime.fromISO(fItem.startsAt ?? "").weekdayLong}s`)
    .sort(
      (a, b) =>
        WEEK_DAYS_SORT_ORDER.indexOf(a) - WEEK_DAYS_SORT_ORDER.indexOf(b)
    );

  return (
    <Stack size="compact">
      {isRecurring && (
        <>
          <Stack size="compact">
            <Text color="primary" weight="bold">
              Recurring Schedule
            </Text>
            <QuoteContent testID="rec=shift-details-quote-content">
              <Text>
                <Text weight="bold">Days of the Week: </Text>
                <Text>{recurringShiftDays?.join(", ")}</Text>
              </Text>
              <Row>
                <Text weight="bold">Time: </Text>
                <Text>
                  {time
                    .getDateTime(recurringShifts[0]?.startsAt)
                    .toFormat("h:mma")}{" "}
                  -{" "}
                  {time
                    .getDateTime(recurringShifts[0]?.endsAt)
                    .toFormat("h:mma")}
                </Text>
              </Row>
            </QuoteContent>
          </Stack>
          <Spacer />
          <Divider />
        </>
      )}
      <Text color="primary" weight="bold">
        {hasAvailableGigs
          ? pluralize(shiftCount, "Available Shift")
          : "Shift Information"}
      </Text>
      {shiftCount === 1 ? (
        <QuoteContent testID="ind-shift-details-quote-content">
          <GigDateTimeContent
            startsAt={schedule[0]?.startsAt}
            endsAt={schedule[0]?.endsAt}
            timezone={schedule[0]?.timezone}
            alwaysDisplayDate
          />
        </QuoteContent>
      ) : (
        <Surface variant="outline">
          <KatanaTabs
            tabs={[
              {
                label: "SHIFTS",
                Contents: (
                  <ContentArea>
                    <Stack>
                      {schedule?.map((node, index) => (
                        <GigDateTimeContent
                          key={index}
                          startsAt={node.startsAt}
                          endsAt={node.endsAt}
                          alwaysDisplayDate
                          dateTag={
                            node.isRecurring ? (
                              <Tag
                                label="Recurring"
                                color="primary"
                                variant="clear"
                              />
                            ) : null
                          }
                        />
                      ))}
                    </Stack>
                  </ContentArea>
                )
              },
              {
                label: "CALENDAR",
                Contents: (
                  <ContentArea>
                    <Calendar
                      value={shiftDates}
                      min={shiftDates?.[0]}
                      max={shiftDates?.[shiftDates.length - 1]}
                    />
                  </ContentArea>
                )
              }
            ]}
          />
        </Surface>
      )}
    </Stack>
  );
}

export const getDate = (timezone?: string | null, startsAt?: string | null) => {
  return startsAt
    ? DateTime.fromJSDate(new Date(startsAt)).toFormat(
        Calendar.LUXON_DATE_FORMAT
      )
    : timezone
      ? DateTime.fromJSDate(new Date(), { zone: timezone })
          .startOf("day")
          .toFormat(Calendar.LUXON_DATE_FORMAT)
      : DateTime.fromJSDate(new Date())
          .startOf("day")
          .toFormat(Calendar.LUXON_DATE_FORMAT);
};
