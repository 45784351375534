import { getDeviceId } from "@gigsmart/isomorphic-shared/app/device-id";
import { getToken } from "@gigsmart/isomorphic-shared/app/notifications";
import { getAuthToken } from "@gigsmart/isomorphic-shared/relay/auth-token";
import fetch from "isomorphic-fetch";

export const ackDevice = async () => {
  try {
    const graphqlUrl = process.env.GRAPHQL_BASE_URL ?? "";

    await fetch(graphqlUrl.replace("/graphql", "/ack"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAuthToken()}`
      },
      body: JSON.stringify({
        deviceId: (await getDeviceId())?.id,
        registrationToken: await getToken()
      })
    });
  } catch (e) {
    console.error("Failed to ack device", e);
  }
};
