import { debounce } from "lodash";
import React, { type ComponentProps, useState } from "react";
import { type TextInput, TouchableOpacity, View } from "react-native";

import { StyledIcon } from "../icon";
import { type ViewStyleProp, theme, useStyles } from "../style";
import KatanaText from "../text/katana-text";
import TextField from "./text-field";

interface Props {
  onSearch: (_: string) => void | (Promise<void> | null);
  placeholder: string;
  testID?: string;
  containerStyle?: ViewStyleProp;
  textInputStyle?: ViewStyleProp;
  leftIconProps?: ComponentProps<typeof StyledIcon>;
  rightIconProps?: ComponentProps<typeof StyledIcon>;
  showClose?: boolean;
  showCancel?: boolean;
}

const StyledSearchInput = ({
  testID,
  onSearch,
  placeholder,
  containerStyle,
  textInputStyle,
  leftIconProps,
  rightIconProps,
  showClose = true,
  showCancel = false,
  ...props
}: Props) => {
  const { styles } = useStyles(({ color, unit, units }) => ({
    rowWrapper: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    searchSection: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: color.white,
      borderRadius: 50,
      borderWidth: 1,
      borderColor: color.neutralDark,
      width: units(100),
      "@phone": {
        flex: 1
      }
    },
    searchIcon: {
      padding: unit.padding.medium
    },
    input: {
      flex: 1,
      paddingVertical: unit.padding.medium,
      backgroundColor: color.white,
      color: color.black
    },
    cancel: {
      paddingLeft: units(3),
      color: color.blue
    },
    hide: { display: "none" }
  }));

  const inputRef = React.createRef<TextInput>();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = debounce(async (text) => await onSearch(text), 250, {
    maxWait: 500
  });
  const handleTextChange = (text: string) => {
    setSearchTerm(text);
    void debouncedSearch(text);
  };
  return (
    <View style={styles.rowWrapper}>
      <View style={[styles.searchSection, containerStyle]}>
        <StyledIcon
          style={styles.searchIcon}
          name="search"
          size={14}
          color={theme.color.neutralDark}
          {...leftIconProps}
        />
        <TextField
          inputRef={inputRef}
          testID={testID}
          style={[styles.input, textInputStyle]}
          placeholder={placeholder}
          onChangeText={handleTextChange}
          value={searchTerm}
          {...props}
        />
        {showClose && (
          <TouchableOpacity
            onPress={(e) => {
              handleTextChange("");
            }}
            onFocus={() => {
              inputRef.current?.focus();
            }}
          >
            <StyledIcon
              style={styles.searchIcon}
              name="times"
              size={18}
              color={theme.color.neutralDark}
              {...rightIconProps}
            />
          </TouchableOpacity>
        )}
      </View>
      <TouchableOpacity
        style={!showCancel && styles.hide}
        // using the 2 events is a workaround for the cancel button to work on web
        // onPressIn not being called on web onPress.
        onPress={() => {
          handleTextChange("");
        }}
        onPressIn={() => {
          handleTextChange("");
          inputRef.current?.blur();
        }}
      >
        <KatanaText style={styles.cancel} variant="bold">
          Cancel
        </KatanaText>
      </TouchableOpacity>
    </View>
  );
};

export default StyledSearchInput;
