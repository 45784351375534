import type { NotificationEntry } from "@gigsmart/isomorphic-shared/notifications";
import { useHistory, useQueryParams } from "@gigsmart/kaizoku";
import { Suspense, useRelayQueryLoader } from "@gigsmart/relay";
import React, { useState } from "react";

import notificationRedirectNodeQuerySpec, {
  type notificationRedirectNodeQuery
} from "./__generated__/notificationRedirectNodeQuery.graphql";
import GigNotAvailable from "./gig-not-available";
import NotificationListContainer from "./notification-list-container";
import NotificationRedirect from "./notification-redirect";
import { handleAction } from "./notification-routing-handler";

export default function NotificationCenterScreen() {
  const history = useHistory();
  const queryParams = useQueryParams<{
    showNotAvailableModal?: boolean;
    modalVariant?: "shift" | "job" | "project" | null;
  }>();
  const [queryRef, loadQuery] =
    useRelayQueryLoader<notificationRedirectNodeQuery>(
      notificationRedirectNodeQuerySpec
    );

  const [currentMessage, setCurrentMessage] = useState<
    NotificationEntry | null | undefined
  >();
  const [modalVariant, setModalVariant] = useState(queryParams?.modalVariant);

  const closeNotAvailableModal = () => {
    setCurrentMessage(null);
    setModalVariant(null);
    history.replace("/notifications");
  };

  const handleMessagePress = (message: NotificationEntry) => {
    if (!message) return;

    const actionResult = handleAction(message);
    if (actionResult?.pathname) {
      history.push(actionResult.pathname);
    } else if (message.topicId) {
      setCurrentMessage(message);
      loadQuery({ id: message.topicId });
    }
  };

  const showNotAvailableModal = !!modalVariant;
  return (
    <>
      <GigNotAvailable
        showModal={showNotAvailableModal}
        variant={modalVariant ?? "shift"}
        onClose={closeNotAvailableModal}
      />
      <NotificationListContainer onPress={handleMessagePress} />
      <Suspense>
        {!showNotAvailableModal && currentMessage && queryRef && (
          <NotificationRedirect
            queryRef={queryRef}
            message={currentMessage}
            setCurrentMessage={setCurrentMessage}
            showNotAvailableModal={setModalVariant}
          />
        )}
      </Suspense>
    </>
  );
}
