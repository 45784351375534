import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import React, { PureComponent, type ReactNode } from "react";
import { TouchableOpacity, View } from "react-native";
import { type ViewStyleProp, createStyles } from "../style";
import { StyledText } from "../text";

export type ChipProps = EventerProps<"pressed"> & {
  style?: ViewStyleProp;
  children: string | ReactNode;
  checked: boolean;
  disabled?: boolean;
  onPress?: () => unknown;
  testID?: string;
  labelStyle?: ViewStyleProp;
  activeContainerStyle?: ViewStyleProp;
  activeLabelStyle?: ViewStyleProp;
};

export const CHIP_HEIGHT = 35;

const styles = createStyles(({ color, font }) => ({
  container: {
    alignItems: "center",
    minHeight: CHIP_HEIGHT,
    borderRadius: CHIP_HEIGHT / 2,
    borderWidth: 1,
    borderColor: color.blue
  },
  label: {
    fontSize: font.size.large,
    color: color.blue,
    marginHorizontal: 15,
    marginVertical: 8,
    textAlign: "center"
  },
  // active state
  containerActive: { backgroundColor: color.blue },
  labelActive: { color: color.white }
}));

@withEventers<"pressed", ChipProps>(
  "Chip",
  ["pressed"],
  ({ children, testID }) =>
    (typeof children === "string" ? children : testID) ?? "Unknown"
)
export default class Chip extends PureComponent<ChipProps> {
  static defaultProps = {
    checked: false,
    labelStyle: {},
    activeContainerStyle: {},
    activeLabelStyle: {},
    eventers: eventerStubs<"pressed">(["pressed"])
  };

  handlePress = () => {
    const { onPress, eventers } = this.props;
    eventers.pressed();
    onPress?.();
  };

  render() {
    const {
      style,
      children,
      checked,
      disabled,
      testID,
      labelStyle,
      activeContainerStyle,
      activeLabelStyle
    } = this.props;
    const labelStyles = [
      styles.label,
      checked && styles.labelActive,
      labelStyle
    ];
    const containerStyles = [
      styles.container,
      checked && styles.containerActive,
      style
    ];

    return (
      <View style={[containerStyles, checked && activeContainerStyle]}>
        <TouchableOpacity
          onPress={this.handlePress}
          disabled={disabled}
          testID={testID}
        >
          {typeof children === "string" ? (
            <StyledText
              style={[labelStyles, checked && activeLabelStyle]}
              selectable={false}
              numberOfLines={1}
            >
              {children}
            </StyledText>
          ) : (
            children
          )}
        </TouchableOpacity>
      </View>
    );
  }
}
