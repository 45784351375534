import { Duration, type DurationUnit } from "luxon";

const DURATION_KEYS = {
  full: {
    days: ["day", "days"],
    hours: ["hour", "hours"],
    minutes: ["minute", "minutes"],
    seconds: ["second", "seconds"]
  },
  compact: {
    days: "d",
    hours: ["hr", "hrs"],
    minutes: "min",
    seconds: "s"
  }
} as const;

export function formatDuration(
  raw: Duration | string | null | undefined,
  format: keyof typeof DURATION_KEYS = "full",
  shiftTo: DurationUnit[] = ["days", "hours", "minute", "seconds"]
) {
  if (!raw) return "-";

  const duration = (
    raw instanceof Duration ? raw : Duration.fromISO(raw)
  ).shiftTo(...shiftTo);

  const parts: string[] = [];
  (["days", "hours", "minutes"] as const).forEach((key) => {
    if (duration[key]) parts.push(formatUnit(duration, key, format));
  });
  if (!parts.length) parts.push(formatUnit(duration, "seconds", format));
  if (parts.length === 1) return (parts[0] ?? "").replaceAll("-", "");

  if (format === "compact") return parts.join(" ").replaceAll("-", "");
  const n = parts.length - 1;
  return `${parts.slice(0, n).join(" ")} and ${parts[n]}`.replaceAll("-", "");
}

export function formatUnit(
  duration: Duration,
  unit: "days" | "hours" | "minutes" | "seconds",
  format: "full" | "compact" = "full"
) {
  const value = Math.round(duration[unit] ?? 0);
  let noun = DURATION_KEYS[format][unit];
  if (Array.isArray(noun)) noun = Math.abs(value) === 1 ? noun[0] : noun[1];
  return `${value} ${noun}`;
}
