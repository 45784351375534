import {
  FooterSpacer,
  ListEmpty,
  LoadingView,
  humanizeDate
} from "@gigsmart/atorasu";
import {
  getConnectionKey,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import useInfiniteList from "@gigsmart/seibutsu/shared/InfiniteList/useInfiniteList";
import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import type {
  CqlFilterEngagementInput,
  OfferedGigSectionsPageQuery
} from "./__generated__/OfferedGigSectionsPageQuery.graphql";
import type { OfferedGigSectionsSubscription } from "./__generated__/OfferedGigSectionsSubscription.graphql";
import type {
  OfferedGigSections_worker$data,
  OfferedGigSections_worker$key
} from "./__generated__/OfferedGigSections_worker.graphql";

type SectionType = {
  key: string;
  data: Array<
    NonNullable<
      ObjectPath<
        OfferedGigSections_worker$data,
        ["engagements", "edges", 0, "node"]
      >
    >
  >;
};

export const filterOfferedEngagements = (
  term?: string,
  queryParam?: "ALL" | "PROJECT" | "SHIFT",
  state?: "BID_REQUESTED" | "OFFERED"
): CqlFilterEngagementInput => {
  let gigTypeFilter: any = { _in: ["PAID", "PROJECT", "VOLUNTEER"] };
  if (queryParam === "PROJECT") {
    gigTypeFilter = { _eq: "PROJECT" };
  }
  if (queryParam === "SHIFT") {
    gigTypeFilter = { _eq: "PAID" };
  }

  return {
    gigType: gigTypeFilter,
    currentStateName: { _eq: state },
    ...(term && {
      _or: [
        { gigName: { _ilike: term } },
        { organizationName: { _ilike: term } }
      ]
    })
  };
};

export function useOfferedGigSections(
  fragmentRef: OfferedGigSections_worker$key | null,
  { where }: { where?: CqlFilterEngagementInput | null } = {},
  state?: "BID_REQUESTED" | "OFFERED"
) {
  const {
    data: sections,
    fragmentData,
    onEndReached,
    onEndReachedThreshold,
    ListFooterComponent,
    ListEmptyComponent
  } = useInfiniteList<
    OfferedGigSectionsPageQuery,
    OfferedGigSections_worker$key,
    SectionType
  >(
    graphql`
      fragment OfferedGigSections_worker on Worker
      @refetchable(queryName: "OfferedGigSectionsPageQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        where: { type: "CqlFilterEngagementInput" }
      ) {
        id
        engagements(
          first: $count
          after: $after
          where: $where
          orderBy: { startsAt: { direction: ASC_NULLS_FIRST } }
        )
          @connection(
            key: "OfferedGigSections_engagements"
            filters: ["where"]
          ) {
          edges {
            node {
              id
              startsAt
              insertedAt
              ...PendingOfferRow_engagement
            }
          }
        }
      }
    `,
    fragmentRef ?? null,
    {
      refetchVars: { where },
      // group by date
      getData: groupByDate,
      renderFooterView: (loading) => (
        <FooterSpacer>{loading && <LoadingView />}</FooterSpacer>
      ),
      renderEmptyView: (loading) =>
        loading ? null : <ListEmpty variant="flat" />
    }
  );

  useRelaySubscription<OfferedGigSectionsSubscription>(
    graphql`
      subscription OfferedGigSectionsSubscription(
        $engagementStates: [EngagementStateName!]
        $workerId: ID!
        $connections: [ID!]!
      ) {
        engagementAdded(
          engagementStates: $engagementStates
          workerId: $workerId
        ) {
          newEngagementEdge @prependEdge(connections: $connections) {
            node {
              id
              startsAt
              insertedAt
              ...PendingOfferRow_engagement
            }
          }
        }
      }
    `,
    {
      engagementStates: [
        state === "BID_REQUESTED" ? "BID_REQUESTED" : "OFFERED"
      ],
      workerId: fragmentData?.id ?? "",
      connections: [
        getConnectionKey(fragmentData?.id, "OfferedGigSections_engagements", {
          where
        })
      ]
    },
    { subscribe: !!fragmentData?.id }
  );
  return {
    sections,
    onEndReached,
    onEndReachedThreshold,
    ListFooterComponent,
    ListEmptyComponent
  };
}

const groupByDate = (data?: OfferedGigSections_worker$data | null) => {
  const sections: SectionType[] = [];
  const get = (startsAt: string | null | undefined, insertedAt: string) => {
    const key = humanizeDate({
      startsAt,
      actualStartsAt: insertedAt,
      size: "lg",
      showDayOfWeek: true
    });

    const section = sections.find((it) => it.key === key);
    if (section) return section;
    const newSection = { key, data: [] };
    sections.push(newSection);
    return newSection;
  };

  const edges = data?.engagements?.edges ?? [];
  for (const it of edges) {
    const node = it?.node;
    if (node) get(node.startsAt, node.insertedAt).data.push(node);
  }

  return sections;
};
