import * as React from "react";
import { Platform } from "react-native";

import { IconDescription, type IconName } from "../icon";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import StyledLink from "./styled-link";

type Props = StylesProps & {
  style?: ViewStyleProp;
  iconName?: IconName;
  label: string;
  testID?: string;
  onPress?: () => unknown;
};

@withStyles(({ color, font }) => ({
  icon: {
    color: color.blue,
    fontSize: font.size.largest,
    lineHeight: 28,
    margin: 0,
    marginRight: 10
  },
  iconLabel: {
    color: color.blue,
    fontSize: font.size.large,
    alignSelf: "center",
    paddingLeft: 0
  },
  noHover: {}
}))
export default class StyledHeaderButton extends React.Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    testID: ""
  };

  render() {
    const { styles, iconName, label, testID, onPress, style } = this.props;
    return (
      <StyledLink hoverStyle={styles.noHover} onPress={onPress}>
        {Platform.OS === "web" && (
          <IconDescription
            iconName={iconName}
            label={label}
            testID={testID}
            iconStyle={styles.icon}
            labelStyle={styles.iconLabel}
            containerStyle={style}
          />
        )}
      </StyledLink>
    );
  }
}
