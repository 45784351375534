/**
 * @generated SignedSource<<c7634327ef9aa44bd7b0c19a0dddfbba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvailableGigsCard_worker$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobBoardSection_worker" | "ProjectGigSection_worker" | "ShiftGigSection_worker">;
  readonly " $fragmentType": "AvailableGigsCard_worker";
};
export type AvailableGigsCard_worker$key = {
  readonly " $data"?: AvailableGigsCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvailableGigsCard_worker">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "maxDistance",
    "variableName": "maxDistance"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jobPostSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AvailableGigsCard_worker",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ShiftGigSection_worker"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ProjectGigSection_worker"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "jobPostSearch",
          "variableName": "jobPostSearch"
        }
      ],
      "kind": "FragmentSpread",
      "name": "JobBoardSection_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "6f220a298ce76acfdb4a4a189395642b";

export default node;
