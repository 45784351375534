import React, { Children, isValidElement } from "react";
import { Image, View } from "react-native";

// eslint-disable-next-line no-restricted-imports
import Stack from "@gigsmart/atorasu/atoms/Stack";
// eslint-disable-next-line no-restricted-imports
import Icon, { type IconName } from "@gigsmart/atorasu/quarks/Icon";
// eslint-disable-next-line no-restricted-imports
import Text from "@gigsmart/atorasu/quarks/Text";
// eslint-disable-next-line no-restricted-imports
import { useTheme } from "@gigsmart/atorasu/style/theme";

import { useStyles } from "../style";

export type AvatarSize = "small" | "medium" | "large" | "extraLarge";
export const sizeMultiplier = {
  small: 1,
  medium: 2,
  large: 3,
  extraLarge: 4
};

const letterPositioning = {
  small: {
    bottom: -19,
    left: 4
  },
  medium: {
    bottom: -39,
    left: 7
  },
  large: {
    bottom: -58,
    left: 11
  },
  extraLarge: {
    bottom: -78,
    left: 14
  }
};
interface AvatarProps {
  pictureUrl: string | null | undefined;
  iconName?: IconName;
  firstName: string;
  lastName: string;
  extraName?: string;
  userId: string;
  displayName: boolean;
  size?: AvatarSize;
  stackName?: boolean;
  children?: React.ReactNode;
  role?: string | null | undefined;
}

export default function AdminAvatar({
  displayName,
  firstName,
  lastName,
  extraName,
  pictureUrl,
  iconName,
  size = "small",
  stackName = false,
  children,
  role,
  ...props
}: AvatarProps) {
  const theme = useTheme();
  const purple = theme.colors.purple.fill;
  const { styles } = useStyles(({ color, font, units }) => ({
    container: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0)"
    },
    roleCircle: {
      height: units(2),
      width: units(2),
      borderWidth: units(1),
      borderRadius: 8,
      borderColor:
        role === "WRK" ? color.blue : role === "REQ" ? color.orange : purple
    },
    imageBorder: {
      borderRadius: units(3 * sizeMultiplier[size]),
      borderWidth: 2,
      borderColor:
        role === "WRK" ? color.blue : role === "REQ" ? color.orange : purple
    },
    circle: {
      borderRadius: units(3 * sizeMultiplier[size]), // this should be half height/width
      height: units(6 * sizeMultiplier[size]),
      width: units(6 * sizeMultiplier[size])
    },
    displayName: {
      // position: "relative",
      // left: units(1.5),
      // top: "5px",
      fontSize: font.size.small
    },
    letters: {
      position: "absolute",
      color: "white",
      fontSize: font.size.medium * sizeMultiplier[size],
      fontWeight: font.weight.bold,
      fontFamily: "monospace",
      ...letterPositioning[size]
    }
  }));
  const { userId } = props;
  const fullName = `${firstName || ""} ${lastName || ""}${
    extraName ? ` - ${extraName}` : ""
  }`.trim();
  const initials = createInitials([firstName, lastName]);
  // const idIndex = convertIdToIndex(userId);

  const renderDisplayName = () => {
    if (stackName) {
      return (
        <Stack size="compact" fill={1} wrap="wrap">
          <Text color="primary" numberOfLines={1} variant="note">
            {firstName}
          </Text>
          <Text color="primary" numberOfLines={1} variant="note">
            {lastName}
          </Text>
        </Stack>
      );
    }

    return (
      <Text color="textPrimary" variant="note" numberOfLines={1}>
        {fullName}
      </Text>
    );
  };

  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child)
  );

  if (pictureUrl) {
    return (
      <Stack horizontal size="compact" alignItems="center" fill={1}>
        {validChildren.map((child) => child)}
        <Image
          source={{ uri: pictureUrl ?? undefined }}
          style={styles.circle as any}
        />
        {role && <View style={styles.roleCircle} />}
        {displayName && renderDisplayName()}
      </Stack>
    );
  }

  if (iconName) {
    return (
      <Stack horizontal size="compact" alignItems="center" fill={1}>
        {validChildren.map((child) => child)}
        <Icon name={iconName} size="medium" variant="solid" color="primary" />
        {role && <View style={styles.roleCircle} />}
        {displayName && renderDisplayName()}
      </Stack>
    );
  }
  return (
    <Stack horizontal size="compact" alignItems="center" fill={1}>
      {validChildren.map((child) => child)}
      <View style={[styles.circle, { backgroundColor: colorFromId(userId) }]}>
        <View>
          <Text style={styles.letters}>{initials}</Text>
        </View>
      </View>
      {role && <View style={styles.roleCircle} />}
      {displayName && renderDisplayName()}
    </Stack>
  );
}

AdminAvatar.defaultProps = {
  displayName: false,
  pictureUrl: null,
  firstName: "",
  lastName: ""
};

export function createInitials(names: string[]) {
  return names.reduce(
    (initials: string, name: string) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name ? initials + name[0]?.toUpperCase() : initials,
    ""
  );
}

function hashId(id: string): number {
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function colorFromId(id: string): string {
  const hash = hashId(id);
  const hexCode = (hash & 0x00ffffff).toString(16).toUpperCase().padEnd(6, "9");

  return `#${hexCode}`;
}
