import { FlatList } from "@gigsmart/atorasu";
import type { FlatListProps } from "@gigsmart/atorasu";
import {
  type GraphQLTaggedNode,
  type OperationType,
  type RelayPreloadedQuery,
  Suspense,
  useFetchQuery,
  useRelayPaginationFragment,
  useRelayPreloadedQuery,
  useRelayQueryLoader,
  type useRelayQueryLoaderHookType
} from "@gigsmart/relay";
import React, {
  useEffect,
  createContext,
  type ReactNode,
  useContext,
  useCallback,
  useMemo,
  useState,
  useRef
} from "react";

export type PaginatorComponent<TOperation extends OperationType> = (
  props: PaginatorProps<TOperation>
) => JSX.Element | null;

export type QueryVariablesType = Record<
  string,
  string | number | null | undefined
> | null;

interface Options {
  displayName: string;
  query: GraphQLTaggedNode;
}

interface LoadBoundaryProps {
  children: ReactNode;
}

export interface PaginatorProps<TOperation extends OperationType>
  extends Omit<
    FlatListProps<any>,
    "data" | "ref" | "refreshing" | "onRefresh" | "onEndReached"
  > {
  pageSize?: number;
  variables: TOperation["variables"];
  paginationFragment: GraphQLTaggedNode;
  extractPaginationFragment: (queryResult: TOperation["response"]) => any;
  extractData: (paginationFragmentResult: any) => any[];
  testID: string;
}

interface InternalPaginatorProps<TOperation extends OperationType>
  extends PaginatorProps<TOperation> {
  queryReference: RelayPreloadedQuery<TOperation>;
  refetching: boolean;
}

export default function createPaginator<TOperation extends OperationType>({
  query,
  displayName
}: Options) {
  const LoaderContext = createContext<
    [
      useRelayQueryLoaderHookType<TOperation>[0],
      useRelayQueryLoaderHookType<TOperation>[1],
      GraphQLTaggedNode | null
    ]
  >([null, () => {}, null]);

  const ScrollIndexContext = createContext<
    [
      number | undefined,
      React.Dispatch<React.SetStateAction<number>> | undefined
    ]
  >([undefined, undefined]);

  const QueryVariablesContext = createContext<
    [QueryVariablesType, (val: QueryVariablesType) => void]
  >([null, () => null]);

  const LoadBoundary = ({ children }: LoadBoundaryProps) => {
    const [queryReference, loadQuery, disposeQuery] =
      useRelayQueryLoader<TOperation>(query);
    const scrollIndexContext = useState(0);
    const [queryVariables, setQueryVariables] =
      useState<QueryVariablesType>(null);
    useEffect(() => {
      return () => {
        disposeQuery();
      };
    }, [disposeQuery]);

    return (
      <LoaderContext.Provider value={[queryReference, loadQuery, query]}>
        <ScrollIndexContext.Provider value={scrollIndexContext}>
          <QueryVariablesContext.Provider
            value={[queryVariables, setQueryVariables]}
          >
            {children}
          </QueryVariablesContext.Provider>
        </ScrollIndexContext.Provider>
      </LoaderContext.Provider>
    );
  };

  const PaginatorQueryLoader = ({
    variables,
    paginationFragment,
    extractPaginationFragment,
    extractData,
    ...listProps
  }: PaginatorProps<TOperation>) => {
    const [refetching, setRefetching] = useState(false);
    const [queryReference, loadQuery, query] = useContext(LoaderContext);
    const fetchQuery = useFetchQuery(query);
    const [queryVariables, setQueryVariables] = useContext(
      QueryVariablesContext
    );
    useEffect(() => {
      if (!queryReference)
        loadQuery(variables, { fetchPolicy: "network-only" });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryReference, loadQuery]);
    useEffect(() => {
      if (
        JSON.stringify(variables) !== JSON.stringify(queryVariables) &&
        query
      ) {
        setRefetching(true);
        fetchQuery(variables).subscribe({
          complete: () => {
            loadQuery(variables, {
              fetchPolicy: "store-or-network"
            });
            setQueryVariables(variables);
            setRefetching(false);
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(variables),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(queryVariables),
      setQueryVariables,
      loadQuery
    ]);

    return queryReference ? (
      <Suspense fallback={null}>
        <Paginator
          queryReference={queryReference}
          variables={variables}
          paginationFragment={paginationFragment}
          extractPaginationFragment={extractPaginationFragment}
          extractData={extractData}
          refetching={refetching}
          {...listProps}
        />
      </Suspense>
    ) : null;
  };

  const Paginator = ({
    variables,
    pageSize = 10,
    queryReference,
    paginationFragment,
    extractPaginationFragment,
    extractData,
    refetching,
    ...listProps
  }: InternalPaginatorProps<TOperation>) => {
    const listRef = useRef<FlatList>();
    const result = useRelayPreloadedQuery<TOperation>(query, queryReference);
    const { data, hasNext, loadNext, refetch, isLoadingNext } =
      useRelayPaginationFragment(
        paginationFragment,
        extractPaginationFragment(result)
      );

    const [offset, setOffset] = useContext(ScrollIndexContext);
    const [refreshing, setRefreshing] = useState(false);

    const listData = useMemo(() => extractData(data), [data]);
    useEffect(() => {
      setTimeout(() => {
        listRef?.current?.scrollToOffset({
          offset: offset ?? 0,
          animated: false
        });
      });
    }, []);

    const handleEndReached = useCallback(() => {
      if (hasNext && !isLoadingNext) {
        loadNext(pageSize);
      }
    }, [hasNext, pageSize, isLoadingNext]);

    const handleRefresh = useCallback(() => {
      setRefreshing(true);
      refetch({}, { onComplete: () => setRefreshing(false) });
    }, [refetch]);

    const handleOnScroll = (e: any) => {
      const yOffset = e?.nativeEvent.contentOffset.y;
      setOffset?.(yOffset);
      listProps?.onScroll?.(e);
    };

    return (
      <FlatList
        {...listProps}
        ref={listRef as any}
        refreshing={refreshing}
        data={listData}
        onRefresh={handleRefresh}
        onEndReached={handleEndReached}
        scrollEventThrottle={16}
        onScroll={handleOnScroll}
      />
    );
  };

  Paginator.displayName = displayName;
  return [
    LoadBoundary,
    PaginatorQueryLoader as PaginatorComponent<TOperation>,
    QueryVariablesContext
  ] as const;
}
