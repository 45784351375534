import {
  type RNUploadableMap,
  type RelayMutationUpdater,
  type RelayRequestError,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import { type GraphQLTaggedNode, readInlineData } from "relay-runtime";
import type {
  useRelayCommand_payload$data,
  useRelayCommand_payload$key
} from "./__generated__/useRelayCommand_payload.graphql";
import { logger as baseLogger } from "./logger";

baseLogger.createLogger("useAsyncRelayCommand");

export type OperationError = NonNullable<
  useRelayCommand_payload$data["errors"]
>[number];

export class RelayCommandError extends Error {
  constructor(public errors: OperationError[]) {
    super("Command failed");
  }
}

export interface CommandParameters {
  readonly response: Record<
    string,
    useRelayCommand_payload$key | null | undefined
  >;
  readonly variables: {};
  readonly rawResponse?:
    | Record<string, useRelayCommand_payload$key | null | undefined>
    | undefined;
}

export interface UseRelayCommandOptions<
  TOperation extends CommandParameters = never
> extends RelayCommandSharedOptions<TOperation> {
  onCommandErrors?: (errors: OperationError[]) => void;
  onSuccess?: () => void;
}

export interface RelayCommandSharedOptions<
  TOperation extends CommandParameters
> {
  onCommandErrors?: (errors: OperationError[]) => void;
  onSuccess?: () => void;
  onCompleted?: (
    response: TOperation["rawResponse"] & TOperation["response"]
  ) => unknown;
  onError?: (error: RelayRequestError, retry?: () => void) => unknown | false;
  updater?: (RelayMutationUpdater<TOperation> | null) | undefined;
}

export interface RelayCommandInvokeOptions<TOperation extends CommandParameters>
  extends RelayCommandSharedOptions<TOperation> {
  uploadables?: RNUploadableMap;
}

export type UseCommandInvokeFunctionType<
  TOperation extends CommandParameters = never
> = (
  variables?: TOperation["variables"],
  options?: RelayCommandInvokeOptions<TOperation>
) => Promise<void>;

const commandFragment = graphql`
  fragment useRelayCommand_payload on CommonOperationPayload @inline {
    correlationId
    errors {
      code
      correlationId
      message
    }
    status
  }
`;

const unknownError: OperationError = {
  code: "E10000",
  message: "Unknown exception",
  correlationId: "unknown"
};

export function useRelayCommand<TOperation extends CommandParameters = never>(
  mutation: GraphQLTaggedNode,
  {
    onSuccess,
    onCommandErrors,
    ...options
  }: UseRelayCommandOptions<TOperation> = {}
) {
  const [mutate, status] = useRelayMutation<TOperation>(mutation, {
    ...options
  });

  const invoke: UseCommandInvokeFunctionType<TOperation> = async (
    variables,
    {
      onSuccess: invokedOnSuccess,
      onCommandErrors: invokedOnCommandErrors,
      ...options
    } = {}
  ) =>
    await new Promise<void>((resolve, reject) => {
      mutate(variables, {
        onSuccess: (response) => {
          const errors = Object.values(response)
            .map((c) =>
              readInlineData<useRelayCommand_payload$key>(commandFragment, c)
            )
            .reduce<OperationError[]>(
              (acc, command) =>
                command?.status === "SUCCESS"
                  ? acc
                  : [
                      ...acc,
                      ...(command?.errors?.length
                        ? command.errors
                        : [unknownError])
                    ],
              []
            );
          if (errors.length) {
            invokedOnCommandErrors?.(errors);
            onCommandErrors?.(errors);
            reject(new RelayCommandError(errors));
          } else {
            onSuccess?.();
            invokedOnSuccess?.();
            resolve();
          }
        },
        ...options
      });
    });

  return [invoke, status] as const;
}
