import {
  Linking,
  LoadingView,
  ModalBody,
  Spacer,
  showModal,
  useActiveAppStateCallback
} from "@gigsmart/atorasu";
import { GenericConsents } from "@gigsmart/feature-flags";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayFragment
} from "@gigsmart/relay";
import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import ConsentDocumentForm from "./ConsentDocumentForm";
import type { UserDocumentConsentCheckQuery } from "./__generated__/UserDocumentConsentCheckQuery.graphql";
import type { UserDocumentConsentCheck_user$key } from "./__generated__/UserDocumentConsentCheck_user.graphql";

const ConsentCheckContext = createContext(true);

interface Props {
  enabled: boolean;
  children: ReactNode;
}

export default createSuspendedQueryContainer<
  UserDocumentConsentCheckQuery,
  Props
>(
  function UserDocumentConsentCheck({ children, enabled, response, retry }) {
    const [currentUrl, setCurrentUrl] = useState<string | null>();

    useEffect(() => {
      Linking.getInitialURL().then(setCurrentUrl);
      const listener = Linking.addEventListener("url", (event) => {
        setCurrentUrl(event.url);
      });
      return () => listener.remove();
    }, []);

    const { viewer } = response ?? {};
    const hasPendingConsents =
      enabled &&
      !currentUrl?.includes("/legal/") &&
      GenericConsents.isEnabled() &&
      !!viewer?.pendingConsents.length;

    useActiveAppStateCallback(() => enabled && retry(), false, [enabled]);

    useEffect(retry, [enabled]);

    useEffect(() => {
      if (!hasPendingConsents || !viewer) return;
      return showModal({
        dismissable: false,
        eventContext: "User Consents",
        fixedHeight: true,
        scrollable: true,
        useModalBody: false,
        onClose: retry,
        children: (close) => (
          <PendingConsentsModal fragmentRef={viewer} onClose={close} />
        )
      }).dispose;
    }, [hasPendingConsents, viewer, retry]);

    return (
      <ConsentCheckContext.Provider value={hasPendingConsents}>
        {children}
      </ConsentCheckContext.Provider>
    );
  },
  {
    query: graphql`
      query UserDocumentConsentCheckQuery {
        viewer {
          pendingConsents {
            filename
          }
          ...UserDocumentConsentCheck_user
        }
      }
    `,
    variables: {}
  }
);

export function useUserHasPendingConsents() {
  return useContext(ConsentCheckContext);
}

type PendingConsentsModalProps = {
  fragmentRef: UserDocumentConsentCheck_user$key | null | undefined;
  onClose: () => void;
};

function PendingConsentsModal({
  fragmentRef,
  onClose
}: PendingConsentsModalProps) {
  const user = useRelayFragment(
    graphql`
      fragment UserDocumentConsentCheck_user on User {
        id
        pendingConsents {
          filename
          ...ConsentDocumentForm_document
        }
      }
    `,
    fragmentRef
  );

  const [consentIndex, setConsentIndex] = useState(0);
  const pendingConsents = user?.pendingConsents ?? [];
  const currentConsent = pendingConsents[consentIndex];

  useEffect(() => {
    if (currentConsent) return;
    // nothing to show: we're done
    onClose();
  }, [currentConsent]);

  if (!currentConsent) {
    return <LoadingView />;
  }

  return (
    <ModalBody scrollable>
      <Spacer />
      <ConsentDocumentForm
        key={currentConsent?.filename}
        document={currentConsent}
        onComplete={() => setConsentIndex((i) => i + 1)}
      />
      <Spacer />
    </ModalBody>
  );
}
