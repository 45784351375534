/**
 * @generated SignedSource<<ac25e18cbdaa0f4e6a887ba205cc5dd4>>
 * @relayHash 172718e8c44e005fff41f23d2ba52987
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:F-CyJ87GhiVpzLf3fbEmYnNljLEeAqt51l5QWKMivZw

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PositionsFormQuery$variables = {
  categoryQuery: string;
  positionsQuery: string;
};
export type PositionsFormQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"usePositionsByCategory_worker">;
  } | null | undefined;
};
export type PositionsFormQuery = {
  response: PositionsFormQuery$data;
  variables: PositionsFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categoryQuery"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "positionsQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PositionsFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "categoryQuery",
                "variableName": "categoryQuery"
              },
              {
                "kind": "Variable",
                "name": "positionsQuery",
                "variableName": "positionsQuery"
              }
            ],
            "kind": "FragmentSpread",
            "name": "usePositionsByCategory_worker"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PositionsFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "category",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "categoryQuery"
                  }
                ],
                "concreteType": "WorkerCategoriesConnection",
                "kind": "LinkedField",
                "name": "workerCategories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerCategoriesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerGigCategory",
                        "kind": "LinkedField",
                        "name": "association",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "confirmedPositions",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigCategory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Icon",
                            "kind": "LinkedField",
                            "name": "categoryIcon",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "data",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 0
                  },
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE status = CONFIRMED"
                  }
                ],
                "concreteType": "WorkerPositionsConnection",
                "kind": "LinkedField",
                "name": "workerPositions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": "workerPositions(first:0,query:\"WHERE status = CONFIRMED\")"
              },
              {
                "alias": "categoryPositions",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 50
                  },
                  {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "positionsQuery"
                  }
                ],
                "concreteType": "WorkerPositionsConnection",
                "kind": "LinkedField",
                "name": "workerPositions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerPositionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerGigPosition",
                        "kind": "LinkedField",
                        "name": "association",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigPosition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:F-CyJ87GhiVpzLf3fbEmYnNljLEeAqt51l5QWKMivZw",
    "metadata": {},
    "name": "PositionsFormQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fe0cd7d914015f8e73e35372bfcd3c86";

export default node;
