/**
 * @generated SignedSource<<0da4a72446bf0b295b2164f996e44dcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftGroupChatShiftCard_conversation$data = {
  readonly gig: {
    readonly actualStartsAt: string | null | undefined;
    readonly address: string | null | undefined;
    readonly area: string | null | undefined;
    readonly endsAt: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly payRate: string | null | undefined;
    readonly startsAt: string | null | undefined;
    readonly timezone: string | null | undefined;
  };
  readonly id: string;
  readonly participant: {
    readonly messageStats: {
      readonly unreadCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ShiftGroupChatShiftCard_conversation";
};
export type ShiftGroupChatShiftCard_conversation$key = {
  readonly " $data"?: ShiftGroupChatShiftCard_conversation$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftGroupChatShiftCard_conversation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftGroupChatShiftCard_conversation",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ConversationParticipant",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserMessageStats",
          "kind": "LinkedField",
          "name": "messageStats",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unreadCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualStartsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShiftGroupConversation",
  "abstractKey": null
};
})();

(node as any).hash = "c3d82ad34e8c36cab344c50847eaabee";

export default node;
