import {
  Avatar,
  Button,
  Column,
  ContentArea,
  FlatList,
  IconCircle,
  Row,
  Spacer,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { getConnectionNodes, graphql } from "@gigsmart/relay";
import useInfiniteList from "@gigsmart/seibutsu/shared/InfiniteList/useInfiniteList";
import { showUnblockOrgModal } from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/UnblockOrgModal";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useCallback, useState } from "react";
import type { BlockedOrganizationsListQuery } from "./__generated__/BlockedOrganizationsListQuery.graphql";
import type { WorkerBlockedOrganizationsList_worker$key } from "./__generated__/WorkerBlockedOrganizationsList_worker.graphql";

type BlockedOrganizationType = NonNullable<
  ObjectPath<
    WorkerBlockedOrganizationsList_worker$key,
    [" $data", "blockedOrganizations", "edges", number, "node"]
  >
>;

type Props = {
  fragmentRef?: WorkerBlockedOrganizationsList_worker$key | null;
};
export default function WorkerBlockedOrganizationsList({ fragmentRef }: Props) {
  const listProps = useInfiniteList<
    BlockedOrganizationsListQuery,
    WorkerBlockedOrganizationsList_worker$key,
    BlockedOrganizationType
  >(
    graphql`
      fragment WorkerBlockedOrganizationsList_worker on Worker
      @refetchable(queryName: "BlockedOrganizationsListQuery")
      @argumentDefinitions(
        first: {type: "Int", defaultValue: 10}
        where: {type: "CqlFilterWorkerBlockedOrganizationInput"}
        orderBy: {type: "[CqlOrderWorkerBlockedOrganizationInput!]"}
        after: {type: "String"}
      ) {
        id
        blockedOrganizations(first: $first, where: $where, orderBy: $orderBy, after: $after)
          @connection(key: "WorkerBlockedOrganizationsList_blockedOrganizations") {
          totalCount
          edges {
            node {
              id
              organization {
                id
                name
                logoUrl
              }
              reason
              insertedAt
            }
          }
        }
      }
    `,
    fragmentRef ?? null,
    { getData: (worker) => getConnectionNodes(worker?.blockedOrganizations) }
  );

  const [removedItems, setRemovedItems] = useState(new Set<string>());
  const addRemovedItem = useCallback((id: string) => {
    setRemovedItems((items) => new Set(items).add(id));
  }, []);

  if (!listProps.fragmentData) return null;
  if (!listProps.fragmentData?.blockedOrganizations?.totalCount) {
    return (
      <Column gap="standard" alignItems="center">
        <IconCircle
          variant="well"
          size="medium"
          icon="hexagon-xmark"
          color="neutral"
          iconColor="primary"
        />
        <Text color="primary" variant="subheader">
          No Blocked Organizations
        </Text>
        <Text>Blocked Organizations will appear here.</Text>
      </Column>
    );
  }

  return (
    <FlatList
      {...listProps}
      testID="worker-blocked-organizations-list"
      ListHeaderComponent={
        <>
          <Text variant="header" color="black">
            My Blocked Organizations
          </Text>
          <Spacer />
        </>
      }
      contentContainerStyle={{ gap: 8 }}
      keyExtractor={(it) => it.id}
      extraData={removedItems}
      renderItem={({ item }) => {
        const isRemoved = removedItems.has(item.id);
        let orgName = item.organization?.name ?? "Unknown Organization";
        if (orgName.length > 22) orgName = `${orgName.slice(0, 22)}...`;

        const handleRemove = () =>
          showUnblockOrgModal({
            workerBlockedOrganizationId: item.id,
            onSuccess: addRemovedItem
          });

        return (
          <Surface variant="outline">
            <ContentArea size="compact">
              <Row
                gap="standard"
                alignItems="center"
                justifyContent="space-between"
                style={{ opacity: isRemoved ? 0.5 : 1 }}
              >
                <Row
                  alignItems="center"
                  justifyContent="flex-start"
                  gap="standard"
                >
                  <Avatar size="medium" uri={item.organization?.logoUrl} />
                  <Text>{orgName}</Text>
                </Row>
                <Button
                  testID={`worker-unblock-organization-${item.id}-button`}
                  label={isRemoved ? "Unblocked" : "Unblock"}
                  size="small"
                  variant="clear"
                  outline
                  disabled={isRemoved}
                  onPress={handleRemove}
                />
              </Row>
            </ContentArea>
          </Surface>
        );
      }}
    />
  );
}
