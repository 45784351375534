import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledPicker } from "@gigsmart/katana";
import React, { type ComponentProps } from "react";

interface Props<T = string>
  extends FormFieldOptions<string>,
    Omit<ComponentProps<typeof StyledPicker>, "searchable"> {
  fill?: true | number;
  label?: string;
  testID?: string;
  options: Array<string | { label: string; value: T }>;
  disabled?: boolean;
  placeholder?: string;
}

/** @deprecated use FomuPickerV2 */
export default function FomuPicker<T = string>({
  name,
  validates,
  ...props
}: Props<T>) {
  const { value, setValue } = useFormField({ name, validates });
  return (
    <StyledPicker
      {...props}
      testID={`${name}-picker`}
      value={value ?? undefined}
      onChangeText={setValue}
    />
  );
}
