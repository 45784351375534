/**
 * @generated SignedSource<<90cce1f824bc66a6f8e80338148bd738>>
 * @relayHash 315274f8b22c88965c7349cbd6b4a0a3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:VreRIk9FqTiW2n2R9PymPyiwrO5iglymsMIm118S5i8

import { ConcreteRequest } from 'relay-runtime';
export type MessageCenterIconQuery$variables = Record<PropertyKey, never>;
export type MessageCenterIconQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly userMessageStats: {
      readonly unreadCount: number;
    };
  } | null | undefined;
};
export type MessageCenterIconQuery = {
  response: MessageCenterIconQuery$data;
  variables: MessageCenterIconQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "conversationTypes",
      "value": [
        "ENGAGEMENT",
        "ORGANIZATION_WORKER"
      ]
    }
  ],
  "concreteType": "UserMessageStats",
  "kind": "LinkedField",
  "name": "userMessageStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    }
  ],
  "storageKey": "userMessageStats(conversationTypes:[\"ENGAGEMENT\",\"ORGANIZATION_WORKER\"])"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCenterIconQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MessageCenterIconQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:VreRIk9FqTiW2n2R9PymPyiwrO5iglymsMIm118S5i8",
    "metadata": {},
    "name": "MessageCenterIconQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ae51f223d0b4264a857185b4dce2b50f";

export default node;
