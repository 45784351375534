/**
 * @generated SignedSource<<42d741d8d728d6ef5e6487679150784a>>
 * @relayHash 5ddf926ff1e80b4780ce04e8d1c55130
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:p-EaCN0h2YVIrCI-3wWw1h-yXubcBqRgvwlliQaeVk8

import { ConcreteRequest } from 'relay-runtime';
export type WorkerAccessState = "AVAILABLE" | "EN_ROUTE" | "NO_ACTIVE_DEVICES" | "NO_LOCATION" | "REQUIRES_BASIC_INFO" | "REQUIRES_PARENT_CONSENT" | "REQUIRES_SKILLS" | "REQUIRES_SMS_CONFIRMATION" | "REQUIRES_TRANSPORTATION" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type WorkerNotificationHandlerSubscription$variables = Record<PropertyKey, never>;
export type WorkerNotificationHandlerSubscription$data = {
  readonly workerUpdated: {
    readonly worker: {
      readonly accessState: WorkerAccessState;
      readonly currentEngagement: {
        readonly id: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type WorkerNotificationHandlerSubscription = {
  response: WorkerNotificationHandlerSubscription$data;
  variables: WorkerNotificationHandlerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessState",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Engagement",
  "kind": "LinkedField",
  "name": "currentEngagement",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerNotificationHandlerSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WorkerNotificationHandlerSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:p-EaCN0h2YVIrCI-3wWw1h-yXubcBqRgvwlliQaeVk8",
    "metadata": {},
    "name": "WorkerNotificationHandlerSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "3c7884bf938c8df4377b39f8572d1e89";

export default node;
