import { Column, IconButton, Stack, Text, Well } from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import React from "react";
import HomeScreenCard from "../worker/HomescreenCard";
import type { DirectHireCard_Worker$key } from "./__generated__/DirectHireCard_Worker.graphql";

interface Props {
  directHireBonus: string;
}

export function DirectHireCard({ directHireBonus }: Props) {
  const history = useHistory();
  const humanizedBonus = currency.humanize(directHireBonus ?? 0, {
    integer: true
  });

  const pressHandler = () => history.push("/direct-hire");

  return (
    <HomeScreenCard
      headerTitle={DirectPaymentsOnly.select(
        "Direct Payments",
        "Direct Payments & Hires"
      )}
      testID="direct-payments-hires-profile-card"
      headerIcon="handshake"
      onHeaderPress={pressHandler}
      action={
        <IconButton
          name="chevron-right"
          size="small"
          variant="solid"
          onPress={pressHandler}
          testID="direct-payment-hire-btn"
          eventEntityType="close"
        />
      }
    >
      <Stack>
        <Text>
          Were you
          <Text weight="bold">
            {DirectPaymentsOnly.select(
              " paid outside the app or hired as a non-W2 employee for an Organization ",
              " paid outside of the app or hired directly by an Organization "
            )}
          </Text>
          you met through GigSmart?
        </Text>
        <Well color="success" size="compact">
          <Column alignItems="center">
            <Text variant="subheader" color="black" weight="normal">
              You may be eligible to receive a
            </Text>
            <Text color="success" weight="bold" testID="humanized-bonus-text">
              {humanizedBonus}{" "}
              {DirectPaymentsOnly.select(
                "Direct Payment Bonus!",
                "Direct Payment & Hire Bonus!"
              )}
            </Text>
          </Column>
        </Well>
      </Stack>
    </HomeScreenCard>
  );
}

export default createRelayFragmentContainer<DirectHireCard_Worker$key>(
  graphql`
    fragment DirectHireCard_Worker on Worker {
      directHireBonus
    }
  `,
  ({ directHireBonus }) => {
    return <DirectHireCard directHireBonus={directHireBonus} />;
  }
);
