import {
  Column,
  ContentArea,
  Divider,
  ListEmptySearch
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import type { GraphQLTaggedNode, KeyType, QuerySpec } from "@gigsmart/relay";
import React from "react";
import Loader from "../Brand/Loader";
import InfiniteGridList from "../shared/InfiniteList/InfiniteGridList";
import ConversationRow from "./ConversationRow";
import type { ConversationRow_conversationLike$key } from "./__generated__/ConversationRow_conversationLike.graphql";

interface Props<TKey extends KeyType, T> {
  activeConversationId?: string;
  onConversationPress: (id: string) => void;
  key: string;
  fragmentInput: GraphQLTaggedNode;
  parentRef: TKey | null;
  getData: (data: TKey[" $data"] | null) => T[] | null;
  viewer?: "worker" | "requester";
  searchTerm?: string;
}

export default function ConversationList<
  Q extends QuerySpec,
  TKey extends KeyType,
  T extends
    | (ConversationRow_conversationLike$key & { id?: string | null })
    | undefined
    | null
>({
  activeConversationId,
  onConversationPress,
  key,
  parentRef,
  fragmentInput,
  getData,
  viewer,
  searchTerm
}: Props<TKey, T>) {
  const currentUser = useCurrentUser();
  const isWorker = viewer === "worker" || currentUser?.__typename === "Worker";
  return (
    <InfiniteGridList<Q, TKey, T>
      key={key}
      grow={false}
      parentRef={parentRef}
      fragmentInput={fragmentInput}
      numColumns={1}
      testID="conversation-list"
      keyExtractor={(conversation) => conversation?.id ?? ""}
      renderItem={(item) => (
        <ConversationRow
          fragmentRef={item}
          isActive={item?.id === activeConversationId}
          isPinned={false}
          onConversationPress={onConversationPress}
          viewer={isWorker ? "worker" : "requester"}
        />
      )}
      ItemSeparatorComponent={Divider}
      spacing="none"
      getData={getData}
      pageSize={10}
      renderEmptyView={(isRefetching) =>
        isRefetching ? (
          <ContentArea alignItems="center" justifyContent="center" fill>
            <Loader />
          </ContentArea>
        ) : searchTerm ? (
          <ListEmptySearch searchTerm={searchTerm} />
        ) : null
      }
      renderFooterView={(isLoadingNext) =>
        isLoadingNext ? (
          <Column fill>
            <Divider />
            <ContentArea alignItems="center" justifyContent="center">
              <Loader />
            </ContentArea>
          </Column>
        ) : (
          <Column fill>
            <Divider />
          </Column>
        )
      }
    />
  );
}
