import {
  Column,
  ContentRow,
  DateTimeInputRow,
  Divider,
  Icon,
  Pressable,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import type { Validator as ValidatorType } from "@gigsmart/fomu/types";
import type { DateTime } from "luxon";
import React, { useEffect, useMemo } from "react";
import {
  createBreakEndValidator,
  createBreakMinimumDurationValidator,
  createBreakOverlapValidator,
  createBreakStartValidator,
  useTimesheetInputFields
} from "./timesheetEditHelpers";

interface Props {
  index: number;
  breakNumber: number;
  removeBreak: () => void;
  gigStart?: DateTime | null;
  gigEnd?: DateTime | null;
  breaks: number[];
  initialValues: Record<string, any>;
  timezone: string;
  minimumPaidGigDuration?: string | null;
}

export default function EditTimesheetBreak({
  breakNumber,
  index,
  removeBreak: removeBreakFn,
  gigStart,
  gigEnd,
  breaks,
  initialValues,
  minimumPaidGigDuration,
  timezone
}: Props) {
  const isSmall = useMatchesViewport(({ size }) => size.xsmall.down);
  const styles = useStyles(({ getUnits }) => ({
    col: { paddingLeft: getUnits(4) }
  }));

  // Create validators
  const { breakStartValidator, breakEndValidator } = useMemo(
    () => ({
      breakStartValidator: [
        createBreakStartValidator({
          dateTimeFieldName: `break${breakNumber}`,
          initialValues,
          breaks
        }),
        createBreakOverlapValidator({
          dateTimeFieldName: `break${breakNumber}Start`,
          breaks
        })
      ],

      breakEndValidator: [
        createBreakEndValidator({
          dateTimeFieldName: `break${breakNumber}`,
          initialValues,
          breaks
        }),
        createBreakOverlapValidator({
          dateTimeFieldName: `break${breakNumber}End`,
          breaks
        }),
        minimumPaidGigDuration &&
          createBreakMinimumDurationValidator({
            dateTimeFieldName: `break${breakNumber}End`,
            breaks,
            minimumPaidGigDuration
          })
      ].filter((val): val is ValidatorType<string> => val !== null)
    }),
    [breakNumber, breaks, initialValues]
  );

  const { isSameDay, availableDates } = useMemo(
    () => calculateInitialFromStartAndEnd(gigStart, gigEnd),
    [gigStart, gigEnd]
  );

  const startFieldProps = useTimesheetInputFields(
    `break${breakNumber}Start`,
    breakStartValidator
  );
  const endFieldProps = useTimesheetInputFields(
    `break${breakNumber}End`,
    breakEndValidator
  );

  const removeBreak = () => {
    startFieldProps.handleInputDestroy();
    endFieldProps.handleInputDestroy();
    removeBreakFn();
  };

  // init form values if needed on mount
  useEffect(() => {
    if (gigStart) {
      const refDate = gigStart.startOf("day");
      if (startFieldProps.onChangeDate !== undefined && !startFieldProps.date)
        startFieldProps.onChangeDate(refDate);
      if (endFieldProps.onChangeDate !== undefined && !endFieldProps.date)
        endFieldProps.onChangeDate(refDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column style={styles.col} zIndex={100 - breakNumber} key={breakNumber}>
      <ContentRow justifyContent="space-between">
        <Text weight="bold">When did break #{index + 1} start and end?</Text>
        <Pressable
          eventEntityType="Remove Break"
          eventTargetName="Remove Break Button"
          onPress={removeBreak}
          testID={`remove-break-${breakNumber}-btn`}
        >
          <Icon variant="solid" name="trash-alt" color="primary" />
        </Pressable>
      </ContentRow>
      <Divider />
      <DateTimeInputRow
        {...startFieldProps}
        zIndex={2}
        inputTestId="break-start-time"
        label={isSmall && isSameDay ? "Break Start" : "Break Start Time"}
        availableDates={availableDates}
      />
      <Divider />
      <DateTimeInputRow
        {...endFieldProps}
        inputTestId="break-end-time"
        label={isSmall && isSameDay ? "Break End" : "Break End Time"}
        availableDates={availableDates}
      />
    </Column>
  );
}

function calculateInitialFromStartAndEnd(
  gigStart?: DateTime | null,
  gigEnd?: DateTime | null
) {
  const isSameDay =
    gigStart && gigEnd ? gigStart.hasSame(gigEnd, "day") : false;
  return {
    isSameDay,
    availableDates:
      gigStart && gigEnd && !isSameDay
        ? [gigStart.startOf("day"), gigEnd.startOf("day")]
        : undefined
  };
}
