import { Button, ContentArea, Spacer, Stack, Text } from "@gigsmart/atorasu";
import {
  type AppNavigationProp,
  defaultBackHandler,
  useNavigation
} from "@gigsmart/kaizoku";
import { showRejectGigPositionPrompt } from "@gigsmart/seibutsu/gig/showRejectGigPositionPrompt";
import React, { type ReactNode, useState } from "react";
import SeriesEngagementsActions from "../../gig-series/modals/series-engagements-actions";
import type { WorkerParamList } from "../../navigation/types";

interface Props {
  workerId?: string | null;
  seriesId?: string | null;
  positionId?: string | null;
  shiftsCount?: number;
  isEnrolled?: boolean;
  note?: ReactNode;
}

export default function SeriesBulkActionRow({
  workerId,
  seriesId,
  positionId,
  shiftsCount = 1,
  isEnrolled = true,
  note
}: Props) {
  const navigation = useNavigation<AppNavigationProp<WorkerParamList>>();
  const [showModal, setShowModal] = useState(false);
  const handlePress = () => setShowModal(true);
  const handleCancel = () => setShowModal(false);
  const handelConfirm = (
    engagementsToAccept?: string[],
    allRejected?: boolean
  ) => {
    setShowModal(false);
    if (seriesId && engagementsToAccept?.length) {
      navigation.push("EngagementAccept", {
        id: seriesId,
        engagementsToAccept
      });
    } else {
      defaultBackHandler();
    }

    if (allRejected && positionId) {
      setTimeout(() => {
        showRejectGigPositionPrompt({ id: positionId });
      }, 400);
    }
  };

  if (!workerId || !seriesId || !positionId) return null;

  return (
    <>
      <ContentArea size="none">
        <Spacer />
        <Stack>
          <Text variant="note" color="neutral">
            You have been offered {shiftsCount} Shifts in a Gig! Please confirm
            your availability.
          </Text>
          <Button
            label="View & Accept Offers"
            testID="view-offers-btn"
            onPress={handlePress}
          />
          {note}
        </Stack>
      </ContentArea>
      {showModal && (
        <SeriesEngagementsActions
          positionId={positionId}
          workerId={workerId}
          seriesId={seriesId}
          needsEnrollment={!isEnrolled}
          onCancel={handleCancel}
          onConfirm={handelConfirm}
        />
      )}
    </>
  );
}
