import {
  HighlightedReminder,
  Stack,
  type StepProps,
  StepSubmit
} from "@gigsmart/atorasu";
import { Form } from "@gigsmart/fomu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useState } from "react";
import SkillCategories from "../../../worker-profile/SkillCategories";
import type { OnboardingData } from "../types";
import PositionsForm from "./PositionsForm";
import type { PositionSelectionStepQuery } from "./__generated__/PositionSelectionStepQuery.graphql";

type Props = StepProps<OnboardingData, { title: string }>;

export default createSuspendedQueryContainer<PositionSelectionStepQuery, Props>(
  function PositionSelectionStepQueryWrapper({
    response,
    stepper,
    setOptions
  }) {
    const { viewer = null } = response ?? {};
    const positionsCount = viewer?.workerPositions?.totalCount ?? 0;
    const [workerCategoryId, setWorkerCategoryId] = useState<string | null>(
      null
    );
    const [categoryId, setCategoryId] = useState<string | null>(null);
    const handleSubmit = () => stepper.nextStep();

    if (workerCategoryId && categoryId) {
      return (
        <PositionsForm
          workerCategoryId={workerCategoryId}
          categoryId={categoryId}
          onChangeTitle={(title) => setOptions({ title })}
          onBackPress={() => {
            setWorkerCategoryId(null);
            setCategoryId(null);
            setOptions({ title: null });
            return true;
          }}
        />
      );
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Stack>
          <SkillCategories
            isOnboarding
            fragmentRef={viewer}
            onCategoryPress={(newWorkerCategoryId, newCategoryId) => {
              setWorkerCategoryId(newWorkerCategoryId);
              setCategoryId(newCategoryId);
            }}
          />
          <HighlightedReminder
            icon="circle-exclamation"
            header="You must select at least one Position to continue. We will notify you of Shift Gig opportunities based on your selections."
          />
        </Stack>
        <StepSubmit
          allowDirty
          testID="category-step-next-btn"
          label="Next"
          disabled={!positionsCount}
        />
      </Form>
    );
  },
  {
    query: graphql`
      query PositionSelectionStepQuery {
        viewer {
          ... on Worker {
            ...SkillCategories_categoriesScreen
            workerPositions(first: 0, query: "WHERE status = CONFIRMED") {
              totalCount
            }
          }
        }
      }
    `,
    variables: {}
  }
);
