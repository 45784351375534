import { type IconName, toast } from "@gigsmart/atorasu";
import useGigLocalDateTime from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import { compact, startCase, toLower } from "lodash";
import moment from "moment-timezone";
import type { AddonFeeSchedule } from "../addon/types";
import {
  type DurationHumanizeVariant,
  currency,
  duration,
  numeric,
  time
} from "../iso";
import type { CurrencyHumanizeOptions } from "../iso/currency";

export function formatPayRate(
  payRate?: string | null,
  gigType?: GigType | null
) {
  return gigType === "VOLUNTEER"
    ? "Volunteer"
    : currency.humanizeRate(payRate, "hr");
}
//
// OLD

// TODO: use relay type to keep this in sync with state_machine.ex
export type GigSeriesStateName =
  | "CANCELED"
  | "DRAFT"
  | "ENDED"
  | "PUBLISHED"
  | "MIGRATED"
  | "%future added value";

export type GigStateName =
  | "ACTIVE"
  | "CANCELED"
  | "COMPLETED"
  | "DRAFT"
  | "EXPIRED"
  | "INACTIVE"
  | "INCOMPLETE"
  | "IN_PROGRESS"
  | "PENDING_REVIEW"
  | "RECONCILED"
  | "UPCOMING"
  | "%future added value";

export type EngagementCapabilityType =
  | "ACCEPT"
  | "ACCEPT_COMMITMENT"
  | "ACCEPT_OTHER"
  | "APPLICATION_TIMEOUT"
  | "APPLY"
  | "APPROVE"
  | "APPROVE_GIG"
  | "APPROVE_SYSTEM_TIMESHEET"
  | "APPROVE_TIMESHEET"
  | "ARRIVE"
  | "AUTO_ARRIVE"
  | "AUTO_BID"
  | "BECOME_AVAILABLE"
  | "BECOME_UNAVAILABLE"
  | "BID"
  | "CALL"
  | "CANCEL"
  | "CANCEL_ENGAGEMENT"
  | "COMMITMENT_TIMEOUT"
  | "CONFIRM"
  | "COUNTER_BID"
  | "COUNTER_OFFER"
  | "CREATED"
  | "DECLINE_COMMITMENT"
  | "DENY_APPLICATION"
  | "DISBURSE"
  | "DISPUTE_TIMESHEET"
  | "EMBARK"
  | "END"
  | "ENGAGE"
  | "EXCEED_DURATION"
  | "FAIL_PAYMENT"
  | "FAIL_PREAUTH"
  | "HIRE"
  | "INACTIVE_CANCEL"
  | "INACTIVE_END"
  | "MARK_AS_COMPLETE"
  | "MODIFY_BID"
  | "MODIFY_OFFER"
  | "NEGOTIATE"
  | "OFFER"
  | "PAUSE"
  | "PAY_WITHOUT_TIMESHEET"
  | "PAY_WORKER_AGAIN"
  | "PICKUP"
  | "REAPPLY"
  | "REBID"
  | "REJECT"
  | "REOFFER"
  | "REQUEST_BID"
  | "REQUEST_HIRE"
  | "REQUEST_START"
  | "RESUME"
  | "REVIEW"
  | "SEND_MESSAGE"
  | "START"
  | "TIMEOUT"
  | "TIMEOUT_CONFIRMATION"
  | "VIEW_BADGES"
  | "VIEW_MESSAGE"
  | "VIEW_QUESTIONNAIRE"
  | "%future added value";

export type EngagementStateAction =
  | "ACCEPT"
  | "ACCEPT_COMMITMENT"
  | "ACCEPT_OTHER"
  | "ACCEPT_PAUSE"
  | "APPLICATION_TIMEOUT"
  | "APPLY"
  | "APPROVE"
  | "APPROVE_GIG"
  | "APPROVE_TIMESHEET"
  | "ARRIVE"
  | "AUTO_ARRIVE"
  | "AUTO_BID"
  | "BECOME_AVAILABLE"
  | "BECOME_UNAVAILABLE"
  | "BID"
  | "CANCEL"
  | "CANCEL_WITH_PAY"
  | "COMMITMENT_TIMEOUT"
  | "CONFIRM"
  | "COUNTER_BID"
  | "COUNTER_OFFER"
  | "CREATED"
  | "DECLINE_COMMITMENT"
  | "DENY_APPLICATION"
  | "DISBURSE"
  | "EMBARK"
  | "END"
  | "ENGAGE"
  | "EXCEED_DURATION"
  | "EXCEED_LATEST_ARRIVAL_TIME"
  | "FAIL_PAYMENT"
  | "FAIL_PREAUTH"
  | "HIRE"
  | "INACTIVE_CANCEL"
  | "INACTIVE_END"
  | "MODIFY_BID"
  | "MODIFY_OFFER"
  | "OFFER"
  | "PAUSE"
  | "PAUSE_REQUEST_TIMEOUT"
  | "PAY"
  | "PAY_WITHOUT_TIMESHEET"
  | "PICKUP"
  | "REAPPLY"
  | "REJECT"
  | "REJECT_PAUSE"
  | "REOFFER"
  | "REBID"
  | "REQUEST_BID"
  | "REQUEST_HIRE"
  | "REQUEST_PAUSE"
  | "REQUEST_START"
  | "RESCIND"
  | "RESUME"
  | "START"
  | "TIMEOUT"
  | "TIMEOUT_CONFIRMATION"
  | "%future added value";

export type AvailableActions =
  | EngagementStateAction
  | "EXTEND_TIME"
  | "REVIEW"
  | "ACTION_REQUIRED";

export type EngagementStateName =
  | "APPLICATION_CANCELED"
  | "APPLICATION_DENIED"
  | "APPLIED"
  | "APPLIED_UNAVAILABLE"
  | "AWAITING_START"
  | "BID_REQUESTED"
  | "BID_REVIEW"
  | "CANCELED"
  | "CANCELED_WITH_PAY"
  | "CONFIRMING"
  | "DISBURSED"
  | "ENDED"
  | "ENGAGED"
  | "ENGAGED_APPLICATION_DENIED"
  | "EN_ROUTE"
  | "HIRE_REQUESTED"
  | "INITIAL"
  | "MISSED"
  | "OFFERED"
  | "PAID"
  | "PAUSED"
  | "PAUSE_REQUESTED"
  | "PAYMENT_FAILED"
  | "PENDING_REVIEW"
  | "PENDING_TIMESHEET_APPROVAL"
  | "PRE_APPROVAL"
  | "PRE_APPROVAL_CANCELED"
  | "PRE_APPROVAL_MISSED"
  | "PRE_APPROVAL_REJECTED"
  | "REJECTED"
  | "RUNNING_LATE"
  | "SCHEDULED"
  | "UNAVAILABLE"
  | "WORKING"
  | "%future added value";

export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReviewerType = "REQUESTER" | "WORKER" | "%future added value";
export type EngagementAddonFeeType =
  | "GENERAL_LIABILITY_INSURANCE"
  | "OCCUPATIONAL_ACCIDENT_INSURANCE"
  | "%future added value";
export interface MoneyAmount {
  amount: string;
  currency: string;
}

type Maybe<T> = T | null | undefined;
type MaybeArray<T> = Maybe<readonly T[]>;

export type EngagementCurrentState = {
  name: EngagementStateName;
  action?: EngagementStateAction;
  transitionedAt?: string;
};

export interface Engagement {
  id?: string;
  currentState: { name: EngagementStateName };
  worker?: { id: string };
  availableActions?: readonly EngagementStateAction[];
}

interface PaymentServiceFee {
  readonly feeType: EngagementAddonFeeType;
  readonly amount: string;
  readonly hourlyRate: string | null | undefined;
}
interface Organization {
  name?: Maybe<string>;
}

interface Requester {
  displayName?: Maybe<string>;
  primaryOrganization?: Maybe<Organization>;
}

export interface GigPushNotificationPayload {
  engagementId: string;
  gigId: string;
  // action: EngagementStateAction | "";
  action: string;
  currentState: EngagementStateName | "";
}

interface GenericPushNotificationData {
  notificationType: string;
  payload: GigPushNotificationPayload; // TODO add other possible payload types
}

// Push notification payload type
export interface GenericPushNotification {
  notificationId?: string | number;
  body?: string | null;
  title?: string | null;
  unsnoozable?: boolean; // for action notifications
  data?: GenericPushNotificationData;
}

export const ALL_ENGAGEMENT_STATE_NAMES: EngagementStateName[] = [
  "APPLICATION_DENIED",
  "APPLIED_UNAVAILABLE",
  "APPLIED",
  "AWAITING_START",
  "CANCELED",
  "CONFIRMING",
  "DISBURSED",
  "EN_ROUTE",
  "ENDED",
  "INITIAL",
  "MISSED",
  "OFFERED",
  "PAID",
  "PAUSE_REQUESTED",
  "PAUSED",
  "PAYMENT_FAILED",
  "PENDING_TIMESHEET_APPROVAL",
  "PRE_APPROVAL_CANCELED",
  "PRE_APPROVAL_MISSED",
  "PRE_APPROVAL_REJECTED",
  "PRE_APPROVAL",
  "REJECTED",
  "RUNNING_LATE",
  "SCHEDULED",
  "UNAVAILABLE",
  "WORKING"
];

export const isApplicableState = (
  name?: GigStateName | null
): name is GigStateName =>
  !!name && ["UPCOMING", "ACTIVE", "IN_PROGRESS"].includes(name);

export const isTerminalState = (
  name?: GigStateName | null
): name is GigStateName =>
  !!name &&
  [
    "RECONCILED",
    "COMPLETED",
    "INCOMPLETE",
    "CANCELED",
    "PENDING_REVIEW"
  ].includes(name);

export function engagementStateColor(engagement: {
  currentState?: { name?: Maybe<EngagementStateName> };
}) {
  const state = engagement?.currentState?.name;
  return stateColor(state);
}

export function stateIcon(state: Maybe<EngagementStateName>): IconName {
  switch (state) {
    case "PENDING_TIMESHEET_APPROVAL":
    case "AWAITING_START":
      return "business-time";
    case "PAUSED":
      return "pause";
    case "APPLIED_UNAVAILABLE":
    case "CANCELED":
    case "MISSED":
    case "UNAVAILABLE":
    case "REJECTED":
      return "ban";
    case "EN_ROUTE":
      return "route";
    case "ENDED":
      return "flag-checkered";
    case "WORKING":
      return "play";
    case "BID_REVIEW":
    case "BID_REQUESTED":
      return "donate";
    default:
      return "bell";
  }
}

export function stateColor(
  state: Maybe<EngagementStateName>,
  gigType?: Maybe<GigType>,
  worker = false
) {
  switch (state) {
    case "AWAITING_START":
    case "PAUSED":
      return "warning";
    case "APPLIED_UNAVAILABLE":
    case "CANCELED":
    case "MISSED":
    case "UNAVAILABLE":
    case "REJECTED":
      return "danger";
    case "EN_ROUTE":
      return "highlight";
    case "ENDED":
    case "PENDING_TIMESHEET_APPROVAL":
      return worker || gigType === "VOLUNTEER" ? "highlight" : "disabled";
    case "WORKING":
      return "success";
    default:
      return "neutral";
  }
}

export function engagementTimeString(engagement: {
  endsAt?: string | null;
  gig: {
    startsAt: string | null | undefined;
    endsAt: string | null | undefined;
  };
}) {
  return gigTimeString({
    startsAt: engagement.gig?.startsAt,
    endsAt: engagement.endsAt ?? engagement.gig?.endsAt
  });
}

export function gigTimeString(
  options?: {
    startsAt?: string | null;
    endsAt?: string | null;
    separator?: string;
    format?: "alt" | "default";
  } | null
) {
  if (!options?.endsAt) return "";
  const { startsAt, endsAt, separator = " - ", format = "default" } = options;
  const hourFormat = format === "alt" ? "h:mmA" : "hh:mmA";
  return [
    startsAt ? moment(startsAt).format(hourFormat) : "ASAP",
    moment(endsAt).format(hourFormat)
  ].join(separator);
}

export function completeDateTimeString(
  gig?: Maybe<{
    startsAt?: any;
    actualStartsAt?: any;
    endsAt?: any;
    duration?: any;
    insertedAt?: any;
  }>
) {
  if (!gig) return "";
  return (
    moment(gig.startsAt ?? gig.actualStartsAt ?? gig.insertedAt).format(
      "ddd. ll [from] "
    ) + gigTimeString(gig)
  );
}

export interface GigFullTimeOptions {
  insertedAt?: any;
  startsAt?: any;
  endsAt: any;
}

export function gigFullTime({
  insertedAt,
  startsAt,
  endsAt
}: GigFullTimeOptions) {
  const day = moment(startsAt || insertedAt).format("ddd, MMM Do");
  return `${day} ${gigTimeString({
    startsAt,
    endsAt,
    format: "alt",
    separator: " to "
  })}`;
}

export function useGigCardDateTimeString(
  gig: Parameters<typeof useGigLocalDateTime>[0]
) {
  const { dateString, timeString } = useGigLocalDateTime(gig, {
    dateFormat: "dateShort"
  });
  return compact([dateString, timeString]).join(", ");
}

export function projectCardDateTimeString(
  startsAt: string | null | undefined,
  insertedAt: string | undefined,
  isTBD: boolean
) {
  if (isTBD) return "Date & Time TBD with Workers";
  if (!startsAt) return `ASAP on ${time.humanize(insertedAt, "dateShort")}`;
  if (time.isToday(startsAt)) return `Today at ${time.humanize(startsAt)}`;
  if (time.isTomorrow(startsAt))
    return `Tomorrow at ${time.humanize(startsAt)}`;
  return `${time.humanize(startsAt, "dateShort")} at ${time.humanize(
    startsAt
  )}`;
}

export function workerProjectCardDateTimeString(
  startsAt: string | null | undefined,
  isTBD: boolean
) {
  if (isTBD) return "Flexible Schedule";
  if (!startsAt) return "ASAP";
  if (time.isToday(startsAt)) return `Today at ${time.humanize(startsAt)}`;
  if (time.isTomorrow(startsAt))
    return `Tomorrow at ${time.humanize(startsAt)}`;
  return `${time.humanize(startsAt, "dateShort")} at ${time.humanize(
    startsAt
  )}`;
}

export function gigDetailDateString(
  gig?: Maybe<{ startsAt?: any; endsAt?: any }>
) {
  if (!gig) return "";
  return gig.startsAt
    ? moment(gig.startsAt).format("ll")
    : moment(gig.endsAt).format("ll");
}

export function engagementDate(engagement: {
  currentState?: EngagementCurrentState;
  gig?: { startsAt?: any; endsAt?: any; duration?: any };
}) {
  return engagement.gig?.startsAt || engagement.currentState?.transitionedAt;
}

export function engagementDateString(engagement: {
  currentState?: EngagementCurrentState;
  endsAt?: any;
  gig: { startsAt?: any; endsAt?: any; duration?: any };
}) {
  const startsAt = engagementDate(engagement);
  // time was extended
  if (engagement.endsAt) {
    const endDate = new Date(engagement.endsAt);
    const startDate = new Date(startsAt);
    if (endDate.getMonth() !== startDate.getMonth()) {
      // different months
      return `${moment(startDate).format("MMM D")}-${moment(endDate).format(
        "MMM D, YYYY"
      )}`;
    }
    if (endDate.getDate() !== startDate.getDate()) {
      // different days
      return `${moment(startDate).format("MMM D")}-${moment(endDate).format(
        "D, YYYY"
      )}`;
    }
  }

  return moment(startsAt).format("ll");
}

export function engagementScheduledIcon(engagement: {
  gig?: { startsAt: any };
}) {
  return gigScheduledIcon(engagement.gig);
}

export function gigScheduledIcon(gig?: Maybe<{ startsAt?: any }>) {
  return gig?.startsAt ? "calendar-alt" : "stopwatch";
}

export function stateStr(state: Maybe<EngagementStateName>, worker = false) {
  if (!state) return "";

  switch (state) {
    case "APPLIED_UNAVAILABLE":
      return "Applied while Unavailable";
    case "PAYMENT_FAILED":
      return "Gig Ended";
    case "ENDED":
      return worker ? "Gig Complete" : "Gig Ended";
    case "OFFERED":
      return "Offered";
    case "SCHEDULED":
      return "Confirmed";
    case "UNAVAILABLE":
      return "Became Unavailable";
    case "CONFIRMING":
      return "Checking Availability";
    case "PENDING_TIMESHEET_APPROVAL":
      return "Pending Approval";
    default:
      return startCase(toLower(state));
  }
}

export function stateIsApplied(stateName: Maybe<EngagementStateName>) {
  return stateName === "APPLIED";
}

export function stateIsUnHired(stateName: Maybe<EngagementStateName>) {
  return (
    !!stateName &&
    (
      [
        "APPLIED",
        "ENGAGED",
        "HIRE_REQUESTED",
        "OFFERED",
        "BID_REVIEW",
        "BID_REQUESTED",
        "CONFIRMING"
      ] as EngagementStateName[]
    ).includes(stateName)
  );
}

export function stateIsComplete(stateName: Maybe<EngagementStateName>) {
  return !!stateName && ["ENDED", "PAYMENT_FAILED"].includes(stateName);
}
export function stateIsIncomplete(stateName: Maybe<EngagementStateName>) {
  return (
    !!stateName &&
    [
      "MISSED",
      "CANCELED",
      "APPLICATION_DENIED",
      "APPLICATION_CANCELED",
      "REJECTED"
    ].includes(stateName)
  );
}

export function stateIsInProgress(stateName: Maybe<EngagementStateName>) {
  return (
    !!stateName &&
    [
      "AWAITING_START",
      "EN_ROUTE",
      "PAUSED",
      "SCHEDULED",
      "WORKING",
      "RUNNING_LATE"
    ].includes(stateName)
  );
}

export function stateIsWorking(stateName: Maybe<EngagementStateName>) {
  return !!stateName && ["WORKING", "PAUSED"].includes(stateName);
}

export function engagementHourlyRateCurrencySymbol(payRate?: string) {
  return moneyCurrencySymbol(payRate);
}

export function moneyAmountStr(
  amount: string,
  volunteer: boolean,
  { plus, decimals = 0 }: { plus?: Maybe<number>; decimals?: number } = {}
) {
  const amountStr = moneyAmount(amount, plus).toFixed(decimals);
  const currency = moneyCurrencySymbol(amount);
  return volunteer ? "" : `${currency}${amountStr}`;
}

export function moneyAmount(hourlyRate: string, plus?: Maybe<number>) {
  const amount = hourlyRate ? Number.parseFloat(hourlyRate || "0") : 0;
  return amount + (plus ?? 0);
}

// TODO need proper translation of currency codes to symbols
export const moneyCurrencySymbol = (_amount?: string) => "$";

function engagementStateTransitionedTime(
  engagement: { states: readonly EngagementCurrentState[] },
  stateName: EngagementStateName
) {
  if (!engagement?.states) {
    return new Date();
  }

  const state = engagement.states.find((s) => s.name === stateName);
  if (!state) {
    return new Date();
  }

  return moment(state.transitionedAt);
}

export function organizationOrRequesterNameAndLastInitial(
  requester?: Maybe<Requester>
) {
  const { displayName, primaryOrganization } = requester ?? {};
  return primaryOrganization?.name
    ? primaryOrganization.name
    : displayName ?? "";
}

export function engagementTimeEarnedStr({
  currentState,
  billableDuration,
  totalDurationWorked,
  currentTime,
  variant
}: {
  currentState?: EngagementCurrentState;
  billableDuration?: Maybe<string>;
  totalDurationWorked?: Maybe<string>;
  currentTime?: Maybe<Date>;
  variant?: DurationHumanizeVariant;
}) {
  const billableTimeSeconds = engagementTimeEarned({
    currentState,
    billableDuration,
    totalDurationWorked,
    currentTime
  });
  if (!billableTimeSeconds) return "";

  return secondsToDurationStr(billableTimeSeconds, variant);
}

export function engagementTimeEarned({
  currentState,
  billableDuration,
  totalDurationWorked,
  currentTime
}: {
  currentState?: EngagementCurrentState;
  billableDuration?: Maybe<string>;
  totalDurationWorked?: Maybe<string>;
  currentTime?: Maybe<Date>;
}) {
  if (!currentState) return 0;
  let billableTimeSeconds: number = duration.toSeconds(billableDuration);

  if (currentState.transitionedAt && currentState.name === "WORKING") {
    billableTimeSeconds += Math.max(
      0,
      time.getDuration(currentTime, currentState.transitionedAt).asSeconds()
    );
  } else if (currentState?.name === "ENDED") {
    if (totalDurationWorked) {
      return Math.round(duration.toSeconds(totalDurationWorked));
    }
  }

  return Math.round(billableTimeSeconds);
}

// TODO should this look at billableEarnings or billableTimeMinutes*hourlyRate?
// do the latter for now

export function calculateTotalFee(
  serviceFees?: readonly PaymentServiceFee[],
  billableSecs = 0
) {
  return (
    serviceFees?.reduce((acc, fee) => {
      return (
        acc +
        Number(
          (currency.toFloat(fee?.hourlyRate) * (billableSecs / 3600)).toFixed(2)
        )
      );
    }, 0) ?? 0
  );
}

export function calculateBillableEarnings({
  currentState,
  billableDuration,
  basePay,
  totalDurationWorked,
  payRate,
  serviceFees,
  currentTime
}: {
  currentState?: EngagementCurrentState;
  billableDuration?: Maybe<string>;
  basePay?: Maybe<string>;
  totalDurationWorked?: Maybe<string>;
  payRate?: Maybe<string>;
  serviceFees?: readonly PaymentServiceFee[];
  currentTime?: Maybe<Date>;
}): number {
  if (currentState?.name === "ENDED" && basePay) {
    return currency.toFloat(basePay);
  }
  const billableTimeSeconds = engagementTimeEarned({
    currentState,
    billableDuration,
    currentTime,
    totalDurationWorked
  });
  const totalEarned =
    currency.toFloat(payRate) * (billableTimeSeconds / (60.0 * 60));
  const totalFee = calculateTotalFee(serviceFees, billableTimeSeconds);

  return totalEarned - totalFee;
}

export function engagementMoneyEarnedStr({
  currentState,
  billableDuration,
  basePay,
  totalDurationWorked,
  payRate,
  currentTime,
  serviceFees,
  showCurrency = false
}: {
  currentState?: EngagementCurrentState;
  billableDuration?: Maybe<string>;
  basePay?: Maybe<string>;
  totalDurationWorked?: Maybe<string>;
  payRate?: string;
  currentTime?: Maybe<Date>;
  serviceFees?: readonly PaymentServiceFee[];
  showCurrency?: boolean;
}) {
  const billableEarnings = calculateBillableEarnings({
    currentState,
    billableDuration,
    basePay,
    totalDurationWorked,
    payRate,
    serviceFees,
    currentTime
  });

  const formatted = showCurrency
    ? currency.humanize(billableEarnings.toFixed(2)) || currency.humanize(0)
    : billableEarnings.toFixed(2) || "0.00";
  return formatted;
}

export function engagementComputeTotalMinutes(
  h: Maybe<string>,
  min: Maybe<string>
) {
  const hours = Number.parseInt(h || "0", 10);
  const minutes = Number.parseInt(min || "0", 10);
  return hours * 60 + minutes;
}

export function secondsToDurationStr(
  seconds = 0,
  format?: DurationHumanizeVariant
) {
  return duration.humanize(duration.fromSeconds(seconds), format);
}

export function engagementAwaitingDurationStr(
  engagement: { states: readonly EngagementCurrentState[] },
  dateNow?: Date,
  longWaitThresholdMinutes = 3
) {
  const awaitingStartTime = engagementStateTransitionedTime(
    engagement,
    "AWAITING_START"
  );
  const waitingDurationMs = moment(dateNow).diff(awaitingStartTime || dateNow);
  if (waitingDurationMs > longWaitThresholdMinutes * 60 * 1000) {
    return moment.duration(waitingDurationMs).humanize();
  }
  return "";
}

export function workingGigRoster(
  engagement: Maybe<{
    currentState: { name: EngagementStateName } | null;
  }>
) {
  if (!engagement?.currentState) {
    return false;
  }
  return [
    "AWAITING_START",
    "SCHEDULED",
    "WORKING",
    "EN_ROUTE",
    "ENDED",
    "PAUSED",
    "PAYMENT_FAILED",
    "PENDING_TIMESHEET_APPROVAL"
  ].includes(engagement?.currentState?.name);
}

export function onGig(
  engagement: Maybe<{
    currentState: { name: EngagementStateName };
  }>
) {
  if (!engagement?.currentState) return false;

  return [
    "AWAITING_START",
    "SCHEDULED",
    "WORKING",
    "OFFERED",
    "EN_ROUTE",
    "ENDED",
    "PAYMENT_FAILED"
  ].includes(engagement.currentState.name);
}

export function isCancelable(
  engagements: MaybeArray<{
    readonly currentState: { readonly name: EngagementStateName };
  }>
) {
  if (!engagements || engagements.length === 0) return true;

  const cancelableStates = ["ENDED", "PAUSED", "PAYMENT_FAILED", "WORKING"];
  for (const engagement of engagements) {
    if (
      engagement?.currentState?.name &&
      cancelableStates.includes(engagement.currentState?.name)
    ) {
      return false;
    }
  }
  return true;
}

export function gigActive(
  gig?: Maybe<{ startsAt?: any; endsAt: any }>
): boolean {
  if (!gig) return false;
  return (
    (!gig.startsAt && moment().isBefore(gig.endsAt)) ||
    (!!gig.startsAt &&
      moment().isSameOrAfter(gig.startsAt) &&
      moment().isBefore(gig.endsAt))
  );
}

export function gigCanTakeWorkers(
  gig?: Maybe<{ endsAt: any; currentState: { name: string } }>
) {
  if (!gig) return false;
  return (
    moment().isBefore(moment(gig.endsAt)) &&
    gig.currentState?.name !== "CANCELED"
  );
}

export function engagementAppliedWorker(
  engagement: Maybe<{
    readonly currentState: { readonly name: EngagementStateName } | null;
  }>
) {
  if (!engagement?.currentState) return false;
  const { name } = engagement?.currentState || {};
  return name === "APPLIED";
}

export function engagementOfferedWorker(
  engagement: Maybe<{
    currentState: { name: EngagementStateName } | null;
  }>
) {
  if (!engagement?.currentState) return false;
  const { name } = engagement?.currentState || {};
  return ["OFFERED", "CONFIRMING"].includes(name);
}

export function inactiveEngagement(
  engagement: Maybe<{
    currentState: { name: EngagementStateName };
  }>
) {
  if (!engagement?.currentState) return false;
  return ["APPLICATION_DENIED", "CANCELED", "REJECTED", "MISSED"].includes(
    engagement?.currentState?.name
  );
}

export function gigDetailHourlyRateStrFormat(
  gig: Maybe<{
    gigType?: Maybe<GigType>;
    isPosted?: Maybe<boolean>;
    payRate?: Maybe<string>;
  }>
) {
  if (!gig) return "";
  const amount = gig.payRate;
  if (gig.gigType === "VOLUNTEER") {
    return "Volunteer";
  }
  if (amount) {
    return `${currency.humanize(amount)}/hr`;
  }
  return "Paid";
}

// TODO:
// engagementPercentElapsedTillExpire(engagement, currentTime) (for TimerBar)
// engagementTimeTillExpire(engagement, currentTime) (for TimerBar)

export function transitionToast(error: Error) {
  const message = error.message;
  if (message?.match(/error transitioning gig/g)) {
    toast.notice(
      "There was an issue with your last request. Please confirm network connection and try again."
    );
  } else if (message) {
    toast.error(message);
  }
}

export function isOutsideApplicantWindow(
  gig?: Maybe<{ readonly endsAt?: Date | string | null }>
) {
  if (!gig) return true;
  const endsAt = moment(gig.endsAt ?? undefined);
  const now = moment();

  return now.isSameOrAfter(endsAt);
}

export function isGigOver(endsAt: Maybe<Date | string>) {
  if (!endsAt) return false;
  const gigEndsAt = moment(endsAt);
  const now = moment();
  return now.isSameOrAfter(gigEndsAt);
}

export const getTimeWorkedString = ({
  currentState,
  billableDuration,
  totalDurationWorked,
  showSeconds = false
}: {
  currentState?: EngagementCurrentState;
  billableDuration?: Maybe<string>;
  totalDurationWorked?: Maybe<string>;
  showSeconds?: boolean;
}): string => {
  const duration = engagementTimeEarned({
    currentState,
    billableDuration,
    totalDurationWorked
  });
  const hours = truncateToDecimals(duration / 3600);
  const minutes = truncateToDecimals((duration - Number(hours) * 3600) / 60);
  if (!showSeconds) return `${hours}h, ${minutes}min`;
  const seconds = truncateToDecimals(
    duration - Number(hours) * 3600 - Number(minutes) * 60
  );
  return `${hours}h ${minutes}min ${seconds}sec`;
};

function truncateToDecimals(num: number, dec = 0) {
  const calcDec = 10 ** dec;
  return Math.trunc(num * calcDec) / calcDec;
}

export const getRateIncreaseStr = (addon: { rateIncrease: Maybe<number> }) => {
  return `$${(addon.rateIncrease ?? 0).toFixed(2)}/h`;
};

export function getAddonFeeAmount(
  addon: Maybe<{
    feeSchedule: Maybe<AddonFeeSchedule>;
    rateIncrease: Maybe<number>;
    minimumFeeIncrease: string;
  }>,
  duration = 0 // in minutes
): number {
  const isHourly = addon?.feeSchedule === "HOURLY";
  return isHourly
    ? (duration * (addon?.rateIncrease ?? 0)) / 60
    : Number.parseFloat(addon?.minimumFeeIncrease ?? "0");
}

export const getAddonFee = (
  addon?: Maybe<{
    feeSchedule: Maybe<AddonFeeSchedule>;
    rateIncrease: Maybe<number>;
    minimumFeeIncrease: string;
  }>,
  duration?: number // in minutes
) => getAddonFeeAmount(addon, duration).toFixed(2);

interface DistanceRangeOptions {
  min?: number | null;
  max?: number | null;
}

const formatOptions = (value: number): Intl.NumberFormatOptions => ({
  maximumFractionDigits: value > 100 ? 0 : 1,
  minimumFractionDigits: value < 100 ? 1 : 0
});

export const formatDistanceRange = (
  options?: DistanceRangeOptions | null,
  suffix = ""
) => {
  let { min, max } = options ?? {};
  min = min ?? 0;
  max = max ?? 0;

  const minStr = numeric.humanize(min ?? 0, formatOptions(min));
  const maxStr = numeric.humanize(max ?? 0, formatOptions(max));
  const distanceStr =
    min && max && minStr !== maxStr ? `${minStr}mi-${maxStr}mi` : `${minStr}mi`;
  return `(${distanceStr}${suffix})`;
};

interface PaymentRangeOptions {
  min?: number | string | null;
  max?: number | string | null;
}

export const formatPayRange = (
  value?: number | string | PaymentRangeOptions | null,
  opt?: CurrencyHumanizeOptions<any>
) => {
  if (typeof value === "number" || typeof value === "string") {
    value = { min: value, max: value };
  }
  const { min, max } = value ?? {};
  if (max && min && min !== max) {
    opt = { integer: true, short: true, ...opt };
    return `${currency.humanize(min, opt)}-${currency.humanize(max, opt)}`;
  }

  const amount = min ?? max;
  return amount
    ? `${currency.humanize(amount, { integer: true, ...opt })}`
    : "";
};

export interface EngagementFilters {
  distance: number;
  workerRating: number;
  completedGigs: number;
  paidWorkers: boolean | undefined;
  transportationState: string[] | undefined;
  workerGroups: Array<{ id: string; name: string }> | undefined;
  workerGroupsCount: number;
}
