import { Linking, Pressable, Stack, Text, showModal } from "@gigsmart/atorasu";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import React, { useState, useEffect } from "react";
import { formatPhoneNumber } from "../shared/Phone/helpers";
import type { PhoneModalMutation } from "./__generated__/PhoneModalMutation.graphql";

interface Props {
  recipientsName: string | null | undefined;
  userType: "Requester" | "Worker";
  callerNumber: string | null | undefined;
  recipientId: string;
}

export default function showPhoneModal(props: Props) {
  showModal({
    eventContext: "phoneModal",
    title: `Call ${props.userType}`,
    useModalFooter: true,
    useModalBody: true,
    dismissable: true,
    children: () => <PhoneModal {...props} />
  });
}

export function PhoneModal({
  recipientsName,
  callerNumber,
  recipientId
}: Omit<Props, "userType">) {
  const [rawNumber, setRawNumber] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [commit] = useRelayMutationPromise<PhoneModalMutation>(graphql`
    mutation PhoneModalMutation($input: AddUserPhoneProxyInput!) {
      addUserPhoneProxy(input: $input) {
        phoneNumber
      }
    }
  `);
  useEffect(() => {
    if (!phoneNumber && recipientId) {
      const getPhoneNumber = async () => {
        const result = await commit({
          input: { targetId: recipientId }
        });
        setRawNumber(rawNumber);
        setPhoneNumber(
          formatPhoneNumber(result?.addUserPhoneProxy?.phoneNumber ?? "")
        );
      };
      void getPhoneNumber();
    }
  }, [commit, phoneNumber, rawNumber, recipientId]);

  const usersNumber = formatPhoneNumber(callerNumber ?? "");
  return (
    <Stack>
      <Text align="center">{recipientsName}</Text>
      <Pressable
        testID="phone-number-link"
        eventEntityType="PhoneModal"
        eventTargetName="phoneNumber"
        onPress={async () => {
          if (await Linking.canOpenURL(`tel:${rawNumber}`)) {
            return await Linking.openURL(`tel:${rawNumber}`);
          }
        }}
      >
        <Text variant="title" align="center">
          {phoneNumber}
        </Text>
      </Pressable>
      <Text variant="note" align="center">
        You must call via your number{"\n"}
        <Text weight="bold"> {usersNumber} </Text>
        for the call to go through.
      </Text>
    </Stack>
  );
}
