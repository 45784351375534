import { ContentArea, Spacer, Stack } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { GigType } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  PAYMENT_TYPES,
  type PaymentTypeKey
} from "@gigsmart/isomorphic-shared/gig/payment-types";
import { BulletList, BulletListItem } from "@gigsmart/katana";
import React, { type ReactNode, type ReactElement } from "react";
import type { Text } from "react-native";

interface Props {
  gigType?: GigType | null;
  spacing?: number;
  thumbnail?: ReactNode;
  thumbnailInsetUnits?: number;
  skillCount?: number;
  skillOrCategoryName?: string | null;
  address?: string | null | ReactElement<Text>;
  addressInfo?: string | null;
  calendar?: string | null | ReactElement<Text>;
  calendarInfo?: string | null;
  paymentType?: PaymentTypeKey | null;
}

const EngagementCardContent = ({
  gigType,
  spacing = 4,
  thumbnail,
  addressInfo,
  skillCount = 1,
  skillOrCategoryName,
  calendar,
  calendarInfo,
  paymentType,
  address,
  thumbnailInsetUnits = 4
}: Props) => {
  if (!paymentType) {
    paymentType = gigType === "PROJECT" ? "NO_PREFERENCE" : "IN_APP";
  }

  const styles = useStyles(
    () => ({
      list: { paddingVertical: 0, flex: 1 }
    }),
    []
  );

  const skillRestCount = skillCount - 1;
  const listProps = { tintColor: "neutralDark", inset: 2.5, numberOfLines: 1 };

  return (
    <Stack horizontal alignItems="center" size="none">
      {thumbnail ?? <Spacer horizontal />}
      <ContentArea>
        <BulletList
          style={styles.list}
          backgroundColor="transparent"
          spacing={1.5}
        >
          <BulletListItem
            {...listProps}
            iconName="box-check"
            infoText={
              skillRestCount > 0
                ? `(+${pluralize(skillRestCount, "skill")})`
                : undefined
            }
          >
            {skillOrCategoryName ?? ""}
          </BulletListItem>

          {!!calendar && (
            <BulletListItem
              {...listProps}
              iconName="calendar-days"
              infoText={calendarInfo}
            >
              {calendar}
            </BulletListItem>
          )}

          <BulletListItem
            {...listProps}
            iconName="location-dot"
            infoText={address !== "REMOTE" ? addressInfo : undefined}
          >
            {address === "REMOTE" ? "Remote" : address ?? "-"}
          </BulletListItem>
          {gigType !== "VOLUNTEER" && (
            <BulletListItem
              {...listProps}
              iconName={PAYMENT_TYPES[paymentType].icon}
            >
              {`Paid via ${PAYMENT_TYPES[paymentType].label}`}
            </BulletListItem>
          )}
        </BulletList>
      </ContentArea>
    </Stack>
  );
};

export default EngagementCardContent;
