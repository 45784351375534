import React from "react";
import { TouchableOpacity } from "react-native";
import { StyledIcon } from "../icon";
import { useStyles } from "../style";

interface Props {
  onPress: () => void;
  testID?: string;
}

export default function CloseModal({ onPress, testID }: Props) {
  const { styles, theme } = useStyles(({ metric }) => ({
    container: {
      position: "absolute",
      top: metric.statusBarHeight(),
      left: 0,
      zIndex: 10,
      padding: 15
    }
  }));
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <StyledIcon
        name="times"
        testID={testID}
        color="blue"
        size={theme.font.size.largest}
      />
    </TouchableOpacity>
  );
}
