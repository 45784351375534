import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";

import useUserValue, { type JsonValue } from "./use-user-value";

export interface WithUserValueProp<T> {
  userValue: T;
  setUserValue: (arg0: T | null | undefined) => void;
}

export const withUserValueStub = {
  userValue: null as any,
  setUserValue: () => undefined
};

const withUserValue =
  <TVal extends JsonValue>(key: string, prop: string): HOCVoid =>
  (WrappedComponent: ComponentType<any>) =>
    applyHOCProperties({
      displayName: `withUserValue[${prop}:${key}]`,
      WrappedComponent,
      HigherOrderComponent: ((props: any) => {
        const [userValue, setUserValue] = useUserValue<TVal>(key);
        const propValue = {
          userValue,
          setUserValue
        };

        return <WrappedComponent {...{ ...props, [prop]: propValue }} />;
      }) as any
    });

export default withUserValue;
