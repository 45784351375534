/**
 * @generated SignedSource<<3cb120f0594af25e235321e1b2355934>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConsentDocumentStepper_documents$data = ReadonlyArray<{
  readonly filename: string;
  readonly " $fragmentSpreads": FragmentRefs<"ConsentDocumentForm_document">;
  readonly " $fragmentType": "ConsentDocumentStepper_documents";
}>;
export type ConsentDocumentStepper_documents$key = ReadonlyArray<{
  readonly " $data"?: ConsentDocumentStepper_documents$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConsentDocumentStepper_documents">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ConsentDocumentStepper_documents",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConsentDocumentForm_document"
    }
  ],
  "type": "LegalDocument",
  "abstractKey": null
};

(node as any).hash = "8b56f006f3a250e3076a7b543d055b53";

export default node;
