/**
 * @generated SignedSource<<0bcc0cef55e0744d0cddb74ba5a58dee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigSummaryRows_gig$data = {
  readonly area: string | null | undefined;
  readonly arrivalInstructions: string | null | undefined;
  readonly category: {
    readonly displayName: string;
  } | null | undefined;
  readonly crisis: {
    readonly title: string;
  } | null | undefined;
  readonly estimatedMileage: number | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly payRate: string | null | undefined;
  readonly pickupEligible: boolean;
  readonly position: {
    readonly name: string;
  } | null | undefined;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly skills: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly skill: {
          readonly name: string;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CategorySummaryRow_gigLike" | "GigPositionSummaryRow_gigLike" | "WorkerShiftScheduleSummaryRow_gig" | "projectSummaryDateTime_gig" | "summaryPaymentType_gig">;
  readonly " $fragmentType": "gigSummaryRows_gig";
};
export type gigSummaryRows_gig$key = {
  readonly " $data"?: gigSummaryRows_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigSummaryRows_gig">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigSummaryRows_gig",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectSummaryDateTime_gig"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "summaryPaymentType_gig"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategorySummaryRow_gigLike"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GigPositionSummaryRow_gigLike"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftScheduleSummaryRow_gig"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "arrivalInstructions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedMileage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "area",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Crisis",
      "kind": "LinkedField",
      "name": "crisis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "GigSkillsConnection",
      "kind": "LinkedField",
      "name": "skills",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigSkillsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSkill",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Skill",
                  "kind": "LinkedField",
                  "name": "skill",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "skills(first:10)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigPosition",
      "kind": "LinkedField",
      "name": "position",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pickupEligible",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};
})();

(node as any).hash = "559526590c2247aa47871be3b65dc6a5";

export default node;
