import React, { Component } from "react";
import { View } from "react-native";

import { type StylesProps, stylesStubs, withStyles } from "../style";
import StyledDatetimePicker from "./styled-datetime-picker";

export interface TimeRange {
  timeFrom: (Date | null | undefined) | (string | null | undefined);
  timeTo: (Date | null | undefined) | (string | null | undefined);
}

type Props = StylesProps & {
  onChange: (arg0: TimeRange | null | undefined) => void;
  maxDate?: (Date | null | undefined) | (string | null | undefined);
  minDate?: (Date | null | undefined) | (string | null | undefined);
  value?: TimeRange | null | undefined;
};

interface State {
  value: TimeRange | null | undefined;
}
@withStyles(({ font }) => ({
  timeRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  timeRowField: {
    width: "48%",
    fontSize: font.size.largest
  }
}))
export default class StyledTimeRange extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    onChange: (_timeRange: TimeRange | null | undefined) => {
      /* noop */
    },
    maxDate: null,
    minDate: null,
    value: { timeFrom: null, timeTo: null }
  };

  state = {
    value: {
      timeFrom: this.props.value?.timeFrom,
      timeTo: this.props.value?.timeTo
    }
  };

  handleChange = (field: string, value?: Date | null | undefined) => {
    const { onChange } = this.props;
    let newValue = null;
    if (field === "timeFrom") {
      newValue = { timeFrom: value, timeTo: this.state.value?.timeTo };
    } else if (field === "timeTo") {
      newValue = { timeFrom: this.state.value?.timeFrom, timeTo: value };
    }
    onChange(newValue);
    this.setState({ value: newValue });
  };

  handleTimeFrom = (value: Date | null | undefined) => {
    this.handleChange("timeFrom", value);
  };

  handleTimeTo = (value: Date | null | undefined) => {
    this.handleChange("timeTo", value);
  };

  render() {
    const { styles } = this.props;

    return (
      <View style={styles.timeRow}>
        <StyledDatetimePicker
          placeholder="Start Time"
          label="Start Time"
          format="h:mm a"
          date={this.props.value?.timeFrom}
          minDate={this.props.minDate}
          maxDate={this.state.value?.timeTo}
          mode="time"
          testID="start-time-picker"
          eventTargetName="Input Start Time Picker"
          onDateChange={this.handleTimeFrom}
          style={styles.timeRowField}
        />
        <StyledDatetimePicker
          placeholder="End Time"
          label="End Time"
          format="h:mm a"
          date={this.props.value?.timeTo}
          minDate={this.state.value?.timeFrom}
          maxDate={this.props.maxDate}
          mode="time"
          testID="end-time-picker"
          eventTargetName="Input End Time Picker"
          onDateChange={this.handleTimeTo}
          style={styles.timeRowField}
        />
      </View>
    );
  }
}
