/**
 * @generated SignedSource<<8d26240dd78083a525d85d7536cd90ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEngagementData$data = {
  readonly billingInfo?: {
    readonly billableDuration: string;
    readonly payRate: string;
  } | null | undefined;
  readonly currentState: {
    readonly name: EngagementStateName;
    readonly transitionedAt: string;
  };
  readonly gig: {
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useGigData">;
  };
  readonly id: string;
  readonly paymentInfo?: {
    readonly basePay: string;
    readonly billableDuration: string;
    readonly payRate: string;
    readonly serviceFees: ReadonlyArray<{
      readonly hourlyRate: string | null | undefined;
    }>;
  } | null | undefined;
  readonly worker: {
    readonly " $fragmentSpreads": FragmentRefs<"useWorkerData">;
  };
  readonly " $fragmentType": "useEngagementData";
};
export type useEngagementData$key = {
  readonly " $data"?: useEngagementData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEngagementData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasBillingInfo"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEngagementData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "condition": "hasBillingInfo",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "paymentInfo",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "basePay",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerServiceFee",
              "kind": "LinkedField",
              "name": "serviceFees",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hourlyRate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasBillingInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementBillingInfo",
          "kind": "LinkedField",
          "name": "billingInfo",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transitionedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGigData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useWorkerData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "bf35dd0f77ec4eda727f347cd0e9b1f7";

export default node;
