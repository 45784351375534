import { showModal } from "@gigsmart/atorasu";
import { useSubscribeToTopic } from "@gigsmart/isomorphic-shared/app/notifications";
import { createRelayNodeContainer, graphql } from "@gigsmart/relay";
import React, { type ReactNode, useEffect } from "react";
import WorkerPositionCheckModal from "./WorkerPositionCheckModal";
import type { WorkerPositionCheckQuery } from "./__generated__/WorkerPositionCheckQuery.graphql";
import type { WorkerPositionCheckSubscription } from "./__generated__/WorkerPositionCheckSubscription.graphql";
import type { WorkerPositionCheck_worker$key } from "./__generated__/WorkerPositionCheck_worker.graphql";
// import { subscribe } from "graphql";

interface Props {
  children?: ReactNode;
  enabled?: boolean;
}

export default createRelayNodeContainer<
  WorkerPositionCheck_worker$key,
  WorkerPositionCheckQuery,
  WorkerPositionCheckSubscription,
  Props,
  {},
  { id: string }
>(
  function WorkerPositionCheck({ enabled, children, result: viewer }) {
    if (!enabled) return <>{children}</>;
    useEffect(() => {
      const confirmedPositionsCount = viewer?.workerPositions?.totalCount ?? 0;
      const isBanned = (viewer?.bans?.totalCount ?? 0) > 0;
      const hasActiveEngagement =
        (viewer?.activeEngagementsAndOffers?.totalCount ?? 0) > 0;
      const hasPosition = confirmedPositionsCount > 0;

      if (!isBanned && !hasActiveEngagement && !hasPosition) {
        showModal({
          raw: true,
          dismissable: false,
          children: (close) => (
            <WorkerPositionCheckModal close={close} fragmentRef={viewer} />
          )
        });
      }
    }, [viewer]);

    useSubscribeToTopic(
      "PREONSITE_TRACKING",
      !!viewer?.preactiveEngagements?.totalCount
    );

    useSubscribeToTopic(
      "ONSITE_TRACKING",
      !!viewer?.activeEngagements?.totalCount
    );

    return <>{children}</>;
  },
  {
    fragment: graphql`
    fragment WorkerPositionCheck_worker on Worker {
      id
      activeEngagementsAndOffers: engagements(
        where: { currentStateName: { _in: [SCHEDULED, EN_ROUTE, AWAITING_START, WORKING, PAUSED, OFFERED] } }
        first: 0
      ) {
        totalCount
      }
      preactiveEngagements: engagements(
        where: { currentStateName: { _in: [SCHEDULED, EN_ROUTE, AWAITING_START] }, gigStateName: { _in: [ACTIVE, IN_PROGRESS] } }
        first: 0
      ) {
        totalCount
      }
      activeEngagements: engagements(
        where: { currentStateName: { _in: [WORKING, PAUSED] }, gigStateName: { _in: [ACTIVE, IN_PROGRESS] } }
        first: 0
      ) {
        totalCount
      }
      bans: restrictions(
        first: 0
        query: "WHERE expiresAt = NULL AND removedAt = NULL"
      ) {
        totalCount
      }
      workerPositions(first: 0, query: "WHERE status = CONFIRMED")
        @connection(key: "WorkerPositionCheck_workerPositions") {
        totalCount
        edges {
          node {
            id
          }
        }
      }
      ...WorkerPositionCheckModal_Worker
    }`,
    variables: {},
    query: graphql`
    query WorkerPositionCheckQuery {
      viewer {
        ...WorkerPositionCheck_worker
      }
    }
  `,
    subscription: graphql`
    subscription WorkerPositionCheckSubscription($id: ID!) {
      nodeUpdated(id: $id) {
        node {
          ...WorkerPositionCheck_worker
        }
      }
    }`,
    getQueryFragmentRef: (data) => data.viewer,
    getSubscriptionFragmentRef: (data) => data.nodeUpdated?.node,
    subscriptionVariables: ({ id }) => ({ id }),
    subscribe: ({ enabled, id }) => !!(enabled && id),
    renderWhenNull: true
  }
);
