/**
 * @generated SignedSource<<27c6f05cf3738557e822f7e2c9465860>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftDetailsCard_shift$data = {
  readonly crisis: {
    readonly title: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CategorySummaryRow_gigLike" | "GigPositionSummaryRow_gigLike" | "WorkerPaymentSummaryRow_shift" | "WorkerSummaryLocation_shift" | "WorkerVerificationSummaryRow_shift">;
  readonly " $fragmentType": "ShiftDetailsCard_shift";
};
export type ShiftDetailsCard_shift$key = {
  readonly " $data"?: ShiftDetailsCard_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDetailsCard_shift">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isBrowsing"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDetailsCard_shift",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategorySummaryRow_gigLike"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GigPositionSummaryRow_gigLike"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isBrowsing",
          "variableName": "isBrowsing"
        }
      ],
      "kind": "FragmentSpread",
      "name": "WorkerSummaryLocation_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerPaymentSummaryRow_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerVerificationSummaryRow_shift"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Crisis",
      "kind": "LinkedField",
      "name": "crisis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "2a2fe4c99d6d775552a05bcb3211eb22";

export default node;
