import { Stack } from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import stateList from "@gigsmart/isomorphic-shared/app/us-state-list";
import { FomuStyledTextInput } from "@gigsmart/isomorphic-shared/fomu-inputs";
import FomuPickerV2 from "@gigsmart/seibutsu/fomu/inputs/FomuPickerV2";
import React from "react";

export default function DriverFields() {
  return (
    <Stack size="compact">
      <FomuPickerV2
        eventTargetName="Picker"
        name="driversLicenseIssuer"
        validates={Validator.presence()}
        label="Driver's License State"
        placeholder="Driver's License State"
        textContentType="addressState"
        options={stateList}
      />
      <FomuStyledTextInput
        testID="drivers-license-id"
        name="driversLicenseId"
        validates={Validator.presence()}
        label="Driver's License ID"
        eventTargetName="Input Driver's License ID"
        placeholder="Driver's License ID"
        autoCapitalize="characters"
        autoCorrect={false}
        normalize={(str) => str.toUpperCase()}
        tip
      />
      <FomuStyledTextInput
        testID="drivers-license-id-confirmation"
        name="driversLicenseIdConfirmation"
        validates={Validator.confirmationOf({
          field: "driversLicenseId",
          message: "must match driver's license id"
        })}
        label="Confirm Driver's License ID"
        eventTargetName="Input Confirmation Driver's License ID"
        placeholder="Confirm Driver's License ID"
        autoCapitalize="characters"
        autoCorrect={false}
        normalize={(str) => str.toUpperCase()}
        tip
      />
    </Stack>
  );
}
