/**
 * @generated SignedSource<<93159b65001be7df221083054773f43a>>
 * @relayHash 07932a47de4eab5a87cb1f0e290aa6af
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:-rIRlHVsal9UhPvGc37ii8pPZJSd_1fNASXFrj8NvW4

import { ConcreteRequest } from 'relay-runtime';
export type AddWorkerLanguageInput = {
  language: string;
  level: string;
  workerId?: string | null | undefined;
};
export type ManageLanguageFormAddLanguageMutation$variables = {
  input: AddWorkerLanguageInput;
};
export type ManageLanguageFormAddLanguageMutation$data = {
  readonly addWorkerLanguage: {
    readonly newWorkerLanguageEdge: {
      readonly cursor: string | null | undefined;
      readonly node: {
        readonly id: string;
        readonly language: string;
        readonly level: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ManageLanguageFormAddLanguageMutation = {
  response: ManageLanguageFormAddLanguageMutation$data;
  variables: ManageLanguageFormAddLanguageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddWorkerLanguagePayload",
    "kind": "LinkedField",
    "name": "addWorkerLanguage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerLanguagesEdge",
        "kind": "LinkedField",
        "name": "newWorkerLanguageEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerLanguage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageLanguageFormAddLanguageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageLanguageFormAddLanguageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:-rIRlHVsal9UhPvGc37ii8pPZJSd_1fNASXFrj8NvW4",
    "metadata": {},
    "name": "ManageLanguageFormAddLanguageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "22e62e444e1fd7c6a34ca806737a2bbc";

export default node;
