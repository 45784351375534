/**
 * @generated SignedSource<<69e4ee191a852b2a2244312e99615af7>>
 * @relayHash 57de689dc2f7541e4fef45d4dd632264
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:L58HbsPeV2YLgPWgrBn06OQNcw9hcO0OBn4K-ywm5wo

import { ConcreteRequest } from 'relay-runtime';
export type AddCancellationReasonDisqualificationInput = {
  cancellationReasonId: string;
  gigFieldItemDefinitionId: string;
};
export type CancellationQuestionnaireAddDisqualificationMutation$variables = {
  input: AddCancellationReasonDisqualificationInput;
};
export type CancellationQuestionnaireAddDisqualificationMutation$data = {
  readonly addCancellationReasonDisqualification: {
    readonly newCancellationReasonDisqualificationEdge: {
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CancellationQuestionnaireAddDisqualificationMutation = {
  response: CancellationQuestionnaireAddDisqualificationMutation$data;
  variables: CancellationQuestionnaireAddDisqualificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCancellationReasonDisqualificationPayload",
    "kind": "LinkedField",
    "name": "addCancellationReasonDisqualification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CancellationReasonDisqualificationsEdge",
        "kind": "LinkedField",
        "name": "newCancellationReasonDisqualificationEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CancellationReasonDisqualification",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancellationQuestionnaireAddDisqualificationMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancellationQuestionnaireAddDisqualificationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:L58HbsPeV2YLgPWgrBn06OQNcw9hcO0OBn4K-ywm5wo",
    "metadata": {},
    "name": "CancellationQuestionnaireAddDisqualificationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "92b23c835c5314d54c31fb5b66cacf34";

export default node;
