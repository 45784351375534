import {
  Button,
  Constraint,
  ContentArea,
  HighlightedReminder,
  KeyboardSpacer,
  Row,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import {
  graphql,
  useRelayFragment,
  useRelayMutationPromise
} from "@gigsmart/relay";
import React from "react";
import NewMessageComposer from "../message/NewMessageComposer";
import type { MessageListFooterJoinConversationMutation } from "./__generated__/MessageListFooterJoinConversationMutation.graphql";
import type { MessageListFooter_conversation$key } from "./__generated__/MessageListFooter_conversation.graphql";
import { showShiftGroupChatSettingsModal } from "./shift-group-chat/ShiftGroupChatSettingsModal";

interface Props {
  variant: "requester" | "worker" | "admin";
  contextId?: string | undefined;
  fragmentRef?: MessageListFooter_conversation$key | null;
}

export default function MessageListFooter({
  variant,
  contextId,
  fragmentRef = null
}: Props) {
  const conversation = useRelayFragment<MessageListFooter_conversation$key>(
    graphql`
      fragment MessageListFooter_conversation on ConversationLike {
        __typename
        ... on Node {
          id
        }
        status
        capabilities
        participant {
          id
        }
        ... on EngagementConversation {
          id
          engagement {
            capabilities {
              status
              type
            }
            gigType
          }
        }
        ... on ShiftGroupConversation {
          currentMessage {
            id
          }
          gig {
            id
            conversationRule
            currentState {
              name
            }
            conversationRule
          }
        }
        ...NewMessageComposer_conversation
      }
    `,
    fragmentRef
  );
  const [joinConversation] =
    useRelayMutationPromise<MessageListFooterJoinConversationMutation>(graphql`
      mutation MessageListFooterJoinConversationMutation(
        $input: AddConversationParticipantInput!
      ) {
        addConversationParticipant(input: $input) {
          newConversationParticipantEdge {
            node {
              id
              readUntil
              readCursor
              capabilities
              conversation {
                ...RequesterConversationHeader_conversationLike
              }
            }
          }
        }
      }
    `);
  const isProjectGigConversation =
    conversation?.__typename === "EngagementConversation";
  const isShiftGroupChat =
    conversation?.__typename === "ShiftGroupConversation";
  const hasMessages = !!conversation?.currentMessage?.id;
  const handleJoinConversation = () => {
    joinConversation({ input: { conversationId: conversation?.id ?? "" } });
  };
  const canSendMessage = conversation?.engagement?.capabilities?.find(
    (c) => c.type === "SEND_MESSAGE" && c.status === "AVAILABLE"
  );
  const disableComposerForWorkerOnShiftGig =
    variant === "worker" &&
    conversation?.__typename === "ShiftGroupConversation" &&
    conversation?.gig?.conversationRule !== "ALL_PARTICIPANTS";
  const renderMessageComposer = () => {
    if (
      (variant === "admin" ||
        (conversation?.engagement?.gigType === "PROJECT" && canSendMessage) ||
        (conversation?.status === "OPEN" && !!conversation.participant?.id)) &&
      !disableComposerForWorkerOnShiftGig
    ) {
      return (
        <NewMessageComposer
          conversationId={conversation?.id ?? ""}
          contextId={isProjectGigConversation ? undefined : contextId}
          fragmentRef={conversation ?? undefined}
        />
      );
    }
    return null;
  };

  const getHighlightedReminderText = () => {
    if (
      variant === "worker" &&
      conversation?.__typename === "ShiftGroupConversation" &&
      conversation?.gig?.conversationRule === "REQUESTER_ONLY" &&
      ["DRAFT", "ACTIVE", "IN_PROGRESS", "UPCOMING"]?.includes(
        conversation?.gig?.currentState?.name ?? ""
      )
    ) {
      return "This Shift Group Chat has been set to read-only by the Organization. You can send messages directly to the Organization through the messaging center.";
    }
    if (
      conversation?.__typename === "ShiftGroupConversation" &&
      conversation?.status === "CLOSED"
    )
      return "This chat is inactive because it has been disabled.";
    if (disableComposerForWorkerOnShiftGig)
      return "This chat is inactive because it has been disabled.";
    if (
      conversation?.__typename === "ShiftGroupConversation" &&
      conversation?.status === "OPEN" &&
      !conversation?.participant?.id
    )
      return "You are currently not participating in this chat. You need to join the chat in order to send a message.";
    if (
      conversation?.__typename === "ShiftGroupConversation" &&
      !["DRAFT", "ACTIVE", "IN_PROGRESS", "UPCOMING"]?.includes(
        conversation?.gig?.currentState?.name ?? ""
      )
    )
      return "This chat is inactive because the Shift has ended.";
    if (
      conversation?.status === "OPEN" &&
      !conversation?.participant?.id &&
      variant !== "admin"
    )
      return "You are currently not participating in this conversation. You need to join the conversation in order to send a message or call the Worker.";
    if (
      conversation?.status === "CLOSED" &&
      variant === "requester" &&
      conversation?.capabilities?.includes("OPEN")
    )
      return "The conversation is currently inactive. You will be able to message this Worker by enabling the conversation in the More menu or the conversation will be enabled automatically on the next Gig they’ve applied to or been hired to.";
    if (conversation?.status === "CLOSED" && variant === "requester")
      return "This conversation is currently inactive. You will be able to message this Worker on the next Shift they’ve applied or scheduled on.";
    if (conversation?.engagement?.gigType === "PROJECT" && variant === "worker")
      return "In order to message this Requester, they must message you first.";
    if (conversation?.status === "CLOSED" && variant === "worker")
      return "This conversation is currently inactive. Your conversation with this Organization will become active again if you are scheduled for additional Shifts. ";
  };
  const highlightedReminderText = getHighlightedReminderText();
  return (
    <ContentArea size="none">
      {renderMessageComposer()}
      <KeyboardSpacer />
      <Stack justifyContent="center" alignItems="center">
        {highlightedReminderText && (
          <Row justifyContent="center" alignItems="center">
            <HighlightedReminder
              testID="message-list-footer-reminder"
              header={highlightedReminderText}
              variant="primary"
              fill
            />
          </Row>
        )}
        {conversation?.capabilities?.includes("JOIN") && (
          <Constraint size="xsmall">
            <Button
              testID="join-conversation-btn"
              label="Join Conversation"
              onPress={handleJoinConversation}
            />
          </Constraint>
        )}
        {isShiftGroupChat &&
          hasMessages &&
          variant === "requester" &&
          conversation?.gig?.conversationRule === "DISABLED" && (
            <Constraint size="xsmall">
              <Button
                testID="enable-chat-btn"
                label="Enable Chat"
                onPress={() =>
                  showShiftGroupChatSettingsModal({
                    conversationRule:
                      conversation?.gig?.conversationRule ?? "DISABLED",
                    gigId: conversation?.gig?.id ?? ""
                  })
                }
              />
            </Constraint>
          )}
      </Stack>
      <Spacer />
    </ContentArea>
  );
}
