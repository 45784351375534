import { checkPermission } from "@gigsmart/atorasu";
import {
  type GeoPosition,
  PositionError
} from "react-native-geolocation-service";
import { IS_TESTING } from "../constants";
import { logger } from "./logger";
import {
  getCurrentPositionFromIp,
  getStaticLocation
} from "./position-fallbacks";
import Geolocation from "./provider";

interface GeolocationOptions {
  enableHighAccuracy?: boolean;
  timeout?: number;
  maximumAge?: number;
}

class GeoError extends Error {
  code: number;
  constructor(message: string, code: PositionError) {
    super(message);
    this.code = code;
  }
}

export const currentLocation = logger.wrap(async function currentLocation(
  options: GeolocationOptions & { ipFallback?: boolean } = {}
): Promise<GeoPosition> {
  options.timeout ??= 10_000;
  options.maximumAge ??= 60_000;
  logger.info("currentLocation", options);
  try {
    return await Promise.race([
      getCurrentPosition(false, options),
      getCurrentPosition(true, options)
    ]);
  } catch (e) {
    logger.error("Error getting location", e);
    return await handleFallback(options, new Error("No position found"));
  }
});

const handleFallback = logger.wrap(
  async (
    { ipFallback, ...options }: GeolocationOptions & { ipFallback?: boolean },
    error: Error
  ): Promise<GeoPosition> => {
    if (IS_TESTING) return await getCurrentPosition(false, options, "static");
    if (ipFallback) return await getCurrentPosition(false, options, "ip");
    return await Promise.reject(error);
  }
);

const getNativeLocation = logger.wrap<typeof Geolocation.getCurrentPosition>(
  async (successCallback, errorCallback, options) => {
    if (
      (
        await checkPermission(
          options?.enableHighAccuracy ? "preciseLocation" : "location",
          { preview: false, required: false }
        )
      ).status !== "granted"
    ) {
      errorCallback?.(
        new GeoError(
          "Missing location permission",
          PositionError.PERMISSION_DENIED
        )
      );
    }
    Geolocation.getCurrentPosition(successCallback, errorCallback, options);
  },
  "getNativeLocation"
);

export const getCurrentPosition = logger.wrap(
  async (
    enableHighAccuracy: boolean,
    options: GeolocationOptions,
    method: "native" | "ip" | "static" = "native"
  ): Promise<GeoPosition> => {
    logger.info(
      `getCurrentPosition (${method}) (enableHighAccuracy: ${enableHighAccuracy})`,
      options
    );
    return await new Promise((resolve, reject) => {
      let geoLocationFn: typeof Geolocation.getCurrentPosition;
      switch (method) {
        case "ip":
          geoLocationFn = getCurrentPositionFromIp;
          break;
        case "static":
          geoLocationFn = getStaticLocation;
          break;
        default:
          geoLocationFn = getNativeLocation;
          break;
      }
      geoLocationFn(
        (pos) => resolve(pos),
        (err) => reject(err),
        { enableHighAccuracy, ...options }
      );
    });
  },
  "getCurrentPosition"
);
