import { time } from "@gigsmart/isomorphic-shared/iso";
import type { HumanizeFormat } from "@gigsmart/isomorphic-shared/iso";
import { compact } from "lodash";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";

interface GigLocalTimeStringInputs {
  startsAt?: string | null | undefined;
  actualStartsAt?: string | null | undefined;
  endsAt?: string | null | undefined;
  timezone?: string | null;
}

interface FormatOptions {
  dateFormat?: HumanizeFormat;
  timeFormat?: HumanizeFormat;
  removeYear?: boolean;
}

/** @deprecated use DateTime/Date or time helpers */
export default function useGigLocalDateTime(
  inputs: GigLocalTimeStringInputs | null | undefined,
  { timeFormat = "hm", removeYear, dateFormat = "fullDate" }: FormatOptions = {}
) {
  const {
    startsAt,
    actualStartsAt,
    endsAt,
    timezone
  }: GigLocalTimeStringInputs = inputs ?? {};
  const isAsap = isNil(startsAt);

  const startTime = actualStartsAt ?? startsAt;
  const startDateTime = startTime ? DateTime.fromISO(startTime) : undefined;
  const endDateTime = endsAt ? DateTime.fromISO(endsAt) : undefined;
  const startsInTZ = timezone ? startDateTime : startDateTime;
  const endsInTZ = timezone ? endDateTime : endDateTime;
  const now = timezone ? DateTime.now() : DateTime.now();

  const sameDay = startsInTZ && endsInTZ && startsInTZ.hasSame(endsInTZ, "day");
  let dateString = compact(sameDay ? [startsInTZ] : [startsInTZ, endsInTZ])
    .map((dt) => time.humanize(dt, dateFormat))
    .join(" to ");

  if (removeYear) dateString = dateString.replace(/, \d{4}/, "");

  const showStartTz =
    (!!startsInTZ && !matchesTz(now, startsInTZ)) ||
    (!!startsInTZ && !!endsInTZ && !matchesTz(startsInTZ, endsInTZ));
  const showEndTz =
    !!endsInTZ && !!startsInTZ && !matchesTz(startsInTZ, endsInTZ);

  const startTzString = showStartTz
    ? ` (${startsInTZ?.toFormat("ZZ ZZZZ")})`
    : "";
  const endTzString = showEndTz ? ` (${endsInTZ?.toFormat("ZZ ZZZZ")})` : "";
  const timeString = compact([
    isAsap
      ? "As soon as possible"
      : `${time.humanize(
          startsInTZ,
          timeFormat,
          timezone
        )}${startTzString}`.toLocaleUpperCase(),
    endsInTZ
      ? `${time.humanize(
          endsInTZ,
          timeFormat,
          timezone
        )}${endTzString}`.toLocaleUpperCase()
      : ""
  ]).join(" to ");

  return endsInTZ
    ? {
        dateString,
        timeString,
        isAsap
      }
    : {
        // One-line case: There's no endsAt value
        dateString: compact([dateString, timeString]).join(" at "),
        isAsap
      };
}

const matchesTz = (source: DateTime, target: DateTime) =>
  source.toFormat("ZZ ZZZZ") === target.toFormat("ZZ ZZZZ");
