import React from "react";

import {
  type IconName,
  MultiSelectHeader,
  MultiSelectRow,
  Stack
} from "@gigsmart/atorasu";
import { FormField, Validator } from "@gigsmart/fomu";

export interface Option {
  id: string;
  label: string;
}

interface Props<T extends Option> {
  testID: string;
  header?: string;
  headerIcon?: IconName;
  headerOptions?: string[];
  options: T[];
  onCheck?: (option: T, value: boolean) => Promise<boolean> | void;
}

export default function FomuMultiSelect<T extends Option>({
  testID,
  header,
  headerIcon,
  headerOptions,
  options,
  onCheck
}: Props<T>) {
  return (
    <>
      <MultiSelectHeader
        testID={`${testID}-${header}-selection-row-header`}
        title={header}
        icon={headerIcon}
        options={headerOptions}
        iconVariant="solid"
      />
      <Stack size="compact">
        {options?.map((option, index) => (
          <FormField
            key={`option-${index}`}
            name={option.id}
            validates={Validator.presence()}
          >
            {({ value, setValue }) => (
              <MultiSelectRow
                testID={`fomu-multiselect-${header}-option-${index}`}
                title={option.label}
                onChange={async (v: boolean) => {
                  if (v !== value) {
                    const confirm = await onCheck?.(option, v);
                    if (confirm !== false) setValue(v);
                  }
                }}
                options={[
                  { testID: `${testID}-option-${index}-no`, value: false },
                  { testID: `${testID}-option-${index}-yes`, value: true }
                ]}
                value={value}
              />
            )}
          </FormField>
        ))}
      </Stack>
    </>
  );
}
