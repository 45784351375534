import React, { type ComponentProps } from "react";

import { Flex } from "../flex";
import { type TextStyleProp, useStyles } from "../style";
import { KatanaText } from "../text";

type Props = ComponentProps<typeof Flex> & {
  titleStyle?: TextStyleProp;
  descriptionStyle?: TextStyleProp;
  title?: string | null | undefined;
  description?: string | null | undefined;
};

export default function RowContent({
  titleStyle,
  descriptionStyle,
  title,
  description,
  children,
  ...props
}: Props) {
  const { styles } = useStyles(({ units }) => ({
    spacing: { marginBottom: units(0.5) },
    spacing2x: { marginBottom: units(1) }
  }));

  return (
    <Flex fill={1} {...props}>
      {!!title && (
        <KatanaText
          variant="rowTitle"
          style={[(!!description || !!children) && styles.spacing, titleStyle]}
        >
          {title}
        </KatanaText>
      )}
      {!!description && (
        <KatanaText
          testID="row-description"
          variant="rowDescription"
          style={[!!children && styles.spacing2x, descriptionStyle]}
        >
          {description}
        </KatanaText>
      )}
      {children}
    </Flex>
  );
}

RowContent.defaultProps = {
  children: null
};
