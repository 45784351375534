import React, { useMemo } from "react";

import {
  Button,
  Column,
  IconCircle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { IndependentContractorAgreement } from "@gigsmart/feature-flags";
import { Form, Validator } from "@gigsmart/fomu";
import { Suspense } from "@gigsmart/relay";
import FomuCheckbox from "@gigsmart/seibutsu/fomu/inputs/FomuCheckbox";
import FomuSubmit from "../../fomu/inputs/FomuSubmit";
import LegalDocumentLink from "../../user-consent/LegalDocumentLink";

type Props = {
  onSubmit: () => void;
  action: "APPLY" | "PICK_UP" | "BID" | "ACCEPT";
  gigType: "SHIFT" | "PROJECT";
  isMultiShift: boolean;
  onClose: () => void;
};

function ApplyConfirmationModal({
  onSubmit,
  onClose,
  action,
  isMultiShift,
  gigType
}: Props) {
  const typeString = gigType === "SHIFT" ? "Shift" : "Project";
  const suffix = isMultiShift ? `these ${typeString}s` : `this ${typeString}`;

  const handleSubmit = () => {
    onClose();
    setTimeout(() => onSubmit(), 200);
  };

  const { prompt, noLabel, yesLabel } = useMemo<{
    prompt: string;
    noLabel: string;
    yesLabel: string;
  }>(
    () =>
      ({
        APPLY: {
          prompt: `Are you sure you want to Apply to ${suffix}?`,
          noLabel: "No, Do Not Apply",
          yesLabel: "Yes, Apply"
        },
        PICK_UP: {
          prompt: `Are you sure you want to Pickup ${suffix}?`,
          noLabel: "No, Do Not Pickup",
          yesLabel: "Yes, Pickup"
        },
        BID: {
          prompt: `Are you sure you want to Bid on ${suffix}?`,
          noLabel: "No, Do Not Bid",
          yesLabel: "Yes, Bid"
        },
        ACCEPT: {
          prompt: `Are you sure you want to Accept the offer on ${suffix}?`,
          noLabel: "No, Do Not Accept",
          yesLabel: "Yes, Accept"
        }
      })[action],
    [action]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader />
      <Suspense fallback={<ModalBody constraint="xsmall" gap="xlarge" />}>
        <ModalBody constraint="xsmall" gap="xlarge">
          <IconCircle
            icon="file-contract"
            size="large"
            variant="well"
            color="primary"
            iconColor="primary"
            alignSelf="center"
          />
          <Column gap="standard">
            <Text variant="header" weight="bold" color="primary" align="center">
              {prompt}
            </Text>
            <FomuCheckbox
              expand
              title={
                <Text align="left">
                  If you proceed, you agree to our{" "}
                  <LegalDocumentLink filename="gigsmart/tos" />
                  {IndependentContractorAgreement.isEnabled() ? (
                    <>
                      {" and "}
                      <LegalDocumentLink filename="gigsmart/independent-contractor-agreement" />
                    </>
                  ) : null}
                </Text>
              }
              name="accept-tos"
              validates={Validator.isTrue()}
              eventTargetName="Engage Gig Accept Terms Checkbox"
            />
          </Column>
        </ModalBody>
        <ModalFooter spacing="compact">
          <Button
            label={noLabel}
            variant="clear"
            fullWidth
            onPress={onClose}
            testID="cancel-confirm-gig-apply-button"
          />
          <FomuSubmit
            testID="engage-gig-button"
            label={yesLabel}
            icon="user-plus"
          />
        </ModalFooter>
      </Suspense>
    </Form>
  );
}

export function showConfirmationModal(props: Omit<Props, "onClose">) {
  return showModal({
    useModalBody: false,
    eventContext: "Apply Gig Confirmation Modal",
    children: (close) => <ApplyConfirmationModal {...props} onClose={close} />
  });
}
