import React from "react";
import { ActivityIndicator, Platform, View } from "react-native";

import { type ViewStyleProp, createStyles, theme } from "../style";

interface Props {
  style?: ViewStyleProp;
  color?: string;
  size?: "small" | "large";
}

const styles = createStyles(() => ({
  container: {
    height: 90,
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default ({
  style,
  color = Platform.OS === "ios" ? "#808080" : theme.color.orange,
  size = Platform.OS === "ios" ? "small" : "large",
  ...props
}: Props) => (
  <View style={[styles.container, style]}>
    <ActivityIndicator size={size} color={color} {...props} />
  </View>
);
