import { Button, Stack } from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit, Validator } from "@gigsmart/fomu";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import React, { useState } from "react";
import FomuPasswordInput from "../fomu/inputs/FomuPasswordInput";
import HomeScreenCard from "../worker/HomescreenCard";
import type { ChangePasswordFormCardMutation } from "./__generated__/ChangePasswordFormCardMutation.graphql";

export default function ChangePasswordFormCard() {
  const [formKey, setFormKey] = useState(Math.random().toString(36));
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [setUserPassword] =
    useRelayMutationPromise<ChangePasswordFormCardMutation>(
      graphql`
        mutation ChangePasswordFormCardMutation($input: SetUserPasswordInput!) {
          setUserPassword(input: $input) {
            ok
          }
        }
      `
    );

  const reset = () => setFormKey(Math.random().toString(36));

  const handleSubmit: FomuSubmitFn = async ({
    values: { currentPassword, password: newPassword }
  }) => {
    try {
      await setUserPassword({
        input: {
          currentPassword,
          newPassword
        }
      });
      toast.success("Password successfully changed!");
      reset();
    } catch (error) {
      toast.error(
        error.message === "invalid"
          ? "Invalid Current Password"
          : "Error saving password"
      );
      setCurrentPasswordError("Does not match Current Password");
    }
  };

  return (
    <Form onSubmit={handleSubmit} key={formKey}>
      <HomeScreenCard
        headerTitle="Change Password"
        headerIcon="key"
        action={
          <FormSubmit>
            {({ submit, submitting, invalid, dirty }) => (
              <Button
                label="Save"
                size="small"
                testID="change-email-button"
                disabled={!dirty || submitting || invalid}
                onPress={submit}
              />
            )}
          </FormSubmit>
        }
      >
        <Stack>
          <FomuPasswordInput
            label="Current Password"
            placeholder="Current Password"
            name="currentPassword"
            validates={[Validator.presence()]}
            errors={currentPasswordError}
            onKeyPress={() => setCurrentPasswordError("")}
          />
          <FomuPasswordInput
            label="New Password"
            placeholder="New Password"
            name="password"
            validates={[
              Validator.presence(),
              Validator.length({
                min: 8,
                message: "must have 8 or more characters"
              })
            ]}
          />
          <FomuPasswordInput
            label="Confirm New Password"
            placeholder="Confirm New Password"
            name="passwordConfirm"
            validates={[
              Validator.confirmationOf({
                field: "password",
                message: "Must match New Password"
              })
            ]}
          />
        </Stack>
      </HomeScreenCard>
    </Form>
  );
}
