import React, { memo, useMemo } from "react";

import type { IconName } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import JobCard from "./job-card";

import type { Color } from "@gigsmart/atorasu";
import type {
  jobCardFragment_job$data,
  jobCardFragment_job$key
} from "./__generated__/jobCardFragment_job.graphql";

const fragmentSpec = graphql`
  fragment jobCardFragment_job on JobPosting {
    place {
      streetAddress
    }
    title
    description
    organization {
      name
    }
    boosted
    paySchedule
    payRateNegotiable
    maximumPayRate
    minimumPayRate
    area
  }
`;

interface Props {
  jobRef: jobCardFragment_job$key | null | undefined;
  onPress?: () => void;
}

const JobCardFragment = memo(
  ({ jobRef, onPress }: Props) => {
    const job = useRelayFragment(fragmentSpec, jobRef);
    const { description, tags } = useMemo(
      () => ({
        description: job?.description,
        tags: generateTags(job)
      }),
      [job]
    );
    if (!job) return null;
    return (
      <JobCard
        title={job?.title}
        company={job?.organization?.name}
        description={description ?? ""}
        tags={tags}
        onPress={onPress}
      />
    );
  },
  (p1, p2) => p1.jobRef === p2.jobRef
);

export default JobCardFragment;

//

const generateTags = (node: jobCardFragment_job$data | null | undefined) => {
  const tags = [];
  if (node?.area) tags.push(node?.area);
  if (node?.payRateNegotiable) {
    tags.push("Negotiable");
  } else if (node?.paySchedule === "ANNUALLY") {
    const amount =
      node?.minimumPayRate === node?.maximumPayRate
        ? `$${Math.floor(currency.toFloat(node?.minimumPayRate)) / 1000}K`
        : `$${Math.floor(currency.toFloat(node?.minimumPayRate)) / 1000}K to ${
            Math.floor(currency.toFloat(node?.maximumPayRate)) / 1000
          }K`;
    tags.push(`Salary • ${amount}`);
  } else {
    const amount =
      node?.minimumPayRate === node?.maximumPayRate
        ? `$${Math.floor(currency.toFloat(node?.minimumPayRate))}`
        : `$${Math.floor(
            currency.toFloat(node?.minimumPayRate)
          )} - $${Math.floor(currency.toFloat(node?.maximumPayRate))}`;
    tags.push(`Hourly • ${amount}`);
  }
  if (node?.boosted) {
    tags.push({
      title: "Highlighted",
      icon: "angles-up" as IconName,
      color: "emphasized" as Color
    });
  }
  return tags;
};
