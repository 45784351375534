/**
 * @generated SignedSource<<f337ee0cd505122473cd73743d713cb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useLegalDocument_document$data = {
  readonly filename: string;
  readonly format: string;
  readonly source: string;
  readonly " $fragmentType": "useLegalDocument_document";
};
export type useLegalDocument_document$key = {
  readonly " $data"?: useLegalDocument_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"useLegalDocument_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useLegalDocument_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    },
    {
      "alias": "source",
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "type": "LegalDocument",
  "abstractKey": null
};

(node as any).hash = "7be609b46bc4c5f1dae6bd7af8787e64";

export default node;
