/**
 * @generated SignedSource<<64607f97eb34967137a0a2be85a9a8ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileStrengthCard_worker$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useWorkerProfileStrength_worker">;
  readonly " $fragmentType": "ProfileStrengthCard_worker";
};
export type ProfileStrengthCard_worker$key = {
  readonly " $data"?: ProfileStrengthCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileStrengthCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileStrengthCard_worker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useWorkerProfileStrength_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "b531fd6cc536fce597aa6b9aea17edf7";

export default node;
