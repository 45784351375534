import React, { type ReactNode } from "react";
import { View } from "react-native";

import { createStyles } from "../style";

const DOT_SIZE = 36;
const BAR_HEIGHT = 30;

const styles = createStyles(({ color }) => ({
  container: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  dotWrapper: {
    alignItems: "center",
    marginRight: 8
  },
  dot: {
    borderRadius: DOT_SIZE / 2,
    borderWidth: 5,
    height: DOT_SIZE - 10,
    width: DOT_SIZE - 10,
    zIndex: 2,
    backgroundColor: color.blueTint,
    borderColor: color.blue
  },
  bar: {
    marginTop: -2,
    marginBottom: -2,
    borderColor: color.neutralMedium,
    backgroundColor: color.blueTint,
    borderLeftWidth: 4,
    borderRightWidth: 4,
    height: BAR_HEIGHT,
    width: 12,
    zIndex: 1
  },
  barActive: {
    backgroundColor: color.green
  },
  dotActive: {
    backgroundColor: color.green,
    borderColor: color.green
  }
}));

interface Props {
  index: number;
  count: number;
  isComplete: boolean;
  children?: ReactNode;
}

export default ({ index, count, isComplete, children }: Props) => {
  const isLast = index === count - 1;
  const isLastBar = index === count - 2;

  return (
    <View style={styles.container}>
      <View style={styles.dotWrapper}>
        <View style={[styles.dot, !isComplete && isLast && styles.dotActive]} />
        {!isLast && (
          <View
            style={[styles.bar, !isComplete && isLastBar && styles.barActive]}
          />
        )}
      </View>
      {children}
    </View>
  );
};
