import {
  ActionRow,
  Button,
  CollapsibleContainer,
  Column,
  ContentArea,
  Divider,
  HighlightedReminder,
  IconText,
  Screen,
  Spacer,
  Stack,
  Surface,
  Text,
  Well,
  useStepper
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { Carousel } from "@gigsmart/isomorphic-shared/media";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import StepHeader from "../../../shared/Stepper/StepHeader";
import ClaimOrganizationSurface from "./ClaimOrganizationSurface";
import Nav from "./Nav";
import type { InformationStepDirectHireClaimQuery } from "./__generated__/InformationStepDirectHireClaimQuery.graphql";
import type { DirectHireClaimsStepper } from "./index";

export default createSuspendedQueryContainer<InformationStepDirectHireClaimQuery>(
  function InformationStep({ response: result }) {
    const { gotoStep } = useStepper<{}, DirectHireClaimsStepper>();

    const styles = useStyles(() => ({
      carousel: { backgroundColor: "transparent" }
    }));
    const claim = result?.node;
    const isHire = claim?.directHireType === "HIRE";
    return (
      <Screen testID="information-step-screen">
        <Column fill justifyContent="space-between">
          <Nav returnTo="agreement" />
          <Stack>
            <StepHeader
              name={DirectPaymentsOnly.select(
                "Direct Payment Request Information",
                "Direct Payment & Hire Request Information"
              )}
              note={`Please add the required information below to the best of your ability. The more thorough the information you provide, the more likely your request will be approved. When you are finished, continue to the next step to review and submit your ${DirectPaymentsOnly.select(
                "Direct Payment",
                "Direct Payment or Hire"
              )} Request.`}
            />
            <Surface>
              <ActionRow
                testID="bonus-type-row"
                icon="badge-dollar"
                title={
                  <Text color="primary" weight="semibold">
                    Bonus Type{" "}
                    {!claim?.directHireType && (
                      <Text color="danger" weight="semibold">
                        (Required)
                      </Text>
                    )}
                  </Text>
                }
                right={
                  claim?.directHireType && DirectPaymentsOnly.isDisabled() ? (
                    <Button
                      testID="edit-type-btn"
                      size="small"
                      label="Edit"
                      icon="pen-to-square"
                      outline
                      onPress={() => gotoStep("type")}
                    />
                  ) : undefined
                }
              />
              <ContentArea>
                {claim?.directHireType ? (
                  <IconText
                    icon="check"
                    iconColor="success"
                    iconVariant="solid"
                    size="small"
                  >
                    Direct {isHire ? "Hire" : "Payment"} Bonus
                  </IconText>
                ) : (
                  <Stack>
                    <HighlightedReminder
                      icon="circle-exclamation"
                      header="You must add the type of bonus you are Requesting to receive."
                    />
                    <Column alignItems="center">
                      <Button
                        testID="add-bonus-type-btn"
                        size="small"
                        icon="plus"
                        label="Add Bonus Type"
                        onPress={() => gotoStep("type")}
                      />
                    </Column>
                  </Stack>
                )}
              </ContentArea>
            </Surface>
            {claim?.directHireType && (
              <ClaimOrganizationSurface fragmentRef={claim} />
            )}
            {claim?.directHireType && (
              <Surface>
                <ActionRow
                  testID="support-and-evidence-row"
                  title={
                    <Text color="primary" weight="semibold">
                      Direct {isHire ? "Hire" : "Payment"} Support & Evidence{" "}
                      {!claim?.comments && (
                        <Text color="danger" weight="semibold">
                          (Required)
                        </Text>
                      )}
                    </Text>
                  }
                  right={
                    claim?.comments ? (
                      <Button
                        testID="edit-evidence-btn"
                        size="small"
                        label="Edit"
                        icon="pen-to-square"
                        outline
                        onPress={() => gotoStep("evidence")}
                      />
                    ) : undefined
                  }
                  icon="list"
                />
                <CollapsibleContainer
                  testID="support-and-evidence-container"
                  startCollapsed
                  header={
                    <ContentArea>
                      {claim?.comments && (
                        <IconText
                          icon="check"
                          iconColor="success"
                          iconVariant="solid"
                          size="small"
                        >
                          Support & Evidence Provided
                        </IconText>
                      )}
                      {!claim?.comments && (
                        <Stack>
                          <HighlightedReminder
                            icon="circle-exclamation"
                            header="You must provide an explanation and evidence to submit."
                          />
                          <Column alignItems="center">
                            <Button
                              testID="add-evidence-btn"
                              size="small"
                              icon="plus"
                              label={`Add Direct ${
                                isHire ? "Hire" : "Payment"
                              } Support`}
                              onPress={() => gotoStep("evidence")}
                            />
                          </Column>
                        </Stack>
                      )}
                    </ContentArea>
                  }
                >
                  {!!claim?.comments && (
                    <>
                      <Divider />
                      <ContentArea>
                        <Well color="neutral" contentVariant="compact">
                          <ContentArea variant="compact" size="none">
                            <Stack>
                              <Text weight="semibold">
                                Confirmation Request Support:
                              </Text>
                              <Text italic>{claim?.comments}</Text>
                            </Stack>
                          </ContentArea>
                          <Spacer />
                          <Carousel
                            style={styles.carousel}
                            sources={getConnectionNodes(claim?.photos) ?? []}
                            thumbWidth={60}
                          />
                        </Well>
                      </ContentArea>
                    </>
                  )}
                </CollapsibleContainer>
              </Surface>
            )}
            {claim?.comments && claim?.firstName && (
              <ContentArea size="none">
                <HighlightedReminder
                  icon="circle-exclamation"
                  header={`Your Direct ${
                    isHire ? "Hire" : "Payment"
                  } request is not complete. Continue to the next step to review and submit to the Organization for review.`}
                />
              </ContentArea>
            )}
          </Stack>
          <ContentArea>
            <Button
              testID="continue-btn"
              label="Continue with Request"
              disabled={
                !claim?.directHireType || !claim?.firstName || !claim?.comments
              }
              onPress={() => gotoStep("review")}
            />
          </ContentArea>
        </Column>
      </Screen>
    );
  },
  {
    query: graphql`
      query InformationStepDirectHireClaimQuery($id: ID!) {
        node(id: $id) {
          ... on DirectHireClaim {
            id
            directHireType
            firstName
            ...ClaimOrganizationSurface_DirectHireClaim
            comments
            photos(first: 5) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: () => {
      const { stepsData } = useStepper<{}, DirectHireClaimsStepper>();
      return { id: stepsData?.id ?? "" };
    }
  }
);
