import React from "react";

import { Stack } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import { useCurrentUser } from "../../current-user";
import type { accountModeItem_gig$key } from "./__generated__/accountModeItem_gig.graphql";
import type { accountModeItem_series$key } from "./__generated__/accountModeItem_series.graphql";
import GigCardItem from "./item";

interface Props {
  gigRef?: accountModeItem_gig$key | null | undefined;
  seriesRef?: accountModeItem_series$key | null | undefined;
}

const GigCardAccountModeItem = ({ gigRef, seriesRef }: Props) => {
  const styles = useStyles(() => ({
    fill: {
      width: "100%"
    }
  }));
  const user = useCurrentUser();
  const gig = useRelayFragment(
    graphql`
      fragment accountModeItem_gig on Gig {
        organization {
          id
        }
      }
    `,
    gigRef ?? null
  );
  const series = useRelayFragment(
    graphql`
      fragment accountModeItem_series on GigSeries {
        organization {
          id
        }
      }
    `,
    seriesRef ?? null
  );

  const showAccountMode = !!user?.primaryOrganization?.uuid;
  const isBusinessGig = !!(gig ?? series)?.organization?.id;

  if (!showAccountMode) return null;
  return (
    <Stack fill horizontal size="compact" justifyContent="flex-start">
      <GigCardItem inset={0} iconName="user-gear" inline>
        Posted from
      </GigCardItem>
      <GigCardItem
        inset={0}
        iconColor="neutralDark"
        iconName={isBusinessGig ? "building" : "house"}
        inline
      >
        {isBusinessGig ? "Business Mode" : "Personal Mode"}
      </GigCardItem>
    </Stack>
  );
};

export default GigCardAccountModeItem;
