import {
  Avatar,
  ContentArea,
  Icon,
  Pressable,
  Row,
  Spacer,
  Stack,
  Surface,
  Text,
  TimeRemaining
} from "@gigsmart/atorasu";
import { currency, time } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { DirectPaymentsAndHiresRow_DirectHireClaim$key } from "./__generated__/DirectPaymentsAndHiresRow_DirectHireClaim.graphql";

interface Props {
  variant: "APPROVED" | "PENDING" | "REJECTED";
  directHireBonus?: string | null;
}

export function DirectPaymentsAndHiresRow({
  id,
  organization,
  directHireType,
  workerPayout,
  responseDeadline,
  transitions,
  variant,
  directHireBonus,
  rejected
}: FragmentContainerInnerComponentProps<
  DirectPaymentsAndHiresRow_DirectHireClaim$key,
  Props
>) {
  const history = useHistory();
  const disbursedAt = transitions?.edges?.[0]?.node?.transitionedAt;
  const noResponse =
    variant === "REJECTED" &&
    (rejected?.edges?.[0]?.node?.transitionedBy?.agent?.__typename ===
      "SystemUser" ||
      rejected?.edges?.[0]?.node?.action === "TIMEOUT");
  return (
    <Pressable
      testID={`direct-payments-and-hires-row-${id}`}
      eventTargetName="Direct Payments and Hires Row"
      eventEntityType="DirectPaymentsAndHiresRow"
      onPress={() => history.push(`/direct-hire/${id}`)}
    >
      <Surface variant="outline">
        <ContentArea size="compact" variant="compact">
          <Stack horizontal justifyContent="space-between" alignItems="center">
            <Stack horizontal alignItems="center">
              <Avatar uri={organization?.logoUrl} size="medium" />
              <Stack size="slim">
                <Text color="primary" weight="bold">
                  {organization?.name}
                </Text>
                <Text variant="note">
                  {currency.humanize(
                    variant === "APPROVED" ? workerPayout : directHireBonus
                  )}{" "}
                  Direct {directHireType === "HIRE" ? "Hire" : "Payment"} Bonus
                </Text>
                {variant === "APPROVED" && (
                  <Text color="success" weight="semibold" variant="note">
                    Paid {time.humanize(disbursedAt, "dateCompact")}
                  </Text>
                )}
                {variant === "PENDING" && (
                  <Text weight="semibold" variant="note">
                    Pending, <TimeRemaining date={responseDeadline} size="sm" />{" "}
                    left
                  </Text>
                )}
                {variant === "REJECTED" && !noResponse && (
                  <Text color="danger" weight="semibold" variant="note">
                    Rejected{" "}
                    {time.humanize(
                      rejected?.edges?.[0]?.node?.transitionedAt,
                      "dateCompact"
                    )}
                  </Text>
                )}
                {variant === "REJECTED" && noResponse && (
                  <Text color="danger" weight="semibold" variant="note">
                    No Response from Requester
                  </Text>
                )}
              </Stack>
            </Stack>
            <Row>
              <Icon name="chevron-right" size="small" variant="solid" />
              <Spacer horizontal size="compact" />
            </Row>
          </Stack>
        </ContentArea>
      </Surface>
    </Pressable>
  );
}

export default createRelayFragmentContainer<
  DirectPaymentsAndHiresRow_DirectHireClaim$key,
  Props
>(
  graphql`
    fragment DirectPaymentsAndHiresRow_DirectHireClaim on DirectHireClaim {
      id
      directHireType
      organization {
        name
        logoUrl
      }
      workerPayout
      rejected: transitions(first: 1, query: "WHERE state = REJECTED") {
        edges {
          node {
            transitionedAt
            transitionedBy {
              agent {
                __typename
              }
            }
            action
          }
        }
      }
      responseDeadline
      transitions(first: 1, query: "WHERE state = DISBURSED") {
        edges {
          node {
            transitionedAt
          }
        }
      }
    }
  `,
  DirectPaymentsAndHiresRow
);
