/**
 * @generated SignedSource<<d007f66776b031ee59a282eb639c04b9>>
 * @relayHash a739fbf6fb651f15bec5c3289061225f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:bBct2P6bgKGNcgJlXVzTkOHuBVsBsLatgAbKMH2o47Q

import { ConcreteRequest } from 'relay-runtime';
export type UnsetProofOnWorkerQualificationInput = {
  workerQualificationId: string;
};
export type useWorkerQualificationProofUnsetMutation$variables = {
  input: UnsetProofOnWorkerQualificationInput;
};
export type useWorkerQualificationProofUnsetMutation$data = {
  readonly unsetProofOnWorkerQualification: {
    readonly updatedWorkerQualification: {
      readonly id: string;
    };
  } | null | undefined;
};
export type useWorkerQualificationProofUnsetMutation = {
  response: useWorkerQualificationProofUnsetMutation$data;
  variables: useWorkerQualificationProofUnsetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnsetProofOnWorkerQualificationPayload",
    "kind": "LinkedField",
    "name": "unsetProofOnWorkerQualification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerQualification",
        "kind": "LinkedField",
        "name": "updatedWorkerQualification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useWorkerQualificationProofUnsetMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWorkerQualificationProofUnsetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:bBct2P6bgKGNcgJlXVzTkOHuBVsBsLatgAbKMH2o47Q",
    "metadata": {},
    "name": "useWorkerQualificationProofUnsetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4f20b2bab0b5cca51c01d7a6ccf64e39";

export default node;
