import React, { type ReactNode } from "react";
import { View } from "react-native";
import { useStyles } from "../style";

interface Props {
  children?: ReactNode;
}

const AccordionContent = ({ children }: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    container: {
      backgroundColor: color.white
    }
  }));

  return <View style={styles.container}>{children}</View>;
};

export default AccordionContent;
