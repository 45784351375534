/**
 * @generated SignedSource<<b59f6da7c08055fa3176f8f6b0922d5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkHistoryForm_worker$data = {
  readonly employer: string;
  readonly employmentEnd: string | null | undefined;
  readonly employmentStart: string;
  readonly id: string;
  readonly positionTitle: string | null | undefined;
  readonly " $fragmentType": "WorkHistoryForm_worker";
};
export type WorkHistoryForm_worker$key = {
  readonly " $data"?: WorkHistoryForm_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkHistoryForm_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkHistoryForm_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "positionTitle",
      "storageKey": null
    }
  ],
  "type": "WorkerWorkHistory",
  "abstractKey": null
};

(node as any).hash = "de6115f921168fb8b24c2559d5d774ba";

export default node;
