import {
  ContentArea,
  Divider,
  EditButton,
  FancyHeader,
  RadioButtonGroup,
  Row,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { Validator, useFormField } from "@gigsmart/fomu";
import React from "react";

import { showEditPaymentMethodModal } from "./EditPaymentMethodModal";

type PaymentMethodType = "BankCard" | "BankAccount";

export default function SetPaymentMethodField() {
  const kind = useFormField<PaymentMethodType>({
    name: "paymentMethodType",
    validates: Validator.presence()
  });

  const bankAccount = useFormField<any>("bankAccount");
  const bankCard = useFormField<any>("bankCard");

  const getField = (value = kind.value) =>
    value === "BankAccount"
      ? bankAccount
      : value === "BankCard"
        ? bankCard
        : null;

  const data = getField()?.value;
  const hasPaymentMethod = !!data;

  const handleChangeKind = (type: PaymentMethodType) => {
    const field = getField(type);
    if (!field) return;

    if (field.value) {
      kind.setValue(type);
    } else {
      showEditPaymentMethodModal({
        type,
        onConfirm: (value) => {
          kind.setValue(type);
          field?.setValue(value);
        }
      });
    }
  };

  const handleEdit = () => {
    if (!kind.value) return;

    const field = getField();
    showEditPaymentMethodModal({
      type: kind.value,
      initialValues: data,
      onConfirm: (value) => field?.setValue(value)
    });
  };

  return (
    <Stack>
      <Row justifyContent="center">
        <RadioButtonGroup
          horizontal
          variant="icon"
          onChange={handleChangeKind}
          value={kind.value}
          buttons={[
            {
              label: "Add Debit Card",
              icon: "credit-card",
              iconCircle: false,
              testID: "card-option",
              value: "BankCard"
            },
            {
              label: "Add Bank Account",
              icon: "building-columns",
              iconCircle: false,
              testID: "bank-account-option",
              value: "BankAccount"
            }
          ]}
        />
      </Row>
      {hasPaymentMethod && <Divider />}
      {hasPaymentMethod && (
        <Surface variant="outline">
          <FancyHeader
            iconType="IconSquare"
            action={
              <EditButton testID="edit-payment-method" onPress={handleEdit} />
            }
            {...(data.type === "BankAccount"
              ? { icon: "building-columns", title: "Bank Account" }
              : { icon: "credit-card", title: "Debit Card" })}
          />
          <ContentArea>
            <Text>{renderDataLabel(data)}</Text>
          </ContentArea>
        </Surface>
      )}
    </Stack>
  );
}

function renderDataLabel(data: any) {
  switch (data.type) {
    case "BankAccount":
      return `${data.accountHolderName} *${data.accountNumber.slice(-4)}`;
    case "BankCard":
      return `${data.name} *${data.number.slice(-4)}`;
  }
}
