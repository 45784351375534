import React from "react";
import { Platform, View } from "react-native";
import { type IconName, StyledIcon } from "../icon";
import { FastImage } from "../image";
import {
  type ImageStyleProp,
  type TextStyleProp,
  createStyles
} from "../style";
import { Hoverable } from "../tappable";
import { StyledText } from "../text";

export interface Props {
  iconName?: IconName;
  imageUri?: string | null;
  imageId?: string;
  imageCircle?: boolean;
  activeStyleColor: TextStyleProp;
  iconStyle: TextStyleProp;
  isActive: boolean;
  badgeText?: string | null;
  label?: string;
  textStyle?: TextStyleProp;
}

const styles = createStyles(({ color, font }) => ({
  hoverBackground: {
    backgroundColor: color.withOpacity(color.blue, 0.1),
    borderRadius: 3
  },
  badgeContainer: {
    borderRadius: 9,
    height: 18,
    minWidth: 18,
    justifyContent: "center",
    backgroundColor: color.orange,
    position: "absolute"
  },
  label: {
    fontSize: font.size.medium,
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: "center"
  },
  badge: {
    color: color.white,
    fontSize: font.size.smallest,
    textAlign: "center",
    marginHorizontal: 3,
    ...Platform.select({
      ios: { top: 1 },
      android: { top: 0 },
      web: { top: 0 }
    })
  },
  badgeContainerStyle: {
    top: 5,
    ...Platform.select({
      ios: { left: "62%" },
      android: { left: "62%" },
      web: { left: "60%" }
    })
  },
  image: {
    marginTop: 8,
    alignSelf: "center",
    height: 26,
    width: 26,
    borderWidth: 1,
    borderColor: "transparent"
  },
  imageActive: { borderColor: color.blue },
  imageCircle: { borderRadius: 13 },
  placeholder: { height: 34 }
}));

const TabIcon = ({
  activeStyleColor,
  iconStyle,
  textStyle,
  isActive,
  iconName,
  imageUri,
  imageId,
  imageCircle = true,
  label,
  badgeText
}: Props) => (
  <Hoverable>
    {(hover) => (
      <View style={hover && styles.hoverBackground}>
        {imageUri ? (
          <FastImage
            style={
              [
                styles.image,
                imageCircle && styles.imageCircle,
                isActive && styles.imageActive
              ] as ImageStyleProp
            }
            source={{ uri: imageUri }}
            imageId={imageId}
          />
        ) : (
          !!iconName && (
            <StyledIcon
              name={iconName}
              variant={isActive ? "regular" : "light"}
              style={[activeStyleColor, iconStyle]}
            />
          )
        )}
        {!!label && (
          <StyledText
            adjustsFontSizeToFit
            numberOfLines={1}
            style={[styles.label, activeStyleColor, textStyle]}
          >
            {label}
          </StyledText>
        )}
        {!!badgeText && (
          <View style={[styles.badgeContainer, styles.badgeContainerStyle]}>
            <StyledText style={styles.badge}>{badgeText}</StyledText>
          </View>
        )}
      </View>
    )}
  </Hoverable>
);

export default TabIcon;
