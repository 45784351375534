import { CollapsibleWell, GridNull, IconText, Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";

import type { LicensesCard_workerprofile$key } from "./__generated__/LicensesCard_workerprofile.graphql";

export const LicensesCard = ({
  licenses
}: FragmentContainerInnerComponentProps<LicensesCard_workerprofile$key>) => {
  const license = getConnectionNodes(licenses)[0];
  const selectedLicense = getConnectionNodes(license?.selectedDefinitions);

  if (!selectedLicense.length) {
    return <GridNull />;
  }

  const items = selectedLicense?.map((license) => (
    <IconText
      icon="check"
      color="success"
      spacing="compact"
      iconSize="small"
      size="medium"
      key={license.label}
    >
      <Text
        color="black"
        variant="subheader"
        weight="normal"
        testID={`license-${license.label}`}
      >
        {license.label}
      </Text>
    </IconText>
  ));

  return (
    <CollapsibleWell
      testID="worker-profile-licenses"
      headerIcon="file-certificate"
      headerText="Licenses & Certifications"
      items={items}
    />
  );
};

export default createRelayFragmentContainer<LicensesCard_workerprofile$key>(
  graphql`
    fragment LicensesCard_workerprofile on Worker {
      id
      licenses: gigFields(
        first: 1
        query: "WHERE title='Licenses & Certifications'"
      ) {
        edges {
          node {
            selectedDefinitions: itemDefinitions(
              first: 15
              query: "WHERE status = CONFIRMED"
            ) {
              edges {
                node {
                  label
                }
              }
            }
          }
        }
      }
    }
  `,
  LicensesCard
);
