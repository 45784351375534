/**
 * @generated SignedSource<<c24eda40dd937ff8fac4dff3bdf0a2b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useEnrollmentData_legalInformation$data = {
  readonly administrativeArea1: string;
  readonly backgroundCheckOptIn: boolean;
  readonly birthdate: string;
  readonly driverCheckOptIn: boolean;
  readonly driversLicenseIssuer: string | null | undefined;
  readonly driversLicenseLast4: string | null | undefined;
  readonly drugScreenOptIn: boolean;
  readonly emailAddress: string;
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly locality: string;
  readonly middleName: string | null | undefined;
  readonly postalCode: string;
  readonly pspTosAccepted: boolean;
  readonly sendReportCopies: boolean;
  readonly street1: string;
  readonly street2: string | null | undefined;
  readonly " $fragmentType": "useEnrollmentData_legalInformation";
};
export type useEnrollmentData_legalInformation$key = {
  readonly " $data"?: useEnrollmentData_legalInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEnrollmentData_legalInformation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useEnrollmentData_legalInformation"
};

(node as any).hash = "9c7692fca1e5553474955f5fce5ea717";

export default node;
