import { where } from "@gigsmart/biruda";
import {
  getConnectionNodes,
  graphql,
  useFetchQueryResult,
  useRelayFragment
} from "@gigsmart/relay";
import { useEffect, useState } from "react";
import type { GigPositionStatus } from "../__generated__/PositionsStepMutation.graphql";
import type { useGigPositionsViewerQuery } from "./__generated__/useGigPositionsViewerQuery.graphql";
import type { useGigPositions_gigLike$key } from "./__generated__/useGigPositions_gigLike.graphql";

export default function useGigPositions(
  fragmentRef: useGigPositions_gigLike$key | null = null
) {
  const node = useRelayFragment(
    graphql`
      fragment useGigPositions_gigLike on GigLike {
        ... on GigSeries {
          category {
            id
            displayName
            categoryIcon {
              data
            }
          }
          position {
            id
            name
          }
        }
        ... on Gig {
          category {
            id
            displayName
            categoryIcon {
              data
            }
          }
          position {
            id
            name
          }
        }
      }
    `,
    fragmentRef
  );

  const [{ viewer = null } = {}] =
    useFetchQueryResult<useGigPositionsViewerQuery>(
      graphql`
        query useGigPositionsViewerQuery($positionsQuery: String!) {
          viewer {
            ... on Worker {
              id
              positions(first: 1, query: $positionsQuery) {
                edges {
                  node {
                    id
                    status
                  }
                }
              }
            }
          }
        }
      `,
      {
        variables: {
          positionsQuery: where({
            gigPositionId: node?.position?.id
          }).toString()
        },
        fetchPolicy: "network-only"
      }
    );

  const workerPosition = getConnectionNodes(viewer?.positions)[0];
  const gigHasCategory = !!node?.category?.id;
  const gigHasPosition = !!node?.position?.id;

  const workerPositionStatus: GigPositionStatus | "LOADING" = viewer?.positions
    ? workerPosition?.status ?? "UNKNOWN"
    : "LOADING";

  const [initialWorkerPositionStatus, setinitialWorkerPositionStatus] =
    useState(workerPositionStatus);

  useEffect(() => {
    if (
      initialWorkerPositionStatus === "LOADING" &&
      initialWorkerPositionStatus !== workerPositionStatus
    ) {
      setinitialWorkerPositionStatus(workerPositionStatus);
    }
  }, [workerPositionStatus]);

  return {
    gigHasCategoryPositions: gigHasCategory && gigHasPosition,
    initialWorkerPositionStatus,
    workerPositionStatus,
    requiredPosition: node,
    workerId: viewer?.id
  };
}
