import type { DateTime as LuxonDateTime } from "luxon";
import type { Moment as MomentDateTime } from "moment";
import type Enum from "./enum";
import type Float from "./float";

export type Value =
  | DateLike
  | Enum
  | Float
  | Now
  | string
  | boolean
  | number
  | null
  | undefined;

export class Now {
  NOW = true;
}

export type DateLike = Date | LuxonDateTime | MomentDateTime | Now;

export const now = () => new Now();
