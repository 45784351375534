import { Switch } from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormToggle } from "@gigsmart/fomu";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";

type RefinedStyledPickerProps = Omit<
  ComponentPropsWithDefaults<typeof Switch>,
  "onSubmitEditing" | "toggled" | "onChange" | "testID"
>;

type Props = RefinedStyledPickerProps & FormFieldOptions<boolean>;

export default function FomuSwitch({ name, ...props }: Props) {
  const { toggle, value } = useFormToggle({ name });

  return (
    <Switch
      toggled={value}
      onChange={toggle}
      testID={`${name}-switch`}
      {...props}
    />
  );
}
