import { graphql, useFetchQuery } from "@gigsmart/relay";
import type { hooksPlaceDetailsSilentQuery } from "./__generated__/hooksPlaceDetailsSilentQuery.graphql";

const query = graphql`
  query hooksPlaceDetailsSilentQuery($placeId: ID!) {
    place: node(id: $placeId) {
      ... on Place {
        id
        area
        postalCode
        country
        administrativeAreaLevel1
        administrativeAreaLevel2
        locality
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

export function useFetchPlaceDetails() {
  const fetchPlace = useFetchQuery<hooksPlaceDetailsSilentQuery>(query);
  return (placeId: string) => fetchPlace({ placeId });
}
