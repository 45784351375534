import React from "react";

import { where } from "@gigsmart/biruda";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ManageLanguageForm from "@gigsmart/seibutsu/worker-profile/ManageLanguageForm";
import type { WorkerParamList } from "../navigation/types";

import type { manageLanguageScreenQuery } from "./__generated__/manageLanguageScreenQuery.graphql";

type Props = AppScreenProps<
  WorkerParamList,
  "ProfileAddLanguage" | "ProfileEditLanguage"
>;
export default createSuspendedQueryContainer<manageLanguageScreenQuery, Props>(
  function ManageLanguagesScreen({ route, response: { viewer } = {} }) {
    const id = route.params?.languageId;
    const selectedLanguage = id ? viewer?.languages?.edges?.[0]?.node : null;
    return (
      <ManageLanguageForm
        {...(!!selectedLanguage && {
          language: selectedLanguage?.language,
          languageLevel: selectedLanguage?.level,
          languageId: selectedLanguage?.id,
          workerId: viewer?.id
        })}
      />
    );
  },
  {
    query: graphql`
      query manageLanguageScreenQuery($query: String!) {
        viewer {
          ... on Worker {
            id
            languages(first: 1, query: $query) {
              edges {
                node {
                  id
                  language
                  level
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({
      query: where({ id: route.params?.languageId }).toString()
    })
  }
);
