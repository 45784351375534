import {
  Persistence,
  usePromisedValue,
  useRequestPermission
} from "@gigsmart/atorasu";
import { captureError } from "@gigsmart/dekigoto";
import { graphql, useFetchQuery } from "@gigsmart/relay";
import { useCallback } from "react";
import type { hooksUseCurrentGeocodedLocationSilentQuery } from "./__generated__/hooksUseCurrentGeocodedLocationSilentQuery.graphql";
import { currentLocation } from "./currentLocation";
import type { GeoPosition } from "./provider";

type Place = NonNullable<
  hooksUseCurrentGeocodedLocationSilentQuery["response"]["reverseGeocode"][number]
>["place"];

const PERSISTENCE_KEY = "lastKnownLocation";

export const useCurrentLocation = (request = false) => {
  const fetchCurrentLocation = useFetchCurrentLocation();
  const location = usePromisedValue(
    async () => await fetchCurrentLocation(request),
    null,
    []
  );
  return location;
};

export const useFetchCurrentLocation = () => {
  const requestPermission = useRequestPermission("location", {
    trace: "useFetchCurrentLocation",
    preview: false
  });
  return useCallback(async (request = false): Promise<GeoPosition | null> => {
    const lastKnownLocation =
      await Persistence.load<GeoPosition>(PERSISTENCE_KEY);
    if (request) requestPermission();
    const nextLocation = await currentLocation({ ipFallback: true });
    void Persistence.save(PERSISTENCE_KEY, {
      timestamp: nextLocation.timestamp,
      coords: {
        accuracy: nextLocation.coords.accuracy,
        altitude: nextLocation.coords.altitude,
        altitudeAccuracy: nextLocation.coords.altitudeAccuracy,
        heading: nextLocation.coords.heading,
        speed: nextLocation.coords.speed,
        latitude: nextLocation.coords.latitude,
        longitude: nextLocation.coords.longitude
      }
    });
    return nextLocation ?? lastKnownLocation;
  }, []);
};

export const useFetchPlaceFromCurrentLocation = () => {
  const fetchCurrentLocation = useFetchCurrentLocation();
  const fetchPlace = useFetchPlaceFromGeoPosition();
  return async (request = false): Promise<Place | null> => {
    const position = await fetchCurrentLocation(request);
    return await fetchPlace(position);
  };
};

export const useFetchPlaceFromGeoPosition = () => {
  const fetchGeocodedLocation =
    useFetchQuery<hooksUseCurrentGeocodedLocationSilentQuery>(graphql`
      query hooksUseCurrentGeocodedLocationSilentQuery(
        $input: ReverseGeocodeInput!
      ) {
        reverseGeocode(input: $input) {
          place {
            area
            postalCode
          }
        }
      }
    `);
  return useCallback(
    async (position: GeoPosition | null | undefined): Promise<Place | null> => {
      if (!position) return null;
      const { latitude, longitude } = position?.coords ?? {};
      const response = await fetchGeocodedLocation({
        input: { location: { latitude, longitude }, layer: "POSTAL_CODE" }
      })
        .toPromise()
        .catch((error) => {
          captureError(error);
          throw error;
        });
      return (response?.reverseGeocode ?? [])[0]?.place ?? null;
    },
    [fetchGeocodedLocation]
  );
};

export const useCurrentGeocodedLocation = (request = false) => {
  const position = useCurrentLocation(request);
  const fetchPlace = useFetchPlaceFromGeoPosition();

  return usePromisedValue<Place | null>(
    async () => await fetchPlace(position),
    null,
    [fetchPlace, position]
  );
};
