import { Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import React from "react";
import { View } from "react-native";

interface Props {
  mainText: string;
  label?: string;
  hintText?: string;
}

const SquareData = ({ label, mainText, hintText }: Props) => {
  const styles = useStyles(({ getColor }) => ({
    container: {
      width: 86,
      height: 86,
      borderRadius: 4,
      justifyContent: "center",
      backgroundColor: getColor("background", "fill")
    },
    hintText: {
      color: "#F69E1E",
      fontSize: 12,
      textAlign: "center"
    },
    mainTextContainer: {
      minHeight: 36,
      justifyContent: "center"
    },
    mainText: {
      color: "#F69E1E",
      fontSize: 20,
      lineHeight: 20,
      textAlign: "center"
    }
  }));

  const mainTextFontSize =
    mainText.length <= 6 ? 20 : mainText.length <= 8 ? 16 : 14;

  return (
    <View style={styles.container}>
      {!!label && <Text style={styles.hintText}>{label}</Text>}
      <View style={styles.mainTextContainer}>
        <Text
          weight="bold"
          style={[
            styles.mainText,
            { fontSize: mainTextFontSize, lineHeight: mainTextFontSize }
          ]}
        >
          {mainText}
        </Text>
      </View>
      {!!hintText && <Text style={styles.hintText}>{hintText}</Text>}
    </View>
  );
};

export default SquareData;
