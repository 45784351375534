/**
 * @generated SignedSource<<0904db6715cf2844d6aa6ef1e142819c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftOrgActionRow_engagement$data = {
  readonly contactNumber: string | null | undefined;
  readonly conversation: {
    readonly id?: string;
  } | null | undefined;
  readonly gig: {
    readonly id: string;
    readonly organization: {
      readonly logoUrl: string | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly primaryContact: {
      readonly displayName: string | null | undefined;
    } | null | undefined;
  };
  readonly gigType: GigType;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useMessaging_engagement" | "usePhoneCall_engagement">;
  readonly " $fragmentType": "ShiftOrgActionRow_engagement";
};
export type ShiftOrgActionRow_engagement$key = {
  readonly " $data"?: ShiftOrgActionRow_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftOrgActionRow_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftOrgActionRow_engagement",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactNumber",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePhoneCall_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMessaging_engagement"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationRequester",
          "kind": "LinkedField",
          "name": "primaryContact",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "conversation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "Node",
          "abstractKey": "__isNode"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "f91cc4aef2c9cfe0fc776ce65b5c9932";

export default node;
