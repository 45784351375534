import { toast } from "@gigsmart/atorasu";
import {
  ConnectionHandler,
  graphql,
  useFetchQueryResult,
  useRelayMutation
} from "@gigsmart/relay";
import type { Option } from "@gigsmart/seibutsu/shared/MultiCategorySelectionRowGroup";
import { showCommercialDriversLicenseModal } from "@gigsmart/seibutsu/worker/CommercialDriversLicenseModal";
import type { useUpdateWorkerQualificationMutation } from "./__generated__/useUpdateWorkerQualificationMutation.graphql";
import type { useUpdateWorkerQualificationProofMutation } from "./__generated__/useUpdateWorkerQualificationProofMutation.graphql";
import type { useUpdateWorkerQualificationQuery } from "./__generated__/useUpdateWorkerQualificationQuery.graphql";

interface Props {
  workerId: string;
  gigId: string;
}

export default function useUpdateWorkerQualification({
  workerId,
  gigId
}: Props) {
  const [{ viewer = null } = {}] =
    useFetchQueryResult<useUpdateWorkerQualificationQuery>(
      graphql`
        query useUpdateWorkerQualificationQuery {
          viewer {
            ... on Worker {
              qualificationProofs(
                first: 0
                query: "WHERE type = DRIVERS_LICENSE"
              )
                @connection(
                  key: "useUpdateWorkerQualification___qualificationProofs"
                ) {
                totalCount
                edges {
                  node {
                    ... on WorkerQualificationDriversLicenseProof {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      `
    );

  const [setQualificationMutation] =
    useRelayMutation<useUpdateWorkerQualificationMutation>(graphql`
      mutation useUpdateWorkerQualificationMutation(
        $input: SetWorkerQualificationInput!
      ) {
        setWorkerQualification(input: $input) {
          newWorkerQualificationEdge {
            node {
              id
              gigFieldItemDefinition {
                id
                label
              }
            }
          }
        }
      }
    `);

  const [setQualificationProofMutation] =
    useRelayMutation<useUpdateWorkerQualificationProofMutation>(graphql`
      mutation useUpdateWorkerQualificationProofMutation(
        $input: SetProofOnWorkerQualificationInput!
      ) {
        setProofOnWorkerQualification(input: $input) {
          updatedWorkerQualification {
            id
            proofStatus
          }
        }
      }
    `);

  const updateWorkerQualification = async ({
    value,
    id,
    label,
    enableAutoChecking
  }: Option) => {
    const onError = () => toast.error("Something went wrong");
    const updater = (store: any) => {
      const node = store.get(gigId);
      if (node != null) {
        node.invalidateRecord();
      }
    };

    const done = (newWorkerQualificationProofId?: string) => {
      setQualificationMutation(
        {
          input: {
            status: value ? "CONFIRMED" : "DENIED",
            gigFieldItemDefinitionId: id,
            workerId
          }
        },
        {
          onError,
          onSuccess: (res) => {
            if (newWorkerQualificationProofId) {
              setQualificationProofMutation(
                {
                  input: {
                    workerQualificationId:
                      res.setWorkerQualification?.newWorkerQualificationEdge
                        ?.node?.id ?? "",
                    workerQualificationProofId: newWorkerQualificationProofId
                  }
                },
                {
                  updater: (store) => {
                    const worker = store.get(workerId);
                    if (!worker) return;
                    const connection = ConnectionHandler.getConnection(
                      worker,
                      "useUpdateWorkerQualification___qualificationProofs",
                      { query: "WHERE type = DRIVERS_LICENSE" }
                    );
                    if (connection) {
                      connection.setValue(1, "totalCount");
                    }
                  }
                }
              );
            }
          },
          updater
        }
      );

      return true;
    };

    if (enableAutoChecking) return done();

    if (value && !viewer?.qualificationProofs?.totalCount) {
      return new Promise<boolean>((resolve) => {
        showCommercialDriversLicenseModal({
          title: label,
          onComplete: (workerQualificationProofId) =>
            resolve(done(workerQualificationProofId)),
          onRequestClose: () => resolve(false)
        });
      });
    }
    return done();
  };

  return { updateWorkerQualification };
}
