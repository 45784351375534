import {
  getActionIconName,
  getGigActionMap
} from "@gigsmart/isomorphic-shared/engagement/gig-action-map";
import { getWaitingStartState } from "@gigsmart/isomorphic-shared/worker/helpers";
import { useHistory } from "@gigsmart/kaizoku";
import { SmallOutlineButton } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import useEngagementTransition, {
  useEngagementWorkerActions
} from "@gigsmart/seibutsu/shifts/hooks/useEngagementTransition";
import React, { useCallback } from "react";
import { updateWorkerLocation } from "../use-location-updater";
import type { engagementMainAction_engagement$key } from "./__generated__/engagementMainAction_engagement.graphql";

const actionMap = getGigActionMap({
  END: { label: "Submit Review", testID: "submit-review-act" },
  APPROVE_TIMESHEET: { label: "View Summary", testID: "view-summary-act" }
});

interface Props {
  engagementRef?: engagementMainAction_engagement$key | null | undefined;
}

const EngagementMainAction = ({ engagementRef }: Props) => {
  const history = useHistory();

  const engagement = useRelayFragment(
    graphql`
      fragment engagementMainAction_engagement on Engagement {
        ...helpersWorker_engagement
        ...useEngagementTransition_engagement
        id
        currentState {
          name
        }
        capabilities {
          type
          status
        }
        worker {
          transportationMethod
        }
      }
    `,
    engagementRef ?? null
  );
  const engagementId = engagement?.id ?? "";
  const [handleAction, { loading }] = useEngagementTransition(engagementId);
  const actions = useEngagementWorkerActions({
    engagementRef: engagement,
    validActions: ["EMBARK", "REQUEST_START", "PAUSE", "RESUME", "START"]
  });

  const { actionSpec, capability } = actions[0] ?? {};
  const handlePress = useCallback(async () => {
    switch (capability) {
      case "END":
      case "APPROVE_TIMESHEET":
        history.push(`/gigs/${engagementId}`);
        break;
      case "ACCEPT":
        history.push(`/gigs/${engagementId}/accept`);
        break;
      default:
        if (capability) {
          handleAction(capability, actionSpec);
          if (capability === "REQUEST_START") await updateWorkerLocation(true);
        }
    }
  }, [capability, engagementId]);

  const iconName = getActionIconName(
    engagement?.worker?.transportationMethod,
    engagement?.currentState?.name,
    actionSpec?.icon
  );

  if (getWaitingStartState(engagement) === "refresh-location") {
    return (
      <SmallOutlineButton
        title="Refresh Location"
        icon="redo"
        onPress={updateWorkerLocation}
      />
    );
  }

  return !capability || !actionSpec ? null : (
    <SmallOutlineButton
      title={actionSpec.label ?? "-"}
      icon={iconName}
      onPress={handlePress}
      disabled={loading}
    />
  );
};

export default EngagementMainAction;
