import { currency } from "@gigsmart/isomorphic-shared/iso";
import { DataRow, DataRowCard } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import moment from "moment-timezone";
import React, { useMemo } from "react";
import type { statsTab_worker$key } from "./__generated__/statsTab_worker.graphql";

interface Props {
  worker: statsTab_worker$key | null | undefined;
}

const yearsBeforeArr = [0, 1, 2, 3] as const;

export default ({ worker }: Props) => {
  const result = useRelayFragment(
    graphql`
      fragment statsTab_worker on Worker{
        insertedAt
        lifetime: totalEarnings
        sevenDay: totalEarnings(dateFrom: "-P7D")
        thirtyDay: totalEarnings(dateFrom: "-P30D")
        dy0: totalEarnings(dateFrom: "^PY", dateTo: "-P0D")
        dy1: totalEarnings(dateFrom: "-P1Y^PY", dateTo: "-P1Y$PY")
        dy2: totalEarnings(dateFrom: "-P2Y^PY", dateTo: "-P2Y$PY")
        dy3: totalEarnings(dateFrom: "-P3Y^PY", dateTo: "-P3Y$PY")
      }
    `,
    worker
  );
  if (!result) return null;

  // - Load 3y in advance
  // - set the last year to render as the last one the worker had any earnings
  // - if the last year to render matches current year, don't show Lifetime row
  const { showLifetime, startFromYear } = useMemo(() => {
    let startFromYear = moment().format("YYYY");
    yearsBeforeArr.forEach((d) => {
      const hasEarnings = currency.toFloat(result[`dy${d}` as const]) > 0;
      if (hasEarnings) {
        startFromYear = moment()
          .startOf("year")
          .subtract(d, "year")
          .format("YYYY");
      }
    });

    return {
      startFromYear,
      showLifetime: startFromYear !== moment().format("YYYY")
    };
  }, [result]);

  return (
    <DataRowCard testID="stats-tab-content">
      <DataRow
        testID="seven-day-earnings"
        label="Last 7 Days"
        secondaryTitle="Earnings"
        data={currency.humanize(result?.sevenDay)}
      />
      <DataRow
        testID="thirty-day-earnings"
        label="Last 30 Days"
        secondaryTitle="Earnings"
        data={currency.humanize(result?.thirtyDay)}
      />
      {yearsBeforeArr.map((yearsBefore) => {
        const earnings = result[`dy${yearsBefore}` as const];
        const year = moment()
          .startOf("year")
          .subtract(yearsBefore, "year")
          .format("YYYY");

        if (year < startFromYear) return null;
        return (
          <DataRow
            key={year}
            testID="ytd-earnings"
            label={
              yearsBefore === 0 ? `Year to Date (${year})` : `Year ${year}`
            }
            secondaryTitle="Earnings"
            data={currency.humanize(earnings)}
          />
        );
      })}
      {showLifetime && (
        <DataRow
          testID="lifetime-earnings"
          label="Lifetime"
          secondaryTitle="Earnings"
          data={currency.humanize(result?.lifetime)}
        />
      )}
    </DataRowCard>
  );
};
