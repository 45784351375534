import {
  Button,
  Column,
  ContentArea,
  Spacer,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { Ribbon, StyledLink } from "@gigsmart/katana";
import React from "react";
import SetYourselfApart from "./images/SetYourselfApartSvg";

interface Props {
  onClose: () => void;
  onDeny?: () => void;
  onAccept?: () => void;
}

function VerificationIntroductionModal({ onClose, onDeny, onAccept }: Props) {
  const handleEnrollNow = () => {
    onAccept?.();
    onClose();
  };
  const handleDeny = () => {
    onDeny?.();
    onClose();
  };

  return (
    <>
      <Ribbon color="green" label="FREE" />
      <ContentArea>
        <Spacer size="compact" />
        <Text align="center" variant="titleLg" color="primary">
          Get Verified
        </Text>
        <Stack>
          <Text align="center" variant="header" weight="normal">
            Set Yourself Apart
          </Text>
          <ContentArea size="none">
            <Spacer />
            <SetYourselfApart />
          </ContentArea>
          <Spacer size="compact" />
          <Text>
            Make more money, build trust, and stand out from other applicants by
            getting verified.
          </Text>
          <Text>
            To get started, simply enroll in account verification. It's
            completely <Text weight="bold">free</Text> and takes{" "}
            <Text weight="bold">minutes</Text>.
          </Text>
          <Text>
            Background checks and/or Motor Vehicle Record checks will only be
            run after you are hired for a Verified Gig.
          </Text>
          <Column>
            <StyledLink
              center
              fontSize={16}
              openURL="https://gigsmart.com/get-verified"
            >
              Learn more about the GigSmart verification process
            </StyledLink>
            <Spacer />
            <Button
              testID="verification-optin-modal-accept-button"
              label="Enroll in Verification Now"
              onPress={handleEnrollNow}
            />
            <Spacer />
            <Button
              testID="verification-optin-modal-reject-button"
              label="No, Thank You"
              variant="clear"
              onPress={handleDeny}
            />
          </Column>
        </Stack>
      </ContentArea>
    </>
  );
}

export const showVerificationIntroductionModal = ({
  onDeny,
  onAccept
}: Omit<Props, "onClose"> = {}) =>
  showModal({
    eventContext: "Get Verified Modal",
    children: (closeFn) => (
      <VerificationIntroductionModal
        onAccept={onAccept}
        onDeny={onDeny}
        onClose={closeFn}
      />
    )
  });
