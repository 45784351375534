import { ConnectionHandler } from "@gigsmart/relay";
import type { OperationType, RecordSourceSelectorProxy } from "@gigsmart/relay";

export function updateMessageAdded<TResponse extends OperationType["response"]>(
  store: RecordSourceSelectorProxy<TResponse>,
  nextCount: number | ((prevCount: number) => number),
  connectionKey: string
) {
  let localNextCount = nextCount;
  const root = store.getRoot();
  const viewer = root.getLinkedRecord("viewer");
  if (!viewer) return;
  const messagesConnection = ConnectionHandler.getConnection(
    viewer,
    connectionKey
  );
  if (!messagesConnection) return;
  if (typeof localNextCount === "function") {
    localNextCount = localNextCount(
      Number(messagesConnection.getValue("totalCount")) ?? 0
    );
  }
  messagesConnection.setValue(Math.max(localNextCount, 0) || 0, "totalCount");
}
