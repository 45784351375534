/**
 * @generated SignedSource<<0c5077e0a7e6263c2114bb08e85cbf39>>
 * @relayHash 6b86b95a136619cf54b8b74a3525873b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:f1VvIJZqgUopA-d77TKmD3H4_AMQ7nsWHWAgFv-zVBM

import { ConcreteRequest } from 'relay-runtime';
export type MessageType = "ALERT" | "CALL" | "CORPORATE" | "DIRECT_HIRE" | "GIG" | "GIG_POSTED" | "JOB_BOOSTED" | "JOB_POSTING" | "MISCELLANEOUS" | "PAYMENT" | "SILENT_MESSAGE" | "USER_MESSAGE" | "%future added value";
export type notificationIconContainerSubscription$variables = {
  messageTypes?: ReadonlyArray<MessageType> | null | undefined;
};
export type notificationIconContainerSubscription$data = {
  readonly messageAdded: {
    readonly unreadCount: number;
  } | null | undefined;
};
export type notificationIconContainerSubscription = {
  response: notificationIconContainerSubscription$data;
  variables: notificationIconContainerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "messageTypes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "messageTypes",
        "variableName": "messageTypes"
      }
    ],
    "concreteType": "MessageAddedPayload",
    "kind": "LinkedField",
    "name": "messageAdded",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unreadCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "notificationIconContainerSubscription",
    "selections": (v1/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "notificationIconContainerSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:f1VvIJZqgUopA-d77TKmD3H4_AMQ7nsWHWAgFv-zVBM",
    "metadata": {},
    "name": "notificationIconContainerSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "811a6e4dbb4516440edc21e53b6b31bf";

export default node;
