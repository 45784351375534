import {
  type ScreenTop,
  StepWithIndicatorsLayout,
  StepWithTitleLayout,
  type UnitSize
} from "@gigsmart/atorasu";
import { Form, type FormHandlersType } from "@gigsmart/fomu";
import type { IconName } from "@gigsmart/katana/icon";
import React from "react";
import type { ComponentProps, ReactNode, Ref } from "react";
import FomuScreenTop from "../fomu/FomuScreenTop";

type Props = ComponentProps<typeof ScreenTop> &
  ComponentProps<typeof FomuScreenTop> &
  Pick<
    ComponentProps<typeof Form>,
    "onSubmit" | "onChange" | "initialValues"
  > & {
    testID: string;
    title: string;
    titleIcon: IconName;
    description?: string;
    gap?: UnitSize;
    stickyFooter?: "never" | "always" | "auto";
    children?: ReactNode;
    formRef?: Ref<FormHandlersType>;
  };

export default function SurfaceFormScreen({
  testID,
  children,
  title,
  titleIcon,
  description,
  leftAccessory,
  showCancelBtn,
  rightAccessory,
  onBackPress,
  showBackButton,
  dirtyOverride,
  onSubmit,
  onChange,
  initialValues,
  gap,
  stickyFooter,
  formRef
}: Props) {
  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      onChange={onChange}
      initialValues={initialValues}
    >
      <StepWithIndicatorsLayout
        stickyFooter={stickyFooter}
        testID={`${testID}-screen`}
        header={
          <FomuScreenTop
            showBackButton={showBackButton}
            onBackPress={onBackPress}
            rightAccessory={rightAccessory}
            leftAccessory={leftAccessory}
            dirtyOverride={dirtyOverride}
            showCancelBtn={showCancelBtn}
          />
        }
      >
        <StepWithTitleLayout
          icon={titleIcon}
          title={title}
          description={description}
          gap={gap}
        >
          {children}
        </StepWithTitleLayout>
      </StepWithIndicatorsLayout>
    </Form>
  );
}
