/**
 * @generated SignedSource<<6e8187e3a3fde7b7b6666af0cafc5d2b>>
 * @relayHash 34f370adfa605cb5e132b02990f6c18b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:cc48s73JrlNhz2tbgvXdNhoMXs_enlXavY4FgXX7uxo

import { ConcreteRequest } from 'relay-runtime';
export type CallIconQuery$variables = {
  recipientId: string;
};
export type CallIconQuery$data = {
  readonly node: {
    readonly displayName?: string | null | undefined;
    readonly user?: {
      readonly displayName: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CallIconQuery = {
  response: CallIconQuery$data;
  variables: CallIconQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recipientId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "recipientId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "User",
  "abstractKey": "__isUser"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CallIconQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "type": "ConversationParticipant",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CallIconQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ConversationParticipant",
            "abstractKey": null
          },
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:cc48s73JrlNhz2tbgvXdNhoMXs_enlXavY4FgXX7uxo",
    "metadata": {},
    "name": "CallIconQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0d2b33007bc92120d076da11a6a98a74";

export default node;
