import { HighlightedReminder } from "@gigsmart/atorasu";
import type {
  EngagementStateAction,
  EngagementStateName,
  GigStateName
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayInlineFragmentContainer,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import React from "react";
import type { WorkerLatestArrivalTimeReminder_gig$key } from "./__generated__/WorkerLatestArrivalTimeReminder_gig.graphql";

interface Props {
  workerStateName?: EngagementStateName;
  isMultiple?: boolean;
}

export const WorkerLatestArrivalTimeReminder = ({
  startsAt,
  latestArrivalTime,
  isMultiple,
  workerStateName
}: FragmentContainerInnerComponentProps<
  WorkerLatestArrivalTimeReminder_gig$key,
  Props
>) => {
  const computeHeader = () => {
    const isAsap = !startsAt;
    const lat = time.humanize(latestArrivalTime, "hm");

    switch (workerStateName) {
      case "BID_REQUESTED":
      case "BID_REVIEW":
      case "OFFERED": {
        const alreadyStarted = !isAsap && time.isWithinHours(startsAt, 0);
        let prefix = isAsap
          ? "This is an ASAP Shift. "
          : alreadyStarted
            ? "This Shift has already started. "
            : "";
        if (
          workerStateName === "BID_REQUESTED" ||
          workerStateName === "BID_REVIEW"
        ) {
          prefix = `${prefix}If your Bid is accepted, you will be offered the Shift. `;
        }

        return `${prefix}If you accept, you must arrive by ${lat}, or the Organization may cancel you from the Shift without pay at their discretion.`;
      }

      case "SCHEDULED":
      case "EN_ROUTE":
      case "AWAITING_START": {
        const prefix = isAsap ? "This is an ASAP Shift. " : "";
        return `${prefix}You must arrive by ${lat}, or the Organization may cancel you from the Shift without pay at their discretion.`;
      }

      default: {
        // 1. No workerStateName, so we handle like an Available Shift
        // 2. With Hourly Bidding, only Available Shifts can be a multiple Shift
        const isWithinOneHour = !!startsAt && time.isWithinHours(startsAt, 1);
        let reminder = isAsap
          ? isMultiple
            ? "One of the available Shifts is an ASAP Shift. "
            : "This is an ASAP Shift. "
          : "";

        // 3. isWithinOneHour is not necessary.
        if (isAsap || isWithinOneHour) {
          reminder = `${reminder}If your Bid is accepted, you will be offered the Shift. `;
        }

        reminder = `${reminder}You must arrive at this Shift by ${lat}`;
        return !isMultiple && isAsap
          ? `${reminder}, or the Organization may cancel you from the Shift without pay at their discretion.`
          : `${reminder}.`;
      }
    }
  };

  return (
    <HighlightedReminder icon="circle-exclamation" header={computeHeader()} />
  );
};

const VALID_STATES: EngagementStateName[] = [
  "BID_REVIEW",
  "BID_REQUESTED",
  "OFFERED",
  "SCHEDULED",
  "EN_ROUTE",
  "AWAITING_START"
  // If adding more states, make sure to update the computeHeader function
];

const VALID_GIG_STATES: GigStateName[] = ["ACTIVE", "IN_PROGRESS"];

const fragmentSpec = graphql`
  fragment WorkerLatestArrivalTimeReminder_gig on Gig @inline {
    startsAt
    latestArrivalTime
    currentState {
      name
    }
  }
`;

export function showLatestArrivalReminder(
  gigRef?: WorkerLatestArrivalTimeReminder_gig$key | null,
  workerStateName?: EngagementStateName,
  workerStateAction?: EngagementStateAction
) {
  if (!gigRef) return false;

  const gig = readRelayInlineFragment(fragmentSpec, gigRef);

  return (
    !!gig.latestArrivalTime &&
    VALID_GIG_STATES.includes(gig?.currentState?.name) &&
    (!workerStateName ||
      (VALID_STATES.includes(workerStateName) &&
        workerStateAction !== "AUTO_ARRIVE"))
  );
}

export default createRelayInlineFragmentContainer<
  WorkerLatestArrivalTimeReminder_gig$key,
  Props
>(fragmentSpec, WorkerLatestArrivalTimeReminder);
