import {
  Button,
  ContentArea,
  IconCircle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spacer,
  Stack,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { SFCUBranded } from "@gigsmart/feature-flags";
import React from "react";

import BenefitsSVG from "./BenefitsSVG";
import SFCUBrandSVG from "./SFCUBrandSVG";

interface Props {
  onClose: () => void;
}

export default function SFCUBenefitModal({ onClose }: Props) {
  const item = (label: string) => (
    <Row alignItems="flex-start">
      <IconCircle size="tiny" icon="check" color="success" variant="well" />
      <ContentArea size="slim" variant="compact" fill>
        <Text>{label}</Text>
      </ContentArea>
    </Row>
  );

  const content = (
    <Stack size="slim">
      {item("No-fee Everyday Checking")}
      {item("Up to $30 a month in ATM surcharge fee rebates")}
      {item("Discounts on auto and personal loans")}
      {item("High-dividend earning savings accounts")}
      {item("Free identity theft recovery resources")}
    </Stack>
  );

  const footer = (
    <ModalFooter>
      <Button
        testID="sfcu-accept"
        label="Get Started"
        eventTargetName={SFCUBranded.select(
          "Branded Get Started Button",
          "Get Started Button"
        )}
        onPress={() => {
          onClose();
          setTimeout(() => {
            showEmbeddedBrowser({
              url: SFCUBranded.select(
                "https://servicecu.org/everyday-checking-for-gig-workers/?scu_lr=scu6916&scu_rs=0323GIGSMART&utm_source=ad2&utm_medium=gigsmart&utm_campaign=2023_Gigsmart",
                "https://servicecu.org/everyday-checking-for-gig-workers/?scu_lr=scu6916&scu_rs=0323GIGSMART&utm_source=ad1&utm_medium=gigsmart&utm_campaign=2023_Gigsmart"
              )
            });
          }, 300);
        }}
      />
      <Button
        testID="cancel"
        eventTargetName="Cancel Button"
        variant="clear"
        label="No, Thank You"
        onPress={onClose}
      />
    </ModalFooter>
  );

  return SFCUBranded.isEnabled() ? (
    <>
      <SFCUBrandSVG />
      <ModalBody>
        <Spacer />
        <Stack>
          <Text
            testID="modal-title"
            variant="header"
            weight="bold"
            align="center"
            color="primary"
          >
            Join Service Credit Union
          </Text>
          <Text>
            When you open a checking account with Service Credit Union, you can
            maximize your earning potential with benefits such as:
          </Text>
          {content}
        </Stack>
      </ModalBody>
      {footer}
    </>
  ) : (
    <>
      <ModalHeader title="Bank With Confidence" />
      <ModalBody>
        <Stack>
          <BenefitsSVG />
          <Text>
            Maximize your earning potential and peace of mind by joining a
            credit union.
          </Text>
          {content}
          <Text>
            Save time and money so you can get back to working on stuff that
            really matters.
          </Text>
        </Stack>
      </ModalBody>
      {footer}
    </>
  );
}
