import React from "react";
import { View } from "react-native";

import { useStyles } from "../style";
import { StyledText } from "../text";

interface Props {
  color: string;
  textColor: string;
  label: string;
}

export default function Ribbon({ textColor, color, label }: Props) {
  const { styles, theme } = useStyles(({ units }) => ({
    container: {
      width: 75,
      height: 75,
      position: "absolute",
      left: 0,
      top: 0,
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      zIndex: 99
    },
    ribbon: {
      top: -15,
      left: -15,
      padding: units(1),
      width: 100,
      transform: [
        {
          rotate: "-45deg"
        }
      ]
    }
  }));
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.ribbon,
          { backgroundColor: theme.color.getColor(color) }
        ]}
      >
        <StyledText bold center color={textColor} selectable={false}>
          {label}
        </StyledText>
      </View>
    </View>
  );
}

Ribbon.defaultProps = {
  color: "green",
  textColor: "white"
};
