import { Button, ContentArea, Divider, SectionHeader } from "@gigsmart/atorasu";
import React from "react";
import type { NotificationSection } from "./use-notification-section";

interface Props {
  section: NotificationSection;
  onReadAll: () => void;
}

const NotificationSectionHeader = ({ section, onReadAll }: Props) => {
  const showReadAll = section.isNew && section.data && section.data.length > 0;

  return (
    <ContentArea variant="none" size="none" color="surface">
      <SectionHeader
        icon="bell"
        label={section.title}
        iconSpacing="medium"
        right={
          showReadAll && (
            <Button
              testID="mark-all-as-read-btn"
              label="Mark all as Read"
              variant="clear"
              outline
              size="small"
              onPress={onReadAll}
            />
          )
        }
      />
      <Divider />
    </ContentArea>
  );
};

export default NotificationSectionHeader;
