import React, { type ComponentProps } from "react";

import { theme } from "../style";
import Spacer from "./spacer";

type Props = ComponentProps<typeof Spacer> & {
  units?: number;
  includeBottomSpace?: boolean;
};

/** @deprecated use the one from "@gigsmart/atorasu" */
export default function FooterSpacer({
  units = 4,
  includeBottomSpace = false,
  ...props
}: Props) {
  const bottomSpaceUnits = theme.pixelsToUnits(theme.metric.bottomSpace());
  const computedUnits = (includeBottomSpace ? bottomSpaceUnits : 0) + units;
  return (
    <Spacer units={Math.max(computedUnits, bottomSpaceUnits)} {...props} />
  );
}
