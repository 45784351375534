import {
  Button,
  Column,
  ContentArea,
  List,
  Row,
  Screen,
  Spacer,
  Stack,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit, Validator } from "@gigsmart/fomu";
import { Carousel } from "@gigsmart/isomorphic-shared/media";
import type { FileUpload } from "@gigsmart/isomorphic-shared/media/media-picker";
import {
  commitBatchMutations,
  getConnectionNodes,
  graphql,
  useFetchQueryResult,
  useRelayMutation,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { compact, differenceBy } from "lodash";
import React, { useState } from "react";
import FomuCommentInput from "../../../fomu/inputs/FomuTextInput";
import StepHeader from "../../../shared/Stepper/StepHeader";
import Nav from "./Nav";
import type { EvidenceStepAddPhotoMutation } from "./__generated__/EvidenceStepAddPhotoMutation.graphql";
import type { EvidenceStepDirectHireClaimQuery } from "./__generated__/EvidenceStepDirectHireClaimQuery.graphql";
import type { EvidenceStepRemovePhotoMutation } from "./__generated__/EvidenceStepRemovePhotoMutation.graphql";
import type { EvidenceStepUpdateDirectHireClaimMutation } from "./__generated__/EvidenceStepUpdateDirectHireClaimMutation.graphql";
import type { DirectHireClaimsStepper } from "./index";

export interface ClaimPhotoType {
  readonly id: string;
  readonly url: string;
}

export default function EvidenceStep() {
  const { gotoStep, stepsData } = useStepper<{}, DirectHireClaimsStepper>();
  const [result] = useFetchQueryResult<EvidenceStepDirectHireClaimQuery>(
    graphql`
      query EvidenceStepDirectHireClaimQuery($id: ID!) {
        node(id: $id) {
          ... on DirectHireClaim {
            directHireType
            comments
            photos(first: 5) {
              edges {
                node {
                  id
                  url
                }
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: "store-or-network", variables: { id: stepsData?.id ?? "" } }
  );
  const [commit] =
    useRelayMutation<EvidenceStepUpdateDirectHireClaimMutation>(graphql`
      mutation EvidenceStepUpdateDirectHireClaimMutation(
        $input: UpdateDirectHireClaimInput!
      ) {
        updateDirectHireClaim(input: $input) {
          directHireClaim {
            id
            comments
          }
        }
      }
    `);

  const [addPhoto] =
    useRelayMutationPromise<EvidenceStepAddPhotoMutation>(graphql`
      mutation EvidenceStepAddPhotoMutation(
        $input: AddDirectHireClaimPhotoInput!
      ) {
        addDirectHireClaimPhoto(input: $input) {
          newDirectHireClaimPhotoEdge {
            node {
              id
              url
            }
          }
        }
      }
    `);
  const [removePhoto] =
    useRelayMutationPromise<EvidenceStepRemovePhotoMutation>(graphql`
      mutation EvidenceStepRemovePhotoMutation(
        $input: RemoveDirectHireClaimPhotoInput!
      ) {
        removeDirectHireClaimPhoto(input: $input) {
          removedDirectHireClaimPhotoId @deleteRecord
        }
      }
    `);
  const isHire = result?.node?.directHireType === "HIRE";
  const [photos, setPhotos] = useState<ClaimPhotoType[]>(
    getConnectionNodes(result?.node?.photos)
  );

  const handleAddPhoto = ({ id, url }: FileUpload) => {
    const newPhoto: ClaimPhotoType = { id, url };
    if (photos?.length < 5) {
      setPhotos(photos ? [...photos, newPhoto] : [newPhoto]);
    }
  };

  const handleRemovePhoto = (index: number) => {
    const newValue = photos?.filter((_d, idx) => idx !== index);
    if (newValue) setPhotos(newValue);
  };

  const handleSave: FomuSubmitFn = async ({ values }) => {
    if (photos?.length) {
      const initialPhotos = getConnectionNodes(result?.node?.photos);
      const toRemove = differenceBy(initialPhotos, photos, (d) => d.id)?.map(
        (photo) => ({ directHireClaimPhotoId: photo?.id })
      );
      const toAdd = differenceBy(photos, initialPhotos, (d) => d.id)?.map(
        (photo) => ({
          directHireClaimId: stepsData?.id ?? "",
          userFileId: photo?.id
        })
      );
      const { promises, errors } = commitBatchMutations(
        compact([...toRemove, ...toAdd]),
        (d) => d,
        (input) => ("directHireClaimId" in input ? addPhoto : removePhoto)
      );
      await Promise.all(promises);
      if (errors.length) {
        errors.forEach((err) =>
          toast.error(err.message || "Error Saving Photos")
        );
        return;
      }
    }
    commit(
      {
        input: {
          directHireClaimId: stepsData?.id ?? "",
          comments: values?.comment
        }
      },
      {
        onSuccess: () =>
          gotoStep(stepsData?.returnTo ?? "information", {
            ...stepsData,
            returnTo: null,
            dirty: true
          }),
        onError: (err) => toast.error(err.message || "Error Saving Evidence")
      }
    );
  };
  return (
    <Screen testID="evidence-step-screen">
      <Form
        onSubmit={handleSave}
        initialValues={{ comment: result?.node?.comments }}
      >
        <Column fill justifyContent="space-between">
          <Nav returnTo="information" />
          <Stack>
            <StepHeader
              name={`Direct ${isHire ? "Hire" : "Payment"} Support`}
              note={`Please be as detailed as possible to assist the Requester in reviewing your Direct ${
                isHire ? "Hire" : "Payment"
              } request. Uploading images or screenshots with supporting evidence of Requester Direct ${
                isHire ? "Hire" : "Payment"
              } will help expedite your review.`}
            />{" "}
            <Surface>
              <ContentArea>
                <Stack>
                  <Text weight="semibold">
                    Why are you submitting Direct {isHire ? "Hire" : "Payment"}{" "}
                    support?
                  </Text>
                  <FomuCommentInput
                    name="comment"
                    min={25}
                    max={1000}
                    multiline
                    validates={[
                      Validator.presence(),
                      Validator.noEmailAddressesOrGmail(),
                      Validator.noPhoneNumbers()
                    ]}
                    placeholder={`Provide as much detail as possible to explain why you are submitting Direct ${
                      isHire ? "Hire" : "Payment"
                    } evidence.`}
                  />
                </Stack>
              </ContentArea>
            </Surface>
            <Surface>
              <ContentArea>
                <Stack>
                  <Text weight="semibold">
                    Add Supporting Evidence Images ({photos?.length})
                  </Text>
                  <ContentArea size="none">
                    <List variant="bullet">
                      <Text weight="semibold">
                        Images will NOT be shared with the Requester.
                      </Text>
                      <Text weight="semibold">
                        Please do NOT submit screenshots of the Get Gigs app.
                      </Text>
                    </List>
                  </ContentArea>
                  <Text>
                    Examples of Direct {isHire ? "Hire" : "Payment"} can
                    include:{" "}
                    {isHire
                      ? "an offer letter, pay stub, 1099 form, schedule, "
                      : "proof of payment (through Venmo, Cash App, Paypal, etc.), "}
                    direct correspondence with the business to work outside of
                    the Get Gigs app.
                  </Text>
                </Stack>
              </ContentArea>
              <Row>
                <Spacer horizontal size="compact" />
                <Carousel
                  sources={photos}
                  showHeader={(photos?.length ?? 0) < 5}
                  onPhotoPick={handleAddPhoto}
                  onPhotoRemove={handleRemovePhoto}
                  fill
                  thumbWidth={60}
                  isDeletable
                />
              </Row>
              <Spacer />
            </Surface>
          </Stack>
          <FormSubmit>
            {({ invalid, submit }) => (
              <ContentArea>
                <Button
                  testID="save-changes-btn"
                  disabled={invalid || photos?.length === 0}
                  label="Save Changes"
                  onPress={submit}
                />
              </ContentArea>
            )}
          </FormSubmit>
        </Column>
      </Form>
    </Screen>
  );
}
