import { Button } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  graphql,
  useRelayMutation,
  useRelaySubscription
} from "@gigsmart/relay";
import React from "react";
import type { MarkAllAsReadButtonMutation } from "./__generated__/MarkAllAsReadButtonMutation.graphql";
import type { MarkAllAsReadButtonSubscription } from "./__generated__/MarkAllAsReadButtonSubscription.graphql";

interface Props {
  visible?: boolean;
}

export default function MarkAllAsReadButton({ visible }: Props) {
  const currentUser = useCurrentUser();
  useRelaySubscription<MarkAllAsReadButtonSubscription>(
    graphql`
      subscription MarkAllAsReadButtonSubscription($userId: ID!) {
        userMessageStatsUpdated(userId: $userId) {
          user {
            id
            userMessageStats {
              unreadCount
            }
          }
        }
      }
    `,
    { userId: currentUser?.id ?? "" }
  );
  const [commit] = useRelayMutation<MarkAllAsReadButtonMutation>(
    graphql`
      mutation MarkAllAsReadButtonMutation($input: MarkAllAsReadInput!) {
        markAllAsRead(input: $input) {
          conversationParticipantEdges {
            node {
              id
              readUntil
            }
          }
        }
      }
    `,
    {
      updater: (store) => {
        const root = store.getRoot();
        const viewer = root.getLinkedRecord("viewer");
        const stats = viewer?.getLinkedRecord("userMessageStats");
        stats?.setValue(0, "unreadCount");
      }
    }
  );
  const handlePress = () => {
    commit({ input: { userId: currentUser?.id ?? "" } });
  };
  if (!visible) return null;
  return (
    <Button
      testID="mark-all-as-read-btn"
      size="small"
      variant="clear"
      label="Mark all as Read"
      icon="eye"
      onPress={handlePress}
    />
  );
}
