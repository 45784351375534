import { Button, TextInput } from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import React, { type ComponentProps, useState } from "react";

type Props = FormFieldOptions &
  Omit<ComponentProps<typeof TextInput>, "testID"> & {
    testID?: string;
  };

export default function FomuPasswordInput({
  testID,
  name,
  validates,
  label,
  placeholder,
  onKeyPress,
  ...rest
}: Props) {
  const [revealPassword, toggleRevealPassword] = useState(false);
  const [showRevealPasswordControls, toggleShowRevealPasswordControls] =
    useState(false);
  const { errorMessage, value, setValue, showErrors } = useFormField({
    name,
    validates
  });

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      rightAccessory={
        showRevealPasswordControls && (
          <Button
            eventTargetName="Password Visibility Toggle Button"
            testID={`${name}-visibility-toggle`}
            onPress={() => toggleRevealPassword((val) => !val)}
            label={revealPassword ? "HIDE" : "SHOW"}
            size="xsmall"
            variant="clear"
          />
        )
      }
      testID={testID ?? `${name}-input`}
      errors={errorMessage}
      value={value ?? ""}
      secureTextEntry={!revealPassword}
      onChangeText={setValue}
      onBlur={showErrors}
      onKeyPress={(e) => {
        onKeyPress?.(e);
        if (!showRevealPasswordControls) {
          toggleShowRevealPasswordControls(true);
        }
      }}
      autoCapitalize="none"
      autoCorrect={false}
      {...rest}
    />
  );
}
