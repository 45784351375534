import {
  Column,
  ContentArea,
  Divider,
  Icon,
  IconText,
  Pressable,
  Row,
  Spacer,
  Stack,
  Surface,
  Text,
  Well,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { WorkerEngagementView } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useHistory } from "@gigsmart/kaizoku";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React from "react";
import CallIcon from "../../shared/CallIcon";
import { Participants } from "../Participants";
import RequesterMoreMenu from "./RequesterMoreMenu";
import ShiftInfo from "./ShiftInfo";
import WorkerInfo from "./WorkerInfo";
import type { RequesterConversationHeader_conversationLike$key } from "./__generated__/RequesterConversationHeader_conversationLike.graphql";

export function RequesterConversationHeader({
  type,
  worker: orgReqWorker,
  engagement,
  engagements,
  participants,
  participant,
  result,
  organization
}: FragmentContainerInnerComponentProps<RequesterConversationHeader_conversationLike$key>) {
  const history = useHistory();
  const isSmUp = useMatchesViewport(({ size }) => size.small.up);
  const worker = orgReqWorker ?? engagement?.worker;
  const workerParticipant = participants?.edges?.find(
    (p) => p?.node?.user?.id === worker?.id
  );
  const currentUser = useCurrentUser();
  const hasManyOrgs = (participant?.user?.organizations?.totalCount ?? 0) > 1;
  const isNotCurrentOrg =
    hasManyOrgs && organization?.id !== currentUser?.organization?.id;
  const isCallable = workerParticipant?.node?.capabilities?.includes("CALL");
  return (
    <Surface topRadius="none" bottomRadius="none">
      {type === "ShiftGroupConversation" && (
        <Well color="primary">
          <IconText
            spacing="medium"
            icon="messages"
            color="primary"
            size="small"
          >
            <Text
              weight="bold"
              color="primary"
              testID="shift-group-chat-participants"
            >
              Shift Group Chat •{" "}
              <Text weight="normal" color="primary">
                {pluralize((participants?.totalCount ?? 0) + 1, "Participant")}
              </Text>
            </Text>
          </IconText>
        </Well>
      )}
      <ContentArea
        size={type === "ShiftGroupConversation" ? "standard" : "compact"}
      >
        <Row>
          <Stack horizontal alignItems="center" justifyContent="center" fill>
            <Column fill justifyContent="center">
              {(type === "OrganizationWorkerConversation" ||
                type === "EngagementConversation") && (
                <WorkerInfo
                  worker={worker}
                  hasManyOrgs={hasManyOrgs}
                  organization={organization}
                />
              )}
              {type === "ShiftGroupConversation" && (
                <ShiftInfo fragmentRef={result ?? null} />
              )}
            </Column>
            {isCallable && (
              <CallIcon recipientId={workerParticipant?.node?.id ?? ""} />
            )}
            {type !== "ShiftGroupConversation" && <Divider dir="y" fullWidth />}
            <Participants
              participants={participants}
              engagements={engagements}
            />
            {isSmUp && <Divider dir="y" fullWidth />}
            {isSmUp && <Spacer size="none" horizontal />}
          </Stack>
          <Row alignItems="center">
            <RequesterMoreMenu
              fragmentRef={result}
              isActive
              isNotCurrentOrg={isNotCurrentOrg}
            />
          </Row>
        </Row>
      </ContentArea>
      {type === "OrganizationWorkerConversation" && !isNotCurrentOrg && (
        <>
          <Spacer size="compact" />
          <Divider />
          <ContentArea size="compact">
            <Pressable
              testID="participants-modal-btn"
              onPress={() =>
                history.push(
                  WorkerEngagementView.select(
                    `/workers/${worker?.id}`,
                    `/workers/${worker?.id}/engagements/summary`
                  )
                )
              }
              eventEntityType="Participants"
              eventTargetName="ParticipantModalButton"
            >
              <Stack
                horizontal
                alignItems="center"
                justifyContent="center"
                size="compact"
              >
                <Icon
                  name="business-time"
                  color="primary"
                  size="small"
                  variant="solid"
                />
                <Text color="primary" weight="bold">
                  View Worker Shifts
                </Text>
                <Icon
                  name="chevron-right"
                  color="primary"
                  size="small"
                  variant="solid"
                />
              </Stack>
            </Pressable>
          </ContentArea>
        </>
      )}
    </Surface>
  );
}

export default createRelayFragmentContainer<RequesterConversationHeader_conversationLike$key>(
  graphql`
    fragment RequesterConversationHeader_conversationLike on ConversationLike
    @argumentDefinitions(isWorker: { type: "Boolean", defaultValue: false }) {
      type: __typename
      ...ShiftInfo_conversation
      participant {
        user {
          ... on Requester {
            organizations(first: 0) {
              totalCount
            }
          }
        }
      }
      participants(first: 30) {
        totalCount
        edges {
          node {
            id
            capabilities
            user {
              __typename
              id
              displayName
              profilePhoto {
                url
              }
              ... on Requester {
                displayName
                primaryOrganization {
                  name
                }
              }
              ... on Worker {
                profilePhoto {
                  url
                }
                isFavorite @skip(if: $isWorker)
                groupAssociations(first: 0) @skip(if: $isWorker) {
                  totalCount
                }
              }
            }
          }
        }
      }
      ...RequesterMoreMenu_conversationLike
      ... on EngagementConversation {
        id
        engagement {
          worker {
            id
            displayName
            profilePhoto {
              url
            }
            isFavorite @skip(if: $isWorker)
            groupAssociations(first: 0) @skip(if: $isWorker) {
              totalCount
            }
          }
        }
      }
      ... on OrganizationWorkerConversation {
        id
        worker {
          id
          displayName
          profilePhoto {
            url
          }
          isFavorite @skip(if: $isWorker)
          groupAssociations(first: 0) @skip(if: $isWorker) {
            totalCount
          }
        }
        organization {
          id
          name
        }
        engagements(
          first: 50
          query: "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
        ) {
          edges {
            node {
              contactNumber
              gig {
                primaryContact {
                  id
                  displayName
                }
              }
              capabilities {
                type
                restrictedBy {
                  message
                }
                expiresAt
                status
              }
            }
          }
        }
      }
    }
  `,
  RequesterConversationHeader
);
