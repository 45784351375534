import { GridList, type GridListProps } from "@gigsmart/katana";
import type { GraphQLTaggedNode, KeyType, QuerySpec } from "@gigsmart/relay";
import React from "react";
import useInfiniteList, { type Options } from "./use-infinite-list";

export type Props<Q extends QuerySpec, TKey extends KeyType, T> = Omit<
  GridListProps<T>,
  "data"
> &
  Options<Q, TKey, T> & {
    fragmentInput: GraphQLTaggedNode;
    parentRef: TKey | null | undefined;
    testID: string;
  };

const InfiniteGridList = <Q extends QuerySpec, TKey extends KeyType, T>({
  fragmentInput,
  parentRef,
  getData,
  pageSize,
  refetchDebounce,
  refetchVars,
  renderEmptyView,
  renderFooterView,
  ...props
}: Props<Q, TKey, T>) => {
  const listProps = useInfiniteList<Q, TKey, T>(fragmentInput, parentRef, {
    getData,
    pageSize,
    refetchDebounce,
    refetchVars,
    renderEmptyView,
    renderFooterView
  });

  return <GridList {...listProps} {...props} />;
};

export default InfiniteGridList;
