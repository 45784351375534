import {
  Column,
  Stack,
  Surface,
  Text,
  Well,
  humanizeDate,
  humanizeDateTime
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { UserMessageContext_userMessage$key } from "./__generated__/UserMessageContext_userMessage.graphql";

export const UserMessageContext = ({
  context,
  author
}: FragmentContainerInnerComponentProps<UserMessageContext_userMessage$key>) => {
  const currentUser = useCurrentUser();
  if (context?.__typename !== "Gig") return null;
  const user =
    currentUser?.__typename === "OrganizationRequester"
      ? currentUser?.requester
      : currentUser;
  const isSender = user?.id === author?.id;
  const messageDirection = isSender ? "flex-end" : "flex-start";
  return (
    <Surface variant="flat" fade>
      <Well
        justifyContent={messageDirection}
        color="highlight"
        size="medium"
        fill
      >
        <Stack horizontal size="compact" fill>
          <Column fill>
            <Text numberOfLines={1}>
              <Text color="highlight" weight="bold">
                Shift:{" "}
              </Text>
              <Text testID="bulk-message-context-title" color="highlight">
                {context?.name}
              </Text>
            </Text>
          </Column>
        </Stack>
        <Stack horizontal size="compact" fill>
          <Text color="highlight" weight="bold">
            Date:
          </Text>
          <Text testID="bulk-message-context-date" color="highlight">
            {context?.startsAt
              ? humanizeDateTime({ startsAt: context?.startsAt, size: "lg" })
              : `${humanizeDate({
                  startsAt: context.actualStartsAt,
                  size: "lg",
                  showDayOfWeek: true
                })} ASAP`}
          </Text>
        </Stack>
      </Well>
    </Surface>
  );
};

export default createRelayFragmentContainer<UserMessageContext_userMessage$key>(
  graphql`
    fragment UserMessageContext_userMessage on UserMessage {
      author {
        __typename
        id
      }
      context {
        __typename
        ... on Gig {
          name
          startsAt
          actualStartsAt
        }
      }
    }
  `,
  UserMessageContext
);
