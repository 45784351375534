import {
  Icon,
  type IconName,
  ScreenTop,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { windowInsets } from "@gigsmart/atorasu/quarks/SafeArea";
import { useStyles } from "@gigsmart/atorasu/style";
import { MarkdownText, StyledButton, StyledLink } from "@gigsmart/katana";
import React, { type ReactNode } from "react";
import { View } from "react-native";

interface Props {
  icon: IconName;
  testID?: string;
  title: string;
  text?: string;
  faqLink?: string;
  children?: ReactNode;
  button?: {
    testID?: string;
    title: string;
    onPress: () => void;
  };
}

export default function RequiredActionGuide({
  icon,
  testID,
  title,
  text,
  faqLink,
  children,
  button
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      container: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: 30 + windowInsets.top,
        paddingBottom: 30 + windowInsets.bottom,
        paddingHorizontal: 35
      },
      topContainer: {
        alignItems: "center"
      },
      icon: {
        fontSize: 35,
        color: getColor("primary", "fill"),
        padding: 50
      },
      title: {
        lineHeight: 34 * 1.4,
        fontSize: 34,
        color: getColor("primary", "fill")
      },
      textContainer: {
        marginTop: getUnits(10)
      },
      text: {
        textAlign: "center",
        fontSize: 24,
        lineHeight: 24 * 1.4,
        color: getColor("primary", "fill"),
        width: "50%"
      },
      link: {
        color: getColor("highlight", "fill")
      }
    }),
    []
  );
  return (
    <>
      <ScreenTop />
      <View style={styles.container} testID={testID ?? "required-action-guide"}>
        <View style={styles.topContainer}>
          <Icon name={icon} style={styles.icon} variant="solid" />
          <Text
            variant="titleLg"
            color="primary"
            align="center"
            style={styles.title}
          >
            {title}
          </Text>
          <View style={styles.textContainer}>
            <MarkdownText style={styles.text} noMargin>
              {text ?? ""}
            </MarkdownText>
          </View>
          {!!faqLink && <Spacer size="large" />}
          {!!faqLink && (
            <StyledLink openURL={faqLink}>
              Frequently Asked Questions
            </StyledLink>
          )}
        </View>
        {children}
        {button && (
          <StyledButton
            testID={button.testID}
            primary
            title={button.title}
            onPress={button.onPress}
          />
        )}
      </View>
    </>
  );
}

RequiredActionGuide.defaultProps = {
  icon: "bell"
};
