/**
 * @generated SignedSource<<3520fdba7af38ac1b3f38dfaadb5bd6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalDocument_document$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useLegalDocument_document">;
  readonly " $fragmentType": "LegalDocument_document";
};
export type LegalDocument_document$key = {
  readonly " $data"?: LegalDocument_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LegalDocument_document",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useLegalDocument_document"
    }
  ],
  "type": "LegalDocument",
  "abstractKey": null
};

(node as any).hash = "eaafd5bcc622cfe4829d67b8abbf1049";

export default node;
