import {
  graphql,
  useRelayFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import React, { createContext, useContext, type ReactNode } from "react";
import type {
  useEngagementData$data,
  useEngagementData$key
} from "./__generated__/useEngagementData.graphql";
import type { useEngagementDataSubscription } from "./__generated__/useEngagementDataSubscription.graphql";
import { GigDataProvider } from "./useGigData";
import { WorkerDataProvider } from "./useWorkerData";

export type EngagementData = useEngagementData$data;
const EngagementContext = createContext<EngagementData | null | undefined>(
  null
);
const EngagementHeaderShownContext = createContext<boolean>(true);

export function EngagementDataProvider({
  children,
  engagement: engagementRef,
  hasBillingInfo,
  showEngagementHeader = true
}: {
  children: ReactNode;
  engagement: useEngagementData$key | null | undefined;
  hasBillingInfo: boolean;
  showEngagementHeader?: boolean;
}) {
  const engagement = useRelayFragment(
    graphql`
      fragment useEngagementData on Engagement
      @argumentDefinitions(hasBillingInfo: { type: "Boolean!" }) {
        id
        paymentInfo @skip(if: $hasBillingInfo) {
          billableDuration
          basePay
          payRate
          serviceFees {
            hourlyRate
          }
        }
        billingInfo @include(if: $hasBillingInfo) {
          billableDuration
          payRate
        }
        currentState {
          name
          transitionedAt
        }
        gig {
          name
          ...useGigData
        }
        worker {
          ...useWorkerData
        }
      }
    `,
    engagementRef
  );

  const engagementId = engagement?.id ?? "";
  useRelaySubscription<useEngagementDataSubscription>(
    graphql`
      subscription useEngagementDataSubscription(
        $engagementId: ID!
        $hasBillingInfo: Boolean!
      ) {
        engagementUpdated(engagementId: $engagementId) {
          engagement {
            paymentInfo @skip(if: $hasBillingInfo) {
              billableDuration
              basePay
              payRate
              serviceFees {
                hourlyRate
              }
            }
            billingInfo @include(if: $hasBillingInfo) {
              billableDuration
              payRate
            }
            currentState {
              name
              transitionedAt
            }
          }
        }
      }
    `,
    { engagementId, hasBillingInfo },
    { subscribe: !!engagementId }
  );

  return (
    <GigDataProvider gig={engagement?.gig ?? null}>
      <WorkerDataProvider worker={engagement?.worker ?? null}>
        <EngagementContext.Provider value={engagement}>
          <EngagementHeaderShownContext.Provider value={showEngagementHeader}>
            {children}
          </EngagementHeaderShownContext.Provider>
        </EngagementContext.Provider>
      </WorkerDataProvider>
    </GigDataProvider>
  );
}

export const useEngagementData = () => useContext(EngagementContext);
export const useEngagementHeaderShown = () =>
  useContext(EngagementHeaderShownContext);
