/**
 * @generated SignedSource<<84db2360c625f697fdb34aa81bb4c682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NegotiableGigField = "STARTS_AT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EngagementInfoRow_engagement$data = {
  readonly gig: {
    readonly name: string | null | undefined;
    readonly negotiable: ReadonlyArray<NegotiableGigField> | null | undefined;
    readonly organization: {
      readonly logoUrl: string | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly requester: {
      readonly displayName: string | null | undefined;
      readonly profilePhoto: {
        readonly url: string | null | undefined;
      } | null | undefined;
    };
    readonly startsAt: string | null | undefined;
  };
  readonly " $fragmentType": "EngagementInfoRow_engagement";
};
export type EngagementInfoRow_engagement$key = {
  readonly " $data"?: EngagementInfoRow_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"EngagementInfoRow_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EngagementInfoRow_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "negotiable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Requester",
          "kind": "LinkedField",
          "name": "requester",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profilePhoto",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "4dd42772f0b3ed3db92fac1fe13ba81f";

export default node;
