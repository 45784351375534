/**
 * @generated SignedSource<<f42174fa51e25dec49abbb1bbc5adb2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type walletTabs_worker$data = {
  readonly walletBalance: string;
  readonly " $fragmentSpreads": FragmentRefs<"statsTab_worker">;
  readonly " $fragmentType": "walletTabs_worker";
};
export type walletTabs_worker$key = {
  readonly " $data"?: walletTabs_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"walletTabs_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "walletTabs_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "walletBalance",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "statsTab_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "6b64b65aed72b0c4b906dda6efd788fa";

export default node;
