import * as React from "react";
import { View } from "react-native";

import {
  type StylesProps,
  ViewportRenderer,
  stylesStubs,
  withStyles
} from "../style";
import TabBar, { type Tab } from "./tab-bar";

type Props = StylesProps & {
  topTabs?: Tab[];
  bottomTabs: Tab[];
};

/** @deprecated */
@withStyles(({ color, font, units, metric }) => ({
  sideNavStyle: {
    height: "100%",
    borderRightWidth: 1,
    borderColor: color.neutralLight,
    zIndex: 20
  },
  barsContainer: {
    flex: 1,
    justifyContent: "space-between",
    width: "72px",
    backgroundColor: color.white
  },
  label: {
    fontSize: font.size.small
  },
  tabButton: {
    // FIXME: Check in the requester app
    justifyContent: "center",
    minHeight: 50 + Math.max(metric.statusBarHeight() - 4, 0),
    paddingTop: Math.max(metric.statusBarHeight() - 4, 0)
  }
}))
export default class SideNav extends React.PureComponent<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  render() {
    const { styles, theme, topTabs, bottomTabs } = this.props;

    return (
      <ViewportRenderer
        renderableBreakpoints={[
          "@desktopWide",
          "@tablet",
          "@desktop",
          "@tabletDown"
        ]}
      >
        <View style={styles.sideNavStyle}>
          <View style={styles.barsContainer}>
            {!!topTabs && (
              <TabBar
                activeColor={theme.color.white}
                activeFontColor={theme.color.blue}
                inactiveFontColor={theme.color.neutralDark}
                styleOverrides={{
                  tabButton: styles.tabButton,
                  sectionContainer: {
                    justifyContent: "flex-start"
                  },
                  label: styles.label
                }}
              >
                {topTabs}
              </TabBar>
            )}
            <TabBar
              bottomTabs
              activeColor={theme.color.white}
              activeFontColor={theme.color.blue}
              inactiveFontColor={theme.color.neutralDark}
              styleOverrides={{
                sectionContainer: {
                  justifyContent: "flex-end"
                },
                label: styles.label
              }}
            >
              {bottomTabs}
            </TabBar>
          </View>
        </View>
      </ViewportRenderer>
    );
  }
}
