import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import { createRelayNodeContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { WorkerParamList } from "../../navigation/types";
import type { engagementDetailsQuery } from "./__generated__/engagementDetailsQuery.graphql";
import type { engagementDetailsSubscription } from "./__generated__/engagementDetailsSubscription.graphql";
import type { engagementDetailsWorker_engagement$key } from "./__generated__/engagementDetailsWorker_engagement.graphql";
import EngagementDetailsContent from "./content";

type Props = AppScreenProps<WorkerParamList, "ShiftDetails" | "ProjectDetails">;

export default createRelayNodeContainer<
  engagementDetailsWorker_engagement$key,
  engagementDetailsQuery,
  engagementDetailsSubscription,
  Props,
  { engagementId: string }
>(
  ({ result: engagement }) => {
    const { restrictions } = useCurrentUser() ?? {};
    const isRestricted = !!restrictions?.totalCount;
    const stateName = engagement?.currentState?.name;
    if (
      !stateName ||
      (isRestricted && stateName !== "PENDING_TIMESHEET_APPROVAL")
    ) {
      return <Redirect back />;
    }

    return <EngagementDetailsContent engagementRef={engagement} />;
  },
  {
    fragment: graphql`
      fragment engagementDetailsWorker_engagement on Engagement {
        currentState {
          name
        }
        ...content_engagement
      }
    `,
    query: graphql`
      query engagementDetailsQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ...engagementDetailsWorker_engagement
        }
      }
    `,
    subscription: graphql`
      subscription engagementDetailsSubscription($engagementId: ID!) {
        engagementUpdated(engagementId: $engagementId) {
          engagement {
            ...engagementDetailsWorker_engagement
          }
        }
      }
    `,
    variables: ({ route }) => ({
      engagementId: route.params.id
    }),
    getQueryFragmentRef: (data) => data?.node,
    getSubscriptionFragmentRef: (data) => data?.engagementUpdated?.engagement,
    fetchPolicy: "network-only"
  }
);
