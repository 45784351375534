/**
 * @generated SignedSource<<43927dcb6d7796131703dd1a6595dcac>>
 * @relayHash fdbaa312cbf78f0ba996fdad7b78a03b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:HOGJNHMaIY8QwtlU4jdeOOhsTYg5yyzQwh-81kMghVQ

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigPositionStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type seriesEngagementsActionsQuery$variables = {
  engagementsQuery: string;
  id: string;
  positionsQuery: string;
};
export type seriesEngagementsActionsQuery$data = {
  readonly series: {
    readonly boosted?: boolean;
    readonly hasMultipleLocations?: boolean;
    readonly nextEngagement?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly gig: {
            readonly gigType: GigType | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCardBody_gig">;
          };
          readonly workerDistance: number | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"seriesEngagementContent_engagement">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly requester?: {
      readonly displayName: string | null | undefined;
    };
    readonly " $fragmentSpreads": FragmentRefs<"engagementActionTable_series">;
  } | null | undefined;
  readonly viewer: {
    readonly positions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly status: GigPositionStatus;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"usePendingOfferCount_worker">;
  } | null | undefined;
};
export type seriesEngagementsActionsQuery = {
  response: seriesEngagementsActionsQuery$data;
  variables: seriesEngagementsActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagementsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "positionsQuery"
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    (v3/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "positionsQuery"
    }
  ],
  "concreteType": "WorkerGigPositionsConnection",
  "kind": "LinkedField",
  "name": "positions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerGigPositionsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerGigPosition",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "engagementsQuery"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boosted",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasMultipleLocations",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v7/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v17 = [
  (v14/*: any*/)
],
v18 = {
  "_neq": "PROJECT"
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 90
  },
  (v7/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v22 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v4/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v25 = [
  (v10/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "seriesEngagementsActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "usePendingOfferCount_worker"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "series",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": [
                  (v7/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "engagementActionTable_series"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Requester",
                "kind": "LinkedField",
                "name": "requester",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "nextEngagement",
                "args": (v11/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "seriesEngagementContent_engagement"
                          },
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "WorkerShiftCardBody_gig"
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "GigSeries",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "seriesEngagementsActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "allOffers",
                "args": [
                  (v16/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "OFFERED",
                          "BID_REQUESTED"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\",\"BID_REQUESTED\"]}})"
              },
              {
                "alias": "projectOffers",
                "args": [
                  (v16/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_eq": "OFFERED"
                      },
                      "gigType": {
                        "_eq": "PROJECT"
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_eq\":\"OFFERED\"},\"gigType\":{\"_eq\":\"PROJECT\"}})"
              },
              {
                "alias": "pendingCount",
                "args": [
                  (v16/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "OFFERED"
                        ]
                      },
                      "gigType": (v18/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              },
              {
                "alias": "bidRequestsCount",
                "args": [
                  (v16/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "BID_REQUESTED"
                        ]
                      },
                      "gigType": (v18/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"BID_REQUESTED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              },
              (v5/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "series",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v19/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableActions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "estimatedPayment",
                            "plural": false,
                            "selections": [
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "latestArrivalTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigState",
                                "kind": "LinkedField",
                                "name": "currentState",
                                "plural": false,
                                "selections": (v22/*: any*/),
                                "storageKey": null
                              },
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v19/*: any*/),
                "filters": [
                  "query"
                ],
                "handle": "connection",
                "key": "engagementActionTable_series_engagements",
                "kind": "LinkedHandle",
                "name": "engagements"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Requester",
                "kind": "LinkedField",
                "name": "requester",
                "plural": false,
                "selections": (v25/*: any*/),
                "storageKey": null
              },
              {
                "alias": "nextEngagement",
                "args": (v11/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Place",
                                "kind": "LinkedField",
                                "name": "place",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "streetAddress",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  (v16/*: any*/)
                                ],
                                "concreteType": "GigSkillsConnection",
                                "kind": "LinkedField",
                                "name": "skills",
                                "plural": false,
                                "selections": (v17/*: any*/),
                                "storageKey": "skills(first:0)"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": (v22/*: any*/),
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "placeId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isRecurring",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "estimatedMileage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": (v22/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigCategory",
                                "kind": "LinkedField",
                                "name": "category",
                                "plural": false,
                                "selections": (v25/*: any*/),
                                "storageKey": null
                              },
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "actualStartsAt",
                                "storageKey": null
                              },
                              (v23/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timezone",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "negotiable",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "skill",
                            "plural": false,
                            "selections": (v22/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "estimatedPayment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "payRate",
                                "storageKey": null
                              },
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "GigSeries",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:HOGJNHMaIY8QwtlU4jdeOOhsTYg5yyzQwh-81kMghVQ",
    "metadata": {},
    "name": "seriesEngagementsActionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e4f5ff48f9e7451b7da99fd9443b0b1c";

export default node;
