import { Pressable, Vibration } from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { type FlagSpec, FlagType } from "@gigsmart/feature-flags/registry";
import React, { useRef, useCallback, useEffect, type ReactNode } from "react";

interface Props {
  flag: FlagSpec;
  children: ReactNode;
}

export default function SecretFeatureButton({ flag, children }: Props) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const handleEnable = useCallback(() => {
    Vibration.vibrate(3_000);
    if (flag.flagType === FlagType.PINNED) {
      toast.error(`Cannot toggle ${flag.name}, it is pinned`);
    } else if (flag.isEnabled()) {
      flag.disable(true);
      toast.success(`You have disabled ${flag.name}`);
    } else {
      flag.enable(true);
      toast.success(`You have enabled ${flag.name}`);
    }
  }, [flag]);

  const clearTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const startTimer = useCallback(() => {
    clearTimer();
    timeoutRef.current = setTimeout(handleEnable, 15_000);
  }, [handleEnable, clearTimer]);

  useEffect(() => clearTimer, [clearTimer]);

  return (
    <Pressable
      testID={`${flag.name}-secret-btn`}
      eventEntityType={`${flag.name} Secret Button`}
      eventTargetName="Secret Feature Button"
      onPressIn={startTimer}
      onPressOut={clearTimer}
    >
      {children}
    </Pressable>
  );
}
