/**
 * @generated SignedSource<<21222ffcc7f9083aae3867e59b7c4aa1>>
 * @relayHash ee8f9b7909e37d822598cb6fb29ca77b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:zKTrggs90uxYdy2VtRE0CFk8Gp1VuXQBvM-nQHGw4Is

import { ConcreteRequest } from 'relay-runtime';
export type DismissAnnouncementInput = {
  announcementId: string;
};
export type announcementDismissMutation$variables = {
  input: DismissAnnouncementInput;
};
export type announcementDismissMutation$data = {
  readonly dismissAnnouncement: {
    readonly announcement: {
      readonly id: string;
    };
  } | null | undefined;
};
export type announcementDismissMutation = {
  response: announcementDismissMutation$data;
  variables: announcementDismissMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "announcementDismissMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissAnnouncementPayload",
        "kind": "LinkedField",
        "name": "dismissAnnouncement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Announcement",
            "kind": "LinkedField",
            "name": "announcement",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "announcementDismissMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissAnnouncementPayload",
        "kind": "LinkedField",
        "name": "dismissAnnouncement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Announcement",
            "kind": "LinkedField",
            "name": "announcement",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:zKTrggs90uxYdy2VtRE0CFk8Gp1VuXQBvM-nQHGw4Is",
    "metadata": {},
    "name": "announcementDismissMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c4c456a695a2119ec28f28a80b2bbadf";

export default node;
