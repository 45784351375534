/**
 * @generated SignedSource<<58a566240175e9abb65e23eb58539dc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type requesterThumbnailRow_gig$data = {
  readonly organization: {
    readonly logoUrl: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly primaryContact: {
    readonly displayName: string | null | undefined;
  } | null | undefined;
  readonly requester: {
    readonly displayName: string | null | undefined;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "requesterThumbnailRow_gig";
};
export type requesterThumbnailRow_gig$key = {
  readonly " $data"?: requesterThumbnailRow_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"requesterThumbnailRow_gig">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "requesterThumbnailRow_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationRequester",
      "kind": "LinkedField",
      "name": "primaryContact",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Requester",
      "kind": "LinkedField",
      "name": "requester",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profilePhoto",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "0f159f257526d38a47a279fb521daa7b";

export default node;
