import React, { type ReactNode } from "react";
import { Image, TouchableOpacity, View } from "react-native";

import { Flex, FlexColumn, FlexRow } from "../flex";
import { StyledIcon } from "../icon";
import { RatingStars } from "../indicator";
import { Spacer } from "../spacer";
import { type ImageStyleProp, useStyles } from "../style";
import { SmallOutlineButton } from "../tappable";
import { StyledText } from "../text";

import BaseRow from "./base-row";

interface Props {
  variant?: "classic" | "quickActions" | "button";
  profilePhotoUrl: string | null | undefined;
  name: string;
  secondaryTitle?: string | null | undefined;
  buttonText?: string;
  rating: number | null | undefined;
  ratingCount: number | null | undefined;
  initials: string;
  handleButtonPress?: () => void;
  handlePhonePress?: () => void;
  handleMessagingPress?: () => void;
  customAvatar?: ReactNode;
}

const AVATAR_WIDTH = 56;

export default ({
  variant = "classic",
  name,
  secondaryTitle,
  profilePhotoUrl,
  initials,
  buttonText,
  rating = 0,
  ratingCount = 0,
  handleButtonPress,
  handlePhonePress,
  handleMessagingPress,
  customAvatar: CustomAvatar
}: Props) => {
  const { styles, theme } = useStyles(({ color, font, units }) => ({
    avatar: {
      height: AVATAR_WIDTH,
      width: AVATAR_WIDTH,
      borderRadius: AVATAR_WIDTH / 2,
      marginRight: units(4)
    },
    text: {
      paddingBottom: units(0.5)
    },
    rating: {
      paddingHorizontal: units(1),
      top: units(-0.5)
    },
    button: {
      width: "auto",
      padding: units(2)
    },
    buttonText: {
      ...font.body("bold")
    },
    iconRight: {
      paddingLeft: units(8)
    },
    initialsContainer: {
      height: AVATAR_WIDTH,
      width: AVATAR_WIDTH,
      borderRadius: AVATAR_WIDTH / 2,
      backgroundColor: color.blue,
      marginRight: units(4)
    },
    actionsContainer: {
      marginLeft: units(4),
      flexShrink: 1,
      alignItems: "center",
      justifyContent: "center"
    }
  }));
  return (
    <BaseRow>
      <FlexRow justifyContent="space-between" fill={1}>
        <FlexRow justifyContent="flex-start" fill={1}>
          {CustomAvatar ? (
            <>
              {CustomAvatar}
              <Spacer direction="row" />
            </>
          ) : profilePhotoUrl ? (
            <Image
              style={styles.avatar as ImageStyleProp}
              source={{ uri: profilePhotoUrl }}
            />
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              style={[styles.avatar, styles.initialsContainer]}
            >
              <StyledText color="white" fontSize={theme.font.size.extraLarge}>
                {initials}
              </StyledText>
            </Flex>
          )}
          <FlexColumn justifyContent="center" fill={1}>
            <StyledText bold color="blue" style={styles.text}>
              {name}
            </StyledText>
            {secondaryTitle && (
              <StyledText
                color="blue"
                fontSize={theme.font.size.small}
                style={styles.text}
              >
                {secondaryTitle}
              </StyledText>
            )}
            <FlexRow alignItems="flex-end">
              {ratingCount ? (
                <>
                  <StyledText
                    color="spiceOrange"
                    bold
                    fontSize={theme.font.size.small}
                  >
                    {(rating ?? 0).toFixed(1)}
                  </StyledText>
                  <RatingStars
                    color="spiceOrange"
                    disabled
                    fontSize={theme.font.size.smallest}
                    rating={rating}
                    style={styles.rating}
                  />
                  <StyledText
                    fontSize={theme.font.size.small}
                    color="neutralDark"
                  >
                    ({ratingCount})
                  </StyledText>
                </>
              ) : (
                <FlexRow fill={1}>
                  <StyledText color="neutralDark">
                    <StyledText bold color="neutralDark">
                      New{" "}
                    </StyledText>
                    (No Reviews Yet)
                  </StyledText>
                </FlexRow>
              )}
            </FlexRow>
          </FlexColumn>
        </FlexRow>
        <View style={styles.actionsContainer}>
          {variant === "button" && buttonText && (
            <SmallOutlineButton
              onPress={handleButtonPress}
              title={buttonText}
            />
          )}
          {variant === "quickActions" && (
            <FlexRow>
              <TouchableOpacity onPress={handlePhonePress}>
                <StyledIcon
                  color="blue"
                  name="phone"
                  variant="solid"
                  size={theme.font.size.large}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleMessagingPress}
                style={styles.iconRight}
              >
                <StyledIcon
                  color="blue"
                  name="message-lines"
                  variant="solid"
                  size={theme.font.size.large}
                />
              </TouchableOpacity>
            </FlexRow>
          )}
        </View>
      </FlexRow>
    </BaseRow>
  );
};
