import React from "react";
import { Pressable } from "react-native";
import Svg, { Defs, Path } from "react-native-svg";

interface Props {
  onPress?: () => void;
}

export default function AltLogo({ onPress, ...props }: Props) {
  return (
    <Pressable onPress={onPress} testID="alt-brandmark">
      <Svg
        id="prefix__Layer_1"
        data-name="Layer 1"
        viewBox="0 0 252 228.95"
        {...props}
        width={50}
        height={50}
      >
        <Defs />
        <Path
          d="M8.47 204.08c0-10.44 8-17 17.79-17a15.31 15.31 0 0 1 14 8l-7.09 3.69a8.14 8.14 0 0 0-6.94-4.19c-5.37 0-9.17 4.09-9.17 9.51s3.8 9.51 9.17 9.51a10.42 10.42 0 0 0 6.05-2v-2.4h-7.35v-6.6h15.86v12.07a18.92 18.92 0 0 1-14.53 6.4c-9.81 0-17.79-6.55-17.79-16.99zM45.27 220.48v-32.85h8.47v32.85zM57.48 204.08c0-10.44 8-17 17.79-17a15.31 15.31 0 0 1 14 8l-7.09 3.69a8.14 8.14 0 0 0-6.94-4.19c-5.37 0-9.17 4.09-9.17 9.51s3.8 9.51 9.17 9.51a10.42 10.42 0 0 0 6-2v-2.4h-7.3v-6.6H89.8v12.07a18.92 18.92 0 0 1-14.53 6.4c-9.81 0-17.79-6.55-17.79-16.99zM93.19 215.85l2.47-3.15a13.55 13.55 0 0 0 10.34 4.73c5.86 0 7.74-3.15 7.74-5.71 0-8.48-19.46-3.75-19.46-15.47 0-5.42 4.83-9.16 11.33-9.16a15.42 15.42 0 0 1 11.62 4.63l-2.46 3a12.39 12.39 0 0 0-9.51-3.94c-3.89 0-6.74 2.12-6.74 5.22 0 7.39 19.45 3.15 19.45 15.42 0 4.73-3.25 9.65-12.12 9.65-5.71 0-9.95-2.16-12.66-5.22zM150.88 220.48v-27.63l-11.14 27.63h-1.67l-11.18-27.63v27.63h-4.09v-32.85h5.86L138.91 213l10.19-25.32h5.9v32.85zM184.86 220.48l-2.9-7.34H165.6l-2.9 7.34H158l13.25-32.85h5.07l13.2 32.85zm-11.08-28.66l-6.95 17.73h13.94zM211.61 220.48l-8.33-13h-6.6v13h-4.08v-32.85h13.2c6 0 10.34 3.84 10.34 9.9a9.13 9.13 0 0 1-8.57 9.56l8.87 13.39zm.29-22.95c0-3.74-2.7-6.26-6.6-6.26h-8.62v12.56h8.62c3.9 0 6.6-2.61 6.6-6.3zM229 220.48v-29.21h-10.4v-3.64h24.93v3.64h-10.45v29.21z"
          fill="#003a5c"
          fillRule="nonzero"
        />
        <Path
          d="M75.4 40.51a74.85 74.85 0 0 1 53.3-22.08v-10a85.34 85.34 0 0 0-85.34 85.38h10a74.89 74.89 0 0 1 22.04-53.3zM128.7 169.2v10a85 85 0 0 0 54.85-20l-6.39-7.63a74.8 74.8 0 0 1-48.46 17.63zM102.14 93.81h-10a36.53 36.53 0 0 0 36.52 36.53v-10a26.59 26.59 0 0 1-26.52-26.53zM128.7 73.57a10.29 10.29 0 1 1 0-20.58V43a20.25 20.25 0 1 0 0 40.49 10.3 10.3 0 0 1 0 20.59v10a20.25 20.25 0 1 0 0-40.49z"
          fill="#df4602"
          fillRule="nonzero"
        />
        <Path
          d="M128.7 136.65A42.82 42.82 0 0 1 91.61 72.4l-8.64-5a52.8 52.8 0 1 0 97.31 37.71H170a42.91 42.91 0 0 1-41.3 31.54z"
          fill="#df4602"
          fillRule="nonzero"
        />
        <Path
          d="M155.27 88.84v10h32.33a59.11 59.11 0 1 1-20.9-50.26l6.39-7.62a69.06 69.06 0 1 0 24.5 57.88c.12-1.64.18-10 .18-10z"
          fill="#df4602"
          fillRule="nonzero"
        />
      </Svg>
    </Pressable>
  );
}
