/**
 * @generated SignedSource<<512296c8386900759b795f7449f59109>>
 * @relayHash 58d16893a546af06aa2a2b15b6a1a032
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:6LXxoan0ulNfH08mqW-xE8EDmwWrerv5_uLZYjulXAY

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
export type OrganizationStepDirectHireClaimQuery$variables = {
  id: string;
};
export type OrganizationStepDirectHireClaimQuery$data = {
  readonly node: {
    readonly directHireDate?: string | null | undefined;
    readonly directHireType?: DirectHireType | null | undefined;
    readonly firstName?: string | null | undefined;
    readonly id?: string;
    readonly lastName?: string | null | undefined;
    readonly organization?: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly place?: {
      readonly streetAddress: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type OrganizationStepDirectHireClaimQuery = {
  response: OrganizationStepDirectHireClaimQuery$data;
  variables: OrganizationStepDirectHireClaimQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directHireDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directHireType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationStepDirectHireClaimQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Place",
                "kind": "LinkedField",
                "name": "place",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "DirectHireClaim",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationStepDirectHireClaimQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Place",
                "kind": "LinkedField",
                "name": "place",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "DirectHireClaim",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:6LXxoan0ulNfH08mqW-xE8EDmwWrerv5_uLZYjulXAY",
    "metadata": {},
    "name": "OrganizationStepDirectHireClaimQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d64179095abcc0b1d1e6e54f5fbaced0";

export default node;
