import {
  ContentArea,
  type IconName,
  Row,
  ScreenScroll,
  Spacer,
  Text,
  toast,
  useCannon
} from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import {
  SolidButton,
  StyledView,
  TabButton,
  confirmPrompt,
  dismiss
} from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import { useAppRating } from "@gigsmart/seibutsu/utils/useAppRating";
import React, { useState } from "react";
import type { WorkerParamList } from "../../navigation/types";
import { formatBalance, toFixedWithoutRounding } from "../helpers";
import type { transferScreenMutation } from "./__generated__/transferScreenMutation.graphql";
import type { transferScreenQuery } from "./__generated__/transferScreenQuery.graphql";
import TabContent from "./tab-content";

const icons = [
  {
    icon: "bolt" as IconName,
    testID: "rapid-transfer",
    text: "Rapid Transfer",
    secondaryText: "3% fee",
    tertiaryText: "Usually available within minutes"
  },
  {
    icon: "envelope-open-dollar" as IconName,
    testID: "standard-transfer",
    text: "Standard Transfer",
    secondaryText: "No Fee",
    tertiaryText: "Payouts typically take 2 - 5 Business Days"
  }
];

type Props = AppScreenProps<WorkerParamList, "WalletTransfer">;

export default createSuspendedQueryContainer<transferScreenQuery, Props>(
  function TransferScreen({ navigation, response: result }) {
    const requestRating = useAppRating();
    const [initiatePayoutCommit] =
      useRelayMutation<transferScreenMutation>(graphql`
        mutation transferScreenMutation($input: AddWorkerPayoutInput!) {
          addWorkerPayout(input: $input) {
            newWorkerPayoutEdge {
              node {
                type
                amount
              }
            }
          }
        }
      `);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const explode = useCannon();
    const balance = result?.viewer?.walletBalance
      ? currency.humanize(result?.viewer?.walletBalance)
      : "0.00";

    // const requestRating = useRequestAppRating();

    const initiatePayout = () => {
      initiatePayoutCommit(
        {
          input: {
            type: selectedTab === 0 ? "INSTANT" : "STANDARD"
          }
        },
        {
          onSuccess: () => {
            explode();
            requestRating();
            toast.success("Your account balance transfer has been initiated!");
            navigation.goBack();
          },
          onError: ({ message }) => {
            if (
              message?.includes("is not a supported method for payouts") &&
              selectedTab === 0
            ) {
              toast.error("Bank not supported by Stripe for Rapid Transfers.");
            } else if (
              message?.includes("is required to enable rapid transfers")
            ) {
              toast.error(
                "Financial Verification is required to enable rapid transfers."
              );
            } else {
              toast.error(message);
            }
          }
        }
      );
    };

    return (
      <ScreenScroll testID="transfer-screen" color="surface">
        <ContentArea size="large">
          <Text color="primary" variant="title" align="center">
            Transfer Balance
          </Text>
          <ContentArea gap="slim">
            <Text variant="hero" color="success" align="center">
              {balance}
            </Text>
            <Text align="center">Account Balance</Text>
          </ContentArea>
        </ContentArea>
        {balance === "0.00" ? (
          <Text align="center">
            You must have an account balance to make a transfer.
          </Text>
        ) : (
          <>
            <Row fill={1}>
              <Spacer horizontal />
              {icons.map((icon, index) => (
                <>
                  <TabButton
                    {...icon}
                    onPress={() => setSelectedTab(index)}
                    isActive={selectedTab === index}
                    iconVariant="solid"
                  />
                  <Spacer horizontal />
                </>
              ))}
            </Row>
            {selectedTab !== null && result?.viewer?.payableAccount && (
              <TabContent
                payableAccount={result.viewer.payableAccount}
                selectedTab={selectedTab}
                fees={fees(result?.viewer?.walletBalance ?? "")}
              />
            )}
          </>
        )}
        <StyledView>
          {(selectedTab === 0 || selectedTab === 1) && (
            <SolidButton
              title={`Transfer $${balanceMinusFees(
                result?.viewer?.walletBalance ?? "",
                selectedTab
              )}`}
              onPress={() =>
                confirmPrompt({
                  title: "Initiate Transfer",
                  subTitle: `Are you sure you want to initiate the transfer of $${balanceMinusFees(
                    result?.viewer?.walletBalance ?? "",
                    selectedTab
                  )}?`,
                  yesLabel: "Yes, Transfer",
                  cancelLabel: "No, Cancel",
                  onDo: () => {
                    initiatePayout();
                    dismiss();
                  },
                  onCancel: () => {
                    dismiss();
                  }
                })
              }
            />
          )}
        </StyledView>
        <Spacer size="large" />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query transferScreenQuery {
        viewer {
          ... on Worker {
            walletBalance
            payableAccount {
              ...tabContent_workerPayableAccount
            }
          }
        }
      }
    `,
    variables: {}
  }
);

const balanceMinusFees = (
  balanceString: string,
  selectedTab: number
): string => {
  const balance = currency.toFloat(balanceString);
  if (selectedTab === 1) return formatBalance(balance);
  let fee = toFixedWithoutRounding(balance * 0.03);
  if (fee < 1) fee = 1;
  return formatBalance(balance - fee);
};

const fees = (balanceString: string) => {
  let fee = currency.toFloat(balanceString) * 0.03;
  if (fee < 1) fee = 1;
  return formatBalance(fee);
};
