/**
 * @generated SignedSource<<4630656cecf083fe85e4f9d34708d8b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CategorySummaryRow_gigLike$data = {
  readonly category: {
    readonly displayName: string;
  } | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly " $fragmentType": "CategorySummaryRow_gigLike";
};
export type CategorySummaryRow_gigLike$key = {
  readonly " $data"?: CategorySummaryRow_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategorySummaryRow_gigLike">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategorySummaryRow_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "dd3c195f4c4faada73ae2cc12b3621aa";

export default node;
