/**
 * @generated SignedSource<<d385c12bf7e9e5795443ee973f346561>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementCommitmentResponse = "ACCEPT" | "DECLINE" | "PENDING" | "TIMEOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftCommitmentReminder_commitment$data = {
  readonly expiresAt: string;
  readonly response: EngagementCommitmentResponse | null | undefined;
  readonly " $fragmentType": "ShiftCommitmentReminder_commitment";
};
export type ShiftCommitmentReminder_commitment$key = {
  readonly " $data"?: ShiftCommitmentReminder_commitment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftCommitmentReminder_commitment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftCommitmentReminder_commitment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "response",
      "storageKey": null
    }
  ],
  "type": "EngagementCommitment",
  "abstractKey": null
};

(node as any).hash = "51b0e33e31fc571f132cb385725a146c";

export default node;
