import React, { type ComponentProps } from "react";

import { Thumbnail } from "../display";
import { FlexRow } from "../flex";
import { type ImageStyleProp, useStyles } from "../style";

type Props = ComponentProps<typeof FlexRow> & {
  thumbUri?: string | null | undefined;
  thumbSize?: number;
  thumbGrayscale?: boolean;
};

const RowThumbnail = ({
  thumbSize = 28,
  thumbUri,
  thumbGrayscale,
  children,
  style,
  ...props
}: Props) => {
  const { styles } = useStyles(({ units }) => ({
    container: {
      paddingVertical: 10,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: units(4)
    },
    thumb: {
      marginRight: units(3)
    }
  }));

  return (
    <FlexRow alignItems="center" style={[styles.container, style]} {...props}>
      <Thumbnail
        style={styles.thumb as ImageStyleProp}
        uri={thumbUri}
        size={thumbSize}
        grayscale={thumbGrayscale}
      />
      {children}
    </FlexRow>
  );
};

export default RowThumbnail;
