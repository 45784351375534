import {
  Button,
  IconSquareButton,
  Image,
  ModalBody,
  ModalFooter,
  Pressable,
  Row,
  Stack,
  Surface,
  Text,
  showModal,
  toast
} from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  FormSubmit,
  Validator,
  useFormField
} from "@gigsmart/fomu";
import stateList from "@gigsmart/isomorphic-shared/app/us-state-list";
import MediaPicker, {
  type MediaPickerRefType
} from "@gigsmart/isomorphic-shared/media/media-picker";
import {
  ConnectionHandler,
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import FomuDateInput from "@gigsmart/seibutsu/fomu/inputs/FomuDateInput";
import FomuPicker from "@gigsmart/seibutsu/fomu/inputs/FomuPicker";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import { DateTime } from "luxon";
import React, { useRef } from "react";

import type { CommercialDriversLicenseModalMutation } from "./__generated__/CommercialDriversLicenseModalMutation.graphql";
import type { CommercialDriversLicenseModalQuery } from "./__generated__/CommercialDriversLicenseModalQuery.graphql";
import type { CommercialDriversLicenseModalUpdateMutation } from "./__generated__/CommercialDriversLicenseModalUpdateMutation.graphql";

interface Props {
  onClose: () => void;
  onComplete: (workerQualificationProofId: string) => void;
}

const Abc = createSuspendedQueryContainer<
  CommercialDriversLicenseModalQuery,
  Props
>(
  function CommercialDriversLicenseModal({
    response,
    retry,
    onClose,
    onComplete
  }) {
    const { viewer = null } = response ?? {};
    const driversLicense = getConnectionNodes(viewer?.qualificationProofs)[0];

    const [addWorkerQualificationDriversLicenseProof] =
      useRelayMutationPromise<CommercialDriversLicenseModalMutation>(
        graphql`
          mutation CommercialDriversLicenseModalMutation(
            $input: AddWorkerQualificationDriversLicenseProofInput!
          ) {
            addWorkerQualificationDriversLicenseProof(input: $input) {
              newWorkerQualificationProofEdge {
                node {
                  ... on WorkerQualificationDriversLicenseProof {
                    id
                    status
                  }
                }
              }
            }
          }
        `
      );

    const [updateWorkerQualificationDriversLicenseProof] =
      useRelayMutationPromise<CommercialDriversLicenseModalUpdateMutation>(
        graphql`
          mutation CommercialDriversLicenseModalUpdateMutation(
            $input: UpdateWorkerQualificationDriversLicenseProofInput!
          ) {
            updateWorkerQualificationDriversLicenseProof(input: $input) {
              updatedWorkerQualificationDriversLicenseProof {
                id
              }
            }
          }
        `
      );

    const submit: FomuSubmitFn = async ({ values }, done, reset) => {
      try {
        const input = {
          frontPhotoId: values.dlFront.id,
          backPhotoId: values.dlBack.id,
          issueDate: values.issueDate,
          expirationDate: values.expirationDate,
          issuingState: values.issuingState,
          licenseNumber: values.licenseNumber
        };

        if (driversLicense) {
          const { updateWorkerQualificationDriversLicenseProof: res } =
            await updateWorkerQualificationDriversLicenseProof({
              input: {
                ...input,
                ...(input.licenseNumber.includes("***") && {
                  licenseNumber: driversLicense.licenseNumber
                }),
                workerQualificationProofId: driversLicense?.id ?? ""
              }
            });
          onComplete?.(
            res?.updatedWorkerQualificationDriversLicenseProof.id ?? ""
          );
        } else {
          const { addWorkerQualificationDriversLicenseProof: res } =
            await addWorkerQualificationDriversLicenseProof(
              { input },
              {
                updater: (store) => {
                  const worker = store.get(viewer?.id ?? "");
                  if (!worker) return;
                  const connection = ConnectionHandler.getConnection(
                    worker,
                    "useQualifications___qualificationProofs",
                    { query: "WHERE type = DRIVERS_LICENSE" }
                  );
                  if (!connection) return;
                  const payload = store.getRootField(
                    "addWorkerQualificationDriversLicenseProof"
                  );
                  if (!payload) return;
                  const serverEdge = payload.getLinkedRecord(
                    "newWorkerQualificationProofEdge"
                  );
                  const newEdge = ConnectionHandler.buildConnectionEdge(
                    store,
                    connection,
                    serverEdge
                  );
                  if (!newEdge) return;
                  ConnectionHandler.insertEdgeBefore(connection, newEdge);
                }
              }
            );
          onComplete?.(res?.newWorkerQualificationProofEdge?.node?.id ?? "");
        }

        done();
        reset();
        retry();

        onClose();
      } catch (error) {
        toast.error("Could not save Drivers License");
      }
    };

    return (
      <Form
        initialValues={{
          issuingState: driversLicense?.issuingState,
          issueDate: driversLicense?.issueDate,
          licenseNumber: (driversLicense?.licenseNumber ?? "").replace(
            /.(?=.{4,}$)/g,
            "*"
          ),
          expirationDate: driversLicense?.expirationDate,
          dlFront: driversLicense?.frontPhoto,
          dlBack: driversLicense?.backPhoto
        }}
        onSubmit={submit}
      >
        <ModalBody>
          <Stack>
            <FomuPicker
              label="State"
              placeholder="Select One"
              options={stateList}
              name={"issuingState"}
              validates={Validator.presence()}
              eventTargetName="Worker Drivers License State"
            />
            <Stack horizontal>
              <FomuDateInput
                fill
                name="issueDate"
                label="Issue Date"
                testID="issueDate-input"
                validates={Validator.composeValidations(
                  Validator.date({
                    format: "YYYY-MM-DD",
                    message: "Invalid Date"
                  }),
                  Validator.date({
                    format: "YYYY-MM-DD",
                    options: {
                      max: new Date(),
                      min: DateTime.now().minus({ years: 80 }).toJSDate()
                    },
                    message: "Issue date cannot be in the future"
                  })
                )}
                eventTargetName="Worker Drivers License Issue Date"
              />
              <FomuDateInput
                fill
                name="expirationDate"
                label="Expiration Date"
                testID="expirationDate-input"
                validates={Validator.composeValidations(
                  Validator.date({
                    format: "YYYY-MM-DD",
                    message: "Invalid Date"
                  }),
                  Validator.date({
                    format: "YYYY-MM-DD",
                    options: {
                      min: new Date(),
                      max: DateTime.now().plus({ years: 100 }).toJSDate()
                    },
                    message: "Cannot add an expired license"
                  })
                )}
                eventTargetName="Worker Drivers License Expiration Date"
              />
            </Stack>
            <FomuTextInput
              name="licenseNumber"
              label="License Number"
              placeholder="Enter Number"
              validates={Validator.presence()}
              autoCapitalize="characters"
              autoCorrect={false}
              format={(str) => str.toUpperCase()}
              maxLength={20}
              sensitive
            />
            <Stack size="compact">
              <Text color="primary" weight="bold">
                Please provide a picture of each side of your license.
              </Text>
              <DriversLicensePicker />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Row>
            <FormSubmit>
              {({ submit, submitting, dirty, invalid }) => (
                <Button
                  disabled={submitting || !dirty || invalid}
                  testID="save-drivers-license"
                  label={`Save ${driversLicense ? "Changes" : ""}`}
                  onPress={submit}
                  fill
                />
              )}
            </FormSubmit>
          </Row>
        </ModalFooter>
      </Form>
    );
  },
  {
    query: graphql`
      query CommercialDriversLicenseModalQuery {
        viewer {
          ... on Worker {
            id
            qualificationProofs(
              first: 1
              query: "WHERE type = DRIVERS_LICENSE"
            ) {
              edges {
                node {
                  ... on WorkerQualificationDriversLicenseProof {
                    id
                    backPhoto {
                      id
                      url
                    }
                    frontPhoto {
                      id
                      url
                    }
                    issuingState
                    issueDate
                    expirationDate
                    licenseNumber
                    status
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);

function DriversLicensePicker() {
  const sides: Array<"Front" | "Back"> = ["Front", "Back"];

  return (
    <Row justifyContent="space-around">
      {sides.map((item) => (
        <DriversLicensePickerItem part={item} key={`${item}-dl`} />
      ))}
    </Row>
  );
}

function DriversLicensePickerItem({ part }: { part: "Front" | "Back" }) {
  const { value, setValue } = useFormField({
    name: `dl${part}`,
    validates: Validator.presence()
  });
  const dlPickerRef = useRef<MediaPickerRefType>(null);
  const select = () => dlPickerRef?.current?.open("camera");

  return (
    <Stack size="compact">
      <Text weight="bold" align="center">
        {part}
      </Text>
      {value ? (
        <Pressable
          testID={`dl-${part}-picker`}
          eventEntityType={`${part} DL Picker`}
          onPress={select}
          eventTargetName={`Worker Drivers License ${part}`}
        >
          <Surface color="background" variant="flat">
            <Row justifyContent="center">
              <Image
                height={60}
                variant="square"
                resizeMode="contain"
                source={{
                  uri: value.url
                }}
              />
            </Row>
          </Surface>
        </Pressable>
      ) : (
        <IconSquareButton
          icon="camera"
          testID={`dl-${part}-picker`}
          color="black"
          iconVariant="regular"
          onPress={select}
        />
      )}
      <MediaPicker ref={dlPickerRef} onChange={(file) => setValue(file)} />
    </Stack>
  );
}

export function showCommercialDriversLicenseModal({
  title,
  onRequestClose,
  ...props
}: Omit<Props, "onClose"> & { title: string; onRequestClose?: () => void }) {
  showModal({
    eventContext: "CommercialDriversLicenseModal",
    title,
    subTitle:
      "You must add the following information for this License to be saved to your Profile.",
    subTitleItalic: false,
    onRequestClose,
    children: (onClose) => <Abc {...props} onClose={onClose} />
  });
}
