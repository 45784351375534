import { Column, FooterSpacer, ScreenScroll } from "@gigsmart/atorasu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import BrowseShiftHeader from "@gigsmart/seibutsu/browse/BrowseShiftHeader";
import ActiveShiftHeader from "@gigsmart/seibutsu/shifts/ActiveShiftHeader";
import { isStateActive } from "@gigsmart/seibutsu/shifts/WorkerShiftState";
import WorkerShiftTabs from "@gigsmart/seibutsu/shifts/WorkerShiftTabs";
import EngagementTopAccessory from "@gigsmart/seibutsu/shifts/accessory/EngagementTopAccessory";
import SingleShiftSummary from "@gigsmart/seibutsu/shifts/accessory/SingleShiftSummary";
import WorkerShiftStatusText from "@gigsmart/seibutsu/shifts/accessory/WorkerShiftStatusText";
import React from "react";
import { updateWorkerLocation } from "../gig/use-location-updater";
import type { WorkerParamList } from "../navigation/types";
import type { WorkerShiftDetailsScreenQuery } from "./__generated__/WorkerShiftDetailsScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "ShiftDetails">;

export default createSuspendedQueryContainer<
  WorkerShiftDetailsScreenQuery,
  Props
>(
  function WorkerShiftDetailsScreen({ response }) {
    const node = response?.node;
    const gig = node?.gig;

    const workerStateName = node?.currentState?.name;
    const isActive = isStateActive(
      workerStateName,
      !!node?.reviewOfRequester?.id
    );

    return (
      <ScreenScroll testID="engagement-details-screen">
        <EngagementTopAccessory fragmentRef={node} />
        <Column gap="standard">
          {isActive ? (
            <ActiveShiftHeader
              onRefreshLocation={updateWorkerLocation}
              fragmentRef={node}
            />
          ) : (
            <BrowseShiftHeader fragmentRef={gig}>
              <WorkerShiftStatusText fragmentRef={node} />
              <SingleShiftSummary fragmentRef={node} />
            </BrowseShiftHeader>
          )}
          {!!node?.id && <WorkerShiftTabs shiftId={node.id} />}
        </Column>
        <FooterSpacer size="xlarge" />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query WorkerShiftDetailsScreenQuery($id: ID!) {
        node(id: $id) {
          ...WorkerShiftStatusText_shift
          ...EngagementTopAccessory_engagement
          ...ActiveShiftHeader_engagement
          ...SingleShiftSummary_engagement
          ... on Engagement {
            id
            currentState {
              name
            }
            gig {
              ...BrowseShiftHeader_shift
            }
            reviewOfRequester {
              id
            }
          }

        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
