import {
  Button,
  ContentArea,
  Icon,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  RadioButtonRow,
  Spacer,
  Surface,
  Text,
  TextInput
} from "@gigsmart/atorasu";
import { asEnum, includes, like, where } from "@gigsmart/biruda";
import { getConnectionNodes, graphql, useRelayMutation } from "@gigsmart/relay";
import React, { useState } from "react";
import { createFlatListPaginator } from "../../../shared/Paginator";
import type { SelectOrganizationModalQuery$data } from "./__generated__/SelectOrganizationModalQuery.graphql";
import type { SelectOrganizationModalUpdateClaimMutation } from "./__generated__/SelectOrganizationModalUpdateClaimMutation.graphql";
import type { SelectOrganizationModal_Worker$data } from "./__generated__/SelectOrganizationModal_Worker.graphql";

const [LoadBoundary, Paginator] = createFlatListPaginator({
  query: graphql`
    query SelectOrganizationModalQuery($query: String!) {
      viewer {
        ... on Worker {
          ...SelectOrganizationModal_Worker @arguments(query: $query)
        }
      }
    }
  `,
  displayName: "SelectOrganizationModalOrganizationList"
});

const paginationFragment = graphql`
  fragment SelectOrganizationModal_Worker on Worker
  @refetchable(queryName: "SelectOrganizationModalPageQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    query: { type: "String!" }
  ) {
    organizations(first: $count, after: $after, query: $query)
      @connection(
        key: "SelectOrganizationModal_organizations"
        filters: ["query"]
      ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

interface Props {
  visible: boolean;
  onCancel: () => void;
  directHireClaimId?: string;
  initialOrgId?: string;
}

export default function SelectOrganizationModal({
  visible,
  onCancel,
  directHireClaimId,
  initialOrgId
}: Props) {
  const [org, setOrg] = useState<string | undefined>(initialOrgId);
  const [searchTerm, setSearchTerm] = useState("");
  const [commit] =
    useRelayMutation<SelectOrganizationModalUpdateClaimMutation>(graphql`
      mutation SelectOrganizationModalUpdateClaimMutation(
        $input: UpdateDirectHireClaimInput!
      ) {
        updateDirectHireClaim(input: $input) {
          directHireClaim {
            id
            organization {
              id
              name
            }
          }
        }
      }
    `);

  const closeModal = () => {
    setSearchTerm("");
    onCancel();
  };

  const handleSave = () => {
    commit(
      {
        input: {
          organizationId: org,
          directHireClaimId: directHireClaimId ?? ""
        }
      },
      { onSuccess: closeModal }
    );
  };
  return (
    <LoadBoundary>
      <ModalContainer
        eventContext={"Add Direct Hire Claim Select Organization Modal"}
        visible={visible}
        onRequestClose={closeModal}
        fixedHeight
        variant={(size) => (size === "small" ? "full-shadow" : "shadow")}
      >
        <Surface>
          <ModalHeader
            title="Select an Organization"
            icon="user-tie"
            titleAction={<Spacer />}
          />
        </Surface>
        <ModalBody>
          <Paginator
            testID="create-direct-hire-claim-organization-list"
            paginationFragment={paginationFragment}
            variables={{
              query: where({
                name: like(searchTerm),
                hasClaim: false,
                anyEngagementStateName: includes(asEnum("SCHEDULED"))
              })
                .orderBy("name", "ASC")
                .toString()
            }}
            extractPaginationFragment={(result) =>
              (result as SelectOrganizationModalQuery$data)?.viewer
            }
            extractData={(result: SelectOrganizationModal_Worker$data) => {
              return getConnectionNodes(result?.organizations);
            }}
            ItemSeparatorComponent={() => <Spacer size="compact" />}
            renderItem={({ item, index }) => {
              return (
                <ContentArea size="none">
                  <RadioButtonRow
                    testID={`org-row-${item?.id}`}
                    eventTargetName="Org Row Radio Button"
                    selected={org === item?.id}
                    onSelect={() => setOrg(item?.id)}
                    title={item?.name}
                  />
                </ContentArea>
              );
            }}
            ListHeaderComponent={
              <ContentArea>
                <TextInput
                  testID="search-input"
                  value={searchTerm}
                  onChangeText={(val) => setSearchTerm(val)}
                  placeholder="Search by Organization Name"
                  leftAccessory={
                    <Icon
                      name="search"
                      variant="solid"
                      size="small"
                      color="neutral"
                    />
                  }
                />
              </ContentArea>
            }
            ListEmptyComponent={
              <ContentArea size="none">
                <Text>
                  No Requesters matching your search criteria were found.
                </Text>
              </ContentArea>
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            testID="save-selection-btn"
            label="Save Selection"
            onPress={handleSave}
            disabled={!org || org === initialOrgId}
          />
        </ModalFooter>
      </ModalContainer>
    </LoadBoundary>
  );
}
