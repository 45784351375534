import {
  Button,
  ContentArea,
  ContentRow,
  Divider,
  FooterSpacer,
  ProductInfoRow,
  ScreenScroll,
  Spacer,
  Surface,
  Text,
  TimeRemaining,
  toast,
  useHistoryBlock
} from "@gigsmart/atorasu";
import { WorkerApproveTimesheet } from "@gigsmart/feature-flags";
import { duration } from "@gigsmart/isomorphic-shared/iso";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { confirmPrompt } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import EngagementInfoRow from "@gigsmart/seibutsu/engagement/EngagementInfoRow";
import TimesheetTabs from "@gigsmart/seibutsu/engagement/TimesheetTabs";
import WithEngagementCapability, {
  useHasEngagementCapability
} from "@gigsmart/seibutsu/engagement/WithEngagementCapability";
import useMileageIsValid from "@gigsmart/seibutsu/engagement/hooks/useMileageIsValid";
import { useSetEngagementTimesheet } from "@gigsmart/seibutsu/engagement/timesheetEditHooks";
import React, { useEffect, useRef } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { reviewTimesheetScreenApproveMutation } from "./__generated__/reviewTimesheetScreenApproveMutation.graphql";
import type { reviewTimesheetScreenQuery } from "./__generated__/reviewTimesheetScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "ReviewTimesheet">;

export default createSuspendedQueryContainer<reviewTimesheetScreenQuery, Props>(
  function ReviewTimesheetScreen({ route, navigation, retry, response }) {
    const engagementId = route.params?.id ?? "";
    const engagement = response?.node;
    const isEditable = engagement?.workerTimesheet?.editable ?? true;
    const resetOnLeaveRef = useRef(true);
    const unblock = useHistoryBlock({
      enabled: !!engagement?.workerTimesheet?.isOverridden && isEditable
    });

    const setTimesheet = useSetEngagementTimesheet();
    const [approveTimesheet] =
      useRelayMutationPromise<reviewTimesheetScreenApproveMutation>(graphql`
        mutation reviewTimesheetScreenApproveMutation(
          $input: ApproveEngagementTimesheetInput!
        ) {
          approveEngagementTimesheet(input: $input) {
            engagementTimesheet {
              id
              totalDurationWorked
              editable
              isOverridden
            }
            engagement {
              endsAt
              currentState {
                action
                name
              }
              gig {
                estimatedMileage
                timezone
              }
              inactiveCancelStates: states(
                first: 0
                query: "WHERE action = INACTIVE_CANCEL"
              ) {
                totalCount
              }
              original: timesheet(variant: SYSTEM) {
                isApproved
                estimatedMileage
                totalDurationWorked
                startedCount: states(first: 0, query: "WHERE action = START") {
                  totalCount
                }
                ...TimesheetList_timesheet @arguments(overridden: false)
              }
              workerTimesheet: timesheet(variant: WORKER) {
                editable
                isApproved
                isOverridden
                paymentStyle
                estimatedMileage
                totalDurationWorked
                ...TimesheetList_timesheet @arguments(overridden: true)
              }
              requesterTimesheet: timesheet(variant: REQUESTER) {
                editable
                isApproved
                isOverridden
                paymentStyle
                estimatedMileage
                totalDurationWorked
                ...TimesheetList_timesheet @arguments(overridden: true)
              }
            }
          }
        }
      `);

    const canApproveSystemTimesheet = useHasEngagementCapability(
      engagement?.capabilities ?? [],
      "APPROVE_SYSTEM_TIMESHEET"
    );

    // TODO: Discard changes on unmount if the timesheet is still editable
    useEffect(() => {
      return () => {
        const shouldReset =
          resetOnLeaveRef.current && !!engagement?.workerTimesheet?.editable;

        if (shouldReset) void handleResetTimesheet();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mileageIsValid =
      useMileageIsValid(
        engagement?.gig?.estimatedMileage,
        engagement?.original?.totalDurationWorked
      ) || !engagement?.gig?.estimatedMileage;

    const totalDurationWorked =
      engagement?.workerTimesheet?.totalDurationWorked ??
      engagement?.original?.totalDurationWorked;

    const timeWorked = duration.humanize(totalDurationWorked, "compact");
    const timeWorkedShort = duration.humanize(
      totalDurationWorked,
      "compact-no-seconds"
    );

    const handleEditTimesheet = () => {
      resetOnLeaveRef.current = false;
      unblock();
      navigation.push("EditTimesheet", { id: engagementId });
    };

    const handleResetTimesheet = async () => {
      await setTimesheet(engagement, "reset", engagement?.workerTimesheet?.id);
      retry();
    };

    const handleApproveTimesheet = () => {
      const timesheetId = engagement?.workerTimesheet?.id;
      if (!timesheetId) return;

      const onDo = async () => {
        try {
          await approveTimesheet({ input: { timesheetId } });
          resetOnLeaveRef.current = false;
          toast.success(
            "Your Updated Timesheet has been submitted to the Requester for approval"
          );
          navigation.replace("ShiftDetails", { id: engagementId });
        } catch (err: any) {
          toast.error(
            err.message ?? "Unexpected error while submitting timesheet"
          );
        }
      };

      confirmPrompt({
        title: "Submit Updated Timesheet",
        subTitle:
          "Are you sure you want to submit your Updated Timesheet to the requester? You will not be able to make edits after submitting.",
        yesLabel: "Submit Updated Timesheet",
        cancelLabel: "Cancel",
        onDo
      });
    };

    const handleApproveSystemTimesheet = async () => {
      const timesheetId = engagement?.original?.id;
      if (!timesheetId) return;
      try {
        await approveTimesheet({
          input: { timesheetId }
        });
        resetOnLeaveRef.current = false;
        toast.success(
          "Your Original Timesheet has been submitted to the Requester for approval"
        );
        navigation.replace("ShiftDetails", { id: engagementId });
      } catch (err: any) {
        toast.error(
          err.message ?? "Unexpected error while submitting timesheet"
        );
      }
    };

    return (
      <ScreenScroll testID="review-timesheet-screen">
        <EngagementInfoRow fragmentRef={engagement} />
        <Spacer />
        {WorkerApproveTimesheet.isEnabled() && canApproveSystemTimesheet && (
          <>
            <Surface>
              <ProductInfoRow
                variant="shadow"
                testID="worker-review-timesheet-time-remaining-row"
                color="danger"
                icon="clock"
                name={
                  <Text color="danger" variant="subheader">
                    <TimeRemaining
                      date={canApproveSystemTimesheet?.expiresAt}
                    />{" "}
                    Remain to Review
                  </Text>
                }
                note={
                  engagement?.workerTimesheet?.isOverridden
                    ? "Please submit your Timesheet as soon as possible"
                    : "Please approve your Timesheet as soon as possible"
                }
              />
              <ContentArea size="compact">
                <Text variant="note" align="center" color="neutral">
                  Please note, Requesters can approve your Timesheet before the
                  6-hour review window has elapsed.
                </Text>
              </ContentArea>
            </Surface>
            <Spacer />
          </>
        )}
        <Surface>
          <ContentArea>
            <Text color="primary" variant="header">
              TIMESHEET REVIEW
            </Text>
          </ContentArea>
          <Divider />
          <ContentRow justifyContent="space-between">
            <Text>Time Worked</Text>
            <Text>{timeWorked}</Text>
          </ContentRow>
          <TimesheetTabs
            variant="worker"
            fragmentRef={engagement}
            onResetTimesheet={handleResetTimesheet}
          />
          {isEditable ? (
            <ContentArea size="compact">
              <Button
                outline
                testID="edit-timesheet-button"
                icon="pen-to-square"
                label="Edit Timesheet"
                onPress={handleEditTimesheet}
              />
            </ContentArea>
          ) : (
            <Spacer />
          )}
        </Surface>
        {!!engagement?.workerTimesheet?.isOverridden && isEditable && (
          <ContentArea>
            <Button
              testID="submit-updated-timesheet-button"
              label={`Submit Updated Timesheet${
                timeWorkedShort ? ` (${timeWorkedShort})` : ""
              }`}
              onPress={handleApproveTimesheet}
            />
          </ContentArea>
        )}
        {WorkerApproveTimesheet.isEnabled() &&
          !engagement?.workerTimesheet?.isOverridden &&
          isEditable && (
            <WithEngagementCapability
              type="APPROVE_SYSTEM_TIMESHEET"
              fragmentRef={engagement}
            >
              <ContentArea>
                <Button
                  testID="approve-system-timesheet-btn"
                  label={`Approve Timesheet (${timeWorkedShort})`}
                  onPress={handleApproveSystemTimesheet}
                  disabled={!mileageIsValid}
                />
              </ContentArea>
            </WithEngagementCapability>
          )}
        <FooterSpacer />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query reviewTimesheetScreenQuery($id: ID!) {
        node(id: $id) {
          ... on Engagement {
            ...EngagementInfoRow_engagement
            ...TimesheetTabs_engagement
            ...timesheetEditHooksReset_engagement
            gig {
              reconcilesAt
              estimatedMileage
            }
            capabilities {
              type
              restrictedBy {
                message
              }
              expiresAt
              status
            }
            original: timesheet(variant: SYSTEM) {
              id
              totalDurationWorked
            }
            workerTimesheet: timesheet(variant: WORKER) {
              id
              totalDurationWorked
              editable
              isOverridden
            }
            ...WithEngagementCapability_engagement
          }
        }
      }
    `,
    variables: ({ route }) => ({
      id: route.params.id
    })
  }
);
