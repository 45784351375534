import {
  ConnectionHandler,
  type RecordSourceSelectorProxy
} from "@gigsmart/relay";
import { notificationsQuery } from "./use-notification-section";

export default function moveMessageUpdater(
  store: RecordSourceSelectorProxy<any>,
  messageId?: string | null | undefined
) {
  const root = store.getRoot();
  const viewer = root.getLinkedRecord("viewer");
  if (!viewer) return;

  const newConn = ConnectionHandler.getConnection(
    viewer,
    "notificationSection_messages",
    { query: notificationsQuery(true) }
  );

  const pastConn = ConnectionHandler.getConnection(
    viewer,
    "notificationSection_messages",
    { query: notificationsQuery(false) }
  );

  // sanity check - if some of the connections is not loaded (?) we don't have
  // much to do here
  if (!newConn || !pastConn) return;

  const moveEdge = (nodeId: string) => {
    const currentEdge = newConn
      ?.getLinkedRecords("edges")
      ?.find(
        (edge: any) => edge?.getLinkedRecord("node")?.getDataID() === nodeId
      );

    if (!currentEdge) return;

    const curInsertedAt = currentEdge
      ?.getLinkedRecord("node")
      ?.getValue("insertedAt");
    const edge = ConnectionHandler.createEdge(
      store,
      pastConn,
      currentEdge.getLinkedRecord("node"),
      "Message"
    );

    // need to copy currentEdge cursor value
    edge.copyFieldsFrom(currentEdge);

    let cursor: string | null = null;
    if (curInsertedAt) {
      const pastEdge = pastConn.getLinkedRecords("edges")?.find((edge) => {
        const pastInsertedAt = edge
          ?.getLinkedRecord<any>("node")
          ?.getValue("insertedAt");

        const cursor = edge.getValue("cursor") as string;
        return pastInsertedAt < curInsertedAt && !!cursor;
      });
      if (pastEdge) {
        cursor = pastEdge.getValue("cursor") as string;
      }
    }
    ConnectionHandler.insertEdgeBefore(pastConn, edge, cursor);
    ConnectionHandler.deleteNode(newConn, nodeId);
  };

  const messageIcon = viewer?.getLinkedRecord("messages", {
    first: 0,
    query:
      "WHERE viewedAt = NULL AND messageType IN [ALERT, CORPORATE, GIG_POSTED, GIG, MISCELLANEOUS, PAYMENT, JOB_BOOSTED]"
  });
  if (messageId) {
    moveEdge(messageId);
    const currentMessageCount =
      (messageIcon?.getValue("totalCount") as number) ?? 0;
    messageIcon?.setValue(currentMessageCount - 1, "totalCount");
  } else {
    messageIcon?.setValue(0, "totalCount");
    const allMessages: string[] | undefined = newConn
      ?.getLinkedRecords("edges")
      ?.map((edge: any) => edge?.getLinkedRecord("node")?.getDataID())
      ?.reverse();

    if (allMessages) {
      let n = allMessages.length;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      while (n--) allMessages[n] && moveEdge(allMessages[n]!);
    }
  }
}
