/**
 * @generated SignedSource<<ff1b4d7b46698ce4c935dd9ee9d6e8e9>>
 * @relayHash efebff13920403b9f7f28a6670ce79c9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:UUDKUX-DCqINuS30FANRK232ys0ewulT88b-hTBMzas

import { ConcreteRequest } from 'relay-runtime';
export type WorkerShiftScheduleSummaryRowQuery$variables = {
  engagementsQuery: string;
  seriesId: string;
};
export type WorkerShiftScheduleSummaryRowQuery$data = {
  readonly viewer: {
    readonly gigSeries?: {
      readonly node: {
        readonly availableGigs: {
          readonly edges: ReadonlyArray<{
            readonly distance: number | null | undefined;
            readonly node: {
              readonly actualStartsAt: string | null | undefined;
              readonly area: string | null | undefined;
              readonly endsAt: string | null | undefined;
              readonly id: string;
              readonly isRecurring: boolean;
              readonly startsAt: string | null | undefined;
              readonly timezone: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number;
        } | null | undefined;
        readonly engagements: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly gig: {
                readonly actualStartsAt: string | null | undefined;
                readonly area: string | null | undefined;
                readonly endsAt: string | null | undefined;
                readonly isRecurring: boolean;
                readonly startsAt: string | null | undefined;
                readonly timezone: string | null | undefined;
              };
              readonly id: string;
              readonly workerDistance: number | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type WorkerShiftScheduleSummaryRowQuery = {
  response: WorkerShiftScheduleSummaryRowQuery$data;
  variables: WorkerShiftScheduleSummaryRowQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagementsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "seriesId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "seriesId"
  }
],
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 90
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    (v3/*: any*/)
  ],
  "concreteType": "AvailableGigsConnection",
  "kind": "LinkedField",
  "name": "availableGigs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailableGigsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "availableGigs(first:90)"
},
v12 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "engagementsQuery"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerShiftScheduleSummaryRowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AvailableGigSeriesEdge",
                "kind": "LinkedField",
                "name": "gigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "EngagementsConnection",
                        "kind": "LinkedField",
                        "name": "engagements",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Engagement",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "gig",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v5/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkerShiftScheduleSummaryRowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AvailableGigSeriesEdge",
                "kind": "LinkedField",
                "name": "gigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "EngagementsConnection",
                        "kind": "LinkedField",
                        "name": "engagements",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Engagement",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "gig",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v5/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:UUDKUX-DCqINuS30FANRK232ys0ewulT88b-hTBMzas",
    "metadata": {},
    "name": "WorkerShiftScheduleSummaryRowQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "741517d10e410dfd15fc3c0120b5ac0e";

export default node;
