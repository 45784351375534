import {
  Avatar,
  Button,
  Column,
  ContentArea,
  Divider,
  IconCircle,
  Row,
  Stack,
  StarRating,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ReactNode } from "react";
import WorkerBlockedOrganizationReminder, {
  showWorkerBlockedOrganizationReminder
} from "../worker/WorkerBlockOrganizations/WorkerBlockedOrganizationReminder";
import type {
  RequesterInfoRow_gigLike$data,
  RequesterInfoRow_gigLike$key
} from "./__generated__/RequesterInfoRow_gigLike.graphql";

interface Props {
  engagementId?: string | null | undefined;
  workerId?: string | null | undefined;
  infoHeader?: string | ReactNode;
  infoBody?: string | ReactNode;
  showViewProfile?: boolean;
}

export function RequesterInfoRow({
  engagementId,
  workerId,
  infoHeader,
  infoBody,
  id,
  gigType,
  organization,
  showViewProfile = true,
  requester
}: FragmentContainerInnerComponentProps<RequesterInfoRow_gigLike$key, Props>) {
  const history = useHistory();
  const profilePhoto = getProfilePhoto(organization, requester);
  const uriPrefix = gigType === "PROJECT" ? "/projects" : "/shifts";
  const { overallRating, reviewsReceived } = requester ?? {};
  if (!organization && !requester) return null;
  return (
    <Surface>
      <ContentArea gap="standard">
        <Row gap="standard" alignItems="center">
          <Column>
            {profilePhoto && <Avatar uri={profilePhoto} />}
            {!profilePhoto && (
              <IconCircle size="medium" icon="building" color="primary" />
            )}
          </Column>
          <Column fill>
            <Stack size="slim">
              <Text color="primary" weight="bold" testID="requester-info-name">
                {getName(organization, requester)}
              </Text>
              <Text
                color="primary"
                variant="note"
                testID="requester-info-contact"
              >
                {getPrimaryContact(organization, requester)}
              </Text>
              <StarRating
                numberOfRatings={reviewsReceived?.totalCount ?? 0}
                rating={overallRating ?? 0}
                showRatings={true}
              />
            </Stack>
          </Column>
          <Column justifyContent="flex-end">
            {showViewProfile && (
              <Button
                testID="view-profile-btn"
                label="View Profile"
                outline={true}
                size="small"
                onPress={() => {
                  engagementId
                    ? history.push(`${uriPrefix}/${engagementId}/requester`)
                    : history.push(`/browse${uriPrefix}/${id}/requester`);
                }}
              />
            )}
          </Column>
        </Row>
        {!!showWorkerBlockedOrganizationReminder(organization?.id) && (
          <WorkerBlockedOrganizationReminder
            workerId={workerId ?? ""}
            organizationId={organization?.id ?? ""}
          />
        )}
        {(!!infoHeader || !!infoBody) && (
          <>
            <Divider />
            <Row>
              <Stack size="slim" fill={1}>
                {!!infoHeader && (
                  <Text weight="bold" testID="requester-info-header">
                    {infoHeader}
                  </Text>
                )}
                {!!infoBody && typeof infoBody === "string" ? (
                  <Text testID="requester-info-body">{infoBody}</Text>
                ) : (
                  infoBody
                )}
              </Stack>
            </Row>
          </>
        )}
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<
  RequesterInfoRow_gigLike$key,
  Props
>(
  graphql`
    fragment RequesterInfoRow_gigLike on GigLike {
      id
      gigType
      ... on Gig {
        organization {
          id
          name
          logoUrl
        }
        requester {
          firstName
          lastInitial
          displayName
          primaryOrganization {
            id
            name
            logoUrl
          }
          overallRating
          reviewsReceived(first: 0) {
            totalCount
          }
          profilePhoto {
            url
          }
        }
      }
      ... on GigSeries {
        organization {
          id
          name
          logoUrl
        }
        requester {
          firstName
          lastInitial
          displayName
          primaryOrganization {
            name
            logoUrl
          }
          overallRating
          reviewsReceived(first: 0) {
            totalCount
          }
          profilePhoto {
            url
          }
        }
      }
    }
  `,
  RequesterInfoRow
);

const getProfilePhoto = (
  organization: RequesterInfoRow_gigLike$data["organization"],
  requester: RequesterInfoRow_gigLike$data["requester"]
) => {
  return organization?.logoUrl ?? requester?.profilePhoto?.url;
};

function getName(
  organization: RequesterInfoRow_gigLike$data["organization"],
  requester: RequesterInfoRow_gigLike$data["requester"]
) {
  const { firstName, lastInitial } = requester ?? {};
  return organization?.name ?? `${firstName ?? ""} ${lastInitial ?? ""}`;
}

function getPrimaryContact(
  organization: RequesterInfoRow_gigLike$data["organization"],
  requester: RequesterInfoRow_gigLike$data["requester"]
) {
  return organization?.name
    ? `Primary Contact: ${requester?.displayName}`
    : null;
}
