import { Stack, Surface, Text, ToastProvider } from "@gigsmart/atorasu";
import React, { useEffect } from "react";
import Loader from "./Loader";

interface Props {
  message?: string;
}

export default function LoadingScreen({ message }: Props) {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 2_500);
    return () => clearTimeout(timeout);
  });
  return (
    <>
      <Surface fill>
        {show && (
          <Stack fill alignItems="center" justifyContent="center">
            <Loader />
            <Text variant="note" color="neutral">
              {message}
            </Text>
          </Stack>
        )}
      </Surface>
      <ToastProvider />
    </>
  );
}
