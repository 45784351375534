import {
  ActionRow,
  ContentArea,
  Divider,
  IconCircle,
  Image,
  Row,
  Stack,
  Surface,
  Tag,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";

interface Props {
  id?: string | null;
  boosted?: boolean | null;
  name?: string | null;
  logoUrl?: string | null;
  shiftCount?: number | null;
  netPay?: string | null;
  pickupEligible?: boolean | null;
}

export default function ShiftPreviewRow({
  id,
  boosted,
  name,
  shiftCount,
  logoUrl,
  netPay,
  pickupEligible
}: Props) {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  if (!id) return null;

  return (
    <Surface variant="outline">
      <ActionRow
        testID={`available-series-${id}`}
        key={`available-series-${id}`}
        variant="flat"
        leftSpacing="small"
        onPress={() => {
          navigation.push("BrowseShiftDetails", {
            id,
            type: pickupEligible ? "pickups" : "shifts"
          });
        }}
        eventEntityType="WorkerHomeAvailableShiftGig"
        title={
          <Stack horizontal alignItems="center" size="slim" fill={1}>
            {boosted && (
              <IconCircle color="emphasized" icon="chevrons-up" size="micro" />
            )}
            <Row fill>
              <Text color="black" variant="subheader" numberOfLines={1}>
                {name}
              </Text>
            </Row>
          </Stack>
        }
        {...(logoUrl
          ? {
              left: (
                <Image
                  height={36}
                  variant="square"
                  source={{
                    uri: logoUrl ?? ""
                  }}
                />
              )
            }
          : { icon: "business-time" })}
        color="warning"
        pressableIconColor="primary"
        iconSquareVariant="standard"
        note={
          <Text variant="note" color="neutral">
            {currency.humanize(netPay)}{" "}
            {shiftCount && <Text>• {pluralize(shiftCount, "Shift")}</Text>}
          </Text>
        }
      />
      {pickupEligible && (
        <>
          <Divider />
          <ContentArea size="compact" variant="compact">
            <Tag
              color="emphasized"
              icon="alarm-clock"
              label="Shift Available Today"
              variant="clear"
            />
          </ContentArea>
        </>
      )}
    </Surface>
  );
}
