import React, { useCallback } from "react";

import { useStyles } from "../style";
import StyledLargeButton, {
  type LargeButtonProps
} from "./styled-large-button";

type Props = LargeButtonProps & {
  readonly buttonColor?: "blue" | "orange";
};

export default ({ buttonColor = "blue", ...buttonProps }: Props) => {
  const { styles } = useStyles(
    ({ color }) => ({
      text: {
        color: color[buttonColor]
      },
      disabledText: {
        color: color.neutralDark
      }
    }),
    [buttonColor]
  );
  const textStyles = useCallback(
    (disabled?: boolean) => (disabled ? styles.disabledText : styles.text),
    [styles]
  );
  return <StyledLargeButton textStyles={textStyles} {...buttonProps} />;
};
