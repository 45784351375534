// convert a FormErrors array to an object with (string) field keys and (string)
// error message values
export function toErrorsObject(
  errors: any[] | null | undefined
): Record<string, string> {
  return errors?.reduce((acc, err: { field?: string; message?: string }) => {
    if (err.field) {
      return { ...acc, [err.field]: err.message };
    }
    return acc;
  }, {});
}

export function getNonFieldErrors(errors: Error[]): string {
  let errorMsg = "";
  if (errors) {
    const numFullErrors = 2;

    errorMsg = errors
      .slice(0, numFullErrors)
      .map((error: any) =>
        error
          ? `${error.field || ""} ${error.message || "unknown error "}`
          : "Unknown error"
      )
      .join(", ");
    const numRemainingErrors =
      errors.length > numFullErrors ? errors.length - numFullErrors : 0;
    if (numRemainingErrors > 0) {
      errorMsg += ` + ${numRemainingErrors} more ${
        numRemainingErrors === 1 ? "error" : "errors"
      }`;
    }
  }
  return errorMsg;
}
