/**
 * @generated SignedSource<<7d71fcaf7c3f003e893cfbe142bca659>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDocumentConsentCheck_user$data = {
  readonly id: string;
  readonly pendingConsents: ReadonlyArray<{
    readonly filename: string;
    readonly " $fragmentSpreads": FragmentRefs<"ConsentDocumentForm_document">;
  }>;
  readonly " $fragmentType": "UserDocumentConsentCheck_user";
};
export type UserDocumentConsentCheck_user$key = {
  readonly " $data"?: UserDocumentConsentCheck_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDocumentConsentCheck_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDocumentConsentCheck_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalDocument",
      "kind": "LinkedField",
      "name": "pendingConsents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConsentDocumentForm_document"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};

(node as any).hash = "85383a5307a579c897cdb85b21988c9b";

export default node;
