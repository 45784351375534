import { Button, ContentArea, Spacer, Stack } from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  FormSubmit,
  FormValues,
  type ValueObject
} from "@gigsmart/fomu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import EditMileage from "@gigsmart/seibutsu/engagement/EditMileage";
import type { ObjectPath } from "@gigsmart/type-utils";
import { DateTime } from "luxon";
import React, { type ReactNode, useMemo, useState } from "react";
import CollapsbileGigSchedule from "../gig/CollapsibleGigSchedule";
import EditTimesheetBreaks from "./EditTimesheetBreaks";
import EditTimesheetTimeWorkedCard from "./EditTimesheetTimeWorkedCard";
import type { EditTimesheetForm_engagement$key } from "./__generated__/EditTimesheetForm_engagement.graphql";
import timesheetInitialValues from "./timesheetInitialValues";

type StateOverrides = NonNullable<
  ObjectPath<
    EditTimesheetForm_engagement$key,
    [" $data", "timesheetVariant", "states", "edges"]
  >
>;

interface Props {
  create: boolean;
  title: string;
  submitLabel: string;
  onSubmit: (
    values: ValueObject,
    breaks: number[],
    overrides: StateOverrides,
    originalStartsAt: string | undefined,
    done: () => void
  ) => void;
  onRemoveTimeWorked?: () => void;
  fragmentRef?: EditTimesheetForm_engagement$key | null | undefined;
  appVariant: "worker" | "requester";
  extraActions?: ReactNode;
}

const EditTimesheetForm = ({
  create,
  title,
  submitLabel,
  onSubmit,
  onRemoveTimeWorked,
  fragmentRef,
  appVariant,
  extraActions
}: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment EditTimesheetForm_engagement on Engagement
      @argumentDefinitions(
        variant: { type: "EngagementTimesheetVariant" }
        overridden: { type: "Boolean" }
      ) {
        ...EditTimesheetTimeWorkedCard_engagement
          @arguments(variant: $variant, overridden: $overridden)
        gig {
          gigType
          estimatedMileage
          timezone
          ...CollapsibleGigSchedule_gig
        }
        startsAt
        endsAt
        worker {
          displayName
        }
        systemTimesheet: timesheet(variant: SYSTEM) {
          estimatedMileage
          states(first: 50, query: "ORDER BY transitionedAt ASC") {
            edges {
              node {
                __typename
                id
                name
                transitionedAt
                action
                ... on EngagementStateOverride {
                  overridesState {
                    id
                  }
                }
              }
            }
          }
        }
        timesheetVariant: timesheet(variant: $variant) {
          totalDurationWorked
          estimatedMileage
          states(
            first: 50
            overridden: $overridden
            query: "ORDER BY transitionedAt ASC"
          ) {
            edges {
              node {
                __typename
                id
                name
                transitionedAt
                action
                ... on EngagementStateOverride {
                  overridesState {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    fragmentRef ?? null
  );
  const gigTimezone = engagement?.gig?.timezone ?? "";
  const {
    initialValues,
    engagementStartTime,
    initialStartTime,
    initialEndTime,
    initialBreaks
  } = useMemo(
    () =>
      timesheetInitialValues(
        create,
        engagement?.timesheetVariant ?? engagement?.systemTimesheet ?? null,
        engagement?.startsAt ?? undefined,
        engagement?.endsAt ?? undefined,
        gigTimezone
      ),
    [create, engagement]
  );
  const [breaks, setBreaks] = useState(initialBreaks);
  const handleSubmit: FomuSubmitFn = ({ values }, done) => {
    const timesheet =
      engagement?.timesheetVariant ?? engagement?.systemTimesheet;
    let originalStartsAt = engagement?.systemTimesheet?.states?.edges?.find(
      (d) => d?.node?.action === "START"
    )?.node?.transitionedAt;
    if (!originalStartsAt) {
      originalStartsAt = engagement?.systemTimesheet?.states?.edges?.find(
        (d) => d?.node?.name === "SCHEDULED"
      )?.node?.transitionedAt;

      // Logic to avoid hitting same timestamp on the backend
      // (Time entries cannot be the same)
      if (originalStartsAt) {
        originalStartsAt = DateTime.fromISO(originalStartsAt)
          .plus({ milliseconds: 5 })
          .toISO();
      }
    }

    onSubmit(
      values,
      breaks,
      timesheet?.states?.edges ?? [],
      originalStartsAt,
      done
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      checkRemovedFields
    >
      <Stack>
        <CollapsbileGigSchedule fragmentRef={engagement?.gig} />
        {engagement?.gig?.estimatedMileage && (
          <EditMileage
            workerName={engagement?.worker?.displayName}
            appVariant={appVariant}
          />
        )}
        <EditTimesheetTimeWorkedCard
          title={title}
          fragmentRef={engagement}
          engagementStartTime={engagementStartTime}
          initialStartTime={initialStartTime}
          initialEndTime={initialEndTime}
          breaks={breaks}
          onRemoveTimeWorked={onRemoveTimeWorked}
          allowReset={!create && appVariant === "requester"}
          appVariant={appVariant}
        />
        <EditTimesheetBreaks
          breaks={breaks}
          setBreaks={setBreaks}
          volunteer={engagement?.gig?.gigType === "VOLUNTEER"}
          initialValues={initialValues}
          timezone={gigTimezone}
        />
        <FormValues>
          {({ values }) => (
            <FormSubmit>
              {({ submit, invalid, submitting }) => {
                const isEquals = checkEquals(
                  breaks,
                  initialValues,
                  values ?? {}
                );
                return (
                  <ContentArea size="none">
                    <Stack>
                      {extraActions}
                      <Button
                        testID="edit-timesheet-form-submit"
                        label={submitLabel}
                        disabled={
                          create ? invalid : invalid || isEquals || submitting
                        }
                        onPress={submit}
                      />
                    </Stack>
                  </ContentArea>
                );
              }}
            </FormSubmit>
          )}
        </FormValues>
      </Stack>
      <Spacer />
    </Form>
  );
};

export default EditTimesheetForm;

function checkEquals(
  breaks: number[],
  initialValues: ValueObject,
  values: ValueObject
) {
  return (
    initialValues.mileage === values.mileage &&
    initialValues.includeBreaks === values.includeBreaks &&
    checkDateEquals(initialValues, values, "startTimeDate") &&
    checkDateEquals(initialValues, values, "endTimeDate") &&
    initialValues.startTimeTime === values.startTimeTime &&
    initialValues.startTimeAmpm === values.startTimeAmpm &&
    initialValues.endTimeTime === values.endTimeTime &&
    initialValues.endTimeAmpm === values.endTimeAmpm &&
    (values.includeBreaks ? breaks : []).every(
      (n) =>
        checkDateEquals(initialValues, values, `break${n}StartDate`) &&
        checkDateEquals(initialValues, values, `break${n}EndDate`) &&
        initialValues[`break${n}StartTime`] === values[`break${n}StartTime`] &&
        initialValues[`break${n}StartAmpm`] === values[`break${n}StartAmpm`] &&
        initialValues[`break${n}EndTime`] === values[`break${n}EndTime`] &&
        initialValues[`break${n}EndAmpm`] === values[`break${n}EndAmpm`]
    )
  );
}

function checkDateEquals(
  initialValues: ValueObject,
  values: ValueObject,
  attr: string
) {
  const initial = initialValues[attr];
  const curr = values[attr];
  return !!curr && initial?.equals(curr);
}
