/**
 * @generated SignedSource<<4c3f29ba0b25a9706953658b7014ee45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGigSeriesData$data = {
  readonly id: string;
  readonly name: string | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "useGigSeriesData";
};
export type useGigSeriesData$key = {
  readonly " $data"?: useGigSeriesData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGigSeriesData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGigSeriesData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigSeries",
  "abstractKey": null
};

(node as any).hash = "8b24795a556e4bc8dd8dde217bf8d917";

export default node;
