import { ContentArea, Surface, Text } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import WorkerBlockedOrganizationsList from "./WorkerBlockedOrganizationsList";
import type { blockedOrganizationManagementScreenQuery } from "./__generated__/blockedOrganizationManagementScreenQuery.graphql";

export default createSuspendedQueryContainer<blockedOrganizationManagementScreenQuery>(
  function BlockedOrganizationManagementScreen({ response }) {
    return (
      <Surface fill>
        <ContentArea gap="standard">
          <Text variant="title" color="primary">
            Blocked Organization Management
          </Text>
          <Text variant="body">
            You have blocked the following Organizations. Unblock to view and
            apply to their available Shifts.
          </Text>
          <WorkerBlockedOrganizationsList fragmentRef={response?.viewer} />
        </ContentArea>
      </Surface>
    );
  },
  {
    query: graphql`
  query blockedOrganizationManagementScreenQuery(
    $first: Int,
    $where: CqlFilterWorkerBlockedOrganizationInput,
    $orderBy: [CqlOrderWorkerBlockedOrganizationInput!],
    $after: String
  ) {
    viewer {
      ... on Worker {
        id
        ...WorkerBlockedOrganizationsList_worker
        @arguments(first: $first, where: $where, orderBy: $orderBy, after: $after)
      }
    }
  }
  `,
    variables: () => ({
      first: 10,
      orderBy: [{ insertedAt: { direction: "DESC" } }]
    })
  }
);
