import { ScreenScroll, Spacer, Stack } from "@gigsmart/atorasu";
import { RemoveBenefitsMenu } from "@gigsmart/feature-flags";
import { useHistory } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import WorkerApplyFooter from "@gigsmart/seibutsu/gig/WorkerApplyFooter";
import RequesterInfoRow from "@gigsmart/seibutsu/requester/RequesterInfoRow";
import { WorkerBenefitsRow } from "@gigsmart/seibutsu/worker/WorkerBenefitsRow";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import React from "react";
import {
  GigDescription,
  GigRemainingTime,
  GigSummaryRows
} from "../gig/engagement-details";
import GigNotAvailable from "../notifications/gig-not-available";
import type { availableGigSeriesDetailQuery } from "./__generated__/availableGigSeriesDetailQuery.graphql";
import ProjectGigNav from "./project-gigs-screen/project-gig-nav";

interface Props {
  id: string;
}

export default createSuspendedQueryContainer<
  availableGigSeriesDetailQuery,
  Props
>(
  function AvailableGigSeriesDetail({ response: { viewer } = {} }) {
    const history = useHistory();
    const series = viewer?.gigSeries?.node;
    const gigEdge = series?.nextGig?.edges?.[0];
    const isProject = series?.gigType === "PROJECT";

    if (!gigEdge?.node) {
      return (
        <GigNotAvailable
          variant={series?.gigType === "PROJECT" ? "project" : "shift"}
          showModal
          onClose={() => history.push("/browse")}
        />
      );
    }

    return (
      <ScreenScroll
        testID="available-gig-series-details-screen"
        header={isProject && <ProjectGigNav />}
        footer={!!series?.id && <WorkerApplyFooter fragmentRef={series} />}
      >
        <GigRemainingTime
          variant="default"
          startsAt={gigEdge.node.startsAt}
          endsAt={gigEdge.node.endsAt}
        />
        <Stack>
          <RequesterInfoRow
            fragmentRef={series}
            workerId={viewer?.id}
            showViewProfile={!isProject}
          />
          <GigDescription gigLikeRef={series} isApplied />
          <GigSummaryRows availableGigSeriesRef={viewer?.gigSeries} />
          {RemoveBenefitsMenu.isDisabled() && <WorkerBenefitsRow />}
          {showWorkerBlockOrg(series) && (
            <>
              <BlockOrganizationCollapsibleRow
                organizationId={series?.organization?.id ?? ""}
              />
              <Spacer />
            </>
          )}
        </Stack>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query availableGigSeriesDetailQuery($id: ID!) {
        viewer {
          ... on Worker {
            id
            gigSeries(id: $id) {
              ...gigSummaryRows_availableGigSeries
              node @trackImpressions(action: VIEWED) {
                id
                gigType
                organization {
                  id
                }
                ...BlockOrganizationCollapsibleRow_shift
                ...WorkerApplyFooter_gigLike
                ...gigDescription_gigLike
                ...RequesterInfoRow_gigLike
                nextGig: availableGigs(first: 1) {
                  edges {
                    ...ShiftDescriptionHeader_availableGig
                    node {
                      startsAt
                      endsAt
                      payRate
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ id }) => ({ id })
  }
);
