import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { useAutoBlockSetting_organizationRequester$key } from "./__generated__/useAutoBlockSetting_organizationRequester.graphql";

export type useAutoBlockSettingFragmentType =
  | useAutoBlockSetting_organizationRequester$key
  | null
  | undefined;

export default function useAutoBlockSetting(
  viewerRef: useAutoBlockSettingFragmentType
) {
  const result = useRelayFragment(
    graphql`
      fragment useAutoBlockSetting_organizationRequester on OrganizationRequester {
        organization {
          settings {
            autoBlock
          }
        }
      }
    `,
    viewerRef
  );

  const autoBlock = !!result?.organization?.settings?.autoBlock;

  return { autoBlock };
}
