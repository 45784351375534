/**
 * @generated SignedSource<<5bba11f379dc353985a323a4532bb296>>
 * @relayHash 68309ce400f9616014223157687acaa0
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:VlU_c1CS3DFiN43NaBEdvT11MpF2iI_i7Cjg6hNSirU

import { ConcreteRequest } from 'relay-runtime';
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type RequestUserPasswordResetInput = {
  emailAddress: string;
  userType: UserType;
};
export type newForgotPasswordMutation$variables = {
  input: RequestUserPasswordResetInput;
};
export type newForgotPasswordMutation$data = {
  readonly requestUserPasswordReset: {
    readonly ok: boolean;
  } | null | undefined;
};
export type newForgotPasswordMutation = {
  response: newForgotPasswordMutation$data;
  variables: newForgotPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestUserPasswordResetPayload",
    "kind": "LinkedField",
    "name": "requestUserPasswordReset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:VlU_c1CS3DFiN43NaBEdvT11MpF2iI_i7Cjg6hNSirU",
    "metadata": {},
    "name": "newForgotPasswordMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "318d4dec11ae56456e97afa96f6f020f";

export default node;
