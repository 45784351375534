import { captureError } from "@gigsmart/dekigoto";
import { times } from "lodash";
import type { EmitterSubscription } from "react-native";
import { RNOnfleetSDK, addListener } from "./RNOnfleetSDK";
import type { IRNOnfleetSDK } from "./types";
import type { LoginOpt, RNOnfleetDriver, RNOnfleetOrganization } from "./types";

class RNOnfleetService {
  phoneNumber?: string;
  driver?: RNOnfleetDriver | null;
  organization?: RNOnfleetOrganization | null;
  listeners?: EmitterSubscription[];

  on: typeof addListener = addListener;

  //
  startTask: IRNOnfleetSDK["startTask"] =
    RNOnfleetSDK.startTask.bind(RNOnfleetSDK);

  completeTask: IRNOnfleetSDK["completeTask"] =
    RNOnfleetSDK.completeTask.bind(RNOnfleetSDK);

  mount() {
    this.listeners = [
      addListener("driver", (driver) => (this.driver = driver)),
      addListener("organization", (org) => (this.organization = org))
    ];
  }

  async login(credentials?: LoginOpt | null): Promise<string | null> {
    if (credentials?.password && credentials.phoneNumber) {
      if (!this.listeners) this.mount();
      try {
        return await RNOnfleetSDK.login({
          ...credentials,
          nextPassword: this.nextPassword()
        });
      } catch (err) {
        captureError(err);
      }
    }

    return null;
  }

  async setOnDuty(val: boolean) {
    try {
      await RNOnfleetSDK.setOnDuty(val);
    } catch (err) {
      console.error(err);
    }
  }

  async unmount(shouldLogout = false) {
    if (this.listeners) {
      this.listeners.forEach((it) => it.remove());
      this.listeners = undefined;
    }

    if (shouldLogout) void this.logout();
  }

  async logout() {
    this.driver = undefined;
    this.phoneNumber = undefined;
  }

  private nextPassword() {
    return times(3, () =>
      Math.round(Math.random() * 10 ** 8).toString(36)
    ).join("-");
  }
}

export const onfleet = new RNOnfleetService();
