import { Column } from "@gigsmart/atorasu";
import React from "react";
import { StyleSheet } from "react-native";
import Svg, { Path } from "react-native-svg";

const RATIO = 343 / 72;

export default function CoBrandedHealthcareSvg() {
  return (
    <Column style={{ width: "100%", aspectRatio: RATIO }}>
      <Svg viewBox="0 0 576 120.91" style={StyleSheet.absoluteFill}>
        <Path d="M0 0h576v120.91H0z" fill="#eef1f7" />
        <Path
          d="M215.4 71.51v-19.1h-6.8v-2.4h16.2v2.4h-6.7v19.1h-2.7"
          fill="#003b5c"
        />
        <Path
          d="M204.3 56.51c0-2.5-1.7-4.1-4.3-4.1h-5.6v8.2h5.6c2.6 0 4.3-1.7 4.3-4.1Zm-.2 15-5.5-8.6h-4.3v8.6h-2.6v-21.5h8.6c3.9 0 6.7 2.5 6.7 6.5s-2.6 6-5.6 6.3l5.7 8.7h-3Z"
          fill="#003b5c"
        />
        <Path
          d="m179.4 52.81-4.5 11.6h9.1l-4.6-11.6Zm7.2 18.7-1.9-4.8h-10.6l-1.9 4.8h-3.1l8.6-21.6h3.3l8.6 21.6h-3Z"
          fill="#003b5c"
        />
        <Path
          d="M164.6 71.51v-18.1l-7.4 18.1h-1.1l-7.3-18.1v18.1h-2.6v-21.5h3.9l6.7 16.59 6.5-16.59h3.8v21.5h-2.5"
          fill="#003b5c"
        />
        <Path
          d="m126.9 68.51 1.6-2.1c1.4 1.6 3.7 3.1 6.7 3.1 3.8 0 5-2.1 5-3.7 0-5.6-12.7-2.5-12.7-10.1 0-3.5 3.2-6 7.4-6 3.3 0 5.7 1.1 7.6 3l-1.6 2c-1.6-1.8-3.8-2.5-6.2-2.5-2.6 0-4.4 1.4-4.4 3.5 0 4.8 12.6 2.1 12.6 10.1 0 3.1-2.1 6.4-7.9 6.4-3.6-.3-6.4-1.7-8.1-3.7"
          fill="#003b5c"
        />
        <Path
          d="M103.6 60.81c0-6.8 5.2-11.1 11.6-11.1 4.8 0 7.6 2.5 9.2 5.2l-4.6 2.5c-.8-1.5-2.5-2.7-4.6-2.7-3.5 0-6 2.6-6 6.2s2.5 6.2 6 6.2c1.6 0 3.2-.6 3.9-1.3v-1.6h-4.8v-4.4h10.4v7.9c-2.3 2.6-5.5 4.2-9.5 4.2-6.4 0-11.6-4.3-11.6-11.1"
          fill="#003b5c"
        />
        <Path d="M95.8 71.51h5.4v-21.5h-5.4v21.5z" fill="#003b5c" />
        <Path
          d="M71.8 60.81c0-6.8 5.2-11.1 11.6-11.1 4.8 0 7.6 2.5 9.2 5.2l-4.6 2.5c-.8-1.5-2.5-2.7-4.6-2.7-3.5 0-6 2.6-6 6.2s2.5 6.2 6 6.2c1.6 0 3.2-.6 3.9-1.3v-1.6h-4.8v-4.4h10.4v7.9c-2.3 2.6-5.5 4.2-9.5 4.2-6.5 0-11.6-4.3-11.6-11.1"
          fill="#003b5c"
        />
        <Path
          d="M42.9 50.6c2.7-2.7 6.3-4.2 10.1-4.2v-1.9c-8.9 0-16.2 7.3-16.2 16.3h1.9c0-3.9 1.5-7.5 4.2-10.2"
          fill="#dc4405"
        />
        <Path
          d="M53 75.2v1.9c4 0 7.6-1.5 10.5-3.8l-1.2-1.5c-2.6 2.2-5.8 3.4-9.3 3.4"
          fill="#dc4405"
        />
        <Path
          d="M48 60.81h-1.9c0 3.8 3.1 7 6.9 7v-1.9c-2.7 0-5-2.3-5-5.1"
          fill="#dc4405"
        />
        <Path
          d="M53 56.91c-1.1 0-1.9-.9-1.9-2s.9-2 1.9-2v-1.9c-2.1 0-3.8 1.7-3.8 3.9s1.7 3.9 3.8 3.9c1.1 0 2 .9 2 2s-.9 2-2 2v1.9c2.1 0 3.8-1.7 3.8-3.9.1-2.2-1.6-3.9-3.8-3.9"
          fill="#dc4405"
        />
        <Path
          d="M53 69.01c-4.5 0-8.1-3.6-8.1-8.2 0-1.5.4-2.9 1.1-4.1l-1.6-.9c-.8 1.5-1.4 3.2-1.4 5 0 5.6 4.5 10.1 10 10.1 4.8 0 8.8-3.4 9.8-7.9h-1.9c-1 3.4-4.1 6-7.9 6"
          fill="#dc4405"
        />
        <Path
          d="M58.1 59.81v1.9h6.2c-.2 2.6-1.4 5.1-3.3 7-2.1 2.1-4.9 3.3-7.9 3.3s-5.8-1.2-7.9-3.3c-2.1-2.1-3.3-5-3.3-8s1.2-5.8 3.3-8c2.1-2.1 4.9-3.3 7.9-3.3 2.6 0 5.2.9 7.2 2.6l1.2-1.5c-2.3-1.9-5.2-3.1-8.5-3.1-7.3 0-13.1 5.9-13.1 13.2s5.9 13.2 13.1 13.2 12.7-5.3 13.1-12.2v-1.9l-8 .1"
          fill="#dc4405"
        />
        <Path d="M249.8 29.41V92.2" stroke="#003b5c" strokeMiterlimit={10} />
        <Path
          d="M396.3 35.51c-.7.3-1.6-.1-1.9-1-.3-.8 0-1.7.7-2s1.6.1 1.9 1c.4.8.1 1.7-.7 2Z"
          fill="#69488f"
        />
        <Path
          d="m400.1 41.81-1.7-1.8 1.3-1.41 1.6 1.91-1.2 1.3z"
          fill="#71c054"
        />
        <Path
          d="M398.8 44.21c-.4-.8-1.6-2.7-3.2-3-.3 0-.6-.1-.9-.1-.6 0-1.3.1-1.8.4-.2.1-.4.2-.5.4 0 0-.1 0-.1.1-.6.3-1.1.8-1.1 1.6 0 .3.1.6.2 1 .5 1.3 1.9 1.9 3 1.9h.5c.4-.1.7-.3.8-.6.4-.3.7-.8.7-1.3 0-.4-.2-.8-.6-1.1-.3-.2-.6-.3-.9-.3-.4 0-.6.2-.8.4-.5.1-.7.4-.8.7 0 .2.1.3.2.4s.2.1.2.1l.6-.5c-.1-.1-.2-.2-.2-.4v-.2h.1c.2 0 .4 0 .7.2.6.3.8.8.8 1.3 0 .3-.1.5-.2.7-.2.1-.4.2-.6.2-1.1 0-2.6-.8-2.9-2.4v-.4c0-.5.1-.8.4-1.1.6-.3 1.2-.4 1.8-.4 1.5 0 3.2 1.6 3.8 2.3.3.4.7 1.2 1 1.8.4.7.6 1.3.6 1.3h.1c.1-.1-.6-2.1-.9-3Z"
          fill="#69488f"
        />
        <Path
          d="m393.6 38.41-1.9.5-.4-1.2 1.9-.5.4 1.2zM409.3 43.1c.4-.4 1-.4 1.5 0s.5 1.1.2 1.5c-.4.4-1 .4-1.5 0s-.6-1.1-.2-1.5ZM405.2 39.1c.1.4-.1.8-.5 1-.5.2-1 0-1.1-.4s.1-.8.5-1c.5-.2.9 0 1.1.4Z"
          fill="#fcb12f"
        />
        <Path d="m406.4 41.21 5-1.9 1.2-2.9-6.2 4.8z" fill="#69488f" />
        <Path
          d="m406.9 43.91-3.7 1.5-1.3 5.19 5-6.69zM407.7 29.51c-.4-.3-.9-.4-1.4-.5-.4-.2-.8-.3-1.2-.3-.9 0-1.8.3-2.5.7-1.5.9-2 3.3-2 4.3v.1c0 1 .2 3.2.2 3.2h.1s0-.7.1-1.6c.1-.8.1-1.8.3-2.3.2-.9 1.3-3.2 2.9-3.8.5-.2 1.2-.3 1.8-.3h.2c.5.2.8.7 1 1.4.1.2.1.5.1.7 0 1.3-1 2.4-2 2.8-.2.1-.3.1-.5.1h-.3c-.1 0-.1-.1-.2-.2-.3-.3-.5-.7-.5-1.1 0-.3.1-.7.4-1 .2-.3.5-.4.7-.5l.1.1c.1.1.1.2.1.2v.4l.9.3s.2-.2.2-.5c0-.1 0-.2-.1-.2-.1-.2-.5-.5-.9-.5h-.2c-.2-.1-.4-.2-.6-.2-.4 0-.8.2-1.1.8-.1.3-.2.5-.2.8 0 .8.7 1.5 1.4 1.6.3.2.6.3.9.3h.2c1.3-.2 2.8-1.4 2.8-3.2.1-.7-.3-1.3-.7-1.6Z"
          fill="#71c054"
        />
        <Path
          d="M296 50.01V71.6h-4v-9.29h-11.2v9.29h-4V50.01h4v8.9H292v-8.9h4zM318.7 60.81c0-4.4-3.2-7.6-7.6-7.6s-7.6 3.2-7.6 7.6 3.2 7.6 7.6 7.6 7.6-3.2 7.6-7.6Zm-19.3 0c0-6.4 4.9-11.1 11.6-11.1s11.6 4.7 11.6 11.1-4.9 11.1-11.6 11.1-11.6-4.7-11.6-11.1ZM343.8 60.81c0-4.4-3.2-7.6-7.6-7.6s-7.6 3.2-7.6 7.6 3.2 7.6 7.6 7.6 7.6-3.2 7.6-7.6Zm-19.3 0c0-6.4 4.9-11.1 11.6-11.1s11.6 4.7 11.6 11.1-4.9 11.1-11.6 11.1-11.6-4.7-11.6-11.1ZM359.9 53.41h-4.7v8.6h4.7c3.5 0 5.3-1.6 5.3-4.3.1-2.8-1.8-4.3-5.3-4.3Zm5.5 18.19-4.4-6.3h-5.7v6.3h-4V50h8.9c5.7 0 9.2 2.9 9.2 7.7 0 3.3-1.7 5.7-4.6 6.8l5 7.1h-4.4ZM385.6 63.41l-4.1-9.4-4 9.4h8.1Zm1.3 3.19h-10.8l-2.1 5h-4.1l9.7-21.6h4l9.8 21.6h-4.2l-2.3-5ZM403.2 63.91v7.69h-4v-7.59l-8.5-14h4.3l6.3 10.5 6.4-10.5h4l-8.5 13.9zM436.3 50.01V71.6h-3.1v-9.69h-12.4v9.69h-3V50.01h3v9.3h12.4v-9.3h3.1zM456.8 68.91v2.69h-15.7V50.01h15.3v2.59h-12.2v6.71h10.9v2.6h-10.9v7h12.6zM475.5 63.71l-4.7-10.6-4.7 10.6h9.4Zm1.1 2.49h-11.5l-2.4 5.4h-3.2l9.8-21.6h3.1l9.8 21.6H479l-2.4-5.4ZM486.1 50.01h3.1v18.9h11.7v2.69h-14.8V50.01zM506.7 52.6h-7.4v-2.59h17.9v2.59h-7.4v19h-3.1v-19zM539.2 50.01V71.6H536v-9.69h-12.3v9.69h-3.2V50.01h3.2v9.3H536v-9.3h3.2z"
          fill="#69488f"
        />
      </Svg>
    </Column>
  );
}
