import { toast } from "@gigsmart/atorasu";
import * as stripe from "@gigsmart/isomorphic-payments";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import { DateTime } from "luxon";
import { useCallback } from "react";
import type { workerAccountSetMutation } from "./__generated__/workerAccountSetMutation.graphql";

interface Values {
  paymentMethodType: "BankAccount" | "BankCard";
  bankAccount?: {
    type: "BankAccount";
    accountNumber: string;
    accountHolderName: string;
    routingNumber: string;
  };
  bankCard?: {
    type: "BankCard";
    name: string;
    number: string;
    cvc: string;
    expiration: string;
    addressZip: string;
  };
}

export function useEditWorkerPayableAccount() {
  const [setWorkerPayableAccount] =
    useRelayMutationPromise<workerAccountSetMutation>(
      graphql`
        mutation workerAccountSetMutation(
          $input: SetWorkerPayableAccountInput!
        ) {
          setWorkerPayableAccount(input: $input) {
            worker {
              accessState
            }
            workerPayableAccount {
              paymentMethod {
                __typename
                last4
                accountHolderName
                ... on BankCard {
                  expirationMonth
                  expirationYear
                  postalCode
                }
                ... on BankAccount {
                  routingNumber
                }
              }
            }
          }
        }
      `
    );

  return useCallback(async (values: Values, onCompleted: () => void) => {
    try {
      const token = await getToken(values);
      if (!token) return;
      const res = await setWorkerPayableAccount(
        {
          input: { token: token.id }
        },
        {
          onCompleted
        }
      );

      return res.setWorkerPayableAccount?.workerPayableAccount?.paymentMethod;
    } catch (err) {
      toast.error(err.message || "Something Went Wrong");
      throw err;
    }
  }, []);
}

async function getToken(values: Values) {
  const data =
    values.paymentMethodType === "BankAccount"
      ? values.bankAccount
      : values.paymentMethodType === "BankCard"
        ? values.bankCard
        : null;
  if (!data) throw new Error("invalid payment type");

  let exp: DateTime;
  switch (data.type) {
    case "BankAccount":
      return await stripe.createTokenWithBankAccount({
        accountNumber: data.accountNumber,
        accountHolderName: data.accountHolderName,
        routingNumber: data.routingNumber,
        country: "US",
        currency: "USD"
      });
    case "BankCard":
      exp = DateTime.fromFormat(data.expiration, "MM/yy");
      return await stripe.createTokenWithCard({
        name: data.name,
        number: data.number,
        cvc: Number.parseInt(data.cvc, 10),
        expMonth: exp.month,
        expYear: exp.year,
        currency: "USD"
      });

    default:
      return null;
  }
}
