import React from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  style?: ViewStyleProp;
  size?: number;
  marginTop?: number;
  marginBottom?: number;
  maxWidth?: number;
}

export default function Divider({
  style,
  size = 1,
  marginTop,
  marginBottom,
  maxWidth
}: Props) {
  const { styles } = useStyles(
    ({ color, unit }) => ({
      divider: {
        backgroundColor: color.neutralLight,
        height: size,
        marginTop: marginTop ?? 0,
        marginBottom: marginBottom ?? unit.margin.small,
        ...(!!maxWidth && { maxWidth, width: "100%", marginHorizontal: "auto" })
      }
    }),
    [size, marginBottom, marginTop]
  );
  return <View style={[styles.divider, style]} />;
}
