import React from "react";
import { Dimensions, View } from "react-native";

import { Flex } from "../flex";
import { useStyles } from "../style";
import SmallButton from "../tappable/small-buttons/small-button";
import { StyledText } from "../text";

interface Props {
  column1Title: string;
  column1Subtitle: string;
  column2Text: string;
  column3Text: string;
  first?: boolean;
  disabled?: boolean;
  onLinkPress?: () => void;
}

const { width } = Dimensions.get("window");
const paddingUnits = width < 375 ? 0 : 4;

export default function ThreeColumnRow({
  column1Title,
  column1Subtitle,
  onLinkPress,
  column2Text,
  column3Text,
  first = false,
  disabled = false
}: Props) {
  const { styles, theme } = useStyles(({ color, font, units }) => ({
    container: {
      flexDirection: "row",
      alignItems: "center",
      borderBottomColor: color.neutralLight,
      borderBottomWidth: 1,
      paddingHorizontal: units(paddingUnits),
      paddingVertical: units(1),
      minHeight: units(13)
    },
    firstContainer: {
      borderTopColor: color.neutralLight,
      borderTopWidth: 1
    },
    titleText: {
      paddingBottom: units(0.5)
    },
    column3Text: {
      textAlign: "right",
      fontSize: font.size.bodyCopy
    },
    disabledText: {
      color: color.neutralDark
    },
    button: {
      paddingHorizontal: 0
    }
  }));

  return (
    <View style={[styles.container, first && styles.firstContainer]}>
      <Flex fill={1} basis="48%">
        <StyledText
          color={disabled ? "neutralDark" : undefined}
          numberOfLines={1}
          style={styles.titleText}
        >
          {column1Title}
        </StyledText>
        <StyledText color="neutralDark" fontSize={theme.font.size.small}>
          {column1Subtitle}
        </StyledText>
      </Flex>
      <Flex fill={1} basis="25%" justifyContent="center" alignItems="center">
        {disabled || (
          <SmallButton
            title={column2Text}
            onPress={onLinkPress}
            containerStyle={styles.button}
          />
        )}
      </Flex>
      <Flex fill={1} basis="25%" justifyContent="center">
        <StyledText
          color={disabled ? "neutralDark" : undefined}
          style={styles.column3Text}
        >
          {column3Text}
        </StyledText>
      </Flex>
    </View>
  );
}
