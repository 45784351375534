import type { IconName } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { ClearInlineButton, Divider, ThumbnailRow } from "@gigsmart/katana";
import React from "react";
import { View } from "react-native";

interface Props {
  spacing?: number;
  name?: string | null;
  actionLabel?: string;
  variant?: "requester" | "worker";
  numberOfLines?: number;
  thumbnailIconName?: IconName;
  iconLabel?: IconName;
  thumbnailIconColor?: string;
  thumbnailTintColor?: string;
}
const GigCardHeader = ({
  variant = "requester",
  spacing = 5,
  name,
  actionLabel,
  numberOfLines,
  thumbnailIconName,
  thumbnailIconColor,
  thumbnailTintColor,
  iconLabel
}: Props) => {
  const styles = useStyles(
    ({ getUnits }) => ({
      container: {
        paddingTop: getUnits(spacing),
        paddingHorizontal: getUnits(spacing),
        paddingBottom: getUnits(0)
      },
      name: { flex: 1 },
      divider: { marginTop: getUnits(1.5), marginBottom: 0 }
    }),
    [spacing]
  );

  const { thumbVariant, buttonColor } =
    variant === "requester"
      ? {
          thumbVariant: "heading" as const,
          buttonColor: "neutralDark"
        }
      : {
          thumbVariant: "cardTitle" as const,
          buttonColor: "blue"
        };

  return (
    <View style={styles.container}>
      <ThumbnailRow
        variant={thumbVariant}
        numberOfLines={numberOfLines}
        iconName={thumbnailIconName}
        iconColor={thumbnailIconColor}
        tintColor={thumbnailTintColor}
        iconLabel={iconLabel}
        label={name ?? ""}
        labelTestID="gig-name"
        rightAccessory={
          !!actionLabel && (
            <ClearInlineButton
              tintColor={buttonColor}
              title={actionLabel}
              disabled
              small
            />
          )
        }
      />
      {variant !== "worker" && <Divider style={styles.divider} />}
    </View>
  );
};

export default GigCardHeader;
