import React from "react";

import { Column, Row, Spacer, Stack } from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import {
  FomuStyledCreditCardInput,
  FomuStyledTextInput
} from "@gigsmart/isomorphic-shared/fomu-inputs";
import { numericKeyboardType } from "@gigsmart/katana";
import moment from "moment-timezone";

export default function DebitCardFields() {
  const keyboardType = numericKeyboardType();
  return (
    <Stack>
      <FomuStyledTextInput
        name="name"
        validates={[Validator.presence({ message: "invalid name" })]}
        placeholder="Full Name"
        label="Name on the Card"
        eventTargetName="Input Full Name"
        testID="card-name"
      />
      <FomuStyledCreditCardInput
        name="number"
        sensitive="card"
        label="Card Number"
        eventTargetName="Input Card Number"
        placeholder="Number"
        testID="card-number"
      />
      <Row fill justifyContent="space-between">
        <FomuStyledTextInput
          fill
          sensitive="card"
          name="cvc"
          validates={[
            Validator.length({ min: 3, max: 4, message: "invalid code" })
          ]}
          label="CVV"
          eventTargetName="Input CVV"
          keyboardType={keyboardType}
          maxLength={4}
          placeholder="000"
          testID="card-cvc"
          maskFn={(val) => (val.match(/\d{1,5}/g) || []).toString()}
        />
        <Spacer horizontal />
        <FomuStyledTextInput
          fill
          name="expiration"
          eventTargetName="Input Expiration Date"
          validates={[
            Validator.date({
              format: "MM/YY",
              message: "invalid date",
              options: { min: moment() }
            }),
            Validator.length({
              exact: 5,
              message: ""
            })
          ]}
          keyboardType={keyboardType}
          label="Exp Date"
          maxLength={5}
          placeholder="MM/YY"
          testID="card-expiration"
          maskFn={(val) => (val.match(/\d{1,2}/g) || []).join("/")}
        />
      </Row>
      <Row>
        <FomuStyledTextInput
          fill
          name="addressZip"
          eventTargetName="Input Zip Code"
          validates={[
            Validator.zipcode({ message: "invalid zip code" }),
            Validator.presence({ required: true })
          ]}
          keyboardType={keyboardType}
          label="Zip"
          maxLength={5}
          placeholder="12345"
          testID="card-address-zip"
          maskFn={(val) => (val.match(/\d{1,5}/g) || []).toString()}
        />
        <Spacer horizontal />
        <Column fill />
      </Row>
    </Stack>
  );
}
