/**
 * @generated SignedSource<<0cf471f19704d4a2324634290b082011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerShiftCardHeader_gigLike$data = {
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly organization: {
    readonly logoUrl: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WorkerShiftCardHeader_gigLike";
};
export type WorkerShiftCardHeader_gigLike$key = {
  readonly " $data"?: WorkerShiftCardHeader_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCardHeader_gigLike">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerShiftCardHeader_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "2d2acff4db5f6b0cf9970eb4228a369c";

export default node;
