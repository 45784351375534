/**
 * @generated SignedSource<<5ae4e9929827a144f82efa55a5af3d3e>>
 * @relayHash d650e7911b5951dd64db33030bd3099c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:eMckweXSENKX8g3hJE4iJsXzjYvpeMSl8JZg23Z_niE

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateWorkerWorkHistoryInput = {
  contactName?: string | null | undefined;
  emailAddress?: string | null | undefined;
  employer?: string | null | undefined;
  employmentEnd?: string | null | undefined;
  employmentStart?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  positionTitle?: string | null | undefined;
  responsibilities?: string | null | undefined;
  workerWorkHistoryId: string;
};
export type WorkHistoryFormUpdateWorkHistoryMutation$variables = {
  input: UpdateWorkerWorkHistoryInput;
};
export type WorkHistoryFormUpdateWorkHistoryMutation$data = {
  readonly updateWorkerWorkHistory: {
    readonly workerWorkHistory: {
      readonly " $fragmentSpreads": FragmentRefs<"WorkHistoryForm_worker">;
    };
  } | null | undefined;
};
export type WorkHistoryFormUpdateWorkHistoryMutation = {
  response: WorkHistoryFormUpdateWorkHistoryMutation$data;
  variables: WorkHistoryFormUpdateWorkHistoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkHistoryFormUpdateWorkHistoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerWorkHistoryPayload",
        "kind": "LinkedField",
        "name": "updateWorkerWorkHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerWorkHistory",
            "kind": "LinkedField",
            "name": "workerWorkHistory",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkHistoryForm_worker"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkHistoryFormUpdateWorkHistoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerWorkHistoryPayload",
        "kind": "LinkedField",
        "name": "updateWorkerWorkHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerWorkHistory",
            "kind": "LinkedField",
            "name": "workerWorkHistory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentEnd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "positionTitle",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:eMckweXSENKX8g3hJE4iJsXzjYvpeMSl8JZg23Z_niE",
    "metadata": {},
    "name": "WorkHistoryFormUpdateWorkHistoryMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "abbf64546d1fd1da902d591f481f41b9";

export default node;
