import React, { type ReactNode, useState } from "react";
import Collapsible from "react-native-collapsible";

import { FlexRow } from "../flex";
import { ToggleText } from "../tappable";

interface Props {
  children: ReactNode;
  collapsedText: string;
  notCollapsedText: string;
  startCollapsed?: boolean;
  testID?: string;
}

const CollapsibleToTop = ({
  children,
  collapsedText,
  notCollapsedText,
  startCollapsed,
  testID
}: Props) => {
  const [collapsed, setCollapsed] = useState(startCollapsed ?? true);
  return (
    <>
      <Collapsible collapsed={collapsed}>{children}</Collapsible>
      <FlexRow justifyContent="center">
        <ToggleText
          testID={testID}
          iconPos="left"
          onLabel={collapsedText}
          offLabel={notCollapsedText}
          value={collapsed}
          onToggle={setCollapsed}
          toTop
          spacing={3}
        />
      </FlexRow>
    </>
  );
};

export default CollapsibleToTop;
