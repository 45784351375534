import { Card, Divider, Surface } from "@gigsmart/atorasu";
import { SummaryRow } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import QualificationSummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/QualificationSummaryRow";
import WorkerShiftScheduleSummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/WorkerShiftScheduleSummaryRow";
import React from "react";
import GigPaymentRows from "../../gig/engagement-details/gig-payment-rows";
import ProjectSummaryDateTime from "../../gig/engagement-details/project-summary-date-time";
import SummaryLocation from "../../gig/engagement-details/summary-location";
import SummaryPayment from "../../gig/engagement-details/summary-payment";
import SummarySkills from "../../gig/engagement-details/summary-skills";
import type { seriesSummaryCard_series$key } from "./__generated__/seriesSummaryCard_series.graphql";

const SeriesSummaryCard = ({
  id,
  skills,
  place,
  area,
  hasMultipleLocations,
  gigType,
  category,
  position,
  nextOffered
}: FragmentContainerInnerComponentProps<seriesSummaryCard_series$key>) => {
  const isProject = gigType === "PROJECT";
  const nextEngagement = nextOffered?.edges?.[0]?.node;
  const gigHasCategoryPosition = !!category?.displayName && !!position?.name;

  return (
    <Card testID="series-summary-card" eventTargetName="Series Summary Card">
      {gigHasCategoryPosition ? (
        <>
          <SummaryRow
            iconName="grid"
            sectionLabel="Category"
            sectionContent={category?.displayName}
          />
          <SummaryRow
            iconName="box-check"
            sectionLabel="Position"
            sectionContent={position.name}
          />
        </>
      ) : (
        <>
          {!isProject && <SummarySkills skillConnection={skills} />}
          {isProject && (
            <SummaryRow
              iconName="box-check"
              sectionLabel="Category"
              sectionContent={category?.displayName}
            />
          )}
        </>
      )}
      {!isProject && (
        <Surface variant="flat">
          <QualificationSummaryRow nodeId={id} />
          <Divider />
        </Surface>
      )}
      <SummaryLocation
        eta
        isProject={isProject}
        distance={nextEngagement?.workerDistance}
        location={{
          placeId: nextEngagement?.gig?.placeId,
          latitude: nextEngagement?.gig?.place?.location?.latitude ?? null,
          longitude: nextEngagement?.gig?.place?.location?.longitude ?? null
        }}
        address={place?.streetAddress}
        area={area}
        hasMultipleLocations={hasMultipleLocations}
      />
      {isProject ? (
        <ProjectSummaryDateTime gig={nextEngagement?.gig ?? null} />
      ) : (
        <WorkerShiftScheduleSummaryRow
          nodeId={id}
          gigRef={nextEngagement?.gig}
          engagementRef={nextEngagement}
        />
      )}
      <SummaryPayment
        isProject={isProject}
        isVolunteer={gigType === "VOLUNTEER"}
        payRate={nextEngagement?.gig?.payRate}
        currentBid={nextEngagement?.currentBid}
      />
      <GigPaymentRows engagement={nextEngagement} />
    </Card>
  );
};

export default createRelayFragmentContainer<seriesSummaryCard_series$key>(
  graphql`
    fragment seriesSummaryCard_series on GigSeries
    @argumentDefinitions(engagementsQuery: { type: "String!" }) {
      id
      area
      place {
        streetAddress
      }
      hasMultipleLocations
      gigType
      skills(first: 10) {
        totalCount
        edges {
          node {
            skill {
              name
            }
          }
        }
      }
      category {
        displayName
      }
      position {
        name
      }
      nextOffered: engagements(first: 1, query: $engagementsQuery) {
        edges {
          node {
            ...WorkerShiftScheduleSummaryRow_engagement
            ...gigPaymentRows_engagement
            workerDistance
            currentBid {
              estimatedAmount
              expectedDuration
              note
              payRate
              paySchedule
            }
            gig {
              ...WorkerShiftScheduleSummaryRow_gig
              ...projectSummaryDateTime_gig
              payRate
              place {
                location {
                  latitude
                  longitude
                }
                area
              }
              placeId
            }
          }
        }
        totalCount
      }
    }
  `,
  SeriesSummaryCard
);
