/**
 * @generated SignedSource<<c0717e3b39da1063ab56da0e6c451560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftDescriptionCard_shift$data = {
  readonly description: string | null | undefined;
  readonly organizationPosition?: {
    readonly photos: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly url: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly photos?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GigTags_gigLike">;
  readonly " $fragmentType": "ShiftDescriptionCard_shift";
};
export type ShiftDescriptionCard_shift$key = {
  readonly " $data"?: ShiftDescriptionCard_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionCard_shift">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDescriptionCard_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GigTags_gigLike"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "GigPhotosConnection",
          "kind": "LinkedField",
          "name": "photos",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigPhotosEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigPhoto",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "photos(first:10)"
        }
      ],
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationPosition",
          "kind": "LinkedField",
          "name": "organizationPosition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "OrganizationPositionPhotosConnection",
              "kind": "LinkedField",
              "name": "photos",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationPositionPhotosEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrganizationPositionPhoto",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "photos(first:10)"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "ab7068d25ca3d9b0b025d39af93af030";

export default node;
