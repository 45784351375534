/**
 * @generated SignedSource<<e044361815662012ed57f803d8b250ab>>
 * @relayHash 965d285cb908949c4a2f128c20ceba79
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:qymWJiyRf1HlxTXaZJ0BC4Ymi0T8MDWDyuZd0mHAIJE

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementBidHistoryModalQuery$variables = {
  engagementId: string;
};
export type EngagementBidHistoryModalQuery$data = {
  readonly node: {
    readonly currentNegotiation?: {
      readonly payRate: string | null | undefined;
      readonly submittedBy: {
        readonly __typename: string;
        readonly displayName: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly gig?: {
      readonly actualStartsAt: string | null | undefined;
      readonly endsAt: string | null | undefined;
      readonly name: string | null | undefined;
      readonly payRate: string | null | undefined;
      readonly position: {
        readonly name: string;
      } | null | undefined;
      readonly startsAt: string | null | undefined;
    };
    readonly id?: string;
    readonly timesheet?: {
      readonly " $fragmentSpreads": FragmentRefs<"helpersGenerateEntries_timesheet">;
    } | null | undefined;
    readonly worker?: {
      readonly " $fragmentSpreads": FragmentRefs<"WorkerInfoRow_worker">;
    };
    readonly workerDistance?: number | null | undefined;
  } | null | undefined;
};
export type EngagementBidHistoryModalQuery = {
  response: EngagementBidHistoryModalQuery$data;
  variables: EngagementBidHistoryModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "engagementId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engagementId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v6 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    {
      "transitionedAt": {
        "direction": "ASC"
      }
    }
  ]
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v12 = [
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v14 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "_or": [
      {
        "name": {
          "_in": [
            "BID_REVIEW",
            "BID_REQUESTED",
            "OFFERED",
            "REJECTED",
            "SCHEDULED"
          ]
        }
      },
      {
        "action": {
          "_eq": "PICKUP"
        },
        "name": {
          "_eq": "SCHEDULED"
        }
      }
    ]
  }
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v18 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngagementBidHistoryModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Worker",
                "kind": "LinkedField",
                "name": "worker",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "WorkerInfoRow_worker"
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "helpersGenerateEntries_timesheet",
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "Variable",
                            "name": "where",
                            "variableName": "where"
                          }
                        ],
                        "concreteType": "EngagementStateOrOverridesConnection",
                        "kind": "LinkedField",
                        "name": "states",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementStateOrOverridesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "transitionedBy",
                                    "plural": false,
                                    "selections": (v12/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementNegotiation",
                                    "kind": "LinkedField",
                                    "name": "negotiation",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "submittedBy",
                                        "plural": false,
                                        "selections": (v12/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "args": [
                      (v14/*: any*/)
                    ],
                    "argumentDefinitions": [
                      {
                        "defaultValue": null,
                        "kind": "LocalArgument",
                        "name": "where"
                      }
                    ]
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementNegotiation",
                "kind": "LinkedField",
                "name": "currentNegotiation",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigPosition",
                    "kind": "LinkedField",
                    "name": "position",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngagementBidHistoryModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Worker",
                "kind": "LinkedField",
                "name": "worker",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBlocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFavorite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "belongsToWorkerGroup",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "profilePhoto",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overallRating",
                    "storageKey": null
                  },
                  {
                    "alias": "reviewCount",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 0
                      }
                    ],
                    "concreteType": "EngagementReviewsConnection",
                    "kind": "LinkedField",
                    "name": "reviewsReceived",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "reviewsReceived(first:0)"
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v14/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementStateOrOverridesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v2/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "transitionedBy",
                                "plural": false,
                                "selections": (v18/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementNegotiation",
                                "kind": "LinkedField",
                                "name": "negotiation",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "submittedBy",
                                    "plural": false,
                                    "selections": (v18/*: any*/),
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "states(first:100,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],where:{\"_or\":[{\"name\":{\"_in\":[\"BID_REVIEW\",\"BID_REQUESTED\",\"OFFERED\",\"REJECTED\",\"SCHEDULED\"]}},{\"action\":{\"_eq\":\"PICKUP\"},\"name\":{\"_eq\":\"SCHEDULED\"}}]})"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementNegotiation",
                "kind": "LinkedField",
                "name": "currentNegotiation",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigPosition",
                    "kind": "LinkedField",
                    "name": "position",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:qymWJiyRf1HlxTXaZJ0BC4Ymi0T8MDWDyuZd0mHAIJE",
    "metadata": {},
    "name": "EngagementBidHistoryModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "dd9314829f6392a0f0ca5a21348c05ed";

export default node;
