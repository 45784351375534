export interface GoogleSigninResponse {
  accessToken: string;
  idToken?: string;
}

const webClientId = process.env.GOOGLE_API_WEB_CLIENT_ID ?? "";
const isReady = false;

let req = 0;

declare let google: { accounts: any };

async function loadAuthScript() {
  await new Promise<void>((resolve, reject) => {
    if (isReady) {
      resolve();
      return;
    }

    const scriptTag = window.document.createElement("script");
    scriptTag.src = "https://accounts.google.com/gsi/client";
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => resolve();
    scriptTag.onerror = () =>
      reject(new Error("Google SignIn is not available."));

    document.body.appendChild(scriptTag);
  });
}

async function getAccessToken() {
  if (typeof google === "undefined") {
    throw new Error("Google SignIn is not available");
  }

  return await new Promise<string>((resolve, reject) => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: webClientId,
      scope: "openid profile email",
      state: `__${++req}`,
      callback: (res: any) => {
        if (res.error) reject(res);
        else resolve(res.access_token);
      },
      error_callback: (err: any) => {
        reject(err);
      }
    });
    client.requestAccessToken();
  });
}

export const googleSignin = async (): Promise<
  GoogleSigninResponse | undefined
> => {
  try {
    await loadAuthScript();
    const accessToken = await getAccessToken();
    return { accessToken };
  } catch (err) {
    if (err.type === "popup_closed") {
      return;
    }

    throw err;
  }
};
