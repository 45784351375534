import { ContentArea, Spacer, Surface, Text } from "@gigsmart/atorasu";
import React from "react";
import FomuCommentInput, {
  type Props as FomuCommentInputProps
} from "../fomu/inputs/FomuCommentInput";

export interface Props extends FomuCommentInputProps {
  title: string;
}

export default function CommentQuestion({ title, ...restProps }: Props) {
  return (
    <Surface>
      <ContentArea>
        <Text weight="bold" testID="comment-question-title">
          {title}
        </Text>
        <Spacer />
        <FomuCommentInput {...restProps} />
      </ContentArea>
    </Surface>
  );
}
