import { Button, Column, TopicPreviewSurface } from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import { showShiftGigInfoModal } from "@gigsmart/seibutsu/modals/ShiftGigsInfoModalTabs";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import ShiftGigsEmptyView from "./ShiftGigsEmptyView";
import ShiftPreviewRow from "./ShiftPreviewRow";
import type { ShiftTopicPreview_worker$key } from "./__generated__/ShiftTopicPreview_worker.graphql";

export const ShiftTopicPreview = ({
  availableGigSeries
}: FragmentContainerInnerComponentProps<ShiftTopicPreview_worker$key>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const seriesList = getConnectionNodes(availableGigSeries) ?? [];
  const isEmpty = seriesList.length === 0;

  return (
    <TopicPreviewSurface
      testID="shift-topic-preview"
      iconName="business-time"
      color="primary"
      title="Shifts"
      subtitle="Work quick on-demand available Shifts, ASAP or up to 30 days in the future. Track your hours and get paid through the app."
      rightAccessory={
        <Button
          testID="learn-more-btn"
          size="xsmall"
          variant="clear"
          label="Learn More"
          icon="circle-question"
          color="primary"
          onPress={showShiftGigInfoModal}
        />
      }
    >
      {isEmpty && <ShiftGigsEmptyView />}
      {seriesList.length > 0 && (
        <Column gap="standard">
          <Column gap="compact">
            {seriesList.map(({ id, boosted, availableGigs }) => {
              const gig = availableGigs?.edges?.[0];
              if (!gig) return null;
              return (
                <ShiftPreviewRow
                  key={id}
                  id={id}
                  name={gig?.node?.name}
                  logoUrl={gig?.node?.organization?.logoUrl}
                  boosted={boosted}
                  netPay={gig?.estimatedPayment?.netPay}
                  shiftCount={availableGigs?.totalCount}
                />
              );
            })}
          </Column>
          <Button
            alignSelf="center"
            testID="view-all-shift-gigs-btn"
            size="small"
            outline
            label="View All Shifts"
            icon="chevron-right"
            iconPlacement="right"
            onPress={() => navigation.push("BrowseShifts", { type: "shifts" })}
          />
        </Column>
      )}
    </TopicPreviewSurface>
  );
};

export default createRelayFragmentContainer<ShiftTopicPreview_worker$key>(
  graphql`
    fragment ShiftTopicPreview_worker on Worker
    @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableGigSeries(
        first: 3
        input: { gigTypes: [PAID, VOLUNTEER], maxDistance: $maxDistance }
      ) {
        edges {
          node {
            id
            category {
              id
            }
            boosted
            availableGigs(first: 1) {
              edges {
                estimatedPayment {
                  payRate
                  netPay
                }
                node {
                  name
                  organization {
                    logoUrl
                  }
                }
              }
              totalCount
            }
          }
        }
        totalCount
      }
    }
  `,
  ShiftTopicPreview
);
