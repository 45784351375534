import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment, { type Moment } from "moment";
import React, { Component, type ComponentProps } from "react";
import { View } from "react-native";
import { StyledIcon } from "../../icon";
import type { ViewStyleProp } from "../../style";
import StyledTextInput from "../styled-text-input";

type Props = Omit<ComponentProps<typeof StyledTextInput>, "format"> & {
  date?: string | Date | null | undefined;
  format?: string;
  initialDate?: Date;
  label: string;
  maxDate?: string | Date | null | undefined;
  minDate?: string | Date | null | undefined;
  onDateChange: (date: Date | null | undefined) => void;
  placeholder: string;
  style: ViewStyleProp;
  disabled?: boolean;
};
interface State {
  pickerShown: boolean;
  date?: string | Date | null | undefined;
}
export default class StyledDatetimePicker extends Component<Props, State> {
  static defaultProps = {
    format: "MMMM Do YYYY, h:mm a",
    placeholder: "Choose Time",
    disabled: false
  };

  state = {
    pickerShown: false
  };

  parseDate = (date: Date | string) => {
    return moment(date);
  };

  computeDates() {
    const { format, initialDate, maxDate, minDate, date } = this.props;

    return {
      date: date ? this.parseDate(date).toDate() : undefined,
      dateFormatted: date ? this.parseDate(date).format(format) : undefined,
      initial: initialDate ? this.parseDate(initialDate).toDate() : new Date(),
      max: maxDate ? this.parseDate(maxDate).toDate() : undefined,
      min: minDate ? this.parseDate(minDate).toDate() : undefined
    };
  }

  renderRightAccessory = () => {
    if (this.props.disabled) return null;
    const { pickerShown } = this.state;
    return (
      <StyledIcon
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ width: 20 }}
        color={pickerShown ? "blueTint" : "blue"}
        variant="solid"
        name={pickerShown ? "caret-up" : "caret-down"}
      />
    );
  };

  handleSubmit = (date?: Moment | null | undefined) => {
    if (date) this.props.onDateChange(date.toDate());
  };

  handleHide = () => {
    if (this.props.disabled) return;
    this.setState({ pickerShown: false });
  };

  handleShow = () => {
    if (this.props.disabled) return;
    this.setState({ pickerShown: true });
  };

  renderInput = () => {
    const { style, label, placeholder, testID } = this.props;
    const { pickerShown } = this.state;
    const { dateFormatted } = this.computeDates();

    return (
      <StyledTextInput
        focused={pickerShown}
        testID={testID}
        label={label}
        placeholder={placeholder}
        editable={false}
        eventTargetName=""
        pointerEvents="none"
        renderRightAccessory={this.renderRightAccessory}
        customPressFunction={this.handleShow}
        style={style}
        value={dateFormatted}
      />
    );
  };

  render() {
    const { format, style } = this.props;
    const { pickerShown } = this.state;
    const { initial, min, max, date } = this.computeDates();
    return (
      <View style={style}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            hideTabs
            value={date}
            onChange={this.handleSubmit}
            minDate={min}
            maxDate={max}
            format={format}
            emptyLabel=""
            initialFocusedDate={initial}
            onClose={this.handleHide}
            onAccept={this.handleHide}
            open={pickerShown}
            onOpen={() => this.setState({ pickerShown: true })}
            TextFieldComponent={this.renderInput}
          />
        </MuiPickersUtilsProvider>
      </View>
    );
  }
}
