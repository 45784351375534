/**
 * @generated SignedSource<<8608a9ca766a9217575e9bc50f102625>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type NegotiableGigField = "STARTS_AT" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerProjectCard_gig$data = {
  readonly area: string | null | undefined;
  readonly category: {
    readonly displayName: string;
  } | null | undefined;
  readonly endsAt: string | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly insertedAt: string;
  readonly name: string | null | undefined;
  readonly negotiable: ReadonlyArray<NegotiableGigField> | null | undefined;
  readonly paymentType: PaymentType | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly placeId: string | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentType": "WorkerProjectCard_gig";
};
export type WorkerProjectCard_gig$key = {
  readonly " $data"?: WorkerProjectCard_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerProjectCard_gig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerProjectCard_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "area",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "negotiable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "0ab50e1f60c7784f983a26157a57a631";

export default node;
