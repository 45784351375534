import React from "react";

import { ContentArea, Screen, Spacer, Text } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import QualificationCategories from "@gigsmart/seibutsu/worker-profile/QualificationCategories";

import type { qualificationsScreenQuery } from "./__generated__/qualificationsScreenQuery.graphql";

export default createSuspendedQueryContainer<qualificationsScreenQuery>(
  function QualificationsScreen({ response: { viewer } = {} }) {
    return (
      <Screen testID="qualifications-screen" color="surface">
        <ContentArea>
          <Text variant="header">Qualifications</Text>
          <Spacer size="medium" />
          <QualificationCategories fragmentRef={viewer} />
        </ContentArea>
      </Screen>
    );
  },
  {
    query: graphql`
      query qualificationsScreenQuery {
        viewer {
          ... on Worker {
            ...QualificationCategories_gigFields
          }
        }
      }
    `,
    variables: {}
  }
);
