import React, { type ReactNode } from "react";
import { View } from "react-native";

import { FlexColumn } from "../flex";
import FlexRow from "../flex/row";
import { type IconName, type IconVariant, StyledIcon } from "../icon";
import { useStyles } from "../style";
import { ClearInlineButton } from "../tappable";
import { StyledText } from "../text";

import BaseRow from "./base-row";

export type SummaryVariant = "classic" | "data" | "button";

export interface SummaryRowProps {
  testID?: string;
  sectionContentTestID?: string;
  variant?: SummaryVariant;
  iconName?: IconName;
  sectionLabel: string;
  sectionLabelSize?: number;
  iconColor?: string;
  iconSize?: number;
  sectionContent: string | ReactNode;
  data?: string | ReactNode;
  noBorder?: boolean;
  handleEdit?: () => void;
  editButtonText?: string;
  editButtonIcon?: IconName;
  editButtonColor?: string;
  editButtonIconPos?: "left" | "right";
  editButtonIconVariant?: IconVariant;
  noPadding?: boolean;
  primaryAction?: ReactNode;
  rowAction?: ReactNode;
}

const SummaryRow = ({
  testID,
  sectionContentTestID,
  variant = "classic",
  data,
  iconName,
  iconColor,
  iconSize,
  sectionContent,
  sectionLabel,
  sectionLabelSize,
  handleEdit,
  editButtonIcon = "pen-to-square",
  editButtonText = "Edit",
  editButtonColor = "blue",
  editButtonIconPos = "left",
  editButtonIconVariant,
  noBorder = false,
  noPadding = false,
  primaryAction,
  rowAction
}: SummaryRowProps) => {
  const { styles, theme } = useStyles(({ font, units }) => ({
    noPadding: {
      paddingHorizontal: 0
    },
    iconContainer: {
      width: units(5),
      alignItems: "center"
    },
    editRow: {
      flexDirection: "row",
      alignItems: "center"
    },
    editText: {
      marginLeft: units(2),
      marginTop: units(1)
    },
    sectionLabel: {
      marginLeft: units(2),
      fontSize: sectionLabelSize ?? font.size.medium
    },
    bottomRow: {
      marginLeft: units(7)
    },
    dataText: {
      marginLeft: units(4)
    },
    marginBottom: {
      marginBottom: units(2)
    },
    summaryRow: {
      alignItems: "flex-start"
    }
  }));

  if (!primaryAction && variant === "button") {
    primaryAction = (
      <ClearInlineButton
        onPress={handleEdit}
        testID={testID && `${testID}-button`}
        title={editButtonText}
        iconName={editButtonIcon}
        tintColor={editButtonColor}
        iconPos={editButtonIconPos}
        iconVariant={editButtonIconVariant}
        small
      />
    );
  }

  return (
    <BaseRow
      testID={testID}
      noBorder={noBorder}
      style={[styles.summaryRow, noPadding && styles.noPadding]}
    >
      <FlexColumn fill={1}>
        <FlexRow justifyContent="space-between" style={styles.marginBottom}>
          <FlexRow alignItems="center">
            <View style={styles.iconContainer}>
              {!!iconName && (
                <StyledIcon
                  name={iconName}
                  color={iconColor}
                  variant="solid"
                  size={iconSize ?? theme.font.size.medium}
                />
              )}
            </View>
            <StyledText bold color="blue" style={styles.sectionLabel}>
              {sectionLabel}
            </StyledText>
          </FlexRow>
          {primaryAction}
        </FlexRow>
        <FlexRow
          justifyContent="space-between"
          fill={1}
          style={styles.bottomRow}
        >
          {typeof sectionContent === "string" ? (
            <StyledText testID={sectionContentTestID}>
              {sectionContent}
            </StyledText>
          ) : (
            sectionContent
          )}
          {variant === "data" &&
            (typeof data === "string" ? (
              <StyledText style={styles.dataText}>{data}</StyledText>
            ) : (
              <View style={styles.dataText}>{data}</View>
            ))}
        </FlexRow>
      </FlexColumn>
      {rowAction}
    </BaseRow>
  );
};

export default SummaryRow;
