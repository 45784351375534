import { useEventer } from "@gigsmart/dekigoto";
import React from "react";
import { View } from "react-native";
import { Spacer } from "../../spacer";
import { DATE_FORMAT, LUXON_DATE_FORMAT, useCurrentMonth } from "./helpers";
import MonthCalendar from "./month-calendar";
import MonthHeader from "./month-header";

interface Props {
  min?: string;
  max?: string;
  value?: string[];
  pinnedDays?: string[];
  onChange?: (value: string[]) => void;
  multiple?: boolean;
  testID?: string;
}

const DatePickerInput = ({
  value,
  min,
  max,
  testID,
  onChange,
  multiple,
  pinnedDays
}: Props) => {
  const [month, onSetMonth] = useCurrentMonth(value?.[0]);
  const trackSelect = useEventer("Selected", testID ?? "Unknown", "Date");

  return (
    <View testID={testID}>
      <MonthHeader min={min} max={max} month={month} onSetMonth={onSetMonth} />
      <Spacer units={6} />
      <MonthCalendar
        min={min}
        max={max}
        month={month}
        selectedDays={value}
        pinnedDays={pinnedDays}
        onChange={(days) => {
          trackSelect();
          onChange?.(days);
        }}
        multiple={multiple}
      />
    </View>
  );
};

DatePickerInput.DATE_FORMAT = DATE_FORMAT;
DatePickerInput.LUXON_DATE_FORMAT = LUXON_DATE_FORMAT;

export default DatePickerInput;
