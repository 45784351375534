import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledTextInput } from "@gigsmart/katana";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";
import { Platform } from "react-native";

type Props = Omit<
  ComponentPropsWithDefaults<typeof StyledTextInput>,
  "onSubmitEditing" | "value" | "onChangeText" | "errors"
> &
  FormFieldOptions & {
    maskFn?: (value: string) => string;
    customOnFocusHandler?: (errors: Error[] | null) => void;
    customOnBlurHandler?: () => void;
  };

export function FomuStyledTextInput({
  name,
  validates,
  shouldShowErrors,
  sensitive,
  maskFn,
  customOnBlurHandler,
  customOnFocusHandler,
  ...props
}: Props) {
  const { setValue, value, submit, errors, showErrors, triggerFocus } =
    useFormField({
      name,
      validates,
      sensitive,
      shouldShowErrors
    });

  const handleOnBlur = () => {
    showErrors();
    customOnBlurHandler?.();
  };

  const handleOnFocus = () => {
    triggerFocus();
    customOnFocusHandler?.(errors);
  };

  return (
    <StyledTextInput
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value}
      onChangeText={(val) => {
        const maskedVal = maskFn ? maskFn(val) : val;
        setValue(maskedVal);
      }}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      errors={errors}
      {...props}
    />
  );
}
