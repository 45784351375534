import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { compact } from "lodash";
import React from "react";
import type { showRejectGigPositionPromptMutation } from "./__generated__/showRejectGigPositionPromptMutation.graphql";
import type { showRejectGigPositionPromptQuery } from "./__generated__/showRejectGigPositionPromptQuery.graphql";

interface Props {
  id: string;
  onClose: () => void;
}

const RejectGigPositionPrompt = createSuspendedQueryContainer<
  showRejectGigPositionPromptQuery,
  Props
>(
  function RejectGigPositionPrompt({ response, onClose }) {
    const { node, viewer } = response ?? {};
    const position = node?.gig?.position ?? node?.position ?? node;

    const [setWorkerPosition, { loading }] =
      useRelayMutationPromise<showRejectGigPositionPromptMutation>(graphql`
        mutation showRejectGigPositionPromptMutation(
          $input: SetWorkerPositionInput!
        ) {
          setWorkerPosition(input: $input) {
            workerPosition {
              id
              status
            }
          }
        }
      `);

    const handleRejectGigPosition = async () => {
      const promises = compact([
        position?.id,
        ...getConnectionNodes(position?.relatedPositions, (it) => it.id)
      ]).map((gigPositionId) =>
        setWorkerPosition({
          input: { gigPositionId, status: "DENIED", workerId: viewer?.id ?? "" }
        })
      );
      await Promise.all(promises);
      onClose();
    };

    return (
      <>
        <ModalHeader
          title={`Do you still want to receive offers for ${position?.name} Shifts?`}
          titleColor="primary"
        />
        <ModalBody>
          <Stack>
            <Text>
              If you would like to stop being notified of or offered{" "}
              {position?.name} Shifts, you can elect to remove this position
              from your Profile.
            </Text>
            <Text>
              You can always update position settings in your Profile if you
              change your mind.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack justifyContent="center" size="compact">
            <Button
              disabled={loading}
              outline
              color="error"
              onPress={handleRejectGigPosition}
              testID="reject-position-btn"
              label="Stop Receiving Offers for Position"
            />
            <Button
              label="Continue Receiving Offers for Position"
              testID="close"
              onPress={onClose}
            />
          </Stack>
        </ModalFooter>
      </>
    );
  },
  {
    query: graphql`
      query showRejectGigPositionPromptQuery($id: ID!) {
        viewer {
          id
        }
        node(id: $id) {
          ... on GigPosition {
            id
            name
            relatedPositions(first: 50) {
              edges {
                node {
                  id
                }
              }
            }
          }
          ... on GigSeries {
            position {
              id
              name
              relatedPositions(first: 50) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
          ... on Engagement {
            gig {
              position {
                id
                name
                relatedPositions(first: 50) {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ id }) => ({ id })
  }
);

export const showRejectGigPositionPrompt = (props: Omit<Props, "onClose">) => {
  showModal({
    eventContext: "RejectGigPositionPrompt",
    variant: "none",
    useModalBody: false,
    children: (closeFn) => (
      <RejectGigPositionPrompt {...props} onClose={closeFn} />
    )
  });
};
