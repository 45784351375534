import { useStepper } from "@gigsmart/atorasu";
import React from "react";

export interface DirectHireClaimsStepper {
  id?: string;
  returnTo?: "review" | null;
  dirty?: boolean;
  directHireClaimType?: "HIRE" | "PAYMENT";
}

export default function AddDirectPaymentOrHireStepper() {
  const { activeStep } = useStepper();
  if (!activeStep) return null;
  const { component: Component } = activeStep;
  return <>{Component}</>;
}
