/**
 * @generated SignedSource<<7f553ff98deeaeefbcfbe0307d3c87e2>>
 * @relayHash aaf064da88188e16daf789d86a3d7851
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:LNSxjYke-cO3NL6r2UlLNsWSo1siFfbbrMbDzdF45vc

import { ConcreteRequest } from 'relay-runtime';
export type WorkerPayoutType = "INSTANT" | "STANDARD" | "%future added value";
export type AddWorkerPayoutInput = {
  type: WorkerPayoutType;
  workerId?: string | null | undefined;
};
export type transferScreenMutation$variables = {
  input: AddWorkerPayoutInput;
};
export type transferScreenMutation$data = {
  readonly addWorkerPayout: {
    readonly newWorkerPayoutEdge: {
      readonly node: {
        readonly amount: string;
        readonly type: WorkerPayoutType;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type transferScreenMutation = {
  response: transferScreenMutation$data;
  variables: transferScreenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transferScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerPayoutPayload",
        "kind": "LinkedField",
        "name": "addWorkerPayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerPayoutsEdge",
            "kind": "LinkedField",
            "name": "newWorkerPayoutEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transferScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkerPayoutPayload",
        "kind": "LinkedField",
        "name": "addWorkerPayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerPayoutsEdge",
            "kind": "LinkedField",
            "name": "newWorkerPayoutEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:LNSxjYke-cO3NL6r2UlLNsWSo1siFfbbrMbDzdF45vc",
    "metadata": {},
    "name": "transferScreenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f100da9211e4f5de025d2dcd4a3b8769";

export default node;
