import { ContentArea, Stack, Surface, Text } from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import { StyledLink } from "@gigsmart/katana";
import React from "react";
import FomuCheckbox from "../../fomu/inputs/FomuCheckbox";
import useLinkToGigSmartSms from "./useLinkToGigsmartSms";
import useLinkToTwilio from "./useLinkToTwilio";

export default function TwilioTermsOfServiceCheckbox() {
  const { linkToPrivacyPolicy, linkToTermsOfService } = useLinkToTwilio();
  const { linkToGigsmartSmsTerms } = useLinkToGigSmartSms();
  return (
    <Surface variant="outline">
      <ContentArea>
        <Stack size="compact">
          <FomuCheckbox
            title={
              <Text>
                I accept the{" "}
                <StyledLink onPress={linkToTermsOfService}>
                  Twilio Terms of Service
                </StyledLink>{" "}
                and have read the{" "}
                <StyledLink onPress={linkToPrivacyPolicy}>
                  Twilio Privacy Notice
                </StyledLink>
              </Text>
            }
            name="twilio-consent"
            eventTargetName="Twilio Consent Checkbox"
            validates={Validator.isTrue()}
            expand
          />
          <FomuCheckbox
            title={
              <Text>
                I have read and agree to the{" "}
                <StyledLink onPress={linkToGigsmartSmsTerms}>
                  GigSmart SMS Terms and Conditions
                </StyledLink>{" "}
              </Text>
            }
            name="gigsmartSmsTermsAndConditionsConsent"
            eventTargetName="GigSmart SMS Terms and Conditions Checkbox"
            validates={Validator.isTrue()}
            expand
          />
        </Stack>
      </ContentArea>
    </Surface>
  );
}
