import { CheckboxSurface, Stack } from "@gigsmart/atorasu";
import { FormField, Validator } from "@gigsmart/fomu";
import React, { type ComponentProps } from "react";

interface CheckboxType
  extends Omit<
    ComponentProps<typeof CheckboxSurface>,
    "onChange" | "selected"
  > {
  name: string;
  required?: boolean;
}
interface Props {
  ordered?: boolean;
  checkboxes: CheckboxType[];
  testID?: string;
}

export default function FomuCheckboxGroup({
  checkboxes,
  testID,
  ordered
}: Props) {
  return (
    <Stack size="compact">
      {checkboxes.map(({ name, required, ...rest }, index) => {
        if (!rest) return null;
        return (
          <FormField
            key={index}
            name={name}
            validates={required ? [Validator.isTrue()] : undefined}
          >
            {({ value, setValue }) => (
              <CheckboxSurface
                {...rest}
                onChange={setValue}
                selected={value}
                number={ordered ? index + 1 : undefined}
                testID={`${name}-checkbox`}
              />
            )}
          </FormField>
        );
      })}
    </Stack>
  );
}
