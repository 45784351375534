import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayPaginationFragment
} from "@gigsmart/relay";
import React from "react";

import {
  ActivityIndicator,
  Button,
  Card,
  ContentArea,
  IconButton,
  Row,
  Spacer,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { DateTime, Duration } from "luxon";
import RecordingPlayer from "./RecordingPlayer";
import type { AdminCallRecordingsQuery } from "./__generated__/AdminCallRecordingsQuery.graphql";
import type { AdminCallRecordings_calls$key } from "./__generated__/AdminCallRecordings_calls.graphql";

interface Props {
  conversationId: string;
}

export default createSuspendedQueryContainer<AdminCallRecordingsQuery, Props>(
  function AdminCallRecordings({ response }) {
    const {
      data: conversation,
      hasNext,
      loadNext
    } = useRelayPaginationFragment<
      AdminCallRecordingsQuery,
      AdminCallRecordings_calls$key
    >(
      graphql`
        fragment AdminCallRecordings_calls on ConversationLike
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
        )
        @refetchable(queryName: "AdminCallRecordingsPaginationQuery") {
          calls(
            first: $count
            after: $cursor
            query: "ORDER BY callStartedAt DESC"
          ) @connection(key: "AdminCallRecordings_calls") {
            edges {
              node {
                id
                uuid
                duration
                toPhoneNumber
                fromPhoneNumber
                callStartedAt
                recordingUrl
                toUser {
                  __typename
                  displayName
                  id
                }
                fromUser {
                  __typename
                  displayName
                  id
                }
              }
            }
          }
        }
      `,
      response?.node ?? null
    );

    const calls = getConnectionNodes(conversation?.calls);
    const count = calls.length;

    const handleShowModal = () =>
      showModal({
        title: "",
        eventContext: "",
        dismissable: true,
        children: () => (
          <ContentArea>
            <Stack>
              <Row alignItems="center" justifyContent="center">
                <Text variant="title">Call History</Text>
              </Row>
              {calls.map((call) => {
                const fromUser = call?.fromUser;
                const toUser = call?.toUser;
                return (
                  <Card testID="" eventTargetName="name" key={call.id}>
                    <ContentArea>
                      <Row alignItems="center" justifyContent="space-between">
                        <Stack>
                          <Text variant="header">{`${fromUser?.displayName} called ${toUser?.displayName}`}</Text>
                          <Text>{`Call Started: ${
                            call?.callStartedAt
                              ? DateTime.fromISO(
                                  call?.callStartedAt
                                ).toLocaleString(DateTime.DATETIME_FULL)
                              : "No Data"
                          }`}</Text>
                          <Text>{`Call Lasted: ${
                            call?.duration
                              ? Duration.fromISO(call?.duration).toHuman()
                              : "No Data"
                          }`}</Text>
                        </Stack>
                        {call?.recordingUrl && (
                          <Stack>
                            <RecordingPlayer recordingUrl={call.recordingUrl} />
                            <IconButton
                              name="download"
                              variant="solid"
                              color="primary"
                              testID={`download-${call.recordingUrl}-button`}
                              onPress={() =>
                                window.open(call.recordingUrl ?? "", "_blank")
                              }
                            />
                          </Stack>
                        )}
                      </Row>
                    </ContentArea>
                  </Card>
                );
              })}
              <Spacer />
            </Stack>
            <Button
              testID="load-more-calls-button"
              onPress={() => loadNext(10)}
              disabled={!hasNext}
              label="Load More"
              size="small"
            />
          </ContentArea>
        )
      });

    return (
      <Button
        testID="admin-call-recordings-view-button"
        label={count ? `View ${count} calls` : "No Calls"}
        disabled={!count}
        color="primary"
        variant="clear"
        onPress={handleShowModal}
      />
    );
  },
  {
    query: graphql`
      query AdminCallRecordingsQuery($id: ID!, $count: Int) {
        node(id: $id) {
          ...AdminCallRecordings_calls @arguments(count: $count)
        }
      }
    `,
    variables: ({ conversationId }) => ({ id: conversationId, count: 25 }),
    FallbackComponent: ActivityIndicator
  }
);
