import { Row, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import React, { Fragment } from "react";
import { View } from "react-native";

interface Props {
  backgroundColor?: string;
  content?: string | string[];
  separator?: "line" | "dot";
}

const GigCardAside = ({
  separator = "dot",
  backgroundColor = "neutralDark",
  content
}: Props) => {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      marginTop: "auto",
      backgroundColor: getColor("neutral", "fill"),
      paddingVertical: getUnits(0.5),
      borderBottomLeftRadius: getUnits(1),
      borderBottomRightRadius: getUnits(1)
    },
    line: {
      width: 1,
      height: 12,
      backgroundColor: getColor("surface", "fill"),
      marginHorizontal: getUnits(2)
    },
    dot: {
      width: 3,
      height: 3,
      borderRadius: 2,
      backgroundColor: getColor("surface", "fill"),
      marginHorizontal: getUnits(1.5)
    }
  }));
  if (!content || content.length === 0) return null;

  if (typeof content === "string") content = [content];
  const divider = <View style={styles[separator]} />;
  return (
    <Row style={styles.container} justifyContent="center" alignItems="center">
      {content?.map((txt, idx) => (
        <Fragment key={idx}>
          {idx > 0 && divider}
          <Text color="surface" align="center" weight="bold">
            {txt}
          </Text>
        </Fragment>
      ))}
    </Row>
  );
};

export default GigCardAside;
