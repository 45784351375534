import {
  Dot,
  GridNull,
  Image,
  ProductInfoOutlineContainer,
  Row,
  Stack,
  Text,
  TimeRemaining
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import useGigLocalDateTime from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import HomeScreenCard from "@gigsmart/seibutsu/worker/HomescreenCard";
import type { ObjectPath } from "@gigsmart/type-utils";
import { compact } from "lodash";
import { DateTime } from "luxon";
import React from "react";

import type { PendingTimesheetsReviewCardSubscription } from "./__generated__/PendingTimesheetsReviewCardSubscription.graphql";
import type { PendingTimesheetsReviewCard_worker$key } from "./__generated__/PendingTimesheetsReviewCard_worker.graphql";

interface Props {
  query: string;
}

export function PendingTimesheetsReviewCard({
  reviewProjects,
  query
}: FragmentContainerInnerComponentProps<
  PendingTimesheetsReviewCard_worker$key,
  Props
>) {
  const isPositionsEnabled = PositionsAndLocations.isEnabled();
  useRelaySubscription<PendingTimesheetsReviewCardSubscription>(
    graphql`
      subscription PendingTimesheetsReviewCardSubscription(
        $timeSheetReviewQuery: String!
      ) {
        workerUpdated {
          worker {
            id
            ...PendingTimesheetsReviewCard_worker
              @arguments(query: $timeSheetReviewQuery)
          }
        }
      }
    `,
    { timeSheetReviewQuery: query }
  );

  const history = useHistory();
  const gigs = compact(reviewProjects?.edges);

  if (gigs.length < 1) return <GridNull />;

  const count = reviewProjects?.edges?.length ?? 0;

  const RenderItem = ({
    gig,
    id,
    transitionTime
  }: {
    gig: ObjectPath<
      PendingTimesheetsReviewCard_worker$key,
      [" $data", "reviewProjects", "edges", 0, "node", "gig"]
    >;
    transitionTime?: string;
    id?: string;
  }) => {
    const formattedDate = useGigLocalDateTime(
      {
        startsAt: gig?.startsAt,
        endsAt: gig?.endsAt,
        timezone: gig?.timezone
      },
      { dateFormat: "fullDateShort" }
    );

    const transitionedBeforeEndtime =
      new Date(transitionTime ?? Date.now()).getTime() <
      new Date(gig?.endsAt ?? Date.now()).getTime();

    const timeRemaining = DateTime.fromJSDate(
      new Date(
        transitionedBeforeEndtime
          ? gig?.endsAt ?? Date.now()
          : transitionTime ?? Date.now()
      )
    )
      .plus({ hours: 6 })
      .toJSON();

    return (
      <ProductInfoOutlineContainer
        testID={`timesheet-review-card-${id}`}
        onPress={() => history.push(`/gigs/${id}`)}
        eventEntityType="Pending Timesheet Review Card"
        icon="business-time"
        iconSquareVariant="standard"
        color="warning"
        {...(isPositionsEnabled && gig?.organization?.logoUrl
          ? {
              left: (
                <Image
                  height={36}
                  variant="square"
                  source={{
                    uri: gig?.organization?.logoUrl ?? ""
                  }}
                />
              )
            }
          : { icon: "business-time" })}
        title={
          <Text variant="subheader" color="black" numberOfLines={1}>
            {isPositionsEnabled ? gig?.position?.name : gig?.name}
          </Text>
        }
        note={
          <Text
            variant="note"
            color="neutral"
          >{`${formattedDate.dateString.replace(/,? \d{4}/, "")} ${
            formattedDate.timeString
          }`}</Text>
        }
        bottomContent={
          <Row alignItems="center">
            <Dot />
            <Text> </Text>
            <TimeRemaining weight="bold" color="danger" date={timeRemaining} />
            <Text> left to review your Timesheet</Text>
          </Row>
        }
      />
    );
  };

  return (
    <HomeScreenCard
      headerTitle={`${pluralize(count, "Timesheet")} Needs Your Review`}
      headerIcon="triangle-exclamation"
      headerColor="danger"
    >
      <Stack size="compact">
        {gigs.map((item, index) => (
          <RenderItem
            key={`item-${index}`}
            id={item.node?.id}
            gig={item?.node?.gig}
            transitionTime={
              item?.node?.timesheet?.states?.edges?.[0]?.node?.transitionedAt
            }
          />
        ))}
      </Stack>
    </HomeScreenCard>
  );
}

export default createRelayFragmentContainer<
  PendingTimesheetsReviewCard_worker$key,
  Props
>(
  graphql`
    fragment PendingTimesheetsReviewCard_worker on Worker
    @argumentDefinitions(query: { type: "String!" }) {
      id
      reviewProjects: engagements(first: 50, query: $query) {
        edges {
          node {
            id
            timesheet(variant: SYSTEM) {
              states(
                first: 1
                query: "WHERE name = PENDING_TIMESHEET_APPROVAL"
              ) {
                edges {
                  node {
                    transitionedAt
                  }
                }
              }
            }
            gig {
              startsAt
              endsAt
              name
              insertedAt
              timezone
              organization {
                logoUrl
              }
              position {
                name
              }
            }
          }
        }
      }
    }
  `,
  PendingTimesheetsReviewCard
);
