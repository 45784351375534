import { getReportType } from "@gigsmart/isomorphic-shared/report/dictionary";
import {
  graphql,
  readRelayInlineFragment,
  useRelayFragment
} from "@gigsmart/relay";
import type { useApplyMetadataIsEnrolled_gigOrEngagement$key } from "./__generated__/useApplyMetadataIsEnrolled_gigOrEngagement.graphql";
import type { useApplyMetadata_gigOrEngagement$key } from "./__generated__/useApplyMetadata_gigOrEngagement.graphql";
import type { useApplyMetadata_worker$key } from "./__generated__/useApplyMetadata_worker.graphql";
import type { useEnrollmentDataFragment } from "./useEnrollmentData";

export function readWorkerEnrolledMetadata(
  enrollmentData: ReturnType<typeof useEnrollmentDataFragment>,
  gigOrEngagementRef?: useApplyMetadataIsEnrolled_gigOrEngagement$key | null
) {
  const gigOrEngagement = getGigOrEngagement(gigOrEngagementRef);
  const gig = gigOrEngagement?.node ?? gigOrEngagement?.gig;
  const missingOptIns =
    gig?.requiredReportTypes?.filter((type) => {
      const key = getReportType(type).optInKey;
      return !enrollmentData?.[key];
    }) ?? [];

  const hasMissingOptIns = !!missingOptIns.length;
  const isMissingDriverInfo =
    missingOptIns.includes("MOTOR_VEHICLE_CHECK") &&
    (!enrollmentData?.driversLicenseLast4 ||
      !enrollmentData.driversLicenseIssuer);

  const requiresMvr = gig?.requiredReportTypes?.includes("MOTOR_VEHICLE_CHECK");
  const driverEligibilityNotice = requiresMvr
    ? enrollmentData?.reports?.find(
        ({ status, type }) =>
          status === "NOT_ELIGIBLE" && type === "MOTOR_VEHICLE_CHECK"
      )?.statusDescription
    : undefined;

  const pendingConsents = gigOrEngagement?.pendingConsents;
  const doesNotRequireMoreInfo =
    enrollmentData?.status === "ENROLLED" &&
    !hasMissingOptIns &&
    !pendingConsents?.length &&
    !gig?.gigSeries?.unmetQualifications?.totalCount;

  return {
    doesNotRequireMoreInfo,
    hasMissingOptIns,
    isMissingDriverInfo,
    driverEligibilityNotice,
    pendingConsents,
    requiredReportTypes: gig?.requiredReportTypes
  };
}

function getGigOrEngagement(
  ref: useApplyMetadataIsEnrolled_gigOrEngagement$key | null | undefined
) {
  return readRelayInlineFragment(
    graphql`
      fragment useApplyMetadataIsEnrolled_gigOrEngagement on AvailableGigOrEngagement
      @inline {
        ... on Engagement {
          pendingConsents {
            ...ConsentDocumentStepper_documents
          }
          gig {
            requiredReportTypes
            position {
              id
            }
            gigSeries {
              unmetQualifications(first: 0) {
                totalCount
              }
            }
          }
        }
        ... on AvailableGigsEdge {
          pendingConsents {
            ...ConsentDocumentStepper_documents
          }
          node {
            requiredReportTypes
            position {
              id
            }
            gigSeries {
              unmetQualifications(first: 0) {
                totalCount
              }
            }
          }
        }
      }
    `,
    ref ?? null
  );
}

export function makeApplyMetadataVars() {
  return {};
}

export default function useApplyMetadata(
  workerRef: useApplyMetadata_worker$key | null | undefined,
  enrollmentData: ReturnType<typeof useEnrollmentDataFragment>
) {
  const worker = useRelayFragment(
    graphql`
      fragment useApplyMetadata_worker on Worker
      @argumentDefinitions(
        gigId: { type: "ID!" }
        maxDistance: { type: "Int!" }
        isSeries: { type: "Boolean!" }
        isApply: { type: "Boolean!" }
      ) {
        id
        payableAccount {
          paymentMethod {
            accountHolderName
          }
        }
        gig(id: $gigId) @skip(if: $isSeries) {
          ...useApplyMetadata_gigOrEngagement
        }
        gigSeries(id: $gigId) @include(if: $isSeries) {
          node {
            id
            placeId
            area
            nextOffered: engagements(
              first: 1
              query: "WHERE currentStateName = OFFERED"
            ) @skip(if: $isApply) {
              edges {
                node {
                  ...useApplyMetadata_gigOrEngagement
                }
              }
              totalCount
            }
            nextGig: availableGigs(
              first: 1
              input: { maxDistance: $maxDistance }
            )
              @connection(key: "useApplyMetadataQuery_nextGig")
              @include(if: $isApply) {
              edges {
                ...useApplyMetadata_gigOrEngagement
              }
              totalCount
            }
          }
        }
      }
    `,
    workerRef ?? null
  );

  const gigOrEngagement =
    useRelayFragment<useApplyMetadata_gigOrEngagement$key>(
      graphql`
        fragment useApplyMetadata_gigOrEngagement on AvailableGigOrEngagement {
          __typename
          ...useApplyMetadataIsEnrolled_gigOrEngagement
          ... on Engagement {
            id
            gig {
              id
              requiredReportTypes
              area
              gigType
              placeId
              paymentType
              currentState {
                name
              }
              gigSeries {
                unmetQualifications(first: 0) {
                  totalCount
                }
              }
            }
          }
          ... on AvailableGigsEdge {
            distance
            node {
              id
              currentState {
                name
              }
              requiredReportTypes
              area
              gigType
              placeId
              paymentType
              gigSeries {
                unmetQualifications(first: 0) {
                  totalCount
                }
              }
            }
          }
        }
      `,
      worker?.gig ??
        worker?.gigSeries?.node?.nextGig?.edges?.[0] ??
        worker?.gigSeries?.node?.nextOffered?.edges?.[0]?.node ??
        null
    );

  const enrollmentWorkerInfo = readWorkerEnrolledMetadata(
    enrollmentData,
    gigOrEngagement
  );

  // Get initial enrollment data
  const shiftCount =
    worker?.gigSeries?.node?.nextGig?.totalCount ??
    worker?.gigSeries?.node?.nextOffered?.totalCount ??
    0;

  const gig = gigOrEngagement?.node ?? gigOrEngagement?.gig;
  const isRemote =
    worker?.gigSeries?.node?.placeId === "REMOTE" ||
    gig?.placeId === "REMOTE" ||
    gig?.area === "REMOTE" ||
    worker?.gigSeries?.node?.area === "REMOTE";

  const workerHasPaymentMethod =
    gig?.gigType === "VOLUNTEER" ||
    !!worker?.payableAccount?.paymentMethod?.accountHolderName;
  const hasMissingQualifications =
    !!gig?.gigSeries?.unmetQualifications?.totalCount;
  const currentStateName = (gigOrEngagement?.gig ?? gigOrEngagement?.node)
    ?.currentState?.name;
  return {
    ...enrollmentWorkerInfo,
    shiftId: gig?.id,
    engagementId: gigOrEngagement?.id,
    gigDistance:
      gigOrEngagement?.__typename === "Engagement"
        ? 0
        : gigOrEngagement?.distance ?? Number.POSITIVE_INFINITY,
    isRemote,
    paymentThroughApp: gig?.paymentType === "IN_APP",
    workerHasPaymentMethod,
    gigType: gig?.gigType,
    gigIsActive:
      currentStateName && ["ACTIVE", "IN_PROGRESS"].includes(currentStateName),
    hasMultipleShifts: shiftCount > 1,
    hasMissingQualifications
  };
}
