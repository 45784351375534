/**
 * @generated SignedSource<<e6ab0e64d93a30350888a5009d575332>>
 * @relayHash 3f2013ff45581582085089f0a64df1c6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:QzLyF48-HNO4uky8hN4TYYr6If0dKMsWRdMnZ0BBuk8

import { ConcreteRequest } from 'relay-runtime';
export type AddDirectHireClaimPhotoInput = {
  directHireClaimId: string;
  userFileId: string;
};
export type EvidenceStepAddPhotoMutation$variables = {
  input: AddDirectHireClaimPhotoInput;
};
export type EvidenceStepAddPhotoMutation$data = {
  readonly addDirectHireClaimPhoto: {
    readonly newDirectHireClaimPhotoEdge: {
      readonly node: {
        readonly id: string;
        readonly url: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EvidenceStepAddPhotoMutation = {
  response: EvidenceStepAddPhotoMutation$data;
  variables: EvidenceStepAddPhotoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddDirectHireClaimPhotoPayload",
    "kind": "LinkedField",
    "name": "addDirectHireClaimPhoto",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaimPhotosEdge",
        "kind": "LinkedField",
        "name": "newDirectHireClaimPhotoEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectHireClaimPhoto",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EvidenceStepAddPhotoMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EvidenceStepAddPhotoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:QzLyF48-HNO4uky8hN4TYYr6If0dKMsWRdMnZ0BBuk8",
    "metadata": {},
    "name": "EvidenceStepAddPhotoMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4be7daeae8055d7badbbd1b9fb027f56";

export default node;
