import {
  Column,
  ContentArea,
  Divider,
  ErrorPage,
  HighlightedReminder,
  ListRow,
  Row,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { getEngagementAddonSpec } from "@gigsmart/isomorphic-shared/addon/dictionary";
import { currency, duration, time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { Duration } from "luxon";
import React from "react";
import CancellationPoliciesInfoModal from "../../gig/CancellationPoliciesInfoModal";
import { formatDuration } from "../../utils/time";
import type { WorkerCancellationFeeSummary_shift$key } from "./__generated__/WorkerCancellationFeeSummary_shift.graphql";

type Props = {};

export const WorkerCancellationFeeSummary = ({
  currentState,
  cancellationFee,
  paymentInfo
}: FragmentContainerInnerComponentProps<
  WorkerCancellationFeeSummary_shift$key,
  Props
>) => {
  if (!cancellationFee) {
    return (
      <ErrorPage
        message="Please contact support@gigsmart.com for assistance."
        title="Error Loading Cancellation Data"
      />
    );
  }

  const startDeltaDuration = Duration.fromISO(cancellationFee.startDelta);
  const before = startDeltaDuration.as("seconds") > 0;
  const at = startDeltaDuration.as("seconds") === 0;
  const timeFrame = at
    ? "at"
    : before
      ? `${formatDuration(startDeltaDuration)} before`
      : `${formatDuration(startDeltaDuration)} after`;
  const scheduledDuration = duration.humanize(
    cancellationFee.scheduledDuration,
    "compact"
  );
  const cancelletionFeeRate = (cancellationFee.feeRate ?? 0) * 100;

  return (
    <Surface>
      <ContentArea gap="standard">
        <Text variant="header">Cancellation Fee</Text>
        <HighlightedReminder
          icon="circle-exclamation"
          header={`The Requester canceled this Shift ${timeFrame} its start time. As a result, you have been compensated ${Math.round(
            cancellationFee.feeRate * 100
          )}% of your scheduled time for this Shift for a total of ${currency.humanize(
            paymentInfo?.netPay
          )}.`}
        />
        <Text variant="subheader">Cancellation Timing</Text>
        <Column>
          <Divider />
          <Stack variant="divider">
            <ListRow
              label="Scheduled Start Time"
              right={time.humanize(cancellationFee.scheduledAt, "dateTime")}
            />
            <ListRow
              label="Canceled Time"
              right={time.humanize(currentState.transitionedAt, "dateTime")}
            />
          </Stack>
          <Divider />
        </Column>
        <Text variant="subheader">Compensation Breakdown</Text>
        <Column>
          <Divider />
          <Stack variant="divider">
            <ListRow
              label="Hourly Rate"
              right={currency.humanizeRate(paymentInfo?.payRate)}
            />
            <ListRow
              label="Scheduled Shift Duration"
              right={duration.humanize(cancellationFee.scheduledDuration)}
            />
            <ListRow
              label="Compensation Rate"
              right={
                <Stack horizontal size="slim">
                  <Text>{Math.round(cancellationFee.feeRate * 100)}%</Text>
                </Stack>
              }
            />
            <ListRow
              label="Cancellation Compensation"
              right={currency.humanize(paymentInfo?.grossPay)}
            >
              <Text variant="note" color="neutral">
                {`(${scheduledDuration} x ${cancelletionFeeRate}%) x ${currency.humanizeRate(
                  paymentInfo?.payRate
                )}`}
              </Text>
            </ListRow>
            {(paymentInfo?.serviceFees ?? []).map(
              ({
                amount,
                feeType,
                hourlyRate,
                minimumAmount,
                percentageRate
              }) => {
                const feeDescParts = [];
                if (percentageRate > 0) feeDescParts.push(`${percentageRate}%`);
                if (currency.toFloat(minimumAmount) > 0)
                  feeDescParts.push(
                    `Minimum ${currency.humanize(minimumAmount)}`
                  );
                const label = `${getEngagementAddonSpec(feeType).name}${
                  feeDescParts.length ? ` (${feeDescParts.join(",")})` : ""
                }`;

                return (
                  <ListRow
                    key={feeType}
                    label={label}
                    right={`- ${currency.humanize(amount)}`}
                  >
                    <Text variant="note" color="neutral">
                      {`(${scheduledDuration} x ${cancelletionFeeRate}%) x ${currency.humanizeRate(
                        hourlyRate
                      )}`}
                    </Text>
                  </ListRow>
                );
              }
            )}
            <ListRow
              label={<Text weight="bold">Cancellation Fee Paid</Text>}
              right={
                <Text weight="bold">
                  {currency.humanize(paymentInfo?.netPay)}
                </Text>
              }
            />
          </Stack>
          <Divider />
        </Column>
        <Row justifyContent="center">
          <CancellationPoliciesInfoModal testID="cancellation-fee-info-modal" />
        </Row>
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  WorkerCancellationFeeSummary_shift$key,
  Props
>(
  graphql`
    fragment WorkerCancellationFeeSummary_shift on Engagement {
      id
      cancellationFee {
        feeRate
        startDelta
        scheduledAt
        scheduledDuration
      }
      paymentInfo {
        payRate
        grossPay
        netPay
        serviceFees {
          amount
          feeType
          hourlyRate
          minimumAmount
          percentageRate
        }
      }
      currentState {
        name
        transitionedAt
      }
    }
  `,
  WorkerCancellationFeeSummary
);
