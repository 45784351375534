/**
 * @generated SignedSource<<f0efdd28e9540dbc4873ecc957a47789>>
 * @relayHash 56828793d177d1b110ed3e7ba81a7061
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:VNo7tAYtyY-uiDxcLEIgWleM4Zssn2UcDctFwh_AfDo

import { ConcreteRequest } from 'relay-runtime';
export type workHistoryScreenQuery$variables = {
  id: string;
};
export type workHistoryScreenQuery$data = {
  readonly node: {
    readonly employer?: string;
    readonly employmentEnd?: string | null | undefined;
    readonly employmentStart?: string;
    readonly id?: string;
    readonly positionTitle?: string | null | undefined;
  } | null | undefined;
};
export type workHistoryScreenQuery = {
  response: workHistoryScreenQuery$data;
  variables: workHistoryScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employer",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentStart",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentEnd",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "positionTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "workHistoryScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "WorkerWorkHistory",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workHistoryScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "WorkerWorkHistory",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:VNo7tAYtyY-uiDxcLEIgWleM4Zssn2UcDctFwh_AfDo",
    "metadata": {},
    "name": "workHistoryScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "30e27928bb4275e4c4292c901f68c409";

export default node;
