import React, { type ReactNode } from "react";

import {
  Carousel,
  type Color,
  Column,
  ContentArea,
  type IconName,
  Text,
  WysiwygContent
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { CollapsibleCard, FadedContainer, StyledView } from "@gigsmart/katana";
import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import GigTags from "@gigsmart/seibutsu/gig/GigTags";
import type { GigType } from "../../available-gig/__generated__/availableGigSeriesDetailQuery.graphql";
import type { gigDescription_gigLike$key } from "./__generated__/gigDescription_gigLike.graphql";

const selectDescription = (
  type: GigType | null | undefined
): { title: string; icon: IconName; color: Color } => {
  switch (type) {
    case "PROJECT":
      return {
        title: "Project Gig",
        icon: "pen-ruler",
        color: "primary"
      };
    default:
      return {
        title: PositionsAndLocations.select("Shift", "Shift Gig"),
        icon: "business-time",
        color: "warning"
      };
  }
};

const getDescriptionSection = (
  gigType: GigType | null | undefined,
  collapsed: boolean,
  description: string
) => {
  const newDescr =
    gigType === "PROJECT"
      ? description
      : `<div style="white-space: pre-wrap">${description}</div>`;

  return (
    <>
      {collapsed ? (
        <FadedContainer>
          <WysiwygContent html={description} />
        </FadedContainer>
      ) : (
        <StyledView verticalPadding={0} horizontalPadding={0}>
          <WysiwygContent html={newDescr} />
        </StyledView>
      )}
    </>
  );
};

interface Props {
  readonly gigLikeRef?: gigDescription_gigLike$key | null;
  readonly collapsed?: boolean;
  isApplied?: boolean;
  header?: ReactNode;
}

const GigDescription = ({
  gigLikeRef,
  collapsed,
  isApplied,
  header
}: Props) => {
  const gigLike = useRelayFragment<gigDescription_gigLike$key>(
    graphql`
      fragment gigDescription_gigLike on GigLike {
        id
        name
        description
        gigType
        ...GigTags_gigLike
        ... on Gig {
          estimatedMileage
          photos(first: 10) {
            totalCount
            edges {
              node {
                url
              }
            }
          }
        }
        ... on GigSeries {
          estimatedMileage
          photos(first: 10) {
            totalCount
            edges {
              node {
                url
              }
            }
          }
        }
      }
    `,
    gigLikeRef ?? null
  );

  if (!gigLike) return null;
  const { description, name, photos } = gigLike;
  const hasLongDescription = Number(description?.length) >= 140;
  const hasPhotos = (photos?.totalCount ?? 0) > 0;
  const collapsible = hasLongDescription || hasPhotos;
  const descr = selectDescription(gigLike?.gigType);

  const renderContent = (collapsed: boolean) => {
    const descriptionSection = getDescriptionSection(
      gigLike?.gigType,
      collapsed,
      description ?? ""
    );

    return (
      <Column gap="standard">
        <Text variant="header" weight="bold">
          {name}
        </Text>
        {header}
        <GigTags
          fragmentRef={gigLike}
          additionalTags={[
            ...(isApplied
              ? [
                  {
                    key: "state",
                    label: descr.title,
                    icon: descr.icon,
                    color: descr.color
                  }
                ]
              : [])
          ]}
        />
        {descriptionSection}
        {!collapsed && hasPhotos && (
          <ContentArea size="compact" variant="none">
            <Carousel
              sources={getConnectionNodes(photos).map((item) => item.url)}
              thumbSize={32.5}
            />
          </ContentArea>
        )}
      </Column>
    );
  };

  return (
    <CollapsibleCard
      showButton={collapsible}
      content={renderContent(false)}
      collapsedContent={renderContent(true)}
      startCollapsed={collapsible && collapsed}
    />
  );
};

export default GigDescription;
