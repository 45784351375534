import { Text, showModal } from "@gigsmart/atorasu";
import { DateTime } from "luxon";
import React, { type ComponentProps, useCallback } from "react";
import DynamicLegalDocument from "./DynamicLegalDocument";
import {
  type UseLegalDocumentOptions,
  useLegalDocument
} from "./useLegalDocument";

type Props = Pick<ComponentProps<typeof Text>, "variant"> &
  UseLegalDocumentOptions & {
    filename: string;
  };

export default function LegalDocumentLink({
  filename,
  defaultTitle,
  defaultDescription,
  defaultPrompt,
  userId,
  ...textProps
}: Props) {
  const { title, consent, format, body } = useLegalDocument(filename, {
    defaultTitle,
    defaultDescription,
    defaultPrompt,
    userId
  });
  const show = useCallback(() => {
    showModal({
      dismissable: true,
      eventContext: `legal/${filename}`,
      title: title,
      subTitle: consent
        ? `You consented to this agreement on ${DateTime.fromISO(
            consent.consentedAt
          ).toLocaleString(DateTime.DATETIME_FULL)} from IP: ${
            consent.consentedIp
          }.`
        : undefined,
      fixedHeight: true,
      scrollable: true,
      actions: [
        {
          icon: "times",
          label: "Close",
          testID: "close",
          autoClose: true
        }
      ],
      children: (
        <DynamicLegalDocument filename={filename} format={format} body={body} />
      )
    });
  }, [title, consent, format, body]);

  return (
    <Text testID={filename} color="link" onPress={show} {...textProps}>
      {title}
    </Text>
  );
}
