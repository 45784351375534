import React, { type ReactNode } from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  children: ReactNode;
  noPadding?: boolean;
  style?: ViewStyleProp;
}

export default function BackgroundCard({
  children,
  style,
  noPadding,
  ...props
}: Props) {
  const { styles } = useStyles(({ color, shadow, unit }) => ({
    card: {
      backgroundColor: color.white,
      borderColor: color.blue,
      borderBottomWidth: 2,
      borderTopWidth: 2,
      position: "relative",
      marginBottom: unit.margin.large,
      paddingHorizontal: 112,
      width: "70%",
      marginHorizontal: "auto",
      ...shadow(),
      "@tablet": {
        paddingHorizontal: unit.padding.large * 2
      },
      "@phone": {
        width: "100%",
        paddingHorizontal: 0
      }
    },
    noPadding: {
      paddingHorizontal: 0
    }
  }));
  return (
    <View
      style={[styles.card, !!noPadding && styles.noPadding, style]}
      {...props}
    >
      {children}
    </View>
  );
}
