import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import RequesterReviews from "../requester/requester-reviews";
import type { requesterReviewsScreenQuery } from "./__generated__/requesterReviewsScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "BrowseGigReviews">;

export default createSuspendedQueryContainer<
  requesterReviewsScreenQuery,
  Props
>(
  function RequesterReviewsScreen({ response: result }) {
    return <RequesterReviews requesterId={result?.node?.requester?.id ?? ""} />;
  },
  {
    query: graphql`
      query requesterReviewsScreenQuery($id: ID!) {
        node(id: $id) {
          ... on GigLike {
            requester {
              id
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params.id })
  }
);
