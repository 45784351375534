import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledOptionalTextInput } from "@gigsmart/katana";
import React, { type ComponentProps } from "react";
import { Platform } from "react-native";

type RefinedStyledOptionalTextInputProps = Omit<
  ComponentProps<typeof StyledOptionalTextInput>,
  "onSubmitEditing" | "value" | "onChangeText" | "errors"
>;

type Props = RefinedStyledOptionalTextInputProps & FormFieldOptions;

export function FomuStyledOptionalTextInput({
  name,
  validates,
  ...props
}: Props) {
  const { setValue, value, submit, errors } = useFormField({
    name,
    validates
  });
  return (
    <StyledOptionalTextInput
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value}
      onChangeText={setValue}
      errors={errors}
      {...props}
    />
  );
}
