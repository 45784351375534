/**
 * @generated SignedSource<<2c22e401522054009d876318ed1eba35>>
 * @relayHash bee0d1366d14b9df9f5ea1c9b8a7d03f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:TsZ7EgNjC6z305nk4jm4eoZQqvPgJnE9Xf8y2rJ6eC4

import { ConcreteRequest } from 'relay-runtime';
export type requesterReviewsScreenQuery$variables = {
  id: string;
};
export type requesterReviewsScreenQuery$data = {
  readonly node: {
    readonly requester?: {
      readonly id: string;
    };
  } | null | undefined;
};
export type requesterReviewsScreenQuery = {
  response: requesterReviewsScreenQuery$data;
  variables: requesterReviewsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Requester",
      "kind": "LinkedField",
      "name": "requester",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "requesterReviewsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "requesterReviewsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:TsZ7EgNjC6z305nk4jm4eoZQqvPgJnE9Xf8y2rJ6eC4",
    "metadata": {},
    "name": "requesterReviewsScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d0c7a94b6d5b9a1ac883e72b95e771ec";

export default node;
