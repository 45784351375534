import React, { useEffect, useState } from "react";

import Stack from "../../container/stack";
import { FlexRow } from "../../flex";
import { useStyles, useViewport } from "../../style";
import { SmallOutlineButton, SmallSolidButton } from "../../tappable";
import { StyledText } from "../../text";

export interface Tab {
  label: string;
  smallLabel?: string;
  count?: number;
  testID?: string;
  onPress?: () => void;
}

interface Props {
  tabs: Tab[];
  defaultTab?: string | null | undefined;
}

export default function ButtonTabs({ tabs, defaultTab }: Props) {
  const { isMobile } = useViewport();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { styles } = useStyles(({ color, unit, units }) => ({
    numberContainer: {
      backgroundColor: color.blue,
      borderRadius: unit.borderRadius,
      paddingHorizontal: units(1),
      height: 18,
      paddingTop: 1,
      marginRight: units(2)
    }
  }));

  const countContent = (count: number | undefined) => {
    if (!count && count !== 0) return null;
    return (
      <FlexRow
        style={styles.numberContainer}
        justifyContent="center"
        alignItems="center"
      >
        <StyledText bold color="white">
          {count}
        </StyledText>
      </FlexRow>
    );
  };

  useEffect(() => {
    if (defaultTab !== activeTab) setActiveTab(defaultTab);
  }, [activeTab, defaultTab]);

  return (
    <Stack direction="row" spacing={2}>
      {tabs?.map(({ label, smallLabel, count, testID, onPress }) => {
        const title = isMobile && smallLabel ? smallLabel : label;
        const ButtonComponent =
          activeTab === label ? SmallSolidButton : SmallOutlineButton;
        return (
          <ButtonComponent
            key={title}
            title={title}
            Content={countContent(count)}
            testID={testID}
            onPress={() => {
              if (activeTab !== label) {
                setActiveTab(label);
                onPress?.();
              }
            }}
          />
        );
      })}
    </Stack>
  );
}
