import React, { type ComponentProps } from "react";

import { useStyles } from "@gigsmart/atorasu/style";
import { BulletListItem } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import type { RestrictedAction_restriction$key } from "./__generated__/RestrictedAction_restriction.graphql";

interface Props {
  restriction: RestrictedAction_restriction$key;
}
export default function RestrictedAction({
  restriction: restrictionRef
}: Props) {
  const restrictedAction = useRelayFragment(
    graphql`
      fragment RestrictedAction_restriction on RestrictedUserAction {
        type
        description
      }
    `,
    restrictionRef
  );

  const styles = useStyles(({ getUnits }) => ({
    textStyle: { lineHeight: getUnits(4), fontWeight: "700" },
    iconStyle: {
      minWidth: 0,
      paddingRight: getUnits(2),
      lineHeight: getUnits(4)
    }
  }));

  const bulletProps: ComponentProps<typeof BulletListItem> = {
    iconName: "circle",
    iconSize: 4,
    iconColor: "black",
    textStyle: styles.textStyle,
    iconStyle: styles.iconStyle
  };

  return (
    <BulletListItem {...bulletProps} bold>
      {restrictedAction?.description ?? "Not found"}
    </BulletListItem>
  );
}
