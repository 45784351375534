import { graphql } from "@gigsmart/relay";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import type { mediaPickerMutation } from "./__generated__/mediaPickerMutation.graphql";

export type FileUpload = NonNullable<
  ObjectUtils.Path<
    mediaPickerMutation,
    ["response", "addUserFile", "newUserFileEdge", "node"]
  >
>;

export const uploadMutation = graphql`
  mutation mediaPickerMutation($input: AddUserFileInput!) {
    addUserFile(input: $input) {
      newUserFileEdge {
        node {
          id
          url
          mimeType
        }
      }
    }
  }
`;
