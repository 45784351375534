/**
 * @generated SignedSource<<0436745de99e8bc6fe10d3702ae73b46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerShiftCard_gigLike$data = {
  readonly __typename: string;
  readonly boosted?: boolean;
  readonly estimatedMileage: number | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCardBody_gig" | "WorkerShiftCardFooter_gigLike" | "WorkerShiftCardHeader_gigLike">;
  readonly " $fragmentType": "WorkerShiftCard_gigLike";
};
export type WorkerShiftCard_gigLike$key = {
  readonly " $data"?: WorkerShiftCard_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCard_gigLike">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerShiftCard_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedMileage",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftCardHeader_gigLike"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftCardFooter_gigLike"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftCardBody_gig"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "boosted",
          "storageKey": null
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "abfbfba3cb05f63e2b115cc81820611b";

export default node;
