/**
 * @generated SignedSource<<7bebffbb42dc82db420685486223127c>>
 * @relayHash 7213f6d8262d55ce125f51d84ee26965
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:9Lqun4zEQGVEpOADeqHSpA_5mCeUDQK7aI91glkR0fk

import { ConcreteRequest } from 'relay-runtime';
export type EmailWorkerEarningsInput = {
  emailAddress?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type earningsHistoryCard_sendEarningsEmailMutation$variables = {
  input: EmailWorkerEarningsInput;
};
export type earningsHistoryCard_sendEarningsEmailMutation$data = {
  readonly emailWorkerEarnings: {
    readonly ok: boolean;
  } | null | undefined;
};
export type earningsHistoryCard_sendEarningsEmailMutation = {
  response: earningsHistoryCard_sendEarningsEmailMutation$data;
  variables: earningsHistoryCard_sendEarningsEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmailWorkerEarningsPayload",
    "kind": "LinkedField",
    "name": "emailWorkerEarnings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "earningsHistoryCard_sendEarningsEmailMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "earningsHistoryCard_sendEarningsEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:9Lqun4zEQGVEpOADeqHSpA_5mCeUDQK7aI91glkR0fk",
    "metadata": {},
    "name": "earningsHistoryCard_sendEarningsEmailMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f440d6242a82a2437beb31ae4b2da264";

export default node;
