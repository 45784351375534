/**
 * @generated SignedSource<<d0435adad48737cce78e4667fa0a5121>>
 * @relayHash 9ffff4c5a78db46cfb4c07d2666e907d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:KkPYMak6DDVckDEMomcVQ4sbk4m7_bm96CoZMnUDyxo

import { ConcreteRequest } from 'relay-runtime';
export type GigPositionStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type UpdateWorkerGigPositionInput = {
  gigPositionId: string;
  status: GigPositionStatus;
  workerId: string;
};
export type PositionsStepMutation$variables = {
  input: UpdateWorkerGigPositionInput;
};
export type PositionsStepMutation$data = {
  readonly updateWorkerGigPosition: {
    readonly workerGigPosition: {
      readonly id: string;
      readonly status: GigPositionStatus;
    };
  } | null | undefined;
};
export type PositionsStepMutation = {
  response: PositionsStepMutation$data;
  variables: PositionsStepMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorkerGigPositionPayload",
    "kind": "LinkedField",
    "name": "updateWorkerGigPosition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerGigPosition",
        "kind": "LinkedField",
        "name": "workerGigPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PositionsStepMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PositionsStepMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:KkPYMak6DDVckDEMomcVQ4sbk4m7_bm96CoZMnUDyxo",
    "metadata": {},
    "name": "PositionsStepMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b6d89a5db8dbb2f046ac63710d8f9011";

export default node;
