import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { HighlightedReminder, Text, TimeTicker } from "@gigsmart/atorasu";
import { DateTime } from "luxon";
import type { ShiftCommitmentReminder_commitment$key } from "./__generated__/ShiftCommitmentReminder_commitment.graphql";

type Props = {};

export const ShiftCommitmentReminder = ({
  response,
  expiresAt
}: FragmentContainerInnerComponentProps<
  ShiftCommitmentReminder_commitment$key,
  Props
>) => {
  switch (response) {
    case "ACCEPT":
      return (
        <HighlightedReminder
          icon="check"
          variant="success"
          note="Failure to show up for your Shift may result in being blocked from working future Shifts for the hiring Organization."
          header="You've confirmed this Shift"
        />
      );
    case "DECLINE":
      return (
        <HighlightedReminder
          icon="times"
          variant="danger"
          header="You've declined this Shift"
        />
      );
    case "TIMEOUT":
      return (
        <HighlightedReminder
          icon="times"
          variant="danger"
          note="You failed to respond to the confirmation prompt. This may result in a temporary or permanent ban from the GigSmart Platform."
          header="You were canceled from the Shift"
        />
      );
    default:
      return (
        <HighlightedReminder
          icon="stopwatch"
          variant="primary"
          note="Failure to respond will result in being placed back into Offered on the Shift and the potential that another Worker may fill the position before you re-accept the outstanding offer."
          header={
            <TimeTicker>
              {(currentTime) => {
                const diff = DateTime.fromJSDate(currentTime)
                  .until(DateTime.fromISO(expiresAt))
                  .toDuration(["hours", "minutes"])
                  .rescale()
                  .set({ milliseconds: 0, seconds: 0 })
                  .plus({ minutes: 1 })
                  .rescale();
                return (
                  <Text>
                    You have{" "}
                    {diff.isValid ? diff.toHuman() : "no time remaining"} to
                    respond
                  </Text>
                );
              }}
            </TimeTicker>
          }
        />
      );
  }
};

export default createRelayFragmentContainer<
  ShiftCommitmentReminder_commitment$key,
  Props
>(
  graphql`
    fragment ShiftCommitmentReminder_commitment on EngagementCommitment {
      expiresAt
      response
    }
  `,
  ShiftCommitmentReminder
);
