/**
 * @generated SignedSource<<15f6eb32a4639e216b309f6651ee0377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DirectHirePaymentCollapsibleCard_Worker$data = {
  readonly directHireBonus: string;
  readonly " $fragmentType": "DirectHirePaymentCollapsibleCard_Worker";
};
export type DirectHirePaymentCollapsibleCard_Worker$key = {
  readonly " $data"?: DirectHirePaymentCollapsibleCard_Worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectHirePaymentCollapsibleCard_Worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectHirePaymentCollapsibleCard_Worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireBonus",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "e37ff5acb82530c5cf1a0dce13423a07";

export default node;
