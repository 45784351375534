/**
 * @generated SignedSource<<672e992912a29c0c4b2a4fdabfd35a3b>>
 * @relayHash 2b4b33c5e7321917ef86c0e2f1280684
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:YRdy4Tizq_ffW6cUwDx8uJSKzRcn4tSSerEVvYGMe0k

import { ConcreteRequest } from 'relay-runtime';
export type AccountMode = "BUSINESS" | "PERSONAL" | "%future added value";
export type GigReportingType = "HIDDEN" | "MANAGED_SERVICES" | "NO_PREAUTH" | "STANDARD" | "VARIABLE_BILLING" | "%future added value";
export type UpdateRequesterInput = {
  accountMode?: AccountMode | null | undefined;
  administrativeArea1?: string | null | undefined;
  biography?: string | null | undefined;
  birthdate?: string | null | undefined;
  disableOtp?: boolean | null | undefined;
  firstName?: string | null | undefined;
  hubspotVid?: string | null | undefined;
  isAgeConfirmed?: boolean | null | undefined;
  isApproved?: boolean | null | undefined;
  jobTitle?: string | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  postalCode?: string | null | undefined;
  referralSource?: string | null | undefined;
  reportingType?: GigReportingType | null | undefined;
  requesterId?: string | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
};
export type ZipCodeStepRquesterMutation$variables = {
  input: UpdateRequesterInput;
};
export type ZipCodeStepRquesterMutation$data = {
  readonly updateRequester: {
    readonly requester: {
      readonly postalCode: string | null | undefined;
    };
  } | null | undefined;
};
export type ZipCodeStepRquesterMutation = {
  response: ZipCodeStepRquesterMutation$data;
  variables: ZipCodeStepRquesterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ZipCodeStepRquesterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRequesterPayload",
        "kind": "LinkedField",
        "name": "updateRequester",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Requester",
            "kind": "LinkedField",
            "name": "requester",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ZipCodeStepRquesterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRequesterPayload",
        "kind": "LinkedField",
        "name": "updateRequester",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Requester",
            "kind": "LinkedField",
            "name": "requester",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:YRdy4Tizq_ffW6cUwDx8uJSKzRcn4tSSerEVvYGMe0k",
    "metadata": {},
    "name": "ZipCodeStepRquesterMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4f361f53fa870dcbe020b15ecd880c67";

export default node;
