import {
  Button,
  ButtonTabs,
  Column,
  Row,
  SearchInput
} from "@gigsmart/atorasu";
import { useFormRef } from "@gigsmart/fomu";
import type { ValueObject } from "@gigsmart/fomu";
import React, { useState, useMemo } from "react";
import type { ComponentProps, ComponentType, ReactNode } from "react";
import type FilterModal from "../../filter/FilterModal";
import ListFilterBubbles from "./ListFilterBubbles";
import type { Bubble } from "./ListFilterBubbles";

export type Values<T> = {
  searchTerm?: string;
  activeTab?: string;
} & ValueObject &
  T;

export interface Props<T> {
  testID: string;
  placeholder?: string;
  onChange: (values: Values<T>) => void;
  tabFilters?: ComponentProps<typeof ButtonTabs>["tabs"];
  initialFilterValues?: Values<T>;
  FilterModalComponent?: ComponentType<BaseFilterModalProps<T>>;
  InlineFilterComponent?: ComponentType<BaseFilterProps<T>>;
  getBubblesFn?: getBubblesFn<T>;
  isEmpty?: boolean;
  CSVButtonComponent?: (filter: T) => ReactNode;
}

export interface BaseFilterModalProps<T> extends BaseFilterProps<T> {
  visible: ComponentProps<typeof FilterModal>["visible"];
  onCancel: ComponentProps<typeof FilterModal>["onCancel"];
}

export interface BaseFilterProps<T> {
  formRef: ComponentProps<typeof FilterModal>["formRef"];
  onSubmit: ComponentProps<typeof FilterModal>["onSubmit"];
  searchTerm?: string;
  activeTab?: string;
  initialValues?: Values<T>;
}

export type getBubblesFn<T> = (filters: T) => Bubble[];

export default function ListFilterHeader<T>({
  testID,
  placeholder = "Search",
  tabFilters,
  onChange,
  FilterModalComponent,
  InlineFilterComponent,
  getBubblesFn,
  initialFilterValues,
  isEmpty,
  CSVButtonComponent
}: Props<T>) {
  const formRef = useFormRef();
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(tabFilters?.[0]?.value);
  const [searchTerm, setSearchTerm] = useState(initialFilterValues?.searchTerm);
  const [filters, setFilters] = useState(initialFilterValues as ValueObject);
  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    const newFilters: ValueObject = {
      ...filters,
      activeTab,
      searchTerm: value
    };
    onChange(newFilters as Values<T>);
  };

  const handleActiveTabChange = (value: string) => {
    setActiveTab(value);
    const newFilters: ValueObject = {
      ...filters,
      searchTerm,
      activeTab: value
    };
    onChange(newFilters as Values<T>);
  };
  const handleSubmit = (values: ValueObject) => {
    setFilters(values);
    const newFilters = { ...values, searchTerm, activeTab };
    onChange(newFilters as Values<T>);
  };
  const handleRemoveBubble = (initialValues: ValueObject) => {
    const newFilters: ValueObject = { ...filters, ...initialValues };
    setFilters(newFilters);
    formRef?.current?.setValues(newFilters);
    const onChangeFilters = { ...newFilters, searchTerm, activeTab };
    onChange(onChangeFilters as Values<T>);
  };
  const bubbles = useMemo(
    () => (getBubblesFn && filters ? getBubblesFn(filters as T) : null),
    [getBubblesFn, filters]
  );
  return (
    <Column gap="standard" fill>
      {tabFilters && activeTab && (
        <ButtonTabs
          tabs={tabFilters}
          activeTab={activeTab}
          setActiveTab={handleActiveTabChange}
        />
      )}
      <Row gap="standard" alignItems="center">
        {isEmpty && !searchTerm?.length ? null : (
          <SearchInput
            fill
            testID={`${testID}-search-bar`}
            placeholder={placeholder}
            onChangeText={handleSearchTermChange}
            value={searchTerm}
            backgroundColor="foreground"
          />
        )}
        {!!FilterModalComponent && !isEmpty && (
          <Button
            alignSelf="center"
            testID={`${testID}-open-filter-modal-btn`}
            icon="filter"
            label="Filter"
            outline
            size="small"
            onPress={() => setShowModal(true)}
          />
        )}
        {!isEmpty && CSVButtonComponent?.(filters as T)}
      </Row>
      {!!InlineFilterComponent && (
        <InlineFilterComponent
          formRef={formRef}
          onSubmit={handleSubmit}
          searchTerm={searchTerm}
          activeTab={activeTab}
          initialValues={initialFilterValues}
        />
      )}
      {bubbles && bubbles?.length > 0 && (
        <ListFilterBubbles
          bubbles={bubbles}
          handleRemoveBubble={handleRemoveBubble}
        />
      )}
      {!!FilterModalComponent && (
        <FilterModalComponent
          formRef={formRef}
          visible={showModal}
          onSubmit={handleSubmit}
          onCancel={() => setShowModal(false)}
          searchTerm={searchTerm}
          activeTab={activeTab}
          initialValues={initialFilterValues}
        />
      )}
    </Column>
  );
}
