import React, { type ElementType } from "react";

import Card from "../container/card";
import Stack from "../container/stack";
import StyledView from "../container/styled-view";
import StyledIcon from "../icon/styled-icon";
import { useStyles, useViewport } from "../style";
import KatanaText from "../text/katana-text";
import KatanaTouchableOpacity from "./katana-touchable-opacity";

interface Props {
  title: string;
  Icon: ElementType;
  eventTargetName: string | null;
  onPress: () => void;
  testID?: string;
}

const TappableCard = ({
  title,
  Icon,
  eventTargetName,
  onPress,
  testID
}: Props) => {
  const { styles } = useStyles(({ units }) => ({
    container: { paddingVertical: units(2) }
  }));
  const { isDesktop } = useViewport();
  return (
    <KatanaTouchableOpacity
      eventTargetName={eventTargetName}
      onPress={onPress}
      testID={testID}
    >
      <StyledView color="transparent" verticalPadding={0} horizontalPadding={1}>
        <Card
          style={styles.container}
          noMargin
          shadow={!isDesktop}
          border={isDesktop}
        >
          <Stack
            fill={1}
            inset={4}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack fill={1} direction="row" alignItems="center">
              <Icon />
              <KatanaText>{title}</KatanaText>
            </Stack>
            <StyledIcon
              name="chevron-right"
              size={14}
              color="neutralDark"
              variant="light"
            />
          </Stack>
        </Card>
      </StyledView>
    </KatanaTouchableOpacity>
  );
};

export default TappableCard;
