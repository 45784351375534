import { Icon, Stack } from "@gigsmart/atorasu";
import type { ValueObject } from "@gigsmart/fomu";
import React from "react";
import type { ComponentProps } from "react";
import FilterBubble from "../../filter/FilterBubble";

export interface Bubble
  extends Omit<ComponentProps<typeof FilterBubble>, "onRemove"> {
  initialValues: ValueObject;
  id?: string;
}

interface Props {
  bubbles?: Bubble[] | null;
  handleRemoveBubble: (v: ValueObject) => void;
}

export default function ListFilterBubbles({
  bubbles,
  handleRemoveBubble
}: Props) {
  if (!bubbles?.length) return null;
  return (
    <Stack horizontal wrap="wrap" size="none" alignItems="center">
      <Icon name="filter" variant="solid" size="medium" color="neutral" />
      {bubbles?.map(({ initialValues, ...bubble }) => (
        <FilterBubble
          {...bubble}
          key={bubble.testID}
          onRemove={() => handleRemoveBubble(initialValues)}
        />
      ))}
    </Stack>
  );
}
