/**
 * @generated SignedSource<<260dca6619d7fb772741e81d20134464>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftGroupChatHomeTabCard_shift$data = {
  readonly userMessageStats: {
    readonly unreadCount: number;
  };
  readonly " $fragmentType": "ShiftGroupChatHomeTabCard_shift";
};
export type ShiftGroupChatHomeTabCard_shift$key = {
  readonly " $data"?: ShiftGroupChatHomeTabCard_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftGroupChatHomeTabCard_shift">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftGroupChatHomeTabCard_shift",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "conversationTypes",
          "value": [
            "SHIFT_GROUP"
          ]
        }
      ],
      "concreteType": "UserMessageStats",
      "kind": "LinkedField",
      "name": "userMessageStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        }
      ],
      "storageKey": "userMessageStats(conversationTypes:[\"SHIFT_GROUP\"])"
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};

(node as any).hash = "dc9f588bbc899279a631fcd66ace6aea";

export default node;
