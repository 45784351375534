import React from "react";

import { ContentArea, Spacer, Stack } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Card, SmallOutlineButton } from "@gigsmart/katana";
import JobBoardInfoContent from "@gigsmart/seibutsu/modals/JobBoardInfoModalContent";

interface Props {
  onClose?: () => void;
}
const JobBoardInfoDismissableCard = ({ onClose }: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    card: {
      paddingHorizontal: getUnits(4)
    },
    icon: {
      fontSize: 24
    }
  }));
  return (
    <Card style={styles.card} onClose={onClose} iconStyle={styles.icon as any}>
      <ContentArea>
        <Stack>
          <Spacer size="none" />
          <JobBoardInfoContent showHeader />
          <SmallOutlineButton fill title="Got It" onPress={onClose} />
        </Stack>
      </ContentArea>
    </Card>
  );
};

export default JobBoardInfoDismissableCard;
