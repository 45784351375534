import {
  Avatar,
  Button,
  Column,
  ContentArea,
  StarRating,
  Surface,
  Text
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { OrganizationRatingRow_org$key } from "./__generated__/OrganizationRatingRow_org.graphql";

type Props = {
  onInfo?: () => void;
  primaryContactName?: string | null;
};

export const OrganizationRatingRow = ({
  name,
  logoUrl,
  reviewsReceived,
  primaryContact,
  primaryContactName,
  overallRating,
  onInfo
}: FragmentContainerInnerComponentProps<
  OrganizationRatingRow_org$key,
  Props
>) => {
  return (
    <Surface variant="outline">
      <ContentArea
        variant="compact"
        size="compact"
        gap="medium"
        horizontal
        alignItems="center"
      >
        <Avatar
          size="large"
          variant="square"
          icon="building"
          color="primary"
          uri={logoUrl}
          fade
        />
        <Column fill>
          <Text weight="bold" color="primary">
            {name}
          </Text>
          <Text variant="note" numberOfLines={1} testID="primary-contact-txt">
            Primary Contact:{" "}
            {primaryContactName ?? primaryContact?.displayName ?? "N/A"}
          </Text>
          <StarRating
            numberOfRatings={reviewsReceived?.totalCount ?? 0}
            rating={overallRating ?? 0}
            showRatings
          />
        </Column>
        {!!onInfo && (
          <Button
            testID="view-info-btn"
            size="small"
            icon="circle-info"
            variant="clear"
            label="View Info"
            onPress={onInfo}
          />
        )}
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  OrganizationRatingRow_org$key,
  Props
>(
  graphql`
    fragment OrganizationRatingRow_org on Organization @argumentDefinitions(
      skipPrimaryContact: { type: "Boolean!", defaultValue: false }
    ) {
      name
      logoUrl
      overallRating
      reviewsReceived(first: 0) {
        totalCount
      }
      primaryContact @skip(if: $skipPrimaryContact) {
        displayName
      }
    }
  `,
  OrganizationRatingRow
);
