/**
 * @generated SignedSource<<3f606b5a9e77bf322f7a9c0321644812>>
 * @relayHash e926b7624cd5e0b963c30ded120b925c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:_1t1vGN-SEBFp88RKqVDqahyQdnhHMpqpttJAcrTcng

import { ConcreteRequest } from 'relay-runtime';
export type UpdateMessageReceiptsInput = {
  query?: string | null | undefined;
  viewed?: boolean | null | undefined;
};
export type useReadAllNotificationsSilentMutation$variables = {
  input: UpdateMessageReceiptsInput;
};
export type useReadAllNotificationsSilentMutation$data = {
  readonly updateMessageReceipts: {
    readonly messageReceipts: ReadonlyArray<{
      readonly id: string;
      readonly viewedAt: string | null | undefined;
    }>;
  } | null | undefined;
};
export type useReadAllNotificationsSilentMutation = {
  response: useReadAllNotificationsSilentMutation$data;
  variables: useReadAllNotificationsSilentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMessageReceiptsPayload",
    "kind": "LinkedField",
    "name": "updateMessageReceipts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageReceipt",
        "kind": "LinkedField",
        "name": "messageReceipts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReadAllNotificationsSilentMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReadAllNotificationsSilentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:_1t1vGN-SEBFp88RKqVDqahyQdnhHMpqpttJAcrTcng",
    "metadata": {},
    "name": "useReadAllNotificationsSilentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fbdc3b3f13b0f36097fec00614ab03a8";

export default node;
