import React from "react";

import {
  ActionRow,
  Card,
  CardFooter,
  Column,
  ContentArea,
  Icon,
  Text,
  Well
} from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { ProfileCard_Worker$key } from "./__generated__/ProfileCard_Worker.graphql";

interface Props {
  directHireBonus: string;
}

export function WorkerDirectHireProfileCard({ directHireBonus }: Props) {
  const history = useHistory();
  const humanizedBonus = currency.humanize(directHireBonus ?? 0, {
    integer: true
  });
  return (
    <Card testID="profile-card" eventTargetName="Profile Card">
      <ActionRow
        testID="direct-hire-row"
        onPress={() => history.push("/direct-hire")}
        title={DirectPaymentsOnly.select(
          "Direct Payments",
          "Direct Payment & Hires"
        )}
        note={DirectPaymentsOnly.select(
          "Your Direct Payments",
          "Your Direct Payment & Hires"
        )}
        icon="handshake"
        iconColor="highlight"
        right={
          <Icon
            size="small"
            color="primary"
            variant="solid"
            name="chevron-right"
          />
        }
      />
      <CardFooter color="white" spacing="none">
        <ContentArea variant="standard" size="compact">
          <Text>
            Were you
            <Text weight="bold">
              {DirectPaymentsOnly.select(
                " paid outside the app or hired as a non-W2 employee for an Organization ",
                " paid outside of the app or hired directly by a Requester "
              )}
            </Text>
            you met through GigSmart?
          </Text>
        </ContentArea>
        <Well color="success" size="compact">
          <Column alignItems="center">
            <Text variant="subheader" color="black" weight="semibold">
              You may be eligible to receive a
            </Text>
            <Text color="success" weight="bold" italic>
              {humanizedBonus}{" "}
              {DirectPaymentsOnly.select(
                "Direct Payment Bonus!",
                "Direct Payment & Hire Bonus!"
              )}
            </Text>
          </Column>
        </Well>
      </CardFooter>
    </Card>
  );
}

export default createRelayFragmentContainer<ProfileCard_Worker$key>(
  graphql`
    fragment ProfileCard_Worker on Worker {
      directHireBonus
    }
  `,
  ({ directHireBonus }) => {
    return <WorkerDirectHireProfileCard directHireBonus={directHireBonus} />;
  }
);
