/**
 * @generated SignedSource<<edb79660514b6896a71487e575e0a9ea>>
 * @relayHash e1c7cfc0a3ff57eb8eaeb701b7e31a19
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:C1i7hVr57xM3FBrRLR-I-wQOW80qkjjfFUSYkngsRqg

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
export type AddDirectHireClaimInput = {
  comments?: string | null | undefined;
  directHireDate?: string | null | undefined;
  directHireType?: DirectHireType | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  organizationId?: string | null | undefined;
  paymentMethodId?: string | null | undefined;
  placeId?: string | null | undefined;
  response?: string | null | undefined;
  timezone?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type AgreementStepCreateClaimMutation$variables = {
  input: AddDirectHireClaimInput;
};
export type AgreementStepCreateClaimMutation$data = {
  readonly addDirectHireClaim: {
    readonly newDirectHireClaimEdge: {
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AgreementStepCreateClaimMutation = {
  response: AgreementStepCreateClaimMutation$data;
  variables: AgreementStepCreateClaimMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddDirectHireClaimPayload",
    "kind": "LinkedField",
    "name": "addDirectHireClaim",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaimsEdge",
        "kind": "LinkedField",
        "name": "newDirectHireClaimEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectHireClaim",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgreementStepCreateClaimMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgreementStepCreateClaimMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:C1i7hVr57xM3FBrRLR-I-wQOW80qkjjfFUSYkngsRqg",
    "metadata": {},
    "name": "AgreementStepCreateClaimMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "75ecb05d4a135fe8c20e8ae71c1595bb";

export default node;
