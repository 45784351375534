/**
 * @generated SignedSource<<9abdf01c1775404016c7ff33e4b2c63c>>
 * @relayHash e839140706b72d27e806f6c864092569
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:EycwjMU1rDNP2yUxZB1bVcJC8E64h1oz_2wWpN0OE2M

import { ConcreteRequest } from 'relay-runtime';
export type AddWorkerEmergencyContactInput = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  relationship: string;
  workerId?: string | null | undefined;
};
export type EmergencyContactFormContainerAddMutation$variables = {
  input: AddWorkerEmergencyContactInput;
};
export type EmergencyContactFormContainerAddMutation$data = {
  readonly addWorkerEmergencyContact: {
    readonly newWorkerEmergencyContactEdge: {
      readonly cursor: string | null | undefined;
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EmergencyContactFormContainerAddMutation = {
  response: EmergencyContactFormContainerAddMutation$data;
  variables: EmergencyContactFormContainerAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddWorkerEmergencyContactPayload",
    "kind": "LinkedField",
    "name": "addWorkerEmergencyContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerEmergencyContactsEdge",
        "kind": "LinkedField",
        "name": "newWorkerEmergencyContactEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerEmergencyContact",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmergencyContactFormContainerAddMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmergencyContactFormContainerAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:EycwjMU1rDNP2yUxZB1bVcJC8E64h1oz_2wWpN0OE2M",
    "metadata": {},
    "name": "EmergencyContactFormContainerAddMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a483afba81a85c4a9b0bf6d25a381a58";

export default node;
