import React from "react";
import AddonModalContent from "./addon-modal-content";
export default function BackgroundChecksModalContent() {
  return (
    <AddonModalContent
      introText="You can hire Workers who have passed a Background Check provided by Checkr."
      listHeader="Background checked Workers:"
      learnMoreText="Learn more about Background Checks"
      learnMoreLink="https://gigsmart.com/verified-gigs"
      listItems={[
        "Have a confirmed identity",
        "Are not a registered sex offender",
        "Are not on the global watchlist",
        "Have passed a national criminal database check"
      ]}
    />
  );
}
