import { Row, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { StyledButton, StyledModal } from "@gigsmart/katana";
import { useRelayMutation } from "@gigsmart/relay";
import atob from "atob";
import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { View } from "react-native";
import type { mediaPickerMutation } from "./__generated__/mediaPickerMutation.graphql";
import { type FileUpload, uploadMutation } from "./media-picker.mutation";

interface Props {
  onChange: (file: FileUpload) => any;
  cameraOptions?: any;
  public?: boolean;
  activeTab: "photo" | "camera";
  hideTabs?: boolean;
  cropOptions?:
    | false
    | {
        width?: number;
        height?: number;
        freeStyle?: boolean;
        circle?: boolean;
      };
}

type TabEnum = "photoGallery" | "camera";
export interface MediaPickerRefType {
  open: (activeTab?: TabEnum) => void;
}

export default forwardRef<MediaPickerRefType, Props>(function MediaPicker(
  { cropOptions, onChange },
  ref
) {
  const styles = useStyles(() => ({
    textStyles: {
      borderWidth: 1,
      borderColor: "#7b7b7b",
      borderStyle: "dashed",
      padding: 20,
      marginHorizontal: 20,
      height: 300,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      "@phone": {
        marginTop: 40,
        height: 200
      }
    },
    textContent: {
      margin: "auto",
      textAlign: "center"
    },
    modalChildren: {
      maxWidth: "100%"
    },
    slider: {
      width: 200,
      height: 40
    }
  }));
  const [commit] = useRelayMutation<mediaPickerMutation>(uploadMutation);
  const [open, setOpen] = React.useState(false);
  const [picture, setPicture] = React.useState<File | null>(null);
  const [scale, setScale] = React.useState(1);
  const editorRef = React.useRef<AvatarEditor>(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setPicture(null);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen
  }));

  const renderImageHandler = () => {
    const {
      height = 500,
      width = 500,
      circle = false
    } = cropOptions !== false ? cropOptions ?? {} : {};
    const borderRadius = circle ? Math.ceil(Math.max(height, width) / 2) : 0;

    return (
      <View>
        {picture !== null ? (
          <View>
            <Row justifyContent="flex-end">
              <StyledButton
                title="-"
                secondary
                onPress={() => setScale(scale - 0.1)}
              />
              <StyledButton
                title="+"
                secondary
                onPress={() => setScale(scale + 0.1)}
              />
            </Row>
            <AvatarEditor
              image={picture}
              ref={editorRef}
              disableBoundaryChecks
              width={500}
              height={500}
              border={50}
              scale={scale}
              borderRadius={borderRadius}
            />
            <StyledButton
              testID="media-picker-save-button"
              title="Save"
              onPress={onClickSave}
            />
          </View>
        ) : (
          <Dropzone
            onDrop={handleOnDrop}
            multiple={false}
            accept={{
              "image/*": []
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={styles.textStyles as any}>
                <input
                  {...getInputProps()}
                  data-testid="media-picker-input-file"
                />
                <Text style={styles.textContent as any}>
                  Drop an image here, or click to select a file to upload.
                </Text>
              </div>
            )}
          </Dropzone>
        )}
      </View>
    );
  };

  // Handle the upload to google cloud storage
  const handleUpload = async (file: File | Blob) => {
    commit(
      { input: { file: "file" } },
      {
        uploadables: { file },
        onSuccess: ({ addUserFile }) => {
          if (addUserFile) {
            const file = addUserFile?.newUserFileEdge?.node;
            if (file) onChange(file);
          }
        },
        onError: (error) => {
          if (error) console.error(error);
        }
      }
    );
  };

  const handleOnDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles?.[0]) {
      setPicture(acceptedFiles[0]);
    }
  };

  const onClickSave = () => {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
        value: function (
          this: HTMLCanvasElement,
          callback: (blob: any) => void,
          type: string,
          quality: number
        ) {
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            const binStr = atob(this.toDataURL(type, quality).split(",")[1]!);
            const len = binStr.length;
            const arr = new Uint8Array(len) as any;

            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            callback(new Blob([arr], { type: type || "image/png" } as any));
          });
        }
      });
    }

    if (editorRef?.current) {
      const image = editorRef?.current?.getImage();
      const canvas = document.createElement("canvas");

      const maxWidth = 1024;
      const maxHeight = 1024;

      let width = Number(image.width);
      let height = Number(image.height);

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(image, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            if (blob) void handleUpload(blob);
            handleClose();
          },
          "image/png",
          0.8
        );
      }
    }
  };

  return (
    <StyledModal
      eventContext="Media Picker"
      visible={open}
      onClose={handleClose}
      hasCancelIcon
      childrenStyle={styles.modalChildren}
    >
      {renderImageHandler()}
    </StyledModal>
  );
});
