import {
  type PermissionRequestOptions,
  type PermissionStatus,
  Platform,
  Stack,
  Text,
  usePermission
} from "@gigsmart/atorasu";
import type { DetailedPermissionResponse } from "@gigsmart/atorasu/organisms/Permissions/registry";
import { logger } from "@gigsmart/roga";
import React, { useCallback, useMemo } from "react";
import { useUserHasPendingConsents } from "../user-consent/UserDocumentConsentCheck";

interface Options {
  trace: string;
  requiresBackgroundLocation?: boolean;
  requiresNotifications?: boolean;
  requiresPreciseLocation?: boolean;
  request?: boolean;
  onDismiss?: () => void;
}

export default function useActiveGigPermissions({
  requiresBackgroundLocation,
  requiresNotifications,
  requiresPreciseLocation,
  onDismiss,
  request: requestOpt,
  trace
}: Options) {
  const userHasPendingConsents = useUserHasPendingConsents();
  const shouldRequest = !userHasPendingConsents && requestOpt;
  const notificationPermission = usePermission("notification", {
    trace: "useActiveGigPermissions",
    required:
      requiresNotifications && !userHasPendingConsents && Platform.OS !== "ios",
    init: shouldRequest ? "request" : "none",
    forceAsk: shouldRequest,
    preview: true,
    onDismiss,
    reason: useMemo(
      () =>
        requiresNotifications ? (
          <Stack>
            <Text variant="subheader" color="primary" align="center">
              Unfortunately, you do not have the permissions that are required
              for this Shift Gig.
            </Text>
            <Text align="center">
              This Shift Gig requires permissions to send Notifications. To
              continue, update your Notification permissions.
            </Text>
          </Stack>
        ) : undefined,
      []
    )
  });
  const backgroundLocation = usePermission("backgroundLocation", {
    trace: "useActiveGigPermissions",
    required: requiresBackgroundLocation && !userHasPendingConsents,
    init: shouldRequest ? "request" : "none",
    forceAsk: shouldRequest,
    preview: true,
    onDismiss,
    reason: useMemo(
      () =>
        requiresBackgroundLocation ? (
          <Stack>
            <Text variant="subheader" color="primary" align="center">
              Unfortunately, you do not have the permissions that are required
              for this Shift Gig.
            </Text>
            <Text align="center">
              This Shift Gig requires access to your location when the app is
              backgrounded. Your location will only be tracked in the background
              when actively working on the Shift.
            </Text>
          </Stack>
        ) : undefined,
      [requiresBackgroundLocation]
    )
  });

  const preciseLocation = usePermission("preciseLocation", {
    trace: "useActiveGigPermissions",
    required: requiresPreciseLocation && !userHasPendingConsents,
    init: backgroundLocation.has && shouldRequest ? "request" : "none",
    forceAsk: shouldRequest,
    preview: true,
    onDismiss,
    reason: useMemo(
      () =>
        requiresBackgroundLocation ? (
          <Stack>
            <Text variant="subheader" color="primary" align="center">
              Unfortunately, you do not have the permissions that are required
              for this Shift Gig.
            </Text>
            <Text align="center">
              This Shift Gig requires access to your location when the app is
              backgrounded. Your location will only be tracked in the background
              when actively working on the Shift.
            </Text>
          </Stack>
        ) : undefined,
      [requiresBackgroundLocation]
    )
  });

  const status = useMemo<PermissionStatus>(
    () =>
      [
        requiresNotifications && Platform.OS !== "ios"
          ? notificationPermission.status
          : "granted",
        requiresBackgroundLocation ? backgroundLocation.status : "granted",
        requiresPreciseLocation ? preciseLocation.status : "granted"
      ].every((status) => status === "granted")
        ? "granted"
        : "blocked",
    [
      requiresNotifications,
      notificationPermission.status,
      requiresBackgroundLocation,
      backgroundLocation.status,
      requiresPreciseLocation,
      preciseLocation.status
    ]
  );

  const has = useMemo<boolean>(
    () =>
      [
        requiresNotifications && Platform.OS !== "ios"
          ? notificationPermission.has
          : true,
        requiresBackgroundLocation ? backgroundLocation.has : true,
        requiresPreciseLocation ? preciseLocation.has : true
      ].every(Boolean),
    [
      requiresNotifications,
      notificationPermission.has,
      requiresBackgroundLocation,
      backgroundLocation.has,
      requiresPreciseLocation,
      preciseLocation.has
    ]
  );

  const determined = useMemo<boolean>(
    () =>
      !shouldRequest ||
      [
        notificationPermission.determined,
        backgroundLocation.determined,
        preciseLocation.determined
      ].every(Boolean),
    [
      shouldRequest,
      notificationPermission.determined,
      backgroundLocation.determined,
      preciseLocation.determined
    ]
  );

  const request = useCallback(
    logger.wrap(async (options?: Partial<PermissionRequestOptions>) => {
      const response = await [
        notificationPermission,
        backgroundLocation,
        preciseLocation
      ].reduce(
        async (acc, permission) => {
          const prevResponse = await acc;
          if (prevResponse.status !== "granted") return prevResponse;
          return await permission.request(options);
        },
        Promise.resolve<DetailedPermissionResponse>({ status: "granted" })
      );
      return response.status;
    }, `${trace}.useActiveGigPermissions.request`),
    [
      notificationPermission.request,
      backgroundLocation.request,
      preciseLocation.request,
      trace
    ]
  );

  return {
    request,
    status,
    determined,
    has
  };
}
