import React, { useEffect, useMemo } from "react";
import { FlatList, type ScrollViewProps } from "react-native";

import { useHistory } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayPaginationFragment
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import { JobBoardConversion } from "../conversions";

import AffiliateJobCardFragment from "./affiliate-job-card-fragment";
import JobCardFragment from "./job-card-fragment";
import JobsListEmptyMessage from "./jobs-list-empty-message";
import JobsListFooter from "./jobs-list-footer";
import JobsListHeader from "./jobs-list-header";

import type { jobsListQuery } from "./__generated__/jobsListQuery.graphql";
import type {
  jobsList_affiliateJobPostings$data,
  jobsList_affiliateJobPostings$key
} from "./__generated__/jobsList_affiliateJobPostings.graphql";
import type {
  jobsList_jobPostings$data,
  jobsList_jobPostings$key
} from "./__generated__/jobsList_jobPostings.graphql";

import type { Values } from "./index";

type AffiliateJobEdge = ObjectPath<
  jobsList_affiliateJobPostings$data,
  ["affiliateJobPostings", "edges", 0]
>;

type JobEdge = ObjectPath<
  jobsList_jobPostings$data,
  ["availableJobPostings", "edges", 0]
>;

type JobNode = ObjectPath<
  jobsList_jobPostings$data,
  ["availableJobPostings", "edges", 0, "jobPost"]
>;

interface Props {
  filters: Values;
  onPressAffiliate?: (job: { name: string; url: string }) => void;
  onScroll?: ScrollViewProps["onScroll"];
}

export default createSuspendedQueryContainer<jobsListQuery, Props>(
  function JobsList({
    response: result,
    setVariables,
    filters,
    onPressAffiliate,
    onScroll
  }) {
    const history = useHistory();
    useEffect(() => {
      const maxDistance = filters.location ? filters.radiusMiles : undefined;
      setVariables({
        search: {
          maxDistance,
          latitude: filters.location.lat,
          longitude: filters.location.lng,
          search: filters.searchTerms,
          orderBy: [{ field: "boosted", direction: "DESC" }]
        },
        input: {
          maxDistance,
          latitude: filters.location.lat,
          longitude: filters.location.lng,
          search: filters.searchTerms
        }
      });
    }, [filters]);

    const {
      data: jobPostingsData,
      hasNext: hasMoreJobPostings,
      loadNext: loadMoreJobPostings
    } = useRelayPaginationFragment<jobsListQuery, jobsList_jobPostings$key>(
      graphql`
        fragment jobsList_jobPostings on Worker
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 20 }
          after: { type: "String" }
          search: { type: "JobPostingSearch" }
        )
        @refetchable(queryName: "jobsListJobPostingsPaginationQuery") {
          availableJobPostings(first: $count, after: $after, search: $search)
            @connection(key: "jobsList_availableJobPostings") {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              __typename
              jobPost: node @trackImpressions(action: ACCESSED) {
                id
                __typename
                ...jobCardFragment_job
              }
            }
          }
        }
      `,
      result?.viewer ?? null
    );
    const {
      data: affiliateJobPostingsData,
      hasNext: hasMoreAffiliateJobPostings,
      loadNext: loadMoreAffiliateJobPostings
    } = useRelayPaginationFragment<
      jobsListQuery,
      jobsList_affiliateJobPostings$key
    >(
      graphql`
        fragment jobsList_affiliateJobPostings on RootQueryType
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 20 }
          after: { type: "String" }
          input: { type: "AffiliateJobPostingsInput" }
        )
        @refetchable(queryName: "jobsListAffiliateJobPostingsPaginationQuery") {
          affiliateJobPostings(first: $count, after: $after, input: $input)
            @connection(key: "jobsList_affiliateJobPostings") {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              __typename
              affiliateJobPost: node {
                id
                name
                url
                ...affiliateJobCardFragment_job
              }
            }
          }
        }
      `,
      result ?? null
    );
    useEffect(() => {
      void JobBoardConversion.track({
        location: filters.location.label,
        searchTerm: filters.searchTerms,
        jobCount: affiliateJobPostingsData?.affiliateJobPostings?.totalCount,
        gigSmartJobCount: jobPostingsData?.availableJobPostings?.totalCount
      });
    }, [
      affiliateJobPostingsData?.affiliateJobPostings?.totalCount,
      jobPostingsData?.availableJobPostings?.totalCount,
      filters
    ]);
    const jobs = useMemo(() => {
      return [
        ...(jobPostingsData?.availableJobPostings?.edges ?? []),
        ...(hasMoreJobPostings
          ? []
          : affiliateJobPostingsData?.affiliateJobPostings?.edges ?? [])
      ];
    }, [jobPostingsData, affiliateJobPostingsData, hasMoreJobPostings]);

    const renderJob = ({ item }: { item: JobEdge | AffiliateJobEdge }) => {
      if (item?.__typename === "AffiliateJobPostingsEdge") {
        const affiliateJobEdge = item as AffiliateJobEdge;
        const jobPost = affiliateJobEdge?.affiliateJobPost;
        if (!jobPost) return null;
        return (
          <AffiliateJobCardFragment
            onPress={() =>
              onPressAffiliate?.({
                name: jobPost?.name ?? "",
                url: jobPost?.url ?? ""
              })
            }
            jobRef={jobPost}
            key={jobPost?.id}
          />
        );
      }
      if (item?.__typename === "AvailableJobPostingsEdge") {
        const jobEdge = item as JobEdge;
        const jobPost = jobEdge?.jobPost;
        if (!jobPost) return null;
        return (
          <JobCardFragment
            jobRef={(jobPost as JobNode) ?? null}
            onPress={() => history.push(`/browse/jobs/${jobPost.id}`)}
            key={jobPost?.id}
          />
        );
      }
      return null;
    };

    const handleLoadMore = () => {
      if (hasMoreJobPostings) return loadMoreJobPostings(20);
      if (hasMoreAffiliateJobPostings) return loadMoreAffiliateJobPostings(20);
    };

    return (
      <FlatList
        data={jobs}
        ListEmptyComponent={<JobsListEmptyMessage />}
        ListFooterComponent={
          <JobsListFooter
            hasMore={hasMoreJobPostings || hasMoreAffiliateJobPostings}
            showFooter={jobs.length > 0}
          />
        }
        ListHeaderComponent={<JobsListHeader />}
        onScroll={onScroll}
        scrollEventThrottle={120}
        renderItem={renderJob}
        onEndReached={handleLoadMore}
      />
    );
  },
  {
    query: graphql`
      query jobsListQuery(
        $search: JobPostingSearch!
        $input: AffiliateJobPostingsInput!
      ) {
        viewer {
          ... on Worker {
            ...jobsList_jobPostings @arguments(search: $search)
          }
        }
        ...jobsList_affiliateJobPostings @arguments(input: $input)
      }
    `,
    variables: ({ filters }) => {
      const maxDistance = filters.location ? filters.radiusMiles : undefined;
      return {
        search: {
          maxDistance,
          latitude: filters.location.lat,
          longitude: filters.location.lng,
          search: filters.searchTerms,
          orderBy: [{ field: "boosted", direction: "DESC" }]
        },
        input: {
          maxDistance,
          latitude: filters.location.lat,
          longitude: filters.location.lng,
          search: filters.searchTerms
        }
      };
    }
  }
);
