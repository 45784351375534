import { Platform, Stack, Text, useStepper } from "@gigsmart/atorasu";
import { Form, FormSubmit, Validator } from "@gigsmart/fomu";
import stateList from "@gigsmart/isomorphic-shared/app/us-state-list";
import {
  FomuStyledDateInput,
  FomuStyledOptionalTextInput,
  FomuStyledTextInput
} from "@gigsmart/isomorphic-shared/fomu-inputs";
import FomuPickerV2 from "@gigsmart/seibutsu/fomu/inputs/FomuPickerV2";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import CardCheckbox from "@gigsmart/seibutsu/worker-verification/CardCheckbox";
import DriverFields from "@gigsmart/seibutsu/worker-verification/DriverFields";
import SecureNotice from "@gigsmart/seibutsu/worker-verification/SecureNotice";
import { compact } from "lodash";
import moment from "moment-timezone";
import React from "react";
import StepperNavActions from "../../shared/Stepper/StepperNavActions";
import type { SetWorkerLegalInformationInput } from "./hooks/__generated__/useEnrollmentMutation.graphql";

const formatSSN = (text: string) =>
  text.replace(/([\d|•]{3})([\d|•]{1,2})?([\d|•]{1,4})?/, (_m, g1, g2, g3) =>
    compact([g1, g2, g3]).join("-")
  );

const normalizeSSN = (text: string) => text.replace(/[^\d]/g, "");

interface Props {
  onSubmit: (
    props: SetWorkerLegalInformationInput,
    next: (data: SetWorkerLegalInformationInput) => void
  ) => void;
  verificationStatus?: string;
  gigId: string;
}

export default function EnrollmentStep({
  onSubmit,
  verificationStatus,
  gigId
}: Props) {
  const { stepsData, nextStep } = useStepper<Step, any>();
  const { driverCheckOptIn } = stepsData;

  return (
    <Form
      initialValues={stepsData}
      onSubmit={({ values, valid }) => {
        valid && onSubmit(values, nextStep);
      }}
    >
      <StepperNavActions />
      <Stack>
        <Text>
          Your information is secure and used only for verification purposes.
        </Text>
        <FomuStyledTextInput
          name="firstName"
          eventTargetName="Input First Name"
          validates={[Validator.presence()]}
          label="First Name"
          placeholder="First Name"
          tip
          disabled={verificationStatus === "ENROLLED"}
          testID="first-name-input"
        />
        <FomuStyledOptionalTextInput
          name="middleName"
          label="Middle Name"
          eventTargetName="Input Middle Name"
          placeholder="Middle Name"
          tip
          disabled={verificationStatus === "ENROLLED"}
          testID="middle-name-input"
        />
        <FomuStyledTextInput
          name="lastName"
          validates={[Validator.presence()]}
          label="Last Name"
          placeholder="Last Name"
          eventTargetName="Input Last Name"
          tip
          disabled={verificationStatus === "ENROLLED"}
          testID="last-name-input"
        />
        <FomuStyledDateInput
          name="birthdate"
          eventTargetName="Input Birth Date"
          validates={Validator.date({
            format: "YYYY-MM-DD",
            message: "Invalid date of birth",
            options: {
              max: moment().subtract(14, "years"),
              min: moment().subtract(105, "years")
            }
          })}
          label="Date of Birth"
          disabled={verificationStatus === "ENROLLED"}
          tip
          testID="dob-picker"
        />
        <FomuStyledTextInput
          name="emailAddress"
          eventTargetName="Input Email"
          validates={[Validator.email(), Validator.presence()]}
          label="Email"
          placeholder="Email"
          disabled={verificationStatus === "ENROLLED"}
          keyboardType="email-address"
          textContentType="emailAddress"
          autoCapitalize="none"
          autoCorrect={false}
          tip
          testID="email-input"
        />
        <FomuStyledTextInput
          name="street1"
          eventTargetName="Input Street 1"
          validates={[Validator.presence()]}
          textContentType="streetAddressLine1"
          label="Street 1"
          placeholder="Street Address"
          tip
          testID="street1-input"
        />
        <FomuStyledTextInput
          name="street2"
          eventTargetName="Input Street 2"
          textContentType="streetAddressLine2"
          label="Street 2"
          placeholder="Street Address"
          testID="street2-input"
        />
        <FomuStyledTextInput
          name="locality"
          eventTargetName="Input City"
          validates={[Validator.presence()]}
          label="City"
          placeholder="City"
          textContentType="addressCity"
          tip
          testID="locality-input"
        />
        <FomuPickerV2
          eventTargetName="State Picker"
          name="administrativeArea1"
          validates={[Validator.presence()]}
          label="State"
          placeholder="State"
          textContentType="addressState"
          options={stateList}
        />
        <FomuStyledTextInput
          eventTargetName="Input Zip Code"
          name="postalCode"
          validates={[
            Validator.zipcode({ message: "invalid zip code" }),
            Validator.presence()
          ]}
          label="Zip Code"
          placeholder="Zip Code"
          textContentType="postalCode"
          keyboardType={
            Platform.OS === "ios" || Platform.OS === "android"
              ? "numeric"
              : "default"
          }
          tip
          maxLength={5}
          normalize={(zipcode: string) =>
            (zipcode.match(/\d{1,5}/g) ?? []).toString()
          }
          testID="postal-code-input"
        />
        <FomuStyledTextInput
          eventTargetName="Input Social Security Number"
          name="nationalIdNumber"
          validates={[
            Validator.presence(),
            Validator.length({
              exact: 9,
              message: "must have exactly 9 numbers"
            })
          ]}
          label="Social Security Number"
          tip
          placeholder="###-##-####"
          disabled={verificationStatus === "ENROLLED"}
          textContentType="none"
          keyboardType="number-pad"
          autoCapitalize="none"
          autoCorrect={false}
          maxLength={11}
          format={formatSSN}
          normalize={normalizeSSN}
          testID="national-id-input"
        />
        <FomuStyledTextInput
          eventTargetName="Input Confirm Social Security Number"
          name="nationalIdNumberConfirmation"
          validates={[
            Validator.confirmationOf({
              field: "nationalIdNumber",
              message: "must match social security number"
            })
          ]}
          tip
          label="Confirm Social Security Number"
          placeholder="###-##-####"
          disabled={verificationStatus === "ENROLLED"}
          textContentType="none"
          keyboardType="number-pad"
          autoCapitalize="none"
          autoCorrect={false}
          maxLength={11}
          format={formatSSN}
          normalize={normalizeSSN}
          testID="national-id-confirm-input"
        />
        <SecureNotice />
        {driverCheckOptIn && (
          <>
            <DriverFields />
            <SecureNotice />
          </>
        )}
        <CardCheckbox
          name="pspTosAccepted"
          validates={[Validator.isTrue()]}
          testID="stripe-agree-checkbox"
        />
      </Stack>
      <FormSubmit>
        {({ submit, invalid }) => (
          <ResponsiveStepperBtnPortal.Entrance
            testID="enrollment-step-gig-stepper-next-button"
            label="Continue"
            disabled={invalid}
            onPress={submit}
          />
        )}
      </FormSubmit>
    </Form>
  );
}
