import { Column } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
/* eslint-disable react-native/no-inline-styles */
import React from "react";
import Svg, { Path, LinearGradient, Stop, G } from "react-native-svg";

export default function FoundBanner() {
  const styles = useStyles(() => ({
    container: {
      width: "100%",
      height: 80,
      borderRadius: 4,
      overflow: "hidden"
    }
  }));
  return (
    <Column style={styles.container}>
      <Svg viewBox="0 0 311 64">
        <LinearGradient
          id="SVGID_1_"
          x1={2622.725}
          x2={2691.996}
          y1={898.912}
          y2={898.912}
          gradientTransform="rotate(90 1839.828 -785.812)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#1a5892" />
          <Stop offset={0.167} stopColor="#215a93" />
          <Stop offset={0.345} stopColor="#326093" />
          <Stop offset={0.528} stopColor="#486a93" />
          <Stop offset={0.714} stopColor="#637992" />
          <Stop offset={0.9} stopColor="#828f91" />
          <Stop offset={1} stopColor="#969f90" />
        </LinearGradient>
        <Path d="M-3.09 66.35V-2.92H313.3v69.27z" fill="url(#SVGID_1_)" />
        <G
          opacity={0.07}
          clipPath="url(#SVGID_00000176001530203240630780000008417457236243426494_)"
        >
          <Path
            d="M257.78-.08c8.63-8.63 20.11-13.38 32.31-13.38v-6.04c-28.57 0-51.73 23.16-51.73 51.73h6.04c-.01-12.2 4.74-23.67 13.38-32.31zM273.98 32.24h-6.04c0 12.23 9.91 22.14 22.14 22.14v-6.04c-8.87 0-16.1-7.22-16.1-16.1zM290.09 19.96c-3.44 0-6.24-2.8-6.24-6.24s2.8-6.24 6.24-6.24V1.45c-6.78 0-12.27 5.5-12.27 12.27S283.31 26 290.09 26c3.44 0 6.24 2.8 6.24 6.24s-2.8 6.24-6.24 6.24v6.04c6.78 0 12.27-5.5 12.27-12.27s-5.49-12.29-12.27-12.29z"
            fill="#ffffff"
          />
          <Path
            d="M290.09 58.2c-14.32 0-25.97-11.65-25.97-25.97 0-4.73 1.27-9.16 3.48-12.98l-5.24-3.02a31.885 31.885 0 0 0-4.28 16c0 17.67 14.33 32 32 32 15.32 0 28.13-10.77 31.27-25.15h-6.22c-3.01 11.01-13.09 19.12-25.04 19.12z"
            fill="#ffffff"
          />
          <Path
            d="M306.19 29.22v6.04h19.6c-.7 8.43-4.32 16.27-10.37 22.32-6.77 6.77-15.77 10.49-25.34 10.49s-18.57-3.73-25.34-10.49c-6.77-6.77-10.49-15.77-10.49-25.34s3.73-18.57 10.49-25.34c6.77-6.77 15.77-10.49 25.34-10.49 8.52 0 16.59 2.96 23.03 8.38L317 .17c-7.28-6.11-16.66-9.8-26.91-9.8-23.12 0-41.87 18.74-41.87 41.87s18.74 41.87 41.87 41.87c22.11 0 40.21-17.14 41.76-38.85.07-1 .11-6.04.11-6.04h-25.77z"
            fill="#ffffff"
          />
        </G>
        <Path
          d="M155.34 15.32h.33v35.36h-.33zM59.82 33.65c0-3.5 2.68-5.7 5.96-5.7 2.46 0 3.95 1.3 4.71 2.68l-2.38 1.24c-.41-.73-1.26-1.4-2.33-1.4-1.8 0-3.07 1.37-3.07 3.19 0 1.8 1.27 3.19 3.07 3.19.81 0 1.63-.33 2.03-.66v-.8h-2.48v-2.22h5.32v4.05c-1.17 1.3-2.79 2.15-4.87 2.15-3.28-.02-5.96-2.22-5.96-5.72zM72.16 39.15V28.14H75v11.01h-2.84zM76.26 33.65c0-3.5 2.68-5.7 5.96-5.7 2.46 0 3.95 1.3 4.71 2.68l-2.38 1.24c-.41-.73-1.26-1.4-2.33-1.4-1.8 0-3.07 1.37-3.07 3.19 0 1.8 1.27 3.19 3.07 3.19.81 0 1.63-.33 2.03-.66v-.8h-2.48v-2.22h5.32v4.05c-1.17 1.3-2.79 2.15-4.87 2.15-3.29-.02-5.96-2.22-5.96-5.72zM88.23 37.6l.83-1.06c.71.83 1.9 1.59 3.45 1.59 1.97 0 2.59-1.06 2.59-1.92 0-2.84-6.52-1.25-6.52-5.19 0-1.82 1.62-3.07 3.8-3.07 1.67 0 2.97.58 3.9 1.55l-.83 1.01c-.83-.92-1.98-1.32-3.19-1.32-1.3 0-2.26.71-2.26 1.75 0 2.48 6.52 1.06 6.52 5.17 0 1.59-1.09 3.24-4.06 3.24-1.9 0-3.32-.73-4.23-1.75zM107.57 39.15v-9.26l-3.73 9.26h-.56l-3.75-9.26v9.26h-1.37V28.14h1.97l3.43 8.49 3.42-8.49h1.97v11.01h-1.38zM118.96 39.15l-.97-2.46h-5.48l-.97 2.46h-1.57l4.44-11.01h1.7l4.43 11.01h-1.58zm-3.72-9.61-2.33 5.95h4.67l-2.34-5.95zM127.93 39.15l-2.79-4.38h-2.21v4.38h-1.37V28.14h4.43c2.01 0 3.47 1.29 3.47 3.32 0 1.98-1.37 3.07-2.87 3.2l2.97 4.49h-1.63zm.1-7.69c0-1.26-.91-2.1-2.21-2.1h-2.89v4.21h2.89c1.3 0 2.21-.88 2.21-2.11zM133.76 39.15v-9.79h-3.48v-1.22h8.36v1.22h-3.5v9.79h-1.38zM45.01 28.43a7.322 7.322 0 0 1 5.21-2.16v-.97c-4.61 0-8.35 3.74-8.35 8.35h.97a7.38 7.38 0 0 1 2.17-5.22zM50.23 41.02v.97c2.04 0 3.91-.73 5.37-1.95l-.63-.75a7.32 7.32 0 0 1-4.74 1.73zM47.63 33.64h-.97c0 1.97 1.6 3.57 3.57 3.57v-.97c-1.43 0-2.6-1.16-2.6-2.6zM50.23 31.66c-.56 0-1.01-.45-1.01-1.01s.45-1.01 1.01-1.01v-.97c-1.09 0-1.98.89-1.98 1.98 0 1.09.89 1.98 1.98 1.98.56 0 1.01.45 1.01 1.01s-.45 1.01-1.01 1.01v.97c1.09 0 1.98-.89 1.98-1.98s-.89-1.98-1.98-1.98z"
          fill="#ffffff"
        />
        <Path
          d="M50.23 37.83c-2.31 0-4.19-1.88-4.19-4.19 0-.76.2-1.48.56-2.09l-.84-.49a5.16 5.16 0 1 0 9.51 3.68h-1a4.197 4.197 0 0 1-4.04 3.09z"
          fill="#ffffff"
        />
        <Path
          d="M52.83 33.16v.97h3.16a5.76 5.76 0 0 1-1.67 3.6 5.74 5.74 0 0 1-4.09 1.69c-1.54 0-3-.6-4.09-1.69-1.09-1.09-1.69-2.54-1.69-4.09s.6-3 1.69-4.09a5.74 5.74 0 0 1 4.09-1.69c1.38 0 2.68.48 3.72 1.35l.63-.75a6.7 6.7 0 0 0-4.34-1.58c-3.73 0-6.76 3.02-6.76 6.76s3.02 6.76 6.76 6.76c3.57 0 6.49-2.76 6.74-6.27.01-.16.02-.97.02-.97h-4.17zM140.72 29.81a1.071 1.071 0 1 1 1.07-1.07c-.01.59-.48 1.07-1.07 1.07zm0-1.97c-.5 0-.9.4-.9.9s.4.9.9.9.9-.41.9-.9c0-.5-.4-.9-.9-.9zm.29 1.52-.32-.48h-.21v.48h-.17v-1.22h.5c.21 0 .4.15.4.37 0 .27-.24.36-.31.36l.33.49h-.22zm-.2-1.07h-.33v.43h.33c.11 0 .22-.09.22-.21 0-.14-.12-.22-.22-.22z"
          fill="#ffffff"
        />
        <G clipPath="url(#SVGID_00000124869522336517124220000000068338829529886851_)">
          <Path
            d="M201.8 39.42c-2.66 0-4.67-1.81-4.67-4.21v-6.87c0-.1.08-.18.18-.18h1.97c.1 0 .18.08.18.18v6.62c0 1.35.98 2.32 2.33 2.32s2.33-.99 2.33-2.34v-6.6c0-.1.08-.18.18-.18h1.97c.1 0 .18.08.18.18v6.87c.02 2.41-1.98 4.21-4.65 4.21zm-13.5 0c-3.13 0-5.31-2.37-5.31-5.75 0-3.39 2.19-5.76 5.31-5.76 3.13 0 5.31 2.37 5.31 5.75s-2.19 5.76-5.31 5.76zm0-9.36c-1.39 0-2.87.95-2.87 3.61s1.48 3.61 2.87 3.61 2.87-.95 2.87-3.61-1.48-3.61-2.87-3.61zm36.66 9.1a.18.18 0 0 1-.18-.18V28.35c0-.1.08-.18.18-.18h3.23c3.77 0 6.2 2.16 6.2 5.49 0 3.34-2.43 5.49-6.2 5.49h-3.23zm2.3-8.91c-.08 0-.14.06-.14.14v6.57c0 .07.06.14.14.14h.93c2.36 0 3.77-1.28 3.77-3.42 0-2.14-1.41-3.43-3.77-3.43h-.93zm-9.37 8.91c-.07 0-.13-.03-.16-.09l-4.59-7.89c-.01-.01-.01-.02-.03-.02-.01 0-.04 0-.04.03v7.79c0 .1-.08.18-.18.18h-1.84a.18.18 0 0 1-.18-.18V28.35c0-.1.08-.18.18-.18h2.67c.07 0 .13.04.16.09l4.18 7.48c.01.01.01.02.03.02.01 0 .04 0 .04-.03v-7.38c0-.1.08-.18.18-.18h1.84c.1 0 .18.08.18.18v10.63c0 .1-.08.18-.18.18h-2.26zm-46.47 0a.18.18 0 0 1-.18-.18V28.35c0-.1.08-.18.18-.18h8.73c.1 0 .18.08.18.18v1.75c0 .1-.08.18-.18.18h-6.45c-.04 0-.07.01-.1.04s-.04.06-.04.1v2.31a11.675 11.675 0 0 0 2.58.31c.33 0 .66-.02.99-.05.55-.05 1.11-.14 1.67-.28.01 0 .03-.01.05-.01.08 0 .16.05.18.13l.47 1.72c.01.05.01.09-.02.13-.02.04-.07.07-.11.08-.68.17-1.36.28-2.03.34-.39.03-.79.05-1.19.05-.35 0-.71-.01-1.07-.04-.46-.04-.96-.11-1.5-.22v4.08c0 .1-.08.18-.18.18l-1.98.01z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#fff"
          />
        </G>
      </Svg>
    </Column>
  );
}
