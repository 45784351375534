/**
 * @generated SignedSource<<d5656c1555f281979f0c1524bd5c0599>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type infoTabs_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly organization: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift" | "CancellationPoliciesRow_shift" | "gigDescription_gigLike" | "gigRequesterRow_gigLike">;
  };
  readonly gigType: GigType;
  readonly id: string;
  readonly reviewOfRequester: {
    readonly rating: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WithdrawButton_engagement" | "WorkerProjectSummaryTab_engagement" | "gigCancellationFeeSummaryTab_engagement" | "gigSummaryRows_engagement" | "pendingApprovalTab_engagement" | "summaryTab_engagement">;
  readonly " $fragmentType": "infoTabs_engagement";
};
export type infoTabs_engagement$key = {
  readonly " $data"?: infoTabs_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"infoTabs_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "infoTabs_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "summaryTab_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "pendingApprovalTab_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "gigSummaryRows_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerProjectSummaryTab_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "gigCancellationFeeSummaryTab_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WithdrawButton_engagement"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "BlockOrganizationCollapsibleRow_shift",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "type": "GigLike",
              "abstractKey": "__isGigLike"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gigDescription_gigLike"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gigRequesterRow_gigLike"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancellationPoliciesRow_shift"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "reviewOfRequester",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rating",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "5ae3787ab8117bed1b251cf697b75e2f";

export default node;
