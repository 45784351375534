/**
 * @generated SignedSource<<695daf005fd8e5bfbfef24afcb2cc5f7>>
 * @relayHash 494615a854ead3c8ea6d9cc333c2dd48
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:6qROXDfC663LYHuseNopCWOUFyn6yth0edLuD4C3YaU

import { ConcreteRequest } from 'relay-runtime';
export type OperatorModalQuery$variables = Record<PropertyKey, never>;
export type OperatorModalQuery$data = {
  readonly viewer: {
    readonly primaryMobile: {
      readonly number: string;
    } | null | undefined;
  } | null | undefined;
};
export type OperatorModalQuery = {
  response: OperatorModalQuery$data;
  variables: OperatorModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMobile",
            "kind": "LinkedField",
            "name": "primaryMobile",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OperatorModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMobile",
            "kind": "LinkedField",
            "name": "primaryMobile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:6qROXDfC663LYHuseNopCWOUFyn6yth0edLuD4C3YaU",
    "metadata": {},
    "name": "OperatorModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f0740976d90ac9a03e2ea7720a12148a";

export default node;
