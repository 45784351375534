/**
 * @generated SignedSource<<2e5bd7e6d24a6d2fd797882b5e101f9b>>
 * @relayHash 41107a95460bfe161a29d9b92d419035
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Gi1LaZhmq41ZYQM2hwrw0H4XsiL3WdzPpgO-19TaTl8

import { ConcreteRequest } from 'relay-runtime';
export type SetUserProfilePhotoInput = {
  photoId?: string | null | undefined;
  public?: boolean | null | undefined;
  userId?: string | null | undefined;
};
export type ProfileHeaderSetUserProfilePhotoMutation$variables = {
  input: SetUserProfilePhotoInput;
};
export type ProfileHeaderSetUserProfilePhotoMutation$data = {
  readonly setUserProfilePhoto: {
    readonly user: {
      readonly id: string;
      readonly profilePhoto?: {
        readonly url: string | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type ProfileHeaderSetUserProfilePhotoMutation = {
  response: ProfileHeaderSetUserProfilePhotoMutation$data;
  variables: ProfileHeaderSetUserProfilePhotoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileHeaderSetUserProfilePhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserProfilePhotoPayload",
        "kind": "LinkedField",
        "name": "setUserProfilePhoto",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "profilePhoto",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileHeaderSetUserProfilePhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserProfilePhotoPayload",
        "kind": "LinkedField",
        "name": "setUserProfilePhoto",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "profilePhoto",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Gi1LaZhmq41ZYQM2hwrw0H4XsiL3WdzPpgO-19TaTl8",
    "metadata": {},
    "name": "ProfileHeaderSetUserProfilePhotoMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "db2840a7397a1d7a5688c0ad8a36b819";

export default node;
