import {
  AbsoluteContainer,
  Avatar,
  Column,
  ContentArea,
  IconCircle,
  IconText,
  Pressable,
  Row,
  Spacer,
  Stack,
  Surface,
  Text,
  Well,
  showImageCarousel,
  showMediaPicker
} from "@gigsmart/atorasu";
import { useMatchesViewport, useStyles } from "@gigsmart/atorasu/style";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { graphql, useRelayFragment, useRelayMutation } from "@gigsmart/relay";
import { DateTime } from "luxon";
import React from "react";
import useAddUserFile from "../utils/useAddUserFile";
import type { ProfileHeaderRefreshed_worker$key } from "./__generated__/ProfileHeaderRefreshed_worker.graphql";
import type { ProfileHeaderSetUserProfilePhotoMutation } from "./__generated__/ProfileHeaderSetUserProfilePhotoMutation.graphql";
import SpiralsImage from "./images/Spirals";

interface Props {
  workerRef: ProfileHeaderRefreshed_worker$key | null | undefined;
  variant?: "detailed" | "avatar-only";
  viewer?: "Requester" | "Worker";
  toolbar?: JSX.Element | null;
  isPublicView?: boolean;
}
export default function ProfileHeader({
  workerRef,
  variant = "detailed",
  viewer = "Worker",
  toolbar,
  isPublicView
}: Props) {
  const isSm = useMatchesViewport(({ size }) => size.small.down);
  const isAuthenticated = !!useCurrentUser()?.id;
  const styles = useStyles(({ getUnits }) => ({
    avatarContainer: {
      backgroundColor: "white",
      padding: getUnits(1.5),
      borderRadius: getUnits(25)
    }
  }));

  const { uploadFile } = useAddUserFile();
  const worker = useRelayFragment<ProfileHeaderRefreshed_worker$key>(
    graphql`
      fragment ProfileHeaderRefreshed_worker on Worker
      @argumentDefinitions(
        skipIfNotWorker: { type: "Boolean", defaultValue: false }
      ) {
        ...useUserPronoun_Worker
        id
        displayName
        firstName
        lastName @skip(if: $skipIfNotWorker)
        locality
        administrativeArea1
        profilePhoto {
          url
          public @skip(if: $skipIfNotWorker)
        }
        favoritedByOrganizationsCount
        insertedAt
        pronoun {
          name
        }
      }
    `,
    workerRef ?? null
  );

  const [setProfilePhoto] =
    useRelayMutation<ProfileHeaderSetUserProfilePhotoMutation>(
      graphql`
        mutation ProfileHeaderSetUserProfilePhotoMutation(
          $input: SetUserProfilePhotoInput!
        ) {
          setUserProfilePhoto(input: $input) {
            user {
              id
              ... on Worker {
                profilePhoto {
                  url
                }
              }
            }
          }
        }
      `
    );

  const workerState = worker?.administrativeArea1;
  const profilePictureIsPublic = isPublicView && !!worker?.profilePhoto?.public;
  const canViewProfilePicture = isPublicView
    ? profilePictureIsPublic
    : !!worker?.profilePhoto?.url;
  const canOpenProfilePicture = isAuthenticated && viewer === "Requester";

  const formatDate = (v: string) =>
    DateTime.fromISO(v ?? new Date().toISOString()).toFormat("MMMM yyyy");

  const displayName = !worker?.lastName
    ? worker?.displayName
    : `${worker.firstName} ${
        viewer === "Worker" ? worker.lastName : `${worker.lastName.charAt(0)}.`
      }`;

  const openProfileImage = () =>
    showImageCarousel([worker?.profilePhoto?.url ?? ""]);

  const handleSelectPhoto = async (uris: string[]) => {
    const file = await uploadFile(uris);
    if (file) {
      setProfilePhoto({
        input: {
          photoId: file.id,
          userId: worker?.id,
          public: worker?.profilePhoto?.public
        }
      });
    }
  };

  return (
    <Surface>
      <AbsoluteContainer top={0} zIndex={0}>
        <Well color="primary" contentVariant="none" size="none">
          <SpiralsImage />
          <Spacer size="medium" />
        </Well>
      </AbsoluteContainer>
      <ContentArea zIndex={9}>
        <Stack
          horizontal
          justifyContent={variant === "avatar-only" ? "center" : undefined}
        >
          <Column style={styles.avatarContainer}>
            <Pressable
              onPress={openProfileImage}
              eventEntityType="profilePicturePressable"
              eventTargetName="Profile Picture Pressable"
              testID="profile-picture-pressable"
              disabled={!canOpenProfilePicture}
            >
              <Avatar
                {...(canViewProfilePicture
                  ? { uri: worker?.profilePhoto?.url }
                  : {
                      icon: "user",
                      color: "divider",
                      iconColor: "white"
                    })}
                size="xLarge"
              />
            </Pressable>
            <AbsoluteContainer bottom={0} right={10}>
              <Row justifyContent="flex-end">
                {variant === "avatar-only" ? (
                  <Pressable
                    eventEntityType="EditProfileChangePicture"
                    eventTargetName="Edit Profile Picture Button"
                    testID="edit-profile-change-picture"
                    onPress={() =>
                      showMediaPicker({
                        crop: "circle",
                        front: true,
                        onSelect: handleSelectPhoto
                      })
                    }
                  >
                    <IconCircle icon="camera" size="small" color="primary" />
                  </Pressable>
                ) : (
                  viewer === "Worker" &&
                  !worker?.profilePhoto?.public &&
                  !!worker?.profilePhoto?.url && (
                    <IconCircle
                      icon="eye-slash"
                      size="small"
                      color="foreground"
                      iconColor="primary"
                    />
                  )
                )}
              </Row>
            </AbsoluteContainer>
          </Column>
          {variant === "detailed" && (
            <Column fill>
              <Spacer />
              <Spacer />
              <Stack horizontal alignItems="center" size="slim">
                <Text
                  variant="header"
                  weight="bold"
                  color="primary"
                  numberOfLines={1}
                  fill={
                    (worker?.displayName ?? "").length > 20 && isSm
                      ? true
                      : undefined
                  }
                >
                  {displayName}
                </Text>
                {!!worker?.pronoun?.name && (
                  <Text variant="note" color="primary">
                    ({worker?.pronoun?.name})
                  </Text>
                )}
              </Stack>
              <Spacer size="medium" />
              <Stack size="slim">
                <IconText icon="location-dot" color="primary" spacing="compact">
                  <Text color="black" variant="subheader" weight="normal">
                    {worker?.locality}
                    {workerState ? `, ${workerState}` : ""}
                  </Text>
                </IconText>
                <IconText
                  icon="calendar-week"
                  color="primary"
                  spacing="compact"
                >
                  <Text color="black" variant="subheader" weight="normal">
                    Member since {formatDate(worker?.insertedAt ?? "")}
                  </Text>
                </IconText>
              </Stack>
            </Column>
          )}
        </Stack>
      </ContentArea>
      {!!toolbar && (
        <AbsoluteContainer top={0} zIndex={10}>
          <ContentArea size="compact" variant="compact">
            {toolbar}
          </ContentArea>
        </AbsoluteContainer>
      )}
    </Surface>
  );
}
