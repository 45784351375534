/**
 * @generated SignedSource<<3cd9774e1ea04d0d309d8dcaa9bc0ed2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type notificationRoutingHandler_node$data = {
  readonly __typename: string;
  readonly canceled?: boolean | null | undefined;
  readonly currentState?: {
    readonly canApply: boolean;
    readonly name: GigStateName;
  };
  readonly endDate?: string | null | undefined;
  readonly endsAt?: string | null | undefined;
  readonly engagements?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currentState: {
          readonly name: EngagementStateName;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly gigSeries?: {
    readonly id: string;
  };
  readonly gigType?: GigType | null | undefined;
  readonly id: string;
  readonly isClosed?: boolean;
  readonly nextGig?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly visible?: boolean | null | undefined;
  readonly " $fragmentType": "notificationRoutingHandler_node";
};
export type notificationRoutingHandler_node$key = {
  readonly " $data"?: notificationRoutingHandler_node$data;
  readonly " $fragmentSpreads": FragmentRefs<"notificationRoutingHandler_node">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "notificationRoutingHandler_node"
};

(node as any).hash = "f2c3336ea9c7d24072124e4daaa4b022";

export default node;
