import { usePermission } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import Geolocation, {
  currentLocation,
  type GeoError,
  type GeoPosition
} from "@gigsmart/isomorphic-shared/geolocation";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import { useCallback, useEffect } from "react";
import { AppState } from "react-native";
import type { useTrackLocationSilentMutation } from "./__generated__/useTrackLocationSilentMutation.graphql";
import { logger as baseLogger } from "./logger";

const logger = baseLogger.createLogger("Foreground");

export default function getHook() {
  return function useTrackLocation(
    shouldRequest: boolean,
    hasActiveGig: boolean
  ) {
    const user = useCurrentUser();

    const locationPermission = usePermission("location", {
      init: shouldRequest ? "request" : "none",
      trace: "useTrackLocation.foreground"
    });

    const [updateLocation] = useRelayMutation<useTrackLocationSilentMutation>(
      graphql`
        mutation useTrackLocationSilentMutation(
          $input: SetWorkerLocationInput!
        ) {
          setWorkerLocation(input: $input) {
            workerLocation {
              id
            }
          }
        }
      `
    );

    const handleLocation = useCallback(
      ({
        coords: { latitude, longitude, accuracy },
        timestamp
      }: GeoPosition) => {
        const loc = { latitude, longitude, accuracy };
        logger.info("current:", loc, timestamp);
        updateLocation({
          input: {
            ...loc,
            reportedAt: new Date(timestamp).toISOString()
          }
        });
      },
      [updateLocation]
    );

    const handleError = useCallback((error: Error | GeoError) => {
      logger.error("error:", error);
    }, []);

    const reportCurrentLocation = useCallback(async () => {
      logger.log("reporting current location");
      currentLocation({
        enableHighAccuracy: hasActiveGig,
        timeout: 5000,
        maximumAge: 0,
        ipFallback: true
      })
        .then((data) => handleLocation(data))
        .catch(handleError);
    }, [hasActiveGig, handleError, handleLocation]);

    // Enable watching
    useEffect(() => {
      if (!user) return;

      void reportCurrentLocation();

      const watchID = locationPermission.has
        ? Geolocation.watchPosition(
            (data) => handleLocation(data),
            handleError,
            {
              enableHighAccuracy: hasActiveGig,
              useSignificantChanges: !hasActiveGig,
              distanceFilter: hasActiveGig ? 160 : 1600
            }
          )
        : undefined;

      const appStateSubscription = AppState.addEventListener(
        "change",
        async () => {
          void reportCurrentLocation();
        }
      );

      return () => {
        if (watchID) Geolocation.clearWatch(watchID);
        appStateSubscription.remove();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasActiveGig, user?.id, user?.uuid, locationPermission.has]);
  };
}
