import {
  Applet,
  EngagementDataProvider,
  useNavigation
} from "@gigsmart/applets";
import { Column, Divider } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useCallback } from "react";
import useActiveGigPermissions from "../gig/useActiveGigPermissions";
import type { WorkerShiftApplets_shift$key } from "./__generated__/WorkerShiftApplets_shift.graphql";

interface Props {
  showNote?: boolean;
}

export const WorkerShiftApplets = ({
  currentState,
  showNote,
  applets,
  result
}: FragmentContainerInnerComponentProps<
  WorkerShiftApplets_shift$key,
  Props
>) => {
  const isPrehire = ["OFFERED", "APPLIED", "CONFIRMING"].includes(
    currentState?.name
  );
  const hasApplets = !!applets?.length;
  const navigator = useNavigation();

  const handleDismiss = useCallback(() => navigator.goBack(), [navigator]);

  useActiveGigPermissions({
    trace: "WorkerShiftApplets",
    requiresNotifications: !isPrehire,
    requiresBackgroundLocation: !isPrehire,
    requiresPreciseLocation: !isPrehire,
    request: !isPrehire && hasApplets,
    onDismiss: !isPrehire && hasApplets ? handleDismiss : undefined
  });

  if (!hasApplets || !result) return null;
  return (
    <Column>
      <Divider color="divider" />
      {applets.map((applet) => (
        <Applet
          key={applet.url}
          url={applet.url}
          props={applet.params}
          showNote={showNote}
          wrapper={{
            Component: EngagementDataProvider,
            props: { engagement: result, hasBillingInfo: false }
          }}
        />
      ))}
    </Column>
  );
};

export default createRelayFragmentContainer<
  WorkerShiftApplets_shift$key,
  Props
>(
  graphql`
    fragment WorkerShiftApplets_shift on Engagement {
      ...useEngagementData @arguments(hasBillingInfo: false)
      currentState {
        name
      }
      gig {
        requiredPermissions
      }
      applets {
        params
        url
      }
    }
  `,
  WorkerShiftApplets
);
