import React from "react";

import { theme, useStyles } from "../../style";
import SmallButton, { type SmallButtonProps } from "./small-button";

type Props = SmallButtonProps & { tintColor?: string };

export default ({ tintColor = "blue", ...props }: Props) => {
  const primaryColor = theme.color.getColor(tintColor);
  const { styles } = useStyles(
    ({ color }) => {
      return {
        container: {
          borderWidth: 1,
          borderColor: primaryColor
        },
        hoverContainer: {
          backgroundColor: color.withOpacity(primaryColor, 0.1)
        },
        disabledContainer: {
          borderColor: color.neutralDark
        }
      };
    },
    [primaryColor]
  );

  return (
    <SmallButton
      textColor={primaryColor}
      containerStyle={styles.container}
      hoverContainerStyle={styles.hoverContainer}
      disabledContainerStyle={styles.disabledContainer}
      {...props}
    />
  );
};
