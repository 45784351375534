/**
 * @generated SignedSource<<048f0d58cf8d38da3069e60e61f1dee3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementDisputeCommentType = "ADDITIONAL_PAYMENT" | "TIMESHEET" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EngagementDisputeSummaryStack_engagement$data = {
  readonly approvedDisputes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly approval: {
          readonly approvedAt: string;
        } | null | undefined;
        readonly comments: ReadonlyArray<{
          readonly body: string;
          readonly type: EngagementDisputeCommentType;
        }>;
        readonly disputeTarget: {
          readonly disputedFinalTimesheet?: {
            readonly diff: {
              readonly additionalPayment: string;
              readonly basePayment: string;
              readonly billableDuration: string;
              readonly totalPayment: string;
              readonly totalWorkerServiceFeeAmount: string;
            } | null | undefined;
            readonly engagementTimesheet: {
              readonly paymentInfo: {
                readonly billableDuration: string;
                readonly netPay: string;
                readonly payRate: string;
              } | null | undefined;
            };
          } | null | undefined;
          readonly id?: string;
          readonly paymentInfo?: {
            readonly billableDuration: string;
            readonly netPay: string;
            readonly payRate: string;
          } | null | undefined;
          readonly paymentStyle?: EngagementTimesheetPaymentStyle;
          readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummary_EngagementTimesheet">;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly finalTimesheet: {
    readonly approvedDisputes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly approval: {
            readonly approvedAt: string;
          } | null | undefined;
          readonly comments: ReadonlyArray<{
            readonly body: string;
            readonly type: EngagementDisputeCommentType;
          }>;
          readonly disputeTarget: {
            readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummary_EngagementTimesheet">;
          };
          readonly expiresAt: string | null | undefined;
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly disputedFinalTimesheet: {
      readonly diff: {
        readonly additionalPayment: string;
        readonly basePayment: string;
        readonly billableDuration: string;
        readonly totalPayment: string;
        readonly totalWorkerServiceFeeAmount: string;
      } | null | undefined;
      readonly engagementTimesheet: {
        readonly paymentInfo: {
          readonly billableDuration: string;
          readonly netPay: string;
          readonly payRate: string;
        } | null | undefined;
      };
    } | null | undefined;
    readonly id: string;
    readonly paymentInfo: {
      readonly billableDuration: string;
      readonly netPay: string;
      readonly payRate: string;
    } | null | undefined;
    readonly paymentStyle: EngagementTimesheetPaymentStyle;
    readonly rejectedDisputes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly approval: {
            readonly approvedAt: string;
            readonly comment: string | null | undefined;
            readonly submittedBy: {
              readonly __typename: string;
            };
          } | null | undefined;
          readonly comments: ReadonlyArray<{
            readonly body: string;
            readonly type: EngagementDisputeCommentType;
          }>;
          readonly disputeTarget: {
            readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummary_EngagementTimesheet">;
          };
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly resolvedDisputesCount: {
      readonly totalCount: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummary_EngagementTimesheet">;
  } | null | undefined;
  readonly openDisputes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly comments: ReadonlyArray<{
          readonly body: string;
          readonly type: EngagementDisputeCommentType;
        }>;
        readonly disputeTarget: {
          readonly disputedFinalTimesheet?: {
            readonly diff: {
              readonly additionalPayment: string;
              readonly basePayment: string;
              readonly billableDuration: string;
              readonly totalPayment: string;
              readonly totalWorkerServiceFeeAmount: string;
            } | null | undefined;
            readonly engagementTimesheet: {
              readonly paymentInfo: {
                readonly billableDuration: string;
                readonly netPay: string;
                readonly payRate: string;
              } | null | undefined;
            };
          } | null | undefined;
          readonly id?: string;
          readonly paymentInfo?: {
            readonly billableDuration: string;
            readonly netPay: string;
            readonly payRate: string;
          } | null | undefined;
          readonly paymentStyle?: EngagementTimesheetPaymentStyle;
          readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummary_EngagementTimesheet">;
        };
        readonly expiresAt: string | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly rejectedDisputes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly approval: {
          readonly approvedAt: string;
          readonly comment: string | null | undefined;
          readonly submittedBy: {
            readonly __typename: string;
          };
        } | null | undefined;
        readonly comments: ReadonlyArray<{
          readonly body: string;
          readonly type: EngagementDisputeCommentType;
        }>;
        readonly disputeTarget: {
          readonly disputedFinalTimesheet?: {
            readonly diff: {
              readonly additionalPayment: string;
              readonly basePayment: string;
              readonly billableDuration: string;
              readonly totalPayment: string;
              readonly totalWorkerServiceFeeAmount: string;
            } | null | undefined;
            readonly engagementTimesheet: {
              readonly paymentInfo: {
                readonly billableDuration: string;
                readonly netPay: string;
                readonly payRate: string;
              } | null | undefined;
            };
          } | null | undefined;
          readonly id?: string;
          readonly paymentInfo?: {
            readonly billableDuration: string;
            readonly netPay: string;
            readonly payRate: string;
          } | null | undefined;
          readonly paymentStyle?: EngagementTimesheetPaymentStyle;
          readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummary_EngagementTimesheet">;
        };
        readonly expiresAt: string | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "EngagementDisputeSummaryStack_engagement";
};
export type EngagementDisputeSummaryStack_engagement$key = {
  readonly " $data"?: EngagementDisputeSummaryStack_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummaryStack_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementDisputeComment",
  "kind": "LinkedField",
  "name": "comments",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "EngagementDisputeSummary_EngagementTimesheet"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheetComparison",
  "kind": "LinkedField",
  "name": "disputedFinalTimesheet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetDiff",
      "kind": "LinkedField",
      "name": "diff",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePayment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPayment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWorkerServiceFeeAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalPayment",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "engagementTimesheet",
      "plural": false,
      "selections": [
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE approvalDisposition = APPROVED"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementDisputeApproval",
  "kind": "LinkedField",
  "name": "approval",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE ApprovalDisposition = REJECTED"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementDisputeApproval",
  "kind": "LinkedField",
  "name": "approval",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "submittedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "disputeTarget",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EngagementDisputeSummaryStack_engagement",
  "selections": [
    {
      "alias": "openDisputes",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE approvalDisposition = NULL"
        }
      ],
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementDisputesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDispute",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "disputeTarget",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v8/*: any*/),
                        (v1/*: any*/),
                        (v7/*: any*/)
                      ],
                      "type": "EngagementTimesheet",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
    },
    {
      "alias": "approvedDisputes",
      "args": (v9/*: any*/),
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementDisputesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDispute",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v11/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "disputeTarget",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v8/*: any*/),
                        (v7/*: any*/)
                      ],
                      "type": "EngagementTimesheet",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = APPROVED\")"
    },
    {
      "alias": "rejectedDisputes",
      "args": (v12/*: any*/),
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementDisputesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDispute",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                (v2/*: any*/),
                (v13/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "disputeTarget",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/),
                        (v1/*: any*/),
                        (v5/*: any*/),
                        (v8/*: any*/),
                        (v7/*: any*/)
                      ],
                      "type": "EngagementTimesheet",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "disputes(first:1,query:\"WHERE ApprovalDisposition = REJECTED\")"
    },
    {
      "alias": "finalTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "FINAL"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v8/*: any*/),
        (v7/*: any*/),
        {
          "alias": "resolvedDisputesCount",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 0
            }
          ],
          "concreteType": "EngagementDisputesConnection",
          "kind": "LinkedField",
          "name": "resolvedDisputes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "resolvedDisputes(first:0)"
        },
        {
          "alias": "approvedDisputes",
          "args": (v9/*: any*/),
          "concreteType": "EngagementDisputesConnection",
          "kind": "LinkedField",
          "name": "disputes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDisputesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementDispute",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v11/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v14/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = APPROVED\")"
        },
        {
          "alias": "rejectedDisputes",
          "args": (v12/*: any*/),
          "concreteType": "EngagementDisputesConnection",
          "kind": "LinkedField",
          "name": "disputes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDisputesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementDispute",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "disputes(first:1,query:\"WHERE ApprovalDisposition = REJECTED\")"
        }
      ],
      "storageKey": "timesheet(variant:\"FINAL\")"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "a69934444ce1232915ed34ca253321d1";

export default node;
