import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import React, { type ReactNode } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import type { IconName } from "../icon";
import {
  type StylesProps,
  type ViewStyleProp,
  type WithSafeAreaInsetsProps,
  safeAreaStub,
  stylesStubs,
  theme,
  withSafeAreaInsets,
  withStyles
} from "../style";

import TabIcon, { type Props as TabIconProps } from "./tab-icon";

export interface Tab {
  label: string;
  testID?: string;
  isActive?: boolean;
  onPress?: () => void;
  iconName?: IconName;
  renderIcon?: (props: TabIconProps) => ReactNode;
  renderImage?: (props: TabIconProps) => ReactNode;
  disabled?: boolean;
}

type Props = StylesProps &
  WithSafeAreaInsetsProps &
  EventerProps<"pressed"> & {
    activeColor?: string;
    activeFontColor?: string;
    inactiveFontColor?: string;
    children: Tab[];
    showPadding?: boolean;
    topBorder?: boolean;
    bottomTabs?: boolean;
    showShadow?: boolean;
    style?: ViewStyleProp;
    styleOverrides?: any;
  };

/** @deprecated */
@withStyles(({ color, font, shadow, units }) => ({
  authenticatedBarsContainer: {
    flex: 1,
    justifyContent: "flex-end"
  },
  barWithBorder: {
    borderTopWidth: Platform.OS === "ios" ? 0.5 : 1,
    borderTopColor: color.neutralMedium
  },
  barWithShadow: {
    ...shadow({
      shadowOffset: { width: 0, height: -5 }
    })
  },
  border: {
    borderWidth: 1,
    borderRadius: 4
  },
  borderLeft: {
    borderLeftWidth: 2
  },
  padded: {
    padding: units(4)
  },
  section: {
    borderColor: color.blue,
    flex: 1
  },
  sectionContainer: {
    flexDirection: "row",
    overflow: "hidden",
    width: "100%"
  },
  label: {
    fontSize: font.size.small,
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: "center"
  },
  icon: {
    fontSize: font.size.largest,
    paddingTop: 10,
    textAlign: "center"
  }
}))
@withEventers("Tab", ["pressed"])
@withSafeAreaInsets
export default class TabBar extends React.Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    insets: safeAreaStub,
    eventers: eventerStubs(["pressed"]),
    activeColor: theme.color.blue,
    activeFontColor: theme.color.white,
    inactiveFontColor: theme.color.blue
  };

  render() {
    const {
      styles,
      style,
      styleOverrides,
      children,
      showPadding,
      showShadow,
      topBorder,
      activeColor,
      bottomTabs
    } = this.props;

    const containerStyles = [
      showPadding && styles.padded,
      showShadow && styles.barWithShadow,
      topBorder && styles.barWithBorder,
      bottomTabs && styles.authenticatedBarsContainer,
      style
    ];
    const sectionStyles = [
      styleOverrides?.sectionContainer || styles.sectionContainer,
      showPadding && [styles.border, { borderColor: activeColor }]
    ];

    return (
      <View style={containerStyles}>
        <View style={sectionStyles}>{children.map(this._renderTab)}</View>
      </View>
    );
  }

  _renderTab = (tab: Tab, index: number) => {
    if (tab.disabled) return null;
    const { activeColor, showPadding, styleOverrides, insets, styles } =
      this.props;
    const activeStyleBackground = tab.isActive
      ? { backgroundColor: activeColor }
      : { backgroundColor: theme.color.white };

    const tabStyles = [
      styles.section,
      activeStyleBackground,
      showPadding && index !== 0 ? styles.borderLeft : {},
      styleOverrides ? styleOverrides.tabButton : {},
      { paddingBottom: Math.max(insets.bottom - 16, 0) }
    ];

    return (
      <View key={tab.label} style={tabStyles}>
        <TouchableOpacity
          testID={tab.testID ?? `TabNavigation_${index}`}
          onPress={() => {
            this.props.eventers.pressed({}, tab.label);
            tab?.onPress?.();
          }}
          accessible={false}
        >
          {this._renderTabIcon(tab)}
        </TouchableOpacity>
      </View>
    );
  };

  _renderTabIcon = (tab: Tab) => {
    const { styles, activeFontColor, inactiveFontColor, styleOverrides } =
      this.props;
    const activeStyleColor = tab.isActive
      ? { color: activeFontColor }
      : { color: inactiveFontColor };

    if (tab.renderImage) {
      return tab.renderImage({
        activeStyleColor,
        isActive: tab.isActive ?? false,
        iconStyle: styles.icon,
        label: tab.label,
        textStyle: [styles.label, activeStyleColor, styleOverrides?.label]
      });
    }

    if (tab.renderIcon && tab.iconName) {
      return tab.renderIcon({
        activeStyleColor,
        isActive: Boolean(tab.isActive) || false,
        iconStyle: styles.icon,
        iconName: tab.iconName,
        label: tab.label,
        textStyle: [styles.label, activeStyleColor, styleOverrides?.label]
      });
    }

    return (
      <TabIcon
        isActive={!!tab.isActive}
        iconName={tab.iconName}
        activeStyleColor={activeStyleColor}
        iconStyle={styles.icon}
        label={tab.label}
        textStyle={[styles.label, activeStyleColor, styleOverrides?.label]}
      />
    );
  };
}
