import { ScreenScroll, StepperProvider } from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import AddDirectPaymentOrHireStepper from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper";
import AgreementStep from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper/AgreementStep";
import EvidenceStep from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper/EvidenceStep";
import InformationStep from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper/InformationStep";
import OrganizationStep from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper/OrganizationStep";
import ReviewStep from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper/ReviewStep";
import TypeStep from "@gigsmart/seibutsu/direct-hire/worker/AddDirectPaymentOrHireStepper/TypeStep";
import { compact } from "lodash";
import React, { useMemo } from "react";
import type { addDirectPaymentOrHireScreenQuery } from "./__generated__/addDirectPaymentOrHireScreenQuery.graphql";

export default createSuspendedQueryContainer<addDirectPaymentOrHireScreenQuery>(
  function AddDirectPaymentOrHireScreen({ response: result }) {
    return (
      <ScreenScroll testID="add-direct-payment-or-hire-screen">
        <StepperProvider
          eventContext="Add Direct Payment or Hire Stepper"
          steps={useMemo(
            () =>
              compact([
                {
                  stepName: "agreement",
                  component: <AgreementStep />
                },
                {
                  stepName: "information",
                  component: <InformationStep />
                },
                DirectPaymentsOnly.isDisabled() && {
                  stepName: "type",
                  component: (
                    <TypeStep bonusAmount={result?.viewer?.directHireBonus} />
                  )
                },
                {
                  stepName: "organization",
                  component: <OrganizationStep />
                },
                {
                  stepName: "evidence",
                  component: <EvidenceStep />
                },
                {
                  stepName: "review",
                  component: <ReviewStep />
                }
              ]),
            []
          )}
        >
          <AddDirectPaymentOrHireStepper />
        </StepperProvider>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query addDirectPaymentOrHireScreenQuery {
        viewer {
          ... on Worker {
            directHireBonus
          }
        }
      }
    `,
    variables: {}
  }
);
