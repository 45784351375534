import { toast } from "@gigsmart/atorasu";
import {
  ContentArea,
  ScreenScroll,
  ScreenTop,
  Spacer,
  Surface,
  Text,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import type { AppNavigationProp } from "@gigsmart/kaizoku";
import { NavPortal } from "@gigsmart/katana";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import type { SharedParamList } from "@gigsmart/seibutsu/navigation/types";
import { useNavigation } from "@react-navigation/native";
import React from "react";

import type { newForgotPasswordMutation } from "./__generated__/newForgotPasswordMutation.graphql";
import AuthNavBarButton from "./auth-navbar-button";

interface Props {
  userType:
    | "ADMIN"
    | "ORGANIZATION_APPLICATION"
    | "ORGANIZATION_REQUESTER"
    | "REQUESTER"
    | "SYSTEM"
    | "WORKER";
}

export default function NewForgotPasswordScreen({ userType }: Props) {
  const navigation =
    useNavigation<AppNavigationProp<SharedParamList, "ResetPasswordSuccess">>();
  const [mutation] = useRelayMutation<newForgotPasswordMutation>(
    graphql`
      mutation newForgotPasswordMutation(
        $input: RequestUserPasswordResetInput!
      ) {
        requestUserPasswordReset(input: $input) {
          ok
        }
      }
    `
  );

  const handleSubmit: FomuSubmitFn = ({ values }) => {
    const emailAddress = values.emailAddress;
    mutation(
      { input: { emailAddress, userType } },
      {
        onSuccess: () => {
          navigation.replace("ResetPasswordSuccess", {
            emailAddress,
            userType
          });
        },
        onError: (result) => {
          toast.error(
            result.message || "Unexpected error while sending email."
          );
        }
      }
    );
  };

  const isSm = useMatchesViewport(({ size }) => size.small.down);

  return (
    <Form initialValues={{ email: "" }} onSubmit={handleSubmit}>
      <ScreenScroll
        testID="forgot-password-screen"
        color={isSm ? "surface" : "background"}
      >
        <NavPortal.Entrance
          renderRightIcon={() => (
            <AuthNavBarButton
              text="Sign In"
              to="/login"
              testID="login-button"
            />
          )}
        />
        <ScreenTop extraSpace="large" />
        <ContentArea
          constraint="small"
          size="none"
          variant="none"
          constrainedVariant="none"
          grow
        >
          <Surface variant={isSm ? "flat" : "shadow"}>
            <Spacer size="large" />
            <ContentArea constraint="xsmall">
              <Text variant="header" testID="wizard-form-title">
                Forgot Password?
              </Text>
              <Spacer size="compact" />
              <Text testID="wizard-form-subtitle">
                Please enter the email address associated with your account.
              </Text>
              <Spacer size="large" />
              <FomuTextInput
                type="email"
                name="emailAddress"
                validates={Validator.email()}
                label="Email"
                autoFocus
              />
            </ContentArea>
            <Spacer size="large" />
          </Surface>
          <ContentArea
            constraint="xsmall"
            grow={isSm}
            justifyContent="flex-end"
          >
            <FomuSubmit testID="submit-button" label="Send" />
          </ContentArea>
        </ContentArea>
      </ScreenScroll>
    </Form>
  );
}
