import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";
import type { MultiSelectionRowGroupOption } from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { showCommercialDriversLicenseModal } from "@gigsmart/seibutsu/worker/CommercialDriversLicenseModal";
import { showDeleteCommercialDriversLicenseModal } from "@gigsmart/seibutsu/worker/useDeleteCommercialDriversLicenseModal";
import useWorkerQualificationProof from "@gigsmart/seibutsu/worker/useWorkerQualificationProof";
import React from "react";
import { useQualifications } from "./hooks/useQualifications";

function LicensesCertificationsScreen() {
  const {
    title,
    subTitle,
    reminderText,
    iconName,
    options,
    proofStatus,
    proofId,
    checkOption,
    onBackPress
  } = useQualifications({
    qualificationTitle: "Licenses & Certifications"
  });

  const { setProofOnWorkerQualification, unsetProofOnWorkerQualification } =
    useWorkerQualificationProof();

  const onComplete = async ({
    value,
    onValueChange,
    id,
    label,
    workerQualificationProofId = ""
  }: MultiSelectionRowGroupOption & {
    workerQualificationProofId?: string;
  }) => {
    onValueChange?.(value);
    checkOption({
      value,
      id,
      label,
      onCompleted: async (workerQualification) => {
        if (label !== "Driver's License") return;
        try {
          if (workerQualification) {
            if (value)
              await setProofOnWorkerQualification({
                input: {
                  workerQualificationId: workerQualification.id,
                  workerQualificationProofId:
                    proofStatus === "VALID" && proofId
                      ? proofId
                      : workerQualificationProofId
                }
              });
            else
              await unsetProofOnWorkerQualification({
                input: {
                  workerQualificationId: workerQualification.id
                }
              });
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    });
  };

  const handleEdit = async ({ label }: MultiSelectionRowGroupOption) => {
    showCommercialDriversLicenseModal({
      title: label ?? "",
      onComplete: () => null
    });
  };

  const handleCheck = async ({
    value,
    label,
    id,
    onValueChange
  }: MultiSelectionRowGroupOption) => {
    if (label === "Driver's License") {
      if (value) {
        if (proofStatus !== "VALID" || !proofId) {
          showCommercialDriversLicenseModal({
            title: label ?? "",
            onComplete: (workerQualificationProofId) =>
              void onComplete({
                workerQualificationProofId,
                value,
                onValueChange,
                label,
                id
              })
          });
        } else {
          void onComplete({ value, onValueChange, label, id });
        }
      } else if (!value) {
        showDeleteCommercialDriversLicenseModal(
          () => void onComplete({ value, onValueChange, label, id })
        );
      }
      return;
    }

    void onComplete({ value, onValueChange, label, id });
  };
  if (!options || options?.length === 0) return null;
  return (
    <ScreenScroll testID="licensesCertificationsScreen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="licenseCertification-rowgroup"
          header={title ?? ""}
          note={subTitle}
          searchHeader="Do you have the following Licenses & Certifications?"
          searchPlaceholder="Search Licenses & Certifications"
          selectionRowHeader=" Licenses & Certifications"
          selectionRowHeaderIcon={iconName}
          options={options}
          autoChecking={false}
          reminder={reminderText}
          onCheckOption={handleCheck}
          showEdit={(options) => options.label === "Driver's License"}
          onEdit={handleEdit}
        />
      </ContentArea>
    </ScreenScroll>
  );
}

export default LicensesCertificationsScreen;
