import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { useEffect, useState } from "react";

import { useUserValue } from "../user-value";

export default function useSearchDistance(defaultDistance: number) {
  const [hasFetchedDistance, toggleFetched] = useState(false);
  const [distance, setDistance] = useUserValue<number>(
    "availableGigSearchDistance"
  );

  useEffect(() => {
    if (distance === undefined || hasFetchedDistance) return; /// not loaded

    let newDistance = distance || defaultDistance;
    if (UnlimitedDistanceFeature.isDisabled() && newDistance > 75) {
      newDistance = 75;
    }

    if (distance !== newDistance) void setDistance(newDistance);
    toggleFetched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedDistance, distance, defaultDistance]);

  return {
    distance,
    persistDistance: (distance: number) => {
      void setDistance(distance);
    },
    hasFetchedDistance
  };
}
