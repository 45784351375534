import { useStepper } from "@gigsmart/atorasu";
import type { $ElementType } from "@gigsmart/type-utils";
import React, { type ComponentProps } from "react";
import type ConsentDocumentForm from "../../user-consent/ConsentDocumentForm";
import ConsentDocumentStepper from "../../user-consent/ConsentDocumentStepper";
import type { ConsentDocumentStepper_documents$key } from "../../user-consent/__generated__/ConsentDocumentStepper_documents.graphql";

interface Props {
  additionalConsents?: $ElementType<
    ComponentProps<typeof ConsentDocumentForm>,
    "additionalConsents"
  >;
  documents?: ConsentDocumentStepper_documents$key;
}

export default function LegalStep({
  additionalConsents,
  documents: documentsFragmentRef
}: Props) {
  const { nextStep: parentNextStep } = useStepper();
  return (
    <ConsentDocumentStepper
      documents={documentsFragmentRef}
      onComplete={() => parentNextStep()}
      additionalConsents={additionalConsents}
    />
  );
}
