import moment from "moment-timezone";
import React, {
  useCallback,
  type ComponentProps,
  useState,
  useEffect
} from "react";
import type { ViewStyle } from "react-native";
import StyledTextInput from "./styled-text-input";
import { numericKeyboardType } from "./text-utils";

type Props = Omit<ComponentProps<typeof StyledTextInput>, "onChangeText"> & {
  style?: ViewStyle;
  dateFormat?: string;
  onChangeText?: (dateValue: string, text: string) => void;
};

const StyledDateInput = ({
  testID = "styled-date-input",
  value,
  onChangeText,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = useState(() => {
    return value ? moment(value).format("MM/DD/YYYY") : "";
  });
  const handleChangeText = useCallback(
    (value: string) => {
      setInputValue(value);
      const momentObj = moment(value, "MM/DD/YYYY", true);
      const isValid = value.length === 10 && momentObj.isValid();
      const dateValue = isValid ? momentObj.format("YYYY-MM-DD") : "";
      onChangeText?.(dateValue, value);
    },
    [onChangeText]
  );

  useEffect(() => {
    if (value) {
      const fmtValue = moment(value).format("MM/DD/YYYY");
      if (fmtValue !== inputValue) setInputValue(fmtValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StyledTextInput
      testID={testID}
      value={inputValue}
      keyboardType={numericKeyboardType()}
      onChangeText={handleChangeText}
      placeholder={props.placeholder ?? "MM/DD/YYYY"}
      mask="date"
      {...props}
    />
  );
};

export default StyledDateInput;
