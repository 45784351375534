/**
 * @generated SignedSource<<bc86a0374e629e23eec3a5c850fced54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CancellationReasonType = "CANCELED" | "DID_NOT_WORK" | "ISSUE_WITH_WORKER" | "NO_LONGER_AVAILABLE" | "RECEIVED_ANOTHER_OFFER" | "REPORT_NO_SHOW" | "REQUESTER_UNRESPONSIVE" | "UNABLE_TO_FINALIZE" | "UNSPECIFIED" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type engagementDetailsHeader_engagement$data = {
  readonly cancellationReason: {
    readonly authoredBy: UserType;
    readonly disqualifications: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly gigFieldItemDefinition: {
            readonly gigField: {
              readonly iconName: string;
              readonly id: string;
              readonly title: string;
            };
            readonly label: string;
          };
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly reasonType: CancellationReasonType;
  } | null | undefined;
  readonly currentState: {
    readonly action: EngagementStateAction;
    readonly name: EngagementStateName;
    readonly transitionedBy: {
      readonly __typename: string;
    } | null | undefined;
  };
  readonly gig: {
    readonly gigSeries: {
      readonly id: string;
    };
    readonly position: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"RequesterInfoRow_gigLike" | "gigRequesterRow_gigLike">;
  };
  readonly gigType: GigType;
  readonly id: string;
  readonly reviewOfRequester: {
    readonly rating: number;
  } | null | undefined;
  readonly states: {
    readonly totalCount: number;
  } | null | undefined;
  readonly worker: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"useEnrollmentData_worker">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EngagementDetailsCard_engagement" | "WorkerShiftApplets_shift" | "actionRow_engagement" | "projectAppliedHeader_engagement" | "useApplyMetadataIsEnrolled_gigOrEngagement">;
  readonly " $fragmentType": "engagementDetailsHeader_engagement";
};
export type engagementDetailsHeader_engagement$key = {
  readonly " $data"?: engagementDetailsHeader_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"engagementDetailsHeader_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalDocument",
  "kind": "LinkedField",
  "name": "pendingConsents",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConsentDocumentStepper_documents"
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "GigPosition",
  "kind": "LinkedField",
  "name": "position",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "requiredReportTypes",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "GigSeries",
    "kind": "LinkedField",
    "name": "gigSeries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 0
          }
        ],
        "concreteType": "GigFieldItemDefinitionsConnection",
        "kind": "LinkedField",
        "name": "unmetQualifications",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": "unmetQualifications(first:0)"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "engagementDetailsHeader_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EngagementDetailsCard_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectAppliedHeader_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "actionRow_engagement"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useApplyMetadataIsEnrolled_gigOrEngagement",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "gig",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "Engagement",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "AvailableGigsEdge",
              "abstractKey": null
            }
          ],
          "type": "AvailableGigOrEngagement",
          "abstractKey": "__isAvailableGigOrEngagement"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftApplets_shift"
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gigRequesterRow_gigLike"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RequesterInfoRow_gigLike"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigSeries",
          "kind": "LinkedField",
          "name": "gigSeries",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "transitionedBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useEnrollmentData_worker"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE name=SCHEDULED"
        }
      ],
      "concreteType": "EngagementStateOrOverridesConnection",
      "kind": "LinkedField",
      "name": "states",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "states(first:1,query:\"WHERE name=SCHEDULED\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CancellationReason",
      "kind": "LinkedField",
      "name": "cancellationReason",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authoredBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reasonType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            }
          ],
          "concreteType": "CancellationReasonDisqualificationsConnection",
          "kind": "LinkedField",
          "name": "disqualifications",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CancellationReasonDisqualificationsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CancellationReasonDisqualification",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GigFieldItemDefinition",
                      "kind": "LinkedField",
                      "name": "gigFieldItemDefinition",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GigField",
                          "kind": "LinkedField",
                          "name": "gigField",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "iconName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "disqualifications(first:50)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementReview",
      "kind": "LinkedField",
      "name": "reviewOfRequester",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rating",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "76b08f7993d8aee4001b4d00af66b340";

export default node;
