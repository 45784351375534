import type { ReactElement } from "react";
import useCurrentTime from "./use-current-time";

const emptyFn = (_currentTime: Date) => null;

interface Props {
  renderProp?: (currentTime: Date) => ReactElement | null;
  children?: (currentTime: Date) => ReactElement | null;
}

const TimeTicker = ({ renderProp, children }: Props) => {
  const currentTime = useCurrentTime();
  const render = renderProp ?? children ?? emptyFn;
  return render(currentTime);
};

export default TimeTicker;
