import React, { type ReactNode } from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../../style";
import TimeSheetRow from "./timesheet-row";

export interface TimesheetContainerEntry {
  event: string;
  timestamp: string;
  disabled?: boolean;
  isOpen?: boolean;
  adjusted?: boolean;
  added?: boolean;
}

interface Props {
  style?: ViewStyleProp;
  entries: TimesheetContainerEntry[] | null | undefined;
  footerNote?: ReactNode;
}

const TimesheetContainer = ({ entries, style, footerNote }: Props) => {
  const { styles } = useStyles(({ units, color }) => ({
    container: {
      flexDirection: "column",
      padding: units(4),
      borderWidth: 1,
      borderColor: color.neutralLight,
      borderRadius: units(1)
    },
    note: {
      textAlign: "center",
      marginTop: units(6),
      marginBottom: units(1)
    }
  }));

  return (
    <View style={[styles.container, style]}>
      {!!entries &&
        entries.map((entry, index) => (
          <TimeSheetRow
            key={`${index}-${entry.event}`}
            label={entry.event}
            timeStamp={entry.timestamp}
            barHidden={index === entries.length - 1}
            barActive={!entry.isOpen}
            disabled={entry.disabled}
            adjusted={entry.adjusted}
            added={entry.added}
          />
        ))}
      {footerNote}
    </View>
  );
};

export default TimesheetContainer;
