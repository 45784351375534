/**
 * @generated SignedSource<<3c8e1f2a3dae47033c4c66bd6f7691b2>>
 * @relayHash a8216af0ae1d6fdbb0eea166909e1ad4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Ws5XPCg2-q8ViMmvQlCKi8zbGpdcAftgX_5T3pyE7fc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationParticipantCapability = "CALL" | "%future added value";
export type AddConversationParticipantInput = {
  conversationId: string;
  readUntil?: string | null | undefined;
  userId?: string | null | undefined;
};
export type RequesterMoreMenuJoinConversationMutation$variables = {
  input: AddConversationParticipantInput;
};
export type RequesterMoreMenuJoinConversationMutation$data = {
  readonly addConversationParticipant: {
    readonly newConversationParticipantEdge: {
      readonly node: {
        readonly capabilities: ReadonlyArray<ConversationParticipantCapability>;
        readonly conversation: {
          readonly " $fragmentSpreads": FragmentRefs<"RequesterConversationHeader_conversationLike">;
        };
        readonly id: string;
        readonly readCursor: string | null | undefined;
        readonly readUntil: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type RequesterMoreMenuJoinConversationMutation = {
  response: RequesterMoreMenuJoinConversationMutation$data;
  variables: RequesterMoreMenuJoinConversationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readUntil",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readCursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capabilities",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v13/*: any*/),
  (v2/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": (v7/*: any*/),
  "concreteType": "WorkerGroupWorkersConnection",
  "kind": "LinkedField",
  "name": "groupAssociations",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "groupAssociations(first:0)"
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v10/*: any*/),
    (v12/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequesterMoreMenuJoinConversationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddConversationParticipantPayload",
        "kind": "LinkedField",
        "name": "addConversationParticipant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationParticipantsEdge",
            "kind": "LinkedField",
            "name": "newConversationParticipantEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationParticipant",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RequesterConversationHeader_conversationLike"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequesterMoreMenuJoinConversationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddConversationParticipantPayload",
        "kind": "LinkedField",
        "name": "addConversationParticipant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationParticipantsEdge",
            "kind": "LinkedField",
            "name": "newConversationParticipantEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationParticipant",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": "type",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ConversationParticipant",
                            "kind": "LinkedField",
                            "name": "participant",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": (v7/*: any*/),
                                        "concreteType": "OrganizationsConnection",
                                        "kind": "LinkedField",
                                        "name": "organizations",
                                        "plural": false,
                                        "selections": (v9/*: any*/),
                                        "storageKey": "organizations(first:0)"
                                      }
                                    ],
                                    "type": "Requester",
                                    "abstractKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 30
                              }
                            ],
                            "concreteType": "ConversationParticipantsConnection",
                            "kind": "LinkedField",
                            "name": "participants",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ConversationParticipantsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ConversationParticipant",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "user",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v2/*: any*/),
                                          (v10/*: any*/),
                                          (v12/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Organization",
                                                "kind": "LinkedField",
                                                "name": "primaryOrganization",
                                                "plural": false,
                                                "selections": (v14/*: any*/),
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "Requester",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v15/*: any*/),
                                              (v16/*: any*/)
                                            ],
                                            "type": "Worker",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "participants(first:30)"
                          },
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Gig",
                                "kind": "LinkedField",
                                "name": "gig",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startsAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "actualStartsAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endsAt",
                                    "storageKey": null
                                  },
                                  (v13/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "conversationRule",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigState",
                                    "kind": "LinkedField",
                                    "name": "currentState",
                                    "plural": false,
                                    "selections": (v14/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ShiftGroupConversation",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v2/*: any*/),
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 50
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
                                  }
                                ],
                                "concreteType": "EngagementsConnection",
                                "kind": "LinkedField",
                                "name": "engagements",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Engagement",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "contactNumber",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Gig",
                                            "kind": "LinkedField",
                                            "name": "gig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "OrganizationRequester",
                                                "kind": "LinkedField",
                                                "name": "primaryContact",
                                                "plural": false,
                                                "selections": [
                                                  (v2/*: any*/),
                                                  (v10/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v2/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "EngagementCapability",
                                            "kind": "LinkedField",
                                            "name": "capabilities",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "type",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "restrictedBy",
                                                "plural": false,
                                                "selections": [
                                                  (v6/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "message",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expiresAt",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "status",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "engagements(first:50,query:\"WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]\")"
                              }
                            ],
                            "type": "OrganizationWorkerConversation",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Engagement",
                                "kind": "LinkedField",
                                "name": "engagement",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "type": "EngagementConversation",
                            "abstractKey": null
                          }
                        ],
                        "type": "ConversationLike",
                        "abstractKey": "__isConversationLike"
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Ws5XPCg2-q8ViMmvQlCKi8zbGpdcAftgX_5T3pyE7fc",
    "metadata": {},
    "name": "RequesterMoreMenuJoinConversationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "ab9e4684687dc1d7deabf7b4f8bbef72";

export default node;
