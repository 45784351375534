import React from "react";

import {
  Button,
  Column,
  IconCircle,
  ProductInfoRow,
  Stack,
  Text,
  TopicPreviewSurface,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";

import { capitalize } from "lodash";
import JobBoardInfoModal from "./JobBoardInfoModal";
import JobBoardPreviewEmptyView from "./JobBoardPreviewEmptyView";
import type {
  JobBoardPreview_root$data,
  JobBoardPreview_root$key
} from "./__generated__/JobBoardPreview_root.graphql";

export type BrowseJobNodeType = NonNullable<
  ObjectPath<
    JobBoardPreview_root$data,
    ["affiliateJobPostings", "edges", 0, "node"]
  >
>;

export const JobBoardPreview = ({
  affiliateJobPostings,
  viewer
}: FragmentContainerInnerComponentProps<JobBoardPreview_root$key>) => {
  const history = useHistory();
  const jobPosts = getConnectionNodes(viewer?.availableJobPostings) ?? [];
  const affiliatePosts = getConnectionNodes(affiliateJobPostings) ?? [];
  const isEmpty = jobPosts.length === 0 && affiliatePosts.length === 0;
  const slicedAffiliatePosts = affiliatePosts.slice(
    0,
    Math.max(0, 3 - jobPosts.length)
  );

  return (
    <TopicPreviewSurface
      testID="job-board-preview"
      iconName="newspaper"
      color="primary"
      title="Job Board"
      subtitle="Browse open part-time and full-time positions on our Job Board. You can apply directly through the hiring company."
      rightAccessory={<JobBoardInfoModal />}
    >
      {isEmpty && <JobBoardPreviewEmptyView />}
      {(jobPosts.length > 0 || affiliatePosts.length > 0) && (
        <Column gap="standard">
          <Column gap="compact">
            {jobPosts.map((job, index) => {
              const { id, title, boosted } = job;
              return (
                <ProductInfoRow
                  testID={`job-post-${index}`}
                  key={`job-post-${index}`}
                  leftSpacing="small"
                  onPress={() => history.push(`/browse/jobs/${id}`)}
                  eventEntityType="WorkerHomeAvailableJobs"
                  name={
                    <Stack horizontal alignItems="center" size="slim">
                      {boosted && (
                        <IconCircle
                          color="emphasized"
                          icon="chevrons-up"
                          size="micro"
                        />
                      )}
                      <Stack fill>
                        <Text
                          color="black"
                          variant="subheader"
                          numberOfLines={1}
                        >
                          {title ?? ""}
                        </Text>
                      </Stack>
                    </Stack>
                  }
                  icon="newspaper"
                  color="neutral"
                  iconSquareVariant="standard"
                  note={
                    <Text variant="note" color="neutral">
                      {generateNote(job)}
                    </Text>
                  }
                />
              );
            })}
            {slicedAffiliatePosts.map((job, index) => (
              <ProductInfoRow
                variant="outline"
                testID={`affiliate-job-post-${index}`}
                key={`affiliate-job-post-${index}`}
                onPress={() =>
                  job.url ? showEmbeddedBrowser({ url: job.url }) : null
                }
                eventEntityType="WorkerHomeAvailableJobs"
                name={
                  <Text color="black" variant="subheader" numberOfLines={1}>
                    {job.name ?? ""}
                  </Text>
                }
                icon="newspaper"
                color="neutral"
                iconSquareVariant="standard"
                note={
                  <Text variant="note" color="neutral" numberOfLines={1}>
                    {generateAffiliateNote(job)}
                  </Text>
                }
              />
            ))}
          </Column>
          <Button
            alignSelf="center"
            testID="view-job-board-btn"
            size="small"
            outline
            label="View Job Board"
            icon="chevron-right"
            iconPlacement="right"
            onPress={() => {
              history.push("browse/jobs");
            }}
          />
        </Column>
      )}
    </TopicPreviewSurface>
  );
};

export default createRelayFragmentContainer<JobBoardPreview_root$key>(
  graphql`
    fragment JobBoardPreview_root on RootQueryType
    @argumentDefinitions(
      affiliateJobPostSearch: { type: "AffiliateJobPostingsInput!" }
      jobPostSearch: { type: "JobPostingSearch!" }
    ) {
      affiliateJobPostings(first: 3, input: $affiliateJobPostSearch) {
        totalCount
        edges {
          node {
            externalId
            name
            url
            maximumSalary
            minimumSalary
            salaryInterval
          }
        }
      }
      viewer {
        ... on Worker {
          availableJobPostings(first: 3, search: $jobPostSearch) {
            totalCount
            edges {
              node @trackImpressions(action: ACCESSED) {
                id
                title
                boosted
                paySchedule
                payRateNegotiable
                maximumPayRate
                minimumPayRate
              }
            }
          }
        }
      }
    }
  `,
  JobBoardPreview
);

const generateNote = ({
  payRateNegotiable,
  minimumPayRate,
  maximumPayRate,
  paySchedule
}: Exclude<
  ObjectPath<
    JobBoardPreview_root$key,
    [" $data", "viewer", "availableJobPostings", "edges", 0, "node"]
  >,
  null | undefined
>) => {
  if (payRateNegotiable) return "Salary • Negotiable";

  if (paySchedule === "ANNUALLY") {
    return `Salary • ${
      minimumPayRate === maximumPayRate
        ? `$${Math.floor(currency.toFloat(minimumPayRate) / 1000)}K`
        : `$${Math.floor(
            currency.toFloat(minimumPayRate) / 1000
          )}K to ${Math.floor(currency.toFloat(maximumPayRate) / 1000)}K`
    }`;
  }
  return `Hourly • ${
    minimumPayRate === maximumPayRate
      ? `$${Math.floor(currency.toFloat(minimumPayRate))}`
      : `$${Math.floor(currency.toFloat(minimumPayRate))} - $${Math.floor(
          currency.toFloat(maximumPayRate)
        )}`
  }`;
};

const generateAffiliateNote = ({
  minimumSalary,
  maximumSalary,
  salaryInterval
}: Exclude<
  ObjectPath<
    JobBoardPreview_root$key,
    [" $data", "affiliateJobPostings", "edges", 0, "node"]
  >,
  null | undefined
>) => {
  if (!salaryInterval && !minimumSalary && !maximumSalary)
    return "Salary • Negotiable";

  const interval = salaryInterval ? `${capitalize(salaryInterval)} • ` : "";

  let salary = "";

  if (minimumSalary === null && maximumSalary === null) {
    return "Negotiable";
  }

  const min = Math.floor(currency.toFloat(minimumSalary));
  const max = Math.floor(currency.toFloat(maximumSalary));

  salary =
    min === max
      ? `$${min > 999 ? `${Math.floor(min / 1000)}K` : min}`
      : `$${min > 999 ? `${Math.floor(min / 1000)}K` : min}` +
        ` ${salaryInterval === "yearly" ? "to" : "-"} $` +
        `${max > 999 ? `${Math.floor(max / 1000)}K` : max}`;

  return interval + salary;
};
