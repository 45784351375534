import { Platform } from "@gigsmart/atorasu";
import { getInitialLink } from "@gigsmart/isomorphic-shared/app/DeepLinking";
import { type AppScreenProps, Redirect } from "@gigsmart/kaizoku";
import * as Sentry from "@sentry/react-native";
import React, { useEffect, useState } from "react";
import type { WorkerParamList } from "../types";

export function NotFoundScreen({
  route
}: AppScreenProps<WorkerParamList, "NotFound">) {
  const [redirect, setRedirect] = useState(Platform.OS === "web");

  useEffect(() => {
    if (Platform.OS !== "web") return;

    const run = async () => {
      const initialUrl = await getInitialLink();
      const message = { initialUrl, current: route.path, params: route.params };

      console.error("Not Found: Redirecting to /", message);
      Sentry.addBreadcrumb({
        category: "navigation.not-found",
        level: "error",
        data: message
      });
    };

    run().finally(() => setRedirect(true));
  }, []);

  return redirect ? <Redirect to="/" /> : null;
}
