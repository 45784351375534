import { toast } from "@gigsmart/atorasu";
import {
  type RelayMutationCommitOptions,
  type UseRelayMutationOptions,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import moment from "moment-timezone";
import { useCallback } from "react";
import type {
  SetWorkerLegalInformationInput,
  useEnrollmentMutation as useEnrollmentMutationType
} from "./__generated__/useEnrollmentMutation.graphql";
import type { useEnrollmentDataFragment } from "./useEnrollmentData";

export default function useEnrollmentMutation(
  enrollmentData: ReturnType<typeof useEnrollmentDataFragment>,
  options?: UseRelayMutationOptions<useEnrollmentMutationType>
) {
  const { onError, ...restOptions } = options ?? {};
  const [mutate, meta] = useRelayMutation<useEnrollmentMutationType>(
    graphql`
      mutation useEnrollmentMutation(
        $updateWorkerInput: UpdateWorkerInput!
        $input: SetWorkerLegalInformationInput!
      ) {
        setWorkerLegalInformation(input: $input) {
          workerLegalInformation {
            ...useEnrollmentData_legalInformation
          }
          workerVerification {
            ...useEnrollmentData_verification
          }
        }
        updateWorker(input: $updateWorkerInput) {
          worker {
            birthdate
          }
        }
      }
    `,
    {
      onError: (error) => {
        toast.error(error.message);
        !!onError && onError(error);
      },
      ...restOptions
    }
  );
  const submit = useCallback(
    (
      {
        firstName,
        middleName,
        lastName,
        birthdate: _birthdate,
        emailAddress,
        street1,
        street2,
        locality,
        administrativeArea1,
        postalCode,
        nationalIdNumber,
        backgroundCheckOptIn,
        driversLicenseIssuer,
        driversLicenseId,
        driverCheckOptIn,
        drugScreenOptIn,
        pspTosAccepted
      }: SetWorkerLegalInformationInput,
      commitOptions?: RelayMutationCommitOptions<useEnrollmentMutationType>
    ) => {
      const birthdate = moment(_birthdate ?? undefined).format("YYYY-MM-DD");
      const input = {
        backgroundCheckOptIn: !!backgroundCheckOptIn,
        driverCheckOptIn: !!driverCheckOptIn,
        drugScreenOptIn: !!drugScreenOptIn,
        ...(!enrollmentData?.hasReport("IDENTITY_CHECK")
          ? { pspTosAccepted }
          : {}),
        ...(!enrollmentData?.hasReports()
          ? {
              firstName,
              middleName: middleName ?? null,
              lastName,
              emailAddress,
              birthdate,
              street1,
              street2,
              locality,
              administrativeArea1,
              postalCode,
              nationalIdNumber
            }
          : {}),
        ...(!enrollmentData?.hasReport("MOTOR_VEHICLE_CHECK") &&
        driverCheckOptIn
          ? {
              driversLicenseIssuer,
              driversLicenseId
            }
          : {
              driversLicenseIssuer: undefined,
              driversLicenseId: undefined
            })
      };
      mutate(
        {
          updateWorkerInput: {
            birthdate
          },
          input
        },
        commitOptions
      );
    },
    [mutate, enrollmentData]
  );
  return [submit, meta] as const;
}
