import type { MessageType } from "./__generated__/notificationIconContainerSubscription.graphql";

const messageTypes: MessageType[] = [
  "ALERT",
  "CORPORATE",
  "GIG_POSTED",
  "GIG",
  "MISCELLANEOUS",
  "PAYMENT",
  "JOB_BOOSTED",
  "JOB_POSTING",
  "DIRECT_HIRE"
];

export default messageTypes;
