/**
 * @generated SignedSource<<dca455605aced85c69c33add3b7e08aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hiringCompanyCard_organization$data = {
  readonly industry: string;
  readonly logoUrl: string | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "hiringCompanyCard_organization";
};
export type hiringCompanyCard_organization$key = {
  readonly " $data"?: hiringCompanyCard_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"hiringCompanyCard_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hiringCompanyCard_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "industry",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "903168a6c28c953b8623904301200754";

export default node;
