import {
  type EventerProps,
  type JsonMap,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import { isEqual } from "lodash";
import React, {
  Component,
  type ComponentType,
  type ComponentProps
} from "react";
import { Keyboard, TouchableOpacity } from "react-native";
import { StyledIcon } from "../icon";
import {
  type StylesProps,
  type TextStyleProp,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import StyledText from "../text/styled-text";

type Props = StylesProps &
  ComponentProps<typeof TouchableOpacity> &
  EventerProps<"pressed"> & {
    buttonStyle?: ViewStyleProp;
    eventTargetName: string | null;
    eventProperties?: JsonMap;
    color?: string;
    disabled?: boolean;
    title?: string;
    onPress: () => Promise<void> | void;
    primary?: boolean;
    secondary?: boolean;
    isInactive?: boolean;
    textColor?: string;
    activeTextStyle?: TextStyleProp;
    textStyle?: TextStyleProp;
    IconComponent?: ComponentType<any>;
    notTouchable?: boolean;
    icon?: Record<string, unknown>;
    rightIcon?: Record<string, unknown>;
    size: number;
    iconStyle?: ViewStyleProp;
  };

@withStyles(({ color }) => ({
  button: {
    borderRadius: 100,
    flexDirection: "row",
    flexWrap: "nowrap",
    paddingVertical: 5,
    paddingHorizontal: 15,
    justifyContent: "center",
    alignItems: "center"
  },
  disabled: {
    backgroundColor: color.neutralDark
  },
  disabledText: {
    color: color.white
  },
  primary: {
    backgroundColor: color.orange
  },
  primaryText: {
    color: color.white
  },
  inactive: {
    backgroundColor: color.neutralMedium,
    color: color.neutralDark
  },
  secondary: {
    backgroundColor: color.transparent,
    borderColor: color.blue,
    borderWidth: 1
  },
  secondaryText: {
    color: color.blue
  },
  iconComponent: {
    marginRight: 10,
    color: color.white
  },
  rightIconComponent: {
    marginLeft: 10,
    color: color.white
  }
}))
@withEventers<"pressed", Props>("Bubble", ["pressed"], "title")
export default class StyledBubble extends Component<Props> {
  static defaultProps = {
    size: 14,
    primary: true,
    eventers: eventerStubs<"pressed">(["pressed"]),
    ...stylesStubs
  };

  handlePress = async () => {
    const { onPress, eventers, eventTargetName, eventProperties } = this.props;
    eventers.pressed(eventProperties, eventTargetName ?? undefined);
    Keyboard.dismiss();
    await onPress();
  };

  get buttonStyle() {
    const {
      disabled,
      buttonStyle: buttonStyleProp,
      color,
      isInactive,
      primary,
      secondary,
      styles,
      theme
    } = this.props;
    const buttonStyle = [];
    if (primary && !secondary) {
      buttonStyle.push(styles.primary);
    }
    if (secondary) {
      buttonStyle.push(styles.secondary);
    }
    if (typeof color === "string") {
      buttonStyle.push({ backgroundColor: theme.color.getColor(color) });
    }
    if (buttonStyleProp) {
      buttonStyle.push(buttonStyleProp);
    }
    if (disabled) {
      buttonStyle.push(styles.disabled);
    }
    if (isInactive) {
      buttonStyle.push(styles.inactive);
    }
    return buttonStyle;
  }

  get textStyle() {
    const {
      isInactive,
      primary,
      secondary,
      styles,
      textColor,
      textStyle: textStyleProp,
      activeTextStyle,
      theme
    } = this.props;
    const textStyle = [];
    if (primary && !secondary) {
      textStyle.push(styles.primaryText);
    }
    if (secondary) {
      textStyle.push(styles.secondaryText);
    }
    if (typeof textColor === "string") {
      textStyle.push({ color: theme.color.getColor(textColor) });
    }
    if (textStyleProp) {
      textStyle.push(textStyleProp);
    }
    if (!isInactive && !!activeTextStyle) textStyle.push(activeTextStyle);
    return textStyle.length > 0 ? textStyle : undefined;
  }

  shouldComponentUpdate(nextProps: Props) {
    return (
      // Shallow Checks
      nextProps.color !== this.props.color ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.title !== this.props.title ||
      nextProps.isInactive !== this.props.isInactive ||
      nextProps.primary !== this.props.primary ||
      nextProps.secondary !== this.props.secondary ||
      nextProps.textColor !== this.props.textColor ||
      nextProps.onPress !== this.props.onPress || // Deep Checks
      !isEqual(nextProps.buttonStyle, this.props.buttonStyle) ||
      !isEqual(nextProps.style, this.props.style) ||
      !isEqual(nextProps.textStyle, this.props.textStyle)
    );
  }

  render() {
    const {
      style,
      styles,
      title,
      disabled,
      IconComponent: IconComponentProps,
      icon,
      testID,
      rightIcon,
      iconStyle,
      // added nonTouchable because disabled and isInactive change the styles
      notTouchable
    } = this.props;

    // worker profile remove buttons use icon without IconComponent
    const IconComponent =
      (Boolean(icon) || Boolean(rightIcon)) && !IconComponentProps
        ? StyledIcon
        : IconComponentProps;

    return (
      <TouchableOpacity
        testID={testID ?? "styled-bubble"}
        style={[styles.button, this.buttonStyle, style]}
        onPress={this.handlePress}
        disabled={!!disabled || !!notTouchable}
      >
        {!!IconComponent && !!icon && (
          <IconComponent
            style={[styles.iconComponent, iconStyle]}
            {...(icon as any)}
          />
        )}
        <StyledText
          numberOfLines={1}
          style={this.textStyle}
          fontSize={this.props.size}
        >
          {title}
        </StyledText>
        {!!IconComponent && !!rightIcon && (
          <IconComponent
            style={styles.rightIconComponent}
            {...(rightIcon as any)}
          />
        )}
      </TouchableOpacity>
    );
  }
}
