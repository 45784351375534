import { duration } from "@gigsmart/isomorphic-shared/iso";
import { useMemo } from "react";

export default function useMileageIsValid(
  gigEstimatedMileage?: number | null,
  gigDuration?: string | null
) {
  return useMemo(() => {
    if (gigDuration === "PT0S" && gigEstimatedMileage === 0) return true;
    if (!gigEstimatedMileage) return false;
    const durationInHours = duration.toHours(gigDuration);
    const mph = gigEstimatedMileage / durationInHours;
    return mph <= 75;
  }, [gigEstimatedMileage, gigDuration]);
}
