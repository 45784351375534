import {
  Button,
  type Color,
  ContentArea,
  type HighlightedStatement,
  type IconName,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { useFormSubmit } from "@gigsmart/fomu";
import React, { type ReactElement } from "react";

export interface Props {
  label: string;
  icon?: IconName;
  color?: Color;
  infoText?:
    | string
    | ReactElement<typeof Text>
    | ReactElement<typeof HighlightedStatement>;
  testID?: string;
}

export default function SubmitQuestionnaire({
  label,
  icon,
  color,
  infoText,
  testID
}: Props) {
  const { submit, invalid, submitting } = useFormSubmit();
  return (
    <ContentArea size="none">
      {typeof infoText === "string" ? (
        <Text align="center" testID={`${testID ?? "submit"}-info-text`}>
          {infoText}
        </Text>
      ) : (
        infoText
      )}
      {!!infoText && <Spacer />}
      <Button
        testID={testID ?? "submit"}
        color={color ?? "primary"}
        onPress={submit}
        disabled={invalid || submitting}
        label={label}
        icon={icon}
      />
      <Spacer />
    </ContentArea>
  );
}
