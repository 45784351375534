import type { Value } from "./value";

export class FunctionDefinition<
  T extends string = string,
  A extends Value[] = Value[]
> {
  constructor(
    public name: T,
    public args: A
  ) {}
}
