import * as React from "react";
import Svg, { Defs, Path, Rect } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

export default function ClockSvg() {
  return (
    <Svg
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      height="100%"
      width="100%"
    >
      <Defs />
      <Path
        fill="none"
        d="M50,71.468A28.60547,28.60547,0,1,0,21.39453,42.86249,28.63807,28.63807,0,0,0,50,71.468Zm2.43732-55.27661A23.44434,23.44434,0,1,1,28.993,39.63568,23.44434,23.44434,0,0,1,52.43732,16.19135Z"
      />
      <Rect
        fill="#e7e7e7"
        x={0.21912}
        y={88.18378}
        width={99.56177}
        height={11.81622}
        rx={2.0257}
      />
      <Path
        fill="#f5c580"
        d="M52.43732,63.08A23.44434,23.44434,0,1,0,28.993,39.63568,23.44435,23.44435,0,0,0,52.43732,63.08ZM48.5,21.10175a1.5,1.5,0,1,1,3,0V42.03094L61,47.966a1.49994,1.49994,0,1,1-1.58984,2.544L49.20508,44.13446a1.50013,1.50013,0,0,1-.70508-1.272Z"
      />
      <Path
        fill="#8a8c8e"
        d="M50,74.468A31.60547,31.60547,0,1,0,18.39453,42.86249,31.64136,31.64136,0,0,0,50,74.468ZM50,14.257A28.60547,28.60547,0,1,1,21.39453,42.86249,28.63807,28.63807,0,0,1,50,14.257Z"
      />
      <Path
        fill="#8a8c8e"
        d="M49.20508,44.13446,59.41016,50.51A1.49994,1.49994,0,0,0,61,47.966l-9.5-5.93506V21.10175a1.5,1.5,0,1,0-3,0V42.86249A1.50013,1.50013,0,0,0,49.20508,44.13446Z"
      />
    </Svg>
  );
}
