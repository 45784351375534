import {
  GoogleMapCircle,
  GoogleMapMarker,
  GoogleMapPolygon
} from "@gigsmart/atorasu";
import React from "react";
import type { GeofenceValue } from "./helpers";

interface Props {
  center?: GeofenceValue["center"];
  radius?: GeofenceValue["radius"];
  paths?: GeofenceValue["paths"];
}

export default function EditGeofenceShape({ center, radius, paths }: Props) {
  const showPolygon = (paths?.length ?? 0) > 0;
  if (!center || (!radius && !paths)) return null;
  return (
    <>
      <GoogleMapMarker center={center ?? center} />
      {showPolygon ? (
        <GoogleMapPolygon paths={paths ?? []} />
      ) : (
        <GoogleMapCircle center={center} radius={radius ?? 0} />
      )}
    </>
  );
}
