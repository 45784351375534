import { type IconName, Spacer } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { DataRow, DataRowCard, StyledView } from "@gigsmart/katana";
import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import groupBy from "lodash/groupBy";
import React from "react";
import {
  type PayableAccount,
  formatPaymentMethod,
  todayOrFutureDate
} from "../helpers";
import type {
  pendingTransfers_worker$data,
  pendingTransfers_worker$key
} from "./__generated__/pendingTransfers_worker.graphql";

type PayoutNode = ObjectPath<
  pendingTransfers_worker$data,
  ["payouts", "edges", 0, "node"]
>;

interface Props {
  worker: pendingTransfers_worker$key | null | undefined;
}

const CARD_DETAILS = {
  INSTANT: {
    iconName: "bolt" as IconName,
    label: "Rapid Transfer",
    testID: "rapid-pending-card"
  },
  STANDARD: {
    iconName: "envelope-open-dollar" as IconName,
    label: "Standard Transfer",
    testID: "standard-pending-card"
  }
} as const;

const PendingTransfers = ({ worker: workerFragmentRef }: Props) => {
  const pendingResults = useRelayFragment(
    graphql`
      fragment pendingTransfers_worker on Worker {
        payouts(
          query: "WHERE status = PENDING ORDER BY insertedAt ASC"
          first: 30
        ) {
          edges {
            node {
              id
              type
              amount
              providerDetails {
                arrivesAt
                method
                destination {
                  ... on BankAccount {
                    bankName
                  }
                  ... on BankCard {
                    brand
                  }
                  last4
                  __typename
                }
              }
            }
          }
        }
      }
    `,
    workerFragmentRef
  );

  const groups = groupBy(getConnectionNodes(pendingResults?.payouts), "type");
  return (
    <>
      {groups.INSTANT && renderCardGroup("INSTANT", groups.INSTANT)}
      {groups.STANDARD && renderCardGroup("STANDARD", groups.STANDARD)}
    </>
  );
};

export default PendingTransfers;

//

const renderCardGroup = (
  type: keyof typeof CARD_DETAILS,
  data: PayoutNode[]
) => {
  if (!data?.length) return null;
  return (
    <StyledView color="transparent" verticalPadding={0}>
      <DataRowCard {...CARD_DETAILS[type]}>
        {data.map((node, index) => (
          <DataRow
            key={node?.id ?? index}
            showSpace
            label={formatAccountInfo(node?.providerDetails?.destination)}
            secondaryTitle={`Estimated Arrival ${todayOrFutureDate(
              node?.providerDetails?.arrivesAt
            )} `}
            data={currency.humanize(node?.amount)}
            boldData
          />
        ))}
      </DataRowCard>
      <Spacer />
    </StyledView>
  );
};

const formatAccountInfo = (destination: PayableAccount | null | undefined) => {
  if (!destination) return "In Transit";
  return `In transit to ${formatPaymentMethod(destination)}`;
};
