import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { createContext, useContext, type ReactNode } from "react";
import type {
  useGigData$data,
  useGigData$key
} from "./__generated__/useGigData.graphql";
import { GigSeriesDataProvider } from "./useGigSeriesData";

export type GigData = useGigData$data;
const GigContext = createContext<GigData | null | undefined>(null);

export function GigDataProvider({
  children,
  gig: gigRef
}: {
  children: ReactNode;
  gig: useGigData$key | null | undefined;
}) {
  const gig = useRelayFragment(
    graphql`
      fragment useGigData on Gig {
        name
        currentState {
          name
        }
        gigSeries {
          ...useGigSeriesData
        }
      }
    `,
    gigRef
  );
  return (
    <GigSeriesDataProvider gigSeries={gig?.gigSeries ?? null}>
      <GigContext.Provider value={gig}>{children}</GigContext.Provider>
    </GigSeriesDataProvider>
  );
}

export const useGigData = () => useContext(GigContext);
