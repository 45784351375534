import { Modal, Pressable, Stack, Text } from "@gigsmart/atorasu";
import React from "react";
import { Linking } from "react-native";
import { formatPhoneNumber } from "../app/phone";

interface Props {
  usersName: string | null | undefined;
  calleePhoneNumber: string | null | undefined;
  callerPhoneNumber: string | null | undefined;
  showModal: boolean;
  userType: "Requester" | "Worker";
  hideModal: () => any;
}

const PhoneModal = ({
  usersName,
  calleePhoneNumber,
  callerPhoneNumber,
  showModal,
  userType,
  hideModal
}: Props) => {
  const calleeNumber =
    calleePhoneNumber && formatPhoneNumber(calleePhoneNumber);
  const callerNumber =
    callerPhoneNumber && formatPhoneNumber(callerPhoneNumber);
  return (
    <Modal
      eventContext="Phone Modal"
      visible={showModal}
      onClose={hideModal}
      hasCancelIcon
      title={`Call ${userType}`}
    >
      <Stack>
        <Text align="center">{usersName}</Text>
        <Pressable
          testID="phone-number-link"
          eventEntityType="PhoneModal"
          eventTargetName="phoneNumber"
          onPress={async () => {
            if (await Linking.canOpenURL(`tel:${calleeNumber}`)) {
              return await Linking.openURL(`tel:${calleeNumber}`);
            }
          }}
        >
          <Text variant="title" align="center">
            {calleeNumber}
          </Text>
        </Pressable>
        <Text variant="note" align="center">
          You must call via your number{"\n"}
          <Text weight="bold"> {callerNumber} </Text>
          for the call to go through.
        </Text>
      </Stack>
    </Modal>
  );
};

export default PhoneModal;
