import React from "react";

import {
  Button,
  Column,
  ContentArea,
  IconCircle,
  Text
} from "@gigsmart/atorasu";
import { PAYMENT_TYPES } from "@gigsmart/isomorphic-shared/gig/payment-types";
import InfoModal from "@gigsmart/isomorphic-shared/modal/info-modal";

interface Props {
  visible: boolean;
  onClose: () => void;
  paymentType:
    | "CASH"
    | "CHECK"
    | "DIGITAL_TRANSFER"
    | "IN_APP"
    | "NO_PREFERENCE"
    | "%future added value";
}

export default function HowYoullGetPaidModal({
  visible,
  onClose,
  paymentType
}: Props) {
  return (
    <InfoModal
      visible={visible}
      onClose={onClose}
      eventContext="HowYoullGetPaidModal"
    >
      <Text variant="header" align="center" color="primary">
        How You'll Get Paid
      </Text>
      <Column alignItems="center">
        <ContentArea size="compact">
          <IconCircle
            icon={PAYMENT_TYPES[paymentType].icon}
            size="large"
            color="input"
          />
        </ContentArea>
      </Column>
      {PAYMENT_TYPES[paymentType].workerInfoText}
      <Button label="Got It" testID="close-modal-btn" onPress={onClose} />
    </InfoModal>
  );
}
