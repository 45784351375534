/**
 * @generated SignedSource<<86ff594b6bb2f906a6faed2a2fbf81e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DisputeTimesheetCard_EngagementTimesheet$data = {
  readonly disputedFinalTimesheet: {
    readonly diff: {
      readonly basePayment: string;
      readonly billableDuration: string;
      readonly totalWorkerServiceFeeAmount: string;
    } | null | undefined;
    readonly engagementTimesheet: {
      readonly paymentInfo: {
        readonly additionalPay: string;
        readonly billableDuration: string;
      } | null | undefined;
    };
  } | null | undefined;
  readonly id: string;
  readonly paymentInfo: {
    readonly additionalPay: string;
    readonly billableDuration: string;
    readonly payRate: string;
    readonly serviceFees: ReadonlyArray<{
      readonly amount: string;
      readonly feeType: EngagementAddonFeeType;
      readonly hourlyRate: string | null | undefined;
    }>;
  } | null | undefined;
  readonly states: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly action: EngagementStateAction;
        readonly id: string;
        readonly name: EngagementStateName;
        readonly negotiation: {
          readonly payRate: string | null | undefined;
          readonly submittedBy: {
            readonly __typename: string;
            readonly displayName: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly overridesState?: {
          readonly id: string;
        } | null | undefined;
        readonly transitionedAt: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly timezone: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
  readonly " $fragmentType": "DisputeTimesheetCard_EngagementTimesheet";
};
export type DisputeTimesheetCard_EngagementTimesheet$key = {
  readonly " $data"?: DisputeTimesheetCard_EngagementTimesheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisputeTimesheetCard_EngagementTimesheet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalPay",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisputeTimesheetCard_EngagementTimesheet",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "overridden",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "TimesheetList_timesheet"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerServiceFee",
          "kind": "LinkedField",
          "name": "serviceFees",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hourlyRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        },
        {
          "kind": "Literal",
          "name": "overridden",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "ORDER BY transitionedAt ASC"
        }
      ],
      "concreteType": "EngagementStateOrOverridesConnection",
      "kind": "LinkedField",
      "name": "states",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementStateOrOverridesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "action",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "transitionedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementNegotiation",
                  "kind": "LinkedField",
                  "name": "negotiation",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "submittedBy",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EngagementState",
                      "kind": "LinkedField",
                      "name": "overridesState",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "EngagementStateOverride",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "states(first:50,overridden:true,query:\"ORDER BY transitionedAt ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetComparison",
      "kind": "LinkedField",
      "name": "disputedFinalTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetDiff",
          "kind": "LinkedField",
          "name": "diff",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "basePayment",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWorkerServiceFeeAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheet",
          "kind": "LinkedField",
          "name": "engagementTimesheet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementPaymentInfo",
              "kind": "LinkedField",
              "name": "paymentInfo",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
};
})();

(node as any).hash = "45ca58bb851742c991239f587c1e5300";

export default node;
