import React, { type ReactNode } from "react";

import {
  Checkbox,
  Column,
  ContentArea,
  Divider,
  Pressable,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";

interface Props {
  title: ReactNode;
  description: string;
  border?: boolean;
  checked: boolean;
  disabled?: boolean;
  onPress?: () => void;
  testID: string;
}

export default function OptInRow({
  title,
  description,
  border,
  checked,
  disabled,
  onPress,
  testID
}: Props) {
  return (
    <>
      <Pressable
        onPress={onPress}
        testID={`${testID}-row`}
        eventEntityType="row"
        eventTargetName={`${title} Opt-In Row`}
      >
        <ContentArea>
          <Row justifyContent="space-between">
            <Column fill>
              <Text color="primary" weight="bold">
                {title}
              </Text>
              <Text>{description}</Text>
            </Column>
            <Spacer horizontal />
            <Column justifyContent="center">
              <Checkbox
                testID={testID}
                selected={checked}
                disabled={disabled}
                onChange={onPress}
              />
            </Column>
          </Row>
        </ContentArea>
      </Pressable>
      {border && <Divider />}
    </>
  );
}
