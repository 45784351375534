import {
  ActivityIndicator,
  ContentArea,
  type ModalProps,
  showModal,
  useModalContext
} from "@gigsmart/atorasu";
import { ResetRelayOrchestrator } from "@gigsmart/relay";
import React, { useMemo, Suspense, useCallback } from "react";
import ErrorBoundary from "./ErrorBoundary";
import type { AppletContract } from "./createApplet";
import { useApplet } from "./fetchApplet";

function toAppletContent(
  { content }: AppletContract["__gigsmart_applet__"],
  props: Record<string, unknown>
) {
  const AppletContent = (props: Record<string, unknown>) => {
    const { onRequestClose } = useModalContext();
    const renderedContent = useMemo(
      () =>
        content({
          isOpen: true,
          toggle: () => onRequestClose?.(),
          ...props
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(props)]
    );

    return <ResetRelayOrchestrator>{renderedContent}</ResetRelayOrchestrator>;
  };

  return () => <AppletContent {...props} />;
}

interface Props extends Pick<ModalProps, "title" | "dismissable"> {
  url: string;
  componentProps: Record<string, unknown>;
}

function AppletModal({ url, componentProps }: Props) {
  const handleApplet = useCallback(
    (applet: AppletContract["__gigsmart_applet__"]) =>
      toAppletContent(applet, componentProps),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(componentProps)]
  );

  const Component = useApplet(url, handleApplet, []);

  return (
    <ErrorBoundary url={String(url)}>
      <Suspense
        fallback={
          <ContentArea
            size="compact"
            alignItems="center"
            justifyContent="center"
          >
            <ActivityIndicator />
          </ContentArea>
        }
      >
        <Component />
      </Suspense>
    </ErrorBoundary>
  );
}

export function showAppletModal({
  url,
  componentProps,
  ...modalProps
}: Props & Pick<ModalProps, "title" | "dismissable">) {
  return showModal({
    ...modalProps,
    eventContext: null,
    children: <AppletModal url={url} componentProps={componentProps} />
  });
}
