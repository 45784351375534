/**
 * @generated SignedSource<<0dc5e164465b00b6711450b4f2ec1a49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkerPayoutType = "INSTANT" | "STANDARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type pendingTransfers_worker$data = {
  readonly payouts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amount: string;
        readonly id: string;
        readonly providerDetails: {
          readonly arrivesAt: string | null | undefined;
          readonly destination: {
            readonly __typename: string;
            readonly bankName?: string;
            readonly brand?: string;
            readonly last4: string;
          } | null | undefined;
          readonly method: string;
        };
        readonly type: WorkerPayoutType;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "pendingTransfers_worker";
};
export type pendingTransfers_worker$key = {
  readonly " $data"?: pendingTransfers_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"pendingTransfers_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pendingTransfers_worker",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 30
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status = PENDING ORDER BY insertedAt ASC"
        }
      ],
      "concreteType": "WorkerPayoutsConnection",
      "kind": "LinkedField",
      "name": "payouts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerPayoutsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerPayout",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorkerPayoutProviderDetails",
                  "kind": "LinkedField",
                  "name": "providerDetails",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "arrivesAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "method",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "destination",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "bankName",
                              "storageKey": null
                            }
                          ],
                          "type": "BankAccount",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "brand",
                              "storageKey": null
                            }
                          ],
                          "type": "BankCard",
                          "abstractKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "last4",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "payouts(first:30,query:\"WHERE status = PENDING ORDER BY insertedAt ASC\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "630374e7d14f40bdffc6bc2deddd805d";

export default node;
