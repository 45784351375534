import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import React, { Component } from "react";
import { Keyboard, TouchableOpacity } from "react-native";
import { type IconName, StyledIcon } from "../icon";
import {
  type StylesProps,
  type TextStyleProp,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import StyledText from "../text/styled-text";

import Hoverable from "./hoverable";

type Props = StylesProps &
  EventerProps<"pressed"> & {
    color?: string;
    disabled?: boolean;
    onPress: () => void;
    primary?: boolean;
    secondary?: boolean;
    showIcon?: boolean;
    iconName?: IconName;
    style?: ViewStyleProp;
    testID?: string;
    title: string;
    bold?: boolean;
    textStyle?: TextStyleProp;
  };
@withStyles(({ color, font }) => ({
  button: {
    alignItems: "center",
    backgroundColor: color.white,
    borderRadius: 25,
    flexDirection: "row",
    height: 50,
    justifyContent: "center"
  },
  disabled: {
    backgroundColor: color.neutralLight,
    borderColor: color.neutralDark
  },
  disabledText: {
    color: color.neutralDark
  },
  icon: {
    fontSize: font.size.extraLarge,
    marginRight: 8
  },
  primary: {
    backgroundColor: color.orange,
    borderColor: color.orange,
    borderWidth: 0,
    borderStyle: "solid"
  },
  hoverPrimary: {
    backgroundColor: color.darken(color.orange, 0.1),
    borderColor: color.darken(color.orange, 0.1)
  },
  primaryText: {
    color: color.white,
    fontSize: font.size.medium
  },
  secondary: {
    borderColor: color.orange,
    backgroundColor: color.white
  },
  hoverSecondary: {
    backgroundColor: color.withOpacity(color.blue, 0.1),
    borderColor: color.withOpacity(color.blue, 0.1),
    borderWidth: 1
  },
  secondaryText: {
    color: color.orange,
    fontSize: font.size.medium
  }
}))
@withEventers<"pressed", Props>("Button", ["pressed"], "title")
export default class StyledButtonRounded extends Component<Props> {
  static defaultProps = {
    primary: true,
    bold: true,
    eventers: eventerStubs<"pressed">(["pressed"]),
    ...stylesStubs
  };

  render() {
    const {
      theme,
      styles,
      testID,
      style,
      textStyle: textStyleProp,
      primary,
      secondary,
      disabled,
      color,
      title,
      bold
    } = this.props;
    // Button label styles
    const textStyles = [
      primary && !secondary && styles.primaryText,
      secondary && styles.secondaryText,
      typeof color === "string" && { color: theme.color.getColor(color) },
      disabled && styles.disabledText,
      textStyleProp
    ];

    // Button container styles (generator)
    const buttonStyles = (hover: boolean) => [
      styles.button,
      primary && !secondary && (hover ? styles.hoverPrimary : styles.primary),
      secondary && (hover ? styles.hoverSecondary : styles.secondary),
      typeof color === "string" && { borderColor: theme.color.getColor(color) },
      disabled && styles.disabled,
      style
    ];

    return (
      <Hoverable>
        {(hover) => (
          <TouchableOpacity
            style={buttonStyles(hover)}
            testID={testID}
            onPress={this._handlePress}
          >
            {this._renderIcon(textStyles)}
            <StyledText bold={bold} style={textStyles}>
              {title}
            </StyledText>
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  }

  _handlePress = () => {
    const { onPress, eventers } = this.props;
    eventers.pressed();
    Keyboard.dismiss();
    onPress();
  };

  _renderIcon = (textStyles: TextStyleProp) => {
    const { styles, primary, secondary, showIcon, iconName } = this.props;
    if (!showIcon) return null;
    let name = iconName;
    if (typeof name === "undefined") {
      if (secondary) name = "ban";
      else if (primary) name = "check";
    }
    if (typeof name === "undefined") return null;

    return (
      <StyledIcon
        name={name}
        variant="solid"
        style={[textStyles, styles.icon]}
      />
    );
  };
}
