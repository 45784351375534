import {
  Column,
  ContentArea,
  Divider,
  IconText,
  Row,
  Spacer,
  Stack,
  Surface,
  Text,
  Well,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React from "react";

import AdminCallRecordings from "./AdminCallRecordings";
import AdminMoreMenu from "./AdminMoreMenu";
import Participants from "./Participants";
import ShiftInfo from "./RequesterConversationHeader/ShiftInfo";
import WorkerInfo from "./RequesterConversationHeader/WorkerInfo";
import type { AdminConversationHeader_conversationLike$key } from "./__generated__/AdminConversationHeader_conversationLike.graphql";

interface Props {
  editing: boolean;
  setEditing: (editing: boolean) => void;
}

export function AdminConversationHeader({
  type,
  id,
  engagement,
  worker: orgReqWorker,
  conversationParticipants: participants,
  organization,
  editing,
  setEditing,
  result
}: FragmentContainerInnerComponentProps<
  AdminConversationHeader_conversationLike$key,
  Props
>) {
  const isSmUp = useMatchesViewport(({ size }) => size.small.up);
  const worker = orgReqWorker ?? engagement?.worker;
  const showAsWorker = false; // location.pathname.includes("/workers");

  if (!result) return null;

  return (
    <Surface topRadius="none" bottomRadius="none">
      {type === "ShiftGroupConversation" && (
        <Well color="primary">
          <IconText
            spacing="medium"
            icon="messages"
            color="primary"
            size="small"
          >
            <Text weight="bold" color="primary">
              Shift Group Chat •{" "}
              <Text weight="normal" color="primary">
                {((participants?.totalCount ?? 0) as number) + 1} Participants
              </Text>
            </Text>
          </IconText>
        </Well>
      )}
      <ContentArea
        size={type === "ShiftGroupConversation" ? "standard" : "compact"}
      >
        <Row>
          <Stack horizontal alignItems="center" justifyContent="center" fill>
            <Stack
              horizontal
              fill
              alignItems="center"
              justifyContent="flex-start"
            >
              <Column fill justifyContent="center">
                {(type === "WorkerOrganizationConversation" ||
                  type === "EngagementConversation") && (
                  <WorkerInfo
                    worker={worker}
                    hasManyOrgs={false}
                    organization={organization}
                  />
                )}
                {type === "ShiftGroupConversation" && (
                  <ShiftInfo fragmentRef={result ?? null} />
                )}
              </Column>
            </Stack>
            {id && (
              <>
                <Divider dir="y" fullWidth />
                <Spacer horizontal />
                <AdminCallRecordings conversationId={id} />
              </>
            )}
            <Divider dir="y" fullWidth />
            <Participants fragmentRef={result} showAsWorker={showAsWorker} />
            {isSmUp && <Divider dir="y" fullWidth />}
            {isSmUp && <Spacer size="none" horizontal />}
          </Stack>
          <Row alignItems="center">
            <AdminMoreMenu
              editing={editing}
              setEditing={setEditing}
              fragmentRef={result ?? null}
            />
          </Row>
        </Row>
      </ContentArea>
      <Spacer size="compact" />
      <Divider />
    </Surface>
  );
}

export default createRelayFragmentContainer<
  AdminConversationHeader_conversationLike$key,
  Props
>(
  graphql`
    fragment AdminConversationHeader_conversationLike on ConversationLike
    @argumentDefinitions {
      type: __typename
      ...ShiftInfo_conversation
      ...AdminMoreMenu_conversationLike
      ...Participants_conversation @arguments(isWorker: false)
      conversationParticipants: participants(first: 0) {
        totalCount
      }
      ... on ShiftGroupConversation {
        id
      }
      ... on EngagementConversation {
        id
        engagement {
          gig {
            id
            requester {
              id
              displayName
              profilePhoto {
                url
              }
              primaryMobile {
                number
              }
            }
          }
          worker {
            id
            displayName
            profilePhoto {
              url
            }
            isFavorite
            groupAssociations(first: 0) {
              totalCount
            }
          }
        }
      }
      ... on OrganizationWorkerConversation {
        id
        worker {
          id
          displayName
          profilePhoto {
            url
          }
          isFavorite
          groupAssociations(first: 0) {
            totalCount
          }
        }
        organization {
          id
          name
          logoUrl
          primaryContact {
            id
            primaryMobile {
              number
            }
          }
        }
        engagements(
          first: 50
          query: "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
        ) {
          edges {
            node {
              contactNumber
              gig {
                primaryContact {
                  id
                  displayName
                }
              }
              capabilities {
                type
                restrictedBy {
                  message
                }
                expiresAt
                status
              }
            }
          }
        }
      }
    }
  `,
  AdminConversationHeader
);
