import { toast } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  graphql,
  useFetchQueryResult,
  useRelayFragment,
  useRelayMutation
} from "@gigsmart/relay";
import fm from "front-matter";
import { titleize } from "inflected";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { consent } from ".";
import type { useLegalDocumentMutation } from "./__generated__/useLegalDocumentMutation.graphql";
import type { useLegalDocumentQuery } from "./__generated__/useLegalDocumentQuery.graphql";
import type { useLegalDocument_document$key } from "./__generated__/useLegalDocument_document.graphql";

export interface UseLegalDocumentOptions {
  defaultTitle?: string;
  defaultPrompt?: string;
  defaultDescription?: string;
  userId?: string;
}

export function useLegalDocument(
  filename: string,
  options?: UseLegalDocumentOptions
) {
  const userId = options?.userId ?? useCurrentUser()?.id ?? "";

  const [result] = useFetchQueryResult<useLegalDocumentQuery>(
    graphql`
      query useLegalDocumentQuery($filename: String!, $userId: ID!) {
        userWithConsent: node(id: $userId) {
          ... on User {
            consentStatus(filename: $filename) {
              consent {
                consentedAt
                consentedIp
                ...LegalDocument_consent
              }
              legalDocument {
                ...useLegalDocument_document
                ...LegalDocument_document
              }
            }
          }
        }
      }
    `,
    { variables: { filename, userId } }
  );
  return {
    document: result?.userWithConsent?.consentStatus?.legalDocument,
    consent: result?.userWithConsent?.consentStatus?.consent,
    consentedIp: result?.userWithConsent?.consentStatus?.consent?.consentedIp,
    ...useLegalDocumentData(
      result?.userWithConsent?.consentStatus?.legalDocument,
      options
    )
  };
}

export function useLegalDocumentData(
  document?: useLegalDocument_document$key | null,
  {
    defaultTitle = "Legal Notice",
    defaultPrompt = "I agree to the terms.",
    defaultDescription
  }: UseLegalDocumentOptions = {}
) {
  const disclaimerDoc = useRelayFragment(
    graphql`
      fragment useLegalDocument_document on LegalDocument {
        format
        source: body
        filename
      }
    `,
    document ?? null
  );
  const [signDisclaimer] = useRelayMutation<useLegalDocumentMutation>(graphql`
    mutation useLegalDocumentMutation($input: AddUserDocumentConsentInput!) {
      addUserDocumentConsent(input: $input) {
        userConsentDocument {
          filename
        }
      }
    }
  `);
  const { source = "", filename = "" } = disclaimerDoc || {};
  const sign = useCallback(
    async (onComplete: () => void) => {
      signDisclaimer(
        {
          input: {
            filename,
            consentSignature: await consent.sign(source)
          }
        },
        {
          onSuccess: onComplete,
          onPayloadErrors: (errors) => {
            const error = errors
              .map((error) =>
                compact([titleize(error.field ?? ""), error.message]).join(" ")
              )
              .join(", ");
            toast.error(error);
          }
        }
      );
    },
    [filename, signDisclaimer, source]
  );

  const { attributes, body } = useMemo(
    () =>
      fm<{
        prompt?: string | string[];
        title?: string;
        description?: string;
        preamble?: string;
      }>(source),
    [source]
  );

  const prompt = attributes?.prompt ?? defaultPrompt;
  const title = attributes?.title ?? defaultTitle;
  const description = attributes?.description ?? defaultDescription;
  const preamble = attributes?.preamble;
  const prompts = Array.isArray(prompt) ? prompt : [prompt];
  const format = disclaimerDoc?.format ?? "unknown";

  return {
    source,
    filename,
    prompts,
    title,
    description,
    preamble,
    body,
    format,
    sign
  };
}
