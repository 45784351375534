/**
 * @generated SignedSource<<c9d817cc32e0a38055f8ecb6f22c1a93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerPositionCheck_worker$data = {
  readonly activeEngagements: {
    readonly totalCount: number;
  } | null | undefined;
  readonly activeEngagementsAndOffers: {
    readonly totalCount: number;
  } | null | undefined;
  readonly bans: {
    readonly totalCount: number;
  } | null | undefined;
  readonly id: string;
  readonly preactiveEngagements: {
    readonly totalCount: number;
  } | null | undefined;
  readonly workerPositions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPositionCheckModal_Worker">;
  readonly " $fragmentType": "WorkerPositionCheck_worker";
};
export type WorkerPositionCheck_worker$key = {
  readonly " $data"?: WorkerPositionCheck_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPositionCheck_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "_in": [
    "ACTIVE",
    "IN_PROGRESS"
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "workerPositions"
        ]
      }
    ]
  },
  "name": "WorkerPositionCheck_worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "activeEngagementsAndOffers",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_in": [
                "SCHEDULED",
                "EN_ROUTE",
                "AWAITING_START",
                "WORKING",
                "PAUSED",
                "OFFERED"
              ]
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"SCHEDULED\",\"EN_ROUTE\",\"AWAITING_START\",\"WORKING\",\"PAUSED\",\"OFFERED\"]}})"
    },
    {
      "alias": "preactiveEngagements",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_in": [
                "SCHEDULED",
                "EN_ROUTE",
                "AWAITING_START"
              ]
            },
            "gigStateName": (v4/*: any*/)
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"SCHEDULED\",\"EN_ROUTE\",\"AWAITING_START\"]},\"gigStateName\":{\"_in\":[\"ACTIVE\",\"IN_PROGRESS\"]}})"
    },
    {
      "alias": "activeEngagements",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_in": [
                "WORKING",
                "PAUSED"
              ]
            },
            "gigStateName": (v4/*: any*/)
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"WORKING\",\"PAUSED\"]},\"gigStateName\":{\"_in\":[\"ACTIVE\",\"IN_PROGRESS\"]}})"
    },
    {
      "alias": "bans",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE expiresAt = NULL AND removedAt = NULL"
        }
      ],
      "concreteType": "UserRestrictionsConnection",
      "kind": "LinkedField",
      "name": "restrictions",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "restrictions(first:0,query:\"WHERE expiresAt = NULL AND removedAt = NULL\")"
    },
    {
      "alias": "workerPositions",
      "args": [
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status = CONFIRMED"
        }
      ],
      "concreteType": "WorkerPositionsConnection",
      "kind": "LinkedField",
      "name": "__WorkerPositionCheck_workerPositions_connection",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerPositionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigPosition",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__WorkerPositionCheck_workerPositions_connection(query:\"WHERE status = CONFIRMED\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerPositionCheckModal_Worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "d1d0de388ae9d2d66d80e16d2010b91b";

export default node;
