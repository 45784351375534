/**
 * @generated SignedSource<<87d437dc1d6d2b35d972047e6092a474>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiDaySelectGigsStep_gig$data = {
  readonly actualStartsAt: string | null | undefined;
  readonly area: string | null | undefined;
  readonly endsAt: string | null | undefined;
  readonly id: string;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
  readonly " $fragmentType": "MultiDaySelectGigsStep_gig";
};
export type MultiDaySelectGigsStep_gig$key = {
  readonly " $data"?: MultiDaySelectGigsStep_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_gig">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "MultiDaySelectGigsStep_gig"
};

(node as any).hash = "1b23886c0d977ad40f63b52cb3d46da4";

export default node;
