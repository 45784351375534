import React from "react";

import { useStyles } from "@gigsmart/atorasu/style";
import { asEnum, where } from "@gigsmart/biruda";
import { GigCard } from "@gigsmart/isomorphic-shared/gig";
import { InfiniteList } from "@gigsmart/isomorphic-shared/list";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";

import type { pendingTimesheetApprovalScreenQueryWorker } from "./__generated__/pendingTimesheetApprovalScreenQueryWorker.graphql";
import type { pendingTimesheetApprovalScreenWorkerQuery } from "./__generated__/pendingTimesheetApprovalScreenWorkerQuery.graphql";
import type { pendingTimesheetApprovalScreenWorker_worker$key } from "./__generated__/pendingTimesheetApprovalScreenWorker_worker.graphql";

const engagementsQuery: string = where({
  currentStateName: asEnum("PENDING_TIMESHEET_APPROVAL")
})
  .orderBy("endsAt", "ASC")
  .toString();

const fragmentSpec = graphql`
  fragment pendingTimesheetApprovalScreenWorker_worker on Worker
  @refetchable(queryName: "pendingTimesheetApprovalScreenQueryWorker")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 15 }
    after: { type: "String" }
    query: { type: "String" }
  ) {
    engagements(first: $count, after: $after, query: $query)
      @connection(key: "engagementsPendingApproval_engagements") {
      edges {
        node {
          id
          gig {
            name
            startsAt
            endsAt
            place {
              streetAddress
            }
            reconcilesAt
            payRate
          }
          currentState {
            name
          }
        }
      }
      totalCount
    }
  }
`;

type EngagementEntry = NonNullable<
  ObjectPath<
    pendingTimesheetApprovalScreenWorker_worker$key,
    [" $data", "engagements", "edges", 0, "node"]
  >
>;

export default createSuspendedQueryContainer<pendingTimesheetApprovalScreenWorkerQuery>(
  function PendingTimesheetApprovalScreen({ response }) {
    const styles = useStyles(({ getUnits, getColor }) => ({
      header: {
        marginTop: getUnits(5),
        marginBottom: getUnits(2),
        color: getColor("primary", "fill")
      },
      scrollContent: {
        flexGrow: 1
      },
      footer: {
        marginTop: "auto",
        paddingVertical: getUnits(1)
      }
    }));

    // const gigSeriesCount = result?.viewer?.gigSeries?.totalCount ?? 0;

    return (
      <InfiniteList<
        pendingTimesheetApprovalScreenQueryWorker,
        pendingTimesheetApprovalScreenWorker_worker$key,
        EngagementEntry
      >
        contentContainerStyle={styles.scrollContent}
        parentRef={response?.viewer ?? null}
        fragmentInput={fragmentSpec}
        getData={(data) => getConnectionNodes(data?.engagements)}
        keyExtractor={(item, index) => item?.id || `${index}`}
        renderItem={({ item }) => <GigCard name={item.gig.name} />}
        renderEmptyView={() => null}
        testID="pending-timesheet-approval-list"
      />
    );
  },
  {
    query: graphql`
      query pendingTimesheetApprovalScreenWorkerQuery($query: String!) {
        viewer {
          ... on Worker {
            id
            ...pendingTimesheetApprovalScreenWorker_worker
              @arguments(query: $query)
          }
        }
      }
    `,
    variables: () => ({
      query: engagementsQuery
    })
  }
);
