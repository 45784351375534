/**
 * @generated SignedSource<<4282a117ad5b49d41cf89b56e05f6849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerInfoRow_worker$data = {
  readonly id: string;
  readonly overallRating: number;
  readonly reviewCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerThumbnail_worker">;
  readonly " $fragmentType": "WorkerInfoRow_worker";
};
export type WorkerInfoRow_worker$key = {
  readonly " $data"?: WorkerInfoRow_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerInfoRow_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerInfoRow_worker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerThumbnail_worker"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallRating",
      "storageKey": null
    },
    {
      "alias": "reviewCount",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        }
      ],
      "concreteType": "EngagementReviewsConnection",
      "kind": "LinkedField",
      "name": "reviewsReceived",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "reviewsReceived(first:0)"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "2433eb5379c433d93a84ddd71ddc6872";

export default node;
