/**
 * @generated SignedSource<<c848049cc0e666525e70ff2077e5bc6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QualificationCategories_gigFields$data = {
  readonly gigFields: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly availableDefinitions: {
          readonly totalCount: number;
        } | null | undefined;
        readonly iconName: string;
        readonly key: string;
        readonly selectedDefinitions: {
          readonly totalCount: number;
        } | null | undefined;
        readonly title: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "QualificationCategories_gigFields";
};
export type QualificationCategories_gigFields$key = {
  readonly " $data"?: QualificationCategories_gigFields$data;
  readonly " $fragmentSpreads": FragmentRefs<"QualificationCategories_gigFields">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QualificationCategories_gigFields",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "ORDER BY order ASC"
        }
      ],
      "concreteType": "GigFieldsConnection",
      "kind": "LinkedField",
      "name": "gigFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigFieldsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigField",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iconName",
                  "storageKey": null
                },
                {
                  "alias": "selectedDefinitions",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "query",
                      "value": "WHERE status = CONFIRMED"
                    }
                  ],
                  "concreteType": "GigFieldItemDefinitionsConnection",
                  "kind": "LinkedField",
                  "name": "itemDefinitions",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "itemDefinitions(first:0,query:\"WHERE status = CONFIRMED\")"
                },
                {
                  "alias": "availableDefinitions",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "query",
                      "value": "WHERE status IN [DENIED, UNKNOWN]"
                    }
                  ],
                  "concreteType": "GigFieldItemDefinitionsConnection",
                  "kind": "LinkedField",
                  "name": "itemDefinitions",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "itemDefinitions(first:0,query:\"WHERE status IN [DENIED, UNKNOWN]\")"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "gigFields(first:10,query:\"ORDER BY order ASC\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "ede9bab848963171ff089eedcd2d0002";

export default node;
