import { Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import SummaryRow from "./SummaryRow";
import type { GigPositionSummaryRow_gigLike$key } from "./__generated__/GigPositionSummaryRow_gigLike.graphql";

type Props = {};

export function GigPositionSummaryRow({
  position
}: FragmentContainerInnerComponentProps<
  GigPositionSummaryRow_gigLike$key,
  Props
>) {
  return (
    <SummaryRow
      testID="gig-position-summary-row"
      icon="box-check"
      field="gig_position_id"
      route="position"
      title="Position"
      missing={!position?.name}
    >
      <Text
        testID="summary-position"
        color={!position?.name ? "neutral" : undefined}
      >
        {position?.name ?? "No Selection"}
      </Text>
    </SummaryRow>
  );
}

export default createRelayFragmentContainer<
  GigPositionSummaryRow_gigLike$key,
  Props
>(
  graphql`
    fragment GigPositionSummaryRow_gigLike on GigLike {
      position {
        name
      }
    }
  `,
  GigPositionSummaryRow
);
