/**
 * @generated SignedSource<<ec3bb8a0496f031b7817fa80c1e4fcd1>>
 * @relayHash a6fe0882efb3b01b2da48cbdde3e560c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:fgkspHIGhtcjJ47uEpzSV5nXgssPMxRCgS-D0UhS8B0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeScreenQuery$variables = {
  hiredProjectsQuery: string;
  timeSheetReviewQuery: string;
  upcomingShiftsQuery: string;
};
export type homeScreenQuery$data = {
  readonly viewer: {
    readonly upcomingShiftCount?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"HiredProjectsCard_worker" | "PendingTimesheetsReviewCard_worker" | "ProfileCard_Worker" | "ShiftGroupChatHomeTabCard_shift" | "VerificationCenterCard_worker">;
  } | null | undefined;
};
export type homeScreenQuery = {
  response: homeScreenQuery$data;
  variables: homeScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hiredProjectsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeSheetReviewQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "upcomingShiftsQuery"
},
v3 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "timeSheetReviewQuery"
},
v4 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "hiredProjectsQuery"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": "upcomingShiftCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "upcomingShiftsQuery"
    }
  ],
  "concreteType": "EngagementsConnection",
  "kind": "LinkedField",
  "name": "engagements",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "homeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VerificationCenterCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ShiftGroupChatHomeTabCard_shift"
              },
              {
                "args": [
                  (v3/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "PendingTimesheetsReviewCard_worker"
              },
              {
                "args": [
                  (v4/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "HiredProjectsCard_worker"
              },
              (v6/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "homeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "directHireBonus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerVerification",
                "kind": "LinkedField",
                "name": "verification",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VerificationReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusDescription",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerLegalInformation",
                "kind": "LinkedField",
                "name": "legalInformation",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundCheckOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "driverCheckOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "drugScreenOptIn",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "reviewProjects",
                "args": [
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "SYSTEM"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 1
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE name = PENDING_TIMESHEET_APPROVAL"
                                  }
                                ],
                                "concreteType": "EngagementStateOrOverridesConnection",
                                "kind": "LinkedField",
                                "name": "states",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementStateOrOverridesEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "transitionedAt",
                                            "storageKey": null
                                          },
                                          (v8/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "states(first:1,query:\"WHERE name = PENDING_TIMESHEET_APPROVAL\")"
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"SYSTEM\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endsAt",
                                "storageKey": null
                              },
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "insertedAt",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logoUrl",
                                    "storageKey": null
                                  },
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "hiredProjects",
                "args": [
                  (v10/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isAsap",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "conversationTypes",
                        "value": [
                          "SHIFT_GROUP"
                        ]
                      }
                    ],
                    "concreteType": "UserMessageStats",
                    "kind": "LinkedField",
                    "name": "userMessageStats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unreadCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "userMessageStats(conversationTypes:[\"SHIFT_GROUP\"])"
                  }
                ],
                "type": "User",
                "abstractKey": "__isUser"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:fgkspHIGhtcjJ47uEpzSV5nXgssPMxRCgS-D0UhS8B0",
    "metadata": {},
    "name": "homeScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "35f39b900768d9f5dad42fbf24e82978";

export default node;
