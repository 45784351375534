import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React, { type ReactNode } from "react";
import { View } from "react-native";
import { FlexColumn, FlexRow } from "../flex";
import { type IconName, StyledIcon } from "../icon";
import { Spacer } from "../spacer";
import { type TextStyleProp, useStyles } from "../style";
import { StyledLink } from "../tappable";
import { StyledText } from "../text";
import BaseRow from "./base-row";

type Props = ComponentPropsWithDefaults<typeof FlexRow> & {
  iconName?: IconName;
  label: string;
  secondaryTitle?: ReactNode;
  secondaryTitleUrl?: string;
  secondaryTitleOnPress?: () => void;
  data?: ReactNode;
  noBorder?: boolean;
  boldLabel?: boolean;
  boldData?: boolean;
  showSpace?: boolean;
  justifyData?: "flex-start" | "flex-end" | "center";
  iconColor?: string;
  color?: string;
  testID?: string;
};

export default ({
  iconName,
  iconColor,
  label,
  secondaryTitle,
  secondaryTitleUrl,
  secondaryTitleOnPress,
  data,
  noBorder = false,
  boldLabel = false,
  boldData = false,
  showSpace = false,
  justifyData = "center",
  color,
  testID = "data-row",
  ...props
}: Props) => {
  const { styles, theme } = useStyles(({ units }) => ({
    iconContainer: {
      marginRight: units(2)
    },
    marginBottom: {
      marginBottom: units(0.5)
    },
    label: { flex: 1 }
  }));
  return (
    <BaseRow noBorder={noBorder} testID={testID} {...props}>
      <FlexColumn justifyContent="center" fill={1}>
        <FlexRow alignItems="center">
          {iconName && (
            <View style={styles.iconContainer}>
              <StyledIcon
                name={iconName}
                variant="solid"
                size={theme.font.size.medium}
                color={iconColor ?? color}
              />
            </View>
          )}
          <StyledText
            style={
              secondaryTitle
                ? (styles.marginBottom as TextStyleProp)
                : styles.label
            }
            bold={boldLabel}
            color={color}
            testID={`${testID}-label`}
          >
            {label}
          </StyledText>
        </FlexRow>
        <FlexRow>
          {typeof secondaryTitle === "string" &&
            secondaryTitle &&
            !secondaryTitleUrl &&
            !secondaryTitleOnPress && (
              <StyledText fontSize={theme.font.size.small} color="neutralDark">
                {secondaryTitle}
              </StyledText>
            )}
          {typeof secondaryTitle === "string" &&
            secondaryTitle &&
            (!!secondaryTitleUrl || secondaryTitleOnPress) && (
              <StyledLink
                fontSize={theme.font.size.small}
                onPress={secondaryTitleOnPress}
                openURL={secondaryTitleUrl}
              >
                {secondaryTitle}
              </StyledLink>
            )}
          {typeof secondaryTitle !== "string" && secondaryTitle}
        </FlexRow>
      </FlexColumn>
      {showSpace && <Spacer direction="row" />}
      <FlexColumn alignItems="center" justifyContent={justifyData}>
        {typeof data === "string" ? (
          <StyledText testID={`${testID}-data`} bold={boldData} color={color}>
            {data}
          </StyledText>
        ) : (
          data
        )}
      </FlexColumn>
    </BaseRow>
  );
};
