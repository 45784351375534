/**
 * @generated SignedSource<<6476f41bd18d469eada5aede1b3c1d1c>>
 * @relayHash baf9117df4214674b23f942b229b4898
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:HThRm8gfMqXomnmNGkpyz2Gkrf_UYTy0DjyVQWMBnuc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerPositionCheckQuery$variables = Record<PropertyKey, never>;
export type WorkerPositionCheckQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"WorkerPositionCheck_worker">;
  } | null | undefined;
};
export type WorkerPositionCheckQuery = {
  response: WorkerPositionCheckQuery$data;
  variables: WorkerPositionCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "_in": [
    "ACTIVE",
    "IN_PROGRESS"
  ]
},
v6 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE status = CONFIRMED"
  }
],
v7 = [
  (v1/*: any*/),
  (v0/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  "query"
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerPositionCheckQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkerPositionCheck_worker"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WorkerPositionCheckQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "activeEngagementsAndOffers",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "SCHEDULED",
                          "EN_ROUTE",
                          "AWAITING_START",
                          "WORKING",
                          "PAUSED",
                          "OFFERED"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"SCHEDULED\",\"EN_ROUTE\",\"AWAITING_START\",\"WORKING\",\"PAUSED\",\"OFFERED\"]}})"
              },
              {
                "alias": "preactiveEngagements",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "SCHEDULED",
                          "EN_ROUTE",
                          "AWAITING_START"
                        ]
                      },
                      "gigStateName": (v5/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"SCHEDULED\",\"EN_ROUTE\",\"AWAITING_START\"]},\"gigStateName\":{\"_in\":[\"ACTIVE\",\"IN_PROGRESS\"]}})"
              },
              {
                "alias": "activeEngagements",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "WORKING",
                          "PAUSED"
                        ]
                      },
                      "gigStateName": (v5/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"WORKING\",\"PAUSED\"]},\"gigStateName\":{\"_in\":[\"ACTIVE\",\"IN_PROGRESS\"]}})"
              },
              {
                "alias": "bans",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE expiresAt = NULL AND removedAt = NULL"
                  }
                ],
                "concreteType": "UserRestrictionsConnection",
                "kind": "LinkedField",
                "name": "restrictions",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "restrictions(first:0,query:\"WHERE expiresAt = NULL AND removedAt = NULL\")"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "WorkerPositionsConnection",
                "kind": "LinkedField",
                "name": "workerPositions",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerPositionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigPosition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": "workerPositions(first:0,query:\"WHERE status = CONFIRMED\")"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": (v10/*: any*/),
                "handle": "connection",
                "key": "WorkerPositionCheck_workerPositions",
                "kind": "LinkedHandle",
                "name": "workerPositions"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "WorkerGigPositionsConnection",
                "kind": "LinkedField",
                "name": "positions",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerGigPositionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerGigPosition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": "positions(first:0,query:\"WHERE status = CONFIRMED\")"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": (v10/*: any*/),
                "handle": "connection",
                "key": "WorkerPositionCheckModal_positions",
                "kind": "LinkedHandle",
                "name": "positions"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 20
                  },
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY priority ASC"
                  }
                ],
                "concreteType": "WorkerCategoriesConnection",
                "kind": "LinkedField",
                "name": "workerCategories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerCategoriesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerGigCategory",
                        "kind": "LinkedField",
                        "name": "association",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "confirmedPositions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unknownPositions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deniedPositions",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigCategory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Icon",
                            "kind": "LinkedField",
                            "name": "categoryIcon",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "data",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "workerCategories(first:20,query:\"ORDER BY priority ASC\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:HThRm8gfMqXomnmNGkpyz2Gkrf_UYTy0DjyVQWMBnuc",
    "metadata": {},
    "name": "WorkerPositionCheckQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7af0f0b19cb44db4b59e9597946ff452";

export default node;
