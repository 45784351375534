import { PositionsAndLocations } from "@gigsmart/feature-flags";
import React from "react";
import AddonModalContent from "./addon-modal-content";

export default function OccupationAccidentInsurance() {
  return (
    <AddonModalContent
      introText={`For every worker age 18 years and older, Occupational Accident Insurance (OAI) serviced by Citadel Insurance Services is included on every ${PositionsAndLocations.select(
        "Shift",
        "Gig"
      )} for FREE`}
      listHeader="What is Occupational Accident Insurance?"
      learnMoreText="Learn more about OAI coverage"
      learnMoreLink="https://gigsmart.com/occupational-accident-insurance"
      listItems={[
        "OAI covers Worker's medical expenses for accidental injuries on the job.",
        "OAI covers Worker's related benefits associated with fatalities on the job.",
        "This free service automatically protects every GigSmart Worker you hire."
      ]}
    />
  );
}
