import { Button, Row } from "@gigsmart/atorasu";
import { renderWaitingStartNode } from "@gigsmart/isomorphic-shared/worker/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { ShiftActions_shift$key } from "../accessory/__generated__/ShiftActions_shift.graphql";
import useEngagementTransition, {
  useEngagementWorkerActions
} from "../hooks/useEngagementTransition";

type Props = {
  onRefreshLocation: () => void;
};

export const ShiftActions = ({
  id,
  result,
  onRefreshLocation
}: FragmentContainerInnerComponentProps<ShiftActions_shift$key, Props>) => {
  const [handleAction, { loading }] = useEngagementTransition(id);
  const actions = useEngagementWorkerActions({ engagementRef: result });

  const waitingStartNode = renderWaitingStartNode(result, onRefreshLocation);
  if (waitingStartNode) return waitingStartNode;

  return actions.length ? (
    <Row gap="standard" wrap="wrap">
      {actions.map(({ actionSpec, capability }, index) => {
        const isLast = index === actions.length - 1;
        return (
          <Button
            key={capability}
            icon={actionSpec.icon}
            outline={actionSpec.buttonVariant === "outline" || !isLast}
            label={actionSpec.label ?? capability}
            testID={actionSpec?.testID ?? `${capability}-button`}
            fullWidth
            fill={index !== 2}
            disabled={loading}
            onPress={() => handleAction(capability, actionSpec)}
          />
        );
      })}
    </Row>
  ) : null;
};

export default createRelayFragmentContainer<ShiftActions_shift$key, Props>(
  graphql`
    fragment ShiftActions_shift on Engagement {
      ...helpersWorker_engagement
      ...useEngagementTransition_engagement
      id
    }
  `,
  ShiftActions
);
