import React from "react";
import type { ViewStyle } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";

interface Props {
  style?: ViewStyle;
}

export default function ShiftGigsSvg({ style }: Props) {
  return (
    <Svg style={style} viewBox="0 0 100 100">
      <Path
        fill="none"
        d="M50.008 99.97a50.138 50.138 0 01-11.993-1.451l-.002.018a50.139 50.139 0 0012.012 1.452v-.017h-.017zM0 49.946q0-.553.012-1.102Q0 49.403 0 49.964a49.833 49.833 0 004.713 21.225l.003-.013A49.833 49.833 0 010 49.946z"
      />
      <Path
        fill="#fee269"
        d="M50.025 43.138l3.273-2.84 1.541-.347.155-.035 4.955-1.115.823-.186.086.08a30.415 30.415 0 00.232-4.442 9.936 9.936 0 01-3.18-3.441A9.507 9.507 0 0154.9 20.546a2.998 2.998 0 01-.362-2.499h-.56a.325.325 0 01-.325-.325v-1.373a.325.325 0 01.326-.325h.62a7.322 7.322 0 015.176-6.103v-.123a.325.325 0 01.325-.326h1.372a.325.325 0 01.273.149c.038 0 .075-.003.113-.003h2.512a.325.325 0 01.27-.146h1.373a.325.325 0 01.326.326v.006a7.32 7.32 0 015.621 6.22h1.169a.325.325 0 01.325.325v1.373a.325.325 0 01-.325.325h-.768a9.515 9.515 0 011.24 2.499 5.331 5.331 0 012.427-.902c3.238-.33 5.515 2.686 5.925 3.228a13.283 13.283 0 011.721 3.635 40.185 40.185 0 011.193 4.055c.93 3.534.818 3.509 1.039 4.106.564 1.527 1.958 3.501 5.712 5.218a18.971 18.971 0 01-7.158-2.989c-1.65-1.135-4.919-3.386-6.285-7.327-.788-2.274-.395-3.53-1.703-5.272a7.361 7.361 0 00-2.492-2.078 9.538 9.538 0 01.081 1.245 9.639 9.639 0 01-6.331 9.003q-.126.15-.255.294l.217-.009c.173 1.562-.194 3.353.439 5.403l.056-.054 1.159.197 4.715.783-.001.017 1.874.318 12.411 2.82-.954 2.122 10.737 2.032a3.305 3.305 0 011.013.371 2.628 2.628 0 01.754.273A50.027 50.027 0 0049.975.013v33.482h.05z"
      />
      <Path
        fill="#fee269"
        d="M80.137 50.865l-4.881-1.425.677-10.023-.773 11.448h4.977z"
      />
      <Path
        fill="#f3a11e"
        d="M66.338 9.804v4.818a.325.325 0 01-.326.325H64.64a.328.328 0 01-.326-.325V9.798a.324.324 0 01.055-.18h-2.512c-.038 0-.075.002-.113.003a.323.323 0 01.053.177v4.854a.325.325 0 01-.326.326H60.1a.33.33 0 01-.325-.326V9.921a7.322 7.322 0 00-5.176 6.103h17.361a7.32 7.32 0 00-5.621-6.22z"
      />
      <Path
        fill="#fbc6b8"
        d="M71.192 22.311l-.043-.009a1.34 1.34 0 00-1.53 1.118l-.192 1.239q-1.527-1.65-3.064-3.351-.839-.93-1.66-1.856.872 1.616 1.695 3.201c-3.527-.048-6.631-2.057-7.626-4.606h-.01c-.246.312-.514.63-.808.95a18.556 18.556 0 01-2.116 1.972 8.844 8.844 0 00-.259 2.128 16.197 16.197 0 002.332 7.715 9.936 9.936 0 003.179 3.441 30.415 30.415 0 01-.233 4.442l3.392 3.172 3.88-3.711c-.632-2.05-.265-3.841-.439-5.403l-.216.01q.13-.144.255-.295a15.974 15.974 0 002.766-5.2 1.336 1.336 0 001.02-.374l.046.048c.018-.032.069-.125.14-.27a1.329 1.329 0 00.166-.355 1.342 1.342 0 00.042-.179l.358-2.307a1.339 1.339 0 00-1.075-1.52zm-6.185 6.891a3.42 3.42 0 01-2.443 1.441 2.79 2.79 0 01-2.026-1.039z"
      />
      <Path
        fill="#fcb535"
        d="M73.128 16.024h-19.15a.325.325 0 00-.326.325v1.373a.325.325 0 00.326.325h19.15a.325.325 0 00.325-.325v-1.373a.325.325 0 00-.325-.325zM61.744 9.62a.325.325 0 00-.273-.148H60.1a.325.325 0 00-.325.326v4.854a.33.33 0 00.325.326h1.372a.325.325 0 00.326-.326V9.798a.323.323 0 00-.053-.177zM66.012 9.472H64.64a.326.326 0 00-.326.326v4.824a.328.328 0 00.326.325h1.372a.325.325 0 00.326-.325V9.798a.325.325 0 00-.326-.326z"
      />
      <Path
        fill="#fff"
        d="M65.007 29.202l-4.469.402a2.79 2.79 0 002.026 1.04 3.42 3.42 0 002.443-1.442z"
      />
      <Path
        fill="#faa635"
        d="M55.035 51.27a1.522 1.522 0 01.217-.746l-.413-10.573-1.54.347.147 10.971zM60.816 43.274l-.054-.278-.422-2.176-.391-2.02-4.955 1.116 4.169-.938.569 9.274h.754a1.523 1.523 0 01.901.295zM67.055 66.58l-.002.012 5.202.05v-.012l-5.2-.05zM75.16 50.865l.773-11.448-1.874-.318-.862 11.766h1.963zM72.255 66.63V54.876h-3.588L67.055 66.58l5.2.05zM68.84 40.173l-.65 2.42-.34 1.263-1.414 7.413h1.78a.83.83 0 01.222.03l.61-8.752.111-1.594.186-2.654z"
      />
      <Path
        fill="#d3d3d3"
        d="M56.564 49.78h1.079a1.532 1.532 0 011.532-1.528h.557l-.57-9.274-4.168.938-.155.035.413 10.573a1.53 1.53 0 011.312-.744zM74.06 39.082l-4.715-.783 4.714.8.001-.017zM69.345 38.299l-.186 2.654-.11 1.594-.611 8.753a.832.832 0 01.611.802l-.382 2.774h3.588v-3.178a.832.832 0 01.832-.833h.11L74.06 39.1z"
      />
      <Path
        fill="#515251"
        d="M68.16 38.253l.026-.151-.056.054.006.022.025.075z"
      />
      <Path
        fill="#515251"
        d="M68.186 38.102l-.025.151-.025-.075-.006-.023-3.88 3.712-3.392-3.172-.086-.08-.823.186.391 2.019.422 2.176.054.278.571 5.273a1.536 1.536 0 01.63 1.233h1.098a1.531 1.531 0 011.53 1.49h1.791l1.415-7.414.339-1.262.65-2.421.505-1.874zM50.503 52.102a.832.832 0 01.832-.833h2.111l-.148-10.971-3.273 2.84v12.434z"
      />
      <Path
        fill="#b56d3b"
        d="M74.095 66.647l-.001.013 14.449.139.005-.013-14.453-.139z"
      />
      <Path
        fill="#b56d3b"
        d="M72.255 66.63v.012l1.839.018.001-.013-1.84-.017z"
      />
      <Path
        fill="#b56d3b"
        d="M94.404 52.426l-8.245-1.56-1.562-.296-.133.295-.502 1.117-3.825-1.117h-7.05a.832.832 0 00-.832.833V66.63l1.84.017 14.453.14L94.404 52.7l.106-.254z"
      />
      <Path
        fill="#515251"
        d="M75.933 39.417l-.677 10.023 4.881 1.425 3.825 1.117.502-1.117.133-.295 2.793-6.211.954-2.121-12.411-2.821z"
      />
      <Path
        fill="#fbc6b8"
        d="M88.548 66.786l-.005.013 5.646.054.005-.013-5.646-.054zM99.932 47.386l-.02-.34-.018-.011a2.628 2.628 0 00-.754-.273 3.305 3.305 0 00-1.013-.37L87.39 44.358l-2.793 6.21 1.562.296 8.245 1.561.106.02-.106.254-5.856 14.086 5.646.054.21-.505 5.483-13.19.019-.045q.037-.615.06-1.234.033-.919.034-1.846 0-1.325-.068-2.634z"
      />
      <Path
        fill="#7f4b37"
        d="M56.139 18.047h-1.602a2.998 2.998 0 00.362 2.499 9.507 9.507 0 003.012 10.266 16.197 16.197 0 01-2.332-7.715 8.844 8.844 0 01.26-2.128 18.556 18.556 0 002.115-1.971c.294-.321.562-.639.809-.95h-2.624zM85.905 34.668c-.22-.597-.11-.572-1.039-4.106a40.185 40.185 0 00-1.193-4.055 13.283 13.283 0 00-1.721-3.635c-.41-.542-2.687-3.557-5.925-3.228a5.331 5.331 0 00-2.427.902 9.515 9.515 0 00-1.24-2.499H58.772c.995 2.549 4.099 4.558 7.626 4.606q-.822-1.584-1.696-3.201.823.925 1.66 1.856 1.538 1.704 3.065 3.35l.193-1.238a1.34 1.34 0 011.529-1.118l.043.01a1.339 1.339 0 011.075 1.52l-.358 2.306a1.342 1.342 0 01-.042.179 1.329 1.329 0 01-.166.354c-.071.146-.122.24-.14.271l-.045-.048a1.336 1.336 0 01-1.021.374 15.974 15.974 0 01-2.766 5.2 9.639 9.639 0 006.331-9.003 9.538 9.538 0 00-.081-1.245 7.361 7.361 0 012.492 2.078c1.308 1.743.915 2.998 1.703 5.272 1.366 3.941 4.636 6.192 6.285 7.327a18.971 18.971 0 007.158 2.989c-3.754-1.717-5.148-3.69-5.712-5.218z"
      />
      <Path
        fill="#c97e4a"
        d="M50.025 66.416v.013l.17.001.002-.012-.172-.002zM65.252 66.562l-.002.013 1.803.017.002-.012-1.803-.018z"
      />
      <Path
        fill="#c97e4a"
        d="M68.438 51.3a.83.83 0 00-.221-.03H51.335a.832.832 0 00-.832.832l-.478 3.47v10.844l.172.002 1.989-13.07.038-.251h15.077l-2.049 13.465 1.803.018 1.612-11.704.382-2.774a.832.832 0 00-.611-.802z"
      />
      <Path
        fill="#8e8e8e"
        d="M63.115 49.78h-1.097a1.536 1.536 0 00-1.532-1.528h-1.311a1.532 1.532 0 00-1.532 1.528h-1.079a1.533 1.533 0 00-1.53 1.49h9.61a1.531 1.531 0 00-1.53-1.49z"
      />
      <Path
        fill="#fff"
        d="M50.197 66.418l-.002.012 3.456.034v-.013l-3.454-.033zM53.651 66.451v.013l9.686.093-.001-.013-9.685-.093zM63.522 66.546l-.002.012 1.73.017.002-.013-1.73-.016zM63.346 66.544l.001.013.157.001.002-.012-.16-.002z"
      />
      <Path
        fill="#fff"
        d="M63.336 66.544l.001.013h.01l-.001-.013h-.01zM63.506 66.546l-.002.012h.016l.002-.012h-.016z"
      />
      <Path
        fill="#fff"
        d="M66.088 53.097H52.224l-.038.251-1.989 13.07 3.454.033 9.685.093h.01l.16.002h.016l1.73.016 2.049-13.465h-1.213z"
      />
      <Path
        fill="#fedf5a"
        d="M67.053 66.592l-.008.06 5.21.05v-.06l-5.202-.05zM72.255 66.642v.06l1.835.018.004-.06-1.839-.018z"
      />
      <Path
        fill="#fedf5a"
        d="M99.906 53.1l-.019.044-5.483 13.19-.21.506-.005.013-5.646-.054-14.449-.139-.004.06-1.835-.018-5.21-.05-1.804-.017-1.733-.017h-.014l-.141-.001h-.009l-9.692-.093-3.466-.034-.161-.001v9.931l8.7 2.553.384.112 20.61 6.046a4.364 4.364 0 01-5.416 2.96l-12.619-3.702a4.366 4.366 0 01-3.134-4.314l-8.525-2.5v22.398a49.99 49.99 0 0047.073-33.105h-.006A49.807 49.807 0 0099.906 53.1zm-13.02 23.176c-.234 1.185-1.962 1.707-5.014 2.76a17.55 17.55 0 01-4.694 1.142c-4.384.291-9.662-2.01-9.646-4.05.013-1.761 4.659-2.777 9.707-3.9 2.654-.59 4.059-.65 5.527-.143 2.121.732 4.422 2.66 4.12 4.191z"
      />
      <Path
        fill="#fedf5a"
        d="M50.025 66.429v.06l.161.001.009-.06-.17-.001zM65.25 66.575l-.009.06 1.804.017.008-.06-1.803-.017z"
      />
      <Path
        fill="#fedf5a"
        d="M50.195 66.43l-.009.06 3.466.034-.001-.06-3.456-.034zM53.651 66.464l.001.06 9.692.093-.007-.06-9.686-.093zM63.52 66.558l-.012.06 1.733.017.009-.06-1.73-.017zM63.347 66.557l.006.06.141.001.01-.06-.157-.001z"
      />
      <Path
        fill="#fedf5a"
        d="M63.337 66.557l.007.06h.009l-.006-.06h-.01zM63.504 66.558l-.01.06h.014l.012-.06h-.016z"
      />
      <Path
        d="M58.55 80.075a4.366 4.366 0 003.134 4.314l12.619 3.701a4.364 4.364 0 005.416-2.959l-20.61-6.046-.384-.112-8.7-2.553v1.154z"
        fill="#3a3a3a"
      />
      <Path
        fill="#fff"
        d="M86.886 76.276c.302-1.532-1.999-3.459-4.12-4.19-1.468-.508-2.872-.449-5.527.142-5.048 1.123-9.694 2.139-9.707 3.9-.016 2.04 5.262 4.341 9.646 4.05a17.55 17.55 0 004.694-1.143c3.052-1.052 4.78-1.574 5.014-2.759zm-10.422 1.955a2.945 2.945 0 01-2.717.466c-.865-.253-2.044-1.01-2.084-2.084a1.836 1.836 0 01.384-1.13 3.16 3.16 0 013.583-.834c.254.098 1.42.548 1.62 1.603a2.308 2.308 0 01-.786 1.98z"
      />
      <Path
        d="M75.63 74.649a3.16 3.16 0 00-3.583.834 1.836 1.836 0 00-.384 1.13c.04 1.073 1.219 1.83 2.084 2.084a2.945 2.945 0 002.717-.466 2.308 2.308 0 00.787-1.979c-.201-1.055-1.367-1.505-1.622-1.603z"
        fill="#faa719"
      />
      <Path
        fill="#f99f1e"
        d="M49.96 33.534l-47.03-.451.014-.04h-.01l-.026.073 47.052.452z"
      />
      <Path
        fill="#f99f1e"
        d="M4.716 71.176q.11-.444.233-.899a36.632 36.632 0 013.8-8.977l8.34-1.807 2.509-.543.533-2.809c-.955-.718-1.765-1.442-1.895-1.545-1.056-1.493-.955-1.703-1.633-4.267a19.97 19.97 0 01-.62-3.344l-.001.001-.581-6.238a.813.813 0 01-.067-.32l-.99-3.59c0-.581.63-1.053 1.408-1.053l13.912 1.429c.778 0 1.41.472 1.41 1.053v2.16a.848.848 0 01-.167.497c-.146 1.37-.361 3.338-.533 4.9a1.41 1.41 0 011.495 1.87l-.75 2.132a1.405 1.405 0 01-1.957.798 12.9 12.9 0 01-.632 2.297l.226 6.259 14.172 3.502-.008.276a5.589 5.589 0 013.813 4.425l2.521 15.478q.394.095.754.196V66.49h-.033V33.494h-.015v.073l-47.052-.452A49.885 49.885 0 00.012 48.844Q0 49.394 0 49.946a49.833 49.833 0 004.716 21.23zM50.008 87.488v-.453l-.07.02z"
      />
      <Path
        fill="#f99f1e"
        d="M50.025 43.138v-9.642l-.05-.001v9.686l.05-.043zM49.975 55.876v.055l.009-.066-.009.011z"
      />
      <Path
        fill="#f99f1e"
        d="M50.025 55.572V43.138l-.05.043v12.695l.009-.011.041-.293zM50.008 66.416h.017V55.819l-.041.046-.009.066v10.557l.033.001v-.073z"
      />
      <Path
        fill="#f99f1e"
        d="M50.008 66.416v.013h.017v-.013h-.017zM49.984 55.865l.041-.046v-.247l-.041.293zM50.008 76.415l.017.005v-9.931h-.017v9.926zM50.008 87.488l.017.105v-.563l-.017.005zM50.008 99.97h.017V89.673l-.017.082zM50.008 83.057l.017.005v-5.488l-.017-.005zM50.008 66.429v.06h.017v-.06h-.017z"
      />
      <Path
        fill="#f99f1e"
        d="M50.008 77.569l.017.005V76.42l-.017-.005v1.154z"
      />
      <Path
        fill="#000"
        d="M29.664 37.214l-13.912-1.429c-.778 0-1.409.472-1.409 1.054l.99 3.589a.813.813 0 00.068.32l.58 6.238h.001a7.753 7.753 0 011.301-5.505h7.353a4.911 4.911 0 00.763 1.004 5.056 5.056 0 002.969 1.45l.648 3.356.188-.534a1.406 1.406 0 011.17-.933c.172-1.562.387-3.53.533-4.9a.848.848 0 00.166-.496v-2.16c0-.582-.63-1.054-1.409-1.054z"
      />
      <Path
        d="M31.004 45.893a1.413 1.413 0 00-1.8.864l-.188.534-.648-3.357a5.056 5.056 0 01-2.97-1.449 4.911 4.911 0 01-.762-1.004h-7.353a7.753 7.753 0 00-1.3 5.504 19.97 19.97 0 00.62 3.344c.678 2.564.577 2.774 1.633 4.267.13.103.94.827 1.895 1.545l-.533 2.809 3.419 2.693 5.739-2.463-.226-6.26a12.9 12.9 0 00.632-2.296 1.405 1.405 0 001.957-.798l.75-2.132a1.412 1.412 0 00-.865-1.8zm-8.9 6.828a5.795 5.795 0 01-2.96-.707l6.307-.508a5.838 5.838 0 01-3.346 1.215z"
        fill="#60421b"
      />
      <Path
        fill="#fff"
        d="M50.008 89.755l.017-.082v-2.08l-.017-.105zM4.716 71.176l-.003.013a49.962 49.962 0 004.036 7.045v-.02a49.964 49.964 0 01-4.033-7.038z"
      />
      <Path
        fill="#fff"
        d="M4.949 70.277q-.124.455-.233.899a49.964 49.964 0 004.033 7.039V61.3a36.632 36.632 0 00-3.8 8.977zM46.733 67.383a5.589 5.589 0 00-3.813-4.425l.008-.277-14.172-3.502-5.74 2.464-3.418-2.693-2.509.543 1.364 3.907.586-2.508.175.08 3.996 1.826 4.577-1.46-.355 3.786-.011.116-.285-.13-4.185-1.923-4.2 1.066.152.434 4.476 12.816 8.577-17.533 9.411 2.326-.037.397-1.103 11.92a13.949 13.949 0 011.602.713 14.175 14.175 0 016.61 7.351q.424.09.815.184zm-24.052-2.18a.56.56 0 11-.56.56.56.56 0 01.56-.56zm0 3.015a.56.56 0 11-.56.56.56.56 0 01.56-.56zm.115 4.377a.56.56 0 11.56-.56.56.56 0 01-.56.56z"
      />
      <Path
        fill="#000"
        d="M19.75 89.772l-.127-.078a50.091 50.091 0 006.2 4.06l-.166-.11a50.094 50.094 0 01-5.906-3.872z"
      />
      <Path
        fill="#000"
        d="M19.75 89.772a50.094 50.094 0 005.907 3.872l-5.342-3.539q-.287-.164-.564-.333z"
      />
      <Path
        fill="#000"
        d="M8.749 78.215v.019a50.308 50.308 0 0010.874 11.46l.128.078A50.308 50.308 0 018.749 78.215zM27.421 65.24l.011-.116-.296-.015.285.131z"
      />
      <Path
        fill="#000"
        d="M22.951 63.187l4.185 1.922.296.015.354-3.785-4.577 1.459.456.208-.714.181z"
      />
      <Path
        fill="#000"
        d="M22.951 63.187l.714-.181-.456-.208-.653.208.395.181z"
      />
      <Path
        fill="#828282"
        d="M50.008 87.035v-3.978q-.36-.1-.754-.196l.683 4.194zM50.008 87.035l.017-.005v-3.968l-.017-.005z"
      />
      <Path
        fill="#9b9a9a"
        d="M48.44 82.677a14.175 14.175 0 00-6.611-7.351 13.949 13.949 0 00-1.602-.713 13.744 13.744 0 00-3.78-.826l-.073-.005.049-.015c-.07-.472-.364-.816-.705-.827-.35-.01-.662.334-.736.818A14.113 14.113 0 0021.72 85.046a58.652 58.652 0 0014.106 1.625q1.39-.01 2.707-.081.296-.016.589-.034l.205-.014a58.671 58.671 0 009.814-1.496 14.117 14.117 0 00-.702-2.369zm-20.612-3.659a12.562 12.562 0 00-1.325 1.693h-1.745a12.914 12.914 0 012.283-2.637 13.372 13.372 0 016.2-3.023h1.744a13.927 13.927 0 00-7.157 3.967z"
      />
      <Path
        fill="#fff"
        d="M27.041 78.074a12.914 12.914 0 00-2.283 2.637h1.745a12.562 12.562 0 011.325-1.693 13.927 13.927 0 017.157-3.967H33.24a13.372 13.372 0 00-6.199 3.023z"
      />
      <Path
        fill="#f99f1e"
        d="M45.429 94.153a5.59 5.59 0 01-6.369-4.365 5.63 5.63 0 01-.048-.254l-.106-.65-.077.835-.814 8.8a50.138 50.138 0 0011.993 1.451V89.755a5.592 5.592 0 01-4.58 4.398z"
      />
      <Path
        fill="#fff"
        d="M49.937 87.055a48.306 48.306 0 01-9.78 1.331q-.596.03-1.21.053l-.113.004.072.442.106.649c.014.085.03.17.048.254a5.59 5.59 0 006.369 4.365 5.592 5.592 0 004.579-4.398v-2.267z"
      />
      <Path
        fill="#828282"
        d="M36.807 88.717l.019-.032a.752.752 0 00.074-.18l-.019.031a.751.751 0 00-.074.18zM49.254 82.86q-.39-.094-.815-.183a14.117 14.117 0 01.702 2.37 58.671 58.671 0 01-9.814 1.495l-.205.014q-.293.018-.59.034-1.315.07-2.706.08a58.652 58.652 0 01-14.106-1.624 14.115 14.115 0 01.622-2.16c-2.395.59-3.83 1.342-3.83 2.16 0 1.751 6.566 3.198 15.07 3.418q.8.021 1.625.027l-.01.027.015-.027.635.003q.533 0 1.058-.007l-.005.017.01-.017q.836-.01 1.65-.035l.066-.003.14-.004.068-.002.114-.004q.613-.022 1.21-.053a48.306 48.306 0 009.78-1.331z"
      />
      <Path
        fill="#000"
        d="M31.956 59.97l-8.578 17.533-4.475-12.817-.152-.433 4.2-1.066-.395-.18.653-.21-3.995-1.825-.175-.08-.586 2.508-1.364-3.907L8.75 61.3v16.915a50.308 50.308 0 0011.002 11.557q.277.169.564.333l5.342 3.54.166.109a49.721 49.721 0 0010.025 4.197q1.073.316 2.165.586l.002-.018.814-8.8.077-.834-.072-.442-.067.002-.141.004-.066.003q-.813.025-1.65.035l-.01.017.005-.017q-.525.006-1.058.007-.32 0-.635-.003l-.016.027.01-.027q-.822-.006-1.625-.027c-8.503-.22-15.069-1.667-15.069-3.418 0-.818 1.435-1.57 3.83-2.16a14.098 14.098 0 0112.64-9.128c.074-.484.386-.829.736-.818.34.011.636.355.705.827l-.049.015.074.005a13.744 13.744 0 013.78.826l1.102-11.92.037-.397z"
      />
      <Circle cx={22.681} cy={65.763} r={0.56} />
      <Path
        fill="#000"
        d="M22.681 69.338a.56.56 0 10-.56-.56.56.56 0 00.56.56zM22.796 71.476a.56.56 0 10.56.56.56.56 0 00-.56-.56z"
      />
      <Path
        fill="#fff"
        d="M22.105 52.721a5.838 5.838 0 003.346-1.215l-6.306.508a5.795 5.795 0 002.96.707z"
      />
      <Path
        fill="#ffedb3"
        d="M2.944 33.044l47.016.451V.011A49.907 49.907 0 002.944 33.043zm19.374-15.061l2.275-4.834-1.608-1.51a.8.8 0 011.096-1.167l2.023 1.9.004.004.005.004.012.013a.81.81 0 01.04.043l.02.023.029.037.022.034.02.03.021.04.014.029a.807.807 0 01.018.042l.011.03.013.04.01.035.007.037.007.04.003.032.004.046v.077l-.003.03-.005.047-.007.034c-.003.014-.005.027-.009.04-.003.016-.008.03-.013.046l-.009.028a.813.813 0 01-.023.057l-.006.014-.003.006-.002.006-.848 1.802h7.76l.171-.43h-1.935a.8.8 0 110-1.601h4.542a.8.8 0 110 1.6h-.882l-.473 1.182 1.687 2.551a7.58 7.58 0 012.518-.795h-2.013a.244.244 0 01-.244-.244v-2.333a.244.244 0 01.244-.243h3.461v-.285l-2.292-.1a.244.244 0 01-.233-.254l.23-5.273a.244.244 0 01.254-.233l2.041.09v-.135h-2.92a.244.244 0 01-.243-.244V4.848a.244.244 0 01.243-.244h2.92v-.142a.2.2 0 01.4 0v.142h2.798a.244.244 0 01.244.244v3.523a.244.244 0 01-.244.244h-2.797v.152l5.874.256a.244.244 0 01.233.255l-.23 5.272a.244.244 0 01-.254.233l-5.623-.245v.267h4.462a.244.244 0 01.244.243v2.333a.244.244 0 01-.244.244h-4.303A7.651 7.651 0 0147.47 25.2a.795.795 0 01-.111.405 7.541 7.541 0 01-15.03.395H30.028a.794.794 0 01-.08-.004l-.012-.002-.02-.003c-.014-.002-.029-.003-.043-.006l-.03-.007-.02-.006-.024-.006c-.016-.004-.03-.01-.046-.016h-.002l-.016-.006-.004-.001-.005-.002a.813.813 0 01-.05-.023l-.024-.012-.002-.001-.003-.001-.033-.02-.031-.02-.012-.007-.023-.018-.029-.021-.006-.006-.016-.014-.02-.019-.003-.003q-.027-.026-.052-.055l-.01-.011-.002-.001-.005-.007a.807.807 0 01-.04-.054l-.009-.014-.005-.007-5.074-8.046-.51 1.085a7.644 7.644 0 013.682 6.534.795.795 0 01-.111.405 7.541 7.541 0 01-15.061 0 .795.795 0 01-.111-.405 7.638 7.638 0 0110.153-7.216z"
      />
      <Path
        fill="#ffedb3"
        d="M32.229 24.398a7.637 7.637 0 012.742-5.095l-1.075-1.626-2.687 6.721zM33.842 24.398h4.498l-2.48-3.749a6.034 6.034 0 00-2.018 3.75zM19.806 31.34a6.148 6.148 0 006.13-5.777.796.796 0 01-.089-.364 6.04 6.04 0 00-2.768-5.074l-2.549 5.414a.8.8 0 01-1.448-.681l2.55-5.418a6.04 6.04 0 00-7.867 5.759.796.796 0 01-.088.364 6.148 6.148 0 006.13 5.776z"
      />
      <Path
        fill="#ffedb3"
        d="M40.493 24.753l.003.004.008.015a.8.8 0 01.031.052l.012.025.022.046.012.033.014.04.01.04.008.036c.004.016.006.032.008.049l.004.028.003.059V25.2c0 .02 0 .04-.002.06l-.002.02-.005.036a.794.794 0 01-.045.171l-.007.019-.019.04-.015.03-.015.026c-.008.015-.016.029-.025.042l-.009.012a.794.794 0 01-.137.15l-.01.01-.022.016c-.014.01-.028.022-.043.031l-.002.002-.007.004a.8.8 0 01-.086.048l-.02.01a.79.79 0 01-.097.037l-.018.004a.795.795 0 01-.083.018l-.03.005a.807.807 0 01-.093.007H33.74a6.14 6.14 0 0012.216-.436.796.796 0 01-.088-.364 6.049 6.049 0 00-5.2-5.981.2.2 0 01-.397-.037v-.005a6.09 6.09 0 00-.444-.018 6.004 6.004 0 00-2.634.605l3.297 4.987zM32.555 16.718h-6.93l4.242 6.726 2.688-6.726zM2.93 33.083l47.03.451v-.039l-47.016-.451z"
      />
      <Path
        fill="#353535"
        d="M26.647 25.999a.8.8 0 01-.712-.436 6.14 6.14 0 01-12.258 0 .8.8 0 01-1.401.041 7.541 7.541 0 0015.06 0 .8.8 0 01-.689.395zM46.67 25.999a.8.8 0 01-.713-.436 6.14 6.14 0 01-12.216.436H32.33a7.541 7.541 0 0015.029-.395.8.8 0 01-.69.395z"
      />
      <Path
        fill="#9b9a9a"
        d="M40.832 17.625h-.16v1.556a.2.2 0 01-.003.037 6.049 6.049 0 015.2 5.98.8.8 0 001.6 0 7.651 7.651 0 00-6.637-7.573zM29.496 25.797l.016.014-.014-.012zM29.872 25.984c.014.003.029.004.043.006h-.004l-.04-.006zM29.518 25.817l.03.021-.003-.002zM29.798 25.965l.023.006h-.003zM29.75 25.949h.002-.002zM29.725 25.94l.005.002.004.001-.004-.002-.005-.001zM30.028 26l-.005-.001a.803.803 0 01-.076-.004.794.794 0 00.08.004h.002zM29.821 25.971l.022.006-.01-.003zM29.547 25.838l.023.018-.01-.008zM29.915 25.99l.02.003h-.007zM29.818 25.97l.003.001.012.003zM30.023 25.999h.006-.006z"
      />
      <Path
        fill="#9b9a9a"
        d="M29.473 25.775l.003.003.02.019.002.002zM29.473 25.775l.003.003-.003-.003zM29.73 25.941l.004.002.016.006h.001zM29.911 25.99h.004l.013.002zM29.545 25.836l.002.002.013.01zM29.284 24.901l-.007.02.012-.033zM29.649 25.904l.002.001-.002-.001zM40.619 25.317a.804.804 0 01-.045.171.794.794 0 00.045-.171zM29.57 25.856l.012.008-.01-.008zM29.843 25.977l.029.007-.026-.007zM29.355 25.633l.01.014-.01-.013zM29.613 25.883l.033.02-.016-.01zM29.935 25.993l.012.002-.01-.002zM40.629 25.199l-.001-.02v.022zM29.65 25.905h.001l.025.012a.79.79 0 00.049.023l.005.001a.798.798 0 01-.08-.036z"
      />
      <Path
        fill="#9b9a9a"
        d="M29.356 25.634l.008.013a.824.824 0 00.04.054l.006.007a.815.815 0 01-.054-.074zM29.41 25.708l.001.001h-.001v-.001zM29.751 25.95l.047.015.02.005a.8.8 0 01-.067-.02zM29.572 25.856l.01.008.031.019.017.01a.803.803 0 01-.058-.037zM29.846 25.977l.026.007.04.006a.8.8 0 01-.066-.013zM30.029 25.999h.002-.002zM29.936 25.993l.011.002c.025.002.05.004.076.004a.801.801 0 01-.087-.006zM29.498 25.799l.014.012.006.006.027.02-.047-.038zM29.334 25.598l.021.035zM29.63 25.893l.016.01.003.001h.002zM29.833 25.974l.01.003h.003zM29.56 25.848l.01.008h.002zM29.928 25.992l.007.001h.001l-.008-.001zM30.454 25.876l.01-.007c-.018.011-.037.02-.055.03.015-.007.03-.014.045-.023zM29.227 25.199v-.019zM29.325 25.583a.803.803 0 01-.056-.13.795.795 0 00.056.13zM29.41 25.709l.011.011q.025.03.052.055a.79.79 0 01-.063-.066zM40.27 25.866l.002-.002-.009.006.007-.004zM40.347 25.807a.794.794 0 00.137-.15.804.804 0 01-.137.15zM40.496 24.757l-.003-.004.011.019zM39.827 25.999H39.836 39.827z"
      />
      <Path
        fill="#c97e4a"
        d="M40.272 17.57v-2.765h-3.461a.244.244 0 00-.244.243v2.333a.244.244 0 00.244.244h2.013a7.666 7.666 0 011.004-.068c.15 0 .297.005.444.014zM40.832 17.625h4.303a.244.244 0 00.244-.244v-2.333a.244.244 0 00-.244-.243h-4.462v2.8l.16.02z"
      />
      <Path
        fill="#c97e4a"
        d="M40.673 17.625h.16l-.16-.02zM40.272 17.57a7.709 7.709 0 00-.444-.013 7.666 7.666 0 00-1.004.068h1.448z"
      />
      <Path
        fill="#9b9a9a"
        d="M40.628 25.18a.773.773 0 00-.003-.059l-.004-.028c-.002-.017-.004-.033-.008-.05l-.008-.034-.01-.04-.014-.041-.012-.033-.022-.046-.012-.025a.8.8 0 00-.03-.052l-.012-.02-.002-.002-3.297-4.987a6.004 6.004 0 012.634-.605c.15 0 .298.007.444.018v-1.55h-1.448a7.58 7.58 0 00-2.518.794L34.62 15.87l.473-1.182h.882a.8.8 0 100-1.6h-4.542a.8.8 0 100 1.6h1.935l-.172.43h-7.76l.849-1.801.002-.006.003-.006.006-.014a.794.794 0 00.023-.057l.01-.028c.004-.015.009-.03.012-.046.004-.013.006-.026.009-.04l.007-.034.005-.048.002-.029.001-.048v-.029a.794.794 0 00-.004-.046l-.003-.032-.007-.04-.008-.037-.01-.034c-.003-.014-.007-.028-.012-.041l-.011-.03a.807.807 0 00-.018-.042l-.014-.028-.021-.04-.02-.031-.022-.034-.029-.037-.019-.023a.795.795 0 00-.041-.043l-.012-.013-.005-.004-.004-.004-2.023-1.9a.8.8 0 00-1.096 1.167l1.608 1.51-2.275 4.834a7.638 7.638 0 00-10.153 7.216.8.8 0 001.6 0 6.04 6.04 0 017.867-5.759l-2.55 5.418a.8.8 0 101.448.681l2.549-5.414a6.04 6.04 0 012.768 5.074.8.8 0 001.6 0 7.644 7.644 0 00-3.681-6.534l.51-1.085 5.074 8.046.005.007-.02-.035.02.036a.815.815 0 00.055.075.79.79 0 00.063.066l.025.024q.023.02.046.037l.016.012.012.009a.787.787 0 00.058.036l.02.012a.798.798 0 00.08.036l.021.008a.8.8 0 00.067.021l.015.004.013.003a.807.807 0 00.065.013l.017.002.008.001a.801.801 0 00.087.006h.008-.002H39.835a.807.807 0 00.094-.007l.03-.005a.795.795 0 00.082-.018l.018-.004a.79.79 0 00.097-.037l.02-.01a.8.8 0 00.087-.048l.01-.006.042-.031.022-.017.01-.009a.804.804 0 00.137-.15l.008-.012c.01-.013.017-.027.026-.042l.015-.025.015-.031.019-.04.007-.019a.804.804 0 00.045-.171l.005-.035.001-.02a.798.798 0 00.003-.06v-.022zm-8.073-8.462l-2.688 6.726-4.242-6.726zm-3.266 8.17l-.012.033.007-.02zm-.062.316v-.024.024zm.042.249a.803.803 0 00.056.13.795.795 0 01-.056-.13zm1.185.423l-.045.024c.018-.01.037-.02.054-.031zm1.775-1.478h-1.02l2.687-6.72 1.075 1.625a7.637 7.637 0 00-2.742 5.095zm1.613 0a6.034 6.034 0 012.019-3.749l2.479 3.75z"
      />
      <Path
        fill="#c97e4a"
        d="M38.231 8.66a.244.244 0 00-.254.233l-.23 5.273a.244.244 0 00.233.254l2.292.1V8.75zM46.296 14.783a.244.244 0 00.254-.233l.23-5.272a.244.244 0 00-.233-.255l-5.874-.256v5.77zM43.47 8.615a.244.244 0 00.244-.244V4.848a.244.244 0 00-.244-.244h-2.797v4.011zM37.352 4.604a.244.244 0 00-.243.244v3.523a.244.244 0 00.243.244h2.92v-4.01z"
      />
      <Path
        fill="#353535"
        d="M40.473 4.262a.2.2 0 00-.2.2v14.72a.2.2 0 00.4 0V4.461a.2.2 0 00-.2-.2z"
      />
    </Svg>
  );
}
