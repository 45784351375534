import React from "react";

import { type IconName, Stack, Text } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import QualificationCategoryRow from "../category-position-qualification/QualificationCategoryRow";

import type { QualificationCategories_gigFields$key } from "./__generated__/QualificationCategories_gigFields.graphql";

export const QualificationCategories = ({
  gigFields
}: FragmentContainerInnerComponentProps<QualificationCategories_gigFields$key>) => {
  const history = useHistory();
  const fields = gigFields?.edges ?? [];
  const defaultIcon = "circle-question";

  const onRowPress = (title: string) => {
    const path = title
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/ +/g, "-")
      .toLowerCase();
    history.push(`/profile/qualifications/${path}`);
  };

  return (
    <Stack>
      <Text>
        Shifts may require specific Qualifications to work them. Select the
        Qualifications you meet to save them to your Profile. We will notify you
        of Shift opportunities based on your selections.
      </Text>
      <Stack size="compact">
        {fields?.map((field) => {
          const category = field?.node;
          const title = category?.title ?? "";
          const iconName = (category?.iconName ?? defaultIcon) as IconName;
          const selectedItems = category?.selectedDefinitions?.totalCount ?? 0;
          const availableItems =
            category?.availableDefinitions?.totalCount ?? 0;

          return (
            <QualificationCategoryRow
              key={title}
              icon={iconName}
              title={title}
              selectedItems={selectedItems}
              availableItems={availableItems}
              onPress={() => onRowPress(title)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default createRelayFragmentContainer<QualificationCategories_gigFields$key>(
  graphql`
    fragment QualificationCategories_gigFields on Worker {
      gigFields(first: 10, query: "ORDER BY order ASC") {
        edges {
          node {
            title
            key
            iconName
            selectedDefinitions: itemDefinitions(
              first: 0
              query: "WHERE status = CONFIRMED"
            ) {
              totalCount
            }
            availableDefinitions: itemDefinitions(
              first: 0
              query: "WHERE status IN [DENIED, UNKNOWN]"
            ) {
              totalCount
            }
          }
        }
      }
    }
  `,
  QualificationCategories
);
