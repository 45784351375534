import React from "react";

import {
  Button,
  ContentArea,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  showModal,
  toast
} from "@gigsmart/atorasu";
import { graphql } from "@gigsmart/relay";
import { useRelayCommand } from "../../relay/use-relay-command";
import type { UnblockOrgModalMutation } from "./__generated__/UnblockOrgModalMutation.graphql";

type Props = {
  workerBlockedOrganizationId: string;
  onClose: () => void;
  onError?: () => void;
  onSuccess?: (workerBlockedOrganizationId: string) => void;
};

function UnblockOrgModal({
  workerBlockedOrganizationId,
  onClose,
  onSuccess,
  onError
}: Props) {
  const [unblockOrganization, { loading }] =
    useRelayCommand<UnblockOrgModalMutation>(graphql`
      mutation UnblockOrgModalMutation($input: AsyncRemoveWorkerBlockedOrganizationInput!) {
        asyncRemoveWorkerBlockedOrganization(input: $input) {
          ...useRelayCommand_payload
        }
      }
    `);

  const handleAction = async () => {
    try {
      await unblockOrganization({
        input: { workerBlockedOrganizationId }
      });

      onClose();
      onSuccess?.(workerBlockedOrganizationId);
    } catch (err) {
      if (onError) onError();
      else toast.error("An error occurred while unblocking this organization.");
    }
  };

  return (
    <>
      <ModalHeader title="Are you sure you want to unblock this Organization?" />
      <ModalBody>
        <ContentArea>
          <Text>
            By unblocking, you can view and apply to available Shifts from the
            Organization.
          </Text>
        </ContentArea>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Do Not Unblock"
          onPress={onClose}
          outline
          color="primary"
          testID="cancel-unblock-button"
          disabled={loading}
        />
        <Button
          label="Unblock"
          onPress={handleAction}
          color="primary"
          testID="submit-unblock-button"
          loading={loading}
        />
      </ModalFooter>
    </>
  );
}

export function showUnblockOrgModal(props: Omit<Props, "onClose">) {
  return showModal({
    eventContext: "Unblock Org Modal",
    children: (close) => <UnblockOrgModal onClose={close} {...props} />
  });
}
