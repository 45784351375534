import useNotificationSection, {
  type NotificationSection
} from "./use-notification-section";

export default function useNotificationSections(): NotificationSection[] {
  return [
    useNotificationSection({
      isNew: true,
      key: "new",
      title: "New Notifications"
    }),
    useNotificationSection({
      isNew: false,
      key: "past",
      title: "Past Notifications"
    })
  ];
}
