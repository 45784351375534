import {
  AbsoluteContainer,
  Button,
  type Color,
  ContentArea,
  ContentRow,
  type IconName,
  IconText,
  Pressable,
  Stack,
  Text
} from "@gigsmart/atorasu";
import React, { type ReactElement, type ReactNode } from "react";
import useSummaryRowEdit, {
  type CheckFn,
  type EditableField
} from "./useSummaryRowEdit";

type NotEditable = {
  field?: never;
  editVariant: "none";
};
type IconVariantProps = {
  field: EditableField | CheckFn;
  editVariant?: "icon";
};
type ButtonVariantProps = {
  field: EditableField | CheckFn;
  editVariant: "button";
  editButtonIcon?: IconName;
  editButtonText: string;
  editButtonOutline?: boolean;
  editButtonVariant?: "solid" | "clear" | "rounded";
  editButtonDisabled?: boolean;
};

type VariantProps = NotEditable | IconVariantProps | ButtonVariantProps;

type Props = VariantProps & {
  icon: IconName;
  route?: string;
  title: string;
  children?: ReactNode;
  iconColor?: Color;
  testID?: string;
  missing?: ReactNode;
  onEditButtonPress?: () => void;
  onEditPressDisabled?: () => void;
  renderInfoModal?: () => ReactElement | null;
  rightNode?: ReactNode;
};

export default function SummaryRow({
  icon,
  field,
  title,
  renderInfoModal,
  iconColor = "primary",
  children,
  route,
  testID,
  editVariant = "icon",
  missing,
  onEditButtonPress,
  onEditPressDisabled,
  rightNode,
  ...rest
}: Props) {
  const [editable, onEdit] = useSummaryRowEdit(field, route);

  rightNode ??= missing ? (
    <Stack horizontal={true} justifyContent="flex-end" alignSelf="stretch">
      <IconText
        color="danger"
        icon="triangle-exclamation"
        iconColor="danger"
        iconVariant="solid"
        spacing="medium"
      >
        Missing
      </IconText>
      <Button
        testID="add-btn"
        icon="plus"
        label="Add"
        size="small"
        color="danger"
        onPress={onEditButtonPress ?? onEdit ?? undefined}
      />
    </Stack>
  ) : editable && onEdit ? (
    {
      none: null,
      icon: (
        <Pressable
          testID={`${testID}-edit-icon`}
          eventEntityType="Edit Button"
          eventTargetName="Edit Button"
          onPress={onEditButtonPress ?? onEdit}
        >
          <IconText color="primary" icon="pen-to-square" size="small">
            Edit
          </IconText>
        </Pressable>
      ),
      button: (
        <Button
          onPress={onEditButtonPress ?? onEdit}
          onPressDisabled={onEditPressDisabled}
          testID={`${testID}-edit-button`}
          label={"editButtonText" in rest ? rest.editButtonText : ""}
          icon={"editButtonIcon" in rest ? rest.editButtonIcon : undefined}
          size="small"
          color="primary"
          variant={
            "editButtonVariant" in rest ? rest.editButtonVariant : undefined
          }
          outline={"editButtonOutline" in rest ? rest.editButtonOutline : true}
          disabled={
            "editButtonDisabled" in rest ? rest.editButtonDisabled : true
          }
        />
      )
    }[editVariant]
  ) : null;

  return (
    <ContentRow
      icon={icon}
      verticalSize="standard"
      alignIcon="top"
      testID={testID}
    >
      <Stack size="compact" fill>
        <Text color={iconColor} variant="header">
          {title}
        </Text>
        {children}
      </Stack>
      {rightNode && (
        <AbsoluteContainer size="small" left="auto" right={0} top={0}>
          <ContentArea>{rightNode}</ContentArea>
        </AbsoluteContainer>
      )}
    </ContentRow>
  );
}
