/**
 * @generated SignedSource<<3e9b7e9e664812ae77e406b7ac928e20>>
 * @relayHash 680c5656225e2bab05e0854fc67b157a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:YCqadJvoBbUjt-L-SSknfIPRuF9n60ghzM82qUPKNmM

import { ConcreteRequest } from 'relay-runtime';
export type useLoginUpdateWorkerMutation$variables = {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
};
export type useLoginUpdateWorkerMutation$data = {
  readonly updateWorker: {
    readonly worker: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    };
  } | null | undefined;
};
export type useLoginUpdateWorkerMutation = {
  response: useLoginUpdateWorkerMutation$data;
  variables: useLoginUpdateWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastName"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoginUpdateWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLoginUpdateWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:YCqadJvoBbUjt-L-SSknfIPRuF9n60ghzM82qUPKNmM",
    "metadata": {},
    "name": "useLoginUpdateWorkerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d66e64a423fe1c9a65b82cd0fd1cd026";

export default node;
