import { asEnum, asEnums, neq, where } from "@gigsmart/biruda";
import type {
  EngagementStateName,
  GigStateName
} from "@gigsmart/isomorphic-shared/gig/helpers";

export type GigType = "PAID" | "VOLUNTEER" | "PROJECT";

export const offeredSeriesEngagementsQuery = (
  workerId?: string | null,
  gigType?: string | null
) => {
  let queryObj = where({
    currentStateName: asEnums(["OFFERED", "BID_REQUESTED"])
  });
  if (workerId) queryObj = queryObj.and({ workerId });
  if (gigType && gigType !== "ALL")
    queryObj = queryObj.and({ gigType: asEnum(gigType) });
  queryObj = queryObj
    .orderBy("startsAt", "ASC NULLS FIRST")
    .orderBy("endsAt", "ASC");

  return queryObj.toString();
};

export const listSeriesQuery = (
  tab: "applied" | "hired",
  gigType?: GigType[]
) => {
  let query = where({
    anyEngagementCurrentStateName:
      tab === "applied"
        ? asEnums(["APPLIED", "CONFIRMING", "ENGAGED", "HIRE_REQUESTED"])
        : asEnums(["SCHEDULED", "PENDING_TIMESHEET_APPROVAL", "PENDING_REVIEW"])
  });
  if (gigType) query = query.and({ gigType: asEnums(gigType) });
  query = query.and({
    anyGigCurrentStateName:
      tab === "applied"
        ? asEnums(["UPCOMING", "ACTIVE", "IN_PROGRESS"])
        : asEnums(["UPCOMING", "IN_PROGRESS", "PENDING_REVIEW"])
  });

  if (gigType?.includes("PROJECT") && tab === "hired")
    return query.orderBy("expiresAt", "ASC").toString();

  return query
    .orderBy("startsAt", "ASC NULLS FIRST")
    .orderBy("endsAt", "ASC")
    .toString();
};

export const seriesEngagementQuery = (
  tab: "applied" | "hired" | "complete" | "incomplete",
  workerId: string
) => {
  switch (tab) {
    case "applied":
      return where({
        currentStateName: asEnum<EngagementStateName>("APPLIED"),
        workerId
      })
        .orderBy("actualStartsAt", "ASC")
        .toString();

    case "hired":
      return where({
        gigStateName: asEnum<GigStateName>("UPCOMING"),
        currentStateName: asEnum<EngagementStateName>("SCHEDULED"),
        workerId
      })
        .or({
          currentStateName: asEnums<EngagementStateName>([
            "ENDED",
            "PENDING_TIMESHEET_APPROVAL"
          ]),
          ratingOfRequester: null,
          workerId
        })
        .orderBy("actualStartsAt", "ASC")
        .toString();

    case "complete":
      return where({
        currentStateName: asEnum<EngagementStateName>("ENDED"),
        ratingOfRequester: neq(null),
        workerId
      })
        .orderBy("actualStartsAt", "ASC")
        .toString();

    case "incomplete":
      return where({
        currentStateName: asEnums<EngagementStateName>([
          "CANCELED",
          "REJECTED",
          "APPLICATION_CANCELED",
          "APPLICATION_DENIED"
        ])
      }).toString();
  }
};
