import { Linking, Platform } from "react-native";

const APP_STORE_LINKS = {
  worker: Platform.select({
    default: {
      appLink: "https://getgigs.onelink.me/cD42/f5yexsjs",
      browserLink: "https://gigsmart.com/find-work/get-gigs/"
    },
    android: {
      appLink: "market://details?id=com.gigsmart.worker",
      browserLink:
        "https://play.google.com/store/apps/details?id=com.gigsmart.worker"
    },
    ios: {
      appLink:
        "itms-apps://itunes.apple.com/us/app/apple-store/id1321576690?mt=8",
      browserLink:
        "https://itunes.apple.com/us/app/gigsmart-get-gigs/id1321576690?mt=8"
    }
  }),
  requester: Platform.select({
    default: {
      appLink: "https://getworkers.gigsmart.com",
      browserLink: "https://getworkers.gigsmart.com"
    },
    android: {
      appLink: "market://details?id=com.gigsmart.requester",
      browserLink:
        "https://play.google.com/store/apps/details?id=com.gigsmart.requester"
    },
    ios: {
      appLink:
        "itms-apps://itunes.apple.com/us/app/apple-store/id1321638274?mt=8",
      browserLink:
        "https://itunes.apple.com/us/app/gigsmart-get-workers/id1321638274?mt=8"
    }
  })
};

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    window.navigator?.userAgent ?? ""
  );

export const openAppStore = async (app: "requester" | "worker") => {
  const { appLink, browserLink } = APP_STORE_LINKS[app];
  const link = isMobileDevice() ? appLink : browserLink;
  Linking.openURL(link).catch(() => void Linking.openURL(browserLink));
};
