/**
 * @generated SignedSource<<cff6b299c1a2991e5910fbb0a9b62ccd>>
 * @relayHash 8ef12ebf725ea694dd8800b5c2b84883
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:weBWglYUM1c0fYaBn6fVe5yhFLMrTUw5U-ATTGx0VbY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type manageMilitaryExpScreenQuery$variables = {
  id: string;
};
export type manageMilitaryExpScreenQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ManageMilitaryExperienceForm_military">;
  } | null | undefined;
};
export type manageMilitaryExpScreenQuery = {
  response: manageMilitaryExpScreenQuery$data;
  variables: manageMilitaryExpScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "manageMilitaryExpScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ManageMilitaryExperienceForm_military"
              }
            ],
            "type": "WorkerMilitaryService",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "manageMilitaryExpScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "militaryIdLast4",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceEnd",
                "storageKey": null
              }
            ],
            "type": "WorkerMilitaryService",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:weBWglYUM1c0fYaBn6fVe5yhFLMrTUw5U-ATTGx0VbY",
    "metadata": {},
    "name": "manageMilitaryExpScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ab899f87a5306a540ee39817eb5b8761";

export default node;
