/**
 * @generated SignedSource<<6283807ea2527856d94af8cb3735cb2c>>
 * @relayHash 8a9b1912b823b3c61eccd5a339a1dd87
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:mF7eKmpGqEykGOsI1x6v-sFxEyExkD8R_f8v-mnZEiQ

import { ConcreteRequest } from 'relay-runtime';
export type AddUserDocumentConsentInput = {
  consentSignature: string;
  filename: string;
};
export type useLegalDocumentMutation$variables = {
  input: AddUserDocumentConsentInput;
};
export type useLegalDocumentMutation$data = {
  readonly addUserDocumentConsent: {
    readonly userConsentDocument: {
      readonly filename: string;
    } | null | undefined;
  } | null | undefined;
};
export type useLegalDocumentMutation = {
  response: useLegalDocumentMutation$data;
  variables: useLegalDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLegalDocumentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddUserDocumentConsentPayload",
        "kind": "LinkedField",
        "name": "addUserDocumentConsent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConsentDocument",
            "kind": "LinkedField",
            "name": "userConsentDocument",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLegalDocumentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddUserDocumentConsentPayload",
        "kind": "LinkedField",
        "name": "addUserDocumentConsent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConsentDocument",
            "kind": "LinkedField",
            "name": "userConsentDocument",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:mF7eKmpGqEykGOsI1x6v-sFxEyExkD8R_f8v-mnZEiQ",
    "metadata": {},
    "name": "useLegalDocumentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "ea92026520084a4b9c1875267d0f5002";

export default node;
