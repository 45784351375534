import {
  Column,
  FooterContent,
  Icon,
  type IconName,
  NavAction,
  ScreenScroll,
  Spacer,
  Text,
  defaultCancelPrompt,
  useHistoryBlock
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useFormSubmit } from "@gigsmart/fomu";
import { NavPortalEntrance, defaultBackHandler } from "@gigsmart/kaizoku";
import { type StepProps, StyledButton } from "@gigsmart/katana";
import React, { type ReactNode, useCallback } from "react";
import { TouchableOpacity, View } from "react-native";

type Props = StepProps & {
  testID: string;
  title: string;
  description?: string;
  showBack?: boolean;
  backText: string;
  backIcon: IconName;
  children: ReactNode;
  renderNextButton: (arg0: {
    hasNext: StepProps["hasNext"];
    nextStep: StepProps["nextStep"];
  }) => ReactNode;
  scroll: boolean;
  stickyFooter?: boolean;
};

export default function StepDetail({
  testID,
  title,
  description,
  children,
  nextStep,
  hasPrev,
  hasNext,
  prevStep,
  showBack,
  backText,
  backIcon,
  stepData,
  scroll,
  renderNextButton,
  stickyFooter
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    header: {
      paddingHorizontal: getUnits(6),
      paddingBottom: getUnits(3)
    },
    headerTitleRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: getUnits(1)
    },
    childrenWrapper: {
      flex: 1,
      marginBottom: "auto"
    }
  }));

  const content = (
    <>
      <View style={styles.header}>
        <View style={styles.headerTitleRow}>
          <Text
            testID="step-detail-title"
            weight="bold"
            variant="bodyLg"
            color="primary"
          >
            {title}
          </Text>
          {hasPrev && showBack && (
            <TouchableOpacity onPress={() => prevStep(stepData)}>
              <Text color="primary">
                <Icon
                  variant="solid"
                  size="medium"
                  name={backIcon}
                  color="primary"
                />
                &nbsp;{backText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        {description && <Text color="neutral">{description}</Text>}
      </View>
      <View style={styles.childrenWrapper}>{children}</View>
    </>
  );

  const footer = (
    <FooterContent inline={!stickyFooter} bgSolid size="xsmall">
      {renderNextButton({ hasNext, nextStep })}
    </FooterContent>
  );

  const { dirty } = useFormSubmit();

  useHistoryBlock({
    enabled: dirty,
    onConfirm: () => {
      if (hasPrev) prevStep();
      else defaultBackHandler({ safeExit: true });
      return true;
    }
  });

  const handleCancel = useCallback(async () => {
    if (await defaultCancelPrompt()) {
      defaultBackHandler({ safeExit: true });
    }
  }, []);

  return (
    <Column fill>
      <NavPortalEntrance
        rightAccessory={
          <NavAction
            testID="create-gig-stepper-cancel-btn"
            onPress={handleCancel}
          >
            <Text weight="bold">Cancel</Text>
          </NavAction>
        }
      />
      <ScreenScroll
        grow
        testID={testID}
        scrollEnabled={scroll}
        keyboardDismissMode={stickyFooter ? "on-drag" : "interactive"}
        extraHeight={90}
      >
        <Spacer />
        {content}
        {!stickyFooter && footer}
      </ScreenScroll>
      {stickyFooter && footer}
    </Column>
  );
}

StepDetail.defaultProps = {
  scroll: true,
  backIcon: "chevron-square-left" as IconName,
  backText: "Back",
  renderNextButton: (({ hasNext, nextStep }) => (
    <StyledButton
      testID="step-detail-next-step-button"
      title={hasNext ? "Continue" : "Complete"}
      onPress={nextStep}
    />
  )) as Props["renderNextButton"]
};
