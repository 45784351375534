import React from "react";

import {
  Button,
  Card,
  ContentArea,
  Divider,
  FancyHeader,
  GridNull,
  Text
} from "@gigsmart/atorasu";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import { useHistory } from "@gigsmart/kaizoku";

export default function CompleteProfileHomeCard() {
  const history = useHistory();
  const [completeProfileCardShown, setCompleteProfileCardShown, isLoading] =
    useUserValue<boolean>("WORKER_HOMESCREEN_COMPLETE_PROFILE_CARD");

  if (completeProfileCardShown || isLoading) return <GridNull />;

  return (
    <Card
      testID="complete-profile-home-card"
      eventTargetName="Complete Profile Home Card"
    >
      <FancyHeader
        icon="user"
        testID="complete-profile-header"
        title="Complete Your Profile"
        onDismiss={async () => await setCompleteProfileCardShown(true)}
      />
      <ContentArea>
        <Text>
          Completing your profile is an important step to help you get hired for
          more Shifts. Give Requesters some background on your skills, work
          history, and more!
        </Text>
      </ContentArea>
      <Divider />
      <Button
        icon="chevron-right"
        iconPlacement="right"
        label="View Profile"
        onPress={async () => history.push("/profile")}
        color="primary"
        variant="clear"
        size="small"
        testID="complete-profile-home-card-button"
      />
    </Card>
  );
}
