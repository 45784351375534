/**
 * @generated SignedSource<<7bf590a2ca4ce2c06402b9a5aeebc227>>
 * @relayHash 9c38e26e4c6c9d725bf79f7a2f20261d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:3vpiyRl4S2PO5NHihC1OutO7VH8MCoyxQ1NTryZthZM

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type UpdateWorkerInput = {
  administrativeArea1?: string | null | undefined;
  allowLegalDuplicates?: boolean | null | undefined;
  biography?: string | null | undefined;
  birthdate?: string | null | undefined;
  demographic?: string | null | undefined;
  disableOtp?: boolean | null | undefined;
  firstName?: string | null | undefined;
  hubspotVid?: string | null | undefined;
  isApproved?: boolean | null | undefined;
  isAvailable?: boolean | null | undefined;
  isVolunteer?: boolean | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  minimumHourlyRate?: string | null | undefined;
  postalCode?: string | null | undefined;
  preferredTimeZone?: string | null | undefined;
  referralSource?: string | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
  transportationMethod?: string | null | undefined;
  transportationState?: WorkerTransportationState | null | undefined;
  workerId?: string | null | undefined;
};
export type SetWorkerLegalInformationInput = {
  administrativeArea1?: string | null | undefined;
  backgroundCheckOptIn?: boolean | null | undefined;
  birthdate?: string | null | undefined;
  driverCheckOptIn?: boolean | null | undefined;
  driversLicenseId?: string | null | undefined;
  driversLicenseIssuer?: string | null | undefined;
  drugScreenOptIn?: boolean | null | undefined;
  emailAddress?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  middleName?: string | null | undefined;
  nationalIdNumber?: string | null | undefined;
  postalCode?: string | null | undefined;
  pspTosAccepted?: boolean | null | undefined;
  sendReportCopies?: boolean | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type useEnrollmentMutation$variables = {
  input: SetWorkerLegalInformationInput;
  updateWorkerInput: UpdateWorkerInput;
};
export type useEnrollmentMutation$data = {
  readonly setWorkerLegalInformation: {
    readonly workerLegalInformation: {
      readonly " $fragmentSpreads": FragmentRefs<"useEnrollmentData_legalInformation">;
    } | null | undefined;
    readonly workerVerification: {
      readonly " $fragmentSpreads": FragmentRefs<"useEnrollmentData_verification">;
    } | null | undefined;
  } | null | undefined;
  readonly updateWorker: {
    readonly worker: {
      readonly birthdate: string | null | undefined;
    };
  } | null | undefined;
};
export type useEnrollmentMutation = {
  response: useEnrollmentMutation$data;
  variables: useEnrollmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updateWorkerInput"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendReportCopies",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "middleName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "street2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locality",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "administrativeArea1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "emailAddress",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postalCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "backgroundCheckOptIn",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "driverCheckOptIn",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "driversLicenseLast4",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "driversLicenseIssuer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "drugScreenOptIn",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "pspTosAccepted",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "VerificationReport",
    "kind": "LinkedField",
    "name": "reports",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statusDescription",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "updateWorkerInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useEnrollmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SetWorkerLegalInformationPayload",
        "kind": "LinkedField",
        "name": "setWorkerLegalInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerLegalInformation",
            "kind": "LinkedField",
            "name": "workerLegalInformation",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "useEnrollmentData_legalInformation",
                "selections": (v5/*: any*/),
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerVerification",
            "kind": "LinkedField",
            "name": "workerVerification",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "useEnrollmentData_verification",
                "selections": (v7/*: any*/),
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useEnrollmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SetWorkerLegalInformationPayload",
        "kind": "LinkedField",
        "name": "setWorkerLegalInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerLegalInformation",
            "kind": "LinkedField",
            "name": "workerLegalInformation",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerVerification",
            "kind": "LinkedField",
            "name": "workerVerification",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:3vpiyRl4S2PO5NHihC1OutO7VH8MCoyxQ1NTryZthZM",
    "metadata": {},
    "name": "useEnrollmentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3c90e666e2531b962a9c3250d8acf26b";

export default node;
