import React from "react";
import { TouchableOpacity, View } from "react-native";

import { Link } from "@gigsmart/kaizoku";

import Card from "../container/card";
import { ViewportRenderer, useStyles } from "../style";
import { StyledText } from "../text";

interface Props {
  showModal: boolean;
  closeModal: () => void;
  options: Array<{
    label: string;
    linkTo?: string;
    onPress?: () => void;
    destructive?: boolean;
    testID?: string;
  }>;
}

export default function ActionSheetModal({
  showModal,
  closeModal,
  options
}: Props) {
  const { styles, theme } = useStyles(({ units, color }) => ({
    card: {
      backgroundColor: color.white,
      borderRadius: 5,
      bottom: 42,
      display: "none",
      top: units(25),
      right: 0,
      height: options.length * units(12),
      marginHorizontal: 0,
      position: "absolute",
      zIndex: 12,
      flexDirection: "column",
      justifyContent: "center"
    },
    cardAfter: {
      position: "absolute",
      width: units(6),
      height: units(6),
      backgroundColor: color.white,
      top: units(-3),
      right: units(4),
      zIndex: 11,
      transform: [{ rotate: "45deg" }]
    },
    flex: {
      display: "flex"
    },
    link: {
      height: units(12),
      flex: 1,
      justifyContent: "center",
      padding: units(3)
    },
    topLink: {
      borderColor: color.neutralLight,
      borderBottomWidth: 0.8
    },

    invisibleCardBack: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: color.transparent,
      zIndex: 10,
      position: "fixed" as any,
      cursor: "default"
    }
  }));
  return (
    <ViewportRenderer
      renderableBreakpoints={[
        "@tablet",
        "@tabletDown",
        "@desktop",
        "@desktopWide"
      ]}
    >
      {showModal && (
        <TouchableOpacity
          style={styles.invisibleCardBack}
          onPress={closeModal}
        />
      )}
      <Card
        style={[styles.card, showModal && styles.flex]}
        testID="action-sheet-modal"
      >
        <View style={styles.cardAfter} />
        {options.map((option, index) => (
          <View
            style={[styles.link, index === 0 && styles.topLink]}
            key={option.label}
          >
            {option.linkTo ? (
              <Link testID="action-sheet-link" to={option.linkTo}>
                <StyledText
                  fontSize={theme.font.size.large}
                  color={option.destructive ? "orange" : "blue"}
                  testID={option.testID}
                >
                  {option.label}
                </StyledText>
              </Link>
            ) : (
              <TouchableOpacity onPress={option.onPress}>
                <StyledText
                  fontSize={theme.font.size.large}
                  color={option.destructive ? "orange" : "blue"}
                  testID={option.testID}
                >
                  {option.label}
                </StyledText>
              </TouchableOpacity>
            )}
          </View>
        ))}
      </Card>
    </ViewportRenderer>
  );
}
