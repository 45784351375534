import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ManageMilitaryExperienceForm from "@gigsmart/seibutsu/worker-profile/ManageMilitaryExperienceForm";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { manageMilitaryExpScreenQuery } from "./__generated__/manageMilitaryExpScreenQuery.graphql";

type Props = AppScreenProps<
  WorkerParamList,
  "ProfileAddMilitary" | "ProfileEditMilitary"
>;

export default createSuspendedQueryContainer<
  manageMilitaryExpScreenQuery,
  Props
>(
  function ManageMilitaryExperienceScreen({ response }) {
    return <ManageMilitaryExperienceForm fragmentRef={response?.node} />;
  },
  {
    query: graphql`
      query manageMilitaryExpScreenQuery($id: ID!) {
        node(id: $id) {
          ... on WorkerMilitaryService {
            ...ManageMilitaryExperienceForm_military
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params?.militaryId ?? "" })
  }
);
