import {
  Button,
  Column,
  ContentArea,
  Divider,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { CrisisGigs, HourlyRateBids } from "@gigsmart/feature-flags";
import {
  stateIsComplete,
  stateIsIncomplete
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { getReportTypes } from "@gigsmart/isomorphic-shared/report/dictionary";
import { Card, StyledLink, SummaryRow } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import { showEngagementBidHistoryModal } from "@gigsmart/seibutsu/engagement/bids/EngagementBidHistoryModal";
import CategorySummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/CategorySummaryRow";
import GigPositionSummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/GigPositionSummaryRow";
import QualificationSummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/QualificationSummaryRow";
import WorkerShiftScheduleSummaryRow from "@gigsmart/seibutsu/gig/SummaryRows/WorkerShiftScheduleSummaryRow";
import React, { useMemo } from "react";
import { View } from "react-native";
import type { gigSummaryRows_availableGig$key } from "./__generated__/gigSummaryRows_availableGig.graphql";
import type { gigSummaryRows_availableGigSeries$key } from "./__generated__/gigSummaryRows_availableGigSeries.graphql";
import type { gigSummaryRows_engagement$key } from "./__generated__/gigSummaryRows_engagement.graphql";
import type { gigSummaryRows_gig$key } from "./__generated__/gigSummaryRows_gig.graphql";
import GigPaymentRows from "./gig-payment-rows";
import ProjectSummaryDateTime from "./project-summary-date-time";
import SummaryLocation from "./summary-location";
import SummaryPayment from "./summary-payment";
import SummaryPaymentType from "./summary-payment-type";

interface Props {
  availableGigRef?: gigSummaryRows_availableGig$key | null | undefined;
  availableGigSeriesRef?:
    | gigSummaryRows_availableGigSeries$key
    | null
    | undefined;
  engagementRef?: gigSummaryRows_engagement$key | null | undefined;
  hidePaymentDetailsRow?: boolean;
}
const GigSummaryRows = ({
  availableGigRef,
  availableGigSeriesRef,
  engagementRef,
  hidePaymentDetailsRow = false
}: Props) => {
  const availableGigSeries =
    useRelayFragment<gigSummaryRows_availableGigSeries$key>(
      graphql`
        fragment gigSummaryRows_availableGigSeries on AvailableGigSeriesEdge {
          node {
            id
            hasMultipleLocations
            nextGig: availableGigs(first: 1) {
              edges {
                ...gigSummaryRows_availableGig
              }
            }
          }
        }
      `,
      availableGigSeriesRef ?? null
    );
  const series = availableGigSeries?.node;
  const seriesNextGig = series?.nextGig?.edges?.[0];
  const availableGig = useRelayFragment<gigSummaryRows_availableGig$key>(
    graphql`
      fragment gigSummaryRows_availableGig on AvailableGigsEdge {
        distance
        node {
          ...gigSummaryRows_gig
        }
        ...gigPaymentRows_availableGig
      }
    `,
    seriesNextGig ?? availableGigRef ?? null
  );

  const engagement = useRelayFragment<gigSummaryRows_engagement$key>(
    graphql`
      fragment gigSummaryRows_engagement on Engagement {
        ...WorkerShiftScheduleSummaryRow_engagement
        id
        gig {
          placeId
          place {
            streetAddress
            location {
              latitude
              longitude
            }
          }

          ...gigSummaryRows_gig
        }
        workerDistance
        currentState {
          name
        }
        currentBid {
          estimatedAmount
          expectedDuration
          note
          payRate
          paySchedule
        }
        ...gigPaymentRows_engagement
      }
    `,
    engagementRef ?? null
  );

  const gig = useRelayFragment<gigSummaryRows_gig$key>(
    graphql`
      fragment gigSummaryRows_gig on Gig {
        ...projectSummaryDateTime_gig
        ...summaryPaymentType_gig
        ...CategorySummaryRow_gigLike
        ...GigPositionSummaryRow_gigLike
        ...WorkerShiftScheduleSummaryRow_gig
        id
        arrivalInstructions
        gigType
        estimatedMileage
        area
        payRate
        crisis {
          title
        }
        category {
          displayName
        }
        requiredReportTypes
        skills(first: 10) {
          edges {
            node {
              skill {
                name
              }
            }
          }
        }
        position {
          name
        }
        pickupEligible
      }
    `,
    availableGig?.node ?? engagement?.gig ?? null
  );

  const [gigArea, gigAddress, isAddressVisible, arrivalInstructions] =
    useMemo(() => {
      const currentStateName = engagement?.currentState?.name ?? "";
      return engagement?.gig?.place?.streetAddress &&
        currentStateName &&
        ![
          "APPLIED",
          "APPLIED_UNAVAILABLE",
          "APPLICATION_DENIED",
          "REJECTED",
          "MISSED",
          "CANCELED"
        ].includes(currentStateName)
        ? [
            gig?.area,
            engagement?.gig?.place?.streetAddress,
            true,
            gig?.arrivalInstructions
          ]
        : [gig?.area, null, false, null];
    }, [
      engagement?.currentState?.name,
      engagement?.gig?.place?.streetAddress,
      gig?.area,
      gig?.arrivalInstructions
    ]);

  const styles = useStyles(({ getColor, getUnits }) => ({
    rowBorder: {
      marginLeft: getUnits(4),
      borderBottomWidth: 1,
      borderBottomColor: getColor("background", "fill")
    },
    marginBottom: {
      marginBottom: getUnits(2)
    }
  }));

  if (!gig) return null;

  const crisis = gig.crisis;
  const volunteer = gig.gigType === "VOLUNTEER";
  const isProject = gig.gigType === "PROJECT";
  const currentState = engagement?.currentState?.name;
  const historyGig =
    stateIsComplete(currentState) || stateIsIncomplete(currentState);
  const requiredReportTypes = getReportTypes([...gig.requiredReportTypes]);

  return (
    <Card noMargin noSpacing noRadius>
      {CrisisGigs.isEnabled() && crisis && (
        <SummaryRow
          sectionContentTestID="skill-name"
          iconName="house-chimney-crack"
          iconColor="orange"
          sectionLabel="Crisis"
          sectionContent={crisis.title}
        />
      )}
      {!isProject && gig.position?.name && (
        <>
          <CategorySummaryRow fragmentRef={gig} icon="grid" />
          <Divider color="background" />
          <GigPositionSummaryRow fragmentRef={gig} />
          <Divider color="background" />
          <QualificationSummaryRow
            nodeId={availableGigSeries?.node?.id ?? gig?.id ?? ""}
          />
          <Divider />
        </>
      )}
      {isProject && (
        <SummaryRow
          sectionContentTestID="category-name"
          iconName="box-check"
          sectionLabel="Category"
          sectionContent={gig?.category?.displayName}
        />
      )}
      <SummaryLocation
        distance={engagement?.workerDistance ?? availableGig?.distance}
        hasMultipleLocations={series?.hasMultipleLocations}
        eta={!isAddressVisible && !currentState}
        etaGigId={gig?.id}
        isProject={gig.gigType === "PROJECT"}
        history={historyGig}
        address={gigAddress}
        area={gigArea}
        location={{
          placeId: engagement?.gig?.placeId,
          latitude: engagement?.gig?.place?.location?.latitude ?? null,
          longitude: engagement?.gig?.place?.location?.longitude ?? null
        }}
        arrivalInstructions={arrivalInstructions ?? ""}
      />
      {isProject ? (
        <ProjectSummaryDateTime gig={gig} />
      ) : (
        <WorkerShiftScheduleSummaryRow
          nodeId={availableGigSeries?.node?.id ?? ""}
          gigRef={gig}
          engagementRef={engagement}
        />
      )}
      <SummaryPayment
        isProject={isProject}
        isVolunteer={volunteer}
        payRate={gig?.payRate}
        currentBid={engagement?.currentBid}
        stateName={engagement?.currentState?.name}
        pickupEligible={gig?.pickupEligible}
      />
      {HourlyRateBids.isEnabled() && engagementRef && !isProject && (
        <ContentArea size="none" variant="xlarge">
          <Row>
            <Button
              size="small"
              outline
              label="View Bid History"
              testID={""}
              onPress={() =>
                showEngagementBidHistoryModal({
                  engagementId: engagement?.id ?? ""
                })
              }
            />
          </Row>
          <Spacer />
        </ContentArea>
      )}
      {!volunteer && !isProject && !historyGig && !hidePaymentDetailsRow && (
        <GigPaymentRows availableGig={availableGig} engagement={engagement} />
      )}
      {isProject && <SummaryPaymentType gig={gig} historyGig={historyGig} />}
      {!!requiredReportTypes?.length && (
        <>
          <View style={styles.rowBorder} />
          <SummaryRow
            iconName="shield-check"
            sectionLabel="Verification"
            sectionContent={
              <Column fill={1}>
                <Text style={styles.marginBottom}>
                  {requiredReportTypes
                    .map(({ requirementText }) => requirementText)
                    .join(", ") || ""}
                </Text>
                <StyledLink
                  fontSize={12}
                  openURL="https://gigsmart.com/workers/get-verified/"
                >
                  Verified Workers Info
                </StyledLink>
              </Column>
            }
          />
        </>
      )}
    </Card>
  );
};

export default GigSummaryRows;
