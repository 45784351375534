import { Column } from "@gigsmart/atorasu";
import {
  ShiftGigsMapScreen,
  UnlimitedDistanceFeature
} from "@gigsmart/feature-flags";
import { type AppScreenProps, NavPortalEntrance } from "@gigsmart/kaizoku";
import { IconButton, SmallOutlineButton } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { showShiftGigInfoModal } from "@gigsmart/seibutsu/modals/ShiftGigsInfoModalTabs";
import { compact } from "lodash";
import React, { useEffect, useState } from "react";
import { useGeolocation } from "../../geolocation";
import type { WorkerParamList } from "../../navigation/types";
import AvailableGigsList from "../available-gigs-list";
import AvailablePickupShiftsList from "../available-pickup-shifts-list";
import { useBrowseGigsQuery } from "../browse-screen-base/browse-gigs.gql";
import BrowseGigsSearch, {
  type SearchFilter
} from "../browse-screen-base/search";
import useSearchDistance from "../use-search-distance";
import type { shiftGigsScreenQuery } from "./__generated__/shiftGigsScreenQuery.graphql";
import ShiftGigsMap from "./shift-gigs-map";

export const initialFilters: SearchFilter = {
  searchTerm: "",
  location: { label: "", lat: 0, lng: 0 },
  radiusMiles: 75,
  gigTypes: ["PAID", "VOLUNTEER"]
};

type Props = AppScreenProps<WorkerParamList, "BrowseShifts">;

export default createSuspendedQueryContainer<shiftGigsScreenQuery, Props>(
  ({ response: result, route, navigation }) => {
    const [mapView, setMapview] = useState(!!route.params.region);
    const isPickupShifts = route.params.type === "pickups";
    const { distance, persistDistance, hasFetchedDistance } = useSearchDistance(
      UnlimitedDistanceFeature.isEnabled() ? 12425 : 75
    );
    const [filter, setFilter] = useState<SearchFilter>({
      ...initialFilters
    });
    const { crisis } = useBrowseGigsQuery(isPickupShifts);

    const currentLocation = useGeolocation();

    useEffect(() => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        radiusMiles: distance ?? 75,
        location: {
          lat: currentLocation?.latitude ?? initialFilters.location.lat,
          lng: currentLocation?.longitude ?? initialFilters.location.lng,
          label:
            compact([currentLocation?.area, currentLocation?.postalCode]).join(
              " "
            ) ?? initialFilters.location.label
        }
      }));
    }, [
      currentLocation?.area,
      currentLocation?.latitude,
      currentLocation?.longitude,
      currentLocation?.postalCode,
      distance
    ]);

    //  TODO::: Update distance filter to all (12425) when hiding it

    const renderButton = () => {
      return ShiftGigsMapScreen.isEnabled() ? (
        <SmallOutlineButton
          icon={mapView ? "list-ul" : "location-dot"}
          title={mapView ? "View List" : "View Map"}
          onPress={() => setMapview(!mapView)}
        />
      ) : null;
    };

    if (!hasFetchedDistance) return null;

    return (
      <Column fill>
        <NavPortalEntrance
          rightAccessory={() => (
            <IconButton
              diameter={56}
              icon="circle-question"
              iconColor="white"
              iconVariant="regular"
              onPress={() => showShiftGigInfoModal()}
            />
          )}
        />
        <BrowseGigsSearch
          collapsed={mapView}
          filter={filter}
          crisis={crisis}
          onClearCrisis={() => navigation.setParams({})}
          onChange={(filter) => {
            persistDistance(filter.radiusMiles);
            initialFilters.radiusMiles = filter.radiusMiles;
            setFilter(filter);
          }}
          renderTopComponent={renderButton}
          isPickupShifts={isPickupShifts}
        />
        {mapView ? (
          <ShiftGigsMap filter={filter} isPickupShifts={isPickupShifts} />
        ) : isPickupShifts ? (
          <AvailablePickupShiftsList
            viewerFragment={result?.viewer ?? null}
            filter={{ ...filter, radiusMiles: distance ?? 75 }}
          />
        ) : (
          <AvailableGigsList
            viewerFragment={result?.viewer ?? null}
            filter={{ ...filter, radiusMiles: distance ?? 75 }}
          />
        )}
      </Column>
    );
  },
  {
    query: graphql`
      query shiftGigsScreenQuery(
        $maxDistance: Int!
        $crisisId: ID
        $searchTerm: String
        $gigTypes: [GigType!]
      ) {
        viewer {
          ... on Worker {
            ...availableGigsList_GigSeries
              @arguments(
                maxDistance: $maxDistance
                crisisId: $crisisId
                searchTerm: $searchTerm
                gigTypes: $gigTypes
              )
            ...availablePickupShiftsList_availableGigs
              @arguments(
                maxDistance: $maxDistance
                crisisId: $crisisId
                searchTerm: $searchTerm
                gigTypes: $gigTypes
              )
          }
        }
      }
    `,
    variables: () => {
      return {
        crisisId: initialFilters.crisisId,
        maxDistance: initialFilters.radiusMiles ?? 75,
        searchTerm: initialFilters.searchTerm,
        gigTypes: initialFilters.gigTypes
      };
    }
  }
);
