import {
  Button,
  Platform,
  Stack,
  type StepProps,
  StepSubmit,
  Text,
  TextInput,
  toast,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { createConversion } from "@gigsmart/dekigoto";
import { NewGwOnboarding } from "@gigsmart/feature-flags";
import {
  type FomuSubmitFn,
  Form,
  Validator,
  useFormField
} from "@gigsmart/fomu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useFetchPlaceFromCurrentLocation } from "@gigsmart/isomorphic-shared/geolocation";
import { CompletedOnboardingConversion } from "@gigsmart/isomorphic-shared/onboarding/conversions";
import { defaultResetHome, useRoute } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import React, { useCallback, useEffect } from "react";
import type { ZipCodeStepRquesterMutation } from "./__generated__/ZipCodeStepRquesterMutation.graphql";
import type { ZipCodeStepWorkerMutation } from "./__generated__/ZipCodeStepWorkerMutation.graphql";
import type { OnboardingData } from "./types";

const ZipCodeConversionTracker = createConversion("onboarding_zip_code");

type Props = StepProps<OnboardingData>;

export default function ZipCodeStep({ stepper }: Props) {
  const current = useCurrentUser();
  const route = useRoute();
  const isOrgRequester = current?.__typename === "OrganizationRequester";
  const userType = stepper.data.userType;

  useEffect(() => {
    void ZipCodeConversionTracker.track({
      step_index: stepper.index,
      event: "zip_code_step",
      url: route.path
    });
  }, [stepper.index, current?.__typename, route.path]);

  const [updateRequester] = useRelayMutation<ZipCodeStepRquesterMutation>(
    graphql`
      mutation ZipCodeStepRquesterMutation($input: UpdateRequesterInput!) {
        updateRequester(input: $input) {
          requester {
            postalCode
          }
        }
      }
    `,
    {
      updater: isOrgRequester
        ? (store, data) => {
            const viewer = store.get(current.id);
            if (viewer && data?.updateRequester?.requester.postalCode) {
              viewer.setValue(
                data.updateRequester.requester.postalCode,
                "postalCode"
              );
            }
          }
        : undefined
    }
  );
  const [updateWorker] = useRelayMutation<ZipCodeStepWorkerMutation>(
    graphql`
      mutation ZipCodeStepWorkerMutation($input: UpdateWorkerInput!) {
        updateWorker(input: $input) {
          worker {
            postalCode
          }
        }
      }
    `
  );

  const handleSubmit: FomuSubmitFn = useCallback(({ values }, done) => {
    const updateZipCode =
      userType === "REQUESTER" ? updateRequester : updateWorker;
    updateZipCode(
      { input: { postalCode: values.zipCode } },
      {
        onSuccess: () => {
          if (isOrgRequester) {
            void CompletedOnboardingConversion.track();
            defaultResetHome();
          } else {
            stepper.nextStep();
          }
        },
        onError: (result) => {
          toast.error(
            result.message || "Unexpected error while submitting postal code"
          );
          done();
        }
      }
    );
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <ZipCodeField />
      <StepSubmit
        testID="next-btn"
        label={NewGwOnboarding.select("Get Started", "Next")}
      />
    </Form>
  );
}

function ZipCodeField() {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const fetchPlace = useFetchPlaceFromCurrentLocation();
  const { errors, value, setValue, submit, showErrors } = useFormField({
    name: "zipCode",
    validates: [Validator.presence(), Validator.zipcode()]
  });
  const handleCurrentLocationPress = useCallback(async () => {
    const place = await fetchPlace(true);
    setValue(place?.postalCode ?? "");
  }, [fetchPlace, setValue]);

  return (
    <Stack>
      <Text>We'll tailor your experience based on your location.</Text>
      <TextInput
        value={value ?? ""}
        errors={errors}
        testID="zip-code-input"
        label="Zip Code"
        maxLength={5}
        type="number"
        keyboardType="numeric"
        autoFocus
        onChangeText={setValue}
        onSubmitEditing={Platform.OS === "web" ? submit : undefined}
        onBlur={showErrors}
      />
      <Button
        testID="use-current-location-btn"
        icon="location-arrow"
        label="Use Current Location"
        outline
        size="small"
        variant="clear"
        onPress={handleCurrentLocationPress}
        alignSelf={isMd ? "flex-start" : "stretch"}
      />
    </Stack>
  );
}
