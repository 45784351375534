import {
  Button,
  Column,
  ContentArea,
  Date as DateComp,
  Divider,
  HighlightedReminder,
  LoadingView,
  QuoteContent,
  Surface,
  Tag,
  Text,
  Time as TimeComp,
  TimeRemaining,
  isInThePast
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { GigDateTimeContent } from "@gigsmart/isomorphic-shared/gig";
import {
  type EngagementStateName,
  formatDistanceRange
} from "@gigsmart/isomorphic-shared/gig/helpers";
import { DatePickerInput, KatanaTabs } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "../gig-series/WorkerLatestArrivalTimeReminder";
import {
  type AvailableShiftData,
  useAvailableShiftsData
} from "./AvailableShiftsData";
import type { AvailableShiftsSummary_shift$key } from "./__generated__/AvailableShiftsSummary_shift.graphql";

type Props = {
  stateName?: EngagementStateName;
  workerDistance?: number | null;
};

export const AvailableShiftsSummary = ({
  __typename,
  id,
  nextGig,
  area,
  result,
  isRecurring,
  stateName,
  workerDistance,
  startsAt,
  latestArrivalTime,
  pickup
}: FragmentContainerInnerComponentProps<
  AvailableShiftsSummary_shift$key,
  Props
>) => {
  const { shifts } = useAvailableShiftsData(id);
  const shiftCount = Math.max(nextGig?.totalCount ?? 0, shifts.length);
  const addr = area ?? "N/A";
  const availableGig =
    __typename === "Gig" ? result : nextGig?.edges?.[0]?.node;

  return (
    <Surface variant="outline">
      <ContentArea gap="standard">
        <Column gap="medium">
          <Text>
            <Text weight="bold">{addr} &bull; </Text>
            {formatDistanceRange({ min: workerDistance }, " away")}
          </Text>
          <Column gap="compact">
            <Text weight="bold" color="primary">
              {pluralize(shiftCount, "Available Shift")}
            </Text>
            {shifts.length === 0 ? (
              <LoadingView size="xlarge" />
            ) : (
              <ShiftsContent shifts={shifts} />
            )}
          </Column>
        </Column>
        {pickup?.eligible ? (
          <HighlightedReminder
            icon="alarm-clock"
            header={
              <Text weight="semibold">
                This Shift is available today starting in{" "}
                <TimeRemaining
                  size="lg"
                  showLargestOnly
                  color="emphasized"
                  weight="semibold"
                  date={isInThePast(startsAt) ? latestArrivalTime : startsAt}
                />
              </Text>
            }
          />
        ) : (
          showLatestArrivalReminder(availableGig, stateName) && (
            <WorkerLatestArrivalTimeReminder
              fragmentRef={availableGig}
              isMultiple={shiftCount > 1}
              workerStateName={stateName}
            />
          )
        )}
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  AvailableShiftsSummary_shift$key,
  Props
>(
  graphql`
    fragment AvailableShiftsSummary_shift on GigLike {
      __typename
      id
      isRecurring
      area
      ... on Gig {
        startsAt
        latestArrivalTime
        pickup {
          eligible
        }
        ...WorkerLatestArrivalTimeReminder_gig
      }
      ... on GigSeries {
        nextGig: availableGigs(first: 1) {
          totalCount
          edges {
            node {
              ...WorkerLatestArrivalTimeReminder_gig
            }
          }
        }
      }
    }
  `,
  AvailableShiftsSummary
);

type ShiftsContentProps = {
  shifts: AvailableShiftData[];
};
function ShiftsContent({ shifts }: ShiftsContentProps) {
  const shiftDates = useMemo(() => shifts.map(getDate), [shifts]);

  const [viewAll, setViewAll] = useState(false);
  const visibleShifts = viewAll ? shifts : shifts.slice(0, 3);
  const showViewAll =
    visibleShifts === shifts || visibleShifts.length < shifts.length;

  if (shifts.length === 1) {
    const shift = shifts[0];
    return (
      <QuoteContent testID="ind-shift-details-quote-content">
        <Column gap="slim">
          <DateComp
            weight="bold"
            size="lg"
            showDayOfWeek
            startsAt={shift?.startsAt}
            actualStartsAt={shift?.actualStartsAt}
          />
          <Text>
            <Text weight="bold">Time: </Text>
            <TimeComp
              size="lg"
              startsAt={shift?.startsAt}
              endsAt={shift?.endsAt}
            />
          </Text>
        </Column>
      </QuoteContent>
    );
  }

  return (
    <Surface variant="outline">
      <KatanaTabs
        tabs={[
          {
            label: "SHIFTS",
            Contents: (
              <Column>
                <ContentArea gap="standard">
                  {visibleShifts.map((node, index) => (
                    <GigDateTimeContent
                      key={index}
                      startsAt={node.startsAt}
                      endsAt={node.endsAt}
                      alwaysDisplayDate
                      dateTag={
                        node.isRecurring ? (
                          <Tag
                            label="Recurring"
                            color="primary"
                            variant="clear"
                          />
                        ) : null
                      }
                    />
                  ))}
                </ContentArea>
                {showViewAll && (
                  <>
                    <Divider />
                    <Button
                      testID="view-more-btn"
                      alignSelf="center"
                      variant="clear"
                      size="small"
                      onPress={() => setViewAll(!viewAll)}
                      {...(viewAll
                        ? { label: "View Less", icon: "chevron-up" }
                        : { label: "View More", icon: "chevron-down" })}
                    />
                  </>
                )}
              </Column>
            )
          },
          {
            label: "CALENDAR",
            Contents: (
              <ContentArea>
                <DatePickerInput
                  value={shiftDates}
                  min={shiftDates?.[0]}
                  max={shiftDates?.[shiftDates.length - 1]}
                />
              </ContentArea>
            )
          }
        ]}
      />
    </Surface>
  );
}

export const getDate = (shift: AvailableShiftData) => {
  return shift.actualStartsAt
    ? DateTime.fromJSDate(new Date(shift.actualStartsAt)).toFormat(
        DatePickerInput.LUXON_DATE_FORMAT
      )
    : shift.timezone
      ? DateTime.fromJSDate(new Date(), { zone: shift.timezone })
          .startOf("day")
          .toFormat(DatePickerInput.LUXON_DATE_FORMAT)
      : DateTime.fromJSDate(new Date())
          .startOf("day")
          .toFormat(DatePickerInput.LUXON_DATE_FORMAT);
};
