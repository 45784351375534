import {
  type IconName,
  IconText,
  Pressable,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import HomeScreenCard from "../worker/HomescreenCard";

import type { CategoryPositionsCard_workerProfile$key } from "./__generated__/CategoryPositionsCard_workerProfile.graphql";

export const CategoryPositionsCard = ({
  positions
}: FragmentContainerInnerComponentProps<CategoryPositionsCard_workerProfile$key>) => {
  const history = useHistory();
  const positionsCount = positions?.totalCount ?? 0;

  const ManageAllButton = (
    <Pressable
      onPress={() => history.push("/profile/categories")}
      eventEntityType="category-positions-card-press"
      testID="category-positions-card-press"
      eventTargetName="Manage Positions Button"
    >
      <IconText
        icon="chevron-right"
        iconPlacement="right"
        color="primary"
        iconSize="small"
        spacing="compact"
      >
        <Text variant="subheader" color="primary">
          Manage
        </Text>
      </IconText>
    </Pressable>
  );

  return (
    <HomeScreenCard
      headerTitle="Categories & Positions"
      headerIcon={"grid" as IconName}
      action={ManageAllButton}
      testID="category-positions-card"
    >
      <Stack>
        <Text>
          Select Categories & Positions you are interested in and able to work.
          We will notify you of Shift opportunities based on your selections.
        </Text>
        <Text align="center" weight="bold" testID="worker-positions-count">
          {pluralize(positionsCount, "Position")} added to Profile
        </Text>
      </Stack>
    </HomeScreenCard>
  );
};

export default createRelayFragmentContainer<CategoryPositionsCard_workerProfile$key>(
  graphql`
    fragment CategoryPositionsCard_workerProfile on Worker {
      positions(first: 0, query: "WHERE status=CONFIRMED") {
        totalCount
      }
    }
  `,
  CategoryPositionsCard
);
