/**
 * @generated SignedSource<<a526871aa676fcbc16d42bc91c409c8e>>
 * @relayHash 2552bab0f3667f0df2855b0926d66b3f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:rYaCY_gpKHGf5BgAYby7lufS0O2wbZV3FgGhN3H2viA

import { ConcreteRequest } from 'relay-runtime';
export type SetWorkerLocationInput = {
  accuracy?: number | null | undefined;
  latitude: number;
  longitude: number;
  reportedAt?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type useTrackLocationSilentMutation$variables = {
  input: SetWorkerLocationInput;
};
export type useTrackLocationSilentMutation$data = {
  readonly setWorkerLocation: {
    readonly workerLocation: {
      readonly id: string;
    };
  } | null | undefined;
};
export type useTrackLocationSilentMutation = {
  response: useTrackLocationSilentMutation$data;
  variables: useTrackLocationSilentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetWorkerLocationPayload",
    "kind": "LinkedField",
    "name": "setWorkerLocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerLocation",
        "kind": "LinkedField",
        "name": "workerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTrackLocationSilentMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTrackLocationSilentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:rYaCY_gpKHGf5BgAYby7lufS0O2wbZV3FgGhN3H2viA",
    "metadata": {},
    "name": "useTrackLocationSilentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8720b635951e05fd7b6cf4fadd28e397";

export default node;
