import {
  Divider,
  SelectableListHeader,
  SelectableListItem,
  Spacer
} from "@gigsmart/atorasu";
import React, { type ReactElement, useMemo } from "react";
import {
  SectionList,
  type SectionListProps,
  type ViewProps
} from "react-native";

import CollapsableSectionHeader from "./collapsable-section-header";

interface BaseSection<TKey extends string, TData> {
  key: TKey;
  data: TData[];
}

interface RenderSectionHeaderInfo<TKey extends string, TData> {
  section: SectionType<TKey, TData>;
}

interface RenderItemInfo<TKey extends string, TData> {
  index: number;
  item: TData;
  section: SectionType<TKey, TData>;
}

export interface SectionType<TKey extends string, TData>
  extends BaseSection<TKey, TData> {
  count: number;
  collapsed: boolean;
  collapsable: boolean;
  selectable: boolean;
}

interface Props<TKey extends string, TData = any>
  extends Omit<
    SectionListProps<TData, SectionType<TKey, TData>>,
    | "sections"
    | "renderItem"
    | "renderSectionHeader"
    | "renderSectionFooter"
    | "collapsable"
    | "keyExtractor"
    | "ItemSeparatorComponent"
  > {
  data?: readonly TData[] | TData[] | null;
  itemStyle?: ViewProps["style"];
  sectionHeaderStyle?: ViewProps["style"];
  order?: TKey[];
  collapsable?: boolean | TKey[];
  selectable?: boolean | TKey[];
  collapsed?: TKey[];
  selected?: string[];
  collapseAllOnExpand?: boolean;
  keyExtractor: (d: TData) => string;
  sectionKeyExtractor: (d: TData) => TKey | undefined;
  renderItem: (info: RenderItemInfo<TKey, TData>) => ReactElement | null;
  renderSectionHeader?: (
    info: RenderSectionHeaderInfo<TKey, TData>
  ) => ReactElement | null;
  renderSectionFooter?: (
    info: RenderSectionHeaderInfo<TKey, TData>
  ) => ReactElement | null;
  renderSelectAll?: (
    info: RenderSectionHeaderInfo<TKey, TData>
  ) => string | null;

  // events
  onChangeSelected?: (selected: string[]) => void;
  onChangeCollapsed?: (
    collapsed: TKey[],
    selected: TKey,
    isCollapsed: boolean
  ) => void;
}

const Separator = () => <Spacer size="compact" />;

const CollapsableSectionList = <TKey extends string, TData>({
  data,
  extraData,
  sectionKeyExtractor,
  itemStyle,
  sectionHeaderStyle,
  stickySectionHeadersEnabled = false,
  collapsable = true,
  selectable = true,
  order = [],
  collapsed = [],
  selected = [],
  renderSectionHeader,
  renderSectionFooter,
  renderItem,
  renderSelectAll = () => "Select All",
  keyExtractor,
  // events
  onChangeCollapsed,
  onChangeSelected,
  collapseAllOnExpand,
  ...sectionProps
}: Props<TKey, TData>) => {
  const sections = useMemo(() => {
    // split data[] into sections
    const result: Array<SectionType<TKey, TData>> = order?.map((sectionKey) =>
      createSection(
        sectionKey,
        collapsable,
        selectable,
        collapsed.includes(sectionKey)
      )
    );

    if (data) {
      for (const d of data) {
        const sectionKey = sectionKeyExtractor(d);
        if (sectionKey) {
          let section = result.find((r) => r.key === sectionKey);
          if (!section) {
            section = createSection(
              sectionKey,
              collapsable,
              selectable,
              collapsed.includes(sectionKey)
            );

            section.count += 1;
            result.push(section);
          }

          section.count += 1;
          section.data.push(d);
        }
      }
    }

    return result
      .filter((d) => d.count > 0)
      .map((section, idx, arr) => {
        if (arr.length < 2) {
          section.collapsable = false;
          section.collapsed = false;
        }
        if (section.collapsed) {
          section.data = [];
        }
        return section;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, collapsed]);

  const handlePressHeader = (section: SectionType<TKey, TData>) => {
    if (!onChangeCollapsed) return;
    const idx = collapsed.indexOf(section.key);
    if (collapseAllOnExpand) {
      const newCollapsed = sections
        .filter((filterableSection) => section.key !== filterableSection.key)
        .map((mappableSection) => mappableSection.key);
      onChangeCollapsed(newCollapsed, section.key, idx < 0);
    } else {
      const newCollapsed = [...collapsed];
      if (idx >= 0) newCollapsed.splice(idx, 1);
      else newCollapsed.push(section.key);
      onChangeCollapsed(newCollapsed, section.key, idx < 0);
    }
  };

  return (
    <SectionList
      extraData={extraData ?? selected}
      keyExtractor={keyExtractor}
      sections={sections}
      stickySectionHeadersEnabled={stickySectionHeadersEnabled}
      renderSectionHeader={(info) => {
        const content = renderSectionHeader?.(info);
        if (!content) return null;
        return (
          <CollapsableSectionHeader
            collapsable={info.section.collapsable}
            collapsed={info.section.collapsed}
            onPress={() => handlePressHeader(info.section)}
          >
            {content}
          </CollapsableSectionHeader>
        );
      }}
      renderSectionFooter={(info) => {
        const footer = renderSectionFooter?.(info);
        return (
          <>
            {footer}
            {info.section.collapsed && <Divider />}
          </>
        );
      }}
      renderItem={(info: RenderItemInfo<TKey, TData>) => {
        const { index, item, section } = info;
        const { data, selectable } = section;

        const itemKey = keyExtractor(item);
        const checked = selected.includes(itemKey);
        const handlePress = (value: boolean) => {
          if (!onChangeSelected) return;
          const newSelected = value
            ? [...selected, itemKey]
            : selected.filter((key) => key !== itemKey);
          onChangeSelected(newSelected);
        };

        const selectAllNode =
          index === 0 && selectable && renderSelectAll({ section });
        const innerContent = renderItem(info);

        const content = (
          <SelectableListItem
            multiple
            selectable={selectable}
            selected={checked}
            onChange={handlePress}
          >
            {innerContent}
          </SelectableListItem>
        );

        if (!selectAllNode) return content;

        const allChecked = section.data.every((d) =>
          selected.includes(keyExtractor(d))
        );

        const handlePressAll = () => {
          if (!onChangeSelected) return;
          const siblingsSelected = selected.filter(
            (key) => !data.find((d) => keyExtractor(d) === key)
          );
          const newSelected = allChecked
            ? siblingsSelected
            : [...siblingsSelected, ...data.map((d) => keyExtractor(d))];
          onChangeSelected(newSelected);
        };

        return (
          <>
            <SelectableListHeader
              title={selectAllNode}
              selected={allChecked}
              onChange={handlePressAll}
            />
            <Separator />
            {content}
          </>
        );
      }}
      ItemSeparatorComponent={Separator}
      {...sectionProps}
    />
  );
};

export default CollapsableSectionList;

const createSection = <TKey extends string>(
  key: TKey,
  collapsable: boolean | TKey[],
  selectable: boolean | TKey[],
  collapsed: boolean
): SectionType<TKey, any> => {
  const sectionCollapsable =
    typeof collapsable === "boolean" ? collapsable : collapsable.includes(key);
  const sectionSelectable =
    typeof selectable === "boolean" ? selectable : selectable.includes(key);
  return {
    key,
    data: [],
    count: 0,
    collapsed,
    collapsable: sectionCollapsable,
    selectable: sectionSelectable
  };
};
