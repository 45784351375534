/**
 * @generated SignedSource<<53166dc75abe67481708ed30ed4e5051>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancellationPoliciesRow_shift$data = {
  readonly requesterCancellationPolicies: ReadonlyArray<{
    readonly description: string;
    readonly minimumStartDelta: string;
  }>;
  readonly workerCancellationPolicies: ReadonlyArray<{
    readonly description: string;
    readonly minimumStartDelta: string;
  }>;
  readonly " $fragmentType": "CancellationPoliciesRow_shift";
};
export type CancellationPoliciesRow_shift$key = {
  readonly " $data"?: CancellationPoliciesRow_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancellationPoliciesRow_shift">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minimumStartDelta",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancellationPoliciesRow_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RequesterCancellationPolicy",
      "kind": "LinkedField",
      "name": "requesterCancellationPolicies",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerCancellationPolicy",
      "kind": "LinkedField",
      "name": "workerCancellationPolicies",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "f480e2433739a9521c3ebbcb08865a1f";

export default node;
