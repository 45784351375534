/**
 * @generated SignedSource<<1d5a015acae4e6a46e6441ed5d52cb08>>
 * @relayHash b8c1f7f735226592a442c16253e5105d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:lOycGHBXHwonlwLhONeHztarC_4ojS8eSS6LEcKgjsA

import { ConcreteRequest } from 'relay-runtime';
export type QualificationSummaryRowQuery$variables = {
  nodeId: string;
};
export type QualificationSummaryRowQuery$data = {
  readonly node: {
    readonly estimatedMileage?: number | null | undefined;
    readonly gigQualifications?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly iconName: string;
          readonly id: string;
          readonly selectedGigFieldItems: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly definition: {
                  readonly label: string;
                };
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly selectedGigSeriesFieldItems?: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly definition: {
                  readonly label: string;
                };
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly title: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id?: string;
    readonly requiredQualifications?: ReadonlyArray<{
      readonly gigField: {
        readonly id: string;
      };
      readonly id: string;
      readonly label: string;
    }>;
    readonly requiresVehicle?: boolean | null | undefined;
  } | null | undefined;
};
export type QualificationSummaryRowQuery = {
  response: QualificationSummaryRowQuery$data;
  variables: QualificationSummaryRowQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "nodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresVehicle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "GigFieldItemDefinition",
  "kind": "LinkedField",
  "name": "requiredQualifications",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GigField",
      "kind": "LinkedField",
      "name": "gigField",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iconName",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinition",
    "kind": "LinkedField",
    "name": "definition",
    "plural": false,
    "selections": [
      (v5/*: any*/)
    ],
    "storageKey": null
  }
],
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinition",
    "kind": "LinkedField",
    "name": "definition",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QualificationSummaryRowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": "gigQualifications",
                    "args": (v7/*: any*/),
                    "concreteType": "GigFieldsConnection",
                    "kind": "LinkedField",
                    "name": "fields",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigFieldsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigField",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": (v7/*: any*/),
                                "concreteType": "GigFieldItemsConnection",
                                "kind": "LinkedField",
                                "name": "selectedGigFieldItems",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigFieldItemsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigFieldItem",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v10/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "selectedGigFieldItems(first:10)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "fields(first:10)"
                  }
                ],
                "type": "Gig",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": "gigQualifications",
                    "args": (v7/*: any*/),
                    "concreteType": "GigFieldsConnection",
                    "kind": "LinkedField",
                    "name": "fields",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigFieldsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigField",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": (v7/*: any*/),
                                "concreteType": "GigSeriesFieldItemsConnection",
                                "kind": "LinkedField",
                                "name": "selectedGigSeriesFieldItems",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigSeriesFieldItemsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSeriesFieldItem",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v10/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "selectedGigSeriesFieldItems(first:10)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "fields(first:10)"
                  }
                ],
                "type": "GigSeries",
                "abstractKey": null
              }
            ],
            "type": "GigLike",
            "abstractKey": "__isGigLike"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QualificationSummaryRowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": "gigQualifications",
                    "args": (v7/*: any*/),
                    "concreteType": "GigFieldsConnection",
                    "kind": "LinkedField",
                    "name": "fields",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigFieldsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigField",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": (v7/*: any*/),
                                "concreteType": "GigFieldItemsConnection",
                                "kind": "LinkedField",
                                "name": "selectedGigFieldItems",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigFieldItemsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigFieldItem",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v11/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "selectedGigFieldItems(first:10)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "fields(first:10)"
                  }
                ],
                "type": "Gig",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": "gigQualifications",
                    "args": (v7/*: any*/),
                    "concreteType": "GigFieldsConnection",
                    "kind": "LinkedField",
                    "name": "fields",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigFieldsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigField",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": (v7/*: any*/),
                                "concreteType": "GigSeriesFieldItemsConnection",
                                "kind": "LinkedField",
                                "name": "selectedGigSeriesFieldItems",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigSeriesFieldItemsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSeriesFieldItem",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v11/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "selectedGigSeriesFieldItems(first:10)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "fields(first:10)"
                  }
                ],
                "type": "GigSeries",
                "abstractKey": null
              }
            ],
            "type": "GigLike",
            "abstractKey": "__isGigLike"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:lOycGHBXHwonlwLhONeHztarC_4ojS8eSS6LEcKgjsA",
    "metadata": {},
    "name": "QualificationSummaryRowQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "003783819b7fab8f79728082ab38dd3e";

export default node;
