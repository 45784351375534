import {
  Column,
  ContentArea,
  Date as DateComp,
  QuoteContent,
  Surface,
  Text,
  Time as TimeComp
} from "@gigsmart/atorasu";
import {
  formatDistanceRange,
  stateIsComplete,
  stateIsIncomplete
} from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "../../gig-series/WorkerLatestArrivalTimeReminder";
import type { SingleShiftSummary_engagement$key } from "./__generated__/SingleShiftSummary_engagement.graphql";

type Props = {
  showAddress?: boolean;
};

export const SingleShiftSummary = ({
  startsAt,
  endsAt,
  insertedAt,
  workerDistance,
  currentState,
  gig,
  showAddress = true
}: FragmentContainerInnerComponentProps<
  SingleShiftSummary_engagement$key,
  Props
>) => {
  const addr = gig?.organizationLocation?.place?.area ?? gig?.area ?? "N/A";
  if (showAddress) {
    showAddress =
      stateIsIncomplete(currentState.name) ||
      stateIsComplete(currentState.name);
  }

  return (
    <Surface variant="outline">
      <ContentArea gap="standard">
        <Column gap="medium">
          {showAddress && !!workerDistance && (
            <Text>
              <Text weight="bold">{addr} &bull; </Text>
              {formatDistanceRange({ min: workerDistance }, " away")}
            </Text>
          )}
          <Column gap="compact">
            <Text weight="bold" color="primary">
              Shift Time
            </Text>
            <QuoteContent>
              <Column gap="slim">
                <DateComp
                  weight="bold"
                  size="lg"
                  showDayOfWeek
                  startsAt={startsAt}
                  actualStartsAt={insertedAt}
                />
                <Text>
                  <Text weight="bold">Time: </Text>
                  <TimeComp size="lg" startsAt={startsAt} endsAt={endsAt} />
                </Text>
              </Column>
            </QuoteContent>
          </Column>
        </Column>
        {showLatestArrivalReminder(
          gig,
          currentState.name,
          currentState.action
        ) && (
          <WorkerLatestArrivalTimeReminder
            fragmentRef={gig}
            isMultiple={false}
            workerStateName={currentState.name}
          />
        )}
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  SingleShiftSummary_engagement$key,
  Props
>(
  graphql`
    fragment SingleShiftSummary_engagement on Engagement {
      id
      startsAt
      endsAt
      insertedAt
      workerDistance
      currentState {
        name
        action
      }
      gig {
        ...WorkerLatestArrivalTimeReminder_gig
        area
        organizationLocation {
          place {
            area
          }
        }
      }
    }
  `,
  SingleShiftSummary
);
