/**
 * @generated SignedSource<<3a87f4b653f59a262f9597e1b2972f1b>>
 * @relayHash 2db24c7d691268af9cc9e66a2ae9dfd8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Owl_dnrAfO4E9W5JQGod5t8iC7W044lSftnYyhZoEK8

import { ConcreteRequest } from 'relay-runtime';
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type ResetUserPasswordInput = {
  password: string;
  token: string;
  userType: UserType;
};
export type resetPasswordMutation$variables = {
  input: ResetUserPasswordInput;
};
export type resetPasswordMutation$data = {
  readonly resetUserPassword: {
    readonly ok: boolean;
  } | null | undefined;
};
export type resetPasswordMutation = {
  response: resetPasswordMutation$data;
  variables: resetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResetUserPasswordPayload",
    "kind": "LinkedField",
    "name": "resetUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:Owl_dnrAfO4E9W5JQGod5t8iC7W044lSftnYyhZoEK8",
    "metadata": {},
    "name": "resetPasswordMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c803ee12c9e885fab9b06511becceee5";

export default node;
