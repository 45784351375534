import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";
import React from "react";
import { useQualifications } from "./hooks/useQualifications";

export default function AttireScreen() {
  const {
    title,
    subTitle,
    reminderText,
    iconName,
    options,
    onBackPress,
    checkOption
  } = useQualifications({ qualificationTitle: "Attire" });
  if (!options || options?.length === 0) return null;
  return (
    <ScreenScroll testID="worker-attire-screen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="worker-attire-screen-layout"
          header={title ?? ""}
          note={subTitle}
          searchHeader="Do you have the following Attire?"
          searchPlaceholder="Search Attire"
          selectionRowHeader="Attire"
          selectionRowHeaderIcon={iconName}
          reminder={reminderText}
          options={options}
          onCheckOption={checkOption}
        />
      </ContentArea>
    </ScreenScroll>
  );
}
