import React from "react";

import { ContentArea, Surface, Text, ThumbnailRow } from "@gigsmart/atorasu";
import { workerProjectCardDateTimeString } from "@gigsmart/isomorphic-shared/gig/helpers";

import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { EngagementInfoRow_engagement$key } from "./__generated__/EngagementInfoRow_engagement.graphql";

interface Props {
  gigName?: string | null;
  name?: string | null;
  isTBD?: boolean;
  photoUrl?: string | null;
  startsAt?: string | null;
}

export function EngagementInfoRow({
  gigName,
  isTBD,
  name,
  photoUrl,
  startsAt
}: Props) {
  return (
    <Surface>
      <ContentArea>
        <ThumbnailRow
          uri={photoUrl}
          icon="user"
          title={name}
          titleTestID="engagement-info-name"
        >
          <Text
            variant="note"
            color="neutral"
            testID="engagement-info-gig-name"
          >
            {gigName}
          </Text>
          <Text variant="note" color="neutral" testID="engagement-info-date">
            {workerProjectCardDateTimeString(startsAt, isTBD ?? false)}
          </Text>
        </ThumbnailRow>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<EngagementInfoRow_engagement$key>(
  graphql`
    fragment EngagementInfoRow_engagement on Engagement {
      gig {
        name
        negotiable
        startsAt
        requester {
          displayName
          profilePhoto {
            url
          }
        }
        organization {
          name
          logoUrl
        }
      }
    }
  `,
  ({ gig }) => {
    const photoUrl =
      gig?.organization?.logoUrl ?? gig?.requester?.profilePhoto?.url;
    const name = gig?.organization?.name ?? gig?.requester?.displayName;
    return (
      <EngagementInfoRow
        photoUrl={photoUrl}
        name={name}
        gigName={gig?.name}
        isTBD={gig?.negotiable?.includes("STARTS_AT") ?? false}
        startsAt={gig?.startsAt}
      />
    );
  }
);
