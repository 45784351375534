import { NotificationCenter } from "@gigsmart/isomorphic-shared/notifications";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";
import WorkerAnnouncements from "../announcement";

export type Props = Pick<
  ComponentPropsWithDefaults<typeof NotificationCenter>,
  "onPress"
>;

export default function NotificationListContainer(props: Props) {
  return (
    <NotificationCenter
      ListHeaderComponent={<WorkerAnnouncements enableSpacing />}
      {...props}
    />
  );
}
