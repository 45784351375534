/**
 * @generated SignedSource<<85419b4494416ea91a339cc84418f94d>>
 * @relayHash 4055bc3f5d8d58d3a0c3af93a78ec4f2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:eKBMkl3IDLD6ibuiQkR-vuiHjQhJbiW1Q3p8B2a5bwI

import { ConcreteRequest } from 'relay-runtime';
export type RequesterOnboardingState = "BASIC_INFO" | "MODE_SELECTION" | "PAYMENT_METHOD" | "SMS_CONFIRMATION" | "%future added value";
export type WorkerAccessState = "AVAILABLE" | "EN_ROUTE" | "NO_ACTIVE_DEVICES" | "NO_LOCATION" | "REQUIRES_BASIC_INFO" | "REQUIRES_PARENT_CONSENT" | "REQUIRES_SKILLS" | "REQUIRES_SMS_CONFIRMATION" | "REQUIRES_TRANSPORTATION" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type ConfirmUserMobileInput = {
  token: string;
  userId?: string | null | undefined;
};
export type SMSVerificationStepSmsConfirmationMutation$variables = {
  input: ConfirmUserMobileInput;
};
export type SMSVerificationStepSmsConfirmationMutation$data = {
  readonly confirmUserMobile: {
    readonly user: {
      readonly accessState?: WorkerAccessState;
      readonly id: string;
      readonly onboardingStates?: ReadonlyArray<{
        readonly complete: boolean;
        readonly onboardingState: RequesterOnboardingState;
      }>;
      readonly postalCode?: string | null | undefined;
      readonly primaryMobile: {
        readonly confirmed: boolean;
        readonly number: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type SMSVerificationStepSmsConfirmationMutation = {
  response: SMSVerificationStepSmsConfirmationMutation$data;
  variables: SMSVerificationStepSmsConfirmationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessState",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OnboardingState",
      "kind": "LinkedField",
      "name": "onboardingStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onboardingState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "complete",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RequesterLike",
  "abstractKey": "__isRequesterLike"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SMSVerificationStepSmsConfirmationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmUserMobilePayload",
        "kind": "LinkedField",
        "name": "confirmUserMobile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMobile",
                "kind": "LinkedField",
                "name": "primaryMobile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SMSVerificationStepSmsConfirmationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmUserMobilePayload",
        "kind": "LinkedField",
        "name": "confirmUserMobile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMobile",
                "kind": "LinkedField",
                "name": "primaryMobile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:eKBMkl3IDLD6ibuiQkR-vuiHjQhJbiW1Q3p8B2a5bwI",
    "metadata": {},
    "name": "SMSVerificationStepSmsConfirmationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "64ddaec165c9bced8b11f4d085f77810";

export default node;
