import {
  ActivityIndicator,
  Column,
  ContentArea,
  typedMemo
} from "@gigsmart/atorasu";
import { ResetRelayOrchestrator } from "@gigsmart/relay";
import { useIsFocused } from "@react-navigation/native";
import React, {
  type ComponentType,
  type ReactElement,
  Suspense,
  useCallback,
  useMemo
} from "react";
import ErrorBoundary from "./ErrorBoundary";
import type { AppletContract } from "./createApplet";
import { useApplet } from "./fetchApplet";
import { useExit, usePortal } from "./portal";

function toAppletPreview<TExtra, TWrapper>(
  { id, preview, content }: AppletContract<TExtra>["__gigsmart_applet__"],
  AppletHeader?: ComponentType<{}>,
  wrapper?: {
    Component: ComponentType<TWrapper & { children: ReactElement }>;
    props: TWrapper;
  }
): ComponentType<TExtra> {
  const wrap = (elem: JSX.Element) => {
    if (!wrapper) return elem;
    const { Component: WrapperComponent, props: wrapperProps } = wrapper;
    return <WrapperComponent {...wrapperProps}>{elem}</WrapperComponent>;
  };

  const AppletContent = (props: TExtra) => {
    const { update, remove } = usePortal(id);
    const isOpen = AppletContent === useExit().AppletContent;
    const open = useCallback(
      () => update({ props, AppletPreview, AppletContent, AppletHeader }),
      [update]
    );

    return wrap(
      <ResetRelayOrchestrator>
        <Column fill>
          {content({
            isOpen,
            toggle: isOpen ? remove : open,
            ...props
          })}
        </Column>
      </ResetRelayOrchestrator>
    );
  };

  const AppletPreview = (props: TExtra) => {
    const { update, remove } = usePortal(id);
    const isOpen = AppletContent === useExit().AppletContent;
    const open = () =>
      update({ props, AppletPreview, AppletContent, AppletHeader });

    return wrap(
      <ResetRelayOrchestrator>
        <Column>
          {preview({
            isOpen,
            toggle: isOpen ? remove : open,
            ...props
          })}
        </Column>
      </ResetRelayOrchestrator>
    );
  };

  return AppletPreview;
}

type Props<TExtra, TWrapper> = TExtra & {
  url: string;
  props: Record<string, unknown> | string | null | undefined;
  AppletHeader?: ComponentType<{}>;
  wrapper?: {
    Component: ComponentType<TWrapper & { children: ReactElement }>;
    props: TWrapper;
  };
};

export const Applet = typedMemo(
  <
    TExtra extends Record<string, unknown>,
    TWrapper extends Record<string, unknown>
  >({
    url,
    props,
    AppletHeader,
    wrapper,
    ...extraProps
  }: Props<TExtra, TWrapper>) => {
    const propsObject = useMemo(
      () => (typeof props === "string" ? JSON.parse(props || "{}") : props),
      [props]
    );
    const handleApplet = useCallback(
      (applet: AppletContract<TExtra>["__gigsmart_applet__"]) =>
        toAppletPreview<TExtra, TWrapper>(applet, AppletHeader, wrapper),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [AppletHeader, wrapper?.Component, JSON.stringify(wrapper?.props)]
    );

    const isFocused = useIsFocused();
    const AppletComponent = useApplet(url, handleApplet, [url, AppletHeader]);

    return !isFocused ? null : (
      <ErrorBoundary url={url}>
        <Suspense
          fallback={
            <ContentArea
              size="compact"
              alignItems="center"
              justifyContent="center"
            >
              <ActivityIndicator />
            </ContentArea>
          }
        >
          <AppletComponent {...extraProps} {...propsObject} />
        </Suspense>
      </ErrorBoundary>
    );
  }
);
