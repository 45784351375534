/**
 * @generated SignedSource<<59f6167fddd674440bb3e464fd955282>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryPositionsCard_workerProfile$data = {
  readonly positions: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "CategoryPositionsCard_workerProfile";
};
export type CategoryPositionsCard_workerProfile$key = {
  readonly " $data"?: CategoryPositionsCard_workerProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryPositionsCard_workerProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryPositionsCard_workerProfile",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status=CONFIRMED"
        }
      ],
      "concreteType": "WorkerGigPositionsConnection",
      "kind": "LinkedField",
      "name": "positions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "positions(first:0,query:\"WHERE status=CONFIRMED\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "e4031e2509951faf8c1da961cbb6e004";

export default node;
