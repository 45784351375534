import { asEnums, gt, lte, neq, now, where } from "@gigsmart/biruda";
import {
  getConnectionKey,
  getConnectionNodes,
  graphql,
  useFetchQueryResult,
  useRelayPaginationFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import memoize from "lodash/memoize";
import { useCallback } from "react";
import type { useNotificationSectionPaginationQuery } from "./__generated__/useNotificationSectionPaginationQuery.graphql";
import type { useNotificationSectionQuery } from "./__generated__/useNotificationSectionQuery.graphql";
import type { useNotificationSectionSubscription } from "./__generated__/useNotificationSectionSubscription.graphql";
import type { useNotificationSection_user$key } from "./__generated__/useNotificationSection_user.graphql";
import messageTypes from "./message-types";

export type NotificationSection = ReturnType<typeof useNotificationSection>;

export const notificationsQuery = memoize((isNew: boolean) => {
  return where({
    messageType: asEnums(messageTypes)
  })
    .and(
      isNew
        ? where({ receiptViewedAt: null }).and(
            where({ expiresAt: null }).or({
              expiresAt: gt(now())
            })
          )
        : where({ expiresAt: lte(now()) }).or({ receiptViewedAt: neq(null) })
    )
    .orderBy("insertedAt", "DESC")
    .toString();
});

interface Options {
  isNew: boolean;
  key: string;
  title: string;
}

export default function useNotificationSection({ isNew, key, title }: Options) {
  const query = notificationsQuery(isNew);
  const [result] = useFetchQueryResult<useNotificationSectionQuery>(
    graphql`
      query useNotificationSectionQuery($query: String) {
        user: viewer {
          id
          ...useNotificationSection_user @arguments(query: $query)
        }
      }
    `,
    {
      variables: { query }
    }
  );
  useRelaySubscription<useNotificationSectionSubscription>(
    graphql`
      subscription useNotificationSectionSubscription(
        $messageTypes: [MessageType!]
        $connections: [ID!]!
      ) {
        messageAdded(messageTypes: $messageTypes) {
          newMessageEdge @prependEdge(connections: $connections) {
            node {
              id
              ...notificationRow_message
            }
          }
        }
      }
    `,
    {
      messageTypes,
      connections: [
        getConnectionKey(result?.user?.id, "notificationSection_messages", {
          query
        })
      ]
    },
    {
      subscribe: !!result?.user && isNew
    }
  );

  const {
    data: user,
    loadNext,
    hasNext,
    isLoadingNext
  } = useRelayPaginationFragment<
    useNotificationSectionPaginationQuery,
    useNotificationSection_user$key
  >(
    graphql`
      fragment useNotificationSection_user on User
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 30 }
        after: { type: "String" }
        query: { type: "String" }
      )
      @refetchable(queryName: "useNotificationSectionPaginationQuery") {
        messages(first: $count, after: $after, query: $query)
          @connection(key: "notificationSection_messages", filters: ["query"]) {
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            cursor
            node {
              id
              insertedAt
              ...notificationRow_message
            }
          }
        }
      }
    `,
    result?.user ?? null
  );

  const loadMore = useCallback(() => {
    if (isLoadingNext) return;
    return loadNext(10);
  }, [isLoadingNext, loadNext]);

  return {
    key,
    title,
    isNew,
    loadMore,
    hasNext,
    isLoading: isLoadingNext,
    data: getConnectionNodes(user?.messages)
  };
}
