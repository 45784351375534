import type { ReactNode } from "react";

let idInc = 0;

export type AppletProps<P = Record<string, unknown>> = P & {
  isOpen: boolean;
  toggle: () => void;
};
export interface AppletSpecification<P = unknown> {
  preview: (props: AppletProps<P>) => ReactNode;
  content: (props: AppletProps<P>) => ReactNode;
}

export interface AppletContract<P = unknown> {
  __gigsmart_applet__: AppletSpecification<P> & { id: number };
  cacheKey?: string;
}

export default function createApplet<P = any>(
  spec: AppletSpecification<P>
): AppletContract<P> {
  const id = idInc++;
  return { __gigsmart_applet__: { ...spec, id } };
}
