import {
  Column,
  HighlightedReminder,
  Platform,
  Row,
  Spacer
} from "@gigsmart/atorasu";
import { renderWaitingStartNode } from "@gigsmart/isomorphic-shared/worker/helpers";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import ShiftDescriptionItem from "@gigsmart/seibutsu/shifts/accessory/ShiftDescriptionItem";
import React from "react";
import EngagementMainAction from "./engagement-main-action";
import ProjectDescriptionItem from "./project-description-item";

import { LocationUpdates } from "@gigsmart/feature-flags";
import type { activeEngagementSummaryRow_engagement$key } from "./__generated__/activeEngagementSummaryRow_engagement.graphql";

interface Props {
  engagementRef: activeEngagementSummaryRow_engagement$key | null | undefined;
}

const ActiveEngagementSummaryRow = ({ engagementRef }: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment activeEngagementSummaryRow_engagement on Engagement {
        ...ShiftDescriptionItem_shift
        ...engagementMainAction_engagement
        ...projectDescriptionItem_engagement
        ...helpersWorker_engagement
        gigType
        currentState {
          name
        }
        gig {
          ...WorkerLatestArrivalTimeReminder_gig
          organizationLocation {
            parkingLocations(first: 1) {
              totalCount
            }
          }
        }
        availableActions
      }
    `,
    engagementRef
  );
  if (!engagement) return null;

  const isProject = engagement?.gigType === "PROJECT";
  const showParkingInfo =
    LocationUpdates.isEnabled() &&
    !isProject &&
    ["SCHEDULED", "EN_ROUTE"].includes(engagement?.currentState?.name) &&
    !!engagement?.gig?.organizationLocation?.parkingLocations?.totalCount;

  return LocationUpdates.select(
    <Column gap="standard">
      <Row gap="standard">
        <Column fill>
          {isProject ? (
            <ProjectDescriptionItem engagementRef={engagement} />
          ) : (
            <>
              <ShiftDescriptionItem
                fill
                fragmentRef={engagement}
                showParkingInfo={showParkingInfo}
              />
              {!showParkingInfo && <Spacer size="standard" />}
            </>
          )}
        </Column>
        {Platform.OS === "ios" && <Column />}
        <Column justifyContent="flex-start">
          <EngagementMainAction engagementRef={engagement} />
        </Column>
      </Row>
      {renderWaitingStartNode(engagement)}
      {showParkingInfo && (
        <HighlightedReminder
          header="Check out the important Parking Location information in the Shift Details."
          icon="square-parking"
        />
      )}
    </Column>,
    <Column gap="standard">
      <Row gap="slim">
        {isProject ? (
          <ProjectDescriptionItem engagementRef={engagement} />
        ) : (
          <ShiftDescriptionItem fill fragmentRef={engagement} />
        )}
        <EngagementMainAction engagementRef={engagement} />
      </Row>
      {renderWaitingStartNode(engagement)}
    </Column>
  );
};

export default ActiveEngagementSummaryRow;
