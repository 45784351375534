import {
  type FileItem,
  type MediaPickerFile,
  MessageComposer,
  toast
} from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  graphql,
  useRelayFragment,
  useRelayMutationPromise,
  useRelaySubscription
} from "@gigsmart/relay";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useAddUserFile from "../utils/useAddUserFile";
import type { NewMessageComposerGigSubscription } from "./__generated__/NewMessageComposerGigSubscription.graphql";
import type { NewMessageComposerMutation } from "./__generated__/NewMessageComposerMutation.graphql";
import type { NewMessageComposer_conversation$key } from "./__generated__/NewMessageComposer_conversation.graphql";
import { showPhoneNumberEmailDetectedModal } from "./showPhoneNumberEmailDetectedModal";

interface Props {
  contextId?: string;
  conversationId: string;
  onMessageSend?: () => void;
  fragmentRef?: NewMessageComposer_conversation$key;
}

const useThrottledCallback = (callback: () => void, delay: number) => {
  const options = { leading: true, trailing: false };
  const cbRef = useRef(callback);
  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);
  return useCallback(
    throttle((...args) => cbRef.current(...(args as [])), delay, options),
    [delay]
  );
};

export default function NewMessageComposer({
  contextId,
  conversationId,
  onMessageSend,
  fragmentRef
}: Props) {
  const [messageText, setMessageText] = useState<string>("");
  const [attachments, setAttachments] = useState<FileItem[]>();
  const [submitting, setSubmitting] = useState(false);
  const { uploadFile } = useAddUserFile();
  const currentUser = useCurrentUser();

  const [addUserMessage] = useRelayMutationPromise<NewMessageComposerMutation>(
    graphql`
      mutation NewMessageComposerMutation($input: AddUserMessageInput!) {
        addUserMessage(input: $input) {
          newMessageEdge {
            cursor
            node {
              id
            }
          }
        }
      }
    `
  );
  const result = useRelayFragment(
    graphql`
      fragment NewMessageComposer_conversation on ConversationLike {
        __typename
        ... on ShiftGroupConversation {
          gig {
            id
            conversationRule
          }
        }
      }
    `,
    fragmentRef ?? null
  );
  const gigId =
    result?.__typename === "ShiftGroupConversation" ? result.gig?.id : null;
  const isShiftGroupChat = result?.__typename === "ShiftGroupConversation";
  const conversationRule =
    result?.__typename === "ShiftGroupConversation"
      ? result.gig?.conversationRule
      : null;

  useRelaySubscription<NewMessageComposerGigSubscription>(
    graphql`
      subscription NewMessageComposerGigSubscription($gigId: ID!) {
        gigUpdated(gigId: $gigId) {
          gig {
            conversationRule
          }
        }
      }
    `,
    {
      gigId: gigId ?? ""
    },
    {
      subscribe: !!gigId
    }
  );

  const uploadAttachment = async (files: MediaPickerFile[]) => {
    const newAttachments = [] as FileItem[];
    for (const file of files) {
      try {
        const userFile = await uploadFile(file);
        if (userFile) {
          newAttachments.push({
            id: userFile.id,
            uri: userFile.url,
            type: userFile.mimeType,
            name: userFile.filename ?? ""
          });
        }
      } catch (e) {
        toast.error("Something went wrong wile uploading image");
      }
    }

    setAttachments((attachments) => (attachments ?? []).concat(newAttachments));
  };

  const removeAttachment = (id: string) => {
    setAttachments(attachments?.filter((item) => item.id !== id));
  };

  const handleError = (error: Error) => {
    if (
      error?.message ===
      "email addresses are not allowed, phone numbers are not allowed"
    ) {
      toast.warning(
        "GigSmart policy prohibits sharing of emails and phone numbers.",
        {
          dismissAfter: 3000
        }
      );
    } else if (error?.message === "email addresses are not allowed") {
      toast.warning("GigSmart policy prohibits sharing of emails.", {
        dismissAfter: 3000
      });
    } else if (error?.message === "phone numbers are not allowed") {
      toast.warning(
        currentUser?.__typename === "Requester" ||
          currentUser?.__typename === "OrganizationRequester"
          ? "GigSmart policy prohibits sharing of phone numbers. You can call this Worker through the app once they are hired for the Gig."
          : "GigSmart policy prohibits sharing of phone numbers. You can call this Requester through the app once you are hired for the Gig.",
        {
          dismissAfter: 3000
        }
      );
    } else if (error?.message === "You cannot send messages at this time") {
      toast.warning("This worker is no longer available for your gig.", {
        dismissAfter: 3000
      });
    } else if (conversationRule !== "ALL_PARTICIPANTS") {
      toast.warning("Your message failed to send. The chat has been disabled.");
    } else {
      toast.warning(
        "Your message failed to send. Confirm network connectivity and try again.",
        { dismissAfter: 3000 }
      );
    }
  };

  const handleSubmit = useThrottledCallback(async () => {
    if ((attachments ?? [])?.length > 10) {
      toast.warning(
        "Cannot send more than 10 images. Please remove additional images to send message."
      );
      return;
    }
    setSubmitting(true);

    const emailValidator = Validator.noEmailAddressesOrGmail({});
    const phoneValidator = Validator.noPhoneNumbers({
      message: "phone numbers are not allowed"
    });
    const emailResult = emailValidator("", messageText);
    const phoneResult = phoneValidator("", messageText);
    const validationCheck =
      emailResult !== null &&
      emailResult?.length === 0 &&
      phoneResult?.length === 0 &&
      phoneResult !== null;

    if (!validationCheck) {
      // show modal
      let contentWarningText =
        "Always use caution when sharing personal contact information to communicate with Workers.";
      if (currentUser?.__typename === "Worker") {
        contentWarningText = isShiftGroupChat
          ? "Always use caution when sharing personal contact information to communicate with Requesters and other Workers on the Shift."
          : "Always use caution when sharing personal contact information to communicate with Requesters.";
      }
      const modalResult = await showPhoneNumberEmailDetectedModal({
        contentWarningText
      });
      if (!modalResult) {
        setSubmitting(false);
        return;
      }
    }

    if (messageText.trim() || attachments) {
      try {
        const attachmentIds = attachments
          ?.map((a) => a.id ?? "")
          .filter(Boolean);
        const msg = await addUserMessage({
          input: {
            attachmentIds,
            ...(messageText && { body: messageText.trim() }),
            ...(contextId && { contextId }),
            conversationId
          }
        });

        msg.addUserMessage?.newMessageEdge?.node && onMessageSend?.();

        setAttachments(undefined);
        setMessageText("");
      } catch (error) {
        handleError(error);
      } finally {
        setSubmitting(false);
      }
    }
  }, 1000);
  return (
    <MessageComposer
      testID="message-composer"
      messageText={messageText}
      onChangeMessageText={setMessageText}
      attachments={attachments}
      onSelectAttachment={uploadAttachment}
      onRemoveAttachment={removeAttachment}
      onSubmit={handleSubmit}
      disableSubmitButton={submitting}
    />
  );
}
