import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { WorkHistoryForm } from "@gigsmart/seibutsu/worker-profile/WorkHistoryForm";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { workHistoryScreenQuery } from "./__generated__/workHistoryScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "ProfileWorkHistory">;

export default createSuspendedQueryContainer<workHistoryScreenQuery, Props>(
  function WorkHistoryScreen({ response }) {
    return <WorkHistoryForm {...response?.node} />;
  },
  {
    query: graphql`
      query workHistoryScreenQuery($id: ID!) {
        node(id: $id) {
          ... on WorkerWorkHistory {
            id
            employer
            employmentStart
            employmentEnd
            positionTitle
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route?.params?.workHistoryId ?? "" })
  }
);
