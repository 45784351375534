import React from "react";

import { Column, Icon, Stack, Text } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import BankingAdCard from "@gigsmart/seibutsu/worker/BankingAdCard";
import UpcomingShiftReminder, {
  upcomingShiftReminderQuery
} from "@gigsmart/seibutsu/worker/UpcomingShiftReminder";

import AccountBalance from "./account-balance";
import PaymentMethodRow from "./payment-method-row";
import UnpaidEngagements from "./unpaid-engagements";
import WalletTabs from "./wallet-tabs";

import {
  ContentArea,
  ScreenScroll,
  Spacer,
  StickyReminderFooter
} from "@gigsmart/atorasu";
import type { walletScreenQuery } from "./__generated__/walletScreenQuery.graphql";
import EarningsHistoryCard from "./earnings-history-card";
import PendingTransfers from "./pending-transfers";

export default createSuspendedQueryContainer<walletScreenQuery>(
  function WalletScreen({ response: result }) {
    const payableAccount = result?.viewer?.payableAccount ?? null;
    const worker = result?.viewer ?? null;
    const upcomingShiftCount = result?.viewer?.upcomingShiftCount?.totalCount;

    return (
      <ScreenScroll
        testID="scrollview-wallet"
        footer={!!upcomingShiftCount && <UpcomingShiftReminder />}
      >
        <AccountBalance worker={worker} />
        <UnpaidEngagements rootQuery={result ?? null} />
        <BankingAdCard screen="WALLET" inset />
        <Spacer />
        <PendingTransfers worker={worker} />
        <PaymentMethodRow workerPayableAccount={payableAccount} />
        <Spacer />
        <WalletTabs worker={worker} workerPayableAccount={payableAccount} />
        <Spacer size="compact" />
        <ContentArea>
          <EarningsHistoryCard />
        </ContentArea>
        <Spacer size="compact" />
        <Column alignSelf="center" testID="powered-by-stripe">
          <Stack horizontal size="compact" alignItems="center">
            <Text color="neutral">Powered By</Text>
            <Icon name="stripe" size="default" color="neutral" />
          </Stack>
        </Column>
        {upcomingShiftCount ? (
          <StickyReminderFooter />
        ) : (
          <Spacer size="large" />
        )}
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query walletScreenQuery(
        $upcomingShiftsQuery: String
      ) {
        viewer {
          ... on Worker {
            ...accountBalance_worker
            payableAccount {
              ...paymentMethodRow_payableAccount
              ...walletTabs_workerPayableAccount
            }
            ...walletTabs_worker
            ...pendingTransfers_worker
            upcomingShiftCount: engagements(
              first: 0
              query: $upcomingShiftsQuery
            ) {
              totalCount
            }
          }
        }
        ...unpaidEngagements_rootQuery
      }
    `,
    variables: {
      upcomingShiftsQuery: upcomingShiftReminderQuery
    },
    fetchPolicy: "network-only"
  }
);
