import { ContentArea, IconCircle, getDisplayInitials } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { Card, UserRow } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import WorkerBlockedOrganizationReminder, {
  showWorkerBlockedOrganizationReminder
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/WorkerBlockedOrganizationReminder";
import React from "react";
import type { gigRequesterRow_gigLike$key } from "./__generated__/gigRequesterRow_gigLike.graphql";

interface Props {
  gigLikeRef?: gigRequesterRow_gigLike$key | null | undefined;
  engagementId?: string | null | undefined;
  workerId?: string | null | undefined;
}

const GigRequesterRow = ({ gigLikeRef, engagementId, workerId }: Props) => {
  const gig = useRelayFragment(
    graphql`
      fragment gigRequesterRow_gigLike on GigLike {
        id
        gigType
        organization {
          id
          name
          logoUrl
          overallRating
          reviewsReceived(first: 0) {
            totalCount
          }
        }
        primaryContact {
          displayName
          overallRating
          profilePhoto {
            url
          }
          requester {
            overallRating
            reviewsReceived(first: 0) {
              totalCount
            }
          }
        }
        requester {
          displayName
          overallRating
          reviewsReceived(first: 0) {
            totalCount
          }
          profilePhoto {
            url
          }
        }
      }
    `,
    gigLikeRef ?? null
  );
  const history = useHistory();

  if (!gig) return null;

  const isProject = gig.gigType === "PROJECT";
  const uriPrefix = isProject ? "/projects" : "/shifts";
  const { overallRating, reviewsReceived } =
    (gig.gigType === "PROJECT" ? gig?.requester : gig?.organization) ?? {};
  const name =
    (!isProject ? gig.organization?.name : gig.requester?.displayName) ?? "";
  const profilePhoto = !isProject
    ? gig.organization?.logoUrl
    : gig.requester?.profilePhoto?.url;
  return (
    <Card noMargin noSpacing noRadius>
      <UserRow
        variant="button"
        buttonText="View Profile"
        customAvatar={
          gig?.organization &&
          !gig?.organization.logoUrl && (
            <IconCircle
              size="medium"
              iconColor="primary"
              color="primary"
              icon="building"
            />
          )
        }
        name={name}
        initials={getDisplayInitials(name)}
        profilePhotoUrl={profilePhoto}
        rating={overallRating ?? 5}
        ratingCount={reviewsReceived?.totalCount}
        secondaryTitle={
          gig.organization
            ? `Primary Contact: ${gig.primaryContact?.displayName}`
            : null
        }
        handleButtonPress={() =>
          engagementId
            ? history.push(`${uriPrefix}/${engagementId}/requester`)
            : history.push(`/browse${uriPrefix}/${gig.id}/requester`)
        }
      />
      {showWorkerBlockedOrganizationReminder(gig?.organization?.id) && (
        <WorkerBlockedOrganizationReminder
          workerId={workerId ?? ""}
          organizationId={gig.organization.id}
          Wrapper={ContentArea}
        />
      )}
    </Card>
  );
};

export default GigRequesterRow;
