import React from "react";
import Svg, {
  Defs,
  LinearGradient,
  Stop,
  G,
  Rect,
  Path
} from "react-native-svg";

export default () => (
  <Svg height={100} width={100} viewBox="0 0 192 192">
    <Defs>
      <LinearGradient
        id="linear-gradient"
        x1={96}
        y1={4.1899}
        x2={96}
        y2={190.76254}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.01109} stopColor="#ed6123" />
        <Stop offset={0.66876} stopColor="#e04326" />
        <Stop offset={1} stopColor="#d83227" />
      </LinearGradient>
    </Defs>
    <G id="Layer_2" data-name="Layer 2">
      <G id="Layer_1-2" data-name="Layer 1">
        <Rect fill="url(#linear-gradient)" width={192} height={192} rx={25} />
        <Path
          fill="#fff"
          d="M49.68443,46.63687A69.353,69.353,0,0,1,99.04758,26.19V16.9698A79.03017,79.03017,0,0,0,20.01736,96h9.22019A69.35291,69.35291,0,0,1,49.68443,46.63687Z"
        />
        <Path
          fill="#fff"
          d="M99.04758,165.81v9.2202A78.70652,78.70652,0,0,0,149.845,156.538l-5.92406-7.06A69.28294,69.28294,0,0,1,99.04758,165.81Z"
        />
        <Path
          fill="#fff"
          d="M74.44735,96H65.22716a33.82041,33.82041,0,0,0,33.82042,33.8204v-9.2202A24.62807,24.62807,0,0,1,74.44735,96Z"
        />
        <Path
          fill="#fff"
          d="M99.04758,77.24954v.002h0a9.53028,9.53028,0,0,1,0-19.06055h0V48.97081h0a18.75047,18.75047,0,1,0,0,37.50093h0v-.002a9.53027,9.53027,0,0,1,0,19.06054v9.22019a18.75046,18.75046,0,0,0,0-37.50092Z"
        />
        <Path
          fill="#fff"
          d="M99.04758,135.67014A39.6559,39.6559,0,0,1,64.70005,76.16947l-7.99859-4.618a48.896,48.896,0,1,0,90.1096,34.9152h-9.49744A39.73969,39.73969,0,0,1,99.04758,135.67014Z"
        />
        <Path
          fill="#fff"
          d="M123.649,91.3899v9.2202h29.94463a54.73965,54.73965,0,1,1-19.3595-46.54383l5.92456-7.06058a63.95817,63.95817,0,1,0,22.68239,53.60441c.10848-1.52287.16679-9.2202.16679-9.2202Z"
        />
      </G>
    </G>
  </Svg>
);
