/**
 * @generated SignedSource<<affb35895a8e28c9e708b22c2f1db668>>
 * @relayHash 779cc5d3e7fab4e271ee12ca011e9754
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:vlHHA4rLOAwllfMh38Am-WnT-IJOU5B61-gtoN85uyY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeolocationProviderLocationSubscription$variables = {
  workerId: string;
};
export type GeolocationProviderLocationSubscription$data = {
  readonly workerLocationAdded: {
    readonly newWorkerLocationEdge: {
      readonly node: {
        readonly worker: {
          readonly currentLocation: {
            readonly " $fragmentSpreads": FragmentRefs<"GeolocationProvider_workerLocation">;
          } | null | undefined;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export type GeolocationProviderLocationSubscription = {
  response: GeolocationProviderLocationSubscription$data;
  variables: GeolocationProviderLocationSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "workerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeolocationProviderLocationSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WorkerLocationAddedPayload",
        "kind": "LinkedField",
        "name": "workerLocationAdded",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerLocationsEdge",
            "kind": "LinkedField",
            "name": "newWorkerLocationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerLocation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerLocation",
                        "kind": "LinkedField",
                        "name": "currentLocation",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "GeolocationProvider_workerLocation"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeolocationProviderLocationSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WorkerLocationAddedPayload",
        "kind": "LinkedField",
        "name": "workerLocationAdded",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerLocationsEdge",
            "kind": "LinkedField",
            "name": "newWorkerLocationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerLocation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerLocation",
                        "kind": "LinkedField",
                        "name": "currentLocation",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latitude",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "longitude",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reportedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accuracy",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rationale",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:vlHHA4rLOAwllfMh38Am-WnT-IJOU5B61-gtoN85uyY",
    "metadata": {},
    "name": "GeolocationProviderLocationSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "3824bb36d716c3a08eb1aa3590b941a2";

export default node;
