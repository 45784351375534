import {
  Avatar,
  Column,
  ContentArea,
  Divider,
  IconButton,
  IconText,
  Row,
  Spacer,
  Stack,
  Surface,
  Text,
  Well,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React from "react";
import { Participants, useShowParticipantsModal } from "./Participants";
import ShiftInfo from "./RequesterConversationHeader/ShiftInfo";
import WorkerMoreMenu from "./WorkerMoreMenu";
import type { WorkerConversationHeader_conversationLike$key } from "./__generated__/WorkerConversationHeader_conversationLike.graphql";

export function WorkerConversationHeader({
  organization,
  engagements,
  participants,
  engagement,
  status,
  type,
  result
}: FragmentContainerInnerComponentProps<WorkerConversationHeader_conversationLike$key>) {
  const showParticipantsModal = useShowParticipantsModal(
    participants,
    engagements,
    organization
  );
  const isSmUp = useMatchesViewport(({ size }) => size.small.up);
  const isCallable = status === "OPEN";
  const name = engagement?.gig?.requester?.displayName ?? organization?.name;
  const profilePhoto =
    engagement?.gig?.requester?.profilePhoto?.url ?? organization?.logoUrl;
  return (
    <Surface topRadius="none" bottomRadius="none">
      {type === "ShiftGroupConversation" && (
        <Well color="primary">
          <IconText
            spacing="medium"
            icon="messages"
            color="primary"
            size="small"
          >
            <Text
              weight="bold"
              color="primary"
              testID="shift-group-chat-participants"
            >
              Shift Group Chat •{" "}
              <Text weight="normal" color="primary">
                {pluralize((participants?.totalCount ?? 0) + 1, "Participant")}
              </Text>
            </Text>
          </IconText>
        </Well>
      )}
      <ContentArea>
        {type === "ShiftGroupConversation" ? (
          <>
            <Stack justifyContent="space-between" horizontal>
              <ShiftInfo fragmentRef={result ?? null} />
              <Participants
                participants={participants}
                engagements={engagements}
              />
            </Stack>
            <Row alignItems="center">
              <WorkerMoreMenu fragmentRef={result} />
            </Row>
          </>
        ) : (
          <Row justifyContent="space-between">
            <Stack horizontal alignItems="center" justifyContent="center" fill>
              <Stack
                horizontal
                fill
                alignItems="center"
                justifyContent="flex-start"
              >
                <Avatar size="medium" uri={profilePhoto} />
                <Column fill>
                  <Text variant="header" numberOfLines={1}>
                    {name}
                  </Text>
                </Column>
              </Stack>
              {isCallable && (
                <IconButton
                  size="small"
                  testID="phone-modal-btn"
                  variant="solid"
                  name="phone"
                  color="primary"
                  onPress={() => showParticipantsModal()}
                />
              )}
              <Divider dir="y" fullWidth />
              <Participants
                participants={participants}
                engagements={engagements}
                organization={organization}
              />
              {isSmUp && <Divider dir="y" fullWidth />}
              {isSmUp && <Spacer size="none" horizontal />}
            </Stack>
            <Row alignItems="center">
              <WorkerMoreMenu fragmentRef={result} />
            </Row>
          </Row>
        )}
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<WorkerConversationHeader_conversationLike$key>(
  graphql`
    fragment WorkerConversationHeader_conversationLike on ConversationLike
    @argumentDefinitions(isWorker: { type: "Boolean", defaultValue: false }) {
      type: __typename
      ...ShiftInfo_conversation
      ...WorkerMoreMenu_conversationLike
      status
      participants(first: 30) {
        totalCount
        edges {
          node {
            id
            capabilities
            user {
              __typename
              id
              ... on OrganizationRequester {
                displayName
                profilePhoto {
                  url
                }
                organization {
                  name
                }
              }
              ... on Requester {
                displayName
                profilePhoto {
                  url
                }
                primaryOrganization {
                  name
                }
              }
              ... on Worker {
                displayName
                profilePhoto {
                  url
                }
                isFavorite @skip(if: $isWorker)
                groupAssociations(first: 0) @skip(if: $isWorker) {
                  totalCount
                }
              }
            }
          }
        }
      }
      ... on EngagementConversation {
        engagement {
          id
          gig {
            id
            requester {
              id
              displayName
              profilePhoto {
                url
              }
            }
          }
        }
      }
      ... on OrganizationWorkerConversation {
        organization {
          id
          name
          logoUrl
          primaryContact {
            id
          }
        }
        engagements(
          first: 50
          query: "WHERE currentStateName IN [CONFIRMING, SCHEDULED, ENDED, PENDING_REVIEW, PENDING_TIMESHEET_APPROVAL]"
        ) {
          edges {
            node {
              contactNumber
              gig {
                primaryContact {
                  id
                  displayName
                }
              }
              capabilities {
                type
                restrictedBy {
                  message
                }
                expiresAt
                status
              }
            }
          }
        }
      }
    }
  `,
  WorkerConversationHeader
);
