import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { createContext, useContext, type ReactNode } from "react";
import type {
  useWorkerData$data,
  useWorkerData$key
} from "./__generated__/useWorkerData.graphql";

export type WorkerData = useWorkerData$data;
const WorkerContext = createContext<WorkerData | null | undefined>(null);

export function WorkerDataProvider({
  children,
  worker: workerRef
}: {
  children: ReactNode;
  worker: useWorkerData$key | null | undefined;
}) {
  const worker = useRelayFragment(
    graphql`
      fragment useWorkerData on Worker {
        displayName
      }
    `,
    workerRef
  );
  return (
    <WorkerContext.Provider value={worker}>{children}</WorkerContext.Provider>
  );
}

export const useWorkerData = () => useContext(WorkerContext);
