import moment from "moment";
import React from "react";

import {
  Column,
  ContentArea,
  Pressable,
  Spacer,
  Stack,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { humanize } from "@gigsmart/isomorphic-shared/iso/time";
import {
  BulletList,
  Divider,
  StyledScrollView,
  StyledView
} from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";

import { gt, now, where } from "@gigsmart/biruda";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import RestrictedAction from "./RestrictedAction";
import type { RestrictionTabsQuery } from "./__generated__/RestrictionTabsQuery.graphql";

interface Props {
  toggleVisibility: (visible: boolean) => void;
}
export default createSuspendedQueryContainer<RestrictionTabsQuery, Props>(
  function RestrictionTabs({ response: result, toggleVisibility }) {
    const accountReinstatementLink = () => {
      toggleVisibility(false);
      showEmbeddedBrowser({
        url: "https://gigsmart.com/find-work/account-reinstatement-information/",
        onClose: () => toggleVisibility(true)
      });
    };

    const restrictions = getConnectionNodes(result?.viewer?.restrictions);
    if (!restrictions || restrictions.length === 0) return null;

    const expiresAtText = (restriction: any) => {
      if (!restriction.expiresAt)
        return (
          <>
            <Text align="center" weight="bold">
              Your account restriction is permanent.
            </Text>
            <ContentArea variant="none">
              <Pressable
                testID="worker-reinstatement-link"
                eventTargetName="Worker Reinstatement Link Button"
                onPress={accountReinstatementLink}
                eventEntityType="WorkerReinstatement"
              >
                <Text color="link" align="center">
                  Learn more about Account Reinstatement
                </Text>
              </Pressable>
            </ContentArea>
          </>
        );

      const now = moment();
      const diff = moment(restriction.expiresAt).diff(now);
      const duration = moment.duration(diff);
      const daysLeft = duration.days();
      const hoursLeft = duration.hours();
      return (
        <Text align="center">
          Your account restriction will expire on{" "}
          {humanize(restriction.expiresAt, "dateShort")} in{"\n\n"}
          <Text weight="bold">
            {pluralize(daysLeft, "day")} and {pluralize(hoursLeft, "hour")}
          </Text>
        </Text>
      );
    };

    const styles = {
      paddingLeft: 16
    };

    return (
      <StyledScrollView showsVerticalScrollIndicator>
        <Spacer size="compact" />
        <Column justifyContent="space-between">
          <StyledView horizontalPadding={1} style={styles}>
            {restrictions.map((restriction, index) => (
              <React.Fragment key={index}>
                <Stack>
                  {index !== 0 && <Divider />}
                  <Text>{restriction?.reason ?? "Reason wasn't found"}</Text>
                  <>
                    <Text>
                      During the restriction, you cannot use the GigSmart app
                      to:
                    </Text>
                    <BulletList backgroundColor="transparent" spacing={1.2}>
                      {restriction?.restrictedActions?.map((action) => (
                        <RestrictedAction
                          key={action.type}
                          restriction={action}
                        />
                      ))}
                    </BulletList>
                    {expiresAtText(restriction)}
                  </>
                </Stack>
              </React.Fragment>
            ))}
          </StyledView>
        </Column>
      </StyledScrollView>
    );
  },
  {
    query: graphql`
      query RestrictionTabsQuery($query: String) {
        viewer {
          restrictions(first: 10, query: $query) {
            edges {
              node {
                reason
                expiresAt
                restrictedActions {
                  type
                  ...RestrictedAction_restriction
                }
              }
            }
          }
        }
      }
    `,
    variables: () => {
      const query = where({
        expiresAt: gt(now()),
        removedAt: null
      })
        .or(
          where({
            expiresAt: null,
            removedAt: null
          })
        )
        .toString();
      return { query };
    }
  }
);
