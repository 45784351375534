import {
  Button,
  Column,
  ContentArea,
  Divider,
  FooterSpacer,
  Icon,
  IconText,
  ScreenScroll,
  ScreenTop,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style"; // eslint-disable-line no-restricted-imports
import type { AppParamList, AppScreenProps } from "@gigsmart/kaizoku";
import { Card } from "@gigsmart/katana";
import { graphql, useRelayOrchestrator } from "@gigsmart/relay";
import RequesterLogo from "@gigsmart/seibutsu/Brand/RequesterLogo";
import WorkerLogo from "@gigsmart/seibutsu/Brand/WorkerLogo";
import React, { useState } from "react";
import { Platform, TouchableOpacity } from "react-native";
import type { shareScreenQuery } from "./__generated__/shareScreenQuery.graphql";
import { referralShare, requesterShare, share } from "./share";

const tiles = {
  Requester: {
    label: "Get Workers",
    text: "Share with those looking for Workers",
    testID: "requester-app-card",
    ImageComponent: RequesterLogo,
    getMessage: (url: string, referralCode?: string | null) =>
      referralCode
        ? `Short on staff? Choose from thousands of workers that meet your businesses' needs. Download the GigSmart Get Workers app: ${url}, and use referral code ${referralCode} during Sign Up!`
        : `Find self-reliant workers.\n\nThis isn't just an app - it's a whole new way to work.\n${url}`
  },
  Worker: {
    label: "Get Gigs",
    text: "Share with those looking for Work",
    testID: "worker-app-card",
    ImageComponent: WorkerLogo,
    getMessage: (url: string, _referralCode?: string | null) =>
      `Find jobs with more freedom and flexibility.\n\nThis isn't just an app - it's a whole new way to work.\n\n${url}`
  }
};

export default function ShareScreen(_: AppScreenProps<AppParamList, "Share">) {
  const { fetchQuery } = useRelayOrchestrator();
  const [{ appName, message, url }, setState] = useState(() => ({
    url: "",
    message: "",
    appName: ""
  }));

  const styles = useStyles(({ getColor }) => ({
    outer: { flex: 1 },
    card: {
      height: 215,
      justifyContent: "center",
      paddingHorizontal: 16
    },
    activeCard: {
      borderColor: getColor("primary", "fill"),
      borderWidth: 2,
      paddingHorizontal: 15
    },
    icon: { padding: 5, alignSelf: "flex-end" },
    check: {
      position: "absolute",
      right: 8,
      top: 6
    }
  }));

  const handleShare = async (appName: "Requester" | "Worker") => {
    const result = await fetchQuery<shareScreenQuery>(
      graphql`
        query shareScreenQuery {
          viewer {
            referralCode {
              code
            }
          }
        }
      `,
      {}
    );

    const referralCode = result.viewer?.referralCode?.code;
    if (Platform.OS === "web") {
      const url =
        appName === "Requester"
          ? await requesterShare("Requester", referralCode)
          : "https://gigsmart.com/workers";
      const message = tiles[appName].getMessage(url, referralCode);
      setState((state) => ({ ...state, appName, message, url }));
    } else {
      appName === "Requester"
        ? referralShare(appName, referralCode)
        : share(appName);
    }
  };

  return (
    <ScreenScroll constraint="small" testID="share-screen">
      <ScreenTop />
      <Surface>
        <ContentArea constraint="xsmall">
          <IconText
            icon="square-share-nodes"
            size="large"
            textWeight="bold"
            color="primary"
            spacing="standard"
          >
            Share GigSmart!
          </IconText>

          <Spacer size="compact" />
          <Text variant="note" color="primary">
            Help us show others the new way to hire Workers or find work.
          </Text>
          <Spacer />
          <Stack horizontal={Platform.OS === "web"}>
            {(["Requester", "Worker"] as const).map((id) => {
              const active = appName === id;
              const { ImageComponent, testID, label, text } = tiles[id];
              return (
                <TouchableOpacity
                  key={id}
                  testID={testID}
                  onPress={async () => void handleShare(id)}
                  style={styles.outer}
                >
                  {active && (
                    <Column style={styles.check}>
                      <Icon
                        size="large"
                        name="circle-check"
                        color="primary"
                        variant="solid"
                      />
                    </Column>
                  )}
                  <Card
                    style={[styles.card, active && styles.activeCard]}
                    noMargin
                    noSpacing
                    shadow={false}
                    border
                  >
                    <Column alignItems="center">
                      <ImageComponent />
                      <Spacer />
                      <Text
                        color={id === "Requester" ? "danger" : "primary"}
                        variant="title"
                        align="center"
                      >
                        {label}
                      </Text>
                      <Spacer size="compact" />
                      <Text variant="note" align="center">
                        {text}
                      </Text>
                    </Column>
                  </Card>
                </TouchableOpacity>
              );
            })}
          </Stack>
          <Spacer />
          {!!appName && (
            <Stack fill={1}>
              <Divider />
              <Button
                testID="email-share-button"
                label="Share with Email"
                icon="square-envelope"
                outline
                color="primary"
                onPress={() =>
                  window.open(
                    `mailto:?subject=${encodeURIComponent(
                      "GigSmart"
                    )}&body=${encodeURIComponent(message)}`,
                    "_blank"
                  )
                }
              />
              <Button
                testID="twitter-share-button"
                label="Share with Twitter"
                icon="twitter-square"
                color="primary"
                outline
                onPress={() =>
                  window.open(
                    `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
                      message
                    )}`,
                    "_blank"
                  )
                }
              />
              <Button
                testID="facebook-share-button"
                label="Share with Facebook"
                icon="facebook-square"
                color="primary"
                outline
                onPress={() =>
                  window.open(
                    `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      url
                    )}&quote=${message}`,
                    "_blank"
                  )
                }
              />
            </Stack>
          )}
        </ContentArea>
      </Surface>
      <FooterSpacer />
    </ScreenScroll>
  );
}
