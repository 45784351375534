import { useDebouncedCallback } from "@gigsmart/atorasu";
import {
  type GraphQLTaggedNode,
  type KeyType,
  type QuerySpec,
  refetchOnly,
  useRelayOrchestrator,
  useRelayPaginationFragment
} from "@gigsmart/relay";
import isEqual from "lodash/isEqual";
import {
  type ComponentType,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

export interface Options<Q extends QuerySpec, TKey extends KeyType, T> {
  pageSize?: number;
  getData: (data: TKey[" $data"] | null | undefined) => T[] | null;
  refetchVars?: Omit<Q["variables"], "id">;
  refetchDebounce?: number;

  renderEmptyView?: (
    loading: boolean
  ) => ComponentType<any> | ReactElement | null;
  renderFooterView?: (
    loading: boolean
  ) => ComponentType<any> | ReactElement | null;
}

/** @deprecated use from @gigsmart/seibutsu */
const useInfiniteList = <Q extends QuerySpec, TKey extends KeyType, T>(
  fragmentInput: GraphQLTaggedNode,
  parentRef: TKey | null | undefined,
  {
    getData,
    pageSize = 20,
    refetchVars,
    refetchDebounce = 300,
    renderEmptyView,
    renderFooterView
  }: Options<Q, TKey, T>
) => {
  const { fetchQuery } = useRelayOrchestrator();
  const {
    data: fragmentData,
    loadNext,
    isLoadingNext,
    hasNext,
    refetch
  } = useRelayPaginationFragment<Q, TKey>(fragmentInput, parentRef);
  const promiseChain = useRef(Promise.resolve());
  const [isRefetching, setRefetching] = useState(false);
  const handleLoadNext = useCallback(() => {
    if (!hasNext) return;
    promiseChain.current = promiseChain.current.then(
      async () =>
        await new Promise((resolve) =>
          loadNext(pageSize, { onComplete: () => resolve() })
        )
    );
  }, [loadNext, pageSize, hasNext]);

  // refetch
  const initialRefetchVars = useRef(refetchVars);
  const debouncedRefetch = useDebouncedCallback((vars: typeof refetchVars) => {
    const fragmentId =
      !!fragmentData && "id" in (fragmentData as object)
        ? (fragmentData as { id: string }).id
        : undefined;
    refetchOnly<Q>(
      refetch,
      fetchQuery,
      parentRef,
      fragmentInput,
      fragmentId,
      vars
    ).finally(() => setRefetching(false));
  }, refetchDebounce);
  const handleRefetch = useCallback(() => {
    // setRefetching(true);
    debouncedRefetch(initialRefetchVars.current);
  }, []);

  useEffect(() => {
    const shouldRefetch =
      !!refetchVars && !isEqual(initialRefetchVars.current, refetchVars);
    if (shouldRefetch) {
      setRefetching(true);
      initialRefetchVars.current = refetchVars;
      debouncedRefetch(refetchVars);
    }
  }, [debouncedRefetch, refetchVars]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(
    () => (isRefetching ? null : getData(fragmentData)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fragmentData, isRefetching]
  );
  return {
    data,
    fragmentData,
    refetch: handleRefetch,
    hasNext,
    isRefetching,
    isLoadingNext,

    onEndReached: handleLoadNext,
    ListEmptyComponent: renderEmptyView?.(isRefetching || isLoadingNext),
    ListFooterComponent: renderFooterView?.(isRefetching || isLoadingNext)
  };
};

export default useInfiniteList;
