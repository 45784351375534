/**
 * @generated SignedSource<<4bc8941486ba8be6164eebb129cb18ef>>
 * @relayHash 148bfbee6d10279a5c4ebe12d656e001
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:jy4Y_nXr4hOcjsyPg4c-X-I67KIZ7PzbXoI0yo8ya8s

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PositionsStepQuery$variables = {
  id: string;
};
export type PositionsStepQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"useGigPositions_gigLike">;
  } | null | undefined;
};
export type PositionsStepQuery = {
  response: PositionsStepQuery$data;
  variables: PositionsStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigCategory",
    "kind": "LinkedField",
    "name": "category",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Icon",
        "kind": "LinkedField",
        "name": "categoryIcon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "GigPosition",
    "kind": "LinkedField",
    "name": "position",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PositionsStepQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useGigPositions_gigLike"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PositionsStepQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "GigSeries",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Gig",
                "abstractKey": null
              }
            ],
            "type": "GigLike",
            "abstractKey": "__isGigLike"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:jy4Y_nXr4hOcjsyPg4c-X-I67KIZ7PzbXoI0yo8ya8s",
    "metadata": {},
    "name": "PositionsStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c116479488aef3c6cf119d174b64a67c";

export default node;
