import React from "react";

import Stack from "../container/stack";
import StyledView from "../container/styled-view";
import { Flex } from "../flex";
import { StyledIcon } from "../icon";
import { Spacer } from "../spacer";
import { theme } from "../style";
import { SmallClearButton, SmallSolidButton } from "../tappable/small-buttons";
import { StyledText } from "../text";

interface Props {
  paymentMethodType: "BankCard" | "BankAccount";
  paymentMethod: {
    paymentMethodFailures?: {
      totalCount: number;
    } | null;
    last4?: string;
    accountHolderName?: string | null;
    expirationMonth?: number;
    expirationYear?: number;
    brand?: string;
    bankName?: string;
  } | null;
  handleChange?: () => void;
  handleAdd?: () => void;
  subtitle?: string;
}

const PaymentInfoRow = ({
  paymentMethodType,
  paymentMethod,
  handleAdd,
  handleChange,
  subtitle
}: Props) => {
  const hasErrors = (paymentMethod?.paymentMethodFailures?.totalCount ?? 0) > 0;
  return (
    <>
      <Spacer border units={0} />
      <StyledView
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        verticalPadding={2}
      >
        <Flex fill={1}>
          <StyledText color={hasErrors ? "red" : "black"} bold={hasErrors}>
            {paymentMethodType === "BankCard" && (
              <>
                {paymentMethod
                  ? `${paymentMethod?.brand?.toUpperCase()} *${
                      paymentMethod?.last4
                    }`
                  : "Credit/Debit Card"}
              </>
            )}
            {paymentMethodType === "BankAccount" && (
              <>
                {paymentMethod
                  ? `${paymentMethod?.bankName?.toUpperCase()} *${
                      paymentMethod?.last4
                    }`
                  : "Bank Account"}
              </>
            )}
          </StyledText>
          {paymentMethodType === "BankCard" && (
            <>
              <Spacer units={1} />
              <StyledText
                color="neutralDark"
                fontSize={theme.font.size.small}
                numberOfLines={1}
              >
                {paymentMethod
                  ? `${paymentMethod?.accountHolderName} ${
                      paymentMethod?.expirationMonth
                    }/${paymentMethod?.expirationYear?.toString()?.slice(-2)}`
                  : subtitle ?? ""}
              </StyledText>
            </>
          )}
        </Flex>
        {paymentMethod ? (
          !!handleChange && (
            <SmallClearButton
              onPress={handleChange}
              title="Change"
              icon="chevron-right"
              iconPosition="right"
            />
          )
        ) : handleAdd ? (
          <SmallSolidButton onPress={handleAdd} icon="plus" title="Add" />
        ) : null}
      </StyledView>
      <Spacer border units={0} />
      {hasErrors && (
        <>
          <Spacer units={2} />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Spacer units={2} direction="row" />
            <StyledIcon
              name="triangle-exclamation"
              color="red"
              size={theme.font.size.small}
              variant="solid"
            />
            <StyledText fontSize={theme.font.size.small}>
              There was an issue with this{" "}
              {paymentMethodType === "BankCard" ? "card" : "account"}.
            </StyledText>
          </Stack>
        </>
      )}
    </>
  );
};

export default PaymentInfoRow;
