import { type FilterTabOption, FilterTabs } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useMemo } from "react";
import type { ProjectGigsFilter_worker$key } from "./__generated__/ProjectGigsFilter_worker.graphql";
import type { EngagementStateName } from "./__generated__/ShiftGigsTabPageQuery.graphql";
import type {
  CqlFilterEngagementInput,
  CqlOrderEngagementInput
} from "./__generated__/ShiftGigsTabQuery.graphql";

export type ProjectFilterType = "projects" | "hired" | "offered" | "applied";

export const PROJECT_STATES: Record<ProjectFilterType, EngagementStateName[]> =
  {
    projects: ["SCHEDULED", "PENDING_REVIEW"],
    hired: ["SCHEDULED", "PENDING_REVIEW"],
    applied: ["APPLIED", "ENGAGED", "HIRE_REQUESTED"],
    offered: ["OFFERED"]
  };

export const getProjectArgs = (
  filter: ProjectFilterType = "projects"
): {
  where: CqlFilterEngagementInput;
  orderBy?: CqlOrderEngagementInput[];
} => {
  const states = PROJECT_STATES[filter];
  return {
    where: { gigType: { _eq: "PROJECT" }, currentStateName: { _in: states } },
    orderBy: [{ currentStateTransitionedAt: { direction: "DESC" } }]
  };
};

type Props = {
  active?: ProjectFilterType;
  onChange: (value: ProjectFilterType) => void;
};

export const ProjectGigsFilter = ({
  projectsHiredCount,
  projectsAppliedCount,
  projectsCount,
  projectsOfferedCount,
  active = "projects",
  onChange
}: FragmentContainerInnerComponentProps<
  ProjectGigsFilter_worker$key,
  Props
>) => {
  const filters = useMemo(
    (): Array<FilterTabOption & { key: ProjectFilterType }> => [
      {
        key: "projects",
        label: pluralize(projectsCount?.totalCount ?? 0, "Projects", false),
        count: projectsCount?.totalCount
      },
      {
        key: "offered",
        label: "Offered",
        count: projectsOfferedCount?.totalCount ?? 0
      },
      {
        key: "hired",
        label: "Hired",
        count: projectsHiredCount?.totalCount ?? 0
      },
      {
        key: "applied",
        label: "Applied",
        count: projectsAppliedCount?.totalCount
      }
    ],
    [
      projectsCount,
      projectsOfferedCount,
      projectsHiredCount,
      projectsAppliedCount
    ]
  );

  const index = filters.findIndex((it) => it.key === active);
  return (
    <FilterTabs
      tabs={filters}
      value={index}
      onChange={(idx) => {
        const newValue = filters[idx]?.key;
        if (newValue) onChange(newValue);
      }}
    />
  );
};

export default createRelayFragmentContainer<
  ProjectGigsFilter_worker$key,
  Props
>(
  graphql`
    fragment ProjectGigsFilter_worker on Worker
    @argumentDefinitions(
      projectsStates: { type: "[EngagementStateName!]!" }
      projectsHiredStates: { type: "[EngagementStateName!]!" }
      projectsAppliedStates: { type: "[EngagementStateName!]!" }
      projectsOfferedStates: { type: "[EngagementStateName!]!" }
    ) {
      projectsCount: engagements(first: 0, where: { gigType: { _eq: PROJECT }, currentStateName: { _in: $projectsStates }}) {
        totalCount
      }
      projectsHiredCount: engagements(first: 0, where: { gigType: { _eq: PROJECT }, currentStateName: { _in: $projectsHiredStates }}) {
        totalCount
      }
      projectsOfferedCount: engagements(
        first: 0
        where: { gigType: { _eq: PROJECT }, currentStateName: { _in: $projectsOfferedStates } }
      ) {
        totalCount
      }
      projectsAppliedCount: engagements(
        first: 0
        where: { gigType: { _eq: PROJECT }, currentStateName: { _in: $projectsAppliedStates } }
      ) {
        totalCount
      }
    }
  `,
  ProjectGigsFilter
);
