import {
  PositionsAndLocations,
  UnlimitedDistanceFeature
} from "@gigsmart/feature-flags";
import { SearchListHeader } from "@gigsmart/isomorphic-shared/list";
import { compact } from "lodash";
import React, { useMemo, type ReactNode } from "react";
import LocationSearch from "../../job/job-list/location-search";
import type { GigType } from "../__generated__/availableGigSeriesDetailQuery.graphql";

export type RelayGigType = GigType;

export interface SearchFilter {
  searchTerm: string;
  radiusMiles: number;
  location: { label: string; lat: number; lng: number };
  gigTypes: GigType[];
  crisisId?: string;
  maxDistance?: number;
}

interface Props {
  filter: SearchFilter;
  onChange?: (filter: SearchFilter) => void;
  collapsed?: boolean;
  crisis?: { id?: string; title?: string } | null;
  onClearCrisis?: () => void;
  isProject?: boolean;
  renderTopComponent?: () => ReactNode;
  isPickupShifts?: boolean;
}

export default function BrowseGigsSearch({
  filter,
  onChange,
  collapsed,
  crisis,
  onClearCrisis,
  isProject = false,
  renderTopComponent,
  isPickupShifts
}: Props) {
  const options = useMemo(
    () =>
      compact([
        { label: "Within 5 miles", value: 5 },
        { label: "Within 15 miles", value: 15 },
        { label: "Within 50 miles", value: 50 },
        { label: "Within 75 miles (default)", value: 75 },
        UnlimitedDistanceFeature.isEnabled() && {
          label: "All",
          shortLabel: "All",
          value: 12425
        }
      ]),
    []
  );
  return (
    <SearchListHeader
      placeholder={
        isProject
          ? "Search by title or category"
          : PositionsAndLocations.isEnabled()
            ? "Search by Position, Category, or Organization"
            : "Search by title, Category, industry, or Requester"
      }
      title={
        isProject
          ? "Project Gigs"
          : isPickupShifts
            ? "Shifts Available Today"
            : PositionsAndLocations.isEnabled()
              ? "Shifts"
              : "Shift Gigs"
      }
      iconName={
        isProject
          ? "pen-ruler"
          : isPickupShifts
            ? "alarm-clock"
            : "business-time"
      }
      collapsed={collapsed}
      value={filter.searchTerm}
      onChangeText={(searchTerm) => onChange?.({ ...filter, searchTerm })}
      renderTopComponent={renderTopComponent}
      collapsedContent={
        <LocationSearch
          onSearchLocation={(radiusMiles) =>
            onChange?.({ ...filter, radiusMiles })
          }
          radiusMiles={filter.radiusMiles}
          location={filter.location}
          crisis={crisis}
          onClearCrisis={onClearCrisis}
          options={options}
          locationDisabled
        />
      }
    />
  );
}
