import React from "react";
import { TouchableOpacity, View } from "react-native";

import { createStyles } from "../../style";
import { StyledText } from "../../text";

const styles = createStyles(({ color, font }) => ({
  container: {
    margin: 3,
    width: 36,
    height: 36,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 18,
    borderWidth: 1,
    borderColor: "transparent"
  },
  disabled: { opacity: 0.2 },
  selected: {
    borderColor: color.blue,
    backgroundColor: color.blue
  },
  pinned: { borderColor: color.neutralDark },
  label: { textAlign: "center" },
  labelSelected: { color: color.white, ...font.body("semibold") }
}));

interface Props {
  label: string;
  isPinned?: boolean;
  isSelected: boolean;
  isSameMonth: boolean;
  disabled?: boolean | undefined;
  onPress?: () => void;
  testID?: string;
}

export default ({
  label,
  isPinned,
  isSelected,
  isSameMonth,
  disabled,
  onPress,
  testID
}: Props) => {
  if (!isSameMonth) return <View style={styles.container} />;

  return (
    <TouchableOpacity
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.container,
        (!!disabled || !isSameMonth) && styles.disabled,
        isPinned && styles.pinned,
        isSelected && styles.selected
      ]}
    >
      <StyledText style={[styles.label, isSelected && styles.labelSelected]}>
        {label}
      </StyledText>
    </TouchableOpacity>
  );
};
