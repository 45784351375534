import React from "react";

import { asEnum, neq, where } from "@gigsmart/biruda";
import { StyledView } from "@gigsmart/katana";
import {
  type FragmentReference,
  createSuspendedQueryContainer,
  graphql,
  useRelayFragment
} from "@gigsmart/relay";

import PriorTransferList from "./prior-transfer-list";

import type { priorTransfersWorkerStripePayoutsQuery } from "./__generated__/priorTransfersWorkerStripePayoutsQuery.graphql";
import type { priorTransfers_workerPayableAccount$key } from "./__generated__/priorTransfers_workerPayableAccount.graphql";

interface Props {
  workerBalance: number;
  workerPayableAccount:
    | priorTransfers_workerPayableAccount$key
    | null
    | undefined;
}

export default createSuspendedQueryContainer<
  priorTransfersWorkerStripePayoutsQuery,
  Props
>(
  ({
    response: result,
    workerBalance,
    workerPayableAccount: workerPayableAccountFragmentReference
  }) => {
    const workerPayableAccount = useRelayFragment(
      graphql`
        fragment priorTransfers_workerPayableAccount on WorkerPayableAccount {
          paymentMethod {
            last4
          }
        }
      `,
      workerPayableAccountFragmentReference
    );

    return (
      <StyledView verticalPadding={0} color="transparent">
        <PriorTransferList
          query={result as FragmentReference}
          workerBalance={workerBalance}
          workerPayableAccount={workerPayableAccount}
        />
      </StyledView>
    );
  },
  {
    query: graphql`
      query priorTransfersWorkerStripePayoutsQuery(
        $priorTransfersQuery: String
      ) {
        ...priorTransferList_payouts
          @arguments(priorTransfersQuery: $priorTransfersQuery)
      }
    `,
    variables: {
      priorTransfersQuery: where({
        gigType: neq(asEnum("PROJECT"))
      }).toString()
    }
  }
);
