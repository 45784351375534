/**
 * @generated SignedSource<<245d72aa6201ea5f19666e73e03639e8>>
 * @relayHash 4caf5027ffd0ea627bb149286f7a10f2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:KjEcJo24lvLeolLq6vrgBV8xXbAir3AYKHIJhBjeFe0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type currentUserDeviceSubscription$variables = {
  id: string;
};
export type currentUserDeviceSubscription$data = {
  readonly nodeUpdated: {
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"currentUser_device">;
    };
  } | null | undefined;
};
export type currentUserDeviceSubscription = {
  response: currentUserDeviceSubscription$data;
  variables: currentUserDeviceSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "currentUserDeviceSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NodeUpdatedPayload",
        "kind": "LinkedField",
        "name": "nodeUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "currentUser_device"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "currentUserDeviceSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NodeUpdatedPayload",
        "kind": "LinkedField",
        "name": "nodeUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pushRegistrationToken",
                    "storageKey": null
                  }
                ],
                "type": "UserDevice",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:KjEcJo24lvLeolLq6vrgBV8xXbAir3AYKHIJhBjeFe0",
    "metadata": {},
    "name": "currentUserDeviceSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "a9a4e6775d75ecd8f3d4ad67e20521d6";

export default node;
