/**
 * @generated SignedSource<<901781b585f811f39fe77768676dbcb0>>
 * @relayHash 7b4918e2878ecf3b69335896a334eadf
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:iCsyFWSX0KMNknjULcA0GXqxvpckNnK-VJR2eWccZDY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type CancelEngagementInput = {
  authoredBy?: UserType | null | undefined;
  blockUser?: boolean | null | undefined;
  cancellationReasonOptionId: string;
  comment?: string | null | undefined;
  engagementId: string;
};
export type CancellationQuestionnaireMutation$variables = {
  force: boolean;
  input: CancelEngagementInput;
};
export type CancellationQuestionnaireMutation$data = {
  readonly cancelEngagement: {
    readonly cancellationReason: {
      readonly id: string;
    } | null | undefined;
    readonly restrictedBy: {
      readonly cancellationFee?: {
        readonly " $fragmentSpreads": FragmentRefs<"CancellationFeeInfoModal_cancellationFee">;
      };
    } | null | undefined;
  } | null | undefined;
};
export type CancellationQuestionnaireMutation = {
  response: CancellationQuestionnaireMutation$data;
  variables: CancellationQuestionnaireMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "force"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CancellationReason",
  "kind": "LinkedField",
  "name": "cancellationReason",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalDue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feeRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDelta",
        "storageKey": null
      }
    ],
    "type": "EngagementCancellationFeeLike",
    "abstractKey": "__isEngagementCancellationFeeLike"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CancellationQuestionnaireMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CancelEngagementPayload",
        "kind": "LinkedField",
        "name": "cancelEngagement",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "restrictedBy",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCancellationFeeEstimate",
                    "kind": "LinkedField",
                    "name": "cancellationFee",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "CancellationFeeInfoModal_cancellationFee",
                        "selections": (v4/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "EngagementCapabilityCancellationFeeRestriction",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CancellationQuestionnaireMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CancelEngagementPayload",
        "kind": "LinkedField",
        "name": "cancelEngagement",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "restrictedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCancellationFeeEstimate",
                    "kind": "LinkedField",
                    "name": "cancellationFee",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "EngagementCapabilityCancellationFeeRestriction",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:iCsyFWSX0KMNknjULcA0GXqxvpckNnK-VJR2eWccZDY",
    "metadata": {},
    "name": "CancellationQuestionnaireMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e044d7decf17557221f48812e59fb137";

export default node;
