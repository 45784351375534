/**
 * @generated SignedSource<<a9f930a40222fe48197b1411f8e0bcb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type priorTransfers_workerPayableAccount$data = {
  readonly paymentMethod: {
    readonly last4: string;
  } | null | undefined;
  readonly " $fragmentType": "priorTransfers_workerPayableAccount";
};
export type priorTransfers_workerPayableAccount$key = {
  readonly " $data"?: priorTransfers_workerPayableAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"priorTransfers_workerPayableAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "priorTransfers_workerPayableAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkerPayableAccount",
  "abstractKey": null
};

(node as any).hash = "4b34604f82aed97bafdef8965af38429";

export default node;
