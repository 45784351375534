import {
  ContentArea,
  ListEmptyView,
  Spacer,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { InfiniteList } from "@gigsmart/isomorphic-shared/list";
import { getConnectionNodes, graphql } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import ReviewRow from "./ReviewRow";
import type { ReviewsListPageQuery } from "./__generated__/ReviewsListPageQuery.graphql";
import type { ReviewsList_Organization$key } from "./__generated__/ReviewsList_Organization.graphql";

type ReviewEdgeType = NonNullable<
  ObjectPath<
    ReviewsList_Organization$key,
    [" $data", "reviewsReceived", "edges", 0, "node"]
  >
>;

interface Props {
  fragmentRef: ReviewsList_Organization$key;
}

export default function ReviewsList({ fragmentRef }: Props) {
  return (
    <InfiniteList<
      ReviewsListPageQuery,
      ReviewsList_Organization$key,
      ReviewEdgeType
    >
      ListHeaderComponent={
        <ContentArea>
          <Text variant="header" align="center" color="primary">
            Organization Reviews
          </Text>
        </ContentArea>
      }
      testID="requester-reviews"
      ItemSeparatorComponent={Spacer}
      parentRef={fragmentRef}
      fragmentInput={graphql`
        fragment ReviewsList_Organization on Organization
        @refetchable(queryName: "ReviewsListPageQuery")
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 4 }
          after: { type: "String" }
        ) {
          reviewsReceived(
            first: $count
            after: $after
            query: "ORDER BY insertedAt DESC"
          ) @connection(key: "ReviewsList_reviewsReceived") {
            edges {
              node {
                id
                ...ReviewRow_EngagementReview
              }
            }
            totalCount
            pageInfo {
              hasNextPage
            }
          }
        }
      `}
      getData={(data) => getConnectionNodes(data?.reviewsReceived)}
      keyExtractor={(item, idx) => item?.id ?? `${idx}`}
      renderItem={({ item }) => (
        <Surface>
          <ReviewRow fragmentRef={item} />
        </Surface>
      )}
      renderEmptyView={(loading) =>
        loading ? null : (
          <ListEmptyView title="This organization has no reviews yet." />
        )
      }
    />
  );
}
