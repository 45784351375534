/**
 * @generated SignedSource<<5ab9eac18db71169fefc70ac615b09ca>>
 * @relayHash b62005e0a18f2f8675910fab3c0e5efe
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:fK7XQUF9C7Up6WXmwYsF1mufF3BO2bpmyMZ1zewhJ0A

import { ConcreteRequest } from 'relay-runtime';
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type RequestUserPasswordResetInput = {
  emailAddress: string;
  userType: UserType;
};
export type resetPasswordSuccessMutation$variables = {
  input: RequestUserPasswordResetInput;
};
export type resetPasswordSuccessMutation$data = {
  readonly requestUserPasswordReset: {
    readonly ok: boolean;
  } | null | undefined;
};
export type resetPasswordSuccessMutation = {
  response: resetPasswordSuccessMutation$data;
  variables: resetPasswordSuccessMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestUserPasswordResetPayload",
    "kind": "LinkedField",
    "name": "requestUserPasswordReset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resetPasswordSuccessMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resetPasswordSuccessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:fK7XQUF9C7Up6WXmwYsF1mufF3BO2bpmyMZ1zewhJ0A",
    "metadata": {},
    "name": "resetPasswordSuccessMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "aa36a8add47cd551794c0d167c1c3316";

export default node;
