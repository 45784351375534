import React, { type ReactNode } from "react";
import { Pressable, View } from "react-native";
import { createStyles } from "../style";
import { StyledText } from "../text";

const styles = createStyles(({ color, units }) => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: units(5),
    paddingHorizontal: units(9),
    paddingBottom: units(8),
    backgroundColor: color.neutralLight
  },
  line: {
    height: units(3),
    flex: 1,
    marginHorizontal: -units(2),
    marginVertical: units(1.5),
    zIndex: -1,
    maxWidth: 280
  },
  circle: {
    width: units(6),
    height: units(6),
    borderRadius: units(3)
  },
  short: {
    paddingHorizontal: "20%"
  },
  inactive: {
    backgroundColor: color.darken(color.neutralLight, 0.1)
  },
  active: {
    backgroundColor: color.blue
  },
  textContainer: {
    position: "absolute",
    top: units(8),
    left: -units(7),
    width: units(21),
    overflow: "visible"
  },
  text: {
    textAlign: "center"
  }
}));

interface StepProp {
  number: number;
  name: string;
}

export interface StepperHeaderProps {
  steps: StepProp[];
  currentStep: StepProp;
  gotoStep: (arg0: number) => void;
  fontSize?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  stepData?: any;
}

export default function StepperHeader({
  steps,
  currentStep,
  gotoStep,
  fontSize = 12
}: StepperHeaderProps) {
  const parts: ReactNode[] = [];
  steps.forEach((step, index) => {
    if (index) {
      parts.push(
        <View
          key={`line-${index}`}
          style={[
            styles.line,
            index < currentStep.number ? styles.active : styles.inactive
          ]}
        />
      );
    }
    parts.push(
      <Pressable
        key={`step-indicator-${index}`}
        disabled={index + 1 >= currentStep.number}
        onPress={() => gotoStep(step.number)}
      >
        <View>
          <View
            style={[
              styles.circle,
              index < currentStep.number ? styles.active : styles.inactive
            ]}
          />
          <View style={styles.textContainer}>
            <StyledText style={[styles.text, { fontSize }]}>
              {step.name}
            </StyledText>
          </View>
        </View>
      </Pressable>
    );
  });
  if (steps.length < 2) return null;
  return (
    <View style={[styles.container, steps.length === 2 && styles.short]}>
      {parts}
    </View>
  );
}
