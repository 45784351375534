/**
 * @generated SignedSource<<344f1166bd265bbc32a6d6813e610b49>>
 * @relayHash b79381173dd1096e6e8ac880044195b9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:0mYAmDN6PgKwnfsOcOCH6QJLddgAu3RtGNCMKQyXBRA

import { ConcreteRequest } from 'relay-runtime';
export type WorkerAccessState = "AVAILABLE" | "EN_ROUTE" | "NO_ACTIVE_DEVICES" | "NO_LOCATION" | "REQUIRES_BASIC_INFO" | "REQUIRES_PARENT_CONSENT" | "REQUIRES_SKILLS" | "REQUIRES_SMS_CONFIRMATION" | "REQUIRES_TRANSPORTATION" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type SetWorkerPayableAccountInput = {
  token: string;
  workerId?: string | null | undefined;
};
export type paymentWorkerMutation$variables = {
  input: SetWorkerPayableAccountInput;
};
export type paymentWorkerMutation$data = {
  readonly setWorkerPayableAccount: {
    readonly worker: {
      readonly accessState: WorkerAccessState;
    } | null | undefined;
    readonly workerPayableAccount: {
      readonly paymentMethod: {
        readonly accountHolderName: string | null | undefined;
        readonly expirationMonth?: number;
        readonly expirationYear?: number;
        readonly last4: string;
        readonly postalCode?: string | null | undefined;
        readonly routingNumber?: string;
        readonly type: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type paymentWorkerMutation = {
  response: paymentWorkerMutation$data;
  variables: paymentWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessState",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountHolderName",
  "storageKey": null
},
v4 = {
  "alias": "type",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    }
  ],
  "type": "BankCard",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "routingNumber",
      "storageKey": null
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetWorkerPayableAccountPayload",
        "kind": "LinkedField",
        "name": "setWorkerPayableAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerPayableAccount",
            "kind": "LinkedField",
            "name": "workerPayableAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetWorkerPayableAccountPayload",
        "kind": "LinkedField",
        "name": "setWorkerPayableAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerPayableAccount",
            "kind": "LinkedField",
            "name": "workerPayableAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:0mYAmDN6PgKwnfsOcOCH6QJLddgAu3RtGNCMKQyXBRA",
    "metadata": {},
    "name": "paymentWorkerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b3e6bb4b7e8a67f3815371a20f50415c";

export default node;
