import { NavAction, Text, defaultCancelPrompt } from "@gigsmart/atorasu";
import { NavPortalEntrance, defaultBackHandler } from "@gigsmart/kaizoku";
import React, { useCallback } from "react";

interface Props {
  onGoBack?: () => void;
  onCancel?: () => void;
}

export default function FomuNavBackButton({ onGoBack, onCancel }: Props) {
  const handleCancel = useCallback(async () => {
    if (await defaultCancelPrompt()) {
      onCancel ? onCancel() : defaultBackHandler();
    }
  }, [onCancel]);

  const handleBackPress = useCallback(async () => {
    onGoBack?.();
    return true;
  }, [onGoBack]);
  return (
    <NavPortalEntrance
      onBackPress={() => {
        handleBackPress();
        return true;
      }}
      rightAccessory={
        <NavAction
          testID="create-gig-stepper-cancel-btn"
          onPress={handleCancel}
        >
          <Text weight="bold">Cancel</Text>
        </NavAction>
      }
    />
  );
}
