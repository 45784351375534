/**
 * @generated SignedSource<<b60596b07813c16152aa38fac4cc691e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type jobDetails_jobPosting$data = {
  readonly description: string | null | undefined;
  readonly organization?: {
    readonly " $fragmentSpreads": FragmentRefs<"hiringCompanyCard_organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"applyButtons_jobPosting" | "detailsCard_jobPosting">;
  readonly " $fragmentType": "jobDetails_jobPosting";
};
export type jobDetails_jobPosting$key = {
  readonly " $data"?: jobDetails_jobPosting$data;
  readonly " $fragmentSpreads": FragmentRefs<"jobDetails_jobPosting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isWorker"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "jobDetails_jobPosting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "applyButtons_jobPosting"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "detailsCard_jobPosting"
    },
    {
      "condition": "isWorker",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "hiringCompanyCard_organization"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "JobPosting",
  "abstractKey": null
};

(node as any).hash = "f9c89b3a79c87898a3fda6dda46d9279";

export default node;
