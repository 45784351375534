import {
  Persistence,
  checkPermission,
  requestPermission
} from "@gigsmart/atorasu";
import {
  disableFeature,
  disableFeatures,
  enableFeature,
  enableFeatures,
  isFeatureEnabled,
  resetFeatures
} from "@gigsmart/feature-flags/registry";
import { navigationRef, useCurrentUrl, useHistory } from "@gigsmart/kaizoku";
import {
  isInstrumented,
  useInstrument
} from "@gigsmart/pickle/support/utils/instrumentation-client";
import {
  useRelayLoader,
  useRelayOrchestrator,
  useRelayRetrier
} from "@gigsmart/relay";
import { useCallback } from "react";
import { Keyboard, Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { getTimeZone } from "react-native-localize";
import RNRestart from "react-native-restart";

const packageBuildNumber = process.env.PACKAGE_BUILD_NUMBER ?? "unknown";

interface NavOptions {
  to: string;
  replace?: boolean;
  timeout?: number;
}

function Instruments() {
  const { isLoading } = useRelayLoader();
  const history = useHistory();
  const currentUrl = useCurrentUrl();

  const { reset } = useRelayOrchestrator();
  const retryAll = useRelayRetrier(true);
  const nav = useCallback(
    async ({ to, replace, timeout }: NavOptions) =>
      await new Promise<string>((resolve, reject) => {
        if (!!navigationRef.current && !navigationRef.isReady()) {
          /// wait before running;
          setTimeout(() => resolve(nav({ to, replace, timeout })), 500);
        } else {
          if (to === currentUrl) {
            resolve(to);
          } else if (history) {
            history[replace ? "replace" : "push"](to);
            setTimeout(() => resolve(to), 500);
          } else {
            reject(new Error("Navigation is not enabled"));
          }
        }
      }),
    [currentUrl, history]
  );
  useInstrument("networkBusy", null, () => isLoading, [isLoading]);
  useInstrument("nav", null, nav, [nav]);
  useInstrument("resetFeatures", null, async () => await resetFeatures());
  useInstrument(
    "wipeData",
    null,
    async (all) => await Persistence.clear(!!all)
  );
  useInstrument("restartApp", null, () => RNRestart.restart());
  useInstrument("relayRetryAll", null, async () => retryAll(), [retryAll]);
  useInstrument("getCurrentPath", null, () => currentUrl, [currentUrl]);
  useInstrument(
    "enableFeature",
    null,
    async ({ flag }) => await enableFeature(flag, true)
  );
  useInstrument(
    "enableFeatures",
    null,
    async ({ flags }) => await enableFeatures(flags, true)
  );
  useInstrument(
    "isFeatureEnabled",
    null,
    async ({ flag }) => await isFeatureEnabled(flag)
  );
  useInstrument(
    "disableFeature",
    null,
    async ({ flag }) => await disableFeature(flag, true)
  );
  useInstrument(
    "disableFeatures",
    null,
    async ({ flags }) => await disableFeatures(flags, true)
  );
  useInstrument("login", null, async ({ token }) => await reset(token), [
    reset
  ]);
  useInstrument("logout", null, async () => await nav({ to: "/logout" }), [
    nav
  ]);
  useInstrument("echo", null, ({ message }) => message);
  useInstrument("hideKeyboard", null, () => Keyboard.dismiss());
  useInstrument("getTimezone", null, () => getTimeZone());
  useInstrument(
    "checkPermission",
    null,
    async ({ permissions }) =>
      await Promise.all(
        (Array.isArray(permissions) ? permissions : [permissions]).map(
          async (permission: any) => {
            await checkPermission(permission);
          }
        )
      )
  );
  useInstrument("getVersionInfo", null, () =>
    Platform.OS === "web"
      ? {
          packageBuildNumber
        }
      : {
          nativeVersion: DeviceInfo.getVersion(),
          buildNumber: DeviceInfo.getBuildNumber(),
          packageBuildNumber
        }
  );
  useInstrument("requestPermission", null, async ({ permissions }) =>
    Promise.all(
      (Array.isArray(permissions) ? permissions : [permissions]).map(
        async (permission: any) => {
          await requestPermission(permission);
        }
      )
    )
  );
  return null;
}

export default !isInstrumented ? () => null : Instruments;
