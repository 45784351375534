import {
  Button,
  Column,
  ContentArea,
  Stack,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { type FomuOnSubmitArgs, Form, FormSubmit } from "@gigsmart/fomu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React from "react";
import FomuRadioButtonGroup from "../../../fomu/inputs/FomuRadioButtonGroup";
import StepHeader from "../../../shared/Stepper/StepHeader";
import Nav from "./Nav";
import type { TypeStepDirectHireClaimQuery } from "./__generated__/TypeStepDirectHireClaimQuery.graphql";
import type { TypeStepUpdateDirectHireClaimMutation } from "./__generated__/TypeStepUpdateDirectHireClaimMutation.graphql";
import type { DirectHireClaimsStepper } from "./index";

interface Props {
  bonusAmount?: string;
}

export default createSuspendedQueryContainer<
  TypeStepDirectHireClaimQuery,
  Props
>(
  function TypeStep({ bonusAmount, response: result }) {
    const { gotoStep, stepsData } = useStepper<
      "type",
      DirectHireClaimsStepper
    >();

    const [updateDirectHireClaim] =
      useRelayMutation<TypeStepUpdateDirectHireClaimMutation>(graphql`
        mutation TypeStepUpdateDirectHireClaimMutation(
          $input: UpdateDirectHireClaimInput!
        ) {
          updateDirectHireClaim(input: $input) {
            directHireClaim {
              id
              directHireType
            }
          }
        }
      `);
    const handleSubmit = ({ values }: FomuOnSubmitArgs) => {
      updateDirectHireClaim(
        {
          input: {
            directHireClaimId: stepsData?.id ?? "",
            directHireType: values?.type
          }
        },
        {
          onSuccess: () =>
            gotoStep("information", {
              ...stepsData,
              directHireClaimType: values?.type
            })
        }
      );
    };
    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={{ type: result?.node?.directHireType }}
      >
        <Column fill justifyContent="space-between">
          <Nav returnTo="information" />
          <Stack>
            <StepHeader
              name="Select a Bonus Type"
              note="Please select the bonus type you are submitting evidence for."
            />
            <Surface>
              <ContentArea>
                <Stack>
                  <Text weight="semibold">
                    What type of Bonus are you submitting?
                  </Text>
                  <FomuRadioButtonGroup
                    variant="box"
                    name="type"
                    required
                    buttons={[
                      {
                        title: (
                          <Text weight="semibold">
                            Direct Payment Bonus -{" "}
                            <Text color="success" weight="semibold">
                              {currency.humanize(bonusAmount)}
                            </Text>
                          </Text>
                        ),
                        description:
                          "An Organization I met through GigSmart has paid me outside of the app (cash, Venmo, etc.)",
                        value: "PAYMENT",
                        testID: "payment-btn"
                      },
                      {
                        title: (
                          <Text weight="semibold">
                            Direct Hire Bonus -{" "}
                            <Text color="success" weight="semibold">
                              {currency.humanize(bonusAmount)}
                            </Text>
                          </Text>
                        ),
                        description:
                          "An Organization I met through GigSmart hired me for a contract or full/part-time position outside of the app.",
                        value: "HIRE",
                        testID: "hire-btn"
                      }
                    ]}
                  />
                </Stack>
              </ContentArea>
            </Surface>
          </Stack>
          <FormSubmit>
            {({ invalid, submit }) => (
              <ContentArea>
                <Button
                  testID="save-changes-btn"
                  disabled={invalid}
                  label="Save Changes"
                  onPress={submit}
                />
              </ContentArea>
            )}
          </FormSubmit>
        </Column>
      </Form>
    );
  },
  {
    query: graphql`
      query TypeStepDirectHireClaimQuery($id: ID!) {
        node(id: $id) {
          ... on DirectHireClaim {
            directHireType
          }
        }
      }
    `,
    variables: () => {
      const { stepsData } = useStepper<"type", DirectHireClaimsStepper>();
      return { id: stepsData?.id ?? "" };
    }
  }
);
