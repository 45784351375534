import {
  AbsoluteContainer,
  ActionRow,
  IconButton,
  ScreenScroll,
  Spacer,
  Stack,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { where } from "@gigsmart/biruda";
import { useHistory } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import CDLCard from "@gigsmart/seibutsu/worker-profile/CDLCard";
import EducationCard from "@gigsmart/seibutsu/worker-profile/EducationCard";
import LanguagesCard from "@gigsmart/seibutsu/worker-profile/LanguagesCard";
import LicensesCard from "@gigsmart/seibutsu/worker-profile/LicensesCard";
import MilitaryCard from "@gigsmart/seibutsu/worker-profile/MilitaryCard";
import ProfileInfoCard from "@gigsmart/seibutsu/worker-profile/ProfileInfoCard";
import WorkHistoryCard from "@gigsmart/seibutsu/worker-profile/WorkHistoryCard";
import useWorkerProfileStrength from "@gigsmart/seibutsu/worker-profile/useWorkerProfileStrength";
import HomeScreenCard from "@gigsmart/seibutsu/worker/HomescreenCard";
import React from "react";
import type { publicViewScreenQuery } from "./__generated__/publicViewScreenQuery.graphql";

export const workerQuery = (workerId: string) => where({ workerId });

export default createSuspendedQueryContainer<publicViewScreenQuery>(
  function PublicViewScreen({ response: { viewer } = {} }) {
    const xLargeUp = useMatchesViewport(({ size }) => size.xlarge.up);

    const {
      showWorkInformationCard,
      showWorkHistory,
      showEducation,
      showLicenses,
      showCDL,
      showLanguages,
      showMilitary
    } = useWorkerProfileStrength(viewer ?? null);
    const history = useHistory();

    return (
      <ScreenScroll
        testID="profile-public-view-screen"
        header={
          <>
            <AbsoluteContainer>
              <ActionRow
                icon="eye"
                title="You are viewing your public profile."
                testID="PublicProfileNotice"
                variant="mixed"
                right={
                  <IconButton
                    name="times"
                    onPress={() => history.goBack()}
                    testID="DismmissPublicProfileNotice"
                  />
                }
              />
            </AbsoluteContainer>
            <Spacer size="xlarge" />
            <Spacer size="medium" />
          </>
        }
      >
        <Stack grow horizontal={xLargeUp}>
          <Stack fill={xLargeUp ? 1 : undefined}>
            <ProfileInfoCard
              workerRef={viewer}
              viewer="Requester"
              isAuthenticated={false}
              isPublicView
            />
          </Stack>
          <Stack fill={xLargeUp ? 1.5 : undefined}>
            {showWorkInformationCard && (
              <HomeScreenCard
                headerTitle="Worker Information"
                headerIcon="user"
              >
                <Stack>
                  {showWorkHistory && (
                    <WorkHistoryCard fragmentRef={viewer} viewer="Requester" />
                  )}
                  {showEducation && (
                    <EducationCard fragmentRef={viewer} viewer="Requester" />
                  )}
                  {showLicenses && <LicensesCard fragmentRef={viewer} />}
                  {showCDL && <CDLCard fragmentRef={viewer} />}
                  {showLanguages && (
                    <LanguagesCard fragmentRef={viewer} viewer="Requester" />
                  )}
                  {showMilitary && (
                    <MilitaryCard fragmentRef={viewer} viewer="Requester" />
                  )}
                </Stack>
              </HomeScreenCard>
            )}
          </Stack>
        </Stack>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query publicViewScreenQuery {
        viewer {
          ... on Worker {
            ...ProfileInfoCard_worker
            ...useWorkerProfileStrength_worker
            ...WorkHistoryCard_worker
            ...EducationCard_worker
            ...LanguagesCard_Worker
            ...MilitaryCard_Worker
            ...LicensesCard_workerprofile
            ...CDLCard_workerprofile
            displayName
          }
        }
      }
    `,
    variables: {}
  }
);
