import { IconButton } from "@gigsmart/katana";
import React, { useState } from "react";
import JobBoardHelpPrompt from "./job-board-help-prompt";

const JobBoardHelpIcon = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <IconButton
        diameter={56}
        icon="circle-question"
        iconColor="white"
        iconVariant="regular"
        onPress={() => setVisible(true)}
      />
      <JobBoardHelpPrompt visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};

export default JobBoardHelpIcon;
