import { Dot, Stack, Text, TimeRemaining } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { DateTime } from "luxon";
import React from "react";

interface Props {
  isPickup?: boolean;
  date: string | null | undefined;
}

function computeRemainingTime(date: string | null | undefined) {
  if (!date) return date;
  const fromNow = DateTime.fromISO(date).diffNow("hours").get("hour");
  return fromNow <= 0 || fromNow >= 48
    ? null
    : fromNow < 1
      ? "Less than 1 hour"
      : pluralize(Math.round(fromNow), "hour");
}

export function showRemainingTime(
  isPickup: boolean,
  date: string | null | undefined
) {
  return isPickup || !!computeRemainingTime(date);
}

export function RemainingTime({ isPickup, date }: Props) {
  const hoursToActionFmt = computeRemainingTime(date);
  return !(isPickup || hoursToActionFmt) ? null : (
    <Stack horizontal size="compact" alignItems="center">
      <Dot size="xs" color="danger" animation="flash" />
      {isPickup ? (
        <Text weight="bold" color="danger">
          <TimeRemaining weight="bold" size="lg" showLargestOnly date={date} />{" "}
          left to pick up
        </Text>
      ) : (
        <Text weight="bold" color="danger">
          {hoursToActionFmt} left to apply
        </Text>
      )}
    </Stack>
  );
}
