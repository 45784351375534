import React from "react";

import { Spacer } from "@gigsmart/atorasu";
import { PanelCard } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import {
  formatDate,
  formatPaymentAmount,
  formatStatusText,
  todayOrFutureDate
} from "../helpers";
import PriorTransferEngagementRows from "./prior-transfer-engagement-rows";
import PriorTransferRowHeader from "./prior-transfer-row-header";

import { currency } from "@gigsmart/isomorphic-shared/iso";
import type { priorTransferRow_payout$key } from "./__generated__/priorTransferRow_payout.graphql";

interface Props {
  stripePayout: priorTransferRow_payout$key | null | undefined;
  testID?: string;
}

export default ({
  stripePayout: stripePayoutFragmentReference,
  testID
}: Props) => {
  const payout = useRelayFragment(
    graphql`
      fragment priorTransferRow_payout on WorkerPayout
      @argumentDefinitions(priorTransfersQuery: { type: "String" }) {
        amount
        feeAmount
        insertedAt
        type
        ...priorTransferEngagementRows_engagements
          @arguments(priorTransfersQuery: $priorTransfersQuery)
        providerDetails {
          method
          arrivesAt
          automatic
          destination {
            ... on BankAccount {
              bankName
            }
            ... on BankCard {
              brand
            }
            last4
            __typename
          }
          status
        }
      }
    `,
    stripePayoutFragmentReference
  );

  const details = payout?.providerDetails;
  if (!payout) return null;
  return (
    <>
      <Spacer />
      <PanelCard
        expandDisabled={!!details?.automatic}
        headerContent={
          <PriorTransferRowHeader
            startedDate={formatDate(payout?.insertedAt)}
            completedDate={todayOrFutureDate(details?.arrivesAt)}
            rapid={payout?.type === "INSTANT"}
            statusText={formatStatusText(details?.status ?? "")}
            paymentAmount={formatPaymentAmount(
              currency.toFloat(payout?.amount)
            )}
          />
        }
        testID={testID}
      >
        <PriorTransferEngagementRows
          instant={payout?.type === "INSTANT"}
          payout={payout}
          destination={details?.destination}
          feeAmount={`- ${formatPaymentAmount(
            currency.toFloat(payout.feeAmount)
          )}`}
        />
      </PanelCard>
    </>
  );
};
