import React, { type ComponentProps } from "react";
import type { ViewStyleProp } from "../style";
import { Hoverable } from "../tappable";
import KatanaTouchableOpacity from "../tappable/katana-touchable-opacity";
import Card from "./card";

type Props = ComponentProps<typeof Card> & {
  containerStyle?: ViewStyleProp;
  disabled?: boolean;
  onPress?: () => void;
  eventTargetName: string | null;
};

const HoverableCard = ({
  testID,
  onPress,
  containerStyle,
  disabled,
  shadow,
  eventTargetName,
  ...props
}: Props) => (
  <KatanaTouchableOpacity
    onPress={onPress}
    activeOpacity={0.65}
    style={containerStyle}
    testID={testID}
    eventTargetName={eventTargetName}
    eventEntityType="Card"
    disabled={disabled}
    accessible={false}
  >
    <Hoverable>
      {(isHover) => (
        <Card
          {...props}
          isHover={isHover && !disabled}
          shadow={shadow ?? !disabled}
        />
      )}
    </Hoverable>
  </KatanaTouchableOpacity>
);

export default HoverableCard;
