import {
  type Color,
  Icon,
  type IconName,
  Pressable,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import React from "react";

interface Props {
  icon?: IconName;
  iconColor?: Color;
  label: string;
  value?: string | number | null;
  onPress?: () => void;
}

export function StatsWell({ icon, iconColor, label, value, onPress }: Props) {
  return (
    <Well fill size="compact">
      <Pressable
        disabled={!onPress}
        eventTargetName="Stats Well Pressable"
        testID={`stats-well-${label}-pressable`}
        eventEntityType="iconPress"
        onPress={onPress}
      >
        <Stack horizontal alignItems="center">
          <Stack alignItems="center" size="slim" fill>
            <Stack horizontal alignItems="center" size="compact">
              {!!icon && (
                <Icon
                  name={icon}
                  color={iconColor}
                  variant="solid"
                  size="tiny"
                />
              )}
              {value !== null && value !== undefined && (
                <Text weight="bold">{value}</Text>
              )}
            </Stack>
            <Text variant="note" align="center">
              {label}
            </Text>
          </Stack>
          {!!onPress && <Icon size="tiny" name="chevron-right" />}
        </Stack>
      </Pressable>
    </Well>
  );
}
