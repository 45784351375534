/**
 * @generated SignedSource<<10b05dd13c7a5fa2f039d2c29329dda0>>
 * @relayHash ed4b88168632c6eb0c0acd3430e07dd2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:I7ICJ_f514OeN_TA1wMUJCXOdCT0BS-yrjxSRNh2JWk

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type directPaymentsAndHiresDetailsScreenWorkerQuery$variables = {
  claimId: string;
};
export type directPaymentsAndHiresDetailsScreenWorkerQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"DirectHireClaimDetailsWorker_DirectHireClaim">;
  } | null | undefined;
  readonly viewer: {
    readonly directHireBonus?: string;
  } | null | undefined;
};
export type directPaymentsAndHiresDetailsScreenWorkerQuery = {
  response: directPaymentsAndHiresDetailsScreenWorkerQuery$data;
  variables: directPaymentsAndHiresDetailsScreenWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "claimId"
  }
],
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireBonus",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "claimId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DirectHireClaimTransitionsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaimTransition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "directPaymentsAndHiresDetailsScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DirectHireClaimDetailsWorker_DirectHireClaim"
              }
            ],
            "type": "DirectHireClaim",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "directPaymentsAndHiresDetailsScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "directHireType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "directHireDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Place",
                "kind": "LinkedField",
                "name": "place",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "streetAddress",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "response",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "comments",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workerPayout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectHireClaimTransition",
                "kind": "LinkedField",
                "name": "currentTransition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 5
                  }
                ],
                "concreteType": "DirectHireClaimPhotosConnection",
                "kind": "LinkedField",
                "name": "photos",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DirectHireClaimPhotosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DirectHireClaimPhoto",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "photos(first:5)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "responseDeadline",
                "storageKey": null
              },
              {
                "alias": "rejected",
                "args": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE state = REJECTED"
                  }
                ],
                "concreteType": "DirectHireClaimTransitionsConnection",
                "kind": "LinkedField",
                "name": "transitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DirectHireClaimTransitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DirectHireClaimTransition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "action",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccessContext",
                            "kind": "LinkedField",
                            "name": "transitionedBy",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "agent",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "transitions(first:1,query:\"WHERE state = REJECTED\")"
              },
              {
                "alias": "published",
                "args": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE state = PUBLISHED"
                  }
                ],
                "concreteType": "DirectHireClaimTransitionsConnection",
                "kind": "LinkedField",
                "name": "transitions",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": "transitions(first:1,query:\"WHERE state = PUBLISHED\")"
              },
              {
                "alias": "approved",
                "args": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE action = ACCEPT"
                  }
                ],
                "concreteType": "DirectHireClaimTransitionsConnection",
                "kind": "LinkedField",
                "name": "transitions",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": "transitions(first:1,query:\"WHERE action = ACCEPT\")"
              },
              {
                "alias": "disbursed",
                "args": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE state = DISBURSED"
                  }
                ],
                "concreteType": "DirectHireClaimTransitionsConnection",
                "kind": "LinkedField",
                "name": "transitions",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": "transitions(first:1,query:\"WHERE state = DISBURSED\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overallRating",
                    "storageKey": null
                  },
                  {
                    "alias": "reviewCount",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 0
                      }
                    ],
                    "concreteType": "EngagementReviewsConnection",
                    "kind": "LinkedField",
                    "name": "reviewsReceived",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "reviewsReceived(first:0)"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "DirectHireClaim",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:I7ICJ_f514OeN_TA1wMUJCXOdCT0BS-yrjxSRNh2JWk",
    "metadata": {},
    "name": "directPaymentsAndHiresDetailsScreenWorkerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "392660eff5b186079049f3ac5e770183";

export default node;
