import React, { type ReactElement } from "react";
import type { Text } from "react-native";
import ContentWrapper, {
  type ContentTypeVariant
} from "../container/content-wrapper";
import Stack from "../container/stack";
import { type IconName, StyledIcon } from "../icon";
import { useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  contentVariant?: ContentTypeVariant;
  marginTop?: string | number;
  tintColor?: string;
  iconName?: IconName;
  message: string | ReactElement<Text>;
}

const InfoBox = ({
  marginTop,
  contentVariant,
  tintColor = "blue",
  iconName,
  message
}: Props) => {
  const { styles } = useStyles(
    ({ unit, units, color }) => {
      const rgb = color.getColor(tintColor);
      return {
        box: {
          backgroundColor: color.withOpacity(rgb, 0.08),
          paddingHorizontal: units(4),
          paddingVertical: units(2),
          borderRadius: unit.borderRadius
        },
        message: {
          flex: 1
        }
      };
    },
    [marginTop, tintColor]
  );

  return (
    <ContentWrapper
      variant={contentVariant}
      component={Stack}
      style={styles.box}
      direction="row"
      alignItems="center"
    >
      {!!iconName && (
        <StyledIcon
          color={tintColor}
          name={iconName}
          variant="solid"
          size={16}
        />
      )}
      <KatanaText style={styles.message} color={tintColor} variant="semibold">
        {message}
      </KatanaText>
    </ContentWrapper>
  );
};

export default InfoBox;
