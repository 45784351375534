import { Divider } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { HoverableCard } from "@gigsmart/katana";
import React, { type ComponentProps, type ReactNode } from "react";

import GigCardHeader from "./header";

interface Props {
  testID?: string;
  name?: string | null;
  count?: number;
  children?: ReactNode;
  onPress?: () => void;
  numberOfLines?: number;
  headerVariant?: ComponentProps<typeof GigCardHeader>["variant"];
  spacing?: ComponentProps<typeof GigCardHeader>["spacing"];
  thumbnailIconName?: ComponentProps<typeof GigCardHeader>["thumbnailIconName"];
  iconLabel?: ComponentProps<typeof GigCardHeader>["iconLabel"];
  thumbNailIconColor?: ComponentProps<
    typeof GigCardHeader
  >["thumbnailIconColor"];
  thumbnailTintColor?: ComponentProps<
    typeof GigCardHeader
  >["thumbnailTintColor"];
  topAccessory?: ReactNode;
  bottomAccessory?: ReactNode;
  inset?: number;
  horizontal?: boolean;
  actionLabel?: string;
  disabled?: boolean;
  shadow?: boolean;
  marginBottom?: number;
}

const GigCard = ({
  testID,
  name,
  count = 1,
  onPress,
  children,
  headerVariant,
  numberOfLines,
  thumbnailIconName,
  thumbNailIconColor,
  thumbnailTintColor,
  iconLabel,
  topAccessory,
  spacing,
  inset = 0,
  horizontal = false,
  marginBottom = 0,
  bottomAccessory,
  actionLabel,
  disabled,
  shadow
}: Props) => {
  const styles = useStyles(
    ({ getUnits }) => ({
      grow: {
        flexGrow: horizontal ? 0 : 1,
        marginBottom: marginBottom ? getUnits(marginBottom) : 0
      },
      content: { marginHorizontal: getUnits(inset) }
    }),
    [inset, horizontal]
  );
  return (
    <HoverableCard
      noMargin
      noSpacing
      onPress={onPress}
      style={styles.grow}
      containerStyle={[styles.grow, styles.content]}
      testID={testID}
      disabled={disabled}
      shadow={shadow}
      eventTargetName="Gig Card"
    >
      {topAccessory}
      <GigCardHeader
        spacing={spacing}
        name={name}
        variant={headerVariant}
        numberOfLines={numberOfLines}
        thumbnailIconName={thumbnailIconName}
        thumbnailIconColor={thumbNailIconColor}
        thumbnailTintColor={thumbnailTintColor}
        iconLabel={iconLabel}
        actionLabel={actionLabel ?? (count > 1 ? `View All ${count}` : "View")}
      />
      {children}
      {bottomAccessory && (
        <>
          <Divider />
          {bottomAccessory}
        </>
      )}
    </HoverableCard>
  );
};

export default GigCard;
