import { Stack, useStepper } from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit } from "@gigsmart/fomu";
import SetPaymentMethodField from "@gigsmart/seibutsu/payment/SetPaymentMethodField";
import { useEditWorkerPayableAccount } from "@gigsmart/seibutsu/payment/workerAccount";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import BankingAdCard from "@gigsmart/seibutsu/worker/BankingAdCard";
import React from "react";
import StepperNavActions from "../../shared/Stepper/StepperNavActions";

export default function PaymentStep() {
  const { nextStep } = useStepper<Step, {}>();
  const editWorkerAccount = useEditWorkerPayableAccount();
  const handleSubmit: FomuSubmitFn = async ({ values }, done) => {
    await editWorkerAccount(values as any, done);
    done();
    nextStep();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <StepperNavActions />
      <Stack>
        <BankingAdCard screen="PAYMENT_STEP" />
        <SetPaymentMethodField />
      </Stack>
      <FormSubmit>
        {({ submit, invalid, submitting }) => (
          <ResponsiveStepperBtnPortal.Entrance
            testID="payment-form-submit-button"
            label="Continue"
            disabled={invalid || submitting}
            loading={submitting}
            onPress={submit}
          />
        )}
      </FormSubmit>
    </Form>
  );
}
