/**
 * @generated SignedSource<<596ab924b6fcfd040827297add3981ad>>
 * @relayHash 7bcef88d26828f17a36d9779e18ae541
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:j5S10FA4PdR6INshyPZG2y1qNT6mMMGdVCkQ3j3oRCk

import { ConcreteRequest } from 'relay-runtime';
export type logoutScreenMutation$variables = Record<PropertyKey, never>;
export type logoutScreenMutation$data = {
  readonly revokeCurrentAuthentication: {
    readonly ok: boolean;
  } | null | undefined;
};
export type logoutScreenMutation = {
  response: logoutScreenMutation$data;
  variables: logoutScreenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RevokeCurrentAuthenticationPayload",
    "kind": "LinkedField",
    "name": "revokeCurrentAuthentication",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "logoutScreenMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "logoutScreenMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "qpid:j5S10FA4PdR6INshyPZG2y1qNT6mMMGdVCkQ3j3oRCk",
    "metadata": {},
    "name": "logoutScreenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e93190bf1b70d5b9e1aa5f30a92e2c2e";

export default node;
