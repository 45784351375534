/**
 * @generated SignedSource<<3759a99bc7e072b92640bbb9f59cd58e>>
 * @relayHash bbc7c7a2d941b36baba963f9fb6e3007
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:DKoOw9iCkNv50bggZS0KwCsHOz75tVOlzzPbgT9KEgg

import { ConcreteRequest } from 'relay-runtime';
export type AddUserFileInput = {
  description?: string | null | undefined;
  file: string;
};
export type useAddUserFileMutation$variables = {
  input: AddUserFileInput;
};
export type useAddUserFileMutation$data = {
  readonly addUserFile: {
    readonly newUserFileEdge: {
      readonly node: {
        readonly filename: string | null | undefined;
        readonly id: string;
        readonly mimeType: string;
        readonly url: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useAddUserFileMutation = {
  response: useAddUserFileMutation$data;
  variables: useAddUserFileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddUserFilePayload",
    "kind": "LinkedField",
    "name": "addUserFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserFilesEdge",
        "kind": "LinkedField",
        "name": "newUserFileEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFile",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mimeType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddUserFileMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddUserFileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:DKoOw9iCkNv50bggZS0KwCsHOz75tVOlzzPbgT9KEgg",
    "metadata": {},
    "name": "useAddUserFileMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "31fca6ca80cf492c3739a0ed32e0f1bc";

export default node;
