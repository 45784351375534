import {
  Button,
  ContentArea,
  FooterSpacer,
  Linking,
  Platform,
  Pressable,
  ScreenScroll,
  ScreenTop,
  Stack,
  Surface,
  Text,
  toast
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type AppNavigationProp,
  type AppParamList,
  type AppScreenProps,
  type ParamListBase,
  useNavigation
} from "@gigsmart/kaizoku";
import Clipboard from "@react-native-clipboard/clipboard";
import React, { useCallback } from "react";
import BuildInfo from "./BuildInfo";

type Props = AppScreenProps<AppParamList, "About">;

export default function AboutScreen(_: Props) {
  const user = useCurrentUser();

  const navigation = useNavigation<AppNavigationProp<ParamListBase>>();

  const linkWebsite = async () =>
    Platform.OS === "web"
      ? window.open("https://www.gigsmart.com", "_blank")
      : await Linking.openURL("https://www.gigsmart.com");

  const handleUserIdCopy = useCallback(() => {
    if (user?.id) {
      Clipboard.setString(user.id);
      toast.success("User ID copied to clipboard");
    }
  }, [user?.id]);

  return (
    <ScreenScroll constraint="small" testID="about-screen">
      <ScreenTop />
      <Surface>
        <ContentArea size="xlarge">
          <Stack>
            <Pressable
              eventEntityType="user-id"
              eventTargetName="user-id"
              testID="user-id"
              onPress={handleUserIdCopy}
            >
              <Text
                variant="titleLg"
                color="primary"
                testID="about-screen-title"
                align="center"
              >
                Welcome to GigSmart!
              </Text>
            </Pressable>
            <Text variant="title" weight="normal" align="center">
              The way Workers choose jobs and Requesters source Workers.
            </Text>
            <ContentArea constraint="xsmall" size="large" variant="none">
              <Stack>
                <Button
                  color="danger"
                  label="Privacy Policy"
                  testID="privacy-policy-button"
                  onPress={() =>
                    navigation.push("Legal", { filename: "privacy" })
                  }
                />
                <Button
                  color="danger"
                  label="Terms of Service"
                  testID="terms-of-service-button"
                  onPress={() => navigation.push("Legal", { filename: "tos" })}
                />
                <Button
                  color="danger"
                  label="Website"
                  testID="website-button"
                  onPress={linkWebsite}
                />
              </Stack>
            </ContentArea>
            <Text align="center" variant="note">
              <Text weight="bold">GigSmart’s Platform is protected by:</Text>
              {"\n"}
              {"\n"}
              U.S. Patent Nos.: 10,592,952; 11,157,982; 11,170,426; 11,954,717;
              11,694,250; 12,100,033
              {"\n"}
              CA Patent No.: 2,971,740
              {"\n"}
              and/or pending patent application(s).
            </Text>
            <BuildInfo />
          </Stack>
        </ContentArea>
      </Surface>
      <FooterSpacer />
    </ScreenScroll>
  );
}
