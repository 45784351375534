import React, { Component, type ComponentType, type ReactNode } from "react";
import { TouchableOpacity, View } from "react-native";

import { StyledScrollView } from "../scrollable";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  theme,
  withStyles
} from "../style";
import { StyledText } from "../text";

interface TabChild {
  label: string;
  testID?: string;
  content: ComponentType<any>;
  renderIcon?: (arg0: any) => ReactNode;
}

type Props = StylesProps & {
  bottomNav?: boolean;
  activeColor?: string;
  activeFontColor?: string;
  activeIndex?: number;
  onIndexChange?: any;
  inactiveFontColor?: string;
  children: TabChild[];
  showPadding?: boolean;
  doNotUseScrollView?: boolean;
  styleOverrides?: {
    container?: ViewStyleProp;
    contentContainer?: ViewStyleProp;
    tabButton?: ViewStyleProp;
    padded?: ViewStyleProp;
  };
  style?: ViewStyleProp;
};

interface State {
  activeIndex: number;
}
@withStyles(({ color, font }) => ({
  active: {
    backgroundColor: color.blue,
    color: color.white
  },
  inactive: {
    backgroundColor: color.white,
    color: color.blue
  },
  border: {
    borderWidth: 1,
    borderRadius: 4
  },
  borderLeft: {
    borderLeftWidth: 1
  },
  container: {
    width: "100%"
  },
  padded: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    width: "100%",
    "@desktopWide": {
      width: 400,
      alignSelf: "center"
    },
    "@tablet": {
      width: "100%"
    }
  },
  section: {
    borderColor: color.blue,
    flex: 1
  },
  sectionContainer: {
    flexDirection: "row",
    overflow: "hidden",
    width: "100%"
  },
  label: {
    fontSize: font.size.medium,
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: "center"
  },
  icon: {
    fontSize: font.size.largest,
    paddingTop: 10,
    textAlign: "center"
  },
  textUnderIcon: {
    paddingTop: 0,
    paddingBottom: 0
  }
}))
export default class TabNavigation extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, nextState: State) {
    return Object.prototype.hasOwnProperty.call(props, "onIndexChange")
      ? { ...nextState, activeIndex: props.activeIndex }
      : nextState;
  }

  static defaultProps = {
    bottomNav: false,
    activeColor: theme.color.blue,
    activeFontColor: theme.color.white,
    inactiveFontColor: theme.color.blue,
    ...stylesStubs
  };

  state = {
    activeIndex: this.props.activeIndex ?? 0
  };

  get containerStyles() {
    const { styles, styleOverrides, style } = this.props;
    return [styleOverrides?.container ?? styles.container, style];
  }

  get contentContainerStyles() {
    const { styleOverrides, doNotUseScrollView } = this.props;
    const { contentContainer } = styleOverrides ?? {};

    if (contentContainer && doNotUseScrollView) {
      return { style: contentContainer };
    }
    if (contentContainer) {
      return { contentContainer };
    }
    return null;
  }

  get sectionStyles() {
    const { activeColor, showPadding, styles } = this.props;
    return [
      styles.sectionContainer,
      showPadding ? [styles.border, { borderColor: activeColor }] : undefined
    ];
  }

  get paddedStyles() {
    const { showPadding, styles, styleOverrides } = this.props;
    return [showPadding ? [styles.padded, styleOverrides?.padded] : undefined];
  }

  goToNextTab = () => {
    const { children } = this.props;
    const { activeIndex } = this.state;
    const newTabIndex = Math.min(activeIndex + 1, children.length - 1);

    this.handleIndexChange(newTabIndex);
  };

  goToPreviousTab = () => {
    const { activeIndex } = this.state;
    const newTabIndex = Math.max(activeIndex - 1, 0);

    this.handleIndexChange(newTabIndex);
  };

  handleIndexChange = (index: number) => {
    if (this.props.onIndexChange) {
      this.props.onIndexChange(index);
    } else {
      this.setState({ activeIndex: index });
    }
  };

  mapChildrenLabels = (child: TabChild, index: number) => {
    const {
      activeColor,
      activeFontColor,
      inactiveFontColor,
      showPadding,
      styleOverrides,
      styles
    } = this.props;
    const { activeIndex } = this.state;

    const activeStyleColor =
      index === activeIndex
        ? { color: activeFontColor }
        : { color: inactiveFontColor };
    const activeStyleBackground =
      index === activeIndex
        ? { backgroundColor: activeColor }
        : { backgroundColor: theme.color.white };

    return (
      <TouchableOpacity
        testID={child.testID ?? `TabNavigation_${index}`}
        key={child.label}
        onPress={() => this.handleIndexChange(index)}
        style={[
          styles.section,
          activeStyleBackground,
          showPadding && index !== 0 ? styles.borderLeft : {},
          styleOverrides ? styleOverrides.tabButton : {}
        ]}
      >
        {child?.renderIcon?.({ activeStyleColor, icon: styles.icon })}
        <StyledText
          numberOfLines={1}
          style={[
            styles.label,
            activeStyleColor,
            child.renderIcon && styles.labelWithIcon
          ]}
        >
          {child.label}
        </StyledText>
      </TouchableOpacity>
    );
  };

  render() {
    const { bottomNav, children } = this.props;
    const { activeIndex } = this.state;
    const navBar = (
      <View style={this.paddedStyles}>
        <View style={this.sectionStyles}>
          {children.map(this.mapChildrenLabels)}
        </View>
      </View>
    );

    if (!children[activeIndex]) {
      throw new Error(`no tab at index ${activeIndex}`);
    }
    const { content: ActiveTab } = children[activeIndex] || {
      content: () => null
    };

    const Container = this.props.doNotUseScrollView ? View : StyledScrollView;

    return (
      <View style={this.containerStyles}>
        {!bottomNav && navBar}
        <Container
          color="transparent"
          testID={`TabNavigation_content_${activeIndex}`}
          {...this.contentContainerStyles}
        >
          <ActiveTab
            goToNextTab={this.goToNextTab}
            goToPreviousTab={this.goToPreviousTab}
          />
        </Container>
        {bottomNav && navBar}
      </View>
    );
  }
}
