import type { Feature, FeatureCollection, Point } from "geojson";

type Region = any;
type MapEvent<T> = any | T;

type Props = {
  topLeftControls?: any;
  topRightControls?: any;
  bottomLeftControls?: any;
  bottomRightControls?: any;
  children?: any;
  bottomAdjust?: number;
  destinationPin?: Feature<Point> | null | undefined;
  pins?: FeatureCollection<Point, Record<string, any>>;
  featureCollection?: FeatureCollection<Point, Record<string, any>>;
  testID?: string;
  clusterize?: boolean;
  onMapPress?: () => void;
  onPressMarker?: (arg0: MapEvent<any>, arg1: string[]) => void;
  onVisibleMilesChange?: (arg0: number) => void;
  onMarkersVisibleChange?: (arg0: boolean) => void;
  onRegionChange?: (region: Region) => void;
  initialRegion?: Region;
};

export default ({ children }: Props) => children;

export type { Region, MapEvent };
