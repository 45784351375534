import * as React from "react";

import { View } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import Svg, { Defs, G, Path } from "react-native-svg";

export default function SpiralsImage() {
  const styles = useStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  }));
  return (
    <View style={styles.container}>
      <Svg width="121px" height="63px" viewBox="0 0 230 121">
        <Defs />
        <G
          id="[GG&amp;GW]-Worker-Profile-Redesign"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.08"
        >
          <G
            id="spirals-top-right"
            transform="translate(114.961000, 60.034383) scale(-1, 1) rotate(90.000000) translate(-114.961000, -60.034383) translate(54.926617, -54.926617)"
            fill="#2C5696"
          >
            <Path
              d="M84,229.922 C84.679,226.276 85.426,222.642 86.017,218.982 C86.639,215.127 87.154,211.256 87.595,207.373 C87.927,204.441 88.14,201.5 88.459,198.568 C88.609,197.185 88.638,195.782 88.72,194.389 C89.14,187.282 89.099,180.164 88.953,173.052 C88.88,169.509 88.642,165.964 88.339,162.425 C88.148,160.201 87.987,157.97 87.744,155.753 C87.431,152.903 87.077,150.056 86.652,147.217 C86.144,143.825 85.579,140.445 84.957,137.073 C84.233,133.147 83.376,129.252 82.444,125.372 C79.648,113.737 75.947,102.393 71.385,91.334 C67.968,83.05 64.066,74.998 59.73,67.152 C56.013,60.427 51.947,53.922 47.594,47.596 C43.786,42.063 39.714,36.728 35.454,31.533 C28.979,23.636 21.914,16.295 14.532,9.256 C12.162,6.997 9.657,4.879 7.212,2.698 C7.035,2.541 6.806,2.432 6.749,2.06 C6.972,1.532 7.589,1.349 8.051,1.004 C8.528,0.647 9.044,0.342 9.565,-9.78034162e-14 C10.1,0.175 10.475,0.538 10.859,0.874 C16.584,5.873 22.08,11.117 27.301,16.638 C31.227,20.788 35,25.078 38.631,29.495 C44.172,36.233 49.344,43.238 54.109,50.541 C58.35,57.038 62.267,63.724 65.857,70.608 C71.188,80.831 75.748,91.379 79.542,102.266 C82.766,111.517 85.408,120.923 87.465,130.501 C88.42,134.949 89.275,139.415 89.976,143.906 C90.486,147.173 90.962,150.449 91.27,153.745 C91.544,156.681 91.879,159.611 92.167,162.546 C92.284,163.736 92.335,164.939 92.382,166.128 C92.485,168.72 92.767,171.305 92.709,173.907 C92.641,176.902 93.093,179.895 92.88,182.888 C92.672,185.801 92.793,188.719 92.639,191.63 C92.459,195.014 92.254,198.399 91.944,201.773 C91.587,205.661 91.157,209.543 90.632,213.415 C89.893,218.87 88.938,224.286 87.869,229.684 C87.853,229.762 87.849,229.843 87.84,229.922 L84,229.922 Z"
              id="Fill-1"
            />
            <Path
              d="M56.4,229.922 C56.319,228.9 56.719,227.957 56.942,226.996 C57.915,222.798 58.724,218.568 59.407,214.317 C59.925,211.092 60.371,207.853 60.706,204.6 C60.971,202.023 61.235,199.448 61.437,196.864 C61.692,193.599 61.75,190.328 61.948,187.065 C62.145,183.826 62.188,180.588 62.043,177.365 C61.923,174.698 62.026,172.019 61.691,169.357 C61.518,167.976 61.542,166.572 61.437,165.178 C61.195,161.957 60.835,158.751 60.474,155.543 C59.974,151.104 59.256,146.698 58.443,142.31 C57.571,137.602 56.474,132.941 55.264,128.307 C52.524,117.813 48.959,107.608 44.607,97.675 C40.123,87.439 34.813,77.654 28.764,68.26 C24.779,62.069 20.414,56.158 15.84,50.398 C14.573,48.803 13.23,47.269 11.923,45.706 C11.771,45.525 11.574,45.37 11.55,45.018 C12.156,44.074 13.225,43.551 14.032,42.711 C14.915,43.076 15.294,43.88 15.812,44.494 C21.515,51.249 26.798,58.319 31.616,65.734 C35.038,70.999 38.199,76.414 41.166,81.948 C44.815,88.755 48.022,95.764 50.891,102.928 C53.938,110.536 56.482,118.313 58.613,126.228 C60.086,131.702 61.32,137.23 62.351,142.801 C62.968,146.131 63.459,149.483 63.924,152.839 C64.372,156.078 64.718,159.327 65.021,162.578 C65.18,164.281 65.249,166.006 65.386,167.711 C65.594,170.299 65.651,172.892 65.744,175.478 C65.888,179.432 65.84,183.395 65.757,187.352 C65.693,190.461 65.526,193.573 65.273,196.673 C64.869,201.637 64.366,206.595 63.634,211.524 C62.742,217.521 61.644,223.48 60.249,229.382 C60.205,229.571 60.085,229.742 60,229.922 L56.4,229.922 Z"
              id="Fill-3"
            />
            <Path
              d="M28.32,229.922 C28.61,228.148 29.178,226.442 29.6,224.701 C30.925,219.234 32.028,213.728 32.88,208.165 C33.43,204.577 33.843,200.976 34.213,197.37 C34.379,195.75 34.455,194.108 34.562,192.477 C34.889,187.453 35.124,182.426 34.938,177.398 C34.845,174.893 34.841,172.378 34.582,169.873 C34.435,168.449 34.404,167.007 34.272,165.577 C34.097,163.672 33.983,161.759 33.727,159.865 C33.391,157.378 33.05,154.891 32.648,152.412 C32.061,148.789 31.382,145.186 30.584,141.605 C29.298,135.834 27.695,130.15 25.896,124.52 C25.778,124.151 25.541,123.788 25.738,123.261 C26.65,122.76 27.74,122.535 28.735,122.199 C29.156,122.353 29.218,122.688 29.316,122.981 C31.317,128.968 32.979,135.049 34.361,141.207 C35.173,144.826 35.854,148.472 36.434,152.134 C36.858,154.81 37.187,157.501 37.518,160.191 C37.909,163.357 38.063,166.543 38.405,169.712 C38.413,169.791 38.415,169.871 38.419,169.951 C38.713,175.74 38.79,181.533 38.641,187.326 C38.573,189.955 38.413,192.586 38.18,195.206 C37.651,201.161 36.953,207.094 35.902,212.986 C34.906,218.567 33.661,224.088 32.219,229.567 C32.188,229.683 32.179,229.804 32.16,229.922 L28.32,229.922 Z"
              id="Fill-5"
            />
            <Path
              d="M111.84,229.922 C111.738,228.705 112.154,227.556 112.351,226.385 C113.046,222.259 113.732,218.134 114.206,213.976 C114.59,210.612 114.998,207.251 115.348,203.883 C115.505,202.378 115.522,200.858 115.644,199.348 C115.94,195.688 115.994,192.016 116.189,188.353 C116.426,183.918 116.388,179.485 116.291,175.058 C116.212,171.473 116.082,167.881 115.837,164.301 C115.616,161.083 115.267,157.873 114.982,154.659 C114.969,154.512 115.038,154.357 115.064,154.229 C116.712,153.764 117.303,153.712 118.495,153.915 C118.765,154.142 118.815,154.458 118.828,154.78 C118.929,157.131 119.22,159.466 119.372,161.814 C119.573,164.92 119.673,168.029 119.866,171.131 C120.154,175.766 119.952,180.396 120.056,185.027 C120.126,188.105 119.892,191.175 119.746,194.241 C119.567,198.018 119.307,201.796 118.918,205.565 C118.652,208.14 118.409,210.72 118.104,213.291 C117.743,216.336 117.279,219.369 116.817,222.401 C116.498,224.488 116.093,226.562 115.758,228.647 C115.687,229.086 115.56,229.501 115.44,229.922 L111.84,229.922 Z"
              id="Fill-7"
            />
            <Path
              d="M0,229.922 C0.536,228.086 1.066,226.247 1.61,224.414 C3.327,218.635 4.645,212.761 5.646,206.823 C6.202,203.527 6.668,200.213 6.946,196.874 C7.114,194.85 7.387,192.834 7.424,190.799 C7.429,190.518 7.449,190.236 7.785,189.896 C8.809,189.67 9.922,190.205 11.237,190.003 C10.948,196.906 10.079,203.59 8.854,210.228 C7.622,216.901 5.991,223.48 3.84,229.922 L0,229.922 Z"
              id="Fill-9"
            />
          </G>
        </G>
      </Svg>
    </View>
  );
}
