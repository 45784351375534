import moment from "moment-timezone";
import { useMemo } from "react";

import { DATE_FORMAT, type Day, MONTH_FORMAT } from "./helpers";

const useCalendar = (month: any) => {
  const weeks = useMemo(() => {
    const result: Day[][] = [];
    const monthStart = moment(month).startOf("month");
    const nextMonth = moment(monthStart).add(1, "month");
    const today = moment().format(DATE_FORMAT);

    const current = monthStart.clone().startOf("week");
    while (current.isBefore(nextMonth)) {
      const nextWeek = moment(current).add(1, "week");
      const currentWeek: Day[] = [];
      while (current.isBefore(nextWeek)) {
        const value = current.format(DATE_FORMAT);
        currentWeek.push({
          value,
          label: current.format("D"),
          month: current.format(MONTH_FORMAT),
          isToday: today === value
        });
        current.add(1, "day");
      }
      result.push(currentWeek);
    }
    return result;
  }, [month]);

  return weeks;
};

export default useCalendar;
