import { IconText, Well } from "@gigsmart/atorasu";
import React from "react";

export const PickupEligibleAttachment = () => {
  return (
    <Well color="emphasized" size="compact">
      <IconText
        size="small"
        color="emphasized"
        icon="alarm-clock"
        iconSize="tiny"
        iconAlign="center"
        textWeight="bold"
      >
        Shift Available Today
      </IconText>
    </Well>
  );
};
