import { ContentArea, Stack, Surface, Text } from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import FomuCheckbox from "@gigsmart/seibutsu/fomu/inputs/FomuCheckbox";
import React from "react";

export default function LegacyMobileNumberDisclosure() {
  return (
    <Surface testID="mobile-number-disclosure-container" variant="outline">
      <ContentArea>
        <Stack>
          <Text color="primary" weight="bold">
            Mobile Number Disclosure
          </Text>
          <Stack>
            <Stack size="compact">
              <Text>You agree to:</Text>
              <FomuCheckbox
                name="twilioSmsCapablePhone"
                title="Have a valid phone number capable of sending and receiving SMS messages, including one-time verification codes"
                testID={"twilioSmsCapablePhone-checkbox"}
                validates={Validator.isTrue()}
                expand
              />
              <FomuCheckbox
                name="twilioConsentGigOrWorkersNotifications"
                title="Receive notifications regarding Gigs or Workers related to your account"
                testID={"twilioConsentGigOrWorkersNotifications-checkbox"}
                validates={Validator.isTrue()}
                expand
              />
              <FomuCheckbox
                name="twilioConsentAccountStatusNotifications"
                title="Receive notifications regarding your account status"
                testID={"twilioConsentAccountStatusNotifications-checkbox"}
                validates={Validator.isTrue()}
                expand
              />
              <FomuCheckbox
                name="twilioConsentNewFeatureOrBenefitsNotifications"
                title="Receive notifications regarding new features and/or benefits of the platform"
                testID={
                  "twilioConsentNewFeatureOrBenefitsNotifications-checkbox"
                }
                validates={Validator.isTrue()}
                expand
              />
            </Stack>
            <Text variant="note" color="neutral" align="center">
              Message frequency will vary. Message and data rates may apply.
              Reply HELP for help or STOP to cancel.
            </Text>
          </Stack>
        </Stack>
      </ContentArea>
    </Surface>
  );
}
