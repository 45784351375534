import {
  AbsoluteContainer,
  Checkbox,
  Column,
  ContentArea,
  FooterContent,
  IconButton,
  Row,
  ScreenScroll,
  ScreenTop,
  Stack,
  Text,
  toast
} from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  Validator,
  useFormFields
} from "@gigsmart/fomu";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import { StyledDatePicker } from "@gigsmart/katana";
import {
  graphql,
  useRelayFragment,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import FomuConfirmExit from "../fomu/inputs/FomuConfirmExit";
import FomuPickerV2 from "../fomu/inputs/FomuPickerV2";
import FomuSubmit from "../fomu/inputs/FomuSubmit";
import FomuTextInput from "../fomu/inputs/FomuTextInput";
import type { ManageMilitaryExperienceFormAddMutation } from "./__generated__/ManageMilitaryExperienceFormAddMutation.graphql";
import type { ManageMilitaryExperienceFormEditMutation } from "./__generated__/ManageMilitaryExperienceFormEditMutation.graphql";
import type { ManageMilitaryExperienceForm_military$key } from "./__generated__/ManageMilitaryExperienceForm_military.graphql";
import useDeleteProfileItem from "./useDeleteProfileItem";

const MILITARY_BRANCHES = [
  {
    label: "United States Army",
    value: "United States Army"
  },
  {
    label: "United States Navy",
    value: "United States Navy"
  },
  {
    label: "United States Marine Corps",
    value: "United States Marine Corps"
  },
  {
    label: "United States Air Force",
    value: "United States Air Force"
  },
  {
    label: "United States Coast Guard",
    value: "United States Coast Guard"
  },
  {
    label: "United States Space Force",
    value: "United States Space Force"
  }
];

type Props = {
  fragmentRef?: ManageMilitaryExperienceForm_military$key | null;
};

export default function ManageMilitaryExperienceForm({ fragmentRef }: Props) {
  const military = useRelayFragment(
    graphql`
    fragment ManageMilitaryExperienceForm_military on WorkerMilitaryService {
      id
      branch
      militaryIdLast4
      serviceStart
      serviceEnd
    }
  `,
    fragmentRef ?? null
  );
  const initialValues = useMemo(
    () => ({
      branch: military?.branch ?? "",
      militaryId: military?.militaryIdLast4
        ? `******${military.militaryIdLast4}`
        : "",
      serviceStart: military?.serviceStart ?? "",
      serviceEnd: military?.serviceEnd ?? "",
      presentPosition: military?.serviceEnd === null
    }),
    [military]
  );

  const [addMilitaryExperience] =
    useRelayMutationPromise<ManageMilitaryExperienceFormAddMutation>(graphql`
      mutation ManageMilitaryExperienceFormAddMutation(
        $input: AddWorkerMilitaryServiceInput!
      ) {
        addWorkerMilitaryService(input: $input) {
          newWorkerMilitaryServiceEdge {
            cursor
            node {
              ...ManageMilitaryExperienceForm_military
            }
          }
        }
      }
    `);

  const [editMilitaryExperience] =
    useRelayMutationPromise<ManageMilitaryExperienceFormEditMutation>(graphql`
      mutation ManageMilitaryExperienceFormEditMutation(
        $input: UpdateWorkerMilitaryServiceInput!
      ) {
        updateWorkerMilitaryService(input: $input) {
          workerMilitaryService {
            ...ManageMilitaryExperienceForm_military
          }
        }
      }
    `);

  const deleteItem = useDeleteProfileItem();

  const handleSubmit: FomuSubmitFn = async ({ values }, done) => {
    const input = {
      branch: values.branch,
      serviceStart: values.serviceStart,
      serviceEnd: values.presentPosition ? null : values.serviceEnd,
      militaryId: values.militaryId
    };

    try {
      if (military) {
        await editMilitaryExperience({
          input: { ...input, workerMilitaryServiceId: military.id }
        });
      } else {
        await addMilitaryExperience({ input });
      }

      toast.success("Military has been successfully saved!");
      defaultBackHandler({ safeExit: true });
    } catch (err) {
      toast.error(err.message || "Something went wrong");
      done();
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      <FomuConfirmExit />
      <ScreenScroll
        color="surface"
        testID="manage-military-experience-screen"
        footer={
          <FooterContent bgColor="surface" bgSolid>
            <FomuSubmit label="Save" testID="update-military-button" />
          </FooterContent>
        }
      >
        <ScreenTop showBackButton />
        <ContentArea>
          <Stack>
            {!!military && (
              <AbsoluteContainer>
                <Row justifyContent="flex-end">
                  <IconButton
                    name="trash-alt"
                    testID="delete-work-experience"
                    variant="solid"
                    color="primary"
                    onPress={() => deleteItem(military.id, "Military")}
                  />
                </Row>
              </AbsoluteContainer>
            )}
            <Text variant="header" color="primary">
              Military
            </Text>
            <FomuPickerV2
              name="branch"
              label="Branch of Service"
              options={MILITARY_BRANCHES}
              placeholder="Choose a Branch"
              validates={Validator.presence()}
              eventTargetName="Input Military Branch Picker"
            />
            <FomuTextInput
              name="militaryId"
              label="Military ID Number"
              placeholder="Military ID Number"
              sensitive
              validates={[Validator.presence(), Validator.length({ min: 4 })]}
            />
            <ServicePeriod />
          </Stack>
        </ContentArea>
      </ScreenScroll>
    </Form>
  );
}

function ServicePeriod() {
  const { serviceStart, serviceEnd, presentPosition } = useFormFields({
    serviceStart: Validator.presence(),
    serviceEnd: Validator.dependsOn({
      field: "presentPosition",
      predicate: ({ currentFieldValue, otherFieldValue }) => {
        if (currentFieldValue || otherFieldValue) return true;
        return "cannot be blank";
      }
    }),
    presentPosition: null
  });
  return (
    <Stack horizontal>
      <Column fill>
        <StyledDatePicker
          label="Service Dates"
          placeholder="Start"
          eventTargetName="Input Start Date Picker"
          value={serviceStart.value}
          date={serviceStart.value}
          maxDate={new Date()}
          onDateChange={(v) => {
            serviceStart.setValue(
              DateTime.fromJSDate(v ?? new Date()).toFormat("yyyy-MM-dd")
            );
          }}
        />
      </Column>
      <Column fill>
        <Stack size="small">
          <StyledDatePicker
            label=" "
            placeholder="End"
            eventTargetName="Input End Date Picker"
            value={serviceEnd.value}
            date={serviceEnd.value}
            minDate={DateTime.fromJSDate(
              new Date(serviceStart.value || Date.now())
            ).toJSDate()}
            maxDate={new Date()}
            onDateChange={(v) => {
              serviceEnd.setValue(
                DateTime.fromJSDate(v ?? new Date()).toFormat("yyyy-MM-dd")
              );
              presentPosition.setValue(false);
            }}
            displayValue={presentPosition.value ? "Present" : null}
          />
          <Stack horizontal alignItems="center" size="compact">
            <Checkbox
              testID="current"
              color="primary"
              selected={presentPosition.value ?? false}
              onChange={(v) => {
                presentPosition.setValue(v);
                serviceEnd.setValue(v ? new Date() : undefined);
              }}
            />
            <Text color="primary">Current</Text>
          </Stack>
        </Stack>
      </Column>
    </Stack>
  );
}
