/**
 * @generated SignedSource<<67d8245feb4626f2987ed95fa9a7eb41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type seriesEngagementContent_engagement$data = {
  readonly estimatedPayment: {
    readonly netPay: string;
    readonly payRate: string;
  } | null | undefined;
  readonly gig: {
    readonly area: string | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
    readonly place: {
      readonly streetAddress: string | null | undefined;
    } | null | undefined;
    readonly position: {
      readonly name: string;
    } | null | undefined;
    readonly skills: {
      readonly totalCount: number;
    } | null | undefined;
  };
  readonly gigType: GigType;
  readonly skill: {
    readonly name: string;
  } | null | undefined;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentType": "seriesEngagementContent_engagement";
};
export type seriesEngagementContent_engagement$key = {
  readonly " $data"?: seriesEngagementContent_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"seriesEngagementContent_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "seriesEngagementContent_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Place",
          "kind": "LinkedField",
          "name": "place",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streetAddress",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 0
            }
          ],
          "concreteType": "GigSkillsConnection",
          "kind": "LinkedField",
          "name": "skills",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "skills(first:0)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigPosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "estimatedPayment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPay",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "5e553c9f6f9081c2784c78459f4619a1";

export default node;
