import React, { Component } from "react";
import { Image, type ImageStyle, TouchableOpacity, View } from "react-native";

import { type IconName, type IconVariant, StyledIcon } from "../icon";
import {
  type ImageStyleProp,
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { IconButton } from "../tappable";

type Props = StylesProps & {
  placeholderIcon: IconName;
  placeholderIconVariant?: IconVariant;
  onPress?: () => void;
  onIconPress?: () => void;
  placeholderBackgroundColor: string;
  backgroundColor: string;
  sourceUri?: string | null | undefined;
  testID?: string;
  width: number;
  imageStyle?: ViewStyleProp;
  align?: "center" | "left";
};

interface State {
  didError: boolean;
}

@withStyles(() => ({
  container: {
    position: "relative"
  },
  iconBottom: {
    right: 0,
    position: "absolute",
    bottom: 0
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    width: "100%"
  },

  center: { alignItems: "center" },
  left: { alignItems: "flex-start" }
}))
export default class Avatar extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    width: 180,
    placeholderBackgroundColor: "neutralLight",
    backgroundColor: "transparent",
    placeholderIcon: "user",
    placeholderIconVariant: "regular"
  };

  state = {
    didError: false
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      // shallow checks
      nextProps.sourceUri !== this.props.sourceUri ||
      nextProps.width !== this.props.width ||
      nextState.didError !== this.state.didError
    );
  }

  handleError = () => this.setState({ didError: true });

  get containerStyles() {
    return [this.props.styles.container, { width: this.props.width }];
  }

  get imageStyle(): ImageStyle {
    const { width } = this.props;
    return {
      borderRadius: width / 2,
      height: width,
      width,
      overflow: "hidden"
    };
  }

  renderImage() {
    const {
      styles,
      sourceUri,
      imageStyle,
      theme,
      onPress,
      backgroundColor: bgColor
    } = this.props;

    return (
      <TouchableOpacity
        disabled={!onPress}
        onPress={onPress}
        style={this.imageStyle}
      >
        <Image
          resizeMode="contain"
          source={{ uri: sourceUri ?? "" }}
          style={
            [
              styles.image,
              imageStyle,
              { backgroundColor: theme.color.getColor(bgColor) }
            ] as ImageStyleProp
          }
          onError={this.handleError}
        />
      </TouchableOpacity>
    );
  }

  renderPlaceholder() {
    const {
      theme,
      styles,
      width,
      placeholderIcon,
      placeholderIconVariant,
      placeholderBackgroundColor: bgColor,
      onPress
    } = this.props;
    return (
      <TouchableOpacity
        disabled={!onPress}
        onPress={onPress}
        style={[
          styles.image,
          this.imageStyle,
          { backgroundColor: theme.color.getColor(bgColor) }
        ]}
      >
        <StyledIcon
          color="white"
          name={placeholderIcon}
          variant={placeholderIconVariant}
          style={{ fontSize: width / 2 }}
        />
      </TouchableOpacity>
    );
  }

  render() {
    const {
      onIconPress,
      sourceUri,
      testID,
      styles,
      theme,
      width,
      align = "center"
    } = this.props;
    const { didError } = this.state;
    const shouldRenderImage = sourceUri && !didError;
    return (
      <View style={align === "center" ? styles.center : styles.left}>
        <View style={this.containerStyles}>
          {shouldRenderImage ? this.renderImage() : this.renderPlaceholder()}
          {!!onIconPress && (
            <View style={styles.iconBottom}>
              <IconButton
                testID={testID}
                backgroundColor={theme.color.blue}
                diameter={Math.max(width / 3.75, 32)}
                icon="plus"
                iconColor={theme.color.white}
                onPress={onIconPress}
                width={48}
              />
            </View>
          )}
        </View>
      </View>
    );
  }
}
