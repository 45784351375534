import { captureError } from "@gigsmart/dekigoto";
import { CompressCapabilities } from "@gigsmart/feature-flags";
import { flagStates } from "@gigsmart/feature-flags/registry";
import { Buffer } from "buffer/";
import pickBy from "lodash/pickBy";
import pako from "pako";
import { getDeviceId } from "../app/device-id";

export default async function encodeCapabilities({
  compress = CompressCapabilities.isEnabled(),
  hasLocationPermission = false,
  hasNotificationsPermission = false,
  hasBackgroundLocationPermission = false
}: {
  compress?: boolean;
  hasLocationPermission?: boolean;
  hasNotificationsPermission?: boolean;
  hasBackgroundLocationPermission?: boolean;
} = {}): Promise<string> {
  const { bot } = await getDeviceId();
  const caps = {
    bot,
    ...flagStates(true), // Send all feature flags as capabilities
    "device-token": process.env.IS_TESTING !== "true",
    "push-notifications": hasNotificationsPermission,
    "location-enabled": hasLocationPermission,
    "background-location-enabled": hasBackgroundLocationPermission
  };
  try {
    const capabilitiesJson = JSON.stringify(
      Object.keys(pickBy(caps, (v) => v))
    );
    if (compress) {
      try {
        return `~${Buffer.from(pako.gzip(capabilitiesJson)).toString(
          "base64"
        )}`;
      } catch (e) {
        return await encodeCapabilities({
          compress: false,
          hasLocationPermission,
          hasNotificationsPermission
        });
      }
    } else {
      return Buffer.from(capabilitiesJson).toString("base64");
    }
  } catch (e) {
    captureError(e);
    throw e;
  }
}
