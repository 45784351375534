/**
 * @generated SignedSource<<51c3bd12880e5a33fedbf9bddfc7c56b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigRequesterRow_gigLike$data = {
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly logoUrl: string | null | undefined;
    readonly name: string;
    readonly overallRating: number;
    readonly reviewsReceived: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly primaryContact: {
    readonly displayName: string | null | undefined;
    readonly overallRating: number;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly requester: {
      readonly overallRating: number;
      readonly reviewsReceived: {
        readonly totalCount: number;
      } | null | undefined;
    };
  } | null | undefined;
  readonly requester: {
    readonly displayName: string | null | undefined;
    readonly overallRating: number;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly reviewsReceived: {
      readonly totalCount: number;
    } | null | undefined;
  };
  readonly " $fragmentType": "gigRequesterRow_gigLike";
};
export type gigRequesterRow_gigLike$key = {
  readonly " $data"?: gigRequesterRow_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigRequesterRow_gigLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallRating",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    }
  ],
  "concreteType": "EngagementReviewsConnection",
  "kind": "LinkedField",
  "name": "reviewsReceived",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "reviewsReceived(first:0)"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigRequesterRow_gigLike",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationRequester",
      "kind": "LinkedField",
      "name": "primaryContact",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v1/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Requester",
          "kind": "LinkedField",
          "name": "requester",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Requester",
      "kind": "LinkedField",
      "name": "requester",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "51a05ee1e75b292ebabb04d95c0e91d5";

export default node;
