import moment from "moment-timezone";
import React, { Component } from "react";
import { View } from "react-native";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../../style";
import { StyledText } from "../../text";
import DayPickerEntry from "./day-picker-entry";

interface WeekdayType {
  label: string;
  value: number;
}

const allWeekdays: string[] = moment.weekdays();

const weekdays: WeekdayType[] = allWeekdays.map((day, idx) => {
  return { label: day.charAt(0), value: idx + 1 };
});

type Props = StylesProps & {
  hintText?: string;
  value?: string;
  onChangeText: (value: string) => unknown;
  style?: ViewStyleProp;
  testID?: string;
};
@withStyles(({ font, color }) => ({
  dayRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  hintText: {
    fontSize: font.size.medium,
    color: color.neutralDark,
    marginTop: 10
  }
}))
export default class DayPicker extends Component<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  render() {
    const { styles, style, hintText, testID } = this.props;
    return (
      <View style={style} testID={testID}>
        <View style={styles.dayRow}>{weekdays.map(this.renderEntry)}</View>
        {!!hintText && (
          <StyledText style={styles.hintText}>{hintText}</StyledText>
        )}
      </View>
    );
  }

  renderEntry = (weekday: WeekdayType) => (
    <DayPickerEntry
      key={`${weekday.value}`}
      label={weekday.label}
      value={weekday.value}
      isSelected={this.isSelected(weekday)}
      onPress={() => this.toggleEntry(weekday)}
    />
  );

  toggleEntry = (weekday: WeekdayType) => {
    const oldValue = this.props.value ? this.props.value.split(",") : [];
    const newValue = this.isSelected(weekday)
      ? oldValue.filter((d) => d !== `${weekday.value}`)
      : [...oldValue, weekday.value];
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    this.props.onChangeText(newValue.sort().join(","));
  };

  isSelected = (weekday: WeekdayType) => {
    if (!this.props.value) return false;
    return this.props.value.includes(`${weekday.value}`);
  };
}
