/**
 * @generated SignedSource<<908682327a40d3abf592512c37172818>>
 * @relayHash 5da4615d72085854f936ef2932326609
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:mLodejzBJYygLX25UW1ZCHgw_Cu341neqnxEyRgvANs

import { ConcreteRequest } from 'relay-runtime';
export type ConversationStatus = "CLOSED" | "OPEN" | "%future added value";
export type SetConversationStatusInput = {
  conversationId: string;
  status: ConversationStatus;
};
export type AdminMoreMenuSetConversationStatusMutation$variables = {
  input: SetConversationStatusInput;
};
export type AdminMoreMenuSetConversationStatusMutation$data = {
  readonly setConversationStatus: {
    readonly conversation: {
      readonly __typename: string;
      readonly id?: string;
      readonly status?: ConversationStatus;
    };
  } | null | undefined;
};
export type AdminMoreMenuSetConversationStatusMutation = {
  response: AdminMoreMenuSetConversationStatusMutation$data;
  variables: AdminMoreMenuSetConversationStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "OrganizationWorkerConversation",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminMoreMenuSetConversationStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetConversationStatusPayload",
        "kind": "LinkedField",
        "name": "setConversationStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminMoreMenuSetConversationStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetConversationStatusPayload",
        "kind": "LinkedField",
        "name": "setConversationStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v2/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:mLodejzBJYygLX25UW1ZCHgw_Cu341neqnxEyRgvANs",
    "metadata": {},
    "name": "AdminMoreMenuSetConversationStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1b44da9107d584b57b94b82a385b9644";

export default node;
