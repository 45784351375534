/**
 * @generated SignedSource<<ce4321885acf27a322d49d83d6dec871>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EducationForm_worker$data = {
  readonly educationLevel: string | null | undefined;
  readonly endDate: string | null | undefined;
  readonly id: string;
  readonly schoolName: string | null | undefined;
  readonly startDate: string | null | undefined;
  readonly " $fragmentType": "EducationForm_worker";
};
export type EducationForm_worker$key = {
  readonly " $data"?: EducationForm_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"EducationForm_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationForm_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "type": "WorkerEducation",
  "abstractKey": null
};

(node as any).hash = "c72b79d2719facd8dfb642cfd4af2df9";

export default node;
