/**
 * @generated SignedSource<<c37d9492b06befed3b4ae6a0c2207923>>
 * @relayHash 3cfc4b09442d998ec8435d5c83e2242c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:8G_PHuiS8VJJDDpyFLIXIiwbN9LDh5Ark0eiKRwsYzg

import { ConcreteRequest } from 'relay-runtime';
export type shareScreenQuery$variables = Record<PropertyKey, never>;
export type shareScreenQuery$data = {
  readonly viewer: {
    readonly referralCode: {
      readonly code: string;
    };
  } | null | undefined;
};
export type shareScreenQuery = {
  response: shareScreenQuery$data;
  variables: shareScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "shareScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReferralCode",
            "kind": "LinkedField",
            "name": "referralCode",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "shareScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReferralCode",
            "kind": "LinkedField",
            "name": "referralCode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:8G_PHuiS8VJJDDpyFLIXIiwbN9LDh5Ark0eiKRwsYzg",
    "metadata": {},
    "name": "shareScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "851c67979b10b46a7f7f362b27873654";

export default node;
