import React from "react";

import {
  Button,
  ContentArea,
  Stack,
  Surface,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import type { useAdminDeleteMessageConfirmationModalDeleteMessageMutation } from "./__generated__/useAdminDeleteMessageConfirmationModalDeleteMessageMutation.graphql";

export default function (id: string) {
  const [deleteMessage] =
    useRelayMutation<useAdminDeleteMessageConfirmationModalDeleteMessageMutation>(graphql`
      mutation useAdminDeleteMessageConfirmationModalDeleteMessageMutation(
        $input: RemoveUserMessageInput!
      ) {
        removeUserMessage(input: $input) {
          removedUserMessageId
          removedUserMessage {
            id
            deletedAt
          }
        }
      }
    `);

  return () =>
    showModal({
      eventContext: "userMessageDeleteMessageModal",
      children: (close) => {
        const handleDeleteMessage = () => {
          deleteMessage(
            {
              input: {
                userMessageId: id
              }
            },
            {
              onSuccess: () => {
                close();
              },
              onError: (error: any) => console.error(error)
            }
          );
        };
        return (
          <Surface color="divider" fill>
            <ContentArea>
              <Stack justifyContent="center" alignItems="center">
                <Text weight="bold">
                  Are you sure you want to delete this message?
                </Text>
                <Stack horizontal justifyContent="flex-end" alignItems="center">
                  <Button
                    size="small"
                    label="Cancel"
                    onPress={close}
                    variant="clear"
                    testID="user-message-delete-message-modal-cancel-button"
                  />
                  <Button
                    size="small"
                    label="Delete"
                    onPress={handleDeleteMessage}
                    testID="user-message-delete-message-modal-delete-button"
                  />
                </Stack>
              </Stack>
            </ContentArea>
          </Surface>
        );
      },
      title: "Confirm Message Deletion",
      style: { minWidth: 400, maxHeight: 240 },
      useModalBody: false,
      fixedHeight: true,
      headerSpacing: "compact"
    });
}
