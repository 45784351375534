import {
  ContentArea,
  ModalBody,
  ModalFooter,
  Spacer,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, type ValueObject } from "@gigsmart/fomu";
import React from "react";

import FomuSubmit from "../fomu/inputs/FomuSubmit";
import BankAccountFields from "../gig/payment/BankAccountFields";
import DebitCardFields from "../gig/payment/DebitCardFields";

interface Props {
  type: "BankAccount" | "BankCard";
  onClose: () => void;
  onConfirm: (values: ValueObject) => void;
  initialValues?: any;
}

function BankAccountModal({
  type,
  initialValues = {},
  onConfirm,
  onClose
}: Props) {
  const handleSubmit: FomuSubmitFn = ({ values }) => {
    onConfirm({ type, ...values });
    onClose();
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <ModalBody scrollable extraScrollHeight={-35}>
        <ContentArea>
          {type === "BankAccount" ? <BankAccountFields /> : <DebitCardFields />}
          <Spacer />
          <Text variant="note" color="neutral">
            Note: GigSmart does not store this information.
          </Text>
        </ContentArea>
      </ModalBody>
      <ModalFooter>
        <FomuSubmit
          testID="submit-edit-payment-form"
          label={type === "BankAccount" ? "Add Bank Account" : "Add Debit Card"}
        />
      </ModalFooter>
    </Form>
  );
}

export function showEditPaymentMethodModal({
  type,
  ...rest
}: Omit<Props, "onClose">) {
  showModal({
    eventContext: `Add ${type} Modal`,
    title:
      type === "BankAccount"
        ? "Add Bank Account Information"
        : "Add Debit Card Information",
    variant: "border",
    fixedHeight: true,
    useModalBody: false,
    children: (closeFn) => (
      <BankAccountModal {...rest} type={type} onClose={closeFn} />
    )
  });
}
