import { Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { StyledCheckbox, StyledView } from "@gigsmart/katana";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React, { type ReactNode } from "react";

type Props = ComponentPropsWithDefaults<typeof StyledCheckbox> & {
  title: ReactNode;
  description: string;
  border?: boolean;
};

export default function OptInRow({
  title,
  description,
  border,
  ...props
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    row: {
      borderBottomColor: getColor("background", "fill"),
      borderBottomWidth: 1
    }
  }));
  return (
    <StyledView style={border && styles.row} color="transparent">
      <StyledCheckbox
        position="left"
        title={
          <>
            <Text color="primary" weight="bold">
              {title}
            </Text>
            <Text>{description}</Text>
          </>
        }
        {...props}
      />
    </StyledView>
  );
}
