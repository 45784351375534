import React, {
  type ReactNode,
  Children,
  cloneElement,
  isValidElement
} from "react";
import Stack from "../container/stack";
import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  backgroundColor?: string;
  children?: ReactNode;
  vSpacing?: number;
  spacing?: number;
  ordered?: boolean;
  style?: ViewStyleProp;
}

const BulletList = ({
  backgroundColor = "neutralLightGray",
  ordered,
  children,
  vSpacing = 4,
  spacing = 2.5,
  style
}: Props) => {
  const { styles } = useStyles(
    ({ color, unit, units }) => ({
      container: {
        paddingVertical: units(vSpacing),
        backgroundColor: color.getColor(backgroundColor),
        borderRadius: unit.borderRadius
      }
    }),
    [backgroundColor, vSpacing]
  );

  if (ordered) {
    children = Children.toArray(children).map((el, index) => {
      if (isValidElement<{ order: number }>(el)) {
        return cloneElement(el, { order: index + 1 });
      }
      return null;
    });
  }

  return (
    <Stack style={[styles.container, style]} spacing={spacing}>
      {children}
    </Stack>
  );
};

export default BulletList;
