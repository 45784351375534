import "./handlers";

import { GridNull, Spacer } from "@gigsmart/atorasu";
import { createAnnouncementsComponent } from "@gigsmart/isomorphic-shared/announcement";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import React from "react";
import type { announcementWorkerQuery } from "./__generated__/announcementWorkerQuery.graphql";
import type { announcementWorkerSubscription } from "./__generated__/announcementWorkerSubscription.graphql";

const Announcements = createAnnouncementsComponent({
  audienceType: "WORKER"
});

interface Props {
  enableSpacing?: boolean;
}

export default createSuspendedQueryContainer<announcementWorkerQuery, Props>(
  function WorkerAnnouncements({
    enableSpacing = true,
    response: { viewer } = {}
  }) {
    const user = useCurrentUser();
    useRelaySubscription<announcementWorkerSubscription>(
      graphql`
        subscription announcementWorkerSubscription {
          workerUpdated {
            worker {
              id
              currentEngagement {
                id
                currentState {
                  name
                }
              }
            }
          }
        }
      `,
      {},
      { subscribe: !!user?.id }
    );

    const hasCurrentEngagement =
      !!viewer?.currentEngagement?.id &&
      !["PENDING_TIMESHEET_APPROVAL", "ENDED"].includes(
        viewer?.currentEngagement?.currentState?.name
      );

    return hasCurrentEngagement ? (
      enableSpacing ? (
        <Spacer />
      ) : (
        <GridNull />
      )
    ) : (
      <Announcements enableSpacing={enableSpacing} />
    );
  },
  {
    query: graphql`
      query announcementWorkerQuery {
        viewer {
          ... on Worker {
            currentEngagement {
              id
              currentState {
                name
              }
            }
          }
        }
      }
    `,
    variables: {},
    FallbackComponent: () => null
  }
);
