import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { StyledPicker } from "@gigsmart/katana";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";
import { Platform } from "react-native";

type RefinedStyledPickerProps = Omit<
  ComponentPropsWithDefaults<typeof StyledPicker>,
  "onSubmitEditing" | "value" | "onChangeText" | "errors" | "styles" | "theme"
>;

type Props = RefinedStyledPickerProps & FormFieldOptions;

export function FomuStyledPicker({ name, validates, ...props }: Props) {
  const { setValue, value, submit, errors } = useFormField({
    name,
    validates
  });
  return (
    <StyledPicker
      onSubmitEditing={Platform.OS === "web" ? submit : undefined}
      value={value}
      onChangeText={setValue}
      errors={errors ?? []}
      {...props}
    />
  );
}
