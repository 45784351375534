/**
 * @generated SignedSource<<3ec0f8cec1e24dc63ea12ce07657aa99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerLatestArrivalTimeReminder_gig$data = {
  readonly currentState: {
    readonly name: GigStateName;
  };
  readonly latestArrivalTime: string | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentType": "WorkerLatestArrivalTimeReminder_gig";
};
export type WorkerLatestArrivalTimeReminder_gig$key = {
  readonly " $data"?: WorkerLatestArrivalTimeReminder_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "WorkerLatestArrivalTimeReminder_gig"
};

(node as any).hash = "8c132798e1089f5434f34ad908754b6d";

export default node;
