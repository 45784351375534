import React from "react";

import { ContentArea, ScreenScroll, Spacer, Text } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import SkillCategories from "@gigsmart/seibutsu/worker-profile/SkillCategories";

import type { categoriesScreenQuery } from "./__generated__/categoriesScreenQuery.graphql";

export default createSuspendedQueryContainer<categoriesScreenQuery>(
  function CategoriesScreen({ response: { viewer } = {} }) {
    return (
      <ScreenScroll testID="categories-screen" color="surface">
        <ContentArea>
          <Text variant="header">Categories & Positions</Text>
          <Spacer size="medium" />
          <SkillCategories fragmentRef={viewer} />
        </ContentArea>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query categoriesScreenQuery {
        viewer {
          ... on Worker {
            ...SkillCategories_categoriesScreen
          }
        }
      }
    `,
    variables: {}
  }
);
