import { BrandButton, Platform } from "@gigsmart/atorasu";
import { googleSignin } from "@gigsmart/isomorphic-shared/auth/social-login/google-signin-helper";
import React from "react";
import useLogin from "../hooks/useLogin";

interface Props {
  appName: "Get Gigs" | "Get Workers";
  signup?: boolean;
  onLogin?: () => void;
}
export default function GoogleLoginButon({ appName, onLogin, signup }: Props) {
  const { processLogin, handleError } = useLogin(
    appName === "Get Gigs" ? "WORKER" : "REQUESTER"
  );

  const handleLogin = async () => {
    const res = await googleSignin().catch((err) => {
      handleError(err, "Something went wrong");
      return null;
    });
    if (res?.accessToken) {
      void processLogin({
        input: {
          identifier: res.idToken ?? "",
          credential: res.accessToken,
          authenticationProvider: "GOOGLE"
        },
        onLogin
      });
    }
  };

  const verb = signup ? "Sign up" : "Sign in";
  return (
    <BrandButton
      label={`${verb} with Google`}
      brand="google"
      onPress={handleLogin}
      appleFont={Platform.OS === "ios"}
    />
  );
}
