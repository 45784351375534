import React from "react";
import { View } from "react-native";

import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  style?: ViewStyleProp;
  inset?: number | boolean;
}

const ListSeparator = ({ style, inset = 4 }: Props) => {
  const { styles, theme } = useStyles(({ color }) => ({
    separator: {
      backgroundColor: color.neutralLight,
      height: 1
    }
  }));

  const sepStyles: ViewStyleProp = [styles.separator];
  if (inset) {
    const marginHorizontal = theme.units(typeof inset === "number" ? inset : 4);
    sepStyles.push({ marginHorizontal });
  }
  if (style) sepStyles.push(style);

  return <View style={sepStyles} />;
};

export default ListSeparator;
