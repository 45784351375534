import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import Requester from "../requester/requester";
import type { gigRequesterQuery } from "./__generated__/gigRequesterQuery.graphql";

type Props = AppScreenProps<
  WorkerParamList,
  "ShiftRequesterProfile" | "ProjectRequesterProfile"
>;

export default createSuspendedQueryContainer<gigRequesterQuery, Props>(
  function GigRequester({ navigation, response }) {
    const engagement = response?.engagement;
    const gigId = engagement?.gig?.id ?? "";
    const requesterId =
      engagement?.gig?.primaryContact?.id ?? engagement?.gig?.requester?.id;
    if (!gigId || !requesterId) return null;

    return (
      <Requester
        requesterId={requesterId}
        gigId={gigId}
        navigation={navigation}
      />
    );
  },
  {
    query: graphql`
      query gigRequesterQuery($id: ID!) {
        engagement: node(id: $id) {
          ... on Engagement {
            gig {
              id
              requester {
                id
              }
              primaryContact {
                id
              }
            }
          }
        }
      }
    `,
    variables: (props) => ({ id: props.route.params.id })
  }
);
