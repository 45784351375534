import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  ALL_ENGAGEMENT_STATE_NAMES,
  type EngagementStateName
} from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  ConnectionHandler,
  useFetchQueryResult,
  useRelaySubscription
} from "@gigsmart/relay";
import difference from "lodash/difference";
import { useMemo } from "react";

import {
  type EngagementListVariant,
  engagementVariants,
  type useEngagementListAddSubscription,
  type useEngagementListQuery,
  type useEngagementListRemoveSubscription,
  type useEngagementList_worker$key
} from "./use-engagement-list.gql";

export interface EngagementListOptions<
  T extends EngagementListVariant = EngagementListVariant
> {
  variant: T;
  query: string;
  liveAdd?: EngagementStateName[];
  includeGigSeries?: boolean;
}

const useEngagementList = <T extends EngagementListVariant>({
  query,
  liveAdd,
  variant,
  includeGigSeries = false
}: EngagementListOptions<T>) => {
  const {
    addSubscriptionSpec,
    removeSubscriptionSpec,
    fragmentSpec,
    querySpec
  } = engagementVariants[variant];

  const worker = useCurrentUser();
  const workerId = worker?.id ?? "";
  const shouldSubscribe = !!worker?.id && !!liveAdd?.length;
  const liveRemove = useMemo<EngagementStateName[]>(
    () => (liveAdd ? difference(ALL_ENGAGEMENT_STATE_NAMES, liveAdd) : []),
    [liveAdd]
  );

  const [result] = useFetchQueryResult<useEngagementListQuery[T]>(querySpec, {
    variables: {
      query,
      includeGigSeries
    }
  });

  // add to list subscription
  useRelaySubscription<useEngagementListAddSubscription[T]>(
    addSubscriptionSpec,
    { workerId, engagementStates: liveAdd, includeGigSeries },
    {
      subscribe: shouldSubscribe,
      updater: (store, data) => {
        const toAddId = data?.engagementAdded?.newEngagementEdge?.node?.id;
        const workerProxy = store.get(workerId);
        const toAdd = store
          ?.getRootField("engagementAdded")
          ?.getLinkedRecord("newEngagementEdge");
        if (!workerProxy || !toAdd) return;
        const conn = ConnectionHandler.getConnection(
          workerProxy,
          "engagementList_engagements",
          { query }
        );

        const alreadyAdded = !!conn
          ?.getLinkedRecords("edges")
          ?.find(
            (edge) => edge?.getLinkedRecord("node")?.getDataID() === toAddId
          );

        if (conn && !alreadyAdded) {
          const edge = ConnectionHandler.buildConnectionEdge(
            store,
            conn,
            toAdd
          );
          if (edge) {
            ConnectionHandler.insertEdgeBefore(conn, edge);
          }
        }
      }
    }
  );

  // remove from list subscription
  useRelaySubscription<useEngagementListRemoveSubscription>(
    removeSubscriptionSpec,
    { workerId, engagementStates: liveRemove },
    {
      subscribe: shouldSubscribe,
      updater: (store, data) => {
        const workerProxy = store.get(workerId);
        const toDelete = data?.engagementAdded?.newEngagementEdge?.node?.id;
        if (!workerProxy || !toDelete) return;
        const conn = ConnectionHandler.getConnection(
          workerProxy,
          "engagementList_engagements",
          { query }
        );
        if (conn) ConnectionHandler.deleteNode(conn, toDelete);
      }
    }
  );

  return {
    parentRef: result?.viewer as useEngagementList_worker$key[T],
    fragmentSpec
  };
};

export default useEngagementList;
