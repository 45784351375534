import type { IRNOnfleetSDK } from "./types";

const noop = async (..._args: any[]) => undefined;
const err = async (..._args: any[]): Promise<any> => {
  throw new Error("Unsupported Operation");
};

export const RNOnfleetSDKStub: IRNOnfleetSDK = {
  addListener: noop,
  removeListeners: noop,
  initSDK: noop,
  login: err,

  setOnDuty: noop,
  startTask: noop,
  getTaskById: err,
  refreshCompletedTasks: noop,
  subscribeToActiveTaskId: noop,
  getCompletedTasks: noop,
  subscribeToCompletedTaskById: noop,
  completeTask: err
};

export const addListener = (eventType: any, listener: any) => {
  return { remove: () => {} };
};
