import { FooterSpacer, Spacer } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { ListSeparator } from "@gigsmart/katana";
import React from "react";
import JobBoardNudge from "../job-board-nudge";

const ShiftGigsListFooter = () => {
  const styles = useStyles(({ getColor }) => ({
    sep: { backgroundColor: getColor("black", "fill", { opacity: 0.08 }) }
  }));

  return (
    <>
      <Spacer size="compact" />
      <ListSeparator style={styles.sep} />
      <JobBoardNudge endOfList />
      <FooterSpacer />
    </>
  );
};
export default ShiftGigsListFooter;
