import React, { Component } from "react";
import { Platform, View } from "react-native";

import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { StyledText } from "../text";

type Props = StylesProps & {
  style?: ViewStyleProp;
  circleStyle?: ViewStyleProp;
  number: number;
  numberSize?: number;
  borderColor?: string;
  numberColor?: string;
  testID?: string;
  textOnly?: boolean;
  delimiter?: string;
};

@withStyles(({ font }) => ({
  container: {
    alignItems: "center"
  },
  circle: {
    borderWidth: 5,
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    width: 150,
    borderRadius: 75,
    padding: 15
  },
  number: {
    fontSize: 50,
    lineHeight: 50 * font.lineHeight.body,
    width: "100%",
    verticalAlign: "middle",
    textAlign: "center"
  }
}))
export default class Counter extends Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    textOnly: false,
    delimiter: ","
  };

  get fontSize() {
    const { number, numberSize, theme } = this.props;

    if (numberSize) {
      return {
        ...theme.font.body("bold"),
        fontSize: numberSize,
        lineHeight: numberSize * theme.font.lineHeight.body
      };
    }
    if (Platform.OS === "ios") return {};

    switch (number.toString().length) {
      case 1:
      case 2:
      case 3:
        return {
          fontSize: 54,
          lineHeight: 54 * theme.font.lineHeight.body
        };
      case 4:
        return {
          fontSize: 42,
          lineHeight: 42 * theme.font.lineHeight.body
        };
      case 5:
        return {
          fontSize: 34,
          lineHeight: 34 * theme.font.lineHeight.body
        };
      case 6:
        return {
          fontSize: 28,
          lineHeight: 28 * theme.font.lineHeight.body
        };
      case 7:
        return {
          fontSize: 24,
          lineHeight: 24 * theme.font.lineHeight.body
        };
      default:
        return {
          fontSize: 21,
          lineHeight: 21 * theme.font.lineHeight.body
        };
    }
  }

  get textStyles() {
    return [
      this.props.styles.number,
      { color: this.props.numberColor },
      this.fontSize
    ];
  }

  get viewStyles() {
    return (
      !this.props.textOnly && [
        this.props.styles.circle,
        { borderColor: this.props.borderColor },
        ...(this.props.circleStyle ? [this.props.circleStyle] : [])
      ]
    );
  }

  render() {
    const { delimiter, number, styles, style, testID } = this.props;

    return (
      <View style={[styles.container, style]}>
        <View style={this.viewStyles}>
          <StyledText
            adjustsFontSizeToFit
            numberOfLines={1}
            testID={testID}
            style={this.textStyles}
          >
            {number
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter ?? ",")}
          </StyledText>
        </View>
      </View>
    );
  }
}
