/**
 * @generated SignedSource<<243e2c7332256693ada0274a554421df>>
 * @relayHash c6a129649c3adca68f76826b6df322f3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:xVBf-sInDocbtMVTPZtcN8zDyM6FXprhkJam8nlWagY

import { ConcreteRequest } from 'relay-runtime';
export type SetUserValueInput = {
  key: string;
  userId?: string | null | undefined;
  value?: string | null | undefined;
};
export type useUserValueMutation$variables = {
  input: SetUserValueInput;
};
export type useUserValueMutation$data = {
  readonly setUserValue: {
    readonly userValue: {
      readonly id: string;
      readonly key: string | null | undefined;
      readonly value: string | null | undefined;
    };
  } | null | undefined;
};
export type useUserValueMutation = {
  response: useUserValueMutation$data;
  variables: useUserValueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetUserValuePayload",
    "kind": "LinkedField",
    "name": "setUserValue",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserValue",
        "kind": "LinkedField",
        "name": "userValue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserValueMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserValueMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:xVBf-sInDocbtMVTPZtcN8zDyM6FXprhkJam8nlWagY",
    "metadata": {},
    "name": "useUserValueMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "45d96e0e9d2964dc95a3b2fe1a693406";

export default node;
