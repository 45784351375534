import { HourlyRateBids } from "@gigsmart/feature-flags";
import type { LinkingOptions } from "@gigsmart/kaizoku";
import { compact } from "lodash";
import type { WorkerParamList } from "./types";

export const createWorkerLinking = (): LinkingOptions<WorkerParamList> => ({
  enabled: true,
  prefixes: compact([
    `http://${process.env.UNIVERSAL_LINK_DOMAIN}`,
    `https://${process.env.UNIVERSAL_LINK_DOMAIN}`,
    `http://${process.env.FIREBASE_DEEPLINK_DOMAIN}`,
    `https://${process.env.FIREBASE_DEEPLINK_DOMAIN}`,
    process.env.URL_SCHEME
  ]),
  config: {
    initialRouteName: "Home",
    screens: {
      Home: {
        path: "",
        screens: {
          HomeTab: "",
          MyGigs: {
            path: "/:tab?",
            stringify: { affiliateJob: () => "undefined" }
          },
          Browse: "/browse",
          Wallet: "/wallet",
          Profile: "/profile"
        }
      },
      MessageCenter: "/message-center",
      Conversation: "/conversation/:topicId",
      ShiftGroupChats: "/shift-group-chats",
      Notifications: "/notifications",
      History: "/history/:tab?",
      Support: "/support",
      Legal: "/legal/*",
      About: "/about",
      Share: "/share",
      FeatureFlags: "/feature/debug",
      EnableFeatureFlags: "/feature/:flags/enable",
      DisableFeatureFlags: "/feature/:flags/disable",
      Signup: "/signup",
      Login: "/login",
      Logout: "/logout",
      ResetPassword: "/reset-password/:token",
      ForgotPassword: "/forgot-password",
      ResetPasswordSuccess: "/success-reset-password",
      Token: "/login/:token",
      JobDetailsRedirect: "/workers/how-it-works",
      PendingTimesheetApproval: "/pending-timesheet-approval",
      Onboarding: "/onboarding",
      ConfirmRegistration: "/confirm-registration/:token",
      RatingsAndReviews: "/profile/reviews",

      WorkerGigSeriesOffered: "/gigsr/:seriesId/offered",

      // Profile
      ProfilePublicView: "/profile/public-view",
      ProfileAboutMe: "/profile/aboutMe",
      ProfileCategories: "/profile/categories",
      ProfileQualifications: "/profile/qualifications",
      ProfileLicensesCertifications:
        "/profile/qualifications/licenses-certifications",
      ProfilePhysicalRequirements:
        "/profile/qualifications/physical-requirements",
      ProfileToolsEquipments: "/profile/qualifications/tools-equipment",
      ProfileAttire: "/profile/qualifications/attire",
      ProfileVehicle: "/profile/qualifications/vehicle-requirements",
      ProfileTravel: "/profile/qualifications/travel-delivery",
      ProfileCDL: "/profile/qualifications/commercial-drivers-license",
      ProfileExperience: "/profile/qualifications/experience",
      ProfileWorkHistory: "/profile/work-history/:workHistoryId?",
      ProfileEducation: "/profile/education/:educationId?",
      ProfileTransportation: "/profile/transportation-method",
      ProfileAddLanguage: "/profile/language/add",
      ProfileEditLanguage: "/profile/language/edit/:languageId",
      ProfileAddMilitary: "/profile/military/add",
      ProfileEditMilitary: "/profile/military/edit/:militaryId",
      ProfileAccount: "/profile/account",
      ProfileEmergencyContacts: "/profile/emergencyContacts",
      BlockedOrganizationManagement: "/profile/blocked-organizations",
      ProfilePositions:
        "/profile/categories/:workerCategoryId/positions/:categoryId",

      OrganizationSummary: "/organization/:orgId",
      OrganizationReviews: "/organization/:orgId/reviews",

      // gig routes
      OfferedGigs: HourlyRateBids.select(
        "/gigs/pending-offers",
        "/gigs/unread-offers"
      ),

      EngagementDetails: "/gigs/:id", // being used only as a fallback
      ShiftDetails: "/shifts/:id",
      NegotiateBid: "/shifts/:id/negotiate",
      ShiftRequesterProfile: "/shifts/:id/requester",

      ProjectDetails: "/projects/:id",
      ProjectRequesterProfile: "/projects/:id/requester",

      PendingTimesheets: "/gigs/pending-timesheets",
      EngagementAccept: "/gigs/:id/accept",
      ReviewTimesheet: "/gigs/:id/review-timesheet",
      DisputeTimesheet: "/gigs/:id/dispute-timesheet",
      EditTimesheet: "/gigs/:id/edit-timesheet",
      CreateTimesheet: "/gigs/:id/create-timesheet",
      CancelEngagement: "/gigs/:id/cancel",
      MarkProjectComplete: "/gigs/:id/mark-as-complete",
      ReviewRequester: "/gigs/:id/rate-and-review",

      BrowseShifts: "/browse/:type",
      BrowseShiftDetails: "/browse/:type/:id",
      BrowseShiftAction: "/browse/:type/:id/:action",
      BrowseProjects: "/browse/projects",
      BrowseProjectDetails: "/browse/projects/:id",
      BrowseJobs: "/browse/jobs",
      BrowseJobsIntro: "/browse/jobs/introduction",
      BrowseJobDetails: "/browse/jobs/:id",

      // combined
      BrowseGigRequester: "/browse/:type/:id/requester",
      BrowseGigPrimaryContact: "/browse/:type/:id/requester/primary-contact",
      BrowseGigReviews: "/browse/:type/:id/requester/reviews",

      // direct hire routes
      DirectPaymentsAndHires: "/direct-hire",
      AddDirectPaymentOrHire: "/direct-hire/add",
      DirectPaymentsAndHiresDetails: "/direct-hire/:id",

      WalletTransfer: "/wallet/transfer",
      LocationDebug: "/location-debug",
      AddPaymentMethod: "/wallet/payment",
      GetVerified: "/get-verified",

      NotFound: "*"
    }
  }
});
