/**
 * @generated SignedSource<<ac9ff55011eb8ac82a02b6592260030d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerPaymentSummaryRow_shift$data = {
  readonly acceptsTips: boolean;
  readonly id: string;
  readonly nextGig?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly payRate: string | null | undefined;
        readonly pickupEligible: boolean;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentRows_availableGig">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly payRate?: string | null | undefined;
  readonly pickupEligible?: boolean;
  readonly " $fragmentType": "WorkerPaymentSummaryRow_shift";
};
export type WorkerPaymentSummaryRow_shift$key = {
  readonly " $data"?: WorkerPaymentSummaryRow_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentSummaryRow_shift">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "payRate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "pickupEligible",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerPaymentSummaryRow_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptsTips",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "nextGig",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AvailableGigsConnection",
          "kind": "LinkedField",
          "name": "availableGigs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailableGigsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorkerPaymentRows_availableGig"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "availableGigs(first:1)"
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "b1b009895143298fdd54256356e2c60e";

export default node;
