/**
 * @generated SignedSource<<b832275049bb71a43e0ad47c0527bfe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftDescriptionHeader_availableGig$data = {
  readonly currentState?: {
    readonly action: EngagementStateAction;
    readonly name: EngagementStateName;
    readonly negotiation: {
      readonly payRate: string | null | undefined;
    } | null | undefined;
  };
  readonly estimatedPayment?: {
    readonly netPay: string;
    readonly payRate?: string;
  } | null | undefined;
  readonly gig?: {
    readonly payRate: string | null | undefined;
  };
  readonly id?: string;
  readonly node?: {
    readonly payRate: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ShiftDescriptionHeader_availableGig";
};
export type ShiftDescriptionHeader_availableGig$key = {
  readonly " $data"?: ShiftDescriptionHeader_availableGig$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionHeader_availableGig">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDescriptionHeader_availableGig",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "estimatedPayment",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AvailableGigsEdge",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementState",
          "kind": "LinkedField",
          "name": "currentState",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "action",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementNegotiation",
              "kind": "LinkedField",
              "name": "negotiation",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "estimatedPayment",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "Engagement",
      "abstractKey": null
    }
  ],
  "type": "AvailableGigOrEngagement",
  "abstractKey": "__isAvailableGigOrEngagement"
};
})();

(node as any).hash = "1c2b454a0c9daa7a11c45189fa09cb3d";

export default node;
