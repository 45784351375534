import { type ViewStyle, useStyles } from "@gigsmart/atorasu/style";
import React, { type ReactNode } from "react";
import { View } from "react-native";

interface Prop {
  testID?: string;
  children: ReactNode;
  style?: ViewStyle;
  contentStyle?: ViewStyle;
}

/** @deprecated Use responsive components. */
export default function WebContent({
  children,
  style,
  contentStyle,
  ...props
}: Prop) {
  const styles = useStyles(() => ({
    container: {
      flex: 1,
      alignItems: "center",
      "@phone": {
        alignItems: "flex-start",
        justifyContent: "flex-start"
      }
    },
    content: {
      flex: 1,
      width: "70%",
      "@tablet": {
        width: "80%"
      },
      "@phone": {
        width: "100%"
      }
    }
  }));
  return (
    <View style={[styles.container, style]} {...props}>
      <View style={[styles.content, contentStyle]}>{children}</View>
    </View>
  );
}
