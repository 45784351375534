import React from "react";

import { IconText, Spacer } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { PanelCard, StyledView } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import type { unpaidEngagements_rootQuery$key } from "./__generated__/unpaidEngagements_rootQuery.graphql";
import EngagementRow from "./engagement-row";
import ProjectEngagementRow from "./project-engagement-row";

interface Props {
  rootQuery: unpaidEngagements_rootQuery$key | null | undefined;
}

export default ({ rootQuery: rootQueryFragmentReference }: Props) => {
  const rootQuery = useRelayFragment(
    graphql`
      fragment unpaidEngagements_rootQuery on RootQueryType {
        viewer {
          ... on Worker {
            engagements(
              first: 20
              query: "WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID ORDER BY endsAt DESC"
            ) {
              edges {
                node {
                  id
                  endsAt
                  currentState {
                    name
                  }
                  gigType
                  ...engagementRowWorker_engagement
                  ...projectEngagementRowPayments_engagement
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    rootQueryFragmentReference
  );
  const totalCount = rootQuery?.viewer?.engagements?.totalCount ?? 0;
  const edges = rootQuery?.viewer?.engagements?.edges;
  if (totalCount === 0) return null;
  return (
    <StyledView verticalPadding={0} color="transparent">
      <PanelCard
        headerContent={
          <IconText icon="circle-info">
            {totalCount === 1
              ? "Your balance is from your last Shift"
              : `Your balance is from your last ${pluralize(
                  totalCount,
                  "Shifts"
                )}`}
          </IconText>
        }
      >
        <StyledView verticalPadding={0}>
          {edges?.map((edge, index) => {
            if (!edge?.node) return null;
            if (edge?.node?.gigType === "PROJECT")
              return (
                <ProjectEngagementRow
                  key={edge?.node?.id}
                  engagement={edge.node}
                  first={index === 0}
                />
              );
            return (
              <EngagementRow
                key={edge.node.id}
                engagement={edge.node}
                first={index === 0}
              />
            );
          })}
        </StyledView>
      </PanelCard>
      <Spacer />
    </StyledView>
  );
};
