/**
 * @generated SignedSource<<a5e5228b5d7a1e4ad37b7d042e43bf68>>
 * @relayHash 6667759d4e0307a945c5b0c46aada727
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:dFmj9QJ9LXZbbXarPv7rHtsFjPDP0C-mPi2Q-1U_VsY

import { ConcreteRequest } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type shiftGigsMapHooksPinsQuery$variables = {
  after?: string | null | undefined;
  count: number;
  gigTypes?: ReadonlyArray<GigType> | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance: number;
  pickupEligible: boolean;
  searchTerm?: string | null | undefined;
};
export type shiftGigsMapHooksPinsQuery$data = {
  readonly viewer: {
    readonly availableGigs?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly approximateLocation: {
            readonly latitude: number;
            readonly longitude: number;
          } | null | undefined;
          readonly gigSeries: {
            readonly id: string;
          };
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
      };
    } | null | undefined;
  } | null | undefined;
};
export type shiftGigsMapHooksPinsQuery = {
  response: shiftGigsMapHooksPinsQuery$data;
  variables: shiftGigsMapHooksPinsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gigTypes"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "latitude"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "longitude"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDistance"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pickupEligible"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "gigTypes",
          "variableName": "gigTypes"
        },
        {
          "kind": "Variable",
          "name": "latitude",
          "variableName": "latitude"
        },
        {
          "kind": "Variable",
          "name": "longitude",
          "variableName": "longitude"
        },
        {
          "kind": "Variable",
          "name": "maxDistance",
          "variableName": "maxDistance"
        },
        {
          "kind": "Variable",
          "name": "pickupEligible",
          "variableName": "pickupEligible"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "availableGigs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableGigsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Location",
                  "kind": "LinkedField",
                  "name": "approximateLocation",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latitude",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "longitude",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigSeries",
                  "kind": "LinkedField",
                  "name": "gigSeries",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "shiftGigsMapHooksPinsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "shiftGigsMapHooksPinsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v9/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:dFmj9QJ9LXZbbXarPv7rHtsFjPDP0C-mPi2Q-1U_VsY",
    "metadata": {},
    "name": "shiftGigsMapHooksPinsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6032882181719d1cc91435a5fe23d5df";

export default node;
