/**
 * @generated SignedSource<<673ca975bf5779cf11917ce922b87e90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerGigCard_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly estimatedPayment: {
    readonly netPay: string;
  } | null | undefined;
  readonly gig: {
    readonly area: string | null | undefined;
    readonly endsAt: string | null | undefined;
    readonly id: string;
    readonly insertedAt: string;
    readonly name: string | null | undefined;
    readonly payRate: string | null | undefined;
    readonly position: {
      readonly name: string;
    } | null | undefined;
    readonly skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly skill: {
            readonly name: string;
          };
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly startsAt: string | null | undefined;
  };
  readonly paymentInfo: {
    readonly netPay: string;
  } | null | undefined;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentType": "WorkerGigCard_engagement";
};
export type WorkerGigCard_engagement$key = {
  readonly " $data"?: WorkerGigCard_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerGigCard_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPay",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerGigCard_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "estimatedPayment",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "insertedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigPosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "GigSkillsConnection",
          "kind": "LinkedField",
          "name": "skills",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSkillsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigSkill",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "skill",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "skills(first:1)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "c71002ae074b82e95966e4682fb267f6";

export default node;
