import { useEventer } from "@gigsmart/dekigoto";
import React from "react";
import { TouchableOpacity, View } from "react-native";

import { type IconName, StyledIcon } from "../icon";
import { type ViewStyleProp, theme, useStyles } from "../style";
import { KatanaText } from "../text";

export interface TileType {
  key?: string;
  icon: IconName;
  label: string;
  testID?: string;
  hidden?: boolean;
  disabled?: boolean;
  onPress?: (arg0?: string) => unknown | null | undefined;
  link?: string;
  linkState?: any;
}

interface Props {
  containerStyle?: ViewStyleProp;
  testID?: string;
  tile: TileType;
  onPress?: (tile: TileType) => void;
}

const TiledIcon = ({ containerStyle, testID, tile, onPress }: Props) => {
  const trackPress = useEventer("Pressed", tile.label, "Icon Button");
  const color = tile.disabled ? theme.color.neutralLight : theme.color.blue;
  const { styles } = useStyles(({ font, units }) => ({
    container: {
      alignItems: "center"
    },
    content: {
      paddingHorizontal: units(1.5),
      paddingVertical: units(4),
      alignItems: "center",
      minWidth: 90
    },
    icon: {
      fontSize: 44,
      paddingTop: units(1),
      paddingBottom: units(4),
      minHeight: 60,
      textAlign: "center",
      "@phone": { fontSize: 36 },
      "@tablet": { fontSize: 36 }
    },
    label: {
      ...font.withSize(font.size.small),
      ...font.body("bold"),
      textAlign: "center"
    }
  }));

  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity
        testID={testID}
        style={styles.content}
        disabled={tile.disabled}
        onPress={() => {
          trackPress();
          if (onPress) onPress(tile);
          else if (tile.onPress) tile.onPress(tile.link);
        }}
      >
        <StyledIcon style={[styles.icon, { color }]} name={tile.icon} />
        <KatanaText style={[styles.label, { color }]} numberOfLines={3}>
          {tile.label}
        </KatanaText>
      </TouchableOpacity>
    </View>
  );
};

export default TiledIcon;
