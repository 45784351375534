import React, { type ComponentType } from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../style";

export type ContentTypeVariant =
  | "default"
  | "md"
  | "xs"
  | "xsPhone"
  | "footer"
  | "inset"
  | "cardInset";

interface Props {
  variant?: ContentTypeVariant;
  component?: ComponentType<any>;
  style?: ViewStyleProp;
}

export default function ContentWrapper<P extends object>({
  component: Component = View,
  variant = "default" as const,
  style,
  ...props
}: Props & P) {
  const { styles } = useStyles(({ units, metric }) => ({
    default: {},
    md: {
      width: "100%",
      maxWidth: units(192),
      marginHorizontal: "auto",
      "@phoneDown": {
        maxWidth: undefined
      }
    },
    xs: {
      width: "100%",
      maxWidth: units(100),
      marginHorizontal: "auto",
      "@phoneDown": {
        maxWidth: undefined
      }
    },
    xsPhone: {
      maxWidth: units(100),
      marginHorizontal: "auto"
    },
    footer: {
      paddingTop: units(4),
      paddingHorizontal: units(4),
      paddingBottom: Math.max(metric.bottomSpace(), units(4))
    },
    inset: {
      padding: units(4)
    },
    cardInset: {
      padding: 20
    }
  }));

  return (
    <Component style={[styles[variant] as ViewStyleProp, style]} {...props} />
  );
}
