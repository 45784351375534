import {
  Button,
  Column,
  Constraint,
  ContentArea,
  ScreenTop,
  StepSubmit,
  Surface,
  Text,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Validator } from "@gigsmart/fomu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import FomuPasswordInput from "@gigsmart/seibutsu/fomu/inputs/FomuPasswordInput";
import Screen from "@gigsmart/seibutsu/layouts/Screen";
import SurfaceFormScreen from "@gigsmart/seibutsu/layouts/SurfaceFormScreen";
import React, { useState } from "react";
import { useAppSettings } from "../app/app-settings";
import type { resetPasswordMutation } from "./__generated__/resetPasswordMutation.graphql";
import type { AuthParamList } from "./types";

type Props = AppScreenProps<AuthParamList, "ResetPassword">;

export default function ResetPassword({ navigation, route }: Props) {
  const { appId } = useAppSettings();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [commit] = useRelayMutation<resetPasswordMutation>(graphql`
    mutation resetPasswordMutation($input: ResetUserPasswordInput!) {
      resetUserPassword(input: $input) {
        ok
      }
    }
  `);
  const handleSubmit: FomuSubmitFn = ({ values }) => {
    if (appId !== "requester" && appId !== "worker") {
      return;
    }

    const token = route.params.token ?? "";
    void commit(
      {
        input: {
          token,
          password: values.password,
          userType: appId === "requester" ? "REQUESTER" : "WORKER"
        }
      },
      { onSuccess: handleSuccess }
    );
  };

  const handleOnReturnHomePress = () => {
    navigation.navigate("Home");
  };

  const handleSuccess = () => {
    toast.success("Password successfully reset");
    setResetSuccess(true);
  };

  if (resetSuccess) {
    return (
      <Screen testID="reset-password-screen" constraint="small">
        <ScreenTop showBackButton={false} />
        <Surface>
          <ContentArea size="xlarge" gap="large">
            <Column gap="compact">
              <Text
                color="primary"
                variant="title"
                weight="semibold"
                align="center"
              >
                Your password has been updated.
              </Text>
              <Text align="center">
                Please return to the Sign In Page to proceed.
              </Text>
            </Column>
            <Constraint size="xsmall">
              <Button
                testID="reset-password-success-btn"
                label="Go Home"
                onPress={handleOnReturnHomePress}
              />
            </Constraint>
          </ContentArea>
        </Surface>
      </Screen>
    );
  }

  return (
    <SurfaceFormScreen
      testID="reset-password-screen"
      title="Reset Password"
      titleIcon="lock-keyhole"
      onSubmit={handleSubmit}
      gap="standard"
      showBackButton={false}
    >
      <FomuPasswordInput
        name="password"
        label="Password"
        validates={Validator.length({ min: 8 })}
      />
      <FomuPasswordInput
        name="passwordConfirmation"
        label="Confirm Password"
        validates={Validator.confirmationOf({ field: "password" })}
      />
      <StepSubmit
        testID="reset-password-submit-btn"
        label="Save New Password"
      />
    </SurfaceFormScreen>
  );
}
