import {
  Card,
  ContentArea,
  DismissableCardHeader,
  Spacer
} from "@gigsmart/atorasu";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import React, { type ReactNode } from "react";

interface Props {
  userValueKey: string;
  children: ReactNode;
  testID: string;
}

export default function DismissableCard({
  userValueKey,
  children,
  testID
}: Props) {
  const [cardDismissed, setCardDismissed] = useUserValue(userValueKey);
  if (cardDismissed) return null;
  return (
    <ContentArea size="none">
      <Card testID="dismissable-card" eventTargetName="Dismissable Card">
        <DismissableCardHeader
          testID={testID}
          onDismiss={async () => await setCardDismissed(true)}
        />
        <ContentArea size="none">
          {children}
          <Spacer />
        </ContentArea>
      </Card>
    </ContentArea>
  );
}
