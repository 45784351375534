import {
  Card,
  Constraint,
  ContentArea,
  InfoSquare,
  Stack
} from "@gigsmart/atorasu";
import { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import React from "react";
import WorkerGigCardGigInfo from "./WorkerGigCardGigInfo";
import WorkerGigCardTitle from "./WorkerGigCardTitle";
import type { WorkerGigCard_availableGigSeriesEdge$key } from "./__generated__/WorkerGigCard_availableGigSeriesEdge.graphql";
import type {
  WorkerGigCard_engagement$data,
  WorkerGigCard_engagement$key
} from "./__generated__/WorkerGigCard_engagement.graphql";

interface StandaloneProps {
  onPress?: () => void;
}

interface Props
  extends NonNullable<ObjectUtils.Path<WorkerGigCard_engagement$data, ["gig"]>>,
    StandaloneProps {
  distance?: number | { max?: number; min?: number } | null;
  minPay?: string | null;
  maxPay?: string | null;
  isMultiShift?: boolean;
  currentState?: ObjectUtils.Path<
    WorkerGigCard_engagement$data,
    ["currentState"]
  >;
  nestedFooterVisible?: boolean;
}

const INFO_SQUARE_STATES = [
  "APPLIED",
  "CONFIRMING",
  "SCHEDULED",
  "RUNNING_LATE",
  "PENDING_TIMESHEET_APPROVAL",
  "ENDED"
];

export const WorkerGigCard = ({
  id,
  name,
  skills,
  position,
  area,
  payRate,
  distance,
  minPay,
  maxPay,
  isMultiShift,
  startsAt,
  endsAt,
  insertedAt,
  currentState,
  onPress,
  nestedFooterVisible
}: Props) => {
  const showInfo =
    !currentState || INFO_SQUARE_STATES.includes(currentState.name);
  return (
    <Constraint size="xsmall">
      <Card
        onPress={onPress}
        testID={`worker-gig-card-${id}`}
        eventTargetName="Worker Gig Card"
        hideInnerMargin={!nestedFooterVisible}
      >
        <ContentArea>
          <Stack>
            <WorkerGigCardTitle id={id} name={name} />
            <Stack horizontal size="compact" alignItems="center">
              {showInfo ? (
                <InfoSquare
                  header={
                    currentState?.name === "PENDING_TIMESHEET_APPROVAL" ||
                    currentState?.name === "ENDED"
                      ? "Total Earnings"
                      : "Estimated Pay"
                  }
                  content={
                    currentState?.name === "PENDING_TIMESHEET_APPROVAL"
                      ? "TBD"
                      : gigHelpers.formatPayRange(
                          { min: minPay, max: maxPay },
                          { floor: true }
                        )
                  }
                  footer={currency.humanizeRate(payRate, "hr")}
                />
              ) : (
                <InfoSquare placeholder />
              )}
              <WorkerGigCardGigInfo
                id={id}
                skill={skills?.edges?.[0]?.node?.skill?.name}
                position={position?.name}
                area={area}
                skillsCount={skills?.totalCount}
                distance={distance}
                isMultiShift={isMultiShift}
                startsAt={startsAt}
                endsAt={endsAt}
                insertedAt={insertedAt}
              />
            </Stack>
          </Stack>
        </ContentArea>
      </Card>
    </Constraint>
  );
};

export const WorkerGigCardGigContainer = createRelayFragmentContainer<
  WorkerGigCard_engagement$key,
  StandaloneProps
>(
  graphql`
    fragment WorkerGigCard_engagement on Engagement {
      estimatedPayment {
        netPay
      }
      paymentInfo {
        netPay
      }
      currentState {
        name
      }
      workerDistance
      gig {
        id
        name
        area
        payRate
        startsAt
        endsAt
        insertedAt
        position {
          name
        }
        skills(first: 1) {
          totalCount
          edges {
            node {
              skill {
                name
              }
            }
          }
        }
      }
    }
  `,
  ({
    gig,
    workerDistance,
    estimatedPayment,
    paymentInfo,
    currentState,
    onPress
  }) => (
    <WorkerGigCard
      {...gig}
      currentState={currentState}
      distance={workerDistance}
      minPay={
        currentState?.name === "ENDED"
          ? paymentInfo?.netPay
          : estimatedPayment?.netPay
      }
      onPress={onPress}
      isMultiShift={false}
    />
  )
);

export const WorkerGigCardAvailableGigsEdgeContainer =
  createRelayFragmentContainer<
    WorkerGigCard_availableGigSeriesEdge$key,
    StandaloneProps
  >(
    graphql`
      fragment WorkerGigCard_availableGigSeriesEdge on AvailableGigSeriesEdge {
        estimatedPaymentRange {
          min: minAmount
          max: maxAmount
        }
        gigDistance {
          max: maxDistance
          min: minDistance
        }
        node {
          id
          name
          area
          payRate
          startsAt
          endsAt
          insertedAt
          position {
            name
          }
          availableGigs(first: 1) {
            totalCount
            edges {
              node {
                startsAt
                endsAt
                insertedAt
              }
            }
          }
          skills(first: 1) {
            totalCount
            edges {
              node {
                skill {
                  name
                }
              }
            }
          }
        }
      }
    `,
    ({ node, gigDistance, estimatedPaymentRange, onPress }) =>
      node ? (
        <WorkerGigCard
          {...node}
          distance={gigDistance}
          minPay={estimatedPaymentRange?.min}
          maxPay={estimatedPaymentRange?.max}
          startsAt={
            node?.availableGigs?.edges?.[0]?.node?.startsAt ?? node?.startsAt
          }
          endsAt={
            node?.availableGigs?.edges?.[0]?.node?.startsAt ?? node?.endsAt
          }
          insertedAt={
            node?.availableGigs?.edges?.[0]?.node?.startsAt ?? node?.insertedAt
          }
          isMultiShift={(node?.availableGigs?.totalCount ?? 0) > 1}
          onPress={onPress}
        />
      ) : null
  );
