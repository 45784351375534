import React, { useState } from "react";

import { getDateStringWithSuffix } from "@gigsmart/isomorphic-shared/iso/time";

import StyledModal from "../../container/styled-modal";
import { StyledIcon } from "../../icon";
import { Spacer } from "../../spacer";
import { useViewport } from "../../style";
import { SolidButton } from "../../tappable";
import DatePickerInput from "../date-picker-input";
import StyledTextInput from "../styled-text-input";

interface Props {
  placeholder?: string;
  value?: string;
  onValueChange?: (arg: string | undefined) => void;
  min?: string;
  max?: string;
  testID?: string;
}

const DateCalendarPicker = ({
  placeholder,
  value,
  max,
  min,
  onValueChange,
  testID
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [curr, setCurr] = useState(value);
  const [selected, setSelected] = useState(curr ? [curr] : undefined);

  const { isMobile } = useViewport();

  const renderCalendarIcon = () => {
    return (
      <>
        <Spacer direction="row" units={3} />
        <StyledIcon variant="solid" name="calendar-days" size={14} />
      </>
    );
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const clearAndClose = () => {
    setSelected(curr ? [curr] : undefined);
    closeModal();
  };

  const onContinue = () => {
    const currentValue =
      selected && selected.length > 0 ? selected[0] : undefined;
    setCurr(currentValue);
    onValueChange?.(currentValue);
    closeModal();
  };

  const formattedValue = curr ? getDateStringWithSuffix(curr) : undefined;

  return (
    <>
      <StyledTextInput
        placeholder={placeholder ?? ""}
        value={formattedValue}
        editable={false}
        pointerEvents="none"
        eventTargetName=""
        renderLeftAccessory={renderCalendarIcon}
        customPressFunction={openModal}
        testID={testID}
      />
      <StyledModal
        eventContext="Calendar Picker Modal"
        visible={showModal}
        hasCancelIcon
        onClose={clearAndClose}
      >
        {isMobile && <Spacer />}
        <DatePickerInput
          value={selected}
          max={max}
          min={min}
          onChange={setSelected}
        />
        <Spacer units={8} />
        <SolidButton
          title="Continue"
          onPress={onContinue}
          testID="continue"
          disabled={!selected}
        />
      </StyledModal>
    </>
  );
};

export default DateCalendarPicker;
