/**
 * @generated SignedSource<<4bcf786069ae580c5c3d7b0769ebb581>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type detailsCard_jobPosting$data = {
  readonly boosted: boolean | null | undefined;
  readonly maximumPayRate: string | null | undefined;
  readonly minimumPayRate: string | null | undefined;
  readonly payRateNegotiable: boolean | null | undefined;
  readonly paySchedule: JobPostingPaySchedule | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly title: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"applyButtons_jobPosting">;
  readonly " $fragmentType": "detailsCard_jobPosting";
};
export type detailsCard_jobPosting$key = {
  readonly " $data"?: detailsCard_jobPosting$data;
  readonly " $fragmentSpreads": FragmentRefs<"detailsCard_jobPosting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "detailsCard_jobPosting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boosted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRateNegotiable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paySchedule",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumPayRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximumPayRate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "applyButtons_jobPosting"
    }
  ],
  "type": "JobPosting",
  "abstractKey": null
};

(node as any).hash = "ac756bf2b2ae4d5b7b17f2f6a7729be9";

export default node;
