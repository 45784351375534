import { PressableRatingStars, Row, Stack, Text } from "@gigsmart/atorasu";
import { type FormFieldOptions, Validator, useFormField } from "@gigsmart/fomu";
import React from "react";

export interface Props extends FormFieldOptions<string> {
  displayName: string;
}

export default function FomuRatingStarsInput({
  name,
  displayName,
  ...restProps
}: Props) {
  const { value, setValue } = useFormField<number>({
    name: "rating",
    validates: Validator.presence()
  });

  return (
    <Stack testID={`${name}-rating-stars-input`}>
      <Row justifyContent="center">
        <Text variant="subheader" color="textPrimary">
          Please provide a rating for {displayName}
        </Text>
      </Row>
      <Row justifyContent="center">
        <PressableRatingStars
          value={value}
          onChange={setValue}
          {...restProps}
        />
      </Row>
    </Stack>
  );
}
