/**
 * @generated SignedSource<<a3411d2a69fff24523e9f84bbf04914a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectAppliedHeader_engagement$data = {
  readonly gig: {
    readonly " $fragmentSpreads": FragmentRefs<"requesterThumbnailRow_gig">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"activeEngagementActions_engagement">;
  readonly " $fragmentType": "projectAppliedHeader_engagement";
};
export type projectAppliedHeader_engagement$key = {
  readonly " $data"?: projectAppliedHeader_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectAppliedHeader_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectAppliedHeader_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "requesterThumbnailRow_gig"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "activeEngagementActions_engagement"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "78611805c4c125f907cffbb383e3c9d8";

export default node;
