import {
  Button,
  Column,
  ContentArea,
  Divider,
  FancyHeader,
  GridNull,
  Linking,
  List,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { BrandedHoorayAd, GenericHoorayAd } from "@gigsmart/feature-flags";
import React from "react";
import CoBrandedHealthcareSvg from "./svgs/CoBrandedHealthcareSvg";
import HealthcareSvg from "./svgs/HealthcareSvg";

type Props = {
  isDetails?: boolean;
  inset?: boolean;
};

export default function HealthcareAdCard(props: Props) {
  const Component = GenericHoorayAd.isEnabled()
    ? GenericAd
    : BrandedHoorayAd.isEnabled()
      ? BrandedAd
      : null;

  if (!Component) return <GridNull />;
  return <Component {...props} />;
}

function GenericAd({ isDetails, inset }: Props) {
  return (
    <Surface
      overflow="hidden"
      {...(inset && { topRadius: "slim", bottomRadius: "slim" })}
    >
      <FancyHeader
        title={
          isDetails
            ? "Enhance Your Healthcare Starting at $40"
            : "Get Healthcare Coverage"
        }
        icon="book-medical"
      />
      <ContentArea>
        <Stack>
          {!isDetails && (
            <Column alignSelf="center">
              <HealthcareSvg />
            </Column>
          )}
          {!isDetails && (
            <Text color="primary" weight="bold">
              Enhance Your Healthcare Starting at $40
            </Text>
          )}
          {isDetails ? (
            <Text>
              GigSmart has joined forces with leading healthcare providers so
              you can tackle common illnesses and accidents more confidently.
              Plans start at $40 per month. Try for 30 days worry-free with a
              full refund, if no claims have been filed. Cancel anytime.
            </Text>
          ) : (
            <Text>
              GigSmart has joined forces with leading healthcare providers so
              you can tackle common illnesses and accidents more confidently.
              Try for 30 days worry-free with a full refund* and cancel anytime!
            </Text>
          )}
          {!isDetails && (
            <Stack>
              <Text>
                <Text weight="bold">Starting at $40 a month</Text>, you get:
              </Text>
              <List variant="circle-check" size="compact">
                <Text>$0 cost telemedicine</Text>
                <Text>$25 per visit care centers</Text>
                <Text>Savings on many medications</Text>
                <Text>Up to $7,500 coverage per accidental injury</Text>
              </List>
            </Stack>
          )}
          {!isDetails && (
            <ContentArea size="small">
              <Text variant="note" align="center" color="neutral">
                *Available if no claims have been filed.
              </Text>
            </ContentArea>
          )}
        </Stack>
      </ContentArea>
      <Divider />
      <Button
        size="small"
        variant="clear"
        testID="hooray-ad-btn"
        label="Get Started"
        icon="external-link-alt"
        onPress={() =>
          Linking.openURL(
            isDetails
              ? "https://hubs.li/Q02KQgQB0"
              : "https://hubs.li/Q02KQgKC0"
          )
        }
      />
    </Surface>
  );
}

function BrandedAd({ isDetails, inset }: Props) {
  return (
    <Surface
      overflow="hidden"
      {...(inset && { topRadius: "slim", bottomRadius: "slim" })}
    >
      {isDetails ? (
        <FancyHeader
          title="Enhance Your Healthcare for $40 & Up"
          icon="book-medical"
        />
      ) : (
        <CoBrandedHealthcareSvg />
      )}
      <ContentArea>
        <Stack>
          {!isDetails && (
            <Text color="primary" weight="bold">
              Enhance Your Healthcare Starting at $40
            </Text>
          )}
          {!isDetails ? (
            <Text>
              GigSmart has joined forces with Hooray Health so you can tackle
              common illnesses and accidents more confidently. Try for 30 days
              worry-free with a full refund* and cancel anytime!
            </Text>
          ) : (
            <Text>
              GigSmart has joined forces with Hooray Health so you can tackle
              common illnesses and accidents more confidently. Try for 30 days
              worry-free with a full refund, if no claims have been filed.
              Cancel anytime.
            </Text>
          )}
          {!isDetails && (
            <Stack>
              <Text>
                <Text weight="bold">Starting at $40 a month</Text>, you get:
              </Text>
              <List variant="circle-check" size="compact">
                <Text>$0 cost telemedicine</Text>
                <Text>$25 per visit care centers</Text>
                <Text>Savings on many medications</Text>
                <Text>Up to $7,500 coverage per accidental injury</Text>
              </List>
            </Stack>
          )}
          {!isDetails && (
            <ContentArea size="small">
              <Text variant="note" align="center" color="neutral">
                *Available if no claims have been filed.
              </Text>
            </ContentArea>
          )}
        </Stack>
      </ContentArea>
      <Divider />
      <Button
        size="small"
        variant="clear"
        testID="hooray-ad-btn"
        label="Get Started"
        icon="external-link-alt"
        onPress={() =>
          Linking.openURL(
            isDetails
              ? "https://hubs.li/Q02KQgQ80"
              : "https://hubs.li/Q02KQgPn0"
          )
        }
      />
    </Surface>
  );
}
