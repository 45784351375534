import { Divider, Spacer, Stack, Text, toast } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { where } from "@gigsmart/biruda";
import { HourlyRateBids, WorkerShiftCardUI } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import type { EngagementStateAction } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type AppNavigationProp,
  type ParamListBase,
  useNavigation
} from "@gigsmart/kaizoku";
import { PromptModal, confirmPrompt } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import WorkerShiftCardBody from "@gigsmart/seibutsu/gig-like/WorkerShiftCard/WorkerShiftCardBody";
import { showWorkerHiredModal } from "@gigsmart/seibutsu/worker/WorkerHiredModal";
import { sortBy, uniq } from "lodash";
import React, { useState } from "react";
import { View } from "react-native";
import EngagementActionTable from "../../gig/engagement-action-table";
import usePendingOfferCount from "../../gig/use-pending-offer-count";
import SeriesEngagementContent from "../cards/series-engagement-content";
import { offeredSeriesEngagementsQuery } from "../series-queries";
import type { seriesEngagementsActionsMutation } from "./__generated__/seriesEngagementsActionsMutation.graphql";
import type { seriesEngagementsActionsQuery } from "./__generated__/seriesEngagementsActionsQuery.graphql";

interface Props {
  seriesId: string;
  workerId: string;
  positionId: string;
  onCancel?: () => void;
  onConfirm?: (
    engagementsToAccept?: string[],
    allEngagementsRejected?: boolean
  ) => void;
  // config
  actions?: gigHelpers.EngagementStateAction[];
  lockedAction?: gigHelpers.EngagementStateAction;
  lockedMessage?: string;
  conflictingAction?: gigHelpers.EngagementStateAction;
  hintText?: string;
  /**
   * If a worker needs enrollment, cannot transition engagements to accept using this modal.
   */
  needsEnrollment?: boolean;
}

export default createSuspendedQueryContainer<
  seriesEngagementsActionsQuery,
  Props
>(
  function SeriesEngagementsActions({
    onCancel,
    onConfirm,
    actions = ["ACCEPT", "REJECT"],
    lockedMessage = "Shift rejected due to scheduling conflict",
    lockedAction = "REJECT",
    conflictingAction = "ACCEPT",
    hintText = "Accept or reject all Shifts before submitting your availability",
    needsEnrollment = false,
    retry,
    response
  }) {
    const { series = null, viewer = null } = response ?? {};
    const navigation = useNavigation<AppNavigationProp<ParamListBase>>();
    const styles = useStyles(({ getUnits }) => ({
      hintText: { marginBottom: getUnits(2), marginTop: -getUnits(3) }
    }));
    const { totalPendingOffers } = usePendingOfferCount(viewer);
    const [transitionEngagements, { loading }] =
      useRelayMutationPromise<seriesEngagementsActionsMutation>(graphql`
        mutation seriesEngagementsActionsMutation(
          $input: TransitionEngagementsInput!
        ) {
          transitionEngagements(input: $input) {
            engagements {
              id
              currentState {
                name
              }
              gig {
                gigType
                startsAt
              }
            }
            errors {
              message
            }
          }
        }
      `);

    const engagement = series?.nextEngagement?.edges?.[0]?.node;
    const shiftCount = series?.nextEngagement?.totalCount ?? 0;
    const [selected, setSelected] = useState<
      Record<string, gigHelpers.EngagementStateAction>
    >({});
    const [showHint, setShowHint] = useState(false);
    const [disabled, setDisabled] = useState(true);

    if (!series || !engagement) return null;

    const hasPosition =
      viewer?.positions?.edges?.[0]?.node?.status === "CONFIRMED";

    const requesterName = series.requester?.displayName ?? "Requester";
    const needsMoreInfo = needsEnrollment || !hasPosition;

    const handleSelectedChange = (
      selected: Record<string, gigHelpers.EngagementStateAction>
    ) => {
      setSelected(selected);
      setShowHint(false);
      setDisabled(
        shiftCount === 0 || Object.keys(selected).length < shiftCount
      );
    };
    const handleDisabled = () => setShowHint(true);
    const handleSubmit = () => {
      confirmPrompt({
        title: "Submit Availability",
        subTitle:
          "Are you sure you want to submit your availability? Once you submit availability you cannot update your response for the shifts.",
        onDo: handleActions,
        yesLabel: "Yes, Submit",
        cancelLabel: "No, Cancel"
      });
    };

    const handleActions = async () => {
      if (disabled) return;
      const allErrors: Array<{
        action: EngagementStateAction;
        message: string;
      }> = [];

      // Bulk action order matter
      const reverseActions = [...actions].reverse();
      for (const action of reverseActions) {
        // will be accepted elsewhere
        if (needsMoreInfo && action === "ACCEPT") continue;
        try {
          const engagementIds: string[] = Object.keys(selected).filter(
            (engagementId) => selected[engagementId] === action
          );
          if (engagementIds.length) {
            const res = await transitionEngagements({
              input: { action, engagementIds }
            });
            if (action === "REJECT" && engagementIds.length) {
              if (totalPendingOffers - Object.keys(selected)?.length > 0) {
                navigation.replace("OfferedGigs", {
                  gigType: HourlyRateBids.select(
                    engagement.gig?.gigType === "PAID" ? "SHIFT" : "PROJECT",
                    "ALL"
                  )
                });
              }
            }
            const next = sortBy(
              res.transitionEngagements?.engagements ?? [],
              (it) => it.gig.startsAt ?? ""
            )[0];
            if (next) {
              const isConfirming = res.transitionEngagements?.engagements.find(
                (it) => it?.currentState?.name === "CONFIRMING"
              );
              const nextSceneState = isConfirming ? "CONFIRMING" : "SCHEDULED";
              if (
                action === "REJECT" &&
                totalPendingOffers - Object.keys(selected)?.length > 0
              ) {
                navigation.replace("OfferedGigs", {
                  gigType: HourlyRateBids.select(
                    engagement.gig?.gigType === "PAID" ? "SHIFT" : "PROJECT",
                    "ALL"
                  )
                });
              } else {
                setTimeout(() => {
                  showWorkerHiredModal({
                    engagementId: next.id,
                    shiftCount: res.transitionEngagements?.engagements.length
                  });
                }, 500);
              }
            }
            res.transitionEngagements?.errors.forEach((err) => {
              if (err.message) allErrors.push({ action, message: err.message });
            });
            void retry();
          }
        } catch (err: any) {
          if (err.message) allErrors.push({ action, message: err.message });
        }
      }

      const allEngagementsRejected = Object.keys(selected).every(
        (engagementId) => selected[engagementId] === "REJECT"
      );
      if (onConfirm) {
        onConfirm(
          needsMoreInfo
            ? Object.keys(selected).filter((id) => selected[id] === "ACCEPT")
            : undefined,
          allEngagementsRejected
        );
      }

      setTimeout(() => {
        if (!needsMoreInfo || allEngagementsRejected) {
          toast.success("Confirmed Shift Availability!");
        }
        if (allErrors.length) {
          const message = uniq(allErrors.map((it) => it.message)).join(", ");
          toast.error(
            `There are some issues transitioning your shifts: ${message}`
          );
        }
      }, 300);
    };

    const topContent = (
      <>
        <Spacer size="medium" />
        <Stack size="medium">
          <Divider />
          {WorkerShiftCardUI.isEnabled() ? (
            <WorkerShiftCardBody
              variant="confirm-modal"
              fragmentRef={engagement.gig}
              boosted={series.boosted}
              distance={engagement.workerDistance}
              shiftCount={shiftCount}
            />
          ) : (
            <SeriesEngagementContent
              hasMultipleLocations={series.hasMultipleLocations}
              fragmentRef={engagement}
            />
          )}
          <Divider />
        </Stack>
        <Spacer />
      </>
    );

    return (
      <PromptModal
        eventContext="Confirm Availability"
        title="Confirm Availability"
        subTitle={`${requesterName} has offered you ${pluralize(
          shiftCount,
          "Shift"
        )}. Select which Shift you would like to accept or reject.`}
        visible
        fixedHeight
        variant="shadow"
        onClose={onCancel}
        topContent={topContent}
        actionsContent={
          showHint && (
            <View style={styles.hintText}>
              <Text variant="note" color="danger" align="center">
                {hintText}
              </Text>
            </View>
          )
        }
        actions={[
          {
            title: "Submit",
            variant: "solid",
            disabled: loading || disabled,
            onPress: handleSubmit,
            onPressDisabled: loading ? undefined : handleDisabled
          }
        ]}
      >
        <EngagementActionTable
          actions={actions}
          conflictingAction={conflictingAction}
          lockedAction={lockedAction}
          lockedMessage={lockedMessage}
          seriesRef={series}
          hasMultipleLocations={series.hasMultipleLocations}
          selected={selected}
          onChange={handleSelectedChange}
        />
      </PromptModal>
    );
  },
  {
    query: graphql`
      query seriesEngagementsActionsQuery(
        $id: ID!
        $engagementsQuery: String!
        $positionsQuery: String!
      ) {
        viewer {
          ...usePendingOfferCount_worker
          ... on Worker {
            positions(first: 1, query: $positionsQuery) {
              edges {
                node {
                  id
                  status
                }
              }
            }
          }
        }
        series: node(id: $id) {
          ... on GigSeries {
            ...engagementActionTable_series @arguments(query: $engagementsQuery)
            boosted
            hasMultipleLocations
            requester {
              displayName
            }
            nextEngagement: engagements(first: 1, query: $engagementsQuery) {
              edges {
                node {
                  ...seriesEngagementContent_engagement
                  workerDistance
                  gig {
                    ...WorkerShiftCardBody_gig
                    gigType
                  }
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    variables: ({ workerId, seriesId, positionId }) => {
      const engagementsQuery = offeredSeriesEngagementsQuery(workerId);
      return {
        id: seriesId,
        engagementsQuery,
        positionsQuery: where({ gigPositionId: positionId }).toString()
      };
    }
  }
);
