import type { AppScreenProps } from "@gigsmart/kaizoku";
import type { SharedParamList } from "@gigsmart/seibutsu/navigation/types";
import React from "react";
import FlagSet from "./FlagsSet";

type Props = AppScreenProps<SharedParamList, "DisableFeatureFlags">;

export default function DisableFeatureFlags({
  route: {
    params: { flags }
  }
}: Props) {
  return <FlagSet flags={flags.split(",")} enable={false} />;
}
