import { graphql, readRelayInlineFragment } from "@gigsmart/relay";
import React, { type ReactElement } from "react";
import type {
  EngagementCapabilityType,
  WithEngagementCapability_engagement$data,
  WithEngagementCapability_engagement$key
} from "./__generated__/WithEngagementCapability_engagement.graphql";

export interface CapabilityType {
  available: boolean;
  restricted: boolean;
  restrictedBy?: string;
  expiresAt: string | null | undefined;
}

export type EngagementCapabilityProps =
  | WithEngagementCapability_engagement$key
  | null
  | undefined;

export function readEngagementCapability(
  ref:
    | WithEngagementCapability_engagement$key
    | WithEngagementCapability_engagement$data["capabilities"]
    | null
    | undefined,
  type: EngagementCapabilityType | EngagementCapabilityType[]
) {
  if (typeof type === "string") type = [type];
  let capabilities: WithEngagementCapability_engagement$data["capabilities"];

  // readonly array type check
  if (ref && "length" in ref) {
    capabilities = ref;
  } else {
    capabilities =
      readRelayInlineFragment(
        graphql`
          fragment WithEngagementCapability_engagement on Engagement @inline {
            capabilities {
              type
              restrictedBy {
                message
              }
              expiresAt
              status
            }
          }
        `,
        ref ?? null
      )?.capabilities ?? [];
  }

  const capability = capabilities.find(
    (c) => type.includes(c.type) && c.status !== "UNAVAILABLE"
  );
  return capability
    ? {
        available: capability.status === "AVAILABLE",
        restricted: capability.status === "RESTRICTED",
        restrictedBy: capability.restrictedBy?.message,
        expiresAt: capability.expiresAt
      }
    : null;
}

//
// as hook

/** @deprecated use readEngagementCapability instead. */
export const useHasEngagementCapability = readEngagementCapability;

export const hasEngagementCapability = (
  ref:
    | WithEngagementCapability_engagement$key
    | WithEngagementCapability_engagement$data["capabilities"]
    | null
    | undefined,
  type: EngagementCapabilityType | EngagementCapabilityType[]
) => {
  const capability = readEngagementCapability(ref, type);
  return !!capability?.available;
};

//
// as component

interface Props {
  type: EngagementCapabilityType | EngagementCapabilityType[];
  fragmentRef?: WithEngagementCapability_engagement$key | null | undefined;
  capabilities?: WithEngagementCapability_engagement$data["capabilities"];
  children:
    | JSX.Element
    | ReactElement
    | ((capability: CapabilityType) => JSX.Element | ReactElement | boolean);
}

export default function WithEngagementCapability({
  fragmentRef,
  capabilities,
  type,
  children
}: Props) {
  const capability = readEngagementCapability(
    fragmentRef ?? capabilities ?? null,
    type
  );

  if (!capability || !children) return null;
  return (
    <>{typeof children === "function" ? children(capability) : children}</>
  );
}
