import { ContentArea, Markdown, Surface } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  markdown: string;
}

export default React.memo<Props>(
  function LegalMarkdown({ markdown }: Props) {
    return (
      <Surface variant="outline" testID="legal-html">
        <ContentArea>
          <Markdown>{markdown}</Markdown>
        </ContentArea>
      </Surface>
    );
  },
  (prev, next) => prev.markdown === next.markdown
);
