/**
 * @generated SignedSource<<8f6c88cb5c581dfe5cf21d5f5c323719>>
 * @relayHash cc561444776c113762f676eef4e1dc32
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:yud-0eZufxPvspvAuklP_ZODZrQDWeUZnWPapRAhWUg

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AsyncAddWorkerBlockedOrganizationInput = {
  organizationId: string;
  reason: string;
  workerId?: string | null | undefined;
};
export type BlockOrgModalAddMutation$variables = {
  input: AsyncAddWorkerBlockedOrganizationInput;
};
export type BlockOrgModalAddMutation$data = {
  readonly asyncAddWorkerBlockedOrganization: {
    readonly " $fragmentSpreads": FragmentRefs<"useRelayCommand_payload">;
  } | null | undefined;
};
export type BlockOrgModalAddMutation = {
  response: BlockOrgModalAddMutation$data;
  variables: BlockOrgModalAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correlationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockOrgModalAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonOperationPayload",
        "kind": "LinkedField",
        "name": "asyncAddWorkerBlockedOrganization",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "useRelayCommand_payload",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockOrgModalAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonOperationPayload",
        "kind": "LinkedField",
        "name": "asyncAddWorkerBlockedOrganization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:yud-0eZufxPvspvAuklP_ZODZrQDWeUZnWPapRAhWUg",
    "metadata": {},
    "name": "BlockOrgModalAddMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7cc5a8460b2a3e1bec6a230410be6764";

export default node;
