import { Text, showModal } from "@gigsmart/atorasu";
import React from "react";

export const showShiftNotAvailableModal = (onClose: () => void) =>
  showModal({
    title: "This Shift is no longer available.",
    eventContext: "Shift Not Available Modal",
    onClose,
    onRequestClose: onClose,
    actions: [
      {
        testID: "shift-not-available-modal-submit",
        label: "View Available Shifts",
        color: "emphasized"
      }
    ],
    children: () => (
      <Text>
        Find more work opportunities in your area using the Browse tab.
      </Text>
    )
  });
