import React from "react";

import { DataRowCard } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { paymentMethodRow_payableAccount$key } from "./__generated__/paymentMethodRow_payableAccount.graphql";

import PaymentMethod from "../payment-method";

interface Props {
  workerPayableAccount: paymentMethodRow_payableAccount$key | null | undefined;
}

export default ({
  workerPayableAccount: workerPayableAccountFragmentRef
}: Props) => {
  const account = useRelayFragment(
    graphql`
      fragment paymentMethodRow_payableAccount on WorkerPayableAccount {
        paymentMethod {
          last4
        }
        ...paymentMethod_payableAccount
      }
    `,
    workerPayableAccountFragmentRef
  );
  return (
    <DataRowCard
      iconName="building-columns"
      label="Receiving Money via"
      subtext={
        !account?.paymentMethod?.last4
          ? "A debit card or bank account is required to transfer money"
          : null
      }
      subtextColor="red"
    >
      <PaymentMethod payableAccount={account} />
    </DataRowCard>
  );
};
