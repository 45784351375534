import {
  ActionRow,
  ContentArea,
  Surface,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import Screen from "@gigsmart/seibutsu/layouts/Screen";
import InfiniteGridListLayout from "@gigsmart/seibutsu/layouts/lists/InfiniteGridList";
import ShiftGroupChatShiftCard from "@gigsmart/seibutsu/user-message/shift-group-chat/ShiftGroupChatShiftCard";
import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import type { shfitGroupChatsScreenWorkerPaginationQuery } from "./__generated__/shfitGroupChatsScreenWorkerPaginationQuery.graphql";
import type { shiftGroupChatsScreenWorkerQuery } from "./__generated__/shiftGroupChatsScreenWorkerQuery.graphql";
import type { shiftGroupChatsScreenWorkerSubscription } from "./__generated__/shiftGroupChatsScreenWorkerSubscription.graphql";
import type {
  shiftGroupChatsScreenWorker_viewer$data,
  shiftGroupChatsScreenWorker_viewer$key
} from "./__generated__/shiftGroupChatsScreenWorker_viewer.graphql";

type ConversationEntry = NonNullable<
  ObjectPath<
    shiftGroupChatsScreenWorker_viewer$data,
    ["conversations", "edges", 0, "node"]
  >
>;

export default createSuspendedQueryContainer<shiftGroupChatsScreenWorkerQuery>(
  function ShiftGroupChatsScreen({ response: { viewer = null } = {} }) {
    useRelaySubscription<shiftGroupChatsScreenWorkerSubscription>(
      graphql`
        subscription shiftGroupChatsScreenWorkerSubscription {
          userMessageStatsUpdated {
            user {
              id
              userMessageStats(conversationTypes: [SHIFT_GROUP]) {
                unreadCount
              }
            }
          }
        }
      `,
      {}
    );
    const isMediumUp = useMatchesViewport((media) => media.size.medium.up);
    return (
      <Screen testID="shift-group-chats-screen" showBackButton inset={false}>
        <ContentArea size="none" variant={isMediumUp ? "standard" : "none"}>
          <Surface>
            <ActionRow
              testID="shift-group-chats-screen-header"
              icon="messages"
              title={`${
                viewer?.userMessageStats?.unreadCount
              } New Shift Group Chat ${pluralize(
                viewer?.userMessageStats?.unreadCount,
                "Message",
                false
              )}`}
            />
          </Surface>
        </ContentArea>
        <InfiniteGridListLayout<
          shfitGroupChatsScreenWorkerPaginationQuery,
          shiftGroupChatsScreenWorker_viewer$key,
          ConversationEntry
        >
          getData={(data) => getConnectionNodes(data?.conversations)}
          keyExtractor={(item) => item?.id ?? ""}
          testID="shift-group-chats-screen-list"
          numColumns={{ medium: 1, large: 2, xxlarge: 3 }}
          parentRef={viewer}
          fragmentInput={graphql`
            fragment shiftGroupChatsScreenWorker_viewer on User
            @refetchable(
              queryName: "shfitGroupChatsScreenWorkerPaginationQuery"
            )
            @argumentDefinitions(
              count: { type: "Int", defaultValue: 12 }
              after: { type: "String" }
            ) {
              conversations(
                first: $count
                after: $after
                query: "WHERE conversationType = SHIFT_GROUP AND hasUnreadMessages = TRUE"
              )
                @connection(
                  key: "shfitGroupChatsScreenWorkerPaginationQuery_conversations"
                ) {
                edges {
                  node {
                    ... on ShiftGroupConversation {
                      id
                      ...ShiftGroupChatShiftCard_conversation
                    }
                  }
                }
              }
            }
          `}
          renderItem={(item) => (
            <ShiftGroupChatShiftCard fragmentRef={item} variant="worker" />
          )}
        />
      </Screen>
    );
  },
  {
    query: graphql`
      query shiftGroupChatsScreenWorkerQuery {
        viewer {
          id
          userMessageStats(conversationTypes: [SHIFT_GROUP]) {
            unreadCount
          }
          ...shiftGroupChatsScreenWorker_viewer
        }
      }
    `,
    variables: {}
  }
);
