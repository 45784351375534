import {
  Button,
  Column,
  ProductInfoRow,
  Text,
  TopicPreviewSurface
} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils/src";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import useGigLocalDateTime from "../../gig/useGigLocalDateTime";
import { showProjectGigsInfoModal } from "../../modals/ProjectGigsInfoModalTabs";
import ProjectTopicEmptyView from "./ProjectTopicEmptyView";
import type { ProjectTopicPreview_worker$key } from "./__generated__/ProjectTopicPreview_worker.graphql";

export const ProjectTopicPreview = ({
  availableProjectGigs
}: FragmentContainerInnerComponentProps<ProjectTopicPreview_worker$key>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const projectList = getConnectionNodes(availableProjectGigs) ?? [];
  const isEmpty = projectList.length === 0;

  // Row renderer
  const RenderProductInfoRow = ({
    id,
    availableGigs
  }: {
    id: string;
    availableGigs: ObjectPath<
      ProjectTopicPreview_worker$key,
      [" $data", "availableProjectGigs", "edges", 0, "node", "availableGigs"]
    >;
  }) => {
    const gig = getConnectionNodes(availableGigs)[0];
    const formattedDate = useGigLocalDateTime(
      { startsAt: gig?.startsAt, timezone: gig?.timezone },
      { dateFormat: "fullDateShort" }
    );

    const flexible =
      !gig?.isAsap && !gig?.startsAt ? "Flexible Schedule" : null;

    return (
      <ProductInfoRow
        testID={`available-project-${id}`}
        leftSpacing="small"
        onPress={() => navigation.push("BrowseProjectDetails", { id })}
        eventEntityType="WorkerHomeAvailableProjectGig"
        name={
          <Text color="black" variant="subheader">
            {gig?.name ?? ""}
          </Text>
        }
        icon="pen-ruler"
        color="highlight"
        iconSquareVariant="standard"
        note={
          <Text variant="note" color="neutral">
            {flexible ?? formattedDate.dateString}
          </Text>
        }
      />
    );
  };

  return (
    <TopicPreviewSurface
      testID="project-topic-preview"
      iconName="pen-ruler"
      color="primary"
      title="Project Gigs"
      subtitle="Work Project Gigs in person or remotely. Communicate directly with the Requester to finalize the timing of the Project and how much you will be paid."
      rightAccessory={
        <Button
          testID="learn-more-btn"
          size="xsmall"
          variant="clear"
          label="Learn More"
          icon="circle-question"
          color="primary"
          onPress={showProjectGigsInfoModal}
        />
      }
    >
      {isEmpty && <ProjectTopicEmptyView />}
      {projectList.length > 0 && (
        <Column gap="standard">
          <Column gap="compact">
            {projectList.map(({ id, availableGigs }, index) => (
              <RenderProductInfoRow
                key={`availaible-project-${index}`}
                id={id}
                availableGigs={availableGigs}
              />
            ))}
          </Column>
          <Button
            alignSelf="center"
            testID="view-all-project-gigs-btn"
            size="small"
            outline
            label="View All Project Gigs"
            icon="chevron-right"
            iconPlacement="right"
            onPress={() => navigation.push("BrowseProjects")}
          />
        </Column>
      )}
    </TopicPreviewSurface>
  );
};

export default createRelayFragmentContainer<ProjectTopicPreview_worker$key>(
  graphql`
    fragment ProjectTopicPreview_worker on Worker
    @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableProjectGigs: availableGigSeries(
        first: 3
        input: { gigTypes: [PROJECT], maxDistance: $maxDistance }
      ) {
        edges {
          node {
            id
            availableGigs(first: 1) {
              edges {
                node {
                  name
                  startsAt
                  isAsap
                  timezone
                }
              }
              totalCount
            }
          }
        }
        totalCount
      }
    }
  `,
  ProjectTopicPreview
);
