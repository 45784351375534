import { NavAction, Stack, Text, useStepper } from "@gigsmart/atorasu";
import { MandatoryApplicationQuestionnaire } from "@gigsmart/feature-flags";
import { Form, FormSubmit, Validator } from "@gigsmart/fomu";
import type { ValueObject } from "@gigsmart/fomu/types";
import type { GigType } from "@gigsmart/isomorphic-shared/gig/helpers";
import { NavPortalEntrance } from "@gigsmart/kaizoku";
import { confirmPrompt } from "@gigsmart/katana";
import FomuTextInput from "@gigsmart/seibutsu/fomu/inputs/FomuTextInput";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import React, { useMemo } from "react";
import StepperNavActions from "../../shared/Stepper/StepperNavActions";

interface Props {
  gigType?: GigType | null;
}

export default function ApplicationQuestionnaireStep({ gigType }: Props) {
  const { nextStep } = useStepper<Step, {}>();
  const questions = useMemo(
    () => [
      `Please describe what makes you a good fit for this ${
        gigType === "PROJECT" ? "Project Gig" : "Gig"
      }.${MandatoryApplicationQuestionnaire.select("*", "")}`,
      `Please describe any past experience with this type of work.${MandatoryApplicationQuestionnaire.select(
        "*",
        ""
      )}`
    ],
    [gigType]
  );
  const initialValues = useMemo(
    () =>
      questions.reduce<ValueObject>(
        (obj, _, idx) => ({ ...obj, [`answer-${idx + 1}`]: "" }),
        {}
      ),
    [questions]
  );

  const processQuestions = (values?: ValueObject) => {
    return values
      ? questions
          .map((question, index) => ({
            question: question.replace("*", ""),
            answer: values[`answer-${index + 1}`] || ""
          }))
          .filter((d) => !!d.answer)
      : null;
  };

  const handleFinalConfirmation = (values?: ValueObject, done?: () => void) => {
    const submitFn = () => {
      done?.();
      nextStep({ questions: processQuestions(values) ?? [] });
    };

    submitFn();
  };

  const handleAnswerConfirmation = (values: ValueObject, done?: () => void) => {
    confirmPrompt({
      title: "Application Questionnaire",
      subTitle:
        "Are you sure you want to submit your Application Questionnaire? You will not be able to edit your responses after submitting.",
      yesLabel: "Yes, Submit Application",
      cancelLabel: "No, Edit My Responses",
      onDo: () => handleFinalConfirmation(values, done),
      onCancel: done
    });
  };

  const renderQuestion = (question: string, index: number, arr: string[]) => (
    <Stack size="compact" key={index}>
      <Text testID={`question-${index + 1}`} color="neutral">
        QUESTION {index + 1}/{arr.length}
      </Text>
      <FomuTextInput
        name={`answer-${index + 1}`}
        validates={[
          Validator.noPhoneNumbers(),
          Validator.noEmailAddressesOrGmail()
        ]}
        label={question}
        multiline
        max={300}
        min={
          MandatoryApplicationQuestionnaire.isEnabled() && index !== 2
            ? 25
            : undefined
        }
      />
    </Stack>
  );

  const handleSkip = (done?: () => void) => {
    confirmPrompt({
      title: "Skip Application Questionnaire",
      subTitle:
        "Are you sure you want to skip the Application Questionnaire? You will not be able to add responses after submitting.",
      yesLabel: "Yes, Skip & Apply",
      cancelLabel: "No, Add My Responses",
      onDo: () => handleFinalConfirmation(undefined, done)
    });
  };

  return (
    <>
      <StepperNavActions />
      <NavPortalEntrance
        rightAccessory={MandatoryApplicationQuestionnaire.select(
          null,
          <NavAction testID="skip-button" onPress={() => handleSkip()}>
            <Text weight="bold">Skip</Text>
          </NavAction>
        )}
      />
      <Form
        initialValues={initialValues}
        onSubmit={({ values, valid, dirty }, done) => {
          if (!valid) return;
          if (dirty || MandatoryApplicationQuestionnaire.isEnabled()) {
            handleAnswerConfirmation(values, done);
          } else handleSkip(done);
        }}
      >
        <Stack>
          <Text>
            Make your application stand out! Answer these questions to tell the
            Requester exactly why they should hire you.
          </Text>
          {questions.map(renderQuestion)}
        </Stack>
        <FormSubmit>
          {({ dirty, submit, submitting, invalid }) => {
            return (
              <ResponsiveStepperBtnPortal.Entrance
                testID="application-questionnaire-gig-stepper-next-button"
                label={
                  dirty || MandatoryApplicationQuestionnaire.isEnabled()
                    ? "Submit & Apply"
                    : "Skip & Apply"
                }
                disabled={submitting || !dirty || invalid}
                onPress={submit}
              />
            );
          }}
        </FormSubmit>
      </Form>
    </>
  );
}
