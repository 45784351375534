import { IconText, Stack, Text, Well } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { checkIsWorkerVerified } from "@gigsmart/isomorphic-shared/worker";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import type {
  OfferedAttachment_engagement$key,
  ReportType
} from "./__generated__/OfferedAttachment_engagement.graphql";

interface Props {
  shiftCount?: number;
}

export const OfferedAttachment = ({
  shiftCount = 1,
  gig
}: FragmentContainerInnerComponentProps<
  OfferedAttachment_engagement$key,
  Props
>) => {
  const user = useCurrentUser();
  const isProject = gig.gigType === "PROJECT";
  const isWorkerVerified =
    !(
      [
        "CONTINUOUS_BACKGROUND_CHECK",
        "BACKGROUND_CHECK",
        "MOTOR_VEHICLE_CHECK"
      ] as ReportType[]
    ).some((report) => gig.requiredReportTypes.includes(report)) ||
    checkIsWorkerVerified(gig.requiredReportTypes, user);

  return (
    <Well color={isProject ? "primary" : "warning"} size="compact">
      {isProject || isWorkerVerified ? (
        <IconText
          fill
          spacing="medium"
          size="small"
          icon="business-time"
          iconColor={isProject ? "primary" : "warning"}
          textWeight="bold"
        >
          Congratulations, you have been offered{" "}
          {isProject
            ? "a Project. "
            : shiftCount > 1
              ? `${pluralize(shiftCount, "Shift")}! `
              : "a Shift. "}
          Please accept to be hired.
        </IconText>
      ) : (
        <Stack size="slim">
          <Text>You have been offered a Verified Gig!</Text>
          <Text weight="bold">
            In order to be hired on this Gig, your Worker Verification will need
            to be completed. Accept this offer to begin verification.
          </Text>
        </Stack>
      )}
    </Well>
  );
};

export default createRelayFragmentContainer<
  OfferedAttachment_engagement$key,
  Props
>(
  graphql`
    fragment OfferedAttachment_engagement on Engagement {
      id
      gig {
        gigType
        requiredReportTypes
      }
    }
  `,
  OfferedAttachment
);
