import {
  ContentArea,
  FancyHeader,
  Text,
  TimeRemaining
} from "@gigsmart/atorasu";
import { PickupShifts } from "@gigsmart/feature-flags";
import { GigCard } from "@gigsmart/isomorphic-shared/gig";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import GigTags, { useGigTags } from "@gigsmart/seibutsu/gig/GigTags";
import React from "react";
import {
  EngagementCardContent,
  GigRemainingTime
} from "../../gig/engagement-details";
import type { gigAvailableCard_gigEdge$key } from "./__generated__/gigAvailableCard_gigEdge.graphql";
import type { gigAvailableCard_seriesEdge$key } from "./__generated__/gigAvailableCard_seriesEdge.graphql";
import {
  getAddressContent,
  getCalendarContent,
  getDistanceContent,
  getPaymentContent
} from "./content";

const gigFragmentSpec = graphql`
  fragment gigAvailableCard_gigEdge on AvailableGigsEdge {
    distance
    estimatedPayment {
      payRate
      netPay
    }
    node {
      name
      area
      endsAt
      startsAt
      position {
        name
      }
      pickup {
        eligible
        windowStart
        windowEnd
      }
      ...GigTags_gigLike
    }
    skills(first: 1) {
      totalCount
      edges {
        node {
          name
        }
      }
    }
  }
`;

const seriesFragmentSpec = graphql`
  fragment gigAvailableCard_seriesEdge on AvailableGigSeriesEdge {
    cursor
    gigDistance {
      max: maxDistance
      min: minDistance
    }
    estimatedPaymentRange {
      max: maxAmount
      min: minAmount
    }
    node {
      id
      name
      area
      gigType
      startsAt
      negotiable
      hasMultipleLocations
      placeId
      paymentType
      position {
        name
      }
      ...GigTags_gigLike
      category {
        displayName
      }
      availableGigs(first: 1) {
        edges {
          ...gigAvailableCard_gigEdge
        }
        totalCount
      }
    }
  }
`;

export type EdgeType$key = {
  readonly node?: {
    readonly __typename: string;
  } | null;
} & (gigAvailableCard_gigEdge$key | gigAvailableCard_seriesEdge$key);

interface Props {
  horizontal?: boolean;
  inset?: number;
  onPress?: () => void;
  edgeRef?: EdgeType$key | null | undefined;
}

/** @deprecated Old UI */
export default function GigAvailableCard({
  horizontal,
  inset = 3,
  edgeRef,
  onPress
}: Props) {
  const seriesEdge = useRelayFragment(
    seriesFragmentSpec,
    edgeRef?.node?.__typename === "GigSeries"
      ? (edgeRef as gigAvailableCard_seriesEdge$key)
      : null
  );

  const gigEdge = useRelayFragment<gigAvailableCard_gigEdge$key>(
    gigFragmentSpec,
    edgeRef?.node?.__typename === "Gig"
      ? (edgeRef as gigAvailableCard_gigEdge$key)
      : seriesEdge?.node?.availableGigs?.edges?.[0] ?? null
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gigTags = useGigTags(seriesEdge?.node ?? gigEdge?.node ?? null);

  // TODO: For GigSeries we shouldn't rely on that
  if (!gigEdge) return null;
  const name = seriesEdge?.node?.name ?? gigEdge?.node?.name;
  const gig = gigEdge ?? seriesEdge?.node?.availableGigs?.edges?.[0];
  const isSingleShift = seriesEdge?.node?.availableGigs?.totalCount === 1;
  const isProject = seriesEdge?.node?.gigType === "PROJECT";
  const isRemote = seriesEdge?.node?.placeId === "REMOTE";
  const pickup = seriesEdge ? null : gigEdge?.node?.pickup;
  const gigCount = seriesEdge?.node?.availableGigs?.totalCount ?? 1;
  const [calendarNode, calendarInfoNode] = getCalendarContent({
    gigCount,
    node: gig.node,
    isProject,
    isTBD: seriesEdge?.node?.negotiable?.includes("STARTS_AT") ?? false
  });
  const addressNode = getAddressContent({
    hasMultipleLocations: isSingleShift
      ? false
      : seriesEdge?.node?.hasMultipleLocations,
    remote: isRemote,
    area: isSingleShift
      ? gigEdge?.node?.area
      : seriesEdge?.node?.area ?? gigEdge?.node?.area
  });
  const paymentNode = getPaymentContent({
    payRate: gigEdge?.estimatedPayment?.payRate,
    netPay: gigEdge?.estimatedPayment?.netPay,
    estimatedPaymentRange: seriesEdge?.estimatedPaymentRange ?? undefined
  });
  const distanceNode = getDistanceContent(
    seriesEdge?.gigDistance ?? gigEdge.distance,
    isRemote
  );

  return (
    <GigCard
      horizontal={horizontal}
      onPress={onPress}
      count={gigCount}
      inset={inset}
      spacing={4}
      headerVariant="worker"
      thumbnailIconName={isProject ? "pen-ruler" : "business-time"}
      thumbnailTintColor={isProject ? "blue" : "spiceOrange"}
      marginBottom={2}
      name={name}
      topAccessory={
        <>
          {!isProject && !pickup?.eligible && (
            <GigRemainingTime
              variant="topAccessory"
              startsAt={gigEdge?.node?.startsAt}
              endsAt={gigEdge?.node?.endsAt}
            />
          )}
          {pickup?.eligible && PickupShifts.isEnabled() && (
            <FancyHeader
              variant="emphasized"
              color="emphasized"
              title={
                <Text weight="bold">
                  Shift Available Today (
                  <TimeRemaining
                    weight="bold"
                    size="lg"
                    showLargestOnly
                    date={pickup?.windowEnd}
                  />{" "}
                  left to pickup)
                </Text>
              }
              icon="alarm-clock"
            />
          )}
        </>
      }
      bottomAccessory={
        gigTags.length ? (
          <ContentArea size="compact">
            <GigTags fragmentRef={seriesEdge?.node ?? gigEdge?.node} />
          </ContentArea>
        ) : null
      }
    >
      <EngagementCardContent
        skillCount={gigEdge?.skills?.totalCount}
        skillOrCategoryName={
          isProject
            ? seriesEdge?.node?.category?.displayName
            : seriesEdge?.node?.position?.name ?? gigEdge?.node?.position?.name
        }
        calendar={calendarNode}
        calendarInfo={calendarInfoNode}
        address={addressNode}
        addressInfo={distanceNode}
        paymentType={seriesEdge?.node?.paymentType}
        thumbnail={paymentNode}
        gigType={seriesEdge?.node?.gigType}
      />
    </GigCard>
  );
}
