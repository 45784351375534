import React from "react";

import { Column, IconCircle, IconText, Stack, Text } from "@gigsmart/atorasu";

interface Props {
  id?: string | null;
  name?: string | null;
}

export default function WorkerGigListCardTitle({ id, name }: Props) {
  return (
    <Stack horizontal alignItems="center">
      <IconCircle icon="business-time" color="warning" size="small" />
      <Column fill>
        <Text
          weight="bold"
          numberOfLines={2}
          testID={`worker-gig-card-title-${id}`}
        >
          {name}
        </Text>
      </Column>
      <IconText
        iconPlacement="right"
        icon="chevron-right"
        color="primary"
        size="small"
        textWeight="bold"
      >
        View
      </IconText>
    </Stack>
  );
}
