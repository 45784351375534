import { Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { KatanaLoadingView } from "@gigsmart/katana";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import type { NotificationSection } from "./use-notification-section";

interface Props {
  section: NotificationSection;
}

const NotificationSectionFooter = ({ section }: Props) => {
  const isEmpty = section.data?.length === 0;
  const styles = useStyles(({ getColor }) => ({
    container: {
      backgroundColor: getColor("surface", "fill"),
      paddingHorizontal: 20,
      height: 50,
      justifyContent: "center",
      borderBottomWidth: 1,
      borderBottomColor: getColor("divider", "fill")
    },
    footerText: {
      color: getColor("disabled", "fill"),
      textAlign: "center"
    },
    loading: {
      height: 50
    }
  }));

  if (isEmpty) {
    return (
      <View style={styles.container}>
        <Text style={styles.footerText}>No notifications to display</Text>
      </View>
    );
  }
  if (section.hasNext) {
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={() => section.loadMore()}
        disabled={section.isLoading}
      >
        {section.isLoading ? (
          <KatanaLoadingView style={styles.loading} />
        ) : (
          <Text style={styles.footerText}>Load More</Text>
        )}
      </TouchableOpacity>
    );
  }
  return null;
};

export default NotificationSectionFooter;
