/**
 * @generated SignedSource<<775a4376f1debec21d6135e96d672011>>
 * @relayHash 043e76c81adb0da0054b9c4ae7e4e5ce
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Q08_gYgazxy8V36P8hOapRiChu19UhfhHF0nOGPvFjo

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerPositionCheckSubscription$variables = {
  id: string;
};
export type WorkerPositionCheckSubscription$data = {
  readonly nodeUpdated: {
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"WorkerPositionCheck_worker">;
    };
  } | null | undefined;
};
export type WorkerPositionCheckSubscription = {
  response: WorkerPositionCheckSubscription$data;
  variables: WorkerPositionCheckSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "_in": [
    "ACTIVE",
    "IN_PROGRESS"
  ]
},
v8 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE status = CONFIRMED"
  }
],
v9 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  "query"
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerPositionCheckSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NodeUpdatedPayload",
        "kind": "LinkedField",
        "name": "nodeUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkerPositionCheck_worker"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerPositionCheckSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NodeUpdatedPayload",
        "kind": "LinkedField",
        "name": "nodeUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "activeEngagementsAndOffers",
                    "args": [
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "currentStateName": {
                            "_in": [
                              "SCHEDULED",
                              "EN_ROUTE",
                              "AWAITING_START",
                              "WORKING",
                              "PAUSED",
                              "OFFERED"
                            ]
                          }
                        }
                      }
                    ],
                    "concreteType": "EngagementsConnection",
                    "kind": "LinkedField",
                    "name": "engagements",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"SCHEDULED\",\"EN_ROUTE\",\"AWAITING_START\",\"WORKING\",\"PAUSED\",\"OFFERED\"]}})"
                  },
                  {
                    "alias": "preactiveEngagements",
                    "args": [
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "currentStateName": {
                            "_in": [
                              "SCHEDULED",
                              "EN_ROUTE",
                              "AWAITING_START"
                            ]
                          },
                          "gigStateName": (v7/*: any*/)
                        }
                      }
                    ],
                    "concreteType": "EngagementsConnection",
                    "kind": "LinkedField",
                    "name": "engagements",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"SCHEDULED\",\"EN_ROUTE\",\"AWAITING_START\"]},\"gigStateName\":{\"_in\":[\"ACTIVE\",\"IN_PROGRESS\"]}})"
                  },
                  {
                    "alias": "activeEngagements",
                    "args": [
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "currentStateName": {
                            "_in": [
                              "WORKING",
                              "PAUSED"
                            ]
                          },
                          "gigStateName": (v7/*: any*/)
                        }
                      }
                    ],
                    "concreteType": "EngagementsConnection",
                    "kind": "LinkedField",
                    "name": "engagements",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"WORKING\",\"PAUSED\"]},\"gigStateName\":{\"_in\":[\"ACTIVE\",\"IN_PROGRESS\"]}})"
                  },
                  {
                    "alias": "bans",
                    "args": [
                      (v4/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "WHERE expiresAt = NULL AND removedAt = NULL"
                      }
                    ],
                    "concreteType": "UserRestrictionsConnection",
                    "kind": "LinkedField",
                    "name": "restrictions",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": "restrictions(first:0,query:\"WHERE expiresAt = NULL AND removedAt = NULL\")"
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "WorkerPositionsConnection",
                    "kind": "LinkedField",
                    "name": "workerPositions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerPositionsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigPosition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": "workerPositions(first:0,query:\"WHERE status = CONFIRMED\")"
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "filters": (v12/*: any*/),
                    "handle": "connection",
                    "key": "WorkerPositionCheck_workerPositions",
                    "kind": "LinkedHandle",
                    "name": "workerPositions"
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "WorkerGigPositionsConnection",
                    "kind": "LinkedField",
                    "name": "positions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerGigPositionsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkerGigPosition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": "positions(first:0,query:\"WHERE status = CONFIRMED\")"
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "filters": (v12/*: any*/),
                    "handle": "connection",
                    "key": "WorkerPositionCheckModal_positions",
                    "kind": "LinkedHandle",
                    "name": "positions"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 20
                      },
                      {
                        "kind": "Literal",
                        "name": "query",
                        "value": "ORDER BY priority ASC"
                      }
                    ],
                    "concreteType": "WorkerCategoriesConnection",
                    "kind": "LinkedField",
                    "name": "workerCategories",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerCategoriesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkerGigCategory",
                            "kind": "LinkedField",
                            "name": "association",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "confirmedPositions",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unknownPositions",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "deniedPositions",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigCategory",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Icon",
                                "kind": "LinkedField",
                                "name": "categoryIcon",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "data",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "workerCategories(first:20,query:\"ORDER BY priority ASC\")"
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Q08_gYgazxy8V36P8hOapRiChu19UhfhHF0nOGPvFjo",
    "metadata": {},
    "name": "WorkerPositionCheckSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "151d28c69e76bc74aa897a951a73b336";

export default node;
