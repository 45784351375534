import React from "react";

import IconText from "../icon/icon-text";
import { theme, useStyles } from "../style";
import KatanaTouchableOpacity from "./katana-touchable-opacity";

interface Props {
  testID?: string;
  value?: boolean;
  onToggle?: (newValue: boolean) => void;
  onLabel?: string;
  offLabel?: string;
  iconPos?: "right" | "left";
  toTop?: boolean;
  spacing?: number;
}

const ToggleText = ({
  testID,
  value,
  onToggle,
  onLabel = "Hide",
  offLabel = "Show",
  iconPos = "right",
  toTop = false,
  spacing = 1.5
}: Props) => {
  const { styles } = useStyles(() => ({
    icon: { fontSize: 12 }
  }));

  const hideIcon = toTop ? "chevron-down" : "chevron-up";
  const showIcon = toTop ? "chevron-up" : "chevron-down";

  return (
    <KatanaTouchableOpacity
      testID={testID ?? "toggle-text"}
      eventTargetName="Toggle Text Pressable"
      onPress={() => onToggle?.(!value)}
    >
      <IconText
        iconPos={iconPos}
        iconName={value ? hideIcon : showIcon}
        iconStyle={styles.icon}
        tintColor={theme.color.blue}
        variant="bold"
        spacing={spacing}
      >
        {value ? onLabel : offLabel}
      </IconText>
    </KatanaTouchableOpacity>
  );
};

export default ToggleText;
