import { currency } from "@gigsmart/isomorphic-shared/iso";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { memo, useMemo } from "react";

import JobCard from "./job-card";

import type {
  affiliateJobCardFragment_job$data,
  affiliateJobCardFragment_job$key
} from "./__generated__/affiliateJobCardFragment_job.graphql";

const fragmentSpec = graphql`
  fragment affiliateJobCardFragment_job on AffiliateJobPosting {
    name
    url
    snippet
    salaryInterval
    maximumSalary
    minimumSalary
    location
    industryName
    hiringCompany {
      name
    }
  }
`;

interface Props {
  jobRef: affiliateJobCardFragment_job$key | null | undefined;
  onPress?: () => void;
}

const AffiliateJobCardFragment = memo(
  ({ jobRef, onPress }: Props) => {
    const job = useRelayFragment(fragmentSpec, jobRef);
    const { description, tags } = useMemo(
      () => ({
        description: decodeEntities(job?.snippet),
        tags: generateTags(job)
      }),
      [job]
    );
    if (!job) return null;
    return (
      <JobCard
        affiliate
        company={job?.hiringCompany?.name}
        title={job?.name}
        description={description}
        tags={tags}
        onPress={onPress}
      />
    );
  },
  (p1, p2) => p1.jobRef === p2.jobRef
);

export default AffiliateJobCardFragment;

//

const decodeEntities = (encodedString?: string | null) => {
  if (!encodedString) return "";
  const translate_re = /&(nbsp|amp|quot|lt|gt|#x2F|#x27);/g;
  const translate: any = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">",
    "#x2F": "/",
    "#x27": "'"
  };
  return encodedString
    .replace(translate_re, (match, entity: any) => translate[entity])
    .replace(/&#(\d+);/gi, (match, numStr) => {
      const num = Number.parseInt(numStr, 10);
      return String.fromCharCode(num);
    })
    .replace(/(<([^>]+)>)/gi, "");
};

const generateTags = (
  node: affiliateJobCardFragment_job$data | null | undefined
) => {
  const tags = [];
  if (node?.location) tags.push(node.location);
  if (node?.salaryInterval) {
    tags.push(salaryIntervalTag(node.salaryInterval));
  }
  if (node?.salaryInterval && node.maximumSalary && node.minimumSalary) {
    tags.push(
      compensationTag(
        node.salaryInterval,
        currency.toFloat(node.minimumSalary),
        currency.toFloat(node.maximumSalary)
      )
    );
  }
  if (node?.industryName) tags.push(node.industryName);
  return tags;
};

const salaryIntervalTag = (salaryInterval: string): string => {
  switch (salaryInterval) {
    case "hourly":
      return "Hourly Pay";
    case "weekly":
      return "Weekly Pay";
    case "monthly":
      return "Monthly Pay";
    case "yearly":
      return "Annual Pay";
    default:
      return "";
  }
};

const compensationTag = (
  salaryInterval: string,
  minimumSalary: number,
  maximumSalary: number
): string => {
  const suffix = getCompensationSuffix(salaryInterval);
  if (maximumSalary === minimumSalary) {
    return `$${minimumSalary
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${suffix}`;
  }
  return `$${minimumSalary
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - $${maximumSalary
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${suffix}`;
};

const getCompensationSuffix = (salaryInterval: string): string => {
  switch (salaryInterval) {
    case "hourly":
      return "per hour";
    case "weekly":
      return "per week";
    case "monthly":
      return "per month";
    case "yearly":
      return "per year";
    default:
      return "";
  }
};
