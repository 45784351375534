import React, { useMemo } from "react";

import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import EmergencyContactFormContainer from "@gigsmart/seibutsu/worker-profile/EmergencyContactFormContainer";

import type { emergencyContactsScreenQuery } from "./__generated__/emergencyContactsScreenQuery.graphql";

export default createSuspendedQueryContainer<emergencyContactsScreenQuery>(
  function EmergencyContactsScreen({ response: { viewer } = {}, retry }) {
    const computeInitialValues = useMemo(() => {
      const initialValues: any = {};
      const existingContacts = viewer?.emergencyContacts?.edges ?? [];
      existingContacts.forEach((contact, i) => {
        const index = (i as number) + 1;
        initialValues[`emailAddress-contact-${index}`] =
          contact?.node?.emailAddress;
        initialValues[`firstName-contact-${index}`] = contact?.node?.firstName;
        initialValues[`lastName-contact-${index}`] = contact?.node?.lastName;
        initialValues[`mobileNumber-contact-${index}`] =
          contact?.node?.mobileNumber.substring(2);
        initialValues[`relationship-contact-${index}`] =
          contact?.node?.relationship;
        initialValues[`workerEmergencyContactId-contact-${index}`] =
          contact?.node?.id;
      });

      return initialValues;
    }, [viewer]);

    const currentContacts = viewer?.emergencyContacts?.edges ?? [];
    const primaryEmail = viewer?.primaryEmail?.address;
    const primaryPhone = viewer?.primaryMobile?.number;
    const birthDate = viewer?.birthdate;
    const workerId = viewer?.id;

    const refetchData = async () => {
      retry();
      computeInitialValues();
    };

    return (
      <EmergencyContactFormContainer
        workerId={workerId ?? ""}
        primaryEmail={primaryEmail ?? ""}
        primaryPhone={primaryPhone}
        birthDate={birthDate}
        initialValues={computeInitialValues}
        formData={currentContacts}
        refetchData={refetchData}
      />
    );
  },
  {
    query: graphql`
      query emergencyContactsScreenQuery {
        viewer {
          ... on Worker {
            id
            birthdate
            primaryEmail {
              address
            }
            primaryMobile {
              number
            }
            emergencyContacts(first: 2) {
              totalCount
              edges {
                node {
                  id
                  firstName
                  lastName
                  relationship
                  mobileNumber
                  emailAddress
                }
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);
