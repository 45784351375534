import { any, asEnums, like, where } from "@gigsmart/biruda";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import React from "react";
import { WorkerEngagementCardV2 } from "../gig-like/WorkerShiftCard";
import InfiniteGridListLayout from "../layouts/lists/InfiniteGridList";
import type { WorkerShiftParams } from "../shifts/navigation";
import WorkerProjectEngagementCard from "./WorkerProjectEngagementCard";
import type { WorkerEngagementHistoryListPaginationQuery } from "./__generated__/WorkerEngagementHistoryListPaginationQuery.graphql";
import type { WorkerEngagementHistoryListQuery } from "./__generated__/WorkerEngagementHistoryListQuery.graphql";
import type { WorkerEngagementHistoryList_worker$key } from "./__generated__/WorkerEngagementHistoryList_worker.graphql";

interface Props {
  variant: "completed" | "not-hired" | "canceled";
}

type NodeType = NonNullable<
  ObjectPath<
    WorkerEngagementHistoryList_worker$key,
    [" $data", "engagements", "edges", 0, "node"]
  >
>;

const QUERY_STATES = {
  completedShifts: ["ENDED"],
  completedProjects: ["ENDED"],
  notHiredShifts: [
    "MISSED",
    "APPLICATION_DENIED",
    "APPLICATION_CANCELED",
    "REJECTED"
  ],
  notHiredProjects: [
    "MISSED",
    "APPLICATION_DENIED",
    "APPLICATION_CANCELED",
    "REJECTED",
    "ENGAGED_APPLICATION_DENIED"
  ],
  canceledShifts: ["CANCELED", "CANCELED_WITH_PAY"],
  canceledProjects: ["CANCELED"]
};

export default createSuspendedQueryContainer<
  WorkerEngagementHistoryListQuery,
  Props
>(
  function WorkerEngagementHistoryList({ variant, response }) {
    const viewer = response?.viewer ?? null;
    const navigation = useNavigation<AppNavigationProp<WorkerShiftParams>>();
    const handlePress = (id: string, isProject: boolean) =>
      navigation.push(isProject ? "ProjectDetails" : "ShiftDetails", { id });

    return (
      <InfiniteGridListLayout<
        WorkerEngagementHistoryListPaginationQuery,
        WorkerEngagementHistoryList_worker$key,
        NodeType
      >
        parentRef={viewer}
        searchPlaceholder="Search by Title or Requester"
        testID="worker-gigs-history-list"
        getRefetchVarsFromValuesFn={(values) => {
          return {
            query: getQuery(
              values?.activeTab ?? "shifts",
              variant,
              values?.searchTerm
            )
          };
        }}
        keyExtractor={(item, index) => item?.id || `${index}`}
        pageSize={12}
        fragmentInput={graphql`
          fragment WorkerEngagementHistoryList_worker on Worker
          @refetchable(queryName: "WorkerEngagementHistoryListPaginationQuery")
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 24 }
            after: { type: "String" }
            query: { type: "String" }
          ) {
            engagements(first: $count, after: $after, query: $query)
              @connection(
                key: "WorkerEngagementHistoryList_engagements"
                filters: ["query"]
              ) {
              edges {
                node {
                  id
                  currentState {
                    name
                  }
                  gigType
                  startsAt
                  ...WorkerEngagementCardV2_engagement
                  ...WorkerProjectEngagementCard_engagement
                }
              }
            }
          }
        `}
        getData={(data) => {
          return getConnectionNodes(data?.engagements);
        }}
        tabFilters={[
          {
            default: true,
            label: "Shifts",
            value: "shifts",
            count: viewer?.shifts?.totalCount ?? 0,
            listEmptyLabel:
              variant === "completed"
                ? "No Completed Shifts"
                : variant === "not-hired"
                  ? "There are no Shifts that you have applied to, but were not hired on."
                  : "You don't have any canceled Shifts!",
            listEmptyDescription:
              variant === "completed"
                ? "Completed Shifts will appear here."
                : variant === "not-hired"
                  ? "Requester offers you've missed, rejected, or applications that were withdrawn before hire will appear here."
                  : "Shifts you were Hired on but did not work will appear here."
          },
          {
            label: "Project",
            value: "projects",
            count: viewer?.projects?.totalCount ?? 0,
            listEmptyLabel:
              variant === "completed"
                ? "No Completed Projects"
                : variant === "not-hired"
                  ? "There are no Projects that you have applied to, but were not hired on."
                  : "You don't have any canceled Projects!",
            listEmptyDescription:
              variant === "completed"
                ? "Completed Projects will appear here."
                : variant === "not-hired"
                  ? "Requester offers you've missed, rejected, or applications that were withdrawn before hire will appear here."
                  : "Projects you were Hired on but did not work will appear here."
          }
        ]}
        renderItem={(item) =>
          item?.gigType === "PROJECT" ? (
            <WorkerProjectEngagementCard
              fragmentRef={item}
              onPress={() => handlePress(item.id, true)}
            />
          ) : (
            <WorkerEngagementCardV2
              fragmentRef={item}
              onPress={() => handlePress(item.id, false)}
            />
          )
        }
      />
    );
  },
  {
    query: graphql`
      query WorkerEngagementHistoryListQuery(
        $query: String!
        $shiftsQuery: String!
        $projectsQuery: String!
      ) {
        viewer {
          ... on Worker {
            id
            shifts: engagements(first: 0, query: $shiftsQuery) {
              totalCount
            }
            projects: engagements(first: 0, query: $projectsQuery) {
              totalCount
            }
            ...WorkerEngagementHistoryList_worker @arguments(query: $query)
          }
        }
      }
    `,
    variables: ({ variant }) => ({
      query: getQuery("shifts", variant),
      shiftsQuery: getQuery("shifts", variant),
      projectsQuery: getQuery("projects", variant)
    })
  }
);

function getQuery(
  filter: "shifts" | "projects",
  tab: "completed" | "not-hired" | "canceled",
  searchTerm?: string
) {
  const states =
    QUERY_STATES[
      `${tab === "not-hired" ? "notHired" : tab}${
        filter === "shifts" ? "Shifts" : "Projects"
      }`
    ];
  const gigType = filter === "shifts" ? ["PAID", "VOLUNTEER"] : ["PROJECT"];
  let query = where({
    currentStateName: asEnums(states),
    gigType: asEnums(gigType)
  });
  if (searchTerm)
    query = query.and(
      any(["gigName", "requesterName", "organizationName"], like(searchTerm))
    );
  const orderBy =
    tab === "completed"
      ? "actualStartsAt"
      : filter === "shifts"
        ? "actualStartsAt"
        : "currentStateTransitionedAt";
  return query.orderBy(orderBy, "DESC").toString();
}
