/**
 * @generated SignedSource<<5ba0b82b5545c08f2edfc885e1c7e0a4>>
 * @relayHash d8add02e7a936f59bfa8a9d595ada701
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:WUONgrc2Fh_UoS2lN-DLao69cBFjmVJtFCaAdm7Exxc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useLegalDocumentQuery$variables = {
  filename: string;
  userId: string;
};
export type useLegalDocumentQuery$data = {
  readonly userWithConsent: {
    readonly consentStatus?: {
      readonly consent: {
        readonly consentedAt: string;
        readonly consentedIp: string;
        readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_consent">;
      } | null | undefined;
      readonly legalDocument: {
        readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_document" | "useLegalDocument_document">;
      };
    } | null | undefined;
  } | null | undefined;
};
export type useLegalDocumentQuery = {
  response: useLegalDocumentQuery$data;
  variables: useLegalDocumentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filename"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "filename",
    "variableName": "filename"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consentedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consentedIp",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLegalDocumentQuery",
    "selections": [
      {
        "alias": "userWithConsent",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UserConsentStatus",
                "kind": "LinkedField",
                "name": "consentStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserConsentDocument",
                    "kind": "LinkedField",
                    "name": "consent",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "LegalDocument_consent"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalDocument",
                    "kind": "LinkedField",
                    "name": "legalDocument",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "useLegalDocument_document"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "LegalDocument_document"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": "__isUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLegalDocumentQuery",
    "selections": [
      {
        "alias": "userWithConsent",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UserConsentStatus",
                "kind": "LinkedField",
                "name": "consentStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserConsentDocument",
                    "kind": "LinkedField",
                    "name": "consent",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalDocument",
                    "kind": "LinkedField",
                    "name": "legalDocument",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "format",
                        "storageKey": null
                      },
                      {
                        "alias": "source",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "filename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": "__isUser"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:WUONgrc2Fh_UoS2lN-DLao69cBFjmVJtFCaAdm7Exxc",
    "metadata": {},
    "name": "useLegalDocumentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "688372911c076d13c78949c2f5bd0e7c";

export default node;
