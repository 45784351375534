import { Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useHistory } from "@gigsmart/kaizoku";
import React from "react";
import { TouchableOpacity } from "react-native";

interface Props {
  text: string;
  to?: string;
  testID?: string;
  onPress?: () => void;
}

export default ({ text, to, onPress, testID }: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    container: {
      marginRight: getUnits(4),
      justifyContent: "center",
      alignItems: "center"
    }
  }));
  const history = useHistory();
  const handlePress = () => {
    if (onPress) {
      onPress();
      return;
    }
    to ? history.push(to) : history.goBack();
  };
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={handlePress}
      testID={testID}
    >
      <Text color="surface" variant="bodyLg" weight="bold">
        {text}
      </Text>
    </TouchableOpacity>
  );
};
