/**
 * @generated SignedSource<<b3a48aef217eb1aff146e00c0fc6e62f>>
 * @relayHash 12ece4a0118e0c1c92e4cfc573ca39a6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:99giaj8onFnxnhbCvNfWSaNl61VBlWXoc-Cx4eAkuE0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicantDirection = "EMAIL" | "WEBSITE" | "%future added value";
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
export type JobPostingReportingType = "HIDDEN" | "STANDARD" | "%future added value";
export type JobPostingState = "CANCELED" | "DISCARDED" | "DRAFT" | "EXPIRED" | "PUBLISHED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JobPostingSearch = {
  applicantDirection?: ApplicantDirection | null | undefined;
  approved?: boolean | null | undefined;
  assignedAdmins?: ReadonlyArray<string> | null | undefined;
  boosted?: boolean | null | undefined;
  canceled?: boolean | null | undefined;
  endDate?: string | null | undefined;
  excludedAdmins?: ReadonlyArray<string> | null | undefined;
  expired?: boolean | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  maxStartDate?: string | null | undefined;
  maxTransactionCount?: number | null | undefined;
  minStartDate?: string | null | undefined;
  minTransactionCount?: number | null | undefined;
  orderBy?: ReadonlyArray<OrderBy> | null | undefined;
  payRateNegotiable?: boolean | null | undefined;
  paySchedule?: JobPostingPaySchedule | null | undefined;
  reportingType?: ReadonlyArray<JobPostingReportingType> | null | undefined;
  search?: string | null | undefined;
  startDate?: string | null | undefined;
  state?: JobPostingState | null | undefined;
  states?: ReadonlyArray<JobPostingState> | null | undefined;
  visible?: boolean | null | undefined;
};
export type OrderBy = {
  direction?: SortDirection | null | undefined;
  field: string;
};
export type AffiliateJobPostingsInput = {
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  minimumSalary?: string | null | undefined;
  postedAfter?: string | null | undefined;
  search?: string | null | undefined;
};
export type jobsListQuery$variables = {
  input: AffiliateJobPostingsInput;
  search: JobPostingSearch;
};
export type jobsListQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"jobsList_jobPostings">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"jobsList_affiliateJobPostings">;
};
export type jobsListQuery = {
  response: jobsListQuery$data;
  variables: jobsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "kind": "Variable",
  "name": "input",
  "variableName": "input"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v7 = [
  (v6/*: any*/),
  (v2/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v13 = [
  (v6/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "jobsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": [
                  (v2/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "jobsList_jobPostings"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "jobsList_affiliateJobPostings"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "jobsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "AvailableJobPostingsConnection",
                "kind": "LinkedField",
                "name": "availableJobPostings",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableJobPostingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": "jobPost",
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Place",
                            "kind": "LinkedField",
                            "name": "place",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "streetAddress",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "boosted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paySchedule",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateNegotiable",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maximumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minimumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "area",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "search"
                ],
                "handle": "connection",
                "key": "jobsList_availableJobPostings",
                "kind": "LinkedHandle",
                "name": "availableJobPostings"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "AffiliateJobPostingsConnection",
        "kind": "LinkedField",
        "name": "affiliateJobPostings",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AffiliateJobPostingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": "affiliateJobPost",
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "snippet",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salaryInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maximumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "industryName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AffiliateJobHiringCompany",
                    "kind": "LinkedField",
                    "name": "hiringCompany",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "filters": [
          "input"
        ],
        "handle": "connection",
        "key": "jobsList_affiliateJobPostings",
        "kind": "LinkedHandle",
        "name": "affiliateJobPostings"
      }
    ]
  },
  "params": {
    "id": "qpid:99giaj8onFnxnhbCvNfWSaNl61VBlWXoc-Cx4eAkuE0",
    "metadata": {},
    "name": "jobsListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5b2783acce21b0411f7db33f77cf94b1";

export default node;
