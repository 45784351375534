/**
 * @generated SignedSource<<9ccf21cde0a5f8d050a0eac0dfef8b5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "DRUG_SCREEN" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useApplyMetadataIsEnrolled_gigOrEngagement$data = {
  readonly gig?: {
    readonly gigSeries: {
      readonly unmetQualifications: {
        readonly totalCount: number;
      } | null | undefined;
    };
    readonly position: {
      readonly id: string;
    } | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
  };
  readonly node?: {
    readonly gigSeries: {
      readonly unmetQualifications: {
        readonly totalCount: number;
      } | null | undefined;
    };
    readonly position: {
      readonly id: string;
    } | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
  } | null | undefined;
  readonly pendingConsents?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ConsentDocumentStepper_documents">;
  }>;
  readonly " $fragmentType": "useApplyMetadataIsEnrolled_gigOrEngagement";
};
export type useApplyMetadataIsEnrolled_gigOrEngagement$key = {
  readonly " $data"?: useApplyMetadataIsEnrolled_gigOrEngagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadataIsEnrolled_gigOrEngagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useApplyMetadataIsEnrolled_gigOrEngagement"
};

(node as any).hash = "0447a3656322c7789eea4dd46024fd00";

export default node;
