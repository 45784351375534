/**
 * @generated SignedSource<<cec427dcdd84278e57491b2b88783dbe>>
 * @relayHash 62c5791626b9fd1d3a07d5790ca88d6c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:7iUwyrPNJkP_n4fCDdaVu79To21cxAJzsI1LIQ-cDxQ

import { ConcreteRequest } from 'relay-runtime';
export type MessageCenterIconSubscription$variables = Record<PropertyKey, never>;
export type MessageCenterIconSubscription$data = {
  readonly userMessageStatsUpdated: {
    readonly user: {
      readonly id: string;
      readonly userMessageStats: {
        readonly unreadCount: number;
      };
    };
  } | null | undefined;
};
export type MessageCenterIconSubscription = {
  response: MessageCenterIconSubscription$data;
  variables: MessageCenterIconSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "conversationTypes",
      "value": [
        "ENGAGEMENT",
        "ORGANIZATION_WORKER"
      ]
    }
  ],
  "concreteType": "UserMessageStats",
  "kind": "LinkedField",
  "name": "userMessageStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    }
  ],
  "storageKey": "userMessageStats(conversationTypes:[\"ENGAGEMENT\",\"ORGANIZATION_WORKER\"])"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCenterIconSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMessageStatsUpdatedPayload",
        "kind": "LinkedField",
        "name": "userMessageStatsUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MessageCenterIconSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMessageStatsUpdatedPayload",
        "kind": "LinkedField",
        "name": "userMessageStatsUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:7iUwyrPNJkP_n4fCDdaVu79To21cxAJzsI1LIQ-cDxQ",
    "metadata": {},
    "name": "MessageCenterIconSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "8743befde28ca7225018500149c52bfa";

export default node;
