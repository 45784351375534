import {
  Column,
  DescriptionItem,
  GridNull,
  Icon,
  Row,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";

import type { ParkingInfoHeaderRow_orgLocation$key } from "./__generated__/ParkingInfoHeaderRow_orgLocation.graphql";

export const ParkingInfoHeaderRow = ({
  parkingLocations
}: FragmentContainerInnerComponentProps<ParkingInfoHeaderRow_orgLocation$key>) => {
  const parkingLocation = parkingLocations?.edges?.[0]?.node;

  if (!parkingLocation || LocationUpdates.isDisabled()) return <GridNull />;

  return (
    <Stack size="none">
      <Text weight="bold">Parking Location</Text>
      <Row gap="slim">
        <Column>
          <DescriptionItem
            title={parkingLocation.place.streetAddress ?? ""}
            numberOfLines={1}
            onTitlePress={() =>
              showLocation({
                latitude: parkingLocation.location?.latitude ?? "",
                longitude: parkingLocation.location?.longitude ?? "",
                googlePlaceId: parkingLocation.place?.id ?? "",
                alwaysIncludeGoogle: true,
                dialogTitle: "Get Directions",
                dialogMessage: "Select a preferred navigation app"
              })
            }
          />
        </Column>
        <Column justifyContent="center">
          <Icon
            name="diamond-turn-right"
            size="small"
            color="link"
            variant="solid"
          />
        </Column>
      </Row>
    </Stack>
  );
};

export default createRelayFragmentContainer<ParkingInfoHeaderRow_orgLocation$key>(
  graphql`
    fragment ParkingInfoHeaderRow_orgLocation on OrganizationLocation {
      parkingLocations(first: 1) {
        edges {
          node {
            place {
              id
              streetAddress
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  `,
  ParkingInfoHeaderRow
);
