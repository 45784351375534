/**
 * @generated SignedSource<<ed8e35bcbe1868abbc1752730aa5d366>>
 * @relayHash 05988526867f89616926d5cb5d15eff3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:MQW53l-fabpXabXpk1HhBpln2eDmK7KCPIGZr0Lu-mU

import { ConcreteRequest } from 'relay-runtime';
export type browseGigsSeriesQuery$variables = {
  crisisId: string;
  hasCrisisId: boolean;
};
export type browseGigsSeriesQuery$data = {
  readonly crisis?: {
    readonly id?: string;
    readonly title?: string;
  } | null | undefined;
};
export type browseGigsSeriesQuery = {
  response: browseGigsSeriesQuery$data;
  variables: browseGigsSeriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "crisisId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasCrisisId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "crisisId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "browseGigsSeriesQuery",
    "selections": [
      {
        "condition": "hasCrisisId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "crisis",
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "type": "Crisis",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "browseGigsSeriesQuery",
    "selections": [
      {
        "condition": "hasCrisisId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "crisis",
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Crisis",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "qpid:MQW53l-fabpXabXpk1HhBpln2eDmK7KCPIGZr0Lu-mU",
    "metadata": {},
    "name": "browseGigsSeriesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "213ede3f28b332f4820e89a8556596da";

export default node;
