/**
 * @generated SignedSource<<a2f18671b71cc8bb20c0d3d52f33328a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type RemoteApplicationIconType = "FONT_AWESOME" | "SVG" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GigTags_gigLike$data = {
  readonly acceptsTips: boolean;
  readonly boosted?: boolean;
  readonly customTags?: ReadonlyArray<{
    readonly color: string | null | undefined;
    readonly content: string;
    readonly icon: {
      readonly data: string;
      readonly type: RemoteApplicationIconType;
    };
  }>;
  readonly estimatedMileage: number | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly isRecurring?: boolean;
  readonly slots?: number | null | undefined;
  readonly " $fragmentType": "GigTags_gigLike";
};
export type GigTags_gigLike$key = {
  readonly " $data"?: GigTags_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"GigTags_gigLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "RemoteApplicationTag",
  "kind": "LinkedField",
  "name": "customTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationIcon",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slots",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GigTags_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptsTips",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedMileage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "boosted",
          "storageKey": null
        },
        (v2/*: any*/),
        (v0/*: any*/)
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "88246448a37e72b88171f5b72e2c1016";

export default node;
