import { Text } from "@gigsmart/atorasu";
import {
  StyledCheckbox,
  type StyledCheckboxProps,
  StyledLink
} from "@gigsmart/katana";
import React from "react";
import { Linking, Platform } from "react-native";

interface Props extends Omit<StyledCheckboxProps, "title"> {
  stripeTermsAccepted: boolean;
  handleStripeTermsAccepted: () => void;
}

const StripeTermsOfService = ({
  stripeTermsAccepted,
  handleStripeTermsAccepted,
  ...props
}: Props) => {
  return (
    <StyledCheckbox
      {...props}
      testID="accept-stripe-checkbox"
      checked={stripeTermsAccepted}
      onPress={handleStripeTermsAccepted}
      title={
        <Text>
          I have read and agree to Stripe's{" "}
          <StyledLink
            onPress={async () =>
              Platform.OS === "web"
                ? window.open("https://stripe.com/us/ssa", "_blank")
                : await Linking.openURL("https://stripe.com/us/ssa")
            }
          >
            Terms of Use
          </StyledLink>{" "}
          and{" "}
          <StyledLink
            onPress={async () =>
              Platform.OS === "web"
                ? window.open("https://stripe.com/us/privacy", "_blank")
                : await Linking.openURL("https://stripe.com/us/privacy")
            }
          >
            Privacy Policy
          </StyledLink>
          .
        </Text>
      }
    />
  );
};

export default StripeTermsOfService;
