import { Divider, Spacer, Text } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { SolidButton, StyledView } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";

import type { accountBalance_worker$key } from "./__generated__/accountBalance_worker.graphql";

interface Props {
  worker: accountBalance_worker$key | null | undefined;
}

export default ({ worker: workerFragmentRef }: Props) => {
  const history = useHistory();
  const worker = useRelayFragment(
    graphql`
      fragment accountBalance_worker on Worker {
        walletBalance
        payableAccount {
          paymentMethod {
            __typename
          }
        }
      }
    `,
    workerFragmentRef
  );

  const workerBalance = currency.toFloat(worker?.walletBalance);
  const renderTransferButton = () => {
    if (!worker?.payableAccount?.paymentMethod) {
      const warningText = workerBalance
        ? "A debit card or bank account is required to transfer money"
        : "A payment method is required to transfer money";
      return (
        <>
          <Divider />
          <Spacer />
          <Text color="neutral" align="center" variant="note">
            {warningText}
          </Text>
        </>
      );
    }

    if (workerBalance > 0) {
      return (
        <>
          <Divider />
          <Spacer />
          <SolidButton
            title="Transfer"
            onPress={() => history.push("/wallet/transfer")}
          />
        </>
      );
    }
  };
  return (
    <StyledView color="transparent">
      <StyledView color="white">
        <Text
          color={workerBalance > 0 ? "success" : "black"}
          variant="title"
          weight="semibold"
          align="center"
        >
          {currency.humanize(worker?.walletBalance)}
        </Text>
        <Spacer />
        <Text align="center">Account Balance</Text>
        {renderTransferButton()}
      </StyledView>
    </StyledView>
  );
};
