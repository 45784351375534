/**
 * @generated SignedSource<<da959a5a54f37c16f84c658f6c13b4a3>>
 * @relayHash 0bef60a54347132fe716810a77ff8e17
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:mpY-Fr_f54fbvLhx-anjNM3C6zO-l6oChYa1I78uenI

import { ConcreteRequest } from 'relay-runtime';
export type GeocodeLayer = "ADDRESS" | "LOCALITY" | "PLACE" | "POSTAL_CODE" | "VENUE" | "%future added value";
export type PlaceProvider = "ALL" | "GOOGLE_MAPS" | "MAPBOX" | "PELIAS" | "%future added value";
export type ReverseGeocodeInput = {
  layer?: GeocodeLayer | null | undefined;
  location?: Coordinates | null | undefined;
  provider?: PlaceProvider | null | undefined;
};
export type Coordinates = {
  latitude: number;
  longitude: number;
};
export type hooksUseCurrentGeocodedLocationSilentQuery$variables = {
  input: ReverseGeocodeInput;
};
export type hooksUseCurrentGeocodedLocationSilentQuery$data = {
  readonly reverseGeocode: ReadonlyArray<{
    readonly place: {
      readonly area: string | null | undefined;
      readonly postalCode: string | null | undefined;
    };
  }>;
};
export type hooksUseCurrentGeocodedLocationSilentQuery = {
  response: hooksUseCurrentGeocodedLocationSilentQuery$data;
  variables: hooksUseCurrentGeocodedLocationSilentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksUseCurrentGeocodedLocationSilentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationSearchResult",
        "kind": "LinkedField",
        "name": "reverseGeocode",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "place",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hooksUseCurrentGeocodedLocationSilentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationSearchResult",
        "kind": "LinkedField",
        "name": "reverseGeocode",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "place",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:mpY-Fr_f54fbvLhx-anjNM3C6zO-l6oChYa1I78uenI",
    "metadata": {},
    "name": "hooksUseCurrentGeocodedLocationSilentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "550b4e2024ceaedc0afe8982dc228651";

export default node;
