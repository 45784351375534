import React from "react";
import { View } from "react-native";

import { StyledIcon } from "../icon";
import { createStyles } from "../style";
import { StyledText } from "../text";

const styles = createStyles(({ color, font }) => ({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: color.blue,
    backgroundColor: color.white
  },
  label: {
    fontSize: font.size.largest,
    lineHeight: font.size.largest * font.lineHeight.body,
    color: color.blue
  },
  icon: {
    fontSize: font.size.largest,
    color: color.blue,
    marginRight: 10
  },
  pushContent: {
    marginRight: "auto"
  }
}));

interface Props {
  paymentLabel: string;
  timeLabel: string;
  inverse?: boolean;
}

export default ({ paymentLabel, timeLabel, inverse }: Props) => (
  <View style={styles.container}>
    {!inverse && (
      <StyledText style={[styles.label, styles.pushContent]}>
        {paymentLabel}
      </StyledText>
    )}
    <StyledIcon name="clock" style={styles.icon} />
    <StyledText style={[styles.label, inverse && styles.pushContent]}>
      {timeLabel}
    </StyledText>
    {inverse && <StyledText style={styles.label}>{paymentLabel}</StyledText>}
  </View>
);
