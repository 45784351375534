import { identify } from "@gigsmart/dekigoto/hooks";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import { useUserHasPendingConsents } from "@gigsmart/seibutsu/user-consent/UserDocumentConsentCheck";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import type { GeolocationProviderLocationSubscription } from "./__generated__/GeolocationProviderLocationSubscription.graphql";
import type { GeolocationProviderQuery } from "./__generated__/GeolocationProviderQuery.graphql";
import type { GeolocationProviderSubscription } from "./__generated__/GeolocationProviderSubscription.graphql";
import type { GeolocationProvider_worker$key } from "./__generated__/GeolocationProvider_worker.graphql";
import type { GeolocationProvider_workerLocation$key } from "./__generated__/GeolocationProvider_workerLocation.graphql";
import useTrackLocation from "./useTrackLocation";

interface StoredLocation {
  readonly accuracy: number | null;
  readonly area: string | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly postalCode: string | null;
  readonly timestamp: number;
}

const Context = createContext<StoredLocation>({
  latitude: 0,
  longitude: 0,
  timestamp: 0,
  accuracy: null,
  area: null,
  postalCode: null
});

export function useGeolocation(update = true) {
  const { latitude, longitude, timestamp, accuracy, area, postalCode } =
    useContext(Context);

  const [geolocation, setGeolocation] = useState({
    latitude,
    longitude,
    timestamp,
    accuracy,
    area,
    postalCode
  });

  useEffect(() => {
    if (update)
      setGeolocation({
        latitude,
        longitude,
        timestamp,
        accuracy,
        area,
        postalCode
      });
  }, [update, latitude, longitude, timestamp, accuracy, area, postalCode]);

  return geolocation;
}

interface Props {
  children: React.ReactNode;
  hasFinishedOnboarding: boolean;
}

export const GeolocationProvider = createSuspendedQueryContainer<
  GeolocationProviderQuery,
  Props
>(
  function GeolocationProvider({ response, hasFinishedOnboarding, children }) {
    const userHasPendingConsents = useUserHasPendingConsents();
    const shouldRequest = hasFinishedOnboarding && !userHasPendingConsents;

    const worker = useRelayFragment<GeolocationProvider_worker$key>(
      graphql`
        fragment GeolocationProvider_worker on Worker @argumentDefinitions(includeArea: { type: "Boolean" }) {
          id
          uuid
          typeName: __typename
          currentEngagement {
            gig {
              currentState {
                name
              }
            }
          }
          currentLocation @cache(ttl: 600) {
            ...GeolocationProvider_workerLocation @arguments(includeArea: $includeArea)
          }
        }
      `,
      response?.viewer ?? null
    );

    const workerLocation =
      useRelayFragment<GeolocationProvider_workerLocation$key>(
        graphql`
          fragment GeolocationProvider_workerLocation on WorkerLocation @argumentDefinitions(includeArea: { type: "Boolean", defaultValue: false }) {
            id
            latitude
            longitude
            reportedAt
            accuracy
            rationale
            ... on WorkerLocation @include(if: $includeArea) {
              area @cache(ttl: 600)
              postalCode @cache(ttl: 600)
            }
          }
        `,
        worker?.currentLocation ?? null
      );

    useRelaySubscription<GeolocationProviderSubscription>(
      graphql`
        subscription GeolocationProviderSubscription($workerId: ID!) {
          workerUpdated(workerId: $workerId) {
            worker {
              ...GeolocationProvider_worker
            }
          }
        }
      `,
      { workerId: String(worker?.id) },
      { subscribe: !!worker }
    );

    useRelaySubscription<GeolocationProviderLocationSubscription>(
      graphql`
        subscription GeolocationProviderLocationSubscription($workerId: ID!) {
          workerLocationAdded(sourceId: $workerId) {
            newWorkerLocationEdge {
              node {
                worker {
                  currentLocation {
                    ...GeolocationProvider_workerLocation
                  }
                }
              }
            }
          }
        }
      `,
      { workerId: String(worker?.id) },
      {
        subscribe: !!worker?.id,
        updater: (store) => {
          const mutation = store.getRootField("workerLocationAdded");
          const newWorkerLocation = mutation
            ?.getLinkedRecord("newWorkerLocationEdge")
            ?.getLinkedRecord("node")
            ?.getLinkedRecord("worker")
            ?.getLinkedRecord("currentLocation");

          const workerNode = store.get(String(worker?.id));
          if (!workerNode || !newWorkerLocation) return;
          workerNode.setLinkedRecord(newWorkerLocation, "currentLocation");
        }
      }
    );

    useEffect(() => {
      if (worker?.uuid)
        identify(worker?.uuid, {
          latitude: workerLocation?.latitude,
          longitude: workerLocation?.longitude
        });
    }, [workerLocation?.latitude, workerLocation?.longitude, worker?.uuid]);

    const hasActiveGig = ["ACTIVE", "IN_PROGRESS"].includes(
      worker?.currentEngagement?.gig?.currentState?.name ?? ""
    );
    useTrackLocation(shouldRequest, hasActiveGig);

    const value = useMemo(
      () => ({
        latitude: workerLocation?.latitude ?? 0,
        longitude: workerLocation?.longitude ?? 0,
        postalCode: workerLocation?.postalCode ?? "",
        area: workerLocation?.area ?? "",
        accuracy: workerLocation?.accuracy ?? 0,
        timestamp: new Date(workerLocation?.reportedAt ?? 0).getTime()
      }),
      [workerLocation]
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
  {
    query: graphql`
      query GeolocationProviderQuery {
        viewer {
          ... on Worker {
            ...GeolocationProvider_worker @arguments(includeArea: true)
          }
        }
      }
    `,
    variables: {}
  }
);
