import {
  Button,
  Column,
  ContentArea,
  FooterSpacer,
  Icon,
  Row,
  Spacer,
  Stack,
  StepIndicator,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import React from "react";
import type { Step } from "./ResponsiveStepper";
import { ResponsiveStepperBtnPortal, ResponsiveStepperPortal } from "./portal";

interface Props {
  showStepIndicator: boolean;
}

export default function VStepLayout<TStep extends Step>({
  showStepIndicator
}: Props) {
  const { activeStep, activeStepNum, steps, stepsData } = useStepper<
    TStep,
    {}
  >();
  const styles = useStyles(({ getFontSize, getUnits }) => ({
    icon: { ...getFontSize(6, 1), minWidth: 34 },
    btnContainer: { minWidth: 198 }
  }));
  if (!activeStep) return null;

  const {
    stepTitle,
    stepTitleIcon,
    component: content,
    stickyFooter,
    stepDescription
  } = activeStep;
  const inlineButtonContent = (
    <ResponsiveStepperBtnPortal.Exit>
      {({ accessory, accessoryInline, ...btnProps }) => (
        <Stack
          horizontal={accessoryInline}
          {...{
            [accessoryInline ? "justifyContent" : "alignItems"]: "flex-end"
          }}
        >
          {accessory}
          <Column style={[styles.btnContainer]}>
            <Button {...btnProps} />
          </Column>
        </Stack>
      )}
    </ResponsiveStepperBtnPortal.Exit>
  );

  return (
    <ResponsiveStepperPortal.Exit>
      {({ showBtn }) => (
        <>
          <Spacer size="slim" />
          <Row fill>
            {showStepIndicator && <StepIndicator layout="vertical" />}
            <Column fill>
              <Surface>
                <ContentArea size="xlarge" variant="xlarge">
                  <Stack horizontal>
                    {!!stepTitleIcon && (
                      <Icon
                        style={styles.icon}
                        name={stepTitleIcon}
                        size="default"
                        color="primary"
                        variant="solid"
                      />
                    )}
                    <Column fill>
                      <Stack horizontal size="compact" alignItems="center">
                        <ResponsiveStepperPortal.Exit>
                          {({ stepTitle: stepTitleOverride }) => (
                            <Text
                              variant="titleLg"
                              color="primary"
                              weight="semibold"
                            >
                              {stepTitleOverride ?? stepTitle}
                            </Text>
                          )}
                        </ResponsiveStepperPortal.Exit>
                        {showStepIndicator && (
                          <Stack horizontal size="compact" alignItems="center">
                            <Text color="primary">•</Text>
                            <Text color="primary">
                              Step&nbsp;{activeStepNum}/{steps.length}
                            </Text>
                          </Stack>
                        )}
                      </Stack>
                      <Spacer />
                      {stepDescription && (
                        <Text color="primary" weight="normal" variant="header">
                          {typeof stepDescription === "function"
                            ? stepDescription(stepsData)
                            : stepDescription}
                        </Text>
                      )}
                      <Spacer />
                      {content}
                      <Spacer size="xlarge" />
                      {showBtn && !stickyFooter && inlineButtonContent}
                    </Column>
                  </Stack>
                </ContentArea>
              </Surface>
              <FooterSpacer />
            </Column>
          </Row>
        </>
      )}
    </ResponsiveStepperPortal.Exit>
  );
}
