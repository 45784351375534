import {
  Button,
  ContentArea,
  HighlightedReminder,
  Icon,
  Row,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import {
  type TabConfig,
  TabItemLabel,
  TabLayout,
  confirmPrompt
} from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { compact } from "lodash";
import React, { useEffect, useState } from "react";

import EngagementTimesheetMileageBox from "./EngagementTimesheetMileageBox";
import TimesheetList from "./TimesheetList";

import type { TimesheetTabs_engagement$key } from "./__generated__/TimesheetTabs_engagement.graphql";

interface Props {
  variant: "requester" | "worker";
  inset?: boolean;
  enableEdit?: boolean;
  onResetTimesheet?: () => void;
}

type TabKeys = "updated" | "original" | "approved";

export function TimesheetTabs({
  original,
  workerTimesheet,
  requesterTimesheet,
  currentState,
  inactiveCancelStates,
  gig,
  workerEta,
  endsAt,
  variant,
  inset = true,
  enableEdit = true,
  onResetTimesheet
}: FragmentContainerInnerComponentProps<TimesheetTabs_engagement$key, Props>) {
  const [active, setActive] = useState<TabKeys>();
  const isPendingTimesheet =
    currentState?.name === "PENDING_TIMESHEET_APPROVAL";
  const promptResetTimesheet = () => {
    if (!isPendingTimesheet) return;
    confirmPrompt({
      title: "Reset to Original Timesheet",
      subTitle:
        "Are you sure you want to reset to the original timesheet? Any edits you have made to the timesheet or total time worked will be removed.",
      yesLabel: "Yes, Reset",
      cancelLabel: "No, Cancel",
      onDo: () => onResetTimesheet?.()
    });
  };

  function getApprovedTimesheetRef() {
    if (original?.isApproved) return original;
    if (workerTimesheet?.isApproved) return workerTimesheet;
    if (requesterTimesheet?.isApproved) return requesterTimesheet;
    return null;
  }

  const approvedTimesheetRef = getApprovedTimesheetRef();
  const updatedTimesheetRef =
    variant === "worker" ? workerTimesheet : requesterTimesheet;

  const isApproved = !!approvedTimesheetRef;
  const isUpdated = !!updatedTimesheetRef?.isOverridden;
  const isFixed =
    updatedTimesheetRef?.paymentStyle === "FIXED_HOURS" ||
    updatedTimesheetRef?.paymentStyle === "FIXED_AMOUNT";

  const neverStarted = original?.startedCount?.totalCount === 0;

  const isCreated =
    (currentState?.action === "INACTIVE_CANCEL" ||
      (inactiveCancelStates?.totalCount ?? 0) > 0) &&
    updatedTimesheetRef?.isOverridden;

  const tabs: Array<TabConfig<TabKeys>> = isApproved
    ? [{ key: "approved" }]
    : compact([
        (isFixed || isUpdated) && { key: "updated" },
        !(isCreated && !updatedTimesheetRef?.editable) && { key: "original" }
      ]);

  useEffect(() => {
    const shouldReset = !tabs.find((d) => d.key === active);
    if (shouldReset) setActive(tabs[0]?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.length]);
  const renderTabMessage = (key: TabKeys) => {
    let message = "";

    if (key === "updated") {
      if (!isCreated) {
        message = isFixed
          ? "* Total time has been manually entered and does not reflect a timesheet."
          : "* Total time reflects this updated timesheet.";
      }
    } else if (key === "original") {
      if (isFixed || isUpdated) {
        message =
          isFixed && !isCreated
            ? "* Total time DOES NOT reflect this timesheet. Total time reflects the manually adjusted total time."
            : "* Total time DOES NOT reflect this timesheet. Total time reflects this Updated Timesheet.";
      }
    }

    if (message) {
      return key === "original" && isCreated ? (
        <ContentArea>
          <Stack alignItems="center">
            <Text>No Timesheet Recorded</Text>
            <Text>{message}</Text>
          </Stack>
        </ContentArea>
      ) : (
        <>
          <Spacer size="medium" />
          <Text align="center">{message}</Text>
        </>
      );
    }

    return null;
  };
  return (
    <ContentArea size="none" variant={inset ? "standard" : "none"}>
      <Surface variant="outline">
        <TabLayout
          tabItemsHideSingle
          active={active}
          onSelectTab={setActive}
          tabs={tabs}
          renderLabel={({ tab, isActive }) => {
            const label = tab.key.toUpperCase();
            if (tab.key === "updated" && updatedTimesheetRef?.editable) {
              return (
                <Row alignItems="center">
                  <Icon
                    name="check"
                    color={isActive ? "success" : "neutral"}
                    variant="solid"
                    size="small"
                  />
                  <TabItemLabel active={isActive} label={` ${label}`} />
                </Row>
              );
            }

            return label;
          }}
          renderContent={(tab) => {
            const showResetButton =
              enableEdit &&
              tab.key === "updated" &&
              updatedTimesheetRef?.editable &&
              isPendingTimesheet &&
              !isCreated;

            const showTabList =
              tab.key === "updated" || tab.key === "approved" || !isCreated;
            return (
              <ContentArea testID={`${tab.key}-tab-content`}>
                <Stack>
                  {tab.key === "updated" &&
                    updatedTimesheetRef?.editable &&
                    variant === "worker" && (
                      <HighlightedReminder
                        icon="check"
                        variant="success"
                        header="Updated Timesheet is Selected"
                        note="This timesheet will be submitted to the Requester for review."
                      />
                    )}
                  {showTabList && (
                    <TimesheetList
                      variant={variant}
                      workerEta={workerEta}
                      endsAt={endsAt}
                      fragmentRef={
                        tab.key === "approved"
                          ? approvedTimesheetRef
                          : tab.key === "original"
                            ? original
                            : updatedTimesheetRef
                      }
                    />
                  )}
                  {renderTabMessage(tab.key)}
                  {variant === "worker" &&
                    !!gig?.estimatedMileage &&
                    !["WORKING", "PAUSED"].includes(currentState?.name) &&
                    !neverStarted && (
                      <EngagementTimesheetMileageBox
                        final={currentState?.name === "ENDED"}
                        gigEstimatedMileage={gig?.estimatedMileage}
                        timesheetEstimatedMileage={
                          tab.key === "original"
                            ? original?.estimatedMileage
                            : tab.key === "approved"
                              ? approvedTimesheetRef?.estimatedMileage
                              : updatedTimesheetRef?.estimatedMileage
                        }
                        gigDuration={
                          tab.key === "original"
                            ? original?.totalDurationWorked
                            : tab.key === "approved"
                              ? approvedTimesheetRef?.totalDurationWorked
                              : updatedTimesheetRef?.totalDurationWorked
                        }
                        variant={variant}
                      />
                    )}
                  {showResetButton && (
                    <Button
                      testID="tabbed-timesheet-clear"
                      variant="clear"
                      label="Reset to Original Timesheet"
                      icon="redo"
                      onPress={promptResetTimesheet}
                    />
                  )}
                </Stack>
              </ContentArea>
            );
          }}
        />
      </Surface>
    </ContentArea>
  );
}

export default createRelayFragmentContainer<
  TimesheetTabs_engagement$key,
  Props
>(
  graphql`
    fragment TimesheetTabs_engagement on Engagement {
      workerEta
      endsAt
      currentState {
        action
        name
      }
      gig {
        estimatedMileage
        timezone
      }
      inactiveCancelStates: states(
        first: 0
        query: "WHERE action = INACTIVE_CANCEL"
      ) {
        totalCount
      }
      original: timesheet(variant: SYSTEM) {
        isApproved
        estimatedMileage
        totalDurationWorked
        startedCount: states(first: 0, query: "WHERE action = START") {
          totalCount
        }
        ...TimesheetList_timesheet @arguments(overridden: false)
      }
      workerTimesheet: timesheet(variant: WORKER) {
        editable
        isApproved
        isOverridden
        paymentStyle
        estimatedMileage
        totalDurationWorked
        ...TimesheetList_timesheet @arguments(overridden: true)
      }
      requesterTimesheet: timesheet(variant: REQUESTER) {
        editable
        isApproved
        isOverridden
        paymentStyle
        estimatedMileage
        totalDurationWorked
        ...TimesheetList_timesheet @arguments(overridden: true)
      }
    }
  `,
  TimesheetTabs
);
