import {
  MaskedTextInput,
  Platform,
  TextInput,
  type ValidMask
} from "@gigsmart/atorasu";
import { type FormFieldOptions, Validator, useFormField } from "@gigsmart/fomu";
import { numeric } from "@gigsmart/isomorphic-shared/iso";
import { compact } from "lodash";
import React, { type ComponentProps } from "react";

interface Props
  extends FormFieldOptions<string>,
    Omit<
      ComponentProps<typeof TextInput>,
      "value" | "errors" | "onBlur" | "onFocus" | "testID"
    > {
  format?: (text: string) => string;
  testID?: string;
  mask?: ValidMask;
}

export default function FomuTextInput({
  name,
  format,
  min,
  max,
  validates,
  sensitive,
  shouldShowErrors,
  mask,
  defaultValue,
  onChangeText,
  testID,
  ...inputProps
}: Props) {
  const { setValue, value, submit, errors, showErrors, triggerFocus } =
    useFormField<string>({
      name,
      validates: compact([
        typeof max === "number" &&
          Validator.length({
            max,
            message: `You have exceeded the maximum character count of ${numeric.humanize(
              max
            )}`
          }),
        typeof min === "number" &&
          Validator.length({
            min,
            message: `You have not reached the minimum character count of ${numeric.humanize(
              min
            )}`
          }),
        ...(Array.isArray(validates) ? validates : [validates])
      ]),
      sensitive,
      shouldShowErrors
    });

  const extraProps = {
    min,
    max,
    errors,
    onSubmitEditing: Platform.OS === "web" ? submit : undefined,
    value: defaultValue ?? value ?? "",
    onChangeText: (text: string) => {
      if (format) text = format(text);
      onChangeText?.(text);
      setValue(text);
    },
    onBlur: showErrors,
    onFocus: triggerFocus
  };

  return mask ? (
    <MaskedTextInput
      testID={testID ?? `${name}-text-input`}
      mask={mask}
      {...inputProps}
      {...extraProps}
    />
  ) : (
    <TextInput
      testID={testID ?? `${name}-text-input`}
      {...inputProps}
      {...extraProps}
    />
  );
}
