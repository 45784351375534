import {
  ContentArea,
  ContentRow,
  Divider,
  InlineButton,
  Row,
  Surface,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { WorkerGigReceipt } from "../../engagement/WorkerGigReceipt";
import type { ApprovedEarningsModalRow_engagement$key } from "./__generated__/ApprovedEarningsModalRow_engagement.graphql";

export function ApprovedEarningsModalRow({
  paymentInfo,
  currentState
}: FragmentContainerInnerComponentProps<ApprovedEarningsModalRow_engagement$key>) {
  const handleShowModal = () => {
    showModal({
      eventContext: "DisputeAdditionalPaymentInfoModal",
      title: "Approved Earnings",
      children: (
        <Surface variant="outline">
          <ContentArea>
            <Text>
              This is your Total Earnings that was approved by the Requester.
            </Text>
          </ContentArea>
          <Divider />
          <WorkerGigReceipt
            paymentInfo={paymentInfo}
            currentState={currentState}
          />
        </Surface>
      ),
      actions: [{ label: "Close", testID: "close" }]
    });
  };
  return (
    <Surface>
      <ContentRow>
        <Row justifyContent="space-between" alignItems="center" fill>
          <Text>
            <Text weight="semibold">Approved Earnings:</Text>{" "}
            <Text weight="semibold" color="neutral">
              {currency.humanize(paymentInfo?.netPay)}
            </Text>
          </Text>
          <InlineButton
            label="View"
            onPress={handleShowModal}
            testID="view-earnings-btn"
          />
        </Row>
      </ContentRow>
    </Surface>
  );
}

export default createRelayFragmentContainer<ApprovedEarningsModalRow_engagement$key>(
  graphql`
    fragment ApprovedEarningsModalRow_engagement on Engagement {
      currentState {
        name
      }
      paymentInfo {
        billableDuration
        additionalPay
        serviceFees {
          hourlyRate
          amount
          feeType
        }
        basePay
        netPay
        payRate
      }
    }
  `,
  ApprovedEarningsModalRow
);
