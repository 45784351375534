import React from "react";
import { View } from "react-native";
import { type IconName, IconText, StyledIcon } from "../icon";
import { useStyles } from "../style";

interface Props {
  title: string;
  iconName?: IconName;
  tintColor?: string;
  isActive?: boolean;
}
const AccordionHeader = ({
  title,
  iconName,
  tintColor = "blue",
  isActive = false
}: Props) => {
  const { styles } = useStyles(({ units, color }) => ({
    container: {
      flexDirection: "row",
      minHeight: 44,
      alignItems: "center"
    },
    text: { flex: 1 }
  }));

  return (
    <View style={styles.container}>
      <IconText
        style={styles.text}
        alignItems="center"
        tintColor={tintColor}
        iconName={iconName}
        variant="h4"
      >
        {title}
      </IconText>
      <StyledIcon
        color={tintColor}
        name={isActive ? "chevron-up" : "chevron-down"}
        variant="solid"
        size={14}
      />
    </View>
  );
};

export default AccordionHeader;
