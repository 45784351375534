import {
  Button,
  Column,
  ContentArea,
  Spacer,
  Stack,
  Text,
  toast
} from "@gigsmart/atorasu";
import { captureError } from "@gigsmart/dekigoto";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { checkIsWorkerVerified } from "@gigsmart/isomorphic-shared/worker";
import { useHistory } from "@gigsmart/kaizoku";
import { confirmPrompt } from "@gigsmart/katana";
import {
  graphql,
  useRelayFragment,
  useRelayMutation,
  useRelaySubscription
} from "@gigsmart/relay";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "@gigsmart/seibutsu/gig-series/WorkerLatestArrivalTimeReminder";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import React, { type ReactNode } from "react";

import {
  getDistanceContent,
  getPaymentContent
} from "../../available-gig/cards/content";
import EngagementEntry from "./engagement-entry";
import TopAccessory from "./top-accessory";

import type { offeredEntryEngagementMutation } from "./__generated__/offeredEntryEngagementMutation.graphql";
import type { offeredEntrySubscription } from "./__generated__/offeredEntrySubscription.graphql";
import type {
  EngagementStateAction,
  offeredEntry_engagement$key
} from "./__generated__/offeredEntry_engagement.graphql";

const CONFIRMATION_MAP: { [key in EngagementStateAction]?: string } = {
  REJECT: "Are you sure you want to reject this Gig offer?"
};

interface Props {
  disabled?: boolean;
  isEnrolled?: boolean;
  onPress?: () => void;
  hasMultipleLocations?: boolean;
  gigCount?: number;
  estimatedPaymentRange?: { min: string; max: string };
  distanceRange?: { min?: number | null; max?: number | null };
  engagementRef: offeredEntry_engagement$key | null | undefined;
  bottomAccessory?: ReactNode;
}

const OfferedEntry = ({
  disabled,
  isEnrolled,
  onPress,
  hasMultipleLocations,
  gigCount = 1,
  estimatedPaymentRange,
  distanceRange,
  engagementRef,
  bottomAccessory
}: Props) => {
  const history = useHistory();
  const user = useCurrentUser();
  const engagement = useRelayFragment(
    graphql`
      fragment offeredEntry_engagement on Engagement {
        ...engagementEntry_engagement
        id
        availableActions
        workerDistance
        gig {
          isPosted
          requiredReportTypes
          ...WorkerLatestArrivalTimeReminder_gig
        }
        currentState {
          name
        }
        gigType
        estimatedPayment {
          payRate
          netPay
        }
      }
    `,
    engagementRef
  );
  const [transitionEngagement, { loading }] =
    useRelayMutation<offeredEntryEngagementMutation>(graphql`
      mutation offeredEntryEngagementMutation(
        $input: TransitionEngagementInput!
      ) {
        transitionEngagement(input: $input) {
          engagement {
            id
            availableActions
            currentState {
              name
            }
            ...offeredEntry_engagement
          }
        }
      }
    `);
  const engagementId = engagement?.id ?? "";
  useRelaySubscription<offeredEntrySubscription>(
    graphql`
      subscription offeredEntrySubscription($engagementId: ID!) {
        engagementUpdated(engagementId: $engagementId) {
          engagement {
            id
            ...offeredEntry_engagement
          }
        }
      }
    `,
    { engagementId },
    { subscribe: !!engagementId }
  );

  if (!engagement) return null;

  const isPosted = engagement.gig?.isPosted;
  const isProject = engagement.gigType === "PROJECT";
  const gigRequiredReports: string[] = engagement.gig
    ?.requiredReportTypes as string[];

  const handleAction = (action: EngagementStateAction) => {
    if (action === "ACCEPT" && !isEnrolled) {
      history.push(`/gigs/${engagement.id}/accept`);
      return;
    }

    const onConfirm = () =>
      transitionEngagement(
        { input: { engagementId, action } },
        {
          onSuccess: () => {
            if (isProject && action === "REJECT") {
              toast.success("The Project Gig Offer was successfully rejected");
            }
          },
          onError: (err) => {
            captureError(err, "error");
            toast.error("Something went wrong.");
          }
        }
      );

    const actionTitle = capitalize(action);
    const subTitle = CONFIRMATION_MAP[action];
    if (subTitle) {
      confirmPrompt({
        title: `${actionTitle} Gig`,
        subTitle,
        onDo: onConfirm,
        yesLabel: `Yes, ${actionTitle}`,
        cancelLabel: `No, Do not ${actionTitle}`
      });
    } else {
      onConfirm();
    }
  };

  const paymentNode = getPaymentContent({
    payRate: engagement.estimatedPayment?.payRate,
    netPay: engagement.estimatedPayment?.netPay,
    estimatedPaymentRange,
    isProject
  });
  const distanceNode = getDistanceContent(
    distanceRange ?? engagement.workerDistance
  );

  const renderHeaderText = (): JSX.Element => {
    if (
      gigRequiredReports?.findIndex((report) =>
        [
          "CONTINUOUS_BACKGROUND_CHECK",
          "BACKGROUND_CHECK",
          "MOTOR_VEHICLE_CHECK"
        ].includes(report)
      ) > -1
    ) {
      const isWorkerVerified: boolean = checkIsWorkerVerified(
        gigRequiredReports,
        user
      );

      if (!isWorkerVerified) {
        return (
          <Column fill gap="medium">
            <Text>You have been offered a Verified Gig!</Text>
            <Text weight="bold">
              In order to be hired on this Gig, your Worker Verification will
              need to be completed. Accept this offer to begin verification.
            </Text>
          </Column>
        );
      }
    }

    return (
      <Column fill={1}>
        <Text weight="bold">
          {isPosted
            ? `Congratulations, you have received ${
                gigCount > 1 ? `${gigCount}` : "a"
              } Shift ${pluralize(
                "offer",
                gigCount
              )}! Please accept to be scheduled.`
            : "Congratulations, you've been invited to a gig! Please accept to be hired."}
        </Text>
      </Column>
    );
  };

  bottomAccessory ??= engagement.availableActions?.length ? (
    <ContentArea size="none">
      <Spacer size="slim" />
      <Stack>
        <Stack horizontal>
          {engagement.availableActions?.slice(0, 2).map((action, i) => (
            <Button
              key={action}
              testID={`${action}-button`}
              size="small"
              outline={i === 0}
              fill
              label={capitalize(action)}
              onPress={() => handleAction(action)}
              disabled={loading}
            />
          ))}
        </Stack>
        {showLatestArrivalReminder(
          engagement.gig,
          engagement.currentState?.name
        ) && (
          <WorkerLatestArrivalTimeReminder
            fragmentRef={engagement.gig}
            isMultiple={false}
            workerStateName={engagement.currentState?.name}
          />
        )}
      </Stack>
      <Spacer />
    </ContentArea>
  ) : null;

  return (
    <EngagementEntry
      engagementRef={engagement}
      hasMultipleLocations={hasMultipleLocations}
      gigCount={gigCount}
      onPress={onPress}
      disabled={disabled}
      addressInfo={distanceNode}
      thumbnail={paymentNode}
      topAccessory={<TopAccessory>{renderHeaderText()}</TopAccessory>}
      bottomAccessory={bottomAccessory}
    />
  );
};

export default OfferedEntry;
