import React from "react";

import { useStyles } from "@gigsmart/atorasu/style";
import ReviewParagraph from "@gigsmart/isomorphic-shared/review/review-paragraph";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { requesterReviewRow_engagementReviewsEdge$key } from "./__generated__/requesterReviewRow_engagementReviewsEdge.graphql";

interface Props {
  fragmentRef?: requesterReviewRow_engagementReviewsEdge$key | null | undefined;
}

export default function RequesterReviewRow({ fragmentRef }: Props) {
  const styles = useStyles(({ getColor }) => ({
    paragraph: {
      borderBottomWidth: 2,
      borderTopWidth: 2,
      marginBottom: 30,
      borderColor: getColor("primary", "fill")
    }
  }));
  const review = useRelayFragment(
    graphql`
      fragment requesterReviewRow_engagementReviewsEdge on EngagementReviewsEdge {
        node {
          skill {
            name
          }
          engagement {
            gig {
              position {
                name
              }
            }
          }
          rating
          review
          insertedAt
          author {
            displayName
          }
        }
      }
    `,
    fragmentRef ?? null
  )?.node;

  return (
    <ReviewParagraph
      disable
      gigEntity={review?.author?.displayName ?? ""}
      gigTitle={
        review?.skill?.name ?? review?.engagement?.gig?.position?.name ?? ""
      }
      rating={review?.rating ?? 5}
      review={review?.review ?? ""}
      reviewDate={review?.insertedAt}
      style={styles.paragraph}
    />
  );
}
