/**
 * @generated SignedSource<<ff02cb7fec372cac0a5e5c59fcf9ca86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type paymentMethod_payableAccount$data = {
  readonly paymentMethod: {
    readonly __typename: string;
    readonly bankName?: string;
    readonly brand?: string;
    readonly last4: string;
  } | null | undefined;
  readonly " $fragmentType": "paymentMethod_payableAccount";
};
export type paymentMethod_payableAccount$key = {
  readonly " $data"?: paymentMethod_payableAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentMethod_payableAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentMethod_payableAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brand",
              "storageKey": null
            }
          ],
          "type": "BankCard",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bankName",
              "storageKey": null
            }
          ],
          "type": "BankAccount",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkerPayableAccount",
  "abstractKey": null
};

(node as any).hash = "70ba18934c33f10db16f6603b1b626fd";

export default node;
