import { DateTime, Stack, Text } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import type { ShiftInfo_conversation$key } from "./__generated__/ShiftInfo_conversation.graphql";

interface Props {
  fragmentRef: ShiftInfo_conversation$key | null | undefined;
}

export default function ShiftInfo({ fragmentRef }: Props) {
  const conversation = useRelayFragment<ShiftInfo_conversation$key>(
    graphql`
      fragment ShiftInfo_conversation on ShiftGroupConversation {
        gig {
          startsAt
          actualStartsAt
          endsAt
          name
        }
      }
    `,
    fragmentRef
  );
  const gig = conversation?.gig;
  return (
    <Stack size="slim" justifyContent="center" fill>
      <Text variant="note" weight="bold" numberOfLines={1}>
        {gig?.name}
      </Text>
      <DateTime
        numberOfLines={1}
        startsAt={gig?.startsAt}
        actualStartsAt={gig?.actualStartsAt}
        endsAt={gig?.endsAt}
        variant="note"
      />
    </Stack>
  );
}
