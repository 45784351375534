import React, { type ReactNode } from "react";
import { Dimensions } from "react-native";

import { Flex, FlexRow } from "../flex";
import { StyledIcon } from "../icon";
import { useStyles } from "../style";
import KatanaTouchableOpacity from "../tappable/katana-touchable-opacity";
import Card from "./card";

const { width } = Dimensions.get("window");

interface Props {
  readonly children: ReactNode | null | undefined;
  readonly collapsed: boolean;
  readonly toggleCollapsed: () => void;
  readonly expandDisabled: boolean | null | undefined;
}

const paddingUnits = width < 375 ? 2 : 4;

export default ({
  children,
  collapsed,
  toggleCollapsed,
  expandDisabled
}: Props) => {
  const { styles } = useStyles(({ units }) => ({
    card: {
      minHeight: units(13),
      marginHorizontal: 0,
      marginVertical: 0,
      zIndex: 2,
      elevation: 3,
      borderRadius: 4,
      paddingLeft: units(paddingUnits),
      paddingVertical: units(1)
    },
    iconContainer: {
      width: width < 375 ? 30 : 46,
      minHeight: units(13),
      padding: units(paddingUnits)
    }
  }));
  return (
    <Card style={styles.card}>
      <FlexRow justifyContent="space-between" alignItems="center" fill={1}>
        <Flex fill={1}>{children}</Flex>
        <KatanaTouchableOpacity
          onPress={toggleCollapsed}
          eventTargetName="Panel Card Header Button"
          testID="panel-card-header-toggle"
        >
          <Flex
            style={styles.iconContainer}
            alignItems="center"
            justifyContent="center"
            fill={1}
          >
            {!expandDisabled && (
              <StyledIcon
                name={collapsed ? "chevron-down" : "chevron-up"}
                variant="solid"
                color="blue"
                size={16}
              />
            )}
          </Flex>
        </KatanaTouchableOpacity>
      </FlexRow>
    </Card>
  );
};
