import { Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Avatar } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import { ScrollView, View } from "react-native";
import type { requesterPrimaryContactQuery } from "./__generated__/requesterPrimaryContactQuery.graphql";

interface Props {
  requesterId: string;
  gigId?: string;
}

export default createSuspendedQueryContainer<
  requesterPrimaryContactQuery,
  Props
>(
  function GigRequesterContactScreen({ response: { node, gig } = {} }) {
    const styles = useStyles(({ getColor }) => ({
      scroll: {
        flex: 1,
        backgroundColor: getColor("background", "fill")
      },
      section: {
        backgroundColor: getColor("background", "fill"),
        padding: 20
      },
      header: {
        alignItems: "center",
        backgroundColor: getColor("surface", "fill"),
        borderTopWidth: 2,
        padding: 20
      },
      title: {
        paddingBottom: 8,
        textAlign: "center"
      },
      bioText: {
        color: getColor("primary", "fill"),
        backgroundColor: getColor("surface", "fill"),
        paddingHorizontal: 40,
        paddingVertical: 20
      },
      bordered: {
        borderBottomWidth: 2,
        borderTopWidth: 1,
        borderTopColor: getColor("neutral", "fill"),
        borderBottomColor: getColor("black", "fill")
      }
    }));
    const {
      displayName,
      biography,
      primaryOrganization: requesterOrg,
      profilePhoto
    } = node ?? {};

    const primaryOrganization = gig?.organization ?? requesterOrg;

    const photoUrl = profilePhoto?.url ?? "";

    return (
      <ScrollView style={styles.scroll}>
        <View style={styles.section}>
          {photoUrl && photoUrl !== "" ? (
            <Avatar width={160} isPublic sourceUri={photoUrl} />
          ) : (
            <Avatar width={160} />
          )}
        </View>
        <View style={styles.header}>
          <Text variant="titleLg" weight="medium" style={styles.title}>
            {primaryOrganization?.name ?? ""}
          </Text>
          <Text variant="title" weight="medium">
            {displayName}
          </Text>
        </View>
        {!!biography && (
          <View style={styles.bordered}>
            <Text style={styles.bioText} variant="bodyLg">
              {biography}
            </Text>
          </View>
        )}
      </ScrollView>
    );
  },
  {
    query: graphql`
      query requesterPrimaryContactQuery(
        $id: ID!
        $gigId: ID!
        $hasGigId: Boolean!
      ) {
        node(id: $id) {
          ... on RequesterLike {
            profilePhoto {
              url
            }
            displayName
            biography
            jobTitle
            primaryOrganization {
              name
            }
          }
        }
        gig: node(id: $gigId) @include(if: $hasGigId) {
          ... on GigLike {
            organization {
              name
            }
          }
        }
      }
    `,
    variables: ({ requesterId, gigId }) => ({
      id: requesterId,
      gigId: gigId ?? "",
      hasGigId: !!gigId
    })
  }
);
