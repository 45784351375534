import {
  ConversationRow as AtlasConversationRow,
  Sticker,
  Text,
  humanizeDate,
  humanizeTime
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React from "react";
import type { ConversationRowSubscription } from "./__generated__/ConversationRowSubscription.graphql";
import type { ConversationRow_conversationLike$key } from "./__generated__/ConversationRow_conversationLike.graphql";

interface Props {
  onConversationPress: (conversationId: string) => void;
  isActive?: boolean;
  isPinned?: boolean;
  viewer?: "worker" | "requester";
}

export function ConversationRow({
  id,
  currentMessage,
  organization,
  worker,
  isActive,
  isPinned,
  onConversationPress,
  engagement,
  participant,
  viewer
}: FragmentContainerInnerComponentProps<
  ConversationRow_conversationLike$key,
  Props
>) {
  const currentUser = useCurrentUser();
  const isWorker = currentUser?.__typename === "Worker";
  const readUntil = participant?.readUntil
    ? DateTime.fromISO(participant?.readUntil)
    : null;
  const sentAt = currentMessage?.insertedAt
    ? DateTime.fromISO(currentMessage?.insertedAt)
    : null;
  const hasUnread = !!(readUntil && sentAt && readUntil < sentAt);
  const hasManyOrgs = (participant?.user?.organizations?.totalCount ?? 0) > 1;
  const handleConversationPress = () => onConversationPress(id ?? "");

  const isMessageSentOnSameDate = sentAt?.hasSame(DateTime.now(), "day");
  const isMessageSentYesterday =
    sentAt && DateTime.now().minus({ days: 1 }).hasSame(sentAt, "day");
  const humanize = isMessageSentOnSameDate ? humanizeTime : humanizeDate;
  const timeStamp = isMessageSentYesterday
    ? "Yesterday"
    : humanize({
        startsAt: sentAt,
        size: "md"
      });

  const counterpart = {
    displayName:
      viewer === "worker"
        ? engagement?.gig?.requester?.displayName ?? organization?.name
        : engagement?.worker?.displayName ?? worker?.displayName,
    photoUrl:
      viewer === "worker"
        ? engagement?.gig?.requester?.profilePhoto?.url ?? organization?.logoUrl
        : engagement?.worker?.profilePhoto?.url ?? worker?.profilePhoto?.url
  };

  const messageAuthor =
    currentMessage?.author?.id === currentUser?.id ||
    currentMessage?.author?.id === currentUser?.requester?.id
      ? "You"
      : currentMessage?.author?.firstName;
  const messagePrefix =
    !isWorker && currentMessage?.author?.__typename === "Worker"
      ? ""
      : `${messageAuthor}: `;

  const messageBody = currentMessage?.attachments?.length
    ? currentMessage?.attachments[currentMessage?.attachments?.length - 1]
        ?.mimeType === "application/pdf"
      ? `${messageAuthor} sent an attachment`
      : `${messageAuthor} sent a photo`
    : `${messagePrefix}${currentMessage?.body}`;

  useRelaySubscription<ConversationRowSubscription>(
    graphql`
      subscription ConversationRowSubscription($conversationId: ID!) {
        conversationUpdated(conversationId: $conversationId) {
          conversation {
            ...ConversationRow_conversationLike
          }
        }
      }
    `,
    { conversationId: id ?? "" },
    { subscribe: !!id }
  );

  return (
    <AtlasConversationRow
      testID="conversation-row"
      avatarUri={counterpart?.photoUrl ?? undefined}
      author={counterpart?.displayName ?? ""}
      timeStamp={timeStamp}
      message={messageBody}
      active={isActive}
      unread={hasUnread}
      pinned={isPinned}
      onPress={handleConversationPress}
      sticker={
        viewer === "worker" ? undefined : worker?.isBlocked ? (
          <Sticker icon="octagon-xmark" color="purple" />
        ) : worker?.isFavorite ? (
          <Sticker icon="star" color="purple" />
        ) : worker?.belongsToWorkerGroup ? (
          <Sticker icon="users-class" color="purple" />
        ) : undefined
      }
      conversationContext={
        hasManyOrgs ? (
          <Text variant="note" numberOfLines={1} color="disabled">
            Messaging with <Text weight="bold">{organization?.name}</Text>
          </Text>
        ) : null
      }
    />
  );
}

export default createRelayFragmentContainer<
  ConversationRow_conversationLike$key,
  Props
>(
  graphql`
    fragment ConversationRow_conversationLike on ConversationLike {
      participant {
        readUntil
        user {
          ... on Requester {
            organizations(first: 0) {
              totalCount
            }
          }
        }
      }
      currentMessage {
        id
        body
        insertedAt
        author {
          __typename
          id
          firstName
          displayName
        }
        attachments {
          id
          mimeType
        }
      }
      ... on ShiftGroupConversation {
        id
      }
      ... on EngagementConversation {
        id
        engagement {
          worker {
            displayName
            profilePhoto {
              url
            }
            isBlocked
            isFavorite
            belongsToWorkerGroup
          }
          gig {
            requester {
              displayName
              profilePhoto {
                url
              }
            }
          }
        }
      }
      ... on OrganizationWorkerConversation {
        id
        worker {
          displayName
          profilePhoto {
            url
          }
          isBlocked
          isFavorite
          belongsToWorkerGroup
        }
        organization {
          id
          name
          logoUrl
        }
      }
    }
  `,
  ConversationRow
);
