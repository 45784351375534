import { graphql, useRelayOrchestrator } from "@gigsmart/relay";
import { useCallback } from "react";
import type {
  CheckUserEmailStatusInput,
  EmailCheckerQuery,
  UserType
} from "./__generated__/EmailCheckerQuery.graphql";

export type { UserType };

export function useEmailChecker() {
  const { fetchQuery } = useRelayOrchestrator();

  return useCallback(
    async (input: CheckUserEmailStatusInput) => {
      const res = await fetchQuery<EmailCheckerQuery>(
        graphql`
          query EmailCheckerQuery($input: CheckUserEmailStatusInput!) {
            checkUserEmailStatus(input: $input) {
              userEmailStatus
            }
          }
        `,
        { input }
      );

      return res.checkUserEmailStatus?.userEmailStatus;
    },
    [fetchQuery]
  );
}
