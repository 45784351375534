import { useEventer } from "@gigsmart/dekigoto";
import React, { type ReactNode } from "react";
import {
  Platform,
  Pressable,
  type TextStyle,
  View,
  type ViewStyle
} from "react-native";
import { type IconName, StyledIcon } from "../icon";
import { type TextStyleProp, type ViewStyleProp, useStyles } from "../style";
import { MarkdownText } from "../text";

type CheckboxPosition = "left" | "top" | "right" | "bottom" | "center";

const TEXT_POSITION_STYLE: Record<CheckboxPosition, TextStyle | undefined> = {
  left: { textAlign: "right" },
  top: { textAlign: "center" },
  right: { textAlign: "left" },
  bottom: { textAlign: "center" },
  center: { textAlign: "left" }
};

const CONTAINER_POSITION_STYLE: Record<
  CheckboxPosition,
  ViewStyle | undefined
> = {
  left: { flexDirection: "row-reverse" },
  top: { flexDirection: "column-reverse" },
  right: { flexDirection: "row" },
  bottom: undefined,
  center: { flexDirection: "row", alignSelf: "center" }
};

const TITLE_POSITION_STYLE: Record<CheckboxPosition, ViewStyle | undefined> = {
  left: { flex: 1 },
  top: undefined,
  right: { flex: 1 },
  bottom: undefined,
  center: undefined
};

const MARKDOWN_STYLES = {
  paragraphStyle: { marginVertical: 0 }
};

export interface Props {
  disabled?: boolean;
  checked?: boolean;
  title: ReactNode | string;
  titleStyle?: ViewStyleProp;
  testID?: string;
  eventTargetName: string | null;
  eventEntityType?: string;
  textStyle?: TextStyleProp;
  style?: ViewStyleProp;
  errors?: Error[] | null | undefined;
  onPress?: () => void;
  onLongPress?: () => void;
  size?: number;
  position?: CheckboxPosition;
  checkedTitle?: string;
  iconName?: IconName;
  onIconPress?: () => void;
  onLongIconPress?: () => void;
  uncheckedVariant?: "solid" | "light" | "regular";
  checkedVariant?: "solid" | "light" | "regular";
}

export default function StyledCheckbox({
  checked,
  title,
  style,
  textStyle,
  titleStyle,
  disabled,
  size = 24,
  onPress,
  onLongPress,
  onIconPress,
  position = "right",
  onLongIconPress,
  testID,
  eventTargetName,
  eventEntityType,
  checkedTitle,
  errors,
  iconName,
  checkedVariant = "solid",
  uncheckedVariant = "regular"
}: Props) {
  const { styles, theme } = useStyles(
    ({ font, units }) => ({
      container: {
        alignItems: "center",
        ...(Platform.OS === "web" && { cursor: "pointer" }),
        ...CONTAINER_POSITION_STYLE[position]
      },
      titleContent: {
        paddingHorizontal: units(2),
        paddingVertical: units(1),
        ...TITLE_POSITION_STYLE[position]
      },
      checkboxText: {
        fontFamily: font.family.body,
        ...TEXT_POSITION_STYLE[position]
      },
      disabled: { opacity: 0.5 }
    }),
    [position]
  );

  const hasErrors = !!errors && errors.length > 0;
  const trackPress = useEventer(
    `Checked ${checked ? "Off" : "On"}`,
    eventTargetName,
    eventEntityType ?? "Checkbox"
  );

  const handlePress = () => {
    trackPress();
    onPress?.();
  };

  return (
    <Pressable
      testID={testID ?? "checkbox-touchable"}
      onLongPress={onLongPress}
      onPress={handlePress}
      accessible={false}
      style={[styles.container, disabled && styles.disabled, style]}
    >
      <StyledIcon
        testID={testID ? `${testID}-icon` : "checkbox-icon"}
        color={hasErrors ? "#dc4405" : theme.color.blue}
        name={iconName ?? (checked ? "square-check" : "square")}
        size={size}
        onLongPress={onLongIconPress}
        onPress={onIconPress}
        variant={checked ? checkedVariant : uncheckedVariant}
      />
      <View style={[styles.titleContent, titleStyle]}>
        {typeof title !== "string" ? (
          title
        ) : (
          <MarkdownText
            paragraphStyle={MARKDOWN_STYLES.paragraphStyle}
            style={[styles.checkboxText, textStyle]}
          >
            {(checked ? checkedTitle ?? title : title) ?? ""}
          </MarkdownText>
        )}
      </View>
    </Pressable>
  );
}
