import {
  ActivityIndicator,
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type RNScrollView,
  type RefreshControlProps,
  ScrollView,
  View
} from "@gigsmart/atorasu";
import React, {
  Component,
  type ReactNode,
  type RefObject,
  type ReactElement
} from "react";
import type { BaseScrollView } from "recyclerlistview";

interface ExtraProps {
  testID?: string;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
  onLayout?: (e: LayoutChangeEvent) => void;
  onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onScrollHandler?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  scrollViewRef?: RefObject<RNScrollView>;
  footerPosition?: "standard" | "bottom";
  refreshControl?: ReactElement<RefreshControlProps>;
  automaticallyAdjustContentInsets?: boolean;
}

export default class PaginatorScrollView extends Component<
  BaseScrollView["props"] & ExtraProps
> {
  scrollTo = (input: { x: number; y: number; animated: boolean }) => {
    const scroller = this.props.scrollViewRef?.current;
    if (scroller?.scrollTo) scroller.scrollTo(input);
  };

  handleLayout = (e: LayoutChangeEvent) => {
    if (e.nativeEvent?.layout.width && e.nativeEvent.layout.height) {
      this.props.onLayout?.(e);
    }
  };

  handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    this.props.onScroll?.(e);
    this.props.onScrollHandler?.(e);
  };

  render() {
    const {
      style,
      header,
      footer,
      children,
      scrollViewRef,
      footerPosition,
      isLoading,
      testID,
      ...rest
    } = this.props;
    return (
      <ScrollView
        {...rest}
        innerRef={scrollViewRef}
        testID={testID ?? "--paginator-scrollview"}
        grow={footerPosition === "bottom"}
        justifyContent={
          footerPosition === "bottom" ? "space-between" : undefined
        }
        onLayout={this.handleLayout}
        onScroll={this.handleScroll}
      >
        <View>
          {header}
          {children}
          {isLoading && <ActivityIndicator />}
        </View>
        {footer}
      </ScrollView>
    );
  }
}
