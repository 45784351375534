import { Dot, IconText, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import moment from "moment";
import React from "react";
import { View } from "react-native";

import TopAccessory from "../engagement-list/top-accessory";

interface Props {
  variant: "topAccessory" | "default";
  startsAt?: string | null;
  endsAt?: string | null;
}

const GigRemainingTime = ({ variant, startsAt, endsAt }: Props) => {
  const styles = useStyles(({ getColor, getUnits }) => ({
    outer: { backgroundColor: getColor("surface", "fill") },
    container: {
      flexDirection: "row",
      alignItems: "center",
      height: getUnits(9),
      paddingHorizontal: getUnits(4),
      backgroundColor: getColor("error", "fill", { opacity: 0.1 })
    }
  }));

  if (!startsAt && !endsAt) return null;

  const hours = moment(startsAt ?? endsAt).diff(Date.now(), "hour", true);
  if (hours <= 0 || hours >= 48) return null;

  const remainingTimeFmt =
    hours <= 1 ? "Less than 1 hour" : pluralize(Math.round(hours), "hour");

  if (variant === "topAccessory") {
    return (
      <TopAccessory spacing={2}>
        <Dot animation="flash" />
        <Text color="danger" weight="bold">
          {remainingTimeFmt} remaining to apply
        </Text>
      </TopAccessory>
    );
  }
  return (
    <View style={styles.outer}>
      <View style={styles.container}>
        <IconText icon="clock" color="danger" textWeight="bold" size="small">
          {remainingTimeFmt}
        </IconText>
        <Text> remaining to apply</Text>
      </View>
    </View>
  );
};

export default GigRemainingTime;
