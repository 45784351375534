import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { Form, Validator } from "@gigsmart/fomu";
import React from "react";
import FomuCheckbox from "../../fomu/inputs/FomuCheckbox";
import FomuSubmit from "../../fomu/inputs/FomuSubmit";
import LegalDocumentLink from "../../user-consent/LegalDocumentLink";

interface Props {
  onConfirm?: () => Promise<void>;
  onDeny?: () => void;
  onDismiss?: () => void;
  onClose: () => void;
}

export const showSecondConfirmationModal = ({
  onConfirm,
  onDeny,
  onDismiss
}: Omit<Props, "onClose">) =>
  showModal({
    eventContext: "Pickup Shift Second Confirmation Modal",
    onRequestClose: onDismiss,
    useModalBody: false,
    variant: "prompt",
    children: (closeFn) => (
      <SecondConfirmationModal
        onConfirm={onConfirm}
        onDeny={onDeny}
        onClose={closeFn}
      />
    )
  });

function SecondConfirmationModal({ onConfirm, onDeny, onClose }: Props) {
  const handleConfirm = () => {
    onClose();
    setTimeout(() => onConfirm?.(), 500);
  };

  const handleDeny = () => {
    onClose();
    setTimeout(() => onDeny?.(), 500);
  };

  return (
    <Form onSubmit={handleConfirm}>
      <ModalHeader
        icon="alarm-clock"
        iconColor="emphasized"
        title="Are you sure you want to pick up this Shift?"
      />
      <ModalBody gap="compact">
        <FomuCheckbox
          title={
            <Text align="left">
              By picking up this Shift, you agree to the Worker Cancellation
              Policy as listed in our{" "}
              <LegalDocumentLink
                filename="gigsmart/tos"
                defaultTitle="Terms of Service"
              />
            </Text>
          }
          name="pickUpShiftSecondConfirmation"
          validates={Validator.isTrue()}
          expand
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onPress={handleDeny}
          label="No, Do Not Pick Up Shift"
          variant="clear"
          testID="pick-up-shift-second-confirmation-deny"
        />
        <FomuSubmit
          icon="alarm-clock"
          label="Yes, Pick Up Shift"
          testID="pick-up-shift-second-confirmation-modal-submit"
          color="emphasized"
        />
      </ModalFooter>
    </Form>
  );
}
