/**
 * @generated SignedSource<<5623bbd054889449257cb910a3c789c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePendingOfferCount_worker$data = {
  readonly allOffers: {
    readonly totalCount: number;
  } | null | undefined;
  readonly bidRequestsCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly id: string;
  readonly pendingCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly projectOffers: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "usePendingOfferCount_worker";
};
export type usePendingOfferCount_worker$key = {
  readonly " $data"?: usePendingOfferCount_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePendingOfferCount_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v2 = {
  "_neq": "PROJECT"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePendingOfferCount_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "allOffers",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_in": [
                "OFFERED",
                "BID_REQUESTED"
              ]
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\",\"BID_REQUESTED\"]}})"
    },
    {
      "alias": "projectOffers",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_eq": "OFFERED"
            },
            "gigType": {
              "_eq": "PROJECT"
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_eq\":\"OFFERED\"},\"gigType\":{\"_eq\":\"PROJECT\"}})"
    },
    {
      "alias": "pendingCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_in": [
                "OFFERED"
              ]
            },
            "gigType": (v2/*: any*/)
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
    },
    {
      "alias": "bidRequestsCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_in": [
                "BID_REQUESTED"
              ]
            },
            "gigType": (v2/*: any*/)
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"BID_REQUESTED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "e19007b64138cd6f5aaa4a6b6a5469c2";

export default node;
