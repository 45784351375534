import { useHistory } from "@gigsmart/kaizoku";
import { useEffect } from "react";
import { getAuthToken } from "../relay/auth-token";
import { useCurrentUser } from "./current-user";

const ignoredPaths = new Set(["/", "/logout"]);
/**
 * Automatically logs user out if there's a previous authenticated user, but the token is gone
 */
const UserTokenCtrl = () => {
  const history = useHistory();
  const user = useCurrentUser();
  useEffect(() => {
    const checkToken = !ignoredPaths.has(history.location.pathname);
    void getAuthToken().then(async (token) => {
      if (checkToken && !token && user) {
        history.replace("/logout");
      }
    });
  });

  return null;
};

export default UserTokenCtrl;
