import { compact, flatMap } from "lodash";
import { DateTime as LuxonDateTime } from "luxon";

import Enum from "./enum";
import Float from "./float";
import { FunctionDefinition } from "./functionDefinition";
import { type DateLike, Now, type Value } from "./value";

export type OperatorToken =
  | "="
  | "!="
  | ">"
  | ">="
  | "<"
  | "<="
  | "IN"
  | "LIKE"
  | "INCLUDES"
  | "EXCLUDES"
  | "BETWEEN"
  | "WITHIN"
  | "INCLUDES ANY"
  | "INCLUDES ALL"
  | "EXCLUDES ALL";

export type Comparable = DateLike | number | Float;
export type GeoCircleFn = FunctionDefinition<
  "GEO_CIRCLE",
  [number, number, number]
>;

export type Constrainable = GeoCircleFn;
export class Operator<V extends OperatorValue> {
  constructor(
    private readonly operator: OperatorToken,
    private readonly value: V
  ) {}

  toString(field: string) {
    if (typeof this.value === "undefined") return;
    return `${field} ${this.operator} ${transformValue(
      this.value,
      this.operator
    )}`;
  }
}

export class NotOperator {
  private readonly op: Operator<Value | Value[]>;

  constructor(op: Operator<Value | Value[]> | Value[]) {
    this.op = op instanceof Operator ? op : inList(op);
  }

  toString(field: string) {
    const expression = this.op.toString(field);
    if (typeof expression === "undefined") return;
    return `NOT ${expression}`;
  }
}

export const transformValue = (
  value: OperatorValue,
  operator?: OperatorToken
): string => {
  if (value instanceof FunctionDefinition)
    return `${value.name}(${value.args
      .map((v) => transformValue(v))
      .join(", ")})`;
  if (value === null) return "NULL";
  if (Array.isArray(value) && operator === "BETWEEN") {
    const values = value.map((val) => transformValue(val)).join(", ");
    return `(${values})`;
  }
  if (Array.isArray(value)) {
    const values = value.map((val) => transformValue(val)).join(", ");
    return `[${values}]`;
  }
  if (value instanceof LuxonDateTime) return `'${value.toISO()}'`;
  if (typeof value === "object" && "toISOString" in value) {
    return `'${value.toISOString()}'`;
  }
  if (value instanceof Enum) return value.value;
  if (value instanceof Float) return value.value.toFixed(value.decimals);
  if (value instanceof Now) return "NOW";
  if (typeof value === "string") return `'${value.replace("'", "\\'")}'`;
  if (typeof value === "boolean") return String(value).toUpperCase();

  return String(value); // handles numbers
};

export type OperatorValue =
  | Value
  | Value[]
  | FunctionDefinition
  | FunctionDefinition[];

export type Operatable =
  | OperatorValue
  | Operator<OperatorValue>
  | NotOperator
  | Array<Operator<OperatorValue> | NotOperator>;

const parseOperation = (
  key: string,
  op: Operatable
): Array<string | undefined> => {
  if (op instanceof Operator || op instanceof NotOperator) {
    return [op.toString(key)];
  }
  if (
    Array.isArray(op) &&
    (op as Array<Operator<Value | Value[]>>).every(
      (o: Value | Operator<Value | Value[]> | NotOperator) =>
        o instanceof Operator || o instanceof NotOperator
    )
  ) {
    return (op as Array<Operator<Value | Value[]>>).flatMap((subop) =>
      parseOperation(key, subop)
    );
  }
  if (Array.isArray(op)) {
    return [inList(op as Value[]).toString(key)];
  }
  return [eq(op as Value).toString(key)];
};

export const parseOperations = (obj?: OperationsObject | null): string[] => {
  if (!obj) return [];
  return flatMap(Object.entries(obj), ([key, op]) =>
    compact(parseOperation(key, op))
  );
};

// Standard Value Operators
export const eq = <T extends Value>(value: T) => new Operator("=", value);
export const neq = <T extends Value>(value: T) => new Operator("!=", value);
export const inList = <T extends Value>(value: T[]) =>
  new Operator("IN", value);
export const includesAny = <T extends Value>(value: T[]) =>
  new Operator("INCLUDES ANY", value);
export const includesAll = <T extends Value>(value: T[]) =>
  new Operator("INCLUDES ALL", value);
export const excludesAll = <T extends Value>(value: T[]) =>
  new Operator("EXCLUDES ALL", value);
export const not = <T extends Value>(op: Operator<T | T[]> | T[]) =>
  new NotOperator(op);

// Comparable Value Operators
export const gt = <T extends Comparable>(value: T) => new Operator(">", value);
export const gte = <T extends Comparable>(value: T) =>
  new Operator(">=", value);
export const lt = <T extends Comparable>(value: T) => new Operator("<", value);
export const lte = <T extends Comparable>(value: T) =>
  new Operator("<=", value);
export const between = <T extends Comparable>(start: T, end: T) =>
  new Operator("BETWEEN", [start, end]);
export const within = <T extends Constrainable>(constrainable: T) =>
  new Operator("WITHIN", constrainable);
export const geoCircle = (
  lat: number,
  lng: number,
  distance: number
): GeoCircleFn => new FunctionDefinition("GEO_CIRCLE", [lat, lng, distance]);

// String Value Operators
export const like = (value: string) => new Operator("LIKE", value || undefined);
export const includes = (value: string | Enum) =>
  new Operator("INCLUDES", value);
export const excludes = (value: string | Enum) =>
  new Operator("EXCLUDES", value);

export type OperationsObject = Record<string, Operatable>;
