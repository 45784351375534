import * as React from "react";
import { View } from "react-native";
import { StyledIcon } from "../icon";
import { createStyles } from "../style";
import { color } from "../style/theme";
import { StyledText } from "../text";

interface Props {
  initial?: number;
  applied: number;
  offered?: number;
  scheduled: number;
  enRoute: number;
  working: number;
  paused: number;
  pendingTimesheetApproval?: number;
  paid: number;
  canceled: number;
}

export default function EngagementStates({
  initial,
  applied,
  offered,
  scheduled,
  enRoute,
  working,
  paused,
  pendingTimesheetApproval,
  paid,
  canceled
}: Props) {
  console.debug(initial);
  return (
    <View style={styles.container}>
      {initial !== null && typeof initial !== "undefined" && initial >= 0 ? (
        <View style={styles.verticalContainer}>
          <StyledIcon
            name="address-card"
            variant="solid"
            color={initial === 0 ? color.neutralDark : "#385E7D"}
            size={16}
            style={styles.icon}
          />
          <StyledText
            color={initial === 0 ? color.neutralDark : color.black}
            fontSize={12}
            center
          >
            {initial}
          </StyledText>
        </View>
      ) : null}
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="clipboard-list"
          variant="solid"
          color={applied === 0 ? color.neutralDark : "#A83C00"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={applied === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {applied}
        </StyledText>
      </View>
      {offered !== null && typeof offered !== "undefined" && offered >= 0 ? (
        <View style={styles.verticalContainer}>
          <StyledIcon
            name="hand-holding-dollar"
            variant="solid"
            color={offered === 0 ? color.neutralDark : "#69BA9C"}
            size={16}
            style={styles.icon}
          />
          <StyledText
            color={offered === 0 ? color.neutralDark : color.black}
            fontSize={12}
            center
          >
            {offered}
          </StyledText>
        </View>
      ) : null}
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="calendar-check"
          variant="solid"
          color={scheduled === 0 ? color.neutralDark : "#3EAFE0"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={scheduled === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {scheduled}
        </StyledText>
      </View>
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="car"
          variant="solid"
          color={enRoute === 0 ? color.neutralDark : "#2C5696"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={enRoute === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {enRoute}
        </StyledText>
      </View>
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="play"
          variant="solid"
          color={working === 0 ? color.neutralDark : "#000000"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={working === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {working}
        </StyledText>
      </View>
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="pause"
          variant="solid"
          color={paused === 0 ? color.neutralDark : "#FFB549"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={paused === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {paused}
        </StyledText>
      </View>
      {pendingTimesheetApproval !== null &&
      typeof pendingTimesheetApproval !== "undefined" &&
      pendingTimesheetApproval >= 0 ? (
        <View style={styles.verticalContainer}>
          <StyledIcon
            name="file-invoice-dollar"
            variant="solid"
            color={
              pendingTimesheetApproval === 0 ? color.neutralDark : "#FF0080"
            }
            size={16}
            style={styles.icon}
          />
          <StyledText
            color={
              pendingTimesheetApproval === 0 ? color.neutralDark : color.black
            }
            fontSize={12}
            center
          >
            {pendingTimesheetApproval}
          </StyledText>
        </View>
      ) : null}
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="money-bill"
          variant="solid"
          color={paid === 0 ? color.neutralDark : "#24925C"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={paid === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {paid}
        </StyledText>
      </View>
      <View style={styles.verticalContainer}>
        <StyledIcon
          name="ban"
          variant="solid"
          color={canceled === 0 ? color.neutralDark : "#CA0000"}
          size={16}
          style={styles.icon}
        />
        <StyledText
          color={canceled === 0 ? color.neutralDark : color.black}
          fontSize={12}
          center
        >
          {canceled}
        </StyledText>
      </View>
    </View>
  );
}

const styles = createStyles(() => ({
  container: {
    flexDirection: "row",
    justifyContent: "center"
  },
  verticalContainer: {
    flexDirection: "column"
  },
  icon: {
    padding: "8px"
  }
}));
