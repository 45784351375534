/**
 * @generated SignedSource<<235f60fc41471d0fd6293105c7966ec6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LanguagesCard_Worker$data = {
  readonly languages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly language: string;
        readonly level: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "LanguagesCard_Worker";
};
export type LanguagesCard_Worker$key = {
  readonly " $data"?: LanguagesCard_Worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"LanguagesCard_Worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LanguagesCard_Worker",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 30
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "ORDER BY language ASC"
        }
      ],
      "concreteType": "WorkerLanguagesConnection",
      "kind": "LinkedField",
      "name": "languages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerLanguagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerLanguage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "language",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "languages(first:30,query:\"ORDER BY language ASC\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "c0ea51648a8962006ee223c07020c5fd";

export default node;
