/**
 * @generated SignedSource<<e84335bf3156607e180c8fc41d74546b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationRow_conversationLike$data = {
  readonly currentMessage: {
    readonly attachments: ReadonlyArray<{
      readonly id: string;
      readonly mimeType: string;
    }>;
    readonly author: {
      readonly __typename: string;
      readonly displayName: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly id: string;
    };
    readonly body: string | null | undefined;
    readonly id: string;
    readonly insertedAt: string;
  } | null | undefined;
  readonly engagement?: {
    readonly gig: {
      readonly requester: {
        readonly displayName: string | null | undefined;
        readonly profilePhoto: {
          readonly url: string | null | undefined;
        } | null | undefined;
      };
    };
    readonly worker: {
      readonly belongsToWorkerGroup: boolean;
      readonly displayName: string | null | undefined;
      readonly isBlocked: boolean;
      readonly isFavorite: boolean;
      readonly profilePhoto: {
        readonly url: string | null | undefined;
      } | null | undefined;
    };
  };
  readonly id?: string;
  readonly organization?: {
    readonly id: string;
    readonly logoUrl: string | null | undefined;
    readonly name: string;
  };
  readonly participant: {
    readonly readUntil: string | null | undefined;
    readonly user: {
      readonly organizations?: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly worker?: {
    readonly belongsToWorkerGroup: boolean;
    readonly displayName: string | null | undefined;
    readonly isBlocked: boolean;
    readonly isFavorite: boolean;
    readonly profilePhoto: {
      readonly url: string | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "ConversationRow_conversationLike";
};
export type ConversationRow_conversationLike$key = {
  readonly " $data"?: ConversationRow_conversationLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConversationRow_conversationLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Worker",
  "kind": "LinkedField",
  "name": "worker",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "belongsToWorkerGroup",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConversationRow_conversationLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConversationParticipant",
      "kind": "LinkedField",
      "name": "participant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readUntil",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 0
                    }
                  ],
                  "concreteType": "OrganizationsConnection",
                  "kind": "LinkedField",
                  "name": "organizations",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "organizations(first:0)"
                }
              ],
              "type": "Requester",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMessage",
      "kind": "LinkedField",
      "name": "currentMessage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "insertedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserMessageAttachment",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mimeType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "ShiftGroupConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Engagement",
          "kind": "LinkedField",
          "name": "engagement",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "gig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Requester",
                  "kind": "LinkedField",
                  "name": "requester",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EngagementConversation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "OrganizationWorkerConversation",
      "abstractKey": null
    }
  ],
  "type": "ConversationLike",
  "abstractKey": "__isConversationLike"
};
})();

(node as any).hash = "c7a7481e93a1f4cdfbb478203f782c06";

export default node;
