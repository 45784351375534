/**
 * @generated SignedSource<<29fabb423b2871cfa0c14b99dba32ae1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type affiliateJobCardFragment_job$data = {
  readonly hiringCompany: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly industryName: string | null | undefined;
  readonly location: string | null | undefined;
  readonly maximumSalary: string | null | undefined;
  readonly minimumSalary: string | null | undefined;
  readonly name: string | null | undefined;
  readonly salaryInterval: string | null | undefined;
  readonly snippet: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "affiliateJobCardFragment_job";
};
export type affiliateJobCardFragment_job$key = {
  readonly " $data"?: affiliateJobCardFragment_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"affiliateJobCardFragment_job">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "affiliateJobCardFragment_job",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snippet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salaryInterval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximumSalary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumSalary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "industryName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AffiliateJobHiringCompany",
      "kind": "LinkedField",
      "name": "hiringCompany",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "AffiliateJobPosting",
  "abstractKey": null
};
})();

(node as any).hash = "8bf880bce72e5dd9d5606e346cdcbeef";

export default node;
