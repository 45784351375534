import {
  Button,
  ContentArea,
  Stack,
  Surface,
  Text,
  TimeRemaining
} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { useHasEngagementCapability } from "@gigsmart/seibutsu/engagement/WithEngagementCapability";
import React from "react";
import type { WorkerShiftParams } from "../shifts/navigation";
import type { WorkerTimesheetInfoCard_engagement$key } from "./__generated__/WorkerTimesheetInfoCard_engagement.graphql";

export function WorkerTimesheetInfoCard({
  id,
  currentState,
  gigType,
  systemTimesheet,
  workerTimesheet,
  capabilities,
  gig
}: FragmentContainerInnerComponentProps<WorkerTimesheetInfoCard_engagement$key>) {
  const nav =
    useNavigation<AppNavigationProp<WorkerShiftParams, "ShiftDetails">>();
  const canApproveSystemTimesheet = useHasEngagementCapability(
    capabilities ?? [],
    "APPROVE_SYSTEM_TIMESHEET"
  );
  const shouldShow =
    canApproveSystemTimesheet &&
    currentState?.name === "PENDING_TIMESHEET_APPROVAL" &&
    gigType !== "PROJECT" &&
    !(workerTimesheet?.isOverridden && !workerTimesheet?.editable);

  const neverStarted = systemTimesheet?.startedCount?.totalCount === 0;
  if (!shouldShow) return null;

  return (
    <Surface>
      <ContentArea>
        <Stack>
          <Text variant="header" color="primary">
            {neverStarted
              ? "Did you work this Shift?"
              : "Please review your Timesheet so that you are properly compensated for your Shift."}
          </Text>
          <Text>
            {neverStarted
              ? "If you worked without starting your time clock, you can create a Timesheet to send to the Requester for approval."
              : "You can approve the existing Timesheet without changes or you can submit an updated version for the Requester to approve."}
          </Text>
          {gig?.reconcilesAt && (
            <Text>
              You have{" "}
              <TimeRemaining
                date={canApproveSystemTimesheet?.expiresAt}
                color="danger"
                weight="bold"
              />{" "}
              {neverStarted
                ? "remaining to create a Timesheet."
                : "to review your Timesheet."}
            </Text>
          )}
          <Button
            testID={
              neverStarted ? "create-timesheet-btn" : "review-timesheet-btn"
            }
            icon={neverStarted ? "plus" : "user-clock"}
            label={neverStarted ? "Create Timesheet" : "Review Timesheet"}
            size={neverStarted ? "small" : undefined}
            outline={neverStarted}
            onPress={() =>
              nav.push(neverStarted ? "CreateTimesheet" : "ReviewTimesheet", {
                id
              })
            }
          />
          <Text variant="note" color="neutral" align="center">
            Please note, Requesters can approve your Timesheet at any time.
          </Text>
        </Stack>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<WorkerTimesheetInfoCard_engagement$key>(
  graphql`
    fragment WorkerTimesheetInfoCard_engagement on Engagement {
      id
      gigType
      gig {
        estimatedMileage
        reconcilesAt
      }
      currentState {
        name
      }
      capabilities {
        type
        restrictedBy {
          message
        }
        expiresAt
        status
      }
      systemTimesheet: timesheet(variant: SYSTEM) {
        startedCount: states(first: 0, query: "WHERE action = START") {
          totalCount
        }
        workerApprovals: approvals(
          first: 0
          query: "WHERE approvedByType = WORKER"
        ) {
          totalCount
        }
      }
      workerTimesheet: timesheet(variant: WORKER) {
        isOverridden
        editable
      }
    }
  `,
  WorkerTimesheetInfoCard
);
