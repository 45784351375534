import {
  Button,
  Column,
  ContentArea,
  DateTime,
  Divider,
  LoadingView,
  ModalBody,
  ModalFooter,
  ScrollView,
  Surface,
  Text,
  Timesheet,
  Well,
  showModal
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import WorkerInfoRow from "../../worker/WorkerInfoRow";
import type { EngagementBidHistoryModalQuery } from "./__generated__/EngagementBidHistoryModalQuery.graphql";
import { generateEntries } from "./helpers";

interface Props {
  engagementId: string;
  onClose: () => void;
}

const EngagementBidHistoryModal = createSuspendedQueryContainer<
  EngagementBidHistoryModalQuery,
  Props
>(
  function EngagementBidHistoryModal({ response, onClose }) {
    const isWorker = useCurrentUser()?.__typename === "Worker";
    const node = response?.node;
    const shiftName = node?.gig?.name ?? "Shift Name Not Found";
    const actualStartsAt = node?.gig?.actualStartsAt;
    const startsAt = node?.gig?.startsAt;
    const endsAt = node?.gig?.endsAt;
    const entries = generateEntries(
      node?.timesheet,
      isWorker,
      node?.gig?.payRate,
      node?.currentNegotiation?.payRate
    );

    return (
      <ContentArea gap="standard">
        {!isWorker && (
          <>
            <Column>
              <Divider />
              <WorkerInfoRow
                fragmentRef={node?.worker}
                distance={node?.workerDistance}
                skill={node?.gig?.position?.name}
              />
              <Divider />
            </Column>
            <Well color="neutral" alignItems="center">
              <Text weight="bold">{shiftName}</Text>
              <DateTime
                actualStartsAt={actualStartsAt}
                endsAt={endsAt}
                startsAt={startsAt}
              />
            </Well>
          </>
        )}
        <Surface variant="outline">
          <ScrollView
            testID="bid-history-scroll-view"
            style={{ height: isWorker ? 500 : 250 }}
          >
            <ContentArea>
              <Timesheet entries={entries} />
            </ContentArea>
          </ScrollView>
        </Surface>
        {isWorker && (
          <Button label="Close" onPress={onClose} testID="close-button" />
        )}
      </ContentArea>
    );
  },
  {
    query: graphql`
      query EngagementBidHistoryModalQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            id
            worker {
              ...WorkerInfoRow_worker
            }
            workerDistance
            timesheet(variant: SYSTEM) {
              ...helpersGenerateEntries_timesheet
                @arguments(
                  where: {
                    _or: [
                      {
                        name: {
                          _in: [
                            BID_REVIEW
                            BID_REQUESTED
                            OFFERED
                            REJECTED
                            SCHEDULED
                          ]
                        }
                      }
                      { name: { _eq: SCHEDULED }, action: { _eq: PICKUP } }
                    ]
                  }
                )
            }
            currentNegotiation {
              payRate
              submittedBy {
                displayName
                __typename
              }
            }
            gig {
              name
              actualStartsAt
              startsAt
              endsAt
              position {
                name
              }
              payRate
            }
          }
        }
      }
    `,
    variables: ({ engagementId }) => ({ engagementId }),
    FallbackComponent: () => (
      <>
        <ModalBody>
          <LoadingView />
        </ModalBody>
        <ModalFooter />
      </>
    )
  }
);

export function showEngagementBidHistoryModal(props: Omit<Props, "onClose">) {
  return showModal({
    eventContext: "Engagement Bid History Modal",
    title: "Bid History",
    children: (close) => (
      <EngagementBidHistoryModal {...props} onClose={close} />
    )
  });
}
