/**
 * @generated SignedSource<<aa42ce7351825c4f4bdf396cf31b43b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerBlockedOrganizationReminder_worker$data = {
  readonly blockedOrganizations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organization: {
          readonly id: string;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "WorkerBlockedOrganizationReminder_worker";
};
export type WorkerBlockedOrganizationReminder_worker$key = {
  readonly " $data"?: WorkerBlockedOrganizationReminder_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerBlockedOrganizationReminder_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerBlockedOrganizationReminder_worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_eq",
                  "variableName": "organizationId"
                }
              ],
              "kind": "ObjectValue",
              "name": "organizationId"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "WorkerBlockedOrganizationsConnection",
      "kind": "LinkedField",
      "name": "blockedOrganizations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerBlockedOrganizationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerBlockedOrganization",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "5896ecb6be3d0f0e94071d8ef5b77e20";

export default node;
