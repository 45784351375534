import { ContentArea, Surface } from "@gigsmart/atorasu";
import { Form } from "@gigsmart/fomu";
import type { StepProps } from "@gigsmart/katana";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import DriverFields from "@gigsmart/seibutsu/worker-verification/DriverFields";
import SecureNotice from "@gigsmart/seibutsu/worker-verification/SecureNotice";
import React from "react";

import StepDetail from "../app/step-detail";

type Props = StepProps & {
  loading: boolean;
};

export default function VerifiedDriverInformation({
  loading,
  ...props
}: Props) {
  return (
    <Form
      initialValues={props.stepData}
      onSubmit={({ values, valid }) => {
        const newData = { ...props.stepData, ...values };
        props.updateData(newData);
        valid && props.nextStep(newData);
      }}
    >
      <StepDetail
        title="Information"
        description="Your information is secure and used only for verification purposes."
        stickyFooter
        renderNextButton={() => (
          <FomuSubmit
            testID="submit-button"
            allowDirty
            disabled={loading}
            label="Save Changes"
          />
        )}
        {...props}
        testID="driver-information-screen"
      >
        <Surface>
          <ContentArea>
            <DriverFields />
          </ContentArea>
        </Surface>
        <SecureNotice />
      </StepDetail>
    </Form>
  );
}
