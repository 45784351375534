/**
 * @generated SignedSource<<64c6626d9ef630787b1519bb08d8611c>>
 * @relayHash 64649446b3f446dd1e2cc0374559752f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:GtcZuz84e20Q9ERYaTh-njzLfYKkuyj7MS7nbXYEAdk

import { ConcreteRequest } from 'relay-runtime';
export type WorkerAccessState = "AVAILABLE" | "EN_ROUTE" | "NO_ACTIVE_DEVICES" | "NO_LOCATION" | "REQUIRES_BASIC_INFO" | "REQUIRES_PARENT_CONSENT" | "REQUIRES_SKILLS" | "REQUIRES_SMS_CONFIRMATION" | "REQUIRES_TRANSPORTATION" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type SetWorkerPayableAccountInput = {
  token: string;
  workerId?: string | null | undefined;
};
export type workerAccountSetMutation$variables = {
  input: SetWorkerPayableAccountInput;
};
export type workerAccountSetMutation$data = {
  readonly setWorkerPayableAccount: {
    readonly worker: {
      readonly accessState: WorkerAccessState;
    } | null | undefined;
    readonly workerPayableAccount: {
      readonly paymentMethod: {
        readonly __typename: string;
        readonly accountHolderName: string | null | undefined;
        readonly expirationMonth?: number;
        readonly expirationYear?: number;
        readonly last4: string;
        readonly postalCode?: string | null | undefined;
        readonly routingNumber?: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type workerAccountSetMutation = {
  response: workerAccountSetMutation$data;
  variables: workerAccountSetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessState",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "paymentMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last4",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountHolderName",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expirationMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expirationYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        }
      ],
      "type": "BankCard",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "routingNumber",
          "storageKey": null
        }
      ],
      "type": "BankAccount",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "workerAccountSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetWorkerPayableAccountPayload",
        "kind": "LinkedField",
        "name": "setWorkerPayableAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerPayableAccount",
            "kind": "LinkedField",
            "name": "workerPayableAccount",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workerAccountSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetWorkerPayableAccountPayload",
        "kind": "LinkedField",
        "name": "setWorkerPayableAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerPayableAccount",
            "kind": "LinkedField",
            "name": "workerPayableAccount",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:GtcZuz84e20Q9ERYaTh-njzLfYKkuyj7MS7nbXYEAdk",
    "metadata": {},
    "name": "workerAccountSetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "81254ed4a1676fdb81256b9dd74d7140";

export default node;
