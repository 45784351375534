import React from "react";
import { View } from "react-native";
import Card from "../container/card";
import { KatanaRatingStars } from "../indicator";
import { Spacer } from "../spacer";
import { useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  variant: "Given" | "Received";
  review: string | null | undefined;
  rating: number | null | undefined;
  name?: string;
}

export default ({ variant, rating, name, review }: Props) => {
  const { styles } = useStyles(({ color, units }) => ({
    cardBody: {
      marginHorizontal: units(5),
      marginTop: units(5),
      marginBottom: units(5.25)
    },
    grey: {
      color: color.neutralDark
    },
    verticalMargin: {
      marginVertical: units(4)
    }
  }));

  return (
    <Card noSpacing noRadius noMargin>
      <View style={styles.cardBody}>
        <KatanaText variant="bold">{`Rating ${variant}`}</KatanaText>
        <Spacer units={1.75} />

        {rating ? (
          <KatanaRatingStars
            fontSize={14}
            spacing={0.5}
            readOnly
            value={rating}
          />
        ) : (
          <KatanaText style={styles.grey}>
            {variant === "Given"
              ? "You have not given a rating yet."
              : `${name ?? ""} has not finished their review yet.`}
          </KatanaText>
        )}

        {(review === null && rating !== null) || (
          <>
            <Spacer />
            <KatanaText variant="bold">{`Comment ${variant}`}</KatanaText>
            <Spacer units={1.5} />
            <KatanaText style={!review && styles.grey}>
              {review ??
                (variant === "Given"
                  ? "You have not written a review yet."
                  : `${name ?? ""} has not left a comment yet.`)}
            </KatanaText>
          </>
        )}
      </View>
    </Card>
  );
};
