import RequiredActionGuide from "@gigsmart/isomorphic-shared/app/required-action-guide";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import React from "react";

interface Props {
  isProject?: boolean;
  onPress?: () => void;
}

export default function GigDistanceNotice({ isProject, onPress }: Props) {
  onPress ??= () => defaultBackHandler();

  return (
    <RequiredActionGuide
      testID="out-of-range-screen"
      icon="location-pin-slash"
      title={`${isProject ? "Project " : ""}Gig Out of Range`}
      text={
        isProject
          ? "You are not eligible to apply to Project Gigs located more than 75 miles away. Please find a project that is closer to you."
          : "If the Gig is more than 75 miles away you are not eligible to apply. Please find a gig that is closer to you."
      }
      button={{
        testID: "go-back-button",
        title: isProject ? "View Project Gigs" : "Find Another Gig",
        onPress
      }}
    />
  );
}
