/**
 * @generated SignedSource<<e87179898480ef046793ec18b10667c4>>
 * @relayHash 78c4cf43d82940e74213e22d6c7bc566
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:dyjBf9a2KPDouiJMVz-TgpVh0s-JONokK9IGixsI3tM

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AsyncRemoveWorkerBlockedOrganizationInput = {
  workerBlockedOrganizationId: string;
};
export type UnblockOrgModalMutation$variables = {
  input: AsyncRemoveWorkerBlockedOrganizationInput;
};
export type UnblockOrgModalMutation$data = {
  readonly asyncRemoveWorkerBlockedOrganization: {
    readonly " $fragmentSpreads": FragmentRefs<"useRelayCommand_payload">;
  } | null | undefined;
};
export type UnblockOrgModalMutation = {
  response: UnblockOrgModalMutation$data;
  variables: UnblockOrgModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correlationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnblockOrgModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonOperationPayload",
        "kind": "LinkedField",
        "name": "asyncRemoveWorkerBlockedOrganization",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "useRelayCommand_payload",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnblockOrgModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonOperationPayload",
        "kind": "LinkedField",
        "name": "asyncRemoveWorkerBlockedOrganization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:dyjBf9a2KPDouiJMVz-TgpVh0s-JONokK9IGixsI3tM",
    "metadata": {},
    "name": "UnblockOrgModalMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fb2a6574ff79de22913fb82015016b4e";

export default node;
