import { NavIcon } from "@gigsmart/atorasu";
import { ShiftGroupChat } from "@gigsmart/feature-flags";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import React from "react";
import type { MessageCenterIconQuery } from "./__generated__/MessageCenterIconQuery.graphql";
import type { MessageCenterIconSubscription } from "./__generated__/MessageCenterIconSubscription.graphql";

export default createSuspendedQueryContainer<MessageCenterIconQuery>(
  function MessageCenterIcon({ response }) {
    const history = useHistory();
    useRelaySubscription<MessageCenterIconSubscription>(
      graphql`
        subscription MessageCenterIconSubscription {
          userMessageStatsUpdated {
            user {
              id
              userMessageStats(
                conversationTypes: [ENGAGEMENT, ORGANIZATION_WORKER]
              ) {
                unreadCount
              }
            }
          }
        }
      `,
      {}
    );

    return (
      <NavIcon
        testID="message-center-icon"
        name={ShiftGroupChat.select("message-lines", "message")}
        badge={response?.viewer?.userMessageStats?.unreadCount}
        onPress={() => history.push("/message-center")}
      />
    );
  },
  {
    query: graphql`
      query MessageCenterIconQuery {
        viewer {
          id
          userMessageStats(
            conversationTypes: [ENGAGEMENT, ORGANIZATION_WORKER]
          ) {
            unreadCount
          }
        }
      }
    `,
    variables: {}
  }
);
