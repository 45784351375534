/**
 * @generated SignedSource<<e56550f1c5e2e3f3b712e5f848a46dad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigPositionSummaryRow_gigLike$data = {
  readonly position: {
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "GigPositionSummaryRow_gigLike";
};
export type GigPositionSummaryRow_gigLike$key = {
  readonly " $data"?: GigPositionSummaryRow_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"GigPositionSummaryRow_gigLike">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GigPositionSummaryRow_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GigPosition",
      "kind": "LinkedField",
      "name": "position",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "321ec9335e103ec1a368338444c2080e";

export default node;
