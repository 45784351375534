/**
 * @generated SignedSource<<b3aa5ffa62e49d9448337528c0472650>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type NegotiableGigField = "STARTS_AT" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigAvailableCard_seriesEdge$data = {
  readonly cursor: string | null | undefined;
  readonly estimatedPaymentRange: {
    readonly max: string;
    readonly min: string;
  } | null | undefined;
  readonly gigDistance: {
    readonly max: number;
    readonly min: number;
  } | null | undefined;
  readonly node: {
    readonly area: string | null | undefined;
    readonly availableGigs: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"gigAvailableCard_gigEdge">;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly category: {
      readonly displayName: string;
    } | null | undefined;
    readonly gigType: GigType | null | undefined;
    readonly hasMultipleLocations: boolean;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly negotiable: ReadonlyArray<NegotiableGigField> | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
    readonly placeId: string | null | undefined;
    readonly position: {
      readonly name: string;
    } | null | undefined;
    readonly startsAt: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"GigTags_gigLike">;
  } | null | undefined;
  readonly " $fragmentType": "gigAvailableCard_seriesEdge";
};
export type gigAvailableCard_seriesEdge$key = {
  readonly " $data"?: gigAvailableCard_seriesEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigAvailableCard_seriesEdge">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigAvailableCard_seriesEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceRange",
      "kind": "LinkedField",
      "name": "gigDistance",
      "plural": false,
      "selections": [
        {
          "alias": "max",
          "args": null,
          "kind": "ScalarField",
          "name": "maxDistance",
          "storageKey": null
        },
        {
          "alias": "min",
          "args": null,
          "kind": "ScalarField",
          "name": "minDistance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MoneyRange",
      "kind": "LinkedField",
      "name": "estimatedPaymentRange",
      "plural": false,
      "selections": [
        {
          "alias": "max",
          "args": null,
          "kind": "ScalarField",
          "name": "maxAmount",
          "storageKey": null
        },
        {
          "alias": "min",
          "args": null,
          "kind": "ScalarField",
          "name": "minAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigSeries",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gigType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "negotiable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMultipleLocations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigPosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GigTags_gigLike"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigCategory",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AvailableGigsConnection",
          "kind": "LinkedField",
          "name": "availableGigs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailableGigsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "gigAvailableCard_gigEdge"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "availableGigs(first:1)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailableGigSeriesEdge",
  "abstractKey": null
};
})();

(node as any).hash = "585d2484496e3ce18b12d54760bc84a5";

export default node;
