/**
 * @generated SignedSource<<a08428f98b68fea88f7216faaa3e4f95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerPaymentRows_availableGig$data = {
  readonly estimatedPayment?: {
    readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentRows_payment">;
  } | null | undefined;
  readonly paymentInfo?: {
    readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentRows_payment">;
  } | null | undefined;
  readonly " $fragmentType": "WorkerPaymentRows_availableGig";
};
export type WorkerPaymentRows_availableGig$key = {
  readonly " $data"?: WorkerPaymentRows_availableGig$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentRows_availableGig">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "WorkerPaymentRows_payment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paySchedule",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "billableDuration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "grossPay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "netPay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerServiceFee",
        "kind": "LinkedField",
        "name": "serviceFees",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hourlyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feeType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerPaymentRows_availableGig",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/)
      ],
      "type": "AvailableGigsEdge",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "paymentInfo",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "Engagement",
      "abstractKey": null
    }
  ],
  "type": "AvailableGigOrEngagement",
  "abstractKey": "__isAvailableGigOrEngagement"
};
})();

(node as any).hash = "1bff7970b25adce4c74db2078d28c167";

export default node;
