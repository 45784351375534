import * as React from "react";
import { View } from "react-native";

import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { StyledText } from "../text";

type Props = StylesProps & {
  label: string;
  color: string;
  textColor: string;
  size?: number;
  style?: ViewStyleProp;
};

@withStyles(({ color }) => ({
  circle: {
    backgroundColor: color.blue,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1000,
    width: 50,
    height: 50
  }
}))
export default class StyledTextCircle extends React.Component<Props> {
  static defaultProps = {
    color: "blue",
    textColor: "white",
    ...stylesStubs
  };

  render() {
    const { label, theme, styles, color, size, textColor, style } = this.props;
    const backgroundStyle = {
      backgroundColor: theme.color.getColor(color)
    };
    return (
      <View style={[styles.circle, backgroundStyle, style]}>
        <StyledText
          fontSize={size ?? theme.font.size.extraLarge}
          color={textColor}
        >
          {label}
        </StyledText>
      </View>
    );
  }
}
