/**
 * @generated SignedSource<<da41270f4461ba66f411e369ed2e10fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type jobCardFragment_job$data = {
  readonly area: string | null | undefined;
  readonly boosted: boolean | null | undefined;
  readonly description: string | null | undefined;
  readonly maximumPayRate: string | null | undefined;
  readonly minimumPayRate: string | null | undefined;
  readonly organization: {
    readonly name: string;
  };
  readonly payRateNegotiable: boolean | null | undefined;
  readonly paySchedule: JobPostingPaySchedule | null | undefined;
  readonly place: {
    readonly streetAddress: string | null | undefined;
  } | null | undefined;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "jobCardFragment_job";
};
export type jobCardFragment_job$key = {
  readonly " $data"?: jobCardFragment_job$data;
  readonly " $fragmentSpreads": FragmentRefs<"jobCardFragment_job">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "jobCardFragment_job",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boosted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paySchedule",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRateNegotiable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximumPayRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumPayRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "area",
      "storageKey": null
    }
  ],
  "type": "JobPosting",
  "abstractKey": null
};

(node as any).hash = "49613bea3d338be1b045e38eb2d445bf";

export default node;
