import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import React, { Component, type ComponentProps, type Ref } from "react";
import { Keyboard, Platform, TextInput } from "react-native";
import {
  type StylesProps,
  type TextStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import StyledInputAccessory, { accessoryID } from "./styled-input-accessory";

type Props = StylesProps &
  ComponentProps<typeof TextInput> &
  Pick<
    ComponentProps<typeof StyledInputAccessory>,
    "attachedToKeyboard" | "onCancel"
  > &
  EventerProps<"blurred"> & {
    inputRef?: Ref<TextInput>;
    style?: TextStyleProp;
    onCancel?: () => void;
    onSubmit?: () => void;
    onChangeText?: (arg0: string) => void;
    value: string;
  };

@withStyles(({ color, font }) => ({
  input: {
    ...font.body("regular"),
    fontSize: font.size.medium,
    padding: 0,
    color: color.black,
    ...Platform.select({
      web: { outlineStyle: "none" }
    })
  }
}))
@withEventers("Input", ["blurred"])
export default class TextField extends Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    attachedToKeyboard: true,
    eventers: eventerStubs(["blurred"])
  };

  inputAccessoryViewID = accessoryID();

  _handleSubmit = () => {
    Keyboard.dismiss();
    if (this.props.onSubmit) this.props.onSubmit();
  };

  _handleBlur = () => {
    this.props.eventers.blurred();
    if (this.props.onBlur) this.props.onBlur({} as any);
  };

  render() {
    const {
      inputRef,
      styles,
      theme,
      style,
      onCancel,
      placeholder,
      attachedToKeyboard,
      onChangeText,
      keyboardType,
      value,
      testID,
      ...props
    } = this.props;

    return (
      <>
        <TextInput
          allowFontScaling={false}
          ref={inputRef}
          style={[styles.input, style]}
          placeholderTextColor={theme.color.neutralDark}
          underlineColorAndroid={theme.color.transparent}
          selectionColor={theme.color.orange}
          placeholder={placeholder}
          inputAccessoryViewID={this.inputAccessoryViewID}
          onChangeText={onChangeText}
          keyboardType={keyboardType}
          onBlur={this._handleBlur}
          value={value}
          testID={testID}
          {...props}
        />
        <StyledInputAccessory
          attachedToKeyboard={attachedToKeyboard}
          onCancel={onCancel}
          onSubmit={this._handleSubmit}
          nativeID={this.inputAccessoryViewID}
        />
      </>
    );
  }
}
