/**
 * @generated SignedSource<<6a8c7b0bdd69e392709d5ba17250d7b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerShiftApplets_shift$data = {
  readonly applets: ReadonlyArray<{
    readonly params: string;
    readonly url: string;
  }>;
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly requiredPermissions: ReadonlyArray<string>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useEngagementData">;
  readonly " $fragmentType": "WorkerShiftApplets_shift";
};
export type WorkerShiftApplets_shift$key = {
  readonly " $data"?: WorkerShiftApplets_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftApplets_shift">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerShiftApplets_shift",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "hasBillingInfo",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "useEngagementData"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredPermissions",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationApplet",
      "kind": "LinkedField",
      "name": "applets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "params",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "6fd4b701aab5b8227e58726eab25df99";

export default node;
