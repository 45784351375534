import React from "react";
import { View } from "react-native";

import { useStyles } from "../../style";

interface Props {
  selected?: boolean;
  disabled?: boolean;
}

export default function RadioButtonCircle({ selected, disabled }: Props) {
  const { styles } = useStyles(({ color, units }) => ({
    circle: {
      height: units(5),
      width: units(5),
      borderRadius: 10,
      borderWidth: 2,
      borderColor: color.blue,
      alignItems: "center",
      justifyContent: "center"
    },
    checkedCircle: {
      width: units(3),
      height: units(3),
      borderRadius: 7,
      backgroundColor: color.blue
    },
    dim: { opacity: 0.5 }
  }));
  return (
    <View style={[styles.circle, disabled && styles.dim]}>
      {selected && <View style={styles.checkedCircle} />}
    </View>
  );
}
