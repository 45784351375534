import { Platform } from "@gigsmart/atorasu";
import {
  getConnectionNodes,
  graphql,
  useFetchQueryResult,
  useRelayFragment,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { compact } from "lodash";
import type { useUserPronounQuery } from "./__generated__/useUserPronounQuery.graphql";
import type { useUserPronounSetMutation } from "./__generated__/useUserPronounSetMutation.graphql";
import type { useUserPronounUnsetMutation } from "./__generated__/useUserPronounUnsetMutation.graphql";
import type { useUserPronoun_Worker$key } from "./__generated__/useUserPronoun_Worker.graphql";

export default function useUserPronoun() {
  const [{ userPronouns = null, viewer = null } = {}] =
    useFetchQueryResult<useUserPronounQuery>(
      graphql`
        query useUserPronounQuery {
          userPronouns(first: 10) {
            edges {
              node {
                id
                name
              }
            }
          }
          viewer {
            ... on Worker {
              ...useUserPronoun_Worker
            }
          }
        }
      `,
      {}
    );

  const frag = useRelayFragment<useUserPronoun_Worker$key>(
    graphql`
      fragment useUserPronoun_Worker on Worker {
        pronoun {
          id
          name
        }
      }
    `,
    viewer ?? null
  );

  const [setUserPronoun] = useRelayMutationPromise<useUserPronounSetMutation>(
    graphql`
      mutation useUserPronounSetMutation($input: SetUserPronounInput!) {
        setUserPronoun(input: $input) {
          user {
            ... on Worker {
              ...useUserPronoun_Worker
            }
          }
        }
      }
    `
  );

  const [unsetUserPronoun] =
    useRelayMutationPromise<useUserPronounUnsetMutation>(
      graphql`
        mutation useUserPronounUnsetMutation($input: UnsetUserPronounInput!) {
          unsetUserPronoun(input: $input) {
            user {
              ... on Worker {
                ...useUserPronoun_Worker
              }
            }
          }
        }
      `
    );

  const pronounList = compact([
    Platform.OS !== "ios" && { id: "reset", name: " - " },
    ...getConnectionNodes(userPronouns)
  ]);
  return {
    pronounList,
    pronoun: frag?.pronoun,
    setUserPronoun,
    unsetUserPronoun
  };
}
