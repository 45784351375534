import React, { useState } from "react";

import {
  Button,
  ContentArea,
  Row,
  ScreenScroll,
  Spacer,
  Stack,
  Surface,
  Text,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit } from "@gigsmart/fomu";
import {
  NavPortalEntrance,
  defaultBackHandler,
  useHistory
} from "@gigsmart/kaizoku";
import { confirmPrompt } from "@gigsmart/katana";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import EmergencyContactForm from "./EmergencyContactForm";

const MAX_ALLOWED_CONTACTS = 2;

type FormData = ReadonlyArray<
  | {
      readonly node:
        | {
            readonly emailAddress: string;
            readonly firstName: string;
            readonly id: string;
            readonly lastName: string;
            readonly mobileNumber: string;
            readonly relationship: string;
          }
        | null
        | undefined;
    }
  | null
  | undefined
>;

interface Props {
  workerId: string;
  primaryEmail: string;
  primaryPhone: string | null | undefined;
  birthDate: string | null | undefined;
  initialValues: any;
  formData: FormData;
  refetchData: () => Promise<void>;
}

export default function EmergencyContactFormContainer({
  workerId,
  primaryEmail,
  primaryPhone,
  birthDate,
  initialValues,
  formData,
  refetchData
}: Props) {
  const history = useHistory();
  const [displayFirstForm, setDisplayFirstForm] = useState(false);
  const [displaySecondForm, setDisplaySecondForm] = useState(false);

  const [addContactMutation] = useRelayMutationPromise(graphql`
    mutation EmergencyContactFormContainerAddMutation(
      $input: AddWorkerEmergencyContactInput!
    ) {
      addWorkerEmergencyContact(input: $input) {
        newWorkerEmergencyContactEdge {
          cursor
          node {
            id
          }
        }
      }
    }
  `);

  const [editContactMutation] = useRelayMutationPromise(graphql`
    mutation EmergencyContactFormContainerEditMutation(
      $input: UpdateWorkerEmergencyContactInput!
    ) {
      updateWorkerEmergencyContact(input: $input) {
        workerEmergencyContact {
          id
        }
      }
    }
  `);

  const handleSubmit: FomuSubmitFn = async ({ values }) => {
    const promises: Array<{ submitFn: any; input: any }> = [];

    for (let index = 1; index <= MAX_ALLOWED_CONTACTS; index++) {
      const workerEmergencyContactId =
        values[`workerEmergencyContactId-contact-${index}`];

      if (values[`emailAddress-contact-${index}`]) {
        const submitFn = workerEmergencyContactId
          ? editContactMutation
          : addContactMutation;

        const input = {
          emailAddress: values[`emailAddress-contact-${index}`],
          firstName: values[`firstName-contact-${index}`],
          lastName: values[`lastName-contact-${index}`],
          mobileNumber: values[`mobileNumber-contact-${index}`],
          relationship: values[`relationship-contact-${index}`],
          ...(workerEmergencyContactId && {
            workerEmergencyContactId
          }),
          ...(!workerEmergencyContactId && { workerId })
        };

        promises.push({ submitFn, input });
      }
    }

    try {
      await Promise.all(
        promises.map((promise) => promise.submitFn({ input: promise.input }))
      );
      toast.success("Emergency contacts successfully saved!");
      history.replace("/profile");
      refetchData();
    } catch (err) {
      toast.error(err);
    }
  };

  const AddFirstContactButton = () => (
    <Row grow={0.5} justifyContent="center">
      <Button
        label="Add Emergency Contact"
        icon="plus"
        variant="clear"
        outline
        testID="add-emergency-contact-button"
        size="small"
        onPress={() => setDisplayFirstForm(true)}
      />
    </Row>
  );

  const AddAnotherContactButton = () => (
    <Row grow={0.5} justifyContent="center">
      <Button
        label="Add Another Emergency Contact"
        icon="plus"
        variant="clear"
        testID="add-other-emergency-contact-button"
        size="small"
        onPress={() => setDisplaySecondForm(true)}
      />
    </Row>
  );

  const SubmitButton = () => (
    <FormSubmit>
      {({ submit, dirty, invalid }) => (
        <>
          <NavPortalEntrance
            showBack
            onBackPress={() => {
              if (dirty) {
                confirmPrompt({
                  title: "Unsaved Changes",
                  subTitle:
                    "Are you sure you want to go back? Any changes you made will not be saved.",
                  yesLabel: "Yes, Go Back",
                  cancelLabel: "No, Cancel",
                  onDo: () => defaultBackHandler()
                });
                return true; // backpress is handled
              }
            }}
          />
          <Surface>
            <ContentArea>
              <Button
                label="Save"
                testID="save-emergency-contacts"
                disabled={invalid || !dirty}
                onPress={submit}
              />
            </ContentArea>
            <Spacer />
          </Surface>
        </>
      )}
    </FormSubmit>
  );

  const currentContacts = formData;
  const currentContactCount = formData.length ?? 0;

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      <ScreenScroll
        testID="emergency-contact-form-screen"
        color="surface"
        footer={<SubmitButton />}
      >
        <ContentArea>
          <Stack size="medium">
            <Text variant="header" color="primary">
              Emergency Contacts
            </Text>
            <Text>
              Emergency contacts will be notified every time you accept a Gig.
            </Text>
          </Stack>
          <Spacer />
          {currentContactCount === 0 && !displayFirstForm && (
            <>
              <AddFirstContactButton />
              <Spacer />
            </>
          )}
          {(currentContactCount >= 1 || displayFirstForm) && (
            <EmergencyContactForm
              formIndex={1}
              birthDate={birthDate}
              workerEmergencyContactId={currentContacts[0]?.node?.id ?? ""}
              accountEmailAddress={primaryEmail ?? ""}
              accountPhoneNumber={primaryPhone}
              onContactRemove={refetchData}
              initialValues={initialValues}
              hideForm={() => setDisplayFirstForm(false)}
            />
          )}
          <Spacer />
          {(displayFirstForm || currentContacts[0]?.node?.id) &&
            !displaySecondForm &&
            !currentContacts[1]?.node?.id && <AddAnotherContactButton />}
          {(displaySecondForm || currentContacts[1]?.node?.id) && (
            <EmergencyContactForm
              formIndex={2}
              birthDate={birthDate}
              workerEmergencyContactId={currentContacts[1]?.node?.id ?? ""}
              accountEmailAddress={primaryEmail ?? ""}
              accountPhoneNumber={primaryPhone}
              onContactRemove={refetchData}
              initialValues={initialValues}
              hideForm={() => setDisplaySecondForm(false)}
            />
          )}
        </ContentArea>
      </ScreenScroll>
    </Form>
  );
}
