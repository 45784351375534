import {
  Button,
  Spacer,
  Stack,
  type StepProps,
  StepSubmit,
  toast,
  useResponsiveValue
} from "@gigsmart/atorasu";
import { createConversion } from "@gigsmart/dekigoto";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { useRoute } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import React, { useCallback, useEffect } from "react";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";
import type { SMSVerificationStepResendCodeMutation } from "./__generated__/SMSVerificationStepResendCodeMutation.graphql";
import type { SMSVerificationStepSmsConfirmationMutation } from "./__generated__/SMSVerificationStepSmsConfirmationMutation.graphql";
import type { OnboardingData } from "./types";

const SmsVerificationConversionTracker = createConversion(
  "onboarding_sms_verification"
);

type Props = StepProps<OnboardingData>;

export default function SMSVerificationStep({ stepper }: Props) {
  const route = useRoute();
  const { setOptions, data } = stepper;
  useEffect(() => {
    void SmsVerificationConversionTracker.track({
      step_index: stepper.index,
      event: "sms_verification_step",
      url: route.path
    });
  }, []);

  const [resendCode] =
    useRelayMutation<SMSVerificationStepResendCodeMutation>(graphql`
      mutation SMSVerificationStepResendCodeMutation {
        sendUserMobileConfirmation(input: {}) {
          ok
        }
      }
    `);
  const [confirmCode] =
    useRelayMutation<SMSVerificationStepSmsConfirmationMutation>(graphql`
      mutation SMSVerificationStepSmsConfirmationMutation(
        $input: ConfirmUserMobileInput!
      ) {
        confirmUserMobile(input: $input) {
          user {
            id
            primaryMobile {
              confirmed
              number
            }
            ... on Worker {
              accessState
            }
            ... on RequesterLike {
              postalCode
              onboardingStates {
                onboardingState
                complete
              }
            }
          }
        }
      }
    `);

  const handleResendCode = useCallback(() => {
    resendCode(
      {},
      {
        onSuccess: () => {
          toast.success(
            "A confirmation code has been re-sent to the mobile number provided."
          );
        },
        onError: (result) => {
          toast.error(
            result.message || "Unexpected error while resending the code."
          );
        }
      }
    );
  }, []);

  const handleSubmit: FomuSubmitFn = useCallback(({ values }, done) => {
    confirmCode(
      { input: { token: values.smsCode ?? "" } },
      {
        onSuccess: () => stepper.nextStep(),
        onError: (result) => {
          done();
          toast.error(
            result.message || "Unexpected error while updating phone number"
          );
        }
      }
    );
  }, []);

  const btnFill = useResponsiveValue<number | boolean>({
    mini: 1,
    large: false
  });

  useEffect(() => {
    setOptions({
      title: `Please enter the 6-digit code sent to ${data?.phone}`
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <FomuTextInput
        name="smsCode"
        validates={Validator.length({ exact: 6 })}
        testID="sms-code-confirmation-input"
        label="Code"
        type="number"
        keyboardType="numeric"
        autoFocus
        maxLength={6}
      />
      <Spacer />
      <Stack horizontal>
        <Button
          fill={btnFill}
          label="Edit Mobile Number"
          outline
          size="small"
          testID="edit-number-button"
          onPress={() => stepper.prevStep()}
        />
        <Button
          fill={btnFill}
          label="Resend Code"
          outline
          size="small"
          testID="resend-code-button"
          onPress={handleResendCode}
        />
      </Stack>
      <StepSubmit
        testID="next-btn"
        label={stepper.hasNextStep ? "Next" : "Update"}
      />
    </Form>
  );
}
