import React, { Component, type ReactNode } from "react";
import { Dimensions, type GestureResponderEvent, View } from "react-native";

import type { ViewStyleProp } from "../style";

interface Props {
  children: ReactNode;
  onDragDown?: any;
  onDragLeft?: any;
  onDragRight?: any;
  onDragUp?: any;
  requireRelease?: boolean;
  style?: ViewStyleProp;
  tolerance: number;
  triggerDistance: number;
}

interface State {
  locked: boolean;
  startX: number;
  startY: number;
}

export default class SwipeView extends Component<Props, State> {
  static defaultProps = {
    style: {},
    tolerance: 50,
    triggerDistance: 150
  };

  state: State = {
    locked: false,
    startX: 0,
    startY: 0
  };

  onDragStart = (event: GestureResponderEvent) => {
    this.setState({
      locked: false,
      startX: event.nativeEvent.pageX,
      startY: event.nativeEvent.pageY
    });
    return !this.state.locked;
  };

  onTrigger = (event: GestureResponderEvent) => {
    const { height, width } = Dimensions.get("window");
    const { locked, startX, startY } = this.state;
    const { pageX, pageY } = event.nativeEvent;
    const { tolerance, triggerDistance } = this.props;

    if (
      !locked &&
      startX &&
      startY &&
      pageX !== 0 &&
      pageX !== width &&
      pageY !== 0 &&
      pageY !== height
    ) {
      const deltaX = startX - pageX;
      const deltaY = startY - pageY;
      if (
        Math.abs(deltaX) > Math.abs(deltaY) &&
        Math.abs(deltaX) >= triggerDistance &&
        Math.abs(deltaY) <= tolerance
      ) {
        this.setState({ locked: true }, () => {
          if (deltaX > 0) {
            this.onDragLeft(event);
          } else if (deltaX < 0) {
            this.onDragRight(event);
          }
        });
      } else if (
        Math.abs(deltaY) > Math.abs(deltaX) &&
        Math.abs(deltaY) >= triggerDistance &&
        Math.abs(deltaX) <= tolerance
      ) {
        this.setState({ locked: true }, () => {
          if (deltaY > 0) {
            this.onDragUp(event);
          } else if (deltaY < 0) {
            this.onDragDown(event);
          }
        });
      }
    }
  };

  onDragDown = (event: GestureResponderEvent) => {
    this.props.onDragDown?.(event);
  };

  onDragLeft = (event: GestureResponderEvent) => {
    this.props.onDragLeft?.(event);
  };

  onDragRight = (event: GestureResponderEvent) => {
    this.props.onDragRight?.(event);
  };

  onDragUp = (event: GestureResponderEvent) => {
    this.props.onDragUp?.(event);
  };

  render() {
    const { children, requireRelease } = this.props;
    const onMove = !requireRelease ? this.onTrigger : undefined;
    const onRelease = requireRelease ? this.onTrigger : undefined;

    return (
      <View
        style={this.props.style}
        onStartShouldSetResponder={this.onDragStart}
        onResponderMove={onMove}
        onResponderRelease={onRelease}
      >
        {children}
      </View>
    );
  }
}
