/**
 * @generated SignedSource<<0a0ff5d5a34622a976023add49003314>>
 * @relayHash 51c31e3b3ed32034a4b6115b628e6467
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:MVp7VHeu2SqeQjHutrOxaSUKonWYHt1YemQcTiFLiM0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetUserEmailInput = {
  address?: string | null | undefined;
  userId?: string | null | undefined;
};
export type ChangeEmailFormCardSetEmailMutation$variables = {
  input: SetUserEmailInput;
};
export type ChangeEmailFormCardSetEmailMutation$data = {
  readonly setUserEmail: {
    readonly userEmail: {
      readonly " $fragmentSpreads": FragmentRefs<"ChangeEmailFormCard_userEmail">;
    };
  } | null | undefined;
};
export type ChangeEmailFormCardSetEmailMutation = {
  response: ChangeEmailFormCardSetEmailMutation$data;
  variables: ChangeEmailFormCardSetEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEmailFormCardSetEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserEmailPayload",
        "kind": "LinkedField",
        "name": "setUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "userEmail",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChangeEmailFormCard_userEmail"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeEmailFormCardSetEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserEmailPayload",
        "kind": "LinkedField",
        "name": "setUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmail",
            "kind": "LinkedField",
            "name": "userEmail",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:MVp7VHeu2SqeQjHutrOxaSUKonWYHt1YemQcTiFLiM0",
    "metadata": {},
    "name": "ChangeEmailFormCardSetEmailMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d60536dcee8f87fb8969dd78d1f3fca4";

export default node;
