import { Screen, Spacer, toast } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Form, type ValueObject } from "@gigsmart/fomu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { StyledScrollView, confirmPrompt } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import EngagementInfoRow from "@gigsmart/seibutsu/engagement/EngagementInfoRow";
import Questionnaire from "@gigsmart/seibutsu/questionnaire/Questionnaire";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { completionQuestionnaireScreenQuery } from "./__generated__/completionQuestionnaireScreenQuery.graphql";
import useGigCompletionQuestions from "./use-gig-completion-questions";
import useSetSummary from "./use-set-summary";

type Props = AppScreenProps<WorkerParamList, "MarkProjectComplete">;

export default createSuspendedQueryContainer<
  completionQuestionnaireScreenQuery,
  Props
>(
  function CompletionQuestionnaire({
    route,
    navigation,
    response: { node } = {}
  }) {
    const styles = useStyles(() => ({
      flexGrow: { flexGrow: 1 },
      fullWidth: { width: "100%" }
    }));

    const engagementId = route.params?.id ?? "";
    const setSummary = useSetSummary(engagementId, true);

    const confirmCompletions = (onConfirm?: () => void) => {
      confirmPrompt({
        title: "Continue & Mark Project as Complete",
        subTitle:
          "Are you sure you want to Mark your Project as Complete? Your Project Summary will be sent to the Requester for review.",
        actions: [
          {
            title: "Yes, Continue & Mark Project as Complete",
            onPress: onConfirm
          },
          { title: "No, Cancel" }
        ]
      });
    };

    const onSubmit = ({ values }: ValueObject) => {
      const {
        projectComplete,
        beenPaid,
        paymentType,
        expectedPaymentType,
        expectedPayment
      } = values;
      if (projectComplete === "no") {
        const isProject = node?.gigType === "PROJECT";
        navigation.replace(isProject ? "ProjectDetails" : "ShiftDetails", {
          id: engagementId
        });
        toast.notice(
          "Please Mark Project as Complete after the Project is done"
        );
        return;
      }
      const paid = beenPaid === "yes";
      confirmCompletions(() =>
        setSummary(
          paid,
          paid ? paymentType : expectedPaymentType ?? expectedPayment
        )
      );
    };

    const questions = useGigCompletionQuestions();
    return (
      <Screen testID="completion-questionnaire-screen">
        <StyledScrollView
          testID="completion-questionnaire"
          color="neutralLight"
          style={styles.fullWidth}
          contentContainerStyle={styles.flexGrow}
        >
          <EngagementInfoRow fragmentRef={node} />
          <Spacer />
          <Form onSubmit={onSubmit}>
            <Questionnaire questions={questions} question="PROJECT_COMPLETE" />
          </Form>
        </StyledScrollView>
      </Screen>
    );
  },
  {
    query: graphql`
      query completionQuestionnaireScreenQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            gigType
            ...EngagementInfoRow_engagement
          }
        }
      }
    `,
    variables: ({ route }) => ({ engagementId: route.params.id })
  }
);
