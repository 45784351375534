import { useNavigation, useRoute } from "@gigsmart/kaizoku";
import React, { useCallback } from "react";
import TabLayout, { type TabLayoutProps } from "./tab-layout";
import type { TabConfig } from "./types";

export type TabRouteProps<K extends string, T extends TabConfig<K>> = Omit<
  TabLayoutProps<K, T>,
  "active" | "onSelectTab"
> & {
  paramName?: string;
  defaultActive?: K;
};

/** @deprecated do not use it */
const TabRoute = <K extends string, T extends TabConfig<K> = TabConfig<K>>({
  paramName = "tab",
  defaultActive,
  ...props
}: TabRouteProps<K, T>) => {
  const route = useRoute();
  const params: any = route.params;
  const active = params[paramName] ?? defaultActive;
  const handleSelectTab = useUpdateCurrentTab(paramName);

  return <TabLayout {...props} active={active} onSelectTab={handleSelectTab} />;
};

/** @deprecated do not use it */
export const useUpdateCurrentTab = <K extends string>(paramName = "tab") => {
  const navigation = useNavigation();
  const setTab = useCallback(
    (tab: K) => {
      navigation.setParams({
        [paramName]: tab === "index" ? undefined : tab
      } as any);
    },
    [paramName]
  );

  return setTab;
};

export default TabRoute;
