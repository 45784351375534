/**
 * @generated SignedSource<<b3f7d0bb7013ee53838ee38387241264>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VerifyMileage_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly estimatedMileage: number | null | undefined;
  };
  readonly systemTimesheet: {
    readonly startedCount: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly workerTimesheet: {
    readonly editable: boolean;
    readonly isOverridden: boolean;
  } | null | undefined;
  readonly " $fragmentType": "VerifyMileage_engagement";
};
export type VerifyMileage_engagement$key = {
  readonly " $data"?: VerifyMileage_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerifyMileage_engagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "VerifyMileage_engagement"
};

(node as any).hash = "a6f4a84ee5af223b02aca31589b3e981";

export default node;
