import {
  ActionRow,
  CollapsibleContainer,
  ContentArea,
  FancyHeader,
  GridNull,
  Image,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import React, { useMemo } from "react";
import type { HomescreenPendingDisputesCardQuery } from "./__generated__/HomescreenPendingDisputesCardQuery.graphql";
import type { HomescreenPendingDisputesCardSubscription } from "./__generated__/HomescreenPendingDisputesCardSubscription.graphql";

export default createSuspendedQueryContainer<HomescreenPendingDisputesCardQuery>(
  function HomescreenPendingDisputesCard({ response: result }) {
    const history = useHistory();

    useRelaySubscription<HomescreenPendingDisputesCardSubscription>(
      graphql`
        subscription HomescreenPendingDisputesCardSubscription {
          workerUpdated {
            worker {
              engagements(
                first: 10
                where: { anyEngagementDisputeDisposition: { _isNull: true }, gigType: { _neq: PROJECT } }
              ) {
                totalCount
                edges {
                  node {
                    id
                    disputes(
                      first: 1
                      where: { approvalDisposition: { _isNull: true } }
                    ) {
                      edges {
                        node {
                          disputeTarget {
                            ... on EngagementTimesheet {
                              disputedFinalTimesheet {
                                diff {
                                  totalPayment
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    gig {
                      name
                      organization {
                      logoUrl
                      }
                      position {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {},
      {
        subscribe: true
      }
    );

    const [firstThree, rest] = useMemo(() => {
      const engagements = getConnectionNodes(result?.viewer?.engagements);
      return [engagements.slice(0, 3), engagements.slice(3)];
    }, [result]);

    if (!result?.viewer?.engagements?.totalCount) return <GridNull />;

    return (
      <>
        <CollapsibleContainer
          testID="pending-disputes-container"
          startCollapsed
          variant="card"
          header={
            <FancyHeader
              icon="file-exclamation"
              title={`${pluralize(
                result?.viewer?.engagements?.totalCount,
                "Dispute"
              )} ${
                result?.viewer?.engagements?.totalCount === 1 ? "is" : "are"
              } Pending a Response`}
              color="black"
              variant="warning"
            />
          }
          content={
            <ContentArea size="compact">
              <Spacer size="compact" />
              <Stack size="compact">
                {firstThree.map((shift) => (
                  <ActionRow
                    variant="outline"
                    onPress={() => history.push(`/gigs/${shift.id}`)}
                    eventEntityType="PressProductInfoRow"
                    {...{
                      left: (
                        <Image
                          height={36}
                          variant="square"
                          source={{
                            uri: shift.gig?.organization?.logoUrl ?? ""
                          }}
                        />
                      )
                    }}
                    iconSquareVariant="standard"
                    title={shift.gig.position?.name}
                    note={`${currency.humanize(
                      shift?.disputes?.edges?.[0]?.node?.disputeTarget
                        ?.disputedFinalTimesheet?.diff?.totalPayment
                    )} Submitted to Requester`}
                    testID={shift.id}
                    noteColor="neutral"
                    key={shift.id}
                    iconColor="warning"
                  />
                ))}
              </Stack>
              {rest?.length <= 0 && <Spacer size="compact" />}
            </ContentArea>
          }
          expandLabel="Show More"
          collapseLabel="Show Less"
        >
          {rest?.length > 0 && (
            <ContentArea size="none">
              <Stack size="compact">
                {rest.map((shift) => (
                  <ActionRow
                    variant="outline"
                    onPress={() => history.push(`/gigs/${shift.id}`)}
                    eventEntityType="PressProductInfoRow"
                    {...{
                      left: (
                        <Image
                          height={36}
                          variant="square"
                          source={{
                            uri: shift.gig?.organization?.logoUrl ?? ""
                          }}
                        />
                      )
                    }}
                    iconSquareVariant="standard"
                    title={shift.gig.position?.name}
                    noteColor="neutral"
                    note={`${currency.humanize(
                      shift?.disputes?.edges?.[0]?.node?.disputeTarget
                        ?.disputedFinalTimesheet?.diff?.totalPayment
                    )} Submitted to Requester`}
                    testID={shift.id}
                    key={shift.id}
                    iconColor="warning"
                  />
                ))}
              </Stack>
              <Spacer />
            </ContentArea>
          )}
        </CollapsibleContainer>
        <Spacer />
      </>
    );
  },
  {
    query: graphql`
      query HomescreenPendingDisputesCardQuery {
        viewer {
          ... on Worker {
            engagements(
              first: 10
              query: "WHERE anyEngagementDisputeDisposition = NULL"
            ) {
              totalCount
              edges {
                node {
                  id
                  disputes(
                    first: 1
                    query: "WHERE approvalDisposition = NULL"
                  ) {
                    edges {
                      node {
                        disputeTarget {
                          ... on EngagementTimesheet {
                            disputedFinalTimesheet {
                              diff {
                                totalPayment
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  gig {
                    name
                    organization {
                      logoUrl
                    }
                    position {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);
