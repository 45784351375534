/**
 * @generated SignedSource<<4da4bee1d811ca9bdfbb6e9eb09208c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type priorTransferEngagementRows_engagements$data = {
  readonly engagements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly endsAt: string | null | undefined;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"engagementRowWorker_engagement">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "priorTransferEngagementRows_engagements";
};
export type priorTransferEngagementRows_engagements$key = {
  readonly " $data"?: priorTransferEngagementRows_engagements$data;
  readonly " $fragmentSpreads": FragmentRefs<"priorTransferEngagementRows_engagements">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priorTransfersQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "priorTransferEngagementRows_engagements",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 8
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "priorTransfersQuery"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Engagement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "engagementRowWorker_engagement"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endsAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkerPayout",
  "abstractKey": null
};

(node as any).hash = "9bb8434c31fee2970f46aea782799ecf";

export default node;
