import React from "react";
import { View } from "react-native";

import { type ViewStyleProp, useStyles } from "../style";
import TiledIcon, { type TileType } from "./tiled-icon";

export type { TileType };

interface Props {
  style?: ViewStyleProp;
  onPressTile?: (tile: TileType) => void;
  tiles: TileType[];
  perRow?: number;
}

const TiledIcons = ({
  style,
  tiles,
  perRow = 3,
  onPressTile,
  ...props
}: Props) => {
  const { styles } = useStyles(
    ({ font, units }) => ({
      container: {
        flexDirection: "row",
        flexWrap: "wrap",
        paddingVertical: units(2)
      },
      iconLabel: font.withSize(font.size.small),
      tileContainer: {
        width: `${100 / perRow}%`,
        marginVertical: units(0.5)
      }
    }),
    [perRow]
  );

  const filteredTiles = tiles.filter(({ hidden }) => !hidden);
  return (
    <View style={[styles.container, style]} {...props}>
      {filteredTiles.map((tile, index) => (
        <TiledIcon
          containerStyle={styles.tileContainer}
          key={tile.key ?? `${index}`}
          testID={tile.testID || `tile-${tile.key ?? ""}`}
          tile={tile}
          onPress={onPressTile}
        />
      ))}
    </View>
  );
};

export default TiledIcons;
