import { ListRow, Surface, Text } from "@gigsmart/atorasu";
import { useFormValue } from "@gigsmart/fomu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import React from "react";
import FomuSwitch from "../../fomu/inputs/FomuSwitch";
import { HourlyRateInput } from "../HourlyRateInput";
import type { MultiDaySelectEntry } from "./MultiDaySelectGigsStep";

type Props = {
  name: string;
  basePayRate?: string | null;
  baseEstimatedPayment?:
    | number
    | string
    | { min: number | string; max: number | string }
    | null;
  minPayRate?: string;
  entries: MultiDaySelectEntry[];
};

export function HourlyRateBidFields({
  name,
  basePayRate,
  baseEstimatedPayment,
  minPayRate,
  entries
}: Props) {
  const isMultiple = entries.length > 1;
  const sameBid = useFormValue<boolean>({ name: "sameBid" }).value || false;
  const entry = entries[0];
  if (!entry) return null;

  return (
    <>
      {isMultiple && (
        <Surface variant="outline">
          <ListRow
            label={
              <Text weight="bold" color="primary">
                Same Bid for all Shifts?
              </Text>
            }
            right={<FomuSwitch name="sameBid" />}
          />
        </Surface>
      )}
      {sameBid ? (
        <HourlyRateInput
          name={name}
          entry={entry}
          numEntries={entries.length}
          minPayRate={minPayRate}
          basePayRate={basePayRate}
          baseEstimatedPayment={baseEstimatedPayment}
        />
      ) : (
        entries.map((it) => (
          <HourlyRateInput
            key={it.value}
            name={`${name}.${it.value}`}
            entry={it}
            minPayRate={minPayRate}
            basePayRate={basePayRate}
            baseEstimatedPayment={currency.toFloat(it.estimatedPayment?.netPay)}
          />
        ))
      )}
    </>
  );
}
