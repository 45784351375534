import moment from "moment-timezone";
import React, { Component } from "react";
import { Animated, type LayoutChangeEvent, View } from "react-native";

import { StyledIcon } from "../icon";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import { StyledText } from "../text";

type Props = StylesProps & {
  invertBar?: boolean;
  startTime: Date;
  endTime: Date;
  onExpire?: Function;
};

interface State {
  availableWidth: number;
  timeRemaining: {
    days?: number;
    hours?: number;
    minutes?: number;
    months?: number;
    seconds?: number;
    total?: number;
    years?: number;
  };
}

@withStyles(({ font, color }) => ({
  bar: {
    backgroundColor: color.blue,
    height: 10
  },
  container: {
    paddingLeft: 0,
    paddingTop: 0,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  time: {
    flexGrow: 1,
    flex: 1
  },
  progressContainer: {
    backgroundColor: color.neutralLight,
    borderRadius: 5,
    height: 10,
    overflow: "hidden",
    width: "auto"
  },
  progressStatus: {
    color: color.neutralLight,
    fontSize: font.size.medium,
    marginBottom: 5
  },
  clock: {
    fontSize: font.size.small,
    paddingRight: 5,
    paddingBottom: 5,
    paddingTop: 5,
    alignSelf: "flex-end"
  },
  text: {
    marginTop: 20,
    marginRight: 5,
    padding: 0,
    alignItems: "center",
    justifyContent: "center"
  }
}))
export default class TimerBar extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs
  };

  state: State = {
    availableWidth: 0,
    timeRemaining: {}
  };

  intervalId: any;

  progress: Animated.Value = new Animated.Value(0);

  UNSAFE_componentWillMount() {
    const { endTime, startTime } = this.props;
    const now = moment();
    const timeElapsed = Number(now) - Number(startTime);
    const timeRemaining = Number(moment(endTime)) - Number(now);
    const timeDuration = Number(moment(endTime)) - Number(moment(startTime));
    const percentComplete = (timeElapsed / timeDuration) * 100;

    // Invalid when endTime is before startTime
    if (startTime <= endTime) {
      this.progress.setValue(Math.round(percentComplete));

      if (timeRemaining <= 0) {
        this.setRemainingTime();
      } else {
        this.setRemainingTime();
        this.intervalId = setInterval(this.setRemainingTime, 1000);
      }

      Animated.timing(this.progress, {
        duration: timeRemaining,
        toValue: 100,
        useNativeDriver: false
      }).start();
    }
  }

  componentDidUpdate() {
    if (
      (this.state.timeRemaining.total ?? 0) < 0 &&
      this.intervalId !== undefined
    ) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  componentWillUnmount() {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
    }
  }

  get progressStyles() {
    const outputRange = [
      0,
      this.state.availableWidth / 2,
      this.state.availableWidth
    ];
    return {
      width: this.progress.interpolate({
        inputRange: [0, 50, 100],
        outputRange: this.props.invertBar ? outputRange.reverse() : outputRange
      })
    };
  }

  get timeDisplay() {
    const {
      total,
      years = 0,
      months = 0,
      days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0
    } = this.state.timeRemaining;
    let times: string[] = [];
    if (years > 0) {
      times = [this.pluralize(years, "year"), this.pluralize(months, "month")];
    } else if (months > 0) {
      times = [this.pluralize(months, "month"), this.pluralize(days, "day")];
    } else if (days > 0) {
      times = [this.pluralize(days, "day"), this.pluralize(hours, "hour")];
    } else if (hours > 0) {
      times = [
        this.pluralize(hours, "hour"),
        this.pluralize(minutes, "minute")
      ];
    } else if (minutes > 0) {
      times = [
        this.pluralize(minutes, "minute"),
        this.pluralize(seconds, "second")
      ];
    } else if (seconds > 0) {
      times = [this.pluralize(seconds, "second")];
    } else if (total !== undefined && total <= 0) {
      return "Time expired";
    }
    const timeStr = times.join(" ");
    return timeStr ? `${timeStr} to accept` : "";
  }

  onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    if (!nativeEvent) return;
    this.setState({ availableWidth: nativeEvent.layout.width });
  };

  pluralize = (count: number, label: string) => {
    return count === 1 ? `${count} ${label}` : `${count} ${label}s`;
  };

  setRemainingTime = () => {
    const diffTime = moment(this.props.endTime).diff(new Date());
    let duration = moment.duration(diffTime);
    if (duration.asSeconds() < 0) duration = moment.duration(0);

    this.setState(
      {
        timeRemaining: {
          total: diffTime,
          years: duration.years(),
          months: duration.months(),
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds()
        }
      },
      () => {
        if (
          this.state.timeRemaining.total &&
          this.state.timeRemaining.total <= 0
        ) {
          this.props?.onExpire?.();
        }
      }
    );
  };

  render() {
    const { styles } = this.props;
    const { timeRemaining } = this.state;
    const hours = timeRemaining.hours
      ? timeRemaining.hours.toString().padStart(2, "0")
      : "00";
    const minutes = timeRemaining.minutes
      ? timeRemaining.minutes.toString().padStart(2, "0")
      : "00";
    const seconds = timeRemaining.seconds
      ? timeRemaining.seconds.toString().padStart(2, "0")
      : "00";

    const deltaTimeString = `${hours}:${minutes}:${seconds}`;

    return (
      <View style={styles.container}>
        <StyledIcon name="clock" color="blue" style={styles.clock} />
        <StyledText style={styles.text}>{deltaTimeString}</StyledText>
        <View style={styles.time}>
          <StyledText style={styles.progressStatus} />
          <View style={styles.progressContainer} onLayout={this.onLayout}>
            <Animated.View
              testID="animated-bar"
              style={[styles.bar, this.progressStyles]}
            />
          </View>
        </View>
      </View>
    );
  }
}
