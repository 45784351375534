import React from "react";

import StyledView from "../container/styled-view";
import { Flex } from "../flex";
import { ViewportRenderer } from "../style";
import StyledSearchBar from "./styled-search-bar";
import StyledSearchInput from "./styled-search-input";

interface Props {
  handleSearch: (val: string) => void;
  placeholder?: string;
  testID?: string;
}

export default function StyledSearch({
  handleSearch,
  testID = "search-input",
  placeholder = ""
}: Props) {
  return (
    <Flex>
      <ViewportRenderer
        renderableBreakpoints={["@phone", "@none", "@phoneDown"]}
      >
        <StyledView padding={2}>
          <StyledSearchBar onSearch={handleSearch} placeholder={placeholder} />
        </StyledView>
      </ViewportRenderer>
      <ViewportRenderer
        renderableBreakpoints={[
          "@tablet",
          "@tabletDown",
          "@desktop",
          "@desktopWide"
        ]}
      >
        <Flex fill={1} alignItems="center">
          <StyledSearchInput
            testID={testID}
            onSearch={handleSearch}
            placeholder={placeholder}
          />
        </Flex>
      </ViewportRenderer>
    </Flex>
  );
}
