import {
  Button,
  ContentArea,
  ScreenScroll,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import {
  type FomuOnSubmitArgs,
  Form,
  FormSubmit,
  FormValues,
  type ValueObject
} from "@gigsmart/fomu";
import FomuNavBackButton from "@gigsmart/isomorphic-shared/nav/fomu-nav-back-button";
import type { StepProps } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ApprovedTimesheetModalRow from "@gigsmart/seibutsu/engagement-dispute/worker/ApprovedTimesheetModalRow";
import DisputeCommentInput from "@gigsmart/seibutsu/engagement-dispute/worker/DisputeCommentInput";
import DisputedTimesheetProjectionsSurface from "@gigsmart/seibutsu/engagement-dispute/worker/DisputedTimesheetProjectionsSurface";
import type { StepPropsType } from "@gigsmart/seibutsu/engagement-dispute/worker/types";
import EditTimesheetBreaks from "@gigsmart/seibutsu/engagement/EditTimesheetBreaks";
import { EditTimesheetTimeWorkedCard } from "@gigsmart/seibutsu/engagement/EditTimesheetTimeWorkedCard";
import { createTimesheetOverrides } from "@gigsmart/seibutsu/engagement/timesheetEditHooks";
import timesheetInitialValues from "@gigsmart/seibutsu/engagement/timesheetInitialValues";
import useSetEngagementSummary, {
  type SetEngagementSummaryInput
} from "@gigsmart/seibutsu/engagement/useSetEngagementSummary";
import StepHeader from "@gigsmart/seibutsu/shared/Stepper/StepHeader";
import React, { useMemo, useState } from "react";
import type { createDisputeTimesheetStepQuery } from "./__generated__/createDisputeTimesheetStepQuery.graphql";

type Props = StepProps<StepPropsType>;

export default createSuspendedQueryContainer<
  createDisputeTimesheetStepQuery,
  Props
>(
  function CreateDisputeTimesheetStep({
    gotoStepByName,
    stepData,
    prevStep,
    response: result
  }) {
    const [timeDiff, setTimeDiff] = useState(0);

    const engagement = result?.engagement;
    const disputeTimesheet = result?.timesheet;
    const commit = useSetEngagementSummary();
    const disputeTimesheetExists = !!result?.timesheet?.id;
    const {
      initialValues,
      engagementStartTime,
      initialStartTime,
      initialEndTime,
      initialBreaks
    } = useMemo(
      () =>
        timesheetInitialValues(
          false,
          disputeTimesheetExists
            ? (result?.timesheet as any)
            : (result?.engagement?.finalTimesheet as any),
          result?.engagement?.startsAt,
          result?.engagement?.endsAt,
          engagement?.timezone ?? ""
        ),
      [disputeTimesheetExists, result?.timesheet, result?.engagement]
    );
    const { initialValues: finalTimesheetInitialValues } = useMemo(
      () =>
        timesheetInitialValues(
          false,
          result?.engagement?.finalTimesheet,
          engagement?.startsAt,
          engagement?.endsAt,
          engagement?.timezone ?? ""
        ),
      [engagement?.finalTimesheet, engagement?.startsAt, engagement?.endsAt]
    );
    const [breaks, setBreaks] = useState(initialBreaks);
    const handleSubmit = ({ values }: FomuOnSubmitArgs, done: () => void) => {
      const timesheet = disputeTimesheet ?? engagement?.finalTimesheet;
      let originalStartsAt = engagement?.finalTimesheet?.states?.edges?.find(
        (d) => d?.node?.action === "START"
      )?.node?.transitionedAt;
      if (!originalStartsAt) {
        originalStartsAt = engagement?.finalTimesheet?.states?.edges?.find(
          (d) => d?.node?.name === "SCHEDULED"
        )?.node?.transitionedAt;
      }
      const overrides = createTimesheetOverrides(
        values,
        breaks,
        timesheet?.states?.edges ?? [],
        originalStartsAt,
        engagement?.timezone ?? ""
      );
      const input: SetEngagementSummaryInput = {
        engagementId: stepData.engagementId ?? "",
        disputedTimesheetId: engagement?.finalTimesheet?.id,
        overrides,
        isApproved: false,
        complete: false,
        createOverrides: true,
        paymentStyle: "CALCULATED"
      };
      if (!stepData?.disputeTimesheetId) {
        input.additionalPayment = engagement?.finalTimesheet?.additionalPayment;
      }
      commit(
        {
          input
        },
        {
          onSuccess: (result) =>
            gotoStepByName("dispute-payment", {
              ...stepData,
              disputeTimesheetId:
                result?.setEngagementSummary?.engagementTimesheet?.id,
              timesheetComment: values?.timesheetComment
            })
        }
      );
    };

    return (
      <ScreenScroll testID="create-dispute-timesheet-step">
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            ...initialValues,
            timesheetComment: stepData?.timesheetComment
          }}
        >
          <FomuNavBackButton onGoBack={prevStep} />
          <Stack fill>
            <StepHeader
              name="Create Dispute Timesheet"
              note="Please update your Timesheet below."
            />
            {engagement?.systemTimesheet && engagement?.finalTimesheet && (
              <ApprovedTimesheetModalRow
                timeWorked={engagement?.finalTimesheet?.totalDurationWorked}
                systemTimesheet={engagement?.systemTimesheet}
                approvedTimesheet={engagement?.finalTimesheet}
                timezone={engagement?.timezone ?? ""}
              />
            )}
            <EditTimesheetTimeWorkedCard
              title="What time did you start and end working?"
              engagementStartTime={engagementStartTime}
              initialStartTime={initialStartTime}
              initialEndTime={initialEndTime}
              systemTimesheet={engagement?.finalTimesheet ?? null}
              timesheetVariant={
                disputeTimesheetExists ? (disputeTimesheet as any) : null
              }
              breaks={initialBreaks}
              onRemoveTimeWorked={() => null}
              allowReset={false}
              appVariant={"worker"}
              timezone={engagement?.timezone ?? null}
              minimumPaidGigDuration={
                engagement?.finalTimesheet?.totalDurationWorked
              }
            />
            <EditTimesheetBreaks
              breaks={breaks}
              setBreaks={setBreaks}
              volunteer={engagement?.gig?.gigType === "VOLUNTEER"}
              initialValues={initialValues}
              timezone={engagement?.timezone ?? ""}
              variant="worker"
              minimumPaidGigDuration={
                engagement?.finalTimesheet?.totalDurationWorked
              }
            />
            <FormValues>
              {({ values, errors }) => (
                <DisputedTimesheetProjectionsSurface
                  totalDurationWorked={
                    engagement?.finalTimesheet?.totalDurationWorked
                  }
                  paymentStyle={engagement?.finalTimesheet?.paymentStyle}
                  initialStates={finalTimesheetInitialValues}
                  updatedStates={values}
                  errors={errors}
                  breaks={breaks}
                  initialBreaks={initialBreaks}
                  payRate={engagement?.paymentInfo?.payRate}
                  fees={engagement?.paymentInfo?.serviceFees}
                  setTimeDiff={setTimeDiff}
                />
              )}
            </FormValues>
            <DisputeCommentInput
              name="timesheetComment"
              label="Why are you updating your Timesheet?"
              placeholder="Provide as much detail as possible in order to help explain to the Requester why you are updating your Timesheet."
            />
            <FormValues>
              {({ values }) => (
                <FormSubmit>
                  {({ submit, invalid, submitting }) => {
                    const isEquals = checkEquals(
                      breaks,
                      initialValues,
                      values ?? {}
                    );
                    return (
                      <ContentArea size="none">
                        <Button
                          testID="save-btn"
                          label="Save Changes"
                          disabled={
                            invalid ||
                            timeDiff <= 0 ||
                            (isEquals &&
                              stepData?.timesheetComment ===
                                values?.timesheetComment)
                          }
                          onPress={submit}
                        />
                      </ContentArea>
                    );
                  }}
                </FormSubmit>
              )}
            </FormValues>
            <Spacer />
          </Stack>
        </Form>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query createDisputeTimesheetStepQuery(
        $engagementId: ID!
        $timesheetId: ID!
        $hasTimesheetId: Boolean!
      ) {
        engagement: node(id: $engagementId) {
          ... on Engagement {
            timezone
            gig {
              gigType
              estimatedMileage
            }
            startsAt
            endsAt
            paymentInfo {
              payRate
              serviceFees {
                hourlyRate
                amount
                feeType
              }
            }
            finalTimesheet: timesheet(variant: FINAL) {
              id
              additionalPayment
              paymentStyle
              totalDurationWorked
              estimatedMileage
              states(
                first: 50
                overridden: true
                query: "ORDER BY transitionedAt ASC"
              ) {
                edges {
                  node {
                    __typename
                    id
                    name
                    transitionedAt
                    action
                    transitionedBy {
                      __typename
                      displayName
                    }
                    negotiation {
                      payRate
                      submittedBy {
                        __typename
                        displayName
                      }
                    }
                    ... on EngagementStateOverride {
                      overridesState {
                        id
                      }
                    }
                  }
                }
              }
            }
            systemTimesheet: timesheet(variant: SYSTEM) {
              id
              paymentStyle
              totalDurationWorked
              estimatedMileage
              states(
                first: 50
                overridden: true
                query: "ORDER BY transitionedAt ASC"
              ) {
                edges {
                  node {
                    __typename
                    id
                    name
                    transitionedAt
                    action
                    transitionedBy {
                      __typename
                      displayName
                    }
                    negotiation {
                      payRate
                      submittedBy {
                        __typename
                        displayName
                      }
                    }
                    ... on EngagementStateOverride {
                      overridesState {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        timesheet: node(id: $timesheetId) @include(if: $hasTimesheetId) {
          ... on EngagementTimesheet {
            id
            disputedTimesheet {
              id
            }
            totalDurationWorked
            estimatedMileage
            states(
              first: 50
              overridden: true
              query: "ORDER BY transitionedAt ASC"
            ) {
              edges {
                node {
                  __typename
                  id
                  name
                  transitionedAt
                  action
                  transitionedBy {
                    __typename
                    displayName
                  }
                  negotiation {
                    payRate
                    submittedBy {
                      __typename
                      displayName
                    }
                  }
                  ... on EngagementStateOverride {
                    overridesState {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ stepData }) => ({
      engagementId: stepData.engagementId ?? "",
      timesheetId: stepData?.disputeTimesheetId ?? "",
      hasTimesheetId: !!stepData?.disputeTimesheetId
    })
  }
);

function checkEquals(
  breaks: number[],
  initialValues: ValueObject,
  values: ValueObject
) {
  return (
    initialValues.mileage === values.mileage &&
    initialValues.includeBreaks === values.includeBreaks &&
    checkDateEquals(initialValues, values, "startTimeDate") &&
    checkDateEquals(initialValues, values, "endTimeDate") &&
    initialValues.startTimeTime === values.startTimeTime &&
    initialValues.startTimeAmpm === values.startTimeAmpm &&
    initialValues.endTimeTime === values.endTimeTime &&
    initialValues.endTimeAmpm === values.endTimeAmpm &&
    (values.includeBreaks ? breaks : []).every(
      (n) =>
        checkDateEquals(initialValues, values, `break${n}StartDate`) &&
        checkDateEquals(initialValues, values, `break${n}EndDate`) &&
        initialValues[`break${n}StartTime`] === values[`break${n}StartTime`] &&
        initialValues[`break${n}StartAmpm`] === values[`break${n}StartAmpm`] &&
        initialValues[`break${n}EndTime`] === values[`break${n}EndTime`] &&
        initialValues[`break${n}EndAmpm`] === values[`break${n}EndAmpm`]
    )
  );
}

function checkDateEquals(
  initialValues: ValueObject,
  values: ValueObject,
  attr: string
) {
  const initial = initialValues[attr];
  const curr = values[attr];
  return !!curr && initial?.equals(curr);
}
