/**
 * @generated SignedSource<<1f6c083b10bf7ba4db5cec92cfe4b115>>
 * @relayHash 22f2565a7a0f438e8dd123a773d220ac
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Ym_YczVyez88V_qHKpo0QELSVI97-YWYSilYoZ07-kY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateWorkerMilitaryServiceInput = {
  branch?: string | null | undefined;
  dutyStation?: string | null | undefined;
  militaryId?: string | null | undefined;
  rank?: string | null | undefined;
  serviceEnd?: string | null | undefined;
  serviceStart?: string | null | undefined;
  specialty?: string | null | undefined;
  workerMilitaryServiceId: string;
};
export type ManageMilitaryExperienceFormEditMutation$variables = {
  input: UpdateWorkerMilitaryServiceInput;
};
export type ManageMilitaryExperienceFormEditMutation$data = {
  readonly updateWorkerMilitaryService: {
    readonly workerMilitaryService: {
      readonly " $fragmentSpreads": FragmentRefs<"ManageMilitaryExperienceForm_military">;
    };
  } | null | undefined;
};
export type ManageMilitaryExperienceFormEditMutation = {
  response: ManageMilitaryExperienceFormEditMutation$data;
  variables: ManageMilitaryExperienceFormEditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageMilitaryExperienceFormEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerMilitaryServicePayload",
        "kind": "LinkedField",
        "name": "updateWorkerMilitaryService",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerMilitaryService",
            "kind": "LinkedField",
            "name": "workerMilitaryService",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ManageMilitaryExperienceForm_military"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageMilitaryExperienceFormEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerMilitaryServicePayload",
        "kind": "LinkedField",
        "name": "updateWorkerMilitaryService",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerMilitaryService",
            "kind": "LinkedField",
            "name": "workerMilitaryService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "militaryIdLast4",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceEnd",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Ym_YczVyez88V_qHKpo0QELSVI97-YWYSilYoZ07-kY",
    "metadata": {},
    "name": "ManageMilitaryExperienceFormEditMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "ddf9acde8f8ad6eeaf04f8d718fd860d";

export default node;
