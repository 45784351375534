/**
 * @generated SignedSource<<c85d8080d1cb5bbb2451240f158389ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type paymentMethodRow_payableAccount$data = {
  readonly paymentMethod: {
    readonly last4: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"paymentMethod_payableAccount">;
  readonly " $fragmentType": "paymentMethodRow_payableAccount";
};
export type paymentMethodRow_payableAccount$key = {
  readonly " $data"?: paymentMethodRow_payableAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentMethodRow_payableAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentMethodRow_payableAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "paymentMethod_payableAccount"
    }
  ],
  "type": "WorkerPayableAccount",
  "abstractKey": null
};

(node as any).hash = "77988100ee3dfbc76e89c6cfc11e17c3";

export default node;
