import {
  Button,
  ModalFooter,
  Stack,
  showModal,
  toast
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, FormSubmit } from "@gigsmart/fomu";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import React from "react";
import type { useDeleteCommercialDriversLicenseModalQuery } from "./__generated__/useDeleteCommercialDriversLicenseModalQuery.graphql";
import type { useDeleteCommercialDriversLicenseModalRemoveProofMutation } from "./__generated__/useDeleteCommercialDriversLicenseModalRemoveProofMutation.graphql";

interface Props {
  onComplete: () => void;
  onClose: () => void;
}
const DeleteCommercialDriversLicense = createSuspendedQueryContainer<
  useDeleteCommercialDriversLicenseModalQuery,
  Props
>(
  function DeleteCommercialDriversLicenseModal({
    onComplete,
    onClose,
    response: { viewer } = {}
  }) {
    const proof = getConnectionNodes(viewer?.qualificationProofs)[0];

    const [removeWorkerQualificationDriversLicenseProof] =
      useRelayMutationPromise<useDeleteCommercialDriversLicenseModalRemoveProofMutation>(
        graphql`
          mutation useDeleteCommercialDriversLicenseModalRemoveProofMutation(
            $input: RemoveWorkerQualificationDriversLicenseProofInput!
          ) {
            removeWorkerQualificationDriversLicenseProof(input: $input) {
              removedWorkerQualificationProofId @deleteRecord
            }
          }
        `
      );

    const submit: FomuSubmitFn = async ({ values }, done, reset) => {
      try {
        await removeWorkerQualificationDriversLicenseProof({
          input: {
            workerQualificationProofId: proof?.id ?? ""
          }
        });
        reset();
        onComplete();
      } catch (error) {
        toast.error("Could not delete Drivers License");
      }
    };

    return (
      <Form initialValues={{}} onSubmit={submit}>
        <ModalFooter>
          <Stack horizontal justifyContent="space-between">
            <Button
              outline
              onPress={onClose}
              testID="cancel-delete-cdl-proof"
              label="Cancel"
              fill
            />
            <FormSubmit>
              {({ submit, submitting, invalid }) => (
                <Button
                  disabled={submitting || invalid}
                  testID="save-drivers-license"
                  label="Delete Drivers License"
                  onPress={submit}
                  fill
                />
              )}
            </FormSubmit>
          </Stack>
        </ModalFooter>
      </Form>
    );
  },
  {
    query: graphql`
      query useDeleteCommercialDriversLicenseModalQuery {
        viewer {
          ... on Worker {
            qualificationProofs(
              first: 1
              query: "WHERE type = DRIVERS_LICENSE"
            ) {
              edges {
                node {
                  ... on WorkerQualificationDriversLicenseProof {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);

export function showDeleteCommercialDriversLicenseModal(
  onComplete?: () => void
) {
  showModal({
    eventContext: "CommecialDriversLicenseModal",
    title: "Would you like to delete your saved License?",
    subTitle: "This action cannot be undone",
    subTitleItalic: false,
    useModalBody: true,
    children: (close) => (
      <DeleteCommercialDriversLicense
        onComplete={() => {
          onComplete?.();
          close();
        }}
        onClose={close}
      />
    )
  });
}
