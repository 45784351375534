/**
 * @generated SignedSource<<e327a952362bc1bfe7bcaf11f880c3d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectGigsTab_worker$data = {
  readonly projectsCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGigsFilter_worker" | "ProjectGigsTabPage_worker">;
  readonly " $fragmentType": "ProjectGigsTab_worker";
};
export type ProjectGigsTab_worker$key = {
  readonly " $data"?: ProjectGigsTab_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectGigsTab_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsAppliedStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsHiredStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsOfferedStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectsStates"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectGigsTab_worker",
  "selections": [
    {
      "alias": "projectsCount",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_in",
                  "variableName": "projectsStates"
                }
              ],
              "kind": "ObjectValue",
              "name": "currentStateName"
            },
            {
              "kind": "Literal",
              "name": "gigType",
              "value": {
                "_eq": "PROJECT"
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectsAppliedStates",
          "variableName": "projectsAppliedStates"
        },
        {
          "kind": "Variable",
          "name": "projectsHiredStates",
          "variableName": "projectsHiredStates"
        },
        {
          "kind": "Variable",
          "name": "projectsOfferedStates",
          "variableName": "projectsOfferedStates"
        },
        {
          "kind": "Variable",
          "name": "projectsStates",
          "variableName": "projectsStates"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectGigsFilter_worker"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectGigsTabPage_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "c5693e4c9fa6a4698200cfed1088a388";

export default node;
