import { createComponentPortal, createPropsPortal } from "@gigsmart/aperture";
import type { Button } from "@gigsmart/atorasu";
import type { ComponentProps, ReactNode } from "react";

export const ResponsiveStepperPortal = createPropsPortal<{
  stepTitle?: string;
  showBtn?: boolean;
}>({ showBtn: true });

export const ResponsiveStepperRightAccessoryPortal = createComponentPortal();

export const ResponsiveStepperBtnPortal = createPropsPortal<
  ComponentProps<typeof Button> & {
    accessory?: ReactNode;
    accessoryInline?: boolean;
  }
>({ label: "Continue", accessory: null, testID: "next-btn" });
