import {
  Modal,
  type ModalAction,
  ModalBody,
  RNImage,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { CorrectAppModalFeature } from "@gigsmart/feature-flags";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import { openAppStore } from "@gigsmart/isomorphic-shared/app/helpers/links";
import { IS_TESTING } from "@gigsmart/isomorphic-shared/constants";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useCurrentUrl } from "@gigsmart/kaizoku";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import rightAppImage from "./right-app-image-modal/right-app-modal.png";

interface Props {
  children: ReactNode;
}

const DO_NOT_SHOW_MODAL_PATHS = [
  "/reset-password",
  "/login/",
  "/confirm-registration",
  "/legal/"
];

const HIDE_CORRECT_APP_SCREEN_KEY = "hideCorrectAppScreen";

export default function CorrectAppCheck({
  children
}: Props): JSX.Element | null {
  const url = useCurrentUrl();
  const { appId } = useAppSettings();
  const user = useCurrentUser();
  const [showCorrectAppCheck, setShowCorrectAppCheck] = useState(false);

  const styles = useStyles(() => ({
    img: { width: "100%", height: undefined, aspectRatio: 1.36 }
  }));

  useEffect(() => {
    (async () => {
      const hasSeenModalBefore =
        (await AsyncStorage.getItem(HIDE_CORRECT_APP_SCREEN_KEY)) === "true";
      const hiddenPath = DO_NOT_SHOW_MODAL_PATHS.some((path) =>
        url.startsWith(path)
      );
      setShowCorrectAppCheck(!hasSeenModalBefore && !hiddenPath);
    })();
  }, [url]);

  const handlePress = useCallback((app: "requester" | "worker") => {
    if (app !== appId) void openAppStore(app);
    else {
      void AsyncStorage.setItem(HIDE_CORRECT_APP_SCREEN_KEY, "true");
    }
  }, []);

  const actions = useMemo<ModalAction[]>(() => {
    const actions = [
      {
        testID: "get-gigs-button",
        label: "Find Gigs",
        autoClose: true,
        outline: true,
        onPress: () => {
          handlePress("worker");
        },
        color: "primary" as const
      },
      {
        testID: "get-gigs-button",
        label: "Hire Workers",
        autoClose: true,
        color: "primary" as const,
        onPress: () => {
          handlePress("requester");
        },
        outline: true
      }
    ];
    return appId === "worker" ? actions.reverse() : actions;
  }, [handlePress, appId]);

  if (IS_TESTING || !CorrectAppModalFeature.isEnabled() || !!user) {
    return <>{children}</>;
  }

  return (
    <>
      <Modal
        exclusive
        title="Welcome to GigSmart!"
        dismissable={false}
        eventContext="Correct App Check"
        visible={!!showCorrectAppCheck}
        scrollable={false}
        actions={actions}
        useModalBody={false}
        onClose={() => setShowCorrectAppCheck(false)}
      >
        <ModalBody gap="standard">
          <RNImage source={{ uri: rightAppImage }} style={styles.img} />
          <Stack size="slim">
            <Text align="center" variant="bodyLg" weight="semibold">
              Are you using the right app?
            </Text>
            <Text align="center">I am looking to:</Text>
          </Stack>
        </ModalBody>
      </Modal>
      {children}
    </>
  );
}
