/**
 * @generated SignedSource<<d3de1a08c3918d1b92e3e05ca59e8c2c>>
 * @relayHash a6d8c60d2f09b242dcd6752f6a602d65
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:tZxeO1iN6n6qsp9bkInlTowqA4pCQp6Rm9-wX4UhLb4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type onboardingCheckWorkerQuery$variables = Record<PropertyKey, never>;
export type onboardingCheckWorkerQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"WorkerOnboardingScreen_worker">;
  } | null | undefined;
};
export type onboardingCheckWorkerQuery = {
  response: onboardingCheckWorkerQuery$data;
  variables: onboardingCheckWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transportationMethod",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transportationState",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    },
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE status = CONFIRMED"
    }
  ],
  "concreteType": "WorkerPositionsConnection",
  "kind": "LinkedField",
  "name": "workerPositions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "workerPositions(first:0,query:\"WHERE status = CONFIRMED\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmed",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "onboardingCheckWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "WorkerOnboardingScreen_worker",
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMobile",
                    "kind": "LinkedField",
                    "name": "primaryMobile",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "onboardingCheckWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMobile",
                "kind": "LinkedField",
                "name": "primaryMobile",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:tZxeO1iN6n6qsp9bkInlTowqA4pCQp6Rm9-wX4UhLb4",
    "metadata": {},
    "name": "onboardingCheckWorkerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0bbba527230f572341af7a2c2bc10497";

export default node;
