import React, { type ReactElement } from "react";
import { View } from "react-native";
import { type TextStyleProp, type ViewStyleProp, createStyles } from "../style";
import { StyledText } from "../text";

interface Props {
  style?: ViewStyleProp;
  titleStyle?: TextStyleProp;
  valueStyle?: TextStyleProp;
  title: string;
  value?: string | ReactElement;
  selectable?: boolean;
  numberOfLines?: number;
}

const styles = createStyles(({ color, font }) => ({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingVertical: 4
  },
  title: {
    ...font.body("bold"),
    fontSize: font.size.medium,
    marginVertical: 2
  },
  value: {
    color: color.orange,
    fontSize: 18,
    lineHeight: font.size.extraLarge
  }
}));

export default ({
  style,
  titleStyle,
  valueStyle,
  title,
  value,
  selectable,
  numberOfLines
}: Props) => (
  <View style={[styles.container, style]}>
    <StyledText
      style={[styles.title, titleStyle]}
      selectable={selectable}
      color="blue"
      numberOfLines={numberOfLines}
    >
      {title}
    </StyledText>
    {typeof value === "string" ? (
      <StyledText selectable={selectable} style={[styles.value, valueStyle]}>
        {value}
      </StyledText>
    ) : (
      value
    )}
  </View>
);
