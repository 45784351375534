import React, { type ComponentType } from "react";
import { View } from "react-native";

import { type ViewStyleProp, createStyles } from "../style";

interface Props {
  style?: ViewStyleProp;
  component?: ComponentType<{}>;
}

const styles = createStyles(() => ({
  container: {
    overflow: "hidden",
    aspectRatio: 2.9
  }
}));

export default ({ style, component: Component }: Props) => (
  <View style={[styles.container, style]}>{!!Component && <Component />}</View>
);
