import { Icon, IconCircle, Text, getDisplayInitials } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import React from "react";
import { Image, View } from "react-native";
import AltLogo from "../brand/alt-logo";

interface Props {
  avatarUrl?: string | null;
  messageType?: string;
  name?: string;
}

const NotificationAvatar = ({ avatarUrl, messageType, name }: Props) => {
  const styles = useStyles(({ getColor, getUnits }) => ({
    avatar: {
      height: 50,
      width: 50,
      borderRadius: 25
    },
    logo: {
      resizeMode: "contain",
      height: 50,
      width: 50
    },
    container: {
      height: 50,
      width: 50,
      alignItems: "center",
      justifyContent: "center"
    },
    InitialsContainer: {
      borderRadius: 50,
      backgroundColor: getColor("primary", "fill")
    },
    initials: {
      paddingTop: getUnits(1)
    }
  }));
  if (messageType === "ALERT")
    return (
      <View style={styles.container}>
        <Icon color="orange" name="circle-exclamation" size="extraLarge" />
      </View>
    );

  if (
    messageType === "CORPORATE" ||
    messageType === "PAYMENT" ||
    messageType === "JOB_POSTING" ||
    messageType === "DIRECT_HIRE"
  )
    return <AltLogo />;

  if (avatarUrl)
    return <Image style={styles.avatar} source={{ uri: avatarUrl }} />;

  if (messageType === "JOB_BOOSTED")
    return <IconCircle size="medium" icon="building" color="primary" />;

  const authorInitials = getDisplayInitials(name || "");
  return (
    <View style={[styles.container, styles.InitialsContainer]}>
      <Text style={styles.initials} color="surface" variant="title">
        {authorInitials}
      </Text>
    </View>
  );
};

export default NotificationAvatar;
