import {
  Column,
  type IconName,
  InputLabel,
  Row,
  SelectableBubble,
  Stack
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { useFormField } from "@gigsmart/fomu";
import type { Validator } from "@gigsmart/fomu/types";
import React, { type ReactNode } from "react";

interface Props<T> {
  name: string;
  options: readonly T[];
  validates?: Validator<T>;
  label: string;
  labelIcon?: IconName;
  labelAccessory?: ReactNode;
  labelExtractor?: (value: T) => string;
  keyExtractor?: (value: T) => string;
}

export default function FomuTagSelect<T>({
  name,
  options,
  validates,
  label,
  labelIcon,
  labelAccessory,
  keyExtractor,
  labelExtractor
}: Props<T>) {
  const { setValue, value, errors } = useFormField<T>({
    name,
    validates
  });

  const styles = useStyles(({ getUnits }) => ({
    item: {
      marginRight: getUnits(2),
      marginBottom: getUnits(2)
    }
  }));

  const errorMessage = (Array.isArray(errors) ? errors : [])
    .map((err: any) => (typeof err === "string" ? err : err.message))
    .join(", ");
  const hasError = !!errorMessage;

  return (
    <Stack size="medium">
      <InputLabel
        label={label}
        icon={labelIcon}
        error={hasError}
        accessory={labelAccessory}
        testID={`${name}-tag-select-input`}
      />
      <Row wrap="wrap">
        {options?.map((opt, idx) => {
          const key = keyExtractor?.(opt) ?? `${idx}`;
          const isSelected =
            !!value &&
            (keyExtractor ? keyExtractor(value) === key : value === opt);
          const label =
            labelExtractor?.(opt) ?? (typeof opt === "string" ? opt : `${opt}`);
          return (
            <Column key={key} style={styles.item}>
              <SelectableBubble
                onChange={() => setValue(opt)}
                selected={isSelected}
                eventTargetName={label}
                label={label}
                testID={`${name}-tag-select-${idx}`}
              />
            </Column>
          );
        })}
      </Row>
    </Stack>
  );
}
