import { EmptyContentSurface, Text } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  searchTerm?: string;
}

export default function ShiftGigsEmptyView({ searchTerm }: Props) {
  return (
    <EmptyContentSurface
      iconName="business-time"
      message={
        searchTerm ? (
          <Text>
            No Shift Gigs Found for <Text weight="bold">"{searchTerm}"</Text>
          </Text>
        ) : (
          "Unfortunately, there are currently no open Shift Gigs in your area."
        )
      }
    />
  );
}
