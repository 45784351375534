import React, { Component, type ReactNode } from "react";
import { TouchableOpacity } from "react-native";

import type { TextStyleProp, ViewStyleProp } from "../style";
import { StyledText } from "../text";

interface Props {
  children: ReactNode;
  style?: ViewStyleProp;
  textStyle?: TextStyleProp;
  testID: string;
  onPress: () => void;
}

export default class TabLabel extends Component<Props> {
  render() {
    const { children, style, textStyle, onPress, testID, ...props } =
      this.props;

    return (
      <TouchableOpacity
        style={style}
        onPress={onPress}
        testID={testID}
        {...props}
      >
        <StyledText style={textStyle}>{children}</StyledText>
      </TouchableOpacity>
    );
  }
}
