import React, { type ComponentProps } from "react";
import { ActivityIndicator } from "react-native";

import { createStyles, theme } from "../style";
import Chip, { CHIP_HEIGHT } from "./chip";

type Props = ComponentProps<typeof Chip> & {
  isLoading: boolean;
};

const styles = createStyles(() => ({
  loadingContainer: {
    height: CHIP_HEIGHT,
    alignSelf: "center",
    position: "absolute"
  }
}));

export default function ChipLoading({
  isLoading,
  children,
  ...chipProps
}: Props) {
  const loadingColor = chipProps.checked ? theme.color.white : theme.color.blue;

  return (
    <Chip {...chipProps} disabled={isLoading}>
      {isLoading ? (
        <ActivityIndicator
          style={styles.loadingContainer}
          color={loadingColor}
        />
      ) : (
        children
      )}
    </Chip>
  );
}

ChipLoading.defaultProps = Chip?.defaultProps;
