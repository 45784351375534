/**
 * @generated SignedSource<<2b93bf45dccdae0b16558beb900b5c67>>
 * @relayHash 835c9819fe2a5bc115731efa93a45383
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ym0CLVzD0B4rkOlMNd8aQyeP5EAtanuSQ5YyhW97VQU

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicantDirection = "EMAIL" | "WEBSITE" | "%future added value";
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
export type JobPostingReportingType = "HIDDEN" | "STANDARD" | "%future added value";
export type JobPostingState = "CANCELED" | "DISCARDED" | "DRAFT" | "EXPIRED" | "PUBLISHED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type AffiliateJobPostingsInput = {
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  minimumSalary?: string | null | undefined;
  postedAfter?: string | null | undefined;
  search?: string | null | undefined;
};
export type JobPostingSearch = {
  applicantDirection?: ApplicantDirection | null | undefined;
  approved?: boolean | null | undefined;
  assignedAdmins?: ReadonlyArray<string> | null | undefined;
  boosted?: boolean | null | undefined;
  canceled?: boolean | null | undefined;
  endDate?: string | null | undefined;
  excludedAdmins?: ReadonlyArray<string> | null | undefined;
  expired?: boolean | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  maxStartDate?: string | null | undefined;
  maxTransactionCount?: number | null | undefined;
  minStartDate?: string | null | undefined;
  minTransactionCount?: number | null | undefined;
  orderBy?: ReadonlyArray<OrderBy> | null | undefined;
  payRateNegotiable?: boolean | null | undefined;
  paySchedule?: JobPostingPaySchedule | null | undefined;
  reportingType?: ReadonlyArray<JobPostingReportingType> | null | undefined;
  search?: string | null | undefined;
  startDate?: string | null | undefined;
  state?: JobPostingState | null | undefined;
  states?: ReadonlyArray<JobPostingState> | null | undefined;
  visible?: boolean | null | undefined;
};
export type OrderBy = {
  direction?: SortDirection | null | undefined;
  field: string;
};
export type myGigsJobsTabQuery$variables = {
  input: AffiliateJobPostingsInput;
  search: JobPostingSearch;
};
export type myGigsJobsTabQuery$data = {
  readonly affiliateJobPostings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly externalId: string;
        readonly name: string | null | undefined;
        readonly url: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"affiliateJobCardFragment_job">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly viewer: {
    readonly availableJobPostings?: {
      readonly edges: ReadonlyArray<{
        readonly jobPost: {
          readonly __typename: "JobPosting";
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"jobCardFragment_job">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type myGigsJobsTabQuery = {
  response: myGigsJobsTabQuery$data;
  variables: myGigsJobsTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 2
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "myGigsJobsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AvailableJobPostingsConnection",
                "kind": "LinkedField",
                "name": "availableJobPostings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableJobPostingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": "jobPost",
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "jobCardFragment_job"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AffiliateJobPostingsConnection",
        "kind": "LinkedField",
        "name": "affiliateJobPostings",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AffiliateJobPostingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "affiliateJobCardFragment_job"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "myGigsJobsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AvailableJobPostingsConnection",
                "kind": "LinkedField",
                "name": "availableJobPostings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableJobPostingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": "jobPost",
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Place",
                            "kind": "LinkedField",
                            "name": "place",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "streetAddress",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "boosted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paySchedule",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateNegotiable",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maximumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minimumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "area",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AffiliateJobPostingsConnection",
        "kind": "LinkedField",
        "name": "affiliateJobPostings",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AffiliateJobPostingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AffiliateJobPosting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "snippet",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salaryInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maximumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumSalary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "industryName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AffiliateJobHiringCompany",
                    "kind": "LinkedField",
                    "name": "hiringCompany",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ym0CLVzD0B4rkOlMNd8aQyeP5EAtanuSQ5YyhW97VQU",
    "metadata": {},
    "name": "myGigsJobsTabQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a720aa29d7f5f8eb5d041e7d2775b490";

export default node;
