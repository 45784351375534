import { LoadingView, Spacer } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import {
  InfiniteList,
  type InfiniteListProps
} from "@gigsmart/isomorphic-shared/list";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { useEnrollmentDataFragment } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentData";
import React from "react";

import useEngagementList, {
  type EngagementListOptions
} from "../gig/engagement-list/use-engagement-list";
import {
  type SeriesGroupData,
  aggregateEngagements,
  type useEngagementList_worker$key
} from "../gig/engagement-list/use-engagement-list.gql";
import type { fakeGigSeriesListQuery } from "./__generated__/fakeGigSeriesListQuery.graphql";
import FakeGigSeriesCard from "./cards/fake-gig-series-card";

interface Props
  extends Omit<
    InfiniteListProps<
      { variables: { query: string }; response: any },
      useEngagementList_worker$key["offered"],
      SeriesGroupData
    >,
    "parentRef" | "fragmentInput" | "getData" | "keyExtractor" | "renderItem"
  > {
  query: string;
  liveAdd?: EngagementListOptions<"offered">["liveAdd"];
  variant: "offered";
  onCardPress?: (id: string) => void;
  onAcceptOffers?: (
    id: string,
    isEnrolled: boolean,
    positionId: string
  ) => void;
}

const EntrySeparator = () => <Spacer size="medium" />;

/** @deprecated */
export default createSuspendedQueryContainer<fakeGigSeriesListQuery, Props>(
  /** @deprecated */
  function FakeGigSeriesList({
    response,
    variant,
    query,
    onCardPress,
    onAcceptOffers,
    ListHeaderComponent = EntrySeparator,
    ListFooterComponent = EntrySeparator,
    liveAdd,
    ...listProps
  }) {
    const enrollmentData = useEnrollmentDataFragment(response?.viewer);
    const { parentRef, fragmentSpec } = useEngagementList({
      liveAdd,
      query,
      variant,
      includeGigSeries: true
    });
    const styles = useStyles(() => ({
      content: { flexGrow: 1 }
    }));
    if (!enrollmentData || !parentRef) return null;
    return (
      <InfiniteList
        {...listProps}
        pageSize={10}
        parentRef={parentRef}
        fragmentInput={fragmentSpec}
        contentContainerStyle={styles.content}
        getData={(raw) => aggregateEngagements(raw, enrollmentData)}
        keyExtractor={(item) => item.seriesId}
        renderItem={({ item }) => (
          <FakeGigSeriesCard
            aggregate={item}
            onPress={() => onCardPress?.(item.seriesId)}
            onPressAcceptOffers={() => {
              onAcceptOffers?.(item.seriesId, item.isEnrolled, item.positionId);
            }}
          />
        )}
        refetchDebounce={500}
        refetchVars={{ query }}
        ListHeaderComponent={<Spacer />}
        ItemSeparatorComponent={EntrySeparator}
        renderFooterView={(loading) =>
          loading ? <LoadingView size="large" /> : <EntrySeparator />
        }
      />
    );
  },
  {
    query: graphql`
      query fakeGigSeriesListQuery {
        viewer {
          ... on Worker {
            ...useEnrollmentData_worker
          }
        }
      }
    `,
    variables: {}
  }
);
