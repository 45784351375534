/**
 * @generated SignedSource<<bf433175d4893250285d022f8e548871>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileHeaderRefreshed_worker$data = {
  readonly administrativeArea1: string | null | undefined;
  readonly displayName: string | null | undefined;
  readonly favoritedByOrganizationsCount: number;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly insertedAt: string;
  readonly lastName?: string | null | undefined;
  readonly locality: string | null | undefined;
  readonly profilePhoto: {
    readonly public?: boolean;
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly pronoun: {
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useUserPronoun_Worker">;
  readonly " $fragmentType": "ProfileHeaderRefreshed_worker";
};
export type ProfileHeaderRefreshed_worker$key = {
  readonly " $data"?: ProfileHeaderRefreshed_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderRefreshed_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipIfNotWorker"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileHeaderRefreshed_worker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useUserPronoun_Worker"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "condition": "skipIfNotWorker",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "administrativeArea1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profilePhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "condition": "skipIfNotWorker",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "public",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoritedByOrganizationsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPronoun",
      "kind": "LinkedField",
      "name": "pronoun",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "f40631be76830512ec761bbe725544ce";

export default node;
