/**
 * @generated SignedSource<<c5b72704dfa0637ed2e46e14628a15d2>>
 * @relayHash aee5fde7365cf4eb7da701321764fd69
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:BWkV4e50QCz8MsiI5cuurl_4QqkD00p156K-cGcK9PU

import { ConcreteRequest } from 'relay-runtime';
export type AnnouncementActionType = "DISPLAY_WEBVIEW" | "OPEN_SCREEN" | "%future added value";
export type AnnouncementAudienceType = "GLOBAL" | "REQUESTER" | "WORKER" | "%future added value";
export type CqlFilterAnnouncementInput = {
  _and?: ReadonlyArray<CqlFilterAnnouncementInput> | null | undefined;
  _not?: CqlFilterAnnouncementInput | null | undefined;
  _or?: ReadonlyArray<CqlFilterAnnouncementInput> | null | undefined;
  audienceType?: CqlEnumAnnouncementAudienceTypeInput | null | undefined;
  expiresAt?: CqlOpDatetimeInput | null | undefined;
  insertedAt?: CqlOpDatetimeInput | null | undefined;
  isDismissed?: CqlOpBooleanInput | null | undefined;
  priority?: CqlOpIntegerInput | null | undefined;
};
export type CqlEnumAnnouncementAudienceTypeInput = {
  _eq?: AnnouncementAudienceType | null | undefined;
  _in?: ReadonlyArray<AnnouncementAudienceType> | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: AnnouncementAudienceType | null | undefined;
};
export type CqlOpDatetimeInput = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
};
export type CqlOpBooleanInput = {
  _eq?: boolean | null | undefined;
  _isNull?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
};
export type CqlOpIntegerInput = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _isNull?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
};
export type announcementQuery$variables = {
  where: CqlFilterAnnouncementInput;
};
export type announcementQuery$data = {
  readonly announcements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly actionMeta: string | null | undefined;
        readonly actionPrompt: string | null | undefined;
        readonly actionType: AnnouncementActionType | null | undefined;
        readonly audienceType: AnnouncementAudienceType | null | undefined;
        readonly content: string;
        readonly dismissable: boolean | null | undefined;
        readonly id: string;
        readonly isDismissed: boolean;
        readonly title: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type announcementQuery = {
  response: announcementQuery$data;
  variables: announcementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AnnouncementsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Announcement",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDismissed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actionPrompt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actionType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actionMeta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dismissable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audienceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      {
        "priority": {
          "direction": "ASC_NULLS_LAST"
        }
      }
    ]
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "announcementQuery",
    "selections": [
      {
        "alias": "announcements",
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "AnnouncementsConnection",
        "kind": "LinkedField",
        "name": "__app_announcements_connection",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "announcementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AnnouncementsConnection",
        "kind": "LinkedField",
        "name": "announcements",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "app_announcements",
        "kind": "LinkedHandle",
        "name": "announcements"
      }
    ]
  },
  "params": {
    "id": "qpid:BWkV4e50QCz8MsiI5cuurl_4QqkD00p156K-cGcK9PU",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "announcements"
          ]
        }
      ]
    },
    "name": "announcementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "04088df167d28c3a589580ec630af888";

export default node;
