/**
 * @generated SignedSource<<6b03599460974b374793e0b7e84e50fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftGigsFilter_worker$data = {
  readonly appliedCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly bidRequestsCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly bidSubmittedCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly confirmingCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly disputePendingCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly hiredCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly paymentPendingCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly pendingOffersCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly shiftsCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly timesheetReviewCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "ShiftGigsFilter_worker";
};
export type ShiftGigsFilter_worker$key = {
  readonly " $data"?: ShiftGigsFilter_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftGigsFilter_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "appliedWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "bidRequestsWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "bidSubmittedWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "confirmingWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "disputePendingWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hiredWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentPendingWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pendingOffersWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shiftsWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timesheetReviewWhere"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftGigsFilter_worker",
  "selections": [
    {
      "alias": "shiftsCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "shiftsWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "hiredCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "hiredWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "confirmingCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "confirmingWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "pendingOffersCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "pendingOffersWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "appliedCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "appliedWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "timesheetReviewCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "timesheetReviewWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "paymentPendingCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "paymentPendingWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "disputePendingCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "disputePendingWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "bidSubmittedCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "bidSubmittedWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "bidRequestsCount",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "bidRequestsWhere"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "7fd63a95ef05e52c508b2745986d5734";

export default node;
