import { Column, Row, Text } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  startedDate: string;
  completedDate: string;
  rapid: boolean;
  statusText: string;
  paymentAmount: string;
}

export default ({
  startedDate,
  completedDate,
  rapid,
  statusText,
  paymentAmount
}: Props) => {
  let statusColor = "neutralDark";
  if (statusText === "Paid") statusColor = "green";
  if (statusText === "Failed") statusColor = "red";
  return (
    <Row alignItems="center" justifyContent="space-between">
      <Column fill={1}>
        <Text color="neutral" variant="note">
          Initiated:
        </Text>
        <Text testID="payout-started-date">{startedDate}</Text>
      </Column>
      <Column fill={1}>
        <Text color="neutral" variant="note">
          {statusText === "Paid" ? "Completed:" : "Est. Arrival:"}
        </Text>
        <Text weight="semibold" testID="payout-completed-date">
          {completedDate}
        </Text>
      </Column>
      <Column fill={1}>
        {rapid && statusText === "Paid" && (
          <Text
            align="center"
            weight="bold"
            color="success"
            testID="payout-rapid"
          >
            Rapid
          </Text>
        )}
        <Text align="center" weight="bold" testID="payout-status-text">
          {statusText}
        </Text>
      </Column>
      <Column fill={1}>
        <Text align="center" weight="semibold">
          {paymentAmount}
        </Text>
      </Column>
    </Row>
  );
};
