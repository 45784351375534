import { Text } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import moment from "moment";
import React from "react";

import EstimatedPaySquare from "../../gig/engagement-details/estimated-pay-square";

interface CalendarContentOptions {
  gigCount?: number;
  node?: gigHelpers.GigFullTimeOptions | null;
  isProject?: boolean;
  isTBD?: boolean;
}
export const getCalendarContent = ({
  node,
  gigCount = 0,
  isProject = false,
  isTBD = false
}: CalendarContentOptions) => {
  const calendarNode =
    gigCount > 1 ? (
      <Text>
        <Text weight="bold">Multi-Shift &bull; </Text>
        {moment(node?.startsAt ?? node?.insertedAt).format("MMM Do")}
      </Text>
    ) : isProject && node ? (
      gigHelpers.workerProjectCardDateTimeString(node.startsAt, isTBD)
    ) : node ? (
      gigHelpers.gigFullTime(node)
    ) : (
      "-"
    );
  const infoNode =
    gigCount > 1 ? `(+${pluralize(gigCount - 1, "shift")})` : null;

  return [calendarNode, infoNode] as const;
};

interface PaymentContentOptions {
  payRate?: string | null;
  netPay?: string | null;
  estimatedPaymentRange?: { min?: string | null; max?: string | null };
  isProject?: boolean;
}

export const getPaymentContent = ({
  payRate,
  netPay,
  estimatedPaymentRange,
  isProject
}: PaymentContentOptions) => {
  if (!payRate || isProject) return null;
  return estimatedPaymentRange ? (
    <EstimatedPaySquare
      payRate={payRate}
      netPay={estimatedPaymentRange.min}
      netPayMax={estimatedPaymentRange.max}
    />
  ) : (
    <EstimatedPaySquare payRate={payRate} netPay={netPay} />
  );
};

interface AddressContentOptions {
  hasMultipleLocations?: boolean;
  remote?: boolean;
  area?: string | null;
}

export const getAddressContent = ({
  hasMultipleLocations,
  remote,
  area
}: AddressContentOptions) =>
  hasMultipleLocations ? (
    <Text weight="bold">Multi-Location</Text>
  ) : remote ? (
    "Remote"
  ) : (
    area
  );

type DistanceContentOptions =
  | number
  | { min?: number | null; max?: number | null };

export const getDistanceContent = (
  distance?: DistanceContentOptions | null,
  isRemote = false
) => {
  if (isRemote) return null;
  return typeof distance === "number"
    ? `(${distance.toFixed(1)}mi)`
    : gigHelpers.formatDistanceRange(distance);
};
