import {
  Avatar,
  Button,
  CollapsibleContainer,
  Column,
  ContentArea,
  Divider,
  FancyHeader,
  HighlightedReminder,
  IconCircle,
  Spacer,
  Stack,
  StarRating,
  Surface,
  Text,
  TimeRemaining,
  TooltipButton,
  Well
} from "@gigsmart/atorasu";
import { currency, time } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import ConfirmationRequestSupport from "./ConfirmationRequestSupport";
import type { DirectHireClaimDetailsWorker_DirectHireClaim$key } from "./__generated__/DirectHireClaimDetailsWorker_DirectHireClaim.graphql";
import useWorkerAboutDirectHireModal from "./useWorkerAboutDirectHireModal";

interface Props {
  directHireBonus?: string | null;
}

export function DirectHireClaimDetails({
  directHireType,
  place,
  organization,
  directHireDate,
  rejected,
  workerPayout,
  currentTransition,
  responseDeadline,
  firstName,
  lastName,
  approved,
  disbursed,
  published,
  directHireBonus,
  comments,
  photos,
  response
}: FragmentContainerInnerComponentProps<
  DirectHireClaimDetailsWorker_DirectHireClaim$key,
  Props
>) {
  const history = useHistory();
  const { showApproved, showPending, showRejected, showNoResponse } =
    useWorkerAboutDirectHireModal();
  const isHire = directHireType === "HIRE";
  const isApproved =
    currentTransition?.state === "CHARGED" ||
    currentTransition?.state === "DISBURSED";
  const isPending = currentTransition?.state === "PUBLISHED";
  const isRejected = !!rejected?.edges?.[0]?.node?.transitionedAt;
  const disbursedAt = disbursed?.edges?.[0]?.node?.transitionedAt;
  const chargedAt = approved?.edges?.[0]?.node?.transitionedAt;
  const publishedAt = published?.edges?.[0]?.node?.transitionedAt;
  const noResponse =
    rejected?.edges?.[0]?.node?.transitionedBy?.agent?.__typename ===
      "SystemUser" || rejected?.edges?.[0]?.node?.action === "TIMEOUT";
  return (
    <Surface>
      <FancyHeader
        icon={isApproved ? "check" : isRejected ? "ban" : "spinner"}
        title={`${
          isApproved
            ? "Confirmed"
            : isRejected
              ? noResponse
                ? "No Response to"
                : "Rejected"
              : "Pending"
        } Direct ${isHire ? "Hire" : "Payment"} ${
          isApproved || isRejected ? "" : "Request"
        }`}
        color={isApproved ? "success" : isRejected ? "danger" : "black"}
        variant={isApproved ? "success" : isRejected ? "danger" : "black"}
        action={
          <TooltipButton
            testID="show-info-modal-btn"
            onPress={
              isApproved
                ? showApproved
                : isRejected
                  ? noResponse
                    ? showNoResponse
                    : showRejected
                  : showPending
            }
            variant="solid"
          />
        }
      />
      <ContentArea>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <Stack horizontal alignItems="center">
            {organization?.logoUrl && <Avatar uri={organization?.logoUrl} />}
            {!organization?.logoUrl && (
              <IconCircle size="medium" icon="building" color="primary" />
            )}
            <Stack size="slim">
              <Text weight="semibold" color="primary" numberOfLines={1}>
                {organization?.name}
              </Text>
              <StarRating
                rating={organization?.overallRating ?? 0}
                numberOfRatings={organization?.reviewCount?.totalCount}
                showRatings={true}
              />
            </Stack>
          </Stack>
          <Button
            testID="view-info-btn"
            outline
            label="View Info"
            size="small"
            onPress={() => history.push(`/organization/${organization?.id}`)}
          />
        </Stack>
      </ContentArea>
      <Divider />
      {isRejected && noResponse && (
        <ContentArea size="none">
          <Spacer />
          <HighlightedReminder
            icon="circle-exclamation"
            variant="error"
            header={`The Requester did not respond to your ${
              isHire ? "Direct Hire" : "Direct Payment"
            } submission, so it was not paid.`}
          />
        </ContentArea>
      )}
      {isRejected && !noResponse && (
        <ContentArea size="none">
          <Spacer />
          <Well>
            <Stack>
              <Text weight="semibold">Requester Response:</Text>
              <Text italic>{response}</Text>
            </Stack>
          </Well>
        </ContentArea>
      )}
      <ContentArea>
        <Divider />
        <Stack variant="divider">
          <ContentArea>
            <Stack horizontal justifyContent="space-between">
              <Column>
                <Text>{isHire ? "Person who Hired" : "Person who Paid"}</Text>
              </Column>
              <Text fill numberOfLines={1} align="right">
                {firstName} {lastName}
              </Text>
            </Stack>
          </ContentArea>
          <ContentArea>
            <Stack horizontal justifyContent="space-between">
              <Column>
                <Text>{isHire ? "Hiring" : "Work"} Location</Text>
              </Column>
              <Text fill numberOfLines={1} align="right">
                {place?.streetAddress}
              </Text>
            </Stack>
          </ContentArea>
          <ContentArea>
            <Stack horizontal justifyContent="space-between">
              <Column>
                <Text>{isHire ? "Hire" : "Payment"} Date</Text>
              </Column>
              <Text numberOfLines={1}>
                {time.humanize(directHireDate, "dateCompact")}
              </Text>
            </Stack>
          </ContentArea>
          {isApproved && (
            <ContentArea>
              <Stack horizontal justifyContent="space-between">
                <Column>
                  <Text>Approved On</Text>
                </Column>
                <Text numberOfLines={1}>
                  {time.humanize(chargedAt, "dateTimeAlt")}
                </Text>
              </Stack>
            </ContentArea>
          )}
          {isPending && (
            <ContentArea>
              <Stack horizontal justifyContent="space-between">
                <Column>
                  <Text>Submitted On</Text>
                </Column>
                <Text numberOfLines={1}>
                  {time.humanize(publishedAt, "dateTimeAlt")}
                </Text>
              </Stack>
            </ContentArea>
          )}
          {!isRejected && (
            <ContentArea>
              <Stack horizontal justifyContent="space-between">
                <Column>
                  <Text>Direct {isHire ? "Hire" : "Payment"} Bonus</Text>
                </Column>
                {isApproved && (
                  <Text numberOfLines={1}>
                    {currency.humanize(workerPayout)}
                  </Text>
                )}
                {isPending && (
                  <Text numberOfLines={1}>
                    {currency.humanize(directHireBonus)}
                  </Text>
                )}
              </Stack>
            </ContentArea>
          )}
          {isRejected && (
            <ContentArea>
              <Stack horizontal justifyContent="space-between">
                <Column>
                  <Text>Rejected On</Text>
                </Column>

                <Text numberOfLines={1}>
                  {time.humanize(
                    rejected?.edges?.[0]?.node?.transitionedAt,
                    "dateTimeAlt"
                  )}
                </Text>
              </Stack>
            </ContentArea>
          )}
          {isApproved && (
            <ContentArea>
              <Stack horizontal justifyContent="space-between">
                <Column>
                  <Text>Paid Out to Wallet</Text>
                </Column>
                <Text numberOfLines={1}>
                  {time.humanize(disbursedAt, "dateTimeAlt")}
                </Text>
              </Stack>
            </ContentArea>
          )}
          {isPending && (
            <ContentArea>
              <Stack horizontal justifyContent="space-between">
                <Column>
                  <Text>Pending Requester Review</Text>
                </Column>
                <Text numberOfLines={1}>
                  <TimeRemaining date={responseDeadline} size="sm" /> left
                </Text>
              </Stack>
            </ContentArea>
          )}
        </Stack>
        <Divider />
        {isRejected && (
          <>
            <Spacer />
            <Stack size="compact">
              <Text weight="bold" align="center">
                Disagree with the results of your Request?
              </Text>
              <Button
                testID="contact-support-btn"
                variant="clear"
                size="small"
                label="Contact Support"
                onPress={() => history.push("/support")}
                icon="file-export"
              />
            </Stack>
          </>
        )}
      </ContentArea>
      {comments && (
        <CollapsibleContainer
          variant="flat"
          header={<React.Fragment />}
          startCollapsed
          testID="request-evidence"
        >
          <Divider />
          <ContentArea>
            <Stack>
              <Text>The following information was sent with your request.</Text>
              <ConfirmationRequestSupport
                comment={comments}
                photos={getConnectionNodes(photos)}
              />
            </Stack>
          </ContentArea>
        </CollapsibleContainer>
      )}
    </Surface>
  );
}

export default createRelayFragmentContainer<
  DirectHireClaimDetailsWorker_DirectHireClaim$key,
  Props
>(
  graphql`
    fragment DirectHireClaimDetailsWorker_DirectHireClaim on DirectHireClaim {
      id
      directHireType
      directHireDate
      firstName
      lastName
      place {
        streetAddress
      }
      response
      timezone
      comments
      workerPayout
      currentTransition {
        state
      }
      photos(first: 5) {
        edges {
          node {
            url
          }
        }
      }
      responseDeadline
      rejected: transitions(first: 1, query: "WHERE state = REJECTED") {
        edges {
          node {
            action
            transitionedAt
            transitionedBy {
              agent {
                __typename
              }
            }
          }
        }
      }
      published: transitions(first: 1, query: "WHERE state = PUBLISHED") {
        edges {
          node {
            transitionedAt
          }
        }
      }
      approved: transitions(first: 1, query: "WHERE action = ACCEPT") {
        edges {
          node {
            transitionedAt
          }
        }
      }
      disbursed: transitions(first: 1, query: "WHERE state = DISBURSED") {
        edges {
          node {
            transitionedAt
          }
        }
      }
      organization {
        id
        name
        logoUrl
        overallRating
        reviewCount: reviewsReceived(first: 0) {
          totalCount
        }
      }
    }
  `,
  DirectHireClaimDetails
);
