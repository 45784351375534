/**
 * @generated SignedSource<<ecc05caf7b661e9fd8893e7433880ad6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type unpaidEngagements_rootQuery$data = {
  readonly viewer: {
    readonly engagements?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly currentState: {
            readonly name: EngagementStateName;
          };
          readonly endsAt: string | null | undefined;
          readonly gigType: GigType;
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"engagementRowWorker_engagement" | "projectEngagementRowPayments_engagement">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "unpaidEngagements_rootQuery";
};
export type unpaidEngagements_rootQuery$key = {
  readonly " $data"?: unpaidEngagements_rootQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"unpaidEngagements_rootQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "unpaidEngagements_rootQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 20
                },
                {
                  "kind": "Literal",
                  "name": "query",
                  "value": "WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID ORDER BY endsAt DESC"
                }
              ],
              "concreteType": "EngagementsConnection",
              "kind": "LinkedField",
              "name": "engagements",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Engagement",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endsAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EngagementState",
                          "kind": "LinkedField",
                          "name": "currentState",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "gigType",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "engagementRowWorker_engagement"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "projectEngagementRowPayments_engagement"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": "engagements(first:20,query:\"WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID ORDER BY endsAt DESC\")"
            }
          ],
          "type": "Worker",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RootQueryType",
  "abstractKey": null
};

(node as any).hash = "52bebe04ae7c6f7568d2131db9d8467f";

export default node;
