import React from "react";
import { View } from "react-native";

import { createStyles } from "../../style";

const BAR_HEIGHT = 26;
const BAR_OUTER_WIDTH = 11;
const BAR_INNER_WIDTH = 3;

const borderWidth = (BAR_OUTER_WIDTH - BAR_INNER_WIDTH) / 2;

const styles = createStyles(({ color }) => ({
  bar: {
    marginTop: -2,
    marginBottom: -2,
    borderColor: color.neutralLight,
    backgroundColor: color.neutralLight,
    borderLeftWidth: borderWidth,
    borderRightWidth: borderWidth,
    height: BAR_HEIGHT + 4,
    width: BAR_OUTER_WIDTH,
    zIndex: 1
  },
  barActive: {
    backgroundColor: color.blue
  }
}));

interface Props {
  active?: boolean;
}

const TimesheetBar = ({ active }: Props) => (
  <View style={[styles.bar, active && styles.barActive]} />
);

export default TimesheetBar;
