import { IconText, Stack, Text } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import { DateTime } from "luxon";
import React, { useState } from "react";
import DynamicLegalDocument from "./DynamicLegalDocument";
import type { LegalDocument_consent$key } from "./__generated__/LegalDocument_consent.graphql";
import type { LegalDocument_document$key } from "./__generated__/LegalDocument_document.graphql";
import { useLegalDocumentData } from "./useLegalDocument";

interface Props {
  document?: LegalDocument_document$key | null | undefined;
  consent?: LegalDocument_consent$key | null | undefined;
  onEndReached?: () => void;
  hideDocument?: boolean;
}

export default function LegalDocument({
  document: documentFragmentRef,
  consent: consentFragmentRef,
  onEndReached = () => {},
  hideDocument: initialHideDocument = false,
  ...options
}: Props) {
  const [hideDocument, setHideDocument] = useState(initialHideDocument);
  const { filename, title, description, body, format } = useLegalDocumentData(
    useRelayFragment(
      graphql`
        fragment LegalDocument_document on LegalDocument {
          ...useLegalDocument_document
        }
      `,
      documentFragmentRef ?? null
    ),
    options
  );

  const { consentedAt, consentedIp } =
    useRelayFragment(
      graphql`
        fragment LegalDocument_consent on UserConsentDocument {
          consentedAt
          consentedIp
        }
      `,
      consentFragmentRef ?? null
    ) ?? {};

  return (
    <Stack size="compact" shrink>
      {consentedAt && (
        <Text italic>
          You consented to this agreement on{" "}
          {DateTime.fromISO(consentedAt).toLocaleString(DateTime.DATETIME_FULL)}{" "}
          from IP: {consentedIp}.
        </Text>
      )}
      {title && (
        <Text weight="bold" color="primary" variant="header">
          {title}
        </Text>
      )}
      {description && (
        <Text variant="subheader" weight="normal" color="black">
          {description}
        </Text>
      )}
      {hideDocument && (
        <Text
          weight="bold"
          color="highlight"
          onPress={() => setHideDocument(false)}
        >
          View {title}
        </Text>
      )}
      {!consentedAt && !hideDocument && (
        <IconText
          icon="down-to-line"
          iconPlacement="left"
          color="primary"
          size="small"
        >
          Read & scroll to the bottom to continue.
        </IconText>
      )}
      {!hideDocument && (
        <DynamicLegalDocument
          filename={filename}
          body={body}
          format={format}
          onEndReached={onEndReached}
        />
      )}
    </Stack>
  );
}
