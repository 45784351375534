/**
 * @generated SignedSource<<1e2379d42c74dab06eec62cc1893708b>>
 * @relayHash 5dcbdb1524a624ee32bf51ff496cf96f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:U-A1_0DqtQql3xjHHdIcS-pf5eCEXVyrukRvgdw7CwM

import { ConcreteRequest } from 'relay-runtime';
export type MessageType = "ALERT" | "CALL" | "CORPORATE" | "DIRECT_HIRE" | "GIG" | "GIG_POSTED" | "JOB_BOOSTED" | "JOB_POSTING" | "MISCELLANEOUS" | "PAYMENT" | "SILENT_MESSAGE" | "USER_MESSAGE" | "%future added value";
export type subscriptionListenerSubscription$variables = Record<PropertyKey, never>;
export type subscriptionListenerSubscription$data = {
  readonly messageAdded: {
    readonly newMessageEdge: {
      readonly node: {
        readonly author: {
          readonly firstName: string | null | undefined;
          readonly id: string;
          readonly lastInitial: string | null | undefined;
          readonly profilePhoto: {
            readonly url: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly body: string | null | undefined;
        readonly data: string | null | undefined;
        readonly expiresAt: string | null | undefined;
        readonly id: string;
        readonly messageType: MessageType;
        readonly receipt: {
          readonly sentAt: string;
          readonly viewedAt: string | null | undefined;
        } | null | undefined;
        readonly title: string;
        readonly topicId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type subscriptionListenerSubscription = {
  response: subscriptionListenerSubscription$data;
  variables: subscriptionListenerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastInitial",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "subscriptionListenerSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageAddedPayload",
        "kind": "LinkedField",
        "name": "messageAdded",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagesEdge",
            "kind": "LinkedField",
            "name": "newMessageEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "profilePhoto",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v0/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageReceipt",
                    "kind": "LinkedField",
                    "name": "receipt",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "subscriptionListenerSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageAddedPayload",
        "kind": "LinkedField",
        "name": "messageAdded",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagesEdge",
            "kind": "LinkedField",
            "name": "newMessageEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "profilePhoto",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v0/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v0/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageReceipt",
                    "kind": "LinkedField",
                    "name": "receipt",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:U-A1_0DqtQql3xjHHdIcS-pf5eCEXVyrukRvgdw7CwM",
    "metadata": {},
    "name": "subscriptionListenerSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "6906fdd8b1ee9a0cfd0e3526895316d3";

export default node;
