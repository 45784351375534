import { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import React from "react";

import SquareData from "./square-data";

interface Props {
  payRate?: string;
  netPay?: string | null;
  netPayMax?: string | null;
  toBeDetermined?: boolean;
}

const EstimatedPaySquare = ({
  toBeDetermined,
  netPay,
  netPayMax,
  payRate
}: Props) => {
  const mainText = toBeDetermined
    ? "TBD"
    : gigHelpers.formatPayRange(
        { min: netPay, max: netPayMax },
        { floor: true }
      );
  return (
    <SquareData
      label={toBeDetermined ? "Total Earnings" : "Estimated Pay"}
      mainText={mainText}
      hintText={
        typeof payRate === "string"
          ? currency.humanizeRate(payRate, "hr")
          : undefined
      }
    />
  );
};

export default EstimatedPaySquare;
