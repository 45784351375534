import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment, { type Moment } from "moment";
import React, { Component, type ComponentProps } from "react";
import { View } from "react-native";
import { StyledIcon } from "../../icon";
import type { ViewStyleProp } from "../../style";
import StyledTextInput from "../styled-text-input";

type Props = Omit<ComponentProps<typeof StyledTextInput>, "format"> & {
  date?: string | Date | null | undefined;
  format?: string;
  initialDate?: Date;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  onDateChange: (arg0: Date | null) => void;
  disableFuture?: boolean;
  displayValue?: string | null | undefined;
  style?: ViewStyleProp;
};

interface State {
  pickerShown: boolean;
}

export default class StyledDatePicker extends Component<Props, State> {
  static defaultProps = {
    format: "MM/DD/YYYY",
    placeholder: "Pick One",
    disabled: false,
    disableFuture: false
  };

  state = {
    pickerShown: false
  };

  parseDate = (date: Date | string) => {
    return moment(date);
  };

  get dates() {
    const { format, initialDate, maxDate, minDate, date } = this.props;
    return {
      date: date
        ? this.parseDate(date).startOf("day").utc().toDate()
        : undefined,
      dateFormatted: date
        ? this.parseDate(date).startOf("day").format(format)
        : undefined,
      initial: initialDate
        ? this.parseDate(initialDate).startOf("day").utc().toDate()
        : new Date(),
      max: maxDate
        ? this.parseDate(maxDate).endOf("day").utc().toDate()
        : undefined,
      min: minDate
        ? this.parseDate(minDate).startOf("day").utc().toDate()
        : undefined
    };
  }

  renderRightAccessory = () => {
    if (this.props.disabled) return null;
    const { pickerShown } = this.state;
    return (
      <StyledIcon
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ width: 20 }}
        color={pickerShown ? "blueTint" : "blue"}
        variant="solid"
        name={pickerShown ? "caret-up" : "caret-down"}
        onPress={this.handleShow}
      />
    );
  };

  handleSubmit = (date: Moment | null | undefined) => {
    this.props.onDateChange(date?.toDate() ?? null);
  };

  handleHide = () => {
    if (this.props.disabled) return;
    this.setState({ pickerShown: false });
  };

  handleShow = () => {
    if (this.props.disabled) return;
    this.setState({ pickerShown: true });
  };

  renderInput = () => {
    const { style, testID, placeholder, format, label, displayValue } =
      this.props;
    const { pickerShown } = this.state;
    return (
      <StyledTextInput
        focused={pickerShown}
        testID={testID}
        placeholder={placeholder ?? format ?? ""}
        label={label}
        editable={false}
        pointerEvents="none"
        eventTargetName=""
        renderRightAccessory={this.renderRightAccessory}
        customPressFunction={this.handleShow}
        style={style}
        value={displayValue ?? this.dates.dateFormatted}
      />
    );
  };

  render() {
    const { style, format, disableFuture, label } = this.props;
    const { pickerShown } = this.state;

    return (
      <View style={style}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            disableFuture={disableFuture}
            format={format}
            initialFocusedDate={this.dates.initial}
            minDate={this.dates.min}
            maxDate={this.dates.max}
            emptyLabel=""
            onChange={this.handleSubmit}
            value={this.dates.date}
            onClose={this.handleHide}
            onAccept={this.handleHide}
            TextFieldComponent={this.renderInput}
            open={pickerShown}
            onOpen={() => this.setState({ pickerShown: true })}
            label={label}
          />
        </MuiPickersUtilsProvider>
      </View>
    );
  }
}
