import React from "react";

import { Stack, Text, useStepper } from "@gigsmart/atorasu";
import { Form, FormSubmit } from "@gigsmart/fomu";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import DriverFields from "@gigsmart/seibutsu/worker-verification/DriverFields";
import SecureNotice from "@gigsmart/seibutsu/worker-verification/SecureNotice";

import StepperNavActions from "../../shared/Stepper/StepperNavActions";
import type { SetWorkerLegalInformationInput } from "./hooks/__generated__/useEnrollmentMutation.graphql";

interface Props {
  onSubmit: (
    props: SetWorkerLegalInformationInput,
    next: (data: SetWorkerLegalInformationInput) => void
  ) => void;
}
export default function DriverInformationStep({ onSubmit }: Props) {
  const { stepsData, nextStep } = useStepper<Step, any>();
  return (
    <Form
      initialValues={stepsData}
      onSubmit={({ values, valid }) => {
        valid && onSubmit(values, nextStep);
      }}
    >
      <StepperNavActions />
      <Stack testID="driver-information-screen" fill grow>
        <Text>
          Your information is secure and used only for verification purposes.
        </Text>
        <DriverFields />
        <SecureNotice />
      </Stack>
      <FormSubmit>
        {({ submit, invalid, submitting }) => (
          <ResponsiveStepperBtnPortal.Entrance
            testID="driver-info-step-gig-stepper-next-button"
            label="Continue"
            disabled={invalid || submitting}
            onPress={submit}
          />
        )}
      </FormSubmit>
    </Form>
  );
}
