import { graphql, useRelayFragment } from "@gigsmart/relay";
import React, { createContext, useContext, type ReactNode } from "react";
import type {
  useOrganizationPositionData$data,
  useOrganizationPositionData$key
} from "./__generated__/useOrganizationPositionData.graphql";

export type OrganizationPositionData = useOrganizationPositionData$data;
const GigSeriesContext = createContext<
  OrganizationPositionData | null | undefined
>(null);

export function OrganizationPostionDataProvider({
  children,
  gigSeries: gigSeriesRef
}: {
  children: ReactNode;
  gigSeries: useOrganizationPositionData$key | null;
}) {
  const gigSeries = useRelayFragment(
    graphql`
      fragment useOrganizationPositionData on OrganizationPosition {
        id
        name
        gigSeries(first: 100) {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    gigSeriesRef
  );
  return (
    <GigSeriesContext.Provider value={gigSeries}>
      {children}
    </GigSeriesContext.Provider>
  );
}

export const useOrganizationPositionData = () => useContext(GigSeriesContext);
