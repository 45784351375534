/**
 * @generated SignedSource<<1d6e0f797f2d4861afdcc5b6e3e476f2>>
 * @relayHash 9377066af4b12b646d4413b67a633622
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:AD3wd4H7khAV-zKMd4EM0z2RNY-JYwEPnhCMOJUwr_I

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnsetUserPronounInput = {
  userId: string;
};
export type useUserPronounUnsetMutation$variables = {
  input: UnsetUserPronounInput;
};
export type useUserPronounUnsetMutation$data = {
  readonly unsetUserPronoun: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"useUserPronoun_Worker">;
    } | null | undefined;
  } | null | undefined;
};
export type useUserPronounUnsetMutation = {
  response: useUserPronounUnsetMutation$data;
  variables: useUserPronounUnsetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserPronounUnsetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnsetUserPronounPayload",
        "kind": "LinkedField",
        "name": "unsetUserPronoun",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "useUserPronoun_Worker"
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserPronounUnsetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnsetUserPronounPayload",
        "kind": "LinkedField",
        "name": "unsetUserPronoun",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPronoun",
                    "kind": "LinkedField",
                    "name": "pronoun",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:AD3wd4H7khAV-zKMd4EM0z2RNY-JYwEPnhCMOJUwr_I",
    "metadata": {},
    "name": "useUserPronounUnsetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bc74bce4cc5b19c7ac2729205756744b";

export default node;
