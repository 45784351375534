/**
 * @generated SignedSource<<f33b8869b72e096090da3f0ec9d80ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerPositionCheckModal_Worker$data = {
  readonly id: string;
  readonly positions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SkillCategories_categoriesScreen">;
  readonly " $fragmentType": "WorkerPositionCheckModal_Worker";
};
export type WorkerPositionCheckModal_Worker$key = {
  readonly " $data"?: WorkerPositionCheckModal_Worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPositionCheckModal_Worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "positions"
        ]
      }
    ]
  },
  "name": "WorkerPositionCheckModal_Worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "positions",
      "args": [
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status = CONFIRMED"
        }
      ],
      "concreteType": "WorkerGigPositionsConnection",
      "kind": "LinkedField",
      "name": "__WorkerPositionCheckModal_positions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerGigPositionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerGigPosition",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__WorkerPositionCheckModal_positions_connection(query:\"WHERE status = CONFIRMED\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillCategories_categoriesScreen"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "5a92994f171240bceec78fc217db9fc1";

export default node;
