import { FooterSpacer, ScreenScroll, Spacer, Stack } from "@gigsmart/atorasu";
import { PickupShifts } from "@gigsmart/feature-flags";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import PickupShiftTopicPreview from "@gigsmart/seibutsu/gig/worker/PickupShiftTopicPreview";
import ShiftTopicPreview from "@gigsmart/seibutsu/gig/worker/ShiftTopicPreview";
import JobBoardPreview from "@gigsmart/seibutsu/job-posts/worker/JobBoardPreview";
import useRetryOnFocus from "@gigsmart/seibutsu/nav/useRetryOnFocus";
import ProjectTopicPreview from "@gigsmart/seibutsu/project/worker/ProjectTopicPreview";
import React, { useMemo } from "react";
import { useGeolocation } from "../../geolocation";
import type {
  BrowseTabScreenQuery,
  OrderBy
} from "./__generated__/BrowseTabScreenQuery.graphql";
import BrowseTabHeader from "./browse-tab-header";

const MAX_DISTANCE = 75;

export default createSuspendedQueryContainer<BrowseTabScreenQuery>(
  function BrowseTabScreen({ response, retry }) {
    useRetryOnFocus(retry);

    const geolocation = useGeolocation(false);
    const stateName = response?.viewer?.currentEngagement?.currentState?.name;
    const address = geolocation.area;
    const hasCurrentEngagement =
      !!stateName &&
      !["PENDING_TIMESHEET_APPROVAL", "ENDED"].includes(stateName);

    return (
      <>
        <ScreenScroll testID="browse-tab">
          {hasCurrentEngagement && <Spacer />}
          <Stack>
            <BrowseTabHeader address={address} />
            {PickupShifts.isEnabled() && (
              <PickupShiftTopicPreview fragmentRef={response?.viewer} />
            )}
            <ShiftTopicPreview fragmentRef={response?.viewer} />
            <JobBoardPreview fragmentRef={response} />
            <ProjectTopicPreview fragmentRef={response?.viewer} />
            <FooterSpacer size="large" />
          </Stack>
        </ScreenScroll>
      </>
    );
  },
  {
    query: graphql`
      query BrowseTabScreenQuery(
        $maxDistance: Int!
        $jobPostSearch: JobPostingSearch!
        $affiliateJobPostSearch: AffiliateJobPostingsInput!
      ) {
        ...JobBoardPreview_root
          @arguments(
            affiliateJobPostSearch: $affiliateJobPostSearch
            jobPostSearch: $jobPostSearch
          )
        viewer {
          ... on Worker {
            ...ShiftTopicPreview_worker @arguments(maxDistance: $maxDistance)
            ...ProjectTopicPreview_worker @arguments(maxDistance: $maxDistance)
            ...PickupShiftTopicPreview_worker
            currentEngagement {
              id
              currentState {
                name
              }
            }
          }
        }
      }
    `,
    variables: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const geolocation = useGeolocation(false);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useMemo(
        () => ({
          maxDistance: MAX_DISTANCE,
          jobPostSearch: {
            maxDistance: MAX_DISTANCE,
            orderBy: [{ field: "boosted", direction: "DESC" }] as OrderBy[],
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
          },
          affiliateJobPostSearch: {
            maxDistance: MAX_DISTANCE,
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
          }
        }),
        [geolocation]
      );
    }
  }
);
