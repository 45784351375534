import React from "react";
import Svg, { type SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 228 204" {...props}>
      <Path
        d="M168.54 198.662L167.828 194.966C162.433 194.714 156.491 194.499 150.119 194.327L149.987 195.04C150.079 195.331 150.128 195.633 150.132 195.938V198.662H143.777V195.938C143.778 195.707 143.804 195.477 143.855 195.252H143.777L143.78 194.173C136.254 194.011 128.224 193.906 119.859 193.869L120.102 197.564L113.052 197.576L113.049 197.587H101.42C101.415 196.271 101.802 194.984 102.531 193.889C97.5123 193.924 92.6234 193.982 87.9076 194.063L87.9146 195.086L87.8917 195.084C87.8973 195.161 87.9146 195.234 87.9146 195.312V198.03H81.5743V195.312C81.5786 195.005 81.6281 194.701 81.721 194.408L81.6839 194.186C75.7898 194.316 70.2033 194.481 65.0191 194.678L64.3734 198.03H52.1122C52.1109 197.056 52.3526 196.096 52.8154 195.239C50.588 195.362 48.4663 195.491 46.4813 195.626L46.7461 197.76L44.5726 198.03L44.2938 195.783C33.1845 196.609 26.5726 197.639 26.5726 198.758C26.5726 201.468 65.2699 203.665 113.005 203.665C160.741 203.665 199.438 201.468 199.438 198.758C199.438 197.583 192.147 196.504 180.003 195.659C180.544 196.567 180.83 197.605 180.83 198.662L168.54 198.662Z"
        fill="#DADDE2"
      />
      <Path
        d="M149.405 41.411H148.777V41.846H149.943V40.475L149.948 38.512V38.51H148.777V40.706H149.405V41.411Z"
        fill="#D8DEDF"
      />
      <Path
        d="M180.187 52.6484H180.398V52.2124C180.398 51.7735 180.565 51.351 180.864 51.0301C181.164 50.7092 181.574 50.5137 182.012 50.4832V48.8077H182.256V50.4832C182.548 50.5046 182.83 50.5998 183.075 50.7599C183.32 50.92 183.521 51.1398 183.658 51.3986V41.5274H185.203V41.0109H179.143V42.6685H180.187V52.6484Z"
        fill="#D8DEDF"
      />
      <Path
        d="M191.788 58.24H192.781V46.567H193.589V43.144H195.871V41.242H196.763V39.34H197.577V38.969H190.698V41.527H191.788V58.24Z"
        fill="#D8DEDF"
      />
      <Path
        d="M149.143 35.6901H148.777V36.3508H149.143V35.6901Z"
        fill="#D8DEDF"
      />
      <Path
        d="M102.507 60.7132C102.094 60.178 101.7 59.6276 101.327 59.0619V61.1715H101.47L102.507 60.7132Z"
        fill="#D8DEDF"
      />
      <Path
        d="M79.9303 48.327H79.0133V53.9367H82.5787V52.4751H83.9598V47.4602H81.5162C81.3456 47.7259 81.111 47.9445 80.8339 48.0959C80.5568 48.2474 80.2461 48.3269 79.9303 48.327Z"
        fill="#D8DEDF"
      />
      <Path
        d="M38.545 44.118V62.505H39.616V63.16H39.823V62.745H40.465V62.305H39.823V61.889H40.465V61.449H39.823V61.033H40.465V60.593H39.823V60.177H40.465V59.737H39.823V59.321H40.465V58.881H39.823V58.466H40.465V58.026H39.823V57.61H40.465V57.17H39.823V56.754H40.465V56.314H39.823V55.898H40.465V55.458H39.823V55.042H40.465V54.602H39.823V54.187H40.465V53.747H39.823V53.331H40.465V52.891H39.823V52.475H45.058V43.591H42.561L42.565 43.619L38.545 44.118Z"
        fill="#D8DEDF"
      />
      <Path
        d="M151.088 52.8114H152.985V49.6366C152.734 49.6366 152.485 49.5871 152.254 49.4909C152.022 49.3948 151.811 49.2539 151.633 49.0763C151.456 48.8987 151.315 48.6879 151.219 48.4559C151.123 48.2238 151.074 47.9752 151.074 47.7241L151.074 44.8026C150.708 44.4815 150.416 44.0842 150.219 43.6385V52.0513H151.088V52.8114Z"
        fill="#D8DEDF"
      />
      <Path d="M180.398 53.137H180.187V54.82H180.398V53.137Z" fill="#D8DEDF" />
      <Path
        d="M220.846 63.343V66.824H225.149V59.015H220.526V56.167H214.883V58.8H212.879V52.486H210.205V63.343H220.846Z"
        fill="#D8DEDF"
      />
      <Path
        d="M9.58999 63.16H10.507V62.505H16.011V53.259H10.595V55.972H8.64099V63.464H9.58999V63.16Z"
        fill="#D8DEDF"
      />
      <Path
        d="M173.237 38.51V60.357H173.865V59.163H174.619V42.669H176.425V42.098H177.315V41.527V38.377H174.886V38.51H173.237Z"
        fill="#D8DEDF"
      />
      <Path
        d="M138.385 66.1595C138.997 64.9141 139.623 63.7417 140.263 62.676V62.6753L140.264 62.6751L140.265 62.6734L141.317 62.3728V55.3085H142.511V52.0513H146.039V51.1284H147.885V52.0513H150.219V43.6385C150.037 43.2193 149.943 42.7672 149.943 42.3101V41.8455H146.311V41.4113H149.405V40.7055H146.311V40.2169H140.936V37.7741H140.421V38.8055H139.905V40.2169H136.105V41.8455H134.802V56.5571H138.385L138.385 66.1595Z"
        fill="#BCC1C5"
      />
      <Path
        d="M29.966 62.505H36.928V62.203H37.429V61.743H36.928V61.159H37.429V60.699H36.928V60.115H37.429V59.655H36.928V59.071H37.429V58.611H36.928V58.027H37.429V57.567H36.928V56.983H37.429V56.523H36.928V55.939H37.429V55.479H36.928V54.895H37.429V54.436H36.928V53.851H37.429V53.392H36.928V52.807H37.429V52.348H36.928V51.763H37.429V51.304H36.928V50.719H37.429V50.26H36.928V49.675H37.429V49.216H36.928V48.631H37.429V48.172H36.928V47.587H37.429V47.128H36.928V46.543H37.429V46.084H36.928V45.499H37.429V45.04H36.928V44.455H37.429V44.256L28.032 45.422V52.309L28.295 54.422L28.953 54.341L29.966 62.505Z"
        fill="#BCC1C5"
      />
      <Path
        d="M172.132 36.3508H173.237V29.0883H172.694V26.2654H170.517C171.572 27.9277 172.132 29.856 172.132 31.8249V36.3508Z"
        fill="#BCC1C5"
      />
      <Path
        d="M171.268 38.5103V38.512L171.192 40.4745L171.081 43.395V43.8324L171.08 50.2882C171.161 50.552 171.204 50.826 171.208 51.1018V51.4007H171.08L171.079 55.495C170.778 56.219 170.319 56.8667 169.737 57.3912C169.154 57.9157 168.462 58.3037 167.71 58.527V59.0328L168.351 59.3167V58.5114H173.074V60.3571H173.237V38.5103H171.268Z"
        fill="#BCC1C5"
      />
      <Path
        d="M82.579 53.937H83.378V57.426H86.325V52.475H83.96H82.579V53.937Z"
        fill="#BCC1C5"
      />
      <Path
        d="M207.615 63.343H210.205V52.486V47.22H207.615V63.343Z"
        fill="#BCC1C5"
      />
      <Path
        d="M126.592 54.577V56.802V58.511H126.822V56.557H134.395V46.235H133.159V45.236H130.829V44.523H128.452V45.236H126.592V53.203V54.577Z"
        fill="#C9CFD2"
      />
      <Path
        d="M88.5501 63.0173L89.2662 63.3428H98.5536C99.0487 62.7057 99.3795 62.3319 99.5704 62.1295V61.1715H101.327V59.0619C98.4278 54.6675 96.8858 49.5175 96.8927 44.2531V41.7175H95.7875V41.2897H95.1693V40.3388H94.7653V41.2897H93.743V41.7175H92.7445V42.1455H91.8888V41.0697H91.4134V42.1455H87.7286V43.667H88.3229V44.8557H87.7286V57.4257H88.5501V63.0173Z"
        fill="#C9CFD2"
      />
      <Path
        d="M55.4356 63.1381V62.9086H55.9217L64.8558 58.6888L64.8507 58.6824V55.8793C63.8943 55.7243 62.9534 55.4858 62.0387 55.1665V48.1799C61.6952 48.0358 61.402 47.7934 61.1957 47.4833C60.9895 47.1731 60.8795 46.8089 60.8795 46.4364V42.529C60.8795 42.1565 60.9896 41.7923 61.1958 41.4822C61.402 41.172 61.6952 40.9296 62.0387 40.7855V38.3657H58.7499V36.2392H61.4613V32.8255C61.4594 30.9131 61.9444 29.0318 62.8707 27.3587H57.1327V47.3754H53.662V65.7535L54.756 63.4593L55.4356 63.1381Z"
        fill="#C9CFD2"
      />
      <Path
        d="M176.425 42.098V42.669H174.619V59.163H176.949V58.24H178.686V57.358H179.691V55.851H179.067V54.82H180.187V53.137H179.441V52.648H180.187V42.669H179.143H178.85V42.098H178.042V41.527H177.315V42.098H176.425Z"
        fill="#C9CFD2"
      />
      <Path
        d="M81.53 59.824L81.538 59.804L82.036 60.033V57.426H83.378V53.937H82.579H79.013V58.68L81.53 59.824Z"
        fill="#C9CFD2"
      />
      <Path d="M152.985 49.637V52.811H153.529V49.637H152.985Z" fill="#C9CFD2" />
      <Path
        d="M183.658 41.5274V51.3986C183.795 51.6482 183.867 51.9279 183.869 52.2124V52.6484H184.826V53.137H183.869V54.82H185.201V55.8514H184.577V57.3578H185.581V58.24H187.318V59.1628H190.403V60.3571H191.788V41.5274H190.243V40.7667H189.268V40.0536H187.937V36.1547H187.509V40.0536H186.178V40.7667H185.203V41.5274H183.658Z"
        fill="#C9CFD2"
      />
      <Path
        d="M27.759 62.505L26.78 54.61L27.441 54.528L26.701 48.561L16.011 49.887V53.259V62.505H27.759Z"
        fill="#C9CFD2"
      />
      <Path
        d="M196.763 41.242H195.871V43.144H193.589V46.567H192.781V58.24H196.688V63.343H207.615V47.22V39.34H203.479V38.484H202.671V39.34H201.744V37.438H200.959V39.34H197.577H196.763V41.242Z"
        fill="#C9CFD2"
      />
      <Path
        d="M40.533 63.16V63.464H41.501V64.475H42.794V66.824H45.058V52.475H39.823V52.891H40.465V53.331H39.823V53.747H40.465V54.187H39.823V54.602H40.465V55.042H39.823V55.458H40.465V55.898H39.823V56.314H40.465V56.754H39.823V57.17H40.465V57.61H39.823V58.026H40.465V58.466H39.823V58.881H40.465V59.321H39.823V59.737H40.465V60.177H39.823V60.593H40.465V61.033H39.823V61.449H40.465V61.889H39.823V62.305H40.465V62.745H39.823V63.16H40.465H40.533Z"
        fill="#C9CFD2"
      />
      <Path
        d="M167.71 59.0329V58.527C167.493 58.602 167.263 58.6686 167.019 58.7268L167.71 59.0329Z"
        fill="#C9CFD2"
      />
      <Path
        d="M27.448 47.601L28.032 52.309V45.422L16.011 46.913V49.02L27.448 47.601Z"
        fill="#C9CFD2"
      />
      <Path
        d="M228 66.8238H220.846V63.3428H196.688V58.24H191.788V60.3571H190.403V59.1629H187.318V58.24H185.581V57.3578H184.577V55.8513H185.201V54.82H183.869V53.137H184.826V52.6484H183.869V52.2124C183.864 51.775 183.696 51.3553 183.397 51.0354C183.099 50.7156 182.692 50.5187 182.256 50.4832V48.8077H182.012V50.4832C181.574 50.5138 181.164 50.7092 180.864 51.0301C180.565 51.351 180.398 51.7735 180.398 52.2124V52.6484H179.441V53.137H180.398V54.82H179.066V55.8514H179.691V57.3579H178.686V58.24H176.949V59.1629H173.865V60.3571H173.074V58.5114H168.351V59.3167L169.819 59.967L178.418 63.7752L178.418 63.7864C178.45 63.8993 179.152 66.3947 179.931 70.8932H228V66.8238Z"
        fill="#C7CFD1"
      />
      <Path
        d="M28.5565 70.2291L28.7318 70.3445L27.7594 62.505H10.507V63.1605H9.58982V63.464H8.62241V64.4752H7.329V66.8239H0V70.8933H28.127C28.1493 70.858 28.1669 70.8211 28.1901 70.7861L28.5565 70.2291Z"
        fill="#C7CFD1"
      />
      <Path
        d="M153.527 58.881V58.883H153.529V52.811H152.985H151.088V52.051H150.219H147.885V51.128H146.039V52.051H142.511V55.308H141.317V62.373L146.345 60.936L153.527 58.881Z"
        fill="#C7CFD1"
      />
      <Path
        d="M83.378 57.426H82.036V60.033L85.764 61.75L88.55 63.017V57.426H87.729H86.325H83.378Z"
        fill="#C7CFD1"
      />
      <Path
        d="M91.5221 70.8932H93.5387C95.0681 68.2848 96.7423 65.7641 98.5536 63.3428H89.2662L89.5221 63.4593C90.3457 65.8923 91.0138 68.3753 91.5221 70.8932V70.8932Z"
        fill="#C7CFD1"
      />
      <Path d="M55.436 62.909V63.138L55.922 62.909H55.436Z" fill="#C7CFD1" />
      <Path
        d="M126.822 58.5114H126.592V60.6966L127.623 61.0315C127.623 61.0315 128.693 63.9788 129.369 70.8932H136.268C136.949 69.2368 137.656 67.6442 138.385 66.1595V56.5571H126.822L126.822 58.5114Z"
        fill="#C7CFD1"
      />
      <Path
        d="M45.0584 66.8238H42.7943V64.4751H41.5007V63.464H40.5334V63.1605H39.6161V62.505H29.9663L31.0069 70.8932H51.211L53.2928 66.5276V31.0876C53.3043 30.5479 53.2049 30.0116 53.0009 29.5118C52.7969 29.012 52.4926 28.5594 52.1067 28.1819C51.7208 27.8044 51.2617 27.51 50.7575 27.317C50.2534 27.124 49.715 27.0364 49.1757 27.0597V24.4593C47.2255 24.4593 45.0584 26.3176 45.0584 29.7847V66.8238H45.0584Z"
        fill="#C7CFD1"
      />
      <Path
        d="M99.7718 61.9275L99.7787 61.9383V61.9189L101.47 61.1715H99.5704V62.1295C99.7059 61.9857 99.7718 61.9275 99.7718 61.9275Z"
        fill="#C7CFD1"
      />
      <Path
        d="M178.418 63.7864L178.413 63.8831L178.409 76.6538V90.9242L178.406 104.029L180.765 95.3545L182.038 100.759C182.036 99.5078 182.035 98.2502 182.035 96.9944C182.074 88.2501 181.371 79.5183 179.931 70.8932C179.152 66.3947 178.45 63.8993 178.418 63.7864Z"
        fill="#003B5C"
      />
      <Path
        d="M178.406 104.029L178.403 116.955L181.433 116.909C181.433 116.909 181.385 117.51 181.253 118.309L182.16 118.906C182.34 118.068 182.442 117.215 182.464 116.358L185.72 116.384L182.038 100.759L180.765 95.3544L178.406 104.029Z"
        fill="#003B5C"
      />
      <Path
        d="M157.891 121.107L157.889 121.3L157.132 121.294L157.133 121.101L156.742 121.098L156.74 121.291L155.983 121.285L155.984 121.092L155.48 121.088L155.485 120.349L153.527 120.333V121.981C153.525 122.278 153.506 122.574 153.469 122.867H162.171L162.18 121.702C159.912 121.601 158.349 121.529 158.349 121.529L158.367 119.215L158.352 121.11L157.891 121.107Z"
        fill="#003B5C"
      />
      <Path
        d="M146.345 60.936L141.317 62.373L140.265 62.673V62.675L146.345 60.936Z"
        fill="#003B5C"
      />
      <Path
        d="M166.941 59.0001C166.941 59.0001 165.18 61.6444 160.235 61.6444C155.29 61.6444 153.529 59.0001 153.529 59.0001V58.8825L153.527 58.8831V118.22L155.502 118.236L155.506 117.667L156.011 117.671L156.012 117.478L156.77 117.484L156.769 117.677L157.16 117.68L157.161 117.487L157.919 117.493L157.918 117.686L158.379 117.69L158.382 117.256L169.476 117.089V71.8702C169.476 61.801 167.301 59.081 166.981 58.7356L166.941 58.7448L166.941 59.0001Z"
        fill="#003B5C"
      />
      <Path
        d="M178.413 63.775V63.883L178.418 63.786V63.775L169.819 59.967L178.413 63.775Z"
        fill="#003B5C"
      />
      <Path
        d="M158.685 128.317L157.885 128.269L151.14 127.863C150.774 128.031 150.393 128.164 150.003 128.263C148.846 128.978 147.512 129.357 146.151 129.358H143.981L143.78 194.173L143.777 195.252H143.855C144.005 194.565 144.38 193.947 144.92 193.496C145.46 193.045 146.135 192.787 146.839 192.762C147.542 192.738 148.233 192.949 148.804 193.361C149.374 193.773 149.79 194.364 149.987 195.04L150.12 194.327L160.41 138.753L160.269 137.664L160.192 137.65L160.236 137.408L159.065 128.34L158.685 128.317L158.685 128.317Z"
        fill="#4D627A"
      />
      <Path
        d="M153.279 123.847L158.495 123.922L158.359 122.867H153.469C153.428 123.198 153.365 123.525 153.279 123.847Z"
        fill="#4D627A"
      />
      <Path
        d="M161.679 128.477L161.714 123.968L161.679 128.497L159.065 128.34L160.236 137.408L161.131 132.578L164.327 130.167L164.827 130.831L161.889 133.046L161.008 137.801L160.269 137.664L160.41 138.753L167.382 192.761H174.896L175.722 130.151L162.501 129.357C162.278 129.343 162.068 129.244 161.915 129.081C161.762 128.917 161.678 128.701 161.679 128.477V128.477Z"
        fill="#4D627A"
      />
      <Path
        d="M158.359 122.867L158.495 123.922L161.714 123.968L162.162 123.975L162.171 122.867H158.359Z"
        fill="#4D627A"
      />
      <Path
        d="M160.269 137.664L161.008 137.801L161.889 133.046L164.827 130.831L164.327 130.167L161.131 132.578L160.236 137.408L160.192 137.65L160.269 137.664Z"
        fill="#3A3B4D"
      />
      <Path
        d="M154.921 45.7137H154.92V47.556H154.921V45.7137Z"
        fill="#60432F"
      />
      <Path
        d="M167.828 194.966L168.54 198.662H180.83C180.826 197.098 180.203 195.599 179.097 194.493C177.991 193.388 176.492 192.765 174.929 192.76H167.403L167.828 194.966Z"
        fill="#2B2C40"
      />
      <Path
        d="M146.954 192.76C146.113 192.765 145.308 193.102 144.713 193.697C144.118 194.292 143.782 195.097 143.777 195.938V198.662H150.132V195.938C150.116 195.1 149.776 194.301 149.184 193.709C148.591 193.116 147.792 192.776 146.954 192.76Z"
        fill="#2B2C40"
      />
      <Path d="M140.263 62.675V62.676L140.264 62.675H140.263Z" fill="#F08A59" />
      <Path
        d="M153.527 121.981V120.333L148.547 120.295L148.782 120.827L148.221 121.177C148.479 121.061 148.77 121.041 149.041 121.121C149.312 121.201 149.546 121.376 149.699 121.614L151.092 123.816L153.279 123.847C153.365 123.525 153.428 123.198 153.469 122.867C153.506 122.574 153.525 122.278 153.527 121.981V121.981Z"
        fill="#F08A59"
      />
      <Path
        d="M153.527 90.924H142.107V90.694L140.263 94.63V101.57L147.609 118.174L153.527 118.22V90.924Z"
        fill="#F08A59"
      />
      <Path
        d="M153.527 80.3208H142.59L143.141 84.5071C143.256 85.4218 143.253 86.3476 143.13 87.2614H153.527V80.3208Z"
        fill="#F08A59"
      />
      <Path
        d="M153.527 76.654V58.883V58.881L146.345 60.936L140.265 62.675L142.107 76.658V76.654H153.527Z"
        fill="#F08A59"
      />
      <Path
        d="M144.954 127.492L140.263 127.21V129.358H146.151C147.512 129.357 148.846 128.978 150.003 128.263C149.152 128.473 148.268 128.513 147.402 128.381C146.535 128.249 145.703 127.946 144.954 127.492V127.492Z"
        fill="#F08A59"
      />
      <Path
        d="M167.019 58.7268L166.981 58.7356C167.301 59.081 169.476 61.801 169.476 71.8702V76.6538H178.409L178.413 63.8831V63.7749L169.819 59.967L168.351 59.3167L167.71 59.0328L167.019 58.7268Z"
        fill="#F08A59"
      />
      <Path
        d="M178.409 80.3208H169.476V87.2614H178.409V80.3208Z"
        fill="#F08A59"
      />
      <Path
        d="M169.476 90.9241V117.089L178.403 116.955L178.406 104.029L178.409 90.9242L169.476 90.9241Z"
        fill="#F08A59"
      />
      <Path
        d="M153.527 76.654H142.107V76.658L142.59 80.321H153.527V76.654Z"
        fill="white"
      />
      <Path
        d="M178.409 76.6538H169.476V80.3208H178.409V76.6538Z"
        fill="white"
      />
      <Path
        d="M153.527 90.9241V87.2614H143.13C142.995 88.3653 142.688 89.4413 142.221 90.4506L142.107 90.694V90.9241H153.527Z"
        fill="white"
      />
      <Path
        d="M178.409 87.2614H169.476V90.9241H178.409V87.2614Z"
        fill="white"
      />
      <Path
        d="M179.059 122.376C177.123 122.361 167.615 121.946 162.18 121.702L162.171 122.867L162.162 123.975L177.023 124.188C177.707 124.21 178.386 124.069 179.005 123.778C179.623 123.486 180.164 123.053 180.583 122.512L179.846 122.027C179.639 122.242 179.357 122.368 179.059 122.376Z"
        fill="#383735"
      />
      <Path
        d="M130.026 119.745L137.156 119.801L136.53 118.493L128.413 118.429C128.98 118.831 129.519 119.271 130.026 119.745Z"
        fill="#383735"
      />
      <Path
        d="M155.485 120.349L155.502 118.236L153.527 118.22L147.609 118.174L148.547 120.295L153.527 120.333L155.485 120.349Z"
        fill="#474543"
      />
      <Path
        d="M187.272 125.136L190.242 125.159C190.351 125.16 190.459 125.182 190.559 125.225C190.66 125.268 190.751 125.33 190.828 125.408C190.904 125.485 190.965 125.578 191.006 125.679C191.047 125.78 191.068 125.888 191.067 125.998L191.063 126.48C191.062 126.594 191.037 126.706 190.991 126.809C190.944 126.912 190.877 127.005 190.793 127.081V128.24L192.455 128.253C196.189 128.282 199.166 122.545 195.994 118.506C195.496 117.747 194.78 117.157 193.94 116.814L193.894 122.698L191.765 122.682L191.766 122.592C191.528 123.472 190.687 124.118 188.9 124.104L187.558 124.093C187.489 124.395 187.396 124.746 187.272 125.136Z"
        fill="#403F3D"
      />
      <Path
        d="M190.793 126.501V127.081C190.877 127.005 190.944 126.912 190.991 126.809C191.037 126.706 191.062 126.594 191.063 126.48L191.067 125.998C191.068 125.888 191.047 125.78 191.006 125.679C190.965 125.578 190.904 125.485 190.828 125.408C190.751 125.33 190.66 125.268 190.559 125.225C190.459 125.182 190.351 125.16 190.242 125.159L187.272 125.136C187.123 125.601 186.946 126.057 186.741 126.501H190.793Z"
        fill="#30302E"
      />
      <Path
        d="M193.894 122.698L193.94 116.814L193.954 114.886L191.827 114.87L191.766 122.592L191.765 122.682L193.894 122.698Z"
        fill="#82878A"
      />
      <Path
        d="M179.846 122.027L180.583 122.512C181.379 121.447 181.919 120.213 182.16 118.906L181.253 118.309C181.046 119.563 180.627 121.298 179.846 122.027Z"
        fill="#383735"
      />
      <Path
        d="M178.403 116.955L169.476 117.089L158.382 117.256L158.379 117.69L157.918 117.686L157.891 121.107L158.352 121.11L158.367 119.215L158.349 121.529C158.349 121.529 159.912 121.601 162.18 121.702C167.615 121.946 177.123 122.361 179.059 122.376C179.357 122.368 179.639 122.242 179.846 122.027C180.627 121.298 181.046 119.563 181.253 118.309C181.385 117.51 181.433 116.91 181.433 116.91L178.403 116.955Z"
        fill="#82878A"
      />
      <Path
        d="M156.769 117.677L156.742 121.098L157.133 121.101L157.16 117.68L156.769 117.677Z"
        fill="#82878A"
      />
      <Path
        d="M155.485 120.349L155.48 121.088L155.984 121.092L156.011 117.671L155.506 117.667L155.502 118.236L155.485 120.349Z"
        fill="#82878A"
      />
      <Path
        d="M157.133 121.101L157.132 121.294L157.889 121.3L157.891 121.107L157.918 117.686L157.919 117.493L157.161 117.487L157.16 117.68L157.133 121.101Z"
        fill="#A1A8AB"
      />
      <Path
        d="M155.984 121.092L155.983 121.285L156.74 121.291L156.742 121.098L156.769 117.677L156.77 117.484L156.012 117.478L156.011 117.671L155.984 121.092Z"
        fill="#A1A8AB"
      />
      <Path
        d="M188.51 123.144L189.74 123.154L189.805 114.876L187.802 114.861L188.575 114.867L188.51 123.144Z"
        fill="#82878A"
      />
      <Path
        d="M182.16 118.906C181.919 120.213 181.379 121.447 180.583 122.512C180.164 123.053 179.623 123.486 179.004 123.778C178.386 124.069 177.707 124.21 177.023 124.188L162.162 123.975L161.714 123.968L161.679 128.477C161.678 128.701 161.762 128.917 161.915 129.081C162.068 129.244 162.278 129.343 162.501 129.357L175.721 130.151L180.695 130.45C181.25 130.489 181.807 130.452 182.352 130.34C182.297 130.091 182.269 129.836 182.268 129.581V126.501H186.741C186.946 126.057 187.123 125.602 187.272 125.136C187.396 124.746 187.489 124.395 187.558 124.093C187.689 123.52 187.737 123.138 187.737 123.138L187.79 116.4L185.72 116.384L182.464 116.358C182.442 117.215 182.34 118.068 182.16 118.906V118.906ZM183.677 123.916C183.458 123.915 183.245 123.848 183.064 123.725C182.883 123.602 182.743 123.429 182.661 123.226C182.579 123.023 182.559 122.801 182.603 122.587C182.647 122.373 182.754 122.177 182.91 122.023C183.066 121.87 183.263 121.766 183.478 121.725C183.693 121.684 183.915 121.708 184.116 121.793C184.318 121.878 184.489 122.021 184.609 122.204C184.729 122.387 184.792 122.601 184.791 122.82C184.789 122.965 184.76 123.108 184.703 123.242C184.647 123.376 184.564 123.497 184.461 123.599C184.357 123.701 184.235 123.781 184.1 123.836C183.966 123.89 183.822 123.917 183.677 123.916V123.916Z"
        fill="#FFC887"
      />
      <Path
        d="M127.684 125.01C127.683 125.269 127.748 125.524 127.873 125.751C127.999 125.978 128.18 126.169 128.401 126.305L127.701 124.86C127.695 124.91 127.685 124.958 127.684 125.01Z"
        fill="#383735"
      />
      <Path
        d="M153.279 123.847L151.092 123.816L152.972 126.788L151.952 127.433C151.691 127.595 151.42 127.739 151.14 127.863L157.885 128.269L157.201 128.228L157.235 123.914L158.72 123.925L158.685 128.317L159.065 128.34L161.679 128.497L161.714 123.968L158.495 123.922L153.279 123.847Z"
        fill="#383735"
      />
      <Path
        d="M144.954 127.492C144.076 126.962 143.334 126.236 142.786 125.37L142.425 124.8L143.087 124.382L140.211 126.177L138.996 123.642L131.791 123.538C131.43 125.225 129.409 126.187 128.737 126.463C128.859 126.505 128.985 126.53 129.114 126.54L140.263 127.21L144.954 127.492Z"
        fill="#383735"
      />
      <Path
        d="M158.685 128.317L158.72 123.925L157.235 123.914L157.201 128.228L157.885 128.269L158.685 128.317Z"
        fill="#FFC887"
      />
      <Path
        d="M183.694 121.706C183.475 121.704 183.261 121.767 183.078 121.887C182.896 122.007 182.753 122.179 182.667 122.38C182.582 122.581 182.559 122.804 182.6 123.018C182.641 123.233 182.744 123.431 182.898 123.587C183.051 123.742 183.247 123.849 183.461 123.893C183.675 123.938 183.898 123.918 184.1 123.836C184.303 123.753 184.477 123.613 184.6 123.432C184.722 123.251 184.789 123.038 184.791 122.82C184.793 122.527 184.679 122.244 184.473 122.036C184.267 121.827 183.987 121.708 183.694 121.706V121.706Z"
        fill="#403F3D"
      />
      <Path
        d="M188.51 123.144L188.575 114.867L187.802 114.861L187.737 123.138L188.51 123.144Z"
        fill="#403F3D"
      />
      <Path
        d="M149.699 121.614C149.546 121.376 149.312 121.201 149.041 121.121C148.77 121.041 148.479 121.061 148.221 121.177L143.087 124.382L142.425 124.8L142.786 125.37C143.737 126.848 145.231 127.893 146.946 128.278C148.661 128.664 150.459 128.361 151.952 127.433L152.972 126.788L151.092 123.816L149.699 121.614Z"
        fill="#60432F"
      />
      <Path
        d="M129.541 94.6676L134.182 92.8182L141.038 110.023L133.846 112.889L136.53 118.493L137.156 119.801L138.996 123.642L140.211 126.177L143.087 124.382L148.221 121.177L148.782 120.827L148.547 120.295L147.609 118.174L140.263 101.57L138.684 98.0003L140.263 94.6299L142.107 90.6939L142.221 90.4506C143.068 88.5915 143.386 86.5351 143.141 84.507L142.59 80.3207L142.107 76.6584L140.265 62.6748L140.265 62.6734L140.264 62.6751L140.263 62.676C139.623 63.7417 138.997 64.9141 138.385 66.1595C137.656 67.6442 136.949 69.2368 136.268 70.8932C133.71 77.349 131.533 83.949 129.747 90.6593C129.694 91.9447 129.624 93.2861 129.541 94.6676Z"
        fill="#003B5C"
      />
      <Path
        d="M154.92 47.818L154.921 47.828V47.556H154.92V47.818Z"
        fill="#DB7170"
      />
      <Path
        d="M151.075 38.5103H149.948V38.512H151.075V38.5103Z"
        fill="#212121"
      />
      <Path d="M171.268 38.512V38.51H170.969V38.512H171.268Z" fill="#212121" />
      <Path
        d="M171.081 40.479V43.395L171.192 40.475H171.081V40.479Z"
        fill="#212121"
      />
      <Path d="M154.92 45.714H154.921V44.347L154.92 45.714Z" fill="#212121" />
      <Path
        d="M171.081 40.4745H157.309C157.228 40.9152 157.101 41.346 156.929 41.7597C157.208 43.6469 157.698 45.4969 158.39 47.2747C158.723 48.1374 159.275 48.8981 159.993 49.4812C160.71 50.0643 161.568 50.4493 162.48 50.5982C162.597 49.9154 162.952 49.2961 163.481 48.8494C164.011 48.4027 164.681 48.1574 165.374 48.1567L168.139 48.1572V48.1573H168.264C168.705 48.1586 169.139 48.2592 169.535 48.4517C169.931 48.6442 170.278 48.9236 170.551 49.269C170.887 47.4777 171.064 45.6604 171.081 43.8381V43.3951L171.081 40.4788V40.4745ZM166.786 47.8536L166.786 47.0418L167.694 47.0419L167.498 45.278L168.306 45.1891L168.601 47.8538L166.786 47.8536Z"
        fill="#60432F"
      />
      <Path d="M170.969 38.512V38.51H157.522V38.512H170.969Z" fill="#60432F" />
      <Path
        d="M157.422 38.5103H157.421V38.512H157.422V38.5103Z"
        fill="#60432F"
      />
      <Path
        d="M165.195 51.3997L164.719 51.3996C164.922 51.8738 165.264 52.275 165.701 52.55C166.137 52.825 166.646 52.9607 167.162 52.9391C167.692 52.9615 168.217 52.8246 168.668 52.546C169.12 52.2673 169.478 51.8597 169.696 51.3758C169.276 51.3419 168.869 51.218 168.502 51.0124C168.135 50.8069 167.816 50.5246 167.568 50.1848C167.297 50.5604 166.94 50.8663 166.527 51.0775C166.115 51.2887 165.658 51.3991 165.195 51.3997Z"
        fill="#60432F"
      />
      <Path
        d="M157.421 38.5103H151.075V38.512H157.421V38.5103Z"
        fill="#212121"
      />
      <Path
        d="M164.849 58.9744C156.452 58.9744 154.921 53.8454 154.921 53.8454V47.8284L154.92 47.8176V47.556L154.92 45.7137L154.921 44.3474L154.921 43.7952C154.921 43.2822 154.717 42.7902 154.354 42.4273C153.992 42.0645 153.5 41.8607 152.987 41.8606C152.48 41.8605 151.993 42.0619 151.635 42.4203C151.276 42.7788 151.075 43.265 151.074 43.7721V43.7724L151.074 44.8026L151.074 47.7241C151.074 47.9752 151.123 48.2238 151.219 48.4559C151.315 48.6879 151.456 48.8987 151.633 49.0763C151.811 49.2539 152.022 49.3948 152.254 49.4909C152.485 49.5871 152.734 49.6366 152.985 49.6366H152.985L153.529 49.6367V59.0001C153.529 59.0001 155.29 61.6444 160.235 61.6444C165.18 61.6444 166.941 59.0001 166.941 59.0001V58.7448C166.255 58.9004 165.553 58.9775 164.849 58.9744V58.9744Z"
        fill="#60432F"
      />
      <Path
        d="M168.306 45.189L167.498 45.278L167.694 47.042H166.786V47.854H168.601L168.306 45.189Z"
        fill="#281C14"
      />
      <Path d="M157.522 38.512V38.51H157.422V38.512H157.522Z" fill="#A16971" />
      <Path
        d="M171.08 50.2883L171.081 43.8381C171.064 45.6604 170.887 47.4777 170.551 49.2689C170.278 48.9235 169.931 48.6441 169.535 48.4516C169.139 48.2592 168.705 48.1585 168.264 48.1572H168.139V48.1572L165.374 48.1567C164.681 48.1574 164.011 48.4027 163.481 48.8494C162.952 49.296 162.597 49.9154 162.48 50.5981C161.568 50.4493 160.71 50.0642 159.993 49.4812C159.275 48.8981 158.723 48.1374 158.39 47.2746C157.698 45.4968 157.208 43.6469 156.929 41.7596C157.101 41.346 157.228 40.9152 157.309 40.4745H149.943V42.3101C149.942 42.7823 150.042 43.2493 150.238 43.6793C150.433 44.1094 150.718 44.4924 151.074 44.8026L151.074 43.7723V43.772C151.074 43.265 151.276 42.7787 151.635 42.4203C151.993 42.0618 152.48 41.8605 152.987 41.8605C153.5 41.8606 153.992 42.0645 154.354 42.4273C154.717 42.7901 154.921 43.2821 154.921 43.7951L154.92 44.3474L154.921 44.3471V53.8454C154.921 53.8454 156.452 58.9744 164.849 58.9744C165.553 58.9774 166.255 58.9004 166.941 58.7448L166.981 58.7355L167.019 58.7267C167.263 58.6683 167.493 58.6017 167.71 58.5269C168.462 58.3036 169.154 57.9156 169.737 57.3911C170.319 56.8667 170.778 56.2189 171.079 55.495L171.08 51.4006H171.208V51.1018C171.204 50.826 171.161 50.552 171.08 50.2883V50.2883ZM167.162 52.9391C166.646 52.9607 166.137 52.825 165.701 52.55C165.264 52.275 164.922 51.8738 164.719 51.3996L165.195 51.3997C165.658 51.3991 166.115 51.2887 166.527 51.0775C166.94 50.8663 167.296 50.5604 167.568 50.1848C167.816 50.5246 168.135 50.8069 168.502 51.0125C168.869 51.218 169.276 51.3419 169.696 51.3758C169.478 51.8597 169.12 52.2673 168.668 52.546C168.217 52.8246 167.692 52.9616 167.162 52.9391V52.9391Z"
        fill="#212121"
      />
      <Path
        d="M151.075 38.51H157.421H157.422H157.522H170.969H171.268H173.237H174.886V38.377V36.351H173.237H172.132H170.217V36.727H167.48V36.351H166.796V36.727H164.059V36.351H163.067V36.727H160.33V36.351H149.143H148.777H146.845V38.51H148.777H149.948H151.075Z"
        fill="#FFC887"
      />
      <Path
        d="M160.33 36.3508V28.14C160.329 26.8793 159.827 25.6707 158.936 24.7793C158.044 23.8879 156.836 23.3865 155.575 23.3851H154.241V22.8696C152.687 23.7923 151.401 25.1029 150.507 26.6729C149.613 28.2429 149.143 30.0183 149.143 31.8249V36.3508L160.33 36.3508Z"
        fill="#FBB766"
      />
      <Path
        d="M170.217 28.14V36.3508H172.132V31.8249C172.128 29.2505 171.172 26.7685 169.449 24.8563C169.953 25.8775 170.216 27.001 170.217 28.14V28.14Z"
        fill="#FBB766"
      />
      <Path
        d="M164.059 28.14C164.059 27.3541 163.863 26.5807 163.49 25.8891C163.117 25.1974 162.578 24.6091 161.922 24.1769C162.67 25.3635 163.067 26.7374 163.067 28.14V36.3508H164.059V28.14Z"
        fill="#FBB766"
      />
      <Path
        d="M167.48 28.14C167.479 27.4821 167.34 26.8318 167.073 26.2307C166.805 25.6296 166.415 25.0908 165.928 24.649C166.499 25.7241 166.797 26.9228 166.796 28.14V36.3508H167.48V28.14Z"
        fill="#FBB766"
      />
      <Path
        d="M161.922 24.177C162.578 24.6092 163.117 25.1975 163.49 25.8891C163.863 26.5808 164.059 27.3542 164.059 28.14V36.727H166.796V28.14C166.797 26.9228 166.499 25.7241 165.928 24.649C165.057 23.8396 163.914 23.3883 162.725 23.3851H161.391V20.9497C160.713 20.7509 160.01 20.6493 159.304 20.6481H157.97V21.0476C159.608 21.605 161.004 22.7103 161.922 24.177V24.177Z"
        fill="#FFC887"
      />
      <Path
        d="M161.391 23.3851H162.725C163.983 23.3962 165.186 23.9007 166.075 24.79C166.965 25.6793 167.469 26.8823 167.48 28.14V36.727H170.217V28.14C170.21 26.1552 169.418 24.2539 168.015 22.8505C166.611 21.4471 164.71 20.6554 162.725 20.6481H161.391V23.3851L161.391 23.3851Z"
        fill="#FFC887"
      />
      <Path
        d="M155.575 23.3851C156.836 23.3865 158.044 23.8879 158.936 24.7793C159.827 25.6707 160.329 26.8793 160.33 28.14V36.727H163.067V28.14C163.067 26.7374 162.67 25.3635 161.922 24.1769C161.248 23.1002 160.313 22.2117 159.203 21.5946C158.093 20.9775 156.845 20.6518 155.575 20.6481H154.241V23.3851L155.575 23.3851Z"
        fill="#FFC887"
      />
      <Path
        d="M171.081 40.4745H171.192L171.268 38.512H170.969C171.045 39.1634 171.083 39.8187 171.081 40.4745Z"
        fill="#212121"
      />
      <Path
        opacity="0.22"
        d="M171.081 40.4745H171.192L171.268 38.512H170.969C171.045 39.1634 171.083 39.8187 171.081 40.4745Z"
        fill="#2E2E3B"
      />
      <Path
        d="M171.081 40.4745C171.083 39.8187 171.045 39.1634 170.969 38.512H157.522C157.456 38.8521 157.423 39.1976 157.422 39.544V38.512H157.421L157.421 39.3088C157.42 39.7 157.382 40.0902 157.309 40.4745L171.081 40.4745Z"
        fill="#60432F"
      />
      <Path
        opacity="0.22"
        d="M171.081 40.4745C171.083 39.8187 171.045 39.1634 170.969 38.512H157.522C157.456 38.8521 157.423 39.1976 157.422 39.544V38.512H157.421L157.421 39.3088C157.42 39.7 157.382 40.0902 157.309 40.4745L171.081 40.4745Z"
        fill="#2E2E3B"
      />
      <Path
        d="M156.201 40.4745H157.309C157.382 40.0902 157.42 39.7 157.421 39.3089L157.421 38.512H149.948L149.943 40.4745H156.201Z"
        fill="#212121"
      />
      <Path
        opacity="0.22"
        d="M156.201 40.4745H157.309C157.382 40.0902 157.42 39.7 157.421 39.3089L157.421 38.512H149.948L149.943 40.4745H156.201Z"
        fill="#2E2E3B"
      />
      <Path
        d="M157.522 38.512H157.422V39.544C157.423 39.1976 157.456 38.8521 157.522 38.512V38.512Z"
        fill="#A16971"
      />
      <Path
        opacity="0.22"
        d="M157.522 38.512H157.422V39.544C157.423 39.1976 157.456 38.8521 157.522 38.512V38.512Z"
        fill="#2E2E3B"
      />
      <Path
        d="M190.793 126.501H182.268V129.581C182.272 130.53 182.651 131.438 183.322 132.109C183.993 132.78 184.901 133.159 185.85 133.163H187.212C188.161 133.163 189.072 132.785 189.744 132.114C190.416 131.442 190.793 130.531 190.793 129.581V126.501V126.501Z"
        fill="#60432F"
      />
      <Path
        d="M42.159 40.346L41.67 36.405L10.845 40.229L11.74 47.443L11.334 44.17L42.159 40.346Z"
        fill="#E5E5E5"
      />
      <Path
        d="M26.701 48.561L27.441 54.528L28.295 54.422L28.032 52.309L27.448 47.601L16.011 49.02L10.496 49.704L9.882 44.758L11.384 44.572L11.278 43.718L8.922 44.01L9.748 50.664L16.011 49.887L26.701 48.561Z"
        fill="#383735"
      />
      <Path
        d="M46.7461 197.76L46.4813 195.626L32.2015 80.5225L32.0843 80.4059L31.7142 80.9683L30.066 79.8836C30.0081 79.8455 29.9565 79.8017 29.9002 79.7621L44.2938 195.783L44.5726 198.03L46.7461 197.76Z"
        fill="#875C16"
      />
      <Path
        d="M29.966 62.505L28.953 54.341L28.295 54.422L27.441 54.528L26.78 54.61L27.759 62.505L28.732 70.345L31.135 71.926L31.007 70.893L29.966 62.505Z"
        fill="#875C16"
      />
      <Path
        d="M28.032 45.422L37.429 44.256L38.545 44.118L42.565 43.619L42.561 43.591L42.159 40.346L11.334 44.17L11.74 47.443L16.011 46.913L28.032 45.422Z"
        fill="#C6C6C6"
      />
      <Path
        d="M49.2816 91.6551C49.9805 90.5929 50.6467 89.5483 51.2801 88.5212L46.9911 95.0485C47.619 94.1505 48.6886 92.5561 49.2816 91.6551Z"
        fill="#E7E7E7"
      />
      <Path
        d="M34.0392 76.2328C34.057 75.5125 33.8914 74.7994 33.5577 74.1608C33.2241 73.5221 32.7334 72.9788 32.1319 72.582L31.1351 71.926L28.7318 70.3445L28.5565 70.2291L28.19 70.7861C28.1668 70.8211 28.1493 70.858 28.1269 70.8933C27.2281 72.3167 26.9109 74.0313 27.241 75.6821C27.5711 77.333 28.5232 78.7937 29.9002 79.7622C29.9565 79.8018 30.0081 79.8456 30.066 79.8837L31.7142 80.9683L32.0843 80.406L32.2015 80.5225L36.995 85.2914L39.8834 80.9022L34.0392 76.2328Z"
        fill="#EDC6A0"
      />
      <Path d="M82.686 95.05L83.405 93.17L82.686 95.05Z" fill="#E7E7E7" />
      <Path
        d="M74.4415 89.7429L75.0125 89.9612C74.8333 89.7996 74.6489 89.6451 74.4573 89.4979C74.4571 89.5793 74.4464 89.6612 74.4415 89.7429Z"
        fill="#003B5C"
      />
      <Path d="M82.686 95.05L81.567 97.976L82.686 95.05Z" fill="#003B5C" />
      <Path d="M81.567 97.976L81.488 98.183L81.567 97.976Z" fill="#003B5C" />
      <Path
        d="M71.2827 93.086C70.1743 92.6625 69.2013 91.9467 68.4672 91.0145C67.7331 90.0822 67.2654 88.9686 67.1136 87.7917C65.1777 88.1893 63.4234 89.205 62.1148 90.6859C60.8062 92.1669 60.0141 94.0329 59.8579 96.0029H77.2379L72.3339 93.4879L71.2827 93.086Z"
        fill="#003B5C"
      />
      <Path
        d="M87.9076 194.063L87.3768 119.183L87.5826 113.523L85.3363 113.483L83.4903 100.646C81.6973 99.8764 80.9705 99.5363 80.9705 99.5363L81.4881 98.1826L79.4195 97.1218V97.9959H77.6122L76.2054 115.581H61.6487L60.2418 97.9959H58.4344V96.0029H58.9203C59.0795 93.7929 59.9646 91.6974 61.4378 90.0424C62.9111 88.3873 64.89 87.2655 67.0668 86.8514C67.0759 86.0835 67.2202 85.3231 67.493 84.6052L67.731 83.9824L71.7293 85.511C72.3274 85.7415 72.8654 86.1047 73.3027 86.5734C73.74 87.042 74.0653 87.6037 74.254 88.2163C75.2979 88.8735 76.2126 89.7163 76.953 90.703L82.3578 92.7694L79.9108 75.7502L85.7648 61.7505L85.7643 61.7502L82.036 60.0328L81.5385 59.8036L81.5297 59.8244L75.1986 74.9646H61.6869L61.3874 60.327L57.6505 62.092L58.1499 75.5292L56.524 110.035L56.6845 119.183H56.6933L58.0329 192.142H65.5285L72.0553 136.659L81.6839 194.186L81.721 194.408C81.9293 193.698 82.3809 193.083 82.9969 192.672C83.6128 192.261 84.3537 192.08 85.0897 192.161C85.8257 192.242 86.5098 192.579 87.0221 193.113C87.5344 193.648 87.8423 194.346 87.8917 195.084L87.9146 195.086L87.9076 194.063Z"
        fill="#003B5C"
      />
      <Path
        d="M58.0329 192.142H58C57.2268 192.142 56.4611 192.294 55.7467 192.59C55.0323 192.886 54.3832 193.32 53.8365 193.866C53.2897 194.413 52.856 195.062 52.5602 195.777C52.2643 196.491 52.1121 197.257 52.1122 198.03H64.3734L65.0191 194.678L65.5074 192.142H58.0329Z"
        fill="#2B2C40"
      />
      <Path
        d="M84.7445 192.142C83.9085 192.158 83.1112 192.497 82.52 193.088C81.9288 193.679 81.5898 194.477 81.5743 195.312V198.03H87.9146V195.312C87.9146 195.234 87.8973 195.161 87.8917 195.084C87.8365 194.287 87.4811 193.54 86.8971 192.994C86.3131 192.448 85.5439 192.144 84.7445 192.142V192.142Z"
        fill="#2B2C40"
      />
      <Path
        d="M93.5133 84.4819L92.346 86.3822L93.855 89.8143C93.7757 87.9491 93.6581 86.1727 93.5133 84.4819Z"
        fill="#E7E7E7"
      />
      <Path
        d="M62.0506 40.3007H62.0417V38.3657H62.0387V40.7855L62.0417 40.7846C62.0426 40.6254 62.0438 40.4658 62.0506 40.3007Z"
        fill="#45362C"
      />
      <Path
        d="M73.8983 40.3007V44.3398H78.0177V42.5509C78.0177 42.0436 78.2192 41.5572 78.5779 41.1985C78.9366 40.8398 79.423 40.6383 79.9303 40.6383C80.2819 40.639 80.6262 40.7381 80.9244 40.9243C81.2227 41.1106 81.4628 41.3766 81.6178 41.6922V40.3007H73.8983Z"
        fill="#45362C"
      />
      <Path
        d="M81.6178 41.6922C81.4628 41.3766 81.2227 41.1106 80.9244 40.9243C80.6262 40.7381 80.2819 40.639 79.9303 40.6383C79.423 40.6383 78.9366 40.8398 78.5779 41.1985C78.2192 41.5572 78.0177 42.0436 78.0177 42.5509V44.3398H73.8983V40.3007H62.0506C62.0438 40.4657 62.0426 40.6254 62.0416 40.7846L62.0387 40.7855C61.6952 40.9296 61.402 41.172 61.1958 41.4822C60.9895 41.7923 60.8795 42.1565 60.8795 42.529V46.4364C60.8795 46.8089 60.9895 47.1731 61.1957 47.4832C61.4019 47.7934 61.6952 48.0358 62.0387 48.1799V55.1665C62.9534 55.4858 63.8943 55.7243 64.8507 55.8793V58.6824L64.8558 58.6888C64.9093 58.7582 66.7862 61.1434 71.9318 61.1434C77.1534 61.1434 79.0132 58.6824 79.0132 58.6824V48.327H79.9302C80.431 48.3249 80.9107 48.125 81.2648 47.7709C81.6189 47.4168 81.8189 46.9372 81.8211 46.4364V42.529C81.8193 42.238 81.7497 41.9515 81.6178 41.6922V41.6922Z"
        fill="#EDC6A0"
      />
      <Path
        d="M67.1136 87.7917C67.0755 87.4798 67.0598 87.1656 67.0667 86.8514C64.89 87.2655 62.9111 88.3873 61.4378 90.0423C59.9646 91.6974 59.0795 93.7929 58.9203 96.0029H59.8579C60.0141 94.0328 60.8062 92.1668 62.1148 90.6859C63.4234 89.2049 65.1777 88.1892 67.1136 87.7917V87.7917Z"
        fill="#3A3B4D"
      />
      <Path
        d="M74.4574 89.4979C74.6489 89.6451 74.8334 89.7996 75.0126 89.9612L76.953 90.703C76.2126 89.7163 75.2979 88.8735 74.254 88.2162C74.3873 88.6305 74.4559 89.0628 74.4574 89.4979V89.4979Z"
        fill="#3A3B4D"
      />
      <Path
        d="M61.649 115.581H76.205L77.612 97.996H60.242L61.649 115.581Z"
        fill="#E6E7E8"
      />
      <Path
        d="M77.238 96.003H59.858H58.92H58.434V97.996H60.242H77.612H79.42V97.122L77.238 96.003Z"
        fill="white"
      />
      <Path
        d="M73.083 36.239V36.61H70.388V36.239H69.411V36.61H66.716V36.239H66.042V36.61H63.347V36.239H61.461H58.75V38.366H62.039H62.042H62.284H73.898H81.618H81.628H86.362V36.239H84.1H73.083Z"
        fill="#003B5C"
      />
      <Path
        d="M77.7657 23.4714C76.5242 23.4728 75.334 23.9665 74.4561 24.8443C73.5782 25.7221 73.0843 26.9123 73.0829 28.1538V36.2392H84.1V32.8255C84.101 30.966 83.6434 29.135 82.7677 27.4946C81.892 25.8542 80.6253 24.4551 79.0797 23.4212V23.4714H77.7657Z"
        fill="#2C5696"
      />
      <Path
        d="M61.4613 36.2392H63.347V28.1538C63.349 27.4921 63.4405 26.8337 63.6188 26.1965C62.206 28.1169 61.4494 30.4414 61.4613 32.8255L61.4613 36.2392Z"
        fill="#2C5696"
      />
      <Path
        d="M66.7157 28.1538C66.7152 26.9552 67.0089 25.7747 67.5709 24.716C67.0909 25.1511 66.7069 25.6816 66.4437 26.2736C66.1804 26.8656 66.0436 27.506 66.0421 28.1539V36.2393H66.7157V28.1538Z"
        fill="#2C5696"
      />
      <Path
        d="M70.3875 28.1538C70.3881 26.7725 70.7793 25.4196 71.516 24.2511C70.8697 24.6768 70.339 25.2561 69.9717 25.9372C69.6043 26.6183 69.4116 27.3799 69.4109 28.1538V36.2392H70.3876L70.3875 28.1538Z"
        fill="#2C5696"
      />
      <Path
        d="M72.0387 23.4715H70.725C69.5545 23.4745 68.4281 23.9189 67.5709 24.716C67.0089 25.7747 66.7152 26.9552 66.7157 28.1539V36.6097H69.4109V28.1538C69.4116 27.3799 69.6042 26.6183 69.9716 25.9372C70.339 25.2561 70.8696 24.6768 71.516 24.2511C72.4199 22.8069 73.7943 21.7185 75.4073 21.1696V20.7762H74.0936C73.398 20.7774 72.7061 20.8774 72.0387 21.0733V23.4715Z"
        fill="#003B5C"
      />
      <Path
        d="M63.347 28.1538V36.6096H66.0421V28.1538C66.0531 26.9153 66.55 25.7306 67.4259 24.8549C68.3018 23.9791 69.4865 23.4823 70.725 23.4715H72.0387V20.7762H70.725C68.7708 20.7844 66.899 21.5643 65.5172 22.9461C64.1353 24.3279 63.3553 26.1997 63.347 28.1538V28.1538Z"
        fill="#003B5C"
      />
      <Path
        d="M71.516 24.2511C70.7793 25.4196 70.3881 26.7725 70.3876 28.1538V36.6096H73.0829V28.1538C73.0844 26.9123 73.5782 25.7221 74.4561 24.8443C75.334 23.9665 76.5243 23.4728 77.7658 23.4715H79.0798V20.7762H77.7658C76.5152 20.7799 75.286 21.1006 74.1931 21.7083C73.1001 22.316 72.1791 23.1909 71.516 24.2511V24.2511Z"
        fill="#003B5C"
      />
      <Path
        opacity="0.22"
        d="M81.628 38.3657H81.6178V40.3007H81.628V38.3657Z"
        fill="#2E2E3B"
      />
      <Path
        d="M62.0506 40.3007C62.0767 39.6506 62.1546 39.0035 62.2835 38.3657H62.0417V40.3007H62.0506Z"
        fill="#45362C"
      />
      <Path
        opacity="0.22"
        d="M62.0506 40.3007C62.0767 39.6506 62.1546 39.0035 62.2835 38.3657H62.0417V40.3007H62.0506Z"
        fill="#2E2E3B"
      />
      <Path
        d="M73.8983 40.3007V38.3657H62.2835C62.1546 39.0035 62.0767 39.6506 62.0506 40.3007H73.8983Z"
        fill="#EDC6A0"
      />
      <Path
        opacity="0.22"
        d="M73.8983 40.3007V38.3657H62.2835C62.1546 39.0035 62.0767 39.6506 62.0506 40.3007H73.8983Z"
        fill="#2E2E3B"
      />
      <Path
        d="M81.6178 38.3657H73.8983V40.3007H81.6178V38.3657Z"
        fill="#45362C"
      />
      <Path
        opacity="0.22"
        d="M81.6178 38.3657H73.8983V40.3007H81.6178V38.3657Z"
        fill="#2E2E3B"
      />
      <Path
        d="M79.0133 58.68V58.6824C79.0133 58.6824 77.1534 61.1434 71.9319 61.1434C66.7862 61.1434 64.9093 58.7582 64.8559 58.6888L55.9218 62.9086L55.4357 63.1381L54.756 63.4592L53.662 65.7535L53.2929 66.5275L51.211 70.8932L44.8638 84.2042L39.898 80.88L39.8834 80.9022L36.995 85.2914L36.151 86.5739C36.151 86.5739 42.6205 92.4362 46.9911 95.0485L51.2802 88.5212L51.2901 88.5061L51.2897 88.5057C52.8317 86.0097 54.1876 83.625 55.3763 81.3835L56.6846 119.183L56.524 110.035L58.1499 75.5292L57.6505 62.0921L61.3874 60.3271L61.6869 74.9646H75.1986L81.5296 59.8244L79.0133 58.68Z"
        fill="#91A8C4"
      />
      <Path
        d="M90.9791 95.6613C89.742 93.7194 88.2689 91.4103 86.5184 88.6711C86.0348 87.9145 85.5345 87.1322 85.0079 86.3085L84.9767 86.2952L84.9869 86.2757L84.9767 86.2597L85.0047 86.2417C85.7747 84.7685 86.5062 83.3905 87.2066 82.0864C89.0416 78.6704 90.6439 75.8132 92.0306 73.4344C91.8619 72.5315 91.6913 71.6809 91.5221 70.8932C91.0138 68.3753 90.3458 65.8923 89.5221 63.4593L89.2662 63.3428L88.5502 63.0173L85.7643 61.7502L85.7647 61.7505L79.9107 75.7502L82.3577 92.7694L83.4046 93.1696L82.6859 95.0498L80.9705 99.5363C80.9705 99.5363 81.6973 99.8763 83.4903 100.646L85.3363 113.483L87.5826 113.523L87.3768 119.183L88.0175 102.544C88.3932 102.699 88.7833 102.859 89.1947 103.028C89.6841 103.228 90.2131 103.312 90.7404 103.274C91.2677 103.235 91.779 103.076 92.2345 102.808C92.69 102.539 93.0772 102.169 93.3661 101.726C93.6549 101.283 93.8375 100.78 93.8995 100.255C93.1153 99.0185 92.152 97.5026 90.9791 95.6613Z"
        fill="#91A8C4"
      />
      <Path
        d="M75.0125 89.9612L74.4415 89.7429C74.4228 90.1767 74.3372 90.6049 74.1878 91.0125L73.1258 93.7905L72.3339 93.4878L77.2379 96.0029L79.4195 97.1218L81.4881 98.1826L82.6856 95.0498L83.4046 93.1696L82.3578 92.7694L76.953 90.703L75.0125 89.9612Z"
        fill="#EDC6A0"
      />
      <Path
        d="M126.592 60.6966V58.9741C126.591 59.5332 126.528 60.0903 126.405 60.6356L126.592 60.6966Z"
        fill="#F6CA82"
      />
      <Path
        d="M99.7787 107.601V103.803L97.181 105.462C93.5047 114.189 99.6834 115.457 99.9432 115.507C99.8443 112.873 99.7782 110.235 99.7782 107.601H99.7787Z"
        fill="#FADFCA"
      />
      <Path
        d="M84.9767 86.2597L84.9869 86.2757L85.0047 86.2417L84.9767 86.2597Z"
        fill="#84C4E4"
      />
      <Path
        d="M114.638 191.283H119.689L119.689 191.284L118.54 167.818C115.966 182.387 114.551 191.272 114.549 191.283H114.638Z"
        fill="#DDC1AF"
      />
      <Path
        d="M102.933 146.302C104.463 156.488 106.333 165.738 108.031 173.157V146.597L102.933 146.302Z"
        fill="#DDC1AF"
      />
      <Path
        d="M119.689 191.283H114.638L113.052 197.576L120.102 197.564L119.859 193.869L119.689 191.284V191.283Z"
        fill="#D3D3D3"
      />
      <Path
        d="M108.031 146.597V191.283H114.549C114.551 191.272 115.966 182.387 118.54 167.818C119.328 163.363 120.227 158.375 121.224 152.945C121.574 151.029 121.961 149.199 122.353 147.425L117.532 147.147L108.031 146.597Z"
        fill="#FADFCA"
      />
      <Path
        d="M114.638 191.283H107.23C106.292 191.295 105.372 191.539 104.552 191.994C103.732 192.449 103.038 193.1 102.531 193.889C101.802 194.984 101.415 196.271 101.42 197.587H113.049L113.052 197.576L114.638 191.283Z"
        fill="white"
      />
      <Path
        d="M124.017 116.806L115.19 120.323L110.121 107.601H99.7782C99.7782 110.235 99.8443 112.873 99.9432 115.507C100.369 125.819 101.367 136.1 102.933 146.302L108.031 146.597L117.532 147.147L122.353 147.425C124.482 137.796 126.871 129.949 125.755 120.841L124.035 117.29L124.017 116.806Z"
        fill="#003B5C"
      />
      <Path
        d="M134.182 92.8182L129.541 94.6676L128.741 94.9864C129.266 95.3891 129.511 96.091 129.362 97.3567C128.315 106.258 127.746 112.371 127.486 115.424L133.846 112.889L141.038 110.023L134.182 92.8182Z"
        fill="black"
      />
      <Path
        d="M121.098 98.032L108.334 103.118L110.121 107.601L115.19 120.323L124.017 116.806L123.296 97.156L121.098 98.032Z"
        fill="black"
      />
      <Path
        d="M131.791 123.538C132.006 122.534 131.63 121.273 130.026 119.745C129.519 119.271 128.98 118.831 128.413 118.429C128.071 118.183 127.71 117.933 127.305 117.675L124.035 117.29L125.755 120.841L127.701 124.86L128.401 126.305L128.518 126.548C128.518 126.548 128.601 126.519 128.737 126.463C129.409 126.187 131.43 125.225 131.791 123.538Z"
        fill="#FADFCA"
      />
      <Path
        d="M129.369 70.8932C128.693 63.9788 127.623 61.0315 127.623 61.0315L126.592 60.6966L126.405 60.6356C126.15 61.7647 125.641 62.8209 124.917 63.7235C124.192 64.6262 123.272 65.3515 122.224 65.8443V69.7825C122.224 70.7912 122.026 71.7899 121.64 72.7218C121.254 73.6536 120.688 74.5003 119.975 75.2135C119.261 75.9268 118.415 76.4925 117.483 76.8785C116.551 77.2645 115.552 77.4631 114.544 77.4631H113.764V63.3629C113.764 62.1395 114.058 60.9339 114.621 59.8476C113.934 59.9707 113.237 60.0338 112.539 60.0361C108.906 60.0361 109.202 58.2605 109.202 58.2605V57.7571L109.196 57.7568L102.507 60.7132L101.47 61.1715L99.7786 61.9189V61.9383L99.7718 61.9274C99.7718 61.9274 99.7059 61.9857 99.5704 62.1295C99.3795 62.3318 99.0487 62.7057 98.5536 63.3428C96.7423 65.7641 95.0681 68.2848 93.5387 70.8932C93.0662 71.6746 92.5653 72.5172 92.0306 73.4343C90.6439 75.8132 89.0416 78.6704 87.2066 82.0863C86.5062 83.3905 85.7747 84.7685 85.0047 86.2417L84.9869 86.2757L84.9766 86.2952L85.0079 86.3084C85.5345 87.1321 86.0347 87.9144 86.5184 88.6711C88.2689 91.4102 89.742 93.7193 90.9791 95.6612C92.1519 97.5026 93.1153 99.0184 93.8995 100.255C96.5384 104.415 97.181 105.462 97.181 105.462L99.7786 103.803V107.601H110.121L108.334 103.118L121.098 98.0319C121.415 94.25 122.055 90.5021 123.01 86.8292L123.19 94.2812L123.296 97.1562L124.017 116.806L124.035 117.29L127.305 117.675C127.305 117.675 127.36 116.904 127.486 115.424C127.746 112.371 128.315 106.258 129.362 97.3567C129.511 96.091 129.266 95.3891 128.741 94.9864L129.541 94.6676C129.624 93.2862 129.694 91.9447 129.747 90.6593C130.075 84.0698 129.948 77.4654 129.369 70.8932V70.8932ZM99.7787 103.287L93.8551 89.8143L92.3461 86.3822L93.5133 84.4819L99.7787 74.2815L99.7787 103.287Z"
        fill="#84C4E4"
      />
      <Path
        d="M119.058 44.9963C112.912 39.4337 106.909 38.3854 104.221 38.2188V47.8763C104.162 49.3146 104.48 50.7433 105.144 52.0206C105.808 53.298 106.794 54.3797 108.004 55.1585C108.386 55.3667 108.787 55.5367 109.202 55.666V58.2605C109.202 58.2605 108.906 60.0361 112.539 60.0361C113.237 60.0338 113.934 59.9707 114.621 59.8476C115.546 58.0534 117.14 56.6949 119.058 56.065V49.3137H119.298C119.821 49.3128 120.33 49.1376 120.743 48.8156C121.156 48.4937 121.45 48.0433 121.579 47.5357C120.785 46.6447 119.943 45.797 119.058 44.9963Z"
        fill="#FADFCA"
      />
      <Path
        d="M114.671 26.107C112.437 26.0981 110.245 26.7167 108.345 27.8924C106.446 29.0681 104.914 30.7535 103.925 32.7569H103.41C102.554 32.7569 101.706 32.9255 100.916 33.253C100.125 33.5805 99.4065 34.0606 98.8014 34.6657C98.1962 35.2709 97.7162 35.9893 97.3887 36.78C97.0612 37.5706 96.8927 38.4181 96.8927 39.2739V44.2532C96.8858 49.5175 98.4278 54.6675 101.327 59.0619C101.7 59.6279 102.093 60.1783 102.507 60.7132L109.196 57.7568L109.202 57.7571V55.666C108.787 55.5367 108.386 55.3668 108.004 55.1586C106.794 54.3798 105.808 53.2981 105.144 52.0207C104.48 50.7433 104.162 49.3146 104.221 47.8763V38.2188C106.909 38.3854 112.911 39.4337 119.058 44.9963C119.943 45.797 120.785 46.6447 121.579 47.5357C121.45 48.0434 121.156 48.4937 120.743 48.8157C120.33 49.1376 119.821 49.3128 119.298 49.3137H119.058V56.065C117.52 56.57 116.181 57.5472 115.23 58.8574C114.28 60.1677 113.767 61.7443 113.764 63.363V77.4631H114.544C115.552 77.4631 116.551 77.2644 117.483 76.8785C118.415 76.4925 119.261 75.9267 119.975 75.2135C120.688 74.5003 121.254 73.6536 121.64 72.7217C122.026 71.7899 122.224 70.7911 122.224 69.7825V65.8443C123.528 65.2296 124.63 64.2578 125.403 63.0417C126.176 61.8257 126.589 60.4152 126.592 58.9741V37.791C126.561 34.6608 125.287 31.6715 123.051 29.4804C120.816 27.2893 117.801 26.0759 114.671 26.107V26.107Z"
        fill="#F6CA82"
      />
      <Path
        d="M67.1136 87.7917C67.2654 88.9685 67.7331 90.0822 68.4672 91.0144C69.2013 91.9466 70.1743 92.6625 71.2827 93.086L72.3338 93.4878L73.1257 93.7906L74.1877 91.0126C74.3372 90.605 74.4228 90.1767 74.4415 89.7429C74.4464 89.6612 74.4571 89.5793 74.4574 89.498C74.4517 88.6372 74.189 87.7978 73.7029 87.0874C73.2169 86.377 72.5296 85.828 71.7294 85.511L67.7311 83.9824L67.4931 84.6052C67.1151 85.6221 66.9851 86.7145 67.1137 87.7917L67.1136 87.7917Z"
        fill="#EDC6A0"
      />
    </Svg>
  );
};
