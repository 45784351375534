import { toast } from "@gigsmart/atorasu";
import {
  type ConfirmPromptOptions,
  confirmPrompt,
  dismiss
} from "@gigsmart/katana";
import {
  type PayloadError,
  graphql,
  useRelayFragment,
  useRelayMutation
} from "@gigsmart/relay";
import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import type {
  EngagementStateAction,
  useTransitionEngagementMutation
} from "./__generated__/useTransitionEngagementMutation.graphql";
import type { useTransitionEngagement_engagement$key } from "./__generated__/useTransitionEngagement_engagement.graphql";

interface UseTransitionEngagementOptions {
  action: EngagementStateAction;
  engagement?: useTransitionEngagement_engagement$key | null | undefined;
  confirmationMessage?: string;
  confirmationTitle?: string;
  confirmationYesLabel?: string;
  confirmationYesLabelStyle?: ConfirmPromptOptions["yesLabelStyle"];
  confirmationNoLabel?: string;
  confirmationNoLabelStyle?: ConfirmPromptOptions["cancelLabelStyle"];

  onSuccess: () => void;
  onError?: (
    errors: readonly PayloadError[],
    handleTransition: () => void
  ) => void;
}

const handleGenericError = (errors: readonly PayloadError[]) => {
  errors.forEach(({ message }) => toast.warning(message));
};

export default function useTransitionEngagement({
  action,
  engagement: engagementRef,
  onSuccess,
  confirmationMessage,
  confirmationTitle,
  confirmationYesLabel,
  confirmationYesLabelStyle,
  confirmationNoLabel,
  confirmationNoLabelStyle,
  onError = handleGenericError
}: UseTransitionEngagementOptions) {
  const [loading, setLoading] = useState(false);
  const engagement = useRelayFragment(
    graphql`
      fragment useTransitionEngagement_engagement on Engagement {
        id
        capabilities {
          category
          restrictedBy {
            message
          }
          status
          type
        }
      }
    `,
    engagementRef ?? null
  );
  const [commit] = useRelayMutation<useTransitionEngagementMutation>(graphql`
    mutation useTransitionEngagementMutation(
      $input: TransitionEngagementInput!
    ) {
      transitionEngagement(input: $input) {
        engagement {
          ...useTransitionEngagement_engagement
        }
      }
    }
  `);

  const transitionHandler = useCallback(() => {
    if (!engagement?.id) return;
    const handleTransition = () => {
      commit(
        { input: { engagementId: engagement.id, action } },
        {
          onSuccess,
          onPayloadErrors: onError
            ? (errors: readonly PayloadError[]) =>
                onError(errors, handleTransition)
            : handleGenericError
        }
      );
    };

    setLoading(true);
    if (confirmationMessage) {
      confirmPrompt({
        title: confirmationTitle ?? `${startCase(action.toLowerCase())} Gig`,
        subTitle: confirmationMessage,
        yesLabel: confirmationYesLabel ?? "Yes",
        yesLabelStyle: confirmationYesLabelStyle,
        cancelLabel: confirmationNoLabel ?? "No",
        cancelLabelStyle: confirmationNoLabelStyle,
        onDo: async () => {
          dismiss();
          handleTransition();
        },
        onCancel: () => {
          setLoading(false);
          dismiss();
        }
      });
    } else {
      handleTransition();
    }
  }, [
    engagement?.id,
    action,
    onSuccess,
    confirmationMessage,
    onError,
    confirmationNoLabel,
    confirmationNoLabelStyle,
    confirmationTitle,
    confirmationYesLabel,
    confirmationYesLabelStyle,
    commit
  ]);

  const capability = useMemo(
    () =>
      (engagement?.capabilities ?? []).find(
        ({ type, category }) => type === action && category === "TRANSITION"
      ),
    [action, engagement?.capabilities]
  );

  return [
    transitionHandler,
    {
      canPerform: capability && !capability?.restrictedBy,
      restriction: capability?.restrictedBy,
      loading
    }
  ] as const;
}
