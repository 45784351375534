import React, { type ReactNode } from "react";
import { Platform, View } from "react-native";
import { type ViewStyleProp, useStyles } from "../style";

interface Prop {
  testID?: string;
  children: ReactNode;
  style?: ViewStyleProp;
  contentStyle?: ViewStyleProp;
  withMarginBottom?: boolean;
}

/** @deprecated use Constraint or ContentArea instead. */
export default function BackgroundCard({
  children,
  style,
  contentStyle,
  withMarginBottom,
  ...props
}: Prop) {
  const { styles } = useStyles(({ color, shadow, unit, units }) => ({
    container: {
      alignItems: "center",
      "@phone": {
        alignItems: "flex-start",
        justifyContent: "flex-start"
      }
    },
    content: {
      backgroundColor: color.white,
      borderRadius: unit.borderRadius,
      width: "60%",
      ...Platform.select({ web: { ...shadow() } }),
      "@phone": {
        width: "100%"
      }
    },
    withMarginBottom: {
      marginBottom: units(3)
    }
  }));
  return (
    <View
      style={[
        styles.container,
        style,
        withMarginBottom && styles.withMarginBottom
      ]}
      {...props}
    >
      <View style={[styles.content, contentStyle]}>{children}</View>
    </View>
  );
}
