import { Button, Column, ContentArea, Stack, Surface } from "@gigsmart/atorasu";
import { Validator, useFormSubmit, useFormValue } from "@gigsmart/fomu";
import React from "react";
import FomuCommentInput from "../fomu/inputs/FomuCommentInput";
import FomuRatingStarsInput from "../fomu/inputs/FomuRatingStarsInput";

interface Props {
  displayName: string;
}

const validatesReview = Validator.create<{ minRequired: number }, string>(
  ({ minRequired = 3 }) =>
    (_fieldName, value, fieldMap) => {
      const hasValue = value && value.trim().length > 0;
      const ratingValue = fieldMap ? fieldMap.get("rating") || 0 : 0;

      return ratingValue > minRequired || !!hasValue
        ? null
        : [new Error("Comment cannot be empty")];
    }
);

const placeholderText =
  "How was it working for this Requester?\nExample: This Requester was a pleasure to work with and provided the guidance needed to complete the Project.";

const RateAndReview = ({ displayName }: Props) => {
  const { submit, invalid } = useFormSubmit();
  const { value: rating } = useFormValue<number>({ name: "rating" });
  const commentRequired = rating && rating < 4;

  return (
    <Stack grow={1}>
      <Surface>
        <ContentArea>
          <Stack>
            <FomuRatingStarsInput name="rating" displayName={displayName} />
            <FomuCommentInput
              label={`Leave a comment${commentRequired ? " (required)" : ""}`}
              name="review"
              displayAdditionalText={false}
              placeholder={placeholderText}
              validates={[validatesReview()]}
              shouldShowErrors={({ values }) => !!values.get("rating")}
            />
          </Stack>
        </ContentArea>
      </Surface>
      <Column justifyContent="flex-end" grow={1}>
        <ContentArea size="none">
          <Button
            testID="rate-review-submit"
            onPress={submit}
            disabled={invalid}
            label="Submit Review"
          />
        </ContentArea>
      </Column>
    </Stack>
  );
};

export default RateAndReview;
