import React from "react";
import { View } from "react-native";

import { createStyles } from "../../style";

const OUTER_DIAMETER = 23;
const INNER_DIAMETER = 11;

const styles = createStyles(({ color }) => ({
  dot: {
    borderRadius: OUTER_DIAMETER / 2,
    borderWidth: (OUTER_DIAMETER - INNER_DIAMETER) / 2,
    height: OUTER_DIAMETER,
    width: OUTER_DIAMETER,
    zIndex: 2,
    backgroundColor: color.darken(color.neutralDark, 0.3),
    borderColor: color.neutralDark
  },
  dotActive: {
    backgroundColor: color.blueTint,
    borderColor: color.blue
  }
}));

interface Props {
  disabled?: boolean;
}

export default ({ disabled }: Props) => {
  return <View style={[styles.dot, !disabled && styles.dotActive]} />;
};
