import type { notificationRow_message$data } from "./__generated__/notificationRow_message.graphql";
import * as Notifications from "./notifications";
export { default as NotificationCenter } from "./notification-center";
export { default as NotificationSectionList } from "./notification-list";
export { default as NotificationIcon } from "./notification-icon";
export { Notifications };

export type NotificationEntry = Omit<
  notificationRow_message$data,
  " $fragmentType"
>;
