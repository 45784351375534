/**
 * @generated SignedSource<<8808aa1a065c2c666fd943d73952f930>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerThumbnail_worker$data = {
  readonly displayName: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerAvatar_worker">;
  readonly " $fragmentType": "WorkerThumbnail_worker";
};
export type WorkerThumbnail_worker$key = {
  readonly " $data"?: WorkerThumbnail_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerThumbnail_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerThumbnail_worker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerAvatar_worker"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "ffb49a072994335612947e0cb7548ca6";

export default node;
