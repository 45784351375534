import React, { useState, type ReactNode } from "react";
import { TouchableOpacity } from "react-native";
import Collapsible from "react-native-collapsible";
import Divider from "../container/divider";
import Stack from "../container/stack";

import { StyledIcon } from "../icon";
import { theme } from "../style";

interface Props {
  startCollapsed?: boolean;
  withDivider?: boolean;
  collapsedContent: ReactNode;
  children: ReactNode;
}

export default function CollapsibleRow({
  startCollapsed = true,
  collapsedContent,
  children,
  withDivider = false
}: Props) {
  const [collapsed, setCollapsed] = useState(startCollapsed);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        fill={1}
      >
        {children}
        <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
          <StyledIcon
            name={collapsed ? "chevron-down" : "chevron-up"}
            variant="solid"
            size={theme.font.size.large}
          />
        </TouchableOpacity>
      </Stack>
      <Collapsible collapsed={collapsed}>
        {withDivider && <Divider marginTop={12} marginBottom={12} />}
        {collapsedContent}
      </Collapsible>
    </>
  );
}
