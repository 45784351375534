import {
  ActionRow,
  Button,
  ContentArea,
  Divider,
  IconCircle,
  IconText,
  Image,
  Row,
  SectionHeader,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionEdges,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import type { ShiftGigSection_worker$key } from "./__generated__/ShiftGigSection_worker.graphql";

export const ShiftGigSection = ({
  availableGigSeries
}: FragmentContainerInnerComponentProps<ShiftGigSection_worker$key>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const isPositionsEnabled = PositionsAndLocations.isEnabled();
  if ((availableGigSeries?.totalCount ?? 0) === 0) return null;

  const seriesList = getConnectionNodes(availableGigSeries);
  if (!seriesList.length) return null;

  return (
    <>
      <ContentArea size="compact">
        <SectionHeader
          left={
            <IconText
              icon="business-time"
              textVariant="subheader"
              color="black"
            >
              {isPositionsEnabled ? "Shifts" : "Shift Gigs"}
            </IconText>
          }
          variant="none"
          right={
            <Button
              testID="view-all-shift-gigs-btn"
              color="primary"
              icon="chevron-right"
              iconPlacement="right"
              label={`View All ${isPositionsEnabled ? "Shifts" : "Shift Gigs"}`}
              onPress={() =>
                navigation.push("BrowseShifts", { type: "shifts" })
              }
              variant="clear"
              size="small"
            />
          }
        />
        <Spacer size="compact" />
        <Stack size="compact">
          {seriesList.map(({ id, boosted, availableGigs }, index) => {
            const gig = getConnectionEdges(availableGigs)?.[0] ?? null;
            if (!gig) return null;

            return (
              <ActionRow
                testID={`available-series-${index}`}
                key={`available-series-${index}`}
                leftSpacing="small"
                onPress={() => navigation.push("BrowseShiftDetails", { id })}
                eventEntityType="WorkerHomeAvailableShiftGig"
                pressableIconColor="primary"
                title={
                  <Stack horizontal alignItems="center" size="slim" fill={1}>
                    {boosted && (
                      <IconCircle
                        color="emphasized"
                        icon="chevrons-up"
                        size="micro"
                      />
                    )}
                    <Row fill>
                      <Text color="black" variant="subheader" numberOfLines={1}>
                        {gig?.node?.name}
                      </Text>
                    </Row>
                  </Stack>
                }
                {...(isPositionsEnabled && gig.node?.organization?.logoUrl
                  ? {
                      left: (
                        <Image
                          height={36}
                          variant="square"
                          source={{
                            uri: gig.node?.organization?.logoUrl ?? ""
                          }}
                        />
                      )
                    }
                  : { icon: "business-time" })}
                color="warning"
                iconSquareVariant="standard"
                note={
                  <Text variant="note" color="neutral">
                    {currency.humanize(gig?.estimatedPayment?.netPay)} •{" "}
                    {pluralize(availableGigs?.totalCount ?? 0, "Shift")}
                  </Text>
                }
              />
            );
          })}
        </Stack>
        <Spacer size="compact" />
      </ContentArea>
      <Divider />
    </>
  );
};

export default createRelayFragmentContainer<ShiftGigSection_worker$key>(
  graphql`
    fragment ShiftGigSection_worker on Worker
    @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableGigSeries(
        first: 3
        input: { gigTypes: [PAID, VOLUNTEER], maxDistance: $maxDistance }
      ) {
        edges {
          node {
            id
            boosted
            availableGigs(first: 1) {
              edges {
                estimatedPayment {
                  payRate
                  netPay
                }
                node {
                  name
                  organization {
                    logoUrl
                  }
                }
              }
              totalCount
            }
          }
        }
        totalCount
      }
    }
  `,
  ShiftGigSection
);
