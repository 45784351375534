import React from "react";

import { FlexRow } from "../../flex";
import { type TextStyleProp, type ViewStyleProp, useStyles } from "../../style";
import { Tag } from "../../tag";
import { StyledText } from "../../text";
import KatanaTimesheetEntry from "./katana-timesheet-entry";

interface Props {
  style?: ViewStyleProp;
  labelStyle?: TextStyleProp;
  timeStampStyle?: TextStyleProp;
  label: string;
  barHidden?: boolean;
  barActive?: boolean;
  timeStamp: string;
  disabled?: boolean;
  adjusted?: boolean;
  added?: boolean;
}

const TimesheetRow = ({
  style,
  labelStyle,
  timeStampStyle,
  label,
  barHidden,
  barActive,
  timeStamp,
  disabled,
  adjusted,
  added
}: Props) => {
  const { styles } = useStyles(({ font, color, units }) => ({
    label: {
      ...font.body("bold"),
      fontSize: font.size.medium,
      paddingTop: units(1),
      marginLeft: units(2),
      marginRight: units(1.5)
    },
    timeStamp: {
      fontSize: font.size.medium,
      lineHeight: font.size.extraLarge,
      paddingTop: units(1),
      marginLeft: "auto"
    },
    disabled: {
      color: color.neutralDark
    },
    tag: {
      alignSelf: "flex-start",
      marginTop: units(0.25)
    }
  }));

  return (
    <FlexRow style={style}>
      <KatanaTimesheetEntry
        barHidden={barHidden}
        barActive={barActive}
        disabled={!!disabled}
      />
      <StyledText
        style={[styles.label, labelStyle, disabled && styles.disabled]}
      >
        {label}
      </StyledText>
      {adjusted && <Tag title="Adjusted" style={styles.tag} />}
      {added && <Tag title="Added" style={styles.tag} />}
      <StyledText
        style={[styles.timeStamp, timeStampStyle, disabled && styles.disabled]}
      >
        {timeStamp}
      </StyledText>
    </FlexRow>
  );
};

export default TimesheetRow;
