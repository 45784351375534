import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import React, { Component } from "react";
import { TouchableOpacity } from "react-native";
import { type IconName, type IconVariant, StyledIcon } from "../icon";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import StyledText from "../text/styled-text";
import Hoverable from "./hoverable";

export interface LargeButtonProps {
  readonly disabled?: boolean;
  readonly title?: string;
  readonly icon?: IconName | null;
  readonly iconVariant?: IconVariant | null;
  readonly iconPosition?: "left" | "right";
  readonly testID?: string;
  readonly onPress?: () => unknown;
  readonly fill?: boolean;
  readonly flex?: boolean;
  readonly compact?: boolean;
  readonly onPressDisabled?: () => void;
  readonly maxWidth?: number;
  readonly numberOfLines?: number;
}

type Props = StylesProps &
  EventerProps<"pressed"> &
  LargeButtonProps & {
    readonly textStyles: (disabled?: boolean) => ViewStyleProp;
    readonly disabledStyles?: () => ViewStyleProp;
    readonly buttonStyles?: (b: boolean) => ViewStyleProp;
  };

@withStyles(({ units }) => ({
  buttonContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    minHeight: units(12),
    paddingVertical: units(2),
    paddingHorizontal: units(4),
    borderRadius: units(1),
    maxWidth: 400,
    width: "100%",
    alignSelf: "center"
  },
  flex: { flex: 1 },
  fill: {
    flex: 1,
    width: undefined,
    maxWidth: undefined,
    alignSelf: undefined
  },
  text: {
    marginHorizontal: units(1),
    textAlign: "center"
  },
  containerCompact: {
    paddingHorizontal: units(2)
  }
}))
@withEventers<"pressed", Props>("Button", ["pressed"], "title")
export default class StyledLargeButton extends Component<Props> {
  static defaultProps: Partial<Props> = {
    iconPosition: "left",
    eventers: eventerStubs<"pressed">(["pressed"]),
    ...stylesStubs
  };

  renderIcon = () => {
    const { icon, disabled, textStyles, theme, styles } = this.props;

    if (typeof icon === "undefined" || icon === null) return null;

    return (
      <StyledIcon
        style={[styles.text, textStyles(disabled)]}
        name={icon}
        size={theme.font.size.large}
        variant={this.props.iconVariant ?? "solid"}
      />
    );
  };

  handlePress = () => {
    const { disabled, onPress, onPressDisabled } = this.props;
    this.props.eventers.pressed();

    if (disabled) {
      if (onPressDisabled) onPressDisabled();
      return;
    }
    if (onPress) onPress();
  };

  render() {
    const {
      styles,
      disabled,
      testID,
      title,
      iconPosition,
      buttonStyles,
      disabledStyles,
      textStyles,
      fill,
      flex,
      compact,
      maxWidth,
      numberOfLines
    } = this.props;

    return (
      <Hoverable>
        {(hover) => (
          <TouchableOpacity
            testID={testID ?? "styled-large-button"}
            style={[
              styles.buttonContainer,
              fill && styles.fill,
              flex && styles.flex,
              compact && styles.containerCompact,
              buttonStyles?.(hover),
              disabled && disabledStyles && disabledStyles(),
              maxWidth ? { maxWidth } : null
            ]}
            onPress={this.handlePress}
          >
            {iconPosition === "left" && this.renderIcon()}
            <StyledText
              bold
              style={[styles.text, textStyles(disabled)]}
              numberOfLines={numberOfLines}
            >
              {title}
            </StyledText>
            {iconPosition === "right" && this.renderIcon()}
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  }
}
