/**
 * @generated SignedSource<<2679372e36d657e586cfcf70d726f60d>>
 * @relayHash 17b2845eb40056871820ebf8c1487b7a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:3ZSOgKRk05QIIdJJsKSTycgCoS8H9fIMfGJ_Isq4npU

import { ConcreteRequest } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigPaySchedule = "FIXED" | "HOURLY" | "INFO_REQUIRED" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type AddEngagementsInput = {
  applicationQuestions?: ReadonlyArray<EngagementQuestionInput> | null | undefined;
  gigIds: ReadonlyArray<string>;
  initialState?: EngagementStateName | null | undefined;
  payRate?: string | null | undefined;
  paySchedule?: GigPaySchedule | null | undefined;
  workerId?: string | null | undefined;
};
export type EngagementQuestionInput = {
  answer: string;
  question: string;
};
export type useEngageToGigAddEngagementsMutation$variables = {
  input: AddEngagementsInput;
};
export type useEngageToGigAddEngagementsMutation$data = {
  readonly addEngagements: {
    readonly newEngagementEdges: ReadonlyArray<{
      readonly node: {
        readonly currentState: {
          readonly name: EngagementStateName;
        };
        readonly gigType: GigType;
        readonly id: string;
      } | null | undefined;
    }>;
  } | null | undefined;
};
export type useEngageToGigAddEngagementsMutation = {
  response: useEngageToGigAddEngagementsMutation$data;
  variables: useEngageToGigAddEngagementsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEngageToGigAddEngagementsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEngagementsPayload",
        "kind": "LinkedField",
        "name": "addEngagements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementsEdge",
            "kind": "LinkedField",
            "name": "newEngagementEdges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Engagement",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEngageToGigAddEngagementsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEngagementsPayload",
        "kind": "LinkedField",
        "name": "addEngagements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementsEdge",
            "kind": "LinkedField",
            "name": "newEngagementEdges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Engagement",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:3ZSOgKRk05QIIdJJsKSTycgCoS8H9fIMfGJ_Isq4npU",
    "metadata": {},
    "name": "useEngageToGigAddEngagementsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9a726425b99ca08f705d55a884993977";

export default node;
