/**
 * @generated SignedSource<<fd96854186d70acbb9a3a2596023b576>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CancellationReasonType = "CANCELED" | "DID_NOT_WORK" | "ISSUE_WITH_WORKER" | "NO_LONGER_AVAILABLE" | "RECEIVED_ANOTHER_OFFER" | "REPORT_NO_SHOW" | "REQUESTER_UNRESPONSIVE" | "UNABLE_TO_FINALIZE" | "UNSPECIFIED" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerShiftStatusText_shift$data = {
  readonly cancellationReason: {
    readonly authoredBy: UserType;
    readonly disqualifications: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly gigFieldItemDefinition: {
            readonly gigField: {
              readonly iconName: string;
              readonly id: string;
              readonly title: string;
            };
            readonly label: string;
          };
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly reasonType: CancellationReasonType;
  } | null | undefined;
  readonly currentState: {
    readonly action: EngagementStateAction;
    readonly name: EngagementStateName;
    readonly transitionedBy: {
      readonly __typename: string;
    } | null | undefined;
  };
  readonly systemTimesheet: {
    readonly scheduledCount: {
      readonly totalCount: number;
    } | null | undefined;
    readonly startedCount: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly timesheet: {
    readonly paid: boolean | null | undefined;
  } | null | undefined;
  readonly workerTimesheet: {
    readonly editable: boolean;
  } | null | undefined;
  readonly " $fragmentType": "WorkerShiftStatusText_shift";
};
export type WorkerShiftStatusText_shift$key = {
  readonly " $data"?: WorkerShiftStatusText_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftStatusText_shift">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerShiftStatusText_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "transitionedBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "FINAL"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paid",
          "storageKey": null
        }
      ],
      "storageKey": "timesheet(variant:\"FINAL\")"
    },
    {
      "alias": "workerTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "WORKER"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editable",
          "storageKey": null
        }
      ],
      "storageKey": "timesheet(variant:\"WORKER\")"
    },
    {
      "alias": "systemTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "SYSTEM"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": "scheduledCount",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE name = SCHEDULED"
            }
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "states(first:0,query:\"WHERE name = SCHEDULED\")"
        },
        {
          "alias": "startedCount",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE action = START"
            }
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "states(first:0,query:\"WHERE action = START\")"
        }
      ],
      "storageKey": "timesheet(variant:\"SYSTEM\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CancellationReason",
      "kind": "LinkedField",
      "name": "cancellationReason",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authoredBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reasonType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            }
          ],
          "concreteType": "CancellationReasonDisqualificationsConnection",
          "kind": "LinkedField",
          "name": "disqualifications",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CancellationReasonDisqualificationsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CancellationReasonDisqualification",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GigFieldItemDefinition",
                      "kind": "LinkedField",
                      "name": "gigFieldItemDefinition",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GigField",
                          "kind": "LinkedField",
                          "name": "gigField",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "iconName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "disqualifications(first:50)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "5a5a90e7d19d74e2661398faf2c89d42";

export default node;
