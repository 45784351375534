import { toast } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { allSettled } from "@gigsmart/isomorphic-shared/app/promises";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  ConnectionHandler,
  type RelayMutationUpdater,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { Duration } from "luxon";
import { useCallback, useState } from "react";
import { useNegotiateEngagement } from "../../../engagement/EngagementExtras";
import { showWorkerHiredModal } from "../../../worker/WorkerHiredModal";
import { showApplySuccessModal } from "../ApplySuccessModal";
import type {
  AddEngagementsInput,
  useEngageToGigAddEngagementsMutation
} from "./__generated__/useEngageToGigAddEngagementsMutation.graphql";
import type {
  EngagementQuestionInput,
  useEngageToGigMutation
} from "./__generated__/useEngageToGigMutation.graphql";
import type { useEngageToGigTransitionMutation } from "./__generated__/useEngageToGigTransitionMutation.graphql";

interface EngageToGigOptions {
  gigId: string;
  engagementId?: string;
  action: "APPLY" | "ACCEPT" | "PICK_UP" | "BID";
  isProject?: boolean;
  isSeries?: boolean;
}

// Data from engage-gig-stepper
export interface EngageToGigParams {
  questions?: EngagementQuestionInput[];
  toAccept?: string[];
  toReject?: string[];
  paySchedule?: "HOURLY" | "FIXED" | "INFO_REQUIRED";
  payRate?: string;
  expectedDuration?: string;
  note?: string;
  estimatedAmount?: string;
  hourlyBid?: string;
  isSingleBid?: boolean;
}

export function useEngageToGig({
  gigId,
  engagementId,
  action,
  isSeries,
  isProject
}: EngageToGigOptions) {
  const history = useHistory();
  const [addEngagement] = useRelayMutationPromise<useEngageToGigMutation>(
    graphql`
      mutation useEngageToGigMutation($input: AddEngagementInput!) {
        addEngagement(input: $input) {
          newEngagementEdge {
            node {
              id
              gigType
              currentState {
                name
              }
            }
          }
        }
      }
    `
  );

  const [addEngagements] =
    useRelayMutationPromise<useEngageToGigAddEngagementsMutation>(
      graphql`
        mutation useEngageToGigAddEngagementsMutation(
          $input: AddEngagementsInput!
        ) {
          addEngagements(input: $input) {
            newEngagementEdges {
              node {
                id
                gigType
                currentState {
                  name
                }
              }
            }
          }
        }
      `
    );

  const [transitionEngagements] =
    useRelayMutationPromise<useEngageToGigTransitionMutation>(graphql`
      mutation useEngageToGigTransitionMutation(
        $input: TransitionEngagementsInput!
      ) {
        transitionEngagements(input: $input) {
          engagements {
            id
            currentState {
              name
            }
          }
          errors {
            message
          }
        }
      }
    `);

  const [confirmedApplication, setConfirmedApplication] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const negotiateEngagement = useNegotiateEngagement();

  const handleAcceptEngagements = useCallback(
    async (toAccept?: string[], toReject?: string[]) => {
      if (!toAccept?.length && engagementId) toAccept = [engagementId];

      if (toReject?.length) {
        await transitionEngagements({
          input: { action: "REJECT", engagementIds: toReject }
        });
      }

      if (toAccept?.length) {
        const res = await transitionEngagements({
          input: { action: "ACCEPT", engagementIds: toAccept }
        });

        const shiftCount = res.transitionEngagements?.engagements?.length;
        const next = res.transitionEngagements?.engagements?.[0];
        if (next) {
          showWorkerHiredModal({ shiftCount, engagementId: next.id });
        }
      }
    },
    [engagementId]
  );

  const handleAddEngagements = useCallback(
    async (input: AddEngagementsInput) => {
      await addEngagements({ input }, { updater });
    },
    []
  );

  const handleAddEngagementsBatch = useCallback(
    async (
      toAccept: string[],
      applicationQuestions: EngagementQuestionInput[],
      hourlyBid: Array<{ id: string; value: string }>
    ) => {
      const result = await allSettled(
        toAccept.map(async (id) => {
          const payRate = hourlyBid.find((it) => it.id === id)?.value;
          if (!payRate) {
            return await Promise.reject(new Error("Invalid pay rate"));
          }

          return await addEngagement(
            { input: { gigId: id, applicationQuestions, payRate } },
            { updater }
          );
        })
      );
      const allErrors = result.every((it) => it.status === "rejected");
      if (allErrors) throw (result[0] as PromiseRejectedResult).reason;
    },
    []
  );

  const handleNegotiate = useCallback(
    async (engagementId?: string, payRate?: string) => {
      if (!engagementId || !payRate) throw new Error("Invalid pay rate");

      await negotiateEngagement({ engagementId, payRate });
      toast.success("Bid Submitted");
      history.push("/shifts?shiftsTab=bidSubmitted");
    },
    []
  );

  const engageToGig = useCallback(
    async ({
      toAccept = [],
      toReject = [],
      questions = [],
      paySchedule,
      estimatedAmount,
      payRate,
      expectedDuration,
      note,
      hourlyBid
    }: EngageToGigParams) => {
      /**
       * 1. Engagement Accept flow (accepting offer)
       *    except for the case of a single bid, where we negotiate the engagement
       *
       * 2. GigSeries Apply flow (applying to series)
       * 2.1 Running addEngagement in batch mode (multiple mutations) when hourly bids vary
       * 2.2 Running addEngagements (single mutation) when the hourly bid is the same for all gigs
       *
       * 3. Single Bid flow (negotiating the engagement)
       *
       * 4. Single Apply flow for PROJECT GIGS
       */

      setIsLoading(true);
      try {
        if (action === "ACCEPT") {
          /// 1. -> worker hired modal
          await handleAcceptEngagements(toAccept, toReject);
        } else if (action === "BID") {
          /// 3. -> Bid Submitted toast + Routing
          await handleNegotiate(
            toAccept?.[0],
            Array.isArray(hourlyBid) ? hourlyBid[0]?.value : hourlyBid
          );
        } else if (isSeries && !isProject) {
          /// 2. -> Apply Success Modal
          if (!toAccept?.length) toAccept = [gigId];
          const shouldRunBatch =
            HourlyRateBids.isEnabled() && Array.isArray(hourlyBid);

          if (shouldRunBatch) {
            // 2.1
            await handleAddEngagementsBatch(toAccept, questions, hourlyBid);
          } else {
            await handleAddEngagements({
              gigIds: toAccept,
              applicationQuestions: questions,
              payRate: HourlyRateBids.select(hourlyBid)
            });
          }

          showApplySuccessModal();
        } else {
          const pay =
            paySchedule === "FIXED"
              ? currency.toISOString(estimatedAmount ?? "")
              : paySchedule === "HOURLY" || !paySchedule
                ? currency.toISOString(
                    payRate ??
                      (Array.isArray(hourlyBid)
                        ? hourlyBid?.[0]?.value
                        : hourlyBid) ??
                      ""
                  )
                : undefined;

          await addEngagement(
            {
              input: {
                applicationQuestions: questions ?? [],
                paySchedule,
                payRate: pay,
                expectedDuration: expectedDuration
                  ? Duration.fromObject({ hours: +expectedDuration }).toISO()
                  : undefined,
                note,
                gigId
              }
            },
            { updater }
          );

          showApplySuccessModal({ isProject });
        }
        setConfirmedApplication(true);
      } catch (err: any) {
        toast.error(err.message);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [
      action,
      addEngagement,
      addEngagements,
      engagementId,
      gigId,
      isProject,
      isSeries,
      transitionEngagements
    ]
  );
  return { engageToGig, confirmedApplication, isLoading };
}

const updater: RelayMutationUpdater<
  useEngageToGigMutation | useEngageToGigAddEngagementsMutation
> = (store, data) => {
  const root = store.getRoot();
  const viewer = root.getLinkedRecord("viewer");

  const gigType =
    data && "addEngagements" in data
      ? data?.addEngagements?.newEngagementEdges?.[0]?.node?.gigType
      : data?.addEngagement?.newEngagementEdge?.node?.gigType;

  if (!viewer) return;
  const appliedConnection = ConnectionHandler.getConnection(
    viewer,
    "myGigSeriesTabs_applied"
  );
  if (appliedConnection) {
    const totalCount = Number(appliedConnection.getValue("totalCount")) ?? 0;
    appliedConnection.setValue(totalCount + 1, "totalCount");
  }
  if (gigType === "PROJECT") {
    const projectConnection = ConnectionHandler.getConnection(
      viewer,
      "myGigSeriesTabs_projectApplied"
    );
    if (projectConnection) {
      const projectCount =
        Number(projectConnection.getValue("totalCount")) ?? 0;
      projectConnection.setValue(projectCount + 1, "totalCount");
    }
  } else {
    const shiftConnection = ConnectionHandler.getConnection(
      viewer,
      "myGigSeriesTabs_shiftApplied"
    );
    if (shiftConnection) {
      const shiftCount = Number(shiftConnection.getValue("totalCount")) ?? 0;
      shiftConnection.setValue(shiftCount + 1, "totalCount");
    }
  }
};
