import { Column } from "@gigsmart/atorasu";
import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { NavPortalEntrance } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useEffect, useState } from "react";
import { useGeolocation } from "../../geolocation";
import JobBoardHelpIcon from "../../job/job-board-help-icon";
import {
  HIDE_PROJECT_GIG_EDUCATION_CARD,
  useUserValue
} from "../../user-value";
import BrowseGigsSearch, {
  type SearchFilter
} from "../browse-screen-base/search";
import useSearchDistance from "../use-search-distance";
import type { projectGigsScreenQuery } from "./__generated__/projectGigsScreenQuery.graphql";
import ProjectGigsList from "./project-gigs-list";

export const initialFilters: SearchFilter = {
  searchTerm: "",
  location: { label: "", lat: 0, lng: 0 },
  radiusMiles: UnlimitedDistanceFeature.isEnabled() ? 12425 : 75,
  gigTypes: ["PROJECT"]
};

export default createSuspendedQueryContainer<projectGigsScreenQuery>(
  function ProjectGigsScreen({ response: result }) {
    const [hideProjectGigEducationCard, setHideProjectGigEducationCard] =
      useUserValue<boolean>(HIDE_PROJECT_GIG_EDUCATION_CARD);

    const { distance, persistDistance, hasFetchedDistance } = useSearchDistance(
      UnlimitedDistanceFeature.isEnabled() ? 12425 : 75
    );
    const [filter, setFilter] = useState<SearchFilter>({
      ...initialFilters,
      searchTerm: (initialFilters?.searchTerm as string) ?? "",
      radiusMiles: (initialFilters?.maxDistance as number) ?? 75,
      gigTypes: ["PROJECT"]
    });

    const currentLocation = useGeolocation();

    useEffect(() => {
      const coords = currentLocation;
      const area = currentLocation?.area;
      const postalCode = currentLocation?.postalCode;
      const location = {
        ...initialFilters.location,
        label: postalCode && area ? `${postalCode}, ${area}` : "",
        ...coords
      };
      setFilter({
        ...filter,
        radiusMiles: distance ?? 75,
        location
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distance, currentLocation]);

    if (!hasFetchedDistance) return null;

    return (
      <Column fill>
        <NavPortalEntrance rightAccessory={() => <JobBoardHelpIcon />} />
        <BrowseGigsSearch
          isProject
          filter={filter}
          onChange={(filter) => {
            persistDistance(filter.radiusMiles);
            initialFilters.radiusMiles = filter.radiusMiles;
            setFilter(filter);
          }}
        />
        <ProjectGigsList
          viewerRef={result?.viewer ?? null}
          filter={{ ...filter, radiusMiles: distance as number }}
          showProjectGigEducationCard={!hideProjectGigEducationCard}
          setHideProjectGigEducationCard={setHideProjectGigEducationCard}
        />
      </Column>
    );
  },
  {
    query: graphql`
      query projectGigsScreenQuery(
        $maxDistance: Int!
        $crisisId: ID
        $searchTerm: String
        $gigTypes: [GigType!]
      ) {
        viewer {
          ... on Worker {
            ...projectGigsList_availableProjects
              @arguments(
                maxDistance: $maxDistance
                crisisId: $crisisId
                searchTerm: $searchTerm
                gigTypes: $gigTypes
              )
          }
        }
      }
    `,
    variables: () => {
      const filter = {
        ...initialFilters,
        searchTerm: (initialFilters?.searchTerm as string) ?? "",
        radiusMiles: (initialFilters?.maxDistance as number) ?? 75,
        gigTypes: ["PROJECT"]
      } as SearchFilter;
      return {
        crisisId: filter.crisisId,
        maxDistance: filter.radiusMiles ?? 75,
        searchTerm: filter.searchTerm,
        gigTypes: filter.gigTypes
      };
    }
  }
);
