import { Column, ContentArea, ListEmpty, Spacer } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  userType: "participating" | "organization" | "worker";
  activeTab: "active" | "inactive";
}

export default function MessageCenterEmptyState({
  userType,
  activeTab
}: Props) {
  return (
    <ContentArea fill testID="message-center-empty-state">
      <Column fill>
        <Spacer size="large" />
        <ListEmpty
          icon="comment-exclamation"
          variant="flat"
          color="primary"
          constraint="small"
          title="No Messages"
          subtitle={getCopy(userType, activeTab)}
        />
      </Column>
    </ContentArea>
  );
}

function getCopy(
  userType: Props["userType"],
  activeTab: "active" | "inactive"
) {
  switch (userType) {
    case "participating":
      return activeTab === "active"
        ? "Active Message Conversations you are participating in will appear here."
        : "Inactive Message Conversations you were participating in will appear here.";
    case "organization":
      return activeTab === "active"
        ? "Active Message Conversations you are not participating in will appear here."
        : "Inactive Message Conversations you were not participating in will appear here.";
    case "worker":
      return activeTab === "active"
        ? "Active Message Conversations will appear here."
        : "Inactive Message Conversations will appear here.";
  }
}
