import {
  Button,
  Column,
  Constraint,
  ContentArea,
  Divider,
  FooterSpacer,
  Spacer,
  Stack,
  StepIndicator,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import React from "react";
import type { Step } from "./ResponsiveStepper";
import { ResponsiveStepperBtnPortal, ResponsiveStepperPortal } from "./portal";

interface Props {
  showStepIndicator: boolean;
}

export default function HStepLayout<TStep extends Step>({
  showStepIndicator
}: Props) {
  const { activeStep, stepsData } = useStepper<TStep, {}>();
  if (!activeStep) return null;

  const {
    stepTitle,
    stepDescription,
    buttonShouldAvoidKeyboard,
    component: content,
    grow
  } = activeStep;

  const inlineButtonContent = !buttonShouldAvoidKeyboard && (
    <ResponsiveStepperBtnPortal.Exit>
      {({ accessory, accessoryInline, ...btnProps }) => (
        <Constraint size="xsmall">
          <Stack horizontal={accessoryInline}>
            {accessory}
            <Button {...btnProps} />
            <Spacer />
          </Stack>
        </Constraint>
      )}
    </ResponsiveStepperBtnPortal.Exit>
  );

  return (
    <ResponsiveStepperPortal.Exit>
      {({ stepTitle: stepTitleOverride, showBtn }) => (
        <ContentArea
          grow={grow}
          size="none"
          constraint="xsmall"
          constrainedVariant="none"
        >
          <Spacer size="medium" />
          <Column grow={grow}>
            {showStepIndicator && (
              <>
                <Stack size="large">
                  <StepIndicator layout="horizontal" />
                  <Divider />
                </Stack>
                <Spacer size="medium" />
              </>
            )}

            {(!!stepTitleOverride || !!stepTitle) && (
              <>
                <Text
                  color="primary"
                  weight="bold"
                  variant="header"
                  testID="step-title"
                >
                  {stepTitleOverride ?? stepTitle}
                </Text>
                <Spacer size="medium" />
              </>
            )}

            {!!stepDescription && (
              <>
                <Text>
                  {typeof stepDescription === "function"
                    ? stepDescription(stepsData)
                    : stepDescription}
                </Text>
                <Spacer />
              </>
            )}
            <Column grow={grow}>
              {content}
              <Spacer />
              {showBtn && inlineButtonContent}
            </Column>
          </Column>
          <FooterSpacer />
        </ContentArea>
      )}
    </ResponsiveStepperPortal.Exit>
  );
}
