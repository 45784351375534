import React from "react";

import { useStyles, useViewport } from "../style";
import { KatanaText } from "../text";
import KatanaTouchableOpacity from "./katana-touchable-opacity";

const NavHeaderButton = ({
  title,
  onPress,
  textColor
}: {
  title: string;
  onPress: () => void;
  textColor?: "blue" | "white";
}) => {
  const { isMobile } = useViewport();
  const { styles } = useStyles(({ color, units, font }) => ({
    rightTextMobile: {
      color: textColor ? color[textColor] : color.white,
      paddingHorizontal: units(4),
      ...font.body("bold")
    },
    rightTextWeb: {
      color: textColor ? color[textColor] : color.blue,
      fontSize: font.size.large,
      ...font.body("semibold")
    }
  }));
  return (
    <KatanaTouchableOpacity onPress={onPress} eventTargetName="">
      <KatanaText
        style={isMobile ? styles.rightTextMobile : styles.rightTextWeb}
      >
        {title}
      </KatanaText>
    </KatanaTouchableOpacity>
  );
};
export default NavHeaderButton;
