import { Alert, type AlertButton, Platform } from "react-native";
import {
  type ConfirmPromptEntry,
  dispatchEvent
} from "./confirm-prompt-provider";

export type ConfirmPromptOptions = ConfirmPromptEntry & {
  // @deprecated: use actions instead
  yesLabel?: string;
  yesLabelStyle?: "default" | "cancel" | "destructive";

  // @deprecated: use actions instead
  cancelLabel?: string;
  cancelLabelStyle?: "default" | "cancel" | "destructive";

  // @deprecated: use actions instead
  oneLabel?: boolean;
  // @deprecated: use actions instead
  onDo?: () => void;
  // @deprecated: use actions instead
  onCancel?: () => void;
};

/** @deprecated use from @gigsmart/atorasu */
export default function confirmPrompt({
  yesLabel = "Yes",
  yesLabelStyle,
  cancelLabel = "Cancel",
  cancelLabelStyle = "cancel",
  onDo,
  onCancel,
  oneLabel,
  actions,
  autoClose = true,
  ...options
}: ConfirmPromptOptions) {
  // backward compatibility
  if (!actions) {
    cancelLabel = process.env.IS_TESTING === "true" ? "No" : cancelLabel;
    yesLabel = process.env.IS_TESTING === "true" ? "Yes" : yesLabel;
    actions = [
      {
        title: yesLabel,
        onPress: onDo,
        testID: "prompt-say-yes",
        style: yesLabelStyle
      }
    ];
    if (!oneLabel) {
      actions = [
        {
          title: cancelLabel,
          onPress: onCancel,
          style: cancelLabelStyle,
          testID: "prompt-say-no"
        },
        ...actions
      ];
    }
  }

  showAlert({ ...options, autoClose, actions });
}

export const showAlert = (entry: ConfirmPromptEntry) => {
  entry = entry.autoClose
    ? {
        ...entry,
        actions: entry.actions?.map((action) => ({
          ...action,
          onPress: () => {
            dismiss();
            action.onPress?.();
          }
        }))
      }
    : entry;

  if (Platform.OS === "web") {
    dispatchEvent("show", entry);
    return;
  }

  const buttons = entry.actions?.map(
    (action): AlertButton => ({
      text: action.title,
      style: action.style,
      onPress: action.onPress
    })
  );

  Alert.alert(entry.title, entry.subTitle, buttons ?? [], {
    cancelable: entry.cancelable ?? false
  });
};

export const dismiss = () => {
  if (Platform.OS === "web") {
    dispatchEvent("dismiss");
  }
};
