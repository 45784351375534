import React from "react";
import { StyleSheet } from "react-native";
import WebView from "react-native-webview";

interface Props {
  uri: string;
}

export default function WebContent({ uri }: Props) {
  return (
    <WebView
      source={{ uri }}
      style={StyleSheet.absoluteFill}
      containerStyle={StyleSheet.absoluteFill}
      automaticallyAdjustsScrollIndicatorInsets
      automaticallyAdjustContentInsets
      domStorageEnabled
      javaScriptEnabled
      javaScriptCanOpenWindowsAutomatically
      androidLayerType="software"
      thirdPartyCookiesEnabled
      applicationNameForUserAgent={"GigsmartApplets/1.0.0"}
      allowsFullscreenVideo
      allowsInlineMediaPlayback
      allowsAirPlayForMediaPlayback
      bounces
      overScrollMode="always"
      dataDetectorTypes="all"
      setBuiltInZoomControls={false}
      geolocationEnabled
      allowFileAccess
      cacheEnabled
      sharedCookiesEnabled
      mixedContentMode="compatibility"
    />
  );
}
