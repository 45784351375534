import {
  Checkbox,
  Column,
  ContentArea,
  Pressable,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { type FormFieldOptions, useFormToggle } from "@gigsmart/fomu";
import { StyledLink } from "@gigsmart/katana";
import { noop } from "lodash";
import React from "react";

type Props = FormFieldOptions & {
  onToggle?: () => void;
  toggled?: boolean;
  prompt?: string;
  testID?: string;
  variant?: "outline" | "shadow";
  size?: "compact" | "medium";
  disabled?: boolean;
};

export default function CardCheckbox({
  onToggle,
  toggled,
  testID,
  prompt,
  variant = "outline",
  size = "compact",
  disabled = false,
  ...options
}: Props) {
  const { toggle, value } = useFormToggle(options);

  return (
    <Pressable
      eventTargetName={testID ?? "card-checkbox-card"}
      eventEntityType="checkbox-card"
      onPress={disabled ? noop : onToggle ?? toggle}
      testID={testID ?? "card-checkbox-card"}
    >
      <Surface variant={variant}>
        <ContentArea size={size}>
          <Stack horizontal alignItems="center">
            <Checkbox
              disabled={disabled}
              selected={typeof toggled === "undefined" ? value : toggled}
              onChange={onToggle ?? toggle}
            />
            <Column fill>
              {prompt ? (
                <Text>{prompt}</Text>
              ) : (
                <Text>
                  I agree to Stripe's{" "}
                  <StyledLink openURL="https://stripe.com/connect-account/legal">
                    <Text>Terms of Service</Text>
                  </StyledLink>{" "}
                  and{" "}
                  <StyledLink openURL="https://stripe.com/privacy">
                    <Text>Privacy Policy</Text>
                  </StyledLink>
                </Text>
              )}
            </Column>
          </Stack>
        </ContentArea>
      </Surface>
    </Pressable>
  );
}
