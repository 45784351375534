import { Button, Column, Row, Text, Well, toast } from "@gigsmart/atorasu";
import { WorkerBlockOrg } from "@gigsmart/feature-flags";
import { useNavigation } from "@gigsmart/kaizoku";
import { createRelayNodeContainer, graphql } from "@gigsmart/relay";
import React from "react";
import { showUnblockOrgModal } from "./UnblockOrgModal";
import type { WorkerBlockedOrganizationReminderQuery } from "./__generated__/WorkerBlockedOrganizationReminderQuery.graphql";
import type { WorkerBlockedOrganizationReminderSubscription } from "./__generated__/WorkerBlockedOrganizationReminderSubscription.graphql";
import type { WorkerBlockedOrganizationReminder_worker$key } from "./__generated__/WorkerBlockedOrganizationReminder_worker.graphql";

type Props = {
  workerId: string;
  organizationId: string;
  Wrapper?: React.ComponentType<{ children: React.ReactNode }>;
};

export const showWorkerBlockedOrganizationReminder = (
  orgId?: string | null
): orgId is string => {
  return WorkerBlockOrg.isEnabled() && !!orgId;
};

export default createRelayNodeContainer<
  WorkerBlockedOrganizationReminder_worker$key,
  WorkerBlockedOrganizationReminderQuery,
  WorkerBlockedOrganizationReminderSubscription,
  Props
>(
  function WorkerBlockedOrganizationReminder({
    blockedOrganizations,
    Wrapper = React.Fragment
  }) {
    const orgIsBlocked = !!(blockedOrganizations?.totalCount ?? 0);
    const workerBlockedOrganization = blockedOrganizations?.edges?.[0]?.node;
    const navigation = useNavigation();

    const handleSubmit = () =>
      showUnblockOrgModal({
        workerBlockedOrganizationId: workerBlockedOrganization?.id ?? "",
        onSuccess: () => {
          toast.success("Organization has been successfully unblocked.");
          navigation.goBack();
        }
      });

    if (!orgIsBlocked || WorkerBlockOrg.isDisabled()) return null;
    return (
      <Wrapper>
        <Well size="compact" color="primary">
          <Column gap="standard" fill>
            <Text
              weight="semibold"
              color="primary"
              testID="worker-blocked-organization-reminder-header"
              align="center"
            >
              You have blocked this Organization. Unblock to view and apply to
              available Shift Gigs from the Organization.
            </Text>
            <Row alignItems="center" fill justifyContent="center">
              <Button
                testID="worker-blocked-organization-reminder-unblock-organization-button"
                size="small"
                outline
                variant="clear"
                label="Unblock"
                onPress={handleSubmit}
              />
            </Row>
          </Column>
        </Well>
      </Wrapper>
    );
  },
  {
    query: graphql`
      query WorkerBlockedOrganizationReminderQuery($organizationId: ID!, $workerId: ID!) {
        node(id: $workerId) {
          ... on Worker {
            ...WorkerBlockedOrganizationReminder_worker
            @arguments(organizationId: $organizationId)
          }
        }
      }
    `,
    fragment: graphql`
    fragment WorkerBlockedOrganizationReminder_worker on Worker @argumentDefinitions(organizationId: { type: "ID!" }) {
      id
      blockedOrganizations(first: 1, where: {organizationId: {_eq: $organizationId}}) {
        totalCount
        edges {
          node {
            id
            organization {
              id
            }
          }
        }
      }
    }
    `,
    subscription: graphql`
    subscription WorkerBlockedOrganizationReminderSubscription($workerId: ID!, $organizationId: ID!) {
      workerUpdated(workerId: $workerId) {
        worker {
        ...WorkerBlockedOrganizationReminder_worker @arguments(organizationId: $organizationId)
        }
      }
    }`,
    getSubscriptionFragmentRef: ({ workerUpdated }) => workerUpdated?.worker,
    getQueryFragmentRef: ({ node }) => node,
    variables: ({ organizationId, workerId }) => ({
      organizationId,
      workerId
    })
  }
);
