import React from "react";

import {
  Button,
  CollapsibleList,
  CollapsibleWell,
  ContentArea,
  Divider,
  GridNull,
  IconText,
  Spacer,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import { WorkerEngagementView } from "@gigsmart/feature-flags";
import { type NavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import HomeScreenCard from "../worker/HomescreenCard";

import type { SharedParamList } from "../navigation/types";
import type { LanguagesCard_Worker$key } from "./__generated__/LanguagesCard_Worker.graphql";

interface Props {
  isNewLanguageAdded?: boolean;
  viewer?: "Requester" | "Worker";
}

export function LanguagesCard({
  languages,
  isNewLanguageAdded,
  viewer = "Worker"
}: FragmentContainerInnerComponentProps<LanguagesCard_Worker$key, Props>) {
  const navigation = useNavigation<NavigationProp<SharedParamList>>();
  const renderWorkerView = () => (
    <HomeScreenCard
      headerTitle="Languages"
      headerIcon="globe-americas"
      testID="profile-languages-card"
    >
      <Stack>
        {languages?.edges && (
          <CollapsibleList
            items={languages?.edges?.map((language) => ({
              title: language?.node?.language ?? "",
              content: <Text>{language?.node?.level}</Text>,
              languageName: language?.node?.language,
              id: language?.node?.id
            }))}
            startCollapsed={!isNewLanguageAdded}
            iconName="pen-to-square"
            onPress={({ id }) =>
              navigation.navigate("ProfileEditLanguage", {
                languageId: id ?? ""
              })
            }
            placeholder="Adding a different language that you speak can increase your chances of finding the right Shifts."
            testID="language-card-collapsible-list"
          />
        )}
        <Button
          label="Add Languages"
          icon="plus"
          variant="clear"
          outline
          size="large"
          testID="add-languages-button"
          onPress={() => navigation.navigate("ProfileAddLanguage")}
        />
      </Stack>
    </HomeScreenCard>
  );

  const renderRequesterView = () => {
    if (!languages?.edges?.length) {
      return <GridNull />;
    }

    const totalCount = languages.edges?.length ?? 0;
    return WorkerEngagementView.select(
      <CollapsibleWell
        testID="worker-profile-languages"
        headerIcon="globe-americas"
        headerText="Languages"
        items={languages?.edges?.map((language, index) => (
          <>
            <Text variant="subheader" color="black">
              {language?.node?.language}
            </Text>
            <Text>{language?.node?.level}</Text>
            {index < totalCount - 1 && (
              <>
                <Spacer size="compact" />
                <Divider />
              </>
            )}
          </>
        ))}
      />,
      <Stack size="medium">
        <Well color="black" size="compact">
          <IconText
            icon="globe-americas"
            spacing="medium"
            textWeight="bold"
            size="small"
          >
            Languages
          </IconText>
        </Well>
        <ContentArea size="none">
          {languages?.edges && (
            <CollapsibleList
              items={languages?.edges?.map((language) => ({
                title: language?.node?.language ?? "",
                content: <Text>{language?.node?.level}</Text>
              }))}
              iconName="pen-to-square"
              testID="requester-worker-language-row"
              startCollapsed={false}
              allowCollapse={false}
            />
          )}
        </ContentArea>
      </Stack>
    );
  };

  return viewer === "Worker" ? renderWorkerView() : renderRequesterView();
}

export default createRelayFragmentContainer<LanguagesCard_Worker$key, Props>(
  graphql`
    fragment LanguagesCard_Worker on Worker {
      languages(first: 30, query: "ORDER BY language ASC") {
        totalCount
        edges {
          node {
            id
            language
            level
          }
        }
      }
    }
  `,
  LanguagesCard
);
