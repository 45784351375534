import {
  Button,
  Checkbox,
  Column,
  ContentArea,
  ScreenScroll,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import NavBackButton from "@gigsmart/isomorphic-shared/nav/nav-back-button";
import type { StepProps } from "@gigsmart/katana";
import React, { useState } from "react";
import StepHeader from "../../shared/Stepper/StepHeader";
import type { StepPropsType } from "./types";

type Props = StepProps<StepPropsType>;

export default function DisputeAgreementStep({
  nextStep,
  stepData,
  prevStep
}: Props) {
  const [agreementOne, setAgreementOne] = useState(
    stepData.agreementOne ?? false
  );
  const [agreementTwo, setAgreementTwo] = useState(
    stepData.agreementTwo ?? false
  );
  return (
    <ScreenScroll testID="dispute-agreement-step">
      <NavBackButton onGoBack={prevStep} />
      <Column fill justifyContent="space-between">
        <Column fill>
          <StepHeader
            name="Payment Dispute Agreement"
            note="If you believe your Shift pay is inaccurate, you can submit a Dispute to the Requester for review. The Requester has 1 business day to review your Dispute. If they agree to your Dispute, your Shift pay will be automatically adjusted."
          />
          <Spacer />
          <Surface>
            <ContentArea>
              <Stack>
                <Text weight="semibold">
                  Please confirm you agree with the following statements by
                  checking the boxes below:
                </Text>
                <Stack size="compact">
                  <Surface variant="outline">
                    <ContentArea>
                      <Stack horizontal>
                        <Column>
                          <Text weight="bold">1.</Text>
                        </Column>
                        <Stack horizontal alignItems="center" fill>
                          <Column fill>
                            <Text>
                              I believe there is an error with my Shift payment
                              that needs to be resolved.
                            </Text>
                          </Column>
                          <Checkbox
                            testID="agreement-one-checkbox"
                            selected={agreementOne}
                            onChange={(selected) => setAgreementOne(selected)}
                          />{" "}
                        </Stack>
                      </Stack>
                    </ContentArea>
                  </Surface>
                  <Surface variant="outline">
                    <ContentArea>
                      <Stack horizontal>
                        <Column>
                          <Text weight="bold">2.</Text>
                        </Column>
                        <Stack horizontal alignItems="center" fill>
                          <Column fill>
                            <Text>
                              I will not submit an inaccurate Payment Dispute to
                              the Organization.
                            </Text>
                          </Column>
                          <Checkbox
                            testID="agreement-two-checkbox"
                            selected={agreementTwo}
                            onChange={(selected) => setAgreementTwo(selected)}
                          />
                        </Stack>
                      </Stack>
                    </ContentArea>
                  </Surface>
                </Stack>
              </Stack>
            </ContentArea>
          </Surface>
        </Column>
        <ContentArea size="none">
          <Spacer />
          <Button
            testID="continue-btn"
            label="I Agree, Continue"
            onPress={() =>
              nextStep({
                ...stepData,
                agreementOne,
                agreementTwo
              })
            }
            disabled={!agreementOne || !agreementTwo}
          />
        </ContentArea>
      </Column>
    </ScreenScroll>
  );
}
