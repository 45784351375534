import {
  ActionRow,
  Button,
  Column,
  ContentArea,
  HighlightedReminder,
  Stack,
  Surface,
  Text,
  useStepper
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { confirmPrompt, dismiss } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React from "react";
import StepHeader from "../../../shared/Stepper/StepHeader";
import ConfirmationRequestSupport from "../ConfirmationRequestSupport";
import ClaimOrganizationSurface from "./ClaimOrganizationSurface";
import Nav from "./Nav";
import type { ReviewStepDirectHireClaimQuery } from "./__generated__/ReviewStepDirectHireClaimQuery.graphql";
import type { ReviewStepPublishClaimMutation } from "./__generated__/ReviewStepPublishClaimMutation.graphql";
import type { DirectHireClaimsStepper } from "./index";

export default createSuspendedQueryContainer<ReviewStepDirectHireClaimQuery>(
  function ReviewStep({ response: result }) {
    const history = useHistory();
    const { gotoStep, stepsData } = useStepper<{}, DirectHireClaimsStepper>();

    const [publishClaim] =
      useRelayMutation<ReviewStepPublishClaimMutation>(graphql`
        mutation ReviewStepPublishClaimMutation(
          $input: TransitionDirectHireClaimInput!
        ) {
          transitionDirectHireClaim(input: $input) {
            transition {
              id
            }
          }
        }
      `);
    const claim = result?.node;
    const isHire = claim?.directHireType === "HIRE";

    const handleCancel = () => {
      confirmPrompt({
        title: `Are you sure you want to Cancel your Direct ${
          isHire ? "Hire" : "Payment"
        } Bonus Request?`,
        subTitle: "",
        yesLabel: "Cancel",
        onDo: () => {
          history.replace("/");
          toast.notice(
            `Your Direct ${
              isHire ? "Hire" : "Payment"
            } Bonus Request has been canceled`
          );
        },
        cancelLabel: "Do Not Cancel",
        onCancel: dismiss
      });
    };
    const handleSubmit = () => {
      confirmPrompt({
        title: `Submit your Direct ${isHire ? "Hire" : "Payment"} Request`,
        subTitle: `Are you sure you want to Submit your Direct ${
          isHire ? "Hire" : "Payment"
        } Bonus Request? Edits cannot be made once submitted.`,
        yesLabel: "Submit",
        onDo: () => {
          publishClaim(
            {
              input: {
                directHireClaimId: stepsData?.id ?? "",
                action: "PUBLISH"
              }
            },
            {
              onSuccess: () => {
                toast.success(
                  `Your Direct ${
                    isHire ? "Hire" : "Payment"
                  } Bonus Request has been submitted for review!`
                );
                history.replace("/direct-hire");
              }
            }
          );
        },
        cancelLabel: "Do Not Submit",
        onCancel: dismiss
      });
    };
    return (
      <Column fill justifyContent="space-between">
        <Nav returnTo="information" />
        <Stack>
          <StepHeader
            name={`Review Your Direct ${
              isHire ? "Hire" : "Payment"
            } Bonus Request`}
            note={`Please check your Direct ${
              isHire ? "Hire" : "Payment"
            } Bonus information below. To make an edit, select the "Edit" button. Be sure to make any necessary adjustments before submitting as all submissions are final.`}
          />
          <ClaimOrganizationSurface fragmentRef={claim} variant="review" />
          <Surface>
            <ActionRow
              testID="support-and-evidence-row"
              title={
                <Text color="primary" weight="semibold">
                  Direct {isHire ? "Hire" : "Payment"} Support & Evidence{" "}
                </Text>
              }
              right={
                claim?.comments ? (
                  <Button
                    testID="edit-btn"
                    size="small"
                    label="Edit"
                    icon="pen-to-square"
                    outline
                    onPress={() =>
                      gotoStep("evidence", {
                        ...stepsData,
                        returnTo: "review"
                      })
                    }
                  />
                ) : undefined
              }
              icon="list"
            />
            <ContentArea>
              <ConfirmationRequestSupport
                comment={claim?.comments}
                photos={getConnectionNodes(claim?.photos)}
              />
            </ContentArea>
          </Surface>
          <ContentArea size="none">
            <HighlightedReminder
              icon="circle-exclamation"
              header={`Please review your Direct ${
                isHire ? "Hire" : "Payment"
              } Bonus Request carefully. Once submitted, you will no longer be able to make any edits.`}
            />
          </ContentArea>
        </Stack>
        <ContentArea>
          <Stack>
            <Button
              testID="cancel-btn"
              outline
              label="Cancel, I No Longer Wish to Submit"
              onPress={handleCancel}
            />
            <Button
              testID="submit-btn"
              onPress={handleSubmit}
              label={`Submit My Direct ${
                isHire ? "Hire" : "Payment"
              } Bonus Request`}
            />
          </Stack>
        </ContentArea>
      </Column>
    );
  },
  {
    query: graphql`
      query ReviewStepDirectHireClaimQuery($id: ID!) {
        node(id: $id) {
          ... on DirectHireClaim {
            id
            directHireType
            ...ClaimOrganizationSurface_DirectHireClaim
            comments
            photos(first: 5) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: () => {
      const { stepsData } = useStepper<{}, DirectHireClaimsStepper>();
      return { id: stepsData?.id ?? "" };
    }
  }
);
