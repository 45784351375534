/**
 * @generated SignedSource<<2a758c19bfb12e29eb79f8bdcf9c8105>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGigPositions_gigLike$data = {
  readonly category?: {
    readonly categoryIcon: {
      readonly data: string;
    } | null | undefined;
    readonly displayName: string;
    readonly id: string;
  } | null | undefined;
  readonly position?: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "useGigPositions_gigLike";
};
export type useGigPositions_gigLike$key = {
  readonly " $data"?: useGigPositions_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGigPositions_gigLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigCategory",
    "kind": "LinkedField",
    "name": "category",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Icon",
        "kind": "LinkedField",
        "name": "categoryIcon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "GigPosition",
    "kind": "LinkedField",
    "name": "position",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGigPositions_gigLike",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "GigSeries",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Gig",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "a79bea041cc8a8638b97049a938e6663";

export default node;
