import React from "react";

import RadioButtonGroup from "../radio-button-group";
import FancyButton, { type FancyButtonProps } from "./fancy-button";

interface Props<V> {
  options: Array<FancyButtonProps<V>>;
  defaultIndex?: number;
  onPress: (arg0: V) => void;
  spacing?: number | null;
  inset?: number;
  testID?: string;
  selectedValue?: any;
}

export default function FancyRadioButton<V>({
  spacing,
  options,
  defaultIndex,
  onPress,
  inset,
  testID,
  selectedValue
}: Props<V>) {
  return (
    <RadioButtonGroup
      selectedValue={selectedValue}
      testID={testID}
      inset={inset}
      direction="row"
      RowComponent={FancyButton}
      options={options}
      onChange={onPress}
      spacing={spacing || 2}
      defaultIndex={defaultIndex}
    />
  );
}
