import React, { type ReactNode } from "react";
import { View } from "react-native";

import FlexRow from "../flex/row";
import StyledIcon, { type IconName } from "../icon/styled-icon";
import useStyles from "../style/use-styles";
import KatanaText from "../text/katana-text";

interface Props {
  children: ReactNode;
  icon?: IconName;
  title: string;
  secondaryTitle?: string;
  renderRightAccessory?: () => void;
  webCard?: boolean;
}

const CardWithTitle = ({
  children,
  icon,
  title,
  secondaryTitle,
  renderRightAccessory,
  webCard = false
}: Props) => {
  const { styles, theme } = useStyles(({ color, font, shadow, units }) => ({
    cardContainer: {
      backgroundColor: color.white,
      padding: webCard ? units(10) : units(4),
      "@desktop": {
        paddingRight: webCard ? units(16) : units(13.5)
      },
      "@tablet": {
        paddingRight: units(8)
      },
      "@phone": {
        paddingRight: units(6)
      },
      borderRadius: webCard ? units(1) : units(0),
      ...shadow()
    },
    icon: {
      width: webCard ? units(8.5) : units(4.5)
    },
    title: {
      color: color.blue,
      fontSize: webCard ? font.size.largest : font.size.medium,
      lineHeight: webCard ? font.size.largest : font.size.medium,
      ...font.body("bold"),
      paddingHorizontal: units(2)
    },
    secondaryTitle: {
      color: color.blue,
      fontSize: font.size.medium
    },
    additionalPadding: {
      paddingLeft: webCard ? units(10) : units(6)
    },
    bodyContainer: {
      flex: 1,
      paddingLeft: webCard ? units(10) : units(6),
      paddingTop: units(4)
    }
  }));
  return (
    <View style={styles.cardContainer}>
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          {!!icon && (
            <StyledIcon
              name={icon}
              variant="solid"
              size={webCard ? theme.font.size.largest : theme.font.size.medium}
              style={styles.icon}
            />
          )}
          <KatanaText style={[styles.title, !icon && styles.additionalPadding]}>
            {title}
          </KatanaText>
          {!!secondaryTitle && (
            <KatanaText style={styles.secondaryTitle}>
              &bull; {secondaryTitle}
            </KatanaText>
          )}
        </FlexRow>
        {!!renderRightAccessory?.()}
      </FlexRow>
      {!!children && <View style={styles.bodyContainer}>{children}</View>}
    </View>
  );
};

export default CardWithTitle;
