import { toast } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import { useMemo } from "react";

import type { useSetSummaryApproveTimesheetMutation } from "./__generated__/useSetSummaryApproveTimesheetMutation.graphql";
import type {
  PaymentType,
  useSetSummaryMutation
} from "./__generated__/useSetSummaryMutation.graphql";

export default function useSetSummary(engagementId: string, approve?: boolean) {
  const history = useHistory();
  const [setSummary] = useRelayMutation<useSetSummaryMutation>(graphql`
    mutation useSetSummaryMutation($input: SetEngagementSummaryInput!) {
      setEngagementSummary(input: $input) {
        engagementTimesheet {
          id
        }
        engagement {
          currentState {
            name
          }
        }
      }
    }
  `);

  const [approveSummary] =
    useRelayMutation<useSetSummaryApproveTimesheetMutation>(graphql`
      mutation useSetSummaryApproveTimesheetMutation(
        $input: ApproveEngagementTimesheetInput!
      ) {
        approveEngagementTimesheet(input: $input) {
          engagement {
            currentState {
              name
            }
          }
        }
      }
    `);

  const handleSuccess = () => {
    toast.success(
      "Project Gig successfully marked as complete! The Requester has been notified"
    );
    history.replace(`/gigs/${engagementId}`);
  };

  return useMemo(
    () => (paid: boolean, paymentType: PaymentType) => {
      setSummary(
        {
          input: {
            engagementId,
            paid,
            paymentType,
            complete: true
          }
        },
        {
          onSuccess: (result) => {
            const timesheetId =
              result?.setEngagementSummary?.engagementTimesheet?.id;
            if (approve && timesheetId) {
              approveSummary(
                {
                  input: {
                    timesheetId
                  }
                },
                { onSuccess: handleSuccess }
              );
            } else {
              handleSuccess();
            }
          }
        }
      );
    },
    []
  );
}
