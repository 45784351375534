/**
 * @generated SignedSource<<c79ca6db76d9106ceb92eb81026e4a70>>
 * @relayHash efbc0f990f1caf995497d9bd0553c9cc
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:4Aaur_SWPjL8bxN7quaKvWbFvRyP8Y0EtJCJjkYiVlo

import { ConcreteRequest } from 'relay-runtime';
export type GigPositionStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type useGigPositionsViewerQuery$variables = {
  positionsQuery: string;
};
export type useGigPositionsViewerQuery$data = {
  readonly viewer: {
    readonly id?: string;
    readonly positions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly status: GigPositionStatus;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useGigPositionsViewerQuery = {
  response: useGigPositionsViewerQuery$data;
  variables: useGigPositionsViewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "positionsQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "positionsQuery"
    }
  ],
  "concreteType": "WorkerGigPositionsConnection",
  "kind": "LinkedField",
  "name": "positions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerGigPositionsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerGigPosition",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGigPositionsViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGigPositionsViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:4Aaur_SWPjL8bxN7quaKvWbFvRyP8Y0EtJCJjkYiVlo",
    "metadata": {},
    "name": "useGigPositionsViewerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5df07e29b2d9e868d8a8da834af3a062";

export default node;
