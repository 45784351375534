import {
  ContentArea,
  List,
  ScrollView,
  Stack,
  Text,
  Well,
  showModal
} from "@gigsmart/atorasu";
import { ProjectGigsPayInApp } from "@gigsmart/feature-flags";
import KatanaTabs, {
  type Tab
} from "@gigsmart/katana/nav/katana-tabs/katana-tabs";
import StyledLink from "@gigsmart/katana/tappable/styled-link";
import React from "react";

export const PROJECT_INFO_TABS: Tab[] = [
  {
    label: "ABOUT",
    Contents: (
      <ScrollView testID="project-about-tab" showsVerticalScrollIndicator>
        <ContentArea>
          <Stack>
            <Text>
              Work Project Gigs on-site or remotely. Submit bids and finalize
              your price and timeline directly with the Requester.
            </Text>
            <Well>
              <List variant="check">
                <Text>Work Projects on-site or remotely </Text>
                <Text>Negotiate your pay rate with the Requester</Text>
                <Text>
                  Discuss and agree upon the project timeline with the Requester
                </Text>
                <Text>
                  Get paid via{" "}
                  {ProjectGigsPayInApp.isEnabled() ? "the app, " : ""}
                  cash, check, or digital transfer
                </Text>
              </List>
            </Well>
            <StyledLink
              center
              openURL="https://gigsmart.com/find-work/residential-project-gigs/"
            >
              View More Info about Project Gigs
            </StyledLink>
          </Stack>
        </ContentArea>
      </ScrollView>
    )
  },
  {
    label: "GET HIRED",
    Contents: (
      <ScrollView testID="project-get-hired-tab" showsVerticalScrollIndicator>
        <ContentArea>
          <Stack>
            <Text>
              To increase your chances of getting hired, we recommend the
              following:
            </Text>
            <Well>
              <List variant="ordered">
                <Text>Add a professional photo to your profile</Text>
                <Text>
                  Add your bio, references, education, and work history to your
                  profile
                </Text>
                <Text>Only apply to Projects matching your skillset</Text>
                <Text>Make your bid competitive</Text>
                <Text>
                  Show off your experience by completing the Application
                  Questionnaire
                </Text>
              </List>
            </Well>
          </Stack>
        </ContentArea>
      </ScrollView>
    )
  },
  {
    label: "STATUSES",
    Contents: (
      <ScrollView testID="project-statused-tab" showsVerticalScrollIndicator>
        <ContentArea>
          <Stack>
            <Stack size="compact">
              <Text weight="bold" variant="header">
                Hired
              </Text>
              <Text>
                When hired, you are expected to complete the Project as you have
                discussed with the Requester.
              </Text>
            </Stack>
            <Stack size="compact">
              <Text weight="bold" variant="header">
                Applied
              </Text>
              <Text>
                Your Project Gig application has been submitted. We will notify
                you if the Requester is interested in working with you.
              </Text>
            </Stack>
          </Stack>
        </ContentArea>
      </ScrollView>
    )
  }
];

interface Options {
  defaultTab?: string;
}

export function showProjectGigsInfoModal({ defaultTab }: Options = {}) {
  return showModal({
    eventContext: "Project Gigs InfoModal",
    title: "Project Gigs",
    headerIcon: "pen-ruler",
    variant: "border",
    fixedHeight: 600,
    children: () => (
      <KatanaTabs tabs={PROJECT_INFO_TABS} defaultTab={defaultTab} />
    ),
    actions: [{ label: "Got It", testID: "close-btn" }]
  });
}
