import { Button, Stack, Text, showModal, toast } from "@gigsmart/atorasu";
import { StyledPhoneNumberInput } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import React, { useState } from "react";
import { formatPhoneNumber } from "../shared/Phone/helpers";
import type { OperatorModalMutation } from "./__generated__/OperatorModalMutation.graphql";
import type { OperatorModalQuery } from "./__generated__/OperatorModalQuery.graphql";

interface Props {
  recipientsName: string | null | undefined;
  recipientId: string;
  onCall?: () => void;
}

export default function showOperatorModel(props: Props) {
  showModal({
    eventContext: "operatorModel",
    title: `Call ${props.recipientsName}`,
    useModalFooter: true,
    useModalBody: true,
    dismissable: true,
    children: (close) => <OperatorModal {...props} onCall={close} />
  });
}

export const OperatorModal = createSuspendedQueryContainer<
  OperatorModalQuery,
  Props
>(
  function OperatorModal({ recipientsName, recipientId, response, onCall }) {
    const [callerNumberOverride, setCallerNumberOverride] = useState<string>();
    const [initCall] = useRelayMutation<OperatorModalMutation>(graphql`
      mutation OperatorModalMutation($input: InitiateOperatorCallInput!) {
        initiateOperatorCall(input: $input) {
          ok
        }
      }
    `);
    const callerNumber =
      callerNumberOverride ?? response?.viewer?.primaryMobile?.number;

    // const usersNumber = formatPhoneNumber(callerNumber ?? "");
    return (
      <Stack>
        <Text>
          The GigSmart operator will call you using the number below and then
          connect you with {recipientsName}
        </Text>
        <StyledPhoneNumberInput
          eventTargetName={"operator caller number override"}
          label="Your Phone Number"
          testID="operator-number-input"
          value={callerNumber}
          onChangeText={(text) =>
            setCallerNumberOverride(formatPhoneNumber(text))
          }
        />

        <Button
          label="Initiate Call"
          testID="initiate-operator-call"
          onPress={() => {
            initCall(
              {
                input: {
                  recipientId,
                  callerNumberOverride
                }
              },
              {
                onCompleted: onCall,
                onPayloadErrors: (errors) =>
                  toast.error(errors.map(({ message }) => message).join(", "))
              }
            );
          }}
        />
      </Stack>
    );
  },
  {
    query: graphql`
      query OperatorModalQuery {
        viewer {
          primaryMobile {
            number
          }
        }
      }
    `,
    variables: {}
  }
);
