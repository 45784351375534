import { Platform, showEmbeddedBrowser } from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";

export function showDirectHirePolicy(variant: "worker" | "requester") {
  const url =
    variant === "worker"
      ? "https://help.gigsmart.com/am-i-eligible-to-receive-the-direct-payment-hire-bonus"
      : DirectPaymentsOnly.select(
          "https://gigsmart.com/direct-payment-policy/",
          "https://help.gigsmart.com/what-if-i-paid-or-hired-a-worker-outside-of-the-gigsmart-platform"
        );

  if (Platform.OS === "web") window.open(url, "_blank");
  else showEmbeddedBrowser({ url });
}

export default function useLinkToDirectHirePolicy(
  variant: "worker" | "requester"
) {
  return () => showDirectHirePolicy(variant);
}
