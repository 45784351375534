import { Text } from "@gigsmart/atorasu";
import { Column, IconButton, type IconName, Row } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { SolidButton, StyledScrollView, StyledView } from "@gigsmart/katana";
import React from "react";
import { SafeAreaView } from "react-native";

interface CompleteRowProps {
  icon: IconName;
  iconColor: string;
  title: string;
  description: string;
}

const rows: CompleteRowProps[] = [
  {
    icon: "briefcase",
    iconColor: "#A68D6E",
    title: "Apply to More Gigs",
    description: "Verified or unverified, the Gigs are yours for the taking."
  },
  {
    icon: "ballot-check",
    iconColor: "neutralDark",
    title: "Verification Upon Hiring",
    description:
      "Background checks and/or Motor Vehicle Record checks will be run after you are hired for a Verified Gig."
  },
  {
    icon: "shield-check",
    iconColor: "blueTint",
    title: "Complete Verification",
    description:
      "Once you’ve been successfully verified, you'll be hired for the verified gig."
  },
  {
    icon: "user-headset",
    iconColor: "neutralDark",
    title: "Potential Follow-up",
    description:
      "If your background or motor vehicle check is flagged, a representative will reach out."
  }
];

const CompleteRow = ({
  title,
  description,
  icon,
  iconColor
}: CompleteRowProps) => {
  const styles = useStyles(({ getUnits }) => ({
    row: {
      paddingHorizontal: getUnits(5),
      paddingVertical: getUnits(4)
    },
    rowText: {
      paddingHorizontal: getUnits(4)
    }
  }));
  return (
    <Row key={title} alignItems="center" style={styles.row}>
      <Column>
        <IconButton
          variant="solid"
          name={icon}
          testID="icon-enrollment-complete"
        />
      </Column>
      <Column fill={1} style={styles.rowText}>
        <Text weight="bold" color="primary">
          {title}
        </Text>
        <Text>{description}</Text>
      </Column>
    </Row>
  );
};

interface Props {
  onDone: () => void;
}

export default function VerifiedEnrollmentComplete({ onDone }: Props) {
  const styles = useStyles(({ getUnits, getColor }) => ({
    safeArea: {
      backgroundColor: getColor("surface", "fill"),
      flex: 1
    },
    container: {
      flex: 1
    },
    contentContainer: {
      paddingHorizontal: getUnits(3),
      paddingVertical: getUnits(5),
      justifyContent: "center",
      flexGrow: 1
    },
    header: {
      marginBottom: getUnits(2)
    }
  }));

  return (
    <SafeAreaView style={styles.safeArea}>
      <StyledScrollView
        bounces={false}
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        <Text
          align="center"
          color="primary"
          variant="title"
          style={styles.header}
          weight="bold"
          testID="opt-in-success-message"
        >
          Way to Go, You've Opted-In for Verification!
        </Text>
        <Text align="center" color="primary" variant="bodyLg">
          Here's whats next:
        </Text>
        {rows.map((props, index) => (
          <CompleteRow key={index} {...props} />
        ))}
      </StyledScrollView>
      <StyledView>
        <SolidButton
          testID="opt-in-success-confirm"
          title="Got It"
          onPress={onDone}
        />
      </StyledView>
    </SafeAreaView>
  );
}
