import { ConnectionHandler, graphql, useRelayMutation } from "@gigsmart/relay";
import { useCallback } from "react";
import type { useReadAllNotificationsSilentMutation } from "./__generated__/useReadAllNotificationsSilentMutation.graphql";
import moveMessageUpdater from "./move-message-updater";
import { notificationIconUpdater } from "./notification-icon-container";
import { notificationsQuery } from "./use-notification-section";

export default function useReadAll() {
  const query = notificationsQuery(true);
  const [readAll] = useRelayMutation<useReadAllNotificationsSilentMutation>(
    graphql`
      mutation useReadAllNotificationsSilentMutation(
        $input: UpdateMessageReceiptsInput!
      ) {
        updateMessageReceipts(input: $input) {
          messageReceipts {
            id
            viewedAt
          }
        }
      }
    `,
    {
      variables: { input: { query, viewed: true } },
      updater: (store, resp) => {
        const cb = (count: number) => {
          return resp?.updateMessageReceipts?.messageReceipts?.length
            ? count - resp?.updateMessageReceipts.messageReceipts.length
            : 0;
        };

        notificationIconUpdater(store, cb);

        const root = store.getRoot();
        const viewer = root.getLinkedRecord("viewer");
        if (viewer) {
          const newConn = ConnectionHandler.getConnection(
            viewer,
            "notificationSection_messages",
            { query: notificationsQuery(true) }
          );
          newConn?.setValue(0, "totalCount");
          moveMessageUpdater(store);
        }
      }
    }
  );
  return useCallback(() => readAll(), [readAll]);
}
