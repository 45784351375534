/**
 * @generated SignedSource<<714e4422d4800c2acec160c7eed7901b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkerPayoutType = "INSTANT" | "STANDARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type priorTransferRow_payout$data = {
  readonly amount: string;
  readonly feeAmount: string | null | undefined;
  readonly insertedAt: string;
  readonly providerDetails: {
    readonly arrivesAt: string | null | undefined;
    readonly automatic: boolean;
    readonly destination: {
      readonly __typename: string;
      readonly bankName?: string;
      readonly brand?: string;
      readonly last4: string;
    } | null | undefined;
    readonly method: string;
    readonly status: string;
  };
  readonly type: WorkerPayoutType;
  readonly " $fragmentSpreads": FragmentRefs<"priorTransferEngagementRows_engagements">;
  readonly " $fragmentType": "priorTransferRow_payout";
};
export type priorTransferRow_payout$key = {
  readonly " $data"?: priorTransferRow_payout$data;
  readonly " $fragmentSpreads": FragmentRefs<"priorTransferRow_payout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priorTransfersQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "priorTransferRow_payout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feeAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "priorTransfersQuery",
          "variableName": "priorTransfersQuery"
        }
      ],
      "kind": "FragmentSpread",
      "name": "priorTransferEngagementRows_engagements"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerPayoutProviderDetails",
      "kind": "LinkedField",
      "name": "providerDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "method",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "arrivesAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "automatic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "destination",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bankName",
                  "storageKey": null
                }
              ],
              "type": "BankAccount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brand",
                  "storageKey": null
                }
              ],
              "type": "BankCard",
              "abstractKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last4",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkerPayout",
  "abstractKey": null
};

(node as any).hash = "6568c69ed7b31b7cd4f7002070da742e";

export default node;
