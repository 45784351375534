/**
 * @generated SignedSource<<548fd5bfaafc900c24f61a197e30cded>>
 * @relayHash f82bb48f364ac839dcb5e06d8abe8731
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:SQLdxJbHwH_btkKocgubFgn_FvePBLIo6Be8mN7GIh0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalScreenQuery$variables = {
  filename: string;
};
export type LegalScreenQuery$data = {
  readonly consentStatus: {
    readonly consent: {
      readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_consent">;
    } | null | undefined;
    readonly legalDocument: {
      readonly " $fragmentSpreads": FragmentRefs<"LegalDocument_document" | "useLegalDocument_document">;
    };
  } | null | undefined;
};
export type LegalScreenQuery = {
  response: LegalScreenQuery$data;
  variables: LegalScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filename"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filename",
    "variableName": "filename"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LegalScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConsentStatus",
        "kind": "LinkedField",
        "name": "consentStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConsentDocument",
            "kind": "LinkedField",
            "name": "consent",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LegalDocument_consent"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalDocument",
            "kind": "LinkedField",
            "name": "legalDocument",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useLegalDocument_document"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LegalDocument_document"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LegalScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConsentStatus",
        "kind": "LinkedField",
        "name": "consentStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConsentDocument",
            "kind": "LinkedField",
            "name": "consent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "consentedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "consentedIp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalDocument",
            "kind": "LinkedField",
            "name": "legalDocument",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "format",
                "storageKey": null
              },
              {
                "alias": "source",
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:SQLdxJbHwH_btkKocgubFgn_FvePBLIo6Be8mN7GIh0",
    "metadata": {},
    "name": "LegalScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ee98064a7d3e8a696c5ea2be2a652b58";

export default node;
