import React, { useContext } from "react";

export interface AppSettingsType {
  appName: string;
  appId: "requester" | "worker" | "admin" | "other";
  onRequestUpdate?: () => Promise<void>;
}

const Context = React.createContext<AppSettingsType>({
  appName: "",
  appId: "other"
});

export const AppSettingsProvider = Context.Provider;

export const useAppSettings = () => useContext(Context);
