import React from "react";

import { IconCircle, Stack, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { StyledView } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { hiringCompanyCard_organization$key } from "./__generated__/hiringCompanyCard_organization.graphql";

interface Props {
  organization: hiringCompanyCard_organization$key | null | undefined;
}

export default function HiringCompanyCard({ organization: orgRef }: Props) {
  const organization = useRelayFragment<hiringCompanyCard_organization$key>(
    graphql`
      fragment hiringCompanyCard_organization on Organization {
        name
        logoUrl
        industry
      }
    `,
    orgRef
  );
  const styles = useStyles(() => ({
    card: {
      zIndex: 2,
      elevation: 2
    }
  }));
  return (
    <StyledView shadow style={styles.card}>
      <Stack horizontal>
        <IconCircle color="primary" size="medium" icon="building" />
        <Stack justifyContent="center" size="slim" fill>
          <Text color="primary" weight="bold" variant="bodyLg">
            {organization?.name}
          </Text>
          <Text variant="bodyLg" color="neutral">
            Industry: {organization?.industry}
          </Text>
        </Stack>
      </Stack>
    </StyledView>
  );
}
