import { type IconName, Stack, Text } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";
import React from "react";
import QualificationCategoryRow from "../category-position-qualification/QualificationCategoryRow";
import type { SkillCategories_categoriesScreen$key } from "./__generated__/SkillCategories_categoriesScreen.graphql";

interface Props {
  onCategoryPress?: (workerCategoryId: string, categoryId: string) => void;
  isOnboarding?: boolean;
}

export const SkillCategories = ({
  workerCategories,
  onCategoryPress,
  isOnboarding
}: FragmentContainerInnerComponentProps<
  SkillCategories_categoriesScreen$key,
  Props
>) => {
  const history = useHistory();
  const categories = getConnectionEdges(workerCategories);

  return (
    <Stack>
      <Text>
        Select Categories & Positions you are interested in and able to work.
        The same Position may be under multiple Categories so be sure to check
        each Category accordingly.{" "}
        {isOnboarding && "These can be changed in the future in your Profile."}
      </Text>
      <Stack size="compact">
        {categories?.map(({ node, association }) => {
          const title = node?.displayName;
          const iconName = node?.categoryIcon?.data as IconName;
          const workerCategoryId = association?.id ?? "";
          const categoryId = node?.id ?? "";

          return (
            <QualificationCategoryRow
              key={title}
              icon={iconName}
              title={title ?? ""}
              selectedItems={association?.confirmedPositions ?? 0}
              availableItems={
                (association?.unknownPositions ?? 0) +
                (association?.deniedPositions ?? 0)
              }
              onPress={() =>
                onCategoryPress
                  ? onCategoryPress(workerCategoryId, categoryId)
                  : history.push(
                      `/profile/categories/${workerCategoryId}/positions/${categoryId}`
                    )
              }
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default createRelayFragmentContainer<
  SkillCategories_categoriesScreen$key,
  Props
>(
  graphql`
    fragment SkillCategories_categoriesScreen on Worker {
      workerCategories(first: 20, query: "ORDER BY priority ASC") {
        edges {
          association {
            id
            confirmedPositions
            unknownPositions
            deniedPositions
          }
          node {
            id
            name
            displayName
            categoryIcon {
              type
              data
            }
          }
        }
      }
    }
  `,
  SkillCategories
);
