/**
 * @generated SignedSource<<989156d0ed14f3ab495cb40891174a29>>
 * @relayHash c1eb80406c738174aeaff85394fb2faf
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ZbSxhRNdbSDj8xLiRFIp6CN8scWDMutn5FI6hCMGkZQ

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageCenterScreenWorkerQuery$variables = {
  activeQuery: string;
  inactiveQuery: string;
};
export type messageCenterScreenWorkerQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ConversationStateTabs_user" | "RequesterMessageCenterTabs_user">;
  } | null | undefined;
};
export type messageCenterScreenWorkerQuery = {
  response: messageCenterScreenWorkerQuery$data;
  variables: messageCenterScreenWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "activeQuery"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inactiveQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "messageCenterScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RequesterMessageCenterTabs_user"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "activeQuery",
                "variableName": "activeQuery"
              },
              {
                "kind": "Variable",
                "name": "inactiveQuery",
                "variableName": "inactiveQuery"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ConversationStateTabs_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "messageCenterScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "conversationTypes",
                "value": [
                  "ENGAGEMENT",
                  "ORGANIZATION_WORKER"
                ]
              }
            ],
            "concreteType": "UserMessageStats",
            "kind": "LinkedField",
            "name": "userMessageStats",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unreadCount",
                "storageKey": null
              }
            ],
            "storageKey": "userMessageStats(conversationTypes:[\"ENGAGEMENT\",\"ORGANIZATION_WORKER\"])"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": "orgActive",
                    "args": [
                      (v2/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "query",
                        "variableName": "activeQuery"
                      }
                    ],
                    "concreteType": "ConversationsConnection",
                    "kind": "LinkedField",
                    "name": "conversations",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": "orgInactive",
                    "args": [
                      (v2/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "query",
                        "variableName": "inactiveQuery"
                      }
                    ],
                    "concreteType": "ConversationsConnection",
                    "kind": "LinkedField",
                    "name": "conversations",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "OrganizationRequester",
            "abstractKey": null
          },
          {
            "alias": "active",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "query",
                "value": "WHERE status = OPEN AND conversationType != SHIFT_GROUP"
              }
            ],
            "concreteType": "ConversationsConnection",
            "kind": "LinkedField",
            "name": "conversations",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "conversations(first:0,query:\"WHERE status = OPEN AND conversationType != SHIFT_GROUP\")"
          },
          {
            "alias": "inactive",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "query",
                "value": "WHERE status = CLOSED AND conversationType != SHIFT_GROUP"
              }
            ],
            "concreteType": "ConversationsConnection",
            "kind": "LinkedField",
            "name": "conversations",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "conversations(first:0,query:\"WHERE status = CLOSED AND conversationType != SHIFT_GROUP\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ZbSxhRNdbSDj8xLiRFIp6CN8scWDMutn5FI6hCMGkZQ",
    "metadata": {},
    "name": "messageCenterScreenWorkerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "07fad1a1760e2efff2d3f4400f4225cc";

export default node;
