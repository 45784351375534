import * as React from "react";

export interface ContextValues {
  onElementLayout: (elementName: string, event: any) => void;
  scrollToElement: (elementName: string) => void;
  scrollToTop: () => void;
  scrollToEnd: () => void;
}

export default React.createContext<ContextValues>({
  onElementLayout(_elementName: string, _event: any) {},
  scrollToElement(_elementName: string) {},
  scrollToTop() {},
  scrollToEnd() {}
});
