/**
 * @generated SignedSource<<d993c847828960d57660e669e885ecde>>
 * @relayHash 9d439648cca705994c38fb87827c5702
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:tHUl7Tq0AXlhuIfZHDvo-Zr0ZYKKOTbrc5ac7cW8S1k

import { ConcreteRequest } from 'relay-runtime';
export type AvailablePickupShiftsCardQuery$variables = Record<PropertyKey, never>;
export type AvailablePickupShiftsCardQuery$data = {
  readonly viewer: {
    readonly availableGigs?: {
      readonly edges: ReadonlyArray<{
        readonly estimatedPayment: {
          readonly netPay: string;
          readonly payRate: string;
        } | null | undefined;
        readonly node: {
          readonly gigSeries: {
            readonly boosted: boolean;
            readonly id: string;
          };
          readonly id: string;
          readonly name: string | null | undefined;
          readonly organization: {
            readonly logoUrl: string | null | undefined;
          } | null | undefined;
          readonly pickupEligible: boolean;
          readonly position: {
            readonly name: string;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type AvailablePickupShiftsCardQuery = {
  response: AvailablePickupShiftsCardQuery$data;
  variables: AvailablePickupShiftsCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Literal",
    "name": "gigTypes",
    "value": [
      "PAID",
      "VOLUNTEER"
    ]
  },
  {
    "kind": "Literal",
    "name": "maxDistance",
    "value": 75
  },
  {
    "kind": "Literal",
    "name": "pickupEligible",
    "value": true
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupEligible",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "GigSeries",
  "kind": "LinkedField",
  "name": "gigSeries",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boosted",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AvailablePickupShiftsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "AvailableGigsConnection",
                "kind": "LinkedField",
                "name": "availableGigs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigPosition",
                            "kind": "LinkedField",
                            "name": "position",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "availableGigs(first:3,gigTypes:[\"PAID\",\"VOLUNTEER\"],maxDistance:75,pickupEligible:true)"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AvailablePickupShiftsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "AvailableGigsConnection",
                "kind": "LinkedField",
                "name": "availableGigs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigPosition",
                            "kind": "LinkedField",
                            "name": "position",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "availableGigs(first:3,gigTypes:[\"PAID\",\"VOLUNTEER\"],maxDistance:75,pickupEligible:true)"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:tHUl7Tq0AXlhuIfZHDvo-Zr0ZYKKOTbrc5ac7cW8S1k",
    "metadata": {},
    "name": "AvailablePickupShiftsCardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "60cbaba8c4050208bfa7a42e0fd7ae8f";

export default node;
