import { toast } from "@gigsmart/atorasu";
import {
  GIG_ACTION_MAP,
  type InternalAction,
  confirmAction,
  getActionIconName,
  getGigActionMap
} from "@gigsmart/isomorphic-shared/engagement/gig-action-map";
import type { EngagementStateAction } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  graphql,
  readRelayInlineFragment,
  useRelayMutation
} from "@gigsmart/relay";
import { compact, sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { showRejectGigPositionPrompt } from "../../gig/showRejectGigPositionPrompt";
import { showMarkArrivalConfirmationModal } from "../../location-tracking/MarkArrivalConfirmationModal";
import type { useEngagementTransitionMutation } from "./__generated__/useEngagementTransitionMutation.graphql";
import type {
  EngagementCapabilityType,
  useEngagementTransition_engagement$key
} from "./__generated__/useEngagementTransition_engagement.graphql";

const CAPABILITY_ORDER = new Map<EngagementCapabilityType, number>([
  ["START", 1],
  ["PAUSE", 2],
  ["RESUME", 3],
  ["REQUEST_START", 4],
  ["ARRIVE", 5],
  ["EMBARK", 6],
  ["MARK_AS_COMPLETE", 7],
  ["END", 8],

  ["REVIEW", 9],
  ["REJECT", 10],
  ["BID", 11],
  ["NEGOTIATE", 12],
  ["COUNTER_BID", 13],
  ["MODIFY_BID", 14],
  ["ACCEPT", 15],

  ["DECLINE_COMMITMENT", 100],
  ["ACCEPT_COMMITMENT", 110]
]);

export default function useEngagementTransition(engagementId: string) {
  const [transitionEngagement, { loading, errors }] =
    useRelayMutation<useEngagementTransitionMutation>(
      graphql`
        mutation useEngagementTransitionMutation($input: TransitionEngagementInput!) {
          transitionEngagement(input: $input) {
            engagement {
              id
              gig {
                primaryContact {
                  displayName
                }
              }
              currentState {
                name
                action
                transitionedAt
              }
              capabilities {
                type
                status
              }
            }
          }
        }
      `
    );

  const handleAction = useCallback(
    (key: InternalAction, actionSpec = GIG_ACTION_MAP[key]) => {
      if (!actionSpec || !engagementId) return;
      if (actionSpec.onPress) return actionSpec.onPress(key);

      confirmAction(actionSpec, key, async (dismiss) => {
        const input = { engagementId, action: key as EngagementStateAction };
        const handleTransition = () =>
          transitionEngagement(
            { input },
            {
              onSuccess: (res) => {
                const engagement = res.transitionEngagement?.engagement;
                const receiverName =
                  engagement?.gig?.primaryContact?.displayName ?? "";
                const toastMessage = actionSpec?.toastMessage?.(receiverName);

                if (toastMessage) {
                  toast.toast(toastMessage, {
                    color: actionSpec.color,
                    quick: actionSpec.quickToast,
                    testID: "gig-action-success"
                  });
                }

                actionSpec.onSuccess?.();
                dismiss?.();
              }
            }
          );

        if (key === "REQUEST_START") {
          showMarkArrivalConfirmationModal({ onMarkArrival: handleTransition });
        } else {
          handleTransition();
        }
      });
    },
    [engagementId, loading]
  );

  return [handleAction, { loading, errors }] as const;
}

type WorkerActionsOptions = {
  engagementRef?: useEngagementTransition_engagement$key | null;
  validActions?: EngagementCapabilityType[];
};

export function useEngagementWorkerActions({
  engagementRef,
  validActions
}: WorkerActionsOptions) {
  // FIXME: Replace with navigation
  const history = useHistory();
  const { id, gigType, currentState, worker, capabilities } =
    readRelayInlineFragment(
      graphql`
      fragment useEngagementTransition_engagement on Engagement @inline {
        id
        gigType
        currentState {
          name
        }
        worker {
          transportationMethod
        }
        capabilities {
          type
          status
        }

      }
    `,
      engagementRef ?? null
    ) ?? {};

  return useMemo(() => {
    if (!id) return [];

    const isProject = gigType === "PROJECT";
    const actionMap = getGigActionMap({
      EMBARK: {
        icon: getActionIconName(
          worker?.transportationMethod,
          currentState?.name
        )
      },
      BID: {
        label: "Start a Bid",
        icon: "circle-dollar-to-slot",
        testID: "start-bid-gig",
        onPress: () => history.push(`/gigs/${id}/accept`)
      },
      REBID: {
        // State after canceling and trying to bid again;
        label: "Start a Bid",
        icon: "circle-dollar-to-slot",
        testID: "start-bid-gig",
        onPress: () => history.push(`/shifts/${id}/negotiate`)
      },
      COUNTER_BID: {
        label: "Counter-Bid",
        icon: "comment-dollar",
        testID: "counter-bid-gig",
        onPress: () => history.push(`/shifts/${id}/negotiate`)
      },
      MODIFY_BID: {
        label: "Edit Bid",
        icon: "pen-to-square",
        testID: "edit-bid-gig",
        buttonVariant: "outline",
        onPress: () => history.push(`/shifts/${id}/negotiate`)
      },
      ACCEPT: {
        label: isProject ? "Accept Project" : "Accept Shift",
        icon: "check",
        testID: "accept-gig",
        color: "success",
        onPress: () => history.push(`/gigs/${id}/accept`)
      },
      REJECT: isProject
        ? {
            toastMessage: () =>
              "The Project Gig Offer was successfully rejected",
            confirmation: {
              title: "Reject Project Gig Offer",
              subTitle:
                "Rejecting this offer is final and you will not be able to get hired for this Project Gig after.",
              yesLabel: "Reject",
              cancelLabel: "Do Not Reject"
            }
          }
        : currentState?.name === "BID_REQUESTED"
          ? {
              confirmationTitle: "Reject Bid Request",
              confirmation: "Are you sure you want to reject this Bid Request?",
              onSuccess: () => showRejectGigPositionPrompt({ id })
            }
          : currentState?.name === "OFFERED"
            ? {
                confirmationTitle: "Reject Offer",
                confirmation: "Are you sure you want to reject this Offer?",
                onSuccess: () => showRejectGigPositionPrompt({ id })
              }
            : {
                label: "Reject Shift",
                onSuccess: () => showRejectGigPositionPrompt({ id })
              },
      MARK_AS_COMPLETE: {
        label: "Mark Project as Complete",
        icon: "circle-check",
        testID: "end-gig",
        color: "primary",
        onPress: () => history.push(`/gigs/${id}/mark-as-complete`)
      },
      REVIEW: {
        label: "Rate & Review",
        icon: "star",
        testID: "review-gig",
        color: "primary",
        onPress: () => history.push(`/gigs/${id}/rate-and-review`)
      }
    });

    return compact(
      sortBy(capabilities, (it) => CAPABILITY_ORDER.get(it?.type) ?? 0)
        .filter(
          (it) =>
            it?.type !== "CANCEL" &&
            it?.status === "AVAILABLE" &&
            (!validActions || validActions.includes(it.type))
        )
        .map((it) => {
          const actionSpec = actionMap[it?.type];
          return actionSpec ? { actionSpec, capability: it.type } : null;
        })
    );
  }, [capabilities]);
}
