import {
  type IconName,
  type IconVariant,
  type MediaPickerFile,
  showMediaPicker
} from "@gigsmart/atorasu";
import { UpdatedAvatar } from "@gigsmart/katana";
import React, { useEffect, useState } from "react";

type Props = {
  onOpen?: () => void;
  onChange?: (value?: string | null) => void;
  value?: MediaPickerFile | null;
  width: number;
  align?: "center" | "left";
  placeholderIcon?: IconName;
  placeholderIconVariant?: IconVariant;
};

export default function AvatarPicker({
  width,
  value,
  onChange,
  onOpen,
  ...rest
}: Props) {
  const handleCameraPress = () => {
    if (onOpen) onOpen();
    else {
      showMediaPicker({
        front: true,
        crop: "circle",
        preview: "circle",
        onSelect: ([uri]) => onChange?.(uri)
      });
    }
  };

  const previewUrl = useImagePreview(value);
  return (
    <UpdatedAvatar
      {...rest}
      onIconPress={handleCameraPress}
      sourceUri={previewUrl}
      width={width}
      testID="camera-icon"
    />
  );
}

export function useImagePreview(value?: MediaPickerFile | null) {
  const [preview, setPreview] = useState(() => previewFromValue(value));
  useEffect(() => {
    asyncPreviewFromValue(value).then(setPreview);
  }, [value]);
  return preview;
}

function previewFromValue(value?: MediaPickerFile | null) {
  if (value) {
    if (typeof value === "string") return value;
    if ("url" in value) return value.url as string;
    if ("uri" in value) return value.uri;
  }
  return null;
}

async function asyncPreviewFromValue(value?: MediaPickerFile | null) {
  if (!value) return null;

  const previewStr = previewFromValue(value);
  if (previewStr) return previewStr;

  return new Promise<string | null>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(value as Blob);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = () => resolve(null);
  });
}
