/**
 * @generated SignedSource<<cf895bebe0606ead4dbce31b3ef41c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerEngagementCardV2_engagement$data = {
  readonly capabilities: ReadonlyArray<{
    readonly expiresAt: string | null | undefined;
    readonly restrictedBy: {
      readonly message: string;
    } | null | undefined;
    readonly status: EngagementCapabilityStatus;
    readonly type: EngagementCapabilityType;
  }>;
  readonly currentNegotiation: {
    readonly payRate: string | null | undefined;
  } | null | undefined;
  readonly currentState: {
    readonly action: EngagementStateAction;
    readonly name: EngagementStateName;
  };
  readonly disputes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly expiresAt: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly estimatedPayment: {
    readonly netPay: string;
    readonly payRate: string;
  } | null | undefined;
  readonly gig: {
    readonly isFilled: boolean | null | undefined;
    readonly payRate: string | null | undefined;
    readonly reconcilesAt: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftCardBody_gig" | "WorkerShiftCardFooter_gigLike" | "WorkerShiftCardHeader_gigLike">;
  };
  readonly id: string;
  readonly paymentInfo: {
    readonly netPay: string;
  } | null | undefined;
  readonly pickupStateCount: {
    readonly states: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerEngagementCardHeader_engagement">;
  readonly " $fragmentType": "WorkerEngagementCardV2_engagement";
};
export type WorkerEngagementCardV2_engagement$key = {
  readonly " $data"?: WorkerEngagementCardV2_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerEngagementCardV2_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerEngagementCardV2_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerEngagementCardHeader_engagement"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "estimatedPayment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementNegotiation",
      "kind": "LinkedField",
      "name": "currentNegotiation",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkerShiftCardHeader_gigLike"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkerShiftCardFooter_gigLike"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkerShiftCardBody_gig"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reconcilesAt",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFilled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE approvalDisposition = NULL"
        }
      ],
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementDisputesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDispute",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
    },
    {
      "alias": "pickupStateCount",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "SYSTEM"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 0
            },
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE action = PICKUP"
            }
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": "states(first:0,query:\"WHERE action = PICKUP\")"
        }
      ],
      "storageKey": "timesheet(variant:\"SYSTEM\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementCapability",
      "kind": "LinkedField",
      "name": "capabilities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "restrictedBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "80ebcef9b97a215818169f7d20fd1023";

export default node;
