import { useRelayOrchestrator } from "@gigsmart/relay";
import { uniqWith } from "lodash";
import { useMemo } from "react";
import { graphql } from "relay-runtime";
import useLatestCallback from "use-latest-callback";
import { v4 } from "uuid";
import { useCurrentUser } from "../current-user";
import type {
  GeocodeLayer,
  PlaceProvider,
  useLocationAutocompleteSilentQuery
} from "./__generated__/useLocationAutocompleteSilentQuery.graphql";
import { useCurrentLocation } from "./hooks";

export type Location = NonNullable<
  useLocationAutocompleteSilentQuery["response"]
>["placeAutocomplete"][number];

export function useLocationAutocomplete(
  layer: GeocodeLayer = "PLACE",
  provider?: PlaceProvider
) {
  const user = useCurrentUser();
  const sessionId = useMemo(v4, []);
  const { fetchQuery } = useRelayOrchestrator();
  if (!provider && user?.__typename === "Admin") provider = "ALL";

  const { latitude, longitude } = useCurrentLocation(true)?.coords ?? {};
  return useLatestCallback(async (search: string) => {
    const res = await fetchQuery<useLocationAutocompleteSilentQuery>(
      graphql`
        query useLocationAutocompleteSilentQuery($input: PlaceAutocompleteInput!) {
          placeAutocomplete(input: $input) {
            placeId
            label
            place {
              location {
                latitude
                longitude
              }
            }
          }
        }
      `,
      {
        input: {
          sessionId,
          search,
          layer,
          near: latitude && longitude ? { latitude, longitude } : null,
          provider
        }
      }
    );

    return uniqWith(
      res.placeAutocomplete.map((data) => ({ label: data.label, data })),
      (a, b) => a.label === b.label
    );
  });
}
