import { FooterSpacer, ScreenScroll, Stack } from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ChangeEmailFormCard from "@gigsmart/seibutsu/worker-profile/ChangeEmailFormCard";
import ChangePasswordFormCard from "@gigsmart/seibutsu/worker-profile/ChangePasswordFormCard";
import React from "react";
import type { accountScreenQuery } from "./__generated__/accountScreenQuery.graphql";

export default createSuspendedQueryContainer<accountScreenQuery>(
  function AccountScreen({ response: { viewer } = {} }) {
    return (
      <ScreenScroll testID="account-screen">
        <Stack>
          <ChangeEmailFormCard fragmentRef={viewer?.primaryEmail} />
          <ChangePasswordFormCard />
        </Stack>
        <FooterSpacer />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query accountScreenQuery {
        viewer {
          ... on Worker {
            primaryEmail {
              ...ChangeEmailFormCard_userEmail
            }
          }
        }
      }
    `,
    variables: {}
  }
);
