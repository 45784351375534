import { toast } from "@gigsmart/atorasu";
import { Screen } from "@gigsmart/atorasu";
import type { ValueObject } from "@gigsmart/fomu";
import { useHistory } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";

import useUpdateWorkerMutation from "@gigsmart/seibutsu/user/Onboarding/hooks/use-update-worker-mutation";
import TransportationMethodForm from "./transportation-method-form";

import type { transportationMethodScreenQuery } from "./__generated__/transportationMethodScreenQuery.graphql";

export default createSuspendedQueryContainer<transportationMethodScreenQuery>(
  function TransportationMethodScreen({ response: { viewer } = {} }) {
    const updateWorker = useUpdateWorkerMutation();
    const history = useHistory();

    const handleSubmit = async (values: ValueObject) => {
      await updateWorker(values);
      toast.success("Your method of transportation has been updated");
      history.go(-1);
    };

    return (
      <Screen testID="worker-profile-manage-transport-screen" color="surface">
        <TransportationMethodForm
          initialValues={{
            transportationMethod: viewer?.transportationMethod,
            transportationState: viewer?.transportationState
          }}
          subtitle="This is used to help us estimate when you'll arrive to your Gig. You can update your primary method of transportation at any time."
          submitLabel="Update"
          onSubmit={async ({ values }) => await handleSubmit(values)}
        />
      </Screen>
    );
  },
  {
    query: graphql`
      query transportationMethodScreenQuery {
        viewer {
          ... on Worker {
            transportationMethod
            transportationState
          }
        }
      }
    `,
    variables: {}
  }
);
