import { Divider, type IconName, Spacer, Stack } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import { readWorkerEnrolledMetadata } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useApplyMetadata";
import { useEnrollmentDataFragment } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentData";
import SummaryListRow from "@gigsmart/seibutsu/gig/SummaryRows/SummaryListRow";
import RequesterInfoRow from "@gigsmart/seibutsu/requester/RequesterInfoRow";
import WorkerShiftApplets from "@gigsmart/seibutsu/shifts/WorkerShiftApplets";
import React from "react";
import SeriesBulkActionRow from "../../gig-series/cards/series-bulk-action-row";
import EngagementDetailsCard from "./EngagementDetailsCard";
import type {
  engagementDetailsHeader_engagement$data,
  engagementDetailsHeader_engagement$key
} from "./__generated__/engagementDetailsHeader_engagement.graphql";
import EngagementDetailsActionRow from "./action-row";
import GigRequesterRow from "./gig-requester-row";
import ProjectAppliedHeader from "./project-applied-header";

interface Props {
  shiftsCount?: number;
  engagementRef: engagementDetailsHeader_engagement$key | null | undefined;
}

const EngagementDetailsHeader = ({ shiftsCount = 1, engagementRef }: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment engagementDetailsHeader_engagement on Engagement {
        ...EngagementDetailsCard_engagement
        ...projectAppliedHeader_engagement
        ...actionRow_engagement
        ...useApplyMetadataIsEnrolled_gigOrEngagement
        ...WorkerShiftApplets_shift
        id
        gig {
          ...gigRequesterRow_gigLike
          ...RequesterInfoRow_gigLike
          gigSeries {
            id
          }
          position {
            id
          }
        }
        gigType
        currentState {
          name
          action
          transitionedBy {
            __typename
          }
        }
        worker {
          id
          ...useEnrollmentData_worker
        }
        states(first: 1, query: "WHERE name=SCHEDULED") {
          totalCount
        }
        cancellationReason {
          authoredBy
          reasonType
          disqualifications(first: 50) {
            totalCount
            edges {
              node {
                gigFieldItemDefinition {
                  label
                  gigField {
                    id
                    title
                    iconName
                  }
                }
              }
            }
          }
        }
        reviewOfRequester {
          rating
        }
      }
    `,
    engagementRef
  );
  const isProject = engagement?.gigType === "PROJECT";

  const stateName = engagement?.currentState?.name;
  switch (stateName) {
    case "CANCELED":
    case "CANCELED_WITH_PAY":
    case "APPLICATION_CANCELED":
    case "APPLICATION_DENIED":
    case "REJECTED":
    case "MISSED":
      return (
        <RequesterInfoRow
          fragmentRef={engagement?.gig}
          engagementId={engagement?.id}
          workerId={engagement?.worker?.id}
          infoHeader={getHeaderMessage(engagement)[0]}
          infoBody={getHeaderMessage(engagement)[1]}
          showViewProfile={!isProject}
        />
      );
    case "ENDED":
      return !engagement?.reviewOfRequester?.rating ? (
        <Header engagement={engagement} shiftsCount={shiftsCount} />
      ) : (
        <GigRequesterRow
          workerId={engagement?.worker?.id}
          gigLikeRef={engagement?.gig}
          engagementId={engagement?.id}
        />
      );
    default:
      if (stateName === "APPLIED" && engagement?.gigType === "PROJECT") {
        return <ProjectAppliedHeader engagementRef={engagement} />;
      }
      return <Header engagement={engagement} shiftsCount={shiftsCount} />;
  }
};

type HeaderProps = {
  engagement: engagementDetailsHeader_engagement$data | null | undefined;
  shiftsCount: number;
};

function Header({ engagement, shiftsCount }: HeaderProps) {
  const seriesId = engagement?.gig?.gigSeries?.id;
  const positionId = engagement?.gig.position?.id;
  const workerId = engagement?.worker?.id;
  const showBulkActions = !!seriesId && !!workerId && shiftsCount > 1;

  const enrollmentData = useEnrollmentDataFragment(engagement?.worker);
  const { doesNotRequireMoreInfo } = readWorkerEnrolledMetadata(
    enrollmentData,
    engagement
  );
  const action = showBulkActions ? (
    <SeriesBulkActionRow
      seriesId={seriesId}
      positionId={positionId}
      workerId={workerId}
      shiftsCount={shiftsCount}
      isEnrolled={doesNotRequireMoreInfo}
    />
  ) : (
    <EngagementDetailsActionRow engagementRef={engagement} />
  );

  return (
    <>
      <EngagementDetailsCard fragmentRef={engagement} actionNode={action} />
      <WorkerShiftApplets showNote fragmentRef={engagement} />
    </>
  );
}

export default EngagementDetailsHeader;

/**
 * getHeaderMessage
 * @param engagement
 * @returns {[string, string]} Tuple where index 0 is header and 1 is body
 */
const getHeaderMessage = (
  engagement: engagementDetailsHeader_engagement$data | null | undefined
) => {
  const reason = engagement?.cancellationReason;
  const isProject = engagement?.gigType === "PROJECT";
  const wasEngagementScheduled = !!engagement?.states?.totalCount;
  const {
    name: stateName,
    transitionedBy,
    action
  } = engagement?.currentState ?? {};
  switch (stateName) {
    case "CANCELED":
      if (transitionedBy?.__typename === "Worker") {
        return isProject
          ? ["You canceled yourself from this Gig after hire."]
          : ["You canceled yourself from this Shift after hire"];
      }
      if (
        transitionedBy?.__typename === "Requester" ||
        reason?.authoredBy === "REQUESTER"
      ) {
        if (reason?.reasonType === "REPORT_NO_SHOW") {
          return ["Requester marked you as a no-show."];
        }
        if (reason?.reasonType === "ISSUE_WITH_WORKER") {
          return ["", "The requester canceled you from the Gig."];
        }
        if ((reason?.disqualifications?.totalCount ?? 0) > 0) {
          const disqualificationCategories = getConnectionNodes(
            engagement?.cancellationReason?.disqualifications,
            (it) => it.gigFieldItemDefinition.gigField
          );
          const body = (
            <>
              <Spacer size="medium" />
              <Divider />
              <Stack variant="divider">
                {disqualificationCategories?.map((cat, index) => (
                  <SummaryListRow
                    key={index}
                    icon={cat?.iconName as IconName}
                    title={cat?.title}
                    listVariant="bullet"
                  >
                    {getConnectionNodes(
                      engagement?.cancellationReason?.disqualifications
                    )
                      ?.filter(
                        (d) =>
                          d?.gigFieldItemDefinition?.gigField?.id === cat.id
                      )
                      ?.map((d) => d.gigFieldItemDefinition.label)}
                  </SummaryListRow>
                ))}
              </Stack>
              <Divider />
            </>
          );
          return [
            "You were canceled from the Shift as you did not meet the following Gig Qualifications:",
            body
          ];
        }

        if (!wasEngagementScheduled && !isProject) {
          return ["You've missed the Requester's Offer."];
        }
        return isProject
          ? ["The Requester canceled you from this Gig after hire.", ""]
          : ["The Requester canceled you from this Shift after hire.", ""];
      }
      return action === "TIMEOUT_CONFIRMATION"
        ? [
            "",
            "There was an issue with your verification that was not resolved prior to the Gig start time"
          ]
        : isProject
          ? ["", "The Project Gig was canceled before it started."]
          : ["", "The Shift Gig was canceled before it started"];
    case "APPLICATION_DENIED":
      return [
        "You were not Hired on this Gig",
        "It will not be counted against your no-show or cancellation rate."
      ];
    case "APPLICATION_CANCELED":
      return transitionedBy?.__typename === "Requester"
        ? ["", "You were canceled from this Gig."]
        : [
            HourlyRateBids.select(
              "You’ve withdrawn your bid.",
              "You've withdrawn this application."
            )
          ];
    case "REJECTED":
      return isProject
        ? ["You've rejected this Gig offer."]
        : ["You've rejected this Shift offer."];
    case "MISSED":
      return ["You've missed the Requester's Offer."];
    case "CANCELED_WITH_PAY":
      return [
        "The Requester canceled you off this Shift.",
        "You have been compensated for this cancellation. View the Summary for more details."
      ];
    default:
      return isProject
        ? ["", "This Project Gig is no longer available"]
        : ["", "This Gig is no longer available"];
  }
};
