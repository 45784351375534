import type { AppScreenProps } from "@gigsmart/kaizoku";
import Screen from "@gigsmart/seibutsu/layouts/Screen";
import MessageList from "@gigsmart/seibutsu/user-message/MessageList";
import React from "react";
import type { WorkerParamList } from "../navigation/types";

type Props = AppScreenProps<WorkerParamList, "Conversation">;

export default function ConversationScreen({ route }: Props) {
  const { topicId, contextId } = route.params;
  return (
    <Screen testID="conversation-screen">
      {topicId && (
        <MessageList
          variant="worker"
          contextId={contextId}
          conversationId={topicId}
        />
      )}
    </Screen>
  );
}
