import React, { useState, useRef } from "react";

import {
  Button,
  ContentArea,
  Divider,
  HighlightedReminder,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { Validator, useFormValue } from "@gigsmart/fomu";
import ClockSvg from "../Brand/ClockSvg";
import InfoSvg from "../Brand/InfoSvg";
import PriceTagSvg from "../Brand/PriceTagSvg";
import FomuCommentInput, {
  type ForwardRefProps as FomuCommentInputRefProps
} from "../fomu/inputs/FomuCommentInput";
import FomuMoneyInput from "../fomu/inputs/FomuMoneyInput";
import FomuRadioButtonGroup from "../fomu/inputs/FomuRadioButtonGroup";
import FomuTextInput from "../fomu/inputs/FomuTextInput";

const SCHEDULE_TITLE = {
  HOURLY: "I want to submit my bid as an hourly rate.",
  FIXED: "I want to submit my bid as a flat rate.",
  INFO_REQUIRED: "I need to ask for more information before submitting my bid."
};

interface Props {
  note?: string | null;
}

export default function EngagementBidForm({ note }: Props) {
  const { value: paySchedule } = useFormValue<
    "HOURLY" | "FIXED" | "INFO_REQUIRED"
  >({
    name: "paySchedule"
  });
  const [showNote, setShowNote] = useState(!!note);
  const noteRef = useRef<FomuCommentInputRefProps>();
  return (
    <Stack>
      <Surface>
        <ContentArea size="compact">
          <Spacer size="compact" />
          <Stack>
            <FomuRadioButtonGroup
              name="paySchedule"
              required
              onChange={() => {
                noteRef?.current?.destroy();
                setShowNote(false);
              }}
              variant="svg"
              buttons={[
                {
                  title: "Hourly Rate",
                  svg: <ClockSvg />,
                  value: "HOURLY",
                  testID: "hourly-radio-option"
                },
                {
                  title: "Flat Rate",
                  svg: <PriceTagSvg />,
                  value: "FIXED",
                  testID: "fixed-radio-option"
                },
                {
                  title: "Need Info",
                  svg: <InfoSvg />,
                  value: "INFO_REQUIRED",
                  testID: "info-required-radio-option"
                }
              ]}
            />
            {!paySchedule && (
              <>
                <Text>Please select an option.</Text>
                <Spacer size="compact" />
              </>
            )}
            {paySchedule && (
              <>
                <Divider />
                <Stack size="compact">
                  <Spacer />
                  <Text weight="bold" align="center">
                    {SCHEDULE_TITLE[paySchedule]}
                  </Text>
                  {paySchedule !== "INFO_REQUIRED" && (
                    <ContentArea>
                      {paySchedule === "HOURLY" && (
                        <Stack size="compact">
                          <FomuMoneyInput
                            horizontal
                            name="payRate"
                            variant="hourly"
                            label="Hourly Rate"
                            min={5}
                            max={500}
                          />
                          <FomuTextInput
                            horizontal
                            type="number"
                            label="Estimated Time to Complete (Hours)"
                            placeholder="0"
                            name="expectedDuration"
                            validates={[
                              Validator.maxNumber({
                                max: 500,
                                message:
                                  "Estimated Time cannot exceed 500 hours"
                              }),
                              Validator.minNumber({
                                min: 1,
                                message:
                                  "Estimated Time cannot be less than 1 hour"
                              })
                            ]}
                          />
                        </Stack>
                      )}
                      {paySchedule === "FIXED" && (
                        <FomuMoneyInput
                          horizontal
                          name="estimatedAmount"
                          label="Flat Rate"
                          min={5}
                          max={100000}
                        />
                      )}
                    </ContentArea>
                  )}
                </Stack>
                {paySchedule !== "INFO_REQUIRED" && <Divider />}
                {showNote || paySchedule === "INFO_REQUIRED" ? (
                  <>
                    <Spacer />
                    <FomuCommentInput
                      ref={noteRef}
                      name="note"
                      removeable={paySchedule !== "INFO_REQUIRED"}
                      label={
                        paySchedule === "INFO_REQUIRED"
                          ? "What other information do you need?"
                          : "Leave a note about your bid"
                      }
                      placeholder={
                        paySchedule === "INFO_REQUIRED"
                          ? "Request more information from the Requester so you can finalize your bid."
                          : "Share more details about your bid or include clarifying notes or questions regarding the Project."
                      }
                      max={1500}
                      min={paySchedule === "INFO_REQUIRED" ? 5 : undefined}
                      required={paySchedule === "INFO_REQUIRED"}
                      onRemove={() => setShowNote(false)}
                      removePrompt={{
                        title: "Delete Bid Note",
                        subTitle:
                          "Are you sure you want to delete the note about your bid?",
                        yesLabel: "Delete Bid Note",
                        cancelLabel: "Do Not Delete Bid Note"
                      }}
                    />
                    <Spacer size="compact" />
                  </>
                ) : (
                  <>
                    <Spacer size="compact" />
                    <Button
                      testID="add-note-button"
                      variant="clear"
                      size="small"
                      icon="plus"
                      label="Add Note About My Bid"
                      onPress={() => setShowNote(true)}
                    />
                  </>
                )}
              </>
            )}
          </Stack>
        </ContentArea>
      </Surface>
      <ContentArea size="none">
        <HighlightedReminder
          icon="circle-exclamation"
          header="Your bid is not final. Be sure you and the Requester agree upon the Project terms before you start working."
        />
      </ContentArea>
    </Stack>
  );
}
