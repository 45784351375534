/**
 * @generated SignedSource<<ef27069c877ff80ab5244ebb51bd3e51>>
 * @relayHash 87952b491658348cdfdcee1402e02f0b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:1IPj6MkCzTj_0Rf1epT7YsYeDOyAAOeWu9P56mjCSzE

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type directPaymentsAndHiresScreenWorkerQuery$variables = {
  approvedQuery: string;
  pendingQuery: string;
  rejectedQuery: string;
};
export type directPaymentsAndHiresScreenWorkerQuery$data = {
  readonly approvedHiresCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly pendingCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly rejectedCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly viewer: {
    readonly directHireBonus?: string;
    readonly " $fragmentSpreads": FragmentRefs<"DirectHirePaymentCollapsibleCard_Worker">;
  } | null | undefined;
};
export type directPaymentsAndHiresScreenWorkerQuery = {
  response: directPaymentsAndHiresScreenWorkerQuery$data;
  variables: directPaymentsAndHiresScreenWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "approvedQuery"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pendingQuery"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rejectedQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directHireBonus",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v4 = {
  "alias": "approvedHiresCount",
  "args": [
    (v2/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "approvedQuery"
    }
  ],
  "concreteType": "DirectHireClaimsConnection",
  "kind": "LinkedField",
  "name": "directHireClaims",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": "pendingCount",
  "args": [
    (v2/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "pendingQuery"
    }
  ],
  "concreteType": "DirectHireClaimsConnection",
  "kind": "LinkedField",
  "name": "directHireClaims",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": "rejectedCount",
  "args": [
    (v2/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "rejectedQuery"
    }
  ],
  "concreteType": "DirectHireClaimsConnection",
  "kind": "LinkedField",
  "name": "directHireClaims",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "directPaymentsAndHiresScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DirectHirePaymentCollapsibleCard_Worker"
              },
              (v1/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "directPaymentsAndHiresScreenWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ]
  },
  "params": {
    "id": "qpid:1IPj6MkCzTj_0Rf1epT7YsYeDOyAAOeWu9P56mjCSzE",
    "metadata": {},
    "name": "directPaymentsAndHiresScreenWorkerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b8598ca5f9ee8277fadf598be5877e01";

export default node;
