import { Stack, Text } from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import type { projectDescriptionItem_engagement$key } from "./__generated__/projectDescriptionItem_engagement.graphql";

interface Props {
  engagementRef: projectDescriptionItem_engagement$key;
}

export default function ProjectDescriptionItem({ engagementRef }: Props) {
  const engagement = useRelayFragment(
    graphql`
      fragment projectDescriptionItem_engagement on Engagement {
        gig {
          startsAt
          negotiable
        }
        currentState {
          name
        }
      }
    `,
    engagementRef
  );
  if (!engagement) return null;
  if (engagement?.gig?.startsAt)
    return (
      <Stack size="compact">
        <Text color="neutral">Scheduled Start:</Text>
        <Text variant="header">
          <Text weight="bold">
            {time.humanize(engagement?.gig?.startsAt, "compactFull")}
          </Text>
        </Text>
      </Stack>
    );

  if (
    !engagement?.gig?.startsAt &&
    !engagement?.gig?.negotiable?.includes("STARTS_AT")
  )
    return (
      <Text weight="bold" variant="header">
        ASAP
      </Text>
    );
  return null;
}
