import { useStyles } from "@gigsmart/atorasu/style";
import type { GraphQLTaggedNode, KeyType, QuerySpec } from "@gigsmart/relay";
import React, {
  type ReactElement,
  type Ref,
  useImperativeHandle,
  useRef
} from "react";
import type { FlatListProps } from "react-native";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";
import useInfiniteList, { type Options } from "./use-infinite-list";

export type ListHandle = { refetch: () => void };

export type Props<Q extends QuerySpec, TKey extends KeyType, T> = Omit<
  FlatListProps<T>,
  "data" | "renderItem"
> &
  Options<Q, TKey, T> & {
    fragmentInput: GraphQLTaggedNode;
    parentRef: TKey | null | undefined;
    renderItem: (options: {
      index: number;
      item: T;
      data: readonly T[] | null;
    }) => ReactElement | null;
    footerBottom?: boolean;
    testID: string;
    listRef?: Ref<ListHandle | null>;
  };

export const useInfiniteListRef = () => useRef<ListHandle | null>(null);

const InfiniteList = <Q extends QuerySpec, TKey extends KeyType, T>({
  fragmentInput,
  parentRef,
  getData,
  pageSize,
  renderEmptyView,
  renderFooterView,
  refetchVars,
  refetchDebounce = 500,
  renderItem,
  footerBottom,
  contentContainerStyle,
  ListFooterComponentStyle,
  listRef,
  ...props
}: Props<Q, TKey, T>) => {
  const styles = useStyles(() => ({
    list: { flexGrow: 1 },
    footerBottom: { marginTop: "auto" }
  }));
  const { data, refetch, ...listProps } = useInfiniteList<Q, TKey, T>(
    fragmentInput,
    parentRef,
    {
      getData,
      pageSize,
      refetchDebounce,
      refetchVars,
      renderEmptyView,
      renderFooterView
    }
  );

  useImperativeHandle(listRef, () => ({ refetch }), []);

  const shouldBounce = !!data && data.length > 0;

  return (
    <KeyboardAwareFlatList
      contentContainerStyle={[styles.list, contentContainerStyle]}
      ListFooterComponentStyle={[
        footerBottom && styles.footerBottom,
        ListFooterComponentStyle
      ]}
      bounces={shouldBounce}
      data={data}
      renderItem={({ item, index }) => renderItem({ item, index, data })}
      {...listProps}
      {...props}
    />
  );
};

export default InfiniteList;
