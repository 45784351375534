import {
  ContentArea,
  Stack,
  Surface,
  Text,
  TooltipButton
} from "@gigsmart/atorasu";
import React from "react";
import FomuMileageInput from "../fomu/inputs/FomuMileageInput";
import { showMileageTrackingInfoModal } from "../modals/MileageTrackingInfoModal";

interface Props {
  variant?: "timesheet" | "totalTime";
  workerName?: string | null;
  appVariant?: "worker" | "requester";
}

export default function EditMileage({
  variant,
  workerName,
  appVariant = "requester"
}: Props) {
  return (
    <Surface>
      <ContentArea>
        <Stack>
          <Stack horizontal size="compact">
            <Text weight="semibold">
              What was the total Travel / Delivery Mileage?
            </Text>
            <TooltipButton
              testID="show-mileage-modal-btn"
              onPress={() => showMileageTrackingInfoModal(appVariant)}
            />
          </Stack>
          <Text>
            Enter the estimated number of miles{" "}
            {appVariant === "requester" ? workerName : "you"} traveled during
            this Shift.
          </Text>
          <FomuMileageInput variant={variant} />
        </Stack>
      </ContentArea>
    </Surface>
  );
}
