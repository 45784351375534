import {
  ActionSheet,
  ContentArea,
  Icon,
  IconButton,
  Pressable,
  Stack,
  Text,
  confirmPrompt,
  toast,
  useMatchesViewport
} from "@gigsmart/atorasu";
import type { ActionSheetOptions } from "@gigsmart/atorasu";
import { WorkerEngagementView } from "@gigsmart/feature-flags";
import { NavPortalEntrance, useHistory } from "@gigsmart/kaizoku";
import {
  createRelayFragmentContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useMemo, useState } from "react";
import type { RequesterMoreMenuJoinConversationMutation } from "./__generated__/RequesterMoreMenuJoinConversationMutation.graphql";
import type { RequesterMoreMenuLeaveConversationMutation } from "./__generated__/RequesterMoreMenuLeaveConversationMutation.graphql";
import type { RequesterMoreMenuSetConversationStatusMutation } from "./__generated__/RequesterMoreMenuSetConversationStatusMutation.graphql";
import type { RequesterMoreMenuUpdateShiftGroupChatMutation } from "./__generated__/RequesterMoreMenuUpdateShiftGroupChatMutation.graphql";
import type {
  RequesterMoreMenu_conversationLike$data,
  RequesterMoreMenu_conversationLike$key
} from "./__generated__/RequesterMoreMenu_conversationLike.graphql";

interface Props {
  isActive?: boolean;
  isNotCurrentOrg?: boolean;
}

export function RequesterMoreMenu({
  isActive,
  worker: orgReqWorker,
  engagement,
  capabilities,
  isNotCurrentOrg,
  id,
  participant,
  gig,
  __typename
}: FragmentContainerInnerComponentProps<
  RequesterMoreMenu_conversationLike$key,
  Props
>) {
  const worker = orgReqWorker ?? engagement?.worker;
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const [visible, setVisible] = useState(false);
  const [joinConversation] =
    useRelayMutation<RequesterMoreMenuJoinConversationMutation>(
      graphql`
        mutation RequesterMoreMenuJoinConversationMutation(
          $input: AddConversationParticipantInput!
        ) {
          addConversationParticipant(input: $input) {
            newConversationParticipantEdge {
              node {
                id
                readUntil
                readCursor
                capabilities
                conversation {
                  ...RequesterConversationHeader_conversationLike
                }
              }
            }
          }
        }
      `
    );
  const [leaveConversation] =
    useRelayMutation<RequesterMoreMenuLeaveConversationMutation>(
      graphql`
        mutation RequesterMoreMenuLeaveConversationMutation(
          $input: RemoveConversationParticipantInput!
        ) {
          removeConversationParticipant(input: $input) {
            removedConversationParticipantId @deleteRecord
            conversation {
              ...RequesterConversationHeader_conversationLike
            }
          }
        }
      `
    );
  const [commitConversationStatus] =
    useRelayMutation<RequesterMoreMenuSetConversationStatusMutation>(
      graphql`
        mutation RequesterMoreMenuSetConversationStatusMutation(
          $input: SetConversationStatusInput!
        ) {
          setConversationStatus(input: $input) {
            conversation {
              ... on ConversationLike {
                status
              }
              ...RequesterConversationHeader_conversationLike
            }
          }
        }
      `
    );
  const [commit] =
    useRelayMutation<RequesterMoreMenuUpdateShiftGroupChatMutation>(graphql`
      mutation RequesterMoreMenuUpdateShiftGroupChatMutation(
        $input: SetGigLikeConversationRuleInput!
      ) {
        setGigLikeConversationRule(input: $input) {
          gigLike {
            ... on GigLike {
              id
              conversationRule
            }
            ... on Gig {
              id
              conversationRule
              conversation {
                id
                status
                participant {
                  messageStats {
                    unreadCount
                  }
                }
                currentMessage {
                  id
                }
              }
            }
          }
        }
      }
    `);
  const history = useHistory();
  const handleJoinConversation = () => {
    joinConversation({ input: { conversationId: id ?? "" } });
  };
  const handleLeaveConversation = () => {
    leaveConversation({
      input: { conversationParticipantId: participant?.id ?? "" }
    });
  };

  const handleChangeConversationRule = (
    rule: ObjectPath<
      RequesterMoreMenu_conversationLike$data,
      ["gig", "conversationRule"]
    >
  ) => {
    if (!rule || !gig?.id) return;
    commit(
      { input: { conversationRule: rule, gigLikeId: gig?.id } },
      {
        optimisticResponse: {
          setGigLikeConversationRule: {
            gigLike: {
              __typename: "Gig",
              id,
              conversationRule: rule
            }
          }
        },
        onSuccess: () => {
          toast.success("Shift Group Chat Settings Updated");
        },
        onError: (e) => {
          toast.error(`Error updating Shift Group Chat Settings ${e.message}`);
        }
      }
    );
  };

  const setConversationStatus = async (status: "OPEN" | "CLOSED") => {
    const result = await confirmPrompt({
      title: `Are you sure you want to ${
        status === "OPEN" ? "enable" : "disable"
      } this conversation?`,
      horizontalActions: false,
      actions: [
        {
          testID: `confirm-${status}-conversation-btn`,
          label: `Yes, ${
            status === "OPEN" ? "Enable" : "Disable"
          } Conversation`,
          value: true,
          type: status === "CLOSED" ? "destructive" : "default"
        },
        {
          testID: `cancel-${status}-conversation-btn`,
          label: "No, Thank You",
          value: false
        }
      ]
    });
    if (result) {
      commitConversationStatus(
        { input: { conversationId: id ?? "", status } },
        {
          optimisticResponse: {
            setConversationStatus: {
              conversation: {
                id,
                status
              }
            }
          }
        }
      );
    }
  };
  const options = useMemo(() => {
    const options: ActionSheetOptions = [];
    if (__typename !== "ShiftGroupConversation") {
      if (capabilities?.includes("CLOSE")) {
        options.push({
          testID: "close-conversation-btn",
          label: "Disable Conversation",
          onPress: async () => await setConversationStatus("CLOSED")
        });
      }
      if (capabilities?.includes("OPEN")) {
        options.push({
          testID: "open-conversation-btn",
          label: "Enable Conversation",
          onPress: async () => await setConversationStatus("OPEN")
        });
      }
      if (capabilities?.includes("JOIN")) {
        options.push({
          testID: "join-conversation-btn",
          label: "Join Conversation",
          onPress: handleJoinConversation
        });
      }
      if (isActive) {
        if (
          !engagement &&
          !isNotCurrentOrg &&
          WorkerEngagementView.isDisabled()
        ) {
          options.push({
            testID: "view-worker-shifts-btn",
            label: "View Worker Shifts",
            onPress: () =>
              history.push(`/workers/${worker?.id}/engagements/summary`)
          });
        }
        options.push({
          testID: "view-worker-profile-btn",
          label: "View Worker Profile",
          onPress: () => history.push(`/workers/${worker?.id}`)
        });
      } else {
        options.push({
          testID: "block-worker-btn",
          label: "Block Worker",
          onPress: () => history.push(`/block-workers/${worker?.id}`),
          destructive: true
        });
      }
    }
    if (__typename === "ShiftGroupConversation") {
      options.push({
        testID: "view-shift-btn",
        label: "View Shift",
        onPress: () => history.push(`/gigs/${gig?.id}`)
      });
      if (gig?.conversationRule === "REQUESTER_ONLY") {
        options.push({
          testID: "allow-workers-to-message-btn",
          label: "Allow Workers to Message",
          onPress: () => handleChangeConversationRule("ALL_PARTICIPANTS")
        });
      }
      if (gig?.conversationRule === "ALL_PARTICIPANTS") {
        options.push({
          testID: "disable-workers-from-messaging-btn",
          label: "Disable Workers from Messaging",
          onPress: () => handleChangeConversationRule("REQUESTER_ONLY")
        });
      }
      if (gig?.conversationRule !== "DISABLED") {
        options.push({
          testID: "disable-group-chat-btn",
          label: "Disable Group Chat",
          onPress: () => handleChangeConversationRule("DISABLED"),
          destructive: true
        });
      }
    }
    if (capabilities?.includes("LEAVE")) {
      options.push({
        testID: "leave-conversation-btn",
        label: `Leave ${
          __typename === "ShiftGroupConversation" ? "Chat" : "Conversation"
        }`,
        onPress: handleLeaveConversation,
        destructive: true
      });
      if (
        ["DRAFT", "ACTIVE", "IN_PROGRESS", "UPCOMING"].includes(
          gig?.currentState?.name ?? ""
        ) &&
        !capabilities?.includes("JOIN") &&
        !capabilities?.includes("LEAVE")
      ) {
        options.push({
          testID: "report-conversation-btn",
          label: `Report ${
            __typename === "ShiftGroupConversation" ? "Chat" : "Conversation"
          }`,
          onPress: () => history.push("/support"),
          destructive: true
        });
      }
    }
    options.push({
      testID: "report-conversation-btn",
      label: `Report ${
        __typename === "ShiftGroupConversation" ? "Chat" : "Conversation"
      }`,
      onPress: () => history.push("/support"),
      destructive: true
    });
    return options;
  }, [capabilities, isActive, gig?.conversationRule]);

  return (
    <ActionSheet
      visible={visible}
      options={options}
      position="right"
      onClose={() => setVisible(false)}
      autoClose
    >
      {isMd && (
        <Pressable
          testID="conversation-more-menu-btn"
          onPress={() => setVisible(true)}
          eventEntityType="Button"
          eventTargetName="ConversationMoreMenuButton"
        >
          <Stack
            horizontal
            alignItems="center"
            justifyContent="center"
            size="compact"
          >
            <Icon
              name="ellipsis-vertical"
              color="primary"
              size="small"
              variant="solid"
            />
            <Text color="primary" weight="bold">
              More
            </Text>
          </Stack>
        </Pressable>
      )}
      {!isMd && (
        <NavPortalEntrance
          rightAccessory={
            <ContentArea size="none">
              <IconButton
                size="medium"
                variant="solid"
                name="ellipsis-vertical"
                onPress={() => setVisible(true)}
                testID="conversation-more-menu-btn"
              />
            </ContentArea>
          }
        />
      )}
    </ActionSheet>
  );
}

export default createRelayFragmentContainer<
  RequesterMoreMenu_conversationLike$key,
  Props
>(
  graphql`
    fragment RequesterMoreMenu_conversationLike on ConversationLike {
      __typename
      capabilities
      participant {
        id
        readUntil
      }
      ... on OrganizationWorkerConversation {
        id
        capabilities
        worker {
          id
        }
      }
      ... on EngagementConversation {
        engagement {
          worker {
            id
          }
        }
      }
      ... on ShiftGroupConversation {
        gig {
          id
          conversationRule
          currentState {
            name
          }
        }
      }
    }
  `,
  RequesterMoreMenu
);
