import { ScreenTop, toast } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { Stepper, StepperHeader } from "@gigsmart/katana";
import type { Step as StepType } from "@gigsmart/katana/nav/stepper";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { useEnrollmentDataFragment } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentData";
import useEnrollmentMutation from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentMutation";
import React, { useMemo, useState } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { enrollmentQuery } from "./__generated__/enrollmentQuery.graphql";
import VerifiedDriverInformation from "./driver-information";
import VerifiedEnrollmentComplete from "./enrollment-complete";
import VerifiedInformation from "./information";
import VerifiedOptIns from "./opt-ins";

type Props = AppScreenProps<WorkerParamList, "GetVerified"> & {
  onEnrolled?: () => void;
};

const VerifiedEnrollmentScreen = createSuspendedQueryContainer<
  enrollmentQuery,
  Props
>(
  ({ response, onEnrolled, navigation }) => {
    const [showComplete, setShowComplete] = useState(false);
    const styles = useStyles(() => ({ stepper: { flex: 1 } }));
    const enrollmentData = useEnrollmentDataFragment(response?.viewer);
    const [showDriverInfo, setShowDriverInfo] = useState(true);
    const notPreviouslyEnrolled = enrollmentData?.status === "NOT_ENROLLED";
    const onEnrolledCb =
      onEnrolled ??
      (() => {
        toast.success("Verification successfully saved.");
        navigation.goBack();
      });
    const [handleSubmit, { loading }] = useEnrollmentMutation(enrollmentData, {
      onSuccess: () => {
        notPreviouslyEnrolled ? setShowComplete(true) : onEnrolledCb();
      }
    });

    const steps: Array<StepType<any>> = useMemo(
      () => [
        {
          name: "Opt-Ins",
          render: (props) => <VerifiedOptIns {...props} />
        },
        {
          name: "Information",
          render: (props) => (
            <VerifiedInformation {...props} loading={loading} />
          ),
          disabled:
            !!enrollmentData?.status &&
            ["ENROLLED", "NOT_ELIGIBLE"].includes(enrollmentData?.status)
        },
        {
          name: "Driver Info",
          render: (props) => (
            <VerifiedDriverInformation {...props} loading={loading} />
          ),
          disabled:
            !showDriverInfo ||
            enrollmentData?.driverCheckOptIn ||
            (!!enrollmentData?.status &&
              ["NOT_ENROLLED", "NOT_ELIGIBLE"].includes(
                enrollmentData?.status
              )) ||
            enrollmentData?.hasReport("MOTOR_VEHICLE_CHECK")
        }
      ],
      [enrollmentData, loading, showDriverInfo]
    );

    if (showComplete)
      return <VerifiedEnrollmentComplete onDone={onEnrolledCb} />;
    if (!enrollmentData) return null;

    return (
      <Stepper
        historyBlock={false}
        eventContext="Enrollment"
        style={styles.stepper}
        renderHeader={({ currentStep, gotoStep, ...props }) => (
          <>
            <ScreenTop
              extraSpace
              onBackPress={() => {
                if (currentStep.number > 1) {
                  gotoStep(currentStep.number - 1);
                  return true;
                }
              }}
            />
            <StepperHeader
              currentStep={currentStep}
              gotoStep={gotoStep}
              {...props}
            />
          </>
        )}
        onComplete={handleSubmit}
        onData={({ driverCheckOptIn }) => {
          !enrollmentData.driverCheckOptIn &&
            setShowDriverInfo(!!driverCheckOptIn);
        }}
        initialData={{ ...enrollmentData, initialData: enrollmentData } as any}
        steps={steps}
        testID="enrollment-stepper"
      />
    );
  },
  {
    query: graphql`
      query enrollmentQuery {
        viewer {
          ... on Worker {
            ...useEnrollmentData_worker
          }
        }
      }
    `,
    variables: {}
  }
);

export default VerifiedEnrollmentScreen;
