import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import { KatanaRatingStars } from "@gigsmart/katana";
import type { ForwardableProps } from "@gigsmart/type-utils";
import React from "react";

type Props = ForwardableProps<
  typeof KatanaRatingStars,
  FormFieldOptions,
  "value" | "onChangeValue"
>;

export const RatingStarsInput = ({ name, validates, ...inputProps }: Props) => {
  const { setValue, value } = useFormField<number | null | undefined>({
    name,
    validates
  });

  return (
    <KatanaRatingStars {...inputProps} value={value} onChangeValue={setValue} />
  );
};
