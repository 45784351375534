import React, { type ReactNode, useContext } from "react";

import type { ViewStyleProp } from "../style";
import Context from "./context";
import ScrollableViewProvider from "./scrollable-view-provider";

interface Props {
  children: ReactNode;
  testID?: string;
  style?: ViewStyleProp;
}

export default function ScrollableView({ children, style, testID }: Props) {
  const contextValues = useContext(Context);

  return (
    <ScrollableViewProvider
      style={style}
      testID={testID}
      contextValues={contextValues}
    >
      {children}
    </ScrollableViewProvider>
  );
}
