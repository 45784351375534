import React, { type ComponentType } from "react";
import Stack from "../../container/stack";

import DefaultRadioRow from "./default-radio-row";

interface Props<V> {
  selectedValue: V;
  direction?: "row" | "column";
  options?: Array<{ value: V } & Record<string, any>>;
  RowComponent?: ComponentType<any>;
  spacing?: number;
  onChange?: (value: V) => void;
  defaultIndex?: number;
  testID?: string;
  inset?: number;
}

export default function RadioButtonGroup<V>({
  selectedValue,
  direction,
  options,
  RowComponent,
  spacing = 0,
  onChange,
  testID,
  inset
}: Props<V>) {
  return (
    <Stack
      testID={testID ?? "radio-button-group"}
      direction={direction}
      spacing={spacing}
      inset={inset}
    >
      {options?.map((option, index) => {
        const selected = selectedValue === option.value;
        const onPress = () => {
          onChange?.(option.value);
        };

        if (RowComponent)
          return (
            <RowComponent
              key={index}
              testID={testID ?? option.title ?? ""}
              index={index}
              selected={selected}
              {...option}
              onPress={onPress}
            />
          );
        return (
          <DefaultRadioRow
            {...option}
            key={index}
            index={index}
            selected={selected}
            onPress={onPress}
          />
        );
      })}
    </Stack>
  );
}
