import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
  type ReactNode
} from "react";
import {
  type LayoutChangeEvent,
  type LayoutRectangle,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  ScrollView,
  View
} from "react-native";

import LinearGradient from "../../container/linear-gradient";
import { theme, useStyles } from "../../style";
import TabItem, { TAB_HEIGHT } from "./tab-item";
import type { RenderTabOptions, TabConfig } from "./types";

export interface TabItemProps<K extends string, T extends TabConfig<K>> {
  tabs: readonly T[];
  active?: K;
  onSelectTab?: (tab: K) => void;
  renderLabel?: (opt: RenderTabOptions<K, T>) => ReactNode;
  tabItemsMaxWidth?: number | string;
}

const defaultRenderLabel = (opt: RenderTabOptions<any>) => {
  if (opt.tab.renderLabel) return opt.tab.renderLabel(opt);
  return opt.tab.label ?? opt.tab.key;
};

const fadeGradient = {
  colors: ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 1)"],
  locations: [0, 0.9],
  opacities: [0, 1],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 }
};

const TabItemList = <K extends string, T extends TabConfig<K>>({
  active,
  tabs,
  renderLabel = defaultRenderLabel,
  onSelectTab,
  tabItemsMaxWidth
}: TabItemProps<K, T>) => {
  const { styles } = useStyles(({ units, color }) => ({
    wrapper: {
      height: TAB_HEIGHT
    },
    itemsContainer: { flexGrow: 1 },
    itemsContainerFill: {
      backgroundColor: "#fff",
      justifyContent: "center",
      marginBottom: -1
    },
    gradient: {
      position: "absolute",
      bottom: 0,
      top: 0,
      width: units(12),
      right: 0
    },
    gradientStart: {
      left: 0,
      right: undefined,
      transform: [{ rotate: "180deg" }]
    }
  }));

  const scrollRef = useRef<ScrollView>(null);
  const containerWidthRef = useRef(0);
  const contentSizeRef = useRef(0);
  const scrollPosRef = useRef(0);
  const tabItemsLayout = useMemo(() => new Map<string, LayoutRectangle>(), []);
  const handledInitialScroll = useRef(false);

  const [fadeStart, setFadeStart] = useState(false);
  const [fadeEnd, setFadeEnd] = useState(false);

  // "isScrollable" only need to be handled if tabItemsMaxWidth is set
  const [isScrollable, setScrollable] = useState(!tabItemsMaxWidth);

  const checkScroll = useCallback(() => {
    const threshold = theme.units(4);
    const containerSize = containerWidthRef.current;
    const contentSize = contentSizeRef.current;
    if (!containerSize || !contentSize) return;

    let newFadeStart: boolean;
    let newFadeEnd: boolean;

    if (contentSize > containerSize) {
      const leftPos = scrollPosRef.current;
      const rightPos = leftPos + containerWidthRef.current;
      newFadeStart = leftPos > threshold;
      newFadeEnd = rightPos < contentSizeRef.current - threshold;
    } else {
      newFadeStart = false;
      newFadeEnd = false;
    }
    if (newFadeStart !== fadeStart) setFadeStart(newFadeStart);
    if (newFadeEnd !== fadeEnd) setFadeEnd(newFadeEnd);

    if (tabItemsMaxWidth && containerSize >= contentSize) {
      if (isScrollable) setScrollable(false);
    } else if (!isScrollable) {
      setScrollable(true);
    }
  }, [fadeStart, fadeEnd, tabItemsMaxWidth, isScrollable]);

  const scrollToTab = useCallback(
    (tab: string) => {
      const layout = tab ? tabItemsLayout.get(tab) : null;
      if (layout && scrollRef.current) {
        const halfContainer = (containerWidthRef.current || 0) / 2;
        scrollRef.current.scrollTo({
          x: Math.max(0, layout.x - halfContainer + layout.width / 2),
          animated: handledInitialScroll.current
        });
      }
    },
    [tabItemsLayout]
  );

  const handleContainerLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!e.nativeEvent) return;

      const newWidth = e.nativeEvent.layout.width;
      if (newWidth !== containerWidthRef.current) {
        containerWidthRef.current = newWidth;
        checkScroll();
      }

      // handle the initialScroll when the content first becomes available
      if (!handledInitialScroll.current) {
        if (active) scrollToTab(active);
        handledInitialScroll.current = true;
      }
    },
    [checkScroll, scrollToTab, active]
  );

  const handleContentSizeChanged = useCallback((w: number) => {
    contentSizeRef.current = w;
  }, []);

  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      scrollPosRef.current = e.nativeEvent.contentOffset.x;
      checkScroll();
    },
    [checkScroll]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkScroll(), []);

  useEffect(() => {
    if (active) scrollToTab(active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <View style={styles.wrapper} onLayout={handleContainerLayout}>
      <ScrollView
        ref={scrollRef}
        horizontal
        bounces={false}
        scrollEnabled={isScrollable}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[
          styles.itemsContainer,
          !isScrollable && styles.itemsContainerFill
        ]}
        onContentSizeChange={handleContentSizeChanged}
        onScroll={handleScroll}
        onScrollEndDrag={handleScroll}
        scrollEventThrottle={16}
      >
        {tabs.map((item) => {
          const isActive = item.key === active;
          const options: RenderTabOptions<K, T> = { tab: item, isActive };
          return (
            <TabItem
              key={item.key}
              maxWidth={tabItemsMaxWidth}
              testID={item.testID ?? `${item.key}-tab-item`}
              label={renderLabel(options)}
              active={isActive}
              onPress={() => onSelectTab?.(item.key)}
              onLayout={(e) =>
                e.nativeEvent &&
                tabItemsLayout.set(item.key, e.nativeEvent.layout)
              }
            />
          );
        })}
      </ScrollView>
      {fadeEnd && (
        <LinearGradient
          pointerEvents="none"
          style={styles.gradient}
          {...fadeGradient}
        />
      )}
      {fadeStart && (
        <LinearGradient
          pointerEvents="none"
          style={[styles.gradient, styles.gradientStart]}
          {...fadeGradient}
        />
      )}
    </View>
  );
};

export default TabItemList;
