import {
  GoogleMapAvatarMarker,
  GoogleMapView,
  type LatLng,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Platform,
  Stack,
  Text,
  Time,
  showModal,
  useMapRef
} from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import { capitalize } from "lodash";
import React from "react";
import {} from "react-native-maps";
import ViewGeofenceShape from "../locations/ViewGeofenceShape";
import type { WorkerLocationMapModalQuery } from "./__generated__/WorkerLocationMapModalQuery.graphql";

interface Props {
  engagementStateId: string;
  engagementTimesheetId: string;
}

const WorkerLocationMapModal = createSuspendedQueryContainer<
  WorkerLocationMapModalQuery,
  Props
>(
  function WorkerLocationMapModal({ response }) {
    const mapRef = useMapRef();
    const {
      name: stateName,
      transitionedAt,
      workerTransitionLocation
    } = response?.engagementState ?? {};
    const { engagement, timezone, id } = response?.engagementTimesheet ?? {};
    const { displayName, profilePhoto } = engagement?.worker ?? {};
    const workerLocation = (workerTransitionLocation as LatLng) ?? {};
    const orgLocation = engagement?.gig?.organizationLocation;
    const action =
      stateName === "AWAITING_START"
        ? "Arrived"
        : stateName === "WORKING"
          ? "Started"
          : stateName === "PENDING_TIMESHEET_APPROVAL"
            ? "Ended"
            : stateName === "RUNNING_LATE"
              ? "Running Late"
              : stateName;

    const fitMapToElements = () =>
      mapRef.current?.fitToElements({
        edgePadding: {
          top: 50,
          right: 50,
          left: 50,
          bottom: 50
        }
      });
    const fitMapToCoordinates = () =>
      mapRef.current?.fitToCoordinates([
        {
          latitude: orgLocation?.location?.latitude ?? 0,
          longitude: orgLocation?.location?.longitude ?? 0
        },
        {
          latitude: workerLocation?.latitude ?? 0,
          longitude: workerLocation?.longitude ?? 0
        }
      ]);

    return (
      <>
        <ModalHeader title={`${displayName}'s Location`} />
        <ModalBody>
          <Stack fill>
            <Text>
              {`Worker ${capitalize(action)} at `}
              <Time startsAt={transitionedAt} timezone={timezone} size="sm" />
            </Text>
            <GoogleMapView
              id="timesheet-map"
              testID="timesheet-map"
              onMapReady={() => {
                if (Platform.OS === "ios") {
                  fitMapToElements();
                } else if (Platform.OS === "android") {
                  setTimeout(fitMapToElements, 1000);
                } else {
                  setTimeout(fitMapToCoordinates, 1000);
                }
              }}
              center={{
                latitude: orgLocation?.location?.latitude ?? 0,
                longitude: orgLocation?.location?.longitude ?? 0
              }}
            >
              <ViewGeofenceShape
                center={{
                  latitude: orgLocation?.location?.latitude ?? 0,
                  longitude: orgLocation?.location?.longitude ?? 0
                }}
                radius={orgLocation?.geofenceRadius}
                paths={orgLocation?.geofenceBoundary?.coordinates?.[0]?.coordinates?.map(
                  (t) => t
                )}
              />
              <GoogleMapAvatarMarker
                testID={`map-pin-${id}`}
                active
                latitude={workerLocation?.latitude ?? 0}
                longitude={workerLocation?.longitude ?? 0}
                avatarUri={profilePhoto?.url ?? ""}
                zIndex={100}
                tracksViewChanges={false}
              />
            </GoogleMapView>
          </Stack>
        </ModalBody>
        <ModalFooter />
      </>
    );
  },
  {
    query: graphql`
      query WorkerLocationMapModalQuery(
        $engagementStateId: ID!
        $engagementTimesheetId: ID!
      ) {
        engagementTimesheet: node(id: $engagementTimesheetId) {
          id
          ... on EngagementTimesheet {
            timezone
            engagement {
              gig {
                organizationLocation {
                  location {
                    latitude
                    longitude
                  }
                  geofenceRadius
                  geofenceBoundary {
                    coordinates {
                      coordinates {
                        latitude
                        longitude
                      }
                    }
                  }
                }
              }
              worker {
                displayName
                profilePhoto {
                  url
                }
              }
            }
          }
        }
        engagementState: node(id: $engagementStateId) {
          ... on EngagementState {
            name
            action
            transitionedAt
            workerTransitionLocation {
              latitude
              longitude
            }
          }
        }
      }
    `,
    variables: ({ engagementStateId, engagementTimesheetId }) => ({
      engagementStateId,
      engagementTimesheetId
    })
  }
);

export default function showWorkerLocationMapModal({
  engagementStateId,
  engagementTimesheetId
}: Props) {
  return showModal({
    eventContext: "WorkerLocationMapModal",
    useModalBody: false,
    fixedHeight: true,
    children: (
      <WorkerLocationMapModal
        engagementStateId={engagementStateId}
        engagementTimesheetId={engagementTimesheetId}
      />
    )
  });
}
