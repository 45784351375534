import React, { type ReactNode } from "react";

import {
  Button,
  HighlightedReminder,
  type IconName,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { type FomuOnSubmitArgs, Form, FormSubmit } from "@gigsmart/fomu";
import FomuMultiSelect from "../fomu/inputs/FomuMultiSelect";

export interface Option {
  id: string;
  label: string;
  value: boolean | null;
  enableAutoChecking: boolean;
}

export interface SelectionCategory {
  selectionRowHeader: string;
  selectionRowHeaderIcon: IconName;
  options: Option[];
}

interface Props {
  header?: string | ReactNode;
  note: string;
  subNote: string;
  info?: string;
  reminder?: string;
  selectionCategories: SelectionCategory[];
  onCheckOption: (value: Option) => Promise<boolean> | void;
  onSubmit?: (v: FomuOnSubmitArgs & { options: Option[] }) => void;
  submitLabel?: string;
  submitButton?: React.ElementType;
}
export default function MultiCategorySelectionRowGroup({
  header,
  note,
  subNote,
  info,
  reminder,
  selectionCategories,
  onCheckOption,
  onSubmit,
  submitLabel = "Save",
  submitButton
}: Props) {
  const options = selectionCategories?.flatMap((category) => category.options);
  const initialValues = Object.fromEntries(
    new Map(options?.map((option) => [option?.id, option?.value]))
  );

  const ButtonComponent = submitButton ?? Button;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={({ values, ...rest }, done) => {
        done();
        onSubmit?.({
          ...rest,
          values,
          options: options.map((option) => ({
            ...option,
            value: values[option?.id]
          }))
        });
      }}
    >
      {header && typeof header !== "string" && header}
      <Stack>
        {header && typeof header === "string" && (
          <Text variant="header" color="primary">
            {header}
          </Text>
        )}
        <Text>{note}</Text>
        <Text weight="bold">{subNote}</Text>
        {selectionCategories?.map((category, categoryIndex) => (
          <FomuMultiSelect
            testID={`fomu-multiselect-${categoryIndex}`}
            header={category.selectionRowHeader}
            headerIcon={category.selectionRowHeaderIcon}
            headerOptions={["NO", "YES"]}
            options={category.options}
            onCheck={(option, check) =>
              onCheckOption({ ...option, value: !!check })
            }
            key={categoryIndex}
          />
        ))}
        {!!info && (
          <Text align="center" color="neutral">
            {info}
          </Text>
        )}
        {!!reminder && (
          <HighlightedReminder icon="circle-info" header={reminder} />
        )}
        {!!onSubmit && (
          <FormSubmit>
            {({ dirty, invalid, submit, submitting }) => (
              <ButtonComponent
                testID="multi-category-selection-row-group-submit"
                label={submitLabel}
                disabled={submitting || invalid}
                onPress={submit}
              />
            )}
          </FormSubmit>
        )}
      </Stack>
    </Form>
  );
}
