/**
 * @generated SignedSource<<1c94f1b61558f7e0676098e6ea35f3a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useWorkerGigFields_worker$data = {
  readonly gigFields: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly available: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly label: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly denied: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly label: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly iconName: string;
        readonly selected: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly label: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly title: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "useWorkerGigFields_worker";
};
export type useWorkerGigFields_worker$key = {
  readonly " $data"?: useWorkerGigFields_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"useWorkerGigFields_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinitionsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GigFieldItemDefinition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useWorkerGigFields_worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "concreteType": "GigFieldsConnection",
      "kind": "LinkedField",
      "name": "gigFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigFieldsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigField",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iconName",
                  "storageKey": null
                },
                {
                  "alias": "selected",
                  "args": [
                    (v1/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "query",
                      "value": "WHERE status = CONFIRMED"
                    }
                  ],
                  "concreteType": "GigFieldItemDefinitionsConnection",
                  "kind": "LinkedField",
                  "name": "itemDefinitions",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": "itemDefinitions(first:10,query:\"WHERE status = CONFIRMED\")"
                },
                {
                  "alias": "denied",
                  "args": [
                    (v1/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "query",
                      "value": "WHERE status = DENIED"
                    }
                  ],
                  "concreteType": "GigFieldItemDefinitionsConnection",
                  "kind": "LinkedField",
                  "name": "itemDefinitions",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": "itemDefinitions(first:10,query:\"WHERE status = DENIED\")"
                },
                {
                  "alias": "available",
                  "args": [
                    (v1/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "query",
                      "value": "WHERE status = UNKNOWN"
                    }
                  ],
                  "concreteType": "GigFieldItemDefinitionsConnection",
                  "kind": "LinkedField",
                  "name": "itemDefinitions",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": "itemDefinitions(first:10,query:\"WHERE status = UNKNOWN\")"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "0ff20976fc02cbd1bc6f609023fe9f04";

export default node;
