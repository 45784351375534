/**
 * @generated SignedSource<<8783330db7c80a4b655fcf73db2dccc8>>
 * @relayHash fc775c9fa6d261f0d7b9b001baad90c7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:TSz7WpK2AyuHii6hEp8NQJXspYzFyGPlqdOwspvQSUU

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type TransportationStepQuery$variables = {
  first: number;
  query: string;
};
export type TransportationStepQuery$data = {
  readonly WorkerTransportationState: {
    readonly enumValues: ReadonlyArray<{
      readonly name: string;
    }> | null | undefined;
  } | null | undefined;
  readonly viewer: {
    readonly transportationMethod?: string | null | undefined;
    readonly transportationState?: WorkerTransportationState | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useWorkerGigFields_worker">;
  } | null | undefined;
};
export type TransportationStepQuery = {
  response: TransportationStepQuery$data;
  variables: TransportationStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transportationMethod",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transportationState",
  "storageKey": null
},
v5 = {
  "alias": "WorkerTransportationState",
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "WorkerTransportationState"
    }
  ],
  "concreteType": "__Type",
  "kind": "LinkedField",
  "name": "__type",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "__EnumValue",
      "kind": "LinkedField",
      "name": "enumValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "__type(name:\"WorkerTransportationState\")"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinitionsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GigFieldItemDefinition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransportationStepQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "FragmentSpread",
                "name": "useWorkerGigFields_worker"
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TransportationStepQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "iconName",
                            "storageKey": null
                          },
                          {
                            "alias": "selected",
                            "args": [
                              (v7/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE status = CONFIRMED"
                              }
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": "itemDefinitions(first:10,query:\"WHERE status = CONFIRMED\")"
                          },
                          {
                            "alias": "denied",
                            "args": [
                              (v7/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE status = DENIED"
                              }
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": "itemDefinitions(first:10,query:\"WHERE status = DENIED\")"
                          },
                          {
                            "alias": "available",
                            "args": [
                              (v7/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE status = UNKNOWN"
                              }
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": "itemDefinitions(first:10,query:\"WHERE status = UNKNOWN\")"
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "id": "qpid:TSz7WpK2AyuHii6hEp8NQJXspYzFyGPlqdOwspvQSUU",
    "metadata": {},
    "name": "TransportationStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ebee9f1cc8c20e0774090a4533679b20";

export default node;
