import { Text, showModal } from "@gigsmart/atorasu";
import React from "react";

interface Props {
  contentWarningText: string;
}
export async function showPhoneNumberEmailDetectedModal({
  contentWarningText
}: Props) {
  return await new Promise((resolve) =>
    showModal({
      eventContext: "show-phone-number-email-detected-modal",
      title: "Share Personal Contact Information",
      children: <Text>{contentWarningText}</Text>,
      actions: [
        {
          testID: "do-not-share-btn",
          label: "Do Not Share",
          onPress: (close) => {
            resolve(false);
            close();
          }
        },
        {
          testID: "share-btn",
          label: "Share",
          onPress: (close) => {
            resolve(true);
            close();
          }
        }
      ]
    })
  );
}
