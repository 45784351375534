import React, { Component, type ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export async function checkForUpdate() {
  /* noop */
}

export class ExpoUpdateWrapper extends Component<Props> {
  render() {
    const children = this.props.children;
    return children;
  }
}
