import React from "react";

import {
  Button,
  ContentArea,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  showModal,
  toast
} from "@gigsmart/atorasu";
import { graphql } from "@gigsmart/relay";
import { useRelayCommand } from "../../relay/use-relay-command";
import type { BlockOrgModalAddMutation } from "./__generated__/BlockOrgModalAddMutation.graphql";

type Props = {
  reason: string;
  organizationId: string;
  workerId?: string;
  onClose: () => void;
  onSuccess: () => void;
};

function BlockOrgModal({
  workerId,
  organizationId,
  reason,
  onClose,
  onSuccess
}: Props) {
  const [blockOrganization, { loading }] =
    useRelayCommand<BlockOrgModalAddMutation>(graphql`
      mutation BlockOrgModalAddMutation($input: AsyncAddWorkerBlockedOrganizationInput!) {
        asyncAddWorkerBlockedOrganization(input: $input) {
          ...useRelayCommand_payload
        }
      }
    `);

  const handleAction = async () => {
    try {
      await blockOrganization({ input: { organizationId, reason, workerId } });
      onClose();
      onSuccess();
    } catch (err) {
      toast.error("An error occurred while blocking this organization.");
    }
  };

  return (
    <>
      <ModalHeader title="Are you sure you want to block this Organization?" />
      <ModalBody>
        <ContentArea>
          <Text>
            If you block this Organization you will no longer see Shifts from
            them. You will have to unblock them to see Shifts from them again.
          </Text>
        </ContentArea>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Do Not Block"
          onPress={onClose}
          outline
          color="primary"
          testID="cancel-block-button"
          disabled={loading}
        />
        <Button
          label="Block"
          onPress={handleAction}
          color="primary"
          testID="submit-block-button"
          loading={loading}
        />
      </ModalFooter>
    </>
  );
}

export function showBlockOrgModal(props: Omit<Props, "onClose">) {
  return showModal({
    eventContext: "Block Org Modal",
    children: (close) => <BlockOrgModal onClose={close} {...props} />
  });
}
