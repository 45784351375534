import { Column, SwipeCarousel, useMatchesViewport } from "@gigsmart/atorasu";
import { useStyle } from "@gigsmart/atorasu/style";
import { toFormattedString } from "@gigsmart/isomorphic-shared/worker";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useCallback } from "react";
import AdvertisePositionsBanner from "../svgs/AdvertisePositionsBanner";
import FindTalentBanner from "../svgs/FindTalentBanner";
import PayWorkBanner from "../svgs/PayWorkBanner";
import TrackProgressBanner from "../svgs/TrackProgressBanner";
import type { GetWorkersSliderQuery } from "./__generated__/GetWorkersSliderQuery.graphql";

interface Props {
  onIndexChanged?: (index: number) => void;
}

export default createSuspendedQueryContainer<GetWorkersSliderQuery, Props>(
  function GetWorkersSlider({ response, onIndexChanged }) {
    const isXs = useMatchesViewport({ maxWidth: 480 });
    const svgStyle = useStyle(() => ({
      width: "100%",
      height: "100%"
    }));
    const workerCount = response?.platformStats?.workerCount ?? 0;
    const roundWorkerCount = (num: number) => {
      let roundedNumber = num;
      if (roundedNumber <= 0) {
        return 0;
      }

      const rest = roundedNumber % 10000;

      if (rest > 5000) {
        roundedNumber = roundedNumber - rest + 10000;
      } else {
        roundedNumber = roundedNumber - rest;
      }

      return toFormattedString(roundedNumber);
    };

    const roundedWorkerCount = roundWorkerCount(workerCount);

    const Component = useCallback(() => {
      const swiperData = [
        {
          title: "Find & Hire On-Demand Talent Fast",
          subtitle: `Instantly connect with over ${roundedWorkerCount} qualified and insured Workers to quickly fill Shifts and scale your workforce.`,
          content: () => (
            <Column fill justifyContent={isXs ? "flex-end" : "center"}>
              <FindTalentBanner
                style={svgStyle}
                {...(isXs && { preserveAspectRatio: "xMaxYMax" })}
              />
            </Column>
          )
        },
        {
          title: "Track Shift Progress",
          subtitle:
            "You can easily track Workers’ statuses and Shift progress with live updates in-app.",
          content: () => (
            <Column fill justifyContent={isXs ? "flex-end" : "center"}>
              <TrackProgressBanner
                style={svgStyle}
                {...(isXs && { preserveAspectRatio: "xMaxYMax" })}
              />
            </Column>
          )
        },
        {
          title: "Advertise Full/Part-Time Positions",
          subtitle:
            "Post your Full/Part-Time Positions on the Job Board to get in front of thousands of active job seekers in minutes.",
          content: () => (
            <Column fill justifyContent={isXs ? "flex-end" : "center"}>
              <AdvertisePositionsBanner
                style={svgStyle}
                {...(isXs && { preserveAspectRatio: "xMaxYMax" })}
              />
            </Column>
          )
        },
        {
          title: "Pay for Work Completed",
          subtitle:
            "Payments are made quickly and securely through the app at the end of each Shift.",
          content: () => (
            <Column fill justifyContent={isXs ? "flex-end" : "center"}>
              <PayWorkBanner
                style={svgStyle}
                {...(isXs && { preserveAspectRatio: "xMaxYMax" })}
              />
            </Column>
          )
        }
      ];

      return (
        <SwipeCarousel data={swiperData} loop onIndexChanged={onIndexChanged} />
      );
    }, [isXs, onIndexChanged, roundedWorkerCount, svgStyle]);

    return <Component />;
  },
  {
    query: graphql`
      query GetWorkersSliderQuery {
        platformStats {
          workerCount
        }
      }
    `,
    variables: {}
  }
);
