/**
 * @generated SignedSource<<543f061ab2076c7310813874a095110a>>
 * @relayHash 3eec75b10dbcf9e5c4fa1bb780c675a1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:3RbDelh61eJWKoYt_LuB-ueV1vlFyA64CF8OQ4Xkwd4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type qualificationsScreenQuery$variables = Record<PropertyKey, never>;
export type qualificationsScreenQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"QualificationCategories_gigFields">;
  } | null | undefined;
};
export type qualificationsScreenQuery = {
  response: qualificationsScreenQuery$data;
  variables: qualificationsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "qualificationsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QualificationCategories_gigFields"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "qualificationsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY order ASC"
                  }
                ],
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "iconName",
                            "storageKey": null
                          },
                          {
                            "alias": "selectedDefinitions",
                            "args": [
                              (v0/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE status = CONFIRMED"
                              }
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v1/*: any*/),
                            "storageKey": "itemDefinitions(first:0,query:\"WHERE status = CONFIRMED\")"
                          },
                          {
                            "alias": "availableDefinitions",
                            "args": [
                              (v0/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE status IN [DENIED, UNKNOWN]"
                              }
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v1/*: any*/),
                            "storageKey": "itemDefinitions(first:0,query:\"WHERE status IN [DENIED, UNKNOWN]\")"
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:10,query:\"ORDER BY order ASC\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:3RbDelh61eJWKoYt_LuB-ueV1vlFyA64CF8OQ4Xkwd4",
    "metadata": {},
    "name": "qualificationsScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9486f97be4b67c8bd0b6816d559eb89f";

export default node;
