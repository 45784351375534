import { Icon, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { IconButton, StyledModal } from "@gigsmart/katana";
import React, { type ComponentProps, useState, useRef } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  type ImageStyle,
  type ListRenderItemInfo,
  TouchableOpacity,
  View,
  type ViewStyle
} from "react-native";
import MediaPicker, {
  type FileUpload,
  type MediaPickerRefType
} from "./media-picker";

interface SourceProp {
  readonly url?: string | null;
  readonly full?: string | null;
  readonly thumb?: string | null;
  readonly fileType?: string | null;
  readonly filename?: string | null;
  readonly mimeType?: string | null;
  readonly public?: boolean | null;
}

interface SourceState {
  full: string;
  thumb: string;
}

interface Props {
  style?: ViewStyle;
  label?: string;
  onPhotoPick: (file: FileUpload) => void;
  crop?: boolean | ComponentProps<typeof MediaPicker>["cropOptions"];
  useFrontCamera?: boolean;
  onPhotoRemove: (idx: number) => void;
  showHeader?: boolean;
  sources: ReadonlyArray<SourceProp | null> | null;
  thumbWidth?: number;
  isDeletable?: boolean;
  onEndReached?: ComponentProps<typeof FlatList>["onEndReached"];
  errorMessage?: string | null;
}

interface State {
  activeIndex: number;
  sources: SourceState[];
  zoomed: boolean;
  activeItem?: {
    readonly url?: string | null;
    readonly full?: string | null;
    readonly thumb?: string | null;
  };
}

export default function Carousel({
  errorMessage,
  isDeletable = true,
  onPhotoRemove = () => {},
  showHeader = false,
  thumbWidth = 130,
  label,
  onPhotoPick = () => {},
  crop,
  useFrontCamera = false,
  style,
  sources: propsSources,
  onEndReached
}: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      backgroundColor: getColor("surface", "fill"),
      bottom: 0,
      left: 0,
      right: 0,
      top: 0
    },
    content: {
      flexDirection: "row"
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20
    },
    delete: {
      position: "absolute",
      left: 10,
      top: 10
    },
    header: {
      justifyContent: "center",
      paddingBottom: 16
    },
    icon: {
      color: getColor("primary", "fill"),
      fontSize: 24
    },
    image: {
      backgroundColor: getColor("background", "fill"),
      overflow: "hidden"
    },
    placeholder: {
      alignItems: "center",
      backgroundColor: getColor("background", "fill"),
      borderWidth: 1,
      borderColor: getColor("neutral", "fill"),
      borderRadius: 5,
      justifyContent: "center"
    },
    bevel: {
      backgroundColor: getColor("black", "fill", { opacity: 0.75 }),
      borderRadius: 10,
      padding: 20
    },
    heightWidth100: {
      height: "100%",
      width: "100%"
    },
    imageStyles: {
      position: "relative"
    },
    modal: {
      // 1024px + 290px
      maxWidth: 1314
    },
    modalChildren: {
      maxWidth: 500,
      maxHeight: 500,
      justifyContent: "center",
      flex: 1,
      width: "auto"
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto"
    },
    imgError: { borderColor: getColor("error", "fill") },
    txtError: { color: getColor("error", "fill") }
  }));

  const [activeIndex, setActiveIndex] = useState(0);
  // const [sources, setSources] = useState<Array<SourceState | null>>((propsSources ?? []).filter(
  //   (source) => source && (source.url || (source.thumb && source.full)))
  const [zoomed, setZoomed] = useState(false);
  const [activeItem, setActiveItem] = useState<SourceProp | null>(null);
  // const [loading, setLoading] = useState(false);
  // const [scrollPosition, setScrollPosition] = useState(0);

  // useEffect(() => {
  //   if (!propSources) return;
  //   const newSources = propSources?.filter((s) => !!s?.url);
  //   setSources(newSources as SourcePropLoaded[]);
  // });
  const listRef = useRef<FlatList<SourceProp | null>>(null);
  const mediaPickerRef = useRef<MediaPickerRefType>(null);

  const close = () => setZoomed(false);

  const buildPlaceholder = () => {
    return (
      <TouchableOpacity
        key="placeholder"
        testID="camera-icon"
        onPress={() => mediaPickerRef.current?.open("camera")}
        style={[
          styles.placeholder,
          !!errorMessage && styles.imgError,
          zoomStyles().image as any
        ]}
      >
        <Icon
          name="camera"
          style={[styles.icon, !!errorMessage && styles.txtError]}
        />
      </TouchableOpacity>
    );
  };

  const handleZoom = (index: number, item: SourceProp) => {
    setActiveIndex(zoomed && showHeader ? index + 1 : index);
    setZoomed(!zoomed);
    setActiveItem(item);
  };

  const handleDelete = () => {
    setActiveIndex(activeIndex - 1);
    setZoomed(!zoomed);
  };

  const renderImageHandler = (uri: string, index: number) => {
    return (
      <View style={styles.imageContainer}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <img src={uri} style={{ minHeight: "100px" }} />
        {zoomed && isDeletable && (
          <IconButton
            backgroundColor="#d54405"
            diameter={35}
            width={35}
            icon="trash"
            iconColor={"#ffffff"}
            onPress={() => {
              handleDelete();
              onPhotoRemove(index);
            }}
            style={styles.delete}
          />
        )}
      </View>
    );
  };

  const mapImages = ({
    item,
    index
  }: ListRenderItemInfo<SourceProp | null>) => {
    if (!item) return null;

    const source = { uri: item.full || item.url || "" };
    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => handleZoom(index, item)}
      >
        <View style={[styles.image, zoomStyles().image as any]}>
          <Image
            resizeMode="contain"
            source={source}
            style={styles.heightWidth100 as ImageStyle}
          />
        </View>
      </TouchableOpacity>
    );
  };

  const zoomStyles = () => {
    if (zoomed) {
      const { width } = Dimensions.get("window");
      return {
        image: {
          height: "100%",
          marginLeft: 0,
          marginRight: 0,
          width
        }
      };
    }
    return {
      container: {
        paddingBottom: 10,
        paddingTop: 10,
        width: "100%"
      },
      header: {},
      image: {
        borderRadius: 5,
        height: thumbWidth,
        marginLeft: 10,
        marginRight: 10,
        width: thumbWidth
      }
    };
  };

  const handleKey = (item: SourceProp | null, index: number) =>
    `${index}_${item?.thumb ?? ""}`;

  const sources = propsSources?.filter(
    (source) => source && (source.url || (source.thumb && source.full))
  );

  return (
    <View style={[styles.container, style]}>
      {!!label && (
        <View style={styles.header}>
          <Text color="primary" weight="bold">
            {label}
          </Text>
        </View>
      )}
      <FlatList
        data={sources}
        horizontal
        keyExtractor={handleKey}
        ListHeaderComponent={showHeader ? buildPlaceholder() : null}
        ref={listRef}
        renderItem={mapImages}
        scrollEnabled
        showsHorizontalScrollIndicator={false}
        style={styles.content}
        onEndReached={onEndReached}
      />
      <MediaPicker
        ref={mediaPickerRef}
        front={useFrontCamera}
        cropOptions={crop ? { freeStyle: true, circle: false } : undefined}
        onChange={onPhotoPick}
      />
      {zoomed && (
        <StyledModal
          eventContext={null}
          animationType="slide"
          hasCancelIcon
          visible={zoomed}
          onClose={close}
          style={styles.modal}
          childrenStyle={styles.modalChildren}
        >
          {renderImageHandler(
            activeItem?.full ?? activeItem?.url ?? "",
            activeIndex
          )}
        </StyledModal>
      )}
    </View>
  );
}
