import { Column, Text } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import {
  type CurrentUserShape,
  useCurrentUser
} from "@gigsmart/isomorphic-shared/current-user";
import { checkIsWorkerVerified } from "@gigsmart/isomorphic-shared/worker";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import ShiftActions from "@gigsmart/seibutsu/shifts/accessory/ShiftActions";
import ShiftCommitmentReminder from "@gigsmart/seibutsu/shifts/accessory/ShiftCommitmentReminder";
import ShiftDescriptionItem from "@gigsmart/seibutsu/shifts/accessory/ShiftDescriptionItem";
import React, { type ReactNode } from "react";
import ProjectDescriptionItem from "../engagement-top-card/project-description-item";
import { updateWorkerLocation } from "../use-location-updater";
import type {
  actionRow_engagement$data,
  actionRow_engagement$key
} from "./__generated__/actionRow_engagement.graphql";

interface Props {
  engagementRef?: actionRow_engagement$key | null;
  note?: ReactNode;
}

const EngagementDetailsActionRow = ({ engagementRef, note }: Props) => {
  const user = useCurrentUser();
  const engagement = useRelayFragment(
    graphql`
      fragment actionRow_engagement on Engagement {
        id
        currentState {
          name
          action
        }
        gig {
          gigType
          requiredReportTypes
          ...WorkerLatestArrivalTimeReminder_gig
        }
        commitment {
          ...ShiftCommitmentReminder_commitment
        }
        ...ShiftActions_shift
        ...ShiftDescriptionItem_shift
        ...projectDescriptionItem_engagement
      }
    `,
    engagementRef ?? null
  );

  const isProject = engagement?.gig.gigType === "PROJECT";
  const workerStateName = engagement?.currentState?.name;

  if (
    !engagement ||
    workerStateName === "APPLIED" ||
    workerStateName === "CONFIRMING" || // short circuit to fix UI bug
    (isProject &&
      ["ENGAGED", "HIRE_REQUESTED"].includes(engagement.currentState?.name))
  ) {
    return null;
  }

  return (
    <Column gap="standard">
      {isProject ? (
        <ProjectDescriptionItem engagementRef={engagement} />
      ) : (
        renderHeader(user, engagement)
      )}
      <ShiftActions
        onRefreshLocation={updateWorkerLocation}
        fragmentRef={engagement}
      />
      <ShiftCommitmentReminder fragmentRef={engagement.commitment} />
      {note}
    </Column>
  );
};

const renderHeader = (
  user?: CurrentUserShape | null,
  engagement?: actionRow_engagement$data
) => {
  const stateName = engagement?.currentState?.name;
  const stateAction = engagement?.currentState?.action;
  if (!engagement || !stateName || !stateAction) return null;

  const gigRequiredReports = engagement.gig?.requiredReportTypes ?? [];

  switch (stateName) {
    case "BID_REQUESTED":
    case "BID_REVIEW":
    case "APPLIED":
      return null; // handled by ShiftStatusText

    case "OFFERED": {
      if (
        HourlyRateBids.isEnabled() &&
        (engagement.currentState.action === "COUNTER_OFFER" ||
          engagement.currentState.action === "MODIFY_OFFER")
      ) {
        return null;
      }

      const isWorkerVerified = checkIsWorkerVerified(
        engagement.gig.requiredReportTypes,
        user
      );

      const requiresBgOrMvr = !!gigRequiredReports.find((it) =>
        [
          "CONTINUOUS_BACKGROUND_CHECK",
          "BACKGROUND_CHECK",
          "MOTOR_VEHICLE_CHECK"
        ].includes(it)
      );

      return isWorkerVerified || !requiresBgOrMvr ? (
        <Text variant="note" color="neutral">
          {`Congratulations, you have received a ${
            engagement.gig.gigType === "PROJECT" ? "Project" : "Shift"
          } offer! Please accept to be scheduled.`}
        </Text>
      ) : (
        <Text color="neutral">
          You have been offered a Verified Gig!{" "}
          <Text weight="bold">
            In order to be hired on this Gig, your Worker Verification will need
            to be completed. Accept this offer to begin verification.
          </Text>
        </Text>
      );
    }

    default:
      return <ShiftDescriptionItem fragmentRef={engagement} />;
  }
};

export default EngagementDetailsActionRow;
