import { NavPortalEntrance } from "@gigsmart/kaizoku";
import React, { type ReactNode } from "react";

interface NavProps {
  title?: string;
  showBack?: boolean;
  user?: null;
  hideToasts?: boolean;
  renderLeftIcon?: () => ReactNode;
  renderRightIcon?: () => ReactNode;
  onPressBack?: () => boolean | void;
}

/** @deprecated use NavPortalEntrance from @gigsmart/kaizoku */
export const Entrance = ({
  title,
  showBack,
  onPressBack,
  renderLeftIcon,
  renderRightIcon,
  hideToasts,
  user
}: NavProps) => (
  <NavPortalEntrance
    title={title}
    showBack={showBack}
    onBackPress={onPressBack}
    leftAccessory={renderLeftIcon?.()}
    rightAccessory={renderRightIcon?.()}
    hideProfile={!user}
    disableToasts={hideToasts}
  />
);
