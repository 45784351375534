import {
  CollapsibleContainer,
  ContentArea,
  FancyHeader,
  GridNull,
  ProductInfoRow,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelaySubscribedFragmentContainer,
  graphql
} from "@gigsmart/relay";
import useGigLocalDateTime from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import type { ObjectPath } from "@gigsmart/type-utils";
import { compact, sortBy } from "lodash";
import React, { type ComponentProps, useState } from "react";

import type { HiredProjectsCardSubscription } from "./__generated__/HiredProjectsCardSubscription.graphql";
import type { HiredProjectsCard_worker$key } from "./__generated__/HiredProjectsCard_worker.graphql";

interface Props {
  query: string;
}

export const HiredProjectsCard = ({
  hiredProjects,
  query
}: FragmentContainerInnerComponentProps<
  HiredProjectsCard_worker$key,
  Props
>) => {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();

  const projects = sortBy(
    compact(hiredProjects?.edges),
    (item) => !item?.node?.gig.isAsap
  );
  const projectCount = hiredProjects?.totalCount ?? 0;

  if (projectCount === 0) return <GridNull />;

  const Content = ({
    startSlice,
    stopSlice,
    size
  }: {
    startSlice?: number;
    stopSlice?: number;
    size?: ComponentProps<typeof ContentArea>["size"];
  }) => (
    <ContentArea size="none">
      <Spacer size={size === "none" ? "compact" : "standard"} />
      <Stack size="compact">
        {(projects?.slice(startSlice, stopSlice) ?? []).map(
          (engagement, index) => (
            <RenderProductInfoRow
              id={engagement.node?.id}
              gig={engagement.node?.gig}
              key={`hired-project-${index}`}
            />
          )
        )}
      </Stack>
      <Spacer
        size={
          (size !== "none" && collapsed) || projectCount <= 3
            ? "standard"
            : size === "none" && !collapsed
              ? "standard"
              : "none"
        }
      />
    </ContentArea>
  );

  const RenderProductInfoRow = ({
    gig,
    id
  }: {
    gig: ObjectPath<
      HiredProjectsCard_worker$key,
      [" $data", "hiredProjects", "edges", 0, "node", "gig"]
    >;
    id?: string;
  }) => {
    const formattedDate = useGigLocalDateTime(
      { startsAt: gig?.startsAt, timezone: gig?.timezone },
      { dateFormat: "fullDateShort" }
    );

    const flexible =
      !gig?.isAsap && !gig?.startsAt ? "Flexible Schedule" : null;

    return (
      <ProductInfoRow
        testID={`project-${id}`}
        key={`project-${id}`}
        variant="outline"
        onPress={() => history.push(`/gigs/${id}`)}
        eventEntityType="HiredProject"
        icon="pen-ruler"
        color="highlight"
        iconSquareVariant="standard"
        name={
          <Text
            numberOfLines={1}
            weight="bold"
            variant="subheader"
            color="black"
          >
            {gig?.name}
          </Text>
        }
        note={
          <Text variant="note" color="neutral">
            {flexible ?? formattedDate.dateString}
          </Text>
        }
      />
    );
  };

  return (
    <CollapsibleContainer
      testID={"projects-container"}
      header={
        <FancyHeader
          icon="pen-ruler"
          title={`${pluralize(projectCount, "Project")} You are Hired On`}
        />
      }
      expandLabel="Show More"
      collapseLabel="Show Less"
      content={<Content startSlice={0} stopSlice={3} />}
      onSetCollapsed={(collapsed) => setCollapsed(collapsed)}
      variant="card"
      startCollapsed
    >
      {projectCount > 3 && <Content startSlice={3} size="none" />}
    </CollapsibleContainer>
  );
};

export default createRelaySubscribedFragmentContainer<
  HiredProjectsCard_worker$key,
  HiredProjectsCardSubscription,
  Props
>(HiredProjectsCard, {
  fragment: graphql`
    fragment HiredProjectsCard_worker on Worker
    @argumentDefinitions(query: { type: "String!" }) {
      id
      hiredProjects: engagements(first: 50, query: $query) {
        totalCount
        edges {
          node {
            id
            gig {
              name
              startsAt
              isAsap
              timezone
            }
          }
        }
      }
    }
  `,
  subscription: graphql`
    subscription HiredProjectsCardSubscription(
      $workerId: ID!
      $hiredProjectsQuery: String!
    ) {
      workerUpdated(workerId: $workerId) {
        worker {
          id
          ...HiredProjectsCard_worker @arguments(query: $hiredProjectsQuery)
        }
      }
    }
  `,
  getSubscriptionFragmentRef: (data) => data?.workerUpdated?.worker,
  subscriptionVariables: ({ query, id }) => ({
    workerId: id,
    hiredProjectsQuery: query
  })
});
