import React from "react";
import { type IconName, IconText, type IconVariant } from "../icon";
import { type ViewStyleProp, useStyles } from "../style";
import KatanaTouchableOpacity from "./katana-touchable-opacity";

interface Props {
  testID?: string;
  style?: ViewStyleProp;
  onPress?: () => void;
  title: string;
  iconName?: IconName;
  iconSize?: number;
  iconPos?: "left" | "right";
  iconVariant?: IconVariant;
  disabled?: boolean;
  fill?: boolean;
  tintColor?: string;
  small?: boolean;
}

const ClearInlineButton = ({
  testID,
  style,
  title,
  small,
  iconName = "chevron-right",
  iconSize = iconName.includes("chevron-") ? 12 : 14,
  iconPos = "right",
  iconVariant,
  onPress,
  fill = false,
  tintColor = "blue",
  disabled
}: Props) => {
  const { styles } = useStyles(
    ({ font, units }) => ({
      title: small
        ? { ...font.body("semibold"), fontSize: font.size.small }
        : font.body("bold"),
      containerFill: { alignItems: "center", padding: units(2) }
    }),
    [small]
  );
  return (
    <KatanaTouchableOpacity
      testID={testID}
      onPress={onPress}
      eventTargetName=""
      disabled={disabled}
      style={[fill && styles.containerFill, style]}
    >
      <IconText
        spacing={small ? 1.5 : 2}
        alignItems="center"
        iconName={iconName}
        iconPos={iconPos}
        iconSize={iconSize}
        iconVariant={iconVariant}
        textStyle={styles.title}
        tintColor={tintColor}
      >
        {title}
      </IconText>
    </KatanaTouchableOpacity>
  );
};

export default ClearInlineButton;
