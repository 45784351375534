import type { AppScreenProps } from "@gigsmart/kaizoku";
import { useEffect } from "react";
import type { WorkerParamList } from "../navigation/types";

type Props = AppScreenProps<WorkerParamList, "JobDetailsRedirect">;

export default function JobDetailsRedirect({ route, navigation }: Props) {
  const { jobId } = route.params;
  useEffect(() => {
    navigation.replace("Home");
    if (jobId) navigation.push("JobDetails", { id: jobId });
  }, []);
  return null;
}
