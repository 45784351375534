import React from "react";

import FlexRow from "../flex/row";
import StyledIcon from "../icon/styled-icon";
import { useStyles } from "../style";
import KatanaText from "../text/katana-text";

const UpdatedCardTitle = ({ title }: { title: string }) => {
  const { styles, theme } = useStyles(({ color, units }) => ({
    row: {
      paddingBottom: units(1),
      borderBottomWidth: 1,
      borderBottomColor: color.neutralLight,
      width: "100%"
    },
    icon: {
      paddingLeft: units(1)
    },
    title: {
      flex: 1
    }
  }));

  return (
    <FlexRow
      justifyContent="space-between"
      alignItems="center"
      style={styles.row}
    >
      <KatanaText variant="sectionTitle" numberOfLines={1} style={styles.title}>
        {title}
      </KatanaText>
      <FlexRow alignItems="center">
        <KatanaText variant="rowDescription">View</KatanaText>
        <StyledIcon
          name="chevron-right"
          color={theme.color.neutralDark}
          size={theme.font.size.small}
          style={styles.icon}
          variant="solid"
        />
      </FlexRow>
    </FlexRow>
  );
};

export default UpdatedCardTitle;
