/**
 * @generated SignedSource<<3c9571b8a18a38afb597cefa9aac0faa>>
 * @relayHash ca877e02dadabf039d4067ad2d65d259
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:BztzQTEAnIVSk1gUZxA8v4OguDP4QhfwNe_mO0TYyxg

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicantDirection = "EMAIL" | "WEBSITE" | "%future added value";
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
export type JobPostingReportingType = "HIDDEN" | "STANDARD" | "%future added value";
export type JobPostingState = "CANCELED" | "DISCARDED" | "DRAFT" | "EXPIRED" | "PUBLISHED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JobPostingSearch = {
  applicantDirection?: ApplicantDirection | null | undefined;
  approved?: boolean | null | undefined;
  assignedAdmins?: ReadonlyArray<string> | null | undefined;
  boosted?: boolean | null | undefined;
  canceled?: boolean | null | undefined;
  endDate?: string | null | undefined;
  excludedAdmins?: ReadonlyArray<string> | null | undefined;
  expired?: boolean | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  maxDistance?: number | null | undefined;
  maxStartDate?: string | null | undefined;
  maxTransactionCount?: number | null | undefined;
  minStartDate?: string | null | undefined;
  minTransactionCount?: number | null | undefined;
  orderBy?: ReadonlyArray<OrderBy> | null | undefined;
  payRateNegotiable?: boolean | null | undefined;
  paySchedule?: JobPostingPaySchedule | null | undefined;
  reportingType?: ReadonlyArray<JobPostingReportingType> | null | undefined;
  search?: string | null | undefined;
  startDate?: string | null | undefined;
  state?: JobPostingState | null | undefined;
  states?: ReadonlyArray<JobPostingState> | null | undefined;
  visible?: boolean | null | undefined;
};
export type OrderBy = {
  direction?: SortDirection | null | undefined;
  field: string;
};
export type jobsListJobPostingsPaginationQuery$variables = {
  after?: string | null | undefined;
  count?: number | null | undefined;
  id: string;
  search?: JobPostingSearch | null | undefined;
};
export type jobsListJobPostingsPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"jobsList_jobPostings">;
  } | null | undefined;
};
export type jobsListJobPostingsPaginationQuery = {
  response: jobsListJobPostingsPaginationQuery$data;
  variables: jobsListJobPostingsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": 20,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "jobsListJobPostingsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "jobsList_jobPostings"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "jobsListJobPostingsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "AvailableJobPostingsConnection",
                "kind": "LinkedField",
                "name": "availableJobPostings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableJobPostingsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": "jobPost",
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Place",
                            "kind": "LinkedField",
                            "name": "place",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "streetAddress",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "boosted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "paySchedule",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRateNegotiable",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maximumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minimumPayRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "area",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPosting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": [
                  "search"
                ],
                "handle": "connection",
                "key": "jobsList_availableJobPostings",
                "kind": "LinkedHandle",
                "name": "availableJobPostings"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:BztzQTEAnIVSk1gUZxA8v4OguDP4QhfwNe_mO0TYyxg",
    "metadata": {},
    "name": "jobsListJobPostingsPaginationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "66bf374be76f7db73bb528eaf30130e7";

export default node;
