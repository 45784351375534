import { Persistence, Stack, toast } from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import React from "react";
import FomuTextInput from "../fomu/inputs/FomuTextInput";
import { useEmailChecker } from "../user/Onboarding/EmailChecker";
import HomeScreenCard from "../worker/HomescreenCard";

import FomuSubmit from "../fomu/inputs/FomuSubmit";
import type { ChangeEmailFormCardSetEmailMutation } from "./__generated__/ChangeEmailFormCardSetEmailMutation.graphql";
import type { ChangeEmailFormCard_userEmail$key } from "./__generated__/ChangeEmailFormCard_userEmail.graphql";

export const ChangeEmailFormCard = ({
  id,
  address
}: FragmentContainerInnerComponentProps<ChangeEmailFormCard_userEmail$key>) => {
  const checkEmail = useEmailChecker();
  const [setUserEmail] =
    useRelayMutationPromise<ChangeEmailFormCardSetEmailMutation>(
      graphql`
        mutation ChangeEmailFormCardSetEmailMutation(
          $input: SetUserEmailInput!
        ) {
          setUserEmail(input: $input) {
            userEmail {
              ...ChangeEmailFormCard_userEmail
            }
          }
        }
      `
    );

  const handleSubmit: FomuSubmitFn = async ({ values: { newEmail } }, done) => {
    try {
      const status = await checkEmail({
        address: newEmail,
        userType: "WORKER"
      });
      if (status !== "AVAILABLE") {
        done();
        toast.info("Email already in use");
        return;
      }

      await setUserEmail({
        input: {
          address: newEmail
        }
      });
      toast.success("Email successfully changed!");
      void Persistence.save("email", newEmail);
      Persistence.keep("email");
    } catch (e) {
      toast.error(`Failed to change email: ${e.message}`);
    } finally {
      done();
    }
  };

  return (
    <Form
      initialValues={{
        currentEmail: address,
        newEmail: "",
        newEmailConfirm: ""
      }}
      onSubmit={handleSubmit}
      key={address}
    >
      <HomeScreenCard
        headerTitle="Change Email"
        headerIcon="envelope-open-text"
        action={
          <FomuSubmit label="Save" size="small" testID="change-email-button" />
        }
      >
        <Stack>
          <FomuTextInput
            label="Current Email"
            name="currentEmail"
            editable={false}
          />
          <FomuTextInput
            label="New Email"
            placeholder="New Email"
            name="newEmail"
            validates={[
              Validator.email(),
              Validator.match({
                pattern: new RegExp(`^(?!\\b${escapeRegExp(address)}\\b)`),
                message: "Cannot match Current Email"
              })
            ]}
          />
          <FomuTextInput
            label="Confirm New Email"
            placeholder="Confirm New Email"
            name="newEmailConfirm"
            validates={[
              Validator.confirmationOf({
                field: "newEmail",
                message: "Must match New Email"
              })
            ]}
          />
        </Stack>
      </HomeScreenCard>
    </Form>
  );
};

export default createRelayFragmentContainer<ChangeEmailFormCard_userEmail$key>(
  graphql`
    fragment ChangeEmailFormCard_userEmail on UserEmail {
      id
      address
    }
  `,
  ChangeEmailFormCard
);

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
