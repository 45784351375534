import * as React from "react";
import Svg, { type SvgProps, Defs, Path } from "react-native-svg";

export default function UserCheckSvg(props: SvgProps) {
  return (
    <Svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      {...props}
    >
      <Defs />
      <Path
        fill="none"
        d="M69.094 42.901a8.787 8.787 0 11-6.128 15.079 14.694 14.694 0 01-.868 1.351 10.206 10.206 0 10.935-15.596c.2.362.379.736.548 1.116a8.746 8.746 0 015.513-1.95zM45.143 64.56a14.728 14.728 0 01-7.006-5.412 11.164 11.164 0 01-4.032 2.98h.71l2.07 1.547h3.043l1.76 2.182 1.735-1.297zM64.694 62.128a11.136 11.136 0 01-3.144-2.064 14.717 14.717 0 01-6.32 4.496h1.718l.754.564 1.169-1.45h3.044l2.07-1.546zM37.626 58.343a14.624 14.624 0 01-.802-1.518 8.787 8.787 0 11-.468-10.869q.23-.656.52-1.282a10.204 10.204 0 10.75 13.669z"
      />
      <Path
        fill="#e7e7e7"
        d="M38.837 66.623l-.903-1.148H21.476l-3.012 3.833h15.998l2.166-2.685h2.209zM65.91 69.308h14.426l-3.012-3.833H60.865l-.902 1.148h3.782l2.165 2.685zM62.966 57.98a8.787 8.787 0 10.615-13.13 14.563 14.563 0 01-.615 13.13zM36.356 45.956a8.788 8.788 0 10.468 10.87 14.467 14.467 0 01-.468-10.87zM24.05 63.675h11.309l-.848-.635h-9.613l-.848.635zM63.44 63.675h11.309l-.848-.635h-9.613l-.848.635z"
      />
      <Path
        fill="#fcb44a"
        d="M62.872 45.485a13.682 13.682 0 00-.559-1.165c-.135-.252-.287-.492-.438-.734l-1.608 1.608-9.797 9.797a.73.73 0 01-1.032 0L44.1 49.654a.73.73 0 010-1.033l1.02-1.02a.73.73 0 011.033 0l3.285 3.284a.73.73 0 001.032 0l9.613-9.613a13.722 13.722 0 00-22.117 3.24c-.15.29-.298.582-.427.884a13.748 13.748 0 00.67 12.153c.163.288.333.572.515.847a13.73 13.73 0 0022.177 1.014c.194-.24.378-.489.556-.743a13.703 13.703 0 001.415-13.182zM64.739 69.308l-1.43-1.773H37.063l-1.43 1.773-3.77 4.673h36.645l-3.769-4.673zM42.189 66.623h.118l-.045-.055-.073.055z"
      />
      <Path
        fill="#fcb44a"
        d="M57.859 66.381l-.731-.547-.483-.361H43.726l-1.464 1.095.045.055H58.182l-.323-.242z"
      />
      <Path
        fill="#083c5c"
        d="M50.186 31.496a.98.98 0 00.98-.98v-8.974a.98.98 0 00-1.96 0v8.974a.98.98 0 00.98.98zM60.612 34.457a.98.98 0 001.339-.359l4.486-7.771a.98.98 0 00-1.697-.98l-4.487 7.77a.98.98 0 00.359 1.34zM38.315 34.098a.98.98 0 001.698-.98l-4.487-7.771a.98.98 0 10-1.698.98zM83.631 69.021l-4.313-5.346h-3.044l-2.07-1.547h-.71a11.11 11.11 0 10-10.937-19.175l-.018-.03 3.236-3.236a.73.73 0 000-1.033l-1.02-1.02a.73.73 0 00-1.033 0l-2.994 2.993a14.644 14.644 0 00-23.44 3.192 11.107 11.107 0 10-11.983 18.309h-.71l-2.07 1.547h-3.043l-4.313 5.346a.456.456 0 10.71.573l4.039-5.007h19.575l1.465 1.816-.295.22h-4.035l-2.166 2.685-3.54 4.387a.456.456 0 10.71.572l.231-.286 3.77-4.674 1.43-1.772h26.246l1.43 1.772 3.77 4.674.23.286a.456.456 0 10.71-.572l-3.539-4.387-2.165-2.685h-4.037l-1.275-.953.873-1.083h19.576l4.039 5.007a.456.456 0 00.71-.573zM69.094 41.72a10.204 10.204 0 11-6.996 17.611 14.558 14.558 0 001.483-14.48c-.17-.38-.348-.754-.548-1.116a10.136 10.136 0 016.061-2.015zM37.54 45.396c.13-.302.276-.594.427-.885a13.722 13.722 0 0122.117-3.24l-9.613 9.614a.73.73 0 01-1.032 0L46.153 47.6a.73.73 0 00-1.032 0l-1.02 1.02a.73.73 0 000 1.033l5.336 5.337a.73.73 0 001.033 0l9.797-9.797 1.608-1.608c.15.242.303.482.438.734.203.379.391.767.559 1.165a13.64 13.64 0 01-1.415 13.182c-.178.254-.362.503-.556.743a13.73 13.73 0 01-22.176-1.014 13.802 13.802 0 01-.515-.847 13.741 13.741 0 01-.671-12.153zm-18.037 6.528a10.196 10.196 0 0117.373-7.25q-.29.626-.519 1.282a14.525 14.525 0 001.27 12.387 10.196 10.196 0 01-18.124-6.42zm4.548 11.75l.848-.634h9.613l.848.635zm17.639 2.183l-1.76-2.182h-3.045l-2.07-1.547h-.71a11.164 11.164 0 004.033-2.98 14.728 14.728 0 007.006 5.412h-1.72zm15.975.766H42.19l.074-.055 1.463-1.095h12.92l.482.361.731.547.323.242zm1.207-2.948l-1.17 1.449-.753-.564h-1.719a14.717 14.717 0 006.32-4.496 11.136 11.136 0 003.145 2.064h-.709l-2.07 1.547zm4.569 0l.848-.635H73.9l.848.635z"
      />
    </Svg>
  );
}
