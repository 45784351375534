import { Column, useDebouncedEffect } from "@gigsmart/atorasu";
import React, { useState, useEffect } from "react";
import { Dimensions } from "react-native";

import { compact, isEqual } from "lodash";
import { useGeolocation } from "../../geolocation";
import { useUserValue } from "../../user-value";
import JobsListHeader from "./job-list-header";
import JobsList from "./jobs-list";

const { height: SCREEN_HEIGHT } = Dimensions.get("window");

export interface Values {
  searchTerms: string;
  radiusMiles: number;
  location: { label: string; lat: number; lng: number };
}

interface Props {
  display?: "flex" | "none";
  onPressAffiliate?: (job: { name: string; url: string }) => void;
}

export default function JobsListScreen({ display, onPressAffiliate }: Props) {
  const currentLocation = useGeolocation(true);
  const [collapsed, setCollapsed] = useState(false);
  const [filters, setFilters] = useState<Values>();
  const [jobBoardFilters, setJobBoardFilters] =
    useUserValue<Values>("jobBoardFilters");

  useEffect(() => {
    const location = {
      lat: currentLocation?.latitude ?? 0,
      lng: currentLocation?.longitude ?? 0,
      label: compact([currentLocation?.area, currentLocation?.postalCode]).join(
        " "
      )
    };

    const init = async () => {
      if (jobBoardFilters) {
        if (typeof jobBoardFilters.location === "string") {
          setFilters({ ...jobBoardFilters, location });
        } else {
          setFilters(jobBoardFilters);
        }
      } else {
        setFilters({
          searchTerms: "",
          radiusMiles: 75,
          location
        });
      }
    };

    void init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation]);

  useDebouncedEffect(() => {
    if (!filters || isEqual(filters, jobBoardFilters)) return;
    void setJobBoardFilters(filters);
  }, [filters]);

  if (!filters) return null;
  return (
    <Column fill display={display}>
      <JobsListHeader
        filters={filters}
        onChange={setFilters}
        collapsed={collapsed}
      />
      <JobsList
        filters={filters}
        onScroll={({ nativeEvent }) => {
          const shouldCollapse =
            nativeEvent.contentOffset.y >= SCREEN_HEIGHT * 1.5;
          if (shouldCollapse !== collapsed) setCollapsed(shouldCollapse);
        }}
        onPressAffiliate={onPressAffiliate}
      />
    </Column>
  );
}
