import "react-native-gesture-handler";
import "react-native-get-random-values";
import "@gigsmart/isomorphic-shared/app/intl-polyfill";
import "@gigsmart/isomorphic-shared/app/url-polyfill";
import "@gigsmart/isomorphic-shared/app/promises";
import "./src/app/config";
import { AppRegistry } from "react-native";
import WorkerApp from "./src/app";
import "expo-asset";
import { patchConsole } from "@gigsmart/roga/backends/console";

patchConsole();

AppRegistry.registerComponent("Worker", () => WorkerApp);
