/**
 * @generated SignedSource<<3035709ffc4f1b25fbc6202bf789c721>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CancellationReasonCommentType = "NONE" | "OPTIONAL" | "REQUIRED" | "%future added value";
export type CancellationReasonOptionVariant = "ISSUE_REPORTED" | "MISSING_QUALIFICATION" | "NO_SHOW" | "STANDARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CancellationReasonQuestion_reason$data = {
  readonly id: string;
  readonly options: ReadonlyArray<{
    readonly blockOption: {
      readonly id: string;
    } | null | undefined;
    readonly commentPrompt: string | null | undefined;
    readonly commentType: CancellationReasonCommentType;
    readonly id: string;
    readonly maximumCommentLength: number | null | undefined;
    readonly minimumCommentLength: number | null | undefined;
    readonly title: string | null | undefined;
    readonly variant: CancellationReasonOptionVariant;
  }>;
  readonly prompt: string | null | undefined;
  readonly summary: string;
  readonly " $fragmentType": "CancellationReasonQuestion_reason";
};
export type CancellationReasonQuestion_reason$key = {
  readonly " $data"?: CancellationReasonQuestion_reason$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancellationReasonQuestion_reason">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancellationReasonQuestion_reason",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementCancellationReasonOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variant",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commentPrompt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimumCommentLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maximumCommentLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BlockReasonOption",
          "kind": "LinkedField",
          "name": "blockOption",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementCancellationReasonCategory",
  "abstractKey": null
};
})();

(node as any).hash = "8ab8f28d7a24363e11f8c881418ff139";

export default node;
