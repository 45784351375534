import {
  Button,
  ContentArea,
  FooterSpacer,
  LoadingView,
  ProductInfoRow
} from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { showProjectGigsInfoModal } from "@gigsmart/seibutsu/modals/ProjectGigsInfoModalTabs";
import { showShiftGigInfoModal } from "@gigsmart/seibutsu/modals/ShiftGigsInfoModalTabs";
import { type NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import type { WorkerParamList } from "../../navigation/types";
import type { ProjectFilterType } from "./ProjectGigsFilter";
import type { ShiftFilterType } from "./ShiftGigsFilter";

type Props = {
  isLoading?: boolean;
  isEmpty?: boolean;
} & (
  | {
      variant: "shifts";
      active?: ShiftFilterType;
      onChange: (value: ShiftFilterType) => void;
    }
  | {
      variant: "projects";
      active?: ProjectFilterType;
      onChange: (value: ProjectFilterType) => void;
    }
);

export default function ProjectGigsFooter({
  isLoading,
  isEmpty,
  variant,
  active,
  onChange
}: Props) {
  const navigation = useNavigation<NavigationProp<WorkerParamList>>();

  const renderContent = () => {
    if (variant === "projects") {
      return isEmpty ? (
        <ProductInfoRow
          variant="card"
          testID="view-projects-gigs-btn"
          icon="pen-ruler"
          name="Interested in working Project Gigs?"
          note="Browse & apply to Project Gigs now"
          color="primary"
          onPress={() => navigation.navigate("BrowseProjects")}
        />
      ) : (
        <ProductInfoRow
          variant="card"
          testID="view-applied-gigs-btn"
          icon="user-check"
          name="Looking for a Project you applied to?"
          note="View them in the Applied tab"
          onPress={() => onChange("applied")}
        />
      );
    }

    return isEmpty ? (
      <ProductInfoRow
        variant="card"
        testID="view-shift-gigs-btn"
        icon="business-time"
        name="Interested in working Shift Gigs?"
        note="Browse & apply to Shift Gigs near you now"
        color="warning"
        onPress={() => navigation.navigate("BrowseShifts", { type: "shifts" })}
      />
    ) : active !== "applied" ? (
      HourlyRateBids.select(
        null,
        <ProductInfoRow
          variant="card"
          testID="view-applied-gigs-btn"
          icon="user-check"
          name="Looking for a Shift you applied to?"
          note="View them in the Applied tab"
          onPress={() => onChange("applied")}
        />
      )
    ) : null;
  };

  return (
    <>
      {isLoading && <LoadingView />}
      <ContentArea gap="standard">
        {renderContent()}
        {variant === "projects" ? (
          <Button
            testID="about-project-gigs-btn"
            label="About Project Gigs"
            variant="clear"
            icon="circle-question"
            size="large"
            onPress={showProjectGigsInfoModal}
          />
        ) : (
          <Button
            testID="about-shift-gigs-and-statuses-btn"
            label="About Shift Gigs & Statuses"
            variant="clear"
            icon="circle-question"
            size="large"
            onPress={showShiftGigInfoModal}
          />
        )}
      </ContentArea>
      <FooterSpacer size="none" />
    </>
  );
}
