import React from "react";

import { useStyles } from "@gigsmart/atorasu/style";
import { useFormField } from "@gigsmart/fomu";

import { Carousel } from "../media";
import type { FileUpload } from "../media/media-picker";

interface Props {
  name: string;
  label: string;
}

export interface PhotoType {
  readonly id: string;
  readonly url: string;
}

export function FomuStyledPhotoCarouselInput({ name, label }: Props) {
  const styles = useStyles(() => ({
    flex: {
      flex: 1
    }
  }));

  const { value, setValue } = useFormField<PhotoType[]>(name);

  const handleAddPhoto = ({ id, url }: FileUpload) => {
    const newPhoto: PhotoType = { id, url };
    setValue(value ? [...value, newPhoto] : [newPhoto]);
  };

  const handleRemovePhoto = (index: number) => {
    const newValue = value?.filter((d, idx) => idx !== index);
    if (newValue) setValue(newValue);
  };

  return (
    <Carousel
      label={label}
      showHeader={!value || (!!value && value.length < 10)}
      sources={value}
      onPhotoPick={handleAddPhoto}
      onPhotoRemove={handleRemovePhoto}
      style={styles.flex}
      thumbWidth={60}
      isDeletable
    />
  );
}
