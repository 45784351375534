import {
  graphql,
  useRelayFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import type { usePendingOfferCountSubscription } from "./__generated__/usePendingOfferCountSubscription.graphql";
import type { usePendingOfferCount_worker$key } from "./__generated__/usePendingOfferCount_worker.graphql";

export default function usePendingOfferCount(
  workerRef: usePendingOfferCount_worker$key | null | undefined
) {
  const worker = useRelayFragment(
    graphql`
      fragment usePendingOfferCount_worker on Worker {
        id
        allOffers: engagements(
          where: {
            currentStateName: { _in: [OFFERED, BID_REQUESTED] }
          }
          first: 0
        ) {
          totalCount
        }
        projectOffers: engagements(
          where: {
            gigType: { _eq: PROJECT }
            currentStateName: { _eq: OFFERED }
          }
          first: 0
        ) {
          totalCount
        }
        pendingCount: engagements(
          where: {
            gigType: { _neq: PROJECT }
            currentStateName: { _in: [OFFERED] }
          }
          first: 0
        ) {
          totalCount
        }
        bidRequestsCount: engagements(
          where: {
            gigType: { _neq: PROJECT }
            currentStateName: { _in: [BID_REQUESTED] }
          }
          first: 0
        ) {
          totalCount
        }
      }
    `,
    workerRef ?? null
  );

  useRelaySubscription<usePendingOfferCountSubscription>(
    graphql`
      subscription usePendingOfferCountSubscription($workerId: ID!) {
        workerUpdated(workerId: $workerId) {
          worker {
            ...usePendingOfferCount_worker
          }
        }
      }
    `,
    { workerId: String(worker?.id) },
    { subscribe: !!worker?.id }
  );

  return {
    totalPendingOffers: worker?.allOffers?.totalCount ?? 0,
    totalProjectOffers: worker?.projectOffers?.totalCount ?? 0,
    totalShiftOffers: worker?.pendingCount?.totalCount ?? 0,
    totalBidRequests: worker?.bidRequestsCount?.totalCount ?? 0
  };
}
