import React, { type ReactNode } from "react";

import { Constraint, Stack, Visible } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { StyledModal } from "@gigsmart/katana";

interface Props {
  children: ReactNode;
  visible: boolean;
  onClose: () => void;
  eventContext: string;
  testID?: string;
  hideCancel?: boolean;
}

export default function InfoModal({
  children,
  visible,
  onClose,
  eventContext,
  testID,
  hideCancel
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    outerModal: {
      paddingHorizontal: getUnits(4)
    },
    innerModal: {
      paddingTop: hideCancel ? getUnits(6) : getUnits(12)
    }
  }));

  return (
    <StyledModal
      visible={visible}
      onClose={onClose}
      eventContext={eventContext}
      hasCancelIcon={!hideCancel}
      outerStyle={styles.outerModal}
      style={styles.innerModal}
      testID={testID}
    >
      <Visible media={(media) => media.platform.web}>
        <Constraint size="xsmall">
          <Stack>{children}</Stack>
        </Constraint>
      </Visible>
      <Visible media={(media) => media.platform.mobile}>
        <Stack>{children}</Stack>
      </Visible>
    </StyledModal>
  );
}
