/**
 * @generated SignedSource<<96498b6cb5f6ce17d5110a8e575bbfd8>>
 * @relayHash 4d302b69553a5c1c8fb19427de22983d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:rYQB2DH7W8J94bWGNh-BxGBWVkwD8DvL5RXwEiqNfHY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpcomingShiftsWorkerHomeScreenSubscription$variables = {
  upcomingShiftsQuery: string;
};
export type UpcomingShiftsWorkerHomeScreenSubscription$data = {
  readonly workerUpdated: {
    readonly worker: {
      readonly " $fragmentSpreads": FragmentRefs<"UpcomingShifts_WorkerHomeScreen">;
    };
  } | null | undefined;
};
export type UpcomingShiftsWorkerHomeScreenSubscription = {
  response: UpcomingShiftsWorkerHomeScreenSubscription$data;
  variables: UpcomingShiftsWorkerHomeScreenSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "upcomingShiftsQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpcomingShiftsWorkerHomeScreenSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "upcomingShiftsQuery",
                    "variableName": "upcomingShiftsQuery"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "UpcomingShifts_WorkerHomeScreen"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpcomingShiftsWorkerHomeScreenSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "alias": "upcomingShifts",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  },
                  {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "upcomingShiftsQuery"
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timezone",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logoUrl",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:rYQB2DH7W8J94bWGNh-BxGBWVkwD8DvL5RXwEiqNfHY",
    "metadata": {},
    "name": "UpcomingShiftsWorkerHomeScreenSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "9ac6e13a1ecf8b746a0664706635f08a";

export default node;
