import { ContentArea, Stack } from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import DirectHireActionRow from "@gigsmart/seibutsu/direct-hire/worker/DirectHireActionRow";
import { EngagementRatingStack } from "@gigsmart/seibutsu/engagement/EngagementRatingStack";
import WorkerPaymentSurface from "@gigsmart/seibutsu/shifts/accessory/WorkerPaymentSurface";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import React from "react";
import type { summaryTab_engagement$key } from "./__generated__/summaryTab_engagement.graphql";

interface Props {
  engagement: summaryTab_engagement$key;
}

/**
 * @deprecated use WorkerSummaryTab from seibutsu
 */
export default function SummaryTab({
  engagement: engagementFragmentRef
}: Props) {
  const engagement = useRelayFragment<summaryTab_engagement$key>(
    graphql`
      fragment summaryTab_engagement on Engagement {
        ...WorkerPaymentSurface_shift
        id
        reviewOfRequester {
          rating
          review
        }
        reviewOfWorker {
          rating
          review
        }
        currentState {
          name
        }
        gig {
          ...BlockOrganizationCollapsibleRow_shift
          id
          organization {
            id
          }
        }
      }
    `,
    engagementFragmentRef
  );
  return (
    <Stack>
      <WorkerPaymentSurface fragmentRef={engagement} />
      <ContentArea size="none">
        <DirectHireActionRow variant="card" />
      </ContentArea>
      <EngagementRatingStack
        variant="worker"
        reviewOfRequester={engagement.reviewOfRequester}
        reviewOfWorker={engagement.reviewOfWorker}
      />
      {showWorkerBlockOrg(engagement?.gig, engagement?.currentState?.name) && (
        <BlockOrganizationCollapsibleRow
          organizationId={engagement?.gig?.organization?.id ?? ""}
        />
      )}
    </Stack>
  );
}
