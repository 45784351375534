import { Card } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { WorkerEngagementCardFooter } from "../../engagement/WorkerEngagementCard/WorkerEngagementCardFooter";
import WorkerEngagementCardHeader from "../../engagement/WorkerEngagementCard/WorkerEngagementCardHeader";
import { PickupEligibleAttachment } from "./PickupEligibleAttachment";
import WorkerShiftCardBody from "./WorkerShiftCardBody";
import WorkerShiftCardFooter from "./WorkerShiftCardFooter";
import WorkerShiftCardHeader from "./WorkerShiftCardHeader";

import { HourlyRateBids } from "@gigsmart/feature-flags";
import type {
  EngagementStateAction,
  EngagementStateName
} from "@gigsmart/isomorphic-shared/gig/helpers";
import type { WorkerEngagementCardV2_engagement$key } from "./__generated__/WorkerEngagementCardV2_engagement.graphql";

interface Props {
  onPress?: (id: string) => void;
}

export const WorkerEngagementCardV2 = ({
  id,
  workerDistance,
  startsAt,
  gig,
  currentState,
  currentNegotiation,
  disputes,
  pickupStateCount,
  capabilities,
  paymentInfo,
  estimatedPayment,
  result,
  onPress
}: FragmentContainerInnerComponentProps<
  WorkerEngagementCardV2_engagement$key,
  Props
>) => {
  const openDisputeExpiration = disputes?.edges?.[0]?.node?.expiresAt;
  const isPickupShift = (pickupStateCount?.states?.totalCount ?? 0) > 0;
  const isFilled = !!gig?.isFilled;

  const isTotal = currentState.name === "ENDED";
  const netPay = isTotal
    ? paymentInfo?.netPay
    : currentState.name === "PENDING_TIMESHEET_APPROVAL"
      ? "TBD"
      : estimatedPayment?.netPay;

  const offeredPayRate =
    currentState?.name === "SCHEDULED"
      ? currentNegotiation?.payRate
      : estimatedPayment?.payRate;
  const payRate = offeredPayRate || gig?.payRate;

  return (
    <Card
      onPress={() => onPress?.(id)}
      testID="worker-engagement-card"
      eventTargetName="Worker Engagement Card"
    >
      <WorkerShiftCardHeader
        fragmentRef={gig}
        attachment={
          isPickupShift && currentState.name === "SCHEDULED" ? (
            <PickupEligibleAttachment />
          ) : (
            <WorkerEngagementCardHeader fragmentRef={result} />
          )
        }
      />
      <WorkerShiftCardBody
        fragmentRef={gig}
        startsAtOverride={startsAt}
        distance={workerDistance}
      />
      {showFooter(currentState.name) && (
        <WorkerShiftCardFooter
          fragmentRef={gig}
          isTotal={isTotal}
          netPay={netPay}
          payRate={payRate}
          payRatePrefix={payRatePrefix(currentState.name, currentState.action)}
          attachment={
            WorkerEngagementCardFooter.isVisible(
              currentState,
              openDisputeExpiration,
              isPickupShift
            ) ? (
              <WorkerEngagementCardFooter
                id={id}
                state={currentState?.name}
                reconcilesAt={gig?.reconcilesAt}
                capabilities={capabilities}
                openDisputeExpiration={openDisputeExpiration}
                isPickupShift={isPickupShift}
                isFilled={isFilled}
              />
            ) : undefined
          }
        />
      )}
    </Card>
  );
};

export default createRelayFragmentContainer<
  WorkerEngagementCardV2_engagement$key,
  Props
>(
  graphql`
    fragment WorkerEngagementCardV2_engagement on Engagement {
      ...WorkerEngagementCardHeader_engagement
      id
      startsAt
      workerDistance
      currentState {
        name
        action
      }
      estimatedPayment {
        netPay
        payRate
      }
      paymentInfo {
        netPay
      }
      currentNegotiation {
        payRate
      }
      gig {
        ...WorkerShiftCardHeader_gigLike
        ...WorkerShiftCardFooter_gigLike
        ...WorkerShiftCardBody_gig
        reconcilesAt
        payRate
        isFilled
      }
      disputes(first: 1, query: "WHERE approvalDisposition = NULL") {
        totalCount
        edges {
          node {
            expiresAt
          }
        }
      }
      pickupStateCount: timesheet(variant: SYSTEM) {
        states(first: 0, query: "WHERE action = PICKUP") {
          totalCount
        }
      }
      capabilities {
        status
        type
        restrictedBy {
          message
        }
        expiresAt
      }
    }
  `,
  WorkerEngagementCardV2
);

//
//

function showFooter(engagementStateName: string) {
  return ![
    // NOT HIRED
    "MISSED",
    "APPLICATION_DENIED",
    "APPLICATION_CANCELED",
    "REJECTED",

    // CANCELED
    "CANCELED",
    "CANCELED_WITH_PAY"
  ].includes(engagementStateName);
}

function payRatePrefix(
  name: EngagementStateName,
  action: EngagementStateAction
) {
  if (HourlyRateBids.isEnabled()) {
    switch (name) {
      case "BID_REVIEW":
        return "Your Bid:";
      case "BID_REQUESTED":
        return "Offered Rate:";
      case "OFFERED":
        return action === "ACCEPT"
          ? "Accepted Rate:"
          : action === "COUNTER_OFFER"
            ? "Countered Rate:"
            : "Offered Rate:";
    }
  }

  return "";
}
