import { ContentArea, Row, SadPanda, Text } from "@gigsmart/atorasu";
import { captureError, track } from "@gigsmart/dekigoto";
import { DeveloperDebug } from "@gigsmart/feature-flags";
import React, { Component, type ReactNode } from "react";

export default class ErrorBoundary extends Component<
  {
    url: string;
    children: ReactNode;
  },
  { error: Error | null | undefined }
> {
  static getDerivedStateFromError(error: Error) {
    // unauthenticated errors are handled elsewhere
    return { error };
  }

  state: { error: Error | null | undefined } = {
    error: null
  };

  componentDidCatch(error: Error, _info: unknown) {
    track(`🐼: ${error.message}`);
    captureError(error, "critical");
  }

  render() {
    const { children, url } = this.props;
    const { error } = this.state;
    if (error) {
      return process.env.CONFIG_ENV === "prod" &&
        DeveloperDebug.isDisabled() ? null : (
        <Row>
          <ContentArea
            size="compact"
            justifyContent="center"
            alignItems="center"
          >
            <SadPanda height={50} width={50} />
            <Text color="danger" variant="note">
              APPLET ERROR
            </Text>
          </ContentArea>
          <ContentArea size="compact" wrap="wrap" fill>
            <Text color="danger" variant="note" weight="bold">
              from: {url}
              {"\n"}
            </Text>
            <Text color="danger" variant="note">
              {`${error?.name}\n${error?.message}`}
            </Text>
          </ContentArea>
        </Row>
      );
    }
    return children;
  }
}
