import {
  Avatar,
  MaxWidthContainer,
  NavAction,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { OrgProfileRedesign } from "@gigsmart/feature-flags";
import {
  useCurrentOrganization,
  useCurrentUser
} from "@gigsmart/isomorphic-shared/current-user";
import { useHistory } from "@gigsmart/kaizoku";
import React from "react";

export default function NavUserProfile() {
  const history = useHistory();
  const user = useCurrentUser();
  const org = useCurrentOrganization();
  if (!user) return null;

  const isBusiness = user?.__typename === "OrganizationRequester";
  const handlePress = () => {
    if (isBusiness && OrgProfileRedesign.isEnabled()) {
      history.push("/organization");
    } else history.push("/profile");
  };

  return (
    <NavAction testID="profile-nav-link" onPress={handlePress}>
      <MaxWidthContainer maxWidth={255}>
        <Row alignItems="center">
          <Spacer horizontal />
          <Avatar
            color="#ffffff30"
            size="medium"
            icon={isBusiness ? "globe" : "circle-user"}
            uri={isBusiness ? org?.logoUrl : user.profilePhoto?.url}
          />
          <Spacer horizontal size="compact" />
          <Text numberOfLines={1} fill>
            {isBusiness ? org?.name : user.displayName}
          </Text>
        </Row>
      </MaxWidthContainer>
    </NavAction>
  );
}
