import React from "react";

import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  createSuspendedQueryContainer,
  graphql
} from "@gigsmart/relay";

import HomeScreenCard from "../HomescreenCard";
import JobPostSection from "./JobBoardSection";
import ProjectGigSection from "./ProjectGigSection";
import ShiftGigSection from "./ShiftGigSection";

import type { AvailableGigsCardQuery } from "./__generated__/AvailableGigsCardQuery.graphql";
import type { AvailableGigsCard_worker$key } from "./__generated__/AvailableGigsCard_worker.graphql";
import type { JobBoardSection_root$key } from "./__generated__/JobBoardSection_root.graphql";

const MAX_DISTANCE = 75;
interface AvailableGigsCardWithQueryProps {
  geolocation: any;
}

export const AvailableGigsCardWithQuery = createSuspendedQueryContainer<
  AvailableGigsCardQuery,
  AvailableGigsCardWithQueryProps
>(
  ({ response }) => (
    <HomeScreenCard
      headerIcon="location-dot"
      headerTitle="Available In Your Area"
      testID="available-area-card"
      contentAreaSize="none"
      contentAreaVariant="none"
    >
      <ShiftGigSection fragmentRef={response?.viewer} />
      <ProjectGigSection fragmentRef={response?.viewer} />
      <JobPostSection
        jobPostFragmentRef={response?.viewer}
        affiliateJobPostFragmentRef={response}
      />
    </HomeScreenCard>
  ),
  {
    query: graphql`
      query AvailableGigsCardQuery(
        $maxDistance: Int!
        $jobPostSearch: JobPostingSearch!
        $affiliateJobPostSearch: AffiliateJobPostingsInput!
      ) {
        ...JobBoardSection_root
          @arguments(affiliateJobPostSearch: $affiliateJobPostSearch)
        viewer {
          ... on Worker {
            ...ShiftGigSection_worker @arguments(maxDistance: $maxDistance)
            ...ProjectGigSection_worker @arguments(maxDistance: $maxDistance)
            ...JobBoardSection_worker @arguments(jobPostSearch: $jobPostSearch)
          }
        }
      }
    `,
    variables: ({ geolocation }) => ({
      maxDistance: MAX_DISTANCE,
      jobPostSearch: {
        maxDistance: MAX_DISTANCE,
        orderBy: [{ field: "boosted", direction: "DESC" as const }],
        latitude: geolocation.latitude,
        longitude: geolocation.longitude
      },
      affiliateJobPostSearch: {
        maxDistance: MAX_DISTANCE,
        latitude: geolocation.latitude,
        longitude: geolocation.longitude
      }
    })
  }
);

interface Props {
  affiliateJobPostFragmentRef: JobBoardSection_root$key;
}

export const AvailableGigsCardWithFragment = ({
  affiliateJobPostFragmentRef,
  result
}: FragmentContainerInnerComponentProps<
  AvailableGigsCard_worker$key,
  Props
>) => {
  return (
    <HomeScreenCard
      headerIcon="location-dot"
      testID="available-area-card"
      headerTitle="Available In Your Area"
      contentAreaSize="none"
      contentAreaVariant="none"
    >
      <ShiftGigSection fragmentRef={result} />
      <ProjectGigSection fragmentRef={result} />
      <JobPostSection
        jobPostFragmentRef={result}
        affiliateJobPostFragmentRef={affiliateJobPostFragmentRef}
      />
    </HomeScreenCard>
  );
};

export default createRelayFragmentContainer<
  AvailableGigsCard_worker$key,
  Props
>(
  graphql`
    fragment AvailableGigsCard_worker on Worker
    @argumentDefinitions(
      maxDistance: { type: "Int" }
      jobPostSearch: { type: "JobPostingSearch!" }
    ) {
      ...ShiftGigSection_worker @arguments(maxDistance: $maxDistance)
      ...ProjectGigSection_worker @arguments(maxDistance: $maxDistance)
      ...JobBoardSection_worker @arguments(jobPostSearch: $jobPostSearch)
    }
  `,
  AvailableGigsCardWithFragment
);
