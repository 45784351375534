import { BrandButton, Platform } from "@gigsmart/atorasu";
import {
  appleSignin,
  isAppleSupported
} from "@gigsmart/isomorphic-shared/auth/social-login/apple-signin-helper";
import React from "react";
import useLogin from "../hooks/useLogin";

interface Props {
  appName: "Get Gigs" | "Get Workers";
  signup?: boolean;
  onLogin?: () => void;
}
export default function AppleLoginButon({ appName, onLogin, signup }: Props) {
  const { processLogin, handleError } = useLogin(
    appName === "Get Gigs" ? "WORKER" : "REQUESTER"
  );

  if (!isAppleSupported()) return null;

  const handleLogin = async () => {
    try {
      const { token, firstName, lastName } = (await appleSignin()) ?? {};
      if (token) {
        void processLogin({
          input: {
            identifier: "",
            credential: token,
            authenticationProvider: "APPLE"
          },
          userProps: { firstName, lastName },
          onLogin
        });
      }
    } catch (err: any) {
      handleError(
        err,
        "Apple Sign-in is currently unavailable. Please contact Support"
      );
    }
  };

  const verb = signup ? "Sign up" : "Sign in";

  return (
    <BrandButton
      label={`${verb} with Apple`}
      brand="apple"
      onPress={handleLogin}
      appleFont={Platform.OS === "ios"}
    />
  );
}
