import { getConnectionNodes, graphql, useRelayFragment } from "@gigsmart/relay";
import type { useWorkerProfileStrength_worker$key } from "./__generated__/useWorkerProfileStrength_worker.graphql";

export type ProfileLapses =
  | "ONBOARDING"
  | "BIOGRAPHY"
  | "EXPERIENCE"
  | "EDUCATION";
const maxPoints = 4;

export default function useWorkerProfileStrength(
  fragmentRef: useWorkerProfileStrength_worker$key | null
) {
  const result = useRelayFragment(
    graphql`
      fragment useWorkerProfileStrength_worker on Worker {
        biography
        workHistory(first: 0) {
          totalCount
        }
        education(first: 0) {
          totalCount
        }
        languageCount: languages(first: 0) {
          totalCount
        }
        militaryCount: militaryServices(first: 0) {
          totalCount
        }
        licenseCount: gigFields(
          first: 1
          query: "WHERE title='Licenses & Certifications'"
        ) {
          edges {
            node {
              selectedDefinitions: itemDefinitions(
                first: 0
                query: "WHERE status = CONFIRMED"
              ) {
                totalCount
              }
            }
          }
        }
        commercialLicenses: gigFields(
          first: 1
          query: "WHERE title LIKE 'Commercial Driver'"
        ) {
          edges {
            node {
              selectedCDL: itemDefinitions(
                first: 10
                query: "WHERE status = CONFIRMED"
              ) {
                totalCount
              }
            }
          }
        }
      }
    `,
    fragmentRef
  );

  const lapses: ProfileLapses[] = [];

  if (!result?.biography) lapses.push("BIOGRAPHY");
  if (!result?.workHistory?.totalCount) lapses.push("EXPERIENCE");
  if (!result?.education?.totalCount) lapses.push("EDUCATION");

  const progress = ((maxPoints - lapses.length) / maxPoints) * 100;

  const showWorkHistory = !!result?.workHistory?.totalCount;
  const showEducation = !!result?.education?.totalCount;
  const showLanguages = !!result?.languageCount?.totalCount;
  const showMilitary = !!result?.militaryCount?.totalCount;
  const showLicenses = !!getConnectionNodes(result?.licenseCount)[0]
    ?.selectedDefinitions?.totalCount;
  const showCDL = !!getConnectionNodes(result?.commercialLicenses)[0]
    ?.selectedCDL?.totalCount;

  const showWorkInformationCard =
    showWorkHistory ||
    showEducation ||
    showLanguages ||
    showMilitary ||
    showLicenses ||
    showCDL;

  return {
    lapses,
    progress,
    showWorkInformationCard,
    showWorkHistory,
    showEducation,
    showLanguages,
    showMilitary,
    showLicenses,
    showCDL
  };
}
