import type { Operatable } from "./operation";
import { Query } from "./query";

// Build a query that checks each field until one is present
export const coalesce = (fields: string[], op: Operatable) =>
  fields.reduce<Query>(
    (q, field, i) =>
      i > 0
        ? q.or(
            fields
              .slice(0, i)
              .reduce((obj, nullField) => ({ ...obj, [nullField]: null }), {
                [field]: op
              })
          )
        : q.or({ [field]: op }),
    new Query()
  );

// Build a query that checks any field against the same operator or value
export const any = (fields: string[], op: Operatable) =>
  fields.reduce<Query>((q, field) => q.or({ [field]: op }), new Query());
