import React from "react";

import { useStyles } from "../../style";
import SmallButton, { type SmallButtonProps } from "./small-button";

type Props = SmallButtonProps & {
  secondary?: boolean;
};

const SmallSolidButton = ({ secondary, ...props }: Props) => {
  const { styles, theme } = useStyles(
    ({ color }) => {
      const tintColor = secondary ? color.orange : color.blue;
      return {
        container: { backgroundColor: tintColor },
        hoverContainer: { backgroundColor: color.darken(tintColor, 0.1) },
        disabledContainer: { backgroundColor: color.neutralDark }
      };
    },
    [secondary]
  );
  return (
    <SmallButton
      containerStyle={styles.container}
      hoverContainerStyle={styles.hoverContainer}
      textColor={theme.color.white}
      disabledTextColor={theme.color.neutralLight}
      disabledContainerStyle={styles.disabledContainer}
      {...props}
    />
  );
};

export default SmallSolidButton;
