/**
 * @generated SignedSource<<5065bf9e0dabee1936acefd4d37f8fb2>>
 * @relayHash a1bb531e4fd7a8f0e1465d9a226baf3c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:pcBylOxzhnGtOEyXGBJ620j30YZdBGYMzOcHrgZqwAI

import { ConcreteRequest } from 'relay-runtime';
export type AcceptOrganizationInviteInput = {
  organizationInviteId: string;
};
export type PasswordStepInviteMutation$variables = {
  input: AcceptOrganizationInviteInput;
};
export type PasswordStepInviteMutation$data = {
  readonly acceptOrganizationInvite: {
    readonly token: string;
  } | null | undefined;
};
export type PasswordStepInviteMutation = {
  response: PasswordStepInviteMutation$data;
  variables: PasswordStepInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AcceptOrganizationInvitePayload",
    "kind": "LinkedField",
    "name": "acceptOrganizationInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordStepInviteMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordStepInviteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:pcBylOxzhnGtOEyXGBJ620j30YZdBGYMzOcHrgZqwAI",
    "metadata": {},
    "name": "PasswordStepInviteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6c17b99715e1c7eddb574ec8f25e17df";

export default node;
