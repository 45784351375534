import moment, { type Moment } from "moment-timezone";
import { useCallback, useMemo, useState } from "react";

export interface Day {
  value: string;
  month: string;
  label: string;
  isToday: boolean;
}

export const MONTH_FORMAT = "YYYY-MM";

export const DATE_FORMAT = "YYYY-MM-DD";
export const LUXON_DATE_FORMAT = "yyyy-MM-dd";

export const useCurrentMonth = (
  selectedDay: string | null | undefined
): [Moment, (n: number) => void] => {
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  const month = useMemo(() => {
    if (selectedMonth) return moment(selectedMonth, MONTH_FORMAT);
    if (selectedDay) return moment(selectedDay, DATE_FORMAT);
    return moment();
  }, [selectedDay, selectedMonth]);

  const onSetMonth = useCallback(
    (add: number) => {
      const newSelectedMonth = moment(month)
        .add(add, "month")
        .format(MONTH_FORMAT);
      setSelectedMonth(newSelectedMonth);
    },
    [month]
  );

  return [month, onSetMonth];
};
