/**
 * @generated SignedSource<<6fce861785e22be72275cca8a91f3b68>>
 * @relayHash d9c3685d8d8f96b1a7c70471dc534a9a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:a44uAMkGCk0ObKcheIMCaEz0tChI660MxyUuYwwmfH0

import { ConcreteRequest } from 'relay-runtime';
export type MessageType = "ALERT" | "CALL" | "CORPORATE" | "DIRECT_HIRE" | "GIG" | "GIG_POSTED" | "JOB_BOOSTED" | "JOB_POSTING" | "MISCELLANEOUS" | "PAYMENT" | "SILENT_MESSAGE" | "USER_MESSAGE" | "%future added value";
export type NotificationIconSubscription$variables = {
  messageTypes?: ReadonlyArray<MessageType> | null | undefined;
};
export type NotificationIconSubscription$data = {
  readonly messageAdded: {
    readonly unreadCount: number;
  } | null | undefined;
};
export type NotificationIconSubscription = {
  response: NotificationIconSubscription$data;
  variables: NotificationIconSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "messageTypes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "messageTypes",
        "variableName": "messageTypes"
      }
    ],
    "concreteType": "MessageAddedPayload",
    "kind": "LinkedField",
    "name": "messageAdded",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unreadCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationIconSubscription",
    "selections": (v1/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationIconSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:a44uAMkGCk0ObKcheIMCaEz0tChI660MxyUuYwwmfH0",
    "metadata": {},
    "name": "NotificationIconSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "7a7802d168600ba5d67a50a4b1c14e72";

export default node;
