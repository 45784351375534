import React, { type ReactNode, useState } from "react";

import { useStyles } from "../style";
import LinearGradient from "./linear-gradient";
import StyledView from "./styled-view";

const FadedContainer = ({ children }: { children: ReactNode }) => {
  const { styles } = useStyles(() => ({
    container: { overflow: "hidden" },
    linearGradient: {
      zIndex: 10,
      position: "absolute",
      height: 24,
      bottom: 0,
      left: 0,
      right: 0
    }
  }));

  const [shouldFade, setShouldFade] = useState(true);
  return (
    <StyledView
      style={styles.container}
      color="transparent"
      height={80}
      paddingBottom={0}
      verticalPadding={0}
      horizontalPadding={0}
      onLayout={({ nativeEvent }) => {
        if (!nativeEvent) return;
        const height = nativeEvent.layout.height;
        if (height < 70) setShouldFade(false);
        else setShouldFade(true);
      }}
    >
      {children}
      <LinearGradient
        locations={[1, 0]}
        opacities={[0, 1]}
        style={styles.linearGradient}
        colors={["white", "#ffffff00"]}
        start={{ x: 0, y: shouldFade ? 1 : 0 }}
        end={{ x: 0, y: 0 }}
      />
    </StyledView>
  );
};

export default FadedContainer;
