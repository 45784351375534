/**
 * @generated SignedSource<<114bc06624dba26b1e9ab9ede40b07f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountBalance_worker$data = {
  readonly payableAccount: {
    readonly paymentMethod: {
      readonly __typename: string;
    } | null | undefined;
  } | null | undefined;
  readonly walletBalance: string;
  readonly " $fragmentType": "accountBalance_worker";
};
export type accountBalance_worker$key = {
  readonly " $data"?: accountBalance_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountBalance_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "accountBalance_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "walletBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerPayableAccount",
      "kind": "LinkedField",
      "name": "payableAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "paymentMethod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "e2513aec3958a3d9845706fe979e02ae";

export default node;
