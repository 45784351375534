import React from "react";

import {
  ContentArea,
  IconText,
  Pressable,
  Row,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  createSuspendedQueryContainer,
  graphql
} from "@gigsmart/relay";
import HomeScreenCard from "./HomescreenCard";

import type { WalletBalanceCardQuery } from "./__generated__/WalletBalanceCardQuery.graphql";
import type { WalletBalanceCard_worker$key } from "./__generated__/WalletBalanceCard_worker.graphql";

export const WalletBalanceCardWithQuery =
  createSuspendedQueryContainer<WalletBalanceCardQuery>(
    function WalletBalanceCardWithQuery({ response }) {
      const history = useHistory();
      const walletBalance = response?.viewer?.walletBalance ?? "";

      const currencyToDisplay = currency.humanize(walletBalance);
      const amount = walletBalance
        ? Number.parseFloat(walletBalance.split(" ")[0] ?? "")
        : 0;
      const color = amount > 0 ? "success" : amount < 0 ? "danger" : undefined;

      return (
        <HomeScreenCard headerIcon="money-bill" headerTitle="Wallet Balance">
          <Surface variant="outline">
            <ContentArea>
              <Pressable
                testID="wallet-row"
                eventTargetName="Wallet Row Balance Card"
                eventEntityType="homescreenWalletRow"
                onPress={() => history.push("/wallet")}
              >
                <Row justifyContent="space-between">
                  <Text variant="title" color={color}>
                    {currencyToDisplay}
                  </Text>
                  <IconText
                    icon="chevron-right"
                    size="small"
                    iconPlacement="right"
                    color="primary"
                    spacing="compact"
                  >
                    <Text variant="subheader" weight="bold">
                      Go to Wallet
                    </Text>
                  </IconText>
                </Row>
              </Pressable>
            </ContentArea>
          </Surface>
        </HomeScreenCard>
      );
    },
    {
      query: graphql`
        query WalletBalanceCardQuery {
          viewer {
            ... on Worker {
              id
              walletBalance
            }
          }
        }
      `,
      variables: {}
    }
  );

export const WalletBalanceCardFragment = graphql`
  fragment WalletBalanceCard_worker on Worker {
    id
    walletBalance
  }
`;

type Props = FragmentContainerInnerComponentProps<WalletBalanceCard_worker$key>;

export const WalletBalanceCardWithFragment = ({ walletBalance }: Props) => {
  const history = useHistory();

  const currencyToDisplay = currency.humanize(walletBalance);
  const amount = walletBalance
    ? Number.parseFloat(walletBalance.split(" ")[0] ?? "")
    : 0;
  const color = amount > 0 ? "success" : amount < 0 ? "danger" : undefined;

  return (
    <HomeScreenCard headerIcon="money-bill" headerTitle="Wallet Balance">
      <Surface variant="outline">
        <ContentArea>
          <Pressable
            testID="wallet-row"
            eventTargetName="Wallet Row Balance Card"
            eventEntityType="homescreenWalletRow"
            onPress={() => history.push("/wallet")}
          >
            <Row justifyContent="space-between">
              <Text variant="title" color={color}>
                {currencyToDisplay}
              </Text>
              <IconText
                icon="chevron-right"
                size="small"
                iconPlacement="right"
                color="primary"
                spacing="compact"
              >
                <Text variant="subheader" weight="bold">
                  Go to Wallet
                </Text>
              </IconText>
            </Row>
          </Pressable>
        </ContentArea>
      </Surface>
    </HomeScreenCard>
  );
};

export default createRelayFragmentContainer<
  WalletBalanceCard_worker$key,
  Props
>(WalletBalanceCardFragment, WalletBalanceCardWithFragment);
