/**
 * @generated SignedSource<<3167e107141ab8a3170af1565156f40e>>
 * @relayHash c3f374a32471c57e271e64b969689450
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:LQ0rMABMDj_5td1ZBf7OUU5ml6I7N8OvVAf_K4HG3VA

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDocumentConsentCheckQuery$variables = Record<PropertyKey, never>;
export type UserDocumentConsentCheckQuery$data = {
  readonly viewer: {
    readonly pendingConsents: ReadonlyArray<{
      readonly filename: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"UserDocumentConsentCheck_user">;
  } | null | undefined;
};
export type UserDocumentConsentCheckQuery = {
  response: UserDocumentConsentCheckQuery$data;
  variables: UserDocumentConsentCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDocumentConsentCheckQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalDocument",
            "kind": "LinkedField",
            "name": "pendingConsents",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserDocumentConsentCheck_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserDocumentConsentCheckQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalDocument",
            "kind": "LinkedField",
            "name": "pendingConsents",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "format",
                "storageKey": null
              },
              {
                "alias": "source",
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:LQ0rMABMDj_5td1ZBf7OUU5ml6I7N8OvVAf_K4HG3VA",
    "metadata": {},
    "name": "UserDocumentConsentCheckQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "57584f47782e2cc1400273a971cc908b";

export default node;
