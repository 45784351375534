import {
  CheckboxRow,
  ContentArea,
  Icon,
  type IconName,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { Validator, useFormField } from "@gigsmart/fomu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React, { useEffect, useMemo } from "react";
import FomuTextInput from "../fomu/inputs/FomuTextInput";
import type { MissingQualificationsQuestion_Gig$key } from "./__generated__/MissingQualificationsQuestion_Gig.graphql";

interface Props {
  categoryId: string;
  option: any;
}

export function MissingQualificationsQuestion({
  categoryId,
  qualifications,
  option
}: FragmentContainerInnerComponentProps<
  MissingQualificationsQuestion_Gig$key,
  Props
>) {
  const { value, setValue } = useFormField<string[]>({
    name: "missingQualifications",
    validates: [Validator.arrayLength({ min: 1, required: true })]
  });
  const { setValue: setOption } = useFormField({
    name: `${categoryId}.option`
  });
  const { setValue: setMissingQualificationsSelected } = useFormField({
    name: "missingQualificationsSelected"
  });
  useEffect(() => {
    setMissingQualificationsSelected(true);
    setOption(option);
    return () => setMissingQualificationsSelected(false);
  }, []);
  const requiredQualifications = useMemo(() => {
    const nodes = getConnectionNodes(qualifications);
    return nodes.filter((node) => node?.selectedItems?.length !== 0);
  }, [qualifications]);
  return (
    <ContentArea>
      <Stack>
        <Text weight="bold" color="primary">
          Which Qualifications did they not meet?
        </Text>
        <Text>Select all that apply.</Text>
        <Text
          weight="semibold"
          color={(value?.length ?? 0) > 0 ? "primary" : "disabled"}
        >
          {pluralize(value?.length ?? 0, "Qualification")} not met
        </Text>
        {requiredQualifications?.map((node, index) => (
          <Stack size="compact" key={index}>
            <Stack horizontal alignItems="center" size="compact">
              <Icon
                name={node.iconName as IconName}
                variant="solid"
                size="small"
              />
              <Text weight="bold">{node.title}</Text>
            </Stack>
            {node.selectedItems?.map((item, innerIndex) => (
              <CheckboxRow
                key={innerIndex}
                onChange={(v) => {
                  if (!value) {
                    setValue([item.definition.id]);
                  } else if (!value?.includes(item.definition.id)) {
                    setValue([item.definition.id, ...value]);
                  } else {
                    setValue(value?.filter((i) => i !== item.definition.id));
                  }
                }}
                title={item.definition.label}
                selected={!!value?.includes(item.definition.id)}
                testID={`${item.definition.label}-checkbox`}
              />
            ))}
          </Stack>
        ))}
        {(value?.length ?? 0) > 0 && (
          <FomuTextInput
            name={`${categoryId}.comment`}
            label={option.commentPrompt ?? ""}
            multiline
            numberOfLines={5}
            min={option.minimumCommentLength ?? 25}
            max={option.maximumCommentLength ?? 300}
            placeholder="Enter additional information"
            validates={Validator.presence()}
          />
        )}
      </Stack>
    </ContentArea>
  );
}

export default createRelayFragmentContainer<
  MissingQualificationsQuestion_Gig$key,
  Props
>(
  graphql`
    fragment MissingQualificationsQuestion_Gig on Gig {
      id
      qualifications: fields(first: 8) {
        totalCount
        edges {
          node {
            id
            title
            iconName
            selectedItems {
              id
              definition {
                id
                label
              }
            }
          }
        }
      }
    }
  `,
  MissingQualificationsQuestion
);
