/**
 * @generated SignedSource<<734b932f9c54800ac84a4323b3a39d81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MissingQualificationsQuestion_Gig$data = {
  readonly id: string;
  readonly qualifications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly iconName: string;
        readonly id: string;
        readonly selectedItems: ReadonlyArray<{
          readonly definition: {
            readonly id: string;
            readonly label: string;
          };
          readonly id: string;
        }> | null | undefined;
        readonly title: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "MissingQualificationsQuestion_Gig";
};
export type MissingQualificationsQuestion_Gig$key = {
  readonly " $data"?: MissingQualificationsQuestion_Gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"MissingQualificationsQuestion_Gig">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MissingQualificationsQuestion_Gig",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "qualifications",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 8
        }
      ],
      "concreteType": "GigFieldsConnection",
      "kind": "LinkedField",
      "name": "fields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GigFieldsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigField",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iconName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigFieldItem",
                  "kind": "LinkedField",
                  "name": "selectedItems",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GigFieldItemDefinition",
                      "kind": "LinkedField",
                      "name": "definition",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "fields(first:8)"
    }
  ],
  "type": "Gig",
  "abstractKey": null
};
})();

(node as any).hash = "934e9bb8e09f448746dd87b0ae1f96ba";

export default node;
