import { Avatar, Stack, Sticker, Text } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import React from "react";
import CallIcon from "../shared/CallIcon";
import type { Participants_conversation$data } from "./__generated__/Participants_conversation.graphql";

type Participant = ObjectUtils.Path<
  NonNullable<Participants_conversation$data["participants"]>,
  ["edges", 0, "node"]
>;

interface Props {
  participant: Participant;
  engagements?: Participants_conversation$data["engagements"];
  variant?: "worker" | "requester";
}

export default function ParticipantRow({
  participant,
  engagements,
  variant = "requester"
}: Props) {
  const currentUser = useCurrentUser();
  const isWorker = participant?.user?.__typename === "Worker";
  const isYou =
    currentUser?.__typename === "OrganizationRequester"
      ? participant?.user?.id === currentUser?.requester?.id
      : participant?.user?.id === currentUser?.id;
  const isFavorite = variant === "requester" && participant?.user?.isFavorite;
  const isInWorkerGroup =
    variant === "requester" &&
    (participant?.user?.groupAssociations?.totalCount ?? 0) > 0;
  const isPrimaryContact = engagements?.edges?.some(
    (e) => e?.node?.gig?.primaryContact?.id === participant?.user?.id
  );
  const isCallable =
    !!participant?.capabilities?.includes("CALL") &&
    !isYou &&
    (isWorker || currentUser?.__typename !== "OrganizationRequester");
  if (participant?.user?.__typename === "%other") return null;

  const profileUrl = participant?.user?.profilePhoto?.url;
  const displayName = participant?.user?.displayName;
  return (
    <Stack horizontal justifyContent="space-between" alignItems="center">
      <Stack horizontal alignItems="center">
        <Avatar
          uri={profileUrl}
          initials={
            !profileUrl
              ? displayName
                  ?.split(" ")
                  .map((n) => n[0])
                  .join("")
              : undefined
          }
          sticker={
            isFavorite ? (
              <Sticker icon="star" color="purple" />
            ) : isInWorkerGroup ? (
              <Sticker icon="users-class" color="purple" />
            ) : undefined
          }
          {...(participant?.user?.__typename === "%gigsmart" && {
            icon: "gigsmart",
            color: "orange",
            fade: true,
            iconSize: "extraLarge"
          })}
        />
        <Stack size="slim">
          <Text>
            <Text
              color="primary"
              weight="bold"
              testID={`participant-name-${participant?.user?.id}`}
            >
              {displayName}
            </Text>
            {isYou && <Text color="primary"> (You)</Text>}
          </Text>
          {isPrimaryContact && (
            <Text variant="note" weight="semibold">
              Primary Contact
            </Text>
          )}
          {isWorker && !isYou && (
            <Text variant="note" weight="semibold">
              Worker
            </Text>
          )}
        </Stack>
      </Stack>
      {isCallable && participant?.id && (
        <CallIcon variant="iconCircle" recipientId={participant.id} />
      )}
    </Stack>
  );
}
