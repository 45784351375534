import React, { type ReactNode } from "react";
import { View } from "react-native";

import { createPropsPortal } from "@gigsmart/aperture";
import { useStyles } from "@gigsmart/atorasu/style";
import { useHistory, useRouteMatch } from "@gigsmart/kaizoku";
import { HeaderView, StyledHeaderButton } from "@gigsmart/katana";

interface Prop {
  showBackButton?: boolean;
  renderLeftAccessory?: () => ReactNode;
  renderRightButton?: () => ReactNode;
  onBackPress?: (() => void) | undefined | null;
}

interface WebHeaderPortalProps {
  onPressBack: (() => void) | undefined | null;
}

/** @deprecated */
export const WebHeaderPortal = createPropsPortal<WebHeaderPortalProps>({
  onPressBack: undefined
});

/** @deprecated use ScreenTop from `atlas`. */
const WebHeader = ({
  showBackButton,
  renderLeftAccessory,
  renderRightButton,
  onBackPress
}: Prop) => {
  const route = useRouteMatch();
  const history = useHistory();
  const handlePress = onBackPress ?? history.goBack;
  const styles = useStyles(() => ({
    rightAccessory: {
      justifyContent: "center"
    }
  }));
  if (route.path.startsWith("/receipts")) return null;
  return (
    <HeaderView>
      <View>
        {showBackButton && (
          <WebHeaderPortal.Exit>
            {({ onPressBack }) => (
              <StyledHeaderButton
                iconName="arrow-left"
                label="Back"
                testID="back-button"
                onPress={onPressBack ?? handlePress}
              />
            )}
          </WebHeaderPortal.Exit>
        )}
        {renderLeftAccessory?.()}
      </View>
      <View style={styles.rightAccessory}>{renderRightButton?.()}</View>
    </HeaderView>
  );
};

export default WebHeader;
