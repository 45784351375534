/**
 * @generated SignedSource<<a52d8d77792e7393085f9bec18dc763a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkerLocationRationale = "APP_STATE_CHANGE" | "END_STATE_EXIT" | "FORCED_TRACKING" | "INITIALIZATION" | "INTERVAL" | "LEGACY_TRACKING" | "NOTIFICATION" | "ONSITE_TRACKING" | "PLATFORM_INITIATED" | "PREONSITE_TRACKING" | "TRACKING" | "USER_INITIATED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GeolocationProvider_workerLocation$data = {
  readonly accuracy: number | null | undefined;
  readonly area?: string | null | undefined;
  readonly id: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly postalCode?: string | null | undefined;
  readonly rationale: WorkerLocationRationale;
  readonly reportedAt: string;
  readonly " $fragmentType": "GeolocationProvider_workerLocation";
};
export type GeolocationProvider_workerLocation$key = {
  readonly " $data"?: GeolocationProvider_workerLocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeolocationProvider_workerLocation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeArea"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeolocationProvider_workerLocation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accuracy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rationale",
      "storageKey": null
    },
    {
      "condition": "includeArea",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "WorkerLocation",
  "abstractKey": null
};

(node as any).hash = "ae815186ed3013a443f59403ae898527";

export default node;
