import { Button } from "@gigsmart/atorasu";
import { type ValueObject, useFormSubmit, useFormValues } from "@gigsmart/fomu";
import React, { type ComponentProps } from "react";

type Props = Omit<ComponentProps<typeof Button>, "onPress" | "label"> & {
  allowDirty?: boolean;
  showLoading?: boolean;
  label: string | ((values?: ValueObject) => string);
};

export default function FomuSubmit({
  disabled,
  loading,
  allowDirty = false,
  showLoading = true,
  label,
  ...buttonProps
}: Props) {
  const { invalid, submit, submitting, dirty } = useFormSubmit();
  const { values } = useFormValues();

  let isDisabled = disabled || invalid || submitting;
  if (!allowDirty) isDisabled ||= !dirty;
  return (
    <Button
      {...buttonProps}
      onPress={submit}
      disabled={isDisabled}
      loading={showLoading && submitting}
      label={typeof label === "function" ? label(values) : label}
    />
  );
}
