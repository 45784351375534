import RequiredActionGuide from "@gigsmart/isomorphic-shared/app/required-action-guide";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";

interface Props {
  text: string;
}

export default function DriverEligibilityNotice({ text }: Props) {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  return (
    <RequiredActionGuide
      icon="ban"
      title="Not Eligible for MVR Gigs"
      text={text}
      button={{
        testID: "go-back-button",
        title: "Find Another Gig",
        onPress: () => navigation.replace("BrowseShifts", { type: "shifts" })
      }}
    />
  );
}
