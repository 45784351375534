/**
 * @generated SignedSource<<10a2120f211a1263d4006521b771a938>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useApplyMetadata_worker$data = {
  readonly gig?: {
    readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadata_gigOrEngagement">;
  } | null | undefined;
  readonly gigSeries?: {
    readonly node: {
      readonly area: string | null | undefined;
      readonly id: string;
      readonly nextGig?: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadata_gigOrEngagement">;
        } | null | undefined> | null | undefined;
        readonly totalCount: number;
      } | null | undefined;
      readonly nextOffered?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadata_gigOrEngagement">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly totalCount: number;
      } | null | undefined;
      readonly placeId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly payableAccount: {
    readonly paymentMethod: {
      readonly accountHolderName: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "useApplyMetadata_worker";
};
export type useApplyMetadata_worker$key = {
  readonly " $data"?: useApplyMetadata_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadata_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gigId"
  }
],
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "useApplyMetadata_gigOrEngagement"
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gigId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isApply"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isSeries"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "gigSeries",
          "node",
          "nextGig"
        ]
      }
    ]
  },
  "name": "useApplyMetadata_worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerPayableAccount",
      "kind": "LinkedField",
      "name": "payableAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "paymentMethod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountHolderName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isSeries",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "gig",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isSeries",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "AvailableGigSeriesEdge",
          "kind": "LinkedField",
          "name": "gigSeries",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSeries",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "placeId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "area",
                  "storageKey": null
                },
                {
                  "condition": "isApply",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "alias": "nextOffered",
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1
                        },
                        {
                          "kind": "Literal",
                          "name": "query",
                          "value": "WHERE currentStateName = OFFERED"
                        }
                      ],
                      "concreteType": "EngagementsConnection",
                      "kind": "LinkedField",
                      "name": "engagements",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EngagementsEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Engagement",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/)
                      ],
                      "storageKey": "engagements(first:1,query:\"WHERE currentStateName = OFFERED\")"
                    }
                  ]
                },
                {
                  "condition": "isApply",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "nextGig",
                      "args": [
                        {
                          "fields": [
                            {
                              "kind": "Variable",
                              "name": "maxDistance",
                              "variableName": "maxDistance"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "input"
                        }
                      ],
                      "concreteType": "AvailableGigsConnection",
                      "kind": "LinkedField",
                      "name": "__useApplyMetadataQuery_nextGig_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AvailableGigsEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "cursor",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Gig",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "__typename",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PageInfo",
                          "kind": "LinkedField",
                          "name": "pageInfo",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "endCursor",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hasNextPage",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "8ffb7452ab22e7a5b6c1d342c4a241c3";

export default node;
