import { Column, Icon, IconText, Row, Spacer, Text } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { CardBody, StyledButton } from "@gigsmart/katana";
import React, { useState, useCallback } from "react";
import { Pressable } from "react-native";

interface Props {
  id?: string;
  title?: string | null;
  content?: string | null;
  dismissable?: boolean | null;
  actionPrompt?: string | undefined | null;
  onDismiss: (arg0: string) => void;
  expanded: boolean;
  onExpand: (arg0: string | null | undefined) => void;
  renderAction?:
    | React.ComponentType<{
        onComplete: () => void;
      }>
    | null
    | undefined;
}

export default function AnnouncementCard({
  id,
  title,
  content,
  actionPrompt,
  dismissable,
  onDismiss,
  expanded,
  onExpand,
  renderAction: Action
}: Props) {
  const [actionActive, setActionActive] = useState(false);
  const enableAction = useCallback(() => setActionActive(true), []);
  const disableAction = useCallback(() => setActionActive(false), []);
  const styles = useStyles(({ getUnits }) => ({
    paragraph: {
      paddingVertical: 5
    },
    button: {
      flex: 1
    },
    spacer: {
      width: getUnits(4)
    },
    chevronContainer: {
      height: "100%",
      marginLeft: 10
    },
    body: {
      marginBottom: 1,
      marginTop: 0,
      paddingBottom: getUnits(1),
      paddingHorizontal: getUnits(1)
    }
  }));
  if (actionActive && Action) return <Action onComplete={disableAction} />;
  return (
    <CardBody style={styles.body}>
      <Pressable onPress={() => onExpand(expanded ? null : id)}>
        <Row justifyContent="space-between" alignItems="center">
          <Column fill={1}>
            <IconText
              icon="megaphone"
              color="primary"
              textWeight="bold"
              size="small"
              spacing="compact"
            >
              {title}
            </IconText>
            <Text
              numberOfLines={expanded ? undefined : 1}
              style={styles.paragraph}
            >
              {content}
            </Text>
          </Column>
          <Column style={styles.chevronContainer}>
            <Icon
              variant="solid"
              name={expanded ? "chevron-up" : "chevron-down"}
              size="medium"
            />
          </Column>
        </Row>
      </Pressable>
      {expanded && (
        <Row justifyContent="center">
          {dismissable && (
            <>
              <StyledButton
                containerViewStyle={styles.button}
                title="Dismiss"
                variant="compact"
                onPress={() => onDismiss(id ?? "")}
                secondary
              />
              <Spacer horizontal />
            </>
          )}
          {actionPrompt && (
            <StyledButton
              containerViewStyle={styles.button}
              onPress={enableAction}
              title={actionPrompt}
              variant="compact"
              color="blue"
            />
          )}
        </Row>
      )}
    </CardBody>
  );
}
