/**
 * @generated SignedSource<<85e10f05e05a1ef4550fc786c74481ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollapsibleGigSchedule_gig$data = {
  readonly actualStartsAt: string | null | undefined;
  readonly endsAt: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "CollapsibleGigSchedule_gig";
};
export type CollapsibleGigSchedule_gig$key = {
  readonly " $data"?: CollapsibleGigSchedule_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollapsibleGigSchedule_gig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollapsibleGigSchedule_gig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "b5c6e9911b034535e5d3eadf33409ebf";

export default node;
