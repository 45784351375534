import { ContentArea, Stack, Surface, Text } from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import React from "react";
import FomuTextInput from "../../fomu/inputs/FomuTextInput";

interface Props {
  name: string;
  label: string;
  placeholder?: string;
}

export default function DisputeCommentInput({
  name,
  label,
  placeholder
}: Props) {
  return (
    <Surface>
      <ContentArea>
        <Stack size="compact">
          <Text weight="semibold">{label}</Text>
          <FomuTextInput
            placeholder={placeholder}
            name={name}
            validates={[
              Validator.noPhoneNumbers(),
              Validator.noEmailAddressesOrGmail(),
              Validator.presence()
            ]}
            multiline
            max={1000}
            min={25}
          />
        </Stack>
      </ContentArea>
    </Surface>
  );
}
