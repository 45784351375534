/**
 * @generated SignedSource<<2454cf12bb44f9960ae88638eb580ede>>
 * @relayHash 88c85594494dd6356599e0c9d9b0f466
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:TJPdxgNT5QgcKQ42gU7GEGM0FeouXmOtlEAObLXTnMY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type walletScreenQuery$variables = {
  upcomingShiftsQuery?: string | null | undefined;
};
export type walletScreenQuery$data = {
  readonly viewer: {
    readonly payableAccount?: {
      readonly " $fragmentSpreads": FragmentRefs<"paymentMethodRow_payableAccount" | "walletTabs_workerPayableAccount">;
    } | null | undefined;
    readonly upcomingShiftCount?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"accountBalance_worker" | "pendingTransfers_worker" | "walletTabs_worker">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"unpaidEngagements_rootQuery">;
};
export type walletScreenQuery = {
  response: walletScreenQuery$data;
  variables: walletScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "upcomingShiftsQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": "upcomingShiftCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "upcomingShiftsQuery"
    }
  ],
  "concreteType": "EngagementsConnection",
  "kind": "LinkedField",
  "name": "engagements",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brand",
      "storageKey": null
    }
  ],
  "type": "BankCard",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "walletScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "accountBalance_worker"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayableAccount",
                "kind": "LinkedField",
                "name": "payableAccount",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "paymentMethodRow_payableAccount"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "walletTabs_workerPayableAccount"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "walletTabs_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "pendingTransfers_worker"
              },
              (v2/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "unpaidEngagements_rootQuery"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "walletScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "walletBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayableAccount",
                "kind": "LinkedField",
                "name": "payableAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "paymentMethod",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              },
              {
                "alias": "lifetime",
                "args": null,
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": null
              },
              {
                "alias": "sevenDay",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P7D"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P7D\")"
              },
              {
                "alias": "thirtyDay",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P30D"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P30D\")"
              },
              {
                "alias": "dy0",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P0D"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"^PY\",dateTo:\"-P0D\")"
              },
              {
                "alias": "dy1",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P1Y^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P1Y$PY"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P1Y^PY\",dateTo:\"-P1Y$PY\")"
              },
              {
                "alias": "dy2",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P2Y^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P2Y$PY"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P2Y^PY\",dateTo:\"-P2Y$PY\")"
              },
              {
                "alias": "dy3",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P3Y^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P3Y$PY"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P3Y^PY\",dateTo:\"-P3Y$PY\")"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 30
                  },
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE status = PENDING ORDER BY insertedAt ASC"
                  }
                ],
                "concreteType": "WorkerPayoutsConnection",
                "kind": "LinkedField",
                "name": "payouts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerPayoutsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerPayout",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkerPayoutProviderDetails",
                            "kind": "LinkedField",
                            "name": "providerDetails",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "arrivesAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "method",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "destination",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v5/*: any*/),
                                  (v4/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payouts(first:30,query:\"WHERE status = PENDING ORDER BY insertedAt ASC\")"
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  (v9/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID ORDER BY endsAt DESC"
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "gigType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "paymentInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "netPay",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              (v9/*: any*/)
                            ],
                            "concreteType": "EngagementPaymentsConnection",
                            "kind": "LinkedField",
                            "name": "payments",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementPaymentsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementPayment",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "payments(first:20)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": "engagements(first:20,query:\"WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID ORDER BY endsAt DESC\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:TJPdxgNT5QgcKQ42gU7GEGM0FeouXmOtlEAObLXTnMY",
    "metadata": {},
    "name": "walletScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e90a4d2f02db110a17f18dac20c7f2d9";

export default node;
