import {
  AllowPermissionInvocationProvider,
  AppMenu,
  AppSidenav,
  Column,
  DrawerItem,
  NavBar,
  NavIcon,
  Spacer,
  Stack,
  Text,
  ToastProvider,
  useLatestCallback,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { EventContext } from "@gigsmart/dekigoto";
import Loader from "@gigsmart/isomorphic-shared/app/loader";
import Logo from "@gigsmart/isomorphic-shared/brand/logo";
import {
  type AppLayoutProps,
  type AppMenuItem,
  findFocusedRoute
} from "@gigsmart/kaizoku";
import { RelayQueryRetryBoundary } from "@gigsmart/relay";
import { Screen } from "@react-navigation/elements";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useContext, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { StyleSheet, View } from "react-native";
import { Drawer } from "react-native-drawer-layout";
import { useSafeAreaFrame } from "react-native-safe-area-context";

const UseIsInScreenContext = createContext(false);
export const useIsInScreen = () => useContext(UseIsInScreenContext);

export function AppLayout({
  navigation,
  state,
  descriptors,
  menuItems,
  stickyHeader,
  navColor,
  navTitle
}: AppLayoutProps) {
  const styles = useStyles(({ colors, getColor }) => ({
    container: { flex: 1, backgroundColor: colors.background.fill },
    screen: { flex: 1 },
    overlay: { backgroundColor: getColor("black", "fill", { opacity: 0.5 }) }
  }));

  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const [open, setOpen] = useState(false);
  const curRoute = state.routes[state.index];
  const {
    NavRightAccessory,
    onLogoPress,
    keyboardDismissMode = "on-drag"
  } = descriptors[curRoute?.key as any]?.options ?? {};

  const handleDrawerToggle = useLatestCallback(() => setOpen((open) => !open));
  const handleDrawerOpen = useLatestCallback(() => setOpen(true));
  const handleDrawerClose = useLatestCallback(() => setOpen(false));

  const handleMenuPress = useLatestCallback((item: AppMenuItem) => {
    if (typeof item === "string") return;

    handleDrawerClose();
    if ("to" in item) {
      if (item.parent) navigation.navigate(item.parent, { screen: item.to });
      else navigation.navigate(item.to);
    } else item.onPress?.();
  });

  const renderNavBar = () => {
    const leftAccessory = isMd ? null : (
      <NavIcon
        name="bars"
        testID="menu-bars-icon"
        onPress={handleDrawerToggle}
      />
    );

    const rightAccessory = NavRightAccessory ? <NavRightAccessory /> : null;
    return (
      <NavBar
        color={navColor}
        leftAccessory={leftAccessory}
        rightAccessory={rightAccessory}
        title={
          <Stack horizontal alignItems="center">
            <Logo onPress={onLogoPress} />
            {navTitle && <Text>{navTitle}</Text>}
          </Stack>
        }
        rootNavigation={navigation}
      >
        <Loader />
      </NavBar>
    );
  };

  const renderDrawerContent = () => (
    <AppMenu>
      <Spacer size="compact" />
      {menuItems?.map((item, idx) => (
        <DrawerItem
          key={item === "separator" ? `sep-${idx}` : item.title}
          item={item}
          onPress={handleMenuPress}
        />
      ))}
    </AppMenu>
  );

  const dimensions = useSafeAreaFrame();
  const activeScreenName = findFocusedRoute(state)?.name;

  return (
    <View style={styles.container}>
      {renderNavBar()}
      <Drawer
        open={!isMd && open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        swipeEnabled={false}
        keyboardDismissMode={keyboardDismissMode}
        layout={dimensions}
        drawerType="front"
        drawerPosition="left"
        overlayStyle={styles.overlay}
        renderDrawerContent={renderDrawerContent}
      >
        <AppSidenav
          items={menuItems}
          active={activeScreenName}
          onPressItem={handleMenuPress}
        >
          {stickyHeader}
          <Column fill>
            {state.routes.map((route, idx) => {
              const isFocused = state.index === idx;
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              const { navigation, render, options } = descriptors[route.key]!;
              const { eventContext } = options ?? {};

              /// do not render unfocused screens on E2E (use `on the` spec)
              /// if (IS_TESTING && !isFocused) return null;

              return (
                <AllowPermissionInvocationProvider
                  key={route.key}
                  value={isFocused}
                >
                  <EventContext
                    name={eventContext ?? route.name}
                    noScreen={isFocused}
                  >
                    <Screen
                      navigation={navigation}
                      route={route}
                      focused={isFocused}
                      style={[
                        StyleSheet.absoluteFill,
                        // eslint-disable-next-line react-native/no-inline-styles
                        { display: isFocused ? "flex" : "none" }
                      ]}
                      header={null}
                      headerShown={false}
                    >
                      <UseIsInScreenContext.Provider value={true}>
                        <RelayQueryRetryBoundary watcher={isFocused}>
                          {render()}
                        </RelayQueryRetryBoundary>
                      </UseIsInScreenContext.Provider>
                    </Screen>
                  </EventContext>
                </AllowPermissionInvocationProvider>
              );
            })}
          </Column>
        </AppSidenav>
        <ToastProvider instrument />
      </Drawer>
    </View>
  );
}
