/**
 * @generated SignedSource<<7b058f7f070cea77ec1e41207a1d8511>>
 * @relayHash 0df8ba38dd034d89770b7b955844e856
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:SWuIKr6MTtP9Q0Ul3Mwts5VCXyK4KEALbfF0LyajrDQ

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type publicViewScreenQuery$variables = Record<PropertyKey, never>;
export type publicViewScreenQuery$data = {
  readonly viewer: {
    readonly displayName?: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CDLCard_workerprofile" | "EducationCard_worker" | "LanguagesCard_Worker" | "LicensesCard_workerprofile" | "MilitaryCard_Worker" | "ProfileInfoCard_worker" | "WorkHistoryCard_worker" | "useWorkerProfileStrength_worker">;
  } | null | undefined;
};
export type publicViewScreenQuery = {
  response: publicViewScreenQuery$data;
  variables: publicViewScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v8 = [
  (v7/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE title='Licenses & Certifications'"
  }
],
v9 = {
  "kind": "Literal",
  "name": "query",
  "value": "WHERE status = CONFIRMED"
},
v10 = {
  "alias": "selectedDefinitions",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 15
    },
    (v9/*: any*/)
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GigFieldItemDefinitionsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigFieldItemDefinition",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "itemDefinitions(first:15,query:\"WHERE status = CONFIRMED\")"
},
v11 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v12 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "publicViewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileInfoCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useWorkerProfileStrength_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkHistoryCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EducationCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LanguagesCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MilitaryCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LicensesCard_workerprofile"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CDLCard_workerprofile"
              },
              (v0/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "publicViewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPronoun",
                "kind": "LinkedField",
                "name": "pronoun",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeArea1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "public",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "favoritedByOrganizationsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "biography",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overallRating",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerGigStats",
                "kind": "LinkedField",
                "name": "gigStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gigsCompleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uniqueOrganizationsWorked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalEarned",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "EngagementReviewsConnection",
                "kind": "LinkedField",
                "name": "reviewsReceived",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "reviewsReceived(first:0)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "WorkerWorkHistoryConnection",
                "kind": "LinkedField",
                "name": "workHistory",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "workHistory(first:0)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "WorkerEducationConnection",
                "kind": "LinkedField",
                "name": "education",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "education(first:0)"
              },
              {
                "alias": "languageCount",
                "args": (v4/*: any*/),
                "concreteType": "WorkerLanguagesConnection",
                "kind": "LinkedField",
                "name": "languages",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "languages(first:0)"
              },
              {
                "alias": "militaryCount",
                "args": (v4/*: any*/),
                "concreteType": "WorkerMilitaryServicesConnection",
                "kind": "LinkedField",
                "name": "militaryServices",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "militaryServices(first:0)"
              },
              {
                "alias": "licenseCount",
                "args": (v8/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "selectedDefinitions",
                            "args": [
                              (v3/*: any*/),
                              (v9/*: any*/)
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": "itemDefinitions(first:0,query:\"WHERE status = CONFIRMED\")"
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:1,query:\"WHERE title='Licenses & Certifications'\")"
              },
              {
                "alias": "commercialLicenses",
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE title LIKE 'Commercial Driver'"
                  }
                ],
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "selectedCDL",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 10
                              },
                              (v9/*: any*/)
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": "itemDefinitions(first:10,query:\"WHERE status = CONFIRMED\")"
                          },
                          (v2/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:1,query:\"WHERE title LIKE 'Commercial Driver'\")"
              },
              {
                "alias": "experience",
                "args": [
                  (v11/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC"
                  }
                ],
                "concreteType": "WorkerWorkHistoryConnection",
                "kind": "LinkedField",
                "name": "workHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerWorkHistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerWorkHistory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employer",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employmentStart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employmentEnd",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "positionTitle",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "workHistory(first:50,query:\"ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC\")"
              },
              {
                "alias": "educationHistory",
                "args": [
                  (v11/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY endDate DESC NULLS FIRST, startDate DESC NULLS FIRST"
                  }
                ],
                "concreteType": "WorkerEducationConnection",
                "kind": "LinkedField",
                "name": "education",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerEducationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerEducation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "schoolName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "educationLevel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "education(first:50,query:\"ORDER BY endDate DESC NULLS FIRST, startDate DESC NULLS FIRST\")"
              },
              {
                "alias": null,
                "args": [
                  (v12/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY language ASC"
                  }
                ],
                "concreteType": "WorkerLanguagesConnection",
                "kind": "LinkedField",
                "name": "languages",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerLanguagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerLanguage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "language",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "level",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "languages(first:30,query:\"ORDER BY language ASC\")"
              },
              {
                "alias": null,
                "args": [
                  (v12/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY serviceEnd DESC NULLS FIRST, serviceStart DESC NULLS FIRST"
                  }
                ],
                "concreteType": "WorkerMilitaryServicesConnection",
                "kind": "LinkedField",
                "name": "militaryServices",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerMilitaryServicesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerMilitaryService",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "branch",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rank",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "serviceStart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "serviceEnd",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "militaryIdLast4",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "militaryServices(first:30,query:\"ORDER BY serviceEnd DESC NULLS FIRST, serviceStart DESC NULLS FIRST\")"
              },
              {
                "alias": "licenses",
                "args": (v8/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:1,query:\"WHERE title='Licenses & Certifications'\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:SWuIKr6MTtP9Q0Ul3Mwts5VCXyK4KEALbfF0LyajrDQ",
    "metadata": {},
    "name": "publicViewScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ac21511c8dca758d67b9abd0d8149caf";

export default node;
