import { BulletListItem } from "@gigsmart/katana";
import React, { type ComponentProps } from "react";

type Props = ComponentProps<typeof BulletListItem>;

const GigCardItem = (props: Props) => (
  <BulletListItem inset={5} iconColor="blue" {...props} />
);

export default GigCardItem;
