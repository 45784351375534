import {
  Column,
  ContentArea,
  type IconName,
  IconText,
  Row,
  StarRating,
  Text
} from "@gigsmart/atorasu";
import { RelationshipStrengthDebug } from "@gigsmart/feature-flags";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import WorkerThumbnail from "./WorkerThumbnail";
import type { WorkerInfoRow_worker$key } from "./__generated__/WorkerInfoRow_worker.graphql";

interface Props {
  relationshipStrength?: number;
  distance?: number | null;
  skill?: string;
  disabled?: boolean;
  showRating?: boolean;
  subtext?: string;
  showWorkerGroups?: boolean;
  action?: string | JSX.Element;
  primaryMobile?: string;
  paymentInfo?: {
    icon: IconName;
    label?: string;
    value: string | null | undefined;
  };
}

export const WorkerInfoRow = ({
  subtext,
  distance,
  skill,
  disabled,
  overallRating,
  reviewCount,
  result,
  paymentInfo,
  primaryMobile,
  relationshipStrength,
  showRating = true,
  showWorkerGroups = true,
  action
}: FragmentContainerInnerComponentProps<WorkerInfoRow_worker$key, Props>) => {
  if (typeof action === "string") {
    action = (
      <IconText
        textWeight="semibold"
        icon="chevron-right"
        iconPlacement="right"
        size="tiny"
        color="neutral"
        testID="worker-info-action-label"
      >
        {action}
      </IconText>
    );
  }

  return (
    <ContentArea size="medium">
      <Row>
        <WorkerThumbnail
          fragmentRef={result}
          disabled={disabled}
          showWorkerGroups={showWorkerGroups}
          fill
        >
          {!!primaryMobile && (
            <Text color="neutral" variant="note" testID="worker-info-phone">
              {primaryMobile}
            </Text>
          )}
          {!!skill && (
            <Text color="neutral" variant="note" testID="worker-info-skill">
              {skill}
            </Text>
          )}
          {!!distance && (
            <IconText
              icon="location-dot"
              size="tiny"
              color="neutral"
              iconAlign="center"
            >
              {`${distance} mi`}
            </IconText>
          )}
          {!!paymentInfo && (
            <IconText
              icon={paymentInfo?.icon}
              size="tiny"
              color="neutral"
              iconAlign="center"
            >
              {paymentInfo.label}
              {!!paymentInfo.label && ": "}
              {currency.humanizeRate(paymentInfo.value)}
            </IconText>
          )}
          {!!subtext && <Text color="neutral">{subtext}</Text>}
        </WorkerThumbnail>
        <Column>
          {showRating && (
            <Column fill={1} alignItems="flex-end">
              <StarRating
                rating={overallRating}
                numberOfRatings={reviewCount?.totalCount ?? 0}
                disabled={disabled}
              />
              {RelationshipStrengthDebug.isEnabled() && (
                <Text variant="note" italic color="danger">
                  Rel. Strength: {relationshipStrength}
                </Text>
              )}
            </Column>
          )}
          {!!action && <Column alignItems="flex-end">{action}</Column>}
        </Column>
      </Row>
    </ContentArea>
  );
};

export default createRelayFragmentContainer<WorkerInfoRow_worker$key, Props>(
  graphql`
    fragment WorkerInfoRow_worker on Worker {
      ...WorkerThumbnail_worker
      id
      overallRating
      reviewCount: reviewsReceived(first: 0) {
        totalCount
      }
    }
  `,
  WorkerInfoRow
);
