import {
  ContentArea,
  type IconName,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer } from "@gigsmart/relay";
import usePositionsByCategory, {
  positionsByCategoryVariables
} from "@gigsmart/seibutsu/positions/hooks/usePositionsByCategory";
import React from "react";
import { graphql } from "relay-runtime";
import type { WorkerParamList } from "../navigation/types";
import type { positionsScreenQuery } from "./__generated__/positionsScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "ProfilePositions">;

export default createSuspendedQueryContainer<positionsScreenQuery, Props>(
  function PositionsScreen({ route, response }) {
    const { workerCategoryId } = route.params;
    const {
      title,
      subtitle,
      searchHeader,
      searchPlaceholder,
      icon,
      options,
      selectOption
    } = usePositionsByCategory(response?.viewer, workerCategoryId);

    return (
      <ScreenScroll testID="positions-screen" color="surface">
        <ScreenTop />
        <ContentArea>
          <MultiSelectionRowGroup
            testID="worker-profile-category-positions-screen"
            header={title ?? ""}
            note={subtitle}
            searchHeader={searchHeader}
            searchPlaceholder={searchPlaceholder}
            selectionRowHeader={title ?? ""}
            selectionRowHeaderIcon={icon as IconName}
            options={options}
            onCheckOption={selectOption}
          />
        </ContentArea>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query positionsScreenQuery(
        $categoryQuery: String!
        $positionsQuery: String!
      ) {
        viewer {
          ...usePositionsByCategory_worker
            @arguments(
              categoryQuery: $categoryQuery
              positionsQuery: $positionsQuery
            )
        }
      }
    `,
    variables: ({ route }) =>
      positionsByCategoryVariables({
        categoryId: route.params.categoryId
      })
  }
);
