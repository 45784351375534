import React, { useState } from "react";

import { useEventer } from "@gigsmart/dekigoto";
import { Animated, Pressable, View } from "react-native";

import { FlexRow } from "../flex";
import { StyledIcon } from "../icon";
import { useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  value: boolean;
  onValueChange: (v: boolean) => unknown;
  testID?: string;
  customLabels?: { true: string; false: string };
}

export default function CustomSwitch({
  value,
  onValueChange,
  testID,
  customLabels
}: Props) {
  const { styles } = useStyles(({ color, units, font }) => ({
    container: {
      width: units(11),
      height: units(6),
      borderRadius: units(7.5),
      backgroundColor: color.neutralMedium
    },
    activeContainer: { backgroundColor: color.blue },
    circleContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    circle: {
      width: units(5),
      height: units(5),
      borderRadius: units(5),
      backgroundColor: color.white,
      justifyContent: "center",
      alignItems: "center"
    },
    label: {
      paddingLeft: units(2),
      fontSize: font.size.small,
      fontWeight: font.weight.bold
    }
  }));

  const trackSwitch = useEventer(
    `Toggled ${value ? "Off" : "On"}`,
    testID ?? "Unknown",
    "Switch"
  );

  const [switchPosition] = useState(new Animated.Value(value ? 10 : -10));

  const handleValueChange = () => {
    const newValue = !value;
    Animated.spring(switchPosition, {
      toValue: newValue ? 10 : -10,
      useNativeDriver: false
    }).start();
    onValueChange(newValue);
    trackSwitch();
  };

  const valueLabel = value
    ? customLabels?.true ?? "YES"
    : customLabels?.false ?? "NO";

  return (
    <FlexRow alignItems="center">
      <Pressable
        onPress={handleValueChange}
        testID={testID ?? "styled-switch"}
        style={[styles.container, value && styles.activeContainer]}
      >
        <Animated.View
          style={[styles.circleContainer, { left: switchPosition }]}
        >
          <View style={styles.circle}>
            {value && <StyledIcon name="check" variant="solid" size={12} />}
          </View>
        </Animated.View>
      </Pressable>
      <KatanaText style={styles.label}>{valueLabel}</KatanaText>
    </FlexRow>
  );
}
