import { Button, Share, Stack, Text } from "@gigsmart/atorasu";
import React from "react";
import HomeScreenCard from "../worker/HomescreenCard";

interface Props {
  workerdId?: string;
}
export default function ShareProfileCard({ workerdId }: Props) {
  return (
    <HomeScreenCard
      headerTitle="Public Profile"
      headerIcon="globe"
      testID="profile-share-card"
    >
      <Stack>
        <Text>
          Share your public profile with others to expand your network and find
          more Shift opportunities.
        </Text>
        <Button
          icon="share-nodes"
          label="Share Public Profile"
          testID="share-profile"
          outline
          onPress={async () => {
            if (!workerdId) return;
            await Share.share({
              message: `https://getworkers.gigsmart.com/workers/${workerdId}`
            });
          }}
        />
      </Stack>
    </HomeScreenCard>
  );
}
