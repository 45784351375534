import React, { type ReactNode } from "react";
import Stack from "../container/stack";
import { IconCircle, type IconName, IconText } from "../icon";
import { type ViewStyleProp, useStyles } from "../style";

interface Props {
  style?: ViewStyleProp;
  variant?: "cardTitle" | "heading";
  label: string;
  tintColor?: string;
  iconLabel?: IconName;
  iconColor?: string;
  iconName?: IconName;
  imageUri?: string;
  rightAccessory?: ReactNode;
  numberOfLines?: number;
  labelTestID?: string;
}

const ThumbnailRow = ({
  style,
  variant = "cardTitle",
  tintColor,
  iconName,
  iconColor,
  iconLabel,
  imageUri,
  label,
  numberOfLines = 2,
  rightAccessory,
  labelTestID = label
}: Props) => {
  const { styles } = useStyles(
    ({ font }) => ({
      label: {
        flex: 1,
        ...font.body(variant === "heading" ? "semibold" : "bold")
      }
    }),
    [variant]
  );

  return (
    <Stack style={style} spacing={2} alignItems="center" direction="row">
      {!!(iconName ?? imageUri) && (
        <IconCircle
          tintColor={tintColor}
          color={iconColor}
          size={variant === "heading" ? "sm" : "xs"}
          name={iconName}
          imageUri={imageUri}
        />
      )}
      <IconText
        iconColor="blue"
        iconName={iconLabel}
        variant={variant === "heading" ? "h3" : "h4"}
        color={variant !== "heading" ? "black" : undefined}
        style={styles.label}
        numberOfLines={numberOfLines}
        testID={labelTestID}
      >
        {label}
      </IconText>
      {rightAccessory}
    </Stack>
  );
};

export default ThumbnailRow;
