/**
 * @generated SignedSource<<e2c961f96cf52c3789b9a677ef27cb08>>
 * @relayHash 6013c0593462acf16c3d59888a315695
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:7pjtpR4kQkWslglpH56yJzsWJBKqFURYKB-gYPUi3vI

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireAction = "ACCEPT" | "ACKNOWLEDGE" | "AUTHOR" | "CHARGE" | "CLOSE" | "DENY" | "ESCALATE" | "GOODWILL" | "PUBLISH" | "REJECT" | "TIMEOUT" | "%future added value";
export type TransitionDirectHireClaimInput = {
  action: DirectHireAction;
  directHireClaimId: string;
};
export type ReviewStepPublishClaimMutation$variables = {
  input: TransitionDirectHireClaimInput;
};
export type ReviewStepPublishClaimMutation$data = {
  readonly transitionDirectHireClaim: {
    readonly transition: {
      readonly id: string;
    };
  } | null | undefined;
};
export type ReviewStepPublishClaimMutation = {
  response: ReviewStepPublishClaimMutation$data;
  variables: ReviewStepPublishClaimMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransitionDirectHireClaimPayload",
    "kind": "LinkedField",
    "name": "transitionDirectHireClaim",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaimTransition",
        "kind": "LinkedField",
        "name": "transition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewStepPublishClaimMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewStepPublishClaimMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:7pjtpR4kQkWslglpH56yJzsWJBKqFURYKB-gYPUi3vI",
    "metadata": {},
    "name": "ReviewStepPublishClaimMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8355388c3e43cb13ebeedfba2174e4ae";

export default node;
