import React, { Component, type ReactNode } from "react";
import { View } from "react-native";

import { type IconName, StyledIcon } from "../icon";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import { StyledButton } from "../tappable";
import { StyledText } from "../text";

type Props = StylesProps & {
  onConfirm: () => any;
  title: string;
  iconName?: IconName;
  confirmationText?: string;
  children?: ReactNode;
};
@withStyles(({ color, font, metric }) => ({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
    paddingTop: 30 + metric.statusBarHeight(),
    paddingBottom: 30 + metric.bottomSpace(),
    paddingHorizontal: 35
  },
  topContainer: {
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    fontSize: font.size.largest,
    color: color.blue
  },
  icon: {
    fontSize: 35,
    color: color.blue,
    padding: 10
  },
  text: {
    lineHeight: font.size.bodyCopy * font.lineHeight.body,
    textAlign: "center",
    fontSize: font.size.bodyCopy
  },
  link: {
    color: color.spiceBlue
  }
}))
export default class ConfirmationModal extends Component<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  render() {
    const { styles, onConfirm, title, iconName, confirmationText, children } =
      this.props;

    return (
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <StyledText style={styles.title}>{title}</StyledText>
          <StyledIcon name={iconName ?? "bell"} style={styles.icon} />
          <StyledText style={styles.text}>
            {children ?? confirmationText}
          </StyledText>
        </View>
        <View>
          <StyledButton
            testID="confirmation-button"
            primary
            title="OK"
            onPress={onConfirm}
          />
        </View>
      </View>
    );
  }
}
