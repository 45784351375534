import { useEffect, useState } from "react";

const useCurrentTime = (interval = 1000, enabled = true) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    if (!enabled) return;

    const intervalID = setInterval(() => setCurrentTime(new Date()), interval);
    return () => {
      clearInterval(intervalID);
    };
  }, [interval, enabled]);

  return currentTime;
};

export default useCurrentTime;
