import { ContentArea, Divider, Spacer, Stack, Text } from "@gigsmart/atorasu";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { EngagementDisputePaymentRows_EngagementTimesheet$key } from "./__generated__/EngagementDisputePaymentRows_EngagementTimesheet.graphql";

interface Props {
  timesheetDisputed?: boolean;
  additionalPayDisputed?: boolean;
}

export function EngagementDisputePaymentRows({
  timesheetDisputed,
  additionalPayDisputed,
  disputedFinalTimesheet
}: FragmentContainerInnerComponentProps<
  EngagementDisputePaymentRows_EngagementTimesheet$key,
  Props
>) {
  const basePaymentMinusFees = (
    Number(disputedFinalTimesheet?.diff?.basePayment?.replace(" USD", "")) -
    Number(
      disputedFinalTimesheet?.diff?.totalWorkerServiceFeeAmount?.replace(
        " USD",
        ""
      ) ?? 0
    )
  ).toFixed(2);
  return (
    <ContentArea>
      <Stack size="compact">
        {timesheetDisputed && (
          <Stack horizontal size="slim" justifyContent="space-between">
            <Text>Time Added via Dispute Timesheet</Text>
            <Text color="success" weight="semibold">
              +{" "}
              {duration.humanize(
                disputedFinalTimesheet?.diff?.billableDuration,
                "semi-compact-no-spaces"
              )}
            </Text>
          </Stack>
        )}
        {timesheetDisputed && (
          <Stack horizontal size="slim" justifyContent="space-between">
            <Text>Additional Earnings via Time Added</Text>
            <Text color="success" weight="semibold">
              + ${basePaymentMinusFees}
            </Text>
          </Stack>
        )}
        {additionalPayDisputed && (
          <Stack horizontal size="slim" justifyContent="space-between">
            <Text>Additional Payment Request</Text>
            <Text color="success" weight="semibold">
              +{" "}
              {currency.humanize(
                disputedFinalTimesheet?.diff?.additionalPayment
              )}
            </Text>
          </Stack>
        )}
        <Divider />
      </Stack>
      <Spacer />
      <Stack horizontal size="slim" justifyContent="space-between">
        <Text weight="semibold">Dispute Total</Text>
        <Text weight="semibold" color="success">
          {currency.humanize(disputedFinalTimesheet?.diff?.totalPayment)}
        </Text>
      </Stack>
    </ContentArea>
  );
}

export default createRelayFragmentContainer<
  EngagementDisputePaymentRows_EngagementTimesheet$key,
  Props
>(
  graphql`
    fragment EngagementDisputePaymentRows_EngagementTimesheet on EngagementTimesheet {
      id
      disputedFinalTimesheet {
        diff {
          billableDuration
          basePayment
          totalPayment
          totalWorkerServiceFeeAmount
          additionalPayment
        }
      }
    }
  `,
  EngagementDisputePaymentRows
);
