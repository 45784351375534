import {
  Collapsible,
  ContentArea,
  HighlightedReminder,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import React from "react";
import type { gigSummaryRows_engagement$data } from "./__generated__/gigSummaryRows_engagement.graphql";

interface Props {
  currentBid?: ObjectUtils.Path<gigSummaryRows_engagement$data, ["currentBid"]>;
}

export default function EngagementCurrentBid({ currentBid }: Props) {
  const bidInfo = () => {
    switch (currentBid?.paySchedule) {
      case "HOURLY": {
        return `${currency.humanize(
          currentBid?.payRate
        )}/hr (Est. ${duration.humanize(
          currentBid?.expectedDuration,
          "hours"
        )}hrs)`;
      }
      case "FIXED": {
        return currency.humanize(currentBid?.estimatedAmount);
      }
      default: {
        return "Not Enough Info";
      }
    }
  };
  return (
    <Stack fill={1}>
      {currentBid?.note ? (
        <Collapsible
          testID="bid-info"
          variant="outline"
          startCollapsed
          header={
            <Text>
              Your Initial Bid: <Text weight="bold">{bidInfo()}</Text>
            </Text>
          }
        >
          <Text weight="bold">
            {currentBid?.paySchedule === "INFO_REQUIRED"
              ? "You asked:"
              : "Note Sent to Requester:"}
          </Text>
          <Text>{currentBid?.note}</Text>
        </Collapsible>
      ) : (
        <Surface variant="outline">
          <ContentArea>
            <Text>
              Your Initial Bid: <Text weight="bold">{bidInfo()}</Text>
            </Text>
          </ContentArea>
        </Surface>
      )}
      <HighlightedReminder
        icon="circle-exclamation"
        header="This is not a final bid. Be sure to agree upon the price with the Requester before you start work."
      />
    </Stack>
  );
}
