import React from "react";

import { toast } from "@gigsmart/atorasu";
import {
  AbsoluteContainer,
  ContentArea,
  FooterContent,
  IconButton,
  Row,
  ScreenScroll,
  ScreenTop,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import FomuPicker from "../fomu/inputs/FomuPicker";
import FomuTextInput from "../fomu/inputs/FomuTextInput";

import FomuConfirmExit from "../fomu/inputs/FomuConfirmExit";
import FomuSubmit from "../fomu/inputs/FomuSubmit";
import type { ManageLanguageFormAddLanguageMutation } from "./__generated__/ManageLanguageFormAddLanguageMutation.graphql";
import type { ManageLanguageFormEditLanguageMutation } from "./__generated__/ManageLanguageFormEditLanguageMutation.graphql";
import useDeleteProfileItem from "./useDeleteProfileItem";

export const LANGUAGE_LEVELS = [
  {
    label: "Beginner",
    value: "Beginner"
  },
  {
    label: "Intermediate",
    value: "Intermediate"
  },
  {
    label: "Proficient",
    value: "Proficient"
  },
  {
    label: "Fluent",
    value: "Fluent"
  },
  {
    label: "Native or Bilingual",
    value: "Native or Bilingual"
  }
];

interface Props {
  language?: string | null;
  languageLevel?: string | null;
  languageId?: string | null;
  workerId?: string | null;
}

export default function ManageLanguageForm({
  language,
  languageLevel,
  languageId,
  workerId
}: Props) {
  const [addLanguageMutation] =
    useRelayMutation<ManageLanguageFormAddLanguageMutation>(graphql`
      mutation ManageLanguageFormAddLanguageMutation(
        $input: AddWorkerLanguageInput!
      ) {
        addWorkerLanguage(input: $input) {
          newWorkerLanguageEdge {
            cursor
            node {
              id
              language
              level
            }
          }
        }
      }
    `);

  const [editLanguageMutation] =
    useRelayMutation<ManageLanguageFormEditLanguageMutation>(graphql`
      mutation ManageLanguageFormEditLanguageMutation(
        $input: UpdateWorkerLanguageInput!
      ) {
        updateWorkerLanguage(input: $input) {
          workerLanguage {
            id
            language
            level
          }
        }
      }
    `);

  const deleteItem = useDeleteProfileItem();

  const handleSubmit: FomuSubmitFn = ({ values }, done, reset) => {
    const onSuccess = () => {
      reset();
      toast.success("Language successfully saved!");
      defaultBackHandler({ safeExit: true });
    };

    if (languageId) {
      editLanguageMutation(
        {
          input: {
            language: values.language,
            level: values.languageLevel,
            workerLanguageId: languageId
          }
        },
        { onSuccess }
      );
    } else {
      addLanguageMutation(
        {
          input: {
            language: values.language,
            level: values.languageLevel,
            workerId
          }
        },
        { onSuccess }
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={{ language, languageLevel }}>
      <FomuConfirmExit />
      <ScreenScroll
        testID="manage-language-form-screen"
        color="surface"
        footer={
          <FooterContent bgColor="surface" bgSolid>
            <FomuSubmit label="Save" testID="update-language-button" />
          </FooterContent>
        }
      >
        <ScreenTop showBackButton />
        <ContentArea>
          <Stack>
            <Text variant="header" color="primary">
              Languages
            </Text>
            {!!languageId && (
              <AbsoluteContainer>
                <Row justifyContent="flex-end">
                  <IconButton
                    name="trash-alt"
                    testID="delete-work-experience"
                    variant="solid"
                    color="primary"
                    onPress={() => deleteItem(languageId, "Language")}
                  />
                </Row>
              </AbsoluteContainer>
            )}
            <FomuTextInput
              name="language"
              validates={Validator.presence()}
              label="Language"
              placeholder="e.g. English"
            />
            <FomuPicker
              name="languageLevel"
              label="Language Level"
              options={LANGUAGE_LEVELS}
              placeholder="e.g. Intermediate"
              eventTargetName="Input Language Level Picker"
              validates={Validator.presence()}
            />
          </Stack>
        </ContentArea>
      </ScreenScroll>
    </Form>
  );
}
