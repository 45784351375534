import { AppletPortal } from "@gigsmart/applets";
import {
  ActionSheetPortal,
  Cannon,
  GoogleMapProvider,
  ModalProvider,
  Persistence,
  SafeAreaProvider,
  Viewport,
  useHasPermission
} from "@gigsmart/atorasu";
import { createConversion } from "@gigsmart/dekigoto";
import {
  SentryDebugButtons,
  UndergoingMaintenanceFeature
} from "@gigsmart/feature-flags";
import {
  type LinkingOptions,
  NavProvider,
  type ParamListBase
} from "@gigsmart/kaizoku";
import ConfirmPromptProvider from "@gigsmart/katana/confirm-prompt/confirm-prompt-provider";
import ViewportProvider from "@gigsmart/katana/style/viewport";
import React, { type ReactNode, useEffect } from "react";
import ErrorBoundary from "../debug/error-boundary";
import { SentryDebug } from "../debug/sentry-debug";
import Instruments from "../e2e/instruments";
import { AppSettingsProvider, type AppSettingsType } from "./app-settings";
import { UndergoingMaintenance } from "./undergoing-maintenance";

interface Props<T extends ParamListBase> {
  settings: AppSettingsType;
  linking?: LinkingOptions<T>;
  children?: ReactNode;
}

const LocationConversion = createConversion("Install Location", {
  once: true
});

export default function AppWrapper<T extends ParamListBase>({
  settings,
  linking,
  children
}: Props<T>) {
  const hasLocationPermission = useHasPermission("location", {
    trace: "AppWrapper"
  });
  useEffect(() => {
    const run = async () => {
      const toggleKey = "installWithLocationTracked";
      if (await Persistence.isOn(toggleKey)) return;

      const { currentLocation } = await import("../geolocation");

      if (hasLocationPermission) {
        await new Promise((resolve) => setTimeout(resolve, 600));
        const pos = await currentLocation();
        if (pos?.coords) {
          const { latitude, longitude } = pos.coords;
          void LocationConversion.track({ latitude, longitude });
          await Persistence.toggle(toggleKey);
        }
      }
    };

    // track appstate??
    if (settings.appId === "requester" || settings.appId === "worker") {
      void run().catch(() => {});
    }
  }, [settings.appId, hasLocationPermission]);

  return (
    <SafeAreaProvider>
      <ErrorBoundary>
        <AppSettingsProvider value={settings}>
          <Viewport testID="app">
            <ViewportProvider>
              <ActionSheetPortal>
                {/* <AppRequestUpdate> */}
                {UndergoingMaintenanceFeature.isEnabled() ? (
                  <NavProvider appName={settings.appName} linking={linking}>
                    <Instruments />
                    <ModalProvider />
                    <UndergoingMaintenance />
                  </NavProvider>
                ) : (
                  <NavProvider appName={settings.appName} linking={linking}>
                    <GoogleMapProvider>
                      <AppletPortal>{children}</AppletPortal>
                      <Instruments />
                      <Cannon />
                      <ModalProvider />
                      <ConfirmPromptProvider />
                    </GoogleMapProvider>
                  </NavProvider>
                )}
                <SentryDebugButtons.IfEnabled>
                  <SentryDebug />
                </SentryDebugButtons.IfEnabled>
                {/* </AppRequestUpdate> */}
              </ActionSheetPortal>
            </ViewportProvider>
          </Viewport>
        </AppSettingsProvider>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
}
