/**
 * @generated SignedSource<<c97f7c9613da6d2533f220883f1adca8>>
 * @relayHash 9a1038724944b9dbff9fc99ecd15d728
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:wokxy15IEyFfMheUxZMaX8DSAOqWHLaXQ7UHilmpIB8

import { ConcreteRequest } from 'relay-runtime';
export type GigConversationRule = "ALL_PARTICIPANTS" | "DISABLED" | "REQUESTER_ONLY" | "%future added value";
export type NewMessageComposerGigSubscription$variables = {
  gigId: string;
};
export type NewMessageComposerGigSubscription$data = {
  readonly gigUpdated: {
    readonly gig: {
      readonly conversationRule: GigConversationRule;
    };
  };
};
export type NewMessageComposerGigSubscription = {
  response: NewMessageComposerGigSubscription$data;
  variables: NewMessageComposerGigSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gigId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "gigId",
    "variableName": "gigId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversationRule",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewMessageComposerGigSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGigPayload",
        "kind": "LinkedField",
        "name": "gigUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Gig",
            "kind": "LinkedField",
            "name": "gig",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewMessageComposerGigSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGigPayload",
        "kind": "LinkedField",
        "name": "gigUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Gig",
            "kind": "LinkedField",
            "name": "gig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:wokxy15IEyFfMheUxZMaX8DSAOqWHLaXQ7UHilmpIB8",
    "metadata": {},
    "name": "NewMessageComposerGigSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "4d9d649106f1f5692c3b4e84aa5cc256";

export default node;
