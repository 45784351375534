/**
 * @generated SignedSource<<fb32179358b39244ad6a4eb47604e20e>>
 * @relayHash 493c837d55e2398959cefae9fca71950
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:z-opuiKbRRjWac5yaatcjzk5QZYpKzFzOmOBjlq2qVY

import { ConcreteRequest } from 'relay-runtime';
export type RemoveEngagementTimesheetInput = {
  timesheetId: string;
};
export type timesheetEditHooksResetTimesheetMutation$variables = {
  input: RemoveEngagementTimesheetInput;
};
export type timesheetEditHooksResetTimesheetMutation$data = {
  readonly removeEngagementTimesheet: {
    readonly removedTimesheetId: string | null | undefined;
  } | null | undefined;
};
export type timesheetEditHooksResetTimesheetMutation = {
  response: timesheetEditHooksResetTimesheetMutation$data;
  variables: timesheetEditHooksResetTimesheetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removedTimesheetId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "timesheetEditHooksResetTimesheetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEngagementTimesheetPayload",
        "kind": "LinkedField",
        "name": "removeEngagementTimesheet",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timesheetEditHooksResetTimesheetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEngagementTimesheetPayload",
        "kind": "LinkedField",
        "name": "removeEngagementTimesheet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "removedTimesheetId"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:z-opuiKbRRjWac5yaatcjzk5QZYpKzFzOmOBjlq2qVY",
    "metadata": {},
    "name": "timesheetEditHooksResetTimesheetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a321edb5522bba04d983c8b61e9a410a";

export default node;
