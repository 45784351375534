/**
 * @generated SignedSource<<6e0035e440daf9b0060882373619569d>>
 * @relayHash c6725584708c69465c23ad3257fddf78
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:iZrH2lj2He9xw7THap-3B8ZmnAykLWQRaY3j2txjb0c

import { ConcreteRequest } from 'relay-runtime';
export type GeocodeLayer = "ADDRESS" | "LOCALITY" | "PLACE" | "POSTAL_CODE" | "VENUE" | "%future added value";
export type PlaceProvider = "ALL" | "GOOGLE_MAPS" | "MAPBOX" | "PELIAS" | "%future added value";
export type PlaceAutocompleteInput = {
  distance?: number | null | undefined;
  layer?: GeocodeLayer | null | undefined;
  near?: Coordinates | null | undefined;
  provider?: PlaceProvider | null | undefined;
  search: string;
  sessionId: string;
};
export type Coordinates = {
  latitude: number;
  longitude: number;
};
export type useLocationAutocompleteSilentQuery$variables = {
  input: PlaceAutocompleteInput;
};
export type useLocationAutocompleteSilentQuery$data = {
  readonly placeAutocomplete: ReadonlyArray<{
    readonly label: string;
    readonly place: {
      readonly location: {
        readonly latitude: number;
        readonly longitude: number;
      } | null | undefined;
    };
    readonly placeId: string;
  }>;
};
export type useLocationAutocompleteSilentQuery = {
  response: useLocationAutocompleteSilentQuery$data;
  variables: useLocationAutocompleteSilentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLocationAutocompleteSilentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationSearchResult",
        "kind": "LinkedField",
        "name": "placeAutocomplete",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "place",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLocationAutocompleteSilentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationSearchResult",
        "kind": "LinkedField",
        "name": "placeAutocomplete",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "place",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:iZrH2lj2He9xw7THap-3B8ZmnAykLWQRaY3j2txjb0c",
    "metadata": {},
    "name": "useLocationAutocompleteSilentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "31b7213f82f407a6dd8e5162a7ffc3ea";

export default node;
