import {
  type RelayRequestError,
  graphql,
  useFetchQueryResult,
  useRelayMutation,
  useRelaySubscription
} from "@gigsmart/relay";
import type { Primitive } from "@gigsmart/type-utils";
import { isEqual, isNil } from "lodash";
import type { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCurrentUser } from "../current-user";
import type {
  useUserValueMutation,
  useUserValueMutation$data
} from "./__generated__/useUserValueMutation.graphql";
import type { useUserValueQuery } from "./__generated__/useUserValueQuery.graphql";
import type { useUserValueSubscription } from "./__generated__/useUserValueSubscription.graphql";

export declare type JsonValue =
  | Exclude<Primitive, symbol>
  | Duration
  | DateTime
  | Record<string, any>
  | JsonValue[];

function load<T extends JsonValue>(value: string | null | undefined) {
  try {
    return typeof value === "string" ? (JSON.parse(value) as T) : value;
  } catch (e) {
    console.warn(e);
    return typeof value === "string"
      ? (JSON.parse(JSON.stringify(value)) as T)
      : null;
  }
}

function dump<T extends JsonValue>(obj: T) {
  return JSON.stringify(obj);
}

export default function useUserValue<T extends JsonValue>(
  key: string,
  { userId, defaultValue }: { userId?: string; defaultValue?: T | null } = {}
) {
  const currentUser = useCurrentUser();
  userId ??= currentUser?.id;
  defaultValue ??= null;
  const [result, { isLoading }] = useFetchQueryResult<useUserValueQuery>(
    graphql`
      query useUserValueQuery($key: String!, $userId: ID!) {
        node(id: $userId) {
          ... on User {
            id
            userValue(key: $key) {
              id
              key
              value
            }
          }
        }
      }
    `,
    {
      skip: !userId,
      networkCacheConfig: {
        batch: true,
        batchKey: "useUserValueBatch"
      },
      variables: { key, userId: userId ?? "" }
    }
  );

  const currentValueRef = useRef<T | null | undefined>(defaultValue);
  const [currentValue, setCurrentValue] = useState<T | null | undefined>(
    defaultValue
  );

  const handleValueChange = useCallback(
    (valueJson: string | null | undefined) => {
      const nextValue = load<T>(valueJson);
      currentValueRef.current = nextValue;
      setCurrentValue((currentValue) =>
        isEqual(currentValue, nextValue) ? currentValue : nextValue
      );
    },
    []
  );

  useEffect(() => {
    if (result?.node?.userValue) {
      handleValueChange(result.node.userValue.value);
    }
  }, [handleValueChange, result]);

  const [setUserValue] = useRelayMutation<useUserValueMutation>(
    graphql`
      mutation useUserValueMutation($input: SetUserValueInput!) {
        setUserValue(input: $input) {
          userValue {
            id
            key
            value
          }
        }
      }
    `,
    {
      onSuccess: useCallback(
        (data: useUserValueMutation$data) => {
          if (data.setUserValue?.userValue) {
            handleValueChange(data.setUserValue.userValue.value);
          }
        },
        [handleValueChange]
      ),
      onError: useCallback((error: RelayRequestError) => {
        console.error("Error setting user value", error);
      }, [])
    }
  );

  // Subscribe to changes
  useRelaySubscription<useUserValueSubscription>(
    graphql`
      subscription useUserValueSubscription($key: String!) {
        userValueSet(key: $key) {
          userValue {
            id
            key
            value
          }
        }
      }
    `,
    { key },
    {
      subscribe: !!(userId && userId === currentUser?.id),
      onNext: (data) => {
        if (data?.userValueSet?.userValue?.value) {
          handleValueChange(data.userValueSet.userValue.value);
        }
      }
    }
  );

  // Build the updater
  const update = useCallback(
    async (value: T | ((prev: T | undefined | null) => T)) => {
      const localValue =
        typeof value === "function" ? value(currentValueRef.current) : value;
      if (!userId || isEqual(currentValueRef.current, value)) return;
      setUserValue({
        input: {
          key,
          userId,
          value: isNil(localValue) ? null : dump(localValue)
        }
      });
    },
    [userId, key, setUserValue]
  );

  return [currentValue, update, isLoading] as const;
}
