import React from "react";

import {
  ContentArea,
  Icon,
  ListRow,
  ModalFooter,
  ModalHeader,
  Pressable,
  Stack,
  Surface,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";

interface Props {
  orgName: string;
  conversationId: string;
  shiftGroupChatId: string;
}

export function showMessageSelectionModal({
  orgName,
  conversationId,
  shiftGroupChatId
}: Props) {
  return showModal({
    eventContext: "message-selection-modal",
    children: (close) => (
      <MessageSelectionModal
        conversationId={conversationId}
        shiftGroupChatId={shiftGroupChatId}
        orgName={orgName}
        onClose={close}
      />
    )
  });
}

export default function MessageSelectionModal({
  orgName,
  conversationId,
  shiftGroupChatId,
  onClose
}: Props & { onClose: () => void }) {
  const history = useHistory();

  const renderRow = (label: string, testID: string, onPress: () => void) => (
    <Pressable
      onPress={() => {
        onPress();
        onClose();
      }}
      testID={testID}
      eventTargetName={`${testID}RowPress`}
      eventEntityType={`${testID}-row-press`}
    >
      <Surface variant="outline" topRadius="slim" bottomRadius="slim">
        <ListRow
          label={label}
          right={
            <Icon
              name="chevron-right"
              color="primary"
              size="small"
              variant="solid"
            />
          }
        />
      </Surface>
    </Pressable>
  );

  return (
    <>
      <ModalHeader />
      <Stack>
        <Text variant="header" weight="bold" color="primary" align="center">
          Who would you like to send a message to?
        </Text>
        <Surface variant="outline" topRadius="slim" bottomRadius="slim">
          <ContentArea>
            <Stack size="compact">
              {renderRow(orgName, "messageSelectionModalOrg", () =>
                history.push(`/conversation/${conversationId}`)
              )}
              {renderRow("Shift Group Chat", "messageSelectionModalShift", () =>
                history.push(`/conversation/${shiftGroupChatId}`)
              )}
            </Stack>
          </ContentArea>
        </Surface>
      </Stack>
      <ModalFooter />
    </>
  );
}
