import React, { PureComponent } from "react";
import { View } from "react-native";

import { StyledIcon } from "../../icon";
import { type StylesProps, stylesStubs, withStyles } from "../../style";

type Props = StylesProps & {
  isActive: boolean;
  color: string;
};

const SIZE = 34;
@withStyles(({ color, font }) => ({
  circle: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    backgroundColor: color.neutralLight,
    borderWidth: 1,
    borderColor: color.neutralLight,
    alignItems: "center",
    justifyContent: "center"
  },
  circleActive: {
    backgroundColor: color.white
  },
  icon: {
    fontSize: font.size.extraLarge,
    lineHeight: font.size.extraLarge * font.lineHeight.body
  }
}))
export default class TrackStar extends PureComponent<Props> {
  static defaultProps = {
    ...stylesStubs
  };

  render() {
    const { styles, isActive, color } = this.props;
    return (
      <View style={[styles.circle, isActive && styles.circleActive]}>
        {isActive && (
          <StyledIcon
            name="star"
            variant="solid"
            style={[styles.icon, { color }]}
          />
        )}
      </View>
    );
  }
}
