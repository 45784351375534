import { IconButton } from "@gigsmart/atorasu";
import React, { useEffect, useState } from "react";

interface Props {
  recordingUrl: string;
}

export default function RecordingPlayer({ recordingUrl }: Props) {
  const useAudio = (url: string) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
      playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
      audio.addEventListener("ended", () => setPlaying(false));
      return () => {
        audio.removeEventListener("ended", () => setPlaying(false));
      };
    }, []);
    return { playing, toggle };
  };

  const { playing, toggle } = useAudio(recordingUrl);
  return (
    <IconButton
      name={playing ? "pause-circle" : "play-circle"}
      testID={`play-recording-${recordingUrl}`}
      variant="solid"
      color="primary"
      onPress={toggle}
    />
  );
}
