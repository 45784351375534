import React, { type ReactNode } from "react";

import { type IconName, StyledIcon } from "../../icon";
import { type ViewStyleProp, useStyles } from "../../style";
import StyledText from "../../text/styled-text";
import Hoverable from "../hoverable";
import KatanaTouchableOpacity from "../katana-touchable-opacity";

export interface SmallButtonProps {
  readonly title: string;
  readonly icon?: IconName;
  readonly testID?: string;
  readonly disabled?: boolean | null | undefined;
  readonly onPress?: () => void;
  readonly onDisabledPress?: () => void;
  readonly fill?: number | boolean;
  fullWidth?: boolean;
  readonly iconPosition?: "left" | "right";
  readonly Content?: ReactNode;
  readonly contentPosition?: "left" | "right";
}

type Props = SmallButtonProps & {
  readonly containerStyle?: ViewStyleProp;
  readonly hoverContainerStyle?: ViewStyleProp;
  readonly textColor?: string;
  readonly disabledTextColor?: string;
  readonly disabledContainerStyle?: ViewStyleProp;
};

export default ({
  disabled,
  onPress,
  onDisabledPress,
  testID = "small-button",
  icon,
  title,
  fill,
  textColor = "blue",
  disabledTextColor = "neutralDark",
  containerStyle,
  hoverContainerStyle,
  disabledContainerStyle,
  iconPosition = "left",
  contentPosition = "left",
  Content,
  fullWidth
}: Props) => {
  const { styles, theme } = useStyles(({ units, font }) => ({
    icon: {
      [iconPosition === "left" ? "marginRight" : "marginLeft"]: units(2)
    },
    touchable: {
      flexDirection: "row",
      alignItems: "center",
      height: units(8),
      paddingHorizontal: units(2),
      paddingVertical: units(1),
      borderRadius: units(1),
      alignSelf: "center"
    },
    fill: {
      flex: fill === true ? 1 : fill || 0,
      alignSelf: undefined,
      maxWidth: units(100),
      justifyContent: "center"
    },
    fullWidth: {
      width: "100%",
      justifyContent: "center",
      alignSelf: undefined
    },
    text: {
      fontSize: font.size.medium,
      lineHeight: font.size.medium * 1.2
    }
  }));
  const iconElem = icon ? (
    <StyledIcon
      name={icon}
      variant="solid"
      color={disabled ? disabledTextColor : textColor}
      style={styles.icon}
      size={theme.font.size.medium}
    />
  ) : null;

  return (
    <Hoverable>
      {(hover) => (
        <KatanaTouchableOpacity
          eventTargetName={title}
          testID={testID ?? "small-button"}
          onPress={disabled ? onDisabledPress : onPress}
          style={[
            styles.touchable,
            containerStyle,
            hover && !disabled && hoverContainerStyle,
            disabled && disabledContainerStyle,
            fill !== undefined && styles.fill,
            fullWidth && styles.fullWidth
          ]}
        >
          {iconPosition === "left" && iconElem}
          {contentPosition === "left" && Content ? Content : null}
          <StyledText
            style={styles.text}
            color={disabled ? disabledTextColor : textColor}
            bold
          >
            {title}
          </StyledText>
          {contentPosition === "right" && Content ? Content : null}
          {iconPosition === "right" && iconElem}
        </KatanaTouchableOpacity>
      )}
    </Hoverable>
  );
};
