import React, { type ReactNode } from "react";
import { View } from "react-native";

import { type ViewStyleProp, ViewportRenderer, useStyles } from "../style";

interface Prop {
  style?: ViewStyleProp;
  children?: ReactNode;
}

export default function HeaderView({ style, children, ...props }: Prop) {
  const { styles } = useStyles(({ rem }) => ({
    container: {
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%",
      marginBottom: rem(1.875),
      height: rem(1.875),
      maxHeight: rem(1.875)
    }
  }));
  return (
    <ViewportRenderer
      renderableBreakpoints={[
        "@tablet",
        "@desktopWide",
        "@desktop",
        "@tabletDown"
      ]}
    >
      <View style={[styles.container, style]} {...props}>
        {children}
      </View>
    </ViewportRenderer>
  );
}
