// FIXME: Move this component to Atlas
// eslint-disable-next-line no-restricted-imports
import Button from "@gigsmart/atorasu/atoms/Button";
import React, { type ReactNode, type ComponentProps } from "react";
import { Linking } from "react-native";

import { type IconName, IconText } from "../icon";
import { KatanaTabs } from "../nav/katana-tabs";
import { Spacer } from "../spacer";
import { useStyles } from "../style";
import { SolidButton } from "../tappable";
import Card from "./card";
import StyledModal from "./styled-modal";

interface Props {
  title: string;
  showModal: boolean;
  handleClose: () => void;
  tabs?: ComponentProps<typeof KatanaTabs>["tabs"];
  children?: ReactNode;
  testID: string;
  titleIcon?: IconName;
  linkUrl?: string;
  linkText?: string;
  buttonTitle?: string;
  showBorder?: boolean;
  maxHeight?: number;
  defaultTab?: string | null | undefined;
}

const TabbedModal = ({
  title,
  showModal,
  handleClose,
  tabs,
  children = null,
  titleIcon,
  testID,
  linkText,
  linkUrl,
  buttonTitle = "Close",
  maxHeight,
  defaultTab,
  showBorder = true
}: Props) => {
  const { styles } = useStyles(({ font, color }) => ({
    title: {
      ...font.body("bold"),
      ...font.withSize(16),
      color: color.blue,
      textAlign: "center"
    },
    card: {
      marginHorizontal: 0,
      maxHeight
    }
  }));
  return (
    <StyledModal
      eventContext={testID ?? "tabbed-modal"}
      visible={showModal}
      hasCancelIcon
      onClose={handleClose}
    >
      <Spacer />
      <IconText
        iconColor="blue"
        iconName={titleIcon}
        textStyle={styles.title}
        alignItems="center"
        justifyContent="center"
      >
        {title}
      </IconText>
      <Spacer />
      <Card border={showBorder} shadow={false} noMargin style={styles.card}>
        {tabs && <KatanaTabs tabs={tabs} defaultTab={defaultTab} />}
        {children}
      </Card>
      {linkUrl && linkText && (
        <>
          <Spacer />
          <Button
            testID="tabbed-modal-link-btn"
            label={linkText}
            variant="clear"
            size="small"
            onPress={async () => await Linking.openURL(linkUrl)}
          />
        </>
      )}
      <Spacer />
      <SolidButton title={buttonTitle} onPress={handleClose} />
    </StyledModal>
  );
};

export default TabbedModal;
