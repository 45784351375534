import { Persistence } from "@gigsmart/atorasu";

const ipGeoLocationApiKey = process.env.IP_GEOLOCATION_API_KEY;

export const getStaticLocation: typeof window.navigator.geolocation.getCurrentPosition =
  (success, error, _options) => {
    success({
      coords: {
        latitude: 39.7392,
        longitude: -104.9903,
        accuracy: Number.POSITIVE_INFINITY,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null
      },
      timestamp: new Date().getTime()
    });
  };

export const getCurrentPositionFromIp: typeof window.navigator.geolocation.getCurrentPosition =
  (success, error, { maximumAge = 60_000, timeout = 5_000 } = {}) => {
    Persistence.fetch(
      "ipLocation",
      async () => {
        if (!ipGeoLocationApiKey) {
          throw new Error("IP Geolocation API Key is not provided");
        }
        const res = await Promise.race([
          fetch(
            `https://api.ipstack.com/check?access_key=${ipGeoLocationApiKey}`,
            {
              method: "GET"
            }
          ),
          new Promise<Response>((resolve, reject) =>
            setTimeout(() => reject(new Error("timeout")), timeout)
          )
        ]);
        if (!res.ok) throw new Error("Invalid Response");
        const json = await res.json();
        success?.({
          coords: {
            latitude: Number(json?.latitude),
            longitude: Number(json?.longitude),
            accuracy: 25_000,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null
          },
          timestamp: new Date().getTime()
        });
      },
      { maxAge: maximumAge }
    ).catch(error);
  };
