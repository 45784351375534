import {
  Collapsible,
  ContentArea,
  Date as DateComponent,
  Text,
  Time
} from "@gigsmart/atorasu";
import { createRelayFragmentContainer, graphql } from "@gigsmart/relay";
import type { FragmentContainerInnerComponentProps } from "@gigsmart/relay";
import React from "react";
import type { CollapsibleGigSchedule_gig$key } from "./__generated__/CollapsibleGigSchedule_gig.graphql";

export function CollapsibleGigSchedule({
  actualStartsAt,
  endsAt
}: FragmentContainerInnerComponentProps<CollapsibleGigSchedule_gig$key>) {
  return (
    <Collapsible
      testID="collapsible-gig-info"
      header={<Text weight="bold">Original Scheduled Shift Info</Text>}
      variant="surface"
    >
      <ContentArea>
        <Text weight="bold">
          Date:{" "}
          <DateComponent size="sm" startsAt={actualStartsAt} weight="normal" />
        </Text>
        <Text weight="bold">
          Start Time: <Time startsAt={actualStartsAt} weight="normal" />
        </Text>
        <Text weight="bold">
          End Time: <Time startsAt={endsAt} weight="normal" />
        </Text>
      </ContentArea>
    </Collapsible>
  );
}

export default createRelayFragmentContainer<CollapsibleGigSchedule_gig$key>(
  graphql`
    fragment CollapsibleGigSchedule_gig on Gig {
      id
      actualStartsAt
      endsAt
    }
  `,
  CollapsibleGigSchedule
);
