import React, { useCallback, useMemo } from "react";

import {
  Button,
  ContentArea,
  FooterSpacer,
  IconText,
  Linking,
  Platform,
  ScreenScroll,
  ScreenTop,
  Spacer,
  Stack,
  Surface,
  Text,
  showEmbeddedBrowser,
  toast
} from "@gigsmart/atorasu";
import { WebsiteSupport } from "@gigsmart/feature-flags";
import { type FomuSubmitFn, Form, Validator } from "@gigsmart/fomu";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type AppNavigationProp,
  type ParamListBase,
  defaultBackHandler,
  useNavigation
} from "@gigsmart/kaizoku";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";

import FomuSubmit from "../fomu/inputs/FomuSubmit";
import FomuTextInput from "../fomu/inputs/FomuTextInput";
import type { SupportScreenMutation } from "./__generated__/SupportScreenMutation.graphql";

export default function SupportScreen() {
  const navigation = useNavigation<AppNavigationProp<ParamListBase>>();
  const { appId } = useAppSettings();
  const user = useCurrentUser();
  const initialValues = useMemo((): Record<string, any> => ({}), []);
  const [submitSupportTicket] = useRelayMutationPromise<SupportScreenMutation>(
    graphql`
      mutation SupportScreenMutation($input: SubmitSupportTicketInput!) {
        submitSupportTicket(input: $input) {
          message
        }
      }
    `,
    {
      onSuccess: () => {
        toast.success("Your support request was submitted.");
        defaultBackHandler();
      },
      onError: () =>
        toast.error(
          "Could not submit a support request. If problems persist, please send an email to support@gigsmart.com"
        )
    }
  );

  const handleCancel = useCallback(() => defaultBackHandler(), []);
  const handleSend: FomuSubmitFn = useCallback(({ values }) => {
    void submitSupportTicket({
      input: {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        description: values.description,
        subject: values.subject
      }
    });
  }, []);

  const handleFaq = useCallback(() => {
    const url =
      appId === "requester"
        ? "https://help.gigsmart.com/get-workers"
        : "https://help.gigsmart.com/get-gigs";

    Platform.OS === "web"
      ? window.open(url, "_blank")
      : void Linking.openURL(url);
  }, [appId]);

  const handleSupport = () => {
    showEmbeddedBrowser({
      url: `https://gigsmart.com/contact-support${
        appId === "requester" ? "#business" : "#worker"
      }`,
      title: "GigSmart Support",
      onClose: () => navigation.goBack()
    });
    // 😂 Basically the support route, if the feature flag is on, shouldn't actually go to the screen really
    if (Platform.OS === "web") navigation.goBack();
  };

  return (
    <ScreenScroll testID="support-screen" constraint="small">
      <ScreenTop />
      {WebsiteSupport.isEnabled() ? (
        <>{handleSupport()}</>
      ) : (
        <Form initialValues={initialValues} onSubmit={handleSend}>
          <Stack>
            <Surface>
              <ContentArea>
                <Text color="primary" weight="bold" testID="support-title">
                  Have Questions or Need Help?
                </Text>
                <Spacer size="large" />
                <ContentArea constraint="xsmall" size="none">
                  <Button
                    outline
                    label="Frequently Asked Questions"
                    onPress={handleFaq}
                    testID="faq-button"
                  />
                </ContentArea>
              </ContentArea>
            </Surface>

            <Surface>
              <ContentArea>
                <IconText
                  spacing="compact"
                  size="small"
                  icon="circle-question"
                  color="primary"
                  textWeight="bold"
                >
                  Contact Support
                </IconText>
                <Spacer size="large" />

                <Stack size="medium">
                  {!user?.id && (
                    <Stack size="medium">
                      <FomuTextInput
                        autoComplete="email"
                        validates={[Validator.presence(), Validator.email()]}
                        name="emailAddress"
                        maxLength={50}
                        label="Email Address"
                        autoCorrect={false}
                        autoCapitalize="none"
                        type="email"
                        textContentType="emailAddress"
                        placeholder="jane.doe@example.com"
                      />
                      <FomuTextInput
                        name="firstName"
                        validates={Validator.presence()}
                        maxLength={50}
                        label="First Name"
                        autoCorrect={false}
                        placeholder="First Name"
                        type="displayName"
                        textContentType="name"
                      />
                      <FomuTextInput
                        name="lastName"
                        validates={Validator.presence()}
                        maxLength={50}
                        label="Last Name"
                        autoCorrect={false}
                        placeholder="Last Name"
                        type="displayName"
                        textContentType="familyName"
                      />
                    </Stack>
                  )}
                  <FomuTextInput
                    name="subject"
                    validates={Validator.presence()}
                    maxLength={50}
                    label="Subject"
                    placeholder="Subject"
                    autoCorrect
                    autoCapitalize="sentences"
                  />
                  <FomuTextInput
                    validates={Validator.presence()}
                    name="description"
                    maxLength={10000}
                    showCounter
                    label="Description"
                    placeholder="Description"
                    multiline
                    autoCorrect
                    autoCapitalize="sentences"
                  />
                </Stack>

                <Spacer size="large" />
                <ContentArea constraint="xsmall" size="none">
                  <FomuSubmit label="Submit" testID="mail-modal-send" />
                  <Spacer size="medium" />
                  <Button
                    outline
                    label="Cancel"
                    onPress={handleCancel}
                    testID="support-cancel-button"
                  />
                </ContentArea>
              </ContentArea>
            </Surface>
          </Stack>
        </Form>
      )}
      <FooterSpacer />
    </ScreenScroll>
  );
}
