import { createLogger } from "@gigsmart/roga";
import React, { Component } from "react";
import { Platform } from "react-native";
import type { ActionEntry } from "../container/prompt-modal";
import PromptModal from "../container/prompt-modal";

export interface ConfirmPromptEntry {
  title: string;
  subTitle: string;
  actions?: ActionEntry[];
  actionsDirection?: "row" | "column";
  cancelable?: boolean;
  autoClose?: boolean;
}

interface State {
  alertVisible: boolean;
  entry?: ConfirmPromptEntry;
}
const logger = createLogger("❔", "Confirm Prompt Provider");

class EventGroup {
  listeners: Function[] = [];
  queue: any[] = [];

  addListener = (fn: Function) => {
    this.listeners.push(fn);
  };

  removeListener = (fn: Function) => {
    while (this.listeners.includes(fn)) {
      this.listeners.splice(this.listeners.indexOf(fn), 1);
    }
  };

  dispatchEvent = (data?: any) => {
    if (this.listeners.length) {
      logger.info("dispatch event", data);
      this.listeners.forEach((fn) => fn(data));
    } else {
      logger.info("queue event", data);
      this.queue.push(data);
    }
  };
}

const registry = {
  show: new EventGroup(),
  dismiss: new EventGroup()
};

type Event = keyof typeof registry;

function addEventListener(event: Event, fn: Function) {
  logger.info("add provider", event, fn);
  registry[event].addListener(fn);
}
function removeEventListener(event: Event, fn: Function) {
  logger.info("remove provider", event, fn);
  registry[event].removeListener(fn);
}

export function dispatchEvent(event: Event, data?: any) {
  registry[event].dispatchEvent(data);
}

export default class ConfirmPromptProvider extends Component<{}, State> {
  state: State = {
    alertVisible: false
  };

  eventListeners = {
    show: async (entry: ConfirmPromptEntry) => {
      logger.info("provider show");
      this.setState({ entry, alertVisible: true });
    },
    dismiss: async () => {
      logger.info("provider dismiss");
      this.setState({ alertVisible: false });
    }
  };

  componentDidMount = () => {
    Object.keys(this.eventListeners).forEach((event: any) => {
      addEventListener(event, (this.eventListeners as any)[event]);
    });
  };

  componentWillUnmount() {
    Object.keys(this.eventListeners).forEach((event: any) => {
      removeEventListener(event, (this.eventListeners as any)[event]);
    });
  }

  render() {
    const { alertVisible, entry } = this.state;
    if (!entry || !alertVisible) return null;

    return (
      <PromptModal
        title={entry.title}
        subTitle={entry.subTitle}
        eventContext={null}
        visible={alertVisible}
        onClose={this.handleClose}
        actions={entry.actions?.map(this.wrapAction) ?? []}
        actionsDirection={entry.actionsDirection}
      />
    );
  }

  private readonly close = () => {
    if (Platform.OS === "web") {
      dispatchEvent("dismiss");
      return;
    }

    this.setState({ alertVisible: false });
  };

  private readonly wrapAction = ({
    onPress,
    style,
    ...action
  }: ActionEntry) => ({
    ...action,
    style,
    onPress: () => {
      onPress?.();
      if (style === "cancel") this.close();
    }
  });

  private readonly handleClose = () => {
    const actions = this.state.entry?.actions;
    const cancelAction = actions?.find((d) => d.style === "cancel");
    if (cancelAction?.onPress) cancelAction?.onPress();

    this.close();
  };
}
