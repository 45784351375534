/**
 * @generated SignedSource<<6fa94c571007ca0b0ea4abd54a11377f>>
 * @relayHash 196d1883aee588156158460c656d8eed
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:LZBeV7NuFbbSOi3x9pDlmbkFm7HHjAX60_ked4O07qk

import { ConcreteRequest } from 'relay-runtime';
export type HomescreenPendingDisputesCardSubscription$variables = Record<PropertyKey, never>;
export type HomescreenPendingDisputesCardSubscription$data = {
  readonly workerUpdated: {
    readonly worker: {
      readonly engagements: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly disputes: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly disputeTarget: {
                    readonly disputedFinalTimesheet?: {
                      readonly diff: {
                        readonly totalPayment: string;
                      } | null | undefined;
                    } | null | undefined;
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly gig: {
              readonly name: string | null | undefined;
              readonly organization: {
                readonly logoUrl: string | null | undefined;
              } | null | undefined;
              readonly position: {
                readonly name: string;
              } | null | undefined;
            };
            readonly id: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly totalCount: number;
      } | null | undefined;
    };
  } | null | undefined;
};
export type HomescreenPendingDisputesCardSubscription = {
  response: HomescreenPendingDisputesCardSubscription$data;
  variables: HomescreenPendingDisputesCardSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_isNull": true
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "anyEngagementDisputeDisposition": (v0/*: any*/),
      "gigType": {
        "_neq": "PROJECT"
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "approvalDisposition": (v0/*: any*/)
    }
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetComparison",
      "kind": "LinkedField",
      "name": "disputedFinalTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetDiff",
          "kind": "LinkedField",
          "name": "diff",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPayment",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomescreenPendingDisputesCardSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementDisputesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementDispute",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "disputeTarget",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "disputes(first:1,where:{\"approvalDisposition\":{\"_isNull\":true}})"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "engagements(first:10,where:{\"anyEngagementDisputeDisposition\":{\"_isNull\":true},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomescreenPendingDisputesCardSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "workerUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementDisputesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementDispute",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "disputeTarget",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                          },
                                          (v5/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v3/*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "disputes(first:1,where:{\"approvalDisposition\":{\"_isNull\":true}})"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "engagements(first:10,where:{\"anyEngagementDisputeDisposition\":{\"_isNull\":true},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:LZBeV7NuFbbSOi3x9pDlmbkFm7HHjAX60_ked4O07qk",
    "metadata": {},
    "name": "HomescreenPendingDisputesCardSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "90bfca201a0e84dc3a3a6577c49b1c9a";

export default node;
