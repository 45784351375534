import { Link, Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { getReportTypes } from "@gigsmart/isomorphic-shared/report/dictionary";
import SummaryRow from "../../gig/SummaryRows/SummaryRow";
import type { WorkerVerificationSummaryRow_shift$key } from "./__generated__/WorkerVerificationSummaryRow_shift.graphql";

type Props = {};

export const WorkerVerificationSummaryRow = ({
  requiredReportTypes
}: FragmentContainerInnerComponentProps<
  WorkerVerificationSummaryRow_shift$key,
  Props
>) => {
  const list = getReportTypes(requiredReportTypes);
  return list.length ? (
    <SummaryRow icon="shield-check" title="Verification" editVariant="none">
      <Text>
        {list.map(({ requirementText }) => requirementText).join(", ") || ""}
      </Text>
      <Link variant="note" openURL="https://gigsmart.com/workers/get-verified/">
        Verified Workers Info
      </Link>
    </SummaryRow>
  ) : null;
};

export default createRelayFragmentContainer<
  WorkerVerificationSummaryRow_shift$key,
  Props
>(
  graphql`
    fragment WorkerVerificationSummaryRow_shift on GigLike {
      ... on Gig {
        requiredReportTypes
      }
      ... on GigSeries {
        requiredReportTypes
      }
    }
  `,
  WorkerVerificationSummaryRow
);
