import { Stack } from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { SolidButton } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import type { WorkerBrowseParams } from "@gigsmart/seibutsu/browse/browse.nav";
import React from "react";
import type { applyButtons_jobPosting$key } from "./__generated__/applyButtons_jobPosting.graphql";
import useHandleApplyViaEmail from "./use-handle-apply-via-email";

interface Props {
  jobPost: applyButtons_jobPosting$key | null | undefined;
  isWorker: boolean;
  onPressApplyViaWeb?: (id: string) => void;
  worker?: {
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly primaryEmail?: {
      readonly address: string;
    } | null;
    readonly primaryMobile?: {
      readonly number: string;
    } | null;
  } | null;
}

export default function ApplyButtons({
  jobPost: jobPostRef,
  isWorker,
  worker
}: Props) {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const jobPost = useRelayFragment<applyButtons_jobPosting$key>(
    graphql`
      fragment applyButtons_jobPosting on JobPosting {
        id
        title
        applyUrl
        applyEmailAddress
      }
    `,
    jobPostRef
  );
  const handleApplyViaEmail = useHandleApplyViaEmail(worker, jobPost);
  const id = jobPost?.id ?? "";

  return (
    <Stack>
      {jobPost?.applyEmailAddress && (
        <SolidButton
          icon="external-link"
          title="Apply via Email Address"
          onPress={handleApplyViaEmail}
          disabled={!isWorker}
        />
      )}
      {!!jobPost?.applyUrl && (
        <SolidButton
          icon="external-link"
          title="Apply via Hiring Website"
          disabled={!isWorker}
          onPress={() =>
            navigation.push("BrowseJobDetails", { id, action: "apply" })
          }
        />
      )}
    </Stack>
  );
}
