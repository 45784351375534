import {
  Column,
  ContentArea,
  Divider,
  ErrorPage,
  HighlightedReminder,
  ListRow,
  Row,
  ScrollView,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { getEngagementAddonSpec } from "@gigsmart/isomorphic-shared/addon/dictionary";
import { currency, duration, time } from "@gigsmart/isomorphic-shared/iso";
import {
  graphql,
  useRelayFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import CancellationPoliciesInfoModal from "@gigsmart/seibutsu/gig/CancellationPoliciesInfoModal";
import { formatDuration } from "@gigsmart/seibutsu/utils/time";
import { Duration } from "luxon";
import React from "react";
import type { gigCancellationFeeSummaryTabSubscription } from "./__generated__/gigCancellationFeeSummaryTabSubscription.graphql";
import type { gigCancellationFeeSummaryTab_engagement$key } from "./__generated__/gigCancellationFeeSummaryTab_engagement.graphql";

const engagementFragment = graphql`
  fragment gigCancellationFeeSummaryTab_engagement on Engagement {
    id
    cancellationFee {
      feeRate
      startDelta
      scheduledAt
      scheduledDuration
    }
    paymentInfo {
      payRate
      grossPay
      netPay
      serviceFees {
        amount
        feeType
        hourlyRate
        minimumAmount
        percentageRate
      }
    }
    currentState {
      name
      transitionedAt
    }
  }
`;

const suscriptionSpec = graphql`
  subscription gigCancellationFeeSummaryTabSubscription($engagementId: ID!) {
    engagementUpdated(engagementId: $engagementId) {
      engagement {
        ...gigCancellationFeeSummaryTab_engagement
      }
    }
  }
`;

interface Props {
  engagement: gigCancellationFeeSummaryTab_engagement$key | null | undefined;
}

export default function GigCancellationFeeSummaryTab({
  engagement: engagementRef
}: Props) {
  const engagement = useRelayFragment(engagementFragment, engagementRef);
  useRelaySubscription<gigCancellationFeeSummaryTabSubscription>(
    suscriptionSpec,
    {
      engagementId: engagement?.id ?? ""
    }
  );

  if (!engagement?.cancellationFee) {
    return (
      <ErrorPage
        message="Please contact support@gigsmart.com for assistance."
        title="Error Loading Cancellation Data"
      />
    );
  }

  const startDeltaDuration = Duration.fromISO(
    engagement.cancellationFee.startDelta
  );
  const before = startDeltaDuration.as("seconds") > 0;
  const at = startDeltaDuration.as("seconds") === 0;
  const timeFrame = at
    ? "at"
    : before
      ? `${formatDuration(startDeltaDuration)} before`
      : `${formatDuration(startDeltaDuration)} after`;
  const scheduledDuration = duration.humanize(
    engagement.cancellationFee.scheduledDuration,
    "compact"
  );
  const cancelletionFeeRate = (engagement.cancellationFee.feeRate ?? 0) * 100;

  return (
    <ScrollView testID="engagement-details-scroller">
      <Surface>
        <ContentArea>
          <Stack>
            <Text variant="header">Cancellation Fee</Text>
            <HighlightedReminder
              icon="circle-exclamation"
              header={`The Requester canceled this Shift ${timeFrame} its start time. As a result, you have been compensated ${Math.round(
                engagement.cancellationFee.feeRate * 100
              )}% of your scheduled time for this Shift for a total of ${currency.humanize(
                engagement.paymentInfo?.netPay
              )}.`}
            />
            <Text variant="subheader">Cancellation Timing</Text>
            <Column>
              <Divider />
              <Stack variant="divider">
                <ListRow
                  label="Scheduled Start Time"
                  right={time.humanize(
                    engagement.cancellationFee.scheduledAt,
                    "dateTime"
                  )}
                />
                <ListRow
                  label="Canceled Time"
                  right={time.humanize(
                    engagement.currentState.transitionedAt,
                    "dateTime"
                  )}
                />
              </Stack>
              <Divider />
            </Column>
            <Text variant="subheader">Compensation Breakdown</Text>
            <Column>
              <Divider />
              <Stack variant="divider">
                <ListRow
                  label="Hourly Rate"
                  right={currency.humanizeRate(engagement.paymentInfo?.payRate)}
                />
                <ListRow
                  label="Scheduled Shift Duration"
                  right={duration.humanize(
                    engagement.cancellationFee.scheduledDuration
                  )}
                />
                <ListRow
                  label="Compensation Rate"
                  right={
                    <Stack horizontal size="slim">
                      <Text>
                        {Math.round(engagement.cancellationFee.feeRate * 100)}%
                      </Text>
                    </Stack>
                  }
                />
                <ListRow
                  label="Cancellation Compensation"
                  right={currency.humanize(engagement.paymentInfo?.grossPay)}
                >
                  <Text variant="note" color="neutral">
                    {`(${scheduledDuration} x ${cancelletionFeeRate}%) x ${currency.humanizeRate(
                      engagement.paymentInfo?.payRate
                    )}`}
                  </Text>
                </ListRow>
                {(engagement.paymentInfo?.serviceFees ?? []).map(
                  ({
                    amount,
                    feeType,
                    hourlyRate,
                    minimumAmount,
                    percentageRate
                  }) => {
                    const feeDescParts = [];
                    if (percentageRate > 0)
                      feeDescParts.push(`${percentageRate}%`);
                    if (currency.toFloat(minimumAmount) > 0)
                      feeDescParts.push(
                        `Minimum ${currency.humanize(minimumAmount)}`
                      );
                    const label = `${getEngagementAddonSpec(feeType).name}${
                      feeDescParts.length ? ` (${feeDescParts.join(",")})` : ""
                    }`;

                    return (
                      <ListRow
                        key={feeType}
                        label={label}
                        right={`- ${currency.humanize(amount)}`}
                      >
                        <Text variant="note" color="neutral">
                          {`(${scheduledDuration} x ${cancelletionFeeRate}%) x ${currency.humanizeRate(
                            hourlyRate
                          )}`}
                        </Text>
                      </ListRow>
                    );
                  }
                )}
                <ListRow
                  label={<Text weight="bold">Cancellation Fee Paid</Text>}
                  right={
                    <Text weight="bold">
                      {currency.humanize(engagement.paymentInfo?.netPay)}
                    </Text>
                  }
                />
              </Stack>
              <Divider />
            </Column>
            <Row justifyContent="center">
              <CancellationPoliciesInfoModal testID="cancellation-fee-info-modal" />
            </Row>
          </Stack>
        </ContentArea>
      </Surface>
    </ScrollView>
  );
}
