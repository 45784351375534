/**
 * @generated SignedSource<<8c7699575cd3bd3a5e46944abf4475ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigPaySchedule = "FIXED" | "HOURLY" | "INFO_REQUIRED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigSummaryRows_engagement$data = {
  readonly currentBid: {
    readonly estimatedAmount: string | null | undefined;
    readonly expectedDuration: string | null | undefined;
    readonly note: string | null | undefined;
    readonly payRate: string | null | undefined;
    readonly paySchedule: GigPaySchedule;
  } | null | undefined;
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly place: {
      readonly location: {
        readonly latitude: number;
        readonly longitude: number;
      } | null | undefined;
      readonly streetAddress: string | null | undefined;
    } | null | undefined;
    readonly placeId: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"gigSummaryRows_gig">;
  };
  readonly id: string;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftScheduleSummaryRow_engagement" | "gigPaymentRows_engagement">;
  readonly " $fragmentType": "gigSummaryRows_engagement";
};
export type gigSummaryRows_engagement$key = {
  readonly " $data"?: gigSummaryRows_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigSummaryRows_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigSummaryRows_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftScheduleSummaryRow_engagement"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Place",
          "kind": "LinkedField",
          "name": "place",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streetAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Location",
              "kind": "LinkedField",
              "name": "location",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "latitude",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "longitude",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "gigSummaryRows_gig"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementBid",
      "kind": "LinkedField",
      "name": "currentBid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimatedAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expectedDuration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paySchedule",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "gigPaymentRows_engagement"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "acad4fb3f60641a2d3c1fe564a46865a";

export default node;
