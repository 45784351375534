/**
 * @generated SignedSource<<351df1cd00b11e63ed1c898319833f96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationStateTabsOrganization_viewer$data = {
  readonly organization: {
    readonly orgActive: {
      readonly totalCount: number;
    } | null | undefined;
    readonly orgInactive: {
      readonly totalCount: number;
    } | null | undefined;
  };
  readonly " $fragmentType": "ConversationStateTabsOrganization_viewer";
};
export type ConversationStateTabsOrganization_viewer$key = {
  readonly " $data"?: ConversationStateTabsOrganization_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConversationStateTabsOrganization_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "activeQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "inactiveQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConversationStateTabsOrganization_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": "orgActive",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "activeQuery"
            }
          ],
          "concreteType": "ConversationsConnection",
          "kind": "LinkedField",
          "name": "conversations",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": "orgInactive",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "inactiveQuery"
            }
          ],
          "concreteType": "ConversationsConnection",
          "kind": "LinkedField",
          "name": "conversations",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationRequester",
  "abstractKey": null
};
})();

(node as any).hash = "50df405c3a1f158eb36a292e0be81bd8";

export default node;
