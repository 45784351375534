import React from "react";

import { IconText, List, Stack, Text, Well } from "@gigsmart/atorasu";

interface Props {
  showHeader?: boolean;
}

const JobBoardInfoContent = ({ showHeader }: Props) => (
  <Stack>
    {showHeader && (
      <IconText icon="newspaper">
        <Text>Job Board</Text>
      </IconText>
    )}
    <Text>
      Browse part-time and full-time positions posted on the Job Board. Apply
      directly through the hiring company.
    </Text>
    <Well>
      <List variant="check">
        <Text>Browse part-time and full-time job openings</Text>
        <Text>Search jobs based on title, company, industry, or location</Text>
        <Text>
          Apply through the company's hiring website or preferred email address
        </Text>
      </List>
    </Well>
  </Stack>
);

export default JobBoardInfoContent;
