import React, { useCallback } from "react";

import { useStyles } from "../style";
import StyledLargeButton, {
  type LargeButtonProps
} from "./styled-large-button";

type Props = LargeButtonProps & {
  readonly buttonColor?: "blue" | "orange";
};

export default ({ buttonColor = "blue", ...buttonProps }: Props) => {
  const { styles } = useStyles(
    ({ color }) => ({
      text: {
        color: color[buttonColor]
      },
      disabledText: {
        color: color.neutralDark
      },
      button: {
        borderWidth: 1,
        borderColor: color[buttonColor]
      },
      buttonHover: {
        backgroundColor: color.withOpacity(color[buttonColor], 0.1),
        borderWidth: 1,
        borderColor: color[buttonColor]
      },
      buttonDisabled: {
        backgroundColor: color.transparent,
        borderColor: color.neutralDark
      }
    }),
    [buttonColor]
  );
  const textStyles = useCallback(
    (disabled?: boolean) => (disabled ? styles.disabledText : styles.text),
    [styles]
  );
  const buttonStyles = useCallback(
    (hover: boolean) => (hover ? styles.buttonHover : styles.button),
    [styles]
  );
  const disabledStyles = useCallback(() => styles.buttonDisabled, [styles]);
  return (
    <StyledLargeButton
      textStyles={textStyles}
      buttonStyles={buttonStyles}
      disabledStyles={disabledStyles}
      {...buttonProps}
    />
  );
};
