import {
  ContentArea,
  ContentRow,
  Divider,
  HighlightedReminder,
  InlineButton,
  Row,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { duration } from "@gigsmart/isomorphic-shared/iso";
import { TabbedModal } from "@gigsmart/katana";
import { graphql } from "@gigsmart/relay";
import React, { useState } from "react";
import { TimesheetList } from "../../engagement/TimesheetList";
import type { ApprovedTimesheetModalRow_EngagementTimesheet$data } from "./__generated__/ApprovedTimesheetModalRow_EngagementTimesheet.graphql";

interface Props {
  timeWorked?: string | null;
  systemTimesheet: Omit<
    ApprovedTimesheetModalRow_EngagementTimesheet$data,
    " $fragmentType"
  >;
  approvedTimesheet: Omit<
    ApprovedTimesheetModalRow_EngagementTimesheet$data,
    " $fragmentType"
  >;
  timezone: string;
}

export default function ApprovedTimesheetModalRow({
  timeWorked,
  systemTimesheet,
  approvedTimesheet,
  timezone
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const tabs = [
    {
      key: "approved",
      label: "APPROVED",
      Contents: (
        <ContentArea>
          <Stack>
            <Text>
              This is the Timesheet the Requester approved. Your approved
              payment is based on this Timesheet.
            </Text>
            {approvedTimesheet?.paymentStyle === "CALCULATED" ? (
              <TimesheetList
                timesheetListStates={approvedTimesheet?.states}
                isApproved
                variant="worker"
                workerEta={null}
                endsAt={null}
                timezone={timezone}
                id={approvedTimesheet.id}
              />
            ) : (
              <>
                <HighlightedReminder
                  icon="circle-exclamation"
                  header="This Requester opted to pay you without a Timesheet."
                />
                <Spacer />
                <Divider />
                <ContentArea>
                  <Stack horizontal justifyContent="space-between">
                    <Text>Time Worked</Text>
                    <Text>
                      {duration.humanize(
                        approvedTimesheet?.totalDurationWorked,
                        "semi-compact-no-spaces"
                      )}
                    </Text>
                  </Stack>
                </ContentArea>
                <Divider />
              </>
            )}
          </Stack>
        </ContentArea>
      )
    },
    {
      key: "system",
      label: "SYSTEM",
      Contents: (
        <ContentArea>
          <Stack>
            <Text>The following times were recorded by the system.</Text>
            <TimesheetList
              timesheetListStates={systemTimesheet?.states ?? null}
              isApproved
              variant="worker"
              workerEta={null}
              endsAt={null}
              timezone={timezone}
              id={systemTimesheet?.id}
            />
          </Stack>
        </ContentArea>
      )
    }
  ];
  return (
    <Surface>
      <ContentRow>
        <Row justifyContent="space-between" alignItems="center" fill>
          <Text>
            <Text weight="semibold">Approved Timesheet:</Text>{" "}
            <Text weight="semibold" color="neutral">
              {duration.humanize(timeWorked, "semi-compact-no-spaces")}
            </Text>
          </Text>
          <InlineButton
            label="View"
            onPress={() => setShowModal(true)}
            testID="view-timesheet-btn"
          />
        </Row>
      </ContentRow>
      <TabbedModal
        testID="approved-timesheet-modal"
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        tabs={tabs}
        title="Approved Timesheet"
      />
    </Surface>
  );
}

graphql`
  fragment ApprovedTimesheetModalRow_EngagementTimesheet on EngagementTimesheet {
    id
    totalDurationWorked
    estimatedMileage
    paymentStyle
    states(first: 50, overridden: true, query: "ORDER BY transitionedAt ASC") {
      edges {
        node {
          __typename
          id
          name
          transitionedAt
          action
          transitionedBy {
            __typename
            displayName
          }
          negotiation {
            payRate
            submittedBy {
              __typename
              displayName
            }
          }
          ... on EngagementStateOverride {
            overridesState {
              id
            }
          }
        }
      }
    }
  }
`;
