import React, { type ComponentProps } from "react";
import StyledTextInput from "../input/styled-text-input/styled-text-input";

type Props = ComponentProps<typeof StyledTextInput>;

const format = (text?: string) => text?.replace(/^\+?1\s?/, "");

const StyledPhoneNumberInput = ({ value: rawValue, ...props }: Props) => {
  const value = format(rawValue);
  return (
    <StyledTextInput
      placeholder="(123) 456-7890"
      keyboardType="phone-pad"
      value={value}
      mask="phoneNumber"
      {...props}
    />
  );
};

export default StyledPhoneNumberInput;
