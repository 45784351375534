import React from "react";
import { View } from "react-native";
import { type IconName, StyledIcon } from "../icon";
import { type ViewStyleProp, useStyles } from "../style";
import { KatanaText } from "../text";

interface Props {
  style?: ViewStyleProp;
  iconName?: IconName;
  label: string;
}

const AlertIconContent = ({
  style,
  iconName = "triangle-exclamation",
  label
}: Props) => {
  const { styles } = useStyles(({ color, font, units }) => ({
    icon: {
      marginBottom: units(5),
      textAlign: "center",
      fontSize: 28,
      color: color.orange
    },
    label: {
      textAlign: "center",
      color: color.orange,
      fontSize: font.size.large
    }
  }));

  return (
    <View style={style}>
      <StyledIcon style={styles.icon} name={iconName} variant="solid" />
      <KatanaText style={styles.label}>{label}</KatanaText>
    </View>
  );
};

export default AlertIconContent;
