/**
 * @generated SignedSource<<bf496d85cbc706ecd3799b45a1126973>>
 * @relayHash 6279713f78def697c1daee71f7a5a6f9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:D1pG1CmYZXOJkYzCccSJvZSnClM8ifMovpe3QwQ4IM4

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QualificationsStepQuery$variables = {
  deniedItemsQuery: string;
  id: string;
  selectedItemsQuery: string;
};
export type QualificationsStepQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"useGigFields_gigOrEngagement">;
  } | null | undefined;
  readonly viewer: {
    readonly gigFields?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly deniedDefinitions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly label: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly iconName: string;
          readonly selectedDefinitions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly label: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly title: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id?: string;
  } | null | undefined;
};
export type QualificationsStepQuery = {
  response: QualificationsStepQuery$data;
  variables: QualificationsStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deniedItemsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedItemsQuery"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iconName",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 40
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinitionsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GigFieldItemDefinition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": "selectedDefinitions",
  "args": [
    (v7/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "selectedItemsQuery"
    }
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": "deniedDefinitions",
  "args": [
    (v7/*: any*/),
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "deniedItemsQuery"
    }
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigFieldItemDefinition",
    "kind": "LinkedField",
    "name": "requiredQualifications",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "GigField",
        "kind": "LinkedField",
        "name": "gigField",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v13/*: any*/),
      "type": "GigSeries",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v13/*: any*/),
      "type": "Gig",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QualificationsStepQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:10)"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "useGigFields_gigOrEngagement",
            "selections": [
              (v14/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QualificationsStepQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:10)"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v14/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:D1pG1CmYZXOJkYzCccSJvZSnClM8ifMovpe3QwQ4IM4",
    "metadata": {},
    "name": "QualificationsStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "bd96131339f7327e385bfeb8669be3d1";

export default node;
