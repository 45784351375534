import {
  Button,
  Column,
  ContentArea,
  Spacer,
  Surface,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { BrandedHoorayAd, GenericHoorayAd } from "@gigsmart/feature-flags";
import React from "react";
import HealthcareAdCard from "../banners/HealthcareAdCard";

type Props = {
  inset?: boolean;
};

export function WorkerBenefitsRow({ inset = true }: Props) {
  if (BrandedHoorayAd.isEnabled() || GenericHoorayAd.isEnabled()) {
    return <HealthcareAdCard isDetails />;
  }

  return (
    <Surface>
      <ContentArea size="medium" horizontal alignItems="center">
        {inset && <Spacer horizontal />}
        {inset && <Spacer horizontal />}
        <Column fill gap="small">
          <Text color="primary" variant="subheader">
            Protect yourself with a Benefit Plan
          </Text>
          <Text variant="note">Browse or create a personalized plan.</Text>
        </Column>
        <Button
          size="small"
          label="View"
          testID="view-benefits-btn"
          outline
          onPress={() =>
            showEmbeddedBrowser({
              url: "https://gigsmart.com/worker-benefits"
            })
          }
        />
      </ContentArea>
    </Surface>
  );
}
