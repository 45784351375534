import { Stack } from "@gigsmart/atorasu";
import { Validator } from "@gigsmart/fomu";
import { FomuStyledTextInput } from "@gigsmart/isomorphic-shared/fomu-inputs";
import React from "react";

export default function BankAccountFields() {
  return (
    <Stack size="compact">
      <FomuStyledTextInput
        name="accountHolderName"
        validates={[Validator.presence({ message: "invalid name" })]}
        label="Name on the Account"
        eventTargetName="Input Full Name"
        placeholder="Full Name"
        testID="account-name"
      />
      <FomuStyledTextInput
        name="accountNumber"
        validates={[
          Validator.match({
            pattern: /\d{8,12}/,
            message: "invalid account number"
          })
        ]}
        label="Account Number"
        eventTargetName="Input Account Number"
        placeholder="Account Number"
        testID="account-number"
        keyboardType="numeric"
        sensitive
      />
      <FomuStyledTextInput
        name="routingNumber"
        validates={[
          Validator.match({
            pattern: /\d{9}/,
            message: "invalid routing number"
          })
        ]}
        label="Routing Number"
        eventTargetName="Input Routing Number"
        placeholder="Routing Number"
        testID="account-routingNumber"
        keyboardType="numeric"
      />
    </Stack>
  );
}
