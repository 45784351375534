/**
 * @generated SignedSource<<49cd76c3b1c58883aff11acc8f5de29b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useUserPronoun_Worker$data = {
  readonly pronoun: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "useUserPronoun_Worker";
};
export type useUserPronoun_Worker$key = {
  readonly " $data"?: useUserPronoun_Worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUserPronoun_Worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useUserPronoun_Worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPronoun",
      "kind": "LinkedField",
      "name": "pronoun",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "2a26d6076674a7889879da96b7e482fd";

export default node;
