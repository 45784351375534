import { currency } from "@gigsmart/isomorphic-shared/iso";
import { KatanaTabs } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";

import PriorTransfers from "./prior-transfers";
import Stats from "./stats";

import type { walletTabs_worker$key } from "./__generated__/walletTabs_worker.graphql";
import type { walletTabs_workerPayableAccount$key } from "./__generated__/walletTabs_workerPayableAccount.graphql";

interface Props {
  worker: walletTabs_worker$key | null | undefined;
  workerPayableAccount: walletTabs_workerPayableAccount$key | null | undefined;
}

export default ({
  worker: workerFragmentRef,
  workerPayableAccount: workerPayableAccountFragmentReference
}: Props) => {
  const worker = useRelayFragment(
    graphql`
      fragment walletTabs_worker on Worker{
        walletBalance
        ...statsTab_worker
      }
    `,
    workerFragmentRef
  );
  const workerPayableAccount = useRelayFragment(
    graphql`
      fragment walletTabs_workerPayableAccount on WorkerPayableAccount {
        ...priorTransfers_workerPayableAccount
      }
    `,
    workerPayableAccountFragmentReference
  );
  const walletTabs = [
    {
      label: "STATS",
      Contents: <Stats worker={worker} />
    },
    {
      label: "PRIOR TRANSFERS",
      Contents: (
        <PriorTransfers
          workerBalance={currency.toFloat(worker?.walletBalance)}
          workerPayableAccount={workerPayableAccount}
        />
      )
    }
  ];

  return <KatanaTabs tabs={walletTabs} defaultTab={walletTabs[0]?.label} />;
};
