import React from "react";

import {
  ContentArea,
  List,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu/";
import { useStyles } from "@gigsmart/atorasu/style";
import { HourlyRateBids, PositionsAndLocations } from "@gigsmart/feature-flags";
import { StyledScrollView, TabbedModal } from "@gigsmart/katana";

interface Props {
  showModal: boolean;
  handleClose: () => void;
}

const NextStepsShiftModal = ({ showModal, handleClose }: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    listview: {
      height: getUnits(72),
      padding: getUnits(3)
    }
  }));

  const shifts = PositionsAndLocations.select("Shifts", "Shift Gigs");
  return (
    <TabbedModal
      testID="next-steps-shift-modal"
      showModal={showModal}
      handleClose={handleClose}
      title={PositionsAndLocations.select(
        "Next Steps for Shifts",
        "Next Steps for Shifts Gigs"
      )}
      buttonTitle="Got it"
    >
      <StyledScrollView
        style={styles.listview}
        showsVerticalScrollIndicator={true}
      >
        <Stack>
          <Text>
            Here's what happens{" "}
            <Text weight={HourlyRateBids.select("bold", undefined)}>
              after you
            </Text>
            <Text weight="bold"> {HourlyRateBids.select("Bid", "apply")}:</Text>
          </Text>
          <Surface variant="flat" color="foreground">
            <ContentArea>
              {HourlyRateBids.isDisabled() ? (
                <List>
                  <Text>
                    The Requester will review your application. If they have any
                    questions, they will be able to message you through the app.
                    {"\n"}
                    If you are no longer available to work the Shift, you can
                    withdraw your application.
                  </Text>
                  <Text>
                    If you are offered Shift(s) for this Gig, you will receive
                    an offer through the app that you must accept or reject.{" "}
                    <Text weight="bold">
                      You are not hired to work a Shift until you have received
                      and accepted a Shift offer. Please do not show up to work
                      a Shift unless you have been hired.
                    </Text>
                    {"\n"}
                    If the Shift requires Verification, once accepting the offer
                    the Verification process will begin.
                  </Text>
                </List>
              ) : (
                <Stack>
                  <List>
                    <Text>
                      The Requester will review your Bid. If they have any
                      questions, they will be able to message you through the
                      app. If you are no longer available to work the Shift, you
                      can withdraw your Bid. You can also edit your Bid while it
                      is awaiting review.
                    </Text>
                    <Text>
                      If your Bid is accepted, you will get an offer for the
                      Shift to accept or reject. The Requester may also send a
                      counter-offer. You can accept the counter-offer, submit a
                      counter-bid, or reject the Shift.
                    </Text>
                  </List>
                  <Text>
                    Note: You are not hired to work a Shift until you have
                    received and accepted a Shift offer. Please do not show up
                    to work a Shift unless you have been hired. If the Shift
                    requires Verification, once accepting the offer, the
                    Verification process begins.
                  </Text>
                </Stack>
              )}
            </ContentArea>
          </Surface>
          <Text>
            Here's what happens after you
            <Text weight="bold"> get hired:</Text>
          </Text>
          <Surface variant="flat" color="foreground">
            <ContentArea>
              <List>
                <Text>
                  Once the Shift is accepted (and Verification is completed if
                  applicable), you'll be hired on the offered Shift(s). You will
                  now have access to the full address of the Shift and will be
                  able to call the Requester through the app.
                </Text>
                <Text>
                  Be sure to use the app to go En Route, indicate you have
                  Arrived, and if your location is confirmed to be on-site
                  through the app, you will be able to start your clock.
                </Text>
                <Text>
                  At the end of the Shift if there are any issues with your time
                  worked you will be able to submit an updated timesheet for the
                  Requester to review and they will be able to make any changes
                  prior to approving your final timesheet.
                </Text>
                <Text>
                  Once your final timesheet is approved you will be able to rate
                  and review the Requester and your earnings will be deposited
                  in your{" "}
                  {PositionsAndLocations.select("Worker Wallet", "Gig Wallet")}.
                  For your safety, receiving payment for {shifts} outside the
                  app is prohibited. {shifts} outside the app is prohibited.
                </Text>
              </List>
            </ContentArea>
          </Surface>
          <Spacer />
        </Stack>
      </StyledScrollView>
    </TabbedModal>
  );
};

export default NextStepsShiftModal;
