import * as inflected from "inflected";

export function pluralize(
  count: number | null | undefined,
  noun: string,
  returnCountString = true
) {
  const localCount = count ?? 0;
  const subject =
    localCount === 1 ? inflected.singularize(noun) : inflected.pluralize(noun);
  return returnCountString ? `${localCount} ${subject}` : `${subject}`;
}
