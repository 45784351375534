import {
  Button,
  CollapsibleText,
  Column,
  Divider,
  HighlightedReminder,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { WorkerEtaCalculator } from "@gigsmart/feature-flags";
import { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import { SummaryRow } from "@gigsmart/katana";
import { ShiftLocationMap } from "@gigsmart/seibutsu/gig/ShiftLocationMap";
import { showEtaModal } from "@gigsmart/seibutsu/shifts/summary/EtaModal";
import React from "react";
import { showLocation } from "react-native-map-link";

interface Props {
  area?: string | null;
  address?: string | null;
  distance?: number | null;
  hasMultipleLocations?: boolean;
  eta?: boolean;
  etaGigId?: string;
  history?: boolean;
  location?: {
    latitude: number | null;
    longitude: number | null;
    placeId?: string | null;
  };

  isProject?: boolean;
  arrivalInstructions?: string;
}

/** @deprecated use Seibutsu WorkerSummaryLocation */
export default function SummaryLocation({
  area,
  address,
  distance,
  hasMultipleLocations,
  eta,
  etaGigId,
  history,
  location,
  isProject,
  arrivalInstructions
}: Props) {
  // address is shown as part of the Date & Time summary row
  if (hasMultipleLocations) return null;

  const hasFullAddress = !!address;
  const distanceStr =
    typeof distance === "number"
      ? gigHelpers.formatDistanceRange({ min: distance }, " away")
      : "";

  const addressOrArea = address ?? area;
  const isRemote = addressOrArea === "REMOTE";
  const showLocationHandler = !eta && !!location && hasFullAddress && !history;
  const showEtaButton =
    WorkerEtaCalculator.isEnabled() &&
    !hasFullAddress &&
    !history &&
    !!etaGigId &&
    eta;

  const handleShowLocation = () => {
    void showLocation({
      latitude: location?.latitude ?? "",
      longitude: location?.longitude ?? "",
      googlePlaceId: location?.placeId ?? "",
      alwaysIncludeGoogle: true,
      dialogTitle: "Get Directions",
      dialogMessage: "Select a preferred navigation app"
    });
  };

  const renderLocationContent = () => {
    if (isRemote) {
      return (
        <Stack size="slim">
          <Text>Remote</Text>
          <Text variant="note" color="neutral">
            You do not need to be on-site to complete this Project.
          </Text>
        </Stack>
      );
    }

    const reminder = !isProject
      ? "The Shift address and arrival instructions will be displayed upon hiring."
      : "The Gig address will be displayed upon hiring.";

    return (
      <Stack>
        <Stack horizontal alignItems="center" size="medium">
          <Stack size="standard" fill>
            <Text>
              {addressOrArea} {!history && distanceStr}
            </Text>
            {!hasFullAddress && !history && (
              <HighlightedReminder header={reminder} icon="map-location-dot" />
            )}
          </Stack>
          {showLocationHandler && (
            <Button
              testID="directions-btn"
              outline
              size="small"
              label="Directions"
              onPress={handleShowLocation}
            />
          )}
        </Stack>
        {!!location && !isProject && hasFullAddress && (
          <Column>
            <Text weight="bold">Clock-in Location:</Text>
            <Spacer size="compact" />
            <ShiftLocationMap center={location} />
          </Column>
        )}
        {!!arrivalInstructions && !isProject && (
          <Column>
            <Divider />
            <Spacer size="medium" />
            <CollapsibleText numberOfLines={3} testID="arrival-instructions">
              <Text weight="bold">Arrival Instructions:</Text>{" "}
              {arrivalInstructions}
            </CollapsibleText>
          </Column>
        )}
        {showEtaButton && (
          <Button
            testID="calculate-estimated-travel-btn"
            outline
            size="small"
            label="Calculate Estimated Travel Time"
            onPress={() => showEtaModal(etaGigId)}
          />
        )}
      </Stack>
    );
  };

  return (
    <SummaryRow
      iconName="location-dot"
      sectionLabel="Location"
      sectionContent={
        <Column fill>
          {renderLocationContent()}
          <Spacer size="compact" />
        </Column>
      }
    />
  );
}
