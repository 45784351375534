import { Button, Text, showModal } from "@gigsmart/atorasu";
import { DirectPaymentsOnly } from "@gigsmart/feature-flags";
import React, { useMemo } from "react";
import { showDirectHirePolicy } from "../useLinkToDirectHirePolicy";

type DirectHireVariant = "APPROVED" | "PENDING" | "REJECTED" | "NO_RESPONSE";

function getModalContents(variant: DirectHireVariant) {
  switch (variant) {
    case "APPROVED":
      return DirectPaymentsOnly.select(
        {
          title: "About Organizations Who Have Directly Paid You",
          body: "When an Organization has confirmed a direct payment, you are notified, and your bonus is paid directly to your Worker Wallet. The Requester may choose to continue to work with you through the GigSmart app or work directly outside the platform."
        },
        {
          title: "About Requesters Who Have Direct Paid or Hired You",
          body: "When a Requester has confirmed a direct payment or hire, you are notified and your bonus is paid directly to your Worker Wallet. The Requester may choose at this point to continue to work with you through the GigSmart app or to continue to work directly outside the platform."
        }
      );

    case "PENDING":
      return DirectPaymentsOnly.select(
        {
          title: "About Pending Direct Payment Requests",
          body: "You have submitted a Direct Payment confirmation request to an Organization. Requesters have up to 3 business days to review your confirmation request. If approved, you will be notified, and your bonus will be paid directly to your Worker Wallet. If rejected, you will be notified and can submit a request to GigSmart for further review."
        },
        {
          title: "About Pending Direct Payment & Hire Requests",
          body: "You have submitted a Direct Payment or Hire confirmation request to an Organization. Requesters have up to 3 business days to review your confirmation request. If approved, you will be notified, and your bonus will be paid directly to your Worker Wallet. If rejected, you will be notified and can submit a request to GigSmart for further review."
        }
      );

    case "REJECTED":
      return DirectPaymentsOnly.select(
        {
          title: "About Organizations Who Have Rejected Your Direct Payment",
          body: "You submitted a Direct Payment confirmation request to an Organization, which was rejected. If you believe this was in error, you can submit a request to GigSmart for further review."
        },
        {
          title:
            "About Organizations Who Have Rejected Your Direct Payment or Hire",
          body: "You submitted a Direct Payment or Hire confirmation request to an Organization that was rejected. If you believe this was in error, you can submit a request to GigSmart for further review."
        }
      );

    case "NO_RESPONSE":
      return DirectPaymentsOnly.select(
        {
          title: "No Response to your Direct Payment",
          body: "You submitted a Direct Payment confirmation request to an Organization and the Organization made no response, so it was not paid. If you believe this was in error, you can submit a request to GigSmart for further review."
        },
        {
          title: "No Response to your Direct Payment or Hire",
          body: "You submitted a Direct Payment or Hire confirmation request to an Organization and the Organization made no response, so it was not paid. If you believe this was in error, you can submit a request to GigSmart for further review."
        }
      );
  }
}

export function showWorkerAboutDirectHireModal(variant: DirectHireVariant) {
  const { title, body } = getModalContents(variant);
  const handleLinkPress = () => showDirectHirePolicy("worker");

  return showModal({
    title,
    children: () => (
      <>
        <Text>{body}</Text>
        <Button
          testID="learn-more-btn"
          variant="clear"
          label={DirectPaymentsOnly.select(
            "Learn More About Direct Payments",
            "Learn About Direct Payments & Hires"
          )}
          icon="external-link"
          onPress={handleLinkPress}
        />
      </>
    ),
    eventContext: "Direct Payments & Hires About Modal",
    actions: [{ label: "Got It", testID: "close-btn" }]
  });
}

export default function useWorkerAboutDirectHireModal() {
  return useMemo(() => {
    return {
      showApproved: () => showWorkerAboutDirectHireModal("APPROVED"),
      showPending: () => showWorkerAboutDirectHireModal("PENDING"),
      showRejected: () => showWorkerAboutDirectHireModal("REJECTED"),
      showNoResponse: () => showWorkerAboutDirectHireModal("NO_RESPONSE")
    };
  }, []);
}
