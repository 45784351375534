import { type RawUtms, getUtmData } from "@gigsmart/dekigoto/integrations/utm";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import { useEffect } from "react";

export default function useUtmUserValue() {
  const [utm, setUtm] = useUserValue<Partial<RawUtms>>("utm", {
    defaultValue: {}
  });

  useEffect(() => {
    getUtmData(new URLSearchParams(), utm ?? undefined).then(({ raw }) =>
      setUtm({ ...(utm ?? {}), ...raw })
    );
  }, [JSON.stringify(utm)]);
}
