import {
  Button,
  Column,
  ContentArea,
  ScreenScroll,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import {
  type FomuOnSubmitArgs,
  Form,
  FormSubmit,
  Validator
} from "@gigsmart/fomu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import FomuNavBackButton from "@gigsmart/isomorphic-shared/nav/fomu-nav-back-button";
import type { StepProps } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import ApprovedEarningsModalRow from "@gigsmart/seibutsu/engagement-dispute/worker/ApprovedEarningsModalRow";
import DisputeCommentInput from "@gigsmart/seibutsu/engagement-dispute/worker/DisputeCommentInput";
import type { StepPropsType } from "@gigsmart/seibutsu/engagement-dispute/worker/types";
import useSetEngagementSummary, {
  type SetEngagementSummaryInput
} from "@gigsmart/seibutsu/engagement/useSetEngagementSummary";
import FomuMoneyInput from "@gigsmart/seibutsu/fomu/inputs/FomuMoneyInput";
import StepHeader from "@gigsmart/seibutsu/shared/Stepper/StepHeader";
import React from "react";
import type { requestAdditionalPaymentStepQuery } from "./__generated__/requestAdditionalPaymentStepQuery.graphql";

type Props = StepProps<StepPropsType>;

export default createSuspendedQueryContainer<
  requestAdditionalPaymentStepQuery,
  Props
>(
  function RequestAdditionalPaymentStep({
    gotoStepByName,
    stepData,
    gotoStep,
    response: result
  }) {
    const finalTimesheet = result?.engagement?.finalTimesheet;
    const disputeTimesheetExists = !!result?.timesheet;
    const commit = useSetEngagementSummary();
    const handleSubmit = ({ values }: FomuOnSubmitArgs) => {
      const additionalPay =
        currency.toFloat(values?.additionalPay) +
        currency.toFloat(finalTimesheet?.additionalPayment);
      const input: SetEngagementSummaryInput = {
        additionalPayment: currency.toISOString(additionalPay),
        engagementId: stepData.engagementId,
        disputedTimesheetId: result?.engagement?.finalTimesheet?.id,
        createOverrides: false
      };
      if (
        !disputeTimesheetExists &&
        finalTimesheet?.paymentStyle !== "CALCULATED"
      ) {
        input.paymentStyle = finalTimesheet?.paymentStyle;
        input.totalDurationWorked = finalTimesheet?.totalDurationWorked;
        input.totalPaymentAmount = finalTimesheet?.totalPaymentAmount;
      }
      commit(
        {
          input
        },
        {
          onSuccess: (result) => {
            gotoStepByName("dispute-payment", {
              ...stepData,
              disputeTimesheetId:
                result?.setEngagementSummary?.engagementTimesheet?.id,
              additionalPayComment: values?.additionalPayComment,
              additionalPayValue: values?.additionalPay
            });
          }
        }
      );
    };
    const disputeTimesheet = result?.timesheet;
    const disputeAdditionalPay =
      disputeTimesheet?.disputedFinalTimesheet?.diff?.additionalPayment;
    return (
      <ScreenScroll testID="request-additional-payment-step">
        <Form
          initialValues={{
            additionalPay:
              disputeAdditionalPay && disputeAdditionalPay !== "0 USD"
                ? Number(disputeAdditionalPay?.replace(" USD", "")).toFixed(2)
                : null,
            additionalPayComment: stepData?.additionalPayComment
          }}
          onSubmit={handleSubmit}
        >
          <FomuNavBackButton onGoBack={() => gotoStep(3, stepData)} />
          <Column fill justifyContent="space-between">
            <Column fill>
              <Stack>
                <StepHeader
                  name="Additional Payment Request"
                  note="Please enter the amount of your Additional Payment Request below."
                />
                <ApprovedEarningsModalRow fragmentRef={result?.engagement} />
                <Surface>
                  <ContentArea>
                    <Stack>
                      <Text weight="semibold">
                        How much Additional Payment would you like to request?
                      </Text>
                      <FomuMoneyInput
                        label="Additional Payment"
                        name="additionalPay"
                        placeholder="0.00"
                        validates={[
                          Validator.maxNumber({
                            max: 500,
                            message: "Amount cannot exceed $500.00"
                          }),
                          Validator.minNumber({
                            min: 5,
                            message: "Amount cannot be less than $5.00",
                            required: true
                          })
                        ]}
                      />
                    </Stack>
                  </ContentArea>
                </Surface>
                <DisputeCommentInput
                  name="additionalPayComment"
                  label="Why are you requesting an Additional Payment?"
                  placeholder="Provide as much detail as possible in order to help explain to the Requester why you are requesting Additional Payment."
                />
              </Stack>
            </Column>
            <ContentArea size="none">
              <Spacer />
              <FormSubmit>
                {({ submit, invalid, dirty }) => (
                  <Button
                    testID="save-btn"
                    label="Save Changes"
                    onPress={submit}
                    disabled={invalid || !dirty}
                  />
                )}
              </FormSubmit>
            </ContentArea>
          </Column>
        </Form>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query requestAdditionalPaymentStepQuery(
        $engagementId: ID!
        $timesheetId: ID!
        $hasTimesheetId: Boolean!
      ) {
        engagement: node(id: $engagementId) {
          ... on Engagement {
            ...ApprovedEarningsModalRow_engagement
            finalTimesheet: timesheet(variant: FINAL) {
              id
              paymentStyle
              additionalPayment
              totalDurationWorked
              totalPaymentAmount
            }
          }
        }
        timesheet: node(id: $timesheetId) @include(if: $hasTimesheetId) {
          ... on EngagementTimesheet {
            disputedFinalTimesheet {
              diff {
                additionalPayment
              }
            }
          }
        }
      }
    `,
    variables: ({ stepData }) => ({
      engagementId: stepData.engagementId,
      timesheetId: stepData?.disputeTimesheetId ?? "",
      hasTimesheetId: !!stepData?.disputeTimesheetId
    })
  }
);
