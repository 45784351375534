/**
 * @generated SignedSource<<a4ce1e611d6eed0d170f798ae63090cf>>
 * @relayHash 6e7e3a0fb270bc11bfb1765dc1594bd4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:7ecZ4R4ivpAfKI8P_IM8dzgKjGdHXzyzS0begsayoE4

import { ConcreteRequest } from 'relay-runtime';
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type AddUserInput = {
  emailAddress: string;
  password: string;
  userType: UserType;
};
export type PasswordStepMutation$variables = {
  input: AddUserInput;
};
export type PasswordStepMutation$data = {
  readonly addUser: {
    readonly token: string;
  } | null | undefined;
};
export type PasswordStepMutation = {
  response: PasswordStepMutation$data;
  variables: PasswordStepMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddUserPayload",
    "kind": "LinkedField",
    "name": "addUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordStepMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordStepMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:7ecZ4R4ivpAfKI8P_IM8dzgKjGdHXzyzS0begsayoE4",
    "metadata": {},
    "name": "PasswordStepMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3a7f91ad8482fc1695f5d45dfc31b8e1";

export default node;
