import {
  Column,
  ContentArea,
  FooterSpacer,
  HighlightedReminder,
  ScreenScroll,
  Spacer,
  Stack
} from "@gigsmart/atorasu";
import { isApplicableState } from "@gigsmart/isomorphic-shared/gig/helpers";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import EngagementDisputeSummaryStack from "@gigsmart/seibutsu/engagement-dispute/worker/EngagementDisputeSummaryStack";
import HelpfulToDoList from "@gigsmart/seibutsu/engagement/HelpfulToDoList";
import ShiftInfoCollapsable from "@gigsmart/seibutsu/engagement/ShiftInfoCollapsable";
import WorkerApplyFooter from "@gigsmart/seibutsu/gig/WorkerApplyFooter";
import React, { type ReactNode, useMemo } from "react";
import type { content_engagement$key } from "./__generated__/content_engagement.graphql";
import EngagementDetailsHeader from "./engagement-details-header";
import { GigAppliedOn } from "./gig-applied-on";
import GigConfirming from "./gig-confirming";
import EngagementDetailsInfoTabs from "./info-tabs";

interface Props {
  header?: ReactNode;
  engagementRef?: content_engagement$key | null | undefined;
}

const EngagementDetailsContent = ({ engagementRef }: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment content_engagement on Engagement {
        id
        insertedAt
        currentState {
          name
        }
        gig {
          ...WorkerApplyFooter_gigLike
          currentState {
            name
          }
        }
        gigType
        ...infoTabs_engagement
        ...HelpfulToDoList_engagement
        ...ShiftInfoCollapsable_engagement
        ...engagementDetailsHeader_engagement
      }
    `,
    engagementRef ?? null
  );

  const isProject = engagement?.gigType === "PROJECT";
  const stateName = engagement?.currentState?.name ?? "";
  const gig = engagement?.gig;
  const { isEngagedOrHireRequested, canReapply, isInProgress } = useMemo(() => {
    const isEngagedOrHireRequested = ["ENGAGED", "HIRE_REQUESTED"].includes(
      stateName
    );
    const isInProgress = ["AWAITING_START", "WORKING", "PAUSED"].includes(
      stateName
    );
    const canReapply =
      isApplicableState(gig?.currentState.name) &&
      (["MISSED", "REJECTED"].includes(stateName) ||
        (stateName === "APPLICATION_CANCELED" && isProject));
    return {
      isEngagedOrHireRequested,
      isInProgress,
      canReapply
    };
  }, [stateName, gig]);

  return (
    <ScreenScroll
      testID="engagement-details"
      footer={
        !!gig &&
        canReapply && (
          <WorkerApplyFooter
            fragmentRef={gig}
            stateName={engagement.currentState.name}
          />
        )
      }
    >
      <Column gap="standard">
        <Stack variant="divider">
          {stateName === "APPLIED" && !!engagement?.insertedAt && (
            <GigAppliedOn insertedAt={engagement.insertedAt} />
          )}
          {engagement?.currentState?.name === "CONFIRMING" && <GigConfirming />}
          <EngagementDetailsHeader engagementRef={engagement} />
        </Stack>
        {!isProject && <ShiftInfoCollapsable fragmentRef={engagement} />}
        {isEngagedOrHireRequested && (
          <HelpfulToDoList fragmentRef={engagement} />
        )}
        {!isProject && (
          <EngagementDisputeSummaryStack engagementId={engagement?.id ?? ""} />
        )}
        {isInProgress && !isProject && (
          <ContentArea size="none">
            <HighlightedReminder
              icon="clock"
              header="Was there an issue with your timesheet? You can always submit an Updated Timesheet at the end of the Shift."
            />
          </ContentArea>
        )}
      </Column>
      <Spacer />
      <EngagementDetailsInfoTabs engagementRef={engagement} />
      <FooterSpacer />
    </ScreenScroll>
  );
};

export default EngagementDetailsContent;
