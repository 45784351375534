import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import GigDistanceNotice from "@gigsmart/seibutsu/gig/ApplyGig/GigDistanceNotice";
import { useNavigation } from "@react-navigation/native";
import React, { type PropsWithChildren } from "react";
import type { gigDistanceCheckWrapperQuery } from "./__generated__/gigDistanceCheckWrapperQuery.graphql";

interface Props extends PropsWithChildren {
  id: string;
  isSeries: boolean;
}

export default createSuspendedQueryContainer<
  gigDistanceCheckWrapperQuery,
  Props
>(
  function GigDistanceCheckWrapper({ children, response }) {
    const navigation = useNavigation();
    const distance =
      response?.viewer?.gig?.distance ??
      response?.viewer?.gig?.workerDistance ??
      response?.viewer?.gigSeries?.gigDistance?.minDistance ??
      Number.POSITIVE_INFINITY;

    return UnlimitedDistanceFeature.isDisabled() && distance > 75 ? (
      <GigDistanceNotice onPress={() => navigation.goBack()} />
    ) : (
      <>{children}</>
    );
  },
  {
    query: graphql`
      query gigDistanceCheckWrapperQuery($id: ID!, $isSeries: Boolean!) {
        viewer {
          ... on Worker {
            gig(id: $id) @skip(if: $isSeries) {
              ... on Engagement {
                workerDistance
              }
              ... on AvailableGigsEdge {
                distance
              }
            }
            gigSeries(id: $id) @include(if: $isSeries) {
              gigDistance {
                minDistance
              }
            }
          }
        }
      }
    `,
    variables: ({ id }) => ({ id, isSeries: id?.startsWith("gigsr") })
  }
);
