import { escapeRegExp } from "lodash";
import { type KeyboardTypeOptions, Platform } from "react-native";

export const numericKeyboardType = (): KeyboardTypeOptions =>
  Platform.select({ default: "numeric", web: "default" });

interface TextLineOptions {
  min?: number;
  max?: number;
  charsPerLine?: number;
}

export function numberOfLinesForText(
  text: string,
  options?: TextLineOptions | null | undefined
) {
  const { min = 1, max = 5, charsPerLine = 70 } = options ?? {};
  if (min >= max) {
    console.warn(
      'Invalid configuration for helper "numberOfLinesForText"',
      options
    );
    return min;
  }

  const textLength = text.length;
  if (textLength < 1) return min;

  const lines = Math.ceil(textLength / charsPerLine);
  if (lines <= min) return min;
  if (lines >= max) return max;
  return lines;
}

export const splitLabel = (
  searchTerm: string | null | undefined,
  label: string
) => {
  const regEx = new RegExp(escapeRegExp(searchTerm ?? ""), "i");
  const replaceMask = (found: string) => `__${found}__`;
  return label.replace(regEx, replaceMask).split("__");
};
