/**
 * @generated SignedSource<<1f8989a860ab351de0547710b019dab1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerGigCard_availableGigSeriesEdge$data = {
  readonly estimatedPaymentRange: {
    readonly max: string;
    readonly min: string;
  } | null | undefined;
  readonly gigDistance: {
    readonly max: number;
    readonly min: number;
  } | null | undefined;
  readonly node: {
    readonly area: string | null | undefined;
    readonly availableGigs: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly endsAt: string | null | undefined;
          readonly insertedAt: string;
          readonly startsAt: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly endsAt: string | null | undefined;
    readonly id: string;
    readonly insertedAt: string;
    readonly name: string | null | undefined;
    readonly payRate: string | null | undefined;
    readonly position: {
      readonly name: string;
    } | null | undefined;
    readonly skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly skill: {
            readonly name: string;
          };
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
    readonly startsAt: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WorkerGigCard_availableGigSeriesEdge";
};
export type WorkerGigCard_availableGigSeriesEdge$key = {
  readonly " $data"?: WorkerGigCard_availableGigSeriesEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerGigCard_availableGigSeriesEdge">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
],
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerGigCard_availableGigSeriesEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MoneyRange",
      "kind": "LinkedField",
      "name": "estimatedPaymentRange",
      "plural": false,
      "selections": [
        {
          "alias": "min",
          "args": null,
          "kind": "ScalarField",
          "name": "minAmount",
          "storageKey": null
        },
        {
          "alias": "max",
          "args": null,
          "kind": "ScalarField",
          "name": "maxAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DistanceRange",
      "kind": "LinkedField",
      "name": "gigDistance",
      "plural": false,
      "selections": [
        {
          "alias": "max",
          "args": null,
          "kind": "ScalarField",
          "name": "maxDistance",
          "storageKey": null
        },
        {
          "alias": "min",
          "args": null,
          "kind": "ScalarField",
          "name": "minDistance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigSeries",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GigPosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "AvailableGigsConnection",
          "kind": "LinkedField",
          "name": "availableGigs",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailableGigsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "availableGigs(first:1)"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "GigSeriesSkillsConnection",
          "kind": "LinkedField",
          "name": "skills",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSeriesSkillsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigSeriesSkill",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "skill",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "skills(first:1)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailableGigSeriesEdge",
  "abstractKey": null
};
})();

(node as any).hash = "73837f8ee9fce20982d4431900ac0eff";

export default node;
