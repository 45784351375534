import { useEventer } from "@gigsmart/dekigoto";
import React, { useCallback } from "react";
import { TouchableOpacity } from "react-native";
import Card from "../container/card";
import { Flex } from "../flex";
import StyledIcon, { type IconName } from "../icon/styled-icon";
import { Spacer } from "../spacer";
import {
  type SchemeShape,
  type ViewStyleProp,
  fallbackScheme,
  getScheme,
  theme,
  useStyles
} from "../style";
import KatanaText from "../text/katana-text";
import StyledText from "../text/styled-text";

interface Props {
  onPress: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
  testID?: string;
  icon: IconName;
  iconSize?: number;
  text: string;
  style?: ViewStyleProp;
  textStyle?: ViewStyleProp;
  iconStyle?: ViewStyleProp;
  scheme?: SchemeShape;
  secondaryText?: string;
  tertiaryText?: string;
  iconVariant?: "light" | "solid" | "regular";
}

const tabButtonScheme = {
  ...fallbackScheme,
  disabled: {
    foreground: theme.color.neutralMedium,
    background: theme.color.white
  }
};

const TabButton = ({
  onPress,
  icon,
  iconSize = 24,
  iconStyle,
  isDisabled,
  isActive,
  style,
  testID,
  text,
  textStyle,
  secondaryText,
  iconVariant,
  tertiaryText,
  scheme = tabButtonScheme
}: Props) => {
  let variant = isActive ? "active" : "inactive";
  if (isDisabled && !isActive) variant = "disabled";
  const { foreground, background } = getScheme(variant, scheme) ?? {};
  const trackPress = useEventer("Pressed", text, "Button");
  const handlePress = useCallback(() => {
    if (isDisabled) {
      return;
    }
    trackPress();
    onPress?.();
  }, [isDisabled, onPress, trackPress]);
  const { styles } = useStyles(
    ({ font, unit, color }) => ({
      card: {
        marginVertical: 0,
        marginHorizontal: 0,
        backgroundColor: background
      },
      cardOutline: {
        borderColor: color.neutralMedium,
        borderWidth: 1
      },
      cardContent: {
        paddingTop: unit.padding.medium,
        alignItems: "center"
      },
      icon: {
        ...font.withSize(iconSize ?? font.size.large),
        marginBottom: unit.margin.medium,
        color: foreground
      },
      header: {
        color: foreground,
        fontSize: font.size.large,
        ...font.body("bold")
      }
    }),
    [background, foreground]
  );
  return (
    <Flex fill={1}>
      <Card
        style={[
          styles.card,
          (!isActive || isDisabled) && styles.cardOutline,
          style
        ]}
        shadow={false}
        testID={testID}
      >
        <TouchableOpacity style={styles.cardContent} onPress={handlePress}>
          <StyledIcon
            style={[styles.icon, iconStyle]}
            name={icon}
            color={foreground}
            size={iconSize}
            variant={iconVariant}
          />
          <KatanaText style={[styles.header, textStyle]}>{text}</KatanaText>
          {Boolean(secondaryText) && (
            <StyledText
              fontSize={theme.font.size.small}
              color={isActive ? "white" : undefined}
            >
              {secondaryText}
            </StyledText>
          )}
          <Spacer units={2} />
        </TouchableOpacity>
      </Card>
      {typeof tertiaryText === "string" && (
        <>
          <Spacer units={2} />
          <StyledText
            center
            bold={isActive}
            color={isActive ? theme.color.neutralDark : undefined}
            fontSize={theme.font.size.small}
          >
            {tertiaryText}
          </StyledText>
        </>
      )}
    </Flex>
  );
};

export default TabButton;
