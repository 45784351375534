import {
  FancyHeader,
  ScreenScroll,
  Spacer,
  Stack,
  Surface,
  Text,
  TimeRemaining
} from "@gigsmart/atorasu";
import { RemoveBenefitsMenu } from "@gigsmart/feature-flags";
import { Redirect } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import WorkerApplyFooter from "@gigsmart/seibutsu/gig/WorkerApplyFooter";
import RequesterInfoRow from "@gigsmart/seibutsu/requester/RequesterInfoRow";
import { WorkerBenefitsRow } from "@gigsmart/seibutsu/worker/WorkerBenefitsRow";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import React from "react";
import {
  GigDescription,
  GigRemainingTime,
  GigSummaryRows
} from "../gig/engagement-details";
import type { availableGigDetailAltQuery } from "./__generated__/availableGigDetailAltQuery.graphql";

interface Props {
  id: string;
}

export default createSuspendedQueryContainer<availableGigDetailAltQuery, Props>(
  function AvailableGigDetailAlt({ response: { viewer } = {} }) {
    if (viewer?.gig?.id) {
      // it's an engagement
      return <Redirect to={`/gigs/${viewer?.gig.id}`} replace />;
    }

    const availableGig = viewer?.gig;
    const gig = availableGig?.node;
    const isProject = gig?.gigType === "PROJECT";

    return (
      <ScreenScroll
        testID="available-gig-detail-screen"
        footer={!!gig && <WorkerApplyFooter fragmentRef={gig} />}
      >
        {gig?.pickup?.eligible ? (
          <Surface variant="flat">
            <FancyHeader
              variant="emphasized"
              color="emphasized"
              title={
                <Text weight="bold">
                  Shift Available Today (
                  <TimeRemaining
                    weight="bold"
                    size="lg"
                    showLargestOnly
                    date={gig?.pickup?.windowEnd}
                  />{" "}
                  left to pickup)
                </Text>
              }
              icon="alarm-clock"
            />
          </Surface>
        ) : (
          <GigRemainingTime
            variant="default"
            startsAt={gig?.startsAt}
            endsAt={gig?.endsAt}
          />
        )}
        <Stack>
          <RequesterInfoRow
            fragmentRef={gig}
            workerId={viewer?.id}
            showViewProfile={!isProject}
          />
          <GigDescription gigLikeRef={gig} isApplied />
          <GigSummaryRows availableGigRef={availableGig} />
          {RemoveBenefitsMenu.isDisabled() && <WorkerBenefitsRow />}
          {showWorkerBlockOrg(gig) && (
            <>
              <BlockOrganizationCollapsibleRow
                organizationId={gig?.organization?.id ?? ""}
              />
              <Spacer />
            </>
          )}
        </Stack>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query availableGigDetailAltQuery($gigId: ID!) {
        viewer {
          ... on Worker {
            id
            gig(id: $gigId) {
              ... on Engagement {
                id
              }
              ... on AvailableGigsEdge {
                ...ShiftDescriptionHeader_availableGig
                ...gigSummaryRows_availableGig
                node @trackImpressions(action: VIEWED) {
                  id
                  startsAt
                  endsAt
                  slots
                  payRate
                  gigType
                  pickup {
                    eligible
                    windowStart
                    windowEnd
                  }
                  organization {
                    id
                  }
                  ...gigDescription_gigLike
                  ...RequesterInfoRow_gigLike
                  ...WorkerApplyFooter_gigLike
                  ...BlockOrganizationCollapsibleRow_shift
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ id: gigId }) => ({ gigId })
  }
);
