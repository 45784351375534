import { DateTime } from "luxon";
import React from "react";

import {
  Button,
  CollapsibleList,
  CollapsibleWell,
  ContentArea,
  Divider,
  GridNull,
  IconText,
  Spacer,
  Stack,
  Text,
  Well
} from "@gigsmart/atorasu";
import { WorkerEngagementView } from "@gigsmart/feature-flags";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import HomeScreenCard from "../worker/HomescreenCard";

import type { MilitaryCard_Worker$key } from "./__generated__/MilitaryCard_Worker.graphql";

interface Props {
  isNewMilitaryExperienceAdded?: boolean;
  viewer?: "Requester" | "Worker";
}

export function MilitaryCard({
  militaryServices,
  isNewMilitaryExperienceAdded,
  viewer = "Worker"
}: FragmentContainerInnerComponentProps<MilitaryCard_Worker$key, Props>) {
  const history = useHistory();

  const formatDate = (v: string | null | undefined) => {
    if (!v) return "Current";

    return DateTime.fromISO(v).toFormat("MMMM yyyy");
  };

  const renderWorkerView = () => (
    <HomeScreenCard
      headerTitle="Military"
      headerIcon="medal"
      testID="profile-military-card"
    >
      <Stack>
        {militaryServices?.edges && (
          <CollapsibleList
            items={militaryServices.edges?.map((service) => ({
              title: service?.node?.branch ?? "",
              content: (
                <>
                  {!!service?.node?.militaryIdLast4?.length && (
                    <Text>
                      Military ID Number:{" "}
                      {`*****${service?.node?.militaryIdLast4}`}
                    </Text>
                  )}
                  <Text>
                    {formatDate(service?.node?.serviceStart)} -{" "}
                    {formatDate(service?.node?.serviceEnd)}
                  </Text>
                </>
              ),
              branch: service?.node?.branch,
              id: service?.node?.id
            }))}
            startCollapsed={!isNewMilitaryExperienceAdded}
            iconName="pen-to-square"
            onPress={({ id }) => history.push(`/profile/military/edit/${id}`)}
            placeholder="Adding your military background can increase your chances of finding the right Shifts and being selected."
            testID="military-card-collapsible-list"
          />
        )}
        <Button
          label="Add Military"
          icon="plus"
          variant="clear"
          outline
          size="large"
          testID="add-military-button"
          onPress={() => history.push("/profile/military/add")}
        />
      </Stack>
    </HomeScreenCard>
  );

  const renderRequesterView = () => {
    if (!militaryServices?.edges?.length) {
      return <GridNull />;
    }

    const totalCount = militaryServices?.edges?.length ?? 0;
    return WorkerEngagementView.select(
      <CollapsibleWell
        testID="worker-profile-military"
        headerIcon="medal"
        headerText="Military"
        items={militaryServices?.edges?.map((service, index) => (
          <>
            <Text variant="subheader" color="black">
              {service?.node?.branch}
            </Text>
            <Text>
              {formatDate(service?.node?.serviceStart)} -{" "}
              {formatDate(service?.node?.serviceEnd)}
            </Text>
            {index < totalCount - 1 && (
              <>
                <Spacer size="compact" />
                <Divider />
              </>
            )}
          </>
        ))}
      />,
      <Stack size="medium">
        <Well color="black" size="compact">
          <IconText
            icon="medal"
            spacing="medium"
            textWeight="bold"
            size="small"
          >
            Military
          </IconText>
        </Well>
        <ContentArea size="none">
          {militaryServices?.edges && (
            <CollapsibleList
              items={militaryServices?.edges?.map((service) => ({
                title: service?.node?.branch ?? "",
                content: (
                  <Text>
                    {formatDate(service?.node?.serviceStart)} -{" "}
                    {formatDate(service?.node?.serviceEnd)}
                  </Text>
                )
              }))}
              iconName="pen-to-square"
              testID="requester-worker-work-military-row"
              startCollapsed={false}
              allowCollapse={false}
            />
          )}
        </ContentArea>
      </Stack>
    );
  };
  return viewer === "Requester" ? renderRequesterView() : renderWorkerView();
}

export default createRelayFragmentContainer<MilitaryCard_Worker$key, Props>(
  graphql`
    fragment MilitaryCard_Worker on Worker
    @argumentDefinitions(
      skipIfNotWorker: { type: "Boolean", defaultValue: false }
    ) {
      militaryServices(
        first: 30
        query: "ORDER BY serviceEnd DESC NULLS FIRST, serviceStart DESC NULLS FIRST"
      ) {
        totalCount
        edges {
          node {
            id
            branch
            rank
            serviceStart
            serviceEnd
            militaryIdLast4 @skip(if: $skipIfNotWorker)
          }
        }
      }
    }
  `,
  MilitaryCard
);
