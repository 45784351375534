import { type FormFieldOptions, useFormToggle } from "@gigsmart/fomu";
import { StyledCheckbox } from "@gigsmart/katana";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React from "react";

type RefinedStyledPickerProps = Omit<
  ComponentPropsWithDefaults<typeof StyledCheckbox>,
  "onSubmitEditing" | "value" | "onChangeText" | "errors"
>;

type Props = RefinedStyledPickerProps & FormFieldOptions<any>;

export function FomuStyledCheckbox({ name, validates, ...props }: Props) {
  const { toggle, value, errors } = useFormToggle({
    name,
    validates
  });

  return (
    <StyledCheckbox
      checked={value}
      onPress={toggle}
      errors={errors || []}
      {...props}
    />
  );
}
