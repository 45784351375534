import { useHistoryBlock } from "@gigsmart/atorasu";
import { EventContext, useEventer } from "@gigsmart/dekigoto";
import {
  type AppNavigationProp,
  CommonActions,
  NavPortalEntrance,
  safeExit,
  useNavigation,
  useRoute
} from "@gigsmart/kaizoku";
import { useInstrument } from "@gigsmart/pickle/support/utils/instrumentation-client";
import isEmpty from "lodash/isEmpty";
import React, {
  type ComponentProps,
  type ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { View } from "react-native";
import { useStyles } from "../style";
import type StepperHeader from "./stepper-header";

export interface StepProps<T = any> {
  hasNext: boolean;
  hasPrev: boolean;
  completionPercentage: number;
  nextStep: (data?: T) => void;
  prevStep: (data?: T) => void;
  gotoStep: (stepNum: number, data: T) => void;
  gotoStepByName: (stepName: string, data?: T | null | undefined) => void;
  stepName: string;
  updateData: (data: Partial<T>) => void;
  stepNumber: number;
  stepsLength: number;
  stepData: T;
  testID: string;
  initialData: T;
  queryParam: string;
  parentStep: StepProps<any> | null | undefined;
  unblock: () => void;
  fill?: number;
}
type StepComponent<T> = ComponentType<StepProps<T>>;
type StepperHeaderComponent = ComponentType<
  ComponentProps<typeof StepperHeader>
>;
export interface StepSpec<T> {
  render: StepComponent<T>;
  name: string;
  disabled?: boolean | ((data: T) => boolean);
  testID?: string;
  onEnterStep?: () => void;
}
export type StepList<T> = Array<StepSpec<T>>;
function normalizeStep<T>(step: Step<T> | null | undefined): StepSpec<T> {
  if (!step) return { render: () => null, name: "", disabled: true };
  if (typeof step === "function") return { render: step, name: "" };
  return { ...step };
}
const SteperContext = createContext<StepProps<any> | null | undefined>(null);
export type Step<T = any> = StepSpec<T> | StepComponent<T>;
export type StepperProps<T> = ComponentProps<typeof View> & {
  testID?: string;
  cancelMessage: string;
  outOfSequence: boolean;
  onComplete: (arg0: T) => unknown;
  onData?: (arg0: T) => void;
  eventContext: string | null;
  steps: Array<Step<T>>;
  instrumentDeserializer: (arg0: any) => T;
  renderHeader?: StepperHeaderComponent;
  completionOffset: number;
  initialData: T;
  initialStep?: number;
  allowedSteps?: Set<number>;
  fill?: number;
  historyBlock?: boolean;
};

/** @deprecated use from atorasu */
export default function Stepper<TStepData>({
  steps,
  initialData,
  initialStep = 1,
  cancelMessage,
  renderHeader: Header,
  onComplete,
  onData,
  testID,
  instrumentDeserializer,
  eventContext,
  outOfSequence,
  completionOffset,
  allowedSteps: initialAllowedSteps,
  historyBlock = true,
  fill,
  ...props
}: StepperProps<TStepData>) {
  const { styles } = useStyles(
    () => ({
      flex: {
        flex: fill
      }
    }),
    [fill]
  );
  // eventers
  const parentStep = useContext(SteperContext);
  const queryParams: { step: string } | undefined = useRoute<any>().params;
  const navigation = useNavigation<AppNavigationProp<any>>();

  const [allowedSteps, setAllowedSteps] = useState<Set<number>>(
    initialAllowedSteps ?? new Set([1])
  );
  const trackIncrease = useEventer("Increased", testID ?? "Unknown", "Stepper");
  const trackDecrease = useEventer("Decreased", testID ?? "Unknown", "Stepper");
  const trackSet = useEventer("Set", testID ?? "Unknown", "Stepper");
  // track the current step
  const [data, setNewData] = useState<TStepData>(initialData);
  const [dirty, setDirty] = useState(false);
  const [navBlocked, setNavBlocked] = useState(false);
  const updateData = useCallback(
    (nextData?: Partial<TStepData> | null | undefined) => {
      const finalNextData = { ...data, ...nextData };
      onData?.(finalNextData);
      setNewData(finalNextData);
      if (!dirty) {
        setNavBlocked(true);
        if (!isEmpty(finalNextData)) {
          setDirty(true);
        }
      }
      return finalNextData;
    },
    [data, dirty, onData, setNavBlocked]
  );
  const didComplete = useRef(false);

  // normalize the steps
  const normalizedSteps = steps
    .map<StepSpec<TStepData>>(normalizeStep)
    .filter((step) => {
      if (typeof step.disabled === "function") return !step.disabled(data);
      return !step.disabled;
    });
  // extract the step information
  const { currentStepNumber, queryParam } = useMemo(() => {
    const param = String(queryParams?.step);
    const parts = param.split(".");
    let nextParentStep = parentStep;
    let expectedPosition = 0;
    while (nextParentStep) {
      expectedPosition++;
      nextParentStep = nextParentStep.parentStep;
    }
    return {
      currentStepNumber: Number(parts[expectedPosition]),
      queryParam: parts.slice(0, expectedPosition + 1).join(".")
    };
  }, [parentStep, queryParams?.step]);
  const currentStep = currentStepNumber
    ? normalizedSteps[currentStepNumber - 1]
    : null;
  // Properties
  const hasPrev = currentStepNumber > 1;
  const hasNext = normalizedSteps.length > currentStepNumber;
  // Block History
  const unblock = useHistoryBlock({
    enabled: historyBlock && navBlocked && dirty
  });

  const handleComplete = useCallback(
    (data: TStepData) => {
      if (!didComplete.current) {
        didComplete.current = true;
        unblock();
        onComplete(data);
      }
    },
    [onComplete]
  );

  // go to the next step, or complete if there are no more
  const gotoStep = useCallback(
    (stepNumber: any, nextData?: TStepData | null | undefined) => {
      const finalNextData = updateData(nextData);
      if (stepNumber > normalizedSteps.length) {
        unblock();
        onComplete(finalNextData);
        return;
      }
      const nextStepNumber = stepNumber > 0 ? stepNumber : 1;
      const stepParam = parentStep
        ? [parentStep.queryParam, nextStepNumber].join(".")
        : nextStepNumber;

      trackSet({
        stepNumber: nextStepNumber,
        stepName: steps[nextStepNumber]?.name
      });

      navigation.dispatch(
        safeExit(CommonActions.setParams({ step: stepParam }))
      );
    },
    [
      navigation,
      normalizedSteps.length,
      onComplete,
      parentStep,
      steps,
      trackSet,
      updateData
    ]
  );
  const prevStep = useCallback(
    (nextData: any) => {
      const nextStepNumber = currentStepNumber - 1;
      trackDecrease({
        stepNumber: nextStepNumber,
        stepName: steps[nextStepNumber]?.name
      });
      gotoStep(nextStepNumber, nextData);
      return true;
    },
    [currentStepNumber, gotoStep, trackDecrease, steps]
  );
  const nextStep = useCallback(
    (nextData: any) => {
      const nextStepNumber = currentStepNumber + 1;
      trackIncrease({
        stepNumber: nextStepNumber,
        stepName: steps[nextStepNumber]?.name
      });

      // when going to the next step, always unlock the nav
      setNavBlocked(false);
      gotoStep(nextStepNumber, nextData);
    },
    [currentStepNumber, gotoStep, trackIncrease, steps]
  );
  useEffect(() => {
    const inSequence =
      currentStepNumber === 1 || allowedSteps.has(currentStepNumber - 1);
    const allowedSequence = outOfSequence || inSequence;
    if (normalizedSteps.length === 0) {
      handleComplete(data);
    } else if (!currentStepNumber) {
      // Goto initial step
      gotoStep(initialStep, null);
    } else if (!allowedSequence) {
      // Jump back to the last allowed step
      gotoStep([...allowedSteps].sort((a, b) => a - b).reverse()[0], null);
    } else if (!allowedSteps.has(currentStepNumber)) {
      // Allow the current step
      setAllowedSteps(new Set([...allowedSteps, currentStepNumber]));
    } else if (currentStep) {
      // onEnterStep callback
      currentStep?.onEnterStep?.();
    } else {
      // Complete
      setNavBlocked(false);
      handleComplete(data);
    }
  }, [
    allowedSteps,
    currentStep,
    currentStepNumber,
    data,
    gotoStep,
    initialStep,
    handleComplete,
    normalizedSteps.length,
    outOfSequence,
    queryParams,
    setNavBlocked
  ]);
  const {
    render: CurrentStepComponent,
    name: currentStepName,
    testID: currentStepTestID = testID
      ? `${testID}-step-${currentStepNumber}`
      : `step-${currentStepNumber}`
  }: Partial<StepSpec<TStepData>> = currentStep ?? {};
  const gotoStepByName = (
    stepName: string,
    nextData?: TStepData | null | undefined
  ) => {
    const index = normalizedSteps.findIndex(({ name }) => name === stepName);
    gotoStep(index + 1, nextData);
  };
  // Goto a step by name
  const gotoStepByTestID = (testID: string, nextData = data) => {
    const index = normalizedSteps.findIndex((step) => step.testID === testID);
    gotoStep(index + 1, nextData);
  };
  // Instruments
  useInstrument("nextStep", testID, (nextData) =>
    nextStep(instrumentDeserializer(nextData))
  );
  useInstrument("prevStep", testID, (nextData) =>
    prevStep(instrumentDeserializer(nextData))
  );
  useInstrument(
    "gotoStep",
    testID,
    ({
      number,
      name: targetName,
      testID: targetTestID,
      data: instrumentData
    }) => {
      const nextData = instrumentDeserializer(instrumentData);
      if (targetTestID) {
        gotoStepByTestID(targetTestID, nextData);
        return;
      }
      if (targetName) {
        gotoStepByName(targetName, nextData);
        return;
      }
      if (number) {
        gotoStep(number, nextData);
      }
    }
  );
  useInstrument("hasPrevStep", testID, () => hasPrev);
  useInstrument("hasNextStep", testID, () => hasNext);
  // Prop
  const currentStepProp: { name: string; number: number } = {
    name: currentStepName ?? "",
    number: currentStepNumber
  };
  if (!currentStep) return null;
  const completionPercentage = Math.round(
    ((allowedSteps.size + completionOffset) /
      (normalizedSteps.length + completionOffset)) *
      100
  );
  const stepProps: StepProps<TStepData> = {
    testID: currentStepTestID,
    prevStep,
    nextStep,
    updateData,
    parentStep,
    queryParam,
    stepName: currentStepName ?? "",
    stepNumber: currentStepNumber,
    stepsLength: steps.length,
    stepData: data,
    completionPercentage,
    gotoStep,
    gotoStepByName,
    hasNext,
    hasPrev,
    initialData,
    unblock: () => setNavBlocked(false)
  };

  // render the header and current step
  return CurrentStepComponent ? (
    <EventContext name={eventContext}>
      <EventContext name={currentStep.name}>
        {hasPrev && <NavPortalEntrance onBackPress={() => prevStep(data)} />}
        <View
          testID={testID}
          style={fill && fill !== 0 ? styles.flex : undefined}
          {...props}
        >
          {!!Header && (
            <Header
              steps={normalizedSteps.map(({ name }, index) => ({
                name,
                number: index + 1
              }))}
              stepData={data}
              currentStep={currentStepProp}
              gotoStep={gotoStep}
            />
          )}
          <SteperContext.Provider value={stepProps}>
            <CurrentStepComponent
              key={`${currentStepNumber}-${currentStepName ?? ""}`}
              {...stepProps}
            />
          </SteperContext.Provider>
        </View>
      </EventContext>
    </EventContext>
  ) : null;
}
Stepper.defaultProps = {
  cancelMessage:
    "Are you sure you want to Cancel? Your information will not be saved.",
  initialData: {},
  instrumentDeserializer: (d: any) => d,
  outOfSequence: process.env.IS_TESTING === "true",
  completionOffset: 0
};
