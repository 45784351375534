import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { graphql, useFetchQueryResult } from "@gigsmart/relay";
import { useUserHasPendingConsents } from "@gigsmart/seibutsu/user-consent/UserDocumentConsentCheck";
import { nextStepName } from "../onboarding/WorkerOnboardingScreen";
import type { onboardingCheckWorkerQuery } from "./__generated__/onboardingCheckWorkerQuery.graphql";

export function useOnboardingCheck() {
  const [result] = useFetchQueryResult<onboardingCheckWorkerQuery>(
    graphql`
      query onboardingCheckWorkerQuery {
        viewer {
          ... on Worker {
            ...WorkerOnboardingScreen_worker
          }
        }
      }
    `,
    {}
  );

  const user = useCurrentUser();
  const hasFinishedOnboarding =
    !!user?.id && !nextStepName(user, result?.viewer);
  const userHasPendingConsents = useUserHasPendingConsents();

  return {
    hasFinishedOnboarding,
    appFullyReady: hasFinishedOnboarding && !userHasPendingConsents
  };
}
