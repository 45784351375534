/**
 * @generated SignedSource<<2c89f306e1df4fca27b60233a2d1421e>>
 * @relayHash 62efc47145ed0496d6b336e8284ff31d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ou-8iAL462zBaq2EvHvm9b-3nlQ_09Dorq_7GQydfHk

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AsyncNegotiateEngagementInput = {
  engagementId: string;
  payRate: string;
};
export type EngagementExtrasNegotiateMutation$variables = {
  input: AsyncNegotiateEngagementInput;
};
export type EngagementExtrasNegotiateMutation$data = {
  readonly asyncNegotiateEngagement: {
    readonly " $fragmentSpreads": FragmentRefs<"useRelayCommand_payload">;
  } | null | undefined;
};
export type EngagementExtrasNegotiateMutation = {
  response: EngagementExtrasNegotiateMutation$data;
  variables: EngagementExtrasNegotiateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correlationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngagementExtrasNegotiateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonOperationPayload",
        "kind": "LinkedField",
        "name": "asyncNegotiateEngagement",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "useRelayCommand_payload",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngagementExtrasNegotiateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommonOperationPayload",
        "kind": "LinkedField",
        "name": "asyncNegotiateEngagement",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ou-8iAL462zBaq2EvHvm9b-3nlQ_09Dorq_7GQydfHk",
    "metadata": {},
    "name": "EngagementExtrasNegotiateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fa14e3a51ebd996e1649951b1cd7aa73";

export default node;
