interface Props {
  format: string;
  filename: string;
  body: string;
}

export default function LegalUnsupported({ filename, format, body }: Props) {
  throw new Error(
    `Unsupported document format: \`${format}\` for \`${filename}\`: \`${body}\``
  );
  // biome-ignore lint/correctness/noUnreachable: <explanation>
  return null;
}
