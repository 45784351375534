import {
  Button,
  Checkbox,
  Column,
  ContentArea,
  Row,
  ScreenScroll,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import NavBackButton from "@gigsmart/isomorphic-shared/nav/nav-back-button";
import type { StepProps } from "@gigsmart/katana";
import React, { useState } from "react";
import StepHeader from "../../shared/Stepper/StepHeader";
import type { StepPropsType } from "./types";

interface Props extends StepProps<StepPropsType> {
  onNextStep: (
    disputeTimesheet: boolean,
    disputeAdditionalPayment: boolean
  ) => void;
}

export default function DisputeTypeStep({
  onNextStep,
  stepData,
  prevStep
}: Props) {
  const [disputeTimesheet, setDisputeTimesheet] = useState(
    stepData.disputeTimesheet ?? false
  );
  const [disputeAdditionalPayment, setDisputeAdditionalPayment] = useState(
    stepData.disputeAdditionalPayment ?? false
  );
  return (
    <ScreenScroll testID="dispute-type-step">
      <Column fill justifyContent="space-between">
        <Column>
          <NavBackButton onGoBack={prevStep} />
          <StepHeader
            name="Dispute Type"
            note="Please Select a Dispute Type below."
          />
          <Spacer />
          <Surface>
            <ContentArea>
              <Stack>
                <Text weight="semibold">
                  How would you like to resolve your Pay Dispute?
                </Text>
                <Stack size="compact">
                  <Surface variant="outline">
                    <ContentArea>
                      <Stack horizontal alignItems="center">
                        <Column fill>
                          <Text>
                            My hourly pay for my time worked is not correct and
                            I would like to submit a{" "}
                            <Text weight="semibold">Dispute Timesheet</Text>
                          </Text>
                        </Column>
                        <Checkbox
                          testID="timesheet-checkbox"
                          selected={disputeTimesheet}
                          onChange={(selected) => setDisputeTimesheet(selected)}
                        />
                      </Stack>
                    </ContentArea>
                  </Surface>
                  <Surface variant="outline">
                    <ContentArea>
                      <Row alignItems="center">
                        <Column fill>
                          <Text>
                            My pay for tips or reimbursement is not correct and
                            I would like to submit a request for{" "}
                            <Text weight="semibold">Additional Payment</Text>
                          </Text>
                        </Column>
                        <Checkbox
                          testID="additional-payment-checkbox"
                          selected={disputeAdditionalPayment}
                          onChange={(selected) =>
                            setDisputeAdditionalPayment(selected)
                          }
                        />
                      </Row>
                    </ContentArea>
                  </Surface>
                </Stack>
                <Text align="center" italic>
                  Select all that apply.
                </Text>
              </Stack>
            </ContentArea>
          </Surface>
        </Column>
        <ContentArea size="none">
          <Spacer />
          <Button
            testID="save-changes-btnDisp"
            label="Save Changes"
            onPress={() =>
              onNextStep(disputeTimesheet, disputeAdditionalPayment)
            }
            disabled={!disputeTimesheet && !disputeAdditionalPayment}
          />
        </ContentArea>
      </Column>
    </ScreenScroll>
  );
}
