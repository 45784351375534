import type { AppScreenProps } from "@gigsmart/kaizoku";
import { useRelayOrchestrator } from "@gigsmart/relay";
import { useEffect, useState } from "react";
import type { AuthParamList } from "./types";

type Props = AppScreenProps<AuthParamList, "Token">;

export default function TokenScreen({ route, navigation }: Props) {
  const orchestrator = useRelayOrchestrator();
  const [mounted, setMounted] = useState(false);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    setMounted(true);
    const tokenParam = route.params.token ?? null;
    setToken(tokenParam);
  }, []);

  useEffect(() => {
    if (!token || !mounted) return;
    navigation.setParams({ token: "" });
    orchestrator.reset(token).then(() => {
      navigation.replace("Home");
    });
  }, [mounted, token]);
  return null;
}
