import { usePermission } from "@gigsmart/atorasu";
import type { NotificationAlike } from "@gigsmart/isomorphic-shared/app/notifications";
import {
  useCurrentDevice,
  useCurrentUser
} from "@gigsmart/isomorphic-shared/current-user";
import { graphql, useRelaySubscription } from "@gigsmart/relay";
import { useMemo } from "react";
import type { subscriptionListenerSubscription } from "./__generated__/subscriptionListenerSubscription.graphql";

interface Props {
  enabled: boolean;
  onNotification: (
    notification: NotificationAlike,
    fromBackground: boolean
  ) => Promise<void>;
}

export default ({ enabled, onNotification }: Props) => {
  const user = useCurrentUser();
  const device = useCurrentDevice();
  const notificationsPermission = usePermission("notification", {
    trace: "SubscriptionListener"
  });
  const subscribe = useMemo<boolean>(
    () =>
      (enabled && process.env.IS_TESTING === "true") ||
      !notificationsPermission.has ||
      !device?.pushRegistrationToken,
    [device?.pushRegistrationToken, notificationsPermission.has]
  );
  useRelaySubscription<subscriptionListenerSubscription>(
    graphql`
      subscription subscriptionListenerSubscription {
        messageAdded {
          newMessageEdge {
            node {
              author {
                id
                firstName
                lastInitial
                profilePhoto {
                  url
                }
              }
              body
              id
              messageType
              expiresAt
              title
              data
              topicId
              receipt {
                viewedAt
                sentAt
              }
            }
          }
        }
      }
    `,
    {},
    {
      onNext: (payload) => {
        const {
          data: rawData,
          title,
          body,
          author,
          id: notificationId,
          receipt
        } = payload?.messageAdded?.newMessageEdge?.node ?? {};

        if (author?.id !== user?.id) {
          const data: Record<string, string> = JSON.parse(rawData ?? "{}");
          void onNotification(
            {
              notificationId,
              title,
              body,
              data,
              alreadyViewed: !!receipt?.viewedAt
            },
            false
          );
        }
      },
      subscribe
    }
  );
  return null;
};
