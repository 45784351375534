import { useIsFocused } from "@gigsmart/kaizoku";
import { useEffect, useRef } from "react";
import type { UseQueryLoaderLoadQueryOptions } from "react-relay";

export default function useRetryOnFocus(
  retryFn: (options?: UseQueryLoaderLoadQueryOptions) => void,
  options?: UseQueryLoaderLoadQueryOptions
) {
  const firstRun = useRef(true);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused || firstRun.current) {
      if (isFocused) firstRun.current = false;
      return;
    }

    retryFn(options);
  }, [isFocused]);
}
