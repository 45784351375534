import { useEffect, useState } from "react";
import type { ListenerMap } from "./RNOnfleetSDK";
import { onfleet } from "./RNOnfleetService";

export function useOnfleetDriver() {
  return useObservedValue("driver", onfleet.driver);
}

export function useOnfleetOrganization() {
  return useObservedValue("organization", onfleet.organization);
}

function useObservedValue<TEvent extends keyof ListenerMap>(
  event: TEvent,
  initialValue?: ListenerMap[TEvent] | null
) {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    const listener = onfleet.on(event, setValue);
    return () => listener.remove();
  }, [event]);
  return value;
}
