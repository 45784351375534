/**
 * @generated SignedSource<<63a3708969c04416a40f8d6d5f70d311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobBoardPreview_root$data = {
  readonly affiliateJobPostings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly externalId: string;
        readonly maximumSalary: string | null | undefined;
        readonly minimumSalary: string | null | undefined;
        readonly name: string | null | undefined;
        readonly salaryInterval: string | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly viewer: {
    readonly availableJobPostings?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly boosted: boolean | null | undefined;
          readonly id: string;
          readonly maximumPayRate: string | null | undefined;
          readonly minimumPayRate: string | null | undefined;
          readonly payRateNegotiable: boolean | null | undefined;
          readonly paySchedule: JobPostingPaySchedule | null | undefined;
          readonly title: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "JobBoardPreview_root";
};
export type JobBoardPreview_root$key = {
  readonly " $data"?: JobBoardPreview_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobBoardPreview_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 3
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "affiliateJobPostSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jobPostSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobBoardPreview_root",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "affiliateJobPostSearch"
        }
      ],
      "concreteType": "AffiliateJobPostingsConnection",
      "kind": "LinkedField",
      "name": "affiliateJobPostings",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AffiliateJobPostingsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AffiliateJobPosting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maximumSalary",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minimumSalary",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "salaryInterval",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Variable",
                  "name": "search",
                  "variableName": "jobPostSearch"
                }
              ],
              "concreteType": "AvailableJobPostingsConnection",
              "kind": "LinkedField",
              "name": "availableJobPostings",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AvailableJobPostingsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "JobPosting",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "boosted",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "paySchedule",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "payRateNegotiable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "maximumPayRate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "minimumPayRate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Worker",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RootQueryType",
  "abstractKey": null
};
})();

(node as any).hash = "51538481678ef081c79efc2aa7b93ee0";

export default node;
