import {
  ContentArea,
  ContentRow,
  HighlightedReminder,
  Row,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import type { ValueObject } from "@gigsmart/fomu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { graphql } from "@gigsmart/relay";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import { Duration } from "luxon";
import React, { useMemo } from "react";
import { getDateTimeFromInputValues } from "../../engagement/timesheetEditHelpers";
import type { DisputedTimesheetProjectionsSurface_Engagement$data } from "./__generated__/DisputedTimesheetProjectionsSurface_Engagement.graphql";

interface Props {
  breaks: number[];
  initialBreaks: number[];
  errors?: Error[] | null;
  initialStates?: ValueObject;
  updatedStates?: ValueObject;
  payRate?: string | null;
  fees?: ObjectUtils.Path<
    DisputedTimesheetProjectionsSurface_Engagement$data,
    ["paymentInfo", "serviceFees"]
  >;
  setTimeDiff?: (val: number) => void;
  paymentStyle?: string;
  totalDurationWorked?: string | null;
}

export default function DisputedTimesheetProjectionsSurface({
  initialStates: initial,
  updatedStates: updated,
  payRate,
  errors,
  breaks,
  initialBreaks,
  fees,
  setTimeDiff,
  paymentStyle,
  totalDurationWorked
}: Props) {
  const timeDiff = useMemo(() => {
    if (
      (errors?.length === 0 ||
        errors?.every((error) =>
          error?.message?.includes("character count")
        )) &&
      updated
    ) {
      const initialStart = getDateTimeFromInputValues(
        initial?.startTimeTime,
        initial?.startTimeAmpm,
        initial?.startTimeDate
      );
      const initialEnd = getDateTimeFromInputValues(
        initial?.endTimeTime,
        initial?.endTimeAmpm,
        initial?.endTimeDate
      );
      const initialDurationWithoutBreaks = initialEnd.diff(initialStart);
      let initialDuration = initialDurationWithoutBreaks;
      initialBreaks.forEach((entry) => {
        const breakStart = getDateTimeFromInputValues(
          initial?.[`break${entry}StartTime`],
          initial?.[`break${entry}StartAmpm`],
          initial?.[`break${entry}StartDate`]
        );
        const breakEnd = getDateTimeFromInputValues(
          initial?.[`break${entry}EndTime`],
          initial?.[`break${entry}EndAmpm`],
          initial?.[`break${entry}EndDate`]
        );
        const breakDuration = breakEnd.diff(breakStart);
        initialDuration = initialDuration.minus(breakDuration);
      });
      if (
        (paymentStyle === "FIXED_AMOUNT" || paymentStyle === "FIXED_HOURS") &&
        totalDurationWorked
      ) {
        initialDuration = Duration.fromISO(totalDurationWorked);
      }
      const updatedStart = getDateTimeFromInputValues(
        updated.startTimeTime,
        updated.startTimeAmpm,
        updated.startTimeDate
      );
      const updatedEnd = getDateTimeFromInputValues(
        updated.endTimeTime,
        updated.endTimeAmpm,
        updated.endTimeDate
      );
      const updatedDurationWithoutBreaks = updatedEnd.diff(updatedStart);
      let updatedDuration = updatedDurationWithoutBreaks;
      breaks.forEach((entry) => {
        const breakStart = getDateTimeFromInputValues(
          updated?.[`break${entry}StartTime`],
          updated?.[`break${entry}StartAmpm`],
          updated?.[`break${entry}StartDate`]
        );
        const breakEnd = getDateTimeFromInputValues(
          updated?.[`break${entry}EndTime`],
          updated?.[`break${entry}EndAmpm`],
          updated?.[`break${entry}EndDate`]
        );
        const breakDuration = breakEnd.diff(breakStart);
        updatedDuration = updatedDuration.minus(breakDuration);
      });
      const timeDiff = updatedDuration.minus(initialDuration);
      setTimeDiff?.(timeDiff.as("milliseconds"));
      return timeDiff.shiftTo("hours", "minutes");
    }
    setTimeDiff?.(0);
    return Duration.fromObject({ milliseconds: 0 }).shiftTo("hours", "minutes");
  }, [initial, updated, initialBreaks, breaks, errors?.length]);
  const payDiff = useMemo(() => {
    const hourlyRate = Number(payRate?.replace(" USD", ""));
    const feeRate = Number(fees?.[0]?.hourlyRate?.replace(" USD", ""));
    if (hourlyRate) {
      if (feeRate) {
        return `$${Math.abs(
          currency.roundDown(
            timeDiff.as("hours") * hourlyRate - timeDiff.as("hours") * feeRate,
            2
          )
        ).toFixed(2)}`;
      }
      return `$${Math.abs(
        currency.roundDown(timeDiff.as("hours") * hourlyRate, 2)
      ).toFixed(2)}`;
    }
    return "$0.00";
  }, [timeDiff, payRate, fees]);
  const isPositive = timeDiff.as("milliseconds") > 0;
  const isNegative = timeDiff.as("milliseconds") < 0;
  return (
    <Stack>
      <Surface>
        <Stack variant="divider">
          <ContentRow>
            <Row fill justifyContent="space-between" alignItems="center">
              <Stack size="slim">
                <Text>Additional Time Worked</Text>
                <Text variant="note" color="neutral">
                  added via Dispute Timesheet
                </Text>
              </Stack>
              <Text
                weight={isPositive ? "semibold" : undefined}
                color={isPositive ? "success" : isNegative ? "danger" : "black"}
              >
                {isNegative ? "-" : isPositive ? "+" : ""}{" "}
                {Math.abs(Math.floor(timeDiff.hours))}h{" "}
                {Math.abs(Math.floor(timeDiff.minutes))}min
              </Text>
            </Row>
          </ContentRow>
          <ContentRow>
            <Row fill justifyContent="space-between" alignItems="center">
              <Stack size="slim">
                <Text>Additional Total Earnings</Text>
                <Text variant="note" color="neutral">
                  ({isNegative ? "-" : isPositive ? "+" : ""}
                  {Math.abs(currency.roundDown(timeDiff.as("hours"), 2))}hr x{" "}
                  {currency.humanizeRate(payRate)}){" "}
                  {fees?.length === 1 && (
                    <Text>
                      - {currency.humanizeRate(fees?.[0]?.hourlyRate)} T&S Fee
                    </Text>
                  )}
                </Text>
              </Stack>
              <Text
                weight={isPositive ? "semibold" : undefined}
                color={isPositive ? "success" : isNegative ? "danger" : "black"}
              >
                {isPositive ? "+" : isNegative ? "-" : ""} {payDiff}
              </Text>
            </Row>
          </ContentRow>
        </Stack>
      </Surface>
      {isNegative && (
        <ContentArea size="none">
          <HighlightedReminder
            icon="circle-exclamation"
            variant="danger"
            header="Total Time Worked in the Disputed Timesheet cannot be less than your approved Total Time Worked."
          />
        </ContentArea>
      )}
    </Stack>
  );
}

graphql`
  fragment DisputedTimesheetProjectionsSurface_Engagement on Engagement {
    paymentInfo {
      payRate
      serviceFees {
        hourlyRate
        amount
        feeType
      }
    }
  }
`;
