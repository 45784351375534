import {
  ContentArea,
  Date as DateComp,
  IncrementingMoneyInput,
  Spacer,
  Surface,
  Text,
  Time as TimeComp
} from "@gigsmart/atorasu";
import { Validator, useFormField } from "@gigsmart/fomu";
import { formatPayRange } from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import React from "react";

type Props = {
  name: string;
  minPayRate?: string;
  numEntries?: number;
  basePayRate?: string | null;
  baseEstimatedPayment?:
    | number
    | string
    | { min: number | string; max: number | string }
    | null;
  entry?: {
    startsAt?: string | null;
    endsAt?: string | null;
    actualStartsAt?: string | null;
    insertedAt?: string | null;
  } | null;
  label?: string;
};

export function HourlyRateInput({
  name,
  minPayRate,
  basePayRate,
  baseEstimatedPayment,
  numEntries = 1,
  entry,
  label
}: Props) {
  if (
    !baseEstimatedPayment ||
    typeof baseEstimatedPayment === "number" ||
    typeof baseEstimatedPayment === "string"
  ) {
    baseEstimatedPayment = {
      min: baseEstimatedPayment ?? 0,
      max: baseEstimatedPayment ?? 0
    };
  }

  const minValue = currency.toFloat(minPayRate);
  const { value, setValue, showErrors, errors, initialValue, invalid } =
    useFormField<string>({
      name,
      validates: Validator.numberBetween({
        min: minValue,
        max: 500
      })
    });

  const rate = currency.toFloat(value) / currency.toFloat(basePayRate);
  const payRange = invalid
    ? "-"
    : formatPayRange(
        {
          min: currency.toFloat(baseEstimatedPayment.min) * rate,
          max: currency.toFloat(baseEstimatedPayment.max) * rate
        },
        { short: false }
      );

  return (
    <Surface variant="outline">
      <Spacer />
      <ContentArea size="none" gap="slim">
        {entry || numEntries === 1 ? (
          <>
            <DateComp
              weight="bold"
              actualStartsAt={entry?.actualStartsAt ?? entry?.insertedAt}
              startsAt={entry?.startsAt}
              showDayOfWeek
              size="lg"
            />
            <TimeComp
              variant="note"
              startsAt={entry?.startsAt}
              endsAt={entry?.endsAt}
            />
          </>
        ) : (
          <Text weight="bold">{numEntries} Shifts</Text>
        )}
        <Text variant="note" color="neutral">
          Estimated Earnings: <Text weight="bold">{payRange}</Text>
        </Text>
      </ContentArea>
      <IncrementingMoneyInput
        testID={`${name}-incrementing-money-input`}
        label={label ?? "Your Hourly Rate Bid:"}
        incrementSize={0.5}
        value={value ?? ""}
        initialValue={initialValue ?? undefined}
        onChangeText={setValue}
        onBlur={showErrors}
        errors={errors}
        min={minValue}
      />
    </Surface>
  );
}
