import React, { useState } from "react";

import {
  Divider,
  IconText,
  Spacer,
  Stack,
  Text,
  TooltipButton
} from "@gigsmart/atorasu";
import { PAYMENT_TYPES } from "@gigsmart/isomorphic-shared/gig/payment-types";
import { SummaryRow } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";

import type { summaryPaymentType_gig$key } from "./__generated__/summaryPaymentType_gig.graphql";
import HowYoullGetPaidModal from "./how-youll-get-paid-modal";

interface Props {
  gig: summaryPaymentType_gig$key;
  historyGig?: boolean;
}

export default function HowYouGetPaid({
  gig: gigFragmentRef,
  historyGig
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const gig = useRelayFragment<summaryPaymentType_gig$key>(
    graphql`
      fragment summaryPaymentType_gig on Gig {
        paymentType
      }
    `,
    gigFragmentRef
  );
  const paymentType = gig?.paymentType;

  if (!paymentType) return null;
  const { label, icon, workerNote } = PAYMENT_TYPES[paymentType];
  return (
    <>
      <Divider />
      <SummaryRow
        noBorder
        sectionContentTestID="payment-type"
        iconName="badge-dollar"
        sectionLabel="How You'll Get Paid"
        sectionContent={
          <Stack fill={1} size="compact">
            <Stack horizontal size="medium" alignItems="center">
              <IconText icon={icon} size="small" spacing="compact">
                {label}
              </IconText>
              {!historyGig && (
                <TooltipButton
                  testID="payment-type-modal"
                  onPress={() => setShowModal(true)}
                />
              )}
            </Stack>
            {workerNote && (
              <Text variant="note" color="neutral">
                {workerNote}
              </Text>
            )}
          </Stack>
        }
      />
      <Spacer size="compact" />
      <HowYoullGetPaidModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        paymentType={paymentType}
      />
    </>
  );
}
