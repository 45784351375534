import React from "react";

import { EmptyContentSurface } from "@gigsmart/atorasu";

const JobBoardEmptyView = ({ searchTerm }: { searchTerm?: string }) => {
  return (
    <EmptyContentSurface
      iconName="newspaper"
      message={
        searchTerm
          ? `No Jobs Found for "${searchTerm}".`
          : "Unfortunately, there are currently no positions available on the Job Board in your area."
      }
    />
  );
};

export default JobBoardEmptyView;
