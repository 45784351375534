import React, { type ComponentProps } from "react";
import { theme } from "../style";
import Spacer from "./spacer";

const TAB_HEIGHT_UNITS = 52;
const MISSING_PERMISSIONS_CARD_UNITS = 58;

type Props = ComponentProps<typeof Spacer> & {
  bottom?: boolean;
};

const BehindTabsSpacer = ({ bottom = false, ...props }: Props) => {
  let units = TAB_HEIGHT_UNITS;
  if (bottom) units += theme.pixelsToUnits(theme.metric.bottomSpace());
  else units = MISSING_PERMISSIONS_CARD_UNITS;

  return <Spacer units={units} {...props} />;
};

export default BehindTabsSpacer;
