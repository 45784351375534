import {
  NavIcon,
  Platform,
  Share,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import React from "react";

interface Props {
  job: { name: string; url: string };
  onPressBack: () => void;
}

export const showAffiliateJobDetail = (
  name: string | null | undefined,
  url: string | null | undefined
) =>
  showEmbeddedBrowser({
    url: url?.replace(/^http:\/\//, "https://") ?? "",
    title: name ?? "",
    rightAccessory: (
      <NavIcon
        name="external-link"
        onPress={async () =>
          await Share.share({
            title: name ?? "",
            ...Platform.select({
              ios: { url: url ?? "" },
              default: { message: url ?? "" }
            })
          })
        }
        testID="icon-share-button"
      />
    )
  });
