import React, { type ReactNode, memo } from "react";
import { View, type ViewProps } from "react-native";

import { theme, useStyles } from "../../style";
import KatanaTouchableOpacity from "../../tappable/katana-touchable-opacity";
import TabIndicator from "./tab-indicator";
import TabItemLabel from "./tab-item-label";

interface Props {
  label: ReactNode;
  active?: boolean;
  onPress?: () => void;
  testID?: ViewProps["testID"];
  onLayout?: ViewProps["onLayout"];
  maxWidth?: number | string;
}

export const TAB_HEIGHT = theme.units(11.5);

const TabItem = ({
  label,
  active,
  onLayout,
  maxWidth,
  testID,
  ...props
}: Props) => {
  const { styles } = useStyles(
    ({ color, units }) => ({
      wrapper: {
        flexGrow: 1,
        backgroundColor: "#fff",
        borderColor: color.neutralLight,
        borderBottomWidth: 1,
        minWidth: 110,
        maxWidth
      },
      container: {
        height: TAB_HEIGHT,
        paddingTop: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: units(3)
      }
    }),
    [maxWidth]
  );

  return (
    <View testID={testID} style={styles.wrapper} onLayout={onLayout}>
      <KatanaTouchableOpacity
        style={styles.container}
        eventTargetName=""
        accessible={false}
        // disabled={active}
        {...props}
      >
        {typeof label === "string" ? (
          <TabItemLabel label={label} active={active} />
        ) : (
          label
        )}
      </KatanaTouchableOpacity>
      {active && <TabIndicator />}
    </View>
  );
};

export default memo(
  TabItem,
  (p1, p2) =>
    p1.label === p2.label &&
    p1.active === p2.active &&
    p1.maxWidth === p2.maxWidth
);
