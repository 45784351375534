/**
 * @generated SignedSource<<67868ab7de0d97ee04a49bdea241122a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type applyButtons_jobPosting$data = {
  readonly applyEmailAddress: string | null | undefined;
  readonly applyUrl: string | null | undefined;
  readonly id: string;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "applyButtons_jobPosting";
};
export type applyButtons_jobPosting$key = {
  readonly " $data"?: applyButtons_jobPosting$data;
  readonly " $fragmentSpreads": FragmentRefs<"applyButtons_jobPosting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "applyButtons_jobPosting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applyEmailAddress",
      "storageKey": null
    }
  ],
  "type": "JobPosting",
  "abstractKey": null
};

(node as any).hash = "aac21f7dc631f7ffc1d234dfc2d4b1f7";

export default node;
