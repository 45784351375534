import { SearchListHeader } from "@gigsmart/isomorphic-shared/list";
import React from "react";

import type { Values } from "./index";
import LocationSearch from "./location-search";

const RADIUS_MILES_OPTIONS = [
  { label: "Within 5 miles", value: 5 },
  { label: "Within 15 miles", value: 15 },
  { label: "Within 50 miles", value: 50 },
  { label: "Within 75 miles (default)", value: 75 }
];

interface Props {
  collapsed?: boolean;
  filters: Values;
  onChange: (values: Values) => void;
}

const JobListHeader = ({ collapsed, filters, onChange }: Props) => {
  return (
    <SearchListHeader
      iconName="newspaper"
      title="Job Board"
      testID="search-bar"
      value={filters.searchTerms}
      onChangeText={(searchTerms) => onChange({ ...filters, searchTerms })}
      placeholder="Search by title, company, industry"
      collapsed={collapsed}
      collapsedContent={
        <LocationSearch
          onSearchLocation={(radiusMiles, location) =>
            onChange({ ...filters, radiusMiles, location })
          }
          radiusMiles={filters.radiusMiles}
          location={filters.location}
          options={RADIUS_MILES_OPTIONS}
        />
      }
    />
  );
};

export default JobListHeader;
