import * as React from "react";

import Flex, { type Props as FlexProps } from "../flex/flex";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";

type Props = StylesProps &
  Omit<FlexProps, "fill"> & {
    children?: React.ReactNode;
    style?: ViewStyleProp;
    color: string;
    fill?: boolean | number;
    testID?: string;
    verticalPadding?: number;
    horizontalPadding?: number;
    padding?: number;
    paddingTop?: number;
    paddingBottom?: number;
    paddingRight?: number;
    maxWidth?: number | string;
    minWidth?: number | string;
    width?: number | string;
    minHeight?: number | string;
    maxHeight?: number | string;
    height?: number | string;
    shadow?: boolean;
    withBorder?: boolean;
    border?: boolean;
    borderRadius?: boolean;
    bottomBorderRadius?: boolean;
    topBorderRadius?: boolean;
  };

@withStyles(({ color, units }) => ({
  container: {
    padding: units(4)
  },
  withBorder: {
    borderBottomColor: color.blue,
    borderBottomWidth: 2
  },
  border: {
    borderColor: color.neutralLight,
    borderBottomWidth: 1
  }
}))
export default class StyledView extends React.Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    color: "white"
  };

  get viewStyles() {
    const {
      color,
      fill,
      style,
      styles,
      theme,
      verticalPadding,
      horizontalPadding,
      padding,
      paddingTop,
      paddingBottom,
      paddingRight,
      width,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      height,
      shadow,
      withBorder,
      border,
      borderRadius,
      bottomBorderRadius,
      topBorderRadius
    } = this.props;
    return [
      styles.container,
      padding || padding === 0 ? { padding: theme.units(padding) } : null,
      withBorder ? styles.withBorder : null,
      fill ? { flex: fill === true ? 1 : fill } : null,
      { backgroundColor: theme.color.getColor(color) },
      verticalPadding !== undefined
        ? { paddingVertical: theme.units(verticalPadding) }
        : null,
      { paddingHorizontal: theme.units(horizontalPadding ?? 4) },
      paddingTop !== undefined ? { paddingTop: theme.units(paddingTop) } : null,
      paddingBottom !== undefined
        ? { paddingBottom: theme.units(paddingBottom) }
        : null,
      paddingRight !== undefined
        ? { paddingRight: theme.units(paddingRight) }
        : null,
      borderRadius ? { borderRadius: theme.unit.borderRadius } : null,
      bottomBorderRadius
        ? {
            borderBottomLeftRadius: theme.unit.borderRadius,
            borderBottomRightRadius: theme.unit.borderRadius
          }
        : null,
      topBorderRadius
        ? {
            borderTopLeftRadius: theme.unit.borderRadius,
            borderTopRightRadius: theme.unit.borderRadius
          }
        : null,
      shadow ? { ...theme.namedShadows.card1 } : null,
      border ? styles.border : null,
      width ? { width } : null,
      height ? { height } : null,
      maxWidth
        ? {
            maxWidth:
              typeof maxWidth === "number" ? theme.units(maxWidth) : maxWidth
          }
        : null,
      minWidth
        ? {
            minWidth:
              typeof minWidth === "number" ? theme.units(minWidth) : minWidth
          }
        : null,
      minHeight
        ? {
            minHeight:
              typeof minHeight === "number" ? theme.units(minHeight) : minHeight
          }
        : null,
      maxHeight
        ? {
            maxHeight:
              typeof maxHeight === "number" ? theme.units(maxHeight) : maxHeight
          }
        : null,
      style
    ];
  }

  render() {
    const {
      children,
      testID,
      color,
      fill,
      style,
      styles,
      theme,
      withBorder,
      verticalPadding,
      horizontalPadding,
      borderRadius,
      padding,
      ...flexProps
    } = this.props;
    return (
      <Flex style={this.viewStyles} testID={testID} {...flexProps}>
        {children}
      </Flex>
    );
  }
}
