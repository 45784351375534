import {
  Button,
  ContentArea,
  IconCircle,
  Stack,
  Text,
  showModal
} from "@gigsmart/atorasu";
import React from "react";

interface Props {
  onClose: () => void;
  submitLabel: string;
}

export function UnmetRequirementsModal({ submitLabel, onClose }: Props) {
  return (
    <ContentArea fill>
      <Stack alignItems="center" justifyContent="center" fill>
        <ContentArea size="none" variant="large">
          <Text variant="header" weight="bold" color="primary" align="center">
            Unfortunately, you do not meet all of the requirements for this Gig.
          </Text>
        </ContentArea>
        <IconCircle
          icon="clipboard-check"
          size="large"
          color="neutral"
          variant="well"
        />
        <Text>
          Your Profile has been updated, and you will no longer receive
          notifications for Gigs that contain requirements you do not meet. If
          things change, you can update your Profile at any time.
        </Text>
      </Stack>
      <Button
        testID="unmet-req-modal-button"
        label={submitLabel}
        onPress={onClose}
      />
    </ContentArea>
  );
}

export function showUnmetRequirementsModal(
  submitLabel: string,
  onClose: () => void
) {
  return showModal({
    raw: true,
    dismissable: true,
    children: (closeFn) => (
      <UnmetRequirementsModal
        submitLabel={submitLabel}
        onClose={() => {
          closeFn();
          onClose();
        }}
      />
    )
  });
}
