import { Screen } from "@gigsmart/atorasu";
import { NavPortalEntrance } from "@gigsmart/kaizoku";
import React from "react";
import { showAffiliateJobDetail } from "./affiliate-job-detail";
import JobBoardHelpIcon from "./job-board-help-icon";
import JobList from "./job-list";

export default function JobsListScreen() {
  return (
    <Screen
      testID="jobs-list-screen"
      header={<NavPortalEntrance rightAccessory={<JobBoardHelpIcon />} />}
    >
      <JobList
        display="flex"
        onPressAffiliate={(job) => showAffiliateJobDetail(job.name, job.url)}
      />
    </Screen>
  );
}
