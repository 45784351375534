import { Icon, Spacer, Text, toast } from "@gigsmart/atorasu";
import { responsive, useStyles } from "@gigsmart/atorasu/style";
import { type AppScreenProps, NavPortalEntrance } from "@gigsmart/kaizoku";
import { SolidButton } from "@gigsmart/katana";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import type { SharedParamList } from "@gigsmart/seibutsu/navigation/types";
import React from "react";
import { SafeAreaView, View } from "react-native";
import type { resetPasswordSuccessMutation } from "./__generated__/resetPasswordSuccessMutation.graphql";
import AuthNavBarButton from "./auth-navbar-button";
import AuthStep from "./auth-step";

export default function ResetPasswordSuccess({
  route
}: AppScreenProps<SharedParamList, "ResetPasswordSuccess">) {
  const styles = useStyles(({ getUnits, getColor, media }) => ({
    safeArea: [
      {
        flex: 1
      },
      responsive(media.size.xsmall.down, {
        backgroundColor: getColor("surface", "fill")
      })
    ],
    title: {
      paddingTop: getUnits(9),
      textAlign: "center"
    },
    body: {
      textAlign: "center",
      paddingTop: getUnits(4)
    },
    center: {
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      paddingHorizontal: getUnits(4),
      paddingTop: getUnits(4)
    }
  }));

  const [mutation] = useRelayMutation<resetPasswordSuccessMutation>(
    graphql`
      mutation resetPasswordSuccessMutation(
        $input: RequestUserPasswordResetInput!
      ) {
        requestUserPasswordReset(input: $input) {
          ok
        }
      }
    `
  );

  const resendEmail = () => {
    mutation(
      {
        input: {
          emailAddress: route.params.emailAddress,
          userType: route.params.userType
        }
      },
      {
        onSuccess: () => {
          toast.success("The request has been re-sent to your email!");
        },
        onError: (result) => {
          toast.error(
            result.message || "Unexpected error while re-sending your email."
          );
        }
      }
    );
  };
  return (
    <>
      <NavPortalEntrance rightAccessory={<AuthNavBarButton text="Sign In" />} />
      <SafeAreaView style={styles.safeArea}>
        <AuthStep
          testID="reset-password-success-screen"
          spaceBetween
          buttonRow={() => (
            <>
              <SolidButton title="Send Again" onPress={resendEmail} />
              <Spacer />
            </>
          )}
        >
          <View style={[styles.center]}>
            <Icon name="paper-plane" size="huge" color="primary" />
            <Text
              style={styles.title}
              color="primary"
              weight="semibold"
              testID="success-title"
              variant="title"
            >
              Request Sent to Your Email!
            </Text>
            <Text style={styles.body} testID="success-subtitle">
              We sent you an email with information explaining how to reset your
              password.
            </Text>
          </View>
        </AuthStep>
      </SafeAreaView>
    </>
  );
}
