/**
 * @generated SignedSource<<e1d85b7fafa55c1ba4cc46b5c3c3045d>>
 * @relayHash c9e40f256ee11f3fe78dc90cfd88fecc
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:P1_fRJu4x1EGmlBUrZdtMwCnY0KVCVdEVhleIDh7gbc

import { ConcreteRequest } from 'relay-runtime';
export type WorkerQualificationStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
export type SetWorkerQualificationInput = {
  gigFieldItemDefinitionId: string;
  status: WorkerQualificationStatus;
  workerId: string;
};
export type useUpdateWorkerQualificationMutation$variables = {
  input: SetWorkerQualificationInput;
};
export type useUpdateWorkerQualificationMutation$data = {
  readonly setWorkerQualification: {
    readonly newWorkerQualificationEdge: {
      readonly node: {
        readonly gigFieldItemDefinition: {
          readonly id: string;
          readonly label: string;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useUpdateWorkerQualificationMutation = {
  response: useUpdateWorkerQualificationMutation$data;
  variables: useUpdateWorkerQualificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetWorkerQualificationPayload",
    "kind": "LinkedField",
    "name": "setWorkerQualification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkerQualificationsEdge",
        "kind": "LinkedField",
        "name": "newWorkerQualificationEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkerQualification",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GigFieldItemDefinition",
                "kind": "LinkedField",
                "name": "gigFieldItemDefinition",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateWorkerQualificationMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateWorkerQualificationMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "qpid:P1_fRJu4x1EGmlBUrZdtMwCnY0KVCVdEVhleIDh7gbc",
    "metadata": {},
    "name": "useUpdateWorkerQualificationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e08a2b76ba955f14d0a65983da068f29";

export default node;
