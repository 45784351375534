import { NavAction, ScreenTop, Text, useStepperV2 } from "@gigsmart/atorasu";
import { NewGwOnboarding } from "@gigsmart/feature-flags";
import type { AuthParamList } from "@gigsmart/isomorphic-shared/auth/types";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type AppNavigationProp,
  defaultBackHandler,
  useNavigation
} from "@gigsmart/kaizoku";
import React from "react";
import type { OnboardingData } from "./types";

export function OnboardingNav() {
  const nav = useNavigation<AppNavigationProp<AuthParamList>>();
  const user = useCurrentUser();
  const stepper = useStepperV2<OnboardingData>();

  const current = stepper.steps[stepper.index];
  const canGoBack =
    !user ||
    current?.name === "Business Information" ||
    // new flow: can go back if selected PERSONAL modal and moved to mobile number step
    (NewGwOnboarding.isEnabled() &&
      stepper.data.accountMode === "PERSONAL" &&
      current?.name === "Mobile Number");

  const handleSignout = () => nav.replace("Logout");
  const handleBack = () => {
    canGoBack && stepper.hasPreviousStep
      ? stepper.prevStep()
      : defaultBackHandler();
    return true;
  };

  const rightAccessory = !!user && (
    <NavAction testID="onboarding-sign-out" onPress={handleSignout}>
      <Text weight="bold" color="primary">
        Sign Out
      </Text>
    </NavAction>
  );

  return (
    <ScreenTop
      showBackButton={canGoBack}
      onBackPress={handleBack}
      rightAccessory={rightAccessory}
    />
  );
}
