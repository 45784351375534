import React from "react";

import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";

import { useQualifications } from "./hooks/useQualifications";

const PhysicalRequirementsScreen = () => {
  const {
    title,
    subTitle,
    reminderText,
    iconName,
    options,
    checkOption,
    onBackPress
  } = useQualifications({
    qualificationTitle: "Physical Requirements"
  });
  if (!options || options?.length === 0) return null;
  return (
    <ScreenScroll testID="physicalRequirementsScreen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="physicalRequirements-selection"
          header={title ?? ""}
          note={subTitle}
          searchHeader="Do you meet the following Physical Requirements?"
          searchPlaceholder="Search Physical Requirements"
          selectionRowHeader="Physical Requirements"
          selectionRowHeaderIcon={iconName}
          options={options}
          reminder={reminderText}
          onCheckOption={checkOption}
        />
      </ContentArea>
    </ScreenScroll>
  );
};

export default PhysicalRequirementsScreen;
