/**
 * @generated SignedSource<<bb61c7d49393c48b25be7263bae42433>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigPositionStatus = "CONFIRMED" | "DENIED" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePositionsByCategory_worker$data = {
  readonly category: {
    readonly edges: ReadonlyArray<{
      readonly association: {
        readonly confirmedPositions: number;
        readonly id: string;
      } | null | undefined;
      readonly node: {
        readonly categoryIcon: {
          readonly data: string;
        } | null | undefined;
        readonly displayName: string;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly categoryPositions: {
    readonly edges: ReadonlyArray<{
      readonly association: {
        readonly id: string;
        readonly status: GigPositionStatus;
      } | null | undefined;
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly workerPositions: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "usePositionsByCategory_worker";
};
export type usePositionsByCategory_worker$key = {
  readonly " $data"?: usePositionsByCategory_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePositionsByCategory_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoryQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "positionsQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePositionsByCategory_worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "category",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "categoryQuery"
        }
      ],
      "concreteType": "WorkerCategoriesConnection",
      "kind": "LinkedField",
      "name": "workerCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerCategoriesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerGigCategory",
              "kind": "LinkedField",
              "name": "association",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "confirmedPositions",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Icon",
                  "kind": "LinkedField",
                  "name": "categoryIcon",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "data",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE status = CONFIRMED"
        }
      ],
      "concreteType": "WorkerPositionsConnection",
      "kind": "LinkedField",
      "name": "workerPositions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "workerPositions(first:0,query:\"WHERE status = CONFIRMED\")"
    },
    {
      "alias": "categoryPositions",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "positionsQuery"
        }
      ],
      "concreteType": "WorkerPositionsConnection",
      "kind": "LinkedField",
      "name": "workerPositions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerPositionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkerGigPosition",
              "kind": "LinkedField",
              "name": "association",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigPosition",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "e4d08b1997a6861192edf142c1e9a49a";

export default node;
