import { showEmbeddedBrowser } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { useEffect } from "react";
import { registerAnnouncementHandler } from "./helpers";

registerAnnouncementHandler(
  "DISPLAY_WEBVIEW",
  ({ url, onComplete }) => {
    useEffect(() => {
      const unsub = showEmbeddedBrowser({ url, onClose: onComplete });
      return () => unsub.dispose();
    }, []);
    return null;
  },
  ({ url }: any) => !!url
);

registerAnnouncementHandler(
  "OPEN_SCREEN",
  ({ options: { url } }) => {
    const history = useHistory();
    history.push(url);
    return null;
  },
  ({ name, options: { url } }: any) => name === "url" && url
);
