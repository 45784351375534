import { Modal } from "@gigsmart/atorasu";
import { EventContext } from "@gigsmart/dekigoto";
import React, { Component, type ReactNode } from "react";
import { StatusBar, View } from "react-native";
import { StyledIcon } from "../icon";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import KatanaTouchableOpacity from "../tappable/katana-touchable-opacity";
import { StyledHeader1, StyledText } from "../text";

type Props = StylesProps & {
  testID?: string;
  eventContext: string | null;
  full?: boolean;
  childrenStyle?: ViewStyleProp;
  animationType?: "slide" | "fade";
  dismissable?: boolean;
  visible: boolean;
  style?: ViewStyleProp;
  innerStyle?: ViewStyleProp;
  outerStyle?: ViewStyleProp;
  onShow?: () => any;
  onClose?: () => any;
  children?: ReactNode;
  header?:
    | {
        title: string;
        body?: string;
        color?: string;
        textColor?: string;
      }
    | null
    | undefined;
  closeTopOffset: number;
  hasCancelIcon?: boolean;
};

@withStyles(({ color, metric, unit, units }) => ({
  outer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: color.withOpacity(color.black, 0.75),
    padding: units(4)
  },
  outerFull: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  inner: {
    borderRadius: unit.borderRadius,
    backgroundColor: color.white,
    paddingHorizontal: units(10),
    paddingTop: units(9),
    paddingBottom: units(10),
    marginHorizontal: 5,
    marginTop: 5 + metric.statusBarHeight(),
    marginBottom: 5,
    maxWidth: 690,
    "@tablet": { maxWidth: 540 },
    "@phoneDown": {
      maxWidth: 440,
      width: "90%",
      paddingTop: units(11),
      paddingHorizontal: units(5),
      paddingBottom: units(5)
    }
  },
  innerFull: {
    backgroundColor: color.white,
    paddingTop: metric.statusBarHeight() + 30,
    flex: 1,
    paddingHorizontal: 30,
    width: "100%",
    height: "100%",
    "@phoneDown": { paddingHorizontal: units(4) }
  },
  closeIconContainer: {
    padding: 10,
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 10
  },

  header: {
    paddingTop: metric.statusBarHeight() + 15,
    padding: 20,
    backgroundColor: color.blue
  },
  toast: {
    paddingTop:
      metric.statusBarHeight() > 0 ? Math.max(metric.statusBarHeight(), 40) : 0
  },
  topPadding: {
    paddingTop: 8
  }
}))
export default class StyledModal extends Component<Props> {
  static defaultProps = {
    ...stylesStubs,
    full: false,
    dismissable: false,
    closeTopOffset: 0
  };

  iconColor = () => {
    const { header } = this.props;

    if (header) {
      if (header.textColor) return header.textColor;
      return "white";
    }
    return "blue";
  };

  headerStyle = () => {
    const { header, styles, theme } = this.props;
    const calculatedStyles = [styles.header];
    const bgStyles = { backgroundColor: theme.color.blue };

    if (header?.color) {
      if (theme.color.getColor(header.color)) {
        bgStyles.backgroundColor = theme.color.getColor(header.color);
      }
      bgStyles.backgroundColor = header.color;
    }
    calculatedStyles.push(bgStyles);

    return calculatedStyles;
  };

  closeModalIcon = () => {
    const { styles, theme, full, closeTopOffset, onClose } = this.props;
    return (
      <KatanaTouchableOpacity
        style={[
          styles.closeIconContainer,
          full ? { top: closeTopOffset + theme.metric.statusBarHeight() } : {}
        ]}
        onPress={onClose}
        eventTargetName="Close Modal Pressable"
      >
        <StyledIcon
          name="times"
          testID="close-modal"
          color={this.iconColor()}
          size={theme.font.size.largest}
        />
      </KatanaTouchableOpacity>
    );
  };

  renderDismissable = () => {
    const {
      styles,
      innerStyle,
      full,
      header,
      hasCancelIcon,
      children,
      childrenStyle,
      style,
      onClose
    } = this.props;
    return (
      <KatanaTouchableOpacity
        onPress={onClose}
        eventTargetName="Dismiss Modal Pressable"
        style={[
          full
            ? [styles.innerFull, header ? { paddingTop: 0 } : {}]
            : styles.inner,
          innerStyle,
          style
        ]}
        activeOpacity={0.99}
      >
        {!full && hasCancelIcon && !header && this.closeModalIcon()}
        <View style={childrenStyle}>{children}</View>
      </KatanaTouchableOpacity>
    );
  };

  renderHeader = () => {
    const { header } = this.props;
    return (
      header && (
        <View style={this.headerStyle()}>
          <StyledHeader1 color={header.textColor ?? "white"}>
            {header.title}
          </StyledHeader1>
          {header.body && (
            <StyledText center color={header.textColor ?? "white"}>
              {header.body}
            </StyledText>
          )}
        </View>
      )
    );
  };

  render() {
    const {
      testID,
      full,
      childrenStyle,
      animationType = "fade",
      dismissable,
      styles,
      visible,
      children,
      onClose,
      onShow,
      header,
      style,
      hasCancelIcon,
      outerStyle,
      innerStyle,
      eventContext
    } = this.props;

    return (
      <Modal
        raw
        animationType={animationType}
        transparent
        visible={visible}
        onRequestClose={onClose}
        onShow={onShow}
      >
        <EventContext name={eventContext}>
          {!header && <StatusBar barStyle="dark-content" />}
          {full && hasCancelIcon && this.closeModalIcon()}
          {header && full && this.renderHeader()}
          {header && !full && (
            <View>
              {hasCancelIcon && this.closeModalIcon()}
              {this.renderHeader()}
            </View>
          )}
          <View
            testID={testID}
            style={[full ? styles.outerFull : styles.outer, outerStyle]}
          >
            {dismissable ? (
              this.renderDismissable()
            ) : (
              <View
                style={[
                  full
                    ? [styles.innerFull, header ? { paddingTop: 0 } : {}]
                    : styles.inner,
                  innerStyle,
                  style
                ]}
              >
                {!full && hasCancelIcon && !header && this.closeModalIcon()}
                <View
                  style={[hasCancelIcon && styles.topPadding, childrenStyle]}
                >
                  {children}
                </View>
              </View>
            )}
          </View>
        </EventContext>
      </Modal>
    );
  }
}
