import type { IconName } from "@gigsmart/atorasu";
import startCase from "lodash/startCase";

export type OptInKey =
  | "id"
  | "backgroundCheckOptIn"
  | "driverCheckOptIn"
  | "drugScreenOptIn";
export type ReportTypeKey =
  | "IDENTITY_CHECK"
  | "IDENTITY_CHECK_REFRESHED"
  | "BACKGROUND_CHECK"
  | "MOTOR_VEHICLE_CHECK"
  | "DRUG_SCREEN"
  | "EOR_WORKER";

interface StatusSpec {
  color: string;
  title: string;
  exclude?: boolean;
}

interface ReportSpec {
  iconName: IconName;
  title: string;
  optInKey: OptInKey;
  requirementText: string;
  hide?: boolean;
}

const reportStatuses = {
  FAIL: {
    color: "red",
    title: "Failed",
    exclude: true
  },
  REQUIRES_ATTENTION: {
    color: "spiceOrange",
    title: "Needs Attention"
  },
  OPTED_OUT: {
    color: "neutralLight",
    title: "Opted-out"
  },
  NOT_STARTED: {
    color: "neutralMedium",
    title: "Opted-in"
  },
  NOT_ELIGIBLE: {
    color: "red",
    title: "Not Eligible"
  },
  PENDING: {
    color: "neutralDark",
    title: "Pending"
  },
  PASS: {
    color: "green",
    title: "Clear"
  }
};

const types: Record<ReportTypeKey, ReportSpec> = {
  IDENTITY_CHECK: {
    iconName: "landmark" as const,
    title: "Financial Verification",
    optInKey: "id",
    requirementText: "Financial Verification Required",
    hide: true
  },
  IDENTITY_CHECK_REFRESHED: {
    iconName: "landmark" as const,
    title: "Financial Verification Update",
    optInKey: "id",
    requirementText: "Financial Verification Required",
    hide: true
  },
  BACKGROUND_CHECK: {
    iconName: "user-shield" as const,
    title: "Background Check",
    optInKey: "backgroundCheckOptIn",
    requirementText: "Background Check Required"
  },
  MOTOR_VEHICLE_CHECK: {
    iconName: "car-side" as const,
    title: "MVR Check",
    optInKey: "driverCheckOptIn",
    requirementText: "MVR Check Required"
  },
  DRUG_SCREEN: {
    iconName: "pills" as const,
    title: "Drug Screen",
    optInKey: "drugScreenOptIn",
    requirementText: "Drug Screen Required"
  },
  EOR_WORKER: {
    iconName: "passport" as const,
    title: "W-2 Workers",
    optInKey: "id",
    requirementText: "W2 Worker Onboarding Required"
  }
};

export const typeKeys: ReportTypeKey[] = Object.keys(types) as any[];

export function getReportStatus(name: string): StatusSpec {
  return (
    reportStatuses[name as keyof typeof reportStatuses] || {
      color: "neutralDark",
      title: "Unknown Status",
      exclude: true
    }
  );
}

export function getReportTypes(
  reportTypes:
    | Array<string | undefined>
    | ReadonlyArray<string | undefined> = []
) {
  return reportTypes.map(getReportType).filter(({ hide }) => !hide);
}

export function getReportType(
  reportType = ""
): ReportSpec & { reportType: string } {
  const title = startCase(reportType);
  let key = reportType as keyof typeof types;

  if (key === "IDENTITY_CHECK") key = "IDENTITY_CHECK_REFRESHED";

  return {
    ...(types[key] || {
      iconName: "exclamation" as const,
      title,
      optInKey: "id",
      requirementText: `${title} Required`
    }),
    reportType
  };
}
