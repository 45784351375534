import React from "react";

import Stack from "../../container/stack";
import { FlexRow } from "../../flex";
import { type IconName, StyledIcon } from "../../icon";
import { useStyles } from "../../style";
import KatanaTouchableOpacity from "../../tappable/katana-touchable-opacity";
import { KatanaText } from "../../text";
import RadioButtonCircle from "./radio-button-circle";

export interface RadioRow<V> {
  value: V;
  label?: string;
  helperLabel?: string;
  icon?: IconName;
  selected?: boolean;
  index?: number;
  onPress?: (value: V) => void;
}

export default function DefaultRadioRow<V>({
  label,
  helperLabel,
  value,
  onPress,
  icon,
  selected,
  index
}: RadioRow<V>) {
  const { styles } = useStyles(({ color, units }) => ({
    row: {
      borderTopWidth: 1,
      borderTopColor: color.neutralLight,
      padding: units(4)
    },
    icon: {
      paddingRight: units(4),
      width: units(10),
      textAlign: "center"
    },
    shrink: { flexShrink: 1 }
  }));
  return (
    <KatanaTouchableOpacity
      eventTargetName={label ?? ""}
      testID={typeof value === "string" ? value : `radio-${index}`}
      onPress={() => {
        onPress?.(value);
      }}
    >
      <FlexRow
        justifyContent="space-between"
        style={styles.row}
        alignItems="center"
      >
        <FlexRow alignItems="center" fill={1}>
          {icon && (
            <StyledIcon name={icon} variant="solid" style={styles.icon} />
          )}
          <Stack direction="row" alignItems="flex-end" spacing={0} shrink={1}>
            <KatanaText style={styles.shrink}>
              {label}
              {!!helperLabel && (
                <KatanaText color="neutralDark">{helperLabel}</KatanaText>
              )}
            </KatanaText>
          </Stack>
        </FlexRow>
        <RadioButtonCircle selected={selected} />
      </FlexRow>
    </KatanaTouchableOpacity>
  );
}
