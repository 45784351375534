import { graphql, useRelayMutation } from "@gigsmart/relay";
import type { useSetEngagementSummaryMutation } from "./__generated__/useSetEngagementSummaryMutation.graphql";
export type { SetEngagementSummaryInput } from "./__generated__/useSetEngagementSummaryMutation.graphql";

export default function useSetEngagementSummary() {
  const [commit] = useRelayMutation<useSetEngagementSummaryMutation>(graphql`
    mutation useSetEngagementSummaryMutation(
      $input: SetEngagementSummaryInput!
    ) {
      setEngagementSummary(input: $input) {
        engagement {
          id
        }
        engagementTimesheet {
          id
          additionalPayment
        }
        newEngagementDisputeEdge {
          node {
            id
          }
        }
      }
    }
  `);

  return commit;
}
