import ShadowView from "@gigsmart/shadow-view";
import type { ForwardableProps } from "@gigsmart/type-utils";
import React, { type ReactNode } from "react";
import { View } from "react-native";

import { type IconName, IconText } from "../icon";
import { Spacer } from "../spacer";
import { useStyles } from "../style";
import { ClearButton, OutlineButton, SolidButton } from "../tappable";
import { KatanaText } from "../text";
import Stack from "./stack";
import StyledModal from "./styled-modal";

export interface ActionEntry {
  key?: string;
  title: string;
  testID?: string;
  onPress?: () => void;
  onPressDisabled?: () => void;
  variant?: keyof typeof BUTTON_VARIANT;
  style?: "default" | "cancel" | "destructive";
  disabled?: boolean;
}

type Props = ForwardableProps<
  typeof StyledModal,
  {
    variant?: "shadow" | "inset" | "border-inset";
    title?: string;
    subTitle?: string;
    titleIcon?: IconName;
    footer?: ReactNode;
    topContent?: ReactNode;
    actions?: readonly ActionEntry[];
    actionsSpacing?: number;
    actionsDirection?: "row" | "column";
    actionsContent?: ReactNode;
    fixedHeight?: boolean | number;
    smallFooter?: boolean;
  }
>;

const BUTTON_VARIANT = {
  solid: SolidButton,
  outline: OutlineButton,
  clear: ClearButton
};

const PromptModal = ({
  variant = "inset",
  title,
  titleIcon,
  subTitle,
  children,
  footer,
  actions,
  actionsSpacing,
  actionsDirection = "row",
  actionsContent,
  style,
  innerStyle,
  topContent,
  fixedHeight = false,
  smallFooter,
  ...props
}: Props) => {
  const { styles, theme } = useStyles(
    ({ color, unit, units, font }) => ({
      container: {
        width: "100%",
        paddingBottom: 0,
        paddingTop: units(12),
        "@tablet": { maxWidth: 540 },
        "@phoneDown": { maxWidth: 440, width: "90%" }
      },
      containerInner: {
        ...(fixedHeight
          ? {
              maxHeight: 590,
              height: typeof fixedHeight === "number" ? fixedHeight : 590
            }
          : {}),
        paddingHorizontal: 0,
        paddingTop: units(12),
        paddingBottom: units(5)
      },
      inset: {
        paddingHorizontal: 40,
        "@phoneDown": { paddingHorizontal: 20 }
      },
      title: {
        ...font.body("semibold"),
        ...font.withSize(16),
        color: color.blue,
        textAlign: "center"
      },
      subTitle: {
        textAlign: "center",
        width: "100%",
        maxWidth: 400,
        marginHorizontal: "auto"
      },
      actionsWrapper: {
        width: "100%",
        maxWidth: 400,
        marginHorizontal: "auto"
      },
      footer: {
        paddingTop: 20,
        paddingBottom: 20
      },
      headerShadow: {
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: 3,
        shadowOffset: { width: 0, height: 3 },
        position: "relative",
        zIndex: 1
      },
      content: {
        position: "relative",
        flex: fixedHeight === false ? undefined : 1
      },
      contentBorderInset: {
        borderWidth: 1,
        borderColor: color.neutralLight,
        marginHorizontal: 40,
        borderRadius: unit.borderRadius,
        "@phoneDown": { marginHorizontal: 20 }
      },
      footerShadow: {
        paddingBottom: 20,
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: 3,
        shadowOffset: { width: 0, height: -3 },
        position: "relative",
        zIndex: 1
      },
      childrenStyle: {
        flex: fixedHeight === false ? undefined : 1
      }
    }),
    [fixedHeight]
  );
  const isSmall = theme.metric.deviceWidth() <= 340;
  const hasHeader = !!title || !!subTitle || !!topContent;
  const hasFooter = !!actions?.length || !actionsContent;

  const headerStyles = [
    styles.inset,
    variant === "shadow" && styles.headerShadow
  ];

  const contentStyles = [
    styles.content,
    variant === "inset" && styles.inset,
    variant === "border-inset" && styles.contentBorderInset
  ];

  const footerStyles = [
    styles.inset,
    styles.footer,
    variant === "shadow" && styles.footerShadow
  ];

  return (
    <StyledModal
      {...props}
      hasCancelIcon
      style={[styles.container, style]}
      innerStyle={[styles.containerInner, innerStyle]}
      childrenStyle={styles.childrenStyle}
    >
      {hasHeader && (
        <ShadowView style={headerStyles}>
          {!!title && (
            <IconText
              iconColor="blue"
              iconName={titleIcon}
              textStyle={styles.title}
              alignItems="center"
              justifyContent="center"
            >
              {title}
            </IconText>
          )}
          {!!title && <Spacer units={2} />}
          {!!subTitle && (
            <KatanaText style={styles.subTitle}>{subTitle}</KatanaText>
          )}
          {topContent}
        </ShadowView>
      )}
      <View style={contentStyles}>{children}</View>
      {!!actions && hasFooter && (
        <ShadowView style={footerStyles}>
          {actionsContent}
          <Stack
            style={styles.actionsWrapper}
            direction={actionsDirection}
            spacing={actionsSpacing ?? isSmall ? 2 : 4}
          >
            {actions.map((action, idx) => {
              const ButtonComponent =
                (action.variant && BUTTON_VARIANT[action.variant]) ??
                OutlineButton;
              return (
                <ButtonComponent
                  fill={actionsDirection !== "column"}
                  key={action.key ?? `${idx}`}
                  testID={action.testID}
                  onPress={action.onPress}
                  onPressDisabled={action.onPressDisabled}
                  title={action.title}
                  compact={isSmall}
                  disabled={action.disabled}
                  buttonColor={
                    action.style === "destructive" ? "orange" : "blue"
                  }
                />
              );
            })}
          </Stack>
        </ShadowView>
      )}
    </StyledModal>
  );
};

export default PromptModal;
