import type { ForwardableProps } from "@gigsmart/type-utils";
import React, {
  Fragment,
  Children,
  isValidElement,
  type ReactNode
} from "react";
import { Flex } from "../flex";
import { Spacer } from "../spacer";

type Props = ForwardableProps<
  typeof Flex,
  {
    spacing?: number;
    children?: ReactNode;
    direction?: "row" | "column";
    inset?: boolean | number;
  }
>;

const Stack = ({
  direction,
  spacing = 4,
  inset = false,
  children,
  ...props
}: Props) => {
  const validChildren = Children.toArray(children).filter(isValidElement);
  const insetSpacing = typeof inset === "number" ? inset : inset ? spacing : 0;

  return (
    <Flex direction={direction} {...props}>
      {!!insetSpacing && <Spacer direction={direction} units={insetSpacing} />}
      {validChildren.map((child, idx) => (
        <Fragment key={`${idx}`}>
          {idx > 0 && spacing > 0 && (
            <Spacer units={spacing} direction={direction} />
          )}
          {child}
        </Fragment>
      ))}
      {!!insetSpacing && <Spacer direction={direction} units={insetSpacing} />}
    </Flex>
  );
};

export default Stack;
