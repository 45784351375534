import { Stack } from "@gigsmart/atorasu";
import { RemoveBenefitsMenu } from "@gigsmart/feature-flags";
import { type TabConfig, TabLayout } from "@gigsmart/katana";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import WorkerProjectSummaryTab from "@gigsmart/seibutsu/engagement/WorkerProjectSummaryTab";
import WithdrawButton from "@gigsmart/seibutsu/gig/WithdrawButton";
import ShiftTimesheetTab from "@gigsmart/seibutsu/shifts/ShiftTimesheetTab";
import CancellationPoliciesRow from "@gigsmart/seibutsu/shifts/accessory/CancellationPoliciesRow";
import { WorkerBenefitsRow } from "@gigsmart/seibutsu/worker/WorkerBenefitsRow";
import BlockOrganizationCollapsibleRow, {
  showWorkerBlockOrg
} from "@gigsmart/seibutsu/worker/WorkerBlockOrganizations/BlockOrganizationCollapsibleRow";
import compact from "lodash/compact";
import React, { useEffect, useMemo, useState } from "react";
import GigCancellationFeeSummaryTab from "../gig-cancellation-fee-summary-tab";
import GigPendingApprovalTab from "../pending-approval-tab";
import SummaryTab from "../summary-tab";
import type { infoTabs_engagement$key } from "./__generated__/infoTabs_engagement.graphql";
import GigDescription from "./gig-description";
import GigSummaryRows from "./gig-summary-rows";

type InfoTabKeys = "details" | "timesheet" | "summary";

interface Props {
  engagementRef?: infoTabs_engagement$key | null | undefined;
}

const EngagementDetailsInfoTabs = ({ engagementRef }: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment infoTabs_engagement on Engagement {
        ...summaryTab_engagement
        ...pendingApprovalTab_engagement
        ...gigSummaryRows_engagement
        ...WorkerProjectSummaryTab_engagement
        ...gigCancellationFeeSummaryTab_engagement
        ...WithdrawButton_engagement
        id
        currentState {
          name
        }
        gigType
        gig {
          organization {
            id
          }
          ...BlockOrganizationCollapsibleRow_shift
          ...gigDescription_gigLike
          ...gigRequesterRow_gigLike
          ...CancellationPoliciesRow_shift
        }
        reviewOfRequester {
          rating
        }
      }
    `,
    engagementRef ?? null
  );

  const engagementId = engagement?.id ?? "";
  const [active, setActive] = useState<InfoTabKeys>(() => {
    const stateName = engagement?.currentState?.name ?? "";
    return stateName === "ENDED" || stateName === "PENDING_TIMESHEET_APPROVAL"
      ? "summary"
      : "details";
  });

  useEffect(() => {
    const stateName = engagement?.currentState?.name ?? "";
    if (stateName === "ENDED" || stateName === "PENDING_TIMESHEET_APPROVAL") {
      setActive("summary");
    }
  }, [engagement?.currentState?.name]);

  const tabs = useMemo(() => {
    const stateName = engagement?.currentState?.name ?? "";
    const isProject = engagement?.gigType === "PROJECT";
    const showTimesheet =
      ["ENDED", "WORKING", "PAUSED", "PENDING_TIMESHEET_APPROVAL"].includes(
        stateName
      ) && !isProject;
    const showSummary = [
      "ENDED",
      "PENDING_REVIEW",
      "PENDING_TIMESHEET_APPROVAL",
      "CANCELED_WITH_PAY"
    ].includes(stateName);

    return compact<TabConfig<InfoTabKeys>>([
      {
        key: "details",
        label: "DETAILS",
        renderContent: () => (
          <Stack>
            <GigDescription gigLikeRef={engagement?.gig} collapsed />
            <GigSummaryRows
              engagementRef={engagement}
              hidePaymentDetailsRow={
                !["APPLIED", "SCHEDULED"].includes(
                  engagement?.currentState.name ?? ""
                )
              }
            />
            <CancellationPoliciesRow fragmentRef={engagement?.gig} />
            {RemoveBenefitsMenu.isDisabled() && <WorkerBenefitsRow />}
            {showWorkerBlockOrg(
              engagement?.gig,
              engagement?.currentState?.name
            ) && (
              <BlockOrganizationCollapsibleRow
                organizationId={engagement?.gig?.organization?.id ?? ""}
              />
            )}
            <WithdrawButton fragmentRef={engagement} />
          </Stack>
        )
      },
      showTimesheet && {
        key: "timesheet",
        label: "TIMESHEET",
        renderContent: () => <ShiftTimesheetTab id={engagementId} />
      },
      showSummary && {
        key: "summary",
        label: isProject ? "PROJECT SUMMARY" : "SUMMARY",
        renderContent: () => {
          if (isProject) {
            return <WorkerProjectSummaryTab fragmentRef={engagement} />;
          }
          if (stateName === "PENDING_TIMESHEET_APPROVAL") {
            return <GigPendingApprovalTab engagement={engagement as any} />;
          }
          if (stateName === "CANCELED_WITH_PAY") {
            return <GigCancellationFeeSummaryTab engagement={engagement} />;
          }
          return <SummaryTab engagement={engagement as any} />;
        }
      }
    ]);
  }, [engagement]);

  if (tabs.length === 1 && tabs[0]) {
    const tab = tabs[0];
    const content = tab?.renderContent?.(tab) ?? null;
    return <>{content}</>;
  }

  return (
    <TabLayout<InfoTabKeys>
      tabs={tabs}
      active={active}
      onSelectTab={setActive}
    />
  );
};

export default EngagementDetailsInfoTabs;
