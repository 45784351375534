import React from "react";
import { View } from "react-native";

import { FlexColumn, FlexRow } from "../flex";
import { type IconName, StyledIcon } from "../icon";
import { useStyles } from "../style";
import { StyledButton } from "../tappable";
import { StyledHeader3, StyledText } from "../text";
import Card from "./card";

interface Props {
  handleClose: () => void;
  icon: IconName;
  headerText: string;
  paragraphText: string;
  buttonText?: string;
}

export default ({
  handleClose,
  icon,
  headerText,
  paragraphText,
  buttonText = "Got It"
}: Props) => {
  const { styles, theme } = useStyles(({ font, units }) => ({
    card: {
      padding: units(4)
    },
    headerText: {
      marginVertical: 0,
      marginHorizontal: units(2),
      textAlign: "left",
      flexWrap: "wrap",
      flex: 1,
      ...font.body("semibold")
    },
    header: {
      marginBottom: units(2)
    },
    buttonText: {
      ...font.body("bold"),
      marginHorizontal: 0,
      paddingHorizontal: 0
    },
    buttonStyle: {
      paddingVertical: units(2)
    },
    closeIcon: {
      top: 0,
      margin: units(2)
    },
    buttonContainer: {
      width: units(24),
      marginRight: units(2),
      marginTop: units(2)
    }
  }));
  return (
    <Card
      style={styles.card}
      onClose={handleClose}
      iconContainerStyle={styles.closeIcon}
    >
      <FlexColumn>
        <FlexRow justifyContent="space-between" style={styles.header}>
          <FlexRow fill={1}>
            <StyledIcon
              size={theme.font.size.large}
              name={icon}
              variant="solid"
              color="blue"
            />
            <StyledHeader3 color="blue" style={styles.headerText}>
              {headerText}
            </StyledHeader3>
          </FlexRow>
        </FlexRow>
        <StyledText fontSize={theme.font.size.small}>
          {paragraphText}
        </StyledText>
        <FlexRow justifyContent="flex-end">
          <View style={styles.buttonContainer}>
            <StyledButton
              title={buttonText}
              secondary
              buttonStyle={styles.buttonStyle}
              textStyle={styles.buttonText}
              onPress={handleClose}
            />
          </View>
        </FlexRow>
      </FlexColumn>
    </Card>
  );
};
