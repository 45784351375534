import React from "react";

import { Spacer } from "@gigsmart/atorasu";

import { HIDE_JOB_BOARD_EDUCATION_CARD, useUserValue } from "../../user-value";
import JobBoardInfoDismissableCard from "../job-board-info-dismissable-card";

export default function JobsListHeader() {
  const [hideJobBoardEducationCard, setHideJobBoardEducationCard] =
    useUserValue<boolean>(HIDE_JOB_BOARD_EDUCATION_CARD);

  if (hideJobBoardEducationCard) return null;
  return (
    <>
      <Spacer size="slim" />
      <JobBoardInfoDismissableCard
        onClose={async () => await setHideJobBoardEducationCard(true)}
      />
    </>
  );
}
