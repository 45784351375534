import React from "react";

import {
  ContentArea,
  Icon,
  type IconName,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { FormField } from "@gigsmart/fomu";
import { TabButton } from "@gigsmart/katana";

interface FormIcon {
  id: string;
  icon: IconName;
  label: string;
  disabled?: boolean;
}
interface Props {
  headerText?: string;
  icons: FormIcon[];
  cardHeader?: string | null | undefined;
}

const PaymentScreenHeader = ({ icons, headerText, cardHeader }: Props) => {
  if (icons.length > 1) {
    return (
      <ContentArea fill size="none">
        {!!headerText && (
          <>
            <Text color="primary" weight="bold">
              {headerText}
            </Text>
            <Spacer />
          </>
        )}
        <Row>
          <FormField name="type">
            {({ value, setValue }) => (
              <>
                {icons.map(({ icon, id, label, disabled }, index) => (
                  <>
                    <TabButton
                      icon={icon}
                      text={label}
                      isActive={value === id}
                      key={label}
                      onPress={() => setValue(id)}
                      isDisabled={disabled}
                    />
                    {index < icons.length - 1 && <Spacer horizontal />}
                  </>
                ))}
              </>
            )}
          </FormField>
        </Row>
      </ContentArea>
    );
  }

  if (icons[0]) {
    const { icon, label } = icons[0];

    return (
      <>
        <Icon name={icon} variant="solid" />
        <Text color="primary" weight="bold">
          {label}
        </Text>
        {cardHeader && (
          <Text color="black" weight="bold">
            {cardHeader}
          </Text>
        )}
      </>
    );
  }

  return null;
};

export default PaymentScreenHeader;
