import { CollapsibleWell, GridNull, IconText, Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";

import type { CDLCard_workerprofile$key } from "./__generated__/CDLCard_workerprofile.graphql";

export function CDLCard({
  commercialLicenses
}: FragmentContainerInnerComponentProps<CDLCard_workerprofile$key>) {
  const license = getConnectionNodes(commercialLicenses)[0];
  const selectedLicense = getConnectionNodes(license?.selectedDefinitions);

  if (!selectedLicense.length) {
    return <GridNull />;
  }

  const items = selectedLicense?.map((license) => (
    <IconText
      icon="check"
      color="success"
      spacing="compact"
      iconSize="small"
      size="medium"
      key={license.label}
    >
      <Text
        color="black"
        variant="subheader"
        weight="normal"
        testID={`license-${license.label}`}
      >
        {license.label}
      </Text>
    </IconText>
  ));

  return (
    <CollapsibleWell
      testID="worker-profile-cdl"
      headerIcon="id-card"
      headerText="Commercial Driver's License"
      items={items}
    />
  );
}

export default createRelayFragmentContainer<CDLCard_workerprofile$key>(
  graphql`
    fragment CDLCard_workerprofile on Worker {
      id
      commercialLicenses: gigFields(
        first: 1
        query: "WHERE title LIKE 'Commercial Driver'"
      ) {
        edges {
          node {
            selectedDefinitions: itemDefinitions(
              first: 15
              query: "WHERE status = CONFIRMED"
            ) {
              edges {
                node {
                  label
                }
              }
            }
          }
        }
      }
    }
  `,
  CDLCard
);
