import {
  ContentArea,
  Icon,
  IconText,
  Surface,
  TextInput,
  useDebouncedEffect
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import React, { useState } from "react";

type Props = {
  count?: number;
  onSearch: (term: string) => void;
  state?: "OFFERED" | "BID_REQUESTED";
};

export function OfferedGigHeader({ count = 0, onSearch, state }: Props) {
  const [term, setTerm] = useState("");
  useDebouncedEffect(() => {
    // handle change filters
    onSearch(term);
  }, [term]);

  return (
    <Surface>
      <ContentArea gap="standard">
        <IconText
          textVariant="header"
          textWeight="bold"
          color="danger"
          size="medium"
          icon="circle-exclamation"
          iconAlign="center"
          spacing="compact"
        >
          {pluralize(
            count,
            state === "BID_REQUESTED" ? "Pending Bid Requests" : "Pending Offer"
          )}
        </IconText>
        <TextInput
          testID="offered-gig-search"
          placeholder="Search by Position, Category or Requester"
          value={term}
          onChangeText={setTerm}
          leftAccessory={
            <Icon name="search" variant="solid" color="neutral" size="medium" />
          }
        />
      </ContentArea>
    </Surface>
  );
}
