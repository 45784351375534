export const isAppleSupported = () => false;

declare const AppleID: any;

export const appleSignin = async () => {
  try {
    await loadAppleSigninScript();
    AppleID.auth.init({
      clientId: process.env.APPLE_CLIENT_ID,
      scope: "name%20email",
      usePopup: true,
      redirectURI: window.location.href
    });

    const res = await AppleID.auth.signIn();
    const appleToken = res.authorization?.id_token;

    if (appleToken)
      return { token: appleToken, firstName: null, lastName: null };
  } catch (err: any) {
    console.warn(err);
    const ignore =
      err.error === "popup_closed_by_user" ||
      err.error === "user_trigger_new_signin_flow";
    if (!ignore) throw err;
  }
};

const loadAppleSigninScript = async () =>
  await new Promise<void>((resolve) => {
    if ("AppleID" in window) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.onload = () => resolve();
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    document.head.appendChild(script);
  });
