/**
 * @generated SignedSource<<37697edfa1e47f99fd5afae7f78e124a>>
 * @relayHash 5eb46d117403a41cfdb1c9f8071820d8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:0n9UG1ew04lpvm9BtbDszu7av7BIiXZkDFqfBXqJnbY

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type jobDetailsQuery$variables = {
  id: string;
  isWorker: boolean;
};
export type jobDetailsQuery$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"jobDetails_jobPosting">;
  } | null | undefined;
  readonly trackedJob?: {
    readonly " $fragmentSpreads": FragmentRefs<"jobDetails_jobPosting">;
  } | null | undefined;
  readonly viewer: {
    readonly firstName?: string | null | undefined;
    readonly lastName?: string | null | undefined;
    readonly primaryEmail?: {
      readonly address: string;
    } | null | undefined;
    readonly primaryMobile?: {
      readonly number: string;
    } | null | undefined;
    readonly primaryOrganization?: {
      readonly " $fragmentSpreads": FragmentRefs<"hiringCompanyCard_organization">;
    } | null | undefined;
  } | null | undefined;
};
export type jobDetailsQuery = {
  response: jobDetailsQuery$data;
  variables: jobDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isWorker"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isWorker",
            "variableName": "isWorker"
          }
        ],
        "kind": "FragmentSpread",
        "name": "jobDetails_jobPosting"
      }
    ],
    "type": "JobPosting",
    "abstractKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "industry",
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = [
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "applyUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "applyEmailAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boosted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "place",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "streetAddress",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payRateNegotiable",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paySchedule",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimumPayRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximumPayRate",
        "storageKey": null
      },
      {
        "condition": "isWorker",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "JobPosting",
    "abstractKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "jobDetailsQuery",
    "selections": [
      {
        "condition": "isWorker",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "trackedJob",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isWorker",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "isWorker",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "primaryOrganization",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "hiringCompanyCard_organization"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "RequesterLike",
                "abstractKey": "__isRequesterLike"
              }
            ]
          },
          {
            "condition": "isWorker",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEmail",
                    "kind": "LinkedField",
                    "name": "primaryEmail",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMobile",
                    "kind": "LinkedField",
                    "name": "primaryMobile",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "jobDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "condition": "isWorker",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "primaryOrganization",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "RequesterLike",
                "abstractKey": "__isRequesterLike"
              }
            ]
          },
          {
            "condition": "isWorker",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEmail",
                    "kind": "LinkedField",
                    "name": "primaryEmail",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMobile",
                    "kind": "LinkedField",
                    "name": "primaryMobile",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Worker",
                "abstractKey": null
              }
            ]
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "isWorker",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "trackedJob",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isWorker",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "qpid:0n9UG1ew04lpvm9BtbDszu7av7BIiXZkDFqfBXqJnbY",
    "metadata": {},
    "name": "jobDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "41e69df6f0d4369f67e030b3230ee458";

export default node;
