/**
 * @generated SignedSource<<26a124aea09e6dde3f7c6ac9adb4cdc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BlockOrganizationCollapsibleRow_shift$data = {
  readonly gigType: GigType | null | undefined;
  readonly organization: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "BlockOrganizationCollapsibleRow_shift";
};
export type BlockOrganizationCollapsibleRow_shift$key = {
  readonly " $data"?: BlockOrganizationCollapsibleRow_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "BlockOrganizationCollapsibleRow_shift"
};

(node as any).hash = "ad2d5eaafd3eaf322b0969be8c9a8589";

export default node;
