/**
 * @generated SignedSource<<6d7bd709d1d64a8e483f8cedbec53ce2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type gigDescription_gigLike$data = {
  readonly description: string | null | undefined;
  readonly estimatedMileage?: number | null | undefined;
  readonly gigType: GigType | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly photos?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GigTags_gigLike">;
  readonly " $fragmentType": "gigDescription_gigLike";
};
export type gigDescription_gigLike$key = {
  readonly " $data"?: gigDescription_gigLike$data;
  readonly " $fragmentSpreads": FragmentRefs<"gigDescription_gigLike">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "gigDescription_gigLike",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gigType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GigTags_gigLike"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "GigPhotosConnection",
          "kind": "LinkedField",
          "name": "photos",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GigPhotosEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigPhoto",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "photos(first:10)"
        }
      ],
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "GigSeriesPhotosConnection",
          "kind": "LinkedField",
          "name": "photos",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSeriesPhotosEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigSeriesPhoto",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "photos(first:10)"
        }
      ],
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "c7671222250eba03951f287129566b7d";

export default node;
