import React, { useState, type ComponentProps, type ReactNode } from "react";
import { LayoutAnimation, Platform, UIManager } from "react-native";

import { BaseRow } from "../row";
import { Spacer } from "../spacer";
import { ToggleText } from "../tappable";
import { KatanaText } from "../text";

type Props = ComponentProps<typeof BaseRow> & {
  readonly children: ReactNode;
  readonly leftText?: string;
  readonly collapsedText: string;
  readonly notCollapsedText: string;
  readonly rightText?: string;
  readonly startCollapsed?: boolean;
  readonly toggleTestID?: string;
  chevronPos?: "right" | "left";
};

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

const CollapsibleView = ({
  children,
  leftText,
  collapsedText,
  notCollapsedText,
  rightText,
  startCollapsed = false,
  toggleTestID,
  noBorder = true,
  alignItems = "center",
  chevronPos,
  ...props
}: Props) => {
  const [visible, setVisible] = useState<boolean>(!startCollapsed);
  const handleCollapsed = (value: boolean) => {
    LayoutAnimation.configureNext({
      duration: 300,
      create: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity
      },
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity
      }
    });
    setVisible(value);
  };

  return (
    <>
      <BaseRow
        noBorder={noBorder}
        alignItems={alignItems}
        justifyContent="center"
        {...props}
      >
        {!!leftText && <KatanaText>{leftText}</KatanaText>}
        <Spacer direction="row" />
        <ToggleText
          testID={toggleTestID}
          onLabel={notCollapsedText}
          offLabel={collapsedText}
          value={visible}
          onToggle={handleCollapsed}
          iconPos={chevronPos}
        />
        <Spacer fill direction="row" />
        {!!rightText && <KatanaText>{rightText}</KatanaText>}
      </BaseRow>
      {visible && children}
    </>
  );
};

export default CollapsibleView;
