import React, { type ComponentProps } from "react";

// eslint-disable-next-line no-restricted-imports
import AtlasLinearGradient from "@gigsmart/atorasu/atoms/LinearGradient";

export type Props = ComponentProps<typeof AtlasLinearGradient>;

/** @deprecated use from @gigsmart/atorasu */
export default function LinearGradient(props: Props) {
  return <AtlasLinearGradient {...props} />;
}
