/**
 * @generated SignedSource<<6d6875157cc31072a5999d350914b567>>
 * @relayHash 6b83072021dc512014e4ec6fc941d5e7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:eDS2LtKBEA59FwhrYxzdqow1EHABcuZKdIPfeg51qas

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type applyGigStepperQuery$variables = {
  gigId: string;
  isApply: boolean;
  isSeries: boolean;
  maxDistance: number;
};
export type applyGigStepperQuery$data = {
  readonly node: {
    readonly actualStartsAt?: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useGigFields_gigOrEngagement" | "useGigPositions_gigLike">;
  } | null | undefined;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"useApplyMetadata_worker" | "useEnrollmentData_worker">;
  } | null | undefined;
};
export type applyGigStepperQuery = {
  response: applyGigStepperQuery$data;
  variables: applyGigStepperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gigId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isApply"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSeries"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDistance"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gigId"
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualStartsAt",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "GigFieldItemDefinition",
  "kind": "LinkedField",
  "name": "requiredQualifications",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigField",
      "kind": "LinkedField",
      "name": "gigField",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iconName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "kind": "Variable",
  "name": "maxDistance",
  "variableName": "maxDistance"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GigCategory",
    "kind": "LinkedField",
    "name": "category",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Icon",
        "kind": "LinkedField",
        "name": "categoryIcon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "GigPosition",
    "kind": "LinkedField",
    "name": "position",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v11/*: any*/)
    ],
    "storageKey": null
  },
  (v7/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street1",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street2",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "administrativeArea1",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalDocument",
  "kind": "LinkedField",
  "name": "pendingConsents",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    },
    {
      "alias": "source",
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v24 = [
  (v6/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "GigPosition",
  "kind": "LinkedField",
  "name": "position",
  "plural": false,
  "selections": (v24/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "GigSeries",
  "kind": "LinkedField",
  "name": "gigSeries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        }
      ],
      "concreteType": "GigFieldItemDefinitionsConnection",
      "kind": "LinkedField",
      "name": "unmetQualifications",
      "plural": false,
      "selections": [
        (v26/*: any*/)
      ],
      "storageKey": "unmetQualifications(first:0)"
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeId",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentType",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "GigState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": [
    (v11/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "Gig",
  "kind": "LinkedField",
  "name": "gig",
  "plural": false,
  "selections": [
    (v23/*: any*/),
    (v25/*: any*/),
    (v27/*: any*/),
    (v6/*: any*/),
    (v28/*: any*/),
    (v29/*: any*/),
    (v30/*: any*/),
    (v31/*: any*/),
    (v32/*: any*/)
  ],
  "storageKey": null
},
v34 = {
  "kind": "InlineFragment",
  "selections": [
    (v22/*: any*/),
    (v33/*: any*/),
    (v6/*: any*/)
  ],
  "type": "Engagement",
  "abstractKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v36 = {
  "kind": "InlineFragment",
  "selections": [
    (v22/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v23/*: any*/),
        (v25/*: any*/),
        (v27/*: any*/),
        (v6/*: any*/),
        (v32/*: any*/),
        (v28/*: any*/),
        (v29/*: any*/),
        (v30/*: any*/),
        (v31/*: any*/)
      ],
      "storageKey": null
    },
    (v35/*: any*/)
  ],
  "type": "AvailableGigsEdge",
  "abstractKey": null
},
v37 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v38 = [
  (v37/*: any*/),
  {
    "fields": [
      (v9/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "applyGigStepperQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useGigPositions_gigLike"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "useGigFields_gigOrEngagement",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "GigSeries",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "Gig",
                    "abstractKey": null
                  }
                ],
                "type": "GigLike",
                "abstractKey": "__isGigLike"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useEnrollmentData_worker"
              },
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "gigId",
                    "variableName": "gigId"
                  },
                  {
                    "kind": "Variable",
                    "name": "isApply",
                    "variableName": "isApply"
                  },
                  {
                    "kind": "Variable",
                    "name": "isSeries",
                    "variableName": "isSeries"
                  },
                  (v9/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "useApplyMetadata_worker"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "applyGigStepperQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "GigSeries",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "Gig",
                "abstractKey": null
              }
            ],
            "type": "GigLike",
            "abstractKey": "__isGigLike"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEmail",
                "kind": "LinkedField",
                "name": "primaryEmail",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerLegalInformation",
                "kind": "LinkedField",
                "name": "legalInformation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sendReportCopies",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "middleName",
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v18/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailAddress",
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundCheckOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "driverCheckOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "driversLicenseLast4",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "driversLicenseIssuer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "drugScreenOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pspTosAccepted",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerVerification",
                "kind": "LinkedField",
                "name": "verification",
                "plural": false,
                "selections": [
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VerificationReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusDescription",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayableAccount",
                "kind": "LinkedField",
                "name": "payableAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentMethodId",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "paymentMethod",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accountHolderName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isAvailableGigOrEngagement"
                      },
                      (v34/*: any*/),
                      (v36/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v24/*: any*/),
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "AvailableGigSeriesEdge",
                    "kind": "LinkedField",
                    "name": "gigSeries",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeries",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v30/*: any*/),
                          (v28/*: any*/),
                          {
                            "condition": "isApply",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "alias": "nextOffered",
                                "args": [
                                  (v37/*: any*/),
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE currentStateName = OFFERED"
                                  }
                                ],
                                "concreteType": "EngagementsConnection",
                                "kind": "LinkedField",
                                "name": "engagements",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Engagement",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v10/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v22/*: any*/),
                                                  (v33/*: any*/)
                                                ],
                                                "type": "Engagement",
                                                "abstractKey": null
                                              },
                                              (v36/*: any*/)
                                            ],
                                            "type": "AvailableGigOrEngagement",
                                            "abstractKey": "__isAvailableGigOrEngagement"
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v26/*: any*/)
                                ],
                                "storageKey": "engagements(first:1,query:\"WHERE currentStateName = OFFERED\")"
                              }
                            ]
                          },
                          {
                            "condition": "isApply",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "alias": "nextGig",
                                "args": (v38/*: any*/),
                                "concreteType": "AvailableGigsConnection",
                                "kind": "LinkedField",
                                "name": "availableGigs",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AvailableGigsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "cursor",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Gig",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v10/*: any*/),
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v10/*: any*/),
                                          (v34/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v22/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Gig",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v23/*: any*/),
                                                  (v25/*: any*/),
                                                  (v27/*: any*/),
                                                  (v32/*: any*/),
                                                  (v28/*: any*/),
                                                  (v29/*: any*/),
                                                  (v30/*: any*/),
                                                  (v31/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v35/*: any*/)
                                            ],
                                            "type": "AvailableGigsEdge",
                                            "abstractKey": null
                                          }
                                        ],
                                        "type": "AvailableGigOrEngagement",
                                        "abstractKey": "__isAvailableGigOrEngagement"
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v26/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "endCursor",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hasNextPage",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": "nextGig",
                                "args": (v38/*: any*/),
                                "filters": [
                                  "input"
                                ],
                                "handle": "connection",
                                "key": "useApplyMetadataQuery_nextGig",
                                "kind": "LinkedHandle",
                                "name": "availableGigs"
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:eDS2LtKBEA59FwhrYxzdqow1EHABcuZKdIPfeg51qas",
    "metadata": {},
    "name": "applyGigStepperQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "93b56da4dba1db37792b298966bc72ab";

export default node;
