import trackFg from "./useTrackLocation.foreground";

const useForegroundTracking = trackFg();

export default function useTrackLocation(
  shouldRequest = false,
  hasActiveGig = false
) {
  useForegroundTracking(shouldRequest, hasActiveGig);
}
