import type { AppScreenProps } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import OrganizationReviewsList from "@gigsmart/seibutsu/organization/ReviewsList";
import React from "react";
import type { WorkerParamList } from "../navigation/types";
import type { organizationReviewsScreenQuery } from "./__generated__/organizationReviewsScreenQuery.graphql";

type Props = AppScreenProps<WorkerParamList, "OrganizationReviews">;

export default createSuspendedQueryContainer<
  organizationReviewsScreenQuery,
  Props
>(
  function OrganizationReviewsScreen({ response: { node } = { node: null } }) {
    return node ? <OrganizationReviewsList fragmentRef={node} /> : null;
  },
  {
    query: graphql`
      query organizationReviewsScreenQuery($id: ID!) {
        node(id: $id) {
          ...ReviewsList_Organization
        }
      }
    `,
    variables: (props) => ({ id: props.route.params.orgId })
  }
);
