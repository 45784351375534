import {
  type GlobalOnSubscriptionNext,
  type RelayMiddleware,
  RelayRequest,
  type RelayRequestAny,
  RelayRequestBatch,
  createRawMiddleware
} from "@gigsmart/relay";
import * as Sentry from "@sentry/react-native";

function getRequestType(req: RelayRequest) {
  return req.isMutation() ? "mutation" : "query";
}

function getRequestMetaData(req: RelayRequestAny) {
  let data;
  let id;
  let requestType: "batch" | "custom" | "mutation" | "query" | "subscription";
  let name;

  if (req instanceof RelayRequest) {
    id = req.getID();
    requestType = getRequestType(req);
    name = req.operation.name;
    data = {
      id,
      query: req.getQueryString() ?? req.operation.metadata.originalText,
      ...(__DEV__ || process.env.IS_TESTING === "true"
        ? { variables: req.getVariables() }
        : {})
    };
  } else if (req instanceof RelayRequestBatch) {
    requestType = "batch";
    id = req.getID();
    name = req.requests
      .map((r) => `${getRequestType(r)}.${r.operation.name}`)
      .join(", ");
    data = { id, requestList: req.requests };
  } else {
    name = "custom";
    requestType = "custom";
    id = "CustomRequest";
    data = { id };
  }
  return { id, data, requestType, name };
}

export const sentrySubscriptionBreadcrumbs: GlobalOnSubscriptionNext = (
  data: any,
  { subscription, variables }
) => {
  Sentry.addBreadcrumb({
    category: "relay.subscription",
    level: data?.errors ? "error" : "info",
    data: {
      id: (subscription as any).operation?.name || "Unknown",
      ...(__DEV__ || process.env.IS_TESTING === "true" ? { variables } : {}),
      ...(data?.errors ? { errors: data?.errors } : {})
    }
  });
};

export const sentryTracer = createRawMiddleware((next) => async (req) => {
  const { data, requestType, name } = getRequestMetaData(req);
  return await Sentry.startSpan(
    { op: `relay.${requestType}`, name },
    async (span) => {
      Sentry.setExtras(data);
      req.fetchOpts.headers["sentry-trace"] = span.spanContext().traceId;
      const response = await next(req);
      return response;
    }
  );
});

export const sentryBreadcrumbs: RelayMiddleware = (next) => async (req) => {
  const { id, data, requestType } = getRequestMetaData(req);

  Sentry.addBreadcrumb({
    category: `relay.${requestType}.request`,
    level: "info",
    data
  });

  const t0 = Date.now();
  try {
    const res = await next(req);
    Sentry.addBreadcrumb({
      category: `relay.${requestType}.response`,
      level: res.errors ? "warning" : "info",
      data: {
        id,
        status: res.status,
        ...(res.errors ? { errors: JSON.stringify(res.errors) } : {}),
        duration: Date.now() - t0
      }
    });
    return res;
  } catch (error) {
    Sentry.addBreadcrumb({
      category: `relay.${requestType}.response`,
      level: "error",
      data: {
        id,
        error,
        duration: Date.now() - t0
      }
    });
    throw error;
  }
};
