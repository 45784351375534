import { Column, SwipeCarousel } from "@gigsmart/atorasu";
import React from "react";
import FindGigBanner from "../svgs/FindGigBanner";
import GetPaidBanner from "../svgs/GetPaidBanner";
import WorkTermsBanner from "../svgs/WorkTermsBanner";

interface Props {
  onIndexChanged?: (index: number) => void;
}
export default function GetGigsSlider({ onIndexChanged }: Props) {
  const swiperData = [
    {
      title: "Find Your Next Gig Quickly",
      subtitle:
        "Instantly access thousands of available hourly Shift Gigs, residential Project Gigs, and full/part-time jobs hiring in your area.",
      content: () => (
        <Column fill justifyContent="flex-end" alignItems="center">
          <FindGigBanner />
        </Column>
      )
    },
    {
      title: "Work On Your Terms",
      subtitle:
        "GigSmart gives you freedom and control to find the right work opportunities best suited to your skillset and availability.",
      content: () => (
        <Column fill justifyContent="flex-end" alignItems="center">
          <WorkTermsBanner />
        </Column>
      )
    },
    {
      title: "Get Paid Fast",
      subtitle:
        "Easily access and transfer your earnings after completing a Shift. GigSmart makes it easy so you can focus on doing more of what you love.",
      content: () => (
        <Column fill justifyContent="flex-end" alignItems="center">
          <GetPaidBanner />
        </Column>
      )
    }
  ];

  return (
    <SwipeCarousel data={swiperData} loop onIndexChanged={onIndexChanged} />
  );
}
