/**
 * @generated SignedSource<<b5e6d174785e76e90919778289ce64e9>>
 * @relayHash 27b129854e70cab7984a078313cbfb56
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:D2WKiHxbT-4gWn6O5GhL3gfBZs4s_CA7q_17rwjvqhw

import { ConcreteRequest } from 'relay-runtime';
export type AddUserFileInput = {
  description?: string | null | undefined;
  file: string;
};
export type mediaPickerMutation$variables = {
  input: AddUserFileInput;
};
export type mediaPickerMutation$data = {
  readonly addUserFile: {
    readonly newUserFileEdge: {
      readonly node: {
        readonly id: string;
        readonly mimeType: string;
        readonly url: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type mediaPickerMutation = {
  response: mediaPickerMutation$data;
  variables: mediaPickerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddUserFilePayload",
    "kind": "LinkedField",
    "name": "addUserFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserFilesEdge",
        "kind": "LinkedField",
        "name": "newUserFileEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFile",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mimeType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mediaPickerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mediaPickerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:D2WKiHxbT-4gWn6O5GhL3gfBZs4s_CA7q_17rwjvqhw",
    "metadata": {},
    "name": "mediaPickerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f8e293de660fc189a00acb5fbfa1e628";

export default node;
