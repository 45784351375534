import {
  Button,
  Screen,
  Spacer,
  toast,
  useHistoryBlock
} from "@gigsmart/atorasu";
import type { AppScreenProps } from "@gigsmart/kaizoku";
import { ContentWrapper, StyledScrollView } from "@gigsmart/katana";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import EditTimesheetForm from "@gigsmart/seibutsu/engagement/EditTimesheetForm";
import EngagementInfoRow from "@gigsmart/seibutsu/engagement/EngagementInfoRow";
import {
  useEditTimesheet,
  useSetEngagementTimesheet
} from "@gigsmart/seibutsu/engagement/timesheetEditHooks";
import React, { type ComponentProps, useState } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { editTimesheetScreenQuery } from "./__generated__/editTimesheetScreenQuery.graphql";

type Props =
  | AppScreenProps<WorkerParamList, "EditTimesheet">
  | AppScreenProps<WorkerParamList, "CreateTimesheet">;

export default createSuspendedQueryContainer<editTimesheetScreenQuery, Props>(
  function EditTimesheetScreen({ route, navigation, response }) {
    const engagementId = route.params?.id ?? "";
    const isCreate = route?.name === "CreateTimesheet";
    const [shouldBlock, setShouldBlock] = useState(true);
    const unblock = useHistoryBlock({ enabled: shouldBlock });
    const engagement = response?.node;
    const setTimesheet = useSetEngagementTimesheet();
    const editTimesheet = useEditTimesheet({
      engagementId,
      timesheetId: engagement?.timesheet?.id,
      timezone: engagement?.gig?.timezone ?? ""
    });
    const handleEditTimesheet: ComponentProps<
      typeof EditTimesheetForm
    >["onSubmit"] = async (
      values: any,
      breaks: any,
      states: any,
      originalStartsAt: any,
      done: any
    ) => {
      try {
        await editTimesheet(values, breaks, states, originalStartsAt);
        unblock();
        toast.success(
          `Timesheet successfully ${isCreate ? "created" : "edited"}!`
        );
        if (isCreate) {
          navigation.replace("ReviewTimesheet", { id: engagementId });
        } else {
          navigation.goBack();
        }
      } catch (err: any) {
        if (!err.message?.includes("invalid permission"))
          toast.error(err.message);
        done();
      }
    };

    const handleRemoveTimeWorked = async () => {
      setShouldBlock(false);
      try {
        await setTimesheet(engagement, "remove-time");
        toast.success("Time worked successfully removed");
        navigation.goBack();
      } catch (err: any) {
        setShouldBlock(true);
        toast.error(err.message ?? "Something went wrong.");
      }
    };
    return (
      <Screen testID="edit-timesheet-screen">
        <StyledScrollView color="transparent">
          <ContentWrapper variant="xs">
            <EngagementInfoRow fragmentRef={engagement} />
            <Spacer />
            <EditTimesheetForm
              fragmentRef={engagement}
              create={isCreate}
              title="What time did you start and end working?"
              submitLabel={isCreate ? "Finish Timesheet" : "Save Changes"}
              onSubmit={handleEditTimesheet}
              onRemoveTimeWorked={handleRemoveTimeWorked}
              appVariant="worker"
              extraActions={
                <Button
                  testID="cancel-engagement-btn"
                  label="I did NOT Work this Shift Gig"
                  icon="ban"
                  outline
                  onPress={() =>
                    navigation.push("CancelEngagement", { id: engagementId })
                  }
                />
              }
            />
          </ContentWrapper>
        </StyledScrollView>
      </Screen>
    );
  },
  {
    query: graphql`
      query editTimesheetScreenQuery($id: ID!) {
        node(id: $id) {
          ... on Engagement {
            ...timesheetEditHooksReset_engagement
            ...EngagementInfoRow_engagement
            ...EditTimesheetForm_engagement
              @arguments(overridden: true, variant: WORKER)
            timesheet {
              id
            }
            gig {
              timezone
            }
          }
        }
      }
    `,
    variables: ({ route }) => ({ id: route.params?.id ?? "" })
  }
);
