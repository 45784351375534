import { ContentArea, Spacer, Surface, Text } from "@gigsmart/atorasu";
import React from "react";
import FomuMoneyInput, {
  type Props as FomuMoneyInputProps
} from "../fomu/inputs/FomuMoneyInput";

export interface Props extends FomuMoneyInputProps {
  title: string;
}

export default function CommentQuestion({ title, ...restProps }: Props) {
  return (
    <Surface>
      <ContentArea>
        <Text weight="bold" testID="comment-question-title">
          {title}
        </Text>
        <Spacer />
        <FomuMoneyInput {...restProps} />
      </ContentArea>
    </Surface>
  );
}
