import {
  Icon,
  type IconName,
  IconText,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import { Card, StyledTextInput } from "@gigsmart/katana";
import React, {
  type ComponentProps,
  type ReactNode,
  useEffect,
  useState
} from "react";
import { LayoutAnimation, View } from "react-native";

type Props = Pick<
  ComponentProps<typeof StyledTextInput>,
  "value" | "onChangeText" | "testID" | "placeholder"
> & {
  iconName?: IconName;
  title: string;
  collapsed?: boolean;
  collapsedContent?: ReactNode;
  renderTopComponent?: () => ReactNode;
};

const setupAnimation = () =>
  LayoutAnimation.configureNext({
    duration: 150,
    create: {
      type: LayoutAnimation.Types.linear,
      property: LayoutAnimation.Properties.opacity
    },
    update: {
      type: LayoutAnimation.Types.easeInEaseOut,
      property: LayoutAnimation.Properties.opacity
    }
  });

const SearchListHeader = ({
  iconName,
  title,
  collapsedContent,
  collapsed = false,
  renderTopComponent,
  ...inputProps
}: Props) => {
  const [showCollapsed, setShowCollapsed] = useState(!collapsed);
  const styles = useStyles(({ getUnits }) => ({
    visibleContent: {
      paddingTop: getUnits(4),
      paddingBottom: getUnits(2),
      paddingHorizontal: getUnits(4)
    },
    center: { justifyContent: "center" }
  }));

  useEffect(() => {
    if (collapsed !== showCollapsed) return;
    setupAnimation();
    setShowCollapsed(!collapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  return (
    <Card noMargin noSpacing noRadius>
      <View style={styles.visibleContent}>
        <Row alignContent="center" justifyContent="space-between">
          <View style={styles.center}>
            {iconName ? (
              <IconText icon={iconName} color="primary">
                {title}
              </IconText>
            ) : (
              <Text>{title}</Text>
            )}
          </View>
          {renderTopComponent?.()}
        </Row>
        <Spacer size="medium" />
        <StyledTextInput
          placeholder="Search by title, company, industry"
          eventTargetName="Input Search List Header"
          clearable
          renderLeftAccessory={() => (
            <Icon name="search" variant="solid" color="neutral" size="small" />
          )}
          {...inputProps}
        />
      </View>
      {showCollapsed ? collapsedContent : <Spacer size="compact" />}
    </Card>
  );
};

export default SearchListHeader;
