import {
  type Color,
  type IconName,
  Pressable,
  Spacer,
  Tag,
  TagContainer,
  Text,
  WysiwygContent,
  useStyles
} from "@gigsmart/atorasu";
import {
  Card,
  ClearInlineButton,
  FadedContainer,
  StyledView,
  ThumbnailRow
} from "@gigsmart/katana";
import React from "react";
import { Platform, View } from "react-native";

interface Props {
  title?: string | null;
  company?: string | null;
  tags?: Array<
    | string
    | { title?: string | null; icon: IconName; color: Color }
    | undefined
    | null
  >;
  description: string;
  onPress?: () => void;
  affiliate?: boolean;
}

const JobCard = ({
  affiliate,
  title,
  company,
  description,
  tags,
  onPress
}: Props) => {
  const styles = useStyles(({ getUnits, getColor }) => ({
    header: {
      paddingHorizontal: getUnits(4),
      paddingTop: getUnits(4),
      paddingBottom: getUnits(1)
    },
    companyName: { marginLeft: getUnits(12), marginRight: getUnits(4) },
    description: {
      color: getColor("neutral", "fill"),
      marginHorizontal: getUnits(4),
      marginVertical: getUnits(3)
    }
  }));

  const tagElements = tags
    ?.map((tag, index) =>
      typeof tag === "string" ? (
        <Tag label={tag} key={`${tag}${index}`} />
      ) : tag?.title ? (
        <Tag
          label={tag.title}
          key={`${tag.title}${index}`}
          color={tag.color}
          icon={tag.icon}
        />
      ) : null
    )
    .filter((it): it is JSX.Element => !!it);

  return (
    <Pressable
      testID="job-card"
      onPress={onPress}
      eventTargetName="Job Card"
      eventEntityType="JobCard"
    >
      <Card>
        <View style={styles.header}>
          <ThumbnailRow
            iconName="newspaper"
            tintColor="neutralDark"
            label={title ?? ""}
            rightAccessory={
              <ClearInlineButton
                disabled
                title={affiliate ? "Open" : "View"}
                iconName={affiliate ? "external-link" : undefined}
                iconPos={affiliate ? "right" : undefined}
              />
            }
          />
        </View>
        <Text style={styles.companyName}>{company}</Text>
        <Spacer size="medium" />
        <FadedContainer>
          <StyledView verticalPadding={0} color="transparent">
            <WysiwygContent
              fontSize={Platform.OS !== "web" ? 12 : undefined}
              html={description}
            />
          </StyledView>
        </FadedContainer>
        {!!tagElements?.length && (
          <TagContainer tags={tagElements} size="none" />
        )}
        <Spacer size="medium" />
      </Card>
    </Pressable>
  );
};

export default JobCard;
