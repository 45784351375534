import { Pressable } from "@gigsmart/atorasu";
import { usePlacementColor } from "@gigsmart/atorasu/style";
import fontColorContrast from "font-color-contrast";
import React from "react";
import Svg, { Path } from "react-native-svg";

interface Props {
  height: number;
  onPress?: () => void;
}

export default function Logo({ onPress, height, ...props }: Props) {
  const {
    spec: { fill } = {}
  } = usePlacementColor("title");
  const contrastColor = fontColorContrast(fill ?? "#ffffff", 0.6);
  const bug = fill === "#ffffff" ? "#d54405" : contrastColor;
  const name = fill === "#ffffff" ? "#003b5c" : contrastColor;

  return (
    <Pressable
      onPress={onPress}
      testID="brandmark"
      eventTargetName="GigSmart Brandmark Pressable"
      eventEntityType="GigSmartBrandmarkPressable"
    >
      <Svg
        viewBox="0 0 255 44"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={Math.SQRT2}
        width={height * (255 / 44)}
        height={height}
        {...props}
      >
        <Path
          d="M47.294 22.022c0-9.227 7.05-15.016 15.712-15.016 6.485 0 10.402 3.439 12.404 7.051l-6.268 3.265c-1.088-1.916-3.308-3.7-6.136-3.7-4.745 0-8.096 3.612-8.096 8.4 0 4.744 3.351 8.4 8.096 8.4 2.133 0 4.309-.87 5.353-1.74v-2.115h-6.528v-5.855h14.014V31.38c-3.09 3.438-7.355 5.658-12.84 5.658-8.66 0-15.712-5.788-15.712-15.016"
          fill={name}
          fillRule="nonzero"
        />
        <Path fill={name} d="M79.805 7.485h7.486v29.03h-7.486z" />
        <Path
          d="M90.598 22.022c0-9.227 7.051-15.016 15.712-15.016 6.485 0 10.403 3.439 12.405 7.051l-6.268 3.265c-1.088-1.916-3.308-3.7-6.137-3.7-4.744 0-8.095 3.612-8.095 8.4 0 4.744 3.351 8.4 8.095 8.4 2.133 0 4.31-.87 5.354-1.74v-2.115h-6.529v-5.855h14.015V31.38c-3.09 3.438-7.355 5.658-12.84 5.658-8.66 0-15.712-5.788-15.712-15.016M122.152 32.424l2.176-2.785c1.872 2.176 5.005 4.178 9.097 4.178 5.18 0 6.833-2.785 6.833-5.049 0-7.486-17.192-3.307-17.192-13.666 0-4.788 4.266-8.095 10.01-8.095 4.397 0 7.835 1.523 10.272 4.091l-2.177 2.654c-2.175-2.437-5.222-3.481-8.4-3.481-3.438 0-5.962 1.871-5.962 4.613 0 6.528 17.192 2.786 17.192 13.623 0 4.179-2.873 8.531-10.707 8.531-5.005 0-8.748-1.915-11.142-4.614M173.118 36.515V12.1l-9.836 24.416h-1.48l-9.88-24.416v24.416h-3.613V7.485h5.18l9.053 22.371 9.01-22.372h5.179v29.031h-3.613zM193.355 11.184l-6.137 15.67h12.318l-6.181-15.67zm9.793 25.332l-2.568-6.486h-14.45l-2.568 6.486h-4.135l11.708-29.031h4.483l11.665 29.03h-4.135zM227.042 16.233c0-3.307-2.394-5.527-5.833-5.527h-7.616v11.099h7.616c3.44 0 5.833-2.308 5.833-5.572m-.261 20.283l-7.356-11.534h-5.832v11.534h-3.613V7.485h11.665c5.31 0 9.14 3.395 9.14 8.748 0 5.223-3.613 8.096-7.574 8.444l7.835 11.839h-4.265zM242.144 36.515v-25.81h-9.184v-3.22h22.023v3.22h-9.226v25.81h-3.613z"
          fill={name}
          fillRule="nonzero"
        />
        <Path
          d="M8.258 8.258A19.307 19.307 0 0122 2.567V0C9.85 0 0 9.85 0 22h2.567c0-5.19 2.021-10.071 5.691-13.742M22 41.433V44a21.91 21.91 0 0014.141-5.148l-1.65-1.965A19.29 19.29 0 0122 41.433M15.152 22h-2.567c0 5.2 4.215 9.415 9.415 9.415v-2.567A6.856 6.856 0 0115.152 22M22 16.78v.001a2.656 2.656 0 01-2.653-2.653A2.655 2.655 0 0122 11.475V8.908a5.22 5.22 0 000 10.44v-.001A2.656 2.656 0 0124.653 22 2.656 2.656 0 0122 24.653v2.567a5.22 5.22 0 000-10.44"
          fill={bug}
          fillRule="nonzero"
        />
        <Path
          d="M22 33.043c-6.09 0-11.043-4.954-11.043-11.043 0-2.01.54-3.895 1.481-5.52l-2.226-1.286A13.548 13.548 0 008.39 22c0 7.517 6.094 13.61 13.61 13.61 6.517 0 11.962-4.58 13.296-10.696h-2.644c-1.281 4.68-5.571 8.13-10.652 8.13"
          fill={bug}
          fillRule="nonzero"
        />
        <Path
          d="M28.849 20.717v2.566h8.335a15.118 15.118 0 01-4.41 9.492A15.138 15.138 0 0122 37.238a15.14 15.14 0 01-10.775-4.463A15.138 15.138 0 016.762 22a15.14 15.14 0 014.463-10.775A15.14 15.14 0 0122 6.762c3.624 0 7.055 1.257 9.795 3.565l1.65-1.966A17.73 17.73 0 0022 4.195C12.167 4.195 4.195 12.167 4.195 22c0 9.833 7.972 17.805 17.805 17.805 9.402 0 17.1-7.287 17.759-16.522.03-.424.046-2.566.046-2.566H28.848z"
          fill={bug}
          fillRule="nonzero"
        />
      </Svg>
    </Pressable>
  );
}

Logo.defaultProps = {
  height: 22
};
