import {
  IconCircle,
  Stack,
  Text,
  showEmbeddedBrowser,
  showModal
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import React from "react";

interface Props {
  variant?: "worker" | "requester";
}

export function MileageTrackingModalContent({ variant = "requester" }: Props) {
  return (
    <Stack>
      <IconCircle
        alignSelf="center"
        icon="truck-fast"
        size="large"
        color="primary"
        variant="well"
      />
      {variant === "requester" && (
        <Text>
          You can use GigSmart Get Workers for all your transporation staffing
          needs.
        </Text>
      )}
      {variant === "requester" ? (
        PositionsAndLocations.select(
          <Text>
            Please provide the estimated total distance each Worker will be
            required to travel while working a Shift for this Position. The
            distance provided should not include commuting to and from the Shift
            location. This information will be used to provide accurate Hired
            and Non-Owned Auto Insurance.
          </Text>,
          <Text>
            Please provide the total distance each Worker will be required to
            travel during the Gig. The distance provided should not include
            commuting to and from the Gig address. This information will be used
            to provide accurate Hired and Non-Owned Auto Insurance for the Gig.
          </Text>
        )
      ) : (
        <Text>
          In order to provide you with accurate insurance coverage during Gigs,
          we collect distance traveled on Shifts that require traveling or
          delivery during the Shift.
        </Text>
      )}
    </Stack>
  );
}

export function showMileageTrackingInfoModal(variant: "worker" | "requester") {
  return showModal({
    testID: "mileage-tracking-info-modal",
    eventContext: "Mileage Tracking Learn More Modal",
    title: "About Traveling / Delivery Mileage Tracking",
    actions: [
      {
        testID: "learn-more-btn",
        label: "Learn More",
        onPress: () =>
          showEmbeddedBrowser({
            url:
              variant === "requester"
                ? "https://gigsmart.com/automotive-insurance/"
                : "https://gigsmart.com/find-workers/automotive-insurance/"
          })
      },
      { testID: "got-it", label: "Got It" }
    ],
    children: <MileageTrackingModalContent variant={variant} />
  });
}
