import { Floating, ProgressStrip } from "@gigsmart/atorasu";
import { useRelayLoader } from "@gigsmart/relay";
import React from "react";

export default function Loader() {
  const { isLoading, progress } = useRelayLoader();
  return (
    <Floating position="bottom">
      <ProgressStrip show={isLoading} progress={progress} />
    </Floating>
  );
}
