import React from "react";

import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";

import { useQualifications } from "./hooks/useQualifications";

const ToolsEquipmentScreen = () => {
  const {
    title,
    subTitle,
    reminderText,
    iconName,
    options,
    checkOption,
    onBackPress
  } = useQualifications({
    qualificationTitle: "Tools & Equipment"
  });
  if (!options || options?.length === 0) return null;
  return (
    <ScreenScroll testID="toolsEquipmentScreen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="toolsEquipment-selection"
          header={title ?? ""}
          note={subTitle}
          searchHeader="Do you have the following Tools & Equipment?"
          searchPlaceholder="Search Tools & Equipment"
          selectionRowHeader="Tools & Equipment"
          selectionRowHeaderIcon={iconName}
          options={options}
          reminder={reminderText}
          onCheckOption={checkOption}
        />
      </ContentArea>
    </ScreenScroll>
  );
};

export default ToolsEquipmentScreen;
