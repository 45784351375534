import * as React from "react";
import Svg, { Defs, Rect, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

export default function PriceTagSvg() {
  return (
    <Svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      height="100%"
      width="100%"
    >
      <Defs />
      <Rect
        x={0.219}
        y={88.184}
        width={99.562}
        height={11.816}
        rx={2.026}
        fill="#e7e7e7"
      />
      <Path
        fill="none"
        d="M33.042 21.703c-.094-.008-.186-.028-.281-.028a3.558 3.558 0 00-2.516 6.074c.042.042.092.07.136.11a4.424 4.424 0 00.748-.61 6.707 6.707 0 001.913-5.546zM32.47 28.73a3.525 3.525 0 002.806-6.013c-.057-.058-.126-.1-.187-.153a8.688 8.688 0 01-2.546 6.098c-.025.025-.048.044-.073.068z"
      />
      <Path
        fill="none"
        d="M36.69 21.303a5.607 5.607 0 11-4.21-1.6 6.652 6.652 0 00-.693-1.179 5.934 5.934 0 00-5.73 6.372l1.449 19.726a.858.858 0 00.247.538l27.405 27.405a.852.852 0 001.202 0l23.733-23.731a.85.85 0 000-1.203L52.687 20.226a.846.846 0 00-.538-.247L34.2 18.66a8.677 8.677 0 01.544 1.392 5.528 5.528 0 011.947 1.251zm19.444 5.583l19.083 19.082a4 4 0 010 5.657L59.22 67.622a4 4 0 01-5.657 0L34.48 48.54a4 4 0 010-5.657l15.997-15.997a4 4 0 015.657 0z"
      />
      <Path
        fill="none"
        d="M21.626 17.745a6.727 6.727 0 000 9.503 6.653 6.653 0 002.72 1.647l-.282-3.853a7.93 7.93 0 015.856-8.249 6.725 6.725 0 00-8.294.952z"
      />
      <Path
        d="M43.537 29.76H66.16a4 4 0 014 4v26.987a4 4 0 01-4 4H43.537a4 4 0 01-4-4V33.761a4 4 0 014-4z"
        transform="rotate(-45 54.849 47.254)"
        fill="#fbb349"
        opacity={0.8}
      />
      <Path
        fill="#898b8d"
        d="M81.507 46.216L54.103 18.813a2.85 2.85 0 00-1.808-.828l-19.556-1.436c-.067-.072-.126-.147-.196-.217a8.72 8.72 0 00-12.331 12.331 8.619 8.619 0 004.29 2.348l1.01 13.76a2.852 2.852 0 00.827 1.804L53.744 73.98a2.855 2.855 0 004.03 0l23.733-23.732a2.85 2.85 0 000-4.032zM32.76 21.676c.095 0 .187.02.28.027a6.707 6.707 0 01-1.912 5.546 4.424 4.424 0 01-.748.61c-.044-.04-.094-.068-.136-.11a3.558 3.558 0 012.516-6.074zm2.328.888c.061.053.13.095.187.153a3.515 3.515 0 01-2.806 6.013c.025-.024.048-.043.073-.068a8.688 8.688 0 002.546-6.098zm-13.463 4.684a6.716 6.716 0 018.294-10.455 7.93 7.93 0 00-5.857 8.25l.283 3.852a6.653 6.653 0 01-2.72-1.647zm58.467 21.585L56.36 72.566a.852.852 0 01-1.202 0L27.753 45.16a.858.858 0 01-.247-.538l-1.448-19.726a5.934 5.934 0 015.73-6.372 6.652 6.652 0 01.692 1.179 5.543 5.543 0 102.263.349 8.677 8.677 0 00-.544-1.392l17.95 1.32a.846.846 0 01.538.246l27.406 27.405a.85.85 0 010 1.202zM46.683 72.955c-2.913 1.733-5.246 2.344-6.939 1.814a4.654 4.654 0 01-2.753-2.791c-2.166-4.766-7.816-17.556-7.873-17.685a1 1 0 00-1.83.809c.058.128 5.712 12.928 7.883 17.703a6.606 6.606 0 003.975 3.872 6.286 6.286 0 001.895.281 13.51 13.51 0 006.665-2.284 1 1 0 10-1.023-1.719z"
      />
    </Svg>
  );
}
