import {
  Button,
  Column,
  GridNull,
  TopicPreviewSurface
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import ShiftPreviewRow from "./ShiftPreviewRow";
import type { PickupShiftTopicPreview_worker$key } from "./__generated__/PickupShiftTopicPreview_worker.graphql";

function PickupShiftTopicPreview({
  availableGigs
}: FragmentContainerInnerComponentProps<PickupShiftTopicPreview_worker$key>) {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const gigsList = getConnectionEdges(availableGigs) ?? [];
  if (gigsList.length === 0) return <GridNull />;
  return (
    <TopicPreviewSurface
      testID="shift-topic-preview"
      iconName="alarm-clock"
      color="emphasized"
      title={PositionsAndLocations.select(
        "Shifts Available for Pick Up Today",
        "Shift Gigs Available for Pick Up Today"
      )}
      subtitle="Pick Up available Shifts in your area and work today!"
      variant="emphasized"
    >
      {gigsList.length > 0 && (
        <Column gap="standard">
          <Column gap="compact">
            {gigsList.map(({ node, estimatedPayment }) =>
              node ? (
                <ShiftPreviewRow
                  key={node.id}
                  name={node.name}
                  logoUrl={node.organization?.logoUrl}
                  id={node.id}
                  boosted={node.gigSeries?.boosted}
                  pickupEligible={node.pickupEligible}
                  netPay={estimatedPayment?.netPay}
                />
              ) : null
            )}
          </Column>
          <Button
            alignSelf="center"
            testID="view-all-shift-gigs-btn"
            size="small"
            outline
            label={PositionsAndLocations.select(
              "View All Available Shifts",
              "View All Available Shift Gigs"
            )}
            icon="chevron-right"
            iconPlacement="right"
            onPress={() => navigation.push("BrowseShifts", { type: "pickups" })}
          />
        </Column>
      )}
    </TopicPreviewSurface>
  );
}

export default createRelayFragmentContainer<PickupShiftTopicPreview_worker$key>(
  graphql`
    fragment PickupShiftTopicPreview_worker on Worker {
      availableGigs(
        first: 3
        gigTypes: [PAID, VOLUNTEER]
        maxDistance: 75
        pickupEligible: true
      ) {
        edges {
          estimatedPayment {
            payRate
            netPay
          }
          node {
            id
            name
            pickupEligible
            gigSeries {
              id
              boosted
            }
            organization {
              logoUrl
            }
          }
        }
        totalCount
      }
    }
  `,
  PickupShiftTopicPreview
);
