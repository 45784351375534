import {
  ContentArea,
  MultiSelectionRowGroup,
  ScreenScroll,
  ScreenTop
} from "@gigsmart/atorasu";
import React from "react";
import { useQualifications } from "./hooks/useQualifications";

export default function VehicleScreen() {
  const {
    subTitle,
    reminderText,
    iconName,
    options,
    onBackPress,
    checkOption
  } = useQualifications({
    qualificationTitle: "Vehicle Requirements"
  });
  if (!options || options?.length === 0) return null;
  return (
    <ScreenScroll testID="vehicle-screen" color="surface">
      <ScreenTop onBackPress={onBackPress} />
      <ContentArea>
        <MultiSelectionRowGroup
          testID="vehicle-screen-layout"
          header="Vehicles"
          note={subTitle}
          searchHeader="Do you have the following Vehicle types?"
          selectionRowHeader="Type of Vehicle"
          selectionRowHeaderIcon={iconName}
          reminder={reminderText}
          options={options}
          onCheckOption={checkOption}
          enableSearch={false}
        />
      </ContentArea>
    </ScreenScroll>
  );
}
