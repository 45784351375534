import {
  AbsoluteContainer,
  Column,
  ContentArea,
  FooterContent,
  IconButton,
  InputLabel,
  Row,
  ScreenScroll,
  ScreenTop,
  Stack,
  Text
} from "@gigsmart/atorasu";
import {
  type FomuSubmitFn,
  Form,
  Validator,
  useFormFields
} from "@gigsmart/fomu";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import {
  createRelayFragmentContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import { DateTime } from "luxon";
import React from "react";
import FomuConfirmExit from "../fomu/inputs/FomuConfirmExit";
import FomuPicker from "../fomu/inputs/FomuPicker";
import FomuSubmit from "../fomu/inputs/FomuSubmit";
import FomuTextInput from "../fomu/inputs/FomuTextInput";

import { toast } from "@gigsmart/atorasu";
import { StyledDatePicker } from "@gigsmart/katana";
import type { EducationFormAddMutation } from "./__generated__/EducationFormAddMutation.graphql";
import type { EducationFormUpdateMutation } from "./__generated__/EducationFormUpdateMutation.graphql";
import type { EducationForm_worker$key } from "./__generated__/EducationForm_worker.graphql";
import useDeleteProfileItem from "./useDeleteProfileItem";

const educationLevels = [
  "Less than High School",
  "High School Diploma or Equivalent",
  "Some College",
  "Post Secondary Award (Non-Degree)",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctoral or Professional Degree"
];

interface Props {
  educationLevel?: string | null;
  endDate?: any | null;
  id?: string;
  schoolName?: string | null;
  startDate?: any | null;
}

export const EducationForm = ({
  id,
  schoolName,
  educationLevel,
  startDate,
  endDate
}: Props) => {
  const [addEducation] = useRelayMutationPromise<EducationFormAddMutation>(
    graphql`
      mutation EducationFormAddMutation($input: AddWorkerEducationInput!) {
        addWorkerEducation(input: $input) {
          newWorkerEducationEdge {
            node {
              ...EducationForm_worker
            }
          }
        }
      }
    `
  );

  const [updateEducation] =
    useRelayMutationPromise<EducationFormUpdateMutation>(
      graphql`
        mutation EducationFormUpdateMutation(
          $input: UpdateWorkerEducationInput!
        ) {
          updateWorkerEducation(input: $input) {
            workerEducation {
              ...EducationForm_worker
            }
          }
        }
      `
    );

  const deleteItem = useDeleteProfileItem();

  const initialValues = {
    schoolName,
    educationLevel,
    educationStart: startDate,
    educationEnd: endDate
  };

  const save: FomuSubmitFn = async (
    { values: { schoolName, educationLevel, educationStart, educationEnd } },
    done,
    reset
  ) => {
    if (id) {
      await updateEducation({
        input: {
          schoolName,
          educationLevel,
          workerEducationId: id,
          startDate: educationStart,
          endDate: educationEnd
        }
      });
    } else {
      await addEducation({
        input: {
          schoolName,
          educationLevel,
          startDate: educationStart,
          endDate: educationEnd
        }
      });
    }

    reset();
    defaultBackHandler({ safeExit: true });
    toast.success("Education successfully saved!");
  };

  return (
    <Form initialValues={initialValues} onSubmit={save}>
      <ScreenScroll
        testID="education-form"
        color="surface"
        grow={false}
        footer={
          <FooterContent bgColor="surface">
            <FomuSubmit label="Save" testID="save-education" />
          </FooterContent>
        }
      >
        <ScreenTop showBackButton />
        <ContentArea>
          <Stack>
            <Text color="primary" variant="header">
              Education
            </Text>
            <Stack>
              {!!id && (
                <AbsoluteContainer right={0}>
                  <Row justifyContent="flex-end">
                    <IconButton
                      name="trash-alt"
                      testID="delete-education"
                      variant="solid"
                      color="primary"
                      onPress={() => deleteItem(id, "Education")}
                    />
                  </Row>
                </AbsoluteContainer>
              )}
              <FomuTextInput
                label="School Name"
                placeholder="School Name"
                name="schoolName"
                validates={Validator.presence()}
                autoCapitalize="sentences"
                autoCorrect={false}
              />
              <FomuPicker
                label="Level of Education"
                name="educationLevel"
                eventTargetName="Input Level of Education Picker"
                placeholder="Level of Education"
                validates={Validator.presence()}
                options={educationLevels.map((Level) => ({
                  value: Level,
                  label: Level
                }))}
              />
              <EducationPeriod />
            </Stack>
          </Stack>
        </ContentArea>
        <FomuConfirmExit />
      </ScreenScroll>
    </Form>
  );
};

function EducationPeriod() {
  const message = "Please add both Start and End Date";
  const { educationStart, educationEnd } = useFormFields({
    educationStart: [
      Validator.dependsOn({
        field: "educationEnd",
        message
      }),
      Validator.dependsOn({
        field: "educationEnd",
        predicate: ({ currentFieldValue, otherFieldValue }) =>
          !(currentFieldValue === otherFieldValue),
        message: "End Date cannot be the same as Start Date"
      })
    ],
    educationEnd: Validator.dependsOn({
      field: "educationStart",
      message
    })
  });

  return (
    <Stack size="slim">
      <InputLabel
        label="Dates (optional)"
        error={!!educationStart.errorMessage || !!educationEnd.errorMessage}
      />
      <Stack horizontal>
        <Column fill>
          <StyledDatePicker
            placeholder="Start"
            eventTargetName="Input Start Date Picker"
            value={educationStart.value}
            date={educationStart.value}
            maxDate={new Date()}
            onDateChange={(v) => {
              const startDate = DateTime.fromJSDate(v ?? new Date());
              educationStart.setValue(startDate.toFormat("yyyy-MM-dd"));
              if (
                (v?.getTime() ?? Date.now()) >
                new Date(educationEnd.value || Date.now()).getTime()
              ) {
                educationEnd.setValue(
                  startDate.plus({ day: 1 }).toFormat("yyyy-MM-dd")
                );
              }
              educationStart.showErrors();
            }}
            error={educationEnd.errorMessage}
          />
        </Column>
        <Column fill>
          <StyledDatePicker
            placeholder="End"
            eventTargetName="Input End Date Picker"
            value={educationEnd.value}
            date={educationEnd.value}
            minDate={DateTime.fromJSDate(
              new Date(educationStart.value || Date.now())
            )
              .plus({ day: 1 })
              .toJSDate()}
            maxDate={DateTime.fromJSDate(
              new Date(educationStart.value || Date.now())
            )
              .plus({ years: 10 })
              .toJSDate()}
            onDateChange={(v) => {
              educationEnd.setValue(
                DateTime.fromJSDate(v ?? new Date()).toFormat("yyyy-MM-dd")
              );
              educationEnd.showErrors();
            }}
            error={educationStart.errorMessage}
          />
        </Column>
      </Stack>
    </Stack>
  );
}

export default createRelayFragmentContainer<EducationForm_worker$key>(
  graphql`
    fragment EducationForm_worker on WorkerEducation {
      id
      schoolName
      educationLevel
      startDate
      endDate
    }
  `,
  EducationForm
);
