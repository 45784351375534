import React, { type ComponentProps } from "react";
import { Text, View } from "react-native";
import { type IconName, StyledIcon } from "../icon";
import { type ViewStyleProp, useStyles } from "../style";
import Tag from "../tag/tag";
import { StyledText } from "../text";

type Props = Omit<ComponentProps<typeof StyledText>, "children"> & {
  style?: ViewStyleProp;
  title: string;
  separator: boolean;
  icon?: IconName;
  iconColor?: string;
  titleStyle?: ViewStyleProp;
};

export default function CardTitle({
  style,
  title,
  separator,
  icon,
  iconColor,
  titleStyle,
  ...titleProps
}: Props) {
  const { styles, theme } = useStyles(({ font, units, color }) => ({
    titleContainer: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row"
    },
    title: {
      ...font.body("semibold"),
      fontSize: units(5),
      marginLeft: units(4.5),
      marginRight: units(3),
      marginTop: units(4)
    },
    separator: {
      height: 1,
      marginHorizontal: units(4.5),
      marginTop: units(1),
      backgroundColor: color.neutralLight
    },
    tag: { marginBottom: units(1.25), marginRight: units(4.5) }
  }));
  return (
    <View style={style}>
      <View style={styles.titleContainer}>
        <StyledText
          fontSize={theme.font.size.extraLarge}
          color="blue"
          {...titleProps}
          style={[styles.title, titleStyle]}
        >
          {icon && (
            <Text>
              <StyledIcon
                name={icon}
                color={iconColor}
                size={theme.units(5)}
                variant="solid"
              />
              &nbsp;
            </Text>
          )}
          {title}
        </StyledText>
        {title === "Post" && (
          <Tag title="Recommended" color="green" style={styles.tag} />
        )}
      </View>

      {separator && <View style={styles.separator} />}
    </View>
  );
}

CardTitle.defaultProps = {
  numberOfLines: 3,
  separator: true
};
