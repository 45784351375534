/**
 * @generated SignedSource<<e0b6c273ccf8f091d77060a95fe59856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftTopicPreview_worker$data = {
  readonly availableGigSeries: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly availableGigs: {
          readonly edges: ReadonlyArray<{
            readonly estimatedPayment: {
              readonly netPay: string;
              readonly payRate: string;
            } | null | undefined;
            readonly node: {
              readonly name: string | null | undefined;
              readonly organization: {
                readonly logoUrl: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number;
        } | null | undefined;
        readonly boosted: boolean;
        readonly category: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "ShiftTopicPreview_worker";
};
export type ShiftTopicPreview_worker$key = {
  readonly " $data"?: ShiftTopicPreview_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftTopicPreview_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftTopicPreview_worker",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "gigTypes",
              "value": [
                "PAID",
                "VOLUNTEER"
              ]
            },
            {
              "kind": "Variable",
              "name": "maxDistance",
              "variableName": "maxDistance"
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "AvailableGigSeriesConnection",
      "kind": "LinkedField",
      "name": "availableGigSeries",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableGigSeriesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSeries",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GigCategory",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "boosted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "AvailableGigsConnection",
                  "kind": "LinkedField",
                  "name": "availableGigs",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AvailableGigsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EngagementPaymentInfo",
                          "kind": "LinkedField",
                          "name": "estimatedPayment",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "payRate",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "netPay",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Gig",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Organization",
                              "kind": "LinkedField",
                              "name": "organization",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "logoUrl",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": "availableGigs(first:1)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "124caddb50a4523121c30c6c5a63819f";

export default node;
