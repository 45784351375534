import { InfoModal, Row, Stack, Text, showModal } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { graphql, readRelayInlineFragment } from "@gigsmart/relay";
import { Duration } from "luxon";
import React, { type ComponentProps } from "react";
import CancellationPoliciesInfoModal from "../gig/CancellationPoliciesInfoModal";
import type {
  CancellationFeeInfoModal_cancellationFee$data,
  CancellationFeeInfoModal_cancellationFee$key
} from "./__generated__/CancellationFeeInfoModal_cancellationFee.graphql";

interface Props {
  cancellationFee:
    | CancellationFeeInfoModal_cancellationFee$key
    | null
    | undefined;
  variant?: ComponentProps<typeof InfoModal>["variant"];
}

export default function CancellationFeeInfoModal({
  cancellationFee: cancellationFeeRef,
  ...props
}: Props) {
  const cancellationFee =
    cancellationFeeRef && readRelayInlineFragment(fragment, cancellationFeeRef);
  if (!cancellationFee) return null;
  const { startDelta, totalDue, feeRate, ...params } =
    getFeeData(cancellationFee);
  // const startDeltaDuration = Duration.fromISO(
  //   cancellationFee?.startDelta ?? ""
  // );
  // const before = startDeltaDuration.as("seconds") > 0;
  // const at = startDeltaDuration.as("seconds") === 0;
  // const timeFrame = at
  //   ? "at"
  //   : before
  //   ? `${formatDuration(startDeltaDuration)} before`
  //   : `${formatDuration(startDeltaDuration)} after`;
  // const seconds = startDelta.as("seconds");
  // const timeFrame =
  //   seconds === 0
  //     ? "This Worker is scheduled to work immediately"
  //     : `This Worker was scheduled to work`;

  return (
    <InfoModal testID="cancellation-fee-info-modal" {...props} {...params}>
      <Stack>
        <Text>
          You canceled this Worker when they were scheduled to work, and were
          charged {Math.round(feeRate * 100)}% of the Worker's Shift duration
          for a total of {currency.humanize(totalDue)}.
        </Text>
        <Row justifyContent="center">
          <CancellationPoliciesInfoModal testID="cancellation-fee-info-modal" />
        </Row>
      </Stack>
    </InfoModal>
  );
}

const fragment = graphql`
  fragment CancellationFeeInfoModal_cancellationFee on EngagementCancellationFeeLike
  @inline {
    totalDue
    feeRate
    startDelta
  }
`;

function getFeeData(
  cancellationFee: CancellationFeeInfoModal_cancellationFee$data
) {
  return {
    ...cancellationFee,
    startDelta: Duration.fromISO(cancellationFee.startDelta),
    title: "Cancellation Fees",
    eventContext: "Cancellation Fee Modal"
  };
}

export function showCancellationFeeModal(
  cancellationFeeRef: CancellationFeeInfoModal_cancellationFee$key | null,
  onConfirm: () => void,
  onRequestClose: () => void
) {
  const cancellationFee = readRelayInlineFragment(fragment, cancellationFeeRef);
  if (!cancellationFee) return;
  const { startDelta, totalDue, feeRate, ...params } =
    getFeeData(cancellationFee);
  // const startDeltaDuration = Duration.fromISO(
  //   cancellationFee?.startDelta ?? ""
  // );
  // const timeFrame = at
  //   ? "at"
  //   : `${formatDuration(startDeltaDuration)} ago`
  // const before = startDeltaDuration.as("seconds") > 0;
  // const at = startDeltaDuration.as("seconds") === 0;
  // const timeFrame = at
  //   ? "after the Shift start time"
  //   : before
  //   ? `${formatDuration(startDeltaDuration)} ago`
  //   : `${formatDuration(startDeltaDuration)} from now`;
  const seconds = startDelta.as("seconds");
  const formattedStartDelta =
    seconds === 0
      ? "This Worker is scheduled to work immediately"
      : "This Worker was scheduled to work";
  showModal({
    ...params,
    actions: [
      {
        label: "Do Not Cancel Worker",
        testID: "do-not-cancel-btn",
        outline: true,
        onPress: onRequestClose,
        color: "primary"
      },
      {
        label: `Pay Cancellation Fee ${currency.humanize(totalDue)}`,
        onPress: onConfirm,
        testID: "cancel-btn"
      }
    ],
    onRequestClose,
    children: (
      <Stack>
        <Text>
          {formattedStartDelta}. If you decide to cancel this Worker, you will
          be charged {Math.round(feeRate * 100)}% of the Worker's Shift duration
          for a total of {currency.humanize(totalDue)}.
        </Text>
        <Row justifyContent="center">
          <CancellationPoliciesInfoModal testID="cancellation-fee-info-modal" />
        </Row>
      </Stack>
    )
  });
}
