import React, { useState } from "react";

import {
  Button,
  List,
  Stack,
  Text,
  TooltipButton,
  Well
} from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import InfoModal from "@gigsmart/isomorphic-shared/modal/info-modal";
import { SummaryRow } from "@gigsmart/katana";
import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import type { gigSummaryRows_engagement$data } from "./__generated__/gigSummaryRows_engagement.graphql";
import EngagementBid from "./engagement-bid";

interface Props {
  isProject?: boolean;
  isVolunteer: boolean;
  payRate?: string | null;
  currentBid?: ObjectUtils.Path<gigSummaryRows_engagement$data, ["currentBid"]>;
  stateName?: EngagementStateName;
  pickupEligible?: boolean;
}

const showAsOffered: EngagementStateName[] = [
  // pre-scheduled states
  "APPLIED",
  "BID_REQUESTED",
  "BID_REVIEW",
  "OFFERED",
  "CONFIRMING",

  // inactive states
  "MISSED",
  "APPLICATION_DENIED",
  "APPLICATION_CANCELED",
  "REJECTED",
  "CANCELED",
  "CANCELED_WITH_PAY"
];

export default function SummaryPayment({
  isProject,
  isVolunteer,
  payRate,
  currentBid,
  pickupEligible,
  stateName
}: Props) {
  const [showBidsModal, setShowBidsModal] = useState(false);

  const renderContent = () => {
    if (isVolunteer) return "Volunteer";
    if (isProject) {
      return currentBid ? (
        <EngagementBid currentBid={currentBid} />
      ) : (
        <Stack fill={1} size="compact">
          <Text>Accepting Bids</Text>
          <TooltipButton
            testID="bids-learn-more-btn"
            label="Learn About Bids"
            onPress={() => setShowBidsModal(true)}
          />
        </Stack>
      );
    }

    if (pickupEligible) return "Pick Up Rate";
    if (HourlyRateBids.isDisabled()) return "Hourly Rate";

    const isOffered = !stateName || showAsOffered.includes(stateName);
    return isOffered ? "Offered Hourly Rate" : "Accepted Hourly Rate";
  };

  return (
    <>
      <SummaryRow
        noBorder
        iconName="dollar-sign"
        sectionLabel="Payment"
        variant={isVolunteer || isProject ? "classic" : "data"}
        sectionContent={renderContent()}
        data={`${
          HourlyRateBids.isEnabled()
            ? currency.humanizeRate(currentBid?.payRate ?? payRate ?? "0 USD")
            : currency.humanizeRate(payRate ?? "0 USD")
        }`}
      />
      <InfoModal
        testID="how-bids-work-modal"
        eventContext="HowBidsWorkModal"
        visible={showBidsModal}
        onClose={() => setShowBidsModal(false)}
      >
        <Text variant="header" align="center" color="primary">
          How Bids Work
        </Text>
        <Text>
          Project Gigs require you to submit a bid as a part of your
          application. Your bid should be the amount of money you would like for
          completing the Project.
        </Text>
        <Well>
          <List>
            <Text>Place an initial bid when you apply</Text>
            <Text>Your bid and application will be sent to the Requester</Text>
            <Text>The Requester can accept or reject your Application</Text>
            <Text>
              If accepted, communicate directly with the Requester to finalize
              the Project terms
            </Text>
            <Text>Complete the Project!</Text>
          </List>
        </Well>
        <Button
          label="Got It"
          testID="close-modal-btn"
          onPress={() => setShowBidsModal(false)}
        />
      </InfoModal>
    </>
  );
}
