import { ErrorPage, Platform, SafeAreaProvider } from "@gigsmart/atorasu";
import { DekigotoError, captureError, track } from "@gigsmart/dekigoto";
import { DeveloperDebug } from "@gigsmart/feature-flags";
import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import { InstrumentationError } from "@gigsmart/pickle/support/utils/instrumentation-client";
import type { RelayRequestError } from "@gigsmart/relay";
import React, { Component, type ComponentType, type ReactNode } from "react";
import RNRestart from "react-native-restart";
import { isUnauthError } from "../relay/auth-token";

interface Props {
  children: ReactNode;
}

interface State {
  error: PandaError<Error | RelayRequestError> | null;
}

// bypass error-boundary if the user somehow got unauthenticated
// example: logging out from another tab
function shouldIgnoreError(error: Error) {
  return error.name === "RelayNetwork" && isUnauthError((error as any).source);
}

export class PandaError<E extends Error> extends DekigotoError<E> {
  constructor(public readonly source: E) {
    super(source);
    this.name = "PandaError";
  }
}

export default class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(source: Error, _info: unknown) {
    if (shouldIgnoreError(source)) return;

    const shouldOverride =
      process.env.CONFIG_ENV === "prod" && DeveloperDebug.isDisabled();
    const error =
      source instanceof PandaError && !shouldOverride
        ? source
        : new PandaError(source);

    track(`🐼: ${error.message}`);
    captureError(error, "critical");
  }

  handleReload = () => {
    if (Platform.OS === "web") window.location.replace("/");
    else RNRestart.restart();
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <>
          <InstrumentationError error={error} />
          <ErrorPage
            errorId={error.id}
            title={error.name}
            message={error.message}
            errorStack={error.stack}
            onReload={this.handleReload}
          />
        </>
      );
    }
    return children;
  }
}

export const withErrorBoundary: HOCVoid = <T,>(
  WrappedComponent: ComponentType<T>
) =>
  applyHOCProperties({
    displayName: "error",
    WrappedComponent,
    HigherOrderComponent: (props) => (
      <SafeAreaProvider>
        <ErrorBoundary>
          <WrappedComponent {...props} />
        </ErrorBoundary>
      </SafeAreaProvider>
    )
  });
