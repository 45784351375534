import { Button, ContentArea, Spacer, Stack } from "@gigsmart/atorasu";
import { WorkerShiftCardUI } from "@gigsmart/feature-flags";
import { WorkerShiftAggregateCard } from "@gigsmart/seibutsu/gig-like/WorkerShiftCard";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "@gigsmart/seibutsu/gig-series/WorkerLatestArrivalTimeReminder";
import React from "react";

import OfferedEntry from "../../gig/engagement-list/offered-entry";
import type { SeriesGroupData } from "../../gig/engagement-list/use-engagement-list.gql";

interface Props {
  aggregate: SeriesGroupData;
  onPress?: () => void;
  onPressAcceptOffers?: () => void;
}

// TODO: Use GigSeries fragment when possible
export default function FakeGigSeriesCard({
  aggregate: {
    engagementRef,
    isEnrolled,
    gigCount,
    hasMultipleLocations,
    nextGig,
    paymentRange,
    distanceRange
  },
  onPress,
  onPressAcceptOffers
}: Props) {
  const isSeries =
    (!!paymentRange && paymentRange.max !== paymentRange.min) ||
    hasMultipleLocations ||
    gigCount > 1;

  if (WorkerShiftCardUI.isEnabled()) {
    return (
      <ContentArea size="none" variant="medium">
        <WorkerShiftAggregateCard
          onPress={onPress}
          shiftCount={gigCount}
          distance={distanceRange}
          netPay={paymentRange}
          fragmentRef={engagementRef as any}
          nextGig={nextGig}
          onPressAcceptOffers={onPressAcceptOffers}
        />
      </ContentArea>
    );
  }

  return (
    <OfferedEntry
      isEnrolled={isEnrolled}
      onPress={onPress}
      engagementRef={engagementRef as any}
      hasMultipleLocations={hasMultipleLocations}
      estimatedPaymentRange={paymentRange}
      distanceRange={distanceRange}
      gigCount={gigCount}
      bottomAccessory={
        isSeries ? (
          <ContentArea size="none">
            <Stack>
              <Button
                testID="view-accept-offers-btn"
                label="View & Accept Offers"
                onPress={onPressAcceptOffers}
                size="xsmall"
              />
              {showLatestArrivalReminder(nextGig) && (
                <WorkerLatestArrivalTimeReminder
                  workerStateName="OFFERED"
                  fragmentRef={nextGig}
                  isMultiple={gigCount > 1}
                />
              )}
            </Stack>
            <Spacer />
          </ContentArea>
        ) : null
      }
    />
  );
}
