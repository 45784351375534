import React from "react";
import RadioQuestion from "../questionnaire/RadioQuestion";

interface Props {
  workerName: string;
}

const BlockWorkerQuestion = ({ workerName }: Props) => (
  <RadioQuestion
    required
    name="shouldBlock"
    title={`Would you like to block ${workerName} from future Gigs?`}
    buttons={[
      {
        testID: "dont-block-worker",
        title: `No, don't block ${workerName}`,
        value: "no",
        eventTargetName: "Dont Block Worker Radio"
      },
      {
        testID: "block-worker",
        title: `Yes, block ${workerName}`,
        value: "yes",
        eventTargetName: "Block Worker Radio"
      }
    ]}
  />
);

export default BlockWorkerQuestion;
