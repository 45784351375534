import {
  AbsoluteContainer,
  Column,
  ContentArea,
  Spacer,
  Stack,
  Surface,
  Text,
  View
} from "@gigsmart/atorasu";
import { useMatchesViewport, useStyles } from "@gigsmart/atorasu/style";
import { useAppSettings } from "@gigsmart/isomorphic-shared/app/app-settings";
import Logo from "@gigsmart/isomorphic-shared/brand/logo";
import { StyledScrollView } from "@gigsmart/katana";
import React from "react";
import LoginForm from "./AuthForm";
import GetGigsSlider from "./Slides/GetGigsSlider";
import GetWorkersSlider from "./Slides/GetWorkersSlider";
import MobileHero from "./svgs/MobileHero";

interface Props {
  signup?: boolean;
  isEmail?: boolean;
}

export default function AuthScreen({ signup, isEmail }: Props) {
  const isLg = useMatchesViewport(({ size }) => size.large.up);
  const isMd = useMatchesViewport(({ size }) => size.medium.down);
  const isXs = useMatchesViewport({ maxWidth: 767 });

  const { appName } = useAppSettings();

  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      sky: {
        backgroundColor: getColor("#edf0f2", "fill"),
        height: 84
      },
      ground: {
        backgroundColor: getColor("#bbd5a6", "fill"),
        height: 32
      },
      carouselWrapper: {
        width: "100%",
        backgroundColor: getColor("primary", "fill", { opacity: 0.08 }),
        flex: 1,
        ...(isLg && { width: "55%", justifyContent: "center" })
      },
      ...(!isXs &&
        !isLg && {
          formWrapperMdOnly: {
            paddingTop: getUnits(5),
            paddingBottom: getUnits(17)
          }
        }),
      formWrapperLg: { width: "45%" }
    }),
    [isXs, isMd, isLg]
  );

  const content = (
    <Surface testID="auth-login-screen" variant="flat" fill>
      <Column fill>
        <Stack horizontal={isLg} size={isLg ? "none" : undefined} fill>
          {isXs && (
            <View>
              <View style={styles.sky} />
              <View style={styles.ground} />
              <AbsoluteContainer>
                <MobileHero height={116} />
              </AbsoluteContainer>
            </View>
          )}
          {!isXs && (
            <Column fill style={styles.carouselWrapper} justifyContent="center">
              <ContentArea fill justifyContent="center">
                {appName === "Get Gigs" && <GetGigsSlider />}
                {appName === "Get Workers" && <GetWorkersSlider />}
              </ContentArea>
            </Column>
          )}
          {isMd ? (
            <View style={styles.formWrapperMdOnly}>
              <LoginForm signup={signup} isEmail={isEmail} />
            </View>
          ) : (
            <Column justifyContent="center" style={styles.formWrapperLg}>
              <Column alignItems="center">
                <Logo height={36} />
                <Spacer size="medium" />
                <Text variant="subheader" color="primary">
                  {appName}
                </Text>
                <Spacer size="large" />
              </Column>
              <LoginForm signup={signup} isEmail={isEmail} />
            </Column>
          )}
        </Stack>
      </Column>
    </Surface>
  );

  return !isXs || signup ? (
    content
  ) : (
    <StyledScrollView
      extraHeight={160}
      extraScrollHeight={40}
      enableResetScrollToCoords
      resetScrollToCoords={{ x: 0, y: 0 }}
      automaticallyAdjustContentInsets={false}
    >
      {content}
    </StyledScrollView>
  );
}
