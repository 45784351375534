import React from "react";

import {
  Avatar,
  Column,
  ContentArea,
  IconButton,
  Row,
  Text
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import PhoneAction from "@gigsmart/isomorphic-shared/messaging/phone-action";
import { type NavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import useMessaging from "../../messaging/useMessaging";
import type { SharedParamList } from "../../navigation/types";
import type { ShiftOrgActionRow_engagement$key } from "./__generated__/ShiftOrgActionRow_engagement.graphql";

type Props = {
  children?: React.ReactNode;
};

export const ShiftOrgActionRow = ({
  id,
  gigType,
  contactNumber,
  conversation,
  gig,
  children,
  result
}: FragmentContainerInnerComponentProps<
  ShiftOrgActionRow_engagement$key,
  Props
>) => {
  const { organization, primaryContact } = gig ?? {};
  const navigation = useNavigation<NavigationProp<SharedParamList>>();
  const user = useCurrentUser();
  const { isMessagingAvailable, onUnavailableMessagingPress } = useMessaging({
    engagementRef: result
  });

  return (
    <ContentArea testID="shift-requester-row" size="medium" gap="standard">
      <Row gap="compact" alignItems="center">
        <Avatar size="small" icon="building" uri={organization?.logoUrl} />
        <Column fill>
          <Text
            weight="bold"
            color="primary"
            numberOfLines={1}
            testID="organization-name-txt"
          >
            {organization?.name}
          </Text>
          <Text variant="note" numberOfLines={1} testID="primary-contact-txt">
            Primary Contact: {primaryContact?.displayName}
          </Text>
        </Column>
        <Row gap="large">
          <IconButton
            testID="profile-btn"
            name="circle-user"
            variant="solid"
            color="primary"
            size="medium"
            align="center"
            onPress={() =>
              navigation.navigate(
                gigType === "PROJECT"
                  ? "ProjectRequesterProfile"
                  : "ShiftRequesterProfile",
                { id }
              )
            }
          />
          <PhoneAction
            isWorker
            engagement={result}
            calleePhoneNumber={contactNumber}
            callerPhoneNumber={user?.primaryMobile?.number}
          >
            {({ isCallable, onPress }) => (
              <IconButton
                size="medium"
                variant="solid"
                color={isCallable ? "primary" : "neutral"}
                name="phone"
                align="center"
                onPress={onPress}
                testID="call-btn"
              />
            )}
          </PhoneAction>
          <IconButton
            testID="message-btn"
            name="message-lines"
            variant="solid"
            color={isMessagingAvailable ? "primary" : "neutral"}
            size="medium"
            align="center"
            onPress={
              isMessagingAvailable
                ? () =>
                    navigation.navigate("Conversation", {
                      topicId: conversation?.id ?? "",
                      contextId: gig.id
                    })
                : onUnavailableMessagingPress
            }
          />
        </Row>
      </Row>
      {!!children && <Column gap="compact">{children}</Column>}
    </ContentArea>
  );
};

export default createRelayFragmentContainer<
  ShiftOrgActionRow_engagement$key,
  Props
>(
  graphql`
    fragment ShiftOrgActionRow_engagement on Engagement {
      id
      gigType
      contactNumber
      ...usePhoneCall_engagement
      ...useMessaging_engagement
      gig {
        id
        primaryContact {
          displayName
        }
        organization {
          name
          logoUrl
        }
      }
      conversation {
        ... on Node {
          id
        }
      }
    }
  `,
  ShiftOrgActionRow
);
