/**
 * @generated SignedSource<<f1f1fd2bfa7f732448d7d52e80f5bfb5>>
 * @relayHash 460413ac3285e6783071e8919550100d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:a458bjPyVwG1sGBNaxX2S82SfHqw3bfg5Age2gQ2hkQ

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profileScreenRefreshedQuery$variables = Record<PropertyKey, never>;
export type profileScreenRefreshedQuery$data = {
  readonly viewer: {
    readonly firstName?: string | null | undefined;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"CategoryPositionsCard_workerProfile" | "DirectHireCard_Worker" | "EducationCard_worker" | "LanguagesCard_Worker" | "MilitaryCard_Worker" | "ProfileInfoCard_worker" | "ProfileStrengthCard_worker" | "QualificationCategories_gigFields" | "TransportationMethodCard_Worker" | "VerificationStatusCard_worker" | "WorkHistoryCard_worker">;
  } | null | undefined;
};
export type profileScreenRefreshedQuery = {
  response: profileScreenRefreshedQuery$data;
  variables: profileScreenRefreshedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v8 = {
  "kind": "Literal",
  "name": "query",
  "value": "WHERE status = CONFIRMED"
},
v9 = {
  "alias": "selectedDefinitions",
  "args": [
    (v3/*: any*/),
    (v8/*: any*/)
  ],
  "concreteType": "GigFieldItemDefinitionsConnection",
  "kind": "LinkedField",
  "name": "itemDefinitions",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "itemDefinitions(first:0,query:\"WHERE status = CONFIRMED\")"
},
v10 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v11 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "profileScreenRefreshedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileInfoCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileStrengthCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkHistoryCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EducationCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DirectHireCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TransportationMethodCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VerificationStatusCard_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LanguagesCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MilitaryCard_Worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QualificationCategories_gigFields"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CategoryPositionsCard_workerProfile"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "profileScreenRefreshedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPronoun",
                "kind": "LinkedField",
                "name": "pronoun",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeArea1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "public",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "favoritedByOrganizationsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "biography",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overallRating",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerGigStats",
                "kind": "LinkedField",
                "name": "gigStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gigsCompleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uniqueOrganizationsWorked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalEarned",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "EngagementReviewsConnection",
                "kind": "LinkedField",
                "name": "reviewsReceived",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "reviewsReceived(first:0)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "WorkerWorkHistoryConnection",
                "kind": "LinkedField",
                "name": "workHistory",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "workHistory(first:0)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "WorkerEducationConnection",
                "kind": "LinkedField",
                "name": "education",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "education(first:0)"
              },
              {
                "alias": "languageCount",
                "args": (v4/*: any*/),
                "concreteType": "WorkerLanguagesConnection",
                "kind": "LinkedField",
                "name": "languages",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "languages(first:0)"
              },
              {
                "alias": "militaryCount",
                "args": (v4/*: any*/),
                "concreteType": "WorkerMilitaryServicesConnection",
                "kind": "LinkedField",
                "name": "militaryServices",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "militaryServices(first:0)"
              },
              {
                "alias": "licenseCount",
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE title='Licenses & Certifications'"
                  }
                ],
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:1,query:\"WHERE title='Licenses & Certifications'\")"
              },
              {
                "alias": "commercialLicenses",
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE title LIKE 'Commercial Driver'"
                  }
                ],
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "selectedCDL",
                            "args": [
                              (v10/*: any*/),
                              (v8/*: any*/)
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": "itemDefinitions(first:10,query:\"WHERE status = CONFIRMED\")"
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:1,query:\"WHERE title LIKE 'Commercial Driver'\")"
              },
              {
                "alias": "experience",
                "args": [
                  (v11/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC"
                  }
                ],
                "concreteType": "WorkerWorkHistoryConnection",
                "kind": "LinkedField",
                "name": "workHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerWorkHistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerWorkHistory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employer",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employmentStart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employmentEnd",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "positionTitle",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "workHistory(first:50,query:\"ORDER BY employmentEnd DESC NULLS FIRST, employmentStart DESC\")"
              },
              {
                "alias": "educationHistory",
                "args": [
                  (v11/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY endDate DESC NULLS FIRST, startDate DESC NULLS FIRST"
                  }
                ],
                "concreteType": "WorkerEducationConnection",
                "kind": "LinkedField",
                "name": "education",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerEducationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerEducation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "schoolName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "educationLevel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "education(first:50,query:\"ORDER BY endDate DESC NULLS FIRST, startDate DESC NULLS FIRST\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "directHireBonus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transportationMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerVerification",
                "kind": "LinkedField",
                "name": "verification",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VerificationReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusDescription",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerLegalInformation",
                "kind": "LinkedField",
                "name": "legalInformation",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundCheckOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "driverCheckOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "drugScreenOptIn",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v13/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY language ASC"
                  }
                ],
                "concreteType": "WorkerLanguagesConnection",
                "kind": "LinkedField",
                "name": "languages",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerLanguagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerLanguage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "language",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "level",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "languages(first:30,query:\"ORDER BY language ASC\")"
              },
              {
                "alias": null,
                "args": [
                  (v13/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY serviceEnd DESC NULLS FIRST, serviceStart DESC NULLS FIRST"
                  }
                ],
                "concreteType": "WorkerMilitaryServicesConnection",
                "kind": "LinkedField",
                "name": "militaryServices",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerMilitaryServicesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerMilitaryService",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "branch",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rank",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "serviceStart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "serviceEnd",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "militaryIdLast4",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "militaryServices(first:30,query:\"ORDER BY serviceEnd DESC NULLS FIRST, serviceStart DESC NULLS FIRST\")"
              },
              {
                "alias": null,
                "args": [
                  (v10/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "ORDER BY order ASC"
                  }
                ],
                "concreteType": "GigFieldsConnection",
                "kind": "LinkedField",
                "name": "gigFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigFieldsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "iconName",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": "availableDefinitions",
                            "args": [
                              (v3/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE status IN [DENIED, UNKNOWN]"
                              }
                            ],
                            "concreteType": "GigFieldItemDefinitionsConnection",
                            "kind": "LinkedField",
                            "name": "itemDefinitions",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": "itemDefinitions(first:0,query:\"WHERE status IN [DENIED, UNKNOWN]\")"
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "gigFields(first:10,query:\"ORDER BY order ASC\")"
              },
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE status=CONFIRMED"
                  }
                ],
                "concreteType": "WorkerGigPositionsConnection",
                "kind": "LinkedField",
                "name": "positions",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "positions(first:0,query:\"WHERE status=CONFIRMED\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:a458bjPyVwG1sGBNaxX2S82SfHqw3bfg5Age2gQ2hkQ",
    "metadata": {},
    "name": "profileScreenRefreshedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "60585f49c4d2b5eb44868d9460a9e4ba";

export default node;
