/**
 * @generated SignedSource<<48c5428cac2d381ac01aab7c4e695b8a>>
 * @relayHash 3874278ba50c945468c304c5b955c7e8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:lXyOtSchllQ7lF4kV1uaO5uHD-jXZv5o06WSHe0e4qY

import { ConcreteRequest } from 'relay-runtime';
export type MarkAllAsReadInput = {
  userId: string;
};
export type MarkAllAsReadButtonMutation$variables = {
  input: MarkAllAsReadInput;
};
export type MarkAllAsReadButtonMutation$data = {
  readonly markAllAsRead: {
    readonly conversationParticipantEdges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly readUntil: string | null | undefined;
      } | null | undefined;
    }>;
  } | null | undefined;
};
export type MarkAllAsReadButtonMutation = {
  response: MarkAllAsReadButtonMutation$data;
  variables: MarkAllAsReadButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkAllAsReadPayload",
    "kind": "LinkedField",
    "name": "markAllAsRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConversationParticipantsEdge",
        "kind": "LinkedField",
        "name": "conversationParticipantEdges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationParticipant",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readUntil",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkAllAsReadButtonMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkAllAsReadButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:lXyOtSchllQ7lF4kV1uaO5uHD-jXZv5o06WSHe0e4qY",
    "metadata": {},
    "name": "MarkAllAsReadButtonMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "330733ef3455c34d2f9a82a674a60f70";

export default node;
