// eslint-disable-next-line no-restricted-imports
import { type Color, Stack } from "@gigsmart/atorasu";
// eslint-disable-next-line no-restricted-imports
import { usePreviousValue } from "@gigsmart/atorasu/hooks";
// FIXME: Move to Seibutsu
// eslint-disable-next-line no-restricted-imports
import { Icon, type IconName, Text } from "@gigsmart/atorasu/quarks";
import React, { useState, useEffect } from "react";
import { createStyles } from "../style";

interface Props {
  count: number;
  iconName: IconName;
  iconColor: Color;
}

export default function EngagementState({ count, iconName, iconColor }: Props) {
  const prevCount = usePreviousValue(count);
  const [textColor, setTextColor] = useState<Color | undefined>(undefined);
  const nextTextColor =
    count > prevCount ? "success" : count < prevCount ? "danger" : undefined;

  useEffect(() => {
    setTextColor(nextTextColor);
    const timeout = setTimeout(setTextColor, 1000, undefined);
    return () => clearTimeout(timeout);
  }, [count, nextTextColor]);

  return (
    <Stack size="slim" alignSelf="center">
      <Icon
        name={iconName}
        variant="solid"
        color={count === 0 ? "neutral" : iconColor}
        size="small"
        style={styles.icon}
      />
      <Text
        color={textColor ?? (count === 0 ? "neutral" : "black")}
        align="center"
        weight={textColor ? "bold" : undefined}
      >
        {nFormatter(count)}
      </Text>
    </Stack>
  );
}

function nFormatter(num: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol
    : "0";
}

const styles = createStyles(() => ({
  verticalContainer: {
    flexDirection: "column"
  },
  icon: {
    paddingHorizontal: 4
  }
}));
