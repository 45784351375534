/**
 * @generated SignedSource<<595f18ff4df2bef527fa32874ced1fe0>>
 * @relayHash 79793b1ef76a9dbc24cedd6e4d2891c9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:JUfFespJRUyw5WJfmk6_IkAzcLdj2KwlV5N04U2efLY

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireType = "HIRE" | "PAYMENT" | "%future added value";
export type UpdateDirectHireClaimInput = {
  comments?: string | null | undefined;
  directHireClaimId: string;
  directHireDate?: string | null | undefined;
  directHireType?: DirectHireType | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  organizationId?: string | null | undefined;
  paymentMethodId?: string | null | undefined;
  placeId?: string | null | undefined;
  response?: string | null | undefined;
  timezone?: string | null | undefined;
  workerId?: string | null | undefined;
};
export type TypeStepUpdateDirectHireClaimMutation$variables = {
  input: UpdateDirectHireClaimInput;
};
export type TypeStepUpdateDirectHireClaimMutation$data = {
  readonly updateDirectHireClaim: {
    readonly directHireClaim: {
      readonly directHireType: DirectHireType | null | undefined;
      readonly id: string;
    };
  } | null | undefined;
};
export type TypeStepUpdateDirectHireClaimMutation = {
  response: TypeStepUpdateDirectHireClaimMutation$data;
  variables: TypeStepUpdateDirectHireClaimMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDirectHireClaimPayload",
    "kind": "LinkedField",
    "name": "updateDirectHireClaim",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectHireClaim",
        "kind": "LinkedField",
        "name": "directHireClaim",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directHireType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TypeStepUpdateDirectHireClaimMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TypeStepUpdateDirectHireClaimMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "qpid:JUfFespJRUyw5WJfmk6_IkAzcLdj2KwlV5N04U2efLY",
    "metadata": {},
    "name": "TypeStepUpdateDirectHireClaimMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f8483ebd53603d92ec8082d879036f9e";

export default node;
