import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { getConnectionEdges, graphql } from "@gigsmart/relay";
import { WorkerAvailableSeriesCard } from "@gigsmart/seibutsu/gig-like/WorkerShiftCard";
import InfiniteGridList from "@gigsmart/seibutsu/shared/InfiniteList/InfiniteGridList";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useMemo } from "react";

import type { WorkerParamList } from "../navigation/types";
import type { availableGigsListPageQuery } from "./__generated__/availableGigsListPageQuery.graphql";
import type { availableGigsList_GigSeries$key } from "./__generated__/availableGigsList_GigSeries.graphql";
import type { SearchFilter } from "./browse-screen-base/search";
import ShiftGigsDismissableCard from "./shift-gigs-screen/shift-gigs-dismissable-card";
import ShiftGigsEmptyView from "./shift-gigs-screen/shift-gigs-empty-view";
import ShiftGigsListFooter from "./shift-gigs-screen/shift-gigs-list-footer";

interface Props {
  filter: SearchFilter;
  viewerFragment: availableGigsList_GigSeries$key | null | undefined;
}

type AvailableSeriesType = NonNullable<
  ObjectPath<
    availableGigsList_GigSeries$key,
    [" $data", "availableSeries", "edges", 0]
  >
>;

export default function AvailableGigsList({ filter, viewerFragment }: Props) {
  const nav = useNavigation<AppNavigationProp<WorkerParamList>>();
  const variables = useMemo(
    () => ({
      maxDistance: filter.radiusMiles,
      searchTerm: filter.searchTerm,
      gigTypes: filter.gigTypes
    }),
    [filter]
  );

  return (
    <InfiniteGridList<
      availableGigsListPageQuery,
      availableGigsList_GigSeries$key,
      AvailableSeriesType
    >
      parentRef={viewerFragment}
      fragmentInput={graphql`
        fragment availableGigsList_GigSeries on Worker
        @refetchable(queryName: "availableGigsListPageQuery")
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 10 }
          after: { type: "String" }
          maxDistance: { type: "Int" }
          crisisId: { type: "ID" }
          searchTerm: { type: "String" }
          gigTypes: { type: "[GigType!]" }
        ) {
          availableSeries: availableGigSeries(
            first: $count
            after: $after
            input: {
              crisisId: $crisisId
              maxDistance: $maxDistance
              searchTerm: $searchTerm
              gigTypes: $gigTypes
            }
          )
            @connection(
              key: "availableGigsList_availableSeries"
              filters: ["input"]
            ) {
            edges {
              ...WorkerAvailableSeriesCard_seriesEdge
              cursor
              node @trackImpressions(action: ACCESSED) {
                __typename
                id
                name
              }
            }
          }
        }
      `}
      getData={(data) => getConnectionEdges(data?.availableSeries)}
      refetchDebounce={10}
      refetchVars={variables}
      keyExtractor={(item) => item.node?.id ?? ""}
      renderItem={(item) => (
        <WorkerAvailableSeriesCard
          fragmentRef={item}
          onPress={() =>
            nav.push("BrowseShiftDetails", {
              id: item.node?.id ?? "",
              type: "shifts"
            })
          }
        />
      )}
      renderHeaderView={() => <ShiftGigsDismissableCard />}
      renderFooterView={() => <ShiftGigsListFooter />}
      testID="gig-series-engagement-list"
      renderEmptyView={() => (
        <ShiftGigsEmptyView searchTerm={filter.searchTerm} />
      )}
    />
  );
}
