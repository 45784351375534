/**
 * @generated SignedSource<<50d05f3729042675d80da3a833e6e341>>
 * @relayHash 06a042c0bb9c682da08970f7b3f7c1a2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Ye7wqJjr5dgqOtc6L_9VjNL7zWch1xmAfzLbYqdNKag

import { ConcreteRequest } from 'relay-runtime';
export type handlersCrisisGigsQuery$variables = {
  crisisId?: string | null | undefined;
};
export type handlersCrisisGigsQuery$data = {
  readonly viewer: {
    readonly availableGigs?: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type handlersCrisisGigsQuery = {
  response: handlersCrisisGigsQuery$data;
  variables: handlersCrisisGigsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "crisisId"
  }
],
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "crisisId",
          "variableName": "crisisId"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "maxDistance",
          "value": 75
        }
      ],
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "availableGigs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "handlersCrisisGigsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "handlersCrisisGigsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Ye7wqJjr5dgqOtc6L_9VjNL7zWch1xmAfzLbYqdNKag",
    "metadata": {},
    "name": "handlersCrisisGigsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "457756f1767589b8fbef6a428392d0d6";

export default node;
