import { toast } from "@gigsmart/atorasu";
import BackgroundGeolocation from "react-native-background-geolocation";
import { reportLocation } from "../geolocation/geolocation";

export const updateWorkerLocation = async (skipToastMessage?: unknown) => {
  try {
    await reportLocation(
      "USER_INITIATED",
      BackgroundGeolocation.DESIRED_ACCURACY_VERY_LOW
    );
    if (skipToastMessage !== true) {
      toast.success("Location successfully updated");
    }
  } catch (err) {
    toast.error(err.message ?? "Something went wrong");
  }
};
