/**
 * @generated SignedSource<<a19510c3a485cd6dae76b0480c629104>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type JobPostingPaySchedule = "ANNUALLY" | "HOURLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type JobBoardSection_worker$data = {
  readonly availableJobPostings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly boosted: boolean | null | undefined;
        readonly id: string;
        readonly maximumPayRate: string | null | undefined;
        readonly minimumPayRate: string | null | undefined;
        readonly payRateNegotiable: boolean | null | undefined;
        readonly paySchedule: JobPostingPaySchedule | null | undefined;
        readonly title: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "JobBoardSection_worker";
};
export type JobBoardSection_worker$key = {
  readonly " $data"?: JobBoardSection_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobBoardSection_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jobPostSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobBoardSection_worker",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "jobPostSearch"
        }
      ],
      "concreteType": "AvailableJobPostingsConnection",
      "kind": "LinkedField",
      "name": "availableJobPostings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableJobPostingsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JobPosting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "boosted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paySchedule",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payRateNegotiable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maximumPayRate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minimumPayRate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "cfe1b369ea1deb310ab32663cc8f4451";

export default node;
