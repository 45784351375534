import {
  Button,
  ModalBody,
  ModalFooter,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import type { useMessaging_engagement$key } from "./__generated__/useMessaging_engagement.graphql";

interface Props {
  engagementRef: useMessaging_engagement$key | null | undefined;
}

export default function useMessaging({ engagementRef }: Props) {
  const engagement = useRelayFragment(
    graphql`
      fragment useMessaging_engagement on Engagement {
        capabilities {
          type
          status
        }
      }
    `,
    engagementRef ?? null
  );

  const isMessagingAvailable =
    engagement?.capabilities?.find(
      (capability) => capability?.type === "SEND_MESSAGE"
    )?.status === "AVAILABLE";

  const onUnavailableMessagingPress = () => {
    showModal({
      eventContext: "UseMessagingModal",
      title: "Messaging",
      children: (close) => (
        <>
          <ModalBody>
            <Text>
              Messaging will be enabled when you are hired for the Shift.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              testID="use-messaging-modal-cta"
              label="Got It"
              outline
              onPress={close}
            />
          </ModalFooter>
        </>
      )
    });
  };

  return {
    isMessagingAvailable,
    onUnavailableMessagingPress
  };
}
