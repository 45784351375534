import React from "react";
import { TouchableOpacity } from "react-native";
import { useStyles } from "../../style";
import { StyledText } from "../../text";

const CIRCLE_SIZE = 38;

interface Prop {
  label: string;
  value: number;
  isSelected?: boolean;
  onPress?: () => void;
}

export default function DayPickerEntry({
  label,
  value,
  isSelected,
  onPress
}: Prop) {
  const { styles } = useStyles(({ color, font }) => ({
    circle: {
      width: CIRCLE_SIZE,
      height: CIRCLE_SIZE,
      borderWidth: 1,
      borderRadius: CIRCLE_SIZE / 2,
      borderColor: color.blue,
      alignItems: "center",
      justifyContent: "center"
    },
    circleSelected: {
      backgroundColor: color.blue
    },
    letter: {
      ...font.body("bold"),
      fontSize: font.size.large,
      color: color.blue
    },
    letterSelected: {
      color: color.white
    }
  }));

  return (
    <TouchableOpacity
      testID={`day-picker-${value}`}
      style={[styles.circle, isSelected && styles.circleSelected]}
      onPress={onPress}
    >
      <StyledText style={[styles.letter, isSelected && styles.letterSelected]}>
        {label}
      </StyledText>
    </TouchableOpacity>
  );
}
