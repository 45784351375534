/**
 * @generated SignedSource<<27eecdac44177494e615b0c3819d6ca4>>
 * @relayHash 746561248cd6705d6ad65512c55b0595
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Yb0A38pIiFXp2U_DXR93Oo9nvKt3if25bwGuDnMbr1s

import { ConcreteRequest } from 'relay-runtime';
export type DirectHireActionRowQuery$variables = Record<PropertyKey, never>;
export type DirectHireActionRowQuery$data = {
  readonly viewer: {
    readonly directHireBonus?: string;
  } | null | undefined;
};
export type DirectHireActionRowQuery = {
  response: DirectHireActionRowQuery$data;
  variables: DirectHireActionRowQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "directHireBonus",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DirectHireActionRowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DirectHireActionRowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Yb0A38pIiFXp2U_DXR93Oo9nvKt3if25bwGuDnMbr1s",
    "metadata": {},
    "name": "DirectHireActionRowQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7384bc74eb6556a2bdffaed96a8b19d4";

export default node;
