import { Button, ContentArea, Stack } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../browse/browse.nav";
import type { WorkerLatestArrivalTimeReminder_gig$key } from "../gig-series/__generated__/WorkerLatestArrivalTimeReminder_gig.graphql";
import type { WorkerApplyFooter_gigLike$key } from "./__generated__/WorkerApplyFooter_gigLike.graphql";

interface Props {
  stateName?: EngagementStateName;
}

export const WorkerApplyFooter = ({
  __typename,
  id,
  requiredPermissions,
  requiredReportTypes,
  pickupEligible,
  nextGig,
  gigType,
  result
}: FragmentContainerInnerComponentProps<
  WorkerApplyFooter_gigLike$key,
  Props
>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const gigId = __typename === "Gig" ? id : nextGig?.edges?.[0]?.node?.id;

  let isMultiple = false;
  let gigRef: WorkerLatestArrivalTimeReminder_gig$key | null | undefined =
    result;

  if (nextGig) {
    const gig = nextGig.edges?.[0]?.node;
    gigRef = gig;
    gigType = gig?.gigType ?? "PAID";
    isMultiple = nextGig.totalCount > 1;
    requiredPermissions = gig?.requiredPermissions;
    requiredReportTypes = gig?.requiredReportTypes;
    pickupEligible = gig?.pickupEligible;
  }

  const handleApply = () => {
    navigation.push("BrowseShiftAction", {
      id,
      type: "shifts",
      action: "apply"
    });
  };

  const handlePickup = () => {
    if (!gigId) return;

    navigation.push("BrowseShiftAction", {
      id: gigId,
      type: "pickups",
      action: "pickup"
    });
  };

  const hasVerification = requiredReportTypes?.find(
    (d) => d === "BACKGROUND_CHECK" || d === "MOTOR_VEHICLE_CHECK"
  );

  return (
    <ContentArea>
      <Stack>
        {pickupEligible ? (
          <Button
            testID="apply-button"
            icon="alarm-clock"
            color="emphasized"
            onPress={handlePickup}
            label="Pick Up Shift"
          />
        ) : (
          <Button
            testID="apply-button"
            icon="user-plus"
            onPress={handleApply}
            label={
              HourlyRateBids.isEnabled() && gigType !== "PROJECT"
                ? "Submit a Bid"
                : `Apply${hasVerification ? " with Verification" : ""}`
            }
          />
        )}
      </Stack>
    </ContentArea>
  );
};

export default createRelayFragmentContainer<
  WorkerApplyFooter_gigLike$key,
  Props
>(
  graphql`
    fragment WorkerApplyFooter_gigLike on GigLike {
      __typename
      id
      gigType
      ... on Gig {
        requiredReportTypes
        requiredPermissions
        pickupEligible
        ...WorkerLatestArrivalTimeReminder_gig
      }
      ... on GigSeries {
        nextGig: availableGigs(first: 1) {
          totalCount
          edges {
            node {
              id
              gigType
              requiredReportTypes
              requiredPermissions
              pickupEligible
              ...WorkerLatestArrivalTimeReminder_gig
            }
          }
        }
      }
    }
  `,
  WorkerApplyFooter
);
