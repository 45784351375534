/**
 * @generated SignedSource<<d637a7bc1faba6fe13aacec49ddc93a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type VerificationReportStatus = "CANCELED" | "FAIL" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PASS" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditProfileForm_worker$data = {
  readonly administrativeArea1: string | null | undefined;
  readonly biography: string | null | undefined;
  readonly birthdate: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly lastName: string | null | undefined;
  readonly locality: string | null | undefined;
  readonly postalCode: string | null | undefined;
  readonly primaryMobile: {
    readonly number: string;
  } | null | undefined;
  readonly profilePhoto: {
    readonly public: boolean;
  } | null | undefined;
  readonly street1: string | null | undefined;
  readonly street2: string | null | undefined;
  readonly verification: {
    readonly reports: ReadonlyArray<{
      readonly status: VerificationReportStatus;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderRefreshed_worker">;
  readonly " $fragmentType": "EditProfileForm_worker";
};
export type EditProfileForm_worker$key = {
  readonly " $data"?: EditProfileForm_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditProfileForm_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditProfileForm_worker",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "skipIfNotWorker",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProfileHeaderRefreshed_worker"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "administrativeArea1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biography",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMobile",
      "kind": "LinkedField",
      "name": "primaryMobile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profilePhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "public",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerVerification",
      "kind": "LinkedField",
      "name": "verification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VerificationReport",
          "kind": "LinkedField",
          "name": "reports",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "77daa2a870c670e89e3ca7a9de16ea40";

export default node;
