/**
 * @generated SignedSource<<333db2db5ee4f1e832fdc219aa4d0937>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type UserRestrictionActionType = "CONFIDENCE" | "SEND_MESSAGES" | "VIEW_GIGS" | "VIEW_JOB_POSTINGS" | "WORK_GIGS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RestrictedAction_restriction$data = {
  readonly description: string;
  readonly type: UserRestrictionActionType;
  readonly " $fragmentType": "RestrictedAction_restriction";
};
export type RestrictedAction_restriction$key = {
  readonly " $data"?: RestrictedAction_restriction$data;
  readonly " $fragmentSpreads": FragmentRefs<"RestrictedAction_restriction">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RestrictedAction_restriction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "RestrictedUserAction",
  "abstractKey": null
};

(node as any).hash = "987c8c46ca739dfed06bd1b36fd789af";

export default node;
